import React, { useState, useEffect, useCallback } from "react";
import { Input, InputAdornment } from "@mui/material";
import { CustomIconButton, icon } from "../../../../common/components";
import styles from "./PasswordInput.module.css";
import { translate } from "../../../../common/providers";

export default function PasswordInput({ onChange }) {
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [isPasswordLongEnough, setIsPasswordLongEnough] = useState(false);
	const [containsNumbers, setContainsNumbers] = useState(false);
	const [hasUppercase, setHasUppercase] = useState(false);
	const [hasLowerCase, setHasLowerCase] = useState(false);
	const [containsSymbol, setContainsSymbol] = useState(false);
	const [passwordsMatch, setPasswordsMatch] = useState(false);

	const changePassword = useCallback(() => {
		onChange({
			password,
			isPasswordLongEnough,
			containsNumbers,
			hasUppercase,
			hasLowerCase,
			containsSymbol,
			passwordsMatch,
		});
	}, [
		onChange,
		password,
		isPasswordLongEnough,
		containsNumbers,
		hasUppercase,
		hasLowerCase,
		containsSymbol,
		passwordsMatch,
	]);
	const handleClickShowPassword = () => {
		setShowPassword((prev) => !prev);
	};
	const checkForNumbers = (string) => {
		const matches = new RegExp(/\d+/g);
		setContainsNumbers(matches.test(string));
	};
	const checkForUpperCase = (string) => {
		const matches = new RegExp(/[A-Z]/);
		setHasUppercase(matches.test(string));
	};
	const checkForLowerCase = (string) => {
		const matches = new RegExp(/[a-z]/);
		setHasLowerCase(matches.test(string));
	};
	const checkForSymbols = (string) => {
		setContainsSymbol(/[@!#$%^&+=*]/g.test(string));
	};
	const handleChange = (e) => {
		const { value } = e.target;
		checkForNumbers(value);
		checkForUpperCase(value);
		checkForLowerCase(value);
		checkForSymbols(value);
		setIsPasswordLongEnough(value.length > 7);
		setPassword(value);
		setPasswordsMatch(value === passwordConfirm);
	};
	const handleChangePasswordConfirm = (e) => {
		const { value } = e.target;
		setPasswordsMatch(value === password);
		setPasswordConfirm(value);
	};
	useEffect(() => {
		changePassword();
	}, [password, passwordConfirm, changePassword]);
	return (
		<>
			<ul className={styles.conditionsList}>
				<li
					className={`${styles.conditionList__item} ${
						isPasswordLongEnough ? styles["conditionList__item--green"] : ""
					}`}
				>
					{translate("common:reset-pwd.pwd-contain-nbr-characters")}
				</li>
				<li
					className={`${styles.conditionList__item} ${
						containsNumbers ? styles["conditionList__item--green"] : ""
					}`}
				>
					{translate("common:reset-pwd.pwd-contain-numbers")}
				</li>
				<li
					className={`${styles.conditionList__item} ${
						hasUppercase ? styles["conditionList__item--green"] : ""
					}`}
				>
					{translate("common:reset-pwd.pwd-contain-uppercase")}
				</li>
				<li
					className={`${styles.conditionList__item} ${
						hasLowerCase ? styles["conditionList__item--green"] : ""
					}`}
				>
					{translate("common:reset-pwd.pwd-contain-lowercase")}
				</li>
				<li
					className={`${styles.conditionList__item} ${
						containsSymbol ? styles["conditionList__item--green"] : ""
					}`}
				>
					{translate("common:reset-pwd.pwd-contain-symbol")}
				</li>
				<li
					className={`${styles.conditionList__item} ${
						passwordsMatch ? styles["conditionList__item--green"] : ""
					}`}
				>
					{translate("common:reset-pwd.pwd-match")}
				</li>
			</ul>
			<Input
				autoComplete="new-password"
				className={styles.newPassword}
				endAdornment={
					<InputAdornment position="end">
						<CustomIconButton
							icon={showPassword ? icon.faEye : icon.faEyeSlash}
							onClick={handleClickShowPassword}
						/>
					</InputAdornment>
				}
				placeholder={`${translate("common:reset-password.placeholder.new-password")}*`}
				type={showPassword ? "text" : "password"}
				value={password}
				onChange={handleChange}
			/>
			<Input
				autoComplete="new-password"
				className={styles.passwordConfirmation}
				endAdornment={
					<InputAdornment position="end">
						<CustomIconButton
							icon={showPassword ? icon.faEye : icon.faEyeSlash}
							onClick={handleClickShowPassword}
						/>
					</InputAdornment>
				}
				placeholder={`${translate("common:reset-password.placeholder.confirm-password")}*`}
				type={showPassword ? "text" : "password"}
				value={passwordConfirm}
				onChange={handleChangePasswordConfirm}
			/>
		</>
	);
}
