import { useEffect } from "react";
import KeycloakService from "./KeycloakService";

const MIN_VALIDITY = 295000;
function TokenRefreshComponent() {
	useEffect(() => {
		const refreshInterval = setInterval(() => {
			KeycloakService.kcAll()
				.updateToken(295)
				.catch(() => {
					KeycloakService.doLogout().then(() => {
						KeycloakService.clearToken();
					});
				});
		}, MIN_VALIDITY); // milliseconds
		return () => {
			clearInterval(refreshInterval);
		};
	}, []);
	return null;
}

export default TokenRefreshComponent;
