import React, { useEffect, useState } from "react";
import { icon, SidePanelContent, ViewTabPanel, ViewTabs } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import * as TABS from "./tabs";

export default function NormPanel({ accessTab, open, norm, onActive, onClose }) {
	const [selectedTab, setSelectedTab] = useState(0);
	useEffect(() => {
		if (!open) {
			setSelectedTab(0);
		}
	}, [open]);
	useEffect(() => setSelectedTab(accessTab), [accessTab]);
	return (
		<SidePanelContent title={norm?.name || ""} onClose={onClose}>
			<ViewTabs
				selectedTab={selectedTab}
				tabs={[
					{ label: translate("norms-center.norm.tabs.details"), iconName: icon.faSearch },
					{ label: translate("norms-center.norm.tabs.detections"), iconName: icon.faList },
					{
						label: translate("norms-center.norm.tabs.new-version"),
						iconName: icon.faCalendarCheck,
					},
				]}
				onChange={(_, tab) => setSelectedTab(tab)}
			/>
			{Object.keys(TABS).map((tab, index) => (
				<ViewTabPanel key={tab} noPadding index={index} value={selectedTab}>
					{TABS[tab]({
						normId: norm?.normId || 0,
						normName: norm?.name || "",
						onActive,
					})}
				</ViewTabPanel>
			))}
		</SidePanelContent>
	);
}
