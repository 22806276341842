import client from "../../client/client";

class GonogoSynthesisService {
	updateComment({ categoryId }, payload, cancelToken) {
		return client.patch(`/select/categories/${categoryId}/synthesis/comments`, {
			payload: { value: payload },
			config: { cancelToken },
		});
	}

	getCommenHistory({ categoryId }, { page = 0, limit = 25 }, cancelToken) {
		return client.get(`/select/categories/${categoryId}/synthesis/comments/history`, {
			params: { page, limit },
			config: { cancelToken },
		});
	}

	getMyComment({ categoryId }, cancelToken) {
		return client.get(`/select/categories/${categoryId}/synthesis/comments/me`, {
			config: { cancelToken },
		});
	}

	getOthersComments({ categoryId }, { page = 0, limit = 25 }, cancelToken) {
		return client.get(`/select/categories/${categoryId}/synthesis/comments/others`, {
			params: { page, limit },
			config: { cancelToken },
		});
	}

	getNote({ categoryId }, cancelToken) {
		return client.get(`/select/categories/${categoryId}/synthesis/note`, {
			config: { cancelToken },
		});
	}

	updateNote({ categoryId }, payload, cancelToken) {
		return client.patch(`/select/categories/${categoryId}/synthesis/note`, {
			payload: JSON.stringify(payload),
			config: { cancelToken },
		});
	}

	deleteNote({ categoryId }, cancelToken) {
		return client.delete(`/select/categories/${categoryId}/synthesis/note`, {
			config: { cancelToken },
		});
	}

	getNoteHistory({ categoryId }, { page = 0, limit = 25 }, cancelToken) {
		return client.get(`/select/categories/${categoryId}/synthesis/notes/history`, {
			params: { page, limit },
			config: { cancelToken },
		});
	}
}

export default new GonogoSynthesisService();
