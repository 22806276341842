import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ApiService, ProjectTeamService } from "../../../../api";
import {
	createNotification,
	CustomDialog,
	I18nTranslateEnumCheckEmailError,
	icon,
	MultipleEmailInput,
} from "../../../../common/components";
import { translate } from "../../../../common/providers";
import styles from "./AddNewMembersDialog.module.css";

export default function AddNewMembersDialog({
	onClose = null,
	open = false,
	onSubmit = null,
	onUpdateActiveUsersCount,
	selectedCompany,
}) {
	const [emailsObjects, setEmailsObjects] = useState([]);
	const [errors, setErrors] = useState([]);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const cancelTokenSourceRef = useRef(null);
	const project = useSelector((state) => state.context.project);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const handleAddToProject = () => {
		const filteredEmails = emailsObjects.filter((eo) => eo.valid).map((eo) => ({ email: eo.email }));
		ProjectTeamService.createAndAddUser(
			{ projectId: project.id, companyId: selectedCompany.id },
			filteredEmails,
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				createNotification({
					type: "success",
					message:
						filteredEmails.length === 1
							? translate("team-management.members.add-new-member-dialog.success-message.add-user")
							: translate("team-management.members.add-new-member-dialog.success-message.add-users"),
				});
				setEmailsObjects([]);
				setConfirmDialogOpen(false);
				setErrors([]);
				onUpdateActiveUsersCount(true, filteredEmails.length);
				onSubmit();
			})
			.catch((err) => {
				console.error(err);
				createNotification({
					type: "error",
					message: translate("team-management.members.add-new-member-dialog.error-message.add-users"),
				});
			});
	};
	const handleClearEmails = () => {
		setEmailsObjects([]);
		setErrors([]);
	};
	const handleDeleteEmail = (emailToDelete) => {
		setEmailsObjects(emailsObjects.filter((e) => e.email !== emailToDelete));
		setErrors(errors.filter((e) => e.email !== emailToDelete));
	};
	const handleAddEmails = (emailsToBeAdded) => {
		ProjectTeamService.checkEmails(
			{ projectId: project.id, companyId: selectedCompany.id },
			{ emails: emailsToBeAdded },
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				const newErrors = [...errors];
				data.forEach((d) => {
					if (!d.valid) {
						newErrors.push({
							email: d.userEmail,
							text: (
								<I18nTranslateEnumCheckEmailError
									autoTranslate="no"
									param={{ email: d.userEmail }}
									value={d.preventionReason}
								/>
							),
						});
					}
				});
				setErrors(newErrors);
				setEmailsObjects([...emailsObjects, ...data.map((e) => ({ email: e.userEmail, valid: e.valid }))]);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleClose = () => {
		if (typeof onClose !== "function") {
			return;
		}
		handleClearEmails();
		onClose();
	};
	const handleSubmit = () => {
		if (errors.length > 0) {
			setConfirmDialogOpen(true);
		} else {
			handleAddToProject();
		}
	};
	const handleCloseConfirmDialog = () => {
		setConfirmDialogOpen(false);
	};
	return (
		<>
			<CustomDialog
				isForm
				open={open && !confirmDialogOpen}
				submitDisabled={emailsObjects.length === 0 || emailsObjects.length === errors.length}
				submitLabel={translate("team-management.members.add-new-member-dialog.submit")}
				title={translate("team-management.members.add-new-member-dialog.title")}
				onClose={handleClose}
				onSubmit={handleSubmit}
			>
				<div className={styles.dialog__content}>
					<MultipleEmailInput
						emailsObjects={emailsObjects}
						errors={errors.map((e) => e.text)}
						onAddEmails={handleAddEmails}
						onClearEmails={handleClearEmails}
						onDeleteEmail={handleDeleteEmail}
					/>
				</div>
			</CustomDialog>
			<CustomDialog
				iconColor="var(--color-red)"
				iconModel={icon.faExclamationTriangle}
				open={open && confirmDialogOpen}
				subTitle={translate("team-management.members.add-member.add-new-member-confirm-dialog.text")}
				title={translate("team-management.members.add-member.add-new-member-confirm-dialog.title")}
				onClose={handleCloseConfirmDialog}
				onSubmit={handleAddToProject}
			/>
		</>
	);
}
