import React from "react";
import { Checkbox, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { CustomIconButton, icon } from "../../../../../common/components";

export default function ExportRow({ label, index, maxIndex, checked, handleToggle, handlePushUp, handlePushDown }) {
	return (
		<ListItem button dense onClick={handleToggle}>
			<ListItemIcon>
				<Checkbox disableRipple checked={checked} color="primary" edge="start" size="small" tabIndex={-1} />
			</ListItemIcon>
			<ListItemText primary={label} />
			<ListItemSecondaryAction>
				<CustomIconButton disabled={index === 0} icon={icon.faChevronUp} onClick={handlePushUp} />
				<CustomIconButton disabled={index === maxIndex} icon={icon.faChevronDown} onClick={handlePushDown} />
			</ListItemSecondaryAction>
		</ListItem>
	);
}
