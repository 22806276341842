import { Chip } from "@mui/material";
import React from "react";
import { CustomButton, SidePanelBox } from "../../../../../../../../../common/components";
import { translate, translateDate } from "../../../../../../../../../common/providers";
import styles from "./TaskBox.module.css";
import stylesTabBox from "../../../TabBox.module.css";

const TaskBox = ({ onUpdateStatus, task = null, userId = 0 }) => (
	<SidePanelBox>
		<div className={stylesTabBox.container}>
			<div>
				<span className={styles["headline--who"]}>
					{translate("smart-review.cmp-details.option-panel.task-tab.assigned-to.who")}
				</span>
				<span className={styles["headline--name"]} translate="no">
					{task.toUser.displayName}
				</span>
				<span className={styles["headline--deadline"]}>
					{translate("smart-review.cmp-details.option-panel.task-tab.deadline")}
				</span>
				<span className={styles["headline--date"]}>{translateDate(task.deadline).replaceAll("/", ".")}</span>
			</div>
			<div className={styles.importantInformation}>
				<Chip
					className={`${styles.importantInformation__priority} ${
						task.priority === "HIGH" && styles["importantInformation--red"]
					} ${task.priority === "NORMAL" && styles["importantInformation--orange"]} ${
						task.priority === "LOW" && styles["importantInformation--green"]
					}`}
					label={`${task.priority} priority`?.toLowerCase()}
					size="small"
					translate="no"
				/>
				<Chip
					className={`${styles.importantInformation__status} ${
						task.status === "NEW" && styles["importantInformation--red"]
					} ${task.status === "IN_PROGRESS" && styles["importantInformation--orange"]} ${
						task.status === "DONE" && styles["importantInformation--green"]
					}`}
					label={task.status.replaceAll("_", " ")}
					size="small"
					translate="no"
				/>
			</div>
			<div>
				<span className={stylesTabBox.description}>{task.description}</span>
			</div>
			<div className={stylesTabBox.container__footer}>
				<span className={stylesTabBox.author} translate="no">
					{`${task.fromUser.displayName} | ${translateDate(task.created).replaceAll("/", ".")}`}
				</span>
				{(userId === task.fromUser.id || userId === task.toUser.id) && (
					<CustomButton variant="outlined" onClick={() => onUpdateStatus(task.id)}>
						{translate("smart-review.cmp-details.option-panel.task-tab.assigned-to.update-status")}
					</CustomButton>
				)}
			</div>
		</div>
	</SidePanelBox>
);

export default TaskBox;
