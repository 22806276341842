// eslint-disable-next-line import/no-unresolved
import Keycloak from "keycloak-js";
import { isChromium } from "..";

const kc = new Keycloak("/keycloak.json");

const doLogin = kc.login;
const doLogout = kc.logout;
const getToken = () => `Bearer ${kc.token}`;
const clearToken = () => kc.clearToken();
const isLoggedIn = () => !!kc.token;
const updateToken = (successCallback) => kc.updateToken(295).then(successCallback).catch(doLogout);
const getUsername = () => kc.tokenParsed?.preferred_username;
const hasRole = (roles) => roles.some((role) => kc.hasRealmRole(role));
const kcAll = () => kc;
const userLanguage = () => {
	switch (kc.tokenParsed?.locale) {
		case "fr":
			return "fr";
		case "es":
			return "esp";
		default:
			return "en";
	}
};

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
	if (
		!(isChromium(window.navigator) || window.env.REACT_APP_ENV === "dev" || window.env.REACT_APP_ENV === "qa") ||
		window.location.href.includes("/projects/join") ||
		window.location.href.includes("/register")
	) {
		onAuthenticatedCallback();
	} else {
		kc.init({
			checkLoginIframe: false,
			pkceMethod: "S256",
			onLoad: "check-sso",
		})
			.then((authenticated) => {
				if (authenticated) {
					if (onAuthenticatedCallback && typeof onAuthenticatedCallback === "function") {
						onAuthenticatedCallback();
					}
				} else {
					doLogin();
				}
			})
			.catch(console.error);
	}
};

const KeycloakService = {
	clearToken,
	initKeycloak,
	doLogin,
	doLogout,
	isLoggedIn,
	getToken,
	updateToken,
	getUsername,
	hasRole,
	kcAll,
	userLanguage,
};

export default KeycloakService;
