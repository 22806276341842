import React from "react";
import { useSelector } from "react-redux";
import { SidePanel, SidePanelContent, Tile } from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import styles from "./DetailSidePanel.module.css";
import { DashboardTable } from "..";

export default function DetailSidePanel({
	rows,
	documentName = "",
	company = null,
	displaySidePanel,
	onPanelClose,
	loading,
	errorLoading,
	onClearNameFilter,
	onChangeNameFilter,
}) {
	const additionalFilters = useSelector(({ dashboard }) => dashboard.additionalFilters);
	const projectId = useSelector(({ context }) => context.project.id);
	return (
		<SidePanel open={displaySidePanel} size={65} onClose={onPanelClose}>
			<SidePanelContent autoTranslate="no" title={documentName} onClose={onPanelClose}>
				<div className={styles.sidePanel}>
					<Tile className={styles.detail}>
						<span className={styles.title} translate="no">
							{translate("dashboard.my-dashboard.side-panel.title", { company })}
						</span>
						{errorLoading ? (
							<div className={styles.error}>{translate("dashboard.my-dashboard.side-panel.error")}</div>
						) : (
							<DashboardTable
								details
								className={styles.table}
								filters={additionalFilters}
								loading={loading}
								projectId={projectId}
								rows={rows}
								onChangeNameFilter={onChangeNameFilter}
								onClearNameFilter={onClearNameFilter}
							/>
						)}
					</Tile>
				</div>
			</SidePanelContent>
		</SidePanel>
	);
}
