import client from "../../client/client";

class ReqViewService {
	getContents({ docId }, { page = 0 }, cancelToken) {
		return client.get(`/smartview/requirements/documents/${docId}`, {
			params: { page },
			config: { cancelToken },
		});
	}

	getDeletedContents({ docId }, { page = 0 }, cancelToken) {
		return client.get(`/smartview/requirements/documents/${docId}/deleted-infos`, {
			params: { page },
			config: { cancelToken },
		});
	}

	search({ docId }, { version, target, otherstatus, category }, cancelToken) {
		return client.get(`/quickaccess/req/documents/${docId}/filter`, {
			params: { version, target, otherstatus, category },
			config: { cancelToken },
		});
	}

	listStatusOnReq({ reqId }, cancelToken) {
		return client.get(`/smartview/requirements/${reqId}/status`, { config: { cancelToken } });
	}

	listStatusOnReqV2({ reqId }, cancelToken) {
		return client.get(`/smartview/requirements/${reqId}/statusV2`, { config: { cancelToken } });
	}

	updateType({ infoId }, payload, cancelToken) {
		return client.patch(`/smartview/requirements/${infoId}`, { payload, config: { cancelToken } });
	}

	reclassify({ infoId }, cancelToken) {
		return client.post(`/smartview/requirements/${infoId}/reclassify/new`, { config: { cancelToken } });
	}

	setIdentical({ infoId }, cancelToken) {
		return client.post(`/smartview/requirements/${infoId}/reclassify/identical`, { config: { cancelToken } });
	}

	removeNew({ infoId }, cancelToken) {
		return client.delete(`/smartview/requirements/${infoId}/reclassify/remove-new`, {
			config: { cancelToken },
		});
	}
}

export default new ReqViewService();
