import { TableContainer } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
	CustomButton,
	CustomIconButton,
	CustomTable,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	CustomTooltip,
	icon,
	OpportunityTableBody,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import styles from "./OpportunityTable.module.css";

export default function OpportunityTable({
	onClickEdit,
	onClickDownload,
	onClickNotify,
	onClickRetry,
	searchValue,
	onClickDelete,
}) {
	const opportunityList = useSelector(({ opportunity }) => opportunity.opportunityList);
	const filteredIds = useSelector(({ opportunity }) => opportunity.filteredIds);
	const [deletedRow, setDeletedRow] = useState();
	const [headers] = useState([
		{
			className: styles.headerCell,
			name: translate("opportunity.table.header-date"),
		},
		{ className: styles.headerCell, name: translate("opportunity.table.header-name") },
		{
			className: styles.headerCell,
			name: translate("opportunity.table.header-owner"),
		},
		{ className: styles.headerCell_action },
	]);
	const handleClickNotify = (row) => {
		onClickNotify(row);
	};
	const handleClickEdit = (row) => {
		onClickEdit(row);
	};
	const handleClickDownload = (row) => {
		onClickDownload(row);
	};
	const handleClickRetry = (row) => {
		onClickRetry(row);
	};
	const handleClickDelete = (row) => {
		const checkedRow = opportunityList.filter((list) => list.id === row.id);
		setDeletedRow(checkedRow[0].id);
	};
	const handleConfirmDelete = (row) => {
		onClickDelete(row);
	};
	const handleLeaveRow = () => {
		setDeletedRow(null);
	};
	return (
		<TableContainer className={styles.tableContainer}>
			<CustomTable stickyHeader>
				<CustomTableHead>
					<CustomTableRow>
						{headers.map((header, index) => (
							<CustomTableCell
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								className={styles.header__cell}
							>
								{header.name}
							</CustomTableCell>
						))}
					</CustomTableRow>
				</CustomTableHead>
				<OpportunityTableBody
					emptyState={
						(searchValue?.length !== 0 && (
							<span>{translate("opportunity.empty-state.no-search-match")}</span>
						)) ||
						(filteredIds?.length !== 0 && (
							<span>{translate("opportunity.empty-state.no-search-match")}</span>
						))
					}
				>
					{Array.isArray(opportunityList) &&
						opportunityList.map((row) => (
							<CustomTableRow key={row.id}>
								<CustomTableCell className={`${styles.row__cell} ${styles["row__cell--15"]}`}>
									{row.date}
								</CustomTableCell>
								<CustomTableCell className={`${styles.row__cell} ${styles["row__cell--35"]}`}>
									<CustomTooltip showWhenOverflow placement="bottom" title={row.name}>
										<span className={styles.row__overFlowHandler}>{row.name}</span>
									</CustomTooltip>
								</CustomTableCell>
								<CustomTableCell className={`${styles.row__cell} ${styles["row__cell--25"]}`}>
									<CustomTooltip showWhenOverflow placement="bottom" title={row.owner}>
										<span className={styles.row__overFlowHandler}>{row.owner}</span>
									</CustomTooltip>
								</CustomTableCell>
								{(row.status === "COMPLETED" && (
									<CustomTableCell className={`${styles.row__cell} ${styles["row__cell--status"]}`}>
										<CustomIconButton
											className={styles.row__editBtn}
											disabled={row.status !== "COMPLETED"}
											icon={icon.faEnvelope}
											iconColor="var(--color-dark-grey-1)"
											size="sm"
											onClick={() => handleClickNotify(row)}
										/>
										<CustomIconButton
											className={styles.row__editBtn}
											disabled={row.status !== "COMPLETED"}
											icon={icon.faPenToSquare}
											iconColor="var(--color-dark-grey-1)"
											size="sm"
											onClick={() => handleClickEdit(row)}
										/>
										{(deletedRow !== row.id && (
											<>
												<CustomIconButton
													icon={icon.faTrash}
													iconColor="var(--color-dark-grey-1)"
													size="sm"
													onClick={() => handleClickDelete(row)}
												/>
												<CustomButton
													color="primary"
													disabled={row.status !== "COMPLETED"}
													variant="contained"
													onClick={() => handleClickDownload(row)}
												>
													{translate("opportunity.table.download-button")}
												</CustomButton>
											</>
										)) || (
											<CustomButton
												className={styles.row__confirmDeleteBtn}
												data-testid="confirm.delete.btn"
												size="sm"
												startIcon={icon.faTrash}
												variant="outlined"
												onClick={() => handleConfirmDelete(row)}
												onMouseLeave={handleLeaveRow}
											>
												{translate("opportunity.table.row.confirm.delete.text")}
											</CustomButton>
										)}
									</CustomTableCell>
								)) ||
									(row.status === "FAILED" && (
										<CustomTableCell
											className={`${styles.row__cell} ${styles["row__cell--status"]}`}
										>
											<CustomIconButton
												icon={icon.faRefresh}
												iconColor="var(--color-dark-grey-1)"
												size="sm"
												onClick={() => handleClickRetry(row)}
											/>
											{(deletedRow !== row.id && (
												<>
													<CustomIconButton
														icon={icon.faTrash}
														iconColor="var(--color-dark-grey-1)"
														size="sm"
														onClick={() => handleClickDelete(row)}
													/>
													<span className={styles.row__failed}>
														{translate("opportunity.table.row.analyse.result-failed")}
													</span>
												</>
											)) || (
												<CustomButton
													className={styles.row__confirmDeleteBtn}
													data-testid="confirm.delete.btn"
													size="sm"
													startIcon={icon.faTrash}
													variant="outlined"
													onClick={() => handleConfirmDelete(row)}
													onMouseLeave={handleLeaveRow}
												>
													{translate("opportunity.table.row.confirm.delete.text")}
												</CustomButton>
											)}
										</CustomTableCell>
									)) ||
									(row.status === "IN_PROGRESS" && (
										<CustomTableCell
											className={`${styles.row__cell} ${styles["row__cell--status"]}`}
										>
											<span className={styles.row__progress}>
												{translate("opportunity.table.row.analyse.result-in-progress")}
											</span>
										</CustomTableCell>
									))}
							</CustomTableRow>
						))}
				</OpportunityTableBody>
			</CustomTable>
		</TableContainer>
	);
}
