import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { translate } from "../../providers";
import { Status } from "./status";
import styles from "./StatusBar.module.css";
import { icon, IconComponent } from "../icon-component";

const SM_FONT_WIDTH = 9;
const MD_FONT_WIDTH = 10;
const StatusBar = ({
	accepted = 0,
	className = "",
	denied = 0,
	id,
	name,
	notMe = 0,
	onClickStatus,
	pending = 0,
	size = "md",
	toBeReviewed = 0,
}) => {
	const [total, setTotal] = useState(0);
	useEffect(() => {
		setTotal(toBeReviewed + accepted + pending + denied + notMe);
	}, [toBeReviewed, accepted, pending, denied, notMe]);

	const handleMinWidth = (value) => {
		if (size === "lg" || size === "xl") {
			return `${value.toString().length * MD_FONT_WIDTH + 4}px`;
		}
		return `${value.toString().length * SM_FONT_WIDTH + 4}px`;
	};

	if (!total || typeof total !== "number") {
		return (
			<div className={styles.noContent}>
				<IconComponent
					className={styles.noContent__infoIcon}
					color="var(--color-blue)"
					icon={icon.faInfoCircle}
				/>
				{translate("common:components.status-bar.no-content")}
			</div>
		);
	}
	const handleClickStatus = (e, status) => {
		if (typeof onClickStatus === "function") {
			onClickStatus(e, id, name, status);
		}
	};
	return (
		<div className={`${styles.statusBar} ${className}`} id="statusBarContainer">
			{toBeReviewed !== 0 && (
				<Status
					className={styles["status--toBeReviewed"]}
					size={size}
					style={{ width: `${(toBeReviewed * 100) / total}%`, minWidth: handleMinWidth(toBeReviewed) }}
					title={translate("common:components.status-bar.to-be-reviewed", { amount: toBeReviewed })}
					value={toBeReviewed}
					onClick={(e) => handleClickStatus(e, "UNREVIEWED")}
				/>
			)}
			{accepted !== 0 && (
				<Status
					className={styles["status--accepted"]}
					size={size}
					style={{ width: `${(accepted * 100) / total}%`, minWidth: handleMinWidth(accepted) }}
					title={translate("common:components.status-bar.accepted", { amount: accepted })}
					value={accepted}
					onClick={(e) => handleClickStatus(e, "YES")}
				/>
			)}
			{pending !== 0 && (
				<Status
					className={styles["status--pending"]}
					size={size}
					style={{ width: `${(pending * 100) / total}%`, minWidth: handleMinWidth(pending) }}
					title={translate("common:components.status-bar.pending", { amount: pending })}
					value={pending}
					onClick={(e) => handleClickStatus(e, "PENDING")}
				/>
			)}
			{denied !== 0 && (
				<Status
					className={styles["status--denied"]}
					size={size}
					style={{ width: `${(denied * 100) / total}%`, minWidth: handleMinWidth(denied) }}
					title={translate("common:components.status-bar.denied", { amount: denied })}
					value={denied}
					onClick={(e) => handleClickStatus(e, "NO")}
				/>
			)}
			{notMe !== 0 && (
				<Status
					className={styles["status--notMe"]}
					size={size}
					style={{ width: `${(notMe * 100) / total}%`, minWidth: handleMinWidth(notMe) }}
					title={translate("common:components.status-bar.not-me", { amount: notMe })}
					value={notMe}
					onClick={(e) => handleClickStatus(e, "NOT_ME")}
				/>
			)}
		</div>
	);
};
StatusBar.propTypes = {
	size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
};
StatusBar.defaultProps = {
	size: "md",
};
export default StatusBar;
