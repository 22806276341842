import { Accordion, AccordionSummary, Checkbox } from "@mui/material";
import React from "react";
import { isSegFeatureEnabled, SegFlags, translate } from "../../providers";
import styles from "./ExpandableCategoryRow.module.css";

export default function HeaderCategoryRow({
	onCheck,
	isAdminCategories,
	isCompanyManagerOrAdmin,
	isChecked,
	disableCheckboxes = false,
	categoriesCount,
	projectId,
}) {
	const handleCheck = (event) => {
		onCheck(event.target.checked);
	};
	return (
		<Accordion
			classes={{
				root: styles.accordion__root,
			}}
			expanded={false}
			style={{
				marginLeft: 0,
			}}
		>
			<AccordionSummary
				classes={{
					root: styles.accordionSummary__root,
					content: styles.accordionSummary__content,
				}}
				style={{
					cursor: "default",
				}}
			>
				<div className={styles.row__leftItems}>
					<div className={styles.expandButtonContainer} />
					{!disableCheckboxes && <Checkbox checked={isChecked} color="primary" onChange={handleCheck} />}
					<div className={`${styles.row__name} ${styles["row__name--title"]}`}>
						{translate("common:header-category-row.name", { categoriesCount })}
					</div>
				</div>
				<div className={styles.row__rightItems}>
					<div className={styles.rightItems__descriptionTitle}>
						{translate("common:header-category-row.description")}
					</div>
					{isAdminCategories && isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES) && (
						<div className={styles.rightItems__subscriptionsTitle}>
							{translate("common:header-category-row.subscriptions")}
						</div>
					)}
					<div className={styles.rightItems__actionTitle}>
						{translate("common:header-category-row.auto-categorization")}
					</div>
					{!!projectId && (
						<div className={styles.rightItems__contentsTitle} data-rightaligned={!isCompanyManagerOrAdmin}>
							{translate("common:header-category-row.linked-contents")}
						</div>
					)}
					{isCompanyManagerOrAdmin && <div className={styles["rightItems__actionTitle--empty"]} />}
				</div>
			</AccordionSummary>
		</Accordion>
	);
}
