import React from "react";
import { Card, CardContent, Divider } from "@mui/material";
import { CanvasDocCard, RateProgressBar } from "../DocCards";
import AddUserForm from "./AddUserForm";
import { CustomButton, CustomIconButton, CustomTooltip, icon } from "../../../../../common/components";
import { ApiService } from "../../../../../api";
import { hasPermission, isSegFeatureEnabled, Permissions, SegFlags, translate } from "../../../../../common/providers";
import styles from "./DocumentDetails.module.css";

function DisplayByDoc({ docDetails, role, onSelectUser }) {
	const statuses = docDetails.find((details) => details.role === role)?.documentStatuses;
	if (!statuses || !Array.isArray(statuses)) {
		return null;
	}
	return statuses.map((status) => (
		<Card
			key={status.userEmail}
			className={styles.card}
			onClick={() => {
				onSelectUser(status.userEmail);
			}}
		>
			<CardContent className={styles.cardContent}>
				<CustomTooltip autoTranslate="no" title={status.userName || status.userEmail}>
					<CustomButton
						autoTranslate="no"
						className={styles.cardButton}
						color="secondary"
						size="md"
						variant="outlined"
					>
						{status.userName || status.userEmail}
					</CustomButton>
				</CustomTooltip>
				<Divider />
				<div className={styles.canvasDocCard}>
					{role !== Permissions.EDITOR.role && isSegFeatureEnabled(SegFlags.TASK) && (
						<CanvasDocCard
							classes={styles}
							color="var(--color-orange)"
							icon={icon.faClipboardList}
							text={translate("canvas.document-details.pending-tasks")}
							value={status.countTasks}
						/>
					)}
					{role === Permissions.REVIEWER.role && (
						<>
							<CanvasDocCard
								classes={styles}
								color="var(--color-green)"
								icon={icon.faUserCheck}
								text={translate("canvas.document-details.reviewed")}
								value={status.countReviewed}
							/>
							<CanvasDocCard
								classes={styles}
								color="var(--color-light-grey-1)"
								icon={icon.faHourglassHalf}
								text={translate("canvas.document-details.unreviewed")}
								value={status.countUnreviewed}
							/>
						</>
					)}
					{role === Permissions.VALIDATOR.role && (
						<>
							<CanvasDocCard
								classes={styles}
								color="var(--color-green)"
								icon={icon.faUserCheck}
								text={translate("canvas.document-details.validated")}
								value={status.countReviewed}
							/>
							{isSegFeatureEnabled(SegFlags.QUESTION_ANSWER) && (
								<CanvasDocCard
									classes={styles}
									color="var(--color-blue)"
									icon={icon.faHandPaper}
									text={translate("canvas.document-details.questions")}
									value={status.countQnAs}
								/>
							)}
							<CanvasDocCard
								classes={styles}
								color="var(--color-light-grey-1)"
								icon={icon.faHourglassHalf}
								text={translate("canvas.document-details.unreviewed")}
								value={status.countUnreviewed}
							/>
						</>
					)}
				</div>
				<Divider />
				{role !== Permissions.EDITOR.role && <RateProgressBar rate={status.rate} />}
			</CardContent>
		</Card>
	));
}

function AddButton({ role, onOpenAddUser }) {
	return <CustomIconButton color="primary" icon={icon.faPlus} size="md" onClick={() => onOpenAddUser(role)} />;
}

class DocumentDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openForm: false,
			role: null,
		};

		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	handleOpenAddUser = (role) => {
		this.setState({ role, openForm: true });
	};

	handleCloseAddUser = () => {
		this.setState({ openForm: false, role: null });
	};

	render() {
		const { role, openForm } = this.state;
		const {
			dataSelectedDocument,
			onSelectUser,
			roles,
			users,
			projectId,
			selectedCompany,
			selectedDocDetails,
			onUpdateUsersRoles,
		} = this.props;
		const isArchived = dataSelectedDocument.documentData.documentStatus === "ARCHIVED";
		return (
			<>
				<div className={styles.title}>
					{translate("canvas.document-details.title", { document: dataSelectedDocument.id })}
				</div>
				<div className={styles.subContainer}>
					<div className={styles.infoDoc}># pages : {dataSelectedDocument.documentData.pages}</div>
				</div>
				<div className={styles.wrapperRole}>
					{roles.map((displayRole, index) => (
						<React.Fragment key={displayRole}>
							<div className={styles.roleTitleContainer} style={{ gridColumn: index + 1 }}>
								{translate(
									`common:enum.document.user-role.${displayRole?.toLowerCase()}`
								).toUpperCase()}
								<br />
								{!isArchived && !hasPermission(Permissions.PROJECT_MEMBER) && (
									<AddButton role={displayRole} onOpenAddUser={this.handleOpenAddUser} />
								)}
							</div>
							<div className={styles.roleContainer} style={{ gridColumn: index + 1 }}>
								<DisplayByDoc
									docDetails={selectedDocDetails || []}
									role={displayRole}
									onSelectUser={onSelectUser}
								/>
							</div>
						</React.Fragment>
					))}
				</div>
				<AddUserForm
					dataSelectedDocument={dataSelectedDocument}
					open={openForm}
					projectId={projectId}
					role={role}
					selectedCompany={selectedCompany}
					selectedDocDetails={selectedDocDetails}
					users={users}
					onClose={this.handleCloseAddUser}
					onUpdateUsersRoles={onUpdateUsersRoles}
				/>
			</>
		);
	}
}

export default DocumentDetails;
