import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import styles from "./ShowMore.module.css";
import CategoryRiskLevel from "./CategoryRiskLevel";
import { IconComponent, icon } from "..";
import { translate } from "../../providers";

export default function ShowMore({ categories, toDisplay }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	return (
		<div>
			<div aria-describedby={id} className={styles.row__more} role="presentation" onClick={handleClick}>
				<span>{translate("common:category.risk-level.more", { count: toDisplay })}</span>
				<IconComponent color="var(--color-blue)" icon={icon.faCaretDown} />
			</div>
			<Popover
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				id={id}
				open={open}
				onClose={handleClose}
			>
				<div className={styles.popover_list}>
					{Array.isArray(categories) &&
						categories.map((cat) => <CategoryRiskLevel key={cat.categoryId || cat.id} category={cat} />)}
				</div>
			</Popover>
		</div>
	);
}
