import { Box, Menu, MenuItem } from "@mui/material";
import React, { useRef, useState } from "react";
import { CustomTooltip, icon, CustomIconButton, ShowMoreSidePanel, Hierarchy } from "../../../../common/components";
import CommentHighlightedText from "../../../../common/components/comment-highlighted-text/CommentHighlightedText";
import { translate, translateDate, translateElapsed } from "../../../../common/providers";
import { POSITIONS_STATUSES } from "../../../../common/constants/positions-statuses";
import styles from "./MentionBox.module.css";

export default function MentionBox({ mention = {}, changeReadStatus, handleClickOnMention, setShowMoreMenu }) {
	const STATUS_ICONS = {
		[POSITIONS_STATUSES.YES]: icon.faCheckCircle,
		[POSITIONS_STATUSES.NO]: icon.faTimesCircle,
		[POSITIONS_STATUSES.PENDING]: icon.faClock,
		[POSITIONS_STATUSES.NOT_ME]: icon.faNotEqual,
		[POSITIONS_STATUSES.UNREVIEWED]: icon.faHourglassHalf,
	};
	const STATUS_ICONS_CLASSNAMES = {
		[POSITIONS_STATUSES.YES]: styles.status_button_YES,
		[POSITIONS_STATUSES.NO]: styles.status_button_NO,
		[POSITIONS_STATUSES.PENDING]: styles.status_button_PENDING,
		[POSITIONS_STATUSES.NOT_ME]: styles.status_button_NOT_ME,
		[POSITIONS_STATUSES.UNREVIEWED]: "",
	};
	const ICONS_COLORS = {
		[POSITIONS_STATUSES.YES]: "var(--color-green)",
		[POSITIONS_STATUSES.NO]: "var(--color-red)",
		[POSITIONS_STATUSES.PENDING]: "var(--color-orange)",
		[POSITIONS_STATUSES.NOT_ME]: "var(--color-black)",
		[POSITIONS_STATUSES.UNREVIEWED]: "var(--color-light-grey-3)",
	};
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [hover, setHover] = useState(false);
	const [openShowMoreSidePanel, setOpenShowMoreSidePanel] = useState(false);
	const [isOverflow, setIsOverflow] = useState(false);
	const menuOpen = Boolean(menuAnchorEl);
	const commentRef = useRef(null);

	const defineIsOverflow = () => {
		setIsOverflow(
			commentRef.current !== null &&
				(commentRef.current.scrollHeight > commentRef.current.clientHeight ||
					commentRef.current.scrollWidth > commentRef.current.clientWidth)
		);
	};

	const handleMenuClick = (e) => {
		e.stopPropagation();
		setMenuAnchorEl(e.currentTarget);
	};
	const handleKeyDown = (e) => {
		if (e.code === "Enter") {
			handleClickOnMention(mention);
		}
	};
	const handleMenuClose = (e) => {
		e.stopPropagation();
		setMenuAnchorEl(null);
	};
	const handleMouseEnter = () => {
		setHover(true);
		defineIsOverflow();
	};
	const handleMouseLeave = () => {
		setHover(false);
	};
	const handleClickShowMore = (e) => {
		e.stopPropagation();
		setShowMoreMenu(true);
		setOpenShowMoreSidePanel(true);
	};
	const handleCloseShowMoreSidePanel = () => {
		setShowMoreMenu(false);
		setOpenShowMoreSidePanel(false);
	};
	return (
		<>
			<div
				className={`${styles.container} ${!mention.readStatus && styles.not_read}`}
				role="button"
				tabIndex={0}
				onClick={() => handleClickOnMention(mention)}
				onKeyDown={handleKeyDown}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<div className={styles.container__line}>
					<div className={styles.line__leftItems}>
						<span className={styles.name}>{mention.userMentionningDisplayName}</span>
						<span className={styles.grayed}>
							<span className={styles.spaced}>-</span>
							<CustomTooltip placement="top" title={translateDate(mention.date).replaceAll(".", "/")}>
								<div className={styles.date}>
									{translateElapsed({ value: mention.elapsed.value, unitKey: mention.elapsed.unit })}
								</div>
							</CustomTooltip>
						</span>
					</div>
					<CustomIconButton
						className={`${styles.status_button} ${STATUS_ICONS_CLASSNAMES[mention.positionStatus]}`}
						icon={STATUS_ICONS[mention.positionStatus]}
						iconClassName={styles.status_button_icon}
						iconColor={ICONS_COLORS[mention.positionStatus]}
						size="sm"
						styles={styles.status_icon}
					/>
					<CustomIconButton
						btnClassName={styles.read_button}
						data-testid="mention.status.btn"
						icon={icon.faEllipsisH}
						iconClassName={styles.read_button_icon}
						iconColor="var(--color-black)"
						size="sm"
						onClick={handleMenuClick}
					/>
					<Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
						<MenuItem onClick={(e) => changeReadStatus(e, mention)}>
							{mention.readStatus ? translate("common:mention.unread") : translate("common:mention.read")}
						</MenuItem>
					</Menu>
				</div>
				<div className={styles.container__line}>
					<div className={styles.documentName}>
						<Hierarchy documentName={mention.documentName} hierarchy={mention.chapterName} />
					</div>
				</div>
				<div className={styles.container__line}>
					<Box ref={commentRef} className={styles.comment}>
						{mention.commentText.split("\n").map((c) => (
							<CommentHighlightedText key={mention.id} textClassName={styles.commentLine} value={c} />
						))}
					</Box>
					{isOverflow && hover && (
						<div className={styles.showMoreContainer}>
							...
							<span className={styles.showMoreButton} role="presentation" onClick={handleClickShowMore}>
								{translate("common:btn.show-more")}
							</span>
						</div>
					)}
				</div>
			</div>
			<ShowMoreSidePanel
				comment={mention.commentText}
				open={openShowMoreSidePanel}
				title={mention.userMentionningDisplayName}
				onClose={handleCloseShowMoreSidePanel}
			/>
		</>
	);
}
