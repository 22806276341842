import React from "react";
import styles from "./DocByRole.module.css";
import { Item, HeaderItem } from "..";
import { roles, generateIcon, generateTitle } from "../../utils/utils";
import { translate } from "../../../../../../providers";

export default function DocByRole({
	documents,
	onDocumentSelection,
	onOpen,
	onResetOption,
	onSetSelectedDocuments,
	selectedOption,
	selection = [],
	type,
}) {
	const currentList =
		(!selectedOption && documents[type]) ||
		(selectedOption && documents[type].find((opt) => opt[type] === selectedOption?.[type])?.documents) ||
		[];
	const handleClick = (e, opt, index) => {
		e.preventDefault();
		let payload = {};
		if (opt[type]) {
			payload = { ...opt, number: index + 1 };
			onOpen(payload);
		} else {
			payload = { id: opt.id, documentName: opt.name, ...opt };
			if (type === "role" && selectedOption[type]) {
				payload.role = roles[selectedOption[type]].role;
			}
			if (typeof onDocumentSelection === "function") {
				onDocumentSelection({ ...payload, forced: true });
			}
		}
	};
	return (
		<div className={styles.documentButtons}>
			{selectedOption && (
				<HeaderItem
					iconName={generateIcon(selectedOption, type)}
					label={generateTitle(
						type === "role"
							? {
									...selectedOption,
									role: translate(
										`common:enum.document.user-role.${selectedOption?.role?.toLowerCase()}`
									),
							  }
							: selectedOption,
						type
					)}
					mode={type}
					onResetOption={onResetOption}
				/>
			)}
			{currentList
				.filter(
					(opt) =>
						!onSetSelectedDocuments || (onSetSelectedDocuments && !selection.some((sel) => sel === opt.id))
				)
				.map((opt, index) => (
					<Item
						key={opt[type] || opt.name}
						hasContent={opt.count > 0}
						iconName={generateIcon(opt, type)}
						item={opt}
						label={generateTitle(
							type === "role" && !selectedOption
								? {
										...opt,
										role: translate(`common:enum.document.user-role.${opt.role?.toLowerCase()}`),
										number: index + 1,
								  }
								: { ...opt, number: index + 1 },
							type
						)}
						next={opt[type]}
						selected={selection.some((sel) => sel === opt.id)}
						onClickItem={(e) => {
							if ((opt[type] && opt.count > 0) || !opt[type]) {
								handleClick(e, opt, index);
							}
						}}
					/>
				))}
		</div>
	);
}
