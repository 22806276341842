import React, { forwardRef } from "react";
import { CustomButton, CustomTooltip, icon, IconComponent } from "../../../../../../common/components";
import { VISIBILITY } from "../../../../../../common/constants";
import styles from "./SmartSearchMenuItem.module.css";

const SmartSearchMenuItem = forwardRef(
	({ disabled, isModifiable, onClick, resultCount, style, title, visibility }, ref) => (
		<CustomTooltip arrow placement="right" title={title}>
			<span ref={ref} style={style}>
				<CustomButton
					fullWidth
					className={styles.menu__item}
					disabled={disabled}
					variant="text"
					onClick={onClick}
				>
					<span className={styles.item__labelLeft}>
						<span
							className={`${styles.item__title} ${(disabled && styles["item__title--disabled"]) || ""}`}
						>
							{title}
						</span>
						{isModifiable && visibility !== VISIBILITY.PRIVATE && (
							<IconComponent color="var(--color-light-grey-2)" icon={icon.faUsers} size="xs" />
						)}
					</span>
					{resultCount !== null && <span className={styles.item__results}>{resultCount}</span>}
				</CustomButton>
			</span>
		</CustomTooltip>
	)
);
export default SmartSearchMenuItem;
