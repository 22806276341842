import client from "../client/client";

class CanvasService {
	addMultiUsersRoleOnDocuments({ projectId }, payload, cancelToken) {
		return client.post(`/canvas/projects/${projectId}/roles`, { payload, config: { cancelToken } });
	}

	getRoleOnDocument({ userId, projectId }, cancelToken) {
		return client.get(`/canvas/projects/${projectId}/users/${userId}/progress`, { config: { cancelToken } });
	}

	removeMultiUsersRoleOnDocuments({ userId, projectId }, payload, cancelToken) {
		return client.patch(`/canvas/projects/${projectId}/users/${userId}/roles`, {
			payload,
			config: { cancelToken },
		});
	}

	getDocumentsPrecedence({ projectId }, { filter }, cancelToken) {
		return client.get(`/canvas/projects/${projectId}/documents`, {
			params: { filter },
			config: { cancelToken },
		});
	}

	getProgressesByDoc({ documentId }, cancelToken) {
		return client.get(`/canvas/documents/${documentId}/progress`, {
			config: { cancelToken },
		});
	}
}

export default new CanvasService();
