import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { IconComponent, BlockTitle, icon } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import styles from "./ProjectDetails.module.css";
import PartnersTable from "./project-details-tables/PartnersTable";
import TeamTable from "./project-details-tables/TeamTable";

function StyledAccordionDetails({ children }) {
	return (
		<AccordionDetails
			classes={{
				root: styles.StyledAccordionDetails__root,
			}}
		>
			{children}
		</AccordionDetails>
	);
}

export default function ProjectDetails({ project = null }) {
	return (
		<>
			<BlockTitle>{translate("admin.project-details.title")}</BlockTitle>
			{!project && (
				<div className={styles.emptyState}>
					<span className={styles.emptyState__text}>
						{translate("admin.project-details.empty-state.project.none-selected")}
					</span>
				</div>
			)}
			{project && (
				<div>
					<div className={styles.details}>
						<span className={styles.details__text} translate="no">
							{project.name}
						</span>
						<span className={styles.details__date}>
							{translate("admin.project-details.created", { trsDate: project.created })}
						</span>
					</div>
					<Accordion>
						<AccordionSummary expandIcon={<IconComponent icon={icon.faChevronDown} />}>
							{translate("admin.project-details.partners")}
						</AccordionSummary>
						<StyledAccordionDetails>
							{Array.isArray(project.companies) && project.companies.length > 0 ? (
								<PartnersTable companies={project.companies} />
							) : (
								<span style={{ fontStyle: "italic", paddingLeft: "8px" }}>
									{translate("common:empty-state.none")}
								</span>
							)}
						</StyledAccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<IconComponent icon={icon.faChevronDown} />}>
							{translate("admin.project-details.team")}
						</AccordionSummary>
						<StyledAccordionDetails>
							{Array.isArray(project.users) && project.users.length > 0 ? (
								<TeamTable users={project.users} />
							) : (
								<span style={{ fontStyle: "italic", paddingLeft: "8px" }}>
									{translate("common:empty-state.none")}
								</span>
							)}
						</StyledAccordionDetails>
					</Accordion>
				</div>
			)}
		</>
	);
}
