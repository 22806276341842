import React from "react";
import { translate } from "../../../../../../common/providers";
import styles from "./Header.module.css";
import { IconComponent, icon, CustomTooltip } from "../../../../../../common/components";

export default function Header() {
	return (
		<>
			<div className={styles.header}>
				<CustomTooltip showWhenOverflow title={translate("common:categories")}>
					<div className={styles.title}>{translate("common:categories")}</div>
				</CustomTooltip>
				<div>
					<div className={styles.details}>
						<IconComponent color="var(--color-light-grey-1)" icon={icon.faAlignLeft} />
					</div>
					<div className={styles.details}>
						<IconComponent color="var(--color-light-grey-1)" icon={icon.faFlag} />
					</div>
					<div>
						<IconComponent color="var(--color-light-grey-1)" icon={icon.faExclamationTriangle} />
					</div>
				</div>
			</div>
		</>
	);
}
