import client from "../../client/client";

const xlsxExportConfig = {
	responseType: "blob",
	Accept: "*/*",
	"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

class ExportService {
	exportRequirements({ projectId }, payload, cancelToken) {
		xlsxExportConfig.cancelToken = cancelToken;
		return client.put(`/export/projects/${projectId}/requirements/xlsx`, { payload, config: xlsxExportConfig });
	}

	getRequirementsColumns(cancelToken) {
		return client.get("/export/requirements/columns", { config: { cancelToken } });
	}

	exportRequirementsProgress({ projectId }, cancelToken) {
		xlsxExportConfig.cancelToken = cancelToken;
		return client.get(`/export/projects/${projectId}/requirements/progress/xlsx`, { config: xlsxExportConfig });
	}

	exportProjectUploadKPIXLSX({ projectId }, cancelToken) {
		xlsxExportConfig.cancelToken = cancelToken;
		return client.get(`/export/projects/${projectId}/kpi/upload/xlsx`, { config: xlsxExportConfig });
	}

	exportQuestionAnswersXLSX({ projectId }, payload, cancelToken) {
		xlsxExportConfig.cancelToken = cancelToken;
		return client.put(`/export/projects/${projectId}/question-answer/xlsx`, { payload, config: xlsxExportConfig });
	}

	exportVersioning({ docId }, cancelToken) {
		xlsxExportConfig.cancelToken = cancelToken;
		return client.get(`/documents/${docId}/versions/export`, { config: xlsxExportConfig });
	}

	getActiveExportTemplatesByProject({ projectId }, cancelToken) {
		return client.get(`/export/projects/${projectId}/export-templates`, { config: { cancelToken } });
	}

	exportRequirementsCustomTemplate({ projectId, templateId }, cancelToken) {
		xlsxExportConfig.cancelToken = cancelToken;
		return client.get(`/export/projects/${projectId}/requirements/${templateId}/xlsx`, {
			config: xlsxExportConfig,
		});
	}
}

export default new ExportService();
