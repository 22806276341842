import React from "react";
import styles from "./SearchList.module.css";
import SearchRow from "../search-row/SearchRow";
import SearchRowTree from "../search-row-tree/SearchRowTree";

export default function SearchList({
	ancestors = [],
	defaultOpen,
	emptyStateText = "",
	isRowChecked,
	isTree = true,
	level = 0,
	onAddAncestor,
	onClickRow,
	onExpand,
	rowLabelKey,
	rowKey = rowLabelKey,
	rowRenderer,
	rowTooltipKey,
	rows,
	subRowCounter,
	subRowKey,
}) {
	return (
		<div className={`${styles.list} ${(!isTree && styles.flat) || ""}`} style={{ paddingLeft: level * 16 }}>
			{(!Array.isArray(rows) || rows.length === 0) && (
				<div className={styles.list__emptyState}>{emptyStateText}</div>
			)}
			{Array.isArray(rows) &&
				rows.length > 0 &&
				rows.map((row) =>
					isTree ? (
						<SearchRowTree
							key={row[rowKey]}
							ancestors={ancestors}
							defaultOpen={defaultOpen}
							isRowChecked={isRowChecked}
							isTree={isTree}
							label={row[rowLabelKey]}
							level={level}
							row={row}
							rowKey={rowKey}
							rowLabelKey={rowLabelKey}
							rowRenderer={rowRenderer}
							rowTooltipKey={rowTooltipKey}
							subRowCounter={subRowCounter}
							subRowKey={subRowKey}
							onAddAncestor={onAddAncestor}
							onClickRow={onClickRow}
							onExpand={onExpand}
						/>
					) : (
						<SearchRow
							key={row[rowKey]}
							checked={isRowChecked(row)}
							label={row[rowLabelKey]}
							tooltip={row[rowTooltipKey] || ""}
							onClick={() => onClickRow(row)}
						/>
					)
				)}
		</div>
	);
}
