import React, { useEffect, useState } from "react";
import { DisplayTable } from "..";
import { translate } from "../../../../../../../common/providers";
import { getStatusBgColor } from "../../../../../../../common/utils";

function createRow({ label, value, key = label }) {
	return { key, label, value, type: "INFORMATION" };
}

export default function ReqInfoTable({
	attributes,
	category = [],
	clause,
	clientRef,
	criticality,
	documentName,
	documentOuts = [],
	id,
	negotiability,
	type,
}) {
	const [rows, setRows] = useState([]);
	useEffect(() => {
		const tableRows = [];
		tableRows.push(
			createRow({ label: translate("requirement-matrix.req-details.info-table.system-id"), value: id })
		);
		tableRows.push(
			createRow({ label: translate("requirement-matrix.req-details.info-table.client-ref"), value: clientRef })
		);
		tableRows.push(
			createRow({ label: translate("requirement-matrix.req-details.info-table.document"), value: documentName })
		);
		tableRows.push(
			createRow({ label: translate("requirement-matrix.req-details.info-table.clause"), value: clause })
		);
		tableRows.push(createRow({ label: translate("requirement-matrix.req-details.info-table.type"), value: type }));
		tableRows.push(
			createRow({
				label: translate("requirement-matrix.req-details.info-table.negotiability"),
				value: negotiability,
			})
		);
		tableRows.push(
			createRow({ label: translate("requirement-matrix.req-details.info-table.criticality"), value: criticality })
		);
		tableRows.push(
			createRow({
				label: translate(translate("common:header.filter.project-categories")),
				value: category.map((cat) => cat.name),
			})
		);
		tableRows.push(
			createRow({
				label: translate("requirement-matrix.req-details.info-table.attributes"),
				value: Object.entries(attributes || {}).map(([key, value]) => `${key}:${value}`),
			})
		);
		tableRows.push(
			createRow({
				label: translate("requirement-matrix.req-details.info-table.covered-in-documents"),
				value: documentOuts.map((doc) => {
					const covered = doc.covered ? "YES" : "NO";
					const style = {
						backgroundColor: getStatusBgColor(covered),
					};
					return { element: doc.name, style };
				}),
			})
		);
		setRows(tableRows);
	}, [id, clientRef, documentName, clause, attributes, category, documentOuts, negotiability, criticality, type]);
	return <DisplayTable rows={rows} />;
}
