import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./Project.module.css";
import { ProjectReqReview, ProjectMilestones, ProjectReqIssues, ProjectDocsInfo } from "../../components";
import { ApiService, DashboardService } from "../../../../api";
import { exportView } from "../../../../navigation";
import { FavoriteCategoriesDialog, ViewBanner } from "../../../../common/components";
import { AnalyticsProvider, Flags, isSegFeatureEnabled, SegFlags, translate } from "../../../../common/providers";

function Project() {
	const [stateProject, setStateProject] = useState(null);
	const [selectedProjectName, setSelectedProjectName] = useState("");
	const [openProjectId, setOpenProjectId] = useState(0);
	const [projectMilestones, setProjectMilestones] = useState(null);
	const cancelTokenSourceRef = useRef(null);
	const navigate = useNavigate();
	const project = useSelector(({ context }) => context.project);
	const company = useSelector(({ context }) => context.company);
	const getMilestones = (projectId, companyId, cancelTokenSource) => {
		DashboardService.getProjectMilestones({ projectId, companyId }, cancelTokenSource.token)
			.then((data) => {
				setProjectMilestones(data);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getProjectInformation = (projectId, companyId, cancelTokenSource) => {
		if (projectId) {
			const variables = { projectId, companyId };
			axios
				.all([
					DashboardService.getProjectDocuments(variables, cancelTokenSource.token),
					DashboardService.getProjectRequirements(variables, cancelTokenSource.token),
					DashboardService.getProjectRequirementsValidation(variables, cancelTokenSource.token),
				])
				.then(
					axios.spread((...data) => {
						setStateProject((prevState) => ({ ...prevState, documents: data[0] }));
						setStateProject((prevState) => ({ ...prevState, projectReq: data[1] }));
						setStateProject((prevState) => ({ ...prevState, projectReqValid: data[2] }));
					})
				)
				.catch((err) => {
					console.error(err);
					setStateProject(null);
				});
		}
	};

	useEffect(() => {
		const documentTitle = translate("dashboard.project.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Project" });
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	useEffect(() => {
		const cancelTokenSource = ApiService.getCancelTokenSource();
		if (project?.id && company?.id) {
			setOpenProjectId(project?.id);
			setSelectedProjectName(project?.name);
			getProjectInformation(project?.id, company?.id, cancelTokenSource);
			getMilestones(project?.id, company?.id, cancelTokenSource);
		}
		return () => {
			ApiService.cancelTokens(cancelTokenSource);
		};
	}, [project, company]);

	return (
		<>
			<ViewBanner
				titles={[
					{ title: translate("navigation:project.dashboard"), key: "dashboard" },
					{ title: translate("navigation:project.project") },
				]}
			/>
			<div className={styles.dashboardContainer}>
				<div className={styles.dashboard}>
					{isSegFeatureEnabled(Flags.MILESTONES) && (
						<div className={styles.line}>
							<ProjectMilestones
								className={styles.dashboard__widget__shadow}
								milestones={projectMilestones}
								onAddMilestone={() =>
									getMilestones(project.id, company.id, cancelTokenSourceRef.current)
								}
								onEditMilestone={() =>
									getMilestones(project.id, company.id, cancelTokenSourceRef.current)
								}
							/>
						</div>
					)}
					<div className={styles.line}>
						{stateProject && stateProject.projectReq && (
							<ProjectReqReview
								className={`${styles["line__element--left"]} ${styles.dashboard__widget__shadow}`}
								clientReq={stateProject.projectReq.clientReq}
								coverage={stateProject.projectReq.coverage}
								newReq={stateProject.projectReq.newReq}
								projectId={openProjectId}
								reviewed={stateProject.projectReq.reviewed}
								totalReq={stateProject.projectReq.totalReq}
								onChangeUrl={navigate}
							/>
						)}
						{stateProject && stateProject.projectReqValid && (
							<ProjectReqIssues
								accepted={stateProject.projectReqValid.accepted}
								className={styles["line__element--right"]}
								denied={stateProject.projectReqValid.denied}
								nature={selectedProjectName}
								notme={stateProject.projectReqValid.notme}
								pending={stateProject.projectReqValid.pending}
								projectId={openProjectId}
								unreviewed={stateProject.projectReqValid.unvalidated}
								unvalidated={stateProject.projectReqValid.unvalidated}
								onChangeUrl={navigate}
							/>
						)}
					</div>
					<div className={`${styles.line} ${styles["line--last"]}`}>
						{stateProject && stateProject.documents && (
							<ProjectDocsInfo
								failed={stateProject.documents.failed}
								inUpload={stateProject.documents.inProgress}
								pending={stateProject.documents.pending}
								projectId={openProjectId}
								timeout={stateProject.documents.timeout}
								totaldocuments={stateProject.documents.totalDocuments}
								totalpages={stateProject.documents.totalPages}
								onChangeUrl={navigate}
							/>
						)}
					</div>
				</div>
			</div>
			{isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES) && <FavoriteCategoriesDialog />}
		</>
	);
}
export default exportView({
	path: "/projects/:projectId/dashboard/project",
	localesPath: "/dashboards/dashboard-project/locales",
	component: Project,
	segFlag: SegFlags.PROJECT_DASHBOARD,
});
