import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Stack } from "@mui/material";
import { translate } from "../../../providers";

export default function YearPicker({
	className = "",
	clearable = false,
	error = false,
	fullWidth = false,
	label = "",
	onChange,
	required = false,
	size = "small",
	value,
}) {
	return (
		<div className={className}>
			<Stack>
				<DatePicker
					disableFuture
					cancelText={translate("common:btn.cancel")}
					clearable={clearable}
					clearText={translate("common:btn.clear-select")}
					componentsProps={{ textField: { variant: "outlined", size } }}
					error={error}
					fullWidth={fullWidth}
					label={label}
					okText={translate("common:btn.ok")}
					openTo="year"
					required={required}
					value={value}
					views={["year"]}
					onChange={onChange}
				/>
			</Stack>
		</div>
	);
}
