import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { TextField } from "@mui/material";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { useSelector, useDispatch } from "react-redux";
import { CollabService, ApiService } from "../../../../../../api";
import CommentHistory from "../CommentHistory";
import { translate } from "../../../../../../common/providers";
import SelectParameters from "../SelectParameters";
import AdditionalParameters from "./AdditionalParameters";
import styles from "./AdditionalDetailDialog.module.css";
import { CustomIconButton, icon } from "../../../../../../common/components";
import { currentRole, isArchived } from "../../../../utils/utils";
import { setDisableKeyEvents } from "../../../../../../common/slice";
import { hasReviewPermissions } from "../../../../permissions/review-permissions-provider";
import REVIEW_PERMISSIONS from "../../../../permissions/review-permissions";

const AdditionalDetailDialog = ({
	finalParameters,
	isParameterIncluded,
	isUnconditional,
	onChangeClientId,
	onChangeEmitter,
	onKeyDownGrid,
	onNewParameter,
	onSelect,
	onSelected,
	onUpdateParameter,
	receiveParamatersOnSelected,
}) => {
	const status = useSelector(({ srDocument }) => srDocument.status);
	const selectedBlock = useSelector(({ srDocument }) => srDocument.selectedBlock);
	const roles = useSelector(({ srDocument }) => srDocument.roles);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const dispatch = useDispatch();
	const cancelTokenSourceRef = useRef(null);
	const [commentHistory, setCommentHistory] = useState([]);
	const requestCommentHistory = useCallback(
		(informationId, cancelTokenSource) => {
			if (currentRole(roles, mode)) {
				CollabService.getHistory({ infoId: informationId }, cancelTokenSource.token)
					.then((data) => {
						setCommentHistory(data.history);
					})
					.catch((err) => {
						console.error(err);
						setCommentHistory([]);
					});
			}
		},
		[roles, mode]
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (selectedBlock) {
			requestCommentHistory(selectedBlock.informationId, cancelTokenSourceRef.current);
			if (selectedBlock?.parameters) {
				receiveParamatersOnSelected();
			}
		}
	}, [requestCommentHistory, receiveParamatersOnSelected, selectedBlock]);
	useEffect(() => {
		if (selectedBlock && selectedBlock.lastStatus !== "not_me" && selectedBlock.lastStatus !== "NOT_ME") {
			requestCommentHistory(selectedBlock.informationId, cancelTokenSourceRef.current);
		}
	}, [selectedBlock, requestCommentHistory]);

	const isClient = useMemo(() => finalParameters?.reqTypeSource === "CLIENT", [finalParameters]);
	const getClassFromClient = () =>
		isClient ? styles["client__button--client"] : styles["client__button--non-client"];
	const canUpdate = useMemo(
		() => hasReviewPermissions(REVIEW_PERMISSIONS.CHANGE_REQ_PARAMETER, { roles }) && !isArchived(status),
		[roles, status]
	);
	const getIconName = () => (isClient && icon.faCheckCircle) || icon.faTimesCircleRegular;
	const getTooltip = () =>
		(isClient && translate("smart-review.additional.from-client-icon")) ||
		translate("smart-review.additional.not-from-client-icon");
	const passListParameters = (parameters) => {
		const list = [];
		if (parameters) {
			for (const property of Object.keys(parameters)) {
				list.push({ name: property, value: parameters[property] });
			}
		}
		return list;
	};
	return (
		<>
			<KeyboardEventHandler onKeyEvent={(key, e) => onKeyDownGrid(key, e)} onKeys={["esc"]} />
			<div className={styles["parameters-block"]}>
				<SelectParameters
					canUpdate={canUpdate}
					criticalityValue={finalParameters?.criticality || ""}
					negotiabilityValue={finalParameters?.negotiability || ""}
					typeValue={finalParameters?.type || ""}
					onSelect={onSelect}
				/>
				<div className={styles["client-parameter"]}>
					{translate("smart-review.additional-detail-dialog.is-tagged")}
					<span>
						{canUpdate ? (
							<CustomIconButton
								icon={getIconName()}
								iconClassName={`${styles.client__button} ${getClassFromClient}`}
								tooltip={getTooltip()}
								onClick={onChangeEmitter}
							/>
						) : (
							<CustomIconButton
								disabled
								icon={getIconName()}
								iconClassName={`${styles.client__button} ${getClassFromClient}`}
							/>
						)}
					</span>
				</div>
				{isClient && (
					<div className={styles["client-parameter"]}>
						<TextField
							fullWidth
							disabled={!canUpdate}
							label={translate("smart-review.additional-detail-dialog.client-id").toUpperCase()}
							value={finalParameters?.clientId || ""}
							variant="outlined"
							onBlur={() => dispatch(setDisableKeyEvents(false))}
							onChange={onChangeClientId}
							onFocus={() => dispatch(setDisableKeyEvents(true))}
						/>
					</div>
				)}
				<div className={styles["additional-parameters"]}>
					<AdditionalParameters
						coreParameters={passListParameters(finalParameters?.coreParams)}
						hasUpdateRights={canUpdate}
						isParameterIncluded={isParameterIncluded}
						isUnconditional={isUnconditional}
						parameters={passListParameters(finalParameters?.projectParams)}
						onNewParameter={onNewParameter}
						onSelected={onSelected}
						onUpdateParameter={onUpdateParameter}
					/>
				</div>
			</div>
			{selectedBlock?.commentsCount > 0 && commentHistory.length > 0 && (
				<CommentHistory commentHistory={commentHistory || []} />
			)}
		</>
	);
};

export default AdditionalDetailDialog;
