import client from "../../client/client";

class GonogoInformationService {
	getCategoryInformation({ categoryId }, { params, page = 0, limit = 50 }, cancelToken) {
		return client.get(`/select/categories/${categoryId}/informations`, {
			params: { ...params, page, limit },
			config: { cancelToken },
		});
	}

	deleteCategoryInformation({ categoryId, informationId }, cancelToken) {
		return client.delete(`/select/categories/${categoryId}/informations/${informationId}`, {
			config: { cancelToken },
		});
	}

	revertCategoryInformation({ categoryId, informationId }, cancelToken) {
		return client.delete(`select/categories/${categoryId}/informations/${informationId}/revert`, {
			config: { cancelToken },
		});
	}

	attachInformationCategory({ categoryId, informationId }, payload, cancelToken) {
		return client.patch(`/select/categories/${categoryId}/informations/${informationId}/attach`, {
			payload: JSON.stringify(payload),
			config: { cancelToken },
		});
	}
}

export default new GonogoInformationService();
