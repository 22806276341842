import React, { useState } from "react";
import { IdentifiedContentBox } from "..";
import styles from "./IdentifiedContents.module.css";
import { isNonEmptyArray } from "../../../../common/utils";
import { CircularLoader, EmptyState, Preview } from "../../../../common/components";
import { translate } from "../../../../common/providers";

const IdentifiedContents = ({ deliverables, filters, loadingDeliverables }) => {
	const [preview, setPreview] = useState(null);

	const handleDisplay = (informationId, projectId, documentId, page, coordinates) =>
		setPreview({
			coordinates,
			projectId,
			documentId,
			page,
			informationId,
		});
	const handleCloseDisplay = () => setPreview(null);
	return (
		<div className={styles.container}>
			{(loadingDeliverables && <CircularLoader />) || (
				<>
					{((isNonEmptyArray(deliverables) || filters.value?.length < 3) &&
						deliverables.map((identifiedContent) => (
							<IdentifiedContentBox
								key={deliverables.informationId}
								identifiedContent={identifiedContent}
								onDisplay={handleDisplay}
							/>
						))) || <EmptyState title={translate("common:empty-state.no-results")} />}
				</>
			)}

			<Preview
				hasDownload
				coordinates={preview?.coordinates}
				docId={preview?.documentId}
				infoId={preview?.informationId}
				open={!!preview}
				page={preview?.page}
				projectId={preview?.projectId}
				onClose={handleCloseDisplay}
			/>
		</div>
	);
};

export default IdentifiedContents;
