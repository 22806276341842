import client from "../../client/client";

const xlsxExportConfig = {
	responseType: "blob",
	Accept: "*/*",
	"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};
class BackOfficeService {
	clearCache(cancelToken) {
		return client.put("/backoffice/caches/all/clear", { config: { cancelToken } });
	}

	sendEmailDatabaseIntegrity(cancelToken) {
		return client.post("/backoffice/databaseIntegrity", { config: { cancelToken } });
	}

	forceUpdateMailjetUserList(cancelToken) {
		return client.get("/backoffice/mailjet/force-update-users", { config: { cancelToken } });
	}

	getAllCompanies(cancelToken) {
		return client.get("/backoffice/companies", { config: { cancelToken } });
	}

	getAllProjects(cancelToken) {
		return client.get("/backoffice/projects", { config: { cancelToken } });
	}

	createCompany(payload, cancelToken) {
		return client.post("/backoffice/companies", { payload, config: { cancelToken } });
	}

	updateCompany({ companyId }, payload, cancelToken) {
		return client.patch(`/backoffice/companies/${companyId}`, { payload, config: { cancelToken } });
	}

	getAllProjectsByCompany({ companyId }, cancelToken) {
		return client.get(`/backoffice/companies/${companyId}/projects`, { config: { cancelToken } });
	}

	getAllProjectsDetails({ companyId }, cancelToken) {
		return client.get(`/backoffice/companies/${companyId}/projectDetails`, { config: { cancelToken } });
	}

	getUsersOfProjects({ companyId, projectId }, cancelToken) {
		return client.get(`/backoffice/companies/${companyId}/projectDetails/${projectId}/users`, {
			config: { cancelToken },
		});
	}

	getPartnersOfProjects({ projectId }, cancelToken) {
		return client.get(`/backoffice/companies/projectDetails/${projectId}/partners`, {
			config: { cancelToken },
		});
	}

	updateUserRoleOnDoc({ companyId, projectId }, payload, cancelToken) {
		return client.put(`/backoffice/companies/${companyId}/projects/${projectId}`, {
			payload,
			config: { cancelToken },
		});
	}

	getCompanyProjectDocuments({ companyId, projectId }, { filter }, cancelToken) {
		return client.get(`/backoffice/canvas/companies/${companyId}/projects/${projectId}/documents`, {
			config: { cancelToken },
			params: { filter },
		});
	}

	getCompanyProjectUsers({ companyId, projectId }, { filter, status, page = 0, limit = 500 }, cancelToken) {
		return client.get(`/backoffice/companies/${companyId}/projects/${projectId}/users`, {
			params: { filter, status, page, limit },
			config: { cancelToken },
		});
	}

	getAllUsersByCompany({ companyId }, cancelToken) {
		return client.get(`/backoffice/companies/${companyId}/users`, { config: { cancelToken } });
	}

	getActiveUsersByCompany({ companyId }, cancelToken) {
		return client.get(`/backoffice/companies/${companyId}/active-users`, { config: { cancelToken } });
	}

	createUser({ companyId }, { email, firstname, lastname, role }, cancelToken) {
		return client.post(`/backoffice/companies/${companyId}/users`, {
			payload: { email, firstname, lastname, role },
			config: { cancelToken },
		});
	}

	updateUserRoleInCompany({ companyId, userId }, role, cancelToken) {
		return client.patch(`/backoffice/companies/${companyId}/users/${userId}`, {
			payload: JSON.stringify(role),
			config: { cancelToken },
		});
	}

	getTokenAndIssueTime({ companyId, userId, projectId }, cancelToken) {
		return client.get(`/backoffice/companies/${companyId}/users/${userId}/projects/${projectId}`, {
			config: { cancelToken },
		});
	}

	updateUserStatusInProject({ companyId, userId, projectId, status }, cancelToken) {
		return client.put(`/backoffice/companies/${companyId}/users/${userId}/projects/${projectId}`, {
			payload: status,
			config: { cancelToken },
		});
	}

	addExistingUserToProject({ companyId, userId, projectId }, cancelToken) {
		return client.post(`/backoffice/companies/${companyId}/users/${userId}/projects/${projectId}`, {
			config: { cancelToken },
		});
	}

	updateUserRoleInProject({ companyId, userId, projectId }, role, cancelToken) {
		return client.patch(`/backoffice/companies/${companyId}/users/${userId}/projects/${projectId}`, {
			payload: JSON.stringify(role),
			config: { cancelToken },
		});
	}

	deleteUserDocumentRoles({ companyId, userId, projectId }, payload, cancelToken) {
		return client.patch(`/backoffice/companies/${companyId}/users/${userId}/projects/${projectId}/roles`, {
			payload,
			config: { cancelToken },
		});
	}

	restartProjectFailedDocuments({ projectId }, cancelToken) {
		return client.post(`/backoffice/projects/${projectId}/documents/restart`, {
			config: { cancelToken },
		});
	}

	forceNormCheck(cancelToken) {
		return client.get("/backoffice/norms/update", {
			config: { cancelToken },
		});
	}

	updateProjectReqDetection({ projectId }, cancelToken) {
		return client.patch(`/backoffice/projects/${projectId}/ReqDetection`, { config: { cancelToken } });
	}

	updateProjectDetails({ projectId }, payload, cancelToken) {
		return client.patch(`/backoffice/projects/${projectId}`, { payload, config: { cancelToken } });
	}

	addExistingPartnerToProject({ projectId, companyId }, cancelToken) {
		return client.post(`/backoffice/projects/${projectId}/companies/${companyId}`, { config: { cancelToken } });
	}

	updateCompanyRoleInProject({ projectId, companyId }, role, cancelToken) {
		return client.patch(`/backoffice/projects/${projectId}/companies/${companyId}`, {
			payload: JSON.stringify(role),
			config: { cancelToken },
		});
	}

	getPlatfortmUsers(payload, { page, limit }, cancelToken) {
		return client.post("/backoffice/users", {
			payload: JSON.stringify(payload),
			params: { page, limit },
			config: { cancelToken },
		});
	}

	updateUserInfo({ userId }, payload, cancelToken) {
		return client.put(`/backoffice/users/${userId}`, { payload, config: { cancelToken } });
	}

	changeBlockUserOnPlatform({ userId }, cancelToken) {
		return client.post(`/backoffice/users/${userId}`, { config: { cancelToken } });
	}

	generateNewPasswordForUser({ userId }, cancelToken) {
		return client.put(`/backoffice/users/${userId}/pwd`, { config: { cancelToken } });
	}

	exportUsers(filters, cancelToken) {
		xlsxExportConfig.cancelToken = cancelToken;
		return client.put("/backoffice/users/export", {
			payload: filters,
			config: xlsxExportConfig,
		});
	}

	getCompanyProjectDocumentProgress({ companyId, documentId }, cancelToken) {
		return client.get(`/backoffice/canvas/companies/${companyId}/documents/${documentId}/progress`, {
			config: { cancelToken },
		});
	}

	getCompanyProjectUserProgress({ userId, projectId, companyId }, cancelToken) {
		return client.get(`/backoffice/canvas/companies/${companyId}/projects/${projectId}/users/${userId}/progress`, {
			config: { cancelToken },
		});
	}

	createTemplate(payload, cancelToken) {
		return client.post("/backoffice/search-templates", {
			payload: JSON.stringify(payload),
			config: { cancelToken },
		});
	}

	getTemplates({ page = 0, limit = 25 }, { filters }, cancelToken) {
		return client.post("/backoffice/search-templates/all", {
			payload: filters,
			params: { page, limit },
			config: { cancelToken },
		});
	}

	countTemplateResults({ projectId, templateId }, payload, cancelToken) {
		return client.get(`/backoffice/search-templates/${templateId}/projects/${projectId}/count`, {
			config: { cancelToken },
			payload,
		});
	}

	templateSearch({ projectId }, filters, { page = 0, limit = 25 }, cancelToken) {
		return client.post(`/backoffice/search-templates/projects/${projectId}/search`, {
			config: { cancelToken },
			payload: filters,
			params: { page, limit },
		});
	}

	getTemplateDetails({ searchTemplateId }, cancelToken) {
		return client.get(`/backoffice/search-templates/${searchTemplateId}`, {
			config: { cancelToken },
		});
	}

	deleteTemplate({ templateId }, cancelToken) {
		return client.delete(`/backoffice/search-templates/${templateId}`, {
			config: { cancelToken },
		});
	}

	updateTemplateDetails({ searchTemplateId }, payload, cancelToken) {
		return client.patch(`/backoffice/search-templates/${searchTemplateId}`, {
			config: { cancelToken },
			payload,
		});
	}

	updateTemplateFilters({ searchTemplateId }, payload, cancelToken) {
		return client.patch(`/backoffice/search-templates/${searchTemplateId}/filters`, {
			config: { cancelToken },
			payload,
		});
	}

	getTemplateProjects(cancelToken, { page = 0, limit = 25 }) {
		return client.get("/backoffice/search-templates/projects", {
			config: { cancelToken },
			params: { page, limit },
		});
	}

	importCategoryTemplate({ file }, cancelToken) {
		return client.upload("/backoffice/import/CategorySearchAutoTemp", file, {
			config: { cancelToken },
		});
	}

	forceSendDailyNotifs(cancelToken) {
		return client.get("/backoffice/notifications/daily", {
			config: { cancelToken },
		});
	}

	forceSendWeeklyNotifs(cancelToken) {
		return client.get("/backoffice/notifications/weekly", {
			config: { cancelToken },
		});
	}

	downloadProject({ projectId }, cancelToken) {
		return client.download(`/backoffice/${projectId}/download`, {
			config: { cancelToken },
		});
	}

	exportTemplateThematicsFromCompany({ companyId }, cancelToken) {
		return client.download(`/backoffice/thematic-templates/company/${companyId}/export`, {
			config: { cancelToken },
		});
	}

	importTemplateThematicsFromCompany({ companyId }, { file }, cancelToken) {
		return client.upload(`/backoffice/thematic-templates/company/${companyId}/import`, file, {
			config: { cancelToken },
		});
	}

	createExportTemplate({ companyId }, { file, name }, cancelToken) {
		return client.upload(`/backoffice/companies/${companyId}/export-template`, file, {
			payload: { value: name },
			config: { cancelToken },
		});
	}

	updateExportTemplatesLinkWithProject({ companyId, templateId }, { toAdd, toRemove }, cancelToken) {
		return client.put(`/backoffice/companies/${companyId}/export-template/${templateId}/projects`, {
			payload: { toAdd, toRemove },
			config: { cancelToken },
		});
	}

	getExportTemplates({ companyId }, cancelToken) {
		return client.get(`/backoffice/companies/${companyId}/export-template`, {
			config: { cancelToken },
		});
	}

	deleteExportTemplates({ companyId, templateId }, cancelToken) {
		return client.delete(`/backoffice/companies/${companyId}/export-template/${templateId}`, {
			config: { cancelToken },
		});
	}

	getActiveTemplateProjects({ companyId, templateId }, cancelToken) {
		return client.get(`/backoffice/companies/${companyId}/export-template/${templateId}/projects`, {
			config: { cancelToken },
		});
	}

	createBu({ companyId }, { payload }, cancelToken) {
		return client.post(`/backoffice/companies/${companyId}`, {
			payload,
			config: { cancelToken },
		});
	}
}
export default new BackOfficeService();
