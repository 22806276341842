import React, { useState } from "react";
import { Alert, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { CustomDialog } from "../../../../../common/components";
import styles from "./SaveTemplateDialog.module.css";
import { translate } from "../../../../../common/providers";

export default function SaveTemplateDialog({ onClose, onSubmit, open, templateName, hasWarning }) {
	const [isNewTemplate, setIsNewTemplate] = useState(0);
	const handleChangeUpdating = (e) => {
		const isUpdatingSearchValue = +e.target.value;
		setIsNewTemplate(isUpdatingSearchValue);
	};
	const handleSubmit = () => {
		onSubmit(isNewTemplate);
	};
	return (
		<CustomDialog
			displayCloseCross
			isForm
			open={open}
			submitLabel={translate("common:btn.save")}
			title={translate("search-template.dialogs.save-search-dialog.title.save")}
			onClose={onClose}
			onSubmit={handleSubmit}
		>
			<div className={styles.content__radios}>
				<RadioGroup value={isNewTemplate} onChange={handleChangeUpdating}>
					<FormControlLabel
						classes={{ label: styles.radios__label }}
						control={<Radio color="primary" />}
						label={translate("search-template.dialogs.save-search-dialog.radios.save-changes", {
							templateName,
						})}
						value={0}
					/>
					<FormControlLabel
						classes={{ label: styles.radios__label }}
						control={<Radio color="primary" />}
						label={translate("search-template.dialogs.save-search-dialog.radios.create-new")}
						value={1}
					/>
				</RadioGroup>
			</div>
			{hasWarning && (
				<Alert severity="error">{translate("smart-search.dialogs.create-template-dialog.alert")}</Alert>
			)}
		</CustomDialog>
	);
}
