import { Chip } from "@mui/material";
import React, { useState } from "react";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import styles from "./UserDetailsTable.module.css";

export default function UserDetailsTable({ projects }) {
	const [headers] = useState([
		translate("admin.user-details.th.project"),
		translate("admin.user-details.th.role"),
		translate("admin.user-details.th.status"),
	]);
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					{headers.map((header) => (
						<CustomTableCell key={header} className={styles.header}>
							{header}
						</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody>
				{Array.isArray(projects) &&
					projects.map((row) => (
						<CustomTableRow key={row.id}>
							<CustomTableCell autoTranslate="no">{row.name} </CustomTableCell>
							<CustomTableCell>
								{Array.isArray(row.roles) && row.roles[0] ? row.roles[0] : "-"}
							</CustomTableCell>
							<CustomTableCell>
								<Chip
									color={row.currentStatus === "ACTIVE" ? "primary" : "default"}
									label={row.currentStatus}
									size="small"
								/>
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
