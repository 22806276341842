import React from "react";
import { SidePanel, SidePanelContent } from "../../../../../../common/components";
import PANELS from "./panels";
import { translate } from "../../../../../../common/providers";

export default function PackagePanels({
	onActive: onActiveFromParent = null,
	onClose,
	onRefreshList,
	onUpdateRow,
	open,
	panel,
	pck,
	platformCompanies,
}) {
	return (
		<SidePanel open={open} size={65} onActive={onActiveFromParent} onClose={onClose}>
			{({ onActive }) => (
				<SidePanelContent
					title={panel && translate(PANELS[panel].title, { name: pck?.name })}
					onActive={onActive}
					onClose={onClose}
				>
					{panel && PANELS[panel].panel({ pck, platformCompanies, onUpdateRow, onClose, onRefreshList })}
				</SidePanelContent>
			)}
		</SidePanel>
	);
}
