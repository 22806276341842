import React from "react";
import { DocumentNameHandler, PageHandler, RolesHandler, ZoomHandler } from "./components";
import styles from "./PdfHeader.module.css";
import { SegFlags, isSegFeatureEnabled } from "../../../../../../common/providers";

const PdfHeader = ({ openDrawer }) => {
	const displayRoles = isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS);
	return (
		<div className={styles.container} data-display-roles={displayRoles}>
			<RolesHandler />
			<DocumentNameHandler />
			<ZoomHandler openDrawer={openDrawer} />
			<PageHandler />
		</div>
	);
};
export default PdfHeader;
