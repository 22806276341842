import React from "react";
import { Checkbox } from "@mui/material";
import styles from "./Box.module.css";

export default function Box({ checked, content, data, onCheck, title, "data-testid": dataTestId }) {
	return (
		<div className={styles.box} data-testid={dataTestId}>
			<div>
				{onCheck && typeof onCheck === "function" && (
					<Checkbox checked={checked} color="primary" onChange={() => onCheck(data, checked)} />
				)}
				<span className={styles.title}>{title}</span>
			</div>
			{content && (
				<div>
					<span>{content}</span>
				</div>
			)}
		</div>
	);
}
