import React from "react";
import { useSelector } from "react-redux";
import styles from "./MainHeader.module.css";
import { Lock, Options, ReviewSynthesis } from "./components";
import { ROLES } from "../../constants/constants";

const MainHeader = () => {
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	return (
		<div className={styles.container}>
			<div>
				{mode === ROLES.VALIDATOR.mode && <ReviewSynthesis />}
				{mode === ROLES.VALIDATOR.mode && <Lock />}
				<Options />
			</div>
		</div>
	);
};
export default MainHeader;
