import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	LabeledCheckbox,
	SidePanelContent,
	YearPicker,
	IconComponent,
	icon,
	CustomButton,
	SidePanelContainer,
	LimitedTextField,
} from "../../../../common/components";
import { translate } from "../../../../common/providers";
import styles from "./CustomNorm.module.css";

export default function CustomNorm({ norm, onUpdate, onCreate, onClose }) {
	const [date, setDate] = useState(null);
	const [link, setLink] = useState("");
	const [description, setDescription] = useState("");
	const [name, setName] = useState("");
	const [addToFavorite, setAddToFavorite] = useState(false);
	useEffect(() => {
		if (!norm || norm?.type !== "MANUAL") {
			return null;
		}
		const { date: d, description: des, link: l, name: n, isFavorite } = norm;
		setDate(new Date(d));
		setDescription(des);
		setLink(l);
		setName(n);
		setAddToFavorite(isFavorite);
		return () => {
			setDate(null);
			setLink("");
			setDescription("");
			setName("");
			setAddToFavorite(false);
		};
	}, [norm]);
	const handleCreate = () =>
		onCreate({ addToFavorite, name, date: date?.getFullYear() || new Date().getFullYear(), description, link });
	const handleUpdate = () =>
		onUpdate({
			addToFavorite,
			name,
			date: date?.getFullYear() || (date.getFullYear && date.getFullYear()) || new Date().getFullYear(),
			description,
			link,
		});
	const handleChangeFavorite = (e) => setAddToFavorite(e?.target?.checked);
	const handleChangeName = (value) => setName(value || "");
	const handleChangeDate = (d) => setDate(d);
	const handleChangeDescription = (value) => setDescription(value || "");
	const handleChangeLink = (e) => setLink(e.target.value || "");
	const invalidLink = link && !(link.startsWith("http://") || link.startsWith("https://"));
	const invalidPayload = invalidLink || !date || !name || !link || !description;
	const title = (norm && "norms-center.add-norm.custom-norm.update") || "norms-center.add-norm.custom-norm.create";
	return (
		<SidePanelContent
			secondaryActions={
				<CustomButton
					color="primary"
					disabled={invalidPayload}
					variant="contained"
					onClick={(norm && handleUpdate) || handleCreate}
				>
					{translate(title)}
				</CustomButton>
			}
			title={translate(title)}
			onClose={onClose}
		>
			<SidePanelContainer>
				<h5>{translate("norms-center.add-norm.custom-norm.panel-title")}</h5>
				<div className={styles.createNorm}>
					<div className={styles.createNorm__fields}>
						<LabeledCheckbox
							checked={addToFavorite}
							label={
								<span>
									{translate("norms-center.add-norm.custom-norm.favorite")}
									&nbsp;
									<IconComponent icon={icon.faStar} size="sm" />
								</span>
							}
							labelPlacement="end"
							onClick={handleChangeFavorite}
						/>
					</div>
					<div className={styles.createNorm__fields}>
						<LimitedTextField
							autoFocus
							fullWidth
							required
							className={styles.fields__name}
							label={translate("norms-center.add-norm.custom-norm.name")}
							size="sm"
							value={name}
							onChange={handleChangeName}
						/>
						<YearPicker
							clearable
							required
							className={styles.fields__date}
							label={translate("norms-center.add-norm.custom-norm.date")}
							value={date}
							onChange={handleChangeDate}
						/>
					</div>
					<div className={styles.createNorm__fields}>
						<LimitedTextField
							fullWidth
							multiline
							required
							label={translate("norms-center.add-norm.custom-norm.description")}
							maxLength={512}
							rows={10}
							value={description}
							onChange={handleChangeDescription}
						/>
					</div>
					<div className={styles.createNorm__fields}>
						<TextField
							fullWidth
							required
							error={!!invalidLink}
							helperText={invalidLink && translate("norms-center.add-norm.custom-norm.link.invalid")}
							label={translate("norms-center.add-norm.custom-norm.link")}
							placeholder="https://"
							size="small"
							value={link}
							variant="outlined"
							onChange={handleChangeLink}
						/>
					</div>
				</div>
			</SidePanelContainer>
		</SidePanelContent>
	);
}
