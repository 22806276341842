import {
	KEY_LANG,
	KEY_SMART_VIEW,
	KEY_USER_COMPANY,
	KEY_USER_EMAIL,
	KEY_USER_ID,
	KEY_USER_DISPLAY_NAME,
	NO_ROLE_ON_DOCUMENT_BANNER_DISABLED,
	HOME_TAB,
	HIDE_DOC_STATUS_INFO_BANNER,
} from "./keys/keys";
import { WebSocket } from "../../api";

class ContextProvider {
	#isLogged;

	constructor() {
		this.localStorage = window.localStorage;
		this.sessionStorage = window.sessionStorage;
		this.#isLogged = false;
		this.bearerToken = "";
	}

	login(token) {
		if (!token || !token.includes("Bearer")) {
			this.logout();
			return this.#isLogged;
		}
		this.#isLogged = true;
		this.bearerToken = token;
		return this.#isLogged;
	}

	logout() {
		this.#isLogged = false;
		this.bearerToken = "";
		this.clearStorage();
		this.sessionStorage.clear();
		WebSocket.disconnect();
	}

	isLogged() {
		return this.#isLogged;
	}

	getToken() {
		return this.bearerToken;
	}

	getLang() {
		return this.localStorage.getItem(KEY_LANG);
	}

	getCompany() {
		return this.localStorage.getItem(KEY_USER_COMPANY);
	}

	setLang(lang) {
		this.localStorage.setItem(KEY_LANG, lang ?? "en");
	}

	setUserDetails({ companyId, displayName, email, userId }) {
		this.localStorage.setItem(KEY_USER_EMAIL, email);
		this.localStorage.setItem(KEY_USER_DISPLAY_NAME, displayName);
		this.localStorage.setItem(KEY_USER_ID, userId);
		this.localStorage.setItem(KEY_USER_COMPANY, companyId);
	}

	setCompany(companyId) {
		this.localStorage.setItem(KEY_USER_COMPANY, companyId);
	}

	getCautionBannerDisabled() {
		return this.sessionStorage.getItem(NO_ROLE_ON_DOCUMENT_BANNER_DISABLED);
	}

	setCautionBannerDisabled(isDisabled) {
		this.sessionStorage.setItem(NO_ROLE_ON_DOCUMENT_BANNER_DISABLED, isDisabled);
	}

	getHideDocStatusInfoBanner() {
		return this.sessionStorage.getItem(HIDE_DOC_STATUS_INFO_BANNER);
	}

	setHideDocStatusInfoBanner(isDisabled) {
		this.sessionStorage.setItem(HIDE_DOC_STATUS_INFO_BANNER, isDisabled);
	}

	getHomeTab() {
		return this.localStorage.getItem(HOME_TAB);
	}

	setHomeTab(tab) {
		this.localStorage.setItem(HOME_TAB, tab);
	}

	clearStorage() {
		this.sessionStorage.setItem(KEY_SMART_VIEW, null);
	}
}

export default new ContextProvider();
