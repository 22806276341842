import React from "react";
import { BlockTitle } from "../../../../common/components";
import { translate } from "../../../../common/providers";

import ProjectsListTable from "./projects-list-table/ProjectsListTable";

export default function ProjectsList({ projects = [], onChangeArchive, onChangeName, onClickRow }) {
	return (
		<>
			<BlockTitle>{translate("admin.project-list.title")}</BlockTitle>
			<ProjectsListTable
				projects={projects}
				onChangeArchive={onChangeArchive}
				onChangeName={onChangeName}
				onSelectRow={onClickRow}
			/>
		</>
	);
}
