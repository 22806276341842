import client from "../client/client";

class SimilarContentService {
	getDocumentsForSimilarContentfilter({ projectId }, { value }, { page, limit }, cancelToken) {
		return client.post(`embedding/projects/${projectId}/documents`, {
			params: { page, limit },
			payload: { value },
			config: { cancelToken },
		});
	}

	getProjectsForSimilarContentfilter({ projectId }, { value }, { page, limit }, cancelToken) {
		return client.post(`embedding/projects/${projectId}`, {
			params: { page, limit },
			payload: { value },
			config: { cancelToken },
		});
	}

	getSimilarContentsInTheSameProject({ projectId, documentId, informationId }, { ids, topK }, cancelToken) {
		return client.post(
			`embedding/projects/${projectId}/documents/${documentId}/information/${informationId}/project`,
			{
				payload: ids,
				params: { topK },
				config: { cancelToken },
			}
		);
	}

	getSimilarContentsInOtherProjects({ projectId, documentId, informationId }, { ids, topK }, cancelToken) {
		return client.post(
			`embedding/projects/${projectId}/documents/${documentId}/information/${informationId}/otherProject`,
			{
				payload: ids,
				params: { topK },
				config: { cancelToken },
			}
		);
	}

	getMostRelevantBlockBySearch({ projectId, topK }, value, cancelToken) {
		return client.post(`embedding/projects/${projectId}/blocs`, {
			payload: { value },
			params: { topK },
			config: { cancelToken },
		});
	}

	getMostRelevantDeliverables({ topK }, value, cancelToken) {
		return client.post("embedding/deliverables", {
			payload: { value },
			params: { topK },
			config: { cancelToken },
		});
	}
}
export default new SimilarContentService();
