import React, { useCallback, useEffect, useRef, useState } from "react";
import { ApiService, CategoryTemplateService } from "../../api";
import {
	CategoryDeleteDialog,
	CategoryDialog,
	CircularLoader,
	CustomButton,
	icon,
	SearchInput,
	ViewBanner,
	ExpandableCategoryRow,
	HeaderCategoryRow,
	AutomationDialog,
	EmptyState,
	HorizontalDivider,
} from "../../common/components";
import { Permissions, translate, Flags, hasPermission } from "../../common/providers";
import { debounce, isNonEmptyArray, updateTreeBranch } from "../../common/utils";
import { exportView } from "../../navigation";
import styles from "./CategoryTemplate.module.css";
import { ACTION_TYPES } from "../admin-categories/constants/constants";
import { addCategories, deleteCategory, getParent, getCategoryFromTree } from "../admin-categories/utils/utils";
import { useApi } from "../../common/hooks";
import searchCardTemplateService from "../../api/services/search-card-template-service";
import ImportTransferCategorySidePanel from "./components/ImportTransferCategorySidepanel";

const debouncedSearch = debounce((searchFunction) => {
	searchFunction();
}, 500);

const CategoryTemplate = () => {
	const [categories, setCategories] = useState([]);
	const [isTreeLoading, setIsTreeLoading] = useState(false);
	const [isNew, setIsNew] = useState(true);
	const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [tempCategory, setTempCategory] = useState({});
	const [tempParent, setTempParent] = useState({});
	const [checkedCategories, setCheckedCategories] = useState([]);
	const cancelTokenSourceRef = useRef(null);
	const updateCancelTokenSourceRef = useRef(null);
	const [openAutomationDialog, setOpenAutomationDialog] = useState(false);
	const [tempAutomation, setTempAutomation] = useState(null);
	const [openImportSidePanel, setOpenImportSidePanel] = useState(false);
	const [openTransferSidePanel, setOpenTransferSidePanel] = useState(false);
	const [needUpdate, setNeedUpdate] = useState(false);
	const { call: getAutomations, cancel: cancelGetAutomations } = useApi(
		CategoryTemplateService.getAutomationTemplate
	);
	const { call: getFilters } = useApi(searchCardTemplateService.getTemplateFilters);
	const { call: updateFilters } = useApi(searchCardTemplateService.updateTemplateFilters);

	const getTree = useCallback((searchText) => {
		setIsTreeLoading(true);
		const request = searchText
			? CategoryTemplateService.getTreeForCompany({ search: searchText }, cancelTokenSourceRef.current.token)
			: CategoryTemplateService.getTreeParentsForCompany(cancelTokenSourceRef.current.token);

		request
			.then((data) => setCategories(data.thematics || data || []))
			.catch((err) => console.error(err))
			.finally(() => setIsTreeLoading(false));
	}, []);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		updateCancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
			ApiService.cancelTokens(updateCancelTokenSourceRef.current);
		};
	}, []);

	useEffect(() => {
		if (!searchValue) {
			getTree(searchValue);
		} else {
			ApiService.cancelTokens(updateCancelTokenSourceRef.current);
			updateCancelTokenSourceRef.current = ApiService.getCancelTokenSource();
			setIsTreeLoading(true);
			debouncedSearch(() => {
				getTree(searchValue);
			});
		}
		if (needUpdate) {
			setNeedUpdate(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [needUpdate, searchValue]);

	const handleClickAdd = (parentId) => {
		setOpenCategoryDialog(true);
		setIsNew(true);
		setTempParent(getCategoryFromTree(categories, parentId));
		setTempCategory({});
	};
	const handleClickDelete = (category) => {
		setOpenDeleteDialog(true);
		setTempCategory({ ...category });
		setTempParent(getParent(categories, category.id));
	};
	const handleClickEdit = (category) => {
		setOpenCategoryDialog(true);
		setIsNew(false);
		setTempCategory({ ...category });
		setTempParent(getParent(categories, category.id));
	};
	const handleCloseCategoryDialog = () => {
		setOpenCategoryDialog(false);
	};
	const handleSubmitCategoryDialog = (
		newCategory,
		parentChanged,
		newParent,
		callback,
		tempSearchCards,
		automationIdsToDelete
	) => {
		const newParentId = newParent?.id || null;
		let adjustedSearchCards = [...tempSearchCards];
		if (isNonEmptyArray(tempSearchCards)) {
			adjustedSearchCards = tempSearchCards.map((tsc) => {
				if (isNonEmptyArray(tsc?.filters?.keywordFilters?.filters)) {
					tsc.filters.keywordFilters.filters = tsc.filters.keywordFilters.filters.filter((f) =>
						isNonEmptyArray(f.keywords)
					);
					return tsc;
				}
				return undefined;
			});
		}
		const payload = {
			thematicName: newCategory.name,
			description: newCategory.description || null,
			parentId: isNew ? newParentId : newCategory.parentId,
			searches: isNonEmptyArray(adjustedSearchCards)
				? adjustedSearchCards.map((tsc) => {
						const { name, filters } = tsc;
						return { name, search: filters };
				  })
				: null,
		};
		const request = isNew
			? CategoryTemplateService.createFastTrackAutomation(payload, cancelTokenSourceRef.current.token)
			: CategoryTemplateService.updateAutomationTemplate(
					{ thematicId: newCategory.id },
					{
						...payload,
						automationIdsToDelete: isNonEmptyArray(automationIdsToDelete) ? automationIdsToDelete : null,
					},
					cancelTokenSourceRef.current.token
			  );

		request
			.then((data) => {
				let newCategories = [];
				let isSet = false;
				if (isNew) {
					isSet = true;
					if (data.parentId) {
						CategoryTemplateService.getTreeChildrenForCompany(
							{ parentId: data.parentId },
							cancelTokenSourceRef.current.token
						)
							.then((oldList) => {
								setCategories((prev) =>
									updateTreeBranch(
										{
											tree: prev,
											idToReplace: data.parentId,
											newBranch: {
												...getCategoryFromTree(categories, data.parentId),
												subThematic: oldList,
												expanded: true,
											},
											iterativeBranchName: "subThematic",
										},
										{ sortKey: "name" }
									)
								);
							})
							.catch((err) => console.error(err));
					} else {
						getTree(searchValue);
					}
				} else {
					const expanded =
						!!newCategory.expanded &&
						Array.isArray(newCategory.subThematic) &&
						newCategory.subThematic.length > 0;
					if (parentChanged) {
						newCategories = deleteCategory(categories, newCategory.id);
						if (!expanded) {
							if (data.parentId) {
								CategoryTemplateService.getTreeChildrenForCompany(
									{ parentId: data.parentId },
									cancelTokenSourceRef.current.token
								)
									.then((oldList) => {
										setCategories((prev) =>
											updateTreeBranch(
												{
													tree: prev,
													idToReplace: data.parentId,
													newBranch: {
														...getCategoryFromTree(categories, data.parentId),
														subThematic: oldList,
														expanded: true,
													},
													iterativeBranchName: "subThematic",
												},
												{ sortKey: "name" }
											)
										);
									})
									.catch((err) => console.error(err));
							}
						} else {
							newCategories = addCategories(newCategories, newCategory.parentId, [{ ...data, expanded }]);
						}
					} else {
						newCategories = updateTreeBranch(
							{
								tree: categories,
								idToReplace: newCategory.id,
								newBranch: {
									...newCategory,
									...data,
									actionCount: payload.searches?.length,
									expanded,
								},
								iterativeBranchName: "subThematic",
							},
							{ sortKey: "name" }
						);
					}
				}
				if (!isSet) {
					setCategories(newCategories);
					if (typeof callback === "function") {
						callback();
					}
				}
				setOpenCategoryDialog(false);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};
	const handleSubmitDeleteDialog = (subThematicKeep) => {
		CategoryTemplateService.patchCompanyTreeUpdate(
			{
				operation: ACTION_TYPES.delete,
				id: tempCategory.id,
				subThematicKeep,
			},
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				let newCategories = [...categories];
				if (subThematicKeep) {
					const { parentId } = tempCategory;
					newCategories = addCategories(
						categories,
						parentId,
						Array.isArray(tempCategory.subThematic) ? tempCategory.subThematic : []
					);
				}
				newCategories = deleteCategory(newCategories, tempCategory.id);
				setOpenDeleteDialog(false);
				setCategories(newCategories);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleToggleExpand = (category) => {
		let isExpanded = true;
		if (category.filtered === false && category.expanded === undefined) {
			isExpanded = false;
		}
		if (
			category.filtered === undefined &&
			!category.expanded &&
			(!Array.isArray(category.subThematic) || category.subThematic?.length === 0)
		) {
			CategoryTemplateService.getTreeChildrenForCompany(
				{ parentId: category.id },
				cancelTokenSourceRef.current.token
			)
				.then((data) => {
					setCategories((prev) =>
						updateTreeBranch({
							tree: prev,
							idToReplace: category.id,
							newBranch: {
								...category,
								expanded:
									!category.filtered && category.expanded === undefined
										? isExpanded
										: !category.expanded,
								subThematic: data,
							},
							iterativeBranchName: "subThematic",
						})
					);
				})
				.catch((err) => console.error(err));
		} else {
			setCategories((prev) =>
				updateTreeBranch({
					tree: prev,
					idToReplace: category.id,
					newBranch: {
						...category,
						expanded:
							!category.filtered && category.expanded === undefined ? isExpanded : !category.expanded,
					},
					iterativeBranchName: "subThematic",
				})
			);
		}
	};
	const handleClearSearchValue = () => {
		setSearchValue("");
	};
	const handleChangeSearchValue = (event) => {
		const newValue = event.target.value;
		setSearchValue(newValue);
	};
	const isRowChecked = (category) => {
		checkedCategories.some((cat) => cat.id === category.id);
	};
	const handleCheckCategory = (category, checked) => {
		let newCheckedCategories = [...checkedCategories];
		if (!checked) {
			newCheckedCategories = checkedCategories.filter((cat) => cat.id !== category.id);
		}
		setCheckedCategories(newCheckedCategories);
	};
	const handleClickAddAction = (thematicTemplate) => {
		setTempAutomation({ thematicTemplateId: thematicTemplate.id });
		setTempCategory(thematicTemplate);
		setOpenAutomationDialog(true);
	};
	const handleCloseAutomationDialog = () => {
		setOpenAutomationDialog(false);
	};
	const handleSubmitAutomationDialog = () => {
		handleCloseAutomationDialog();
		const newCategories = updateTreeBranch({
			tree: categories,
			idToReplace: tempAutomation.thematicTemplateId,
			newBranch: {
				...tempCategory,
				actionCount: 1,
			},
			iterativeBranchName: "subThematic",
		});
		setCategories(newCategories);
	};
	const isCompanyManagerOrAdmin = hasPermission([Permissions.COMPANY_ADMIN, Permissions.COMPANY_MANAGER]);

	// const handleOpenTransferSidePanel = () => {
	// 	setOpenTransferSidePanel(true);
	// };
	// const handleOpenImportSidePanel = () => {
	// 	setOpenImportSidePanel(true);
	// };
	const handleCloseImportSidePanel = () => {
		setOpenImportSidePanel(false);
	};
	const handleCloseTransferSidePanel = () => {
		setOpenTransferSidePanel(false);
	};
	const handleSubmitImportSidePanel = () => {
		handleCloseImportSidePanel();
		setNeedUpdate(true);
	};
	const handleSubmitTransferSidePanel = () => {
		handleCloseTransferSidePanel();
		setNeedUpdate(true);
	};

	// const TransferButton = (
	// 	<CustomButton
	// 		color="secondary"
	// 		disabled={!Array.isArray(categories) || categories.length === 0}
	// 		size="md"
	// 		startIcon={icon.faArrowUpFromBracket}
	// 		variant="outlined"
	// 		onClick={handleOpenTransferSidePanel}
	// 	>
	// 		{translate("common:categories.button.transfer")}
	// 	</CustomButton>
	// );
	// const ImportButton = (
	// 	<CustomButton
	// 		color="secondary"
	// 		size="md"
	// 		startIcon={icon.faFileImport}
	// 		variant="outlined"
	// 		onClick={handleOpenImportSidePanel}
	// 	>
	// 		{translate("common:categories.button.import")}
	// 	</CustomButton>
	// );
	const newCategoryButton = (
		<CustomButton color="primary" startIcon={icon.faPlus} variant="contained" onClick={() => handleClickAdd()}>
			{translate("common:categories.button.new-thematic")}
		</CustomButton>
	);
	const noSearchRender = (
		<EmptyState
			btn2={newCategoryButton}
			content={translate("category-template.category-row.no-search-match-text")}
			link={translate("category-template.category-row.no-search-match-link")}
			linkAddress={translate("category-template.category-row.no-search-match-link-address")}
			title={translate("category-template.category-row.no-search-match-title")}
		/>
	);
	const noCategoryRender = (
		<EmptyState
			btn2={newCategoryButton}
			content={translate("category-template.category-row.no-search-match-text")}
			link={translate("category-template.category-row.no-search-match-link")}
			linkAddress={translate("category-template.category-row.no-search-match-link-address")}
			title={translate("category-template.category-row.no-category-title")}
		/>
	);
	return (
		<>
			<ViewBanner
				options={(isCompanyManagerOrAdmin && [/* TransferButton, ImportButton, */ newCategoryButton]) || []}
				titles={[
					{ title: translate("navigation:common.templates"), key: "templates" },
					{ title: translate("navigation:common.templates.category-templates") },
				]}
			/>

			{!isTreeLoading && !searchValue && categories?.length === 0 ? (
				<>{noCategoryRender}</>
			) : (
				<div className={styles.mainContainer}>
					<div className={styles.searchContainer}>
						<SearchInput
							className={styles.searchContainer__textfield}
							label={translate("common:categories.textfield.label")}
							value={searchValue}
							onChange={handleChangeSearchValue}
							onClearSearch={handleClearSearchValue}
						/>
					</div>
					<div className={styles.mainContainer__list}>
						{(isTreeLoading && (
							<div className={styles.mainContainer__loader}>
								<CircularLoader />
							</div>
						)) ||
							(Array.isArray(categories) && categories.length > 0 && (
								<>
									<HeaderCategoryRow
										disableCheckboxes
										categoriesCount={(categories || []).length}
										isChecked={false}
										isCompanyManagerOrAdmin={isCompanyManagerOrAdmin}
										onCheck={handleCheckCategory}
									/>
									{categories.map((category) => (
										<ExpandableCategoryRow
											key={category.id}
											disableCheckboxes
											category={category}
											expanded={
												(category.filtered === false && category.expanded === undefined) ||
												category.expanded
											}
											isActionsAllowed={isCompanyManagerOrAdmin}
											isRowChecked={isRowChecked}
											onCheck={handleCheckCategory}
											onClickAdd={handleClickAdd}
											onClickAddAction={handleClickAddAction}
											onClickDelete={handleClickDelete}
											onClickEdit={handleClickEdit}
											onToggleExpand={handleToggleExpand}
										/>
									))}
								</>
							)) || (
								<>
									<HeaderCategoryRow
										disableCheckboxes
										categoriesCount={(categories || []).length}
										isChecked={false}
										isCompanyManagerOrAdmin={isCompanyManagerOrAdmin}
										onCheck={handleCheckCategory}
									/>
									<HorizontalDivider />
									<div className={styles.noRowsContainer}>{noSearchRender}</div>
								</>
							)}
					</div>
				</div>
			)}
			<ImportTransferCategorySidePanel
				importing={openImportSidePanel}
				open={openImportSidePanel || openTransferSidePanel}
				title={translate(
					(openImportSidePanel && "common:categories.import-panel.in-template.title") ||
						"common:categories.transfer-panel.in-template.title"
				)}
				onClose={(openImportSidePanel && handleCloseImportSidePanel) || handleCloseTransferSidePanel}
				onSubmit={(openImportSidePanel && handleSubmitImportSidePanel) || handleSubmitTransferSidePanel}
			/>
			<CategoryDialog
				isTemplate
				availableParents={!searchValue && categories}
				category={tempCategory}
				isNew={isNew}
				open={openCategoryDialog}
				parentCategory={tempParent}
				onClose={handleCloseCategoryDialog}
				onGetAutomations={{ getAutomations, cancelGetAutomations }}
				onGetFilters={getFilters}
				onSubmit={handleSubmitCategoryDialog}
				onUpdateFilters={updateFilters}
			/>
			<CategoryDeleteDialog
				isTemplate
				categoryToDelete={tempCategory}
				open={openDeleteDialog}
				parentCategory={tempParent}
				onClose={handleCloseDeleteDialog}
				onSubmit={handleSubmitDeleteDialog}
			/>
			<AutomationDialog
				isMultiProject
				isNew
				action={tempAutomation}
				availableParents={!searchValue && categories}
				open={openAutomationDialog}
				onClose={handleCloseAutomationDialog}
				onSubmit={handleSubmitAutomationDialog}
			/>
		</>
	);
};

export default exportView({
	path: "/category-template",
	localesPath: "/category-template/locales",
	component: CategoryTemplate,
	flag: Flags.THEMATIC,
});
