import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { NavUtils } from "../../../../../../../../navigation";
import {
	CustomButton,
	CustomDialog,
	CustomTooltip,
	DropDown,
	icon,
	IconComponent,
} from "../../../../../../../../common/components";
import { ROLES } from "../../../../../../constants/constants";
import styles from "./RolesHandler.module.css";
import { setHoveredBlock, setMode } from "../../../../../../slice/document/document-slice";
import { checkReqValMode, translateRole } from "../../../../../../utils/utils";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../../../../../common/providers";

const RoleRow = ({ label, display = false }) => (
	<span className={styles.roleDisplay}>
		{label}
		{display && <IconComponent color="primary" icon={icon.faChevronDown} />}
	</span>
);

const RoleButton = React.forwardRef(
	(
		{ active = false, disabled = false, display = false, iconName = "", label = "", tooltip = "", onClick = null },
		ref = null
	) => (
		<CustomTooltip placement="right-start" title={tooltip}>
			<span className={styles.wrapper}>
				<CustomButton
					ref={ref}
					fullWidth
					className={styles.roleContent}
					color="secondary"
					data-active={active}
					disabled={disabled}
					startIcon={icon[iconName]}
					variant="text"
					onClick={onClick}
				>
					<RoleRow display={display} label={label} />
				</CustomButton>
			</span>
		</CustomTooltip>
	)
);
const MainRoleButton = React.forwardRef(({ display = false, iconName, label, onClick }, ref = null) => (
	<CustomButton ref={ref} color="primary" startIcon={icon[iconName]} variant="outlined" onClick={onClick}>
		<RoleRow display={display} label={label} />
	</CustomButton>
));

const RolesDropdown = React.forwardRef(
	({ currentRole, mode, realRoles = [], onClose = null, onClick = null, open, roles = [] }, ref = null) => (
		<DropDown ref={ref} open={open} onClose={onClose}>
			<div className={styles.dropdown}>
				<div className={styles.dropdown__content}>
					{Object.entries(ROLES)
						.filter((entry) => {
							if (
								realRoles.length > 0 &&
								entry[1].name === ROLES.READ.name &&
								(roles.includes(ROLES.VALIDATOR.name.toUpperCase()) ||
									roles.includes(ROLES.REVIEWER.name.toUpperCase()))
							) {
								return false;
							}
							return true;
						})
						.map(([key, value]) => (
							<CustomTooltip key={key} title="hello">
								<RoleButton
									active={mode === value.mode && currentRole.modeName === value.modeName}
									disabled={currentRole.mode === value.mode || !roles.includes(key)}
									iconName={value.icon}
									label={translateRole(value.modeName)}
									tooltip={
										((!roles.includes(key) ||
											(roles.length === 1 && ROLES.READ.name === roles[0].toUpperCase())) &&
											translate("smart-review.roles-handler.disabled-role")) ||
										(currentRole === value.mode &&
											translate("smart-review.roles-handler.active-role"))
									}
									onClick={() => onClick(value.mode)}
								/>
							</CustomTooltip>
						))}
				</div>
			</div>
		</DropDown>
	)
);

export default function RolesHandler() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const rolesRef = useRef(null);
	const [openDropdown, setOpenDropdown] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const roles = useSelector(({ srDocument }) => srDocument?.roles);
	const selectedMode = useSelector(({ srDocument }) => srDocument?.mode);
	const page = useSelector(({ srPdf }) => srPdf?.page);
	const docId = useSelector(({ srDocument }) => srDocument?.documentId);
	const projectId = useSelector(({ context }) => context?.project?.id);

	const handleOpenRolesList = () => setOpenDropdown((prev) => !prev);
	const handleOpenDialog = () => setOpenDialog((prev) => !prev);
	const goToMode = (mode) => {
		navigate(
			NavUtils.goToSmartReview({
				projectId,
				documentId: docId,
				pageStart: page + 1,
				role: mode,
			}),
			{ state: location?.state }
		);
		dispatch(setMode(mode));
	};
	const handleChangeMode = (mode) => {
		dispatch(setHoveredBlock(null));
		if (ROLES.VALIDATOR.mode === mode) {
			handleOpenDialog();
		} else {
			goToMode(mode);
		}
		handleOpenRolesList();
	};
	const handleSubmit = () => {
		goToMode(ROLES.VALIDATOR.mode);
		handleOpenDialog();
	};
	const headerRoles = useMemo(
		() => {
			if (Array.isArray(roles) && !roles.some((r) => checkReqValMode(ROLES[r].mode))) {
				return [...roles, ROLES.READ.name.toUpperCase()];
			}
			return roles;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[roles]
	);
	const currentRole = useMemo(
		() => (headerRoles || []).map((r) => ROLES[r]).find((r) => r.mode === selectedMode),
		[headerRoles, selectedMode]
	);
	return (
		isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS) && (
			<div>
				{Array.isArray(headerRoles) && headerRoles.length > 0 && (
					<MainRoleButton
						ref={rolesRef}
						display
						iconName={currentRole.icon}
						label={translateRole(currentRole.modeName)}
						onClick={handleOpenRolesList}
					/>
				)}
				<RolesDropdown
					ref={rolesRef}
					currentRole={currentRole}
					mode={selectedMode}
					open={openDropdown}
					realRoles={roles}
					roles={headerRoles}
					onClick={handleChangeMode}
					onClose={handleOpenRolesList}
				/>
				<CustomDialog
					iconColor="var(--color-light-grey-2)"
					iconModel={icon.faExclamationTriangle}
					open={openDialog}
					subTitle={translate("smart-review.validation-mode.warning.description")}
					title={translate("smart-review.validation-mode.warning.title")}
					onClose={handleOpenDialog}
					onSubmit={handleSubmit}
				/>
			</div>
		)
	);
}
