import React, { useEffect, useState } from "react";
import MultiInput from "../components/multi-input/MultiInput";
import { translate } from "../../../../../providers";
import { useEffectOnce } from "../../../../../hooks";
import { isNonEmptyArray } from "../../../../../utils";

const InformationIdFilter = ({ onChange, value, readOnly = false }) => {
	const [idInputs, setIdInputs] = useState([""]);

	useEffect(() => {
		if (idInputs.some((val) => Number(val) > 0)) {
			onChange({ elements: idInputs.map((val) => Number(val)) });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idInputs]);
	useEffectOnce(
		() => {
			setIdInputs(value?.elements);
		},
		[value],
		() => isNonEmptyArray(value?.elements)
	);
	const handleAddNew = () => {
		if (readOnly) {
			return;
		}
		setIdInputs((prev) => [...prev, ""]);
	};
	const handleChangeInput = (newValue, index) => {
		if (readOnly) {
			return;
		}
		const tempIdInputs = [...idInputs];
		tempIdInputs[index] = newValue;
		setIdInputs(tempIdInputs);
	};
	const handleRemove = (index) => {
		if (readOnly) {
			return;
		}
		const tempIdInputs = [...idInputs];
		tempIdInputs.splice(index, 1);
		setIdInputs(tempIdInputs);
	};
	return (
		Array.isArray(idInputs) &&
		idInputs.map((idInput, index) => (
			<MultiInput
				// eslint-disable-next-line react/no-array-index-key
				key={index}
				disableLimitation
				error={Number(idInput) === 0}
				helperText={translate("common:component.filters.input-filter.helper.numeric")}
				index={index}
				input={idInput}
				isLast={idInputs.length - 1 === index}
				readOnly={readOnly}
				type="number"
				onAddNew={handleAddNew}
				onChangeInput={handleChangeInput}
				onRemove={handleRemove}
			/>
		))
	);
};

export default InformationIdFilter;
