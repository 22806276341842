import React from "react";
import { CustomDialog, CustomIconButton, CustomTooltip, icon, InfiniteList2 } from "../../../../../common/components";
import styles from "./EditDialog.module.css";
import { translate } from "../../../../../common/providers";

export default function EditDialog({
	infiniteRequest,
	isMySearchTemplate,
	onClose,
	onEdit,
	onRemove,
	open,
	title,
	userId,
}) {
	const rowRenderer = (item) => (
		<div key={item.id} className={styles.editRow}>
			<CustomTooltip arrow placement="left" title={item.title}>
				<div className={styles.item__title}>{item.title}</div>
			</CustomTooltip>
			{(!isMySearchTemplate || item.creatorId === userId) && (
				<>
					<CustomIconButton icon={icon.faPen} size="md" onClick={() => onEdit(item)} />
					<CustomIconButton icon={icon.faTrash} size="md" onClick={() => onRemove(item)} />
				</>
			)}
		</div>
	);
	return (
		<CustomDialog
			displayCloseCross
			isForm
			closeLabel={translate("common:btn.ok")}
			open={open}
			title={title}
			onClose={onClose}
		>
			<InfiniteList2 request={infiniteRequest} rowRenderer={rowRenderer} />
		</CustomDialog>
	);
}
