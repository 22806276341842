import React, { useMemo } from "react";
import { CustomBreadcrumb } from "..";
import styles from "./Hierarchy.module.css";

export default function Hierarchy({
	breadcrumbClassName = "",
	charsKeptInEnd = 12,
	className = "",
	disabled = false,
	documentName = "",
	documentNamePlacement = "start",
	enableFirstLocationCut = false,
	hierarchy = "",
	isDangerousSetInnerHTML = false,
	openOnHover = true,
}) {
	const fullToc = useMemo(() => {
		const docArray = (documentName.length > 0 && [documentName]) || [];
		const hierarchyArray =
			(hierarchy?.length > 0 && hierarchy.split(" / ").map((x) => (x === "/" && "root") || x)) || [];
		const home = (hierarchy?.length > 0 && !hierarchy.split(" / ").some((x) => x === "/") && ["root"]) || [];
		return (
			(documentNamePlacement === "start" && [...docArray, ...home, ...hierarchyArray]) || [
				...home,
				...hierarchyArray,
				...docArray,
			]
		).map((toc, index) => {
			if (toc === "root") {
				return { id: "root", label: null };
			}
			return { id: index, label: toc };
		});
	}, [documentName, documentNamePlacement, hierarchy]);
	return (
		<CustomBreadcrumb
			closeOnLeave
			breadcrumbClassName={`${styles.breadcrumb} ${breadcrumbClassName}`}
			charsKeptInEnd={charsKeptInEnd}
			className={className}
			disabled={disabled}
			enableFirstLocationCut={enableFirstLocationCut}
			isDangerousSetInnerHTML={isDangerousSetInnerHTML}
			locations={fullToc}
			openOnHover={openOnHover}
		/>
	);
}
