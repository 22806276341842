import React, { useState } from "react";
import { Select } from "@mui/material";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	CustomIconButton,
	CustomTooltip,
	icon,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import TokenInfoDialog from "./TokenInfoDialog";

export default function TeamTable({ companyId, onUpdateStatus = null, projectId, users = [] }) {
	const status = ["ACTIVE", "BLOCKED", "PENDING"];
	const [open, setOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const handleOpenDetails = (e, row) => {
		setOpen((prev) => !prev);
		setSelectedUser(row);
	};
	const handleChange = (e, user) => {
		e.stopPropagation();
		onUpdateStatus({ selectedUser: user, selectedStatus: e.target.value });
	};
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					<CustomTableCell>{translate("dsi.project-details.th.name")}</CustomTableCell>
					<CustomTableCell>{translate("dsi.project-details.th.email")}</CustomTableCell>
					<CustomTableCell>{translate("dsi.project-details.th.status")}</CustomTableCell>
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody>
				{Array.isArray(users) &&
					users.map((user) => (
						<CustomTableRow key={user.id}>
							<CustomTableCell autoTranslate="no">
								<span> {user.displayName}</span>
								<span aria-hidden>
									<CustomTooltip title={translate("dsi.user-details.icon-tooltip.login-info")}>
										<CustomIconButton
											icon={icon.faInfo}
											onClick={(e) => {
												handleOpenDetails(e, user);
											}}
										/>
									</CustomTooltip>
								</span>
							</CustomTableCell>
							<CustomTableCell autoTranslate="no">{user.email}</CustomTableCell>
							<CustomTableCell>
								<Select native value={user.statusInProject} onChange={(e) => handleChange(e, user)}>
									{status.map((r) => (
										<option key={r} value={r}>
											{r}
										</option>
									))}
								</Select>
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
			<TokenInfoDialog
				companyId={companyId}
				open={open}
				projectId={projectId}
				userId={selectedUser?.id}
				onClose={(e) => {
					handleOpenDetails(e, null);
				}}
			/>
		</CustomTable>
	);
}
