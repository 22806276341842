import React from "react";
import { connect } from "react-redux";
import { ApiService } from "../../api";
import OpportunityBanner from "../../common/components/view-banner/OpportunityBanner";
import { AnalyticsProvider, ContextProvider, Flags, translate } from "../../common/providers";
import { exportView } from "../../navigation";
import styles from "./Opportunity.module.css";
import OppotunityList from "./components/opportunity-list/OpportunityList";
import OpportunityDrop from "./components/opportunity-import/opportunity-drop/OpportunityDrop";
import { withWebsocket } from "../../common/components";
import { setNeedUpdate } from "./slice/opportunitySlice";

const mapStateToProps = ({ context, opportunity }) => ({
	companyId: context.company?.id,
	isUploading: opportunity.isUploading,
});
const mapDispatchToProps = (dispatch) => ({
	onSetNeedUpdate: (payload) => dispatch(setNeedUpdate(payload)),
});

class Opportunity extends React.Component {
	constructor(props) {
		super(props);
		this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentDidMount() {
		const documentTitle = translate("opportunity.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Opportunities" });
		ContextProvider.setHomeTab("opportunity");
		this.subscribeSocket();
		window.addEventListener("beforeunload", this.handleBeforeUnload);
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
		window.removeEventListener("beforeunload", this.handleBeforeUnload);
	}

	subscribeSocket = () => {
		const { subscribeWS, companyId } = this.props;
		subscribeWS(`/topic/opportunity/company/${companyId}/refresh`, this.handleRefreshMessage);
	};

	handleRefreshMessage = ({ reason }) => {
		const { onSetNeedUpdate } = this.props;
		if (reason !== "NEW_DOCUMENT") {
			return;
		}
		onSetNeedUpdate(true);
	};

	handleBeforeUnload = (event) => {
		const { isUploading } = this.props;
		if (isUploading) {
			const confirmationMessage = translate("opportunity.upload.cancel.confirm-dialog.confirmation-text");
			event.returnValue = confirmationMessage;
			return confirmationMessage;
		}
		return "";
	};

	render() {
		return (
			<div className={styles.opportunity}>
				<OpportunityBanner />
				<div className={styles.main__container}>
					<OpportunityDrop />
					<OppotunityList />
				</div>
			</div>
		);
	}
}

export { default as opportunitySlice } from "./slice/opportunitySlice";
export default exportView({
	path: "/opportunity",
	localesPath: "/opportunity/locales",
	component: withWebsocket(connect(mapStateToProps, mapDispatchToProps)(Opportunity)),
	flag: Flags.OPPORTUNITY,
});
