import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NormDetailsSidePanel } from "../..";
import { ApiService, NormsService } from "../../../../../api";
import {
	SearchInput,
	SidePanel,
	IconComponent,
	icon,
	CustomButton,
	SidePanelHeader,
	SidePanelContainer,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import { addCustomNorm, addNormToProject, getNormsInProject } from "../../../slice/normsCenterSlice";
import AddNorm from "../../add-norm/AddNorm";
import NewVersionTabTable from "./new-version-tab-table/NewVersionTabTable";
import styles from "./NewVersionTab.module.css";

export default function NewVersionTab({ normId, normName, onActive: onActiveFromParent }) {
	const dispatch = useDispatch();
	const { limit, page, filters } = useSelector(({ normsCenter }) => ({
		limit: normsCenter.limit,
		page: normsCenter.page,
		filters: normsCenter.filters,
	}));
	const [openCreateNormPanel, dispatchOpenCreateNormPanel] = useReducer((prev) => !prev, false);
	const [selectedRow, setSelectedRow] = useState(null);
	const [newVersions, setNewVersions] = useState([]);
	const [normFilter, setNormFilter] = useState("");
	const cancelTokenSrcRef = useRef(null);
	useEffect(() => {
		cancelTokenSrcRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.getCancelTokenSource(cancelTokenSrcRef.current);
		};
	}, []);
	useEffect(() => {
		if (!normId) {
			return null;
		}
		const cancelTokenSrc = ApiService.getCancelTokenSource();
		NormsService.getVersions({ normId }, cancelTokenSrc.token)
			.then(setNewVersions)
			.catch((err) => console.error(err));
		return () => {
			ApiService.cancelTokens(cancelTokenSrc);
		};
	}, [normId]);
	const filteredNewVersions = useMemo(
		() => newVersions.filter((nV) => nV.name?.toLowerCase().startsWith(normFilter?.toLowerCase())),
		[newVersions, normFilter]
	);
	const addNewVersion = (newVersionId) => {
		NormsService.addVersion({ normId, newVersionId }, cancelTokenSrcRef.current.token)
			.then(() => NormsService.getVersions({ normId }, cancelTokenSrcRef.current.token))
			.then(setNewVersions)
			.then(() => {
				dispatch(getNormsInProject({ token: cancelTokenSrcRef.current.token, limit, page, filters }));
			})
			.catch((err) => console.error(err));
	};
	const handleCreateNorm = (payload) => {
		dispatchOpenCreateNormPanel();
		dispatch(addCustomNorm({ payload, token: cancelTokenSrcRef.current.token }))
			.then(unwrapResult)
			.then(({ id }) => {
				if (newVersions.length === 0) {
					addNewVersion(id);
				}
				return id;
			})
			.catch((err) => console.error(err));
	};
	const handleAddNewVersion = ({ id: newVersionId }) => {
		dispatchOpenCreateNormPanel();
		dispatch(addNormToProject({ normId: newVersionId, token: cancelTokenSrcRef.current.token }));
		if (newVersions.length === 0) {
			addNewVersion(newVersionId);
		}
	};
	const handleChangeNormFilter = (e) => setNormFilter(e?.target?.value || "");
	const handleClearNormFilter = () => setNormFilter("");
	const handleClickRow = (row) => {
		setSelectedRow(row);
	};
	const handleCloseNewVersionPanel = () => {
		setSelectedRow(null);
	};
	return (
		<>
			<SidePanelContainer>
				<h5>{translate("norms-center.norm.tabs.new-version.title")}</h5>
				{Array.isArray(newVersions) && newVersions.length > 0 ? (
					<>
						<SidePanelHeader>
							<SearchInput
								label={translate("norms-center.norm.tabs.new-version.filter")}
								value={normFilter}
								onChange={handleChangeNormFilter}
								onClearSearch={handleClearNormFilter}
							/>
							<span className={styles.normVersion__spacer} />
							<CustomButton color="primary" variant="contained" onClick={dispatchOpenCreateNormPanel}>
								{translate("norms-center.norm.tabs.new-version.add-norm")}
							</CustomButton>
						</SidePanelHeader>
						<NewVersionTabTable newVersions={filteredNewVersions} onSelectRow={handleClickRow} />
					</>
				) : (
					<div className={styles.newVersion__emptyState}>
						<IconComponent className={styles.emptyState__icon} icon={icon.faCalendarCheckRegular} />
						<span className={styles.emptyState__text}>
							<b>{normName}</b>
							{translate("norms-center.norm.tabs.new-version.empty-state")}
						</span>
						<CustomButton color="primary" variant="contained" onClick={dispatchOpenCreateNormPanel}>
							{translate("norms-center.norm.tabs.new-version.add")}
						</CustomButton>
					</div>
				)}
			</SidePanelContainer>
			<SidePanel
				open={openCreateNormPanel}
				size={65}
				onActive={onActiveFromParent}
				onClose={dispatchOpenCreateNormPanel}
			>
				{({ onActive }) => (
					<AddNorm
						onActive={onActive}
						onAddNorm={handleAddNewVersion}
						onClose={dispatchOpenCreateNormPanel}
						onCreateNorm={handleCreateNorm}
					/>
				)}
			</SidePanel>
			<NormDetailsSidePanel
				normId={selectedRow?.id || 0}
				normName={selectedRow?.name || ""}
				open={!!selectedRow}
				onActive={onActiveFromParent}
				onClose={handleCloseNewVersionPanel}
			/>
		</>
	);
}
