import {
	Flags,
	hasCompanyPermission,
	hasPermission,
	isCompanyTypeAllowed,
	isFeatureEnabled,
	isSegFeatureEnabled,
	Permissions,
	SegFlags,
} from "../../common/providers";

export const checkValidity = ({ companyRole, flag, role, segFlag, allowedCompanyTypes } = {}) => {
	const checks = [
		flag ? isFeatureEnabled(flag) : true,
		segFlag ? isSegFeatureEnabled(segFlag) : true,
		role ? hasPermission(role) : true,
		companyRole ? hasCompanyPermission(companyRole) : true,
		allowedCompanyTypes ? isCompanyTypeAllowed(allowedCompanyTypes) : true,
	];
	return checks.every(Boolean);
};

const WRONG_FLAG = "Wrong flag denomination";
const WRONG_ROLE = "Wrong role denomination";
const MISSING_INFO = "Missing view information";
function validateFlag(flag) {
	if (flag && !Array.isArray(flag) && !Object.values(Flags).includes(flag)) {
		throw Error(WRONG_FLAG);
	} else if (flag && Array.isArray(flag) && flag.length > 0) {
		flag.forEach((f) => {
			if (!Object.values(Flags).includes(f)) {
				throw Error(WRONG_FLAG);
			}
		});
	}
}
function validateSegFlag(flag) {
	if (flag && !Array.isArray(flag) && !SegFlags[flag]) {
		throw Error(WRONG_FLAG);
	} else if (flag && Array.isArray(flag) && flag.length > 0) {
		flag.forEach((f) => {
			if (!SegFlags[f]) {
				throw Error(WRONG_FLAG);
			}
		});
	}
}
function validateRole(role) {
	if (role && !Array.isArray(role) && !Object.values(Permissions).includes(role)) {
		throw Error(WRONG_ROLE);
	} else if (role && Array.isArray(role) && role.length > 0) {
		role.forEach((r) => {
			if (!Object.values(Permissions).includes(r)) {
				throw Error(WRONG_ROLE);
			}
		});
	}
}
export const exportView = ({ companyRole, component, flag, localesPath, path, role, segFlag }) => {
	if (!component || Array.isArray(component)) {
		throw Error(MISSING_INFO);
	}
	validateSegFlag(segFlag);
	validateFlag(flag);
	validateRole(role);
	validateRole(companyRole);
	if (!path || (Array.isArray(path) && path.length === 0)) {
		throw Error(MISSING_INFO);
	}
	if (Array.isArray(path) && path.length > 0) {
		path.forEach((p) => {
			if (!p.path) {
				throw Error(MISSING_INFO);
			}
			validateSegFlag(p.segFlag);
			validateFlag(p.flag);
			validateRole(p.role);
			validateRole(p.companyRole);
		});
	}
	return { path, localesPath, component, flag, role, companyRole, segFlag };
};
export const getProjectIdFromURL = (url) => {
	const projectURI = "/projects/";
	const projectURIIndex = url.indexOf(projectURI);
	if (projectURIIndex === -1) {
		return "";
	}
	return url.substring(projectURIIndex + projectURI.length).split("/", 1)[0];
};

export const isInMyReviewPage = (url) => url.pathname.includes("/review/my-review/");
