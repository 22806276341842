import React from "react";
import { Paper, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { translate } from "../../../../../../common/providers";
import styles from "./RedressDialog.module.css";

export default function RedressGroup({ action, handleChangeAction, GROUP_ACTION, isClause }) {
	return (
		<div className={styles.container}>
			<Paper className={styles.paper}>
				<FormControl className={styles.content}>
					<InputLabel className={styles.inputLabel}>
						{translate("smart-review.redress-group.item-group-with")}
					</InputLabel>
					<Select
						labelWidth={100}
						size="small"
						value={action || (!isClause && GROUP_ACTION[0]) || GROUP_ACTION[1]}
						onChange={handleChangeAction}
					>
						{!isClause && (
							<MenuItem value={GROUP_ACTION[0]}>
								{translate("smart-review.redress-group.item-content-above")}
							</MenuItem>
						)}
						<MenuItem value={GROUP_ACTION[1]}>
							{translate("smart-review.redress-group.item-content-below")}
						</MenuItem>
					</Select>
				</FormControl>
			</Paper>
		</div>
	);
}
