/* eslint-disable quotes */
import React, { useState } from "react";
import { InputAdornment } from "@mui/material";
import styles from "./MultipleKeywordsInput.module.css";
import { ButtonsTextField, CustomIconButton, I18nTranslate, icon } from "../../..";
import { translate } from "../../../../providers";
import { isNonEmptyArray } from "../../../../utils";

export default function MultipleKeywordsInput({ className, defaultKeywords, onUpdate, property, spaceAllowed }) {
	const [value, setValue] = useState("");
	const isDuplicate = (val) => defaultKeywords.some((ko) => ko.toLowerCase() === val.toLowerCase());
	const handleKeyDown = (evt) => {
		const controls = ["Enter", "Tab", ",", ";", " "];
		if (((spaceAllowed && controls.slice(0, -1)) || controls).includes(evt.key)) {
			evt.preventDefault();
			setValue("");
			if (value.length > 0 && !isDuplicate(value)) {
				onUpdate(property, [...defaultKeywords, value]);
			}
		}
	};
	const handleChange = (event) => {
		setValue(event.target.value);
	};
	const handleSplitKeywords = (pastedKeywords) => {
		let pasteArray = [];
		const spaceAllowedReg = /(?:[\n,;\r]+| (?=(?:[^"]*"[^"]*")*[^"]*$))/;
		const noSpaceReg = /[\n,; \r]+/;
		pasteArray = pastedKeywords
			.split((spaceAllowed && spaceAllowedReg) || noSpaceReg)
			.map((el) => el.replaceAll('"', ""));
		return pasteArray;
	};
	const handlePaste = (event) => {
		event.preventDefault();
		const paste = event.clipboardData.getData("text");
		const pasteArray = handleSplitKeywords(paste).filter((ko) => ko.length > 0);
		if (isNonEmptyArray(pasteArray)) {
			const removedNonConformKeywords =
				(spaceAllowed && pasteArray) || pasteArray.filter((ko) => !ko.includes(" "));
			const removedDuplicates = Array.from(new Set(removedNonConformKeywords.map((ko) => ko.toLowerCase())));
			const toBeAdded = removedDuplicates.filter((keyword) => !isDuplicate(keyword));
			onUpdate(property, [...defaultKeywords, ...toBeAdded]);
		}
	};
	const handleClickKeyword = (keyword) => {
		setValue(keyword);
		onUpdate(
			property,
			defaultKeywords.filter((ko) => ko !== keyword)
		);
	};
	const handleDeleteKeyword = (keyword) => {
		onUpdate(
			property,
			defaultKeywords.filter((ko) => ko !== keyword)
		);
	};
	const handleResetValue = () => {
		setValue("");
	};
	const handleSuppr = () => {
		if (defaultKeywords.length > 0) {
			onUpdate(property, defaultKeywords.slice(0, -1));
		}
	};
	const handleClearAll = () => {
		onUpdate(property, []);
	};
	return (
		<div className={className}>
			<ButtonsTextField
				displayField="keyword"
				helperText={
					value && (
						<I18nTranslate
							param={{
								hintClassName: styles.textfield__hint,
								enterClassName: styles["textfield__hint--grey"],
							}}
							translationKey="common:multiple-email-input.text-field.hint"
						/>
					)
				}
				InputProps={
					value
						? {
								endAdornment: (
									<InputAdornment position="end">
										<CustomIconButton icon={icon.faTimes} onClick={handleResetValue} />
									</InputAdornment>
								),
						  }
						: {}
				}
				items={defaultKeywords.map((ko) => ({
					keyword: ko,
					color: "primary",
					deletable: true,
				}))}
				keyField="keyword"
				placeholder={translate("common:multiple-keywords-input.text-field.label")}
				value={value}
				onChange={handleChange}
				onClearItems={handleClearAll}
				onClickItem={(item) => handleClickKeyword(item.keyword)}
				onDeleteItem={(item) => handleDeleteKeyword(item.keyword)}
				onKeyDown={handleKeyDown}
				onPaste={handlePaste}
				onSuppr={handleSuppr}
			/>
		</div>
	);
}
