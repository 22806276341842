import React, { useCallback, useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiService, PublicService } from "../../api";
import { aitendersLogo } from "../../common/resources/images";
import styles from "./OnboardingProject.module.css";
import { NavUtils } from "../../navigation";
import { CircularLoader, CustomDialog } from "../../common/components";
import { translate } from "../../common/providers";
import KeycloakService from "../../services/KeycloakService";

export default ({ onLogout }) => {
	const [openJoinErrorDialog, setOpenJoinErrorDialog] = useState(false);
	const [tokenVerified, setTokenVerified] = useState(false);
	const isLogged = KeycloakService.isLoggedIn();
	const cancelTokenSourceRef = useRef(null);
	const location = useLocation();
	const navigate = useNavigate();

	const redirectToRegister = (historyState) => {
		navigate("/register", { state: { ...historyState } });
	};
	const verifyToken = useCallback(
		(invitationToken) => {
			if (!tokenVerified) {
				const cancelToken = ApiService.getCancelTokenSource();
				PublicService.joinProject({ invitationToken }, cancelToken.token)
					.then(({ data }) => {
						setTokenVerified(true);
						if (data.userAccountExists) {
							window.location.href = NavUtils.goToProject(data.projectId);
						} else {
							const historyState = {
								registerEmail: data.userEmail,
								invitationToken,
							};
							if (isLogged) {
								PublicService.logout(cancelTokenSourceRef.current.token).finally(() => {
									onLogout();
									redirectToRegister(historyState);
								});
							} else {
								redirectToRegister(historyState);
							}
						}
					})
					.catch((err) => {
						console.error(err);
						// Commented for temporary hotfix to directly
						// setOpenJoinErrorDialog(true);
						// Redirect to home instead of displaying error message
						window.location.href = NavUtils.goToHome();
						setTokenVerified(true);
					})
					.finally(() => ApiService.cancelTokens(cancelToken));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isLogged, onLogout, tokenVerified]
	);
	useEffect(() => {
		const cancelTokenSource = ApiService.getCancelTokenSource();
		const params = new URLSearchParams(location.search);
		const invitationToken = params.get("token") || "";
		verifyToken(invitationToken);
		return () => {
			ApiService.cancelTokens(cancelTokenSource);
		};
	}, [verifyToken, location?.search]);

	const handleCloseJoinErrorDialog = () => {
		setOpenJoinErrorDialog(false);
		window.location.href = NavUtils.goToHome();
	};

	return (
		<>
			<div className={styles["main-container"]}>
				<img alt="aitenders_logo" src={aitendersLogo} />
				<CircularLoader className={styles.loader} />
			</div>
			<CustomDialog
				hideCloseButton
				maxWidth="xs"
				open={openJoinErrorDialog}
				submitLabel={translate("common:onboarding-project.dialog.go-to-home")}
				subTitle={translate("common:onboarding-project.dialog.content")}
				title={translate("common:onboarding-project.dialog.title")}
				onSubmit={handleCloseJoinErrorDialog}
			/>
		</>
	);
};
