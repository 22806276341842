import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import styles from "./InformationBox.module.css";

import { translate } from "../../providers";
import { NavUtils } from "../../../navigation";
import { IconComponent, icon } from "../icon-component";
import { VerticalDivider } from "../dividers";
import { CustomTooltip } from "../tooltip";
import Hierarchy from "../hierarchy/Hierarchy";
import { CustomButton, CustomIconButton } from "../buttons";
import { CustomButtonLink } from "../links";
import Preview from "../preview/Preview";

const InformationBox = ({ isMultiProject, onOpenSidePanel, information, buttonSourceDisplay = false }) => {
	const location = useLocation();
	const projectId = useSelector(({ context }) => context.project.id);
	const companyId = useSelector(({ context }) => context.company.id);
	const [preview, setPreview] = useState(null);
	const headerRender = (informationType) =>
		informationType === "INFORMATION" ? (
			<>
				<IconComponent
					className={styles.infoIcon}
					color="var(--color-light-grey-1)"
					icon={icon.faStream}
					size="sm"
				/>
				{`INFO_${information.informationId}`}
			</>
		) : (
			<>
				<IconComponent className={styles.infoIcon} color="var(--color-red)" icon={icon.faAsterisk} size="sm" />
				{`REQ_${information.informationId}`}
			</>
		);
	const handleOpenSidePanel = () => {
		onOpenSidePanel(information);
	};
	const handleCloseDisplay = () => setPreview(null);
	const handleDisplay = () =>
		setPreview({
			coordinates: information.rectangleDTO,
			informationId: information.informationId,
			documentId: information.documentId,
			page: information.page,
			type: information.type,
		});
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				{headerRender(information.informationType)}
				<VerticalDivider className={styles.verticalDivider} />
				{isMultiProject && (
					<CustomTooltip showWhenOverflow title={information.projectName}>
						<span className={styles.projectName}>{information.projectName}</span>
					</CustomTooltip>
				)}
				{!isMultiProject && (
					<Hierarchy
						enableFirstLocationCut
						isDangerousSetInnerHTML
						breadcrumbClassName={styles.breadcrumb}
						charsKeptInEnd={6}
						className={styles.breadcrumbContainer}
						documentName={information.documentName}
						hierarchy={information.location}
						openOnHover={false}
					/>
				)}
				{buttonSourceDisplay && (
					<CustomIconButton
						icon={icon.faFile}
						size="sm"
						tooltip={translate("common:btn.display")}
						variant="outlined"
						onClick={handleDisplay}
					/>
				)}
			</div>
			{isMultiProject && (
				<div className={styles.header}>
					<Hierarchy
						enableFirstLocationCut
						isDangerousSetInnerHTML
						breadcrumbClassName={styles.breadcrumb}
						charsKeptInEnd={6}
						className={styles.breadcrumbContainer}
						documentName={information.documentName}
						hierarchy={information.location}
						openOnHover={false}
					/>
				</div>
			)}
			<div className={styles.content}>{information.content}</div>
			<div className={styles.footer}>
				{information.confidence && (
					<span>
						{translate("common:information-box.confident", {
							confidence: Math.floor(information.confidence * 100),
						})}
					</span>
				)}
				{(isMultiProject && (
					<CustomButton
						className={styles.openBtn}
						color="secondary"
						size="sm"
						variant="outlined"
						onClick={handleOpenSidePanel}
					>
						{translate("common:add-template-panel.box.details-button")}
					</CustomButton>
				)) || (
					<CustomButtonLink
						openInNewTab
						buttonClassName={styles.openBtn}
						color="secondary"
						size="sm"
						startIcon={icon.faUpRightFromSquare}
						state={location?.state}
						to={NavUtils.goToSmartReview({
							projectId,
							documentId: information.documentId,
							pageStart: information.pageStart || information.page || 1,
							infoId: information.informationId || null,
							companyId,
						})}
						variant="outlined"
					>
						{translate("common:component.preview.open-document")}
					</CustomButtonLink>
				)}
			</div>
			<Preview
				coordinates={preview?.coordinates}
				docId={preview?.documentId}
				infoId={preview?.informationId}
				infoType={preview?.type}
				open={!!preview}
				page={preview?.page}
				projectId={projectId}
				onClose={handleCloseDisplay}
			/>
		</div>
	);
};

export default InformationBox;
