import { Checkbox } from "@mui/material";
import React from "react";
import {
	CustomButton,
	GenericDialog,
	GenericDialogContent,
	GenericDialogFooter,
	GenericDialogHeader,
} from "../../../../common/components";
import { translate } from "../../../../common/providers";

export default function ConfirmDeleteDialog({
	deleteContent,
	isFolder,
	onClose,
	onConfirm,
	onSetDeleteContent,
	open,
	text,
	textSubDir,
}) {
	const handleConfirm = () => onConfirm();

	return (
		<GenericDialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
			<GenericDialogHeader onClose={onClose}>
				<h4>{translate("document-center.confirm-delete.title")}</h4>
			</GenericDialogHeader>
			<GenericDialogContent dividers>
				<div>{text}</div>
				{isFolder && (
					<div>
						<Checkbox checked={deleteContent} color="primary" onChange={onSetDeleteContent} />
						{textSubDir}
					</div>
				)}
			</GenericDialogContent>
			<GenericDialogFooter>
				<CustomButton color="primary" variant="outlined" onClick={onClose}>
					{translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton color="primary" variant="contained" onClick={handleConfirm}>
					{translate("common:btn.confirm")}
				</CustomButton>
			</GenericDialogFooter>
		</GenericDialog>
	);
}
