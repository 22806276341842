import React from "react";
import { ListItem, ListItemText, Divider, Checkbox, ListItemIcon } from "@mui/material";
import { translate } from "../../../../../common/providers";
import { CustomTooltip } from "../../../../../common/components";

export default function RelatedContentDocuments({ item, onClickDocument, itemIsChecked, onCheck, contentReferenced }) {
	const handleChange = (event) => {
		onCheck(event.target.checked, item);
	};

	const renderDocumentSecondaryText = () => {
		if (itemIsChecked) {
			return translate("qa-journal.qa-details.related-content.secondary-text.all-document");
		}
		if (contentReferenced > 0) {
			return translate("qa-journal.qa-details.related-content.secondary-text.content-number", {
				contentReferenced,
			});
		}
		return translate("qa-journal.qa-details.related-content.secondary-text.no-content");
	};
	return (
		<>
			<ListItem key={item.documentId} button dense>
				<ListItemIcon>
					<Checkbox
						disableRipple
						checked={itemIsChecked}
						color="primary"
						edge="start"
						onChange={handleChange}
					/>
				</ListItemIcon>
				<CustomTooltip title={item.documentFilename || ""}>
					<ListItemText
						id={item.documentId}
						primary={item.documentName}
						secondary={renderDocumentSecondaryText()}
						translate="no"
						onClick={() => onClickDocument(item)}
					/>
				</CustomTooltip>
			</ListItem>
			<Divider />
		</>
	);
}
