import React, { Fragment, useEffect, useState } from "react";
import { Collapse, List, ListItem, ListItemIcon, ListItemSecondaryAction } from "@mui/material";
import { useSelector } from "react-redux";
import { CustomIconButton, CustomTooltip, icon, IconComponent } from "../../../../../common/components";
import { Permissions, translate } from "../../../../../common/providers";
import styles from "./CompanyRow.module.css";

const CompanyRow = ({
	company,
	displayLeaderIcon = false,
	level = 0,
	selectedCompany,
	isProjectDirector,
	onBlock,
	onClickCompany,
	onEdit,
}) => {
	const myCompanyId = useSelector(({ context }) => context.company.id);
	const [collapsed, setCollapsed] = useState(true);
	const [isSelected, setIsSelected] = useState(false);
	const [isMyCompany, setIsMyCompany] = useState(false);
	const [secondaryActions, setSecondaryActions] = useState([]);
	const handleCollapse = () => setCollapsed((prev) => !prev);
	useEffect(() => {
		setIsSelected((company && company.id) === (selectedCompany && selectedCompany.id));
		setIsMyCompany(company && company.id === myCompanyId);
		return () => {
			setIsMyCompany(false);
			setIsSelected(false);
		};
	}, [company, selectedCompany, myCompanyId]);
	useEffect(() => {
		const secActions = [
			{
				id: 1,
				action: (
					<CustomTooltip title={translate("team-management.company-row.title.number-of-active-users")}>
						<span>
							<IconComponent color="var(--color-light-grey-3)" icon={icon.faUser} />
							{company.activeUsers}
						</span>
					</CustomTooltip>
				),
			},
		];
		if (isProjectDirector) {
			secActions.push({
				id: 2,
				action: (
					<CustomIconButton
						icon={icon.faPen}
						tooltip={translate("team-management.company-row.title.edit")}
						onClick={() => onEdit({ company })}
					/>
				),
			});
		} else if (!isMyCompany && isProjectDirector) {
			secActions.push({
				id: 3,
				action: (
					<CustomIconButton
						icon={company.blocked ? icon.faLock : icon.faLockOpen}
						tooltip={`${
							company.blocked
								? translate("team-management.company-row.unblock")
								: translate("team-management.company-row.block")
						} ${translate("team-management.company-row.company")}`}
						onClick={() => onBlock({ company })}
					/>
				),
			});
		} else {
			const tooltipTitle = company.blocked
				? translate("team-management.company-row.title.company-is.blocked")
				: translate("team-management.company-row.title.company-is.not-blocked");
			secActions.push({
				id: 4,
				action: (
					<CustomTooltip title={tooltipTitle}>
						<IconComponent icon={company.blocked ? icon.faLock : icon.faLockOpen} />
					</CustomTooltip>
				),
			});
		}
		setSecondaryActions(secActions);
		return () => setSecondaryActions([]);
	}, [
		company,
		company.activeUsers,
		company.blocked,
		company.privacy,
		displayLeaderIcon,
		isMyCompany,
		isProjectDirector,
		onBlock,
		onEdit,
	]);
	return (
		<>
			<ListItem
				button
				selected={isSelected}
				style={{ paddingLeft: `${16 * (1 + level)}px` }}
				onClick={() => onClickCompany(company)}
			>
				<span className={styles.listItem__companyName} translate="no">
					<ListItemIcon>
						<IconComponent
							color="var(--color-dark-grey-1)"
							icon={company.type === "COMPANY" ? icon.faBuilding : icon.faUserFriends}
						/>
					</ListItemIcon>
					{displayLeaderIcon ? (
						<CustomTooltip title={translate("team-management.company-row.title.company-leader")}>
							<IconComponent className={styles.companyName__leaderIcon} icon={icon.faStar} />
						</CustomTooltip>
					) : null}
					<CustomTooltip autoTranslate="no" placement="right" title={company.name}>
						<span>{isMyCompany ? <b>{company.name}</b> : company.name}</span>
					</CustomTooltip>
				</span>
				<span className={styles.listItem__icons}>
					<ListItemSecondaryAction>
						{secondaryActions.map((secondaryAction) => (
							<Fragment key={secondaryAction.id}>{secondaryAction.action}</Fragment>
						))}
						{Array.isArray(company.companies) && company.companies.length > 0 ? (
							<CustomIconButton
								icon={(collapsed && icon.faChevronUp) || icon.faChevronDown}
								size="sm"
								tooltip={translate(
									(collapsed && "team-management.company-row.toolltip.reduce") ||
										"team-management.company-row.toolltip.enlarge"
								)}
								onClick={handleCollapse}
							>
								{(collapsed && <IconComponent icon={icon.faChevronUp} />) || (
									<IconComponent icon={icon.faChevronDown} />
								)}
							</CustomIconButton>
						) : (
							<CustomIconButton icon={icon.faMinus} />
						)}
					</ListItemSecondaryAction>
				</span>
			</ListItem>
			{Array.isArray(company.companies) && company.companies.length > 0 && (
				<Collapse unmountOnExit in={collapsed} timeout="auto">
					<List dense disablePadding>
						{company.companies.map((cny) => (
							<CompanyRow
								key={cny.id}
								company={cny}
								displayLeaderIcon={cny.role === Permissions.PROJECT_LEADER.role}
								isProjectDirector={isProjectDirector}
								level={level + 1}
								selectedCompany={selectedCompany}
								onBlock={onBlock}
								onClickCompany={onClickCompany}
								onEdit={onEdit}
							/>
						))}
					</List>
				</Collapse>
			)}
		</>
	);
};

export default CompanyRow;
