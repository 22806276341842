import React from "react";
import { CustomButton, CustomIconButton, icon } from "../../../../../../common/components";
import styles from "./PackageRow.module.css";

export default function PackageRow({ pck, onSelectRow }) {
	const { companies, description, features, id, name, projects } = pck;
	return (
		<div key={id} className={styles.row}>
			<div>
				<span>{name}</span>
				<CustomIconButton
					btnClassName={styles.import__btn}
					icon={icon.faPen}
					iconColor="var(--color-light-grey-2)"
					size="md"
					onClick={() => {
						onSelectRow(pck, "info");
					}}
				/>
			</div>
			<div>
				<span>{description}</span>
				<CustomIconButton
					btnClassName={styles.import__btn}
					icon={icon.faPen}
					iconColor="var(--color-light-grey-2)"
					size="md"
					onClick={() => {
						onSelectRow(pck, "info");
					}}
				/>
			</div>
			<div>
				<CustomButton
					variant="outlined"
					onClick={() => {
						onSelectRow(pck, "features");
					}}
				>
					{features}
				</CustomButton>
			</div>
			<div>
				<CustomButton
					data-testid="companies"
					variant="outlined"
					onClick={() => {
						onSelectRow(pck, "companies");
					}}
				>
					{companies}
				</CustomButton>
			</div>
			<div>
				<CustomButton
					variant="outlined"
					onClick={() => {
						onSelectRow(pck, "projects");
					}}
				>
					{projects}
				</CustomButton>
			</div>
		</div>
	);
}
