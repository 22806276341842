import React, { useState, useEffect, useCallback } from "react";
import Tree from "react-virtualized-tree";
import { translate } from "../../../../common/providers";
import Directory from "./Directory";
import Expandable from "./Expandable";
import styles from "./Directory.module.css";

const DirectoryTree = ({ includeNodes, selectedNodes, currentDirectoryId, tree, disabled, onClickDirectory }) => {
	const [nodes, setNodes] = useState([]);
	const formatNodes = useCallback(
		(newNodes, expanded) =>
			newNodes.map((newNode) => {
				const { id } = newNode;
				let { directories } = newNode;
				const hasChildren = Array.isArray(directories) && directories.length > 0;
				const includesNodesSelector = (directory) =>
					directories.some((dir) => selectedNodes.includes(dir.id))
						? selectedNodes.includes(directory.id)
						: true;
				if (hasChildren && Array.isArray(selectedNodes) && selectedNodes.length > 0) {
					directories = directories.filter((directory) =>
						includeNodes ? includesNodesSelector(directory) : !selectedNodes.includes(directory.id)
					);
				} else if (hasChildren && includeNodes && currentDirectoryId === id) {
					directories = [];
				}
				return {
					...newNode,
					children: hasChildren ? formatNodes(directories, true) : [],
					state: {
						expanded,
					},
				};
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => {
		setNodes(formatNodes([tree], true));
	}, [formatNodes, tree]);

	const handleChange = (newNodes) => {
		setNodes(newNodes);
	};
	return (
		<Tree nodeMarginLeft={12} nodes={nodes} onChange={handleChange}>
			{({ style, node, ...rest }) => (
				<div className={styles.directoryTree__container} style={{ ...style }}>
					<Expandable disabled={disabled} node={node} {...rest}>
						<Directory
							currentDirectoryId={currentDirectoryId}
							disabled={disabled}
							node={node}
							onClickDirectory={onClickDirectory}
						>
							{node.label || translate("document-center.root-dir")}
						</Directory>
					</Expandable>
				</div>
			)}
		</Tree>
	);
};

export default DirectoryTree;
