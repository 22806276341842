import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";
import { useSelector } from "react-redux";
import { Tile, TileHeader, TileBody, CustomIconButton, icon } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import AddMilestoneDialog from "./AddMilestoneDialog";
import EditMilestoneDialog from "./EditMilestoneDialog";

import { SCOPES } from "../../../../common/providers/permissions/permissions";
import styles from "./ProjectMilestones.module.css";

export default function ProjectMilestones({ milestones, className, onEditMilestone, onAddMilestone }) {
	const [milestonesList, setMilestonesList] = useState([]);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [openAddDialog, setOpenAddDialog] = useState(false);
	const [canAddMilestone, setCanAddMilestone] = useState(false);

	const newUserRole = useSelector(({ context }) => context.roles[SCOPES.PROJECT]);

	useEffect(() => {
		setCanAddMilestone(newUserRole.includes("LEADER") || newUserRole.includes("MANAGER"));
	}, [newUserRole]);

	useEffect(() => {
		const externalMilestone = [];
		const internalMilestone = [];
		/* To get internal milestone's names and values (dates) */
		const internalMilestoneNames =
			milestones && milestones.internalMilestone ? Object.keys(milestones.internalMilestone) : [];
		const internalMilestoneValues =
			milestones && milestones.internalMilestone ? Object.values(milestones.internalMilestone) : [];
		for (let i = 0; i < internalMilestoneNames.length; i += 1) {
			internalMilestone.push([
				"INTERNAL",
				internalMilestoneNames[i],
				new Date(internalMilestoneValues[i]),
				new Date(internalMilestoneValues[i]),
			]);
		}
		/* To get external milestone's names and values (dates) */
		const externalMilestoneNames =
			milestones && milestones.externalMilestone ? Object.keys(milestones.externalMilestone) : [];
		const externalMilestoneValues =
			milestones && milestones.externalMilestone ? Object.values(milestones.externalMilestone) : [];

		for (let i = 0; i < externalMilestoneNames.length; i += 1) {
			externalMilestone.push([
				"EXTERNAL",
				externalMilestoneNames[i],
				new Date(externalMilestoneValues[i]),
				new Date(externalMilestoneValues[i]),
			]);
		}
		const userDefinedMilestone =
			milestones && milestones.userDefinedMilestone
				? milestones.userDefinedMilestone.map((milestone) => [
						milestone.type,
						milestone.name,
						new Date(milestone.date),
						new Date(milestone.date),
				  ])
				: [];
		const tempData = [
			[
				{ type: "string", id: "Project" },
				{ type: "string", id: "Phase" },
				{ type: "date", id: "Start" },
				{ type: "date", id: "End" },
			],
			...userDefinedMilestone,
			...externalMilestone,
			...internalMilestone,
		];
		/**
		 * If there is only one milestone, we have to add a second milestone,
		 * because mandatory to have at less 2 milestones
		 */
		if (tempData.length === 2) {
			tempData.push(["INTERNAL", "", new Date(), new Date()]);
		}
		setMilestonesList(tempData);
	}, [milestones]);

	const closeEditDialog = () => {
		setOpenEditDialog(false);
	};
	const closeAddDialog = () => {
		setOpenAddDialog(false);
	};
	const handleOpenEdit = () => {
		setOpenEditDialog(true);
	};
	const handleOpenAdd = () => {
		setOpenAddDialog(true);
	};
	return (
		<>
			<Tile className={className}>
				<TileHeader iconName={icon.faCalendarDay} title={translate("dashboards.milestone")}>
					{canAddMilestone && (
						<div className={styles.tileHeader__buttons}>
							<CustomIconButton
								disabled={
									!milestones ||
									!milestones.userDefinedMilestone ||
									milestones.userDefinedMilestone.length === 0
								}
								icon={icon.faPen}
								onClick={handleOpenEdit}
							/>
							<CustomIconButton
								icon={icon.faPlus}
								tooltip={translate("dashboards.add-new-milestone.tooltip.title")}
								onClick={handleOpenAdd}
							/>
						</div>
					)}
				</TileHeader>
				<TileBody>
					{milestones &&
					milestones.userDefinedMilestone &&
					milestones.externalMilestone &&
					milestones.internalMilestone &&
					milestonesList.length > 1 ? (
						<Chart
							chartType="Timeline"
							data={milestonesList}
							height="150px"
							loader={<div>{translate("dashboards.project-timeline.chart.loading-chart")}</div>}
							options={{
								timeline: {
									groupByRowLabel: true,
									colorByRowLabel: false,
									rowLabelStyle: {
										fontName: "Roboto",
										fontSize: "1rem",
										color: "var(--color-dark-blue)",
									},
									barLabelStyle: { fontName: "Garamond", fontSize: "0.85rem" },
								},
								backgroundColor: "var(--color-white)",
							}}
							width="100%"
						/>
					) : (
						<div className={styles.noMilestone}>{translate("dashboards.empty-state.none-milestone")}</div>
					)}
				</TileBody>
			</Tile>
			{canAddMilestone && (
				<>
					<AddMilestoneDialog open={openAddDialog} onAdd={onAddMilestone} onClose={closeAddDialog} />
					<EditMilestoneDialog
						milestonesList={milestones && (milestones.userDefinedMilestone || [])}
						open={openEditDialog}
						onClose={closeEditDialog}
						onEditMilestone={onEditMilestone}
					/>
				</>
			)}
		</>
	);
}
