import { useRef, useEffect, useState, useCallback } from "react";
import { ApiService } from "../../api";
import { debounce } from "../utils";

/**
 * /!\ useApiDebounced will trigger a re-render when isLoading changes which means: every time.
 * Even if you don't use isLoading in your component make sure to use useCallback
 * in the case you are passing a method ref to a useEffect.
 *
 * See example in DocumentFilter::handleSearch
 */
export default function useApiDebounced(serviceMethodRef, timeout = 1000) {
	const [isLoading, setIsLoading] = useState(false);
	const cancelTokenRef = useRef(null);
	const debounceFnRef = useRef(
		debounce((onResponse, onError, params, token, canceled = false) => {
			if (canceled) {
				setIsLoading(false);
				return;
			}
			serviceMethodRef(...params, token)
				.finally(() => setIsLoading(false))
				.then(onResponse)
				.catch(onError);
		}, timeout)
	);

	useEffect(() => {
		cancelTokenRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenRef.current);
		};
	}, []);

	const call = useCallback(
		(onResponse, onError, ...params) => {
			setIsLoading(true);
			if (params.length > serviceMethodRef.length - 1) {
				throw Error("ERROR: call#useApiDebounced - Number of params exceeds expected params");
			}
			debounceFnRef.current(onResponse, onError, params, cancelTokenRef.current.token, false);
		},
		[serviceMethodRef]
	);

	const cancel = useCallback(() => {
		ApiService.cancelTokens(cancelTokenRef.current);
		debounceFnRef.current(null, null, null, null, true);
		cancelTokenRef.current = ApiService.getCancelTokenSource();
	}, []);

	return { call, cancel, isLoading };
}
