import React from "react";
import { CustomButton, I18nTranslate, icon } from "..";
import { translate } from "../../providers";
import styles from "./CategoryContents.module.css";

export default function CategoryContents({
	contentsCount = 0,
	documentsCount = 0,
	isProjectDirector = false,
	onClickAddContent,
	onClickCounts,
}) {
	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			onClickCounts();
		}
	};
	return (
		((contentsCount > 0 || documentsCount > 0) && (
			<div
				aria-label="Category counter"
				className={styles.countersContainer}
				role="button"
				tabIndex={0}
				onClick={onClickCounts}
				onKeyPress={handleKeyPress}
			>
				<I18nTranslate
					param={{ contentsCount, documentsCount }}
					translationKey="admin-categories.category-row.counters"
				/>
			</div>
		)) ||
		(isProjectDirector && (
			<CustomButton
				className={styles.buttonHeightFit}
				color="secondary"
				data-testid="add.content.btn"
				size="md"
				startIcon={icon.faPlus}
				variant="outlined"
				onClick={onClickAddContent}
			>
				{translate("admin-categories.category-row.button.add-content")}
			</CustomButton>
		))
	);
}
