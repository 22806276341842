import React, { useMemo } from "react";
import { Droppable } from "react-beautiful-dnd";
import styles from "./Column.module.css";
import { Document } from "..";
import { CustomIconButton, CustomTooltip, IconComponent, icon } from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";

const Column = ({ documents, onDelete, onEdit, precedence, providedPrec }) => {
	const notEmpty = useMemo(
		() => Array.isArray(documents[0]?.documents) && documents[0]?.documents.length > 0,
		[documents]
	);
	const handleDelete = () => {
		onDelete(precedence, documents[0]?.documents);
	};
	const handleEdit = () => {
		onEdit(precedence);
	};
	return (
		<>
			<div className={styles.column__header}>
				<div className={styles.precedence__handle} {...providedPrec.dragHandleProps}>
					{precedence.precedence !== "Autres" && (
						<IconComponent
							color="var(--color-white)"
							data-testid={`draggableGruop_${precedence.precedence}`}
							icon={icon.faGripLines}
							size="lg"
						/>
					)}
				</div>
				<div className={styles.precedenceInfo__container}>
					<CustomTooltip
						showWhenOverflow
						title={
							precedence.precedence === "Autres"
								? translate("document-center.side-panel.precedences.other")
								: precedence.precedence
						}
					>
						<span className={styles.precedenceName}>
							{precedence.precedence === "Autres"
								? translate("document-center.side-panel.precedences.other")
								: precedence.precedence}
						</span>
					</CustomTooltip>
					{precedence.precedence !== "Autres" && (
						<div className={styles.precedenceModify__btnContainer}>
							<CustomIconButton
								btnClassName={styles.precedenceModify__btn}
								data-testid="edit.group.btn"
								icon={icon.faPenToSquare}
								tooltip={translate("common:btn.edit")}
								onClick={handleEdit}
							/>
							<CustomIconButton
								btnClassName={styles.precedenceModify__btn}
								data-testid="delete.group.btn"
								icon={icon.faTrash}
								tooltip={translate("common:btn.delete")}
								onClick={handleDelete}
							/>
						</div>
					)}
				</div>
			</div>
			<Droppable droppableId={precedence.precedenceId.toString()} type="Document">
				{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						className={styles.documentsContainer}
						data-testid="group.document.container"
						style={{
							backgroundColor: snapshot.isDraggingOver ? "var(--color-blue)" : "var(--shades-grey-500)",
						}}
						{...provided.draggableProps}
					>
						{notEmpty &&
							documents[0]?.documents.map(
								(doc, index) =>
									doc.documentStatus !== "ARCHIVED" && (
										<React.Fragment key={doc.id}>
											<Document document={doc} index={index} />
										</React.Fragment>
									)
							)}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</>
	);
};

export default Column;
