import { createSlice } from "@reduxjs/toolkit";
import {
	getClauses,
	getCriticalityList,
	getDocumentDetails,
	getDocumentInformation,
	getDocumentPhysicalDetails,
	getLinkTypes,
	getNegotiabilityList,
	getReqTypesList,
	getTransverseInformation,
	getVersionStatuses,
} from "./document-thunks";
import { handleRedressCheck, getMode } from "../utils";
import { CLICK_TYPES, QA_FILTER_LIMIT, ROLES, SENTENCE_TYPE } from "../../constants/constants";
import { isNonEmptyArray } from "../../../../common/utils";
import { checkReq } from "../../utils/utils";
import { SOURCE_TYPES } from "../../../../common/constants";

const initialState = {
	/* DOCUMENT INFORMATION */
	documentId: null,
	clauseId: null,
	clauses: [],
	currentToc: null,
	loadingClauses: false,
	temporaryDocument: null,
	mode: ROLES.REVIEWER.mode,
	criticalityList: [],
	negotiabilityList: [],
	partners: [],
	reqTypeList: [],
	versionStatuses: [],
	finalParameters: {},
	globalCounters: {
		yesCounter: 0,
		pendingCounter: 0,
		noCounter: 0,
		newCounter: 0,
	},
	linkTypes: [],
	/* REDRESSING */
	redressList: [],
	redressingInformation: null,
	redressingOrders: {},
	redressingPage: null,
	/* SEARCH AND SELECTION */
	counter: 0,
	hoveredBlock: null,
	currentSearchIndex: 0,
	searchData: null,
	selectedBlock: null,
	selectedRectangles: [],
	selectedSearch: null,
	fromQuickAccess: false,
	qaMetadata: {},
	selectionBarCoordinates: null,
	selectionSidePanelOpen: false,
	selectionType: CLICK_TYPES.CLICK,
	shiftSections: [],
	currentFocus: "" /* types : multi, qa */,
	/* BOOLEANS */
	isFrozen: false,
	sidePanelOpen: false,
	showVersioning: true,
	checkedNoDoc: true,
	hideFusionDialogDisplay: false,
	isSidePanelOpenByFilter: false,
	isSidePanelOpenByNavRoute: true,
	hideDenyingDialogDisplay: false,
	isNewNotification: false,
	sortingAlgorithms: [],
	selectedSortAlgo: "",
};
const srDocumentSlice = createSlice({
	name: "srDocument",
	initialState,
	reducers: {
		setSelectedSortAlgo: (state, { payload }) => ({
			...state,
			selectedSortAlgo: payload,
		}),
		setSortingAlgorithms: (state, { payload }) => ({
			...state,
			sortingAlgorithms: payload,
		}),
		resetSidePanelContent: (state, { payload }) => ({
			...state,
			isSidePanelToBeReset: payload,
		}),
		setIsSelecting: (state, { payload }) => ({
			...state,
			isSelecting: payload,
			currentFocus: (payload && "multi") || "qa",
		}),
		setHideFusionDialogDisplay: (state, { payload }) => ({
			...state,
			hideFusionDialogDisplay: payload,
		}),
		setHideDenyingDialogDisplay: (state, { payload }) => ({
			...state,
			hideDenyingDialogDisplay: payload,
		}),
		setSelectionType: (state, { payload }) => ({
			...state,
			selectionType: payload,
		}),
		setSelectionSidePanelOpen: (state, { payload }) => ({
			...state,
			selectionSidePanelOpen: payload,
		}),
		setSelectionBarCoordinates: (state, { payload }) => ({
			...state,
			selectionBarCoordinates: payload,
		}),
		setSelectedRectangles: (state, { payload }) => ({
			...state,
			selectedRectangles:
				(isNonEmptyArray(payload) && payload) ||
				(payload &&
					payload.inside &&
					((!state.selectedRectangles.some((sr) => sr.informationId === payload.rectangle.informationId) && [
						...state.selectedRectangles,
						{
							informationId: payload.rectangle.informationId,
							rectangles: payload.rectangle.rectangles,
							isReq: checkReq(payload.rectangle),
							contentId: payload.rectangle.contentId,
						},
					]) ||
						state.selectedRectangles)) ||
				(payload &&
					!payload.inside &&
					state.selectedRectangles.filter((sr) => sr.informationId !== payload.rectangle.informationId)) ||
				[],
			currentFocus: (payload && "multi") || "qa",
			shiftSections: (payload && state.shiftSections) || [],
		}),
		setShiftSections: (state, { payload }) => ({
			...state,
			shiftSections: (payload && [...state.shiftSections, payload]) || [],
		}),
		setCurrentFocus: (state, { payload }) => ({
			...state,
			currentFocus: payload,
		}),
		setCurrentSearchIndex: (state, { payload }) => ({
			...state,
			currentSearchIndex: payload,
			currentFocus: "qa",
			selectedRectangles: [],
		}),
		setTemporaryDocument: (state, { payload }) => {
			const { fromToc } = payload || {};
			return {
				...state,
				temporaryDocument: payload,
				fromQuickAccess: fromToc || state.fromQuickAccess,
			};
		},
		setFromQuickAccess: (state, { payload }) => ({ ...state, fromQuickAccess: payload }),
		setShowVersioning: (state, { payload }) => ({ ...state, showVersioning: payload }),
		updateCaptureFreeze: (state) => ({ ...state, isFrozen: !state.isFrozen }),
		redressInfo: (state, { payload }) => {
			const { informationId, page, orders, redressList } = payload || {};
			return {
				...state,
				redressingInformation: state.redressingInformation ? null : informationId,
				redressingPage: state.redressingInformation ? null : page,
				redressingOrders: state.redressingInformation ? {} : orders,
				redressList: state.redressingInformation ? [] : redressList,
			};
		},
		setPartners: (state, { payload }) => ({ ...state, partners: payload }),
		setFinalParameters: (state, { payload }) => {
			const { finalParameters, parameterId } = payload;
			return { ...state, finalParameters, parameterId };
		},
		redressCheck: (state, { payload }) => ({ ...handleRedressCheck(state, payload) }),
		resetDocument: (state) => ({
			...initialState,
			mode: state.mode,
			criticalityList: state.criticalityList,
			negotiabilityList: state.negotiabilityList,
			reqTypeList: state.reqTypeList,
			partners: state.partners,
			selectedSearch: state.selectedSearch,
			searchData: state.searchData,
			searchIn: state.searchData,
			fromQuickAccess: state.fromQuickAccess,
			currentSearchIndex: state.currentSearchIndex,
			qaMetadata: state.qaMetadata,
			tabToOpen: state.tabToOpen,
			subTabToOpen: state.subTabToOpen,
			sidePanelIsRequirement: state.sidePanelIsRequirement,
			currentFocus: state.currentFocus,
			isSidePanelOpenByFilter: state.isSidePanelOpenByFilter,
			sidePanelInformationId: state.sidePanelInformationId,
			sidePanelOpen: state.isSidePanelOpenByNavRoute ? !!state.sidePanelInformationId : state.sidePanelOpen,
			temporaryDocument: state.temporaryDocument,
			linkTypes: state.linkTypes,
		}),
		goToNextSearch: (state) => {
			const currentIndex = state.currentSearchIndex;
			const nextIndex = (currentIndex + 1) % state.searchData.length;
			const additional =
				(state.searchData[nextIndex].documentId !== state.documentId && {
					temporaryDocument: { id: state.searchData[nextIndex].documentId, fromQa: true },
				}) ||
				{};
			return {
				...state,
				currentFocus: "qa",
				selectionSidePanelOpen: false,
				selectedSearch: state.searchData[nextIndex],
				currentSearchIndex: nextIndex,
				selectedRectangles: [],
				...additional,
			};
		},
		goToPreviousSearch: (state) => {
			const currentIndex = state.currentSearchIndex;
			const previousIndex = (currentIndex - 1) % state.searchData.length;
			const additional =
				(state.searchData[previousIndex].documentId !== state.documentId && {
					temporaryDocument: { id: state.searchData[previousIndex].documentId, fromQa: true },
				}) ||
				{};
			return {
				...state,
				currentFocus: "qa",
				selectionSidePanelOpen: false,
				selectedSearch: state.searchData[previousIndex],
				currentSearchIndex: (currentIndex === 0 && QA_FILTER_LIMIT - 1) || previousIndex,
				selectedRectangles: [],
				...additional,
			};
		},
		changeSearch: (state, { payload }) => ({
			...state,
			selectedSearch: (Array.isArray(state.searchData) && state.searchData[payload]) || null,
		}),
		setMode: (state, { payload }) => ({ ...state, mode: payload }),
		setSearchData: (state, { payload }) => ({ ...state, searchData: payload }),
		setCommentData: (state, { payload }) => ({ ...state, ...payload }),
		setSelectedSearch: (state, { payload }) => {
			const additional = {};
			if (!payload?.informationId && !payload?.infoId) {
				additional.sidePanelOpen = false;
			}
			if (payload?.isRequirement !== undefined) {
				additional.sidePanelIsRequirement = payload.isRequirement;
			}
			if (payload?.openSidePanel || payload?.source || state.sidePanelOpen) {
				additional.sidePanelOpen = true;
				if (payload?.source) {
					additional.tabToOpen = payload.source;
				} else if (state?.tabToOpen || payload?.tabToOpen) {
					additional.tabToOpen = state?.tabToOpen ? state?.tabToOpen : payload?.tabToOpen;
				} else {
					additional.tabToOpen =
						(payload &&
							"isRequirement" in payload &&
							((payload.isRequirement && SOURCE_TYPES.POSITIONS) || SOURCE_TYPES.COMMENTS)) ||
						SOURCE_TYPES.CATEGORY;
				}
				if (state.subTabToOpen) {
					additional.subTabToOpen = state.subTabToOpen;
				}
			}
			if (payload?.Type !== undefined) {
				additional.sidePanelIsRequirement = payload?.Type ? checkReq(payload) : state.sidePanelIsRequirement;
			}
			return {
				...state,
				selectedSearch: payload,
				currentFocus: "qa",
				sidePanelCommentPage: payload?.commentPage || payload?.pageStart || null,
				sidePanelCommentScroll: payload?.commentScroll || null,
				sidePanelInformationId: payload?.informationId || payload?.infoId || null,
				sidePanelInformationType: payload?.isRequirement || null,
				...additional,
			};
		},
		setDocumentId: (state, { payload }) => ({ ...state, documentId: payload }),
		setSelectedBlock: (state, { payload }) => ({ ...state, selectedBlock: payload }),
		setHoveredBlock: (state, { payload }) => ({ ...state, hoveredBlock: payload }),
		setDocumentInfo: (state, { payload }) => ({ ...state, documentInfo: payload }),
		setTempQuestion: (state, { payload }) => ({ ...state, sidePanelTempQuestion: payload }),
		setCheckedNoDoc: (state, { payload }) => ({ ...state, checkedNoDoc: payload }),
		setSidePanel: (state, { payload }) => {
			const { informationId, sidePanelOpen, tabToOpen, subTabToOpen, page, isRequirement } = payload;
			let newTempComment = state.sidePanelTempComment;
			let newTempQuestion = state.sidePanelTempQuestion;
			if (state.sidePanelInformationId !== informationId) {
				newTempComment = "";
				newTempQuestion = "";
			}
			const additional = {};
			if (!informationId) {
				additional.selectedSearch = null;
			}
			if (subTabToOpen) {
				additional.subTabToOpen = subTabToOpen;
			}
			return {
				...state,
				...additional,
				sidePanelOpen,
				tabToOpen,
				sidePanelInformationId: informationId,
				sidePanelPage: page,
				sidePanelIsRequirement: isRequirement,
				sidePanelTempComment: newTempComment,
				sidePanelTempQuestion: newTempQuestion,
			};
		},
		setInfoType: (state, { payload }) => {
			const { informationId, informationType } = payload;
			return {
				...state,
				updatedInformationId: informationId,
				updatedInformationType: informationType,
				sidePanelIsRequirement: informationType === SENTENCE_TYPE.REQUIREMENT,
			};
		},
		setIsSidePanelOpenByFilter: (state, { payload }) => ({ ...state, isSidePanelOpenByFilter: payload }),
		setIsSidePanelOpenByNavRoute: (state, { payload }) => ({ ...state, isSidePanelOpenByNavRoute: payload }),
		setTabToOpen: (state, { payload }) => ({ ...state, tabToOpen: payload }),
		setSubTabToOpen: (state, { payload }) => ({ ...state, subTabToOpen: payload }),
		setTempComment: (state, { payload }) => ({ ...state, sidePanelTempComment: payload }),
		setCurrentToc: (state, { payload }) => ({ ...state, currentToc: payload }),
		setIsNewNotification: (state, { payload }) => ({ ...state, isNewNotification: payload }),
	},
	extraReducers: (builder) => {
		builder.addCase(getDocumentDetails.fulfilled, (state, action) => {
			const { role } = action.meta.arg;
			const { roles } = action.payload;
			const { mode } = state;
			const newMode = getMode({
				roles,
				role: role || mode || (state.currentFocus === "qa" && ROLES.REVIEWER.mode),
				mode,
			});
			return {
				...state,
				mode: newMode,
				...action.payload,
				checkedNoDoc: false,
			};
		});
		builder.addCase(getDocumentDetails.rejected, (state) => ({
			...state,
			checkedNoDoc: true,
		}));
		builder.addCase(getDocumentPhysicalDetails.fulfilled, (state, action) => {
			const { numberOfPages, pageDimensions, pageHigh, pageWidth, ...rest } = action.payload;
			let pDimensions = pageDimensions;
			if (!isNonEmptyArray(pageDimensions)) {
				pDimensions = Array.from({ length: numberOfPages }).fill({ width: pageWidth, height: pageHigh });
			}
			let accumulatedTop = 0;
			const pagesWithTop = pDimensions.map((page, index) => {
				const pageWithTop = { ...page, top: accumulatedTop, page: index + 1 };
				accumulatedTop += page.height;
				return pageWithTop;
			});
			return {
				...state,
				pageDimensions: pagesWithTop,
				...rest,
			};
		});
		builder.addCase(getDocumentPhysicalDetails.rejected, (state) => ({
			...state,
			checkedNoDoc: true,
		}));
		builder.addCase(getDocumentInformation.fulfilled, (state, action) => {
			const { isFrozen, previousVersionId, previousVersionName, status, totalPages, uploaded } = action.payload;
			return {
				...state,
				isFrozen,
				status,
				previousVersionId,
				previousVersionName,
				numberOfPages: totalPages,
				uploaded,
			};
		});
		builder.addCase(getDocumentInformation.rejected, (state) => ({
			...state,
			checkedNoDoc: true,
		}));
		builder.addCase(getCriticalityList.fulfilled, (state, action) => ({
			...state,
			criticalityList: action.payload,
		}));
		builder.addCase(getNegotiabilityList.fulfilled, (state, action) => ({
			...state,
			negotiabilityList: action.payload,
		}));
		builder.addCase(getReqTypesList.fulfilled, (state, action) => ({
			...state,
			reqTypeList: action.payload,
		}));
		builder.addCase(getVersionStatuses.fulfilled, (state, action) => ({
			...state,
			versionStatuses: action.payload,
		}));
		builder.addCase(getClauses.fulfilled, (state, action) => {
			const globalCounters = {
				yesCounter: 0,
				pendingCounter: 0,
				noCounter: 0,
				newCounter: 0,
			};
			if (Array.isArray(action.payload)) {
				for (const actionData of action.payload) {
					globalCounters.yesCounter += actionData.yesCounter;
					globalCounters.pendingCounter += actionData.pendingCounter;
					globalCounters.noCounter += actionData.noCounter;
					globalCounters.newCounter += actionData.newCounter;
				}
			}
			return { ...state, clauses: action.payload, globalCounters, loadingClauses: false };
		});
		builder.addCase(getClauses.pending, (state) => ({
			...state,
			loadingClauses: true,
		}));
		builder.addCase(getClauses.rejected, (state) => ({
			...state,
			loadingClauses: false,
			clauses: [],
		}));
		builder.addCase(getTransverseInformation.fulfilled, (state, action) => {
			const { contents, totalPages, totalElements, pageNumber, hasMore } = action.payload;
			const { currentSearchIndex, payload, redirection } = action.meta.arg;
			const isThereAnotherFilter = Object.keys(payload).some(
				(key) => key !== "documents" && key !== "Type" && key !== "separator"
			);
			const additional =
				(isThereAnotherFilter && {
					selectedSearch:
						(redirection && {
							...contents[currentSearchIndex || 0],
							openSidePanel: state.sidePanelOpen,
						}) ||
						state.selectedSearch,
					searchData: contents,
					currentSearchIndex:
						redirection && Number.isFinite(currentSearchIndex)
							? currentSearchIndex
							: state.currentSearchIndex || 0,
				}) ||
				{};
			return {
				...state,
				currentFocus: "qa",
				fromQuickAccess: true,
				qaMetadata: { totalPages, totalElements, pageNumber, hasMore },
				temporaryDocument:
					(redirection &&
						Array.isArray(contents) &&
						contents[currentSearchIndex || 0] &&
						contents[currentSearchIndex || 0].documentId !== state.documentId && {
							id: contents[currentSearchIndex || 0].documentId,
							fromQa: true,
						}) ||
					state.temporaryDocument,
				...additional,
			};
		});
		builder.addCase(getTransverseInformation.rejected, (state) => ({
			...state,
			fromQuickAccess: true,
			searchData: [],
			selectedSearch: null,
		}));
		builder.addCase(getLinkTypes.fulfilled, (state, action) => ({
			...state,
			linkTypes: action.payload,
		}));
		builder.addMatcher(
			({ type }) => typeof type === "string" && type.endsWith("rejected"),
			(_, { error }) => {
				console.error(error.message);
			}
		);
	},
});
export {
	getClauses,
	getCriticalityList,
	getDocumentDetails,
	getDocumentInformation,
	getDocumentPhysicalDetails,
	getNegotiabilityList,
	getReqTypesList,
	getTransverseInformation,
	getVersionStatuses,
	getLinkTypes,
};
export const {
	resetSidePanelContent,
	changeSearch,
	goToNextSearch,
	goToPreviousSearch,
	redressCheck,
	redressInfo,
	resetDocument,
	setCheckedNoDoc,
	setCurrentFocus,
	setCurrentSearchIndex,
	setCurrentToc,
	setDocumentId,
	setDocumentInfo,
	setFinalParameters,
	setFromQuickAccess,
	setHideDenyingDialogDisplay,
	setHideFusionDialogDisplay,
	setHoveredBlock,
	setInfoType,
	setIsSelecting,
	setIsSidePanelOpenByFilter,
	setIsSidePanelOpenByNavRoute,
	setMode,
	setPartners,
	setSearchData,
	setSelectedBlock,
	setSelectedRectangles,
	setSelectedSearch,
	setSelectionBarCoordinates,
	setSelectionSidePanelOpen,
	setSelectionType,
	setShiftSections,
	setShowVersioning,
	setSidePanel,
	setTabToOpen,
	setTempComment,
	setTempQuestion,
	setTemporaryDocument,
	updateCaptureFreeze,
	setIsNewNotification,
	setSubTabToOpen,
	setCommentData,
	setSelectedSortAlgo,
	setSortingAlgorithms,
} = srDocumentSlice.actions;
export default srDocumentSlice.reducer;
