import React, { useEffect, useState } from "react";
import styles from "./Resizer.module.css";
import { VerticalDivider } from "../dividers";

const Resizer = ({ className = "", active = true }) => {
	// Position and width
	let x = 0;
	let previousWidth = 0;
	const [previousSide, setPreviousSide] = useState(null);
	const [resize, setResize] = useState(null);

	useEffect(() => {
		if (active && !resize) {
			setResize(document.getElementById("resizable"));
		}
		if (active && resize && !previousSide) {
			setPreviousSide(resize?.previousElementSibling);
		}
		if (!active && resize) {
			setResize(null);
			setPreviousSide(null);
		}
	}, [active, resize, previousSide]);

	useEffect(() => {
		// For resizing after a closeable panel
		if (!active && previousSide?.style) {
			previousSide.style.width = "fit-content";
		}
	}, [previousSide?.style, active]);

	const mouseMoveHandler = (e) => {
		// Size modification
		const dx = e.clientX - x;
		const w = ((previousWidth + dx) * 100) / resize.parentNode.getBoundingClientRect().width;
		previousSide.style.width = `${w}%`;
		document.body.style.cursor = "col-resize";
		document.body.style.userSelect = "none";
		document.body.style.pointerEvents = "none";
	};

	const mouseUpHandler = () => {
		// Remove the handlers and style
		resize.style.removeProperty("cursor");
		document.body.style.removeProperty("cursor");
		document.body.style.removeProperty("user-select");
		document.body.style.removeProperty("pointer-events");
		document.removeEventListener("mousemove", mouseMoveHandler);
		document.removeEventListener("mouseup", mouseUpHandler);
	};

	const mouseDownHandler = (e) => {
		// Get the current mouse position
		x = e.clientX;
		previousWidth = previousSide?.getBoundingClientRect().width;
		// Attach the listeners to `document`
		document.addEventListener("mousemove", mouseMoveHandler);
		document.addEventListener("mouseup", mouseUpHandler);
	};

	useEffect(() => {
		if (active && resize && previousSide) {
			resize?.addEventListener("mousedown", mouseDownHandler);
		}
	});
	return (
		<>
			{active ? (
				<div className={`${styles.resizer__horizontal} ${className}`} id="resizable" />
			) : (
				<VerticalDivider />
			)}
		</>
	);
};
export default Resizer;
