import { FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	CategorySelectionList,
	CategoryTemplateSelectionList,
	CustomButton,
	CustomDialog,
	I18nTranslate,
	icon,
	IconComponent,
	LimitedTextField,
} from "../..";
import styles from "./CategoryDialog.module.css";
import { Flags, SegFlags, isFeatureEnabled, isSegFeatureEnabled, translate } from "../../../providers";
import { setDisableKeyEvents } from "../../../slice";
import AddAutomaticCategorizationSidePanel from "./AddAutomaticCategorizationSidePanel";
import SearchCardTable from "./SearchCardTable";
import { isNonEmptyArray } from "../../../utils";

export default function CategoryDialog({
	availableParents,
	category,
	isNew,
	isTemplate = false,
	onClose,
	onSubmit,
	onGetAutomations,
	open,
	parentCategory,
	onLaunch,
	onGetFilters,
	onUpdateFilters,
}) {
	const [tempCategory, setTempCategory] = useState(null);
	const [tempParent, setTempParent] = useState(null);
	const [openWarningDialog, setOpenWarningDialog] = useState(false);
	const [parent, setParent] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [openSidePanel, setOpenSidePanel] = useState(false);
	const [warningTranslationKey, setWarningTranslationKey] = useState("");
	const [tempSearchCards, setTempSearchCards] = useState([]);
	const [currentSearchCards, setCurrentSearchCards] = useState([]);
	const [searchCardId, setSearchCardId] = useState(null);
	const [searchCardName, setSearchCardName] = useState(null);
	const [defaultFilters, setDefaultFilters] = useState({});
	const [automationIdsToDelete, setAutomationIdsToDelete] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [focusName, setFocusName] = useState(false);
	const projectId = useSelector(({ context }) => context.project?.id);
	useEffect(
		() => {
			if (!category?.id || isNew) {
				return null;
			}
			if (onGetAutomations?.getAutomations && typeof onGetAutomations.getAutomations === "function") {
				onGetAutomations
					.getAutomations({ thematicId: category.id })
					.then((data) => setCurrentSearchCards(data))
					.catch(console.error)
					.finally(() => setIsEdit(false));
			}
			return () => {
				onGetAutomations?.cancelGetAutomations();
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isNew, category, isEdit]
	);
	const dispatch = useDispatch();
	useEffect(() => {
		setTempCategory({ ...category, parentId: parentCategory?.id });
		setParent(parentCategory);
	}, [category, parentCategory]);
	const handleChangeName = (name) => {
		setTempCategory((prev) => ({ ...prev, name: name.split("\n").join("") }));
	};
	const handleChangeDescription = (text) => {
		setTempCategory((prev) => ({ ...prev, description: text }));
	};
	const handleChangeParent = (newParent) => {
		setTempCategory((prev) => ({ ...prev, parentId: (newParent && newParent.id) || null }));
		setTempParent(newParent);
		if (newParent?.id === parentCategory?.id) {
			setWarningTranslationKey("");
		} else if (newParent?.id) {
			if (parentCategory?.id) {
				setWarningTranslationKey("common:categories.category-dialog.warning.both");
			} else {
				setWarningTranslationKey("common:categories.category-dialog.warning.new-parent");
			}
		} else {
			setWarningTranslationKey("common:categories.category-dialog.warning.old-parent");
		}
	};
	const handleResetState = () => {
		setTempCategory(null);
		setTempParent(null);
		setParent(null);
		setTempSearchCards([]);
		setCurrentSearchCards([]);
		setDefaultFilters({});
		setAutomationIdsToDelete([]);
	};
	const handleInitiateClose = () => {
		setOpenWarningDialog(true);
	};
	const handleClose = () => {
		setWarningTranslationKey("");
		onClose();
		handleResetState();
		setOpenWarningDialog(false);
	};
	const handleCreation = () => {
		setSearchCardId(null);
		setSearchCardName(null);
		setOpenSidePanel(true);
	};
	const handleCloseCreation = () => {
		setDefaultFilters({});
		setOpenSidePanel(false);
	};

	const handleOpenDialog = () => {
		setOpenDialog((prev) => !prev);
	};
	const handleValidateCategory = () => {
		setParent(tempParent);
		handleOpenDialog();
	};

	const handleOpenEdit = (row) => {
		setOpenSidePanel(true);
		if (row?.searchId) {
			setSearchCardId(row?.searchId);
			setSearchCardName(row?.searchName);
		} else {
			setDefaultFilters(row);
		}
	};
	const handleSearchCards = (newsc) => {
		const { id, tempId, ...payload } = newsc;
		if (id && onUpdateFilters && typeof onUpdateFilters === "function") {
			const parameters = (isTemplate && { searchTemplateId: id }) || { searchCardId: id };
			if (!isTemplate && isNonEmptyArray(payload?.filters?.keywordFilters?.filters)) {
				payload.filters.keywordFilters.filters = payload.filters.keywordFilters.filters.filter((f) =>
					isNonEmptyArray(f.keywords)
				);
			}
			if (isTemplate && isNonEmptyArray(payload?.filters?.keywordFilters?.filters)) {
				payload.filters.keywordFilters.filters = payload.filters.keywordFilters.filters.filter((f) =>
					isNonEmptyArray(f.keywords)
				);
			}
			onUpdateFilters(parameters, payload)
				.then(() => handleCloseCreation())
				.catch(console.error);
		} else if (tempId || tempId === 0) {
			setTempSearchCards((prev) => prev.map((tsc, index) => (index === tempId && { ...tsc, ...payload }) || tsc));
		} else {
			setTempSearchCards((prev) => [...prev, newsc]);
		}
	};
	const handleSubmit = () => {
		onSubmit(
			tempCategory,
			warningTranslationKey !== "",
			parent,
			() => setWarningTranslationKey(""),
			tempSearchCards,
			automationIdsToDelete
		);
		handleResetState();
	};
	const handleDelete = (row) => {
		if (row?.tempId || row.tempId === 0) {
			setTempSearchCards((prev) => prev.filter((tsc, index) => row.tempId !== index));
		} else {
			setAutomationIdsToDelete((prev) => [...prev, row.actionId]);
			setCurrentSearchCards((prev) => prev.filter((csc) => csc.actionId !== row.actionId));
		}
	};
	const handleFocusCatName = () => {
		dispatch(setDisableKeyEvents(true));
		setFocusName(true);
	};
	const handleLeaveFocusName = () => {
		dispatch(setDisableKeyEvents(false));
		setFocusName(false);
	};

	return (
		<CustomDialog
			disableBackdropClick
			disableEscapeKeyDown
			isForm
			className={styles.dialog}
			open={open}
			submitDisabled={!tempCategory || !tempCategory.name}
			title={
				isNew
					? translate("common:categories.category-dialog.title.new")
					: translate("common:categories.category-dialog.title.update")
			}
			onClose={handleInitiateClose}
			onSubmit={handleSubmit}
		>
			<FormControl className={styles.dialog__form} data-testid="new.category.form">
				<div className={styles.nameContainer} data-is-focused={focusName}>
					<LimitedTextField
						fullWidth
						multiline
						containerClassName={styles.dialog__nameTextfield}
						data-testid="new.category.form.name"
						label={`${translate("common:categories.category-dialog.name-textfield.label")}*`}
						maxLength={80}
						size="sm"
						value={tempCategory?.name}
						onBlur={handleLeaveFocusName}
						onChange={handleChangeName}
						onFocus={handleFocusCatName}
					/>
				</div>
				<TextField
					fullWidth
					InputLabelProps={{ shrink: !!parent?.name }}
					inputProps={{ className: styles.dialog__categorySelectionInput, readOnly: true }}
					label={translate("common:categories.category-dialog.parent-textfield.label")}
					size="small"
					value={parent?.name}
					variant="outlined"
					onClick={handleOpenDialog}
				/>
				{!isTemplate && warningTranslationKey && !isNew && (
					<div className={styles.dialog__warning}>
						<IconComponent className={styles["dialog__warning--large"]} icon={icon.faExclamationTriangle} />
						<I18nTranslate
							className={styles["dialog__warning--small"]}
							param={{
								categoryName: category?.name,
								oldParentName: parentCategory?.name,
								newParentName: parent?.name,
							}}
							translationKey={warningTranslationKey}
						/>
					</div>
				)}
				<LimitedTextField
					fullWidth
					multiline
					containerClassName={styles.dialog__descTextfield}
					label={translate("common:categories.category-dialog.description-textfield.label")}
					maxLength={80}
					size="sm"
					value={tempCategory?.description || ""}
					variant="outlined"
					onBlur={() => dispatch(setDisableKeyEvents(false))}
					onChange={handleChangeDescription}
					onFocus={() => dispatch(setDisableKeyEvents(true))}
				/>
			</FormControl>
			{isFeatureEnabled(Flags.ACTION_AUTO) && (isTemplate || isSegFeatureEnabled(SegFlags.AUTOMATION)) && (
				<>
					<div>
						<div className={styles.automationForm__header}>
							<span>{translate("common:categories.category-dialog.subtitle.automation")}</span>
							<CustomButton
								color="primary"
								disabled={isNew && !tempCategory?.name}
								variant="contained"
								onClick={handleCreation}
							>
								{translate("common:btn.create")}
							</CustomButton>
						</div>
					</div>
					<div>
						{isNonEmptyArray([...currentSearchCards, ...tempSearchCards]) && (
							<SearchCardTable
								isTemplate={isTemplate}
								searchCards={currentSearchCards}
								tempSearchCards={tempSearchCards.map((tsc, index) => ({ ...tsc, tempId: index }))}
								onDelete={handleDelete}
								onEdit={handleOpenEdit}
								onLaunch={onLaunch}
							/>
						)}
					</div>
				</>
			)}
			<CustomDialog
				isForm
				open={openDialog}
				submitLabel={translate("common:btn.validate")}
				title={translate("common:categories.category-dialog.parent-textfield.label")}
				onClose={handleOpenDialog}
				onSubmit={handleValidateCategory}
			>
				{isTemplate ? (
					<CategoryTemplateSelectionList
						allowDefaultChanges
						allowPreExpand
						includeNone
						isEditable
						returnCatOnSelection
						singleSelection
						availableParents={availableParents}
						canSelectAll={false}
						defaultCheckedIds={[parent?.id]}
						projectId={projectId}
						onChangeCheckedIds={handleChangeParent}
					/>
				) : (
					<CategorySelectionList
						allowDefaultChanges
						allowPreExpand
						includeNone
						isEditable
						returnCatOnSelection
						singleSelection
						availableParents={availableParents}
						defaultCheckedIds={[parent?.id]}
						projectId={projectId}
						onChangeCheckedIds={handleChangeParent}
					/>
				)}
			</CustomDialog>
			<AddAutomaticCategorizationSidePanel
				categoryName={tempCategory?.name}
				defaultFilters={defaultFilters}
				isNew={isNew}
				isTemplate={isTemplate}
				open={openSidePanel}
				searchCardId={searchCardId}
				searchCardName={searchCardName}
				setIsEdit={setIsEdit}
				onClose={handleCloseCreation}
				onGetFilters={onGetFilters}
				onSubmit={handleSearchCards}
			/>
			<CustomDialog
				data-testid="confirm.delete.dialog"
				iconColor="var(--color-red)"
				iconModel={icon.faExclamationTriangle}
				open={openWarningDialog}
				submitLabel={translate("common:btn.confirm")}
				subTitle={
					isNew
						? translate("common:categories.close-warning.create.sub-title")
						: translate("common:categories.close-warning.edit.sub-title")
				}
				onClose={() => setOpenWarningDialog(false)}
				onSubmit={handleClose}
			/>
		</CustomDialog>
	);
}
