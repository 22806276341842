import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../../../../common/providers";
import { CustomTooltip, icon, IconComponent } from "../../../../../../common/components";
import { setHoveredBlock } from "../../../../slice/document/document-slice";
import styles from "./DocumentVersionSignalMessage.module.css";

export default function DocumentVersionSignalMessage({ data, isNew, onHoverSignal, showVersioning }) {
	const dispatch = useDispatch();
	const roles = useSelector(({ srDocument }) => srDocument?.roles);
	const hasRole = useMemo(() => roles.length > 0, [roles]);
	const handleEnter = () => {
		dispatch(setHoveredBlock(data));
		onHoverSignal();
	};
	const detailBlockClassName = isNew ? styles.detailBlock__isNew : styles.detailBlock__notNew;
	return (
		<>
			{hasRole && showVersioning && data?.lastStatus === "UNREVIEWED" && (
				<CustomTooltip
					showWhenOverflow
					title={
						isNew
							? translate("smart-review.version-band.new")
							: translate("smart-review.version-band.modified")
					}
				>
					<div className={`${styles.detailBlock} ${detailBlockClassName}`} onMouseEnter={handleEnter}>
						<IconComponent
							className={styles.statusIcon}
							color={isNew ? "var(--color-white)" : "var(--color-red)"}
							icon={isNew ? icon.faPlusCircle : icon.faExclamationTriangle}
							size="2xl"
						/>
						{isNew
							? translate("smart-review.version-band.new")
							: translate("smart-review.version-band.modified")}
					</div>
				</CustomTooltip>
			)}
		</>
	);
}
