import client from "../client/client";
import { ContextProvider } from "../../common/providers";

class PublicService {
	login({ email, password }, cancelToken) {
		return client.post("login", {
			payload: {
				username: email,
				password,
			},
			config: { cancelToken },
		});
	}

	logout(cancelToken) {
		ContextProvider.logout();
		return client.post("/public/logout", { config: { cancelToken } });
	}

	refreshToken(cancelToken) {
		return client.post("/public/refresh", {
			config: {
				cancelToken,
			},
		});
	}

	setPassword({ newPassword, token }, cancelToken) {
		return client.put("public/users/me/set-password", {
			payload: {
				newPassword,
				token,
			},
			config: { cancelToken },
		});
	}

	forgotPassword({ email }, cancelToken) {
		return client.put("public/users/me/password-reset-request", { payload: { email }, config: { cancelToken } });
	}

	resetPassword({ newPassword, token }, cancelToken) {
		return client.put("public/users/me/reset-password", {
			payload: {
				newPassword,
				token,
			},
			config: { cancelToken },
		});
	}

	joinProject({ invitationToken }, cancelToken) {
		return client.post("/public/projects/join", { payload: { value: invitationToken }, config: { cancelToken } });
	}

	register({ token, firstname, lastname, passWord }, cancelToken) {
		return client.post("/public/users/register", {
			payload: { token, firstname, lastname, passWord },
			config: { cancelToken },
		});
	}

	downloadTerms(cancelToken) {
		return client.get("/public/terms", {
			config: {
				responseType: "blob",
				Accept: "*/*",
				cancelToken,
			},
		});
	}
}

export default new PublicService();
