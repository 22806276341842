import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-virtualized/styles.css";
import "./index.css";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme } from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";
import KeycloakService from "./services/KeycloakService";
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";
import superstore from "./store";
import NotSupported from "./views/not-supported/NotSupported";
import TokenRefreshComponent from "./services/TokenRefreshComponent";

const theme = createTheme({
	palette: {
		primary: {
			main: "rgb(56, 128, 232)",
		},
		secondary: {
			main: "rgb(224, 85, 56)",
		},
	},
	fontFamily: ["Roboto", "sans-serif", "-apple-system", "Arial"].join(","),
});
export const muiCache = createCache({
	key: "mui",
	prepend: true,
});

export function isChromium(navigator) {
	const brands = navigator.userAgentData?.brands;
	if (!brands) {
		return false;
	}
	return brands.some((brand) => brand.brand === "Chromium" && brand.version > 89);
}
const isSupported = isChromium(window.navigator);

const renderApp = () => {
	if (isSupported || window.env.REACT_APP_ENV === "dev" || window.env.REACT_APP_ENV === "qa") {
		ReactDOM.render(
			<Provider store={superstore.store}>
				<TokenRefreshComponent />
				<BrowserRouter>
					<PersistGate
						loader={
							<div style={{ margin: "auto" }}>
								<CircularProgress color="primary" />
							</div>
						}
						persistor={superstore.persistor}
					>
						<CacheProvider value={muiCache}>
							<ThemeProvider theme={theme}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<Root />
								</LocalizationProvider>
							</ThemeProvider>
						</CacheProvider>
					</PersistGate>
				</BrowserRouter>
			</Provider>,
			document.getElementById("root")
		);
	} else {
		ReactDOM.render(<NotSupported />, document.getElementById("root"));
	}
};

KeycloakService.initKeycloak(renderApp);

serviceWorker.unregister();
