import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Tab, Tabs } from "@mui/material";
import { useParams } from "react-router-dom";
import { AnalyticsProvider, Flags, Permissions, SegFlags, translate } from "../../common/providers";
import {
	BlockTitle,
	createNotification,
	CustomTooltip,
	icon,
	IconComponent,
	ViewBanner,
} from "../../common/components";
import styles from "./ConsistencyCheck.module.css";
import { Documents } from "./components";
import { ApiService, ControlService, DocumentService, RedressmentService } from "../../api";
import tabs from "./tabs/tabs";
import RedressToc from "./components/redress/RedressToc";
import { exportView } from "../../navigation";

function StyledTabs({ children, onChange, value }) {
	return (
		<Tabs
			classes={{ root: styles.StyledTabs__root, indicator: styles.StyledTabs__indicator }}
			textColor="primary"
			value={value}
			variant="fullWidth"
			onChange={onChange}
		>
			{children}
		</Tabs>
	);
}

function TabPanel({ children, index, value }) {
	return (
		<div hidden={value !== index} style={{ display: "flex", height: "100%", overflow: "hidden" }}>
			{value === index && children}
		</div>
	);
}

const REDRESS_ACTION = ["LEFT", "RIGHT"];

const ConsistencyCheck = () => {
	const [documentName, setDocumentName] = useState("");
	const [selectedDocument, setSelectedDocument] = useState(null);
	const [documentsLoading, setDocumentsLoading] = useState(false);
	const [tocErrorsLoading, setTocErrorsLoading] = useState(false);
	const [tocLoading, setTocLoading] = useState(false);
	const [contentErrorsLoading, setContentErrorsLoading] = useState(false);
	const [documents, setDocuments] = useState([]);
	const [tocErrors, setTocErrors] = useState([]);
	const [contentErrors, setContentErrors] = useState([]);
	const [value, setValue] = useState(0);
	const [tocList, setTocList] = useState([]);
	const [moveLoading] = useState(false);
	const [movingToc, setMovingToc] = useState(false);
	const [showErrorLog, setShowErrorLog] = useState(true);
	const [showAllErrors, setShowAllErrors] = useState(true);
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(25);
	const [showLeftPanel, setShowLeftPanel] = useState(true);
	const cancelTokenSourceRef = useRef(null);
	const updateCancelTokenSourceRef = useRef(null);
	const params = useParams();
	const projectId = useMemo(() => params.projectId, [params.projectId]);

	const handleSelectDocument = (document) => {
		if (document) {
			setSelectedDocument(document);
			setDocumentName(document.docName);
			setTocList(document.tocList || null);
		}
	};
	const getDocuments = useCallback(() => {
		if (!Array.isArray(documents) || documents.length === 0) {
			setDocumentsLoading(true);
			ControlService.getErrorCountersByProject({ projectId }, cancelTokenSourceRef.current.token)
				.then((data) => {
					setDocuments(data);
					if (!selectedDocument) {
						handleSelectDocument(data[0]);
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => setDocumentsLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectId, selectedDocument]);
	const getContentErrorsByDocument = useCallback(
		({ documentId }, cancelTokenSource) => {
			setContentErrorsLoading(true);
			ControlService.getContentErrorsByDocument({ documentId }, { page, limit }, cancelTokenSource.token)
				.then((data) => {
					setContentErrors(data);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => setContentErrorsLoading(false));
		},
		[limit, page]
	);
	const getTocErrorsByDocument = useCallback(
		({ documentId }, cancelTokenSource) => {
			setTocErrorsLoading(true);
			ControlService.getTocErrorsByDocument({ documentId }, { page, limit }, cancelTokenSource.token)
				.then((data) => {
					setTocErrors(data);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => setTocErrorsLoading(false));
		},
		[limit, page]
	);
	const getTocListByDocument = useCallback(() => {
		if (!selectedDocument || selectedDocument.tocList) {
			return;
		}
		setTocLoading(true);
		DocumentService.getDocumentTocList(
			{
				docId: selectedDocument.docId,
			},
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				selectedDocument.tocList = data;
				setTocList(selectedDocument.tocList);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => setTocLoading(false));
	}, [selectedDocument]);

	useEffect(() => {
		const documentTitle = translate("consistency-check.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Verification of detections" });
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		updateCancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
			ApiService.cancelTokens(updateCancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		getDocuments();
	}, [getDocuments]);
	useEffect(() => {
		if (selectedDocument) {
			getContentErrorsByDocument({ documentId: selectedDocument.docId }, updateCancelTokenSourceRef.current);
			getTocErrorsByDocument({ documentId: selectedDocument.docId }, updateCancelTokenSourceRef.current);
			getTocListByDocument();
		}
	}, [selectedDocument, page, limit, getContentErrorsByDocument, getTocErrorsByDocument, getTocListByDocument]);

	const getDocumentError = ({ documentId }) => {
		ControlService.getErrorCountersByDocument({ documentId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				const newDocuments = [...documents];
				const index = newDocuments.findIndex((d) => d.docId === documentId);
				if (index !== -1) {
					newDocuments[index] = data;
				}
				setDocuments(newDocuments);
				setSelectedDocument(data);
			})
			.catch((err) => console.error(err));
	};
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const showErrorsLog = (document) => {
		setShowErrorLog(true);
		setSelectedDocument(document);
	};
	const showTocRedressment = (selDocument) => {
		handleSelectDocument(document);
		setShowErrorLog(false);
		setSelectedDocument(selDocument);
	};
	const rollbackMove = (selectedToc, action) => {
		if (action === REDRESS_ACTION[1]) {
			--selectedToc.level;
		} else {
			++selectedToc.level;
		}
		const { level } = selectedToc;
		const tmpTocList = tocList;
		let foundNode = tmpTocList.find((t) => t.id === selectedToc.id);
		const findNode = (parents, id) => {
			if (!Array.isArray(parents)) {
				return;
			}
			for (const parent of parents) {
				if (parent.id === id) {
					foundNode = parent;
					break;
				}
				findNode(parent.subClauses, id);
			}
		};
		if (!foundNode) {
			findNode(tmpTocList, selectedToc.id);
		}
		foundNode.level = level;
		setMovingToc(false);
		setTocList(tmpTocList);
	};
	const handleMove = (action, selectedToc) => {
		if (selectedToc.level < 1 && action === REDRESS_ACTION[0]) {
			return;
		}
		const payload = {
			id: selectedToc.id,
			type: "CLAUSE",
			action: action || REDRESS_ACTION[0],
		};
		createNotification({ message: translate("consistency-check.errors.notification.info-processing") });
		setMovingToc(true);
		RedressmentService.move({ docId: selectedDocument.docId }, payload, cancelTokenSourceRef.current.token)
			.then(() => {
				setMovingToc(false);
				createNotification({
					type: "success",
					message: translate("consistency-check.errors.notification.success-message"),
				});
			})
			.catch((err) => {
				console.error(err);
				rollbackMove(selectedToc, action);
			});
	};
	const handleShowAllErrors = () => setShowAllErrors((prev) => !prev);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setLimit(+event.target.value);
		setPage(0);
	};
	const handleDisplayLeftPanel = () => setShowLeftPanel((prev) => !prev);
	return (
		<>
			<ViewBanner
				titles={[
					{ title: translate("navigation:project.review"), key: "review" },
					{ title: translate("navigation:project.verification-of-detections") },
				]}
			/>
			<div className={styles.container}>
				{showLeftPanel && (
					<div className={styles["left-panel"]}>
						<Documents
							documents={documents}
							isLoading={documentsLoading}
							projectId={projectId}
							selectedDocument={selectedDocument}
							showErrorLog={showErrorsLog}
							showTocRedressment={showTocRedressment}
							onClickRow={handleSelectDocument}
						/>
					</div>
				)}
				<Button className={styles["collapse-button"]} onClick={handleDisplayLeftPanel}>
					<IconComponent icon={showLeftPanel ? icon.faChevronLeft : icon.faChevronRight} />
				</Button>
				{showErrorLog && (
					<div className={styles["right-panel"]}>
						<CustomTooltip title={selectedDocument?.docFilename || ""}>
							<BlockTitle autoTranslate="no">
								{translate("consistency-check.consistency-check.log-error-title", {
									documentName: documentName ? `(${documentName})` : "",
								})}
							</BlockTitle>
						</CustomTooltip>
						<>
							<StyledTabs textColor="primary" value={value} variant="fullWidth" onChange={handleChange}>
								{tabs.map((i, index) => {
									const key = index;
									return (
										<Tab
											key={key}
											label={i.tab({
												contentCounter:
													(selectedDocument && selectedDocument.contentsCounter) || 0,

												tocCounter: (selectedDocument && selectedDocument.tocsCounter) || 0,
											})}
										/>
									);
								})}
							</StyledTabs>
							{tabs.map((i, index) => {
								const key = index;
								return (
									<TabPanel key={key} index={index} value={value}>
										{i.panel({
											documents,
											tocErrors,
											contentErrors,
											tocErrorsLoading,
											contentErrorsLoading,
											selectedDocument,
											projectId,
											tocList,
											moveLoading,
											showTocRedressment,
											showAllErrors,
											showOnlyResolvedErrors: handleShowAllErrors,
											getTocErrorsByDocument,
											getContentErrorsByDocument,
											getDocumentError,
											handleChangeRowsPerPage,
											handleChangePage,
											page,
											limit,
											getDocuments,
										})}
									</TabPanel>
								);
							})}
						</>
					</div>
				)}
				{!showErrorLog && (
					<div className={styles["right-panel"]}>
						<CustomTooltip title={selectedDocument.docFilename}>
							<BlockTitle autoTranslate="no">
								{translate("consistency-check.consistency-check.toc-redressment-title", {
									documentName: documentName ? `(${documentName})` : "",
								})}
							</BlockTitle>
						</CustomTooltip>
						<span className={styles["redress-header"]}>
							{translate("consistency-check.consistency-check.redress-header")}
						</span>
						<RedressToc
							handleMove={handleMove}
							loading={moveLoading || tocLoading}
							maxMode={!showLeftPanel}
							movingToc={movingToc}
							REDRESS_ACTION={REDRESS_ACTION}
							tocList={tocList}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default exportView({
	path: "/projects/:projectId/review/verification-of-detections/*",
	localesPath: "/consistency-check/locales",
	component: ConsistencyCheck,
	flag: Flags.CONTROL,
	segFlag: SegFlags.VERIFICATION_OF_DETECTIONS,
	role: Permissions.PROJECT_LEADER,
	companyRole: [Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER],
});
