import React, { useMemo, useRef, useState, useEffect } from "react";
import { CustomSwitch, OptionsPanelContainer, SearchInput } from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import { ApiService, PackageManagementService } from "../../../../../../../api";
import Box from "../../../../box/Box";
import { isEqual, isNonEmptyArray, mapTree, searchInTree } from "../../../../../../../common/utils";
import styles from "./CompanyList.module.css";

export default function CompanyList({ id, onUpdateRow, platformCompanies }) {
	const cancelTokenSourceRef = useRef();
	const [companies, setCompanies] = useState([]);
	const [tempCompanies, setTempCompanies] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [show, setShow] = useState(false);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		PackageManagementService.getActivePackageCompanies({ packageId: id }, cancelTokenSourceRef.current.token).then(
			(data) => {
				setCompanies(data);
				setTempCompanies(data);
			}
		);
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [id]);
	const handleCheck = (data, checked) => {
		if (checked) {
			setTempCompanies((prev) => prev.filter((tcp) => tcp.id !== data.id));
		} else {
			const foundedBranch = searchInTree(platformCompanies, data.id);
			setTempCompanies((prev) => [...prev, foundedBranch]);
		}
	};
	const handleReset = () => {
		setTempCompanies(companies);
	};
	const handleChangeSearch = (e) => {
		setSearchInput(e.target.value);
	};
	const handleClearSearch = () => {
		setSearchInput("");
	};
	const handleSave = () => {
		const toAdd = tempCompanies.filter((tc) => !companies.some((c) => c.id === tc.id)).map((ta) => ta.id);
		const toRemove = companies.filter((tc) => !tempCompanies.some((c) => c.id === tc.id)).map((ta) => ta.id);
		PackageManagementService.updateActivePackageCompanies(
			{ packageId: id },
			{
				toAdd: isNonEmptyArray(toAdd) ? toAdd : undefined,
				toRemove: isNonEmptyArray(toRemove) ? toRemove : undefined,
			},
			cancelTokenSourceRef.current.token
		).then(() => {
			setCompanies(tempCompanies);
			onUpdateRow(id, "companies", tempCompanies.length);
		});
	};
	const hasDiff = useMemo(
		() =>
			isEqual(
				mapTree(companies, (x) => x.id),
				mapTree(tempCompanies, (x) => x.id)
			),
		[companies, tempCompanies]
	);
	const renderCompanyRows = (c) => (
		<React.Fragment key={c.id}>
			<Box
				key={c.id}
				checked={tempCompanies.some((tcp) => tcp.id === c.id)}
				content={translate("dsi.package-management.panel.companies.description", {
					id: c.id,
					type: c.type,
				})}
				data={c}
				title={c.companyName}
				onCheck={handleCheck}
			/>
			{Array.isArray(c.children) && c.children.map((childCompany) => renderCompanyRows(childCompany))}
		</React.Fragment>
	);
	const filterCompanies = (tree, searchQuery) => {
		if (!searchQuery) {
			return tree;
		}

		return tree.filter((company) => {
			const matchesCompany =
				company.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
				company.type.toLowerCase().includes(searchQuery.toLowerCase());
			const matchesChildren =
				Array.isArray(company.children) &&
				company.children.some((childCompany) =>
					childCompany.companyName.toLowerCase().includes(searchQuery.toLowerCase())
				);
			return matchesCompany || matchesChildren;
		});
	};

	return (
		<OptionsPanelContainer
			buttonText={translate("common:btn.save")}
			buttonTextSecondary={translate("common:btn.cancel")}
			disabledPrimaryButton={hasDiff}
			disabledSecondaryButton={hasDiff}
			onClick={handleSave}
			onClickSecondary={handleReset}
		>
			<div className={styles.container}>
				<div>
					<SearchInput
						fullWidth
						disabled={platformCompanies.length === 0}
						placeholder={translate("common:btn.search")}
						value={searchInput}
						onChange={handleChangeSearch}
						onClearSearch={handleClearSearch}
					/>
				</div>
				<div>
					<span>
						{translate("dsi.package-management.panel.companies.selected-count", {
							count: tempCompanies.length,
						})}
					</span>
					<span className={styles.switch}>
						<CustomSwitch checked={show} color="primary" onChange={() => setShow((prev) => !prev)} />
						{translate("common:list.show-selected")}
					</span>
				</div>
				{filterCompanies(platformCompanies, searchInput).map((company) => renderCompanyRows(company))}
			</div>
		</OptionsPanelContainer>
	);
}
