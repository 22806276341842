import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InformationBox, SidePanel, SidePanelContent } from "../../../../common/components";
import styles from "./SourceSidePanel.module.css";
import { ChatProjectService } from "../../../../api";
import { useApi } from "../../../../common/hooks";
import { translate } from "../../../../common/providers";

const SourceSidePanel = ({ open, onClose, selectedChat, idChatAnswer }) => {
	const { call: onGetAnswerChatSources } = useApi(ChatProjectService.getAnswerChatSources);
	const projectId = useSelector(({ context }) => context.project?.id);
	const [loading, setLoading] = useState(false);
	const [sources, setSources] = useState([]);
	useEffect(() => {
		if (projectId && selectedChat && idChatAnswer && open) {
			setLoading(true);
			onGetAnswerChatSources({ projectId, chatId: selectedChat, messageId: idChatAnswer })
				.then((data) => {
					setSources(data);
				})
				.catch((error) => {
					console.error("Error fetching sources:", error);
					if (error.response) {
						console.error("Response data:", error.response.data);
					}
				})
				.finally(() => setLoading(false));
		}
	}, [projectId, selectedChat, onGetAnswerChatSources, idChatAnswer, open]);
	return (
		<SidePanel open={open} size={65} onClose={onClose}>
			{({ onActive }) => (
				<SidePanelContent
					className={styles.sidePanel}
					title={translate("chat-project.sources")}
					onActive={onActive}
					onClose={onClose}
				>
					<>
						{loading && <div>{translate("chat-project.loading")}</div>}
						{!loading &&
							sources.map((source) => (
								<InformationBox key={source.informationId} buttonSourceDisplay information={source} />
							))}
					</>
				</SidePanelContent>
			)}
		</SidePanel>
	);
};

export default SourceSidePanel;
