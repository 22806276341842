import React from "react";
import styles from "./IdentifiedContentBox.module.css";
import {
	CustomIconButton,
	CustomTooltip,
	Hierarchy,
	VerticalDivider,
	createNotification,
	icon,
} from "../../../../common/components";
import { translate, translateDate } from "../../../../common/providers";
import { CollabService, ContentService, DocumentService } from "../../../../api";
import { useApi } from "../../../../common/hooks";
import { downloadFile } from "../../../../common/utils";

const IdentifiedContentBox = ({ identifiedContent, onDisplay }) => {
	const { call: onGetStringContent } = useApi(CollabService.getStringContent);
	const { call: onDownloadDocument } = useApi(DocumentService.downloadDocument);
	const { call: onGetContentCoordinatesFromInformation } = useApi(
		ContentService.getContentCoordinatesFromInformation
	);
	const requestContentString = () => {
		if (identifiedContent) {
			onGetStringContent({ infoId: identifiedContent.informationId })
				.then((data) => {
					navigator.clipboard.writeText(data.value);
					createNotification({
						type: "info",
						message: translate("multi-project.identified-content-box.notification.content-copied"),
					});
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};
	const handleDownload = () => {
		onDownloadDocument({ docId: identifiedContent.documentId })
			.then(({ data, filename }) => downloadFile({ data, filename, filetype: "zip" }))
			.catch((err) => console.error(err));
	};
	const handleClickDisplay = () => {
		const { informationId, documentId, pageStart, projectId } = identifiedContent;
		onGetContentCoordinatesFromInformation({ docId: documentId, informationId })
			.then((data) => onDisplay(informationId, projectId, documentId, pageStart, data))
			.catch((err) => console.error(err));
	};
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<div className={styles.header_leftSideInfo}>
					<CustomTooltip showWhenOverflow title={identifiedContent.projectName}>
						<div className={styles.projectName}>{identifiedContent.projectName}</div>
					</CustomTooltip>
					<VerticalDivider className={styles.divider} />
					<div>{translateDate(identifiedContent.created).replaceAll(".", "/")}</div>
					<VerticalDivider className={styles.divider} />
					<div>
						{translate("multi-project-search.identified-content-box.pages", {
							ammountOfPages: identifiedContent.pageStart,
						})}
					</div>
					<VerticalDivider className={styles.divider} />
					<div className={styles.heararchyContainer}>
						<Hierarchy
							enableFirstLocationCut
							isDangerousSetInnerHTML
							breadcrumbClassName={styles.breadcrumb}
							charsKeptInEnd={6}
							className={styles.breadcrumbContainer}
							documentName={identifiedContent.documentName}
							hierarchy={identifiedContent.location}
							openOnHover={false}
						/>
					</div>
				</div>
				<div className={styles.header_rightSideInfo}>
					<CustomIconButton
						btnClassName={styles.button}
						icon={icon.faCopy}
						iconClassName={styles.iconClassName}
						tooltip={translate("multi-project-search.identified-content-box.button.copy")}
						variant="outlined"
						onClick={requestContentString}
					/>
					<VerticalDivider className={styles["divider--wide"]} />
					<CustomIconButton
						btnClassName={styles.button}
						icon={icon.faFile}
						iconClassName={styles.iconClassName}
						tooltip={translate("common:btn.display")}
						variant="outlined"
						onClick={handleClickDisplay}
					/>
					<CustomIconButton
						btnClassName={styles.button}
						icon={icon.faDownload}
						iconClassName={styles.iconClassName}
						tooltip={translate("common:component.preview.button.download")}
						variant="outlined"
						onClick={handleDownload}
					/>
				</div>
			</div>
			<div>{identifiedContent.content}</div>
		</div>
	);
};

export default IdentifiedContentBox;
