import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService, ProjectCreationService } from "../../../../../api";
import {
	CustomDialog,
	I18nTranslateEnumCheckEmailError,
	icon,
	MultipleEmailInput,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import { loadProjectCreation } from "../../../slice/newProjectSlice";
import styles from "./AddNewMembersDialog.module.css";

export default function AddNewMembersDialog({ open = false, onClose = null, onSubmit = null, prefilledEmail = "" }) {
	const [emailsObjects, setEmailsObjects] = useState([]);
	const cancelTokenSourceRef = useRef(null);
	const [errors, setErrors] = useState([]);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const projectCreation = useSelector((state) => state.newProject.projectCreation);
	const dispatch = useDispatch();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleAddToProject = () => {
		const payload = emailsObjects.filter((eo) => eo.valid).map((eo) => ({ email: eo.email }));
		ProjectCreationService.addNewUser(
			{ projectId: projectCreation.project.id },
			payload,
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				dispatch(loadProjectCreation({ token: cancelTokenSourceRef.current.token }));
				setEmailsObjects([]);
				setConfirmDialogOpen(false);
				setErrors([]);
				onSubmit();
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleClearEmails = () => {
		setEmailsObjects([]);
		setErrors([]);
	};
	const handleAddEmails = (emailsToBeAdded) => {
		ProjectCreationService.checkEmails(
			{ projectId: projectCreation.project.id },
			{ emails: emailsToBeAdded },
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				const newErrors = [...errors];
				data.forEach((d) => {
					if (!d.valid) {
						newErrors.push({
							email: d.userEmail,
							text: (
								<I18nTranslateEnumCheckEmailError
									autoTranslate="no"
									param={{ email: d.userEmail }}
									value={d.preventionReason}
								/>
							),
						});
					}
				});
				setErrors(newErrors);
				setEmailsObjects([...emailsObjects, ...data.map((e) => ({ email: e.userEmail, valid: e.valid }))]);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleDeleteEmail = (emailToDelete) => {
		setEmailsObjects(emailsObjects.filter((e) => e.email !== emailToDelete));
		setErrors(errors.filter((e) => e.email !== emailToDelete));
	};
	const handleSubmit = () => {
		if (errors.length > 0) {
			setConfirmDialogOpen(true);
		} else {
			handleAddToProject();
		}
	};
	const handleClose = () => {
		setEmailsObjects([]);
		onClose();
	};

	const handleCloseConfirmDialog = () => {
		setConfirmDialogOpen(false);
	};
	return (
		<>
			<CustomDialog
				isForm
				open={open && !confirmDialogOpen}
				submitDisabled={emailsObjects.length === 0 || emailsObjects.length === errors.length}
				submitLabel={translate("new-project.add-member.add-new-member-dialog.submit")}
				title={translate("new-project.add-member.add-new-member-dialog.title")}
				onClose={handleClose}
				onSubmit={handleSubmit}
			>
				<div className={styles.dialog__content}>
					<MultipleEmailInput
						emailsObjects={emailsObjects}
						errors={errors.map((e) => e.text)}
						prefilledEmail={prefilledEmail}
						onAddEmails={handleAddEmails}
						onClearEmails={handleClearEmails}
						onDeleteEmail={handleDeleteEmail}
					/>
				</div>
			</CustomDialog>
			<CustomDialog
				iconColor="var(--color-red)"
				iconModel={icon.faExclamationTriangle}
				open={open && confirmDialogOpen}
				subTitle={translate("new-project.add-member.add-new-member-confirm-dialog.text")}
				title={translate("new-project.add-member.add-new-member-confirm-dialog.title")}
				onClose={handleCloseConfirmDialog}
				onSubmit={handleAddToProject}
			/>
		</>
	);
}
