import React from "react";
import styles from "./ProjectTitle.module.css";
import { CustomButton, CustomTooltip } from "../../../../common/components";
import { translate } from "../../../../common/providers";

const ProjectTitle = ({ onChangeCompany, projectName = "", selectedIndex }) => (
	<div className={styles.projectTitle}>
		<CustomTooltip showWhenOverflow title={projectName}>
			<span className={styles.projectTitle__text}>{projectName}</span>
		</CustomTooltip>
		<CustomButton data-testid="project.open" variant="outlined" onClick={() => onChangeCompany(selectedIndex)}>
			{translate("dashboards.project-req-review.content.open-project")}
		</CustomButton>
	</div>
);

export default ProjectTitle;
