import React, { useState } from "react";
import { DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox } from "@mui/material";
import {
	CustomButton,
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableRow,
	GenericDialog,
	icon,
} from "../../../../common/components";
import styles from "./ConfirmImportDialog.module.css";
import { translate } from "../../../../common/providers";

const ConfirmImportDialog = ({ open = false, onClose, onConfirm, template = [], eraseOption = false }) => {
	const [shouldErase, setShouldErase] = useState(false);

	const handleOnConfirm = () => {
		onConfirm(shouldErase);
		setShouldErase(false);
	};
	const handleChangeErase = () => setShouldErase((prev) => !prev);
	const handleOnClose = () => {
		onClose();
		setShouldErase(false);
	};

	return (
		<GenericDialog disableBackdropClick fullWidth open={open} onClose={handleOnClose}>
			<DialogTitle>
				<div className={styles.dialog__title}>
					<span className={styles["dialog__title-text"]}>{translate("deliverable.import.confirm")}</span>
					<CustomIconButton icon={icon.faTimes} onClick={onClose} />
				</div>
			</DialogTitle>
			<DialogContent dividers className={styles.dialog__content}>
				{eraseOption ? (
					<FormControlLabel
						control={
							<Checkbox
								checked={shouldErase}
								color="primary"
								name="shouldErase"
								onChange={handleChangeErase}
							/>
						}
						label={translate("deliverable.import.label.erase")}
					/>
				) : (
					<span>{translate("deliverable.import.confirm.warning")}</span>
				)}
				<br />
				<span>
					<u style={{ textTransform: "uppercase" }}>
						<strong>{translate("deliverable.import.confirm.headers.required")}</strong>
					</u>
					{translate("deliverable.import.confirm.headers.order")}
				</span>
				<CustomTable className={styles.template__table}>
					<CustomTableBody>
						<CustomTableRow>
							{template.map((s) => (
								<CustomTableCell key={s} className={styles.template__cell}>
									{s}
								</CustomTableCell>
							))}
						</CustomTableRow>
					</CustomTableBody>
				</CustomTable>
			</DialogContent>
			<DialogActions>
				<CustomButton color="primary" variant="outlined" onClick={handleOnClose}>
					{translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton color="primary" variant="contained" onClick={handleOnConfirm}>
					{translate("common:btn.confirm")}
				</CustomButton>
			</DialogActions>
		</GenericDialog>
	);
};

export default ConfirmImportDialog;
