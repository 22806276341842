import React from "react";
import { I18nTranslate, icon } from "../../../../common/components";
import { CommentsRightPanel, QuestionsRightPanel, SearchRightPanel } from ".";
import { SegFlags } from "../../../../common/providers";

const tabs = [
	{
		index: 0,
		icon: icon.faClipboardCheck,
		name: "smart-review.right-panel.menu.information-details",
		component: null,
	},
	{
		index: 1,
		icon: icon.faComments,
		name: "smart-review.right-panel.menu.comments",
		component: () => <CommentsRightPanel />,
	},
	{
		index: 2,
		icon: icon.faCheckDouble,
		name: "smart-review.right-panel.menu.position-comments",
		component: () => <CommentsRightPanel isValidationComments />,
		segFlag: SegFlags.PROJECT_REQUIREMENTS,
	},
	{
		index: 3,
		icon: icon.faHandPaper,
		name: "smart-review.right-panel.menu.questions",
		component: () => <QuestionsRightPanel />,
		segFlag: SegFlags.QUESTION_ANSWER,
	},
	{
		index: 4,
		icon: icon.faSearch,
		content: <I18nTranslate autoTranslate="no" translationKey="smart-review.right-panel.menu.search" />,
		component: () => <SearchRightPanel />,
	},
];

export default tabs;
