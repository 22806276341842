import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { IconComponent, BlockTitle, icon } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import UserDetailsTable from "./user-details-table/UserDetailsTable";
import styles from "./UserDetails.module.css";

function StyledAccordionDetails({ children }) {
	return <AccordionDetails classes={{ root: styles.StyledAccordionDetails__root }}>{children}</AccordionDetails>;
}

export default function UserDetails({ user }) {
	return (
		<div className={styles.container}>
			<BlockTitle>{translate("admin.user-details.title.user-details")}</BlockTitle>
			{!user && (
				<div className={styles.empty}>
					<span>{translate("admin.user-details.no-user-selected")}</span>
				</div>
			)}
			{user && (
				<div>
					<div className={styles.details}>
						<span translate="no">{user.displayName}</span>
						<span translate="no">{user.email}</span>
					</div>
					<Accordion>
						<AccordionSummary expandIcon={<IconComponent icon={icon.faChevronDown} />}>
							{translate("admin.user-details.operations")}
						</AccordionSummary>
						<StyledAccordionDetails>
							{Array.isArray(user.projects) && user.projects.length > 0 ? (
								<UserDetailsTable projects={user.projects} />
							) : (
								<span className={styles.emptyDetails}>{translate("common:empty-state.none")}</span>
							)}
						</StyledAccordionDetails>
					</Accordion>
				</div>
			)}
		</div>
	);
}
