import React, { useEffect, useState, useMemo } from "react";
import { FormControlLabel, MenuItem, Radio, RadioGroup, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { CustomDialog, icon, IconComponent } from "../../../../../common/components";
import styles from "./SaveSearchDialog.module.css";
import {
	Flags,
	isFeatureEnabled,
	isSegFeatureEnabled,
	Permissions,
	SegFlags,
	translate,
} from "../../../../../common/providers";
import { VISIBILITY, VISIBILITY_FOR_COPY } from "../../../../../common/constants";
import { checkValidity } from "../../../../../navigation";

export default function SaveSearchDialog({
	id = 0,
	creatorId = 0,
	description,
	isEditting = false,
	isMakingCopy = false,
	isUpdating = false,
	isForMySearch = false,
	title,
	onClose,
	onSubmit,
	open,
	visibility,
}) {
	const [isUpdatingSearch, setIsUpdatingSearch] = useState(0);
	const [titleInput, setTitleInput] = useState("");
	const [descriptionInput, setDescriptionInput] = useState("");
	const [visibilityInput, setVisibilityInput] = useState(visibility);
	const companyName = useSelector(({ context }) => context.company.name);
	const visibilityInputs = useMemo(() => {
		if (isMakingCopy) {
			if (
				!checkValidity({
					role: [Permissions.PROJECT_MANAGER, Permissions.PROJECT_LEADER],
					segFlag: SegFlags.AUTOMATION,
				})
			) {
				delete VISIBILITY_FOR_COPY.AUTOMATION;
			}
			return VISIBILITY_FOR_COPY;
		}
		return VISIBILITY;
	}, [isMakingCopy]);
	useEffect(
		() => () => {
			if (open) {
				setIsUpdatingSearch(+isUpdating);
				setTitleInput(title ?? "");
				setDescriptionInput(description ?? "");
				if (!isForMySearch) {
					setVisibilityInput(visibility ?? visibilityInputs.PRIVATE);
				}
			}
		},
		[open, title, isUpdating, description, visibility, visibilityInputs, isForMySearch]
	);
	useEffect(() => setIsUpdatingSearch(+isUpdating), [isUpdating]);
	useEffect(() => setTitleInput(title ?? ""), [title]);
	useEffect(() => setDescriptionInput(description ?? ""), [description]);
	useEffect(() => {
		if (!isForMySearch) {
			setVisibilityInput(visibility ?? visibilityInputs.PRIVATE);
		}
	}, [visibility, visibilityInputs, isForMySearch]);
	useEffect(() => {
		if (isMakingCopy && isForMySearch) {
			setTitleInput(
				(prev) => `${prev} (${translate("smart-search.dialogs.save-search-dialog.title.copy")?.toLowerCase()})`
			);
			setVisibilityInput(visibility);
		}
	}, [isMakingCopy, visibility, isForMySearch]);

	const handleChangeUpdating = (e) => {
		const isUpdatingSearchValue = +e.target.value;
		setIsUpdatingSearch(isUpdatingSearchValue);
		if (!isUpdatingSearchValue) {
			setTitleInput(
				(prev) => `${prev} (${translate("smart-search.dialogs.save-search-dialog.title.copy")?.toLowerCase()})`
			);
		}
	};
	const handleChangeTitle = (e) => setTitleInput(e.target.value);
	const handleChangeDescription = (e) => setDescriptionInput(e.target.value);
	const handleChangeVisibility = (e) => setVisibilityInput(e.target.value);
	const handleSubmit = () => {
		onSubmit({
			isUpdating: isMakingCopy ? false : isUpdatingSearch,
			id,
			creatorId,
			title: titleInput,
			description: descriptionInput,
			visibility: isForMySearch || isMakingCopy ? visibilityInput : VISIBILITY_FOR_COPY.AUTOMATION,
		});
		onClose();
	};
	const dialogTitle = useMemo(() => {
		if (isEditting) {
			return translate("smart-search.dialogs.save-search-dialog.title.edit");
		}
		if (isMakingCopy) {
			return translate("smart-search.dialogs.save-search-dialog.title.copy-search");
		}
		return translate("smart-search.dialogs.save-search-dialog.title.save");
	}, [isEditting, isMakingCopy]);

	const hasErrors = useMemo(() => !titleInput || !visibilityInput, [titleInput, visibilityInput]);
	return (
		<CustomDialog
			displayCloseCross
			isForm
			open={open}
			submitDisabled={hasErrors}
			submitLabel={translate("common:btn.save")}
			title={dialogTitle}
			onClose={onClose}
			onSubmit={handleSubmit}
		>
			{isUpdating && !isMakingCopy && (
				<div className={styles.content__radios}>
					<RadioGroup value={isUpdatingSearch} onChange={handleChangeUpdating}>
						<FormControlLabel
							classes={{ label: styles.radios__label }}
							control={<Radio color="primary" />}
							label={translate("smart-search.dialogs.save-search-dialog.radios.save-changes", {
								title,
							})}
							value={1}
						/>
						<FormControlLabel
							classes={{ label: styles.radios__label }}
							control={<Radio color="primary" />}
							label={translate("smart-search.dialogs.save-search-dialog.radios.create-new")}
							value={0}
						/>
					</RadioGroup>
				</div>
			)}
			{(!isUpdatingSearch || isMakingCopy) && (
				<div className={styles.content__inputs}>
					<TextField
						color="primary"
						helperText={translate("smart-search.dialogs.save-search-dialog.input.name.helper")}
						inputProps={({ type: "text", maxLength: 250 }, { "data-testid": "sc.create.name" })}
						placeholder={`${translate("smart-search.dialogs.save-search-dialog.input.name")}*`}
						size="small"
						value={titleInput}
						variant="outlined"
						onChange={handleChangeTitle}
					/>
					<TextField
						color="primary"
						helperText={translate("smart-search.dialogs.save-search-dialog.input.description.helper")}
						inputProps={({ type: "text", maxLength: 1000 }, { "data-testid": "sc.create.description" })}
						placeholder={`${translate("smart-search.dialogs.save-search-dialog.input.description")}`}
						size="small"
						value={descriptionInput}
						variant="outlined"
						onChange={handleChangeDescription}
					/>
					{(isForMySearch || isMakingCopy) && (
						<TextField
							select
							color="primary"
							helperText={translate("smart-search.dialogs.save-search-dialog.input.visibility.helper")}
							placeholder={translate("smart-search.dialogs.save-search-dialog.input.visibility")}
							size="small"
							value={visibilityInput}
							variant="outlined"
							onChange={handleChangeVisibility}
						>
							{Object.entries(visibilityInputs)
								.filter(
									(entry) =>
										(!isFeatureEnabled(Flags.PARTNERS) && entry[0] === visibilityInputs.PRIVATE) ||
										isFeatureEnabled(Flags.PARTNERS)
								)
								.map(([key, value]) => {
									if (key === "PROJECT" && !isSegFeatureEnabled(SegFlags.PARTNERS)) {
										return null;
									}
									return (
										<MenuItem key={key} value={value}>
											{translate(`smart-search.enum.visibility.${key?.toLowerCase()}`, {
												companyName,
											})}
										</MenuItem>
									);
								})}
						</TextField>
					)}
					{!isForMySearch && !isMakingCopy && (
						<div className={styles.infoBox}>
							<IconComponent
								className={styles.infoIcon}
								color="var(--color-blue)"
								icon={icon.faInfoCircle}
								size="lg"
							/>
							{translate("smart-search.dialogs.save-search-dialog.visible-manager-leader")}
						</div>
					)}
				</div>
			)}
		</CustomDialog>
	);
}
