export const SOURCE_TYPES = {
	DASHBOARD: "dashboard",
	DOC_CENTER: "doc-center",
	CANVAS: "canvas",
	TASKS: "tasks",
	COMMENTS: "comments",
	QUESTIONS: "questions",
	POSITIONS: "positions",
	CATEGORY: "category",
	DELIVERABLES: "deliverables",
	VERSIONING: "versioning",
	SIMILAR_CONTENT: "similar-content",
	LINK_BETWEEN_CONTENTS: "links",
	HISTORY: "history",
	MENTION: "mention",
	QA: "qa",
};
