import React, { useCallback, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { CustomDialog } from "../..";
import { translate } from "../../../providers";
import styles from "./SingleFieldDialog.module.css";

export default function SingleFieldDialog({
	cancelLabel = "",
	label = "",
	onClose,
	onSubmit,
	open,
	submitLabel = "",
	title,
	value = "",
}) {
	const [error, setError] = useState(false);
	const [payload, setPayload] = useState(value);

	useEffect(() => {
		setPayload(value);
		setError(false);
	}, [value]);
	const handleCloseAndSubmit = useCallback(() => {
		const err = !payload;
		if (!err) {
			onSubmit(payload);
			onClose();
		}
		setError(err);
	}, [onClose, onSubmit, payload]);
	useEffect(() => {
		const handleKeyUp = (e) => {
			if (open && e.key === "Enter" && payload) {
				handleCloseAndSubmit();
			}
		};
		document.addEventListener("keyup", handleKeyUp);
		return () => {
			document.removeEventListener("keyup", handleKeyUp);
		};
	}, [open, payload, handleCloseAndSubmit]);
	const handleChange = (e) => {
		const newValue = e.target.value;
		setPayload(newValue);
		setError(!newValue);
	};
	const handleReset = () => {
		setError(false);
		setPayload(value);
	};

	return (
		<CustomDialog
			displayCloseCross
			isForm
			closeLabel={cancelLabel || translate("common:btn.cancel")}
			open={open}
			submitDisabled={!payload}
			submitLabel={submitLabel || translate("common:btn.submit")}
			title={title}
			onClose={onClose}
			onExited={handleReset}
			onSubmit={handleCloseAndSubmit}
		>
			<TextField
				autoFocus
				fullWidth
				required
				className={styles.textField}
				error={error}
				helperText={error && "Required"}
				label={label}
				size="small"
				value={payload || ""}
				variant="outlined"
				onChange={handleChange}
			/>
		</CustomDialog>
	);
}
