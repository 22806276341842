import client from "../client/client";

class ChatProjectService {
	askNewQuestion({ projectId }, { filter, value, summary = null }, cancelToken) {
		return client.post(`/projectchat/projects/${projectId}/chats/message`, {
			payload: { filter: { ...filter }, value, summary },
			config: { cancelToken },
		});
	}

	continueAsking({ projectId, chatId }, { filter, value, summary = null }, cancelToken) {
		return client.post(`/projectchat/projects/${projectId}/chats/${chatId}/message`, {
			payload: { filter: { ...filter }, value, summary },
			config: { cancelToken },
		});
	}

	getAllChats({ projectId }, { page = 0, limit = 15 }, cancelToken) {
		return client.get(`/projectchat/projects/${projectId}/chats`, {
			config: { cancelToken },
			params: { page, limit },
		});
	}

	deleteChat({ projectId, chatId }, cancelToken) {
		return client.delete(`/projectchat/projects/${projectId}/chats/${chatId}`, {
			config: { cancelToken },
		});
	}

	editChatName({ projectId, chatId }, { value }, cancelToken) {
		return client.put(`/projectchat/projects/${projectId}/chats/${chatId}`, {
			payload: { value },
			config: { cancelToken },
		});
	}

	getChat({ projectId, chatId }, { page = 0, limit = 15 }, cancelToken) {
		return client.get(`/projectchat/projects/${projectId}/chats/${chatId}`, {
			config: { cancelToken },
			params: { page, limit },
		});
	}

	getAnswerChatSources({ projectId, chatId, messageId }, cancelToken) {
		return client.get(`/projectchat/projects/${projectId}/chats/${chatId}/messages/${messageId}`, {
			config: { cancelToken },
		});
	}
}

export default new ChatProjectService();
