import React, { Fragment } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import versioningData from "./versioning.json";
import versioningTmpData from "./versioning-tmp.json";
import { translate } from "../../providers";
import styles from "./VersioningDialog.module.css";
import { CustomButton } from "../buttons";
import { capitalize } from "../../utils";

const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

const displayVersionContent = (title, list) => (
	<div className={styles.versionContent}>
		<strong>{title}</strong>
		{list.map((content, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<div key={index} className={styles.changeItem}>
				{content}
			</div>
		))}
	</div>
);

const VersioningDialog = ({ handleClose, open = false }) => {
	const renderVersioningContent = () => {
		const showTmp =
			versioningTmpData &&
			Object.values(versioningTmpData).some((value) => Array.isArray(value) && value.length > 0);
		const tmp =
			(versioningTmpData &&
				showTmp &&
				Object.entries(versioningTmpData)
					.filter(([, value]) => Array.isArray(value))
					.map(([key, value]) => displayVersionContent(capitalize(key), value))) ||
			[];
		return (
			<>
				<div className={styles.content}>
					{showTmp && (
						<div className={styles.versionItem}>
							<div className={styles.versionNumber}>Next version</div>
							{tmp.map((t, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<Fragment key={index}>{t}</Fragment>
							))}
						</div>
					)}
					{Array.isArray(versioningData.changelog) &&
						versioningData.changelog.map(({ version, date, content }) => (
							<div key={version} className={styles.versionItem}>
								<div className={styles.versionNumber}>{version}</div>
								<div className={styles.versionDate}>
									{new Date(date).toLocaleDateString(undefined, options)}
								</div>
								{Array.isArray(content.features) && displayVersionContent("Features", content.features)}
								{Array.isArray(content.updates) && displayVersionContent("Updates", content.updates)}
								{Array.isArray(content.fixes) && displayVersionContent("Fixes", content.fixes)}
							</div>
						))}
				</div>
			</>
		);
	};

	return (
		<Dialog
			fullWidth
			aria-labelledby="form-dialog-title"
			classes={{ paper: styles.dialogPaper }}
			maxWidth="lg"
			open={open}
			onClose={handleClose}
		>
			<DialogTitle classes={{ root: styles.dialogTitle }} id="form-dialog-title">
				Versioning
			</DialogTitle>
			<DialogContent>{renderVersioningContent()}</DialogContent>
			<DialogActions>
				<CustomButton color="primary" onClick={handleClose}>
					{translate("common:btn.close")}
				</CustomButton>
			</DialogActions>
		</Dialog>
	);
};

export default VersioningDialog;
