import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ProjectDirectoryService } from "../../../../../../api";
import { CircularLoader, icon, IconComponent } from "../../../../../../common/components";
import { useApi } from "../../../../../../common/hooks";
import { translate, translateDate } from "../../../../../../common/providers";
import styles from "./FolderInformation.module.css";

const rows = [
	{ label: "document-center.side-panel.information.folder.name", valueRenderer: ({ label }) => label },
	{ label: "document-center.side-panel.information.folder.location", valueRenderer: ({ location }) => location },
	{
		label: "document-center.side-panel.information.folder.content",
		valueRenderer: ({ numberOfFolders, numberOfDocuments }) =>
			translate("document-center.side-panel.information.folder.content.value", {
				numberOfFolders,
				numberOfDocuments,
			}),
	},
	{ label: "document-center.side-panel.information.folder.pages", valueRenderer: ({ totalPages }) => totalPages },
	{ label: "document-center.side-panel.information.folder.reqs", valueRenderer: ({ totalReqs }) => totalReqs },
	{
		label: "document-center.side-panel.information.folder.created",
		valueRenderer: ({ created }) => translateDate(created),
	},
	{ label: "document-center.side-panel.information.folder.created-by", valueRenderer: ({ creator }) => creator },
];

export default function FolderInformation({ directoryId }) {
	const projectId = useSelector(({ context }) => context.project.id);
	const { call, cancel, isLoading } = useApi(ProjectDirectoryService.getInformation);
	const [information, setInformation] = useState(null);

	useEffect(() => {
		if (!directoryId) {
			return null;
		}
		call({ projectId, directoryId }).then(setInformation).catch(console.error);
		return () => {
			cancel();
		};
	}, [projectId, directoryId, call, cancel]);

	return (
		<div className={styles.folder__container}>
			<div className={styles.folder__information}>
				{(!isLoading &&
					information &&
					rows.map((row) => (
						<div key={row.label} className={styles.folder__row}>
							<span className={styles.row__label}>{translate(row.label)}</span>
							<span className={styles.row__value}>{row.valueRenderer(information) || "-"}</span>
						</div>
					))) || <CircularLoader />}
			</div>
			<div className={styles.folder__iconContainer}>
				<IconComponent className={styles.folder__icon} color="var(--color-light-grey-2)" icon={icon.faFolder} />
			</div>
		</div>
	);
}
