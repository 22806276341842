import React from "react";
import { getFilteredDataAttributes } from "../../utils";
import styles from "./BlockTitle.module.css";

const BlockTitle = React.forwardRef(
	({ autoTranslate = "yes", children, onMouseOver = null, onMouseLeave = null, onFocus, ...dataAttributes }, ref) => {
		const filteredDataAttributes = getFilteredDataAttributes(dataAttributes);
		return (
			<div
				ref={ref}
				className={styles.blockTitle}
				translate={autoTranslate}
				onFocus={onFocus}
				onMouseLeave={onMouseLeave}
				onMouseOver={onMouseOver}
				{...filteredDataAttributes}
			>
				{children}
			</div>
		);
	}
);

export default BlockTitle;
