import React, { useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "@mui/material";
import { translate } from "../../../../../../../common/providers";
import { SOURCE_TYPES } from "../../../../../../../common/constants";
import styles from "./AdditionalOptions.module.css";
import TABS from "../../tabs";
import { CustomButton, Hierarchy, ViewTabPanel, ViewTabs } from "../../../../../../../common/components";
import { SENTENCE_TYPE } from "../../../../../constants/constants";

const debounceFunc = debounce((func) => func(), 100);

const AdditionalOptions = ({ information, selectedMode, myDocInfo }) => {
	const [selectedTab, setSelectedTab] = useState("comments");
	const [hide, setHide] = useState(true);
	const [isContentOverflowing, setIsContentOverflowing] = useState(false);
	const [informationTextHeight, setInformationTextHeight] = useState(false);
	const textRef = useRef(null);
	const informationRef = useRef(null);
	const tabHeaders = useMemo(
		() => [
			{
				id: SOURCE_TYPES.POSITIONS,
				label: translate("common:filters.select-filter.menus.positions"),
				disabled: false,
				tab: "PositionsTab",
			},
			{
				id: SOURCE_TYPES.COMMENTS,
				label: translate("smart-review.cmp-details.option-panel.comments"),
				tab: "CommentsTab",
			},
			{
				id: SOURCE_TYPES.CATEGORY,
				label: translate("smart-review.cmp-details.option-panel.categories"),
				disabled: false,
				tab: "CategoriesTab",
			},
			{
				id: SOURCE_TYPES.QUESTIONS,
				label: translate("smart-review.cmp-details.option-panel.questions"),
				disabled: false,
				tab: "QuestionsTab",
			},
		],
		[]
	);
	const handleContentOverflow = () => {
		setIsContentOverflowing(textRef.current?.scrollHeight > textRef.current?.offsetHeight);
		debounceFunc(() => {
			setInformationTextHeight(informationRef.current?.offsetHeight);
		});
	};
	useEffect(() => {
		setInformationTextHeight(informationRef.current?.offsetHeight);
	}, [hide]);
	useEffect(() => {
		handleContentOverflow();
	}, []);
	useEffect(() => {
		window.addEventListener("resize", handleContentOverflow);
		return () => {
			window.removeEventListener("resize", handleContentOverflow);
		};
	}, []);
	const handleShowMore = () => {
		textRef.current.scrollTo({ top: 0, behavior: "auto" });
		setHide((prev) => !prev);
	};

	return (
		<>
			<div ref={informationRef} className={styles.informationContent}>
				<Hierarchy
					enableFirstLocationCut
					isDangerousSetInnerHTML
					breadcrumbClassName={styles.breadcrumb}
					charsKeptInEnd={6}
					className={styles.breadcrumbContainer}
					documentName={information.documentName}
					hierarchy={information.location}
					openOnHover={false}
				/>
				<div ref={textRef} className={styles.informationContent__text} data-hide={hide}>
					{information.content}
				</div>
				{isContentOverflowing && (
					<div className={styles.informationContent__btnContainer}>
						<CustomButton
							className={styles.informationContent__btn}
							color="secondary"
							variant="outlined"
							onClick={handleShowMore}
						>
							{(hide && translate("smart-review.cmp-details.option-panel.show-more")) ||
								translate("smart-review.cmp-details.option-panel.show-less")}
						</CustomButton>
					</div>
				)}
			</div>
			<div className={styles.panel__content}>
				<ViewTabs
					className={styles.tabs}
					selectedTab={selectedTab}
					tabClassName={styles.tab}
					tabIdentifier="id"
					tabs={tabHeaders.filter((th) => !th.disabled)}
					onChange={(_, tab) => {
						setSelectedTab(tab);
					}}
				/>
				<div
					className={styles.panel__subcontent}
					style={{ height: `calc(100% - ${informationTextHeight}px - 90px)` }}
				>
					{tabHeaders
						.filter((tabHeader) => !tabHeader?.disabled)
						.map((tab) => (
							<ViewTabPanel key={tab.tab} noPadding index={tab.id} value={selectedTab}>
								{TABS[tab.tab]({
									isRequirementAdditional: information.informationType === SENTENCE_TYPE.REQUIREMENT,
									disableEditing: true,
									informationId: information.informationId,
									page: information.pageStart,
									selectedMode,
									myDocInfo,
								})}
							</ViewTabPanel>
						))}
				</div>
			</div>
		</>
	);
};

export default AdditionalOptions;
