import React from "react";
import { DialogTitle, DialogActions, DialogContent } from "@mui/material";
import { connect } from "react-redux";
import { ApiService, TaskService } from "../../../../api";
import { AnalyticsProvider, SegFlags, translate } from "../../../../common/providers";
import styles from "./Tasks.module.css";
import { TasksSidePanel } from "./components";
import TasksTable from "./components/tasks-table/TasksTable";
import { exportView } from "../../../../navigation";
import { CommonFilters, CustomButton, FiltersWrapper, GenericDialog, ViewBanner } from "../../../../common/components";

function processTaskList(list) {
	const taskList = [];
	if (Array.isArray(list)) {
		for (const element of list) {
			for (const task of element.tasks) {
				if (!task.deadLine) {
					task.deadLine = task.deadline;
				}
				taskList.push(
					Object.assign(task, {
						documentId: element.documentId,
						documentName: element.documentName,
					})
				);
			}
		}
	}
	return taskList;
}

const mapStateToProps = ({ context }) => ({
	project: context.project,
	currentUser: context.user,
});

class Tasks extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filters: { owning: false, stakeholding: false },
			taskList: [],
			displaySidePanel: false,
			selectedTask: null,
			openDelete: false,
			toDelete: null,
		};
		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentDidMount() {
		const documentTitle = translate("dashboard.tasks.document.title");
		const { project } = this.props;
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Tasks" });
		TaskService.getAllByProject({ projectId: project.id }, this.cancelTokenSource.token)
			.then((data) => {
				this.setState({
					taskList: (Array.isArray(data) && processTaskList(data)) || [],
				});
			})
			.catch((err) => console.error(err));
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	updateStatus = (taskId) => {
		if (!taskId) {
			return;
		}
		TaskService.nextStep({ taskId }, this.cancelTokenSource.token)
			.then((data) => {
				this.setState((prevState) => ({
					taskList:
						(data &&
							prevState.taskList.map((x) =>
								x.taskId === taskId ? { ...x, taskStatus: data.status } : x
							)) ||
						prevState.taskList,
					selectedTask: { ...prevState.selectedTask, taskStatus: data.status },
				}));
			})
			.catch((err) => {
				console.error(err);
			});
	};

	updatePriority = ({ taskId, priority }) => {
		let newPriority;
		if (priority === "LOW") {
			newPriority = "NORMAL";
		} else if (priority === "NORMAL") {
			newPriority = "HIGH";
		} else {
			newPriority = "LOW";
		}
		TaskService.updateTask({ taskId }, { priority: newPriority }, this.cancelTokenSource.token)
			.then(() => {
				this.setState((prevState) => ({
					taskList: prevState.taskList.map((x) =>
						x.taskId === taskId ? { ...x, priority: newPriority } : x
					),
					selectedTask: { ...prevState.selectedTask, priority: newPriority },
				}));
			})
			.catch((err) => {
				console.error(err);
			});
	};

	updateDescription = ({ taskId, description }) => {
		TaskService.updateTask({ taskId }, { description }, this.cancelTokenSource.token)
			.then(() => {
				this.setState((prevState) => ({
					taskList: prevState.taskList.map((x) => (x.taskId === taskId ? { ...x, description } : x)),
				}));
			})
			.catch((err) => {
				console.error(err);
			});
	};

	updateDeadline = ({ taskId, deadline }) => {
		TaskService.updateTask({ taskId }, { deadline }, this.cancelTokenSource.token)
			.then(() => {
				this.setState((prevState) => ({
					taskList: prevState.taskList.map((x) => (x.taskId === taskId ? { ...x, deadline } : x)),
				}));
			})
			.catch((err) => {
				console.error(err);
			});
	};

	deleteTask = () => {
		const { toDelete } = this.state;
		if (toDelete) {
			TaskService.deleteTask({ taskId: toDelete.taskId }, this.cancelTokenSource.token)
				.then(() => {
					this.setState((prevState) => ({
						taskList: prevState.taskList.filter((x) => x.taskId !== toDelete.taskId),
						toDelete: null,
						openDelete: false,
					}));
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	handleDialogDelete = (data) => {
		this.setState((prevState) => ({ toDelete: data || null, openDelete: !prevState.openDelete }));
	};

	handleApplyFilters = (newFilters) => {
		this.setState({ filters: newFilters });
	};

	handleRowClick = (taskId) => {
		const { taskList } = this.state;
		this.setState((prevState) => ({
			selectedTask: taskList.find((t) => t.taskId === taskId),
			displaySidePanel: !prevState.displaySidePanel,
		}));
	};

	handlePanelClose = () => {
		this.setState({
			displaySidePanel: false,
		});
	};

	render() {
		const { filters, taskList, openDelete, toDelete, displaySidePanel, selectedTask } = this.state;
		const { currentUser, project } = this.props;
		return (
			<div className={styles.container}>
				<ViewBanner
					titles={[
						{ title: translate("navigation:project.dashboard"), key: "dashboard" },
						{ title: translate("navigation:project.tasks") },
					]}
				/>
				<div className={styles.task__filters}>
					<FiltersWrapper
						className={styles.task__filtersWrapper}
						components={[
							{ default: true, enabled: true, component: CommonFilters.STAKE_HOLDING },
							{ default: true, enabled: true, component: CommonFilters.OWNING },
						]}
						defaultFilters={filters}
						onApply={this.handleApplyFilters}
					/>
				</div>
				<div className={styles.task__container}>
					<TasksTable
						currentUser={currentUser}
						projectId={project.id}
						taskList={taskList.filter(
							(task) =>
								(filters.owning && currentUser.email === task.fromUserEmail) ||
								(filters.stakeholding && currentUser.email === task.toUserEmail) ||
								(!filters.owning && !filters.stakeholding)
						)}
						onDialogDelete={this.handleDialogDelete}
						onRowClick={this.handleRowClick}
					/>
					<GenericDialog
						disableBackdropClick
						fullWidth
						open={!!toDelete && openDelete}
						onClose={this.handleDialogDelete}
					>
						<DialogTitle>
							{translate("dashboards.task-dashboard.delete-dialog.title", {
								taskId: toDelete?.taskId || "",
							})}
						</DialogTitle>
						<DialogContent dividers>
							{translate("dashboards.task-dashboard.delete-dialog.confirmation")}
						</DialogContent>
						<DialogActions>
							<CustomButton color="primary" variant="outlined" onClick={this.handleDialogDelete}>
								{translate("common:btn.cancel")}
							</CustomButton>
							<CustomButton color="primary" variant="contained" onClick={this.deleteTask}>
								{translate("common:btn.submit")}
							</CustomButton>
						</DialogActions>
					</GenericDialog>
					<TasksSidePanel
						currentUser={currentUser}
						displaySidePanel={displaySidePanel}
						project={project}
						selectedTask={selectedTask}
						onDialogDelete={this.handleDialogDelete}
						onPanelClose={this.handlePanelClose}
						onUpdateDeadLine={this.updateDeadline}
						onUpdateDescription={this.updateDescription}
						onUpdatePriority={this.updatePriority}
						onUpdateStatus={this.updateStatus}
					/>
				</div>
			</div>
		);
	}
}

export default exportView({
	path: "/projects/:projectId/dashboard/tasks",
	localesPath: "/dashboards/dashboard-project/locales",
	component: connect(mapStateToProps)(Tasks),
	segFlag: [SegFlags.SMART_REVIEW, SegFlags.TASK],
});
