import React, { useEffect, useMemo, useRef, useState } from "react";
import { batch, useSelector, useDispatch } from "react-redux";
import styles from "./SmartSearchFilters.module.css";
import {
	CommonFilters,
	CustomButton,
	CustomDialog,
	CustomSwitch,
	CustomTooltip,
	DocumentFilter,
	InformationIdFilter,
	FiltersWrapper,
	generateFilters,
	icon,
	KeywordFilter,
	ProjectCategoriesFilter,
} from "../../../../common/components";
import { ApiService, SearchCardService } from "../../../../api";
import { CreateTemplateDialog, SaveSearchDialog } from "../dialogs";
import {
	Flags,
	hasPermission,
	isFeatureEnabled,
	isSegFeatureEnabled,
	Permissions,
	SegFlags,
	translate,
} from "../../../../common/providers";
import { isNonEmptyObject } from "../../../../common/utils";

export default function SmartSearchFilters({
	onCountResults,
	onCreateSearch,
	getCriticalityParameters,
	getNegotiabilityParameters,
	getTypeParameters,
	onRemoveNullFilters,
	onSearch,
	onSetFilters,
	onSetIsFilterTocOut,
	onSetIsLoadingSearch,
	onUpdateSearchFilters,
	setTotalResults,
	slice,
}) {
	const [filtersPreviewRequest, setFiltersPreviewRequest] = useState();
	const currentUserId = useSelector(({ context }) => context.user.id);
	const { id, title, description, visibility, creatorId, isNew } = useSelector((state) => state[slice].currentSearch);
	const modified = useSelector((state) => state[slice].modified);
	const hasFilters = useSelector((state) => state[slice].hasFilters);
	const criticalities = useSelector((state) => state[slice].criticalities);
	const negotiabilities = useSelector((state) => state[slice].negotiabilities);
	const types = useSelector((state) => state[slice].types);
	const filters = useSelector((state) => state[slice].filters);
	const limit = useSelector((state) => state[slice].limit);
	const isFilterTocOut = useSelector((state) => state[slice].isFilterTocOut);
	const selectedSortAlgo = useSelector((state) => state[slice].selectedSortAlgo);
	const tokenSourceRef = useRef(null);
	const [isMakingCopy, setIsMakingCopy] = useState(false);
	const [openSaveSearch, setOpenSaveSearch] = useState(false);
	const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
	const [openDisplayTitlesDialog, setOpenDisplayTitlesDialog] = useState(false);
	const projectId = useSelector(({ context }) => context.project?.id);
	const dispatch = useDispatch();
	const isForMySearch = useMemo(() => slice === "mySearch", [slice]);
	useEffect(() => {
		tokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(tokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		batch(() => {
			dispatch(getCriticalityParameters(tokenSourceRef.current.token));
			dispatch(getNegotiabilityParameters(tokenSourceRef.current.token));
			dispatch(getTypeParameters(tokenSourceRef.current.token));
		});
	}, [dispatch, getCriticalityParameters, getNegotiabilityParameters, getTypeParameters]);

	useEffect(() => {
		onCountResults({ token: tokenSourceRef.current.token })
			.then((data) => {
				dispatch(setTotalResults(data.payload));
			})
			.catch(console.error);
	}, [onCountResults, dispatch, setTotalResults]);
	useEffect(
		() => {
			setFiltersPreviewRequest(() => (previewFilters) => {
				const newFilters = generateFilters(previewFilters);
				return SearchCardService.countResults(
					{ projectId },
					{ ...newFilters, isFilterTocOut },
					tokenSourceRef.current.token
				).then((data) => data);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[projectId, isFilterTocOut]
	);
	const handleApply = (payload) => {
		if (tokenSourceRef.current?.token) {
			batch(() => {
				onSetFilters(payload);
				if (isNonEmptyObject(onRemoveNullFilters(payload))) {
					onSetIsLoadingSearch(true);
					onSearch({
						filters: generateFilters(payload),
						page: 0,
						limit,
						sort: selectedSortAlgo,
						token: tokenSourceRef.current.token,
					});
				}
			});
		}
	};
	const handleOpenCreateTemplate = () => setOpenCreateTemplate(true);
	const handleCloseCreateTemplate = () => setOpenCreateTemplate(false);
	const handleOpenCopySearch = () => {
		setOpenSaveSearch(true);
		setIsMakingCopy(true);
	};
	const handleOpenSaveSearch = () => setOpenSaveSearch(true);
	const handleCloseSaveSearch = () => {
		setOpenSaveSearch(false);
		setIsMakingCopy(false);
	};
	const handleSaveSearch = (payload) => {
		const { isUpdating } = payload;
		const tempPayload = { ...payload, description: payload.description === "" ? undefined : payload.description };
		batch(() => {
			if (isUpdating) {
				onUpdateSearchFilters({
					searchCardId: payload.id,
					creatorId: payload.creatorId,
					token: tokenSourceRef.current.token,
				});
			} else {
				onCreateSearch({ ...tempPayload, token: tokenSourceRef.current.token });
			}
		});
	};
	const handleCloseDisplayTitlesDialog = () => {
		setOpenDisplayTitlesDialog(false);
	};
	const handleValidateHideTitles = () => {
		onSetIsLoadingSearch(true);
		onSetIsFilterTocOut(true);
		onSearch({
			page: 0,
			limit,
			sort: selectedSortAlgo,
			filters: generateFilters(filters),
			token: tokenSourceRef.current.token,
		});
		handleCloseDisplayTitlesDialog();
	};
	const handleChangeDisplayTitle = () => {
		if (!isFilterTocOut) {
			setOpenDisplayTitlesDialog(true);
		} else {
			onSetIsLoadingSearch(true);
			onSetIsFilterTocOut(false);
			onSearch({
				page: 0,
				limit,
				sort: selectedSortAlgo,
				filters: generateFilters(filters),
				token: tokenSourceRef.current.token,
			});
		}
	};
	const customLabelApply = (results) => {
		if (results <= 1 && results !== null) {
			return translate("common:component.filter.apply", { results });
		}
		if (results > 1) {
			return translate("common:component.filters.apply", { results });
		}
		return translate("common:btn.apply");
	};
	return (
		<>
			<div className={styles.filters__titleContainer}>
				<div className={styles.filters__information}>
					<div className={styles.filters__titleWrapper}>
						<div>
							<CustomTooltip arrow placement="bottom" title={isNew ? "" : title}>
								<span className={styles.titleWrapper__title}>
									{(isNew && translate("smart-search.drawer.new-search")) || title}
								</span>
							</CustomTooltip>
							{modified && (
								<span className={styles.titleWrapper__notSaved}>
									({translate("common:filters.search.not-saved")})
								</span>
							)}
						</div>
					</div>
					<div className={styles.filters__descriptionWrapper}>
						<span className={styles.filters__description}>
							{(isNew && translate("common:filters.new-search.description")) || description}
						</span>
					</div>
				</div>
				<span className={styles.titleWrapper__displayTitles}>
					<CustomSwitch checked={!isFilterTocOut} onChange={handleChangeDisplayTitle} />
					{translate("smart-search.results.display-titles")}
				</span>
			</div>
			<div className={styles.filters__wrapperContainer}>
				<FiltersWrapper
					hasLargeSearch
					hasSeparator
					multiline
					addFilterButtonVariant="contained"
					className={styles.filters__wrapper}
					components={[
						{
							enabled: true,
							component: CommonFilters.KEYWORDS,
							renderer: <KeywordFilter />,
						},
						{
							enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
							withDescription: false,
							multiSelection: true,
							labelKey: "label",
							valueKey: "key",
							component: CommonFilters.REQUIREMENT,
						},
						{ enabled: true, component: CommonFilters.INFORMATION_ID, renderer: <InformationIdFilter /> },
						{
							enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
							component: CommonFilters.DOCUMENTS,
							renderer: <DocumentFilter />,
							withDescription: false,
						},
						{
							enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
							component: CommonFilters.TOC,
							renderer: <KeywordFilter />,
						},
						{
							enabled: isSegFeatureEnabled(SegFlags.CATEGORY),
							component: CommonFilters.CATEGORIES,
							hasSearch: false,
							renderer: <ProjectCategoriesFilter disableFavoriteCategories />,
							hasFavorites: true,
						},
						{
							enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
							component: CommonFilters.CLIENT_REF,
						},
						{
							enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
							component: CommonFilters.CRITICALITY,
							labelKey: "label",
							valueKey: "value",
							dynamicItems: criticalities?.map((c) => ({
								label: `common:enum.params.criticality.${c.name.toLowerCase()}`,
								value: c.name,
							})),
						},
						{
							enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
							component: CommonFilters.NEGOTIABILITY,
							labelKey: "label",
							valueKey: "value",
							dynamicItems: negotiabilities?.map((n) => ({
								label: `common:enum.params.negotiability.${n.name.toLowerCase()}`,
								value: n.name,
							})),
						},
						{
							enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
							component: CommonFilters.TYPE,
							labelKey: "label",
							valueKey: "value",
							dynamicItems: types?.map((t) => ({
								label: `common:enum.params.type.${t.name.toLowerCase()}`,
								value: t.name,
							})),
						},
					]}
					customLabelApply={customLabelApply}
					defaultFilters={filters}
					previewRequest={filtersPreviewRequest}
					onApply={handleApply}
				/>
				{modified && hasFilters && (
					<>
						<CustomButton
							color="primary"
							startIcon={icon.faSave}
							variant="outlined"
							onClick={handleOpenSaveSearch}
						>
							{translate("smart-search.filters.search.save-search")}
						</CustomButton>
					</>
				)}
				{isFeatureEnabled(Flags.SEARCHTEMPLATE) &&
					!modified &&
					!isNew &&
					(slice === "mySearch" ||
						hasPermission([Permissions.COMPANY_ADMIN, Permissions.COMPANY_MANAGER])) && (
						<CustomButton
							color="primary"
							startIcon={icon.faCodeBranch}
							variant="outlined"
							onClick={handleOpenCreateTemplate}
						>
							{translate("smart-search.filters.search.create-template")}
						</CustomButton>
					)}
				{!modified && !isNew && (
					<CustomButton
						color="primary"
						startIcon={icon.faCopy}
						variant="outlined"
						onClick={handleOpenCopySearch}
					>
						{translate("smart-search.filters.search.copy-search")}
					</CustomButton>
				)}
			</div>
			<SaveSearchDialog
				creatorId={creatorId}
				description={description}
				id={id}
				isForMySearch={isForMySearch}
				isMakingCopy={isMakingCopy}
				isUpdating={!!id && !!creatorId && (slice !== "mySearch" || creatorId === currentUserId)}
				open={openSaveSearch}
				title={title}
				visibility={visibility}
				onClose={handleCloseSaveSearch}
				onSubmit={handleSaveSearch}
			/>
			<CreateTemplateDialog
				hasWarning={!!filters.documents || !!filters.informationIds || !!filters.category}
				isForMySearch={isForMySearch}
				open={openCreateTemplate}
				slice={slice}
				onClose={handleCloseCreateTemplate}
				onSubmit={handleCloseCreateTemplate}
			/>
			<CustomDialog
				iconColor="var(--color-red)"
				iconModel={icon.faExclamationTriangle}
				open={openDisplayTitlesDialog}
				submitLabel={translate("smart-search.results.warning-dialog.confirm")}
				subTitle={translate("smart-search.results.warning-dialog.text")}
				title={translate("smart-search.results.warning-dialog.title")}
				onClose={handleCloseDisplayTitlesDialog}
				onSubmit={handleValidateHideTitles}
			/>
		</>
	);
}
