import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Comments.module.css";
import { Comment } from "..";
import { ApiService, GonogoSynthesisService } from "../../../../../../api";
import {
	InfiniteList2,
	CommentSidePanel,
	HorizontalDivider,
	CustomButton,
	icon,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import { setCommentCount, setOwnComment } from "../../../../slice/gonogo-slice";

export default function Comments({ categoryId }) {
	const cancelTokenSourceRef = useRef(null);
	const [openPanel, setOpenPanel] = useState(false);
	const [myComment, setMyComment] = useState(null);
	const commentCount = useSelector(({ gonogo }) => gonogo.commentCount);
	const dispatch = useDispatch();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (!categoryId) {
			return;
		}
		GonogoSynthesisService.getMyComment({ categoryId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setMyComment(data);
				dispatch(setOwnComment(data));
			})
			.catch((err) => console.error(err));
	}, [categoryId, dispatch]);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	useEffect(() => {
		if (categoryId) {
			setInfiniteListRequest(
				() =>
					({ limit, page }) =>
						GonogoSynthesisService.getOthersComments(
							{ categoryId },
							{ page, limit },
							cancelTokenSourceRef.current.token
						)
							.then((data) => {
								dispatch(setCommentCount(data?.totalElements || 0));
								return data;
							})
							.catch((err) => console.error(err))
			);
		}
	}, [categoryId, dispatch]);
	const handleOpenSidePanel = () => {
		setOpenPanel(true);
	};
	const handleCloseSidePanel = () => {
		setOpenPanel(false);
	};
	const handleUpdateComment = (com) => {
		GonogoSynthesisService.updateComment({ categoryId }, com, cancelTokenSourceRef.current.token)
			.then((data) => {
				if (!myComment) {
					dispatch(setOwnComment(data));
				}
				setMyComment(data);
				handleCloseSidePanel();
			})
			.catch((err) => console.error(err));
	};
	return (
		<div className={styles.commentSection} data-cy="comment-section">
			<div>
				<HorizontalDivider />
				<span className={styles.header}>
					{translate("gonogo.details.comment-synthesis", {
						commentCount: commentCount + ((myComment?.content && 1) || 0),
					})}
				</span>
				<div />
				{!myComment && (
					<CustomButton
						color="secondary"
						startIcon={icon.faPlus}
						variant="outlined"
						onClick={handleOpenSidePanel}
					>
						{translate("gonogo.details.comment-synthesis.add-comment")}
					</CustomButton>
				)}
			</div>
			<Comment owner comment={myComment} onEdit={handleOpenSidePanel} />
			<InfiniteList2
				anticipate
				className={styles.list}
				noRowsText={translate("gonogo.details.comment-synthesis.no-rows")}
				request={infiniteListRequest}
				rowRenderer={(row) => <Comment key={row.id} comment={row} />}
			/>
			<CommentSidePanel
				disableAutocomplete
				data-testid="gonogo.comment.dailog"
				oldComment={myComment?.content}
				open={openPanel}
				optionsPanelContainerTitle={translate("gonogo.details.comment.side-panel.title")}
				placeholder={translate("gonogo.details.comment.side-panel.placeholder")}
				title={
					(myComment?.content?.length > 0 && translate("gonogo.details.comment-synthesis.edit-comment")) ||
					translate("gonogo.details.comment-synthesis.add-comment")
				}
				onAddComment={handleUpdateComment}
				onClose={handleCloseSidePanel}
				onEditComment={handleUpdateComment}
			/>
		</div>
	);
}
