import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	CustomSwitch,
	InfiniteList2,
	OptionsPanelContainer,
	SearchInput,
} from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import { ApiService, FeatureManagementService, PackageManagementService } from "../../../../../../../api";
import Box from "../../../../box/Box";
import { debounce, isEqual, isNonEmptyArray } from "../../../../../../../common/utils";

const debounceFunc = debounce((func) => func(), 500);

export default function FeatureList({ id, onUpdateRow }) {
	const cancelTokenSourceRef = useRef();
	const [featureName, setName] = useState("");

	const [features, setFeatures] = useState([]);
	const [tempFeatures, setTempFeatures] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [show, setShow] = useState(false);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const handleChangeFilters = useCallback((name) => {
		setName(name);
	}, []);
	useEffect(() => {
		debounceFunc(() => handleChangeFilters(searchInput));
	}, [searchInput, handleChangeFilters]);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		PackageManagementService.getPackageFeatures({ packageId: id }, cancelTokenSourceRef.current.token).then(
			(data) => {
				setFeatures(data);
				setTempFeatures(data);
			}
		);
		setInfiniteListRequest(
			() =>
				({ page, limit }) =>
					FeatureManagementService.getAllPlatformFeatures(
						{ page, limit },
						(featureName && featureName.length > 0 && { name: featureName }) || {},
						cancelTokenSourceRef.current.token
					)
		);
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [id, featureName]);
	const handleCheck = (data, checked) => {
		if (checked) {
			setTempFeatures((prev) => prev.filter((tcp) => tcp.id !== data.id));
		} else {
			setTempFeatures((prev) => [...prev, data]);
		}
	};
	const handleReset = () => {
		setTempFeatures(features);
	};
	const handleChangeSearch = (e) => {
		setSearchInput(e.target.value);
	};
	const handleClearSearch = () => {
		setSearchInput("");
	};
	const handleSave = () => {
		const toAdd = tempFeatures.filter((tc) => !features.some((c) => c.id === tc.id)).map((ta) => ta.id);
		const toRemove = features.filter((tc) => !tempFeatures.some((c) => c.id === tc.id)).map((ta) => ta.id);
		PackageManagementService.updatePackageFeatures(
			{ packageId: id },
			{
				toAdd: isNonEmptyArray(toAdd) ? toAdd : undefined,
				toRemove: isNonEmptyArray(toRemove) ? toRemove : undefined,
			},
			cancelTokenSourceRef.current.token
		).then(() => {
			setFeatures(tempFeatures);
			onUpdateRow(id, "features", tempFeatures.length);
		});
	};
	const hasDiff = useMemo(
		() =>
			isEqual(
				features.map((x) => x.id),
				tempFeatures.map((x) => x.id)
			),
		[features, tempFeatures]
	);
	const rowRenderer = (feat) => (
		<Box
			key={feat.id}
			checked={tempFeatures.some((tcp) => tcp.id === feat.id)}
			content={feat.description}
			data={feat}
			data-testid={`feat-id-${feat.id}`}
			id={feat.id}
			title={feat.name}
			onCheck={handleCheck}
		/>
	);

	return (
		<OptionsPanelContainer
			buttonText={translate("common:btn.save")}
			buttonTextSecondary={translate("common:btn.cancel")}
			disabledPrimaryButton={hasDiff}
			disabledSecondaryButton={hasDiff}
			onClick={handleSave}
			onClickSecondary={handleReset}
		>
			<div>
				<div>
					<SearchInput
						fullWidth
						placeholder={translate("common:btn.search")}
						value={searchInput}
						onChange={handleChangeSearch}
						onClearSearch={handleClearSearch}
					/>
				</div>
				<div>
					<span>
						{translate("dsi.package-management.panel.features.selected-count", {
							count: tempFeatures.length,
						})}
					</span>
					<span>
						<CustomSwitch checked={show} color="primary" onChange={() => setShow((prev) => !prev)} />
						{translate("common:list.show-selected")}
					</span>
				</div>
				{!show && <InfiniteList2 request={infiniteListRequest} rowRenderer={rowRenderer} />}
				{show &&
					features
						.filter(
							(f) =>
								(!show && f.name?.toLowerCase().includes(searchInput?.toLowerCase() || "")) ||
								(f.name?.toLowerCase().includes(searchInput?.toLowerCase() || "") &&
									tempFeatures.some((tc) => tc.id === f.id))
						)
						.map((c) => (
							<Box
								key={c.id}
								checked={tempFeatures.some((tcp) => tcp.id === c.id)}
								content={c.description}
								data={c}
								data-testid={`feat-id-${c.id}`}
								title={c.name}
								onCheck={handleCheck}
							/>
						))}
			</div>
		</OptionsPanelContainer>
	);
}
