import React, { useState } from "react";
import { CustomButton, SidePanel, SidePanelContent } from "../../../../../common/components";
import styles from "./NotifySidePanel.module.css";
import { translate } from "../../../../../common/providers";
import { OpportunityCollaborators } from "../../opportunity-import/opportunity-creation-panel/components";
import NotificationHistory from "./NotificationHistory";
import { useApi } from "../../../../../common/hooks";
import { OpportunityService } from "../../../../../api";

export default function NotifySidePanel({ opportunityId, open, onActive, onClose }) {
	const [toNotifyList, setToNotifyList] = useState([]);
	const { call: addNotification } = useApi(OpportunityService.addNotification);
	const [toUpdate, setToUpdate] = useState(0);
	const handleAddCollaborator = (col) => setToNotifyList((prev) => [...prev, col]);
	const handleRemoveCollaborator = (col) => setToNotifyList((prev) => prev.filter((pc) => col !== pc));
	const handleCancel = () => {
		setToNotifyList([]);
		onClose();
	};
	const handleSubmit = () => {
		addNotification({ opportunityId }, toNotifyList)
			.then(() => {
				setToNotifyList([]);
				setToUpdate(toUpdate + 1);
			})
			.catch((err) => console.error(err));
	};
	return (
		<SidePanel open={open} size={30} onActive={onActive} onClose={onClose}>
			<SidePanelContent
				className={styles.sidePanelContent}
				title={translate("opportunity.notify.side-panel.title")}
				onClose={onClose}
			>
				<div className={styles.main}>
					<div className={styles.container}>
						{open && (
							<OpportunityCollaborators
								collaborators={toNotifyList}
								onAdd={handleAddCollaborator}
								onRemove={handleRemoveCollaborator}
							/>
						)}
						<NotificationHistory opportunityId={opportunityId} toUpdate={toUpdate} />
					</div>
					<div className={styles.footer}>
						<CustomButton color="secondary" variant="outlined" onClick={handleCancel}>
							{translate("common:btn.cancel")}
						</CustomButton>
						<CustomButton
							color="primary"
							disabled={toNotifyList.length === 0}
							variant="contained"
							onClick={handleSubmit}
						>
							{translate("common:btn.submit")}
						</CustomButton>
					</div>
				</div>
			</SidePanelContent>
		</SidePanel>
	);
}
