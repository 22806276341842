import { DialogContent } from "@mui/material";
import React from "react";

export default function GenericDialogContent({ children, className = "", dividers = false }) {
	return (
		<DialogContent className={className} dividers={dividers}>
			{children}
		</DialogContent>
	);
}
