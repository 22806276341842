import React from "react";
import { useSelector } from "react-redux";
import { CustomDialog } from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";

export default function ExportDialog({ open, onClose, onExport }) {
	const count = useSelector(({ dsi }) => dsi.totalElements);
	return (
		<CustomDialog
			disableBackdropClick
			open={open}
			submitLabel={translate("common:header.btn.export-user-list")}
			subTitle={translate("header.export-dialog.sub-title", { count })}
			title={translate("header.export-dialog.title")}
			onClose={onClose}
			onSubmit={onExport}
		/>
	);
}
