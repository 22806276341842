export const DOCUMENT_STATUSES = {
	completed: "COMPLETED",
	errorDuplicated: "ERROR_DUPLICATED",
	errorProcess: "ERROR_PROCESS",
	errorNotSupported: "ERROR_NOT_SUPPORTED",
};
export const ERROR_STATUSES_TRANSLATION_KEYS = {
	ERROR_DUPLICATED: "error-duplicated",
	ERROR_PROCESS: "error-process",
	ERROR_NOT_SUPPORTED: "error-not-supported",
};
