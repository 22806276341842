import React, { useRef, useState } from "react";
import { Checkbox } from "@mui/material";
import { icon } from "../icon-component";
import styles from "./MultiSelect.module.css";
import { CustomButton, CustomTooltip, DropDown, InfiniteList2 } from "..";

const MultiSelect = ({
	className,
	color = "primary",
	dropClassName = "",
	fieldLabel = "name",
	hasSearch,
	infiniteRequest,
	label,
	onApply,
	size = "md",
}) => {
	const [open, setOpen] = useState(false);
	const [selectionList, setSelectionList] = useState([]);
	const anchorRef = useRef(null);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleApply = (selection) => {
		let tempSelections = [...selectionList];
		if (selectionList.includes(selection.id)) {
			tempSelections = tempSelections.filter((tempSelection) => tempSelection !== selection.id);
		} else {
			tempSelections = [...tempSelections, selection.id];
		}
		setSelectionList(tempSelections);
		onApply(tempSelections);
	};
	const rowRenderer = (row) => (
		<CustomTooltip showWhenOverflow title={row[fieldLabel]}>
			<div className={styles.multiSelect__row} role="presentation" onClick={() => handleApply(row)}>
				<Checkbox checked={selectionList.includes(row.id)} />
				{row[fieldLabel]}
			</div>
		</CustomTooltip>
	);
	return (
		<div className={`${styles.container} ${className}`}>
			<CustomButton
				ref={anchorRef}
				color={color}
				endIcon={icon.faChevronDown}
				size={size}
				variant="outlined"
				onClick={handleOpen}
			>
				{label}
			</CustomButton>
			<DropDown ref={anchorRef} disablePortal className={styles.dropdown} open={open} onClose={handleClose}>
				<div className={`${styles.dropdown__container} ${dropClassName}`}>
					<InfiniteList2 hasSearch={hasSearch} request={infiniteRequest} rowRenderer={rowRenderer} />
				</div>
			</DropDown>
		</div>
	);
};

export default MultiSelect;
