import { Buffer } from "buffer";
import React from "react";
import { DocumentService, ApiService } from "../../../../../api";
import { GenericDialog, GenericDialogHeader, GenericDialogContent } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";

class TaskContent extends React.Component {
	constructor(props) {
		super(props);
		this.state = { open: false, image: null };
		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	handleHover = () => {
		const { image } = this.state;
		const { data } = this.props;
		if (data.contentType !== "PARAGRAPH") {
			this.setState((prevState) => ({ open: !prevState.open }));
			if (!image) {
				this.requestImage(data.documentId, data.pageStart);
			}
		}
	};

	requestImage = (docId, page) => {
		DocumentService.getPage({ docId }, { page }, this.cancelTokenSource.token)
			.then((data) => {
				if (data) {
					const binaryData = Buffer.from(data, "binary").toString("base64");
					this.setState({ image: binaryData });
				}
			})
			.catch((err) => console.error(err));
	};

	render() {
		const { image, open } = this.state;
		const { data } = this.props;
		return (
			<div style={{ width: "100%", height: "100%" }}>
				<div
					style={{
						justifyContent: "justify",
						height: "auto",
						overflowY: "auto",
					}}
				>
					{data.contentType === "PARAGRAPH" || data.contentType === "TEXT" ? (
						data.contents
					) : (
						<div
							style={{
								border: "1px solid var(--color-light-grey-3)",
								borderRadius: 8,
								textAlign: "center",
								textTransform: "uppercase",
							}}
							onMouseEnter={this.handleHover}
						>
							{translate("dashboards.task-content.show-img")}
						</div>
					)}
				</div>

				<GenericDialog maxWidth="md" open={open} onClose={this.handleHover}>
					<GenericDialogHeader>{data.contentType}</GenericDialogHeader>
					<GenericDialogContent dividers>
						<img
							alt={`img_${data.contentId}`}
							height={800}
							src={`data:image/jpeg;base64,${image}`}
							width={750}
						/>
					</GenericDialogContent>
				</GenericDialog>
			</div>
		);
	}
}

export default TaskContent;
