import React, { useState, useEffect, useRef, useCallback } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { QuestionBox } from "..";
import { ApiService, CollabService } from "../../../../../../../../../api";
import {
	CircularLoader,
	IconComponent,
	icon,
	SidePanelBox,
	CustomTooltip,
	CustomIconButton,
	LimitedAutocompleteTextField,
} from "../../../../../../../../../common/components";
import styles from "./QuestionsTabView.module.css";
import { updateNode } from "../../../../../../../slice/pdf/pdf-slice";
import { setTempQuestion } from "../../../../../../../slice/document/document-slice";
import { handleQuestionCountIncrement } from "../../../../../../../slice/utils";
import { Flags, isFeatureEnabled, translate } from "../../../../../../../../../common/providers";
import QuestionDetailsSidePanel from "../../../../../questions-right-panel/components/QuestionDetailsSidePanel";
import { setDisableKeyEvents } from "../../../../../../../../../common/slice";

const QuestionsTabView = ({ disableEditing = false, informationId = 0, pageStart = 0, selectedMode }) => {
	const cancelTokenSourceRef = useRef(null);
	const [questions, setQuestions] = useState([]);
	const [qaDetailsId, setQaDetailsId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [openDetails, setOpenDetails] = useState(false);
	const tempQuestion = useSelector(({ srDocument }) => srDocument.sidePanelTempQuestion);
	const user = useSelector(({ context }) => context.user);
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const dispatch = useDispatch();

	const fetchQuestionAnswers = useCallback(() => {
		CollabService.getQuestionAnswers({ informationId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setQuestions(data);
				setIsLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setIsLoading(false);
			});
	}, [informationId]);
	useEffect(
		() => {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
			return () => {
				ApiService.cancelTokens(cancelTokenSourceRef.current);
				if (disableKeyEvents) {
					dispatch(setDisableKeyEvents(false));
				}
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	useEffect(() => {
		setIsLoading(true);
		if (informationId) {
			fetchQuestionAnswers();
		}
	}, [informationId, fetchQuestionAnswers]);

	const handleOpenDetailSidePanel = (qaId) => {
		setQaDetailsId(qaId);
		setOpenDetails(true);
	};
	const handleCloseDetailSidePanel = () => {
		setOpenDetails(false);
	};
	const handleAddQuestion = () => {
		if (tempQuestion?.length >= 3) {
			CollabService.createQuestionAnswer({ informationId }, tempQuestion, cancelTokenSourceRef.current.token)
				.then(() => {
					fetchQuestionAnswers();
					batch(() => {
						dispatch(
							updateNode({
								mode: selectedMode,
								pageConditions: { page: pageStart },
								informationId,
								updates: [{ property: "questionAnswer", callback: handleQuestionCountIncrement }],
							})
						);
						dispatch(setTempQuestion(""));
					});
				})
				.catch((err) => console.error(err));
		}
	};
	const handleChangeTempQuestion = (newQuestion) => {
		dispatch(setTempQuestion(newQuestion));
	};
	return (
		<>
			<div className={styles.container}>
				{!disableEditing && (
					<SidePanelBox className={styles.container__newComment} hasShadow={false}>
						<>
							<div className={styles.container__line}>
								<CustomTooltip showWhenOverflow title={user.displayName}>
									<div className={styles.name}>{user.displayName}</div>
								</CustomTooltip>
							</div>
							<LimitedAutocompleteTextField
								autoFocus
								fullWidth
								multiline
								className={styles.textField}
								displayLength={tempQuestion?.length > 0}
								helperTextClassName={styles.helperTextClassName}
								maxLength={512}
								placeholder={translate(
									"smart-review.cmp-details.option-panel.question-tab.your-question"
								)}
								rows={tempQuestion?.length > 3 ? 4 : 1}
								size="sm"
								trigger={`${(isFeatureEnabled(Flags.MENTIONS) && "@") || null}`}
								value={tempQuestion}
								onBlur={() => dispatch(setDisableKeyEvents(false))}
								onChange={handleChangeTempQuestion}
								onFocus={() => dispatch(setDisableKeyEvents(true))}
							/>
						</>
						{tempQuestion?.length > 0 && (
							<div className={styles.line__rightItems}>
								<CustomIconButton
									btnClassName={styles.btnClassName}
									data-testid="comment.send.btn"
									icon={icon.faPaperPlane}
									iconClassName={styles.iconClassName}
									tooltip={translate("common:comment.send-button")}
									variant="outlined"
									onClick={handleAddQuestion}
								/>
							</div>
						)}
					</SidePanelBox>
				)}
				<div
					className={
						(disableEditing && styles.list__fullHeight) ||
						(tempQuestion?.length === 0 && styles.list__emptyText) ||
						(tempQuestion?.length !== 0 && tempQuestion?.length <= 3 && styles.list__shortText) ||
						(tempQuestion?.length > 3 && styles.list__largeText) ||
						""
					}
				>
					{Array.isArray(questions) &&
						questions.length > 0 &&
						questions.map((question) => (
							<QuestionBox
								key={question.id}
								question={question}
								onOpenDetais={handleOpenDetailSidePanel}
							/>
						))}
					{(!Array.isArray(questions) || questions.length === 0) && !isLoading && (
						<div className={styles.noContent}>
							<IconComponent
								className={styles.noContent__infoIcon}
								color="var(--color-blue)"
								icon={icon.faInfoCircle}
								size="2xl"
							/>
							{translate("common:empty-state.no-results")}
						</div>
					)}
					{isLoading && (
						<div className={styles.loading}>
							<CircularLoader />
						</div>
					)}
				</div>
			</div>
			<QuestionDetailsSidePanel open={openDetails} qaId={qaDetailsId} onClose={handleCloseDetailSidePanel} />
		</>
	);
};

export default QuestionsTabView;
