import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
	createNotification,
	SidePanel,
	SidePanelContent,
	SidePanelContainer,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import { ApiService, NormsPendingService } from "../../../../../../api";
import { NormList, CustomNorm, NormDetailsSidePanel } from "../../../../components";
import { AutomaticDetections, ButtonNormBody } from "..";
import { addCustomNorm, getPendingDetections } from "../../../../slice/normsCenterSlice";

export default function MatchedNorms({
	open,
	pendingId,
	pendingIds,
	matchedNormId,
	textDetected,
	onOpenMatchedNorms,
	onUpdatePendingNorm,
	onActive: onActiveFromParent = null,
}) {
	const [openCreateCustomNorm, setOpenCreateCustomNorm] = useState(false);
	const [openDetails, setOpenDetails] = useState(null);
	const [automaticDetections, setAutomaticDetections] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	const { limit, page, filters, pendingStatus } = useSelector(
		({ normsCenter }) => ({
			limit: normsCenter.limit,
			page: normsCenter.page,
			filters: normsCenter.filters,
			pendingStatus: normsCenter.pendingStatus,
		}),
		shallowEqual
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const fetchAutomaticDetections = (pendId) => {
		if (!pendId) {
			return;
		}
		setIsLoading(true);
		NormsPendingService.getAutomaticDetections({ pendingId: pendId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setAutomaticDetections(data);
				setIsLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setIsLoading(false);
			});
	};
	useEffect(() => {
		if (pendingId && open) {
			fetchAutomaticDetections(pendingId);
		}
	}, [pendingId, open]);
	const handleUpdateNorm = (row, isManual, single) => {
		const newNormId = row.id || row.matchedNormId;
		if (matchedNormId && newNormId) {
			const call = single ? "updateMatchedNorm" : "updateSimilarMatchedNorm";
			NormsPendingService[call](
				{ matchId: pendingId, pendingId, normId: newNormId },
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					onUpdatePendingNorm({
						ids: [pendingId],
						update: {
							matchedNormId: newNormId,
							matchedNormName: row.name || row.matchedNormName,
							trust: isManual ? "MANUAL" : row.trust,
						},
					});
					dispatch(
						getPendingDetections({
							token: cancelTokenSourceRef.current.token,
							pendingStatus,
							limit,
							page,
							filters,
							displayResults: true,
						})
					);
					createNotification({
						type: "success",
						message: translate(
							single
								? "norms-center.matched-norms.update.success"
								: "norms-center.matched-norms.update-with-similar.success",
							{
								name: row.name || row.matchedNormName,
							}
						),
					});
					onOpenMatchedNorms();
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};
	const handleUpdateMultipleNorms = (row) => {
		NormsPendingService.updateMatchedNormMultiple(
			{ normId: row.id, pendingNormIds: pendingIds },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				const newNormId = row.id || row.matchedNormId;
				onUpdatePendingNorm({
					ids: pendingIds,
					update: {
						matchedNormId: newNormId,
						matchedNormName: row.name || row.matchedNormName,
						trust: "MANUAL",
					},
				});
				onOpenMatchedNorms();
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleCreateNorm = (payload) => {
		dispatch(addCustomNorm({ payload, token: cancelTokenSourceRef.current.token }))
			.then(unwrapResult)
			.then((data) => {
				handleUpdateNorm(data, true, false);
			})
			.catch((err) => console.error(err));
	};
	const handleOpenCreateCustomNorm = () => setOpenCreateCustomNorm(true);
	const handleCloseCreateCustomNorm = () => setOpenCreateCustomNorm(false);
	const handleCreateCustomNorm = (payload) => {
		setOpenCreateCustomNorm(false);
		handleCreateNorm(payload);
	};
	const handleOpenDetails = (normId, normName) => setOpenDetails({ normId, normName });
	const handleCloseDetails = () => setOpenDetails(null);
	const handleSelectManual = (row, single) => {
		if (Array.isArray(pendingIds)) {
			handleUpdateMultipleNorms(row);
		} else {
			handleUpdateNorm(row, true, single);
		}
	};
	const handleSelectAutomatic = (row, single) => {
		handleUpdateNorm(row, false, single);
	};
	return (
		<SidePanel open={open} size={90} onActive={onActiveFromParent} onClose={onOpenMatchedNorms}>
			{({ onActive }) => (
				<SidePanelContent
					title={
						(Array.isArray(pendingIds) && translate("norms-center.matched-norms.title.multiple")) ||
						translate("norms-center.matched-norms.title", { id: textDetected || "" })
					}
					onClose={onOpenMatchedNorms}
				>
					{!pendingIds && (
						<SidePanelContainer>
							<h5>{translate("norms-center.matched-norms.automatic-detection")}</h5>
							<AutomaticDetections
								content={automaticDetections}
								isLoading={isLoading}
								onSelectRow={handleSelectAutomatic}
								onShowDetails={handleOpenDetails}
							/>
						</SidePanelContainer>
					)}
					<SidePanelContainer>
						<h5>{translate("norms-center.matched-norms.search")}</h5>
						<NormList
							CustomActionButtonBody={ButtonNormBody}
							multipleOnly={Array.isArray(pendingIds)}
							onCreateCustomNorm={handleOpenCreateCustomNorm}
							onSelectRow={handleSelectManual}
							onShowDetails={handleOpenDetails}
						/>
					</SidePanelContainer>
					<SidePanel
						open={openCreateCustomNorm}
						size={65}
						onActive={onActive}
						onClose={handleCloseCreateCustomNorm}
					>
						<CustomNorm onClose={handleCloseCreateCustomNorm} onCreate={handleCreateCustomNorm} />
					</SidePanel>
					<NormDetailsSidePanel
						normId={openDetails?.normId || ""}
						normName={openDetails?.normName || ""}
						open={!!openDetails}
						onActive={onActive}
						onClose={handleCloseDetails}
					/>
				</SidePanelContent>
			)}
		</SidePanel>
	);
}
