import { MenuList } from "@mui/material";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { CustomButton, IconComponent, DropDown } from "../../../../common/components";
import { MenuItemSelectable } from "../..";
import styles from "./ProfileMenu.module.css";

const myProfileMenus = { name: "navigation:home.edit-my-profile", to: "/profile" };
const myNotifsPrefs = { name: "navigation:home.notification-settings", to: "/notification-settings" };
const logout = { name: "navigation:home.logout" };
export default function ProfileMenu({
	menuName,
	open = false,
	onIsSelected = null,
	onClose = null,
	onOpen = null,
	onClickLogout = null,
	onOpenHover = null,
	onSelect = null,
}) {
	const user = useSelector(({ context }) => context.user);
	const anchorRef = useRef(null);
	const handleOpen = () => {
		onOpen(menuName);
	};
	const handleOpenHover = () => {
		onOpenHover(menuName);
	};
	const handleClose = () => {
		onClose();
	};
	const handleSelect = () => {
		if (!onIsSelected(myProfileMenus.to)) {
			onSelect(myProfileMenus);
		}
	};
	const handleSelectNotifsPrefs = () => {
		if (!onIsSelected(myNotifsPrefs.to)) {
			onSelect(myNotifsPrefs);
		}
	};

	return (
		<>
			<CustomButton
				ref={anchorRef}
				className={styles.profileMenu}
				data-cy="profileMenu_btn"
				data-testid="profileMenu.btn"
				onClick={handleOpen}
				onMouseOver={handleOpenHover}
			>
				<IconComponent autoTranslate="no" className={styles.profileMenu__icon}>
					{user?.initials}
				</IconComponent>
			</CustomButton>
			<DropDown ref={anchorRef} open={open} onClose={handleClose}>
				<MenuList data-testid="profileMenu.menu">
					<MenuItemSelectable menuItem={myProfileMenus} onIsSelected={onIsSelected} onSelect={handleSelect} />
					<MenuItemSelectable
						menuItem={myNotifsPrefs}
						onIsSelected={onIsSelected}
						onSelect={handleSelectNotifsPrefs}
					/>
					<MenuItemSelectable menuItem={logout} onSelect={onClickLogout} />
				</MenuList>
			</DropDown>
		</>
	);
}
