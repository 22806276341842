import React from "react";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";

export default function TeamTable({ users = [] }) {
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					<CustomTableCell>{translate("admin.project-details.th.name")}</CustomTableCell>
					<CustomTableCell>{translate("admin.project-details.th.email")}</CustomTableCell>
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody>
				{Array.isArray(users) &&
					users.map((user) => (
						<CustomTableRow key={user.id}>
							<CustomTableCell autoTranslate="no">{user.displayName}</CustomTableCell>
							<CustomTableCell autoTranslate="no">{user.email}</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
