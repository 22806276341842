import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./SearchCardTemplateSelectionList.module.css";
import { ApiService, SearchCardTemplateService } from "../../../../api";
import { InfiniteList2, ViewTabs } from "../..";
import { translateEnumTemplatetype } from "../../../providers";
import { TemplateBox, TemplateListHeader } from "./components";
import { VISIBILITY_TEMPLATE_AUTOMATION } from "../../../constants";

export default function SearchCardTemplateSelectionList({
	allowDefaultChanges = false,
	defaultCheckedIds = [],
	defaultPrivacy,
	hideCheckAll = false,
	isFilter = false,
	keepSelectionAcrossTabs = false,
	onChange,
	rowRenderer,
	singleSelection = false,
}) {
	const cancelTokenSourceRef = useRef(null);
	const company = useSelector(({ context }) => context.company);
	const [selectedTab, setSelectedTab] = useState(0);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const [checkedTemplates, setCheckedTemplates] = useState([]);
	const [uncheckedTemplates, setUncheckedTemplates] = useState([]);
	const [templatesCount, setTemplatesCount] = useState(0);
	const [allChecked, setAllChecked] = useState(false);
	const [needUpdate, setNeedUpdate] = useState(false);
	const addTemplateTabs = Object.values(isFilter ? { AUTOMATION: "AUTOMATION" } : VISIBILITY_TEMPLATE_AUTOMATION).map(
		(type) => ({
			label: translateEnumTemplatetype(type, { companyName: company.name }),
		})
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		setCheckedTemplates([...defaultCheckedIds]);
		setInfiniteListRequest(
			() =>
				({ page, limit }) =>
					SearchCardTemplateService.getTemplates(
						{ page, limit },
						{ filters: {} },
						cancelTokenSourceRef.current.token
					)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company]);
	useEffect(() => {
		if (allowDefaultChanges) {
			setCheckedTemplates([...defaultCheckedIds]);
		}
	}, [allowDefaultChanges, defaultCheckedIds]);
	useEffect(() => {
		const templateOwner = Object.values(VISIBILITY_TEMPLATE_AUTOMATION)[selectedTab];
		if (!keepSelectionAcrossTabs) {
			setCheckedTemplates([]);
		}
		setAllChecked(false);
		setUncheckedTemplates([]);
		setInfiniteListRequest(
			() =>
				({ page, limit, search }) =>
					SearchCardTemplateService.getTemplates(
						{ page, limit },
						{
							filters: {
								templateOwner,
								name: search,
							},
						},
						cancelTokenSourceRef.current.token
					)
		);
	}, [selectedTab, keepSelectionAcrossTabs]);
	useEffect(() => {
		if (defaultPrivacy) {
			const newSelectedTab = Object.values(VISIBILITY_TEMPLATE_AUTOMATION).indexOf(defaultPrivacy);
			if (newSelectedTab >= 0) {
				setNeedUpdate(true);
				setSelectedTab(newSelectedTab);
			}
		}
	}, [defaultPrivacy]);
	useEffect(() => {
		if (needUpdate) {
			setNeedUpdate(false);
		}
	}, [needUpdate]);
	const handleChangeSelectedTab = (_, newTab) => {
		setSelectedTab(newTab);
	};
	const handleCheckTemplate = (template, newChecked) => {
		if (singleSelection) {
			setCheckedTemplates([template.id]);
			if (typeof onChange === "function") {
				onChange([template.id], template);
			}
			return;
		}
		if (newChecked) {
			const newCheckedTemplates = [...checkedTemplates, template.id];
			setCheckedTemplates(newCheckedTemplates);
			setUncheckedTemplates((prev) => prev.filter((tmpId) => tmpId !== template.id));
			onChange(newCheckedTemplates, template);
		} else {
			const newCheckedTemplates = checkedTemplates.filter((tmpId) => tmpId !== template.id);
			setCheckedTemplates(newCheckedTemplates);
			if (typeof onChange === "function") {
				onChange(newCheckedTemplates, template);
			}
			if (allChecked) {
				setUncheckedTemplates((prev) => [...prev, template.id]);
			}
		}
	};
	const isRowChecked = (row) =>
		(allChecked && !uncheckedTemplates.includes(row.id)) || checkedTemplates.includes(row.id);
	const defaultRowRenderer = (row) => (
		<TemplateBox
			checked={isRowChecked(row)}
			singleSelection={singleSelection}
			template={row}
			onCheck={(newChecked) => handleCheckTemplate(row, newChecked)}
		/>
	);
	const onInfiniteResponse = (data) => {
		setTemplatesCount(data.totalElements || 0);
	};
	const handleCheckAll = (event) => {
		const newChecked = event.target.checked;
		setAllChecked(newChecked);
		if (newChecked) {
			setUncheckedTemplates([]);
		} else {
			setCheckedTemplates([]);
		}
	};
	return (
		<div>
			<ViewTabs
				noPadding
				noShadow
				selectedTab={selectedTab}
				tabs={addTemplateTabs}
				variant="scrollable"
				onChange={handleChangeSelectedTab}
			/>
			<div className={styles.searchListContainer}>
				<InfiniteList2
					hasSearch
					callOnResponse={onInfiniteResponse}
					request={needUpdate ? null : infiniteListRequest}
					rowRenderer={
						rowRenderer
							? (row) => rowRenderer({ row, isRowChecked, handleCheckTemplate })
							: defaultRowRenderer
					}
				>
					{!singleSelection && !hideCheckAll && (
						<TemplateListHeader
							allChecked={allChecked}
							checkedTemplates={checkedTemplates}
							templatesCount={templatesCount}
							uncheckedTemplates={uncheckedTemplates}
							onCheckAll={handleCheckAll}
						/>
					)}
				</InfiniteList2>
			</div>
		</div>
	);
}
