import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	CircularLoader,
	CommonFilters,
	CustomButton,
	EmptyState,
	FiltersWrapper,
	ViewTabs,
	InfiniteListFilter,
	InformationBox,
} from "../../../../../../../../../common/components";
import { translate } from "../../../../../../../../../common/providers";
import { ApiService, SimilarContentService } from "../../../../../../../../../api";
import { isNonEmptyArray, isNumber, shallowEqual } from "../../../../../../../../../common/utils";
import styles from "./SimilarContentTabView.module.css";
import { setSubTabToOpen } from "../../../../../../../slice/document/document-slice";

const SimilarContentTabView = ({ informationId, onOpenAdditionalSidePanel }) => {
	const [similarContents, setSimilarContents] = useState([]);
	const [projectsInfiniteListRequest, setProjectsInfiniteListRequest] = useState(null);
	const [documentsInfiniteListRequest, setDocumentsInfiniteListRequest] = useState(null);
	const [search, setSearch] = useState();
	const [displayedAmmount, setDisplayedAmmount] = useState(3);
	const [defaultFilters, setDefaultFilters] = useState({ projects: [], documents: [] });
	const [prevDefaultFilters, setPrevDefaultFilters] = useState({ projects: [], documents: [] });
	const [prevInformationId, setPrevInformationId] = useState(0);
	const [selectedTab, setSelectedTab] = useState("MultiDocument");
	const [tabChanged, setTabChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const projectId = useSelector(({ context }) => context.project.id);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const subTabToOpen = useSelector(({ srDocument }) => srDocument.subTabToOpen);
	const dispatch = useDispatch();
	const cancelTokenSourceRef = useRef();
	const tabHeaders = useMemo(
		() => [
			{
				id: "MultiDocument",
				label: translate("smart-review.cmp-details.option-panel.similar-content.tabs.project"),
			},
			{
				id: "MultiProject",
				label: translate("smart-review.cmp-details.option-panel.similar-content.tabs.multi-projects"),
			},
		],
		[]
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (isNumber(subTabToOpen)) {
			const newTab = tabHeaders?.[subTabToOpen]?.id;
			if (newTab) {
				setSelectedTab(newTab);
				setTabChanged(true);
			}
		}
	}, [subTabToOpen, tabHeaders]);
	useEffect(
		() => {
			if (
				documentId &&
				projectId &&
				informationId &&
				(!shallowEqual(prevDefaultFilters, defaultFilters) || prevInformationId !== informationId || tabChanged)
			) {
				if (isNumber(subTabToOpen) && tabHeaders[subTabToOpen].id !== selectedTab) {
					return;
				}
				setPrevInformationId(informationId);
				let request = SimilarContentService.getSimilarContentsInTheSameDocument;
				let ids;
				if (selectedTab === "MultiDocument") {
					request = SimilarContentService.getSimilarContentsInTheSameProject;
					ids = isNonEmptyArray(defaultFilters.documents) ? { values: defaultFilters.documents } : undefined;
				}
				if (selectedTab === "MultiProject") {
					request = SimilarContentService.getSimilarContentsInOtherProjects;
					ids = isNonEmptyArray(defaultFilters.projects) ? { values: defaultFilters.projects } : undefined;
				}
				setTabChanged(false);
				setLoading(true);
				request(
					{ projectId, documentId, informationId },
					{ ids, topK: 50 },
					cancelTokenSourceRef.current?.token
				)
					.then((data) => setSimilarContents(data))
					.catch((err) => console.error(err))
					.finally(() => {
						setLoading(false);
					});
			}
		},
		// eslint-disable-next-line
		[
			projectId,
			documentId,
			selectedTab,
			defaultFilters,
			prevDefaultFilters,
			prevInformationId,
			tabChanged,
			informationId,
		]
	);
	useEffect(() => {
		setProjectsInfiniteListRequest(
			() =>
				({ page, limit }) =>
					SimilarContentService.getProjectsForSimilarContentfilter(
						{ projectId },
						{ value: search || undefined },
						{ page, limit },
						cancelTokenSourceRef.current.token
					)
		);
	}, [projectId, search]);
	useEffect(() => {
		setDocumentsInfiniteListRequest(
			() =>
				({ page, limit }) =>
					SimilarContentService.getDocumentsForSimilarContentfilter(
						{ projectId },
						{ value: search || undefined },
						{ page, limit },
						cancelTokenSourceRef.current.token
					)
		);
	}, [projectId, search]);
	const handleIncrementDisplayedAmount = () => {
		setDisplayedAmmount((prev) => (prev + 3 > similarContents.length ? similarContents.length : prev + 3));
	};
	const handleChangeTab = (_, tab) => {
		dispatch(setSubTabToOpen(tabHeaders.findIndex((t) => t.id === tab)));
	};
	const handleApplyFilter = (newFilters) => {
		setPrevDefaultFilters(defaultFilters);
		setDefaultFilters(newFilters);
	};
	return (
		<div className={styles.container}>
			<ViewTabs
				className={styles.tabs}
				selectedTab={selectedTab}
				tabIdentifier="id"
				tabs={tabHeaders}
				variant="scrollable"
				onChange={handleChangeTab}
			/>
			{(!loading && (
				<>
					{selectedTab === "MultiProject" && (
						<div className={styles.filterContainer}>
							<FiltersWrapper
								disableWidthAdjustments
								multiline
								className={styles.filter}
								components={[
									{
										enabled: selectedTab === "MultiDocument",
										default: true,
										component: CommonFilters.DOCUMENTS,
										hasIsSeparator: false,
										renderer: (
											<InfiniteListFilter
												identifier="id"
												labelIdentifier="documentName"
												request={documentsInfiniteListRequest}
												onChangeSearch={setSearch}
											/>
										),
										withDescription: false,
									},
									{
										enabled: selectedTab === "MultiProject",
										default: true,
										component: CommonFilters.PROJECT,
										renderer: (
											<InfiniteListFilter
												identifier="id"
												labelIdentifier="name"
												request={projectsInfiniteListRequest}
												onChangeSearch={setSearch}
											/>
										),
										withDescription: false,
									},
								]}
								defaultFilters={defaultFilters}
								directApply={false}
								onApply={handleApplyFilter}
							/>
						</div>
					)}
					{(isNonEmptyArray(similarContents) &&
						[...Array(displayedAmmount)].map((_, i) => (
							<InformationBox
								key={similarContents[i].informationId}
								information={similarContents[i]}
								isMultiProject={selectedTab === "MultiProject"}
								onOpenSidePanel={onOpenAdditionalSidePanel}
							/>
						))) || (
						<EmptyState
							className={styles.emptyState}
							content={translate("smart-review.cmp-details.option-panel.similar-content.empty-state")}
						/>
					)}
					{similarContents.length > displayedAmmount && (
						<CustomButton
							fullWidth
							color="secondary"
							variant="outlined"
							onClick={handleIncrementDisplayedAmount}
						>
							{translate("common:btn.show-more")}
						</CustomButton>
					)}
				</>
			)) || <CircularLoader size={40} />}
		</div>
	);
};

export default SimilarContentTabView;
