import React from "react";
import { Switch } from "@mui/material";

export default function CustomSwitch({
	checked,
	color = "primary",
	className = "",
	size = "medium",
	disabled = false,
	disableRipple = false,
	edge = false,
	required = false,
	onChange = null,
}) {
	return (
		<Switch
			checked={checked}
			className={className}
			color={color}
			disabled={disabled}
			disableRipple={disableRipple}
			edge={edge}
			required={required}
			size={size}
			onChange={onChange}
		/>
	);
}
