export function findNode(id, array) {
	for (const node of array) {
		if (node.id === id) {
			return node;
		}
		if (node.subTree) {
			const child = findNode(id, node.subTree);
			if (child) {
				return child;
			}
		}
	}
	return null;
}

export function updateNode({ id, root, updates, updateArray }) {
	const newArray = [...root];
	if (id) {
		return newArray.map(
			(node) =>
				(node.id === id && { ...node, ...updates }) ||
				(node.subTree && { ...node, subTree: updateNode({ id, root: node.subTree, updates }) }) ||
				node
		);
	}
	if (Array.isArray(updateArray)) {
		return newArray.map((node) => {
			const matchedNode = updateArray.find((n) => n.id === node.id);
			if (matchedNode) {
				if (node.subTree) {
					return { ...node, ...matchedNode, subTree: updateNode({ root: node.subTree, updateArray }) };
				}
				return { ...node, ...matchedNode };
			}
			if (node.subTree) {
				return { ...node, subTree: updateNode({ root: node.subTree, updateArray }) };
			}
			return node;
		});
	}
	return newArray;
}
export function updateInformationCounter({ informationId, informations }) {
	const newInformations = [...informations];
	return newInformations.map((info) => {
		const newInfo = { ...info };
		if (info.informationId === informationId) {
			newInfo.commentsCount += 1;
		}
		return newInfo;
	});
}
