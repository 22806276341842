import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService, ProjectCreationService } from "../../../../api";
import {
	CategoryList,
	CustomAccordion,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	I18nTranslate,
} from "../../../../common/components";
import { translate, translateEnumProjectRole } from "../../../../common/providers";
import { setCurrentStep } from "../../slice/newProjectSlice";
import { DocumentProgressList } from "../details-and-documents-step/components";
import { SummaryBlock } from "./components";
import styles from "./SummaryStep.module.css";

function countAllCategories(categories) {
	if (!Array.isArray(categories) || categories.length === 0) {
		return 0;
	}
	let result = categories.length;
	categories.forEach((cat) => {
		result += countAllCategories(cat.subThematic);
	});
	return result;
}

export default function SummaryStep() {
	const projectCreation = useSelector((state) => state.newProject.projectCreation);
	const currentUser = useSelector((state) => state.newProject.currentUser);
	const hasTeamSegFlag = useSelector((state) => state.newProject.hasTeamSegFlag);
	const hasCategorySegFlag = useSelector((state) => state.newProject.hasCategorySegFlag);
	const [projectSummary, setProjectSumamry] = useState({});
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	});
	useEffect(() => {
		ProjectCreationService.getProjectSummary(
			{ projectId: projectCreation.project.id },
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				setProjectSumamry(data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [projectCreation]);
	const dispatch = useDispatch();
	const handleClickEditDetails = () => {
		dispatch(setCurrentStep(0));
	};
	const handleClickEditMembers = () => {
		dispatch(setCurrentStep(2));
	};
	const handleClickEditCategories = () => {
		dispatch(setCurrentStep(1));
	};
	return (
		<div className={styles["main-container"]}>
			<SummaryBlock
				title={translate("new-project.summary.summary-block.title.details-and-documents")}
				onClickEdit={handleClickEditDetails}
			>
				<CustomAccordion
					rightIndented
					header={
						<span>
							<I18nTranslate
								param={{
									filesNb: (projectSummary.documents || []).length,
									totalPages: projectSummary.totalPages,
								}}
								translationKey="new-project.summary.accordion-title.selected-files"
							/>
						</span>
					}
				>
					<DocumentProgressList documents={projectSummary.documents} />
				</CustomAccordion>
			</SummaryBlock>
			<SummaryBlock
				hideAction={!hasTeamSegFlag}
				title={translate("new-project.summary.summary-block.title.members-invitations")}
				onClickEdit={handleClickEditMembers}
			>
				<CustomAccordion
					rightIndented
					header={
						<b>
							{translate("new-project.summary.accordion-title.members", {
								membersCount: (projectSummary.users || []).length + 1,
							})}
						</b>
					}
				>
					<CustomTable className={styles.list}>
						<CustomTableHead>
							<CustomTableRow className={styles["header-row"]}>
								<CustomTableCell />
								<CustomTableCell />
							</CustomTableRow>
						</CustomTableHead>
						<CustomTableBody>
							{Array.isArray(projectSummary.users) &&
								[currentUser, ...projectSummary.users].map((u) => (
									<CustomTableRow key={u.email} className={styles.row}>
										<CustomTableCell
											autoTranslate="no"
											className={`${styles["name-cell"]} ${styles.cell}`}
										>
											{u.email}
										</CustomTableCell>
										<CustomTableCell className={styles["role-cell"]}>
											<div className={styles.cell} translate="no">
												{translateEnumProjectRole(u.role)}
											</div>
										</CustomTableCell>
									</CustomTableRow>
								))}
						</CustomTableBody>
					</CustomTable>
				</CustomAccordion>
			</SummaryBlock>
			<SummaryBlock
				hideAction={!hasCategorySegFlag}
				title={translate("new-project.summary.summary-block.title.categories")}
				onClickEdit={handleClickEditCategories}
			>
				<CustomAccordion
					rightIndented
					header={
						<b>
							{translate("new-project.summary.accordion-title.selected-categories", {
								categoriesCount: countAllCategories(projectSummary.thematics),
							})}
						</b>
					}
				>
					<CategoryList categoryList={projectSummary?.thematics || []} disableFiltered={false} />
				</CustomAccordion>
			</SummaryBlock>
		</div>
	);
}
