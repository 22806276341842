import React from "react";
import {
	CustomIconButton,
	CustomTooltip,
	IconComponent,
	VerticalDivider,
	icon,
} from "../../../../../../../common/components";
import styles from "./PanelHeader.module.css";
import { SENTENCE_TYPE } from "../../../../../constants/constants";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../../../../common/providers";
import ReviewRedirectionId from "../ChatRedirection/ReviewRedirectionId";

const PanelHeader = ({
	confidence,
	contentId,
	informationId,
	isRequirement,
	onAutoScroll,
	onClose,
	projectName,
	updatedInformationId,
	updatedInformationType,
}) => {
	const headerRender = (isInformationType) =>
		isInformationType || !isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS) ? (
			<>
				<IconComponent className={styles.infoIcon} color="var(--color-light-grey-1)" icon={icon.faStream} />
				{contentId || `INFO_${informationId}`}
			</>
		) : (
			<>
				<IconComponent className={styles.infoIcon} color="var(--color-red)" icon={icon.faAsterisk} />
				{contentId || `REQ_${informationId}`}
			</>
		);
	return (
		<div className={styles.panel__header}>
			<div className={styles.mainContainer}>
				<div className={styles.mainContainer__header}>
					<CustomIconButton
						className={styles.header__icon}
						data-testid="panel.close.btn"
						icon={icon.faTimes}
						iconClassname={styles["header__icon--large"]}
						onClick={onClose}
					/>
					<span className={styles.header__text} translate="no">
						{(informationId === updatedInformationId &&
							headerRender(updatedInformationType === SENTENCE_TYPE.INFORMATION)) ||
							headerRender(!isRequirement)}
					</span>
					{isSegFeatureEnabled(SegFlags.CHAT_PROJECT) && <ReviewRedirectionId />}
				</div>
				<CustomIconButton
					data-testid="panel.scroll.btn"
					icon={icon.faLocationCrosshairs}
					tooltip={translate("smart-review.right-panel.menu.scroll-button")}
					variant="outlined"
					onClick={onAutoScroll}
				/>
			</div>
			{(projectName || confidence) && (
				<div className={styles.secondaryContainer}>
					{projectName && (
						<CustomTooltip showWhenOverflow title={projectName}>
							<span className={styles.projectName}>{projectName}</span>
						</CustomTooltip>
					)}
					{projectName && confidence && <VerticalDivider className={styles.verticalDivider} />}
					{confidence && <span>{Math.floor(confidence * 100)}%</span>}
				</div>
			)}
		</div>
	);
};

export default PanelHeader;
