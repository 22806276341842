import React, { useCallback, useEffect, useRef, useState } from "react";
import { InfiniteList2 } from "../../../../common/components";
import { FilterHeader, FlagHeaders, FlagPanels, FlagRow } from "./components";
import styles from "./DSIFlags.module.css";
import { ApiService, BackOfficeService, FeatureManagementService } from "../../../../api";

const generatePayload = (n, pid) => {
	const payload = {};
	if (n?.length) {
		payload.name = n;
	}
	if (pid) {
		payload.packageId = pid;
	}
	return payload;
};
export default function DSIFlags() {
	const cancelTokenSourceRef = useRef();
	const [plName, setName] = useState("");
	const [plPackageId, setPackageId] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [panel, setPanel] = useState("");
	const [platformProjects, setPlatformProjects] = useState([]);
	const [count, setCount] = useState(0);

	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const handleChangeFilters = useCallback(({ name, packageId }) => {
		setName(name);
		setPackageId(packageId);
	}, []);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		setInfiniteListRequest(
			() =>
				({ page, limit }) =>
					FeatureManagementService.getAllPlatformFeatures(
						{ page, limit },
						generatePayload(plName, plPackageId),
						cancelTokenSourceRef.current.token
					)
		);
		BackOfficeService.getAllProjects(cancelTokenSourceRef.current.token)
			.then((data) => setPlatformProjects(data))
			.catch((err) => console.error(err));
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [plName, plPackageId, count]);
	const handleSelectRow = (row, pane) => {
		setSelectedRow(row);
		setPanel(pane);
	};
	const rowRenderer = (feat) => <FlagRow feat={feat} onSelectRow={handleSelectRow} />;
	const handleClose = () => {
		setSelectedRow(null);
		setPanel("");
	};
	const handleUpdateRow = () => {
		setCount((prev) => prev + 1);
	};
	return (
		<div className={styles.container}>
			<FilterHeader onChangeFilters={handleChangeFilters} />
			<FlagHeaders />
			<div className={styles.list}>
				<InfiniteList2 request={infiniteListRequest} rowRenderer={rowRenderer} />
			</div>
			<FlagPanels
				feat={selectedRow}
				open={!!selectedRow}
				panel={panel}
				platformProjects={platformProjects}
				onClose={handleClose}
				onUpdateRow={handleUpdateRow}
			/>
		</div>
	);
}
