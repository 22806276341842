import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import styles from "./Search.module.css";
import {
	CommonFilters,
	CustomButton,
	DocumentFilter,
	FiltersWrapper,
	InformationIdFilter,
	KeywordFilter,
	PositionFilter,
	ProjectCategoriesFilter,
	generateFilters,
	icon,
} from "../../../../common/components";
import { ApiService, ChatProjectService, TransverseService } from "../../../../api";
import { useApi, useEffectOnce } from "../../../../common/hooks";
import apiService from "../../../../api/services/api-service";
import { setDisableKeyEvents } from "../../../../common/slice";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../common/providers";
import { generatePayload } from "../../../my-review/utils/utils";
import smartReviewContext from "../../../my-review/context/smart-review-context";
import { isNonEmptyObject } from "../../../../common/utils";

const Search = ({
	askNewQuestion,
	chatPayload,
	onApply,
	onChangeSelectedChat,
	onChangeTextFilter,
	onSetCancelAnswer,
	onSetTextMsg,
	onSetToRefresh,
	onSetToRefreshConversation,
	parameters,
	partners,
	selectedChat,
	setChatPayload,
}) => {
	const { call: continueAsking } = useApi(ChatProjectService.continueAsking);
	const projectId = useSelector(({ context }) => context.project?.id);
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const project = useSelector(({ context }) => context.project);
	const cancelTokenSourceRef = useRef(null);
	const buttonRef = useRef(null);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [shiftPressed, setShiftPressed] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [filtersPreviewRequest, setFiltersPreviewRequest] = useState();

	useEffect(
		() => {
			cancelTokenSourceRef.current = apiService.getCancelTokenSource();
			return () => {
				ApiService.cancelTokens(cancelTokenSourceRef.current);
				if (disableKeyEvents) {
					dispatch(setDisableKeyEvents(false));
				}
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	const handleSendTextToConversation = useCallback(() => {
		const { value, filter, summary } = chatPayload;
		setLoading(true);
		onSetCancelAnswer(false);
		onSetTextMsg(value);
		const handleError = (error) => {
			console.error(error);
			onSetToRefreshConversation(true);
			onSetCancelAnswer(true);
		};
		if (selectedChat) {
			continueAsking({ projectId, chatId: selectedChat }, { filter: generateFilters(filter), value, summary })
				.then(() => {
					onSetToRefreshConversation(true);
					onSetTextMsg("");
				})
				.catch((error) => {
					handleError(error);
				})
				.finally(() => setLoading(false));
		} else {
			askNewQuestion({ projectId }, { filter: generateFilters(filter), value, summary })
				.then((data) => {
					onSetToRefreshConversation(true);
					onSetToRefresh(true);
					onChangeSelectedChat(data.chatId);
					onSetTextMsg("");
				})
				.catch((error) => {
					handleError(error);
				})
				.finally(() => setLoading(false));
		}
		chatPayload.value = "";
	}, [
		askNewQuestion,
		chatPayload,
		continueAsking,
		onChangeSelectedChat,
		onSetCancelAnswer,
		onSetTextMsg,
		onSetToRefresh,
		onSetToRefreshConversation,
		projectId,
		selectedChat,
		setLoading,
	]);
	const handleChangeTextFilter = (input, summary = null) => {
		const value = input?.target ? input.target.value : input;
		setChatPayload((prev) => ({ ...prev, value, summary }));
	};

	useEffect(() => {
		const handleKeyReleased = (e) => {
			if (isActive && e.key === "Shift") {
				setShiftPressed(false);
			}
		};
		const handleKeyDetect = (e) => {
			if (isActive && e.key === "Shift") {
				setShiftPressed(true);
			}
			if (isActive && e.key === "Enter" && !shiftPressed) {
				e.preventDefault();
				handleSendTextToConversation();
				onChangeTextFilter("");
			}
			if (isActive && (e.key === "ArrowLeft" || e.key === "ArrowRight")) {
				e.preventDefault();
				const textField = e.target;
				if (e.key === "ArrowLeft") {
					if (textField.selectionStart > 0) {
						textField.setSelectionRange(textField.selectionStart - 1, textField.selectionStart - 1);
					}
				} else if (e.key === "ArrowRight") {
					if (textField.selectionStart < textField.value.length) {
						textField.setSelectionRange(textField.selectionStart + 1, textField.selectionStart + 1);
					}
				}
			}
		};
		window.addEventListener("keydown", handleKeyDetect, true);
		window.addEventListener("keyup", handleKeyReleased, true);
		return () => {
			window.removeEventListener("keydown", handleKeyDetect, true);
			window.removeEventListener("keyup", handleKeyReleased, true);
		};
	}, [shiftPressed, isActive, handleSendTextToConversation, onChangeTextFilter]);
	const handleBlur = () => {
		dispatch(setDisableKeyEvents(false));
		setIsActive(false);
	};
	const handleFocus = () => {
		dispatch(setDisableKeyEvents(true));
		setIsActive(true);
	};
	useEffect(
		() => {
			setFiltersPreviewRequest(() => (previewFilters) => {
				const newFilters = generatePayload({ ...generateFilters(previewFilters) });
				return TransverseService.getCount(
					{ projectId: project.id },
					newFilters,
					cancelTokenSourceRef.current.token
				).then((data) => data.value);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[project]
	);
	const customLabelApply = (results) => {
		if (results <= 1 && results !== null) {
			return translate("common:component.filter.apply", { results });
		}
		if (results > 1) {
			return translate("common:component.filters.apply", { results });
		}
		return translate("common:btn.apply");
	};
	const handleRemove = (newFilters) => {
		smartReviewContext.updateFilterInStorage(newFilters);
		const { separator, Type, ...rest } = newFilters;
		if (!isNonEmptyObject(rest)) {
			smartReviewContext.setContentSelection(null);
		}
	};
	useEffectOnce(
		() => {
			const storedRedirectState = localStorage.getItem("chatRedirectState");
			if (storedRedirectState) {
				const parsedState = JSON.parse(storedRedirectState);
				setChatPayload(parsedState.state.filters);
				if (!parsedState.notAutoLaunch && buttonRef.current) {
					setTimeout(() => {
						buttonRef.current.click();
					}, 0);
				}

				localStorage.removeItem("chatRedirectState");
			}
		},
		[setChatPayload],
		() => true
	);
	return (
		<div className={styles.container}>
			<div className={styles.textLine__container}>
				<TextField
					fullWidth
					multiline
					className={styles.textField}
					disabled={loading}
					maxRows={5}
					placeholder={
						loading ? translate("chat-project.loading") : translate("chat-project.ask-question.placeholder")
					}
					value={chatPayload.value || ""}
					onBlur={handleBlur}
					onChange={handleChangeTextFilter}
					onFocus={handleFocus}
				/>
				<div>
					<CustomButton
						ref={buttonRef}
						disabled={!chatPayload.value || chatPayload.value.length < 3 || loading}
						icon={icon.faPaperPlane}
						variant="contained"
						onClick={handleSendTextToConversation}
					>
						{loading ? translate("chat-project.loading") : translate("chat-project.send-button")}
					</CustomButton>
				</div>
			</div>
			<span className={styles.text_filter}>{translate("chat-project.text-filter")}</span>
			<FiltersWrapper
				hasSeparator
				multiline
				className={styles.filters_wrapper}
				components={[
					{
						default: true,
						enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
						component: CommonFilters.DOCUMENTS,
						renderer: <DocumentFilter />,
						withDescription: false,
					},
					{
						default: true,
						enabled: isSegFeatureEnabled(SegFlags.CATEGORY),
						component: CommonFilters.CATEGORIES,
						renderer: <ProjectCategoriesFilter />,
					},
					{
						enabled: true,
						component: CommonFilters.KEYWORDS,
						renderer: <KeywordFilter />,
					},
					{
						enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
						withDescription: false,
						multiSelection: true,
						labelKey: "label",
						valueKey: "key",
						component: CommonFilters.REQUIREMENT,
					},
					{
						enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
						component: CommonFilters.TOC,
						renderer: <KeywordFilter />,
					},
					{
						enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
						component: CommonFilters.POSITIONS,
						renderer: <PositionFilter />,
						partners,
					},
					{ enabled: true, component: CommonFilters.INFORMATION_ID, renderer: <InformationIdFilter /> },
					{
						enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
						component: CommonFilters.CRITICALITY,
						labelKey: "label",
						valueKey: "value",
						dynamicItems: parameters?.criticality?.map((c) => ({
							label: `common:enum.params.criticality.${c.toLowerCase()}`,
							value: c,
						})),
					},
					{
						enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
						component: CommonFilters.NEGOTIABILITY,
						labelKey: "label",
						valueKey: "value",
						dynamicItems: parameters?.negotiability?.map((n) => ({
							label: `common:enum.params.negotiability.${n.toLowerCase()}`,
							value: n,
						})),
					},
					{
						enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
						component: CommonFilters.TYPE,
						labelKey: "label",
						valueKey: "value",
						dynamicItems: parameters?.type?.map((t) => ({
							label: `common:enum.params.type.${t.toLowerCase()}`,
							value: t,
						})),
					},
				]}
				customLabelApply={customLabelApply}
				defaultFilters={chatPayload.filter}
				directApply={false}
				display={false}
				filterPopUpPlacement="top-start"
				previewRequest={filtersPreviewRequest}
				onApply={onApply}
				onRemove={handleRemove}
			/>
		</div>
	);
};

export default Search;
