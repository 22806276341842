import React from "react";
import styles from "./ViewTabPanel.module.css";
import { getFilteredDataAttributes } from "../../../utils";

export default function ViewTabPanel({ children, value, index, noPadding = false, className = "", ...dataAttributes }) {
	const classNames = `${styles.tabPanel || ""} ${className} ${
		(noPadding && styles["tabPanel--no-padding"]) || ""
	}`.trim();
	const filteredDataAttributes = getFilteredDataAttributes(dataAttributes);
	return (
		<div className={classNames} hidden={value !== index} {...filteredDataAttributes}>
			{value === index && children}
		</div>
	);
}
