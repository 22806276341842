import React from "react";
import { connect } from "react-redux";
import { WebSocket } from "../../../api";
import { setShouldReconnectWebsocket } from "../../slice";

const mapDispathToProps = (dispatch) => ({
	onReconnectWS: (payload) => dispatch(setShouldReconnectWebsocket(payload)),
});

const mapStateToProps = ({ context }) => ({
	shouldReconnectWebsocket: context.shouldReconnectWebsocket,
});

export default function withWebsocket(Component) {
	return connect(
		mapStateToProps,
		mapDispathToProps
	)(
		class extends React.Component {
			state = { subscriptions: [], subscriptionsMetadata: [] };

			componentDidMount() {
				WebSocket.connect();
			}

			componentDidUpdate() {
				const { shouldReconnectWebsocket, onReconnectWS } = this.props;
				const { subscriptions, subscriptionsMetadata } = this.state;
				if (shouldReconnectWebsocket) {
					subscriptions.forEach((s) => !!s && s.unsubscribe());
					WebSocket.disconnect();
					this.reset();
					this.connect()
						.then(() => {
							subscriptionsMetadata.forEach(({ destination, callback }) =>
								this.susbcribe(destination, callback)
							);
						})
						.catch(console.error);
					onReconnectWS(false);
				}
			}

			componentWillUnmount() {
				const { subscriptions } = this.state;
				subscriptions.forEach((s) => !!s && s.unsubscribe());
				WebSocket.disconnect();
			}

			reset() {
				this.setState({ subscriptions: [], subscriptionsMetadata: [] });
			}

			connect = () =>
				new Promise((resolve) => {
					WebSocket.connect();
					setTimeout(resolve, 3000);
				});

			susbcribe = (destination, callback) => {
				this.setState((prev) => ({
					subscriptionsMetadata: [...prev.subscriptionsMetadata, { destination, callback }],
				}));
				WebSocket.subscribe(destination, callback)
					.then((subscription) => {
						const { subscriptions } = this.state;
						subscriptions.push(subscription);
						this.setState(subscriptions);
					})
					.catch((err) => console.error(err));
			};

			render() {
				return <Component {...this.props} connectWS={this.connect} subscribeWS={this.susbcribe} />;
			}
		}
	);
}
