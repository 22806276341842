import { useState, useEffect } from "react";

const getHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

export default function useCurrentHeight({ difference = 0 }) {
	const [height, setHeight] = useState(getHeight() + difference);
	useEffect(() => {
		let timeoutId = null;
		const resizeListener = () => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => setHeight(getHeight() + difference), 150);
		};
		setHeight(getHeight() + difference);
		window.addEventListener("resize", resizeListener);
		return () => {
			window.removeEventListener("resize", resizeListener);
		};
	}, [difference]);
	return height;
}
