import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AnalyticsProvider, Flags, Permissions, translate } from "../../../common/providers";
import { exportView } from "../../../navigation";
import SearchTemplate from "./CompanySearchTemplate";
import { setIsDsi } from "./slice/company-search-template-slice";

const CompanySearchTemplateView = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		const documentTitle = translate("search-template.document.title-searches-for-automation");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Companies Search" });
		dispatch(setIsDsi(false));
	}, [dispatch]);

	return <SearchTemplate />;
};

export { default as companySearchTemplateSlice } from "./slice/company-search-template-slice";
export default exportView({
	path: [{ path: "/search-templates/search-for-automation-templates" }, { path: ":id" }],
	component: CompanySearchTemplateView,
	localesPath: "/search-template/locales",
	flag: [Flags.SEARCHCARD, Flags.ACTION_AUTO],
	companyRole: [Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER],
});
