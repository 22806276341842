import React, { useEffect, useMemo, useRef } from "react";
import { batch, useSelector, useDispatch } from "react-redux";
import { ApiService } from "../../api";
import { VerticalNavbar } from "../../common/components";
import { useEffectOnce } from "../../common/hooks";
import { translate } from "../../common/providers";
import { KEY_SMART_VIEW, KEY_SMART_VIEW_FILTER } from "../../common/providers/keys/keys";
import { checkValidity } from "../../navigation";
import { DocumentViewer, RightPanels } from "./components";
import { tabs } from "./components/right-panels";
import styles from "./MyReviewContent.module.css";
import { setSelectedSearch, setSidePanel } from "./slice/document/document-slice";
import { setFirstPage, setScrollTo, setSelectedMenu } from "./slice/pdf/pdf-slice";
import { computeAbsoluteTop } from "./utils/utils";
import {
	setIsFilterLoadedFromLocal,
	setIsHandleScroll,
	setIsPdfScrolling,
	setSelectedDocuments,
	setTransverseFilters,
} from "./slice/project-document/project-document-slice";
import { isNonEmptyArray, isNonEmptyObject } from "../../common/utils";
import { PdfHeader } from "./components/document-viewer/components";
import REVIEW_PERMISSIONS from "./permissions/review-permissions";
import { OPERATORS } from "./constants/constants";
import { hasReviewPermissions } from "./permissions/review-permissions-provider";

const MyReviewContent = ({ openDrawer }) => {
	const cancelTokenSourceRef = useRef();
	const pdfScroll = useRef(null);
	const cautionBannerDisabled = useSelector(({ context }) => context.cautionBannerDisabled);
	const selectedMenu = useSelector(({ srPdf }) => srPdf.selectedMenu);
	const page = useSelector(({ srPdf }) => srPdf.page);
	const zoom = useSelector(({ srPdf }) => srPdf.zoom);
	const docId = useSelector(({ srDocument }) => srDocument.documentId);
	const numberOfPages = useSelector(({ srDocument }) => srDocument.numberOfPages);
	const sidePanelOpen = useSelector(({ srDocument }) => srDocument.sidePanelOpen);
	const pageDimensions = useSelector(({ srDocument }) => srDocument.pageDimensions);
	const position = useSelector(({ srPdf }) => srPdf.position);
	const positionPage = useSelector(({ srPdf }) => srPdf.positionPage);
	const isHandleScroll = useSelector(({ srProjectDocument }) => srProjectDocument.isHandleScroll);
	const firstPage = useSelector(({ srPdf }) => srPdf.firstPage);
	const isPdfScrolling = useSelector(({ srProjectDocument }) => srProjectDocument.isPdfScrolling);
	const roles = useSelector(({ srDocument }) => srDocument.roles);
	const selectionSidePanelOpen = useSelector(({ srDocument }) => srDocument.selectionSidePanelOpen);
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const isSelecting = useSelector(({ srDocument }) => srDocument.isSelecting);
	const sidePanelInformationId = useSelector(({ srDocument }) => srDocument.sidePanelInformationId);

	const isSelectionPanelOn = useMemo(
		() =>
			selectionSidePanelOpen &&
			selectedRectangles.length > 1 &&
			!isSelecting &&
			hasReviewPermissions(REVIEW_PERMISSIONS.CATEGORY_UPDATE, { roles }),
		[roles, selectionSidePanelOpen, selectedRectangles, isSelecting]
	);
	const dispatch = useDispatch();

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		const contextItems = JSON.parse(window.sessionStorage.getItem(KEY_SMART_VIEW)) || {};
		const filterItems = JSON.parse(window.sessionStorage.getItem(KEY_SMART_VIEW_FILTER)) || {};
		const initFilter = { separator: OPERATORS.AND, Type: "QuickAccessSearchFilterDTO" };
		dispatch(setTransverseFilters({ ...initFilter, ...filterItems }));
		if (isNonEmptyArray(contextItems.selectedDocuments)) {
			dispatch(setSelectedDocuments(contextItems.selectedDocuments));
		}
		if (contextItems?.menu >= 0 || contextItems?.menu <= 4) {
			dispatch(setSelectedMenu(contextItems.menu));
		}
		if (isNonEmptyObject(contextItems?.sidePanelInfo)) {
			dispatch(setSelectedSearch(contextItems?.sidePanelInfo));
		}
		dispatch(setIsFilterLoadedFromLocal(true));
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	/*
	 * Handling scroll events
	 */
	useEffect(() => {
		const handleScrollLeft = () => {
			if (isHandleScroll) {
				dispatch(setIsPdfScrolling(false));
				dispatch(setIsHandleScroll(false));
			} else if (!isPdfScrolling) {
				dispatch(setIsPdfScrolling(true));
			}
		};
		if (pdfScroll?.current) {
			pdfScroll.current.addEventListener("scroll", handleScrollLeft);
		}
		return () => {
			if (pdfScroll?.current) {
				// eslint-disable-next-line
				pdfScroll.current.removeEventListener("scroll", handleScrollLeft);
			}
		};
	}, [sidePanelOpen, docId, isHandleScroll, isPdfScrolling, dispatch]);

	useEffectOnce(
		() => {
			const newTop = computeAbsoluteTop(pageDimensions, page, 0, zoom);
			pdfScroll.current.scrollTop = newTop;
		},
		[page, numberOfPages, zoom],
		() => page >= 0 && page < numberOfPages && pdfScroll?.current
	);

	useEffect(
		() => {
			if (Number.isFinite(position) && Number.isFinite(positionPage)) {
				const newTop = computeAbsoluteTop(
					pageDimensions,
					positionPage,
					(position >= 0.2 && position - 0.2) || 0,
					zoom
				);
				if (pdfScroll.current) {
					pdfScroll.current.scrollTop = newTop;
				}
			}
		},
		// eslint-disable-next-line
		[position, positionPage, pageDimensions, zoom]
	);

	useEffect(() => {
		if (
			Array.isArray(pageDimensions) &&
			Number.isFinite(page) &&
			Number.isFinite(firstPage) &&
			firstPage > 0 &&
			zoom > 0
		) {
			batch(() => {
				dispatch(setScrollTo({ page: firstPage, position: 0.01 }));
				dispatch(setFirstPage(null));
			});
		}
	}, [dispatch, firstPage, page, pageDimensions, zoom]);

	/*
	 * Handling right vertical navbar
	 */
	const handleSelectMenu = (index) => {
		dispatch(setSelectedMenu(index));
		dispatch(
			setSidePanel({
				sidePanelOpen: false,
				informationId: null,
				page: 0,
				tabToOpen: "",
				isRequirement: null,
			})
		);
		window.sessionStorage.setItem(
			KEY_SMART_VIEW,
			JSON.stringify({
				...(JSON.parse(window.sessionStorage.getItem(KEY_SMART_VIEW)) || {}),
				menu: index,
			})
		);
	};
	return (
		<>
			{docId && (
				<div className={styles.pdfHeader}>
					<PdfHeader openDrawer={openDrawer} />
				</div>
			)}
			<div className={styles.reviewContent}>
				<div className={styles.pdfContainer} data-is-navbar-open={selectedMenu !== 0}>
					{docId && (
						<DocumentViewer
							cautionBannerDisabled={cautionBannerDisabled}
							isSelectionPanelOn={isSelectionPanelOn}
							openDrawer={openDrawer}
							pdfScroll={pdfScroll}
							selectedMenu={selectedMenu}
						/>
					)}
				</div>
				<VerticalNavbar
					cautionBannerDisabled={cautionBannerDisabled}
					className={styles.verticalNavbar}
					displayContent={() => <RightPanels isSelectionPanelOn={isSelectionPanelOn} menu={selectedMenu} />}
					menuContentClassName={styles.menuContentClassName}
					menus={tabs
						.filter((m) => checkValidity(m))
						.map((t) => ({ ...t, name: (t?.name && translate(t.name)) || t.content }))}
					open={isSelectionPanelOn || (sidePanelOpen && !!sidePanelInformationId) || selectedMenu !== 0}
					selectedMenu={selectedMenu}
					onSelectMenu={handleSelectMenu}
				/>
			</div>
		</>
	);
};
export default MyReviewContent;
