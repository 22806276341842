import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./DocumentsStatusInformationBanner.module.css";
import { CustomIconButton, icon } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { setHideDocStatusInfoBanner } from "../../slice/document-center-slice";

const DocumentsStatusInformationBanner = () => {
	const analyzingDocuments = useSelector(({ documentCenter }) => documentCenter.analyzingDocuments);
	const failedDocuments = useSelector(({ documentCenter }) => documentCenter.failedDocuments);
	const dispatch = useDispatch();
	const generateCounterText = () => {
		if (analyzingDocuments === 1) {
			return translate("document-center.status-info-banner.document-analyzing", { docCount: analyzingDocuments });
		}
		if (analyzingDocuments > 1) {
			return translate("document-center.status-info-banner.documents-analyzing", {
				docCount: analyzingDocuments,
			});
		}
		if (failedDocuments === 1) {
			return translate("document-center.status-info-banner.document-failed", { docCount: failedDocuments });
		}
		if (failedDocuments > 1) {
			return translate("document-center.status-info-banner.document-failed", { docCount: failedDocuments });
		}
		return translate("document-center.status-info-banner.analysis-calculation");
	};
	const handleClick = () => {
		dispatch(setHideDocStatusInfoBanner(true));
	};
	return (
		<div className={`${styles.container} ${analyzingDocuments && styles["container--analyzing"]}`}>
			<div className={styles.textContainer}>
				<div className={styles.boldText}>
					{analyzingDocuments
						? translate("document-center.status-info-banner.analyzing")
						: translate("document-center.status-info-banner.failed")}
				</div>
				<div>{generateCounterText()}</div>
			</div>
			{!analyzingDocuments && (
				<div>
					<CustomIconButton icon={icon.faTimes} size="sm" onClick={handleClick} />
				</div>
			)}
		</div>
	);
};

export default DocumentsStatusInformationBanner;
