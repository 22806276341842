import React from "react";
import { translate } from "../../../../../../common/providers";
import styles from "./FlagHeaders.module.css";

export default function FlagHeaders() {
	return (
		<div className={styles.main}>
			<div>{translate("dsi.feature-management.table.header.feature-name")}</div>
			<div>{translate("dsi.feature-management.table.header.description")}</div>
			<div>{translate("dsi.feature-management.table.header.packages")}</div>
			<div>{translate("dsi.feature-management.table.header.projects")}</div>
			<div />
		</div>
	);
}
