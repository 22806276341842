import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useApi } from "../../../../../common/hooks";
import { DeliverablesService, DocumentOutCoverageService } from "../../../../../api";
import { isNonEmptyArray } from "../../../../../common/utils";
import styles from "./PhasesTree.module.css";
import {
	CircularLoader,
	CommonFilters,
	CustomAccordion,
	CustomButton,
	CustomDialog,
	CustomTooltip,
	DebounceSearchTextField,
	FiltersWrapper,
	I18nTranslate,
	IconComponent,
	ProjectCategoriesFilter,
	generateFilters,
	icon,
} from "../../../../../common/components";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../../common/providers";
import DeliverableRow from "../deliverable-row/DeliverableRow";

const PhasesTree = ({ filters, selectedCount, totalCount }) => {
	const { call: getPhasesWithCounters } = useApi(DocumentOutCoverageService.getPhasesWithCounters);
	const { call: searchPhasesWithDeliverable } = useApi(DocumentOutCoverageService.searchPhasesWithDeliverable);
	const { call: getAllDeliverablesWithCounters } = useApi(DocumentOutCoverageService.getAllDeliverablesWithCounters);
	const { call: reassignDeliverables } = useApi(DocumentOutCoverageService.reassignDeliverables);
	const { call: getDeliverablesCount } = useApi(DeliverablesService.getDeliverablesCount);
	const projectId = useSelector(({ context }) => context.project?.id);
	const [phases, setPhases] = useState([]);
	const [closeExpansion, setCloseExpansion] = useState(false);
	const [loadingChanges, setLoadingChanges] = useState(false);
	const [resetDeliverable, setResetDeliverable] = useState(false);
	const [openCancelDialog, setOpenCancelDialog] = useState(false);
	const [delivFilters, setDelivFilters] = useState({ name: "", categories: {} });
	const [isLoadingDeliverables, setIsLoadingDeliverables] = useState(false);
	const [removeDeliverables, setRemoveDeliverables] = useState([]);
	const [addDeliverables, setAddDeliverables] = useState([]);
	const [filtersPreviewRequest, setFiltersPreviewRequest] = useState(null);
	const deliverablesCount = useMemo(() => {
		if (delivFilters?.name || isNonEmptyArray(delivFilters?.categories?.CATEGORY)) {
			let count = 0;
			phases.forEach((phase) => (count += phase.deliverables?.length || 0));
			return count;
		}
		return 0;
	}, [delivFilters, phases]);

	const handleResetSelection = (toDefault = true) => {
		if (!toDefault) {
			setPhases((prev) =>
				prev.map((phase) => ({
					...phase,
					deliverables: phase.deliverables?.map((deliverable) => {
						if (addDeliverables.includes(deliverable.id)) {
							return { ...deliverable, reqCount: selectedCount };
						}
						if (removeDeliverables.includes(deliverable.id)) {
							return { ...deliverable, reqCount: 0 };
						}
						return deliverable;
					}),
				}))
			);
		}
		setResetDeliverable(true);
		setAddDeliverables([]);
		setRemoveDeliverables([]);
	};
	useEffect(() => {
		setFiltersPreviewRequest(
			() => (previewFilters) =>
				getDeliverablesCount(
					{ projectId },
					{
						filters: { ...generateFilters(previewFilters), isSelected: false },
					}
				).then((data) => data.value)
		);
	}, [projectId, getDeliverablesCount]);
	useEffect(() => {
		if (projectId) {
			setIsLoadingDeliverables(true);
			if (delivFilters?.name || isNonEmptyArray(delivFilters?.categories?.CATEGORY)) {
				searchPhasesWithDeliverable(
					{ projectId },
					{
						filters,
						delivFilters: generateFilters({
							...delivFilters,
							name: delivFilters?.name ? delivFilters.name : undefined,
						}),
					}
				)
					.then((data) => setPhases(data))
					.catch(console.error)
					.finally(() => setIsLoadingDeliverables(false));
			} else {
				getPhasesWithCounters({ projectId })
					.then((data) => setPhases(data))
					.catch(console.error)
					.finally(() => setIsLoadingDeliverables(false));
			}
		}
	}, [getPhasesWithCounters, searchPhasesWithDeliverable, projectId, delivFilters, filters]);
	useEffect(() => {
		if (selectedCount > 0) {
			setPhases((prev) => prev.map((p) => ({ ...p, deliverables: [] })));
			setCloseExpansion(true);
			setAddDeliverables([]);
			setRemoveDeliverables([]);
		}
	}, [selectedCount]);
	const handleExpandPhase = (expandablePhase) => {
		if (expandablePhase.deliverables?.length === expandablePhase.deliverablesCount) {
			return;
		}
		getAllDeliverablesWithCounters({ projectId, phaseId: expandablePhase.phaseId }, generateFilters(filters))
			.then((data) => {
				const newPhases = phases.map((phase) => {
					if (phase.phaseId === expandablePhase.phaseId) {
						return { ...phase, deliverables: data };
					}
					return phase;
				});
				setPhases(newPhases);
			})
			.catch(console.error);
	};
	const handleSelect = ({ deliverable, action }) => {
		if (action === "toAdd") {
			setAddDeliverables((prev) => [...prev, deliverable.id]);
			setRemoveDeliverables((prev) => prev.filter((p) => p !== deliverable.id));
		} else if (action === "toRemove") {
			setRemoveDeliverables((prev) => [...prev, deliverable.id]);
			setAddDeliverables((prev) => prev.filter((p) => p !== deliverable.id));
		} else if (action === "fullRemove") {
			setRemoveDeliverables((prev) => prev.filter((p) => p !== deliverable.id));
			setAddDeliverables((prev) => prev.filter((p) => p !== deliverable.id));
		}
	};
	const handleValidate = () => {
		setLoadingChanges(true);
		reassignDeliverables(
			{ projectId },
			{
				filters: generateFilters(filters),
				add: isNonEmptyArray(addDeliverables) ? addDeliverables : undefined,
				remove: isNonEmptyArray(removeDeliverables) ? removeDeliverables : undefined,
			}
		)
			.then(() => {
				handleResetSelection(false);
			})
			.catch(console.error)
			.finally(() => {
				setLoadingChanges(false);
			});
	};
	const handleOpenCancelDialog = () => {
		setOpenCancelDialog(true);
	};
	const handleCloseCancelDialog = () => {
		setOpenCancelDialog(false);
	};
	const handleConfirmCancel = () => {
		handleResetSelection();
		handleCloseCancelDialog();
	};
	const handleApply = (newFilters) => {
		setDelivFilters(newFilters);
	};
	const handleChangeSearchText = (value) => {
		setDelivFilters((prev) => ({ ...prev, name: value }));
	};
	const customLabelApply = (results) => {
		if (results <= 1 && results !== null) {
			return translate("common:component.filter.apply", { results });
		}
		if (results > 1) {
			return translate("common:component.filters.apply", { results });
		}
		return translate("common:btn.apply");
	};

	return (
		<>
			{(selectedCount > 0 && (
				<>
					<div className={styles.container_search}>
						<DebounceSearchTextField
							initValue={filters.name}
							isLoading={isLoadingDeliverables}
							placeholder={translate("common:deliverables.search-textfield.placeholder")}
							onChange={handleChangeSearchText}
						/>
						<FiltersWrapper
							multiline
							components={[
								{
									default: true,
									enabled: isSegFeatureEnabled(SegFlags.CATEGORY),
									component: CommonFilters.CATEGORIES,
									renderer: <ProjectCategoriesFilter disableFavoriteCategories />,
									hasFavorites: true,
								},
							]}
							customLabelApply={customLabelApply}
							defaultFilters={delivFilters}
							disablePortal={false}
							filterPopUpPlacement="auto"
							previewRequest={filtersPreviewRequest}
							onApply={handleApply}
						/>
					</div>
					{(delivFilters?.name || isNonEmptyArray(delivFilters?.categories?.CATEGORY)) && (
						<div className={styles.counter}>
							<I18nTranslate
								param={{ deliverablesCount, totalCount }}
								translationKey={
									deliverablesCount === 1
										? "common:deliverables-count.deliverable-on"
										: "common:deliverables-count.deliverables-on"
								}
							/>
						</div>
					)}
					{(isLoadingDeliverables && (
						<div className={styles.circularLoaderContainer}>
							<CircularLoader />
						</div>
					)) || (
						<div className={styles.phasesContainer}>
							{isNonEmptyArray(phases) &&
								phases.map((phase) => (
									<CustomAccordion
										key={phase.phaseId}
										className={styles.accordionContent}
										closeExpansion={closeExpansion}
										defaultOpen={
											delivFilters?.name || isNonEmptyArray(delivFilters?.categories?.CATEGORY)
										}
										header={
											<CustomTooltip showWhenOverflow title={phase?.phaseName}>
												<div className={styles.phaseName}>{phase?.phaseName}</div>
											</CustomTooltip>
										}
										iconColor="var(--color-light-grey-1)"
										onExpand={() => handleExpandPhase(phase)}
										onSetCloseExpansion={setCloseExpansion}
									>
										{(phase.deliverablesCount > 0 && (
											<div>
												{isNonEmptyArray(phase.deliverables) &&
													phase.deliverables.map((deliverable) => (
														<DeliverableRow
															key={deliverable.id}
															deliverable={deliverable}
															loadingChanges={loadingChanges}
															resetDeliverable={resetDeliverable}
															selectedCount={selectedCount}
															onSelect={handleSelect}
															onSetResetDeliverable={setResetDeliverable}
														/>
													))}
											</div>
										)) ||
											null}
									</CustomAccordion>
								))}
						</div>
					)}
					<div className={styles.actionContainer}>
						<CustomButton
							color="secondary"
							disabled={
								(!isNonEmptyArray(removeDeliverables) && !isNonEmptyArray(addDeliverables)) ||
								loadingChanges
							}
							variant="outlined"
							onClick={handleOpenCancelDialog}
						>
							{translate("common:btn.cancel")}
						</CustomButton>
						<CustomButton
							disabled={
								(!isNonEmptyArray(removeDeliverables) && !isNonEmptyArray(addDeliverables)) ||
								loadingChanges
							}
							variant="contained"
							onClick={handleValidate}
						>
							{translate("common:btn.validate")}
						</CustomButton>
					</div>
				</>
			)) || (
				<div className={styles.emptyState__container}>
					<IconComponent
						className={styles.emptyState__icon}
						color="var(--color-blue)"
						icon={icon.faInfoCircle}
					/>
					{translate("coverage.document-list.select-some-requirement-affilitate")}
				</div>
			)}
			<CustomDialog
				iconColor="var(--color-red)"
				iconModel={icon.faExclamationTriangle}
				open={openCancelDialog}
				subTitle={translate("common:close-warning.are-you-sure-to-cancel-changes")}
				onClose={handleCloseCancelDialog}
				onSubmit={handleConfirmCancel}
			/>
		</>
	);
};

export default PhasesTree;
