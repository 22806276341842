import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Filter, FilterSearchList } from "../../../../common/components";
import { translate } from "../../../../common/providers";

export default function TrustFilter({ selectedTrusts, onSelectTrusts }) {
	const trustLevels = useSelector(({ normsCenter }) => normsCenter.trustLevels);
	const [checkedTrusts, setCheckedTrusts] = useState([]);
	const [alreadyOpened, setAlreadyOpened] = useState(false);
	useEffect(() => {
		setCheckedTrusts(selectedTrusts);
	}, [selectedTrusts]);

	const handleOpen = () => {
		if (!checkedTrusts && !alreadyOpened) {
			setAlreadyOpened(true);
		}
	};
	const handleSelectTrust = (newTrusts) => {
		if (!newTrusts) {
			return;
		}
		setCheckedTrusts(newTrusts);
		onSelectTrusts(newTrusts);
	};

	const handleClearChecked = () => {
		setCheckedTrusts([]);
		onSelectTrusts([]);
	};

	return (
		<Filter
			isActive={checkedTrusts && checkedTrusts.length > 0}
			label={translate("norms-center.tab.detections.select.trust-level")}
			title={translate("norms-center.tab.detections.select.trust-level")}
			onClear={handleClearChecked}
			onOpen={handleOpen}
		>
			<FilterSearchList
				initChecked={checkedTrusts || []}
				rowKey="name"
				rowLabelKey="displayName"
				rows={trustLevels}
				searchPlaceholder={translate("norms-center.filter.norm.search")}
				onClear={handleClearChecked}
				onClickRow={handleSelectTrust}
			/>
		</Filter>
	);
}
