import { MenuItem } from "@mui/material";
import React from "react";
import { CustomTooltip } from "../../../../../../../../../common/components";
import styles from "./TeammateRow.module.css";

export default function TeammateRow({ onClick, style, user }) {
	return (
		<MenuItem key={user.email} style={style} onClick={() => onClick(user)}>
			<CustomTooltip arrow showWhenOverflow placement="left" title={user.displayName || user.email}>
				<span className={styles.rowContent}>{user.displayName || user.email}</span>
			</CustomTooltip>
		</MenuItem>
	);
}
