import { Checkbox, FormControl, TextField } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiService, PublicService } from "../../api";
import { CustomButton, LinkButton } from "../../common/components";
import { translate } from "../../common/providers";
import { checkPassword, downloadFile } from "../../common/utils";
import styles from "./Register.module.css";
import { NavUtils } from "../../navigation";
import { setToken } from "../../common/slice";

const Register = () => {
	const [password, setPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [passwordErrors, setPasswordErrors] = useState([]);
	const [passwordConfirmationError, setPasswordConfirmationError] = useState("");
	const [invitationToken, setInvitationToken] = useState("");
	const [lockEmail, setLockEmail] = useState(false);
	const [email, setEmail] = useState("");
	const cancelTokenSourceRef = useRef(null);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		const { registerEmail, invitationToken: newInvitationToken } = location.state || {};
		if (!registerEmail || !newInvitationToken) {
			window.location.href = NavUtils.goToHome();
		}
		setLockEmail(!!registerEmail);
		setEmail(registerEmail || "");
		setInvitationToken(newInvitationToken);
	}, [location.state]);

	const handleClickRegister = (event) => {
		const form = event.target;
		event.preventDefault();
		const cancelToken = ApiService.getCancelTokenSource();
		PublicService.register(
			{
				firstname: form.firstName.value,
				lastname: form.lastName.value,
				passWord: form.password.value,
				token: invitationToken,
			},
			cancelToken.token
		)
			.then(({ data, token }) => {
				dispatch(setToken(token));
				if (data.projectId) {
					navigate(NavUtils.goToProject(data.projectId), { replace: null });
				} else {
					navigate(NavUtils.goToHome(), { replace: null });
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => ApiService.cancelTokens(cancelToken));
	};
	const handleChangePassword = (event) => {
		const conditions = checkPassword(event.target.value, passwordConfirmation);
		const errors = [];
		if (!conditions.isPasswordLongEnough) {
			errors.push(translate("common:register.password-contain-nbr-characters"));
		}
		if (!conditions.containsNumbers) {
			errors.push(translate("common:register.password-contain-numbers"));
		}
		if (!conditions.hasUppercase) {
			errors.push(translate("common:register.password-contain-uppercase"));
		}
		if (!conditions.hasLowerCase) {
			errors.push(translate("common:register.password-contain-lowercase"));
		}
		if (!conditions.containsSymbol) {
			errors.push(translate("common:register.password-contain-symbol"));
		}
		setPassword(event.target.value);
		setPasswordErrors(errors);
	};
	const handleChangePasswordConfirmation = (event) => {
		const conditions = checkPassword(password, event.target.value);
		setPasswordConfirmation(event.target.value);
		setPasswordConfirmationError(
			conditions.passwordsAreEquals ? "" : translate("common:register.password-not-equals")
		);
	};
	const handleChangeFirstName = (event) => {
		setFirstName(event.target.value);
	};
	const handleChangeLastName = (event) => {
		setLastName(event.target.value);
	};
	const handleChangeTerms = (event) => {
		setTermsAccepted(event.target.checked);
	};
	const handleDownloadTerms = () => {
		PublicService.downloadTerms(cancelTokenSourceRef.current.token)
			.then(({ data }) => {
				downloadFile({ data, filename: "aitenders_terms", filetype: "zip" });
			})
			.catch((error) => {
				console.error(error);
			});
	};
	return (
		<div className={styles["form-container"]}>
			<form className={styles.form} onSubmit={handleClickRegister}>
				<span className={styles.title}>Create your account</span>
				<FormControl fullWidth required margin="normal">
					<TextField
						disabled={lockEmail}
						id="email"
						inputProps={{
							"data-testid": "register.email.input",
						}}
						label={translate("common:register.email.label")}
						name="email"
						value={email}
					/>
				</FormControl>
				<FormControl fullWidth required margin="normal">
					<TextField
						id="first-name"
						inputProps={{
							"data-testid": "register.first-name.input",
						}}
						label={translate("common:register.first-name.label")}
						name="firstName"
						value={firstName}
						onChange={handleChangeFirstName}
					/>
				</FormControl>
				<FormControl fullWidth required margin="normal">
					<TextField
						id="last-name"
						inputProps={{
							"data-testid": "register.last-name.input",
						}}
						label={translate("common:register.last-name.label")}
						name="lastName"
						value={lastName}
						onChange={handleChangeLastName}
					/>
				</FormControl>
				<FormControl fullWidth required>
					<TextField
						error={passwordErrors.length > 0}
						helperText={
							(passwordErrors.length > 0 && (
								<ul className={styles.textfield__helper}>
									{passwordErrors.map((error) => (
										<li key={error}>{error}</li>
									))}
								</ul>
							)) ||
							""
						}
						id="password"
						inputProps={{
							"data-testid": "register.password.input",
						}}
						label={translate("common:register.password.label")}
						type="password"
						value={password || ""}
						onChange={handleChangePassword}
					/>
				</FormControl>
				<FormControl fullWidth required margin="normal">
					<TextField
						error={!!passwordConfirmationError}
						helperText={passwordConfirmationError}
						id="password-confirmation"
						inputProps={{
							"data-testid": "register.password-confirmation.input",
						}}
						label={translate("common:register.password-confirmation.label")}
						name="passwordConfirmation"
						type="password"
						value={passwordConfirmation}
						onChange={handleChangePasswordConfirmation}
					/>
				</FormControl>
				<div className={styles["inline-text"]}>
					<Checkbox checked={termsAccepted} color="primary" onChange={handleChangeTerms} />
					{translate("common:register.conditions.text")}
					&nbsp;
					<LinkButton onClick={handleDownloadTerms}>
						{translate("common:register.conditions.button")}
					</LinkButton>
				</div>
				<CustomButton
					fullWidth
					color="primary"
					data-testid="register.btn"
					disabled={
						passwordErrors.length > 0 ||
						!!passwordConfirmationError ||
						!termsAccepted ||
						!email ||
						!firstName.trim() ||
						!lastName.trim()
					}
					id="joinProjectSubmitButton"
					type="submit"
					variant="contained"
				>
					{translate("common:register.create-account.button")}
				</CustomButton>
			</form>
		</div>
	);
};

export default Register;
