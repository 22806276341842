import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import { IconComponent, CustomIconButton, icon, CustomPopOver } from "../../../../../../../../common/components";
import { ApiService, CollabService } from "../../../../../../../../api";
import { translate } from "../../../../../../../../common/providers";
import styles from "./InformationsDeletedOverlay.module.css";
import { dangerousSetInnerHTML } from "../../../../../../../../common/utils";
import { updateNode } from "../../../../../../slice/pdf/pdf-slice";

const TOPGAP = -16;

function VersionDeletedText({ className, text }) {
	return dangerousSetInnerHTML({ className, text });
}

export default function InformationsDeletedOverlay({
	height,
	hoveredWarning,
	deletedData,
	priority,
	onSetPriority,
	setHoveredWarning,
}) {
	const dispatch = useDispatch();
	const infosV1 = deletedData?.deletedInfosIds;
	const v2Id = deletedData?.informationId;
	const v2Rectangles = deletedData?.rectangles;
	const [open, setOpen] = useState(false);
	const [top, setTop] = useState(0);
	const [openPopInfoVersionStatus, setOpenPopInfoVersionStatus] = useState(false);
	const docId = useSelector(({ srDocument }) => srDocument.documentId);
	const isSelecting = useSelector(({ srDocument }) => srDocument.isSelecting);
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		if (Array.isArray(v2Rectangles) && v2Rectangles.length > 0) {
			setTop(height * ((v2Rectangles[0].y1 + v2Rectangles[0].y2) / 2));
		}
	}, [height, v2Rectangles]);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	});

	useEffect(() => {
		if (hoveredWarning !== v2Id) {
			setOpen(false);
		}
	}, [hoveredWarning, v2Id]);

	const getDeletedData = (documentId, informationsId) => {
		const stringInfos = [];
		CollabService.getListStringContent({ documentId }, informationsId, cancelTokenSourceRef.current.token)
			.then((data) => {
				data.map((a, i) => stringInfos.push({ id: informationsId[i], value: a.value }));
				dispatch(
					updateNode({
						mode: "deletedData",
						pageConditions: { page: deletedData.pageStart },
						informationId: deletedData.informationId,
						updates: [{ property: "deletedText", value: stringInfos }],
					})
				);
			})
			.catch((err) => console.error(err));
	};
	useEffect(
		() => {
			if (!deletedData?.deletedText && open) {
				getDeletedData(docId, infosV1);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, open]
	);
	useEffect(() => {
		if (priority !== "delete") {
			setOpen(false);
		}
	}, [priority]);
	const handleMouseEnter = () => {
		if (!isSelecting) {
			setHoveredWarning(v2Id);
			setOpen(true);
			onSetPriority("delete");
		}
	};

	const handleClose = (e) => {
		e?.stopPropagation();
		setOpen(false);
	};

	const contents = {
		text: deletedData?.deletedText,
		title: translate(
			deletedData?.deletedText?.length > 1
				? "smart-review.version-icon.version-deleted-contents"
				: "smart-review.version-icon.version-deleted-content",
			{ count: deletedData?.deletedText?.length }
		),
	};

	const handleOpenPop = () => {
		setOpenPopInfoVersionStatus(true);
	};

	return (
		<>
			<div
				key={v2Id}
				className={styles.versionIcon}
				role="presentation"
				style={{
					top: top + TOPGAP,
				}}
				type="pointer"
				onClick={handleClose}
				onMouseEnter={handleMouseEnter}
			>
				<CustomPopOver
					content={
						<>
							<div className={styles.content}>
								<div className={styles.modal__actions}>
									<div>
										<br />
										<CustomPopOver
											hovered
											content={translate("smart-review.version-info-icon.deleted")}
											open={openPopInfoVersionStatus}
											placement="bottom"
											onClose={() => setOpenPopInfoVersionStatus(false)}
										>
											<span role="presentation" onMouseEnter={handleOpenPop}>
												<IconComponent
													className={styles.icon}
													fontSize="24px"
													icon={icon.faInfoCircle}
													size="lg"
												/>
											</span>
										</CustomPopOver>
									</div>
									<CustomIconButton
										icon={icon.faTimes}
										iconClassName={styles.icon}
										onClick={handleClose}
									/>
								</div>
								<div className={styles.deletedContents}>
									<h6>{contents.title}</h6>
									{deletedData?.deletedText &&
										contents?.text.map((text, i) => (
											<div key={text?.id}>
												{i !== 0 && <Divider />}
												<VersionDeletedText
													className={styles.deletedContent}
													text={text?.value}
												/>
											</div>
										))}
								</div>
							</div>
						</>
					}
					open={open}
					placement="bottom"
					onClose={handleClose}
				>
					<span role="presentation" onClick={handleClose}>
						<IconComponent
							className={styles.deletedIcon}
							color="var(--color-red)"
							icon={icon.faMinusCircle}
							size="lg"
						/>
					</span>
				</CustomPopOver>
			</div>
		</>
	);
}
