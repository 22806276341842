import React from "react";
import { useSelector } from "react-redux";
import { CustomTooltip, InfiniteList2, CustomButtonLink, EmptyState } from "../../..";
import { NavUtils } from "../../../../../navigation";
import { translate } from "../../../../providers";
import styles from "./DocumentList.module.css";

const DocumentList = ({ documentRequest, isPopUp = false, searchValue, totalDocuments }) => {
	const projectId = useSelector(({ context }) => context.project?.id);
	const rowRenderer = (row) => (
		<div key={row.documentId} className={styles.documentRow} data-header={!!row.isHeader}>
			<CustomTooltip showWhenOverflow title={row.documentName}>
				<div className={`${styles["cell--30"]} ${styles.cell} ${styles.documentName}`}>{row.documentName} </div>
			</CustomTooltip>
			<div className={`${styles["cell--70"]} ${styles.cell}`}>
				<CustomButtonLink
					to={NavUtils.goToSmartReview({
						projectId,
						documentId: row.documentId,
					})}
					variant="outlined"
				>
					{row.infoCount}
				</CustomButtonLink>
			</div>
		</div>
	);
	const accessToDocumentsButton = (
		<CustomButtonLink
			color="primary"
			data-testid="accessToDocument.btn"
			to={NavUtils.goToDocumentCenter(projectId)}
			variant="contained"
		>
			{translate("dashboard.my-dashboard.my-reading-list.no-document-imported-btn")}
		</CustomButtonLink>
	);
	const noDocumentRender = (
		<EmptyState
			btn1={accessToDocumentsButton}
			content={translate("dashboard.my-dashboard.my-reading-list.no-document-imported-text")}
			link={translate("dashboard.my-dashboard.my-reading-list.no-document-imported-link")}
			linkAddress={translate("common:redirection.documents-knowledge-base.link")}
			title={translate("dashboard.my-dashboard.my-reading-list.no-document-imported-title")}
		/>
	);
	const noSearchRender = (
		<>
			{searchValue && searchValue.length !== 0 && (
				<EmptyState title={translate("dashboard.my-dashboard.my-reading-list.no-filter-search-match")} />
			)}
		</>
	);

	return (
		<div className={styles.container}>
			<div className={styles.documentRow}>
				<div className={`${styles["cell--30"]} ${styles.cell__header}`}>
					{translate("common:reading-list.documentList.header.document")}
				</div>
				<div className={`${styles["cell--70"]} ${styles.cell__header}`}>
					{translate("common:reading-list.documentList.header.content")}
					<div className={styles["cell__header--subtext"]}>
						{translate("common:reading-list.documentList.header.content--helper")}
					</div>
				</div>
			</div>
			{totalDocuments === 0 ? (
				<>{noDocumentRender}</>
			) : (
				<div className={styles.listContainer} data-ispopup={isPopUp}>
					<InfiniteList2 noRowsText={noSearchRender} request={documentRequest} rowRenderer={rowRenderer} />
				</div>
			)}
		</div>
	);
};

export default DocumentList;
