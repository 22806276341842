import { useLocation, useNavigate } from "react-router-dom";
import { usePrevious } from "../../../common/hooks";

const regex = /^\/projects\/[0-9]+\/.*/;

const HandleBackButton = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const prevLocation = usePrevious(location);
	const locationPath = location.pathname;
	const prevLocationPath = prevLocation?.pathname || "";
	window.onpopstate = () => {
		if (locationPath !== prevLocationPath && locationPath && prevLocationPath) {
			if (regex.test(locationPath) && !regex.test(prevLocationPath)) {
				window.location.href = location.pathname;
			} else {
				navigate(location.pathname);
			}
		}
	};
	return null;
};

export default HandleBackButton;
