import React from "react";
import styles from "./ErrorBoundary.module.css";
import { translate } from "../../../providers";
import ErrorMessage from "../error-message/ErrorMessage";

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null };
	}

	componentDidCatch(error) {
		this.setState({
			hasError: true,
			error,
		});
	}

	render() {
		const { hasError, error } = this.state;
		const { children } = this.props;
		if (hasError) {
			return (
				<div className={styles.errorBoundary__container}>
					<ErrorMessage
						content={
							<div>
								{translate("common:error-boundary.user-message.content")}
								<br />
								<div className={styles.errorBoundary__message}>{error.message}</div>
							</div>
						}
						title={translate("common:error-boundary.user-message.title")}
					/>
				</div>
			);
		}
		return children;
	}
}
