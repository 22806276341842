import { Paper, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsProvider, translate } from "../../common/providers";
import { exportView } from "../../navigation";
import { getUser } from "../../common/slice";
import UpdateProfileDialog from "./components/update-profile-dialog/UpdateProfileDialog";
import { MyService, ApiService } from "../../api";
import styles from "./Profile.module.css";
import { CustomButton, ViewBanner, createNotification } from "../../common/components";

const Profile = () => {
	const [openUpdateProfile, setOpenUpdateProfile] = useState(false);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	const user = useSelector(({ context }) => context.user);
	const language = useSelector(({ context }) => context.language.value);

	useEffect(() => {
		const documentTitle = translate("profile.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "My profile" });
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleResetPassword = () => {
		MyService.changePasswordSso({ locale: language }, cancelTokenSourceRef.current.token)
			.then(() => {
				createNotification({
					type: "success",
					message: translate("common:reset-password-sso.notification.password-changed"),
				});
			})
			.catch((err) => console.error(err));
	};

	const handleOpenUpdateProfile = () => {
		setOpenUpdateProfile((prev) => !prev);
	};

	const handleUserInfoUpdate = (payload) => {
		if (!payload) {
			return;
		}
		MyService.changeInformation(payload, cancelTokenSourceRef.current.token)
			.then(() => {
				dispatch(getUser(ApiService.getCancelTokenSource().token));
			})
			.catch((err) => console.error(err));
	};
	return (
		<>
			<ViewBanner titles={[{ title: translate("navigation:home.edit-my-profile") }]} />
			<Paper classes={{ root: styles.paper }}>
				<Typography className={styles.displayName} component="h3" translate="no" variant="h5">
					{user.displayName}
				</Typography>
				<Typography component="p" translate="no">
					Email : {user.email}
				</Typography>
			</Paper>
			<br />
			<span className={styles.buttons}>
				<CustomButton
					classes={styles.button}
					color="secondary"
					variant="outlined"
					onClick={handleOpenUpdateProfile}
				>
					{translate("profile.update-profile.btn")}
				</CustomButton>
				<CustomButton
					className={styles.button}
					color="secondary"
					variant="outlined"
					onClick={handleResetPassword}
				>
					{translate("profile.change-pwd.btn")}
				</CustomButton>
			</span>
			<UpdateProfileDialog
				open={openUpdateProfile}
				user={user}
				onClose={handleOpenUpdateProfile}
				onUpdate={handleUserInfoUpdate}
			/>
		</>
	);
};

export default exportView({
	path: "/profile",
	component: Profile,
	localesPath: "/profile/locales",
});
