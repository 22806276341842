import React, { useState } from "react";
import { translate } from "../../../../../common/providers";
import { CustomDialog } from "../../../../../common/components";
import UpdateDateRow from "./UpdateDateRow";

const UpdateQADatesDialog = ({ open, close, onUpdateSentDate, onUpdateReceiveDate }) => {
	const [sentDate, setSentDate] = useState("");
	const [receiveDate, setReceiveDate] = useState("");

	const handleSentDateChange = (e) => {
		setSentDate((e.target && e.target.value) || "");
	};

	const handleReceiveDateChange = (e) => {
		setReceiveDate((e.target && e.target.value) || "");
	};

	return (
		<CustomDialog
			displayCloseCross
			isForm
			open={open}
			title={translate("qa-journal.qa-actions.update-qa-dates-dialog.title")}
			onClose={close}
		>
			<UpdateDateRow
				date={sentDate}
				label={translate("qa-journal.qa-actions.update-qa-dates-dialog.textfield.sent-date.label")}
				updateButtonLabel={translate("qa-journal.qa-actions.update-qa-dates-dialog.btn.update-sent-date")}
				onDateChange={handleSentDateChange}
				onUpdateDate={onUpdateSentDate}
			/>

			<UpdateDateRow
				date={receiveDate}
				label={translate("qa-journal.qa-actions.update-qa-dates-dialog.textfield.receive-date.label")}
				updateButtonLabel={translate("qa-journal.qa-actions.update-qa-dates-dialog.btn.update-receive-date")}
				onDateChange={handleReceiveDateChange}
				onUpdateDate={onUpdateReceiveDate}
			/>
		</CustomDialog>
	);
};
export default UpdateQADatesDialog;
