import React, { useState, useEffect } from "react";
import {
	DialogTitle,
	DialogContent,
	DialogActions,
	List,
	ListItem,
	ListItemIcon,
	Checkbox,
	ListItemText,
	CircularProgress,
} from "@mui/material";
import styles from "./UpdateCompanyDialog.module.css";
import { translate } from "../../../../common/providers";
import { ApiService, ProjectTeamService } from "../../../../api";
import { CustomIconButton, icon, GenericDialog, CustomButton } from "../../../../common/components";

export default function UpdateCompanyDialog({ open, onClose, onSubmit, projectId, company }) {
	const [selectedPartners, setSelectedPartners] = useState(company.companies || []);
	const [isLoadingPartners, setIsLoadingPartners] = useState(false);
	const [partners, setPartners] = useState([]);

	const handleCloseAndAction = () => {
		onSubmit(selectedPartners.map((p) => p.id));
		onClose();
	};

	const handleToggle = (partner) => {
		if (selectedPartners.some((p) => p.id === partner.id)) {
			setSelectedPartners((prev) => prev.filter((p) => p.id !== partner.id));
		} else {
			setSelectedPartners((prev) => [...prev, partner]);
		}
	};

	const reset = () => {
		setSelectedPartners([]);
		setIsLoadingPartners(false);
		setPartners([]);
	};

	useEffect(() => {
		setIsLoadingPartners(true);
		const cancelTokenSource = ApiService.getCancelTokenSource();
		ProjectTeamService.getAvailableChildrenFromParentInclusive(
			{ projectId, companyId: company.id },
			"",
			cancelTokenSource.token
		)
			.then((data) => {
				setPartners(Array.isArray(data) ? data : []);
				setIsLoadingPartners(false);
			})
			.catch((err) => {
				console.error(err);
				setIsLoadingPartners(false);
			});
		return () => {
			ApiService.cancelTokens(cancelTokenSource);
		};
	}, [projectId, company.id, company.companies]);

	return (
		<GenericDialog disableBackdropClick fullWidth open={open} onClose={onClose} onExited={reset}>
			<DialogTitle>
				<div className={styles.dialog__title}>
					<span className={styles["dialog__title-text"]}>
						{translate("team-management.update-company-dialog.title")}
					</span>
					<CustomIconButton icon={icon.faTimes} onClick={onClose} />
				</div>
			</DialogTitle>
			<DialogContent dividers className={styles.dialog__content}>
				{!isLoadingPartners && partners.length > 0 && (
					<List>
						{partners.map((partner) => (
							<ListItem key={partner.id} button dense onClick={() => handleToggle(partner)}>
								<ListItemIcon>
									<Checkbox
										disableRipple
										checked={selectedPartners.some((p) => p.id === partner.id)}
										color="primary"
										edge="start"
										inputProps={{ "aria-labelledby": partner.id }}
										tabIndex={-1}
									/>
								</ListItemIcon>
								<ListItemText
									id={partner.id}
									primary={partner.companyName || partner.name}
									secondary={partner.type}
									translate="no"
								/>
							</ListItem>
						))}
					</List>
				)}
				{!isLoadingPartners && partners.length === 0 && (
					<div className={styles.dialog__partners}>
						{translate("team-management.add-team-dialog.empty-partners")}
					</div>
				)}
				{isLoadingPartners && (
					<div className={styles.dialog__partners}>
						<CircularProgress />
					</div>
				)}
			</DialogContent>
			<DialogActions>
				<CustomButton color="primary" variant="outlined" onClick={onClose}>
					{translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton color="primary" variant="contained" onClick={handleCloseAndAction}>
					{translate("common:btn.submit")}
				</CustomButton>
			</DialogActions>
		</GenericDialog>
	);
}
