import { debounce } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ApiService, FavoriteCategoryService } from "../../../../api";
import { CircularLoader, CustomButton, CustomDialog, SearchInput } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import styles from "./CategorySubscriptionsDialog.module.css";

const debounceSearch = debounce((searchFunction) => {
	searchFunction();
}, 1000);

const CategorySubscriptionsDialog = ({ category, onClose, open }) => {
	const projectId = useSelector(({ context }) => context.project?.id);
	const [users, setUsers] = useState([]);
	const [isLoadingUsers, setIsLoadingUsers] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [searchValueDebounced, setSearchValueDebounced] = useState("");
	const cancelTokenSourceRef = useRef();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		setIsLoadingUsers(true);
		if (projectId && category?.id && searchValue === searchValueDebounced) {
			FavoriteCategoryService.getAllUsersByCategory(
				{ projectId, categoryId: category.id, name: searchValue },
				cancelTokenSourceRef.current.token
			)
				.then((data) => setUsers(data))
				.catch((err) => console.error(err))
				.finally(() => setIsLoadingUsers(false));
		}
	}, [projectId, category, searchValue, searchValueDebounced]);
	const handleClearSearchValue = () => {
		setSearchValue("");
		setSearchValueDebounced("");
	};
	const handleChangeSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
		debounceSearch(() => setSearchValueDebounced(value));
	};
	return (
		<CustomDialog
			displayCloseCross
			hideCloseButton
			isForm
			open={open}
			title={category?.name}
			onClose={onClose}
			onSubmit={onClose}
		>
			<SearchInput
				className={styles.searchContainer__textfield}
				label={translate("admin-categories.category-subscription-dialog.search")}
				value={searchValue}
				onChange={handleChangeSearchValue}
				onClearSearch={handleClearSearchValue}
			/>
			{(!isLoadingUsers && (
				<div>
					{Array.isArray(users) &&
						users.length > 0 &&
						users.map((user) => (
							<div key={user.id} className={styles.userRow}>
								{user.name}
								<CustomButton disabled variant={user.isSubscribed ? "outlined" : "contained"}>
									{user.isSubscribed
										? translate("admin-categories.category-subscription-dialog.subscriber")
										: translate("admin-categories.category-subscription-dialog.force subscription")}
								</CustomButton>
							</div>
						))}
				</div>
			)) || (
				<div className={styles.loaderContainer}>
					<CircularLoader />
				</div>
			)}
		</CustomDialog>
	);
};

export default CategorySubscriptionsDialog;
