import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Norm } from "..";
import {
	CustomButton,
	CustomButtonLink,
	CustomDialog,
	SidePanelContainer,
	I18nTranslate,
	SidePanel,
} from "../../../../common/components";
import styles from "./NormDetails.module.css";
import CustomNorm from "../custom-norm/CustomNorm";
import { ApiService, NormsService } from "../../../../api";
import { translate } from "../../../../common/providers";
import { DetailsTable, RelatedNormsTable } from "./tables";
import {
	changeNormFavoriteStatus,
	deleteCustomNorm,
	getNormsInProject,
	updateNorm,
} from "../../slice/normsCenterSlice";

export default function NormDetails({ normId, onActive: onActiveFromParent }) {
	const dispatch = useDispatch();
	const { limit, page, filters } = useSelector(({ normsCenter }) => ({
		limit: normsCenter.limit,
		page: normsCenter.page,
		filters: normsCenter.filters,
	}));
	const [openEdit, setOpenEdit] = useReducer((prev) => !prev, false);
	const [openRelatedNorms, setOpenRelatedNorms] = useReducer((prev) => !prev, false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState({ open: false, onSubmit: null });
	const [selectedNorm, setSelectedNorm] = useState(null);
	const [details, setDetails] = useState(null);
	const cancelTokenSrcRef = useRef(null);
	useEffect(() => {
		cancelTokenSrcRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSrcRef.current);
			cancelTokenSrcRef.current = null;
		};
	}, []);
	useEffect(() => {
		if (!normId) {
			setDetails(null);
			return null;
		}
		const cancelTokenSource = ApiService.getCancelTokenSource();
		NormsService.get({ normId }, cancelTokenSource.token)
			.then(setDetails)
			.catch((err) => console.error(err));
		return () => {
			ApiService.cancelTokens(cancelTokenSource);
		};
	}, [normId]);
	const { norm, relatedNorms } = details || {};
	const isCustom = norm?.type === "MANUAL";
	const normDetails = useMemo(() => !!norm && [norm], [norm]);
	const handleFavorite = () => {
		const favNorm = { ...norm, isFavorite: !norm.isFavorite };
		setDetails((prev) => ({
			...prev,
			norm: favNorm,
		}));
		dispatch(
			changeNormFavoriteStatus({ normId, isFavorite: norm.isFavorite, token: cancelTokenSrcRef.current.token })
		);
	};
	const handleUpdateNorm = (payload) => {
		setOpenEdit();
		dispatch(updateNorm({ normId, payload, token: cancelTokenSrcRef.current.token }))
			.then(unwrapResult)
			.then((data) => {
				setDetails((prev) => ({
					...prev,
					norm: data,
				}));
			})
			.catch((err) => console.error(err));
	};
	const handleSelectRelatedNorms = (row) => {
		setSelectedNorm({ normId: row.uuid, name: row.name });
		setOpenRelatedNorms();
	};
	const handleCloseDialog = () => setOpenDeleteDialog({ open: false, onSubmit: null });
	const handleOpenDialog = () => {
		setOpenDeleteDialog({
			open: true,
			onSubmit: () => {
				dispatch(deleteCustomNorm({ normId, token: cancelTokenSrcRef.current.token }))
					.then(() =>
						dispatch(getNormsInProject({ token: cancelTokenSrcRef.current.token, limit, page, filters }))
					)
					.catch((err) => {
						console.error(err);
					});
				handleCloseDialog();
			},
		});
	};
	return (
		<>
			<SidePanelContainer>
				<h5>{translate("norms-center.norm-details.details")}</h5>
				<DetailsTable isCustom={isCustom} normDetails={normDetails} onFavorite={handleFavorite} />
				<p
					className={`${styles.details__description} ${
						!norm?.description && styles["details__description--empty"]
					}`}
				>
					{norm?.description || translate("norms-center.norm-details.empty-state.description")}
				</p>
				<div className={styles.details__footer}>
					{norm?.link && (
						<CustomButtonLink external openInNewTab color="primary" to={norm.link}>
							{translate("norms-center.norm-details.link")}
						</CustomButtonLink>
					)}
					{norm?.cobazLink && (
						<CustomButtonLink external openInNewTab color="primary" to={norm.cobazLink}>
							{translate("norms-center.norm-details.cobase-link")}
						</CustomButtonLink>
					)}
					{isCustom && (
						<span>
							<CustomButton color="primary" variant="text" onClick={setOpenEdit}>
								{translate("norms-center.norm-details.edit")}
							</CustomButton>

							<CustomButton color="secondary" variant="text" onClick={handleOpenDialog}>
								{translate("norms-center.norm-details.delete")}
							</CustomButton>
						</span>
					)}
				</div>
			</SidePanelContainer>
			<SidePanelContainer>
				<h5>{translate("norms-center.norm-details.related-norms")}</h5>
				<RelatedNormsTable relatedNorms={relatedNorms || []} onSelectRow={handleSelectRelatedNorms} />
			</SidePanelContainer>
			{isCustom && (
				<SidePanel open={openEdit} size={65} onActive={onActiveFromParent} onClose={setOpenEdit}>
					<CustomNorm norm={norm} onClose={setOpenEdit} onUpdate={handleUpdateNorm} />
				</SidePanel>
			)}
			{isCustom && (
				<CustomDialog
					open={openDeleteDialog?.open || false}
					submitLabel={translate("norms-center.norm-details.dialog.submit")}
					title={translate("norms-center.norm-details.dialog.title", { normName: norm?.name || "" })}
					onClose={handleCloseDialog}
					onSubmit={openDeleteDialog?.onSubmit}
				>
					<I18nTranslate
						param={{ normName: norm?.name || "" }}
						translationKey="norms-center.norm-details.dialog.content"
					/>
				</CustomDialog>
			)}
			<Norm
				norm={selectedNorm}
				open={openRelatedNorms}
				onActive={onActiveFromParent}
				onClose={setOpenRelatedNorms}
			/>
		</>
	);
}
