import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../providers";
import StatusButtons from "./StatusButtons";
import StatusIcons from "./StatusIcons";
import { OPERATORS } from "../../../../constants";
import { CustomButton } from "../../../buttons";
import { icon } from "../../../icon-component";
import styles from "./PositionFilter.module.css";
import { useEffectOnce } from "../../../../hooks";
import { isNonEmptyArray } from "../../../../utils";

const STANDARD_POSITIONS = {
	myReview: "myReview",
	othersReview: "othersReview",
	myValidation: "myValidation",
};

export default function PositionFilter({ value = {}, onChange, partners, readOnly = false }) {
	const myCompany = useSelector(({ context }) => context.company);
	const [andOr, setAndOr] = useState(OPERATORS.OR);
	const [fullPartnerSelection, setFullPartnerSelection] = useState([]);
	const [localValue, setLocalValue] = useState([]);
	const [reset, setReset] = useState(false);
	useEffect(() => {
		let tempPartnerList = [
			{ id: STANDARD_POSITIONS.myReview, name: translate("common:filter.status.my-review"), display: true },
			{
				id: STANDARD_POSITIONS.othersReview,
				name: translate("common:filter.status.others-review"),
				display: true,
			},
			{ id: STANDARD_POSITIONS.myValidation, name: myCompany.name, display: true },
		];
		if (isSegFeatureEnabled(SegFlags.PARTNERS)) {
			tempPartnerList = [...tempPartnerList, ...partners];
		}
		tempPartnerList = tempPartnerList.map((partner) => {
			if (localValue.some((lValue) => lValue.key === partner.id)) {
				return { ...partner, display: false };
			}
			return partner;
		});
		setFullPartnerSelection(tempPartnerList);
	}, [partners, myCompany, localValue]);
	useEffectOnce(
		() => {
			let tempValue = [];
			if (value.myReview?.elements) {
				tempValue = [...tempValue, { key: "myReview", value: value.myReview.elements }];
			}
			if (value.othersReview?.elements) {
				tempValue = [...tempValue, { key: "othersReview", value: value.othersReview.elements }];
			}
			if (value.myValidation?.elements) {
				tempValue = [...tempValue, { key: "myValidation", value: value.myValidation.elements }];
			}
			if (Array.isArray(value.positions?.elements) && value.positions?.elements.length > 0) {
				value.positions.elements.forEach((company) => {
					tempValue = [...tempValue, { key: company.companyId, value: company.status }];
				});
			}
			if (tempValue.length === 0) {
				tempValue = [{ key: "myReview", value: [] }];
			}
			if (value?.separator) {
				setAndOr(value.separator);
			}
			setLocalValue(tempValue);
		},
		[value],
		() => value
	);
	useEffect(() => {
		setReset(Object.keys(value).length === 0);
	}, [value]);
	useEffect(() => {
		if (reset) {
			setLocalValue([{ key: "myReview", value: [] }]);
		}
	}, [reset]);
	const clearEmptyPositions = (filterValue) => {
		const tempFilterValue = JSON.parse(JSON.stringify(filterValue));
		if (
			tempFilterValue.myReview?.elements?.length === undefined ||
			tempFilterValue.myReview?.elements?.length === 0
		) {
			delete tempFilterValue.myReview;
		}
		if (
			tempFilterValue.myValidation?.elements?.length === undefined ||
			tempFilterValue.myValidation?.elements?.length === 0
		) {
			delete tempFilterValue.myValidation;
		}
		if (
			tempFilterValue.othersReview?.elements?.length === undefined ||
			tempFilterValue.othersReview?.elements?.length === 0
		) {
			delete tempFilterValue.othersReview;
		}
		if (tempFilterValue?.positions?.elements?.length > 0) {
			tempFilterValue.positions.elements = tempFilterValue.positions.elements.filter(
				(pos) => pos.status?.length > 0
			);
		}
		if (
			tempFilterValue.positions?.elements?.length === undefined ||
			tempFilterValue.positions?.elements?.length === 0
		) {
			delete tempFilterValue.positions;
		}
		if (
			tempFilterValue.myReview === undefined &&
			tempFilterValue.myValidation === undefined &&
			tempFilterValue.othersReview === undefined &&
			tempFilterValue.positions === undefined
		) {
			delete tempFilterValue.separator;
		}
		return tempFilterValue;
	};
	const handleChangeAndOr = (entry) => {
		if (readOnly) {
			return;
		}
		setAndOr(entry);
		const tempValue = { ...value };
		tempValue.separator = entry;
		onChange(clearEmptyPositions(tempValue));
	};
	const handleSetAddNew = () => {
		setLocalValue([
			...localValue,
			{ key: fullPartnerSelection.filter((partner) => partner.display)[0].id, value: [] },
		]);
	};
	const handleChange = (newValue) => {
		newValue.separator = andOr;
		const isDefined = Object.values(newValue).some((v) => v !== undefined && (!Array.isArray(v) || v.length > 0));
		onChange(isDefined ? clearEmptyPositions(newValue) : undefined);
	};
	const handleChangeParams = (key, e) => {
		const filter = value[key]
			? { ...value[key], elements: value[key].elements || [] }
			: { elements: [], is: true, matchAll: false };
		const newValue = e.target.value.toUpperCase();
		if (filter.elements.some((v) => v === newValue)) {
			filter.elements = filter.elements.filter((v) => v !== newValue);
		} else {
			filter.elements = [...filter.elements, newValue];
		}
		handleChange({ ...value, [key]: filter });
		const tempLocalValue = [...localValue];
		setLocalValue(tempLocalValue.map((lValue) => (lValue.key === key ? { key, value: filter.elements } : lValue)));
	};
	const handleClickCompanies = (companyId, e) => {
		const { value: newValue } = e.target;
		const filter = value.positions ? { ...value.positions } : { elements: [], is: true, matchAll: false };
		let companyFilter;
		if (filter.elements.some((c) => c.companyId === companyId)) {
			companyFilter = { ...filter.elements.find((c) => c.companyId === companyId) };
			filter.elements = filter.elements.filter((c) => c.companyId !== companyId);
		} else {
			companyFilter = { companyId, status: [] };
		}
		if (!companyFilter.status) {
			companyFilter.status = [];
		}
		if (companyFilter.status.some((v) => v === newValue)) {
			companyFilter.status = companyFilter.status.filter((v) => v !== newValue);
		} else {
			companyFilter.status = [...companyFilter.status, newValue];
		}
		if (companyFilter.status.length !== 0) {
			filter.elements.push(companyFilter);
		}
		handleChange({ ...value, positions: filter });
		const tempLocalValue = [...localValue];
		setLocalValue(
			tempLocalValue.map((lValue) =>
				lValue.key === companyId ? { key: companyId, value: companyFilter.status } : lValue
			)
		);
	};
	const handleSelectStatus = (key, e) => {
		if (readOnly) {
			return;
		}
		if (
			[STANDARD_POSITIONS.myReview, STANDARD_POSITIONS.othersReview, STANDARD_POSITIONS.myValidation].includes(
				key
			)
		) {
			handleChangeParams(key, e);
		} else {
			handleClickCompanies(key, e);
		}
	};
	const handleSetActiveCompany = (oldKey, newKey) => {
		const tempLocalValue = [...localValue];
		setLocalValue(
			tempLocalValue.map((lValue) => (lValue.key === oldKey ? { value: lValue.value, key: newKey } : lValue))
		);
		const tempValue = { ...value };
		// myReview, othersReview, companiesReview -> myReview, othersReview, companiesReview
		if (
			[STANDARD_POSITIONS.myReview, STANDARD_POSITIONS.othersReview, STANDARD_POSITIONS.myValidation].includes(
				oldKey
			) &&
			[STANDARD_POSITIONS.myReview, STANDARD_POSITIONS.othersReview, STANDARD_POSITIONS.myValidation].includes(
				newKey
			)
		) {
			delete Object.assign(tempValue, { [newKey]: tempValue[oldKey] })[oldKey];
		}
		// myReview, othersReview, companiesReview -> partners review
		if (
			[STANDARD_POSITIONS.myReview, STANDARD_POSITIONS.othersReview, STANDARD_POSITIONS.myValidation].includes(
				oldKey
			) &&
			![STANDARD_POSITIONS.myReview, STANDARD_POSITIONS.othersReview, STANDARD_POSITIONS.myValidation].includes(
				newKey
			)
		) {
			if (Array.isArray(tempValue?.positions?.elements) && tempValue.positions.elements.length > 0) {
				tempValue.positions.elements = [
					...tempValue.positions.elements,
					{ companyId: newKey, status: tempValue[oldKey].elements },
				];
			} else if (isNonEmptyArray(tempValue[oldKey]?.elements)) {
				tempValue.positions = {
					is: true,
					matchAll: false,
					elements: [{ companyId: newKey, status: tempValue[oldKey]?.elements }],
				};
			}
			delete tempValue[oldKey];
		}
		// partners review -> myReview, othersReview, companiesReview
		if (
			![STANDARD_POSITIONS.myReview, STANDARD_POSITIONS.othersReview, STANDARD_POSITIONS.myValidation].includes(
				oldKey
			) &&
			[STANDARD_POSITIONS.myReview, STANDARD_POSITIONS.othersReview, STANDARD_POSITIONS.myValidation].includes(
				newKey
			)
		) {
			tempValue[newKey] = {
				elements: tempValue.positions?.elements.find((pos) => pos.companyId === oldKey).status,
				is: true,
				matchAll: false,
			};
			if (tempValue.positions?.elements.length === 1) {
				delete tempValue.positions;
			} else {
				tempValue.positions.elements = tempValue.positions?.elements.filter((pos) => pos.companyId !== oldKey);
			}
		}
		// partners review -> partners review
		if (
			![STANDARD_POSITIONS.myReview, STANDARD_POSITIONS.othersReview, STANDARD_POSITIONS.myValidation].includes(
				oldKey
			) &&
			![STANDARD_POSITIONS.myReview, STANDARD_POSITIONS.othersReview, STANDARD_POSITIONS.myValidation].includes(
				newKey
			)
		) {
			tempValue.positions.elements = tempValue.positions.elements.map((lValue) =>
				lValue.companyId === oldKey ? { status: lValue.status, companyId: newKey } : lValue
			);
		}
		if (
			tempValue?.myReview !== undefined ||
			tempValue?.othersReview !== undefined ||
			tempValue?.myValidation !== undefined ||
			tempValue?.positions?.elements?.some((el) => el.status !== undefined)
		) {
			tempValue.separator = andOr;
			onChange(tempValue);
		}
	};
	const handleRemove = (keyToRemove) => {
		const tempLocalValue = [...localValue];
		setLocalValue(tempLocalValue.filter((lValue) => lValue.key !== keyToRemove));
		const tempParentValue = { ...value };
		if (
			[STANDARD_POSITIONS.myReview, STANDARD_POSITIONS.othersReview, STANDARD_POSITIONS.myValidation].includes(
				keyToRemove
			)
		) {
			delete tempParentValue[keyToRemove];
		} else {
			tempParentValue.positions.elements = tempParentValue.positions.elements.filter(
				(company) => company.companyId !== keyToRemove
			);
			if (!Array.isArray(tempParentValue.positions.elements) || tempParentValue.positions.elements.length === 0) {
				delete tempParentValue.positions;
			}
		}
		tempParentValue.separator = andOr;
		onChange(tempParentValue);
	};

	return (
		<div>
			{isNonEmptyArray(localValue) && (
				<>
					<StatusIcons size="md" />
					{localValue.map((lValue, index) => (
						<StatusButtons
							key={lValue.key}
							showUnreviewed
							activeCompany={lValue.key}
							andOr={andOr}
							order={index}
							partnersSelection={fullPartnerSelection}
							positionValue={lValue.value}
							readOnly={readOnly}
							onChangeAndOr={handleChangeAndOr}
							onRemove={handleRemove}
							onSelectStatus={handleSelectStatus}
							onSetActiveCompany={handleSetActiveCompany}
						/>
					))}
				</>
			)}
			{Array.isArray(fullPartnerSelection) &&
				fullPartnerSelection.filter((partner) => partner.display).length > 0 && (
					<div className={styles.addFilter__container}>
						<CustomButton
							disabled={readOnly}
							size="sm"
							startIcon={icon.faPlus}
							variant="outlined"
							onClick={handleSetAddNew}
						>
							{translate("common:filters.select-filter.title")}
						</CustomButton>
					</div>
				)}
		</div>
	);
}
