import React, { useState, useRef, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import {
	createNotification,
	CustomButton,
	icon,
	IconComponent,
	LimitedTextField,
	OptionsPanelContainer,
} from "../../../../../../../common/components";
import { ApiService, FeatureManagementService } from "../../../../../../../api";
import { translate } from "../../../../../../../common/providers";
import styles from "./Info.module.css";

function StyledAccordionDetails({ children }) {
	return (
		<AccordionDetails
			classes={{
				root: styles.StyledAccordionDetails__root,
			}}
		>
			{children}
		</AccordionDetails>
	);
}
export default function Info({ feat: { id, description }, onUpdateRow }) {
	const cancelTokenSourceRef = useRef();
	const [newDescription, setDescription] = useState(description || "");

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [id]);

	const handleChangeDescription = (value) => {
		setDescription(value);
	};
	const handleSubmit = () =>
		FeatureManagementService.updateFeatureDescription(
			{ featureId: id },
			{ description: newDescription },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				onUpdateRow();
				createNotification({ type: "success", message: translate("dsi.project-details.name-update.success") });
			})
			.catch((err) => console.error(err));
	return (
		<OptionsPanelContainer>
			<div className={styles.container}>
				<Accordion defaultExpanded>
					<AccordionSummary expandIcon={<IconComponent icon={icon.faChevronDown} />}>
						{translate("dsi.package-management.panel.info.description")}
					</AccordionSummary>
					<StyledAccordionDetails>
						<div className={styles.text}>
							<LimitedTextField
								fullWidth
								multiline
								maxLength={512}
								placeholder={translate("dsi.package-management.panel.info.description")}
								rows={newDescription.length > 3 ? 4 : 1}
								value={newDescription}
								onChange={handleChangeDescription}
							/>
							<CustomButton color="primary" onClick={handleSubmit}>
								{translate("common:btn.submit")}
							</CustomButton>
						</div>
					</StyledAccordionDetails>
				</Accordion>
			</div>
		</OptionsPanelContainer>
	);
}
