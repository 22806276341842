import React, { useState, useRef } from "react";

import { InputAdornment } from "@mui/material";
import { ButtonsTextField, CustomIconButton, icon } from "../..";
import styles from "../multiple-email-input/MultipleEmailInput.module.css";
import { I18nTranslate } from "../../i18n";

export default function MultipleWordInput({
	disableClearAll = false,
	errorMessage,
	onChange,
	placeholder = "",
	wordObjects = [],
}) {
	const [value, setValue] = useState("");
	const [hasError, setHasError] = useState(false);
	const emailInputRef = useRef(null);
	const handleChange = (e) => {
		setValue(e.target.value);
		setHasError(false);
	};
	const handleKeyDown = (evt) => {
		if (["Enter", "Tab", ","].includes(evt.key)) {
			evt.preventDefault();
			const v = value.trim();
			if (v.length === 0) {
				return;
			}
			if (!wordObjects.some((wordObj) => wordObj.toLowerCase() === v.toLowerCase())) {
				onChange([...wordObjects, v]);
				setValue("");
			} else {
				setHasError(true);
			}
		}
	};
	const handleDeleteWord = (index) => {
		wordObjects.splice(index, 1);
		onChange([...wordObjects]);
	};
	const handleDeleteAll = () => {
		onChange([]);
	};
	const handleResetValue = () => {
		setValue("");
		setHasError(false);
	};
	const handleSuppr = () => {
		if (wordObjects.length > 0) {
			const tempWordObjects = [...wordObjects];
			tempWordObjects.splice(-1);
			onChange(tempWordObjects);
		}
	};
	return (
		<>
			<ButtonsTextField
				ref={emailInputRef}
				displayField="word"
				helperText={
					value && (
						<I18nTranslate
							param={{
								hintClassName: styles.textfield__hint,
								enterClassName: styles["textfield__hint--grey"],
							}}
							translationKey="common:multiple-email-input.text-field.hint"
						/>
					)
				}
				InputProps={
					value
						? {
								endAdornment: (
									<InputAdornment position="end">
										<CustomIconButton icon={icon.faTimes} onClick={handleResetValue} />
									</InputAdornment>
								),
						  }
						: {}
				}
				items={wordObjects.map((worObj) => ({
					word: worObj,
					color: "primary",
					deletable: true,
				}))}
				keyField="word"
				placeholder={placeholder}
				value={value}
				onChange={handleChange}
				onClearItems={disableClearAll ? null : handleDeleteAll}
				onClickItem={(_, index) => handleDeleteWord(index)}
				onDeleteItem={(_, index) => handleDeleteWord(index)}
				onKeyDown={handleKeyDown}
				onSuppr={handleSuppr}
			/>
			{hasError && <span className={styles["errors-container__list"]}>{errorMessage}</span>}
		</>
	);
}
