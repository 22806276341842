import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Paper } from "@mui/material";
import { getRandomInt } from "../../../../../../common/utils";
import styles from "./RedressSplit.module.css";

const ORIGIN_CONTENT_COLOR = "var(--status-no-20)";

export default function RedressSplit({ lines, loading, handleSplit }) {
	const [displayedLines, setDisplayedLines] = useState(lines);
	const [splits, setSplits] = useState([]);

	useEffect(() => {
		handleSplit(splits);
		return () => {
			handleSplit([]);
		};
	}, [splits, handleSplit]);

	useEffect(() => {
		setDisplayedLines(lines);
		setSplits([]);
	}, [lines]);

	function processLineColor(splitArray) {
		if (!Array.isArray(splitArray) || splitArray.length === 0) {
			setDisplayedLines(
				displayedLines.map((line) => {
					line.color = "";
					return line;
				})
			);
			return;
		}
		let splitColor = ORIGIN_CONTENT_COLOR;
		const newLines = displayedLines.map((line) => {
			if (splitArray.some((l) => l.id === line.id)) {
				if (!!line.color && line.color !== splitColor) {
					splitColor = line.color;
				} else {
					splitColor = `rgba(${[getRandomInt(255), getRandomInt(255), getRandomInt(255), 0.4].join(",")})`;
				}
			}
			line.color = splitColor;
			return line;
		});
		setDisplayedLines(newLines);
	}
	function processLine(line) {
		if (!line) {
			return;
		}
		let splitArray = splits;
		if (splitArray.some((l) => l.id === line.id)) {
			splitArray = splitArray.filter((l) => l.id !== line.id);
		} else {
			splitArray.push(line);
		}
		processLineColor(splitArray);
		setSplits(splitArray);
	}

	return (
		<Paper className={styles.container}>
			{loading ? (
				<CircularProgress className={styles.circularProgress} size={60} />
			) : (
				<div>
					{displayedLines.map((line, index) => (
						<Button
							key={line.id}
							className={styles["redress-split__button"]}
							disabled={index === 0}
							style={{
								backgroundColor:
									index === 0 ? ORIGIN_CONTENT_COLOR : line.color || "var(--color-white)",
							}}
							onClick={() => processLine(line)}
						>
							{line.text}
						</Button>
					))}
				</div>
			)}
		</Paper>
	);
}
