const has = Object.prototype.hasOwnProperty; // cache the lookup once, in module scope.
export const hasProperty = (object, key) => has.call(object, key);
export const arraymove = (arr, fromIndex, toIndex) => {
	const element = arr[fromIndex];
	arr.splice(fromIndex, 1);
	arr.splice(toIndex, 0, element);
	return arr;
};

export const filterObjectKeys = (obj, filterCallback) =>
	Object.keys(obj)
		.filter((key) => filterCallback(key))
		.reduce((filtered, key) => {
			filtered[key] = obj[key];
			return filtered;
		}, {});

export const isNonEmptyObject = (obj) => obj && typeof obj === "object" && Object.keys(obj).length > 0;

export const handleCheckInTree = ({ row, rowKey, isTree, checkedElements, predecessors, handleResult }) => {
	let newCheckedElements = [];
	let newPredecessors = [];
	const { [rowKey]: identifier, ancestors } = row;

	if (checkedElements.some((element) => element === identifier)) {
		newCheckedElements = checkedElements.filter((element) => element !== identifier);
		if (isTree) {
			newCheckedElements = newCheckedElements.filter(
				(e) =>
					!(
						!ancestors.includes(e) &&
						predecessors.some((p) => (p.list.includes(e) || p.checked === e) && p.list.includes(identifier))
					)
			);
			newPredecessors = [...predecessors.filter((p) => !p.list.includes(identifier) && p.checked !== identifier)];
			const lastAncestor = ancestors[ancestors.length - 1];
			if (lastAncestor && ancestors.length > 1) {
				newPredecessors.push({ checked: lastAncestor, list: [...ancestors.slice(0, ancestors.length - 1)] });
			}
		}
	} else {
		newCheckedElements = [...checkedElements, identifier];
		if (isTree) {
			newCheckedElements = [...newCheckedElements, ...ancestors];
			newCheckedElements = newCheckedElements.filter((e, index) => newCheckedElements.indexOf(e) === index);
			if (ancestors.length > 0) {
				newPredecessors = [
					...predecessors.filter((p) => !ancestors.includes(p.checked)),
					{ checked: identifier, list: [...ancestors] },
				];
			} else {
				newPredecessors = [...predecessors.filter((p) => !ancestors.includes(p.checked))];
			}
		}
	}
	handleResult({ newCheckedElements, newPredecessors });
};

export const clearEmpties = (obj) => {
	for (const k in obj) {
		if (!obj[k] || typeof obj[k] !== "object") {
			continue;
		}
		clearEmpties(obj[k]);
		if (Object.keys(obj[k]).length === 0) {
			delete obj[k];
		}
	}
	return obj;
};

export const clearFirstLevelEmpties = (obj) => {
	const newObj = { ...obj };
	Object.keys(newObj).forEach((k) => {
		if (
			[undefined, null].includes(newObj[k]) ||
			!isNonEmptyObject(newObj[k]) ||
			(Array.isArray(newObj[k]) && newObj[k].length === 0)
		) {
			delete newObj[k];
		}
	});
	return newObj;
};
export const shallowEqual = (object1, object2) => {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}
	for (const key of keys1) {
		if (object1[key].length !== object2[key].length) {
			return false;
		}
	}

	return true;
};
