import { debounce, InputAdornment, TablePagination, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { icon } from "@fortawesome/fontawesome-svg-core";
import styles from "./AddMembersSidePanel.module.css";
import { ApiService } from "../../../../api";
import { translate } from "../../../providers";
import SidePanel from "../side-panel/SidePanel";
import SidePanelContent from "../side-panel-content/side-panel-content/SidePanelContent";
import { CustomButton, CustomIconButton } from "../../buttons";
import { UsersTable } from "../../table";

const debounceSearch = debounce((searchFunction) => {
	searchFunction();
}, 1000);
const rowsPerPageOptions = [15, 30, 100];
export default function AddMembersSidePanel({
	addToProjectLabel = "",
	autoTranslate = "yes",
	availableUsers = [],
	availableUsersLoading = false,
	checkedUsers = [],
	newMembersLabel = "",
	newMembersOnly,
	onAddPrefilledEmail,
	onAddToProject = null,
	onChangeAvailableUsersLoading,
	onChangeCheckedUsers = null,
	onChangeOpenDialog,
	onClose = null,
	onSearchUsers = null,
	open = false,
	title = "",
	totalAvailableUsers = 0,
}) {
	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		if (!open) {
			return;
		}
		if (newMembersOnly) {
			onChangeOpenDialog(true);
		} else {
			onSearchUsers(page, rowsPerPage);
		}
	}, [open, onSearchUsers, newMembersOnly, page, rowsPerPage, onChangeOpenDialog]);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const handleCheckUser = (user) => {
		const existingIndex = checkedUsers.findIndex((u) => u.email === user.email);
		const newCheckedUsers = [...checkedUsers];
		if (existingIndex >= 0) {
			newCheckedUsers.splice(existingIndex, 1);
		} else {
			newCheckedUsers.push(user);
		}
		onChangeCheckedUsers(newCheckedUsers);
	};
	const handleCheckAll = () => {
		const checked = availableUsers.length === checkedUsers.length;
		if (checked) {
			onChangeCheckedUsers([]);
		} else {
			onChangeCheckedUsers(availableUsers);
		}
	};
	const handleOpenDialog = () => {
		onChangeOpenDialog(true);
		onAddPrefilledEmail("");
	};
	const handleOpenPrefilledDialog = () => {
		onChangeOpenDialog(true);
		onAddPrefilledEmail(searchText);
	};
	const handleClose = () => {
		setSearchText("");
		onChangeCheckedUsers([]);
		onClose();
	};
	const handleResetSearchText = () => {
		onChangeAvailableUsersLoading(true);
		setSearchText("");
		setPage(0);
		onSearchUsers(0, rowsPerPage);
	};
	const handleChangeSearchText = (event) => {
		const filter = event?.target?.value || "";
		onChangeAvailableUsersLoading(true);
		setSearchText(filter);
		setPage(0);
		debounceSearch(() => onSearchUsers(0, rowsPerPage, filter));
	};
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const handleAddToProject = () => {
		setSearchText("");
		onAddToProject();
	};
	const addButton = () => (
		<CustomButton color="primary" variant="outlined" onClick={handleOpenDialog}>
			{newMembersLabel}
		</CustomButton>
	);
	return (
		<SidePanel open={open && !newMembersOnly} size={65} onClose={handleClose}>
			<SidePanelContent title={translate("common:component.add-members-side-panel.title")} onClose={handleClose}>
				<div className={styles["side-panel__content"]}>
					<div className={styles["side-panel__header"]}>
						<div className={styles.title} translate={autoTranslate}>
							{title}
						</div>
						<div className={styles.header__buttons}>
							{addButton()}
							<CustomButton
								autoTranslate={autoTranslate}
								color="primary"
								disabled={checkedUsers.length === 0}
								variant="contained"
								onClick={handleAddToProject}
							>
								{addToProjectLabel}
							</CustomButton>
						</div>
					</div>
					<TextField
						className={styles["search-input"]}
						data-testid="add.member.search.field"
						disabled={
							!availableUsersLoading && Array.isArray(availableUsers) && availableUsers.length === 0
						}
						InputProps={
							searchText
								? {
										endAdornment: (
											<InputAdornment position="end">
												<CustomIconButton
													icon={icon.faTimesCircleRegular}
													onClick={handleResetSearchText}
												/>
											</InputAdornment>
										),
								  }
								: null
						}
						label={translate("common:component.add-members-side-panel.search-field.label")}
						size="small"
						value={searchText}
						variant="outlined"
						onChange={handleChangeSearchText}
					/>
					<UsersTable
						checkedUsers={checkedUsers}
						className={styles["side-panel__users-list"]}
						emptyStateButton={() => addButton()}
						isLoading={availableUsersLoading}
						searchText={searchText}
						users={availableUsers}
						onCheckAll={handleCheckAll}
						onCheckUser={handleCheckUser}
						onOpenDialog={handleOpenPrefilledDialog}
					/>
					<TablePagination
						className={styles["side-panel__pagination"]}
						component="div"
						count={totalAvailableUsers}
						page={page}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={rowsPerPageOptions}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</div>
			</SidePanelContent>
		</SidePanel>
	);
}
