import { Divider } from "@mui/material";
import React from "react";
import { InfiniteList } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import ReqRow from "../req-row/ReqRow";
import styles from "./ReqList.module.css";
import ReqListEmptyState from "./ReqListEmptyState";
import ReqListLoader from "./ReqListLoader";

export default function ReqList({
	totalRequirementsInProject,
	hasMore = false,
	isLoadingReqs,
	totalElements = 0,
	requirements = [],
	onLoadMore,
	onSelectRequirement,
	selectedRequirement,
}) {
	const isRowLoaded = ({ index }) => !hasMore || index < requirements.length;
	const rowRenderer = ({ key, index, style }) => {
		const { id, clientRef, content } = requirements[index];
		return (
			<ReqRow
				key={key}
				clientRef={clientRef}
				content={content}
				id={id}
				selected={selectedRequirement && selectedRequirement.id === id}
				style={style}
				onClick={() => onSelectRequirement(id)}
			/>
		);
	};
	const noRowsRenderer = () =>
		requirements.length === 0 && isLoadingReqs ? <ReqListLoader /> : <ReqListEmptyState />;
	const handleLoadMore = () => onLoadMore();
	return (
		<div className={styles["req-list"]}>
			<div className={styles["req-list__counter"]}>
				{translate("requirement-matrix.req-list.result-counter", { totalElements, totalRequirementsInProject })}
			</div>
			<Divider />
			<InfiniteList
				className={styles["req-list__infinite-list"]}
				currentTotalElements={requirements.length}
				height={1080}
				isRowLoaded={isRowLoaded}
				loadMoreRows={handleLoadMore}
				noRowsRenderer={noRowsRenderer}
				rowHeight={75}
				rowRenderer={rowRenderer}
				threshold={45}
				totalElements={totalElements}
				width={1920}
			/>
		</div>
	);
}
