import React, { useRef, useEffect, useState } from "react";
import { progressStepColor, adjustValue } from "../utils/utils";
import styles from "./ProgressBar.module.css";

export default function ProgressBar({ value, label = "", className = "" }) {
	const [fits, setFits] = useState(false);
	const labelRef = useRef(null);
	const barRef = useRef(null);
	const adjustedValue = adjustValue(value);
	const frontColor = progressStepColor(adjustedValue);
	useEffect(() => {
		const calculateFits = () => {
			setFits((barRef.current?.clientWidth || 0) * adjustedValue > (labelRef.current?.clientWidth || 0) + 10);
		};
		calculateFits();
		window.addEventListener("resize", calculateFits);
		return () => {
			window.removeEventListener("resize", calculateFits);
		};
	}, [adjustedValue]);
	return (
		<div ref={barRef} className={`${styles.progressbar__backbar} ${className}`}>
			<div
				className={styles.progressbar__frontbar}
				style={{
					width: `${adjustedValue * 100}%`,
					backgroundColor: frontColor,
				}}
			>
				<div
					ref={labelRef}
					className={`${styles.progressbar__label} ${
						(fits && styles["progressbar__label--inside"]) || styles["progressbar__label--outside"]
					}`}
					style={{
						marginLeft: fits
							? `calc( 100% - ${(labelRef.current?.clientWidth || 0) + 5}px)`
							: "calc( 100% + 5px)",
					}}
				>
					{label}
				</div>
			</div>
		</div>
	);
}
