import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	ContentService,
	RequirementParameterService,
	DocumentService,
	MyService,
	VersionRedressmentService,
	TransverseService,
	InformationLinkService,
} from "../../../../api";

export const getDocumentDetails = createAsyncThunk("srDocument/getDocumentDetails", ({ docId, token }) =>
	MyService.getDocumentDetails({ docId }, token)
);

export const getDocumentInformation = createAsyncThunk("srDocument/getDocumentInformation", ({ docId, token }) =>
	DocumentService.getInformation({ docId }, token)
);

export const getDocumentPhysicalDetails = createAsyncThunk(
	"srDocument/getDocumentPhysicalDetails",
	({ docId, token }) => DocumentService.getDocumentPhysicalDetails({ docId }, token)
);

export const getVersionStatuses = createAsyncThunk("srDocument/getVersionStatuses", (token) =>
	VersionRedressmentService.getStatuses(token)
);

export const getCriticalityList = createAsyncThunk("srDocument/getCriticalityList", (token) =>
	RequirementParameterService.getCriticality(token)
);

export const getNegotiabilityList = createAsyncThunk("srDocument/getNegotiabilityList", (token) =>
	RequirementParameterService.getNegotiability(token)
);

export const getReqTypesList = createAsyncThunk("srDocument/getReqTypesList", (token) =>
	RequirementParameterService.getReqTypes(token)
);

export const getClauses = createAsyncThunk("srDocument/getClauses", ({ docId, token }) =>
	ContentService.getDocumentClauses({ docId }, token)
);

export const getTransverseInformation = createAsyncThunk(
	"srDocument/getTransverseInformation",
	({ projectId, page, limit, payload, token }) =>
		TransverseService.getInformation({ projectId }, { page, limit }, payload, token)
);

export const getLinkTypes = createAsyncThunk("srDocument/getLinkTypes", ({ projectId, token }) =>
	InformationLinkService.getLinkTypes({ projectId }, token)
);
