import client from "../../client/client";

class NormsProjectService {
	addToProject({ normId, projectId }, cancelToken) {
		return client.put(`/norms/${normId}/projects/${projectId}`, { config: { cancelToken } });
	}

	getNormDetections({ normId, projectId }, cancelToken, { documents }) {
		return client.get(`/norms/${normId}/projects/${projectId}/detections`, {
			params: { documents },
			config: { cancelToken },
		});
	}

	deleteNormsInProject(payload, cancelToken) {
		return client.patch("/norms-in-project", { payload, config: { cancelToken } });
	}

	getNorms(
		{ projectId },
		cancelToken,
		{ name, documents, newVersion, favorite, page = 0, limit = 25, orderBy, status, dir } = {}
	) {
		return client.get(`/norms/projects/${projectId}`, {
			params: { name, documents, newVersion, status, favorite, page, limit, orderBy, dir },
			config: { cancelToken },
		});
	}

	addCustomNorm({ projectId }, payload, cancelToken) {
		return client.post(`/norms/projects/${projectId}`, { payload, config: { cancelToken } });
	}

	getTotal({ projectId }, cancelToken) {
		return client.get(`/norms/projects/${projectId}/total`, { config: { cancelToken } });
	}

	update({ normId, projectId }, payload, cancelToken) {
		return client.patch(`/norms/${normId}/projects/${projectId}`, { payload, config: { cancelToken } });
	}
}

export default new NormsProjectService();
