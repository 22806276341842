import React from "react";
import { CircularProgress } from "@mui/material";
import styles from "./CircularLoader.module.css";

export default function CircularLoader({
	className,
	size = 100,
	legacy = false,
	color = "primary",
	thickness = 3.6,
	value = 0,
	variant = "indeterminate",
}) {
	const legacyClassName = legacy ? styles["circularLoader--legacy"] : "";
	return (
		<CircularProgress
			className={`${legacyClassName} ${className}`}
			color={color}
			size={size}
			thickness={thickness}
			value={value}
			variant={variant}
		/>
	);
}
