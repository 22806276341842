import React, { useState, useEffect } from "react";
import { SearchInput, CircularLoader } from "..";
import { translate } from "../../providers";
import SearchList from "./search-list/SearchList";
import SearchListInfinite from "./search-list-infinite/SearchListInfinite";
import styles from "./SearchListContent.module.css";
import { CustomButton } from "../buttons";

export default function SearchListContent({
	defaultOpen = false,
	emptyStateText = "",
	isInfinite = false,
	isLoading = false,
	isTree = false,
	onChangeSearch = null,
	onClearSearch = null,
	onClearSelection,
	onClickRow,
	onExpand,
	onLoadMore = null,
	parentCheckedElements = [],
	rowLabelKey,
	rowKey = rowLabelKey,
	rowRenderer,
	rowTooltipKey,
	rows,
	searchInput = "",
	searchPlaceholder = "",
	subRowCounter = "",
	subRowKey = "",
	totalElements = 0,
}) {
	const [checkedElements, setCheckedElements] = useState(parentCheckedElements);

	useEffect(() => {
		if (parentCheckedElements && Array.isArray(parentCheckedElements) && parentCheckedElements.length > 0) {
			setCheckedElements(parentCheckedElements);
		}
	}, [parentCheckedElements]);
	const isRowChecked = ({ [rowKey]: identifier }) => checkedElements.some((element) => element === identifier);

	const handleCheckCategory = (row) => {
		if (checkedElements.some((r) => r === row[rowKey])) {
			onClickRow(checkedElements.filter((p) => p !== row[rowKey]));
			setCheckedElements((prev) => prev.filter((p) => p !== row[rowKey]));
		} else {
			onClickRow([...checkedElements, row[rowKey]]);
			setCheckedElements((prev) => [...prev, row[rowKey]]);
		}
	};

	return (
		<div className={styles.listContent}>
			{onChangeSearch && (
				<div className={styles.listContent__search}>
					<SearchInput
						fullWidth
						isLoading={isLoading}
						placeholder={searchPlaceholder}
						value={searchInput}
						onChange={onChangeSearch}
						onClearSearch={onClearSearch}
					/>
				</div>
			)}
			{typeof onClearSelection === "function" && (
				<CustomButton
					className={styles.listContent__clearBtn}
					color="primary"
					size="md"
					variant="text"
					onClick={onClearSelection}
				>
					{translate("common:btn.clear-select")}
				</CustomButton>
			)}
			{isLoading && (
				<div className={styles.listContent__stateContainer}>
					<CircularLoader size={45} />
				</div>
			)}
			{!isLoading && !isInfinite && (
				<SearchList
					defaultOpen={defaultOpen}
					emptyStateText={emptyStateText}
					isRowChecked={isRowChecked}
					isTree={isTree}
					rowKey={rowKey}
					rowLabelKey={rowLabelKey}
					rowRenderer={rowRenderer}
					rows={rows}
					rowTooltipKey={rowTooltipKey}
					subRowCounter={subRowCounter}
					subRowKey={subRowKey}
					onClickRow={handleCheckCategory}
					onExpand={onExpand}
				/>
			)}
			{!isLoading && isInfinite && (
				<SearchListInfinite
					currentTotalElements={rows.length}
					emptyStateText={emptyStateText}
					isRowChecked={isRowChecked}
					rowLabelKey={rowLabelKey}
					rows={rows}
					rowTooltipKey={rowTooltipKey}
					totalElements={totalElements}
					onClickRow={onClickRow}
					onLoadMore={onLoadMore}
				/>
			)}
		</div>
	);
}
