import React from "react";
import { CustomButton, CustomTooltip, SidePanelBox } from "../../../../../../../../../common/components";
import { translate, translateDate, translateElapsed } from "../../../../../../../../../common/providers";
import styles from "./QuestionBox.module.css";

const QuestionBox = ({ onOpenDetais, question = null }) => {
	const handleClick = () => {
		onOpenDetais(question.id);
	};
	return (
		<SidePanelBox className={styles.container} hasShadow={false}>
			<div className={styles.container__line}>
				<div className={styles.line__leftItems}>
					<span className={styles.username}>{question?.asker?.displayName}</span>
					<span className={styles.text}>
						{translate("smart-review.cmp-details.option-panel.history.row.qustion.add")}
					</span>
				</div>
				<span className={styles.grayed}>
					<CustomTooltip placement="top" title={translateDate(question.modified).replaceAll(".", "/")}>
						<div className={styles.date}>
							{translateElapsed({
								value: question.elapsed.value,
								unitKey: question.elapsed.unit,
							})}
						</div>
					</CustomTooltip>
				</span>
			</div>
			<div className={styles.container__line}>
				{question.question.split("\n").map((q) => (
					<p key={q} className={styles.questionLine}>
						{q}
					</p>
				))}
			</div>
			<div className={styles.line__rightItems}>
				<CustomButton color="secondary" size="sm" variant="outlined" onClick={handleClick}>
					{translate("smart-review.cmp-details.option-panel.question-tab.btn.details")}
				</CustomButton>
			</div>
		</SidePanelBox>
	);
};

export default QuestionBox;
