import React, { useEffect, useMemo, useRef, useState } from "react";
import { CustomSwitch, OptionsPanelContainer, SearchInput } from "../../../../../../../common/components";
import { translate, translateDate } from "../../../../../../../common/providers";
import { ApiService, FeatureManagementService } from "../../../../../../../api";
import Box from "../../../../box/Box";
import { isEqual, isNonEmptyArray } from "../../../../../../../common/utils";
import styles from "./ProjectList.module.css";

export default function ProjectList({ feat: { id }, onUpdateRow, platformProjects }) {
	const cancelTokenSourceRef = useRef();
	const [projects, setProjects] = useState([]);
	const [tempProjects, setTempProjects] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [show, setShow] = useState(false);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		FeatureManagementService.getActiveFeatureProjects({ featureId: id }, cancelTokenSourceRef.current.token).then(
			(data) => {
				setProjects(data);
				setTempProjects(data);
			}
		);
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [id]);
	const handleCheck = (data, checked) => {
		if (checked) {
			setTempProjects((prev) => prev.filter((tcp) => tcp.id !== data.id));
		} else {
			setTempProjects((prev) => [...prev, platformProjects.find((pc) => pc.id === data.id)]);
		}
	};
	const handleReset = () => {
		setTempProjects(projects);
	};
	const handleChangeSearch = (e) => {
		setSearchInput(e.target.value);
	};
	const handleClearSearch = () => {
		setSearchInput("");
	};
	const handleSave = () => {
		const toAdd = tempProjects.filter((tc) => !projects.some((c) => c.id === tc.id)).map((ta) => ta.id);
		const toRemove = projects.filter((tc) => !tempProjects.some((c) => c.id === tc.id)).map((ta) => ta.id);
		FeatureManagementService.updateActiveFeatureProjects(
			{ featureId: id },
			{
				toAdd: isNonEmptyArray(toAdd) ? toAdd : undefined,
				toRemove: isNonEmptyArray(toRemove) ? toRemove : undefined,
			},
			cancelTokenSourceRef.current.token
		).then(() => {
			setProjects(tempProjects);
			onUpdateRow(id, "companies", tempProjects.length);
		});
	};
	const hasDiff = useMemo(
		() =>
			isEqual(
				projects.map((x) => x.id),
				tempProjects.map((x) => x.id)
			),
		[projects, tempProjects]
	);
	return (
		<OptionsPanelContainer
			buttonText={translate("common:btn.save")}
			buttonTextSecondary={translate("common:btn.cancel")}
			disabledPrimaryButton={hasDiff}
			disabledSecondaryButton={hasDiff}
			onClick={handleSave}
			onClickSecondary={handleReset}
		>
			<div className={styles.container}>
				<div>
					<SearchInput
						fullWidth
						disabled={platformProjects.length === 0}
						placeholder={translate("common:btn.search")}
						value={searchInput}
						onChange={handleChangeSearch}
						onClearSearch={handleClearSearch}
					/>
				</div>
				<div>
					<span>
						{translate("dsi.feature-management.panel.project.selected-count", {
							count: tempProjects.length,
						})}
					</span>
					<span className={styles.switch}>
						<CustomSwitch checked={show} color="primary" onChange={() => setShow((prev) => !prev)} />
						{translate("common:list.show-selected")}
					</span>
				</div>
				{platformProjects
					.filter(
						(pc) =>
							(!show && pc.name?.toLowerCase().includes(searchInput?.toLowerCase() || "")) ||
							(pc.name?.toLowerCase().includes(searchInput?.toLowerCase() || "") &&
								tempProjects.some((tc) => tc.id === pc.id))
					)
					.map((c) => (
						<Box
							key={c.id}
							checked={tempProjects.some((tcp) => tcp.id === c.id)}
							content={translateDate(c.created)}
							data={c}
							id={c.id}
							title={c.name}
							onCheck={handleCheck}
						/>
					))}
			</div>
		</OptionsPanelContainer>
	);
}
