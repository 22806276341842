export const getIndicesOf = (searchStr, str, caseSensitive) => {
	const searchStrLen = searchStr.length;
	if (searchStrLen === 0) {
		return [];
	}
	let startIndex = 0;
	const indices = [];
	const processedStr = !caseSensitive ? str?.toLowerCase() : str;
	const processedSearchStr = !caseSensitive ? searchStr?.toLowerCase() : searchStr;
	let index = processedStr.indexOf(processedSearchStr, startIndex);
	while (index > -1) {
		indices.push(index);
		startIndex = index + searchStrLen;
		index = str.indexOf(processedSearchStr, startIndex);
	}
	return indices;
};

export const cutAndStyleChange = (content, indices, partLength) => {
	const styledContent = [];
	let startIndex = 0;
	if (indices.length === 0) {
		return [{ content }];
	}
	for (let i = 0; i < indices.length; i++) {
		if (startIndex === 0 && indices[i] === 0) {
			styledContent.push({
				content: content.slice(0, partLength),
				styled: true,
			});
			if (indices[i + 1]) {
				styledContent.push({
					content: content.slice(partLength, indices[i + 1]),
					styled: false,
				});
				startIndex = indices[i + 1];
			} else {
				styledContent.push({
					content: content.slice(partLength, content.length),
					styled: false,
				});
			}
		} else if (startIndex === 0 && indices[i] !== 0) {
			styledContent.push({
				content: content.slice(0, indices[i]),
				styled: false,
			});
			styledContent.push({
				content: content.slice(indices[i], indices[i] + partLength),
				styled: true,
			});
			if (indices[i + 1]) {
				styledContent.push({
					content: content.slice(indices[i] + partLength, indices[i + 1]),
					styled: false,
				});
				startIndex = indices[i + 1];
			} else {
				styledContent.push({
					content: content.slice(indices[i] + partLength, content.length),
					styled: false,
				});
			}
		} else if (startIndex > 0 && startIndex === indices[i]) {
			styledContent.push({
				content: content.slice(indices[i], indices[i] + partLength),
				styled: true,
			});
			if (indices[i + 1]) {
				styledContent.push({
					content: content.slice(indices[i] + partLength, indices[i + 1]),
					styled: false,
				});
				startIndex = indices[i + 1];
			} else {
				styledContent.push({
					content: content.slice(indices[i] + partLength, content.length),
					styled: false,
				});
			}
		} else if (startIndex > 0 && startIndex !== indices[i]) {
			styledContent.push({
				content: content.slice(startIndex, indices[i]),
				styled: false,
			});
			styledContent.push({
				content: content.slice(indices[i], indices[i] + partLength),
				styled: true,
			});
			if (indices[i + 1]) {
				styledContent.push({
					content: content.slice(indices[i] + partLength, indices[i + 1]),
					styled: false,
				});
				startIndex = indices[i + 1];
			} else {
				styledContent.push({
					content: content.slice(indices[i] + partLength, content.length),
					styled: false,
				});
			}
		}
	}
	return styledContent;
};
