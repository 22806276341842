import React from "react";
import { useDispatch } from "react-redux";
import { CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "./ContentAutocompleteSearch.module.css";
import ContentToLink from "../content-to-link/ContentToLink";
import { setDisableKeyEvents } from "../../../../../../../../../common/slice";

const ContentAutocompleteSearch = ({
	disabled = false,
	contents,
	emptyStateLabel,
	error,
	helperText,
	inputValue,
	isLoading,
	label = "",
	onReset,
	onSearch,
	onSelect,
}) => {
	const dispatch = useDispatch();
	return (
		<Autocomplete
			clearOnBlur
			fullWidth
			disabled={disabled}
			filterOptions={(options) => options}
			getOptionLabel={(option) => option.content}
			inputValue={inputValue || ""}
			loading={isLoading}
			noOptionsText={emptyStateLabel}
			options={contents || []}
			renderInput={(params) => (
				<TextField
					{...params}
					error={error}
					helperText={error && helperText}
					InputLabelProps={{ className: styles.labelProps }}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{isLoading ? <CircularProgress size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
						className: styles.props,
					}}
					label={label}
					variant="outlined"
					onBlur={() => dispatch(setDisableKeyEvents(false))}
					onFocus={() => dispatch(setDisableKeyEvents(true))}
				/>
			)}
			renderOption={(props, option) => (
				<div {...props} className={styles.option}>
					<ContentToLink content={option} />
				</div>
			)}
			size="small"
			onChange={(e, value, reason) => {
				if (reason === "clear") {
					onReset();
					return;
				}
				onSelect(value);
			}}
			onInputChange={(e, value, reason) => {
				if (reason === "clear") {
					onReset();
					return;
				}
				onSearch(value);
			}}
		/>
	);
};
export default ContentAutocompleteSearch;
