import React, { useState, useEffect } from "react";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	CustomTooltip,
} from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import { sortArray } from "../../../../../../../common/utils";
import { displayTrustLevel } from "../../../../../utils/utils";
import styles from "./AutomaticDetectionsTable.module.css";
import { ButtonNormBody } from "../..";

export default function AutomaticDetectionsTable({ content, isLoading, onSelectRow, onShowDetails }) {
	const [headers] = useState([
		{ name: translate("norms-center.matched-norms.ref"), sortKey: "matchedNormName" },
		{ name: translate("norms-center.matched-norms.date"), sortKey: "date" },
		{ name: translate("norms-center.matched-norms.name") },
		{ name: translate("norms-center.matched-norms.trust"), sortKey: "trust" },
		{ name: "", type: "selected" },
		{ name: translate("norms-center.matched-norms.show-details") },
		{ name: translate("norms-center.matched-norms.all-detections") },
		{ name: translate("norms-center.matched-norms.this-detection") },
	]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");
	const [sortedTableData, setSortedTableData] = useState(content);
	useEffect(() => {
		if (Array.isArray(content) && content.length > 0) {
			setSortedTableData(sortArray(content, order, orderBy));
		} else {
			setSortedTableData([]);
		}
	}, [content, order, orderBy]);
	const handleSort = (property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};
	const handleShowDetails = (e, matchedNormId, matchedNormName) => {
		e.stopPropagation();
		onShowDetails(matchedNormId, matchedNormName);
	};

	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					{headers.map((header) => (
						<CustomTableCell
							key={`${header.sortKey}${header.name}`}
							active={orderBy === header.sortKey}
							className={`${styles.header} ${header.className}`}
							direction={order}
							sortKey={header.sortKey}
							onSort={() => {
								handleSort(header.sortKey);
							}}
						>
							{header.name}
						</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody emptyState={translate("norms-center.matched-norms.empty")} isLoading={isLoading}>
				{Array.isArray(sortedTableData) &&
					sortedTableData.map((row) => (
						<CustomTableRow key={row.matchedNormId} hover>
							<CustomTableCell>{row.matchedNormName}</CustomTableCell>
							<CustomTableCell>{row.matchedNormDate?.replace("/", ".") || "-"}</CustomTableCell>
							<CustomTableCell>
								<CustomTooltip arrow showWhenOverflow title={row.matchedNormDescription || ""}>
									<span className={styles.norms__name}>{row.matchedNormDescription}</span>
								</CustomTooltip>
							</CustomTableCell>
							<CustomTableCell>{displayTrustLevel(row.trust)}</CustomTableCell>
							<CustomTableCell>
								{row.isSelected && (
									<span className={styles.automaticDetections__selected}>
										{translate("norms-center.matched-norms.selected")}
									</span>
								)}
							</CustomTableCell>
							<ButtonNormBody row={row} onSelectRow={onSelectRow} onShowDetails={handleShowDetails} />
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
