import React, { useEffect, useState } from "react";
import { CompaniesList, UsersList, UserDetails } from "../../components";
import { CustomDialog } from "../../../../common/components";
import styles from "./DSIUsers.module.css";
import { translate } from "../../../../common/providers";
import { isNonEmptyArray, searchInTree } from "../../../../common/utils";
import { useEffectOnce } from "../../../../common/hooks";

export default function DSIUsers({
	companies = [],
	userRolesInProject = [],
	userRolesInCompany = [],
	onChangeBlockUser,
	onSelectCompany,
	onCreateUser,
	onRegeneratePassword,
	onUpdateUser,
	onUpdateUserRoleInProject,
	onUpdateUserRoleInCompany,
	onUpdateUserStatusInProject,
}) {
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [isAdding, setIsAdding] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState({ isOpen: false });

	const handleSelectCompany = (company) => {
		if (company !== selectedCompany) {
			setSelectedCompany(company);
			onSelectCompany(company);
		}
	};
	useEffect(() => {
		if (selectedCompany) {
			setSelectedCompany(searchInTree(companies, selectedCompany?.id));
		}
	}, [companies, selectedCompany]);
	useEffectOnce(
		() => {
			handleSelectCompany(companies[0]);
		},
		[],
		() => selectedCompany === null && isNonEmptyArray(companies)
	);

	const handleSelectUser = ({ user }) => {
		if (user !== selectedUser) {
			setSelectedUser(user);
		}
	};

	const handleChangeRoleInCompany = ({ role, user }) => {
		if (!role || !user || !selectedCompany || user.companyRole === role) {
			return;
		}
		onUpdateUserRoleInCompany({ company: selectedCompany, user, role });
	};

	const handleCloseConfirmDialog = () => setOpenConfirmDialog({ isOpen: false });

	const handleChangeBlock = ({ user }) => {
		if (!user) {
			return;
		}
		setOpenConfirmDialog({
			isOpen: true,
			title: translate("dsi.dsi-users.title.ublock-user"),
			text: translate("dsi.dsi-users.text.please-confirm"),
			onSubmit: () => {
				onChangeBlockUser(user);
				handleCloseConfirmDialog();
			},
		});
	};

	const handleRegenPassword = ({ user }) => {
		if (!user || !user.id) {
			return;
		}
		setOpenConfirmDialog({
			isOpen: true,
			title: translate("dsi.dsi-users.title.password-regeneration", { emai: user.email }),
			text: translate("dsi.dsi-users.text.please-confirm"),
			onSubmit: () => {
				onRegeneratePassword(user);
				handleCloseConfirmDialog();
			},
		});
	};

	const handleChangeProjectRole = ({ role, project }) => {
		if (!role || !project || !selectedCompany || !selectedUser) {
			return;
		}
		onUpdateUserRoleInProject({ role, project, user: selectedUser, company: selectedCompany });
	};

	const handleUpdateUserStatusInProject = ({ project, selectedStatus }) => {
		onUpdateUserStatusInProject({
			company: selectedCompany,
			selectedUser,
			project,
			selectedStatus,
		});
	};

	return (
		<>
			<div className={styles["left-panel"]}>
				<CompaniesList
					companies={companies || []}
					selectedCompany={selectedCompany}
					onClickRow={handleSelectCompany}
				/>
			</div>
			<div className={styles["middle-panel"]}>
				<UsersList
					COMPANY_ROLES={userRolesInCompany}
					selectedUser={selectedUser}
					setIsAdding={setIsAdding}
					users={searchInTree(companies, selectedCompany?.id)?.users || []}
					onChangeLock={handleChangeBlock}
					onChangeRole={handleChangeRoleInCompany}
					onClickRow={handleSelectUser}
					onRegenPassword={handleRegenPassword}
				/>
			</div>
			<UserDetails
				isAdding={isAdding}
				PROJECT_ROLES={userRolesInProject}
				selectedCompany={selectedCompany}
				user={
					(searchInTree(companies, selectedCompany?.id)?.users || []).find(
						(u) => u.id === selectedUser?.id
					) || selectedUser
				}
				onAdd={({ payload }) => {
					onCreateUser(selectedCompany, payload);
					setIsAdding(false);
				}}
				onChangeRole={handleChangeProjectRole}
				onClose={() => {
					setSelectedUser(null);
					setIsAdding(false);
				}}
				onUpdateStatus={handleUpdateUserStatusInProject}
				onUpdateUser={onUpdateUser}
			/>
			<CustomDialog
				maxWidth="xs"
				open={openConfirmDialog && openConfirmDialog.isOpen}
				subTitle={openConfirmDialog && openConfirmDialog.text}
				title={openConfirmDialog && openConfirmDialog.title}
				onClose={handleCloseConfirmDialog}
				onSubmit={openConfirmDialog && openConfirmDialog.onSubmit}
			/>
		</>
	);
}
