import React from "react";
import { useSelector } from "react-redux";
import { Header, CategoryRow } from "./components";
import styles from "./CategoryDrawer.module.css";

export default function CategoryDrawer() {
	const categories = useSelector(({ gonogo }) => gonogo?.categories);
	return (
		<div className={styles.drawer}>
			<Header />
			<div className={styles.content}>
				{categories.map((cat) => (
					<CategoryRow key={cat.id} category={cat} />
				))}
			</div>
		</div>
	);
}
