import { isNonEmptyArray } from "../utils";

let userRoles = null;
let companyRoles = null;
let companyType = null;

export const setUserRoles = (roles) => {
	userRoles = roles;
};

export const setCompanyRoles = (roles) => {
	companyRoles = roles;
};

export const setCompanyType = (type) => {
	companyType = type;
};

export const isCompanyTypeAllowed = (type) => {
	if (!companyType) {
		return false;
	}
	if (isNonEmptyArray(type)) {
		return type.some((t) => t === companyType);
	}
	return false;
};

export default function hasPermission(...roles) {
	return roles.flat().some((r) => {
		const { scope, role } = r;
		if (!scope || !role || !userRoles) {
			return false;
		}
		const scopedUserRoles = userRoles[scope];
		if (!scopedUserRoles) {
			return false;
		}
		if (Array.isArray(scopedUserRoles)) {
			return scopedUserRoles.some((rle) => rle === role);
		}
		return scopedUserRoles.includes(role);
	});
}

export function hasCompanyPermission(...roles) {
	return roles.flat().some((r) => {
		const { scope, role } = r;
		if (!scope || !role || !companyRoles) {
			return false;
		}
		const scopedCompanyRoles = companyRoles[scope];
		if (!scopedCompanyRoles) {
			return false;
		}
		if (Array.isArray(scopedCompanyRoles)) {
			return scopedCompanyRoles.some((rle) => rle === role);
		}
		return scopedCompanyRoles.includes(role);
	});
}
