import { ButtonGroup } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import styles from "./CustomButtonGroup.module.css";

const CustomButtonGroup = ({ children, color = "primary", fullWidth = false, size = "md", variant = "text" }) => (
	<ButtonGroup
		className={styles.btnGroup}
		color={color}
		data-color={color}
		data-size={size}
		data-variant={variant}
		fullWidth={fullWidth}
		variant="text"
	>
		{children}
	</ButtonGroup>
);
CustomButtonGroup.propTypes = {
	size: PropTypes.oneOf(["sm", "md", "lg"]),
	color: PropTypes.oneOf(["primary", "secondary"]),
	variant: PropTypes.oneOf(["contained", "outlined", "text"]),
};
CustomButtonGroup.defaultProps = {
	size: "md",
	color: "primary",
	variant: "text",
};
export default CustomButtonGroup;
