import client from "../../client/client";

class QATagService {
	createQuestionTagInProject({ projectId }, payload, cancelToken) {
		return client.post(`/question-tag/projects/${projectId}`, {
			payload: { value: payload },
			config: { cancelToken },
		});
	}

	getQuestionTagInProject({ projectId }, cancelToken) {
		return client.get(`/question-tag/projects/${projectId}`, { config: { cancelToken } });
	}

	searchQuesionTagInProject({ projectId }, search, cancelToken) {
		return client.get(`/question-tag/projects/${projectId}/search`, {
			params: { filter: search },
			config: { cancelToken },
		});
	}
}
export default new QATagService();
