import { Flags, SegFlags } from "../../common/providers";

export default ({ projectId }) => [
	{
		name: "navigation:home.admin-categories",
		to: `/projects/${projectId}/project-categories`,
		flag: Flags.THEMATIC,
		segFlag: SegFlags.CATEGORY,
	},
	{
		name: "navigation:home.team",
		to: `/projects/${projectId}/team`,
		flag: Flags.PARTNERS,
		segFlag: SegFlags.TEAM,
	},
	{
		name: "navigation:home.documents",
		to: `/projects/${projectId}/documents`,
		segFlag: SegFlags.DOCUMENT_CENTER,
		flag: Flags.DOCUMENT_CENTER,
	},
	{
		name: "navigation:home.rights-on-documents",
		to: `/projects/${projectId}/rights-on-documents`,
		segFlag: [SegFlags.RIGHTS_ON_DOCUMENTS, SegFlags.TEAM],
	},
];
