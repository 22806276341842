import client from "../../client/client";

class QuickAccessService {
	filterRequirements({ documentId }, payload, cancelToken) {
		return client.put(`/quickaccess/documents/${documentId}/filter`, { payload, config: { cancelToken } });
	}

	getInfoVersionList(cancelToken) {
		return client.get("/quickaccess/info-version", {
			config: { cancelToken },
		});
	}
}

export default new QuickAccessService();
