import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/material";
import { useApi } from "../../../../hooks";
import { DeliverablesService, DocumentOutService } from "../../../../../api";
import { CircularLoader } from "../../../loaders";
import { DeliverablesPhasesList } from "./components";
import styles from "./DeliverablesFilter.module.css";
import { isNonEmptyArray, isNonEmptyObject } from "../../../../utils";
import { FiltersWrapper } from "../../wrappers";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../providers";
import { CommonFilters, ProjectCategoriesFilter } from "..";
import { cleanFilters, generateFilters } from "../../utils";
import { icon, IconComponent } from "../../../icon-component";

const DeliverablesFilter = ({ search, value, onChange, readOnly }) => {
	const { call: getAllPhasesByProject } = useApi(DeliverablesService.getAllPhasesByProject);
	const { call: getAllDeliverablesByProjectAndPhaseForFilter } = useApi(
		DeliverablesService.getAllDeliverablesByProjectAndPhaseForFilter
	);
	const { call: getDeliverablesByCatFilter } = useApi(DocumentOutService.getDeliverablesByCatFilter);
	const { call: getDeliverablesCount } = useApi(DeliverablesService.getDeliverablesCount);
	const projectId = useSelector(({ context }) => context.project.id);
	const [phasesTree, setPhasesTree] = useState([]);
	const [isLoadingDeliverables, setIsLoadingDeliverables] = useState(false);
	const [catFilters, setCatFilters] = useState({});
	const [filtersPreviewRequest, setFiltersPreviewRequest] = useState(null);
	const hasFilters = useMemo(() => search?.length > 0 || isNonEmptyObject(catFilters), [search, catFilters]);
	const currentDeliverables = useMemo(() => {
		if (hasFilters && isNonEmptyArray(phasesTree)) {
			let currentDelivs = [];
			phasesTree.forEach((phase) =>
				phase?.deliverables?.forEach((deliv) => (currentDelivs = [...currentDelivs, deliv.id]))
			);
			return currentDelivs;
		}
		return [];
	}, [hasFilters, phasesTree]);
	const allInSearchSelected = useMemo(
		() => currentDeliverables.every((currentDeliv) => value?.elements?.includes(currentDeliv)),
		[currentDeliverables, value]
	);

	const fetchPhases = useCallback(() => {
		setIsLoadingDeliverables(true);
		getAllPhasesByProject({ projectId })
			.then((data) => setPhasesTree(data))
			.catch((err) => {
				console.error(err);
			})
			.finally(() => setIsLoadingDeliverables(false));
	}, [getAllPhasesByProject, projectId]);
	const fetchPhasesTree = useCallback(() => {
		setIsLoadingDeliverables(true);
		getDeliverablesByCatFilter(
			{ projectId },
			{ categories: generateFilters(catFilters)?.categories, name: search || undefined }
		)
			.then((data) => setPhasesTree(data))
			.catch((err) => {
				console.error(err);
			})
			.finally(() => setIsLoadingDeliverables(false));
	}, [getDeliverablesByCatFilter, catFilters, projectId, search]);

	useEffect(() => {
		if (!hasFilters) {
			fetchPhases();
		} else {
			fetchPhasesTree();
		}
	}, [search, hasFilters, fetchPhases, fetchPhasesTree]);
	useEffect(() => {
		setFiltersPreviewRequest(
			() => (previewFilters) =>
				getDeliverablesCount(
					{ projectId },
					{
						filters: { ...generateFilters(previewFilters), name: search },
					}
				).then((data) => data.value)
		);
	}, [projectId, search, getDeliverablesCount]);
	const handleExpandPhase = ({ expandablePhase, expandablePhaseId }) => {
		if (!expandablePhaseId && expandablePhase.deliverablesCount === expandablePhase.deliverables?.length) {
			return;
		}
		const phaseId = expandablePhaseId || expandablePhase.phaseId;
		getAllDeliverablesByProjectAndPhaseForFilter({ projectId, phaseId })
			.then((data) => {
				const newPhases = phasesTree.map((phase) => {
					if (phase.phaseId === phaseId) {
						return { ...phase, defaultExpanded: true, deliverables: data };
					}
					return phase;
				});
				setPhasesTree(newPhases);
			})
			.catch(console.error);
	};
	const handleChange = (newValue, isChecked) => {
		const tempValue =
			isNonEmptyObject(value) && Object.keys(value)?.some((v) => v !== "is" && v !== "matchAll")
				? { ...value }
				: { elements: [], is: value?.is, matchAll: value?.matchAll };
		if (isChecked) {
			tempValue.elements = tempValue.elements.filter((element) => element !== newValue);
		} else {
			tempValue.elements = [...tempValue.elements, newValue];
		}
		onChange(tempValue);
	};
	const handleApply = (newFilters) => {
		setCatFilters(cleanFilters(newFilters));
	};
	const handleRemove = (newFilters) => {
		setCatFilters(newFilters);
	};
	const handleSelectAll = () => {
		const tempValue =
			isNonEmptyObject(value) && Object.keys(value)?.some((v) => v !== "is" && v !== "matchAll")
				? { ...value }
				: { elements: [], is: value?.is, matchAll: value?.matchAll };
		if (allInSearchSelected) {
			tempValue.elements = tempValue.elements.filter((delivId) => !currentDeliverables.includes(delivId));
		} else {
			currentDeliverables.forEach((currentDeliv) => {
				if (!tempValue.elements.includes(currentDeliv)) {
					tempValue.elements = [...tempValue.elements, currentDeliv];
				}
			});
		}
		onChange(tempValue);
	};
	const customLabelApply = (results) => {
		if (results <= 1 && results !== null) {
			return translate("common:component.filter.apply", { results });
		}
		if (results > 1) {
			return translate("common:component.filters.apply", { results });
		}
		return translate("common:btn.apply");
	};
	return (
		<div className={styles.container}>
			<div className={styles.stickyHeader}>
				<FiltersWrapper
					multiline
					buttonSize="sm"
					className={styles.innerFilterContainer}
					components={[
						{
							default: true,
							enabled: isSegFeatureEnabled(SegFlags.CATEGORY),
							component: CommonFilters.CATEGORIES,
							renderer: <ProjectCategoriesFilter />,
							popUpClassName: styles.categoriesPopUp,
							hasFavorites: true,
						},
					]}
					customLabelApply={customLabelApply}
					defaultFilters={catFilters}
					previewRequest={filtersPreviewRequest}
					onApply={handleApply}
					onRemove={handleRemove}
				/>
				{hasFilters && phasesTree?.length > 0 && (
					<div>
						<Checkbox
							checked={isNonEmptyArray(phasesTree) && allInSearchSelected}
							indeterminate={
								isNonEmptyArray(phasesTree) && isNonEmptyArray(value?.elements) && !allInSearchSelected
							}
							onClick={handleSelectAll}
						/>
						<span>{translate("common:btn.select-all")}</span>
					</div>
				)}
			</div>
			{(isLoadingDeliverables && <CircularLoader />) ||
				(isNonEmptyArray(phasesTree) ? (
					<DeliverablesPhasesList
						hasFilters={hasFilters}
						phases={phasesTree}
						readOnly={readOnly}
						selection={value?.elements}
						onChange={handleChange}
						onExpandPhase={handleExpandPhase}
					/>
				) : (
					<div className={styles.noContent}>
						<IconComponent
							className={styles.noContent__infoIcon}
							color="var(--color-blue)"
							icon={icon.faInfoCircle}
						/>
						{translate("common:component.filters.deliverables.empty-state")}
					</div>
				))}
		</div>
	);
};

export default DeliverablesFilter;
