import React, { useState, useEffect } from "react";
import {
	DialogTitle,
	DialogContent,
	DialogActions,
	List,
	ListItem,
	ListItemIcon,
	Checkbox,
	ListItemText,
	TextField,
	CircularProgress,
} from "@mui/material";
import { debounce } from "../../../../common/utils";
import styles from "./AddConsortiumDialog.module.css";
import { translate } from "../../../../common/providers";
import AutoCompleteSearch from "./AutoCompleteSearch";
import { ProjectTeamService, ApiService } from "../../../../api";
import { CustomIconButton, GenericDialog, icon, CustomButton, I18nTranslate } from "../../../../common/components";

let isSubscribed = false;

const deboucedSearch = debounce(({ setIsLoadingUsers, projectId, search = "", setUsers }, cancelTokenSource) => {
	ProjectTeamService.searchUsersInProject({ projectId }, search, cancelTokenSource.token)
		.then((data) => {
			if (isSubscribed) {
				setUsers(Array.isArray(data) ? data : []);
			}
		})
		.catch((err) => (isSubscribed ? console.error(err) : null))
		.finally(() => setIsLoadingUsers(false));
}, 1000);

export default function AddConsortiumDialog({ open, onClose, onSubmit, projectId }) {
	const [selectedPartners, setSelectedPartners] = useState([]);
	const [payload, setPayload] = useState({ name: "", leaderEmail: "" });
	const [errors, setErrors] = useState({ name: false, leaderEmail: false });
	const [isLoadingUsers, setIsLoadingUsers] = useState(false);
	const [isLoadingPartners, setIsLoadingPartners] = useState(false);
	const [users, setUsers] = useState([]);
	const [partners, setPartners] = useState([]);
	const [search, setSearch] = useState("");

	const handleCloseAndAction = () => {
		if (Object.keys(errors).some((key) => errors[key]) || Object.keys(payload).some((key) => !payload[key])) {
			Object.keys(payload).forEach((key) => !payload[key] && setErrors((prev) => ({ ...prev, [key]: true })));
			return;
		}
		payload.companyIds = selectedPartners.map((p) => p.id);
		onSubmit(payload);
		onClose();
	};

	const handleToggle = (partner) => {
		if (selectedPartners.some((p) => p.id === partner.id)) {
			setSelectedPartners((prev) => prev.filter((p) => p.id !== partner.id));
		} else {
			setSelectedPartners((prev) => [...prev, partner]);
		}
	};

	const handleChange = (field, value) => {
		if (!field) {
			return;
		}
		if (!value) {
			setErrors((prev) => ({ ...prev, [field]: true }));
		} else {
			setErrors((prev) => ({ ...prev, [field]: false }));
		}
		setPayload((prev) => ({ ...prev, [field]: value }));
	};

	const handleSelectUser = (value) => handleChange("leaderEmail", value.email);

	const searchUser = (e) => setSearch(e.target.value);

	const reset = () => {
		setSelectedPartners([]);
		setPayload({ name: "", leaderEmail: "" });
		setErrors({ name: false, leaderEmail: false });
		setIsLoadingUsers(false);
		setIsLoadingPartners(false);
		setUsers([]);
		setPartners([]);
		setSearch("");
	};

	useEffect(() => {
		isSubscribed = true;
		return () => {
			isSubscribed = false;
			reset();
		};
	}, []);

	useEffect(() => {
		const cancelTokenSource = ApiService.getCancelTokenSource();
		setIsLoadingPartners(true);
		ProjectTeamService.getAvailableChildrenFromParentExclusive({ projectId }, cancelTokenSource.token)
			.then((data) => {
				setPartners(Array.isArray(data) ? data : []);
				setIsLoadingPartners(false);
			})
			.catch((err) => {
				console.error(err);
				setIsLoadingPartners(false);
			});
		return () => {
			ApiService.cancelTokens(cancelTokenSource);
		};
	}, [projectId]);

	useEffect(() => {
		const cancelTokenSource = ApiService.getCancelTokenSource();
		setIsLoadingUsers(true);
		deboucedSearch({ setIsLoadingUsers, projectId, search, setUsers }, cancelTokenSource);
		return () => {
			ApiService.cancelTokens(cancelTokenSource);
		};
	}, [projectId, search]);

	return (
		<GenericDialog disableBackdropClick fullWidth open={open} onClose={onClose} onExited={reset}>
			<DialogTitle>
				<div className={styles.dialog__title}>
					<span className={styles["dialog__title-text"]}>
						{translate("team-management.add-team-dialog.title")}
					</span>
					<CustomIconButton icon={icon.faTimes} onClick={onClose} />
				</div>
			</DialogTitle>
			<DialogContent dividers className={styles.dialog__content}>
				<div>
					<I18nTranslate
						className={styles.helper__text}
						translationKey="team-management.add-team-dialog.content.team-name"
					/>
					<TextField
						required
						className={styles.dialog__field}
						error={errors && errors.name}
						helperText={errors && errors.name && translate("common:error.required")}
						InputLabelProps={{ required: false }}
						label={translate("team-management.add-team-dialog.name")}
						value={(payload && payload.name) || ""}
						variant="outlined"
						onChange={(e) => handleChange("name", e.target.value)}
					/>
					<I18nTranslate
						className={styles.helper__text}
						translationKey="team-management.add-team-dialog.content.team-leader"
					/>
					<AutoCompleteSearch
						error={errors && errors.leaderEmail}
						handleSelectUser={handleSelectUser}
						isLoadingUsers={isLoadingUsers}
						searchUser={searchUser}
						users={users}
					/>
				</div>
				<span className={styles.helper__text}>
					{translate("team-management.add-team-dialog.content.team-partner")}
				</span>
				{!isLoadingPartners && partners.length > 0 && (
					<List>
						{partners.map((partner) => (
							<ListItem key={partner.id} button dense onClick={() => handleToggle(partner)}>
								<ListItemIcon>
									<Checkbox
										disableRipple
										checked={selectedPartners.some((p) => p.id === partner.id)}
										color="primary"
										edge="start"
										inputProps={{ "aria-labelledby": partner.id }}
										tabIndex={-1}
									/>
								</ListItemIcon>
								<ListItemText
									id={partner.id}
									primary={partner.companyName}
									secondary={partner.type}
									translate="no"
								/>
							</ListItem>
						))}
					</List>
				)}
				{!isLoadingPartners && partners.length === 0 && (
					<div className={styles.dialog__partners}>
						{translate("team-management.add-team-dialog.empty-partners")}
					</div>
				)}
				{isLoadingPartners && (
					<div className={styles.dialog__partners}>
						<CircularProgress />
					</div>
				)}
			</DialogContent>
			<DialogActions>
				<CustomButton color="primary" variant="outlined" onClick={onClose}>
					{translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton color="primary" variant="contained" onClick={handleCloseAndAction}>
					{translate("common:btn.submit")}
				</CustomButton>
			</DialogActions>
		</GenericDialog>
	);
}
