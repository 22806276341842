import client from "../../client/client";

class GonogoCategoryService {
	getCategoriesTree({ projectId }, cancelToken) {
		return client.get(`/select/projects/${projectId}/tree`, { config: { cancelToken } });
	}

	getCategory({ projectId, categoryId }, cancelToken) {
		return client.get(`/select/projects/${projectId}/subtree/${categoryId}`, { config: { cancelToken } });
	}

	exportCategories({ projectId }, cancelToken) {
		return client.download(`/select/projects/${projectId}/categories/export`, { config: { cancelToken } });
	}
}

export default new GonogoCategoryService();
