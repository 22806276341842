import client from "../../client/client";

class DocumentService {
	getPage({ docId }, { page = 1 }, cancelToken) {
		return client.get(`/documents/${docId}/image`, {
			config: { responseType: "blob", cancelToken },
			params: { page, timestamp: Date.now() },
		});
	}

	retryExtract({ docId }, cancelToken) {
		return client.post(`/documents/${docId}/retry`, { config: { cancelToken } });
	}

	delete({ docId }, cancelToken) {
		return client.delete(`/documents/${docId}`, { config: { cancelToken } });
	}

	renameDocument({ docId }, name, cancelToken) {
		return client.put(`/documents/${docId}`, { payload: { value: name }, config: { cancelToken } });
	}

	getInformation({ docId }, cancelToken) {
		return client.get(`/documents/${docId}`, { config: { cancelToken } });
	}

	getVersions({ docId }, cancelToken) {
		return client.get(`/documents/${docId}/versions`, { config: { cancelToken } });
	}

	getDocumentPhysicalDetails({ docId }, cancelToken) {
		return client.get(`/documents/${docId}/details`, { config: { cancelToken } });
	}

	getDocumentQa({ docId }, cancelToken) {
		return client.get(`/documents/${docId}/question-answer`, { config: { cancelToken } });
	}

	getDocumentTocList({ docId }, cancelToken) {
		return client.get(`/documents/${docId}/toc-list`, { config: { cancelToken } });
	}

	freezeDocument({ docId }, cancelToken) {
		return client.patch(`/documents/${docId}/freeze`, { config: { cancelToken } });
	}

	getDocumentPreviewDimensions({ docId, page }, { adjPages }, cancelToken) {
		return client.get(`/documents/${docId}/pages/${page}/preview`, {
			params: { adjPages },
			config: { cancelToken },
		});
	}

	getDocumentTypes(cancelToken) {
		return client.get("/documents/types", { config: { cancelToken } });
	}

	downloadDocument({ docId }, cancelToken) {
		return client.download(`/documents/${docId}/download`, {
			config: { cancelToken },
		});
	}
	// End Precedence Controller
}

export default new DocumentService();
