import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularLoader, OptionsPanelContainer } from "../../../../../../../../../common/components";
import { translate } from "../../../../../../../../../common/providers";
import informationLinkService from "../../../../../../../../../api/services/smartview/information-link-service";
import { ApiService } from "../../../../../../../../../api";
import { isNonEmptyArray, isNonEmptyObject } from "../../../../../../../../../common/utils";
import styles from "./LinkTabView.module.css";
import { useApi, usePrevious } from "../../../../../../../../../common/hooks";
import { linkDirections, oppositeLink } from "../../../../../../../utils/utils";
import LinkCreationDialog from "../link-creation-dialog/LinkCreationDialog";
import LinkRow from "../link-row/LinkRow";
import { resetSidePanelContent } from "../../../../../../../slice/document/document-slice";
import { updateNode } from "../../../../../../../slice/pdf/pdf-slice";
import { handleLinkTypeCountIncrement } from "../../../../../../../slice/utils";

const LinkTabView = ({ informationId }) => {
	const cancelTokenSourceRef = useRef(null);
	const [links, setLinks] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [openDialog, setOpenDialog] = useState(false);
	const selectedMode = useSelector(({ srDocument }) => srDocument.mode);
	const selectedSearch = useSelector(({ srDocument }) => srDocument.selectedSearch);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const isSidePanelToBeReset = useSelector(({ srDocument }) => srDocument.isSidePanelToBeReset);
	const { call: createLink } = useApi(informationLinkService.createInformationLinks);
	const dispatch = useDispatch();
	const fetchLinks = useCallback(() => {
		informationLinkService
			.getInformationLinks({ informationId }, cancelTokenSourceRef.current.token)
			.then((data) => setLinks(data))
			.catch((err) => console.error(err))
			.finally(() => setIsLoading(false));
	}, [informationId]);
	useEffect(
		() => {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
			return () => {
				ApiService.cancelTokens(cancelTokenSourceRef.current);
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	const prevReset = usePrevious(isSidePanelToBeReset);
	const prevId = usePrevious(informationId);
	useEffect(() => {
		if (
			informationId &&
			(prevId !== informationId || (isSidePanelToBeReset && isSidePanelToBeReset !== prevReset))
		) {
			setIsLoading(true);
			fetchLinks();
			if (isSidePanelToBeReset) {
				dispatch(resetSidePanelContent(false));
			}
		}
	}, [dispatch, informationId, prevId, isSidePanelToBeReset, prevReset, fetchLinks]);
	const handleOpenDialogLink = () => {
		setOpenDialog(true);
	};
	const handleCloseDialogLink = () => {
		setOpenDialog(false);
	};
	const handleSubmit = ({ linkType, linkId }) => {
		const payload = linkDirections({ linkId, informationId, linkType });
		if (isNonEmptyObject(payload)) {
			createLink({ informationId }, payload)
				.then((data) => {
					if (data) {
						setLinks((prev) => [{ ...data, link: linkType }, ...prev]);
						const updates = (opposite) => [
							{
								property: "linkTypeCount",
								callback: handleLinkTypeCountIncrement,
								parameters: { linkType: (opposite && oppositeLink(linkType)) || linkType },
							},
						];
						dispatch(
							updateNode({
								mode: selectedMode,
								pageConditions: { page: selectedSearch.pageStart },
								informationId,
								updates: updates(false),
							})
						);
						if (documentId === data.documentId) {
							dispatch(
								updateNode({
									mode: selectedMode,
									pageConditions: { page: data.pageStart },
									informationId: data.informationId,
									updates: updates(true),
								})
							);
						}
					}
					handleCloseDialogLink();
				})
				.catch(console.error);
		}
	};
	const handleRemoveLink = (linkId) => {
		setLinks((prev) => prev.filter((l) => l.linkId !== linkId));
	};
	const handleEditLink = (linkId, newLinkType) => {
		setLinks((prev) => prev.map((l) => (l.linkId === linkId && { ...l, link: newLinkType }) || l));
	};
	return (
		<div className={styles.container}>
			<OptionsPanelContainer
				buttonText={translate("smart-review.cmp-details.option-panel.link-tab.create-link")}
				mainClassName={styles.options}
				onClick={handleOpenDialogLink}
			/>
			{(!isLoading && isNonEmptyArray(links) && (
				<div className={styles.rows}>
					{links.map((link) => (
						<LinkRow
							key={link.linkId}
							data={link}
							informationId={informationId}
							onEditLink={handleEditLink}
							onRemoveLink={handleRemoveLink}
						/>
					))}
				</div>
			)) ||
				(!isLoading && <div className={styles.emptyState}>{translate("common:empty-state.no-results")}</div>)}
			{isLoading && (
				<div className={styles.loading}>
					<CircularLoader />
				</div>
			)}
			<LinkCreationDialog open={openDialog} onClose={handleCloseDialogLink} onSubmit={handleSubmit} />
		</div>
	);
};

export default LinkTabView;
