import React, { useState } from "react";
import { CustomDialog, HorizontalDivider, icon } from "../../../../..";
import { translate } from "../../../../../../providers";
import { isNonEmptyArray } from "../../../../../../utils";
import Item from "../item/Item";
import styles from "./SelectionList.module.css";

export default function SelectionList({ selection, onSelect, onClear, disabled }) {
	const [open, setOpen] = useState(false);
	const handleClose = () => {
		setOpen((prev) => !prev);
	};
	return (
		<>
			{isNonEmptyArray(selection) && (
				<div className={styles.parent}>
					<div>
						<span>{translate("common:filters.document-list.selection", { count: selection.length })}</span>
						<span className={styles.unselect} role="presentation" onClick={handleClose}>
							<u>{translate("common:filters.document-list.selection.unselect")}</u>
						</span>
					</div>
					{selection.map((sD) => (
						<Item
							key={sD.id}
							selected
							disabled={disabled}
							iconName="faFilePdf"
							item={{ ...sD, selected: true }}
							label={sD.documentName}
							onClickItem={() => {
								onSelect(sD);
							}}
						/>
					))}
					<div className={styles.divider}>
						<HorizontalDivider />
					</div>
				</div>
			)}
			<CustomDialog
				className={styles.warningDialog}
				iconColor="var(--color-light-grey-1)"
				iconModel={icon.faExclamationTriangle}
				open={open}
				submitLabel={translate("common:filters.document-list.selection.unselect")}
				subTitle={translate("common:filters.document-list.selection.unselect.message")}
				title={translate("common:filters.document-list.selection.unselect")}
				onClose={handleClose}
				onSubmit={() => {
					onClear();
					handleClose();
				}}
			/>
		</>
	);
}
