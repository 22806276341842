import React from "react";
import styles from "./UserQuide.module.css";
import { translate } from "../../../../common/providers";
import { I18nTranslate } from "../../../../common/components";

const UserGuide = () => (
	<div className={styles.container}>
		<div className={styles.title}>
			<strong>{translate("chat-project.guide.welcome")}</strong>
		</div>
		<div className={styles.subtitle}> {translate("chat-project.guide.disscuss")}</div>

		<div className={styles.propositions_points}>
			<I18nTranslate translationKey="chat-project.guide-propositions-discussions" />
		</div>
		<div className={styles.subtitle}>
			<I18nTranslate translationKey="chat-project.guide.contact" />
		</div>
		<div className={styles.propositions_points}>
			<I18nTranslate translationKey="chat-project.guide-propositions-ideas" />
		</div>
	</div>
);

export default UserGuide;
