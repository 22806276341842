import React from "react";
import { Drawer } from "@mui/material";
import classNames from "classnames";
import styles from "./CustomDrawer.module.css";

export default function CustomDrawer({ expandMenu, children }) {
	return (
		<Drawer
			BackdropProps={{ style: { position: "absolute" } }}
			classes={{
				paper: classNames({
					[styles.minDrawerOpen]: expandMenu,
					[styles.minDrawerClose]: !expandMenu,
				}),
			}}
			className={classNames(styles.minDrawer, {
				[styles.minDrawerOpen]: expandMenu,
				[styles.minDrawerClose]: !expandMenu,
			})}
			ModalProps={{
				style: { position: "absolute" },
			}}
			open={expandMenu}
			PaperProps={{ style: { position: "absolute" } }}
			variant="permanent"
		>
			{children}
		</Drawer>
	);
}
