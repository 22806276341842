import React from "react";
import { InfiniteList } from "../../../../common/components";
import { MembersListEmptyState, MembersListLoader, MembersListHeader, MembersListRow } from "..";
import styles from "./MembersList.module.css";
import { translate } from "../../../../common/providers";

const ROLES_NAMES = { leader: "LEADER", member: "MEMBER", manager: "MANAGER" };
export default function MembersList({
	totalElements,
	isLoadingUsers,
	onLoadMore,
	loggedUser,
	onSendInvitation,
	roles,
	onChangeRole,
	isUserProjectLeader,
	isUserProjectManager,
	onChangeLock,
	isMyCompanyProjectLeader,
	users = [],
	selectedCompanyIsMine = false,
}) {
	const rowRenderer = ({ key, index, style }) => {
		const user = users[index];
		const disabledRoles = roles.map((r) => ({
			...r,
			disabled: isUserProjectManager && r.name === ROLES_NAMES.leader,
			tooltip:
				(isUserProjectManager &&
					r.name === ROLES_NAMES.leader &&
					translate("team-management.members.selector.tooltip.not-leader")) ||
				"",
		}));
		return (
			<MembersListRow
				key={key}
				allowAddRights={isUserProjectLeader || isUserProjectManager}
				allowChangeRole={
					user.id !== loggedUser.id &&
					((selectedCompanyIsMine &&
						(isUserProjectLeader || (isUserProjectManager && user.projectRole !== ROLES_NAMES.leader))) ||
						(isMyCompanyProjectLeader && isUserProjectLeader))
				}
				allowSendInvitation={
					((isUserProjectLeader || isUserProjectManager) && selectedCompanyIsMine) ||
					(isUserProjectLeader && isMyCompanyProjectLeader)
				}
				isCurrentUser={user.id === loggedUser.id}
				roles={disabledRoles}
				selectedCompanyIsMine={selectedCompanyIsMine}
				style={style}
				user={user}
				onChangeLock={onChangeLock}
				onChangeRole={onChangeRole}
				onSendInvitation={onSendInvitation}
			/>
		);
	};
	const isRowLoaded = ({ index }) => !!users[index];
	const noRowsRenderer = () =>
		users.length === 0 && isLoadingUsers ? <MembersListLoader /> : <MembersListEmptyState />;

	const handleLoadMore = () => onLoadMore();
	return (
		<>
			<MembersListHeader />
			<div className={styles.listContainer}>
				<InfiniteList
					className={styles.infiniteList}
					currentTotalElements={users.length}
					isRowLoaded={isRowLoaded}
					loadMoreRows={handleLoadMore}
					noRowsRenderer={noRowsRenderer}
					rowHeight={80}
					rowRenderer={rowRenderer}
					threshold={15}
					totalElements={totalElements}
				/>
			</div>
		</>
	);
}
