import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { checkHardInformation, checkReqValMode, computeAbsoluteTop } from "../../../utils/utils";
import SuperDetailBlock from "./components/SuperDetailBlock";
import { CONTENT_RANGE, DOCUMENT_STATUSES, SENTENCE_TYPE } from "../../../constants/constants";
import styles from "./SyncedDetailsRightPanel.module.css";

const SyncedDetailsRightPanel = React.memo(({ hoveredBlock, onMouseEnterBlock }) => {
	const content = useSelector(({ srPdf }) => srPdf.content);
	const page = useSelector(({ srPdf }) => srPdf.page);
	const zoom = useSelector(({ srPdf }) => srPdf.zoom);
	const isArchived = useSelector(({ srDocument }) => srDocument.status === DOCUMENT_STATUSES.ARCHIVED);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const pageDimensions = useSelector(({ srDocument }) => srDocument.pageDimensions);
	const [currentRange, setCurrentRange] = useState({ min: -1, max: -1 });
	const [lineSize, setLineSize] = useState(0);

	useEffect(() => {
		const handleResize = () => {
			setLineSize((window.innerWidth > 1440 && 32) || 24);
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(
		() => {
			if (currentRange.min < 0 || page <= currentRange.min || page >= currentRange.max) {
				setCurrentRange({
					min: page - CONTENT_RANGE >= CONTENT_RANGE ? page - CONTENT_RANGE : 0,
					max: page - CONTENT_RANGE >= CONTENT_RANGE ? page + CONTENT_RANGE : CONTENT_RANGE,
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[page]
	);

	const restrictedContent = useMemo(
		() => {
			let currentTop = 0;
			if (!checkReqValMode(mode)) {
				return [];
			}
			const filteredArray = content
				.filter((c) => c.page - 1 >= currentRange.min - 1 && c.page - 1 <= currentRange.max + 1 && !!c[mode])
				.map((pageContent) => {
					const pc = [...pageContent[mode]];
					return pc.sort((a, b) => a.rectangle.y1 - b.rectangle.y1);
				});

			const flattenedArray = [].concat(...filteredArray);
			return flattenedArray.flat().map((fa) => {
				const isRequirement = checkHardInformation(fa);
				const newTop = computeAbsoluteTop(pageDimensions, fa.pageStart - 1, fa.rectangle.y1, zoom) - 5;
				const top =
					(isRequirement && ((newTop > currentTop + lineSize && newTop) || currentTop + lineSize)) || newTop;
				if (isRequirement) {
					currentTop = top;
				}
				return { ...fa, top };
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[content, mode, currentRange, lineSize, zoom]
	);

	const totalHeight = useMemo(
		() => ((pageDimensions || []).reduce((acc, curr) => acc + curr.height, 0) * zoom) / 100 || 0,
		[pageDimensions, zoom]
	);
	return (
		<div className={styles.syncedDetailsRightPanel} id="syncedDetailsRightPanel__id">
			<div className={styles.syncedDetailsRightPanel__content} style={{ height: totalHeight || 0 }}>
				{checkReqValMode(mode) && (
					<>
						{restrictedContent.map(
							(x, index) =>
								(((mode === "req" &&
									((x && x.validatedSentenceType === SENTENCE_TYPE.REQUIREMENT) ||
										(!x.validatedSentenceType && x.sentenceType === SENTENCE_TYPE.REQUIREMENT))) ||
									x.sentenceType === SENTENCE_TYPE.INFORMATION ||
									mode === "val" ||
									isArchived) && (
									<SuperDetailBlock
										key={x.informationId}
										currentPosition={x.top || 0}
										data={x}
										hoveredBlock={hoveredBlock}
										isFirstInPage={index === 0}
										onMouseEnterBlock={onMouseEnterBlock}
									/>
								)) ||
								null
						)}
					</>
				)}
			</div>
		</div>
	);
});

export default SyncedDetailsRightPanel;
