import { MenuItem } from "@mui/material";
import React from "react";
import { translate } from "../../../../../../../../common/providers";
import styles from "./SelectDropDownRow.module.css";
import { CustomTooltip } from "../../../../../../../../common/components";

export default function SelectDropDownRow({ myId, onClick, style, user }) {
	const helperText = myId === user.id ? translate("dashboard.my-dashboard.option-header.my-account") : "";
	return (
		<MenuItem style={style} onClick={() => onClick(user)}>
			<CustomTooltip
				key={user.email}
				arrow
				showWhenOverflow
				title={`${user.displayName || user.email} ${helperText}`}
			>
				<span className={styles.row}>
					{user.displayName || user.email}
					<span className={styles.row__owner}>{helperText}</span>
				</span>
			</CustomTooltip>
		</MenuItem>
	);
}
