import React, { forwardRef } from "react";
import { MenuItem, MenuList } from "@mui/material";
import { icon, IconComponent } from "../../../../common/components";
import styles from "./DocumentCenterMainDropDown.module.css";
import { translate } from "../../../../common/providers";

function DocumentCenterMainDropDown({ onImportFiles, onNewFolder }, ref) {
	return (
		<MenuList ref={ref} className={styles.dropDown}>
			<label htmlFor="import">
				<MenuItem className={styles.item}>
					<input
						hidden
						multiple
						accept="*"
						data-cy="documentCenterMainDropDown_import"
						id="import"
						name="import"
						type="file"
						onChange={onImportFiles}
					/>
					<IconComponent color="var(--color-light-grey-2)" icon={icon.faFileUpload} />
					<span>
						{translate("document-center.main-drop-down.import")}
						<span className={styles.textCondition_color}>
							&nbsp;({translate("common:upload-file.size-condition")})
						</span>
					</span>
				</MenuItem>
			</label>
			<MenuItem onClick={onNewFolder}>
				<span className={styles.item}>
					<IconComponent color="var(--color-light-grey-2)" icon={icon.faFolderPlus} />
					<span>{translate("document-center.main-drop-down.folder")}</span>
				</span>
			</MenuItem>
		</MenuList>
	);
}

export default forwardRef(DocumentCenterMainDropDown);
