import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Selector, ViewBanner } from "../../common/components";
import { exportView } from "../../navigation";
import { ApiService } from "../../api";
import { getUserNotificationsFrequency, setUserNotificationsFrequency } from "../../common/slice";
import styles from "./NotificationSettings.module.css";
import { translate, translateEnumNotificationFrequency } from "../../common/providers";
import { NOTIFICATIONS_FREQUENCY } from "../../common/constants/notifications-frequency";

const NotificationSettings = () => {
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();

	const isLogged = useSelector(({ context }) => context.isLogged);
	const ctxNotifsFreq = useSelector(({ context }) => context.notificationsFrequency);
	const [notifsFreq, setNotifsFreq] = useState(ctxNotifsFreq);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	useEffect(() => {
		if (isLogged) {
			dispatch(getUserNotificationsFrequency(cancelTokenSourceRef.current.token));
		}
	}, [isLogged, dispatch]);

	useEffect(() => {
		setNotifsFreq(ctxNotifsFreq);
	}, [ctxNotifsFreq]);

	const handleChange = (e) => {
		const { value } = e.target;
		if (NOTIFICATIONS_FREQUENCY.includes(value)) {
			setNotifsFreq(value);
			const cancelToken = ApiService.getCancelTokenSource();
			dispatch(setUserNotificationsFrequency({ value, cancelToken }));
		}
	};

	const displayValue = (item) => translateEnumNotificationFrequency(item);
	const itemRenderer = (item) => translateEnumNotificationFrequency(item);

	return (
		<>
			<ViewBanner titles={[{ title: translate("navigation:home.notification-settings") }]} />
			<div className={styles.container}>
				<p className={styles.pageTitle}>{translate("notification-settings.title")}</p>
				<p className={styles.pageSubtitle}>{translate("notification-settings.subtitle")}</p>

				<div className={styles.section}>
					<p className={styles.sectionTitle}>{translate("notification-settings.general-notifications")}</p>
					<p className={styles.subsectionTitle}>
						{translate("notification-settings.email-notifications.title")}
					</p>
					<p className={styles.subsectionContent}>
						{translate("notification-settings.email-notifications.content")}
					</p>

					<div className={styles.subsectionContainer}>
						<div className={styles.subsectionContainerLeft}>
							<p className={styles.subsectionContainerTitle}>
								{translate("notification-settings.projects-notifications")}
							</p>
							<p className={styles.subsectionContainerContent}>
								{translate("notification-settings.projects-notifications.content")}
							</p>
						</div>
						<div className={styles.subsectionContainerRight}>
							<Selector
								fullWidth
								className={styles.selector}
								itemRenderer={itemRenderer}
								items={NOTIFICATIONS_FREQUENCY}
								value={notifsFreq}
								valueRenderer={(item) => displayValue(item)}
								onChange={handleChange}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default exportView({
	path: "/notification-settings",
	component: NotificationSettings,
	localesPath: "/notification-settings/locales",
});
