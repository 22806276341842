import { KEYWORD_FILTER_OPERATOR } from "../../../../../common/constants";
import { translate } from "../../../../../common/providers";

export const SEPARATOR = { AND: "AND", OR: "OR" };

export const CONTENT_TYPE = "CONTENT_TYPE";
export const CLASSIFICATION = "CLASSIFICATION";
export const THEMATICS = "THEMATICS";
export const CRITICALITY = "CRITICALITY";
export const NEGOTIABILITY = "NEGOTIABILITY";
export const TYPE = "TYPE";

export const isPositiveKeywordFilter = (operator) =>
	[KEYWORD_FILTER_OPERATOR.IS_EXACTLY, KEYWORD_FILTER_OPERATOR.START_WITH].includes(operator);

const translateEnum = (type, value) => translate(`dashboard.my-dashboard.filters.${type}.${value}`);
export const translateEnumKeywordTitle = (value) => translateEnum("keywords.title", value?.toLowerCase());
