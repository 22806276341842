import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService, BackOfficeService, SearchCardTemplateService } from "../../../../../api";
import { CircularLoader, CustomButton, CustomDialog, icon, InfiniteList2 } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import { EditTemplateDialog } from "../..";
import EditDialog from "../edit-dialog/EditDialog";
import styles from "./SearchTemplateMenu.module.css";
import SearchTemplateMenuItem from "./SearchTemplateMenuItem";

export default function SearchTemplateMenu({
	hasAuthorsSearch,
	infiniteRequest,
	isLoading = false,
	menusCount,
	onClickItem,
	onClickOpen,
	onRemove,
	open,
	setIsUpdating,
	slice,
	templateOwner,
	title,
}) {
	const userId = useSelector(({ context }) => context.user.id);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [openEditTemplateDialog, setOpenEditTemplateDialog] = useState(false);
	const [tempTemplate, setTempTemplate] = useState(null);
	const currentSearchId = useSelector((state) => state[slice].currentSearch?.id || 0);
	const isUpdating = useSelector((state) => state[slice].isUpdating);
	const isDsi = useSelector((state) => state[slice].isDsi);
	const [editDialogInfiniteRequest, setEditDialogInfiniteRequest] = useState(null);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();

	const isModifiable = !!onRemove;

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		setEditDialogInfiniteRequest(
			() =>
				({ limit: reqLimit, page }) =>
					(isDsi ? BackOfficeService : SearchCardTemplateService).getTemplates(
						{ page, limit: reqLimit },
						{ filters: { templateOwner } },
						cancelTokenSourceRef.current.token
					)
		);
	}, [templateOwner, isDsi]);

	const handleOpenEditDialog = () => setOpenEditDialog((prev) => !prev);
	const handleEdit = (item) => {
		setOpenEditTemplateDialog(true);
		setTempTemplate({ ...item });
	};
	const handleCloseEditTemplateDialog = () => {
		setOpenEditTemplateDialog(false);
	};
	const handleSubmitEditTemplateDialog = () => {
		dispatch(setIsUpdating(true));
		const payload = {
			title: tempTemplate.title,
			description: tempTemplate.description || null,
			visibility: tempTemplate.visibility,
		};
		(isDsi ? BackOfficeService : SearchCardTemplateService)
			.updateTemplateDetails({ searchTemplateId: tempTemplate.id }, payload, cancelTokenSourceRef.current.token)
			.then(() => {
				dispatch(setIsUpdating(false));
				onClickItem(tempTemplate);
				handleCloseEditTemplateDialog();
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleChangeTempTemplate = (newTemplate) => {
		setTempTemplate(newTemplate);
	};
	const handleCloseConfirmDialog = () => setOpenConfirmDialog(null);
	const handleRemove = ({ id, title: scTitle, creatorId }) => {
		setOpenConfirmDialog({
			id,
			text: translate("search-template.drawer.menu.remove-dialog.text", { title: scTitle }),
			onConfirm: () => {
				onRemove({ id, creatorId });
				handleCloseConfirmDialog();
			},
		});
	};
	const rowRenderer = (item) => {
		const disabled = item.id === currentSearchId;
		return (
			<SearchTemplateMenuItem
				disabled={disabled}
				isModifiable={isModifiable}
				resultCount={item.resultCount}
				title={item.title}
				visibility={item.visibility}
				onClick={() => onClickItem(item)}
			/>
		);
	};
	return (
		<>
			<span className={styles.menu__header}>
				<CustomButton
					className={styles.menu__btn}
					endIcon={open ? icon.faChevronDown : icon.faChevronRight}
					variant="text"
					onClick={onClickOpen}
				>
					{title}
				</CustomButton>
				{isModifiable && open && (
					<CustomButton className={styles.menu__editBtn} onClick={handleOpenEditDialog}>
						{translate("common:btn.edit")}
					</CustomButton>
				)}
			</span>
			{open &&
				(isLoading ? (
					<div className={styles.loaderContainer}>
						<CircularLoader size={40} />
					</div>
				) : (
					<InfiniteList2
						className={styles.infiniteListContainer}
						request={infiniteRequest}
						rowRenderer={rowRenderer}
						scrollContainerClassName={styles.infiniteList__scrollContainer}
						style={{ maxHeight: `calc(100vh - ${227 + menusCount * 40 + (hasAuthorsSearch ? 40 : 0)}px)` }}
					/>
				))}
			<EditDialog
				infiniteRequest={isUpdating ? null : editDialogInfiniteRequest}
				isMySearchTemplate={slice === "mySearchTemplate"}
				open={openEditDialog}
				title={title}
				userId={userId}
				onClose={handleOpenEditDialog}
				onEdit={handleEdit}
				onRemove={handleRemove}
			/>
			<CustomDialog
				maxWidth="xs"
				open={!!openConfirmDialog}
				submitLabel={translate("common:btn.confirm")}
				subTitle={openConfirmDialog?.text || ""}
				title={translate("search-template.drawer.menu.remove-dialog.title")}
				onClose={handleCloseConfirmDialog}
				onSubmit={openConfirmDialog?.onConfirm || null}
			/>
			<EditTemplateDialog
				isMySearchTemplate={slice === "mySearchTemplate"}
				open={openEditTemplateDialog}
				template={tempTemplate}
				onChangeTempTemplate={handleChangeTempTemplate}
				onClose={handleCloseEditTemplateDialog}
				onSubmit={handleSubmitEditTemplateDialog}
			/>
		</>
	);
}
