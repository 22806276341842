import React from "react";
import PropTypes from "prop-types";
import styles from "./VerticalNavbar.module.css";
import { CustomIconButton } from "..";

const VerticalNavbar = ({
	cautionBannerDisabled = false,
	className = "",
	displayContent = null,
	menus = [],
	menuContentClassName = null,
	onSelectMenu = null,
	open = false,
	position = "right",
	selectedMenu = 0,
}) => (
	<div
		className={`${styles.container} ${className}`}
		data-caution-banner-hidden={cautionBannerDisabled}
		data-open={open}
		data-position={position}
	>
		{open && typeof displayContent === "function" && (
			<div className={`${styles.container__menuContent} ${menuContentClassName}`}>{displayContent()}</div>
		)}
		<div className={styles.container__menus}>
			{Array.isArray(menus) &&
				menus.length > 0 &&
				menus
					.filter((m) => !m.disabled)
					.map((menu) => (
						<CustomIconButton
							key={menu.index}
							disableRipple
							badgeClassName={styles.button__badge}
							badgeContent={menu.counter}
							color={selectedMenu === menu.index ? "primary" : "secondary"}
							icon={menu.icon}
							tooltip={menu.name}
							tooltipPlacement="left"
							onClick={() => onSelectMenu(menu.index)}
						/>
					))}
		</div>
	</div>
);

VerticalNavbar.propTypes = {
	position: PropTypes.oneOf(["right", "left"]),
};
VerticalNavbar.defaultProps = {
	position: "right",
};

export default VerticalNavbar;
