import { Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import { CanvasService, BackOfficeService, ApiService } from "../../../../../api";
import { IconComponent, createNotification, icon, CustomButton } from "../../../../../common/components";
import { translate, translateEnumDocumentUserRole } from "../../../../../common/providers";
import styles from "./AddUserForm.module.css";

function initialState() {
	return {
		snapshotSelection: [],
		selection: [],
		newlySelected: [],
		toDelete: [],
		openInformationDialog: false,
		user: "",
		role: null,
		toConfirm: [],
		listNames: [],
		errorMessage: null,
	};
}
function extractUsers(docDetails, role) {
	return (docDetails.find((detail) => detail.role === role)?.documentStatuses || []).map((status) => ({
		id: status.userId,
		displayName: status.userName,
		email: status.userEmail,
		preSubmit: true,
	}));
}
class AddUserForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...initialState() };
		this.handleAddName = this.handleAddName.bind(this);
		this.handleSubmitAndClose = this.handleSubmitAndClose.bind(this);
		this.reset = this.reset.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.requestAddUsers = this.requestAddUsers.bind(this);
		this.requestDeleteUser = this.requestDeleteUser.bind(this);
		this.handleCloseConfirmation = this.handleCloseConfirmation.bind(this);
		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	componentDidUpdate(prevProps) {
		const { open, role, selectedDocDetails } = this.props;
		if (prevProps.open !== open || prevProps.role !== role) {
			if (open) {
				const users = extractUsers(selectedDocDetails, role);
				this.handleUpdateForm({
					role,
					selection: users,
					snapshotSelection: users,
				});
			}
		}
	}

	handleUpdateForm({ role, selection, snapshotSelection }) {
		this.setState({
			role,
			selection,
			snapshotSelection,
		});
	}

	reset() {
		this.setState(initialState());
	}

	handleClose() {
		const { onClose } = this.props;
		this.reset();
		onClose();
	}

	handleSubmitAndClose() {
		const { role, newlySelected, toDelete } = this.state;
		const { selectedDocDetails, onClose } = this.props;
		const foundExisting = extractUsers(selectedDocDetails, role);
		if (foundExisting) {
			this.requestAddUsers(newlySelected);
			if (Array.isArray(toDelete) && toDelete.length > 0) {
				for (const toDeleteElement of toDelete) {
					if (foundExisting.some((x) => x.id === toDeleteElement.id)) {
						this.requestDeleteUser(toDeleteElement);
					}
				}
			}
		} else {
			this.requestAddUsers(newlySelected);
		}

		this.reset();
		onClose();
	}

	handleAddName(user) {
		const { selection, toDelete, snapshotSelection, newlySelected } = this.state;
		this.setState({ errorMessage: null });
		if (!selection.some((x) => x.id === user.id)) {
			this.setState({
				selection: [...selection, user],
			});
		}
		if (!snapshotSelection.some((x) => x.id === user.id) && !newlySelected.some((x) => x.id === user.id)) {
			this.setState({
				newlySelected: [...newlySelected, user],
			});
		}
		const newToDelete = toDelete.filter((x) => x.id !== user.id);
		this.setState({ toDelete: newToDelete });
	}

	handleDelete(user) {
		const { toDelete, snapshotSelection, selection, newlySelected } = this.state;
		if (!toDelete.some((x) => x.id === user.id) && snapshotSelection.some((x) => x.id === user.id)) {
			toDelete.push(user);
		}
		this.setState({
			selection: selection.filter((x) => x.id !== user.id),
			newlySelected: newlySelected.filter((x) => x.id !== user.id),
			toDelete,
		});
	}

	requestAddUsers(users) {
		const { role } = this.state;
		const { onUpdateUsersRoles, dataSelectedDocument, projectId, selectedCompany } = this.props;
		if (!(Array.isArray(users) && users.length > 0)) {
			return;
		}
		const userIds = users.map((user) => user.id);
		const service = selectedCompany ? BackOfficeService : CanvasService;
		const method = selectedCompany ? "updateUserRoleOnDoc" : "addMultiUsersRoleOnDocuments";
		service[method](
			{ projectId, companyId: selectedCompany?.id },
			{
				userIds,
				documentIds: [dataSelectedDocument.documentData.id],
				role,
			},
			this.cancelTokenSource.token
		)
			.then(() => {
				const message = translate("canvas.add-multi-user-roles-form.success.role-has-been-added", {
					roleCount: userIds.length,
				});
				createNotification({ type: "success", message, timeout: 3000 });
				onUpdateUsersRoles();
			})
			.catch((err) => console.error(err));
	}

	requestDeleteUser(user) {
		const { role } = this.state;
		const { onUpdateUsersRoles, dataSelectedDocument, projectId, selectedCompany } = this.props;
		const service = selectedCompany ? BackOfficeService : CanvasService;
		const method = selectedCompany ? "deleteUserDocumentRoles" : "removeMultiUsersRoleOnDocuments";
		const payload = [{ role, documentId: dataSelectedDocument.documentData.id }];
		service[method](
			{ userId: user.id, projectId, companyId: selectedCompany?.id },
			payload,
			this.cancelTokenSource.token
		)
			.then(() => {
				const message = translate("canvas.add-user-form.success.user-has-been-remove-from-role", {
					displayName: user.displayName,
					role,
				});
				createNotification({ type: "success", message, timeout: 3000 });
				onUpdateUsersRoles();
				this.setState((prevState) => ({
					toDelete: prevState.toDelete.filter((x) => x !== user),
				}));
			})
			.catch((err) => {
				console.error(err);
			});
	}

	handleCloseConfirmation() {
		this.reset();
	}

	render() {
		const { errorMessage, selection } = this.state;
		const { open, role, dataSelectedDocument, users } = this.props;
		return (
			<>
				<Dialog fullWidth aria-labelledby="form-dialog-title" open={open} onClose={this.handleClose}>
					<DialogTitle className={styles.title} id="form-dialog-title">
						<IconComponent className={styles.userIcon} color="var(--color-blue)" icon={icon.faUser} />
						{translate("canvas.add-user-form.title")}
					</DialogTitle>
					<DialogContentText className={styles.text}>
						{translate("canvas.add-user-form.doc-role", {
							role: translateEnumDocumentUserRole(role || ""),
							document: dataSelectedDocument.id,
						})}
						<br />
					</DialogContentText>
					<DialogContentText className={styles.textError}>{errorMessage}</DialogContentText>
					<DialogContent className={styles.content}>
						<div className={styles.formWrapper}>
							<div className={styles.userContainer}>
								{users
									.filter((user) => !selection.some((u) => u.id === user.id))
									.map((user) => (
										<div
											key={user.id}
											className={styles.user}
											role="presentation"
											translate="no"
											onClick={() => {
												this.handleAddName(user);
											}}
										>
											{user.displayName || user.email}
										</div>
									))}
							</div>
							<div className={styles.selectedContainer}>
								{selection.map((user) => (
									<Chip
										key={user.id}
										className={styles.chip}
										label={user.displayName || user.email}
										translate="no"
										onDelete={() => {
											this.handleDelete(user);
										}}
									/>
								))}
							</div>
						</div>
					</DialogContent>
					<DialogActions className={styles.actions}>
						<CustomButton color="primary" variant="outlined" onClick={this.handleClose}>
							{translate("common:btn.cancel")}
						</CustomButton>
						<CustomButton color="primary" variant="contained" onClick={this.handleSubmitAndClose}>
							{translate("common:btn.submit")}
						</CustomButton>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

export default AddUserForm;
