import { Checkbox } from "@mui/material";
import React from "react";
import { CircularLoader, CustomDoubleEntryGrid } from "../../../../common/components";
import styles from "./PrivacyGrid.module.css";

export default function PrivacyGrid({
	companies = [],
	companyId,
	isLoading,
	isMyCompanyProjectLeader = false,
	onChangePrivacy,
}) {
	const cellRenderer = ({ xItem, yItem }) => {
		const companyTo = yItem.companyTo.find((company) => company.id === xItem.id);
		return (
			<div className={styles.cell}>
				{(xItem.id !== yItem.id && companyTo && (
					<Checkbox
						checked={!companyTo.privacy}
						color="primary"
						disabled={!companyId || (companyId !== yItem.id && !isMyCompanyProjectLeader)}
						onChange={(event) => onChangePrivacy(event, { companyFrom: yItem, companyTo: xItem })}
					/>
				)) || <div className={styles["cell--disabled"]} />}
			</div>
		);
	};
	return isLoading ? (
		<CircularLoader size={50} />
	) : (
		<CustomDoubleEntryGrid
			cellHeight={50}
			cellRenderer={cellRenderer}
			cellWidth={50}
			className={styles.grid}
			squareSide={150}
			xDisplayField="companyName"
			xItems={companies}
			xKeyField="id"
			yDisplayField="companyName"
			yItems={companies}
			yKeyField="id"
		/>
	);
}
