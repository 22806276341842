import React, { useState, useEffect, useRef } from "react";
import {
	CustomButton,
	GenericDialog,
	GenericDialogContent,
	GenericDialogFooter,
	GenericDialogHeader,
	SearchInput,
	I18nTranslate,
	CircularLoader,
	ActionRow,
	ActionTree,
} from "../../../../../common/components";
import styles from "./ActionDialog.module.css";
import { translate } from "../../../../../common/providers";
import { searchInString } from "../../../../../common/utils";

function filteredCategoryTree(rows, search) {
	const newTree = [];
	rows.forEach((r) => {
		const filteredChildren = Array.isArray(r.subThematic) ? filteredCategoryTree(r.subThematic, search) : [];
		if (searchInString(r.name, search) || filteredChildren.length > 0) {
			newTree.push({ ...r, subThematic: filteredChildren });
		}
	});
	return newTree;
}

export default function ActionDialog({
	isLoading = false,
	isProcessing = false,
	items = [],
	multiple = false,
	none = false,
	onClose = null,
	onSubmit = null,
	open = false,
	title = "",
	totalCount = 0,
	totalSelectedReq = 0,
	type = {},
}) {
	const searchInputRef = useRef(null);
	const [rows, setRows] = useState([]);
	const [search, setSearch] = useState("");
	useEffect(() => {
		if (open) {
			if (!multiple) {
				const newRows = items.map((it) => ({ ...it, checked: it.count === totalCount }));
				if (none) {
					setRows([{ name: "None", checked: false }, ...newRows]);
				} else {
					setRows(newRows);
				}
			} else {
				setRows(items);
			}
		} else {
			setRows([]);
		}
	}, [open, items, totalCount, none, multiple]);
	const handleCheckRow = (row) => {
		setRows((prev) =>
			prev.map((r) =>
				r.name === row.name
					? { ...r, checked: multiple ? !r.checked : true, visited: true }
					: { ...r, checked: multiple ? r.checked : false }
			)
		);
	};

	const updateThematicTree = (arr, item) => {
		const newTree = [...arr];
		function recurse(obj, parent, parentChecked) {
			let isUpdated = parent || false;
			if (item.id === obj.id) {
				obj.visited = true;
				isUpdated = obj.checked;
				parentChecked = !obj.checked; // eslint-disable-line no-param-reassign
				obj.checked = !obj.checked;
			}
			if (item.ancestors.some((x) => obj.id === x)) {
				if (!obj.checked) {
					obj.visited = true;
				}
				obj.checked = true;
			}
			if (parent) {
				obj.visited = true;
				obj.checked = parentChecked;
			}
			if (Array.isArray(obj.subThematic) && obj.subThematic.length > 0) {
				obj.subThematic.forEach((row) => {
					recurse(row, isUpdated, parentChecked);
				});
			}
		}
		newTree.forEach((row) => {
			recurse(row, false, false);
		});
		return newTree;
	};

	const handleCheckTreeRow = (item) => {
		setRows((prev) => updateThematicTree(prev, item));
	};
	const handleSubmit = () => {
		onSubmit(rows, type);
	};
	const handleChangeSearch = (e) => {
		setSearch(e.target.value);
	};
	const handleClearSearch = () => {
		setSearch("");
	};
	const totalSelected = none ? totalSelectedReq : totalCount;
	return (
		<GenericDialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
			<GenericDialogHeader onClose={onClose}>{title}</GenericDialogHeader>
			<GenericDialogContent dividers>
				{(isLoading && (
					<div className={styles.actionDial__loader}>
						<CircularLoader size={64} />
					</div>
				)) ||
					(!isProcessing && (
						<>
							{multiple && (
								<SearchInput
									ref={searchInputRef}
									autoFocus
									fullWidth
									className={styles.actionDial__searchInput}
									label={translate("smart-search.results.action-dialog.search")}
									value={search}
									onChange={handleChangeSearch}
									onClearSearch={handleClearSearch}
								/>
							)}
							{!multiple &&
								Array.isArray(rows) &&
								rows.map((item) => (
									<ActionRow
										key={item.name}
										item={item}
										multiple={false}
										total={totalCount}
										onCheckRow={handleCheckRow}
									/>
								))}
							{multiple && Array.isArray(rows) && (
								<ActionTree
									rows={filteredCategoryTree(rows, search)}
									total={totalCount}
									onCheckRow={handleCheckTreeRow}
								/>
							)}
						</>
					)) || (
						<div className={styles.actionDial__loader}>
							<div>
								<CircularLoader size={64} />
							</div>
							{translate("smart-search.results.action-dialog.request-in-progress")}
						</div>
					)}
			</GenericDialogContent>
			{!isLoading && !isProcessing && (
				<span className={styles.actionDial__informationApply}>
					<I18nTranslate
						param={{ totalSelected }}
						translationKey={
							(none && "smart-search.results.action-dialog.apply-warning.only-req") ||
							"smart-search.results.action-dialog.apply-warning"
						}
					/>
					&nbsp;
					{totalSelected > 1000 && (
						<b>{translate("smart-search.results.action-dialog.apply-warning.high-number")}</b>
					)}
				</span>
			)}
			<GenericDialogFooter>
				<CustomButton color="primary" variant="outlined" onClick={onClose}>
					{translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton
					color="primary"
					disabled={isLoading || isProcessing}
					variant="contained"
					onClick={handleSubmit}
				>
					{translate("common:btn.apply")}
				</CustomButton>
			</GenericDialogFooter>
		</GenericDialog>
	);
}
