import { debounce, isNonEmptyObject } from "../../../common/utils";

export const FILTERS = {
	PROJECTS: "projects",
	COMPANIES: "companies",
	SEARCH: "search",
};

export const debouncedMethod = debounce((func, params) => {
	func(params);
}, 500);
export const generateDocumentFilterName = (docs, defaultName) => {
	if (Array.isArray(docs) && docs.length > 0) {
		return `${docs.length} ${defaultName}`;
	}
	return defaultName;
};
export const generateFilterTitle = (defaultName, nbItemsSelected) => {
	if (nbItemsSelected > 0) {
		return `${defaultName} (${nbItemsSelected})`;
	}
	return defaultName;
};

export function removeNullFilters(filters) {
	const newFilters = {};
	Object.entries(filters).forEach(([fKey, value]) => {
		if (
			!fKey ||
			value === undefined ||
			value === null ||
			(Array.isArray(value) && value.length === 0) ||
			(typeof value === "object" && !isNonEmptyObject(value))
		) {
			return;
		}
		newFilters[fKey] = value;
	});
	return newFilters;
}
