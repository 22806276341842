import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "@mui/material";
import { redressCheck } from "../../../../../../slice/document/document-slice";
import styles from "./RedressInfoCheckbox.module.css";

function checkDisability(current, redressList, minOriginal, maxOriginal) {
	const checkedList = redressList.filter((x) => x.checked).map((x) => x.order);
	const isCurrentInList = checkedList.find((x) => x === current);
	const isThereOnlyOne = isCurrentInList && checkedList.length < 2;
	const isEndMin = Math.max(...checkedList) === minOriginal && isCurrentInList === minOriginal;
	const isStartMax = Math.min(...checkedList) === maxOriginal && isCurrentInList === maxOriginal;
	const isOriginalAtomic = minOriginal === maxOriginal && minOriginal === isCurrentInList;
	return isOriginalAtomic || isThereOnlyOne || isEndMin || isStartMax;
}

export default function RedressInfoCheckbox({ metadata }) {
	const zoom = useSelector(({ srPdf }) => srPdf.zoom);
	const pageDimensions = useSelector(({ srDocument }) => srDocument.pageDimensions);
	const redressingOrders = useSelector(({ srDocument }) => srDocument.redressingOrders);
	const redressList = useSelector(({ srDocument }) => srDocument.redressList);
	const dispatch = useDispatch();
	const handleCheck = (order) => {
		dispatch(redressCheck(order));
	};
	return (
		<>
			{Array.isArray(metadata) &&
				metadata.map((content) => (
					<div key={content.informationId}>
						{Array.isArray(content.rectangles) &&
							content.rectangles.map((rectangle) => (
								<div
									key={`${content.informationId}.${rectangle.contentId}.${rectangle.order}`}
									className={styles.redressInfo}
									style={{
										left:
											(rectangle.x1 * pageDimensions[content.pageStart - 1].width * zoom) / 100 -
											28,
										top:
											(rectangle.y1 * pageDimensions[content.pageStart - 1].height * zoom) / 100 -
											8,
									}}
								>
									<Checkbox
										checked={redressList.some((x) => x.order === rectangle.order && x.checked)}
										classes={{ root: styles.checkbox }}
										color="primary"
										disabled={checkDisability(
											rectangle.order,
											redressList,
											redressingOrders.start,
											redressingOrders.end
										)}
										onChange={() => {
											handleCheck(rectangle.order);
										}}
									/>
								</div>
							))}
					</div>
				))}
		</>
	);
}
