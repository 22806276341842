import React, { useState, useEffect, useMemo } from "react";
import { Divider, Chip, Fab, TextField, InputAdornment, Checkbox } from "@mui/material";
import { icon, IconComponent } from "../../../../common/components";
import styles from "./DocumentPanel.module.css";
import { hasCompanyPermission, hasPermission, Permissions, translate } from "../../../../common/providers";
import { isNonEmptyArray, searchInString } from "../../../../common/utils";

const DocumentPanel = ({
	documents,
	filterTypes,
	handleCheckAllDocuments,
	handleCheckDocument,
	handleOpenDialog,
	phases,
	removeFilter,
	selectDoc,
	selectedDoc,
	selectedDocuments,
}) => {
	const [displayedDocuments, setDisplayedDocuments] = useState(documents);
	const [search, setSearch] = useState("");
	const hasCreationPermission = useMemo(
		() =>
			hasCompanyPermission([Permissions.PROJECT_LEADER]) &&
			hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]),
		[]
	);

	useEffect(() => {
		let docs = documents;
		if (search) {
			docs = documents.filter((doc) => searchInString(doc.name, search));
		}
		filterTypes.forEach((filterType) => {
			const { filters } = filterType;
			if (filters.length === 0) {
				return;
			}
			docs = docs.filter((doc) => {
				if (Array.isArray(doc[filterType.type])) {
					return doc[filterType.type].some((i) => filters.some((filter) => filter.id === i.id));
				}
				if (doc[filterType.type]) {
					return filters.some((filter) => filter.id === doc[filterType.type].id);
				}
				return false;
			});
		});
		setDisplayedDocuments(docs);
	}, [documents, filterTypes, search]);

	const handleSearch = (e) => {
		setSearch(e.target.value || "");
	};

	return (
		<div className={styles.documents}>
			<span className={styles.documents__header}>
				<span className={styles["documents__header-title"]}>
					{translate("deliverable.document-panel.documents-list")}
				</span>
				{hasCreationPermission && (
					<Fab
						className={styles["documents__header-add"]}
						disabled={!isNonEmptyArray(phases)}
						size="small"
						onClick={handleOpenDialog}
					>
						<IconComponent color="var(--color-dark-grey-1)" icon={icon.faPlus} />
					</Fab>
				)}
			</span>
			<div className={styles.documents__search}>
				<TextField
					fullWidth
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconComponent icon={icon.faSearch} />
							</InputAdornment>
						),
					}}
					placeholder={translate("deliverable.search.placeholder")}
					size="small"
					value={search || ""}
					variant="outlined"
					onChange={handleSearch}
				/>
			</div>
			<div className={styles.documents__filters}>
				{Array.isArray(filterTypes) &&
					filterTypes.map((filterType) =>
						filterType.filters.map((filter) => {
							const [key, label] = filterType.display(filter);
							return (
								<Chip
									key={key}
									label={label}
									size="small"
									style={{
										padding: "0px 2px",
										textOverflow: "ellipsis",
										width: "100%",
										color: `${filterType.color}`,
										backgroundColor: `${filterType.backgroundColor}`,
									}}
									onClick={() => removeFilter({ filterName: filterType.name, element: filter })}
									onDelete={() => removeFilter({ filterName: filterType.name, element: filter })}
								/>
							);
						})
					)}
			</div>
			<Divider />
			{Array.isArray(displayedDocuments) && displayedDocuments.length > 0 ? (
				<div className={styles.documents__list}>
					<div className={styles["documents__list-header"]}>
						{hasCreationPermission && (
							<span
								aria-hidden
								style={{ cursor: "pointer" }}
								onClick={() => handleCheckAllDocuments(selectedDocuments.length === documents.length)}
							>
								{translate("common:btn.select-all")}
								<Checkbox
									checked={
										Array.isArray(selectedDocuments) &&
										Array.isArray(documents) &&
										selectedDocuments.length === documents.length
									}
									color="primary"
								/>
							</span>
						)}
					</div>
					{displayedDocuments.map((doc) => (
						<div
							key={doc.id}
							className={styles["documents__list-row"]}
							style={
								selectedDoc && selectedDoc.id === doc.id
									? { backgroundColor: "var(--color-light-grey-3)", color: "var(--color-white)" }
									: null
							}
						>
							<div
								aria-hidden
								style={{ cursor: "pointer", width: "100%", padding: "12px 8px" }}
								onClick={() =>
									selectedDoc && selectedDoc.id === doc.id ? selectDoc(null) : selectDoc(doc)
								}
							>
								<span>{doc.name}</span>
							</div>
							{hasCreationPermission && (
								<Checkbox
									checked={
										Array.isArray(selectedDocuments) &&
										selectedDocuments.some((d) => d.id === doc.id)
									}
									color="primary"
									onClick={() => handleCheckDocument(doc)}
								/>
							)}
						</div>
					))}
				</div>
			) : (
				<div className={styles.documentList__emptyState}>
					{translate("deliverable.document-panel.documents-list.empty-state")}
				</div>
			)}
		</div>
	);
};
export default DocumentPanel;
