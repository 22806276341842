import React, { useState } from "react";
import { Divider, List, ListItem, ListItemText } from "@mui/material";
import styles from "./FilteredRelatedContent.module.css";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../../common/providers";
import { NavUtils } from "../../../../../navigation";
import { CustomButton, Preview } from "../../../../../common/components";
import { ROLES } from "../../../../my-review/constants/constants";
import { SOURCE_TYPES } from "../../../../../common/constants";

export default function FilteredRelatedContent({
	handleCoordinates,
	projectId,
	projectReferences = [],
	references = [],
}) {
	const [coordinates, setCoordinates] = useState([]);
	const [preview, setPreview] = useState(null);

	const fetchReferenceCoordinates = (docId, contentId) => {
		handleCoordinates(docId, contentId)
			.then((data) => {
				setCoordinates(data);
			})
			.catch((err) => console.error(err));
	};
	const handleOpenDocument = (index) => {
		const reference = references.find((r) => r.documentName === projectReferences[index].documentName);
		const projectReference = projectReferences.find((pr) => pr.documentName === reference.documentName);
		const page = projectReference?.contents?.[0]?.page;
		const firstReference = projectReference?.contents?.[0]?.informationId;
		if (!reference?.documentId || !page) {
			return;
		}
		window.location.href = NavUtils.goToSmartReview({
			projectId,
			documentId: reference.documentId,
			pageStart: page,
			infoId: firstReference,
			role: ROLES.REVIEWER.mode,
			source: SOURCE_TYPES.QA,
		});
	};
	const handleDisplayDocument = (index, page, c) => {
		const documentId = references.find((r) => r.documentName === projectReferences[index].documentName)?.documentId;
		if (!documentId) {
			return;
		}
		setPreview({ documentId, informationId: c.informationId, page });
		fetchReferenceCoordinates(documentId, c.contentId);
	};
	const handleCloseDisplay = () => setPreview(null);

	return (
		<>
			<div disabled className={styles["related-container"]}>
				{Array.isArray(projectReferences) && projectReferences.length > 0 ? (
					<List aria-labelledby="nested-list-subheader" component="nav">
						{projectReferences.map((rf, index) => (
							<div key={rf.documentName} className={styles["document-line"]}>
								{index > 0 && <Divider />}
								<ListItem dense>
									<ListItemText id={rf.documentName} primary={rf.documentName} translate="no" />
									{isSegFeatureEnabled(SegFlags.SMART_REVIEW) && (
										<CustomButton
											className={styles["open-document"]}
											color="primary"
											variant="contained"
											onClick={() => handleOpenDocument(index)}
										>
											{translate("qa-journal.qa-details.related-content.open-document")}
										</CustomButton>
									)}
								</ListItem>
								<List dense className={styles["related-list"]}>
									{rf.contents?.map((c) => (
										<ListItem key={c.content} dense>
											<ListItemText id={c.content} primary={c.clause} secondary={c.content} />
											<CustomButton
												color="primary"
												variant="outlined"
												onClick={() => handleDisplayDocument(index, c.page, c)}
											>
												{translate("qa-journal.qa-details.related-content.display")}
											</CustomButton>
										</ListItem>
									))}
								</List>
							</div>
						))}
					</List>
				) : (
					<div>{translate("qa-journal.qa-details.related-content.blank")}</div>
				)}
			</div>
			<Preview
				coordinates={coordinates}
				docId={preview?.documentId}
				infoId={preview?.informationId}
				open={!!preview}
				page={preview?.page}
				projectId={projectId}
				role={ROLES.REVIEWER.mode}
				onClose={handleCloseDisplay}
			/>
		</>
	);
}
