import React from "react";
import { useDispatch } from "react-redux";
import { CircularProgress, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import styles from "./DocumentAutocompleteSearch.module.css";
import { CustomTooltip } from "../../tooltip";
import { setDisableKeyEvents } from "../../../slice";
import Hierarchy from "../../hierarchy/Hierarchy";

const DocumentAutoCompleteSearch = ({
	disabled = false,
	documents,
	emptyStateLabel,
	error,
	helperText,
	inputValue,
	isLoading,
	label = "",
	onReset,
	onSearch,
	onSelect,
}) => {
	const filterOptions = createFilterOptions({
		stringify: (option) => `${option.documentName} ${option.documentFilename}`,
	});
	const dispatch = useDispatch();
	return (
		<Autocomplete
			clearOnBlur
			fullWidth
			disabled={disabled}
			filterOptions={filterOptions}
			getOptionLabel={(option) => option.documentName}
			inputValue={inputValue || ""}
			loading={isLoading}
			noOptionsText={emptyStateLabel}
			options={documents || []}
			renderInput={(params) => (
				<TextField
					{...params}
					error={error}
					helperText={error && helperText}
					InputLabelProps={{ className: styles.labelProps }}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{isLoading ? <CircularProgress size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
						className: styles.props,
					}}
					label={label}
					variant="outlined"
					onBlur={() => dispatch(setDisableKeyEvents(false))}
					onFocus={() => dispatch(setDisableKeyEvents(true))}
				/>
			)}
			renderOption={(props, option) => (
				<CustomTooltip
					showWhenOverflow
					title={
						<>
							<b>{option.documentName}</b> ({option.documentFilename})
						</>
					}
				>
					<div {...props} className={styles.option}>
						<Hierarchy
							enableFirstLocationCut
							isDangerousSetInnerHTML
							className={styles.hierarchy}
							documentName={option.documentName}
							documentNamePlacement="end"
							hierarchy={option.location}
						/>
					</div>
				</CustomTooltip>
			)}
			size="small"
			onChange={(e, value, reason) => {
				if (reason === "clear") {
					onReset();
					return;
				}
				const { directoryId = null, id } = value || {};
				onSelect({ id: directoryId }, id);
			}}
			onInputChange={(e, value, reason) => {
				if (reason === "clear") {
					onReset();
					return;
				}
				onSearch(value);
			}}
		/>
	);
};
export default DocumentAutoCompleteSearch;
