import React, { useEffect, useState } from "react";
import { CustomDialog, IconComponent, Selector, icon } from "../../../../../../../../../common/components";
import { translate } from "../../../../../../../../../common/providers";
import styles from "./LinkRow.module.css";
import { isNonEmptyArray } from "../../../../../../../../../common/utils";
import { linkConversion, translateEnumLinks } from "../../../../../../../utils/utils";
import { useApi } from "../../../../../../../../../common/hooks";
import informationLinkService from "../../../../../../../../../api/services/smartview/information-link-service";

const LinkEditDialog = ({ informationId, link, linkId, linkTypes, open, onClose, onEditLink }) => {
	const [newLinkType, setNewLinkType] = useState("");
	const { call: editLink } = useApi(informationLinkService.editLink);
	const handleSelectType = (e) => {
		const { value } = e.target;
		setNewLinkType(value);
	};
	const handleEdit = () => {
		if (newLinkType) {
			editLink({ informationId, linkId }, { linkType: newLinkType })
				.then(() => {
					onEditLink(linkId, newLinkType);
					onClose();
				})
				.catch(console.error);
		}
	};
	useEffect(() => {
		if (!open) {
			setNewLinkType("");
		}
		if (open) {
			setNewLinkType(link);
		}
	}, [open, link]);
	return (
		<CustomDialog
			isForm
			open={open}
			title={translate("smart-review.cmp-details.option-panel.link-tab.edit-link.dialog.title")}
			onClose={onClose}
			onSubmit={handleEdit}
		>
			<div className={styles.block}>
				<div>
					<span className={styles.title}>
						{translate("smart-review.cmp-details.option-panel.link-tab.create-link.link-type")}
					</span>
					<IconComponent
						className={styles.infoIcon}
						color="var(--color-red)"
						icon={icon.faAsterisk}
						size="2xs"
					/>
				</div>
				<Selector
					fullWidth
					autoTranslate="no"
					disabled={!isNonEmptyArray(linkTypes)}
					itemRenderer={(label) => (
						<span>
							<IconComponent
								className={styles.linkIcon}
								color={linkConversion(label)?.color}
								icon={icon.faLink}
								size="xs"
							/>
							{translateEnumLinks(label)}
						</span>
					)}
					items={linkTypes || []}
					label={translate("smart-review.cmp-details.option-panel.link-tab.create-link.link-type.select")}
					value={newLinkType}
					valueRenderer={(label) => <>{translateEnumLinks(label)}</>}
					onChange={handleSelectType}
				/>
			</div>
		</CustomDialog>
	);
};

export default LinkEditDialog;
