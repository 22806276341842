import client from "../client/client";

class CommentService {
	answerComment({ commentId }, { value }, cancelToken) {
		return client.post(`/comments/${commentId}`, { payload: { value }, config: { cancelToken } });
	}

	deleteComment({ commentId }, cancelToken) {
		return client.delete(`/comments/${commentId}`, { config: { cancelToken } });
	}

	updateAnswerComment({ commentId }, { value }, cancelToken) {
		return client.patch(`/comments/${commentId}`, { payload: { value }, config: { cancelToken } });
	}

	getAnswerComments({ commentId }, { page, limit }, cancelToken) {
		return client.get(`/comments/${commentId}/children`, { config: { cancelToken }, params: { page, limit } });
	}

	updateDiscussionStatus({ commentId }, open, cancelToken) {
		return client.patch(`/comments/${commentId}/discussion`, { payload: open, config: { cancelToken } });
	}

	getAllPrimeComments({ informationId }, { page, limit, isOpenForDiscussion }, cancelToken) {
		return client.get(`/comments/information/${informationId}`, {
			config: { cancelToken },
			params: { page, limit, isOpenForDiscussion },
		});
	}

	addPrimeComment({ informationId }, { value }, cancelToken) {
		return client.post(`/comments/information/${informationId}`, {
			payload: { value },
			config: { cancelToken },
		});
	}

	getCommentStatusCount({ informationId }, cancelToken) {
		return client.get(`/comments/information/${informationId}/count`, {
			config: { cancelToken },
		});
	}

	getAllPrimeProjectComments({ projectId }, payload, { page, limit }, cancelToken) {
		return client.post(`/comments/projects/${projectId}`, {
			payload,
			config: { cancelToken },
			params: { page, limit },
		});
	}

	getAllIssueComments({ projectId }, payload, { page, limit }, cancelToken) {
		return client.post(`/comments/projects/${projectId}/issue-comments`, {
			payload,
			config: { cancelToken },
			params: { page, limit },
		});
	}
}

export default new CommentService();
