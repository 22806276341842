import React, { useState } from "react";
import { Collapse, Paper } from "@mui/material";
import styles from "./Section.module.css";
import { CustomIconButton, CustomTooltip, icon } from "../../../../../../../common/components";

export default function Section({ children, className = "", elevation = 1, title, onCollapse }) {
	const [collapsed, setCollapsed] = useState(false);
	const handleCollaspe = () => {
		if (typeof onCollapse === "function") {
			onCollapse();
		}
		setCollapsed((prev) => !prev);
	};
	return (
		<section className={`${styles.section} ${className}`}>
			<CustomTooltip showWhenOverflow title={title}>
				<span className={styles.section__title}>
					<CustomIconButton
						icon={collapsed ? icon.faChevronDown : icon.faChevronRight}
						onClick={handleCollaspe}
					/>
					{title}
				</span>
			</CustomTooltip>
			<Collapse unmountOnExit in={collapsed} timeout="auto">
				<Paper square className={styles.section__content} elevation={elevation}>
					{children}
				</Paper>
			</Collapse>
		</section>
	);
}
