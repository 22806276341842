import { Radio } from "@mui/material";
import React, { useState } from "react";
import {
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	icon,
} from "../../../../common/components";
import { translate, translateDate } from "../../../../common/providers";
import styles from "./UsersListTable.module.css";

export default function UsersListTable({
	loggedUser,
	users,
	selectedUser,
	onChangeLock,
	onChangeRole,
	onClickRow,
	COMPANY_ROLES,
}) {
	const [tableHeaders] = useState(() => [
		translate("admin.user-list.th.name"),
		translate("admin.user-list.th.creation-date"),
		translate("admin.user-list.th.admin"),
		translate("admin.user-list.th.manager"),
		translate("admin.user-list.th.member"),
		translate("admin.user-list.th.actions"),
	]);
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					{tableHeaders.map((header, index) => {
						const key = index;
						return (
							<CustomTableCell key={key} className={styles.headerCell}>
								{header}
							</CustomTableCell>
						);
					})}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody emptyState={translate("admin.user-list.empty-state.user")}>
				{Array.isArray(users) &&
					users.map((user) => (
						<CustomTableRow
							key={user.id}
							hover
							className={`${styles.tableRow} ${
								(selectedUser === user && styles["tableRow--selected"]) || ""
							}`}
							onClick={() => onClickRow({ user })}
						>
							<CustomTableCell className={styles.cell__name}>
								<span className={styles["cell__name--bold"]} translate="no">
									{user.displayName}
								</span>
								<span translate="no">{user.email}</span>
							</CustomTableCell>
							<CustomTableCell>{translateDate(user.registered)}</CustomTableCell>
							{Array.isArray(COMPANY_ROLES) &&
								COMPANY_ROLES.map((role) => (
									<CustomTableCell key={role.name}>
										<Radio
											checked={user.companyRole === role.name}
											color="primary"
											disabled={user.id === loggedUser.id}
											onChange={() => onChangeRole({ role: role.name, user })}
										/>
									</CustomTableCell>
								))}
							<CustomTableCell>
								{user.id === loggedUser.id && "-"}
								{user.id !== loggedUser.id && (
									<CustomIconButton
										icon={user.locked ? icon.faLock : icon.faLockOpen}
										tooltip={
											user.locked
												? translate("admin.user-list.unlock-user-access")
												: translate("admin.user-list.lock-user-access")
										}
										onClick={() => onChangeLock({ user })}
									/>
								)}
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
