import { InputLabel, OutlinedInput } from "@mui/material";
import React, { useRef, useState } from "react";
import SelectorList from "./SelectorList";

const InfiniteListSelector = ({
	callOnResponse,
	className,
	classNameInfiniteList,
	classes,
	disabled = false,
	endAdornmentRenderer,
	infiniteListRequest,
	nullable = false,
	onChangeSearchText,
	onClick,
	onResetSearchText,
	placeholder = "",
	placeholderClassName = "",
	rowRenderer,
	searchValue = "",
	shrink = false,
	value,
}) => {
	const anchorRef = useRef(null);
	const [isDropDownOpen, setIsDropDownOpen] = useState(false);
	const handleOpenDropDown = () => {
		if (!disabled) {
			setIsDropDownOpen(true);
		}
	};
	const handleCloseDropDown = () => {
		setIsDropDownOpen(false);
	};
	const handleClick = (item) => {
		handleCloseDropDown();
		onClick(item);
	};
	const endAdornment = endAdornmentRenderer(isDropDownOpen);
	return (
		<>
			{placeholder && (
				<InputLabel className={placeholderClassName} shrink={isDropDownOpen || shrink} size="small">
					{placeholder}
				</InputLabel>
			)}
			<OutlinedInput
				ref={anchorRef}
				readOnly
				classes={classes}
				className={className}
				disabled={disabled}
				endAdornment={endAdornment}
				value={value}
				onClick={handleOpenDropDown}
			/>
			<SelectorList
				ref={anchorRef}
				callOnResponse={callOnResponse}
				classNameInfiniteList={classNameInfiniteList}
				infiniteListRequest={infiniteListRequest}
				nullable={nullable}
				open={isDropDownOpen}
				rowRenderer={rowRenderer}
				searchValue={searchValue}
				onChangeSearchText={onChangeSearchText}
				onClick={handleClick}
				onClose={handleCloseDropDown}
				onResetSearchText={onResetSearchText}
			/>
		</>
	);
};

export default InfiniteListSelector;
