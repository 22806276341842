import React, { useState } from "react";
import { TablePagination } from "@mui/material";
import {
	CustomButton,
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	CustomTooltip,
	icon,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import styles from "./NormsTable.module.css";

const EmptyState = ({ hasFilters, onOpenAddNormPanel, onResetFilters }) => (
	<div className={styles.emptyState}>
		<p>
			{hasFilters
				? translate("norms-center.tab.norms.empty-state-with-filter")
				: translate("norms-center.tab.norms.empty-state")}
		</p>
		<span>
			{hasFilters ? (
				<CustomButton color="primary" onClick={onResetFilters}>
					{translate("norms-center.norm-table.filter-reset")}
				</CustomButton>
			) : (
				<CustomButton color="primary" onClick={onOpenAddNormPanel}>
					{translate("norms-center.banner.btn.add-norm")}
				</CustomButton>
			)}
		</span>
	</div>
);
const statusClassName = (row) => {
	if (row.isCustom) {
		return styles["status--custom"];
	}
	return styles[`status--${row.status?.toLowerCase()}`];
};

export default function NormsTable({
	isLoading,
	hasFilters,
	norms,
	page,
	rowsPerPageLimit,
	totalElements,
	onOpenAddNormPanel,
	onChangeFavoriteStatus,
	onPageChange,
	onRowsPerPageChange,
	onClickDetections,
	onClickNewVersion,
	onClickPending,
	onResetFilters,
	onSelectRow,
	onSort,
	orderDirection,
	orderBy,
}) {
	const [headers] = useState([
		{ name: translate("norms-center.tab.norms.headers.favorite") },
		{ name: translate("norms-center.tab.norms.headers.ref"), sortKey: "NAME" },
		{ name: translate("norms-center.tab.norms.headers.release"), sortKey: "YEAR" },
		{ name: translate("norms-center.tab.norms.headers.name") },
		{ name: translate("norms-center.tab.norms.headers.new-version"), sortKey: "NEW_VERSION_NAME" },
		{ name: translate("norms-center.tab.norms.headers.new-release"), sortKey: "NEW_VERSION_DATE" },
		{ name: translate("norms-center.tab.norms.headers.detections") },
		{ name: translate("norms-center.tab.norms.headers.pending") },
		{ name: "", className: styles.norms__headerCellBtn },
	]);
	return (
		<>
			<CustomTable stickyHeader>
				<CustomTableHead>
					<CustomTableRow>
						{headers.map((header) => (
							<CustomTableCell
								key={header.name}
								active={orderBy === header.sortKey}
								className={`${styles.header} ${header.className}`}
								direction={orderDirection || "asc"}
								sortKey={header.sortKey}
								onSort={() => {
									if (typeof onSort === "function") {
										onSort(header.sortKey);
									}
								}}
							>
								{header.name}
							</CustomTableCell>
						))}
					</CustomTableRow>
				</CustomTableHead>
				<CustomTableBody
					emptyState={
						<EmptyState
							hasFilters={hasFilters}
							onOpenAddNormPanel={onOpenAddNormPanel}
							onResetFilters={onResetFilters}
						/>
					}
					isLoading={isLoading}
				>
					{Array.isArray(norms) &&
						norms.map((row) => (
							<CustomTableRow
								key={row.normId}
								hover
								onClick={() => {
									onSelectRow(row);
								}}
							>
								{({ isHovering }) => (
									<>
										<CustomTableCell>
											{row.isFavorite ? (
												<CustomIconButton
													icon={icon.faStar}
													tooltip={translate(
														"norms-center.tab.norms.table.favorite.tooltip.remove"
													)}
													onClick={(e) => {
														e.stopPropagation();
														onChangeFavoriteStatus(row);
													}}
												/>
											) : (
												<CustomIconButton
													icon={icon.faStarRegular}
													tooltip={translate("norms-center.tab.norms.table.favorite.tooltip")}
													onClick={(e) => {
														e.stopPropagation();
														onChangeFavoriteStatus(row);
													}}
												/>
											)}
										</CustomTableCell>
										<CustomTableCell className={styles["norm--underline"]}>
											<span>{row.name}</span>
											<br />
											<span className={`${styles.status} ${statusClassName(row)}`}>
												{(row.isCustom &&
													translate("norms-center.tab.norms.table.status.custom")) ||
													translate(
														`norms-center.tab.norms.table.status.${row.status?.toLowerCase()}`
													)}
											</span>
										</CustomTableCell>
										<CustomTableCell>{row.date?.replace("/", ".") || "-"}</CustomTableCell>
										<CustomTableCell>
											<CustomTooltip arrow showWhenOverflow title={row.title || ""}>
												<span className={styles.norms__name}>{row.title}</span>
											</CustomTooltip>
										</CustomTableCell>
										<CustomTableCell>
											{row.newVersionId && (
												<span
													className={styles["norm--clickable"]}
													role="presentation"
													onClick={(e) => {
														e.stopPropagation();
														onClickNewVersion(row);
													}}
												>
													<span className={styles.newVersion__textBlock}>
														<span className={styles["norm--underline"]}>
															{row.newVersion}
														</span>
														<span
															className={`${styles.status} ${
																row.newVersionStatus === "ACTIVE"
																	? styles["status--active"]
																	: styles["status--canceled"]
															}`}
														>
															{translate(
																`norms-center.tab.norms.table.status.${row.newVersionStatus?.toLowerCase()}`
															)}
														</span>
													</span>
												</span>
											)}
										</CustomTableCell>
										<CustomTableCell className={styles["norm--bold"]}>
											{row.newReleaseDate?.replace("/", ".")}
										</CustomTableCell>
										<CustomTableCell>
											{!!row.detections && (
												<CustomIconButton
													label={row.detections}
													tooltip={translate(
														"norms-center.tab.norms.table.detections.tooltip"
													)}
													variant="contained"
													onClick={(e) => {
														e.stopPropagation();
														onClickDetections(row);
													}}
												/>
											)}
										</CustomTableCell>
										<CustomTableCell>
											{!!row.pendings && (
												<CustomIconButton
													label={row.pendings}
													tooltip={translate("norms-center.tab.norms.table.pending.tooltip")}
													variant="contained"
													onClick={(e) => {
														e.stopPropagation();
														onClickPending(row);
													}}
												/>
											)}
										</CustomTableCell>
										<CustomTableCell align="right">
											{isHovering && (
												<CustomIconButton
													icon={icon.faInfoCircle}
													onClick={() => {
														onSelectRow(row);
													}}
												/>
											)}
										</CustomTableCell>
									</>
								)}
							</CustomTableRow>
						))}
				</CustomTableBody>
			</CustomTable>
			{Array.isArray(norms) && norms.length > 0 && (
				<TablePagination
					className={styles.tablePagination}
					component="div"
					count={totalElements}
					page={page}
					rowsPerPage={rowsPerPageLimit}
					rowsPerPageOptions={[10, 25, 100]}
					onPageChange={onPageChange}
					onRowsPerPageChange={onRowsPerPageChange}
				/>
			)}
		</>
	);
}
