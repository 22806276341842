import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "@mui/material";
import { ApiService, SearchCardTemplateService } from "../../../../../../api";
import {
	CircularLoader,
	CustomButton,
	I18nTranslate,
	icon,
	InfiniteList2,
	OptionsPanelContainer,
	SidePanel,
	SidePanelContent,
	ViewTabs,
} from "../../../../../../common/components";
import { translate, translateEnumTemplatetype } from "../../../../../../common/providers";
import styles from "./AddTemplateSidePanel.module.css";
import { TemplateBox } from "../../../../components";
import { VISIBILITY_TEMPLATE_AUTOMATION } from "../../../../../../common/constants";
import { setIsUpdating } from "../../../slice/search-for-automation-slice";

export default function AddTemplateSidePanel({ open, onClose }) {
	const cancelTokenSourceRef = useRef(null);
	const projectId = useSelector(({ context }) => context.project.id);
	const companyId = useSelector(({ context }) => context.company.id);
	const companyName = useSelector(({ context }) => context.company.name);
	const [selectedTab, setSelectedTab] = useState(0);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const [checkedTemplates, setCheckedTemplates] = useState([]);
	const [uncheckedTemplates, setUncheckedTemplates] = useState([]);
	const [addedTemplates, setAddedTemplates] = useState([]);
	const [addedLoadingTemplates, setAddedLoadingTemplates] = useState([]);
	const [authorInput, setAuthorInput] = useState(null);
	const [templatesCount, setTemplatesCount] = useState(0);
	const [allChecked, setAllChecked] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [isAllAdded, setIsAllAdded] = useState(false);
	const [notAddedTemplates, setNotAddedTemplates] = useState([]);
	const [isAllAddedLoading, setIsAllAddedLoading] = useState(false);
	const dispatch = useDispatch();
	const addTemplateTabs = Object.values(VISIBILITY_TEMPLATE_AUTOMATION).map((type) => ({
		label: translateEnumTemplatetype(type, { companyName }),
	}));
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		setInfiniteListRequest(
			() =>
				({ page, limit }) =>
					SearchCardTemplateService.getTemplates(
						{ page, limit },
						{ filters: {} },
						cancelTokenSourceRef.current.token
					)
		);
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [projectId, companyId]);
	useEffect(() => {
		let templateOwner = Object.values(VISIBILITY_TEMPLATE_AUTOMATION)[selectedTab];
		if (templateOwner === VISIBILITY_TEMPLATE_AUTOMATION.ALL) {
			templateOwner = undefined;
		}
		setAddedTemplates([]);
		setCheckedTemplates([]);
		setIsAllAdded(false);
		setAllChecked(false);
		setUncheckedTemplates([]);
		setNotAddedTemplates([]);
		setInfiniteListRequest(() => ({ page, limit, search }) => {
			setSearchValue(search || undefined);
			return SearchCardTemplateService.getTemplates(
				{ page, limit },
				{
					filters: {
						templateOwner,
						name: search,
					},
				},
				cancelTokenSourceRef.current.token
			);
		});
	}, [selectedTab, authorInput]);
	const handleChangeSelectedTab = (_, newTab) => {
		setSelectedTab(newTab);
	};
	const handleCheckTemplate = (template, newChecked) => {
		if (newChecked) {
			setCheckedTemplates((prev) => [...prev, template.id]);
			setUncheckedTemplates((prev) => prev.filter((tmpId) => tmpId !== template.id));
		} else {
			setCheckedTemplates((prev) => prev.filter((tmpId) => tmpId !== template.id));
			if (allChecked) {
				setUncheckedTemplates((prev) => [...prev, template.id]);
			}
		}
	};
	const handleAddTemplates = (templatesIds) => {
		setAddedLoadingTemplates((prev) => [...prev, ...templatesIds]);
		SearchCardTemplateService.addTemplatesToProject(
			{ projectId },
			{ ids: templatesIds, visibility: "AUTOMATION" },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				setAddedTemplates((prev) => [...prev, ...templatesIds]);
				setAddedLoadingTemplates((prev) => prev.filter((tmpId) => !templatesIds.includes(tmpId)));
				dispatch(setIsUpdating(true));
			})
			.catch((err) => {
				console.error(err);
				setAddedLoadingTemplates((prev) => prev.filter((tmpId) => !templatesIds.includes(tmpId)));
			});
	};
	const addCheckedTemplates = () => {
		if (!allChecked) {
			handleAddTemplates(checkedTemplates);
		} else {
			let templateOwner = Object.values(VISIBILITY_TEMPLATE_AUTOMATION)[selectedTab];
			if (templateOwner === VISIBILITY_TEMPLATE_AUTOMATION.ALL) {
				templateOwner = undefined;
			}
			setIsAllAddedLoading(true);
			SearchCardTemplateService.addTemplatesToProjectByFilter(
				{ projectId },
				{
					templateOwner,
					name: searchValue,
					excludedIds: uncheckedTemplates,
					visibility: "AUTOMATION",
				},
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					setIsAllAdded(true);
					setNotAddedTemplates([...uncheckedTemplates]);
					setAddedTemplates([]);
					setAddedLoadingTemplates([]);
					setIsAllAddedLoading(false);
					dispatch(setIsUpdating(true));
				})
				.catch((err) => {
					console.error(err);
					setAddedLoadingTemplates([]);
					setIsAllAddedLoading(false);
				});
		}
	};
	const isRowChecked = (row) =>
		(allChecked && !uncheckedTemplates.includes(row.id)) || checkedTemplates.includes(row.id);
	const rowRenderer = (row) => (
		<TemplateBox
			checked={isRowChecked(row)}
			isAdded={isAllAdded ? !notAddedTemplates.includes(row.id) : addedTemplates.includes(row.id)}
			isAddedLoading={addedLoadingTemplates.includes(row.id)}
			template={row}
			onAdd={(tmp) => handleAddTemplates([tmp.id])}
			onCheck={(newChecked) => handleCheckTemplate(row, newChecked)}
		/>
	);
	const onInfiniteResponse = (data) => {
		setTemplatesCount(data.totalElements || 0);
	};
	const handleCheckAll = (event) => {
		const newChecked = event.target.checked;
		setAllChecked(newChecked);
		if (newChecked) {
			setUncheckedTemplates([]);
		} else {
			setCheckedTemplates([]);
		}
	};
	const handleClose = () => {
		setAddedTemplates([]);
		setCheckedTemplates([]);
		setIsAllAdded(false);
		setAllChecked(false);
		setUncheckedTemplates([]);
		setNotAddedTemplates([]);
		setAuthorInput(null);
		onClose();
	};
	return (
		<SidePanel offset={2} open={open} size={60} onClose={handleClose}>
			<SidePanelContent title={translate("smart-search.add-template-panel.title")} onClose={handleClose}>
				<ViewTabs
					selectedTab={selectedTab}
					tabs={addTemplateTabs}
					variant="scrollable"
					onChange={handleChangeSelectedTab}
				/>
				<OptionsPanelContainer noHeader onClickSecondary={handleClose}>
					<InfiniteList2
						hasSearch
						callOnResponse={onInfiniteResponse}
						request={infiniteListRequest}
						rowRenderer={rowRenderer}
					>
						<div className={styles.selectAllContainer}>
							<Checkbox
								checked={
									(allChecked && uncheckedTemplates.length === 0) ||
									(templatesCount !== 0 && templatesCount === checkedTemplates.length)
								}
								color="primary"
								onChange={handleCheckAll}
							/>
							{((allChecked || checkedTemplates.length > 0) && (
								<div className={styles.actionsRow}>
									<I18nTranslate
										param={{
											templatesCount,
											selectionCount: allChecked
												? templatesCount - uncheckedTemplates.length
												: checkedTemplates.length,
										}}
										translationKey="common:add-template-panel.templates-count.selection"
									/>
									<span className={styles.actionsRow__separator}>&nbsp;|&nbsp;</span>
									<span className={styles.actionsRow__actionText}>
										{translate("common:add-template-panel.actions")}
									</span>
									{(isAllAddedLoading && (
										<CustomButton
											className={styles.actionsRow__addButton}
											color="primary"
											variant="contained"
										>
											<CircularLoader color="info" size={25} />
										</CustomButton>
									)) || (
										<CustomButton
											className={styles.actionsRow__addButton}
											color="primary"
											startIcon={icon.faPlus}
											variant="contained"
											onClick={addCheckedTemplates}
										>
											{translate("common:btn.add")}
										</CustomButton>
									)}
								</div>
							)) || (
								<span className={styles.selectAllContainer__count}>
									{translate("common:add-template-panel.templates-count", {
										templatesCount,
									})}
								</span>
							)}
						</div>
					</InfiniteList2>
				</OptionsPanelContainer>
			</SidePanelContent>
		</SidePanel>
	);
}
