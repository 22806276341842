import { MenuItem, TextField } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { CustomDialog, icon, IconComponent } from "../../../../../common/components";
import { VISIBILITY_TEMPLATE_CREATION, VISIBILITY_TEMPLATE_AUTOMATION } from "../../../../../common/constants";
import { translate } from "../../../../../common/providers";
import styles from "./EditTemplateDialog.module.css";

export default function EditTemplateDialog({
	isMySearchTemplate,
	open,
	template = {},
	onChangeTempTemplate,
	onClose,
	onSubmit,
}) {
	const visibilityInputs = useMemo(
		() => (isMySearchTemplate ? VISIBILITY_TEMPLATE_CREATION : VISIBILITY_TEMPLATE_AUTOMATION),
		[isMySearchTemplate]
	);
	const isDsi = useSelector(({ mySearchTemplate }) => mySearchTemplate.isDsi);
	const handleChangeName = (e) => {
		onChangeTempTemplate({ ...template, title: e.target.value });
	};
	const handleChangeDescription = (e) => {
		onChangeTempTemplate({ ...template, description: e.target.value });
	};
	const handleChangeVisibility = (e) => {
		onChangeTempTemplate({ ...template, visibility: e.target.value });
	};
	return (
		<CustomDialog
			isForm
			className={styles.dialog}
			open={open}
			submitDisabled={!template?.title}
			submitLabel={translate("common:btn.save")}
			title={translate("search-template.dialogs.create-template-dialog.title")}
			onClose={onClose}
			onSubmit={onSubmit}
		>
			<div className={styles.content__inputs}>
				<TextField
					color="primary"
					helperText={translate("search-template.dialogs.create-template-dialog.input.name.helper")}
					inputProps={{ type: "text", maxLength: 250 }}
					placeholder={`${translate("search-template.dialogs.create-template-dialog.input.name")}*`}
					size="small"
					value={template?.title}
					variant="outlined"
					onChange={handleChangeName}
				/>
				<TextField
					color="primary"
					helperText={translate("search-template.dialogs.create-template-dialog.input.description.helper")}
					inputProps={{ type: "text", maxLength: 1000 }}
					placeholder={translate("search-template.dialogs.create-template-dialog.input.description")}
					size="small"
					value={template?.description}
					variant="outlined"
					onChange={handleChangeDescription}
				/>
				{!isDsi && isMySearchTemplate && (
					<>
						<TextField
							select
							color="primary"
							helperText={translate(
								"search-template.dialogs.create-template-dialog.input.visibility.helper"
							)}
							size="small"
							value={template?.visibility}
							variant="outlined"
							onChange={handleChangeVisibility}
						>
							{Object.entries(visibilityInputs).map(([key, value]) => (
								<MenuItem key={key} value={value}>
									{translate(
										`search-template.dialogs.create-template-dialog.input.visibility.${value
											?.toLowerCase()
											.replaceAll("_", "-")}`
									)}
								</MenuItem>
							))}
						</TextField>
					</>
				)}
				{(!isMySearchTemplate || isDsi) && (
					<div className={styles.infoBox}>
						<IconComponent
							className={styles.infoIcon}
							color="var(--color-blue)"
							icon={icon.faInfoCircle}
							size="lg"
						/>
						{translate("search-template.dialogs.create-template-dialog.visible-leader-manager")}
					</div>
				)}
			</div>
		</CustomDialog>
	);
}
