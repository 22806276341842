import React from "react";
import { Checkbox, Chip } from "@mui/material";
import { translate, translateDate } from "../../../../../common/providers";
import styles from "./QAListRow.module.css";

export default function QAListRow({ style, qa, onClick, onSelect, selected, checked, onDelete }) {
	const className = selected ? `${styles["qa-row"]} ${styles["qa-row--selected"]}` : `${styles["qa-row"]}`;
	const classNameCheckbox = selected
		? `${styles["qa-row__checkbox"]}  ${styles["qa-row__checkbox--selected"]}`
		: styles["qa-row__checkbox"];
	return (
		<button className={className} style={style} type="button">
			<div className={styles["checkbox-container"]}>
				<Checkbox checked={checked} className={classNameCheckbox} color="primary" onClick={onClick} />
				<div aria-hidden className={styles["row-container"]} onClick={onSelect}>
					<span className={styles["row-container__span"]}>
						{qa.question?.split("\n").map((q) => (
							<p key={q} className={styles.comment}>
								{q}
							</p>
						))}
					</span>
					<span
						className={`${styles["row-container__chips"]} ${
							(qa.tags?.length === 0 && styles["row-container__chips--empty"]) || ""
						}`}
					>
						{Array.isArray(qa.tags) &&
							qa.tags.map((tag) => (
								<Chip
									key={tag.id}
									color="default"
									label={tag.tag}
									size="medium"
									onDelete={() => onDelete(qa, tag)}
								/>
							))}
					</span>
					<span>
						{(qa.clientId &&
							translate("qa-journal.qa-details.client-id", {
								clientId: qa.clientId,
							})) ||
							translate("qa-journal.qa-list.qa-list-row.qa-id", { qaId: qa.id })}
					</span>
					<div className={styles["date-container"]}>
						<span>{translate("qa-journal.qa-list.qa-list-row.date-span.modified")}</span>
						<span> {translateDate(qa.modified)} </span>
					</div>
				</div>
			</div>
		</button>
	);
}
