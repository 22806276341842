import React from "react";
import { useSelector } from "react-redux";
import { NavUtils } from "../../../navigation";
import KeycloakService from "../../../services/KeycloakService";
import { translate } from "../../providers";
import permissions from "../../providers/permissions/permissions";
import { icon, IconComponent } from "../icon-component";
import { CustomButtonLink } from "../links";
import TitleMenu from "./TitleMenu";
import styles from "./ViewBanner.module.css";

export default function ViewBanner({ className = null, options = [], titles }) {
	// Limit options display to 3. If need more, use a menu
	options.length = 3;
	const projectName = useSelector(({ context }) => context.project?.name);
	return (
		<>
			<header data-home className={`${className} ${styles.banner}`}>
				<CustomButtonLink
					buttonClassName={styles.banner__homeBtn}
					color="secondary"
					size="lg"
					to={
						(KeycloakService.hasRole([permissions.REALM.ADMIN.claim]) && NavUtils.goToDSI()) ||
						NavUtils.goToHome()
					}
				>
					{translate("common:btn.home")}
				</CustomButtonLink>
				{Array.isArray(titles) && titles.length > 0 && (
					<IconComponent color="var(--color-light-grey-1)" icon={icon.faCaretRight} />
				)}
				{projectName && (
					<>
						<span className={styles.banner__title} translate="no">
							{projectName}
						</span>
						<IconComponent color="var(--color-light-grey-1)" icon={icon.faCaretRight} />
					</>
				)}
				{Array.isArray(titles) &&
					titles.length > 0 &&
					titles.map((title, index) =>
						title.title ? (
							<TitleMenu
								key={title.title + title.key}
								index={index}
								length={titles.length}
								title={title}
							/>
						) : null
					)}
				<span className={styles.banner__spacer} />
				<span className={styles.banner__options}>
					{Array.isArray(options) &&
						options.map((opt, index) => {
							const key = index;
							return (
								<span key={key} className={styles.banner__option}>
									{opt}
								</span>
							);
						})}
				</span>
			</header>
		</>
	);
}
