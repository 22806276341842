import React from "react";
import { useSelector } from "react-redux";
import styles from "./DocumentDragAndDropInfo.module.css";
import { isNonEmptyArray } from "../../../utils";
import { translate } from "../../../providers";
import { icon, IconComponent } from "../../icon-component";

const DocumentDragAndDropInfo = ({ className = "", project = false }) => {
	const location = useSelector(({ documentCenter }) => documentCenter.location);
	const getLastLocationName = () => {
		if (!project) {
			if (isNonEmptyArray(location) && location?.[location.length - 1]?.label) {
				return location?.[location.length - 1]?.label;
			}
			return translate("document-center.root-dir");
		}
		return null;
	};
	return (
		<div className={`${styles.dragAndDropHelper__container} ${className}`}>
			<div className={styles.uploadIcon}>
				<IconComponent color="var(--color-white)" icon={icon.faFileImport} size="3xl" />
			</div>
			<div className={styles.info}>
				<div className={styles.infoMainText}>
					{translate(
						(project && "common:document.drag-and-drop.info-text-project") ||
							"common:document.drag-and-drop.info-text"
					)}
				</div>
				<div>
					{!project && (
						<>
							<IconComponent
								className={styles.folderIcon}
								color="var(--color-white)"
								icon={icon.faFolder}
								size="sm"
							/>
							<span className={styles.locationName}>{getLastLocationName()}</span>
						</>
					)}
					<div className={styles.supportedFiles}>
						{translate("common:document.drag-and-drop.supported-files")}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DocumentDragAndDropInfo;
