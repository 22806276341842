import client from "../../client/client";

class ProjectService {
	getTypes(cancelToken) {
		return client.get("/projects/types", { config: { cancelToken } });
	}

	getUsersByDocumentAndRole({ projectId, documentId, role }, cancelToken) {
		return client.get(`/projects/${projectId}/documents/${documentId}/roles/${role}`, { config: { cancelToken } });
	}
}

export default new ProjectService();
