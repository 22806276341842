import { Checkbox, Radio } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ApiService, SearchCardTemplateService } from "../../../../../api";
import {
	CircularLoader,
	CustomButton,
	CustomTooltip,
	I18nTranslate,
	icon,
	IconComponent,
	SidePanelBox,
} from "../../..";
import { SEARCH_TYPES } from "../../../../constants/search-types";
import { translate, translateDate, translateElapsed } from "../../../../providers";
import { NavUtils } from "../../../../../navigation";
import styles from "./TemplateBox.module.css";

export default function TemplateBox({
	checked = false,
	isAdded = false,
	isAddedLoading = false,
	onAdd,
	onCheck,
	singleSelection = false,
	template = {},
}) {
	const [hover, setHover] = useState(false);
	const projectId = useSelector(({ context }) => context.project?.id);
	const [totalResults, setTotalResults] = useState(null);
	const [alreadyHovered, setAlreadyHovered] = useState(false);
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const loadResults = () => {
		SearchCardTemplateService.countTemplateResults(
			{ projectId, templateId: template.id },
			{ ...template.filters, Type: SEARCH_TYPES.template },
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				setTotalResults(data);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleMouseEnter = () => {
		setHover(true);
		if (projectId) {
			if (!alreadyHovered) {
				loadResults();
				setAlreadyHovered(true);
			}
		}
	};
	const handleMouseLeave = () => {
		setHover(false);
	};
	const handleCheck = () => {
		if (typeof onCheck === "function") {
			onCheck(!checked);
		}
	};
	const handleAddTemplate = () => {
		if (typeof onAdd === "function") {
			onAdd(template);
		}
	};
	const handleClickDetails = () => {
		window.open(NavUtils.goToMySearchTemplate(template.id, projectId));
	};
	return (
		<>
			<SidePanelBox
				className={styles.container}
				hasShadow={false}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<div className={styles.templateBox}>
					<div className={styles.templateBox__checkboxContainer}>
						{(singleSelection && <Radio checked={checked} color="primary" onChange={handleCheck} />) || (
							<Checkbox checked={checked} color="primary" onChange={handleCheck} />
						)}
					</div>
					<div className={styles.templateBox__contentContainer}>
						<div className={styles.contentContainer__header}>
							<div className={styles.header__leftItems}>
								<CustomTooltip title={template.title || ""}>
									<div className={styles.leftItems__title}>{template.title}</div>
								</CustomTooltip>
								<div className={styles.leftItems__subtitleContainer}>
									<div className={styles.subTitleContainer__username}>
										{template.creatorName}&nbsp;-&nbsp;
									</div>
									<CustomTooltip
										placement="top"
										title={
											(!!template.modified &&
												translateDate(template.modified).replaceAll(".", "/")) ||
											""
										}
									>
										<div className={styles.subTitleContainer__elapsed}>
											{translateElapsed({
												value: template.elapsed?.value,
												unitKey: template.elapsed?.unit,
											})}
										</div>
									</CustomTooltip>
								</div>
							</div>
							<div className={styles.header__rightItems} data-hover={hover}>
								{hover && (
									<>
										{!!projectId && (
											<div className={styles.rightItems__results}>
												{(totalResults !== null && (
													<I18nTranslate
														param={{
															resultsCount: totalResults,
														}}
														translationKey="common:add-template-panel.box.results"
													/>
												)) || <CircularLoader size={25} />}
											</div>
										)}
										{projectId && (
											<CustomButton variant="outlined" onClick={handleClickDetails}>
												{translate("common:add-template-panel.box.details-button")}
											</CustomButton>
										)}
										{!isAdded && !isAddedLoading && !!projectId && (
											<CustomButton
												color="primary"
												startIcon={icon.faPlus}
												variant="contained"
												onClick={handleAddTemplate}
											>
												{translate("common:btn.add")}
											</CustomButton>
										)}
									</>
								)}
								{(isAddedLoading && (
									<CustomButton color="primary" variant="contained">
										<CircularLoader color="info" size={25} />
									</CustomButton>
								)) ||
									(isAdded && (
										<IconComponent color="var(--color-green)" icon={icon.faCheckCircle} size="xl" />
									))}
							</div>
						</div>
						<div className={styles.contentContainer__body}>{template.description}</div>
					</div>
				</div>
			</SidePanelBox>
		</>
	);
}
