import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce, InputAdornment, CircularProgress, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import {
	AddMembersSidePanel,
	BlockTitle,
	createNotification,
	CustomButton,
	CustomIconButton,
	icon,
	IconComponent,
	ViewTabs,
} from "../../../../common/components";
import { translate, Permissions } from "../../../../common/providers";
import styles from "./Members.module.css";
import { ApiService, ProjectTeamService } from "../../../../api";
import { MembersList } from ".";
import { STATUSES } from "../../constants/constants";
import AddNewMembersDialog from "./AddNewMembersDialog";

const debounceSearch = debounce((searchFunction) => {
	searchFunction();
}, 1000);

export default function Members({
	selectedCompany,
	projectId,
	onUpdateActiveUsersCount,
	projectRoles = [],
	isMyCompanyProjectLeader,
	isUserProjectManager,
	isUserProjectLeader,
	myCompany,
}) {
	const loggedUser = useSelector(({ context }) => context.user);
	const project = useSelector((state) => state.context.project);
	const [selectedTab, setSelectedTab] = useState(0);
	const [users, setUsers] = useState([]);
	const [counters, setCounters] = useState({});
	const [openPanel, setOpenPanel] = useState(false);
	const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
	const [searchFilterValue, setSearchFilterValue] = useState("");
	const [statusFilter, setStatusFilter] = useState("");
	const [isLoadingUsers, setIsLoadingUsers] = useState(false);
	const [totalElements, setTotalElements] = useState(0);
	const [page, setPage] = useState(0);
	const [availableUsers, setAvailableUsers] = useState([]);
	const [totalAvailableUsers, setTotalAvailableUsers] = useState(0);
	const [availableUsersLoading, setAvailableUsersLoading] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [checkedUsers, setCheckedUsers] = useState([]);
	const cancelTokenSourceRef = useRef(null);
	const newMembersOnly =
		selectedCompany.id !== myCompany.id &&
		isMyCompanyProjectLeader &&
		isUserProjectLeader &&
		selectedCompany.type !== "CONSORTIUM";

	useEffect(() => {
		if (!cancelTokenSourceRef.current) {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		}
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const getCounters = useCallback(
		(pId, cId) => {
			ProjectTeamService.getCounts({ projectId: pId, companyId: cId }, cancelTokenSourceRef.current.token)
				.then((data) => {
					setCounters(data);
					switch (selectedTab) {
						case 0:
							setTotalElements(data.countAll);
							break;
						case 1:
							setTotalElements(data.countActive);
							break;
						case 2:
							setTotalElements(data.countPending);
							break;
						case 3:
							setTotalElements(data.countBlocked);
							break;
						default:
					}
				})
				.catch((err) => {
					console.error(err);
				});
		},
		[selectedTab]
	);
	useEffect(() => {
		getCounters(projectId, selectedCompany.id);
	}, [getCounters, projectId, selectedCompany.id]);
	const loadMore = useCallback(
		(filter, status, pageNum = 0) => {
			setIsLoadingUsers(true);
			if (!cancelTokenSourceRef.current) {
				cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
			}
			return ProjectTeamService.getUsersByCompany(
				{ projectId, companyId: selectedCompany.id },
				{ filter, status, page: pageNum, limit: 25 },
				cancelTokenSourceRef.current.token
			)
				.then((data) => {
					setUsers((prev) => (pageNum === 0 ? data.contents : [...prev, ...data.contents]));
					setIsLoadingUsers(false);
				})
				.catch((err) => {
					console.error(err);
				});
		},
		[projectId, selectedCompany]
	);
	const reloadUsers = useCallback(
		(search, status) => {
			loadMore(search, status, 0);
			setPage(1);
		},
		[loadMore]
	);
	useEffect(() => {
		reloadUsers(searchFilterDebounced, statusFilter);
	}, [statusFilter, searchFilterDebounced, reloadUsers]);

	const handleOpenPanel = () => {
		setOpenPanel(true);
	};
	const handleClosePanel = () => {
		setOpenPanel(false);
	};
	const handleLoadMore = () => {
		setPage(page + 1);
		return loadMore(searchFilterDebounced, statusFilter, page);
	};
	const handleChangeSelectedTab = (event, tab) => {
		if (tab === selectedTab) {
			return;
		}
		setSelectedTab(tab);
		setPage(0);
		setUsers([]);
		switch (tab) {
			case 0:
				setStatusFilter("");
				setTotalElements(counters.countAll);
				break;
			case 1:
				setStatusFilter("ACTIVE");
				setTotalElements(counters.countActive);
				break;
			case 2:
				setStatusFilter("PENDING");
				setTotalElements(counters.countPending);
				break;
			case 3:
				setStatusFilter("BLOCKED");
				setTotalElements(counters.countBlocked);
				break;
			default:
				setStatusFilter("");
		}
	};
	const handleSendInvitation = (userId) => {
		ProjectTeamService.sendInvitation(
			{ projectId, userId, companyId: selectedCompany.id },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				createNotification({
					type: "success",
					message: translate("team-management.members.members-table.success-message.invitation"),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleChangeUserRole = ({ user, role }) => {
		const userId = user.id;
		ProjectTeamService.changeUserRole(
			{ projectId, userId },
			{ role, targetCompanyId: selectedCompany.id === myCompany.id ? null : selectedCompany.id },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				const index = users.findIndex((u) => u.id === userId);
				if (index >= 0) {
					setUsers((prev) => {
						const newUsers = [...prev];
						newUsers[index].projectRole = role;
						return newUsers;
					});
				}
			})
			.catch((err) => {
				console.error(err);
				createNotification({
					type: "error",
					message: translate("team-management.members.members-table.error-message.at-least-on-leader"),
				});
			});
	};
	const handleChangeLock = (user) => {
		const isBlocked = user.blocked;
		const userId = user.id;
		ProjectTeamService.changeUserAccess(
			{ projectId, userId },
			{
				status: isBlocked ? STATUSES.active : STATUSES.blocked,
				targetCompanyId: selectedCompany.id === myCompany.id ? null : selectedCompany.id,
			},
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				const index = users.findIndex((u) => u.id === userId);
				if (index >= 0) {
					setUsers((prev) => {
						const newUsers = [...prev];
						if (newUsers[index].status === STATUSES.pending || selectedTab === 3 || selectedTab === 1) {
							newUsers.splice(index, 1);
						} else {
							newUsers[index].blocked = !isBlocked;
						}
						return newUsers;
					});
				}
				getCounters(projectId, selectedCompany.id);
				onUpdateActiveUsersCount(isBlocked && user.status !== "PENDING");
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleChangeSearch = (event) => {
		setSearchFilterValue(event.target.value);
		if (event.target.value !== "") {
			setUsers([]);
			setIsLoadingUsers(true);
			debounceSearch(() => setSearchFilterDebounced(event.target.value));
		} else {
			setSearchFilterDebounced(event.target.value);
		}
	};
	const onClearSearch = () => {
		setSearchFilterValue("");
		setSearchFilterDebounced("");
	};
	const handleAddToProject = () => {
		ProjectTeamService.addExistingUsers(
			{ projectId: project.id, companyId: selectedCompany.id },
			checkedUsers.map((u) => ({ id: u.id })),
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				reloadUsers(searchFilterDebounced, statusFilter);
				getCounters(projectId, selectedCompany.id);
				createNotification({
					type: "success",
					message:
						checkedUsers.length === 1
							? translate("team-management.members.side-panel.success-message.add-user")
							: translate("team-management.members.side-panel.success-message.add-users"),
				});
				setCheckedUsers([]);
				onUpdateActiveUsersCount(true, checkedUsers.length);
				handleClosePanel();
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleSearchUsers = useCallback(
		(searchPage, limit, filter = "") => {
			let request;
			if (selectedCompany.type === "CONSORTIUM") {
				request = ProjectTeamService.getAvailableUsersForConsortium(
					{
						projectId: project.id,
						consortiumId: selectedCompany.id,
					},
					{ filter, page: searchPage, limit },
					cancelTokenSourceRef.current.token
				);
			} else {
				request = ProjectTeamService.getAvailableUsers(
					{
						projectId: project.id,
					},
					{ filter, page: searchPage, limit },
					cancelTokenSourceRef.current.token
				);
			}
			request
				.then((data) => {
					setAvailableUsersLoading(false);
					setAvailableUsers(data.contents);
					setTotalAvailableUsers(data.totalElements);
				})
				.catch((err) => {
					setAvailableUsersLoading(false);
					console.error(err);
				});
		},
		[project.id, selectedCompany]
	);
	const handleChangeAvailableUsersLoading = (isLoading) => {
		setAvailableUsersLoading(isLoading);
	};
	const handleCloseDialog = () => {
		setOpenDialog(false);
		if (newMembersOnly) {
			handleClosePanel();
		}
	};
	const handleSubmitDialog = () => {
		setOpenDialog(false);
		handleClosePanel();
		setCheckedUsers([]);
		reloadUsers();
		getCounters(projectId, selectedCompany.id);
	};
	const allowAddUser =
		(selectedCompany.id === myCompany.id && (isUserProjectLeader || isUserProjectManager)) ||
		(isUserProjectLeader && myCompany.role === Permissions.PROJECT_LEADER.role);
	return (
		<div className={styles.container}>
			<BlockTitle autoTranslate="no">
				{translate("team-management.members.title", {
					companyName: selectedCompany.name || "",
				})}
			</BlockTitle>
			<div className={styles.header}>
				<TextField
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{isLoadingUsers && <CircularProgress size={25} thickness={3} />}
								{!isLoadingUsers && !searchFilterValue && <IconComponent icon={icon.faSearch} />}
								{!isLoadingUsers && onClearSearch && searchFilterValue && (
									<CustomIconButton icon={icon.faTimes} onClick={onClearSearch} />
								)}
							</InputAdornment>
						),
					}}
					placeholder={translate("team-management.panel-header.placeholder.search")}
					size="small"
					value={searchFilterValue}
					variant="outlined"
					onChange={handleChangeSearch}
				/>
				{allowAddUser && (
					<CustomButton
						className={styles.button}
						color="primary"
						data-testid="add.user.button"
						variant="contained"
						onClick={handleOpenPanel}
					>
						{translate("team-management.members.button.add-users")}
					</CustomButton>
				)}
			</div>
			<ViewTabs
				level="first"
				selectedTab={selectedTab}
				tabs={[
					{ label: translate("team-management.members.tabs.all", { count: counters.countAll }) },
					{ label: translate("team-management.members.tabs.members", { count: counters.countActive }) },
					{
						label: translate("team-management.members.tabs.pending-invitations", {
							count: counters.countPending,
						}),
					},
					{
						label: translate("team-management.members.tabs.blocked-users", {
							count: counters.countBlocked,
						}),
					},
				]}
				onChange={handleChangeSelectedTab}
			/>
			<MembersList
				isLoadingUsers={isLoadingUsers}
				isMyCompanyProjectLeader={isMyCompanyProjectLeader}
				isUserProjectLeader={isUserProjectLeader}
				isUserProjectManager={isUserProjectManager}
				loggedUser={loggedUser}
				roles={projectRoles}
				selectedCompanyIsMine={selectedCompany.id === myCompany.id}
				totalElements={totalElements}
				users={users}
				onChangeLock={handleChangeLock}
				onChangeRole={handleChangeUserRole}
				onLoadMore={handleLoadMore}
				onSendInvitation={handleSendInvitation}
			/>
			{allowAddUser && (
				<>
					<AddMembersSidePanel
						addToProjectLabel={
							selectedCompany.type === "CONSORTIUM"
								? translate("team-management.members.side-panel.button.add-to-team", {
										teamName: selectedCompany.name,
								  })
								: translate("team-management.members.side-panel.button.add-to-project")
						}
						availableUsers={availableUsers}
						availableUsersLoading={availableUsersLoading}
						checkedUsers={checkedUsers}
						newMembersLabel={translate("team-management.members.side-panel.button.add-new-members")}
						newMembersOnly={newMembersOnly}
						open={openPanel}
						title={
							selectedCompany.type === "CONSORTIUM"
								? translate("team-management.members.side-panel.title.project-members")
								: translate("team-management.members.side-panel.title.directory", {
										partnerName: selectedCompany.name,
								  })
						}
						totalAvailableUsers={totalAvailableUsers}
						onAddToProject={handleAddToProject}
						onChangeAvailableUsersLoading={handleChangeAvailableUsersLoading}
						onChangeCheckedUsers={setCheckedUsers}
						onChangeOpenDialog={setOpenDialog}
						onClose={handleClosePanel}
						onSearchUsers={handleSearchUsers}
					/>
					<AddNewMembersDialog
						open={openDialog}
						selectedCompany={selectedCompany}
						onClose={handleCloseDialog}
						onSubmit={handleSubmitDialog}
						onUpdateActiveUsersCount={onUpdateActiveUsersCount}
					/>
				</>
			)}
		</div>
	);
}
