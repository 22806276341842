import client from "../client/client";

class CategoryTemplateService {
	getTreeForCompany({ search = "" }, cancelToken) {
		return client.get("/thematic-templates", {
			params: { search },
			config: { cancelToken },
		});
	}

	getTreeParentsForCompany(cancelToken) {
		return client.get("/thematic-templates/parents", {
			config: { cancelToken },
		});
	}

	getTreeChildrenForCompany({ parentId }, cancelToken) {
		return client.get(`/thematic-templates/${parentId}/children`, {
			config: { cancelToken },
		});
	}

	getAllChildrenOfParentForCompany({ parentId }, cancelToken) {
		return client.get(`/thematic-templates/${parentId}/children/all`, {
			config: { cancelToken },
		});
	}

	createFastTrackAutomation(payload, cancelToken) {
		return client.post("/thematic-templates", {
			payload,
			config: { cancelToken },
		});
	}

	getAutomationTemplate({ thematicId }, cancelToken) {
		return client.get(`/thematic-templates/${thematicId}`, {
			config: { cancelToken },
		});
	}

	updateAutomationTemplate({ thematicId }, payload, cancelToken) {
		return client.patch(`/thematic-templates/${thematicId}`, {
			payload,
			config: { cancelToken },
		});
	}

	selectAllTreeForCompany(cancelToken) {
		return client.get("/thematic-templates/all", {
			config: { cancelToken },
		});
	}

	getCountOfAllTreeForCompany(cancelToken) {
		return client.get("/thematic-templates/count", {
			config: { cancelToken },
		});
	}

	getRoots(cancelToken) {
		return client.get("thematic-templates/rootTypes", { config: { cancelToken } });
	}

	importIntoProject({ projectId }, payload, cancelToken) {
		return client.post(`/thematic-templates/projects/${projectId}`, {
			payload,
			config: { cancelToken },
		});
	}

	patchCompanyTreeUpdate(payload, cancelToken) {
		return client.patch("/thematic-templates", { payload, config: { cancelToken } });
	}

	export(cancelToken) {
		return client.download("/thematic-templates/export", {
			config: { cancelToken },
		});
	}

	import({ file }, cancelToken) {
		return client.upload("/thematic-templates/import", file, {
			config: { cancelToken },
		});
	}
}
export default new CategoryTemplateService();
