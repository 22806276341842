import React from "react";
import { useSelector } from "react-redux";
import { Chip } from "@mui/material";
import { CustomIconButton, icon, TextMidCut } from "../../../../../common/components";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../../common/providers";
import styles from "./DocumentCenterPanelTitle.module.css";

const DocumentCenterPanelTitle = React.forwardRef(({ docType, onRename, title }, ref) => {
	const hasActionPermission = useSelector(({ documentCenter }) => documentCenter.hasActionPermission);
	return (
		<span className={styles.title}>
			<TextMidCut
				tooltip
				autoTranslate="no"
				className={styles.label__text}
				data-is-deliverable={docType === "DELIVERABLE"}
				text={title}
			/>
			{isSegFeatureEnabled(SegFlags.DOC_TYPE_DELIVERABLE) && docType === "DELIVERABLE" && (
				<Chip
					className={styles.label__chip}
					label={translate("document-center.table.actions.type.deliverable")}
					size="small"
				/>
			)}
			{hasActionPermission && (
				<CustomIconButton
					ref={ref}
					icon={icon.faPen}
					iconColor="var(--color-light-grey-2)"
					tooltip={translate("document-center.side-panel.rename")}
					onClick={onRename}
				/>
			)}
		</span>
	);
});
export default DocumentCenterPanelTitle;
