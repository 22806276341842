import React, { useState, useRef, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, TextField } from "@mui/material";
import {
	createNotification,
	CustomButton,
	icon,
	IconComponent,
	LimitedTextField,
	OptionsPanelContainer,
} from "../../../../../../../common/components";
import { ApiService, PackageManagementService } from "../../../../../../../api";
import { translate } from "../../../../../../../common/providers";
import styles from "./Info.module.css";

function StyledAccordionDetails({ children }) {
	return (
		<AccordionDetails
			classes={{
				root: styles.StyledAccordionDetails__root,
			}}
		>
			{children}
		</AccordionDetails>
	);
}
export default function Info({ onClose, onRefreshList = null, onUpdateRow = null, pck = null }) {
	const cancelTokenSourceRef = useRef();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const [newName, setName] = useState(pck?.name || "");
	const [newDescription, setDescription] = useState(pck?.description || "");
	const handleChange = (e) => {
		setName(e?.target?.value);
	};
	const handleChangeDescription = (val) => {
		setDescription(val);
	};
	const handleSubmit = () => {
		const promise =
			(pck &&
				PackageManagementService.updatePackage(
					{ packageId: pck?.id },
					{ name: newName, description: newDescription },
					cancelTokenSourceRef.current.token
				)) ||
			PackageManagementService.createPackage(
				{ name: newName, description: newDescription },
				cancelTokenSourceRef.current.token
			);
		promise
			.then((data) => {
				if (onUpdateRow && typeof onUpdateRow === "function") {
					onUpdateRow(pck?.id, "name", newName);
					onUpdateRow(pck?.id, "description", newDescription);
				}
				createNotification({
					type: "success",
					message:
						(pck && translate("dsi.project-details.name-update.success")) ||
						translate("dsi.package-management.panel.creation.success"),
				});
				if (!pck) {
					onRefreshList({ ...data, companies: 0, features: 0, projects: 0 });
				}
				onClose();
			})
			.catch((err) => console.error(err));
	};
	return (
		<OptionsPanelContainer
			buttonText={translate("common:btn.submit")}
			disabledPrimaryButton={newName.length < 2}
			noHeader={pck}
			onClick={handleSubmit}
		>
			<div className={styles.container}>
				<Accordion defaultExpanded>
					<AccordionSummary expandIcon={<IconComponent icon={icon.faChevronDown} />}>
						{translate("dsi.package-management.panel.info.name")}
					</AccordionSummary>
					<StyledAccordionDetails>
						<div className={styles.text}>
							<TextField
								fullWidth
								label={translate("dsi.package-management.panel.info.name")}
								value={newName || ""}
								variant="outlined"
								onChange={handleChange}
							/>
							{pck && (
								<CustomButton color="primary" onClick={handleSubmit}>
									{translate("common:btn.submit")}
								</CustomButton>
							)}
						</div>
					</StyledAccordionDetails>
				</Accordion>
				<Accordion defaultExpanded>
					<AccordionSummary expandIcon={<IconComponent icon={icon.faChevronDown} />}>
						{translate("dsi.package-management.panel.info.description")}
					</AccordionSummary>
					<StyledAccordionDetails>
						<div className={styles.text}>
							<LimitedTextField
								fullWidth
								multiline
								maxLength={512}
								placeholder={translate("dsi.package-management.panel.info.description")}
								rows={newDescription.length > 3 ? 4 : 1}
								size="sm"
								value={newDescription}
								onChange={handleChangeDescription}
							/>
							{pck && (
								<CustomButton color="primary" onClick={handleSubmit}>
									{translate("common:btn.submit")}
								</CustomButton>
							)}
						</div>
					</StyledAccordionDetails>
				</Accordion>
			</div>
		</OptionsPanelContainer>
	);
}
