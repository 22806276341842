import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "@mui/material";
import {
	CustomButton,
	CustomTooltip,
	SidePanelBox,
	VerticalDivider,
	ShowMoreSidePanel,
	PositionIcon,
} from "../../../../../../common/components";
import { POSITIONS_STATUSES } from "../../../../../../common/constants/positions-statuses";
import { translate, translateDate, translateElapsed } from "../../../../../../common/providers";
import {
	setSelectedSearch,
	setTemporaryDocument,
	setCurrentFocus,
	setHoveredBlock,
	setCommentData,
} from "../../../../slice/document/document-slice";
import styles from "./CommentBox.module.css";
import CommentHighlightedText from "../../../../../../common/components/comment-highlighted-text/CommentHighlightedText";
import { COMMENT_TYPES, SOURCE_TYPES } from "../../../../../../common/constants";
import smartReviewContext from "../../../../context/smart-review-context";

const debouncedFunction = debounce((func) => func(), 0);

export default function CommentBox({
	comment = {},
	commentPage,
	isOpenConversation = false,
	isValidationComments = false,
	scrollRef,
}) {
	const [hover, setHover] = useState(false);
	const [isOverflow, setIsOverflow] = useState(false);
	const [openShowMoreSidePanel, setOpenShowMoreSidePanel] = useState(false);
	const reduxdocumentId = useSelector(({ srDocument }) => srDocument.documentId);
	const commentRef = useRef(null);
	const dispatch = useDispatch();
	const defineIsOverflow = () => {
		setIsOverflow(
			commentRef.current !== null &&
				(commentRef.current.scrollHeight > commentRef.current.clientHeight ||
					commentRef.current.scrollWidth > commentRef.current.clientWidth)
		);
	};
	const locateInsideDocument = ({ page, informationId, commentId, commentScroll, y1, x1, y2, x2, documentId }) => {
		dispatch(setHoveredBlock(null));
		dispatch(setCurrentFocus("qa"));
		const payload = {
			pageStart: page,
			infoId: informationId,
			commentId,
			commentPage,
			commentScroll,
			commentOpenConversation: isOpenConversation,
			y1,
			x1,
			y2,
			x2,
			documentId,
			source: (isValidationComments && SOURCE_TYPES.POSITIONS) || SOURCE_TYPES.COMMENTS,
			fromUrl: true,
		};
		smartReviewContext.setSidePanelInfo(payload);
		dispatch(setSelectedSearch(payload));
		dispatch(
			setCommentData({ sidePanelCommentId: commentId, sidePanelCommentOpenConversation: isOpenConversation })
		);
	};
	const handleSearchChangePage = () => {
		const { commentId, informationId, page, x1, x2, y1, y2, documentId } = comment;
		const commentScroll = scrollRef.current.scrollTop;
		if (reduxdocumentId !== documentId) {
			dispatch(
				setTemporaryDocument({
					id: documentId,
					fromToc: true,
					coordinates: { page, y1 },
				})
			);
		}
		debouncedFunction(() => {
			locateInsideDocument({ page, informationId, commentId, commentScroll, y1, x1, y2, x2, documentId });
		});
	};
	const handleMouseEnter = () => {
		setHover(true);
		defineIsOverflow();
	};
	const handleMouseLeave = () => {
		setHover(false);
	};
	const handleClickShowMore = () => {
		setOpenShowMoreSidePanel(true);
	};
	const handleCloseShowMoreSidePanel = () => {
		setOpenShowMoreSidePanel(false);
	};
	return (
		<>
			<SidePanelBox
				clickable
				className={styles.container}
				hasShadow={false}
				onClick={handleSearchChangePage}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<div className={styles.container__line} data-is-closed-conversation={!isOpenConversation}>
					<CustomTooltip showWhenOverflow title={comment.username}>
						<div className={styles.name}>{comment.username}</div>
					</CustomTooltip>
					{(isValidationComments && (
						<div className={styles.line__rightItems}>
							<div className={styles.documentTime__container}>
								<CustomTooltip showWhenOverflow title={comment.documentName}>
									<div className={styles.documentName}>
										{translate("common:reading-list.documentList.header.document")}&nbsp;
										{comment.documentName}
									</div>
								</CustomTooltip>
								<CustomTooltip placement="top" title={translateDate(comment.date).replaceAll(".", "/")}>
									<span>
										{translateElapsed({
											value: comment.elapsed.value,
											unitKey: comment.elapsed.unit,
										})}
									</span>
								</CustomTooltip>
							</div>
							<VerticalDivider className={styles.divider} />
							<PositionIcon positionsStatus={POSITIONS_STATUSES[comment.status]} />
						</div>
					)) || (
						<div className={styles.line__rightItems}>
							<CustomTooltip showWhenOverflow title={comment.documentName}>
								<div className={styles.documentName}>
									{translate("common:reading-list.documentList.header.document")}&nbsp;
									{comment.documentName}
								</div>
							</CustomTooltip>
							<VerticalDivider className={styles.divider} />
							<CustomTooltip placement="top" title={translateDate(comment.date).replaceAll(".", "/")}>
								<span>
									{translateElapsed({
										value: comment.elapsed.value,
										unitKey: comment.elapsed.unit,
									})}
								</span>
							</CustomTooltip>
						</div>
					)}
				</div>
				<div
					className={styles.container__line}
					data-has-replies={comment?.childrenCount > 0}
					data-is-closed-conversation={!isOpenConversation}
				>
					<div ref={commentRef} className={styles.comment}>
						{comment.comment.split("\n").map((com, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<CommentHighlightedText key={com + index} textClassName={styles.commentLine} value={com} />
						))}
					</div>
				</div>
				{isOverflow && hover && (
					<div className={styles.showMoreContainer}>
						...
						<CustomButton className={styles.showMoreButton} size="sm" onClick={handleClickShowMore}>
							{translate("common:btn.show-more")}
						</CustomButton>
					</div>
				)}
				{comment?.childrenCount > 0 && (
					<div className={styles.childrenCount}>
						{(comment?.childrenCount === 1 && translate("common:comment.replies-count.singular")) ||
							translate("common:comment.replies-count.multiple", { count: comment?.childrenCount })}
					</div>
				)}
			</SidePanelBox>
			<ShowMoreSidePanel
				comment={comment.comment}
				open={openShowMoreSidePanel}
				title={comment.type === COMMENT_TYPES.VALIDATION ? comment.companyName : comment.username}
				onClose={handleCloseShowMoreSidePanel}
			/>
		</>
	);
}
