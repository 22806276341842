import { Select } from "@mui/material";
import React from "react";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";

export default function PartnersTable({ companies = [], roles = [], onChangeCompanyRole = null }) {
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					<CustomTableCell>{translate("dsi.project-details.th.code")}</CustomTableCell>
					<CustomTableCell>{translate("dsi.project-details.th.name")}</CustomTableCell>
					<CustomTableCell>{translate("dsi.project-details.th.role")}</CustomTableCell>
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody>
				{Array.isArray(companies) &&
					companies.map((row) => (
						<CustomTableRow key={row.id}>
							<CustomTableCell autoTranslate="no">{row.shortname}</CustomTableCell>
							<CustomTableCell autoTranslate="no">{row.name}</CustomTableCell>
							<CustomTableCell>
								<Select
									native
									value={row.role}
									onChange={(e) => onChangeCompanyRole({ role: e.target.value, company: row })}
								>
									{roles.map((r) => (
										<option key={r.name} value={r.name}>
											{r.displayName}
										</option>
									))}
								</Select>
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
