import { TablePagination } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import {
	CustomButton,
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	CustomTooltip,
	icon,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import { sortArray } from "../../../../../common/utils";
import styles from "./NormListTable.module.css";

export default function NormListTable({
	contents,
	isLoading,
	multipleOnly = false,
	page,
	rowsPerPageLimit,
	totalElements,
	onPageChange,
	onRowsPerPageChange,
	onCreateCustomNorm,
	onSelectRow,
	onShowDetails,
	CustomActionButtonBody = null,
}) {
	const headers = useMemo(() => {
		if (CustomActionButtonBody) {
			const tableHeaders = [
				{ name: translate("norms-center.add-norm.table.ref"), sortKey: "name" },
				{ name: translate("norms-center.add-norm.table.date"), sortKey: "releaseYear" },
				{ name: translate("norms-center.add-norm.table.name") },
				{ name: translate("norms-center.matched-norms.show-details") },
				{ name: translate("norms-center.matched-norms.all-detections") },
			];
			if (multipleOnly) {
				return tableHeaders;
			}
			tableHeaders.push({ name: translate("norms-center.matched-norms.this-detection") });
			return tableHeaders;
		}
		return [
			{ name: translate("norms-center.add-norm.table.ref"), sortKey: "name" },
			{ name: translate("norms-center.add-norm.table.date"), sortKey: "releaseYear" },
			{ name: translate("norms-center.add-norm.table.name") },
			{ name: translate("norms-center.add-norm.table.show-details") },
			{ name: "", className: styles.normList__headerCellBtn },
		];
	}, [CustomActionButtonBody, multipleOnly]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");
	const [sortedTableData, setSortedTableData] = useState(contents);

	useEffect(() => {
		if (Array.isArray(contents) && contents.length > 0) {
			setSortedTableData(sortArray(contents, order, orderBy));
		} else {
			setSortedTableData([]);
		}
	}, [contents, order, orderBy]);

	const handleSort = (property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};
	const handleShowDetails = (e, normId, normName) => {
		e.stopPropagation();
		onShowDetails(normId, normName);
	};
	return (
		<>
			<CustomTable stickyHeader>
				<CustomTableHead>
					<CustomTableRow>
						{headers.map((header) => (
							<CustomTableCell
								key={header.name}
								active={orderBy === header.sortKey}
								className={`${styles.normList__header} ${header.className}`}
								direction={order}
								sortKey={header.sortKey}
								onSort={() => {
									handleSort(header.sortKey);
								}}
							>
								{header.name}
							</CustomTableCell>
						))}
					</CustomTableRow>
				</CustomTableHead>
				<CustomTableBody
					emptyState={
						<div className={styles.normList__emptyState}>
							<span>{translate("norms-center.add-norm.table.empty-state")}</span>
							<CustomButton color="primary" variant="contained" onClick={onCreateCustomNorm}>
								{translate("norms-center.add-norm.create-norm")}
							</CustomButton>
						</div>
					}
					isLoading={isLoading}
				>
					{Array.isArray(sortedTableData) &&
						sortedTableData.map((row) => (
							<CustomTableRow
								key={row.id}
								hover
								onClick={(!CustomActionButtonBody && (() => onSelectRow(row))) || null}
							>
								{({ isHovering }) => (
									<>
										<CustomTableCell>{row.name}</CustomTableCell>
										<CustomTableCell>{row.date?.replace("/", ".") || "-"}</CustomTableCell>
										<CustomTableCell>
											<CustomTooltip arrow showWhenOverflow title={row.title || ""}>
												<span className={styles.norms__name}>{row.title}</span>
											</CustomTooltip>
										</CustomTableCell>
										{(CustomActionButtonBody && (
											<CustomActionButtonBody
												multipleOnly={multipleOnly}
												row={row}
												onSelectRow={onSelectRow}
												onShowDetails={handleShowDetails}
											/>
										)) || (
											<>
												<CustomTableCell>
													<CustomIconButton
														icon={icon.faInfo}
														variant="contained"
														onClick={(e) => handleShowDetails(e, row.id, row.name)}
													/>
												</CustomTableCell>
												<CustomTableCell align="right">
													{isHovering && <CustomIconButton icon={icon.faPlus} size="sm" />}
												</CustomTableCell>
											</>
										)}
									</>
								)}
							</CustomTableRow>
						))}
				</CustomTableBody>
			</CustomTable>
			{Array.isArray(contents) && contents.length > 0 && (
				<TablePagination
					component="div"
					count={totalElements}
					page={page}
					rowsPerPage={rowsPerPageLimit}
					rowsPerPageOptions={[10, 25, 100]}
					onPageChange={onPageChange}
					onRowsPerPageChange={onRowsPerPageChange}
				/>
			)}
		</>
	);
}
