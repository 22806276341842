import React from "react";
import { Icon } from "@mui/material";
import { IconComponent, icon, CustomDialog } from "../../../../../common/components";
import styles from "./ErrorLogDialog.module.css";
import { translate } from "../../../../../common/providers";

const ErrorLogDialog = ({ open = false, onClose, filename = "", errors = [] }) => (
	<CustomDialog
		disableBackdropClick
		displayCloseCross
		closeLabel={translate("common:btn.ok")}
		open={open}
		title={translate("qa-journal.qa-import.error-log-dialog.title", {
			filename: filename ? `(${filename})` : "",
		})}
		onClose={onClose}
	>
		<div className={styles.dialog__content}>
			{errors.map((err) => (
				<span key={err}>
					<Icon aria-label="error">
						<IconComponent icon={icon.faExclamationCircle} />
					</Icon>
					&nbsp;
					{err}
				</span>
			))}
		</div>
	</CustomDialog>
);

export default ErrorLogDialog;
