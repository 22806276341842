import React from "react";
import axios from "axios";
import { TextField, Checkbox, Divider, debounce } from "@mui/material";
import {
	createNotification,
	CustomButton,
	CustomIconButton,
	CustomTooltip,
	I18nTranslate,
	Selector,
	icon,
	CircularLoader,
	ViewBanner,
} from "../../common/components";
import {
	ProjectTeamService,
	BackOfficeService,
	CanvasService,
	ExportService,
	RoleService,
	ApiService,
} from "../../api";
import { downloadFile, isDeepEqualComplex } from "../../common/utils";
import styles from "./Canvas.module.css";
import Details from "./details/Details";
import AddMultiUserRolesForm from "./details/components/multi-select-doc/AddMultiUserRolesForm";
import UserList from "./components/UserList";
import { translate, hasPermission, Permissions } from "../../common/providers";
import Document from "./components/Document";

const initState = {
	documents: {},
	filteredDocuments: {},
	columns: {},
	columnOrder: [],
	users: [],
	roles: [],
	notArchivedDocuments: [],
	notArchivedFilteredDocuments: [],
	selectedUser: "",
	selectedDocument: "",
	dataSelectedUser: [],
	errorNetwork: 200,
	filter: "",
	debouncedFilter: "",
	toDelete: [],
	openMultiUserRolesForm: false,
	selectedDocuments: [],
	selectAllDocuments: false,
	SelectAllDocsToDelete: false,
	hideArchives: true,
	updateCounter: 0,
	selectedDocDetails: null,
	isDocsLoading: false,
	isRolesLoading: false,
	selectedUserDetails: null,
	dragToIndex: 0,
	dragCurrentIndex: 0,
	dragCurrentColumn: "",
};

class CanvasContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = initState;
		this.searchUser = this.searchUser.bind(this);
		this.searchDocument = this.searchDocument.bind(this);

		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	componentDidMount() {
		this.initData();
	}

	loadDocuments = (filter) => {
		this.setState({ isDocsLoading: true });
		const { projectId, selectedCompany, getDocumentMethod } = this.props;
		const documentPayload = selectedCompany ? { projectId, companyId: selectedCompany.id } : { projectId };
		const documentService = selectedCompany ? BackOfficeService : CanvasService;
		documentService[getDocumentMethod](documentPayload, { filter }, this.cancelTokenSource.token)
			.then((data) => {
				this.handleMatrix(data);
				this.setState({ isDocsLoading: false });
			})
			.catch((err) => {
				console.error(err);
				this.setState({ isDocsLoading: false });
			});
	};

	initData = () => {
		this.setState(initState);
		const { projectId, selectedCompany, getUserMethod } = this.props;
		const documentPayload = selectedCompany ? { projectId, companyId: selectedCompany.id } : { projectId };
		const userService = selectedCompany ? BackOfficeService : ProjectTeamService;
		axios
			.all([
				RoleService.listUserOnDocument(this.cancelTokenSource.token),
				userService[getUserMethod](documentPayload, {}, this.cancelTokenSource.token),
			])
			.then(
				axios.spread((...data) => {
					this.handleRoleTypes(data[0]);
					this.handleUsers(data[1].contents);
				})
			)
			.catch((errors) => {
				if (Array.isArray(errors)) {
					errors.forEach((e) => this.handleGetError(e));
				}
			});
		this.loadDocuments();
	};

	componentDidUpdate(prevProps, prevState) {
		const { debouncedFilter, documents, filteredDocuments } = this.state;
		const { selectedProject } = this.props;
		if (debouncedFilter !== prevState.debouncedFilter) {
			Object.keys(documents).forEach((key) => {
				documents[key].checked = false;
				return documents[key];
			});
			this.setState({ documents, selectAllDocuments: false });
		}
		if (documents && !isDeepEqualComplex(prevState.documents, documents)) {
			const notArchivedDocuments = Object.keys(documents)
				.map((key) => documents[key])
				.filter((document) => document.documentData.documentStatus !== "ARCHIVED");
			this.setState({ notArchivedDocuments });
		}
		if (debouncedFilter && !isDeepEqualComplex(prevState.filteredDocuments, filteredDocuments)) {
			const notArchivedFilteredDocuments = Object.keys(filteredDocuments)
				.map((key) => filteredDocuments[key])
				.filter((document) => document.documentData.documentStatus !== "ARCHIVED");
			this.setState({ notArchivedFilteredDocuments });
		}
		if (prevProps.selectedProject !== selectedProject && prevProps.selectedProject) {
			this.initData();
		}

		if (prevState.debouncedFilter !== debouncedFilter) {
			this.loadDocuments(debouncedFilter);
		}
	}

	handleGetError = (error) => {
		this.setState({ errorNetwork: error });
	};

	handleRoleTypes = (data) => {
		this.setState({ roles: data });
	};

	handleUsers = (data) => {
		this.setState({ users: data });
	};

	handleMatrix = (data) => {
		const { users } = this.state;
		const { location } = this.props;
		const processedData = this.processPrecedenceTable(data);
		this.setState((prev) => ({
			documents: processedData.documents,
			filteredDocuments: processedData.documents,
			columns: processedData.columns,
			columnOrder: processedData.columnOrder,
			selectedDocument: "",
			selectedUser: prev.selectedUser || (Array.isArray(users) && users.length > 0 ? users[0].email : ""),
		}));
		const docId = (location && parseInt(location.hash.replace("#", ""), 10)) || 0;
		if (docId) {
			const document = Object.values(processedData.documents).find((x) => x.documentData.id === docId);
			if (document) {
				this.setState({ selectedDocument: document.id });
			}
		}
	};

	processPrecedenceTable = (precedencesData) => {
		const columnOrder = [];
		const columns = {};
		const documents = {};
		precedencesData.forEach((pData) => {
			columnOrder.push(pData.precedence.precedenceId);
			columns[pData.precedence.precedenceId] = {
				id: pData.precedence.precedenceId,
				title: pData.precedence.precedence,
			};
			const docIds = [];
			pData.documentUsersRoles.forEach((role) => {
				docIds.push(role.id);
				documents[role.id] = {
					id: role.id,
					documentData: role,
					checked: false,
				};
			});
			columns[pData.precedence.precedenceId] = Object.assign(columns[pData.precedence.precedenceId], {
				docIds,
			});
		});
		return { columnOrder, columns, documents };
	};

	handleOpenMultiUserRolesForm = () => {
		this.setState({
			openMultiUserRolesForm: true,
		});
	};

	handleCloseMultiUserRolesForm = (submit) => {
		const { filteredDocuments, documents } = this.state;
		const newFilteredDocuments = {};
		const newDocuments = {};
		for (const doc of Object.keys(filteredDocuments)) {
			Object.assign(newFilteredDocuments, { [doc]: { ...filteredDocuments[doc], checked: false } });
			Object.assign(newDocuments, { [doc]: { ...documents[doc], checked: false } });
		}
		this.setState({
			openMultiUserRolesForm: false,
			selectedDocuments: [],
			filteredDocuments: newFilteredDocuments,
			documents: newDocuments,
			selectAllDocuments: false,
		});
		if (submit) {
			this.handleUpdateUsersRoles();
		}
	};

	handleCheckedDocument = (docName) => {
		this.setState((prevState) => ({
			filteredDocuments: prevState.filteredDocuments
				? {
						...prevState.filteredDocuments,
						[docName]: {
							...prevState.filteredDocuments[docName],
							checked: !prevState.filteredDocuments[docName].checked,
						},
				  }
				: null,
			documents: {
				...prevState.documents,
				[docName]: { ...prevState.documents[docName], checked: !prevState.documents[docName].checked },
			},
		}));
	};

	handleSelectedDocuments = (isChecked, id) => {
		if (isChecked) {
			this.setState((prevState) => ({
				selectedDocuments: [id, ...prevState.selectedDocuments.filter((value) => value !== id)],
			}));
		} else {
			this.setState((prevState) => ({
				selectedDocuments: prevState.selectedDocuments.filter((value) => value !== id),
			}));
		}
	};

	handleSelectAllDocuments = () => {
		const {
			notArchivedFilteredDocuments,
			documents,
			filteredDocuments,
			debouncedFilter,
			notArchivedDocuments,
			selectAllDocuments,
		} = this.state;
		const selectedDocumentsTemp = [];
		if (!debouncedFilter && Array.isArray(notArchivedDocuments) && notArchivedDocuments.length > 0) {
			notArchivedDocuments.forEach((document) => {
				documents[document.id].checked = !selectAllDocuments;
				if (!selectAllDocuments) {
					selectedDocumentsTemp.push(document.documentData.id);
				}
			});
		}
		if (debouncedFilter && Array.isArray(notArchivedFilteredDocuments) && notArchivedFilteredDocuments.length > 0) {
			notArchivedFilteredDocuments.forEach((document) => {
				filteredDocuments[document.id].checked = !selectAllDocuments;
				if (!selectAllDocuments) {
					selectedDocumentsTemp.push(document.documentData.id);
				}
			});
		}
		this.setState((prev) => ({
			selectAllDocuments: !prev.selectAllDocuments,
			documents,
			filteredDocuments,
			selectedDocuments: selectedDocumentsTemp,
		}));
	};

	handlePrecedenceByUser = (email, id) => {
		const { projectId, selectedCompany } = this.props;
		this.setState({ selectedUser: email, isRolesLoading: true });
		const request = selectedCompany
			? BackOfficeService.getCompanyProjectUserProgress(
					{ userId: id, projectId, companyId: selectedCompany.id },
					this.cancelTokenSource.token
			  )
			: CanvasService.getRoleOnDocument({ userId: id, projectId }, this.cancelTokenSource.token);
		request
			.then((data) => {
				this.setState({
					selectedUserDetails: data.map((d) => ({
						...d,
						documentStatuses: d.documentStatuses.map((doc) => ({ ...doc, checked: false })),
					})),
					selectedDocument: "",
					dataSelectedUser: data.map((x) => ({ ...x, checked: false })),
					toDelete: [],
					SelectAllDocsToDelete: false,
					isRolesLoading: false,
				});
			})
			.catch((err) => {
				console.error(err);
				this.setState({ isRolesLoading: false });
			});
	};

	handleChecked = (documentId, role) => {
		this.setState((prevState) => ({
			dataSelectedUser: prevState.dataSelectedUser.map((value) =>
				value.documentId === documentId && value.userRole === role
					? { ...value, checked: !value.checked }
					: value
			),
			selectedUserDetails: prevState.selectedUserDetails.map((d) => ({
				...d,
				documentStatuses: d.documentStatuses.map((doc) =>
					doc.documentId === documentId && doc.userRole === role ? { ...doc, checked: !doc.checked } : doc
				),
			})),
		}));
	};

	handleSelectedAllDocs = () => {
		const { SelectAllDocsToDelete, selectedUserDetails } = this.state;
		if (SelectAllDocsToDelete) {
			this.setState((prevState) => ({
				dataSelectedUser: prevState.dataSelectedUser.map((value) => ({
					...value,
					checked: false,
				})),
				selectedUserDetails: prevState.selectedUserDetails.map((d) => ({
					...d,
					documentStatuses: d.documentStatuses.map((doc) => ({ ...doc, checked: false })),
				})),
				toDelete: [],
				SelectAllDocsToDelete: false,
			}));
		} else {
			const newToDelete = [];
			selectedUserDetails.forEach((detail) => {
				detail.documentStatuses
					.filter((doc) => doc.documentStatus !== "ARCHIVED")
					.forEach((doc) => {
						newToDelete.push({ documentId: doc.documentId, docName: doc.documentName, role: doc.userRole });
					});
			});
			this.setState((prevState) => ({
				dataSelectedUser: prevState.dataSelectedUser.map((value) => ({
					...value,
					checked: true,
				})),
				selectedUserDetails: prevState.selectedUserDetails.map((d) => ({
					...d,
					documentStatuses: d.documentStatuses.map((doc) => ({
						...doc,
						checked: doc.documentStatus !== "ARCHIVED",
					})),
				})),
				toDelete: newToDelete,
				SelectAllDocsToDelete: true,
			}));
		}
	};

	handleUpdateTabDoctoDelete = (isChecked, documentId, docName, role) => {
		if (isChecked) {
			this.setState((prevState) => ({
				toDelete: [
					{ documentId, docName, role },
					...prevState.toDelete.filter((value) => value.role !== role || value.documentId !== documentId),
				],
			}));
		} else {
			this.setState((prevState) => ({
				toDelete: prevState.toDelete.filter((value) => value.role !== role || value.documentId !== documentId),
			}));
		}
	};

	handleReset = () => {
		this.setState((prevState) => ({
			selectedUserDetails: prevState.selectedUserDetails.map((d) => ({
				...d,
				documentStatuses: d.documentStatuses.map((doc) => ({ ...doc, checked: false })),
			})),
			dataSelectedUser: prevState.dataSelectedUser.map((value) => ({ ...value, checked: false })),
			toDelete: [],
			SelectAllDocsToDelete: false,
		}));
	};

	handleUpdateUsersRoles = () => {
		const { debouncedFilter, selectedUser, users, documents, selectedDocument } = this.state;
		this.loadDocuments(debouncedFilter);
		if (!selectedUser && selectedDocument) {
			this.handleSelectDocument(documents[selectedDocument], documents[selectedDocument].documentData.precedence);
		} else if (!selectedDocument && selectedUser) {
			this.handlePrecedenceByUser(selectedUser, users.find((u) => u.email === selectedUser).id);
		}
	};

	handleSelectDocument = (doc) => {
		const { selectedCompany } = this.props;
		this.setState({
			isRolesLoading: true,
		});
		const request = selectedCompany
			? BackOfficeService.getCompanyProjectDocumentProgress(
					{ documentId: doc.documentData.id, companyId: selectedCompany.id },
					this.cancelTokenSource.token
			  )
			: CanvasService.getProgressesByDoc({ documentId: doc.documentData.id }, this.cancelTokenSource.token);
		request
			.then((data) => {
				this.setState({
					selectedDocDetails: data,
					selectedDocument: doc.id,
					selectedUser: "",
					isRolesLoading: false,
				});
			})
			.catch((err) => {
				console.error(err);
				this.setState({
					isRolesLoading: false,
				});
			});
	};

	searchUser = () => {
		const { selectedUser, users } = this.state;
		return (selectedUser !== "" && users.find((user) => user.email === selectedUser)) || null;
	};

	searchDocument = () => {
		const { selectedDocument, documents } = this.state;
		return (selectedDocument !== "" && documents[selectedDocument]) || null;
	};

	debounceSearch = debounce((searchValue) => this.setState({ debouncedFilter: searchValue }), 1000);

	handleChangeFilter = (e) => {
		this.setState({ filter: e.target.value });
		this.debounceSearch(e.target.value);
	};

	handleSelectUser = (email) => {
		this.setState({ selectedUser: email });
	};

	handleHideArchives = () => {
		this.setState((prev) => ({ hideArchives: !prev.hideArchives }));
	};

	handleExport = () => {
		const { projectId } = this.props;
		createNotification({ message: translate("canvas.notif-info-exporting") });
		ExportService.exportRequirementsProgress({ projectId }, this.cancelTokenSource.token)
			.then(({ data, filename }) => downloadFile({ data, filename, filetype: "xlsx" }))
			.catch((err) => {
				console.error(err);
			});
	};

	render() {
		const {
			errorNetwork,
			documents,
			filter,
			debouncedFilter,
			hideArchives,
			selectedDocument,
			columnOrder,
			columns,
			filteredDocuments,
			users,
			selectedUser,
			notArchivedDocuments,
			notArchivedFilteredDocuments,
			selectedDocuments,
			SelectAllDocsToDelete,
			dataSelectedUser,
			openMultiUserRolesForm,
			roles,
			toDelete,
			selectedDocDetails,
			isDocsLoading,
			isRolesLoading,
			selectedUserDetails,
		} = this.state;
		const {
			companyName,
			projectId,
			onSelectCompany,
			companies,
			selectedCompany,
			selectedProject,
			onSelectProject,
		} = this.props;
		return (
			<div className={styles["main-container"]}>
				<ViewBanner
					titles={[
						{ title: translate("navigation:project.project-configuration"), key: "projectAdmin" },
						{ title: translate("navigation:home.rights-on-documents") },
					]}
				/>
				{errorNetwork ? (
					<div className={styles["wrapper-canvas"]}>
						<div className={styles.precedence}>
							<div className={styles.banner} translate="no">
								{!onSelectCompany && translate("canvas.banner", { companyName })}
								{onSelectCompany && companies && (
									<>
										<Selector
											fullWidth
											autoTranslate="no"
											field="companyName"
											items={companies}
											label={translate("dsi.canvas.selector.document.label")}
											size="medium"
											value={selectedCompany || ""}
											onChange={(e) => onSelectCompany(e.target.value)}
										/>
										<Selector
											fullWidth
											autoTranslate="no"
											field="name"
											items={selectedCompany?.projects || []}
											label={translate("dsi.canvas.selector.project.label")}
											size="medium"
											value={selectedProject || ""}
											onChange={(e) => onSelectProject(e.target.value)}
										/>
									</>
								)}
							</div>
							<div className={styles["filter-selector"]}>
								<div className={styles.filter}>
									<TextField
										fullWidth
										className={styles.filter__input}
										id="standard-name-input"
										inputProps={{
											className: styles["input-props"],
										}}
										label={translate("canvas.text-field.search-text")}
										margin="normal"
										value={filter}
										variant="outlined"
										onChange={this.handleChangeFilter}
									/>
									<CustomIconButton
										className={styles.filter__btn}
										icon={(hideArchives && icon.faEye) || icon.faEyeSlash}
										tooltip={
											(hideArchives && translate("canvas.archives.show")) ||
											translate("canvas.archives.hide")
										}
										onClick={this.handleHideArchives}
									/>
									<CustomIconButton
										className={styles.filter__btn}
										icon={icon.faUpload}
										tooltip={translate("canvas.export")}
										onClick={this.handleExport}
									/>
								</div>
								<div className={styles.selector}>
									<span className={styles["selector__btn-select-all"]}>
										{!hasPermission(Permissions.PROJECT_MEMBER) && (
											<CustomTooltip
												placement="top"
												title={translate(
													"canvas.user-details.tooltip.select-all-the-documents"
												)}
											>
												<Checkbox
													checked={selectedDocuments.length > 0}
													color="primary"
													indeterminate={
														selectedDocuments.length > 0 &&
														((debouncedFilter &&
															notArchivedFilteredDocuments.length >
																selectedDocuments.length) ||
															notArchivedDocuments.length > selectedDocuments.length)
													}
													onClick={this.handleSelectAllDocuments}
												/>
											</CustomTooltip>
										)}
									</span>
									<span className={styles.resultsCount}>
										{(selectedDocuments.length === 0 && (
											<I18nTranslate
												param={{
													totalResults: debouncedFilter
														? notArchivedFilteredDocuments.length
														: notArchivedDocuments.length,
												}}
												translationKey={
													(((debouncedFilter && notArchivedFilteredDocuments.length) ||
														notArchivedDocuments.length) > 1 &&
														"canvas.results.count-total") ||
													"canvas.results.count-single-total"
												}
											/>
										)) || (
											<I18nTranslate
												param={{
													totalResults: debouncedFilter
														? notArchivedFilteredDocuments.length
														: notArchivedDocuments.length,
													totalMatches: selectedDocuments.length,
												}}
												translationKey={
													(selectedDocuments.length > 1 && "canvas.results.count-match") ||
													"canvas.results.count-single-match"
												}
											/>
										)}
									</span>
									{!hasPermission(Permissions.PROJECT_MEMBER) && (
										<>
											<Divider
												className={styles.selector__divider}
												orientation="vertical"
												variant="middle"
											/>
											<span className={styles["selector__divider-text"]}>
												{translate("canvas.helper.actions")}
											</span>
											<div className={styles["selector__btn-add-role"]}>
												<CustomTooltip
													placement="top"
													title={translate("canvas.tooltip.add-roles")}
												>
													<span>
														<CustomButton
															className={styles.addRoleButton}
															color="primary"
															disabled={selectedDocuments.length < 1}
															variant="contained"
															onClick={this.handleOpenMultiUserRolesForm}
														>
															{translate("canvas.btn.add-role")}
														</CustomButton>
													</span>
												</CustomTooltip>
											</div>
										</>
									)}
									<div className={styles.roleContainer}>
										<span className={styles.role}>{translate("canvas.helper.editor")}</span>
										<span className={styles.role}>{translate("canvas.helper.validator")}</span>
										<span className={styles.role}>{translate("canvas.helper.reviewer")}</span>
									</div>
								</div>
							</div>
							{(isDocsLoading && (
								<div className={styles.loaderContainer}>
									<CircularLoader />
								</div>
							)) || (
								<>
									{columnOrder.map((columnId) => {
										const column = columns[columnId];
										const docs = column.docIds.map((docId) => {
											if (filteredDocuments && debouncedFilter) {
												return filteredDocuments[docId];
											}
											return documents[docId];
										});
										return (
											<div key={column.id}>
												<b>{column.title}</b>
												{docs
													.filter(
														(x) =>
															(hideArchives &&
																x?.documentData?.documentStatus !== "ARCHIVED") ||
															!hideArchives
													)
													.map((doc) => (
														<Document
															key={doc.id}
															checked={selectedDocuments.includes(doc.id)}
															column={column}
															document={doc}
															projectId={projectId}
															roles={roles}
															selectedDocument={selectedDocument}
															onCheckDocument={this.handleCheckedDocument}
															onSelectDocument={this.handleSelectDocument}
															onSelectDocuments={this.handleSelectedDocuments}
														/>
													))}
											</div>
										);
									})}
								</>
							)}
						</div>
						<div className={styles.users}>
							<div className={styles.container}>
								<div className={styles["user-title"]}>{translate("canvas.title-user")}</div>
								<UserList
									projectId={projectId}
									selectedCompany={selectedCompany}
									selectedDocument={selectedDocument}
									selectedUser={selectedUser}
									users={users}
									onChangePrecedenceByUser={this.handlePrecedenceByUser}
								/>
							</div>
						</div>
						<div className={styles.details}>
							{(isRolesLoading && (
								<div className={styles.loaderContainer}>
									<CircularLoader />
								</div>
							)) || (
								<Details
									dataSelectedDocument={this.searchDocument()}
									dataSelectedUser={this.searchUser()}
									hideArchives={hideArchives}
									projectId={projectId}
									roles={roles}
									rolesSelectedUser={dataSelectedUser}
									SelectAllDocsToDelete={SelectAllDocsToDelete}
									selectedCompany={selectedCompany}
									selectedDocDetails={selectedDocDetails}
									selectedDocument={selectedDocument}
									selectedUser={selectedUser}
									selectedUserDetails={selectedUserDetails}
									toDelete={toDelete}
									users={users}
									onCheck={this.handleChecked}
									onReset={this.handleReset}
									onSelectedAllDocs={this.handleSelectedAllDocs}
									onSelectUser={this.handleSelectUser}
									onUpdateTabDocToDelete={this.handleUpdateTabDoctoDelete}
									onUpdateUsersRoles={this.handleUpdateUsersRoles}
								/>
							)}
						</div>
						<AddMultiUserRolesForm
							open={openMultiUserRolesForm}
							projectId={projectId}
							roles={roles}
							selectedCompany={selectedCompany}
							selectedDocuments={selectedDocuments}
							users={users}
							onCloseMultiUserRolesForm={this.handleCloseMultiUserRolesForm}
						/>
					</div>
				) : (
					<div className={styles["error-network"]}>
						<div className={styles["error-text"]}>
							<strong>
								{translate("canvas.title-error")} {errorNetwork}
							</strong>
							<br />
							{errorNetwork === 500 && <em>{translate("canvas.error-network")}</em>}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default CanvasContainer;
