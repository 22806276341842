import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Divider, Drawer } from "@mui/material";
import { IconComponent, icon } from "..";
import { hasPermission } from "../../providers";
import CustomDrawer from "./CustomDrawer";
import styles from "./DrawerInterface.module.css";
import { CustomButton, CustomIconButton } from "../buttons";

const DrawerInterface = ({
	currentMenu,
	displayContent,
	displayOptions,
	displaySubMenu,
	expandMenu,
	getLatestExpansion,
	menus,
	modeMax,
	onChangeModeMax,
	rightMenu,
	setMenu,
}) => {
	const [expandMenuState, setExpandMenuState] = useState(false);

	useEffect(() => {
		setExpandMenuState(expandMenu);
	}, [expandMenu]);

	const handleClick = (index) => {
		if (getLatestExpansion) {
			getLatestExpansion(true);
		}
		setMenu(index);
		if (typeof onChangeModeMax === "function") {
			onChangeModeMax(true, index);
		}
	};

	const displayMenus = () => (
		<>
			{menus.map((menu) => {
				if (menu && ((menu.role && !hasPermission(menu.role)) || menu.disabled)) {
					return null;
				}
				return (
					<div key={menu.index} className={styles.menuItem}>
						{(typeof menu.icon === "string" && (
							<CustomButton
								disableRipple
								className={styles.letterBtn}
								onClick={() => handleClick(menu.index)}
							>
								<IconComponent
									className={
										currentMenu === menu.index
											? styles["letterBtn__letterIcon--selected"]
											: styles["letterBtn__letterIcon--unselected"]
									}
								>
									{menu.icon}
								</IconComponent>
							</CustomButton>
						)) || (
							<CustomIconButton
								badgeClassName={styles.button__badge}
								badgeContent={menu.counter}
								color={currentMenu === menu.index ? "primary" : "secondary"}
								icon={menu.icon}
								tooltip={menu.name}
								onClick={() => handleClick(menu.index)}
							/>
						)}
					</div>
				);
			})}
		</>
	);

	const handleClickCloseButton = () => {
		setExpandMenuState(true);

		if (typeof onChangeModeMax === "function") {
			onChangeModeMax(false);
		}
		if (getLatestExpansion) {
			getLatestExpansion(!expandMenuState);
		}
	};
	return (
		<div className={styles.mainContainer}>
			{rightMenu && <div className={styles.contentContainer}>{displayContent()}</div>}
			{!modeMax ? (
				<CustomDrawer expandMenu={expandMenu}>
					<div className={styles.drawer__fullSize}>
						<div>{displayMenus()}</div>
					</div>
				</CustomDrawer>
			) : (
				<Drawer
					BackdropProps={{ style: { position: "absolute" } }}
					classes={{
						paper: classNames({
							[styles.maxDrawerOpen]: modeMax,
						}),
					}}
					className={classNames(styles.maxDrawer, {
						[styles.maxDrawerOpen]: modeMax,
					})}
					ModalProps={{
						style: { position: "absolute" },
					}}
					open={modeMax}
					PaperProps={{ style: { position: "absolute" } }}
					variant="permanent"
				>
					<div className={styles.drawer__fullSize}>
						<div className={styles.iconContainer}>{displayMenus()}</div>
						<div className={styles.subMenuSection}>
							<div className={styles.drawer__menu}>
								<CustomIconButton
									className={styles.closeButton}
									icon={icon.faTimes}
									iconClassName={styles.closeButton__icon}
									onClick={handleClickCloseButton}
								/>
								{!!displayOptions && displayOptions()}
							</div>
							<Divider />
							{!!displaySubMenu && displaySubMenu()}
						</div>
					</div>
				</Drawer>
			)}
			{!rightMenu && <div className={styles.contentContainer}>{displayContent()}</div>}
		</div>
	);
};

export default DrawerInterface;
