import { Chip } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	CustomIconButton,
	CustomTooltip,
	icon,
	SidePanelBox,
	VerticalDivider,
} from "../../../../../../common/components";
import {
	translate,
	translateDate,
	translateElapsed,
	translateEnumQuestionStatus,
} from "../../../../../../common/providers";
import { debounce } from "../../../../../../common/utils";
import { setSelectedSearch, setTemporaryDocument } from "../../../../slice/document/document-slice";
import styles from "./QuestionBox.module.css";
import { setQuestionsSelectedTab } from "../../../../../../common/slice";

const debouncedSearch = debounce(({ handleSetSelectedSearch, infoId, pageStart, y1, documentId }) => {
	handleSetSelectedSearch({
		pageStart,
		infoId,
		y1,
		documentId,
	});
}, 100);
export default function QuestionBox({ noAction = true, onClickDetails, question = {} }) {
	const docId = useSelector(({ srDocument }) => srDocument.documentId);
	const questionsSelectedTab = useSelector(({ context }) => context.questionsSelectedTab);
	const [referenceIndex, setReferenceIndex] = useState(questionsSelectedTab.index);
	const commentRef = useRef(null);
	const dispatch = useDispatch();

	const handleSetSelectedSearch = (data) => {
		dispatch(setSelectedSearch({ ...data }));
	};
	let preventSearchChangePage = false;
	const handleSearch = (ref) => {
		const questRef = question.references?.[ref];
		if (questRef) {
			const { informationId, page, y1, documentId } = questRef;
			if (documentId !== docId) {
				dispatch(setQuestionsSelectedTab({ tab: 1, index: ref }));
				dispatch(
					setTemporaryDocument({
						id: documentId,
						fromToc: true,
						coordinates: { page, y1 },
					})
				);
			}
			debouncedSearch({
				handleSetSelectedSearch,
				pageStart: page,
				infoId: informationId,
				y1,
				documentId,
			});
		}
	};
	const handleSearchChangePage = () => {
		if (!preventSearchChangePage) {
			handleSearch(referenceIndex);
		}
		preventSearchChangePage = false;
	};
	const handleClickDetails = () => {
		preventSearchChangePage = true;
		onClickDetails();
	};
	const handleClickArrow = (isNext) => {
		preventSearchChangePage = true;
		let newReferenceIndex = referenceIndex;
		if (isNext) {
			if (question.references?.length > referenceIndex + 1) {
				newReferenceIndex += 1;
			} else if (referenceIndex > 0) {
				newReferenceIndex = 0;
			}
		} else if (referenceIndex === 0) {
			if (question.references?.length > 1) {
				newReferenceIndex = question.references.length - 1;
			}
		} else {
			newReferenceIndex -= 1;
		}
		if (newReferenceIndex !== referenceIndex) {
			setReferenceIndex(newReferenceIndex);
			handleSearch(newReferenceIndex);
		}
	};
	return (
		<>
			<SidePanelBox clickable className={styles.container} hasShadow={false} onClick={handleSearchChangePage}>
				<div className={styles.container__line}>
					<div className={styles.line__leftItems}>
						<CustomTooltip showWhenOverflow autoTranslate="no" title={question.asker?.displayName || ""}>
							<span className={styles.username} translate="no">
								{question.asker?.displayName || ""}
							</span>
						</CustomTooltip>
						<span className={styles.clientId}>
							{translate("smart-review.right-panel.question-box.client-id", {
								clientId: question.asker.id,
							})}
						</span>
					</div>
					<div className={styles.line__rightItems}>
						<CustomTooltip
							placement="top"
							title={(!!question.modified && translateDate(question.modified).replaceAll(".", "/")) || ""}
						>
							<div className={styles.date}>
								{translateElapsed({
									value: question.elapsed?.value,
									unitKey: question.elapsed?.unit,
								})}
							</div>
						</CustomTooltip>
						<VerticalDivider className={styles.divider} />
						<CustomTooltip placement="top" title={translateEnumQuestionStatus(question.status)}>
							<Chip
								className={styles.chip}
								data-status={question.status}
								label={translateEnumQuestionStatus(question.status)}
								size="small"
							/>
						</CustomTooltip>
					</div>
				</div>
				<div ref={commentRef} className={styles.result}>
					{question.question?.split("\n").map((q) => (
						<p key={q} className={styles.questionLine}>
							{q}
						</p>
					))}
				</div>
				<div className={styles.buttonsContainer}>
					<CustomIconButton
						icon={icon.faInfoCircle}
						iconClassName={styles.icon}
						size="sm"
						variant="outlined"
						onClick={handleClickDetails}
					/>
				</div>
				{!noAction && (question.references?.length || 0) > 1 && (
					<div className={styles.container__references}>
						<CustomIconButton
							btnClassName={styles.button}
							disabled={(question.references?.length || 0) <= 1}
							icon={icon.faArrowLeft}
							iconClassName={styles.icon}
							size="sm"
							onClick={() => handleClickArrow(false)}
						/>
						{translate("smart-review.right-panel.question-box.references", {
							referenceIndex: referenceIndex + 1,
							totalReferences: question.references?.length || 0,
						})}
						<CustomIconButton
							btnClassName={styles.button}
							disabled={(question.references?.length || 0) <= 1}
							icon={icon.faArrowRight}
							iconClassName={styles.icon}
							size="sm"
							onClick={() => handleClickArrow(true)}
						/>
					</div>
				)}
			</SidePanelBox>
		</>
	);
}
