import axios from "axios";
import { v5 as uuidv5 } from "uuid";
import KeycloakService from "../../services/KeycloakService";
import configAxios from "./config";
import { translate } from "../../common/providers";
import { createNotification } from "../../common/components";
import superstore from "../../store";
import { setToken } from "../../common/slice";
import { ApiService, UserService } from "..";

const API_URL = window.env.REACT_APP_API;
const CONTENT_TYPE = "application/json;charset=UTF-8";
const REQ_CANCEL_MESSAGE = "Pending requests were cancelled";
const CONTENT_DISPOSITION_REGEX = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/;
const HIDDEN_URL_REGEX = /\/dashboards\/projects\/\d+\/companies\/\d+\/requirements/;
const MENTION_URL_REGEX = /\/mentions\/projects\/\d+\/company\/\d+/;
const HIDDEN_RESPONSE_STATUSES = [409, 500, 502, 503];

const ERROR_MESSAGES = { CANCELLED: "canceled" };

const buildUrl = (url) => (url.startsWith("/", 0) ? `${API_URL + url}` : `${API_URL}/${url}`);
const formatError = (err) => {
	if (err && err.response && typeof err.response.data === "string") {
		return err.response.data;
	}
	if (err && err.response && err.response.data) {
		let { message } = err.response.data;
		if (err.response.data.message === "No message available") {
			message = err.response.data.error ?? translate("common:error.operation-has-failed");
		}
		return `${err.response.data.status} - ${message}`;
	}
	return err.message || err.status;
};
const translateError = (err) => {
	const errorData = err.response?.data;
	if (errorData && errorData.translationKey) {
		const params = { ...errorData.params };
		return translate(`backend:${errorData.translationKey}`, params);
	}
	return "";
};
const generateGUID = (config) => {
	let guidString = "";
	if (config?.url) {
		guidString += config.url;
	}
	if (config?.method) {
		guidString += config.method;
	}
	if (config?.data) {
		guidString += JSON.stringify(config.data);
	}
	if (config?.headers?.Authorization) {
		guidString += config.headers.Authorization;
	}
	return uuidv5(guidString, window.env.REACT_APP_UUID_NAMESPACE);
};
class Client {
	constructor() {
		const client = axios.create(configAxios);
		client.interceptors.request.use((config) => {
			const cb = () => {
				config.headers.Authorization = KeycloakService.getToken();
				return Promise.resolve(config);
			};
			if (!config.cancelToken) {
				throw new Error("No cancel token was found for this request");
			}
			if (KeycloakService.isLoggedIn() && !config.url.includes("/public")) {
				if (config.method !== "get") {
					config.headers.XGUID = generateGUID(config);
				}
			}
			config.withCredentials = true; // Sends cookies with requests
			if (KeycloakService.isLoggedIn() && !config.url.includes("/public")) {
				// eslint-disable-next-line
				return KeycloakService.updateToken(cb);
			}
			// eslint-disable-next-line
			return config;
		});

		client.interceptors.response.use(
			(res) => {
				// Any status code that lie within the range of 2xx cause this function to trigger
				const tkURL = res.config.url.substring(res.config.baseURL.length) || "";
				if (
					tkURL &&
					["/login", "/public/projects/join", "/public/users/register", "/public/refresh"].some((uri) =>
						tkURL.includes(uri)
					)
				) {
					const token = res.headers.authorization || null;
					const data = res.data || null;
					return { data, token };
				}
				if ((res?.headers?.["content-disposition"] || "").includes("filename")) {
					const contentDisposition = CONTENT_DISPOSITION_REGEX.exec(res.headers["content-disposition"]);
					let filename = contentDisposition[1];
					// eslint-disable-next-line quotes
					if ((filename || "").startsWith('"')) {
						// Strip double quotes
						filename = filename.substring(1, filename.length - 1);
					}
					return { data: res.data, filename };
				}
				return res.data;
			},
			(err) => {
				// Any status codes that falls outside the range of 2xx cause this function to trigger
				const formatedError = formatError(err);
				const URL = err.config?.url.substring(err.config.baseURL.length) || "";
				if (!KeycloakService.isLoggedIn() && URL && ["/public/refresh"].some((uri) => URL.includes(uri))) {
					return Promise.reject(formatedError);
				}
				// Performs logout on 401s, except for the response of /refresh
				if (err.response?.status === 401) {
					if (!URL || !URL.includes("/public/projects/join")) {
						superstore.store.dispatch(setToken());
					}
					if (!URL || !["/login", "/public/projects/join"].some((uri) => URL.includes(uri))) {
						window.location.reload();
					}
					return Promise.reject(formatedError);
				}
				if (err.response?.status === 502) {
					createNotification({ type: "error", message: translate("backend:error.server.block") });
				}
				if (
					!HIDDEN_URL_REGEX.test(URL) &&
					!MENTION_URL_REGEX.test(URL) &&
					(window.env.REACT_APP_ENV === "dev" ||
						window.env.REACT_APP_ENV === "qa" ||
						!HIDDEN_RESPONSE_STATUSES.includes(err.response.status))
				) {
					const translatedError = translateError(err);
					if (formatedError !== ERROR_MESSAGES.CANCELLED && err.response?.status !== 502) {
						createNotification({ type: "error", message: translatedError || formatedError });
					}
				}
				if (
					(err.response?.status === 403 &&
						err.response?.data?.translationKey === "error.user.company.not.set") ||
					(err.response?.status === 403 &&
						err.response?.data?.message ===
							"You don't have access to the instance {}. Please check the name of the instance you try to access or contact your account administrator")
				) {
					const cancelToken = ApiService.getCancelTokenSource().token;
					UserService.logout(cancelToken).finally(() => {
						KeycloakService.doLogout().then(() => {
							KeycloakService.clearToken();
						});
					});
				}
				return Promise.reject(formatedError);
			}
		);

		this.client = client;
	}

	get(url, { params, config = {} } = {}) {
		return this.client.get(buildUrl(url), { params, ...config });
	}

	delete(url, { params, config = {} } = {}) {
		return this.client.delete(buildUrl(url), { params, ...config });
	}

	post(url, { payload = null, params, config = {} } = {}) {
		Object.assign(config, { headers: { ...(config?.headers || {}), "Content-Type": CONTENT_TYPE } });
		return this.client.post(buildUrl(url), payload, { params, ...config });
	}

	put(url, { payload = null, params, config = {} } = {}) {
		Object.assign(config, { headers: { ...(config?.headers || {}), "Content-Type": CONTENT_TYPE } });
		return this.client.put(buildUrl(url), payload, { params, ...config });
	}

	patch(url, { payload = null, params, config = {} } = {}) {
		Object.assign(config, { headers: { ...(config?.headers || {}), "Content-Type": CONTENT_TYPE } });
		return this.client.patch(buildUrl(url), payload, { params, ...config });
	}

	upload(url, files, { payload = null, params, config = {} } = {}) {
		const formData = new FormData();
		if (Array.isArray(files)) {
			files.forEach((file) => formData.append("files", file));
		} else {
			formData.append("file", files);
		}
		if (payload) {
			formData.append(
				"payload",
				new Blob([typeof payload === "object" ? JSON.stringify(payload) : payload], {
					type: CONTENT_TYPE,
				})
			);
		}
		Object.assign(config, { headers: { ...(config?.headers || {}), "Content-Type": undefined } }); // Will be automatically set to multipart/form-data
		return this.client.post(buildUrl(url), formData, { params, ...config });
	}

	download(url, { params, config = {} } = {}) {
		return this.client.get(buildUrl(url), {
			params,
			...config,
			responseType: "arraybuffer",
		});
	}

	downloadPatch(url, { payload = null, params, config = {} } = {}) {
		Object.assign(config, { headers: { ...(config?.headers || {}), "Content-Type": CONTENT_TYPE } });
		return this.client.patch(buildUrl(url), payload, { params, responseType: "arraybuffer", ...config });
	}

	getCancelTokenSource() {
		return axios.CancelToken.source();
	}

	cancelTokens(cancelTokenSource) {
		cancelTokenSource.cancel(REQ_CANCEL_MESSAGE);
	}

	isRequestCancellation(errorMessage) {
		return errorMessage === REQ_CANCEL_MESSAGE;
	}
}

export default new Client();
