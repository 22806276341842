import React from "react";
import styles from "./ContentToLink.module.css";
import { SENTENCE_TYPE } from "../../../../../../../constants/constants";
import { CustomIconButton, Hierarchy, icon } from "../../../../../../../../../common/components";
import { dangerousSetInnerHTML } from "../../../../../../../../../common/utils";

const spanRegex = /<\/?span[^>]*>/g;

const ContentToLink = ({ content, isSelectedResult, onClose = null }) => {
	const { content: text, documentName, informationId, location, type } = content;
	const removeSpanTags = (spanText) => spanText.replace(spanRegex, "");
	const selectedContent = isSelectedResult ? removeSpanTags(text) : dangerousSetInnerHTML({ text });
	return (
		<div className={styles.container}>
			<div>
				<div className={styles.rowId}>
					{(type === SENTENCE_TYPE.INFORMATION && "INFO_") || "REQ_"}
					{informationId}
				</div>
				{onClose && typeof onClose === "function" && (
					<CustomIconButton
						btnClassName={styles.close}
						icon={icon.faTimes}
						iconColor="var(--color-light-grey-1)"
						onClick={onClose}
					/>
				)}
			</div>
			<div>
				<Hierarchy
					isDangerousSetInnerHTML
					breadcrumbClassName={styles.heriarchy}
					documentName={documentName}
					hierarchy={location}
				/>
			</div>
			<div>{selectedContent}</div>
		</div>
	);
};

export default ContentToLink;
