import client from "../../client/client";

class MyService {
	getDetails(cancelToken) {
		return client.get("users/me", { config: { cancelToken } });
	}

	changeInformation(payload, cancelToken) {
		return client.post("/users/me", { payload, config: { cancelToken } });
	}

	getProjects(cancelToken, { page = 0, limit = 50, sort = "created", direction, payload }) {
		return client.post("/users/me/projects", {
			params: { page, limit, sort, direction },
			payload,
			config: { cancelToken },
		});
	}

	getProjectDetails({ projectId }, cancelToken) {
		return client.get(`/users/me/projects/${projectId}`, { config: { cancelToken } });
	}

	getDocumentDetails({ docId }, cancelToken) {
		return client.get(`/users/me/documents/${docId}`, { config: { cancelToken } });
	}

	changePassword(payload, cancelToken) {
		return client.patch("/users/me/password", { payload, config: { cancelToken } });
	}

	changePasswordSso({ locale }, cancelToken) {
		return client.patch(`/users/me/password/${locale}`, { config: { cancelToken } });
	}

	changeLanguage({ value }, cancelToken) {
		return client.post("/users/me/language", { payload: { value }, config: { cancelToken } });
	}

	getPreferedLanguage(cancelToken) {
		return client.get("/users/me/language", { config: { cancelToken } });
	}

	changeUserNotificationsFrequency({ value }, cancelToken) {
		return client.patch("/users/me/notifications", { payload: JSON.stringify(value), config: { cancelToken } });
	}

	getUserNotificationsFrequency(cancelToken) {
		return client.get("/users/me/notifications", { config: { cancelToken } });
	}
}

export default new MyService();
