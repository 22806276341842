import React from "react";
import { hasCompanyPermission, Permissions, translate } from "../../../../common/providers";
import { CustomButton, SearchInput } from "../../../../common/components";
import styles from "./PanelHeader.module.css";

export default function PanelHeader({
	isLoading,
	isUserProjectLeader,
	onAdd,
	onClearSearch,
	onClickPrivacy,
	onSearch,
	search,
}) {
	const isMyCompanyProjectLeader = hasCompanyPermission(Permissions.PROJECT_LEADER);
	const isMyCompanyProjectManager = hasCompanyPermission(Permissions.PROJECT_MANAGER);

	return (
		<div className={styles.panelHeader}>
			{isUserProjectLeader && (
				<div className={styles.panelHeader__row}>
					<CustomButton color="primary" variant="outlined" onClick={onClickPrivacy}>
						{translate("team-management.panel-header.privacy-btn")}
					</CustomButton>
					{(isMyCompanyProjectManager || isMyCompanyProjectLeader) && (
						<CustomButton color="primary" variant="contained" onClick={onAdd}>
							{translate("team-management.panel-header.create-new-team-btn")}
						</CustomButton>
					)}
				</div>
			)}
			<div className={styles.panelHeader__row}>
				<SearchInput
					fullWidth
					isLoading={isLoading}
					placeholder={translate("team-management.panel-header.placeholder.search")}
					value={search}
					onChange={onSearch}
					onClearSearch={onClearSearch}
				/>
			</div>
		</div>
	);
}
