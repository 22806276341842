import React from "react";
import { IconComponent, HorizontalDivider } from "../..";
import styles from "./TileHeader.module.css";

export default function TileHeader({ className = "", iconName = "", title = "", children }) {
	return (
		<header className={styles.tileHeader}>
			<div className={styles.tileHeader__content}>
				<div className={`${styles["tileHeader__content--left"]} ${className}`}>
					{iconName && (
						<IconComponent className={styles.tileHeader__icon} fontSize="default" icon={iconName} />
					)}
					{title}
				</div>
				{children}
			</div>
			<HorizontalDivider />
		</header>
	);
}
