import React, { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import styles from "../add-user-dialog/AddUserDialog.module.css";
import { translate } from "../../../../common/providers";
import { CustomDialog } from "../../../../common/components";
import { isNonEmptyArray } from "../../../../common/utils";

export default function AddPartnerDialog({ open, onClose, onSubmit, companies, selectedCompany }) {
	const [selectedPartner, setSelectedPartner] = useState(null);
	const handleSelectPartner = (e) => setSelectedPartner(e.target.value);
	const handleSubmit = () => {
		if (!selectedPartner) {
			return;
		}
		onSubmit(selectedPartner);
		setSelectedPartner(null);
		onClose();
	};
	const renderMenuItemsWithChildren = (treeRows, selectedComp) =>
		treeRows.map((row) => {
			const isSelectedCompany = row?.id === selectedComp?.id;
			return [
				!isSelectedCompany ? (
					<MenuItem key={row.id} translate="no" value={row}>
						{row.companyName}
					</MenuItem>
				) : null,
				isNonEmptyArray(row.children) && renderMenuItemsWithChildren(row.children, selectedComp),
			];
		});
	return (
		<CustomDialog
			isForm
			open={open}
			title={translate("dsi.add-partner-dialog.add-partner-to-project")}
			onClose={onClose}
			onSubmit={handleSubmit}
		>
			<FormControl
				fullWidth
				className={styles.formControl}
				label={translate("dsi.add-partner-dialog.label.select-company")}
				variant="outlined"
			>
				<InputLabel className={styles.inputLabel}>
					{translate("dsi.add-partner-dialog.label.select-company")}
				</InputLabel>
				<Select fullWidth size="small" value={selectedPartner || ""} onChange={handleSelectPartner}>
					{companies && renderMenuItemsWithChildren(companies, selectedCompany)}
				</Select>
			</FormControl>
		</CustomDialog>
	);
}
