import client from "../client/client";

class MentionsService {
	getTeamMembers({ projectId }, { page = 0, limit = 25 }, cancelToken) {
		return client.get(`/mentions/projects/${projectId}/users`, {
			params: { page, limit },
			config: { cancelToken },
		});
	}

	getUserMentionsByProject({ projectId }, { page = 0, limit = 25, isRead }, cancelToken) {
		return client.get(`/mentions/projects/${projectId}/me`, {
			params: { page, limit, isRead },
			config: { cancelToken },
		});
	}

	changeReadStatus({ mentionId }, cancelToken) {
		return client.patch(`/mentions/${mentionId}/read`, {
			config: { cancelToken },
		});
	}

	markAllAsReadOnProject({ projectId }, cancelToken) {
		return client.patch(`/mentions/projects/${projectId}/all`, {
			config: { cancelToken },
		});
	}

	getUserUnreadMentionsCountByProject({ projectId }, cancelToken) {
		return client.get(`/mentions/projects/${projectId}`, {
			config: { cancelToken },
		});
	}

	getUserUnreadMentionsCountByProjectAndCompany({ projectId, companyId }, cancelToken) {
		return client.get(`/mentions/projects/${projectId}/company/${companyId}`, {
			config: { cancelToken },
		});
	}
}

export default new MentionsService();
