import React, { useState } from "react";
import { SearchInput } from "../..";
import { translate } from "../../../providers";
import { CustomButton } from "../../buttons";
import { CircularLoader } from "../../loaders";
import styles from "./FilterSearchListOpportunity.module.css";
import { handleCheckInTree } from "../../../utils";
import FilterSearchRow from "../../filters/filter-search-list/filter-search-row/FilterSearchRow";

export default function FilterSearchListOpportunity({
	defaultInputValue = "",
	filterKey = "",
	initChecked = [],
	isLoading = false,
	isTree,
	onChangeSearch,
	onClear = null,
	onClearSearch,
	onClickRow,
	onExpand,
	rowLabelKey,
	rowKey = rowLabelKey,
	rowSecondaryLabelKey = "",
	rows,
	searchPlaceholder,
	subRowCounter = "",
	subRowKey = "",
	withRowLabelTooltip = false,
}) {
	const [checkedElements, setCheckedElements] = useState(initChecked);
	const [predecessors, setPredecessors] = useState([]);
	const [inputValue, setInputValue] = useState(defaultInputValue);
	const handleChangeSearch = (e) => {
		setInputValue(e.target.value);
		onChangeSearch(e);
	};
	const handleClearSearch = () => {
		setInputValue("");
		onClearSearch();
	};

	const handleResult = ({ newCheckedElements, newPredecessors }) => {
		setCheckedElements(newCheckedElements);
		setPredecessors(newPredecessors);
		const filterIds = newCheckedElements.filter((e) => !newPredecessors.some((pred) => pred.list.includes(e)));
		onClickRow(filterIds, filterKey);
	};

	const handleClick = (row) => {
		handleCheckInTree({
			row,
			rowKey,
			isTree,
			checkedElements,
			predecessors,
			handleResult,
		});
	};
	const handleClear = () => {
		setCheckedElements([]);
		onClear();
	};
	const isRowAncestor = ({ [rowKey]: identifier }) =>
		isTree && predecessors.some((element) => element.list.some((e) => e === identifier));
	const isRowChecked = ({ [rowKey]: identifier }) => checkedElements.some((element) => element === identifier);
	const isRowCheckedOrAncestor = (row) => isRowChecked(row) || isRowAncestor(row);
	const handleAddAncestor = (predecessor) => {
		setPredecessors((prev) => [...prev, predecessor]);
	};
	return (
		<div className={styles.filter}>
			{!!onChangeSearch && (
				<div className={styles.filter__search}>
					<SearchInput
						fullWidth
						isLoading={isLoading}
						placeholder={searchPlaceholder}
						value={inputValue}
						onChange={handleChangeSearch}
						onClearSearch={onClearSearch ? handleClearSearch : null}
					/>
				</div>
			)}
			{isLoading && (
				<div className={styles.filter__stateContainer}>
					<CircularLoader size={45} />
				</div>
			)}
			<div className={styles.filter__options}>
				{onClear && (
					<CustomButton
						className={styles.options__clear}
						color="primary"
						size="md"
						variant="text"
						onClick={handleClear}
					>
						{translate("common:btn.clear-select")}
					</CustomButton>
				)}
			</div>
			{!isLoading &&
				Array.isArray(rows) &&
				rows.length > 0 &&
				rows.map((row) => (
					<FilterSearchRow
						key={row[rowKey]}
						checkedElements={checkedElements}
						isRowChecked={isRowCheckedOrAncestor}
						isTree={isTree}
						label={row[rowLabelKey]}
						row={row}
						rowKey={rowKey}
						rowLabelKey={rowLabelKey}
						secondaryLabel={row[rowSecondaryLabelKey]}
						subRowCounter={subRowCounter}
						subRowKey={subRowKey}
						withRowLabelTooltip={withRowLabelTooltip}
						onAddAncestor={handleAddAncestor}
						onClick={handleClick}
						onExpand={onExpand}
					/>
				))}
		</div>
	);
}
