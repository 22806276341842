import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from ".";
import { ApiService } from "../../../../../../../../../api";
import { setUpdateTocNode } from "../../../../../../../slice/project-document/project-document-slice";
import { getClauseTransverse } from "../../../../../../../slice/project-document/project-document-thunks";
import { generatePayload } from "../../../../../../../utils/utils";

export default function Clause({ level, data, onSelect }) {
	const cancelTokenSourceRef = useRef(null);
	const projectId = useSelector(({ context }) => context.project.id);
	const filters = useSelector(({ srProjectDocument }) => srProjectDocument.filters);
	const dispatch = useDispatch();
	useEffect(() => {
		if (!cancelTokenSourceRef.current) {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		}
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const handleGetClause = () => {
		if (data.subclauseExist && (!data.subClauses || data.subClauses.length === 0)) {
			dispatch(
				getClauseTransverse({
					projectId,
					documentId: data.documentId,
					clauseId: data.id,
					filters: { ...generatePayload(filters), totalContent: data.totalContent },
					token: cancelTokenSourceRef.current.token,
				})
			);
		}
	};
	const handleOpen = (e) => {
		e.stopPropagation();
		e.preventDefault();
		dispatch(setUpdateTocNode({ documentId: data.documentId, clauseId: data.id, propName: "open" }));
		handleGetClause();
	};
	const { subClauses, open, parents = [], ...rest } = data;
	return (
		<>
			<Row
				data={data}
				hasChildren={data.subclauseExist}
				label={data.content}
				level={level + 1}
				open={open}
				onOpen={handleOpen}
				onSelect={onSelect}
			/>
			{open &&
				Array.isArray(subClauses) &&
				subClauses.length > 0 &&
				subClauses.map((clause) => (
					<Clause
						key={clause.id}
						data={{ ...clause, documentId: data.documentId, parents: [...parents, rest] }}
						level={level + 1}
						onSelect={onSelect}
					/>
				))}
		</>
	);
}
