import { Client } from "@stomp/stompjs";
import KeycloakService from "../../services/KeycloakService";

/**
 * this.subscribe will return a subscription from which it is preferable to call unsubscribe() mthd.
 * Meaning, the view responsible for subscribing to the ws chan, is also responsible for unsubscribing.
 */
class WebSocket {
	constructor() {
		this.record = {};
	}

	init() {
		this.client = new Client();
		this.client.brokerURL = window.env.REACT_APP_WS;
		this.client.reconnectDelay = 0;
		this.client.connectionTimeout = 30000;
		this.client.onStompError = (frame) => {
			console.error(`ERROR: onStompError#WebSocket - ${frame.body}`);
		};
	}

	connect() {
		if (this.client && this.client.active) {
			return;
		}
		const token = KeycloakService.getToken();
		if (!token) {
			return;
		}
		this.init();
		this.client.connectHeaders = { "X-Authorization": token };
		this.client.activate();
	}

	forceDisconnect() {
		if (this.client?.active) {
			Object.keys(this.record).forEach((key) => (this.record[key] = false));
			this.client.forceDisconnect();
			this.client = null;
		}
	}

	disconnect() {
		if (this.client?.active) {
			Object.keys(this.record).forEach((key) => (this.record[key] = false));
			this.client.deactivate();
			this.client = null;
		}
		return Promise.resolve();
	}

	async subscribe(destination, callback, headers = {}) {
		if (!destination || !callback) {
			return null;
		}
		const ts = Date.now();
		this.record[ts] = true;
		try {
			await this.waitForConnection();
			if (!this.record[ts]) {
				return null;
			}
			return this.client.subscribe(destination, (message) => callback(JSON.parse(message.body)), headers);
		} catch (err) {
			console.error(err);
			throw new Error(err);
		}
	}

	waitForConnection() {
		return new Promise((resolve, reject) => {
			if (this.client && !this.client.active) {
				reject(new Error("WS not activated"));
			} else if (this.client?.connected) {
				resolve();
			}
			const maxNumberOfAttempts = 10;
			const intervalTime = 200; // ms
			let currentAttempt = 0;
			const interval = setInterval(() => {
				if (currentAttempt > maxNumberOfAttempts - 1) {
					clearInterval(interval);
					reject(new Error("Maximum number of attempts exceeded"));
				} else if (this.client?.connected) {
					clearInterval(interval);
					resolve();
				}
				currentAttempt++;
			}, intervalTime);
		});
	}
}

export default new WebSocket();
