import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CollabService, ValidatorService, ApiService, CommentService } from "../../../../../../api";
import { CustomIconButton, CustomTooltip, icon, IconComponent } from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import { getStatusColor } from "../../../../../../common/utils";
import { REQ_STATUSES, SENTENCE_TYPE, ROLES, DOCUMENT_STATUSES, STATUS_BUTTONS } from "../../../../constants/constants";
import NotMyCompany from "./NotMyCompany";
import TextInputDialog from "./TextInputDialog";
import { updateNode } from "../../../../slice/pdf/pdf-slice";
import { convertStatusDBtoInput, convertStatusInputToDB, checkReqValMode } from "../../../../utils/utils";
import styles from "./StatusCheckButtons.module.css";
import { setHideDenyingDialogDisplay } from "../../../../slice/document/document-slice";

const displayValidationIcon = ({
	informationId,
	isFrozenByParent,
	parentName,
	sentenceType,
	validatedSentenceType,
	validatedStatus,
	validatedType,
}) => {
	if (!informationId) {
		return null;
	}
	const color = getStatusColor(validatedStatus);
	const icons = { NO: icon.faTimesCircle, YES: icon.faCheckCircle, PENDING: icon.faClock, NOT_ME: icon.faNotEqual };
	if (validatedSentenceType === SENTENCE_TYPE.REQUIREMENT || validatedType === SENTENCE_TYPE.REQUIREMENT) {
		if (isFrozenByParent) {
			return (
				<CustomTooltip
					title={translate("smart-review.validation-checker.title.position-frozen", {
						name: parentName,
					})}
				>
					<IconComponent color={color} icon={icon.faCheckCircle} size="xl" />
				</CustomTooltip>
			);
		}
		return <IconComponent icon={icon.faCheckCircle} size="xl" style={color} />;
	}
	if (sentenceType === SENTENCE_TYPE.INFORMATION) {
		return <IconComponent className={styles["icon__font--white"]} icon={icon.faInfoCircle} size="xl" />;
	}
	return (
		<IconComponent
			className={styles.statusIcon}
			color={color}
			data-type={validatedStatus}
			icon={icons[validatedStatus]}
			size="lg"
		/>
	);
};

const displayButton = ({ isFrozen, statusValue, hasReviewerRole, mode, handleChange, statusName, color }) => {
	const buttonStyle = styles[`button--${statusName}`];
	const icons = {
		denied: icon.faTimesCircle,
		accepted: icon.faCheckCircle,
		pending: icon.faClock,
	};
	return (
		!(isFrozen && statusValue.value !== statusName) && (
			<CustomIconButton
				btnClassName={`${styles.button} ${buttonStyle}`}
				data-checked={statusValue.value === statusName}
				data-other-checked={statusValue.value !== "unreviewed" && statusValue.value !== statusName}
				data-testid={`${statusName}.position.btn`}
				disabled={isFrozen || (!hasReviewerRole && mode === ROLES.REVIEWER.mode)}
				icon={icons[statusName]}
				iconClassName={styles.icon}
				iconColor={(statusValue.isCurrent && color) || "var(--color-light-grey-1)"}
				size="sm"
				tooltip={translate(`smart-review.status-check-button.title.${statusName}`)}
				tooltipPlacement="top"
				onClick={() => handleChange(statusName)}
			/>
		)
	);
};

const initStatus = ({ mode, lastStatus, validatedStatus }) =>
	(mode === ROLES.REVIEWER.mode && convertStatusDBtoInput(lastStatus)) ||
	(mode === ROLES.VALIDATOR.mode && convertStatusDBtoInput(validatedStatus)) ||
	null;

export default function StatusCheckButtons({
	data: {
		formerStatus,
		informationId,
		isFrozen,
		isFrozenByConsortium,
		isFrozenByParent,
		lastStatus,
		pageStart,
		parentName,
		sentenceType,
		validatedSentenceType,
		validatedStatus,
		validatedType,
	},
	ownCompany,
	onDispatchIssueComment,
	setOpeningIssuer,
}) {
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const hideDenyingDialogDisplay = useSelector(({ srDocument }) => srDocument.hideDenyingDialogDisplay);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const isArchived = useSelector(({ srDocument }) => srDocument.status === DOCUMENT_STATUSES.ARCHIVED);
	const savedImageLength = useSelector(({ srPdf }) => srPdf?.content?.length || 0);
	const selectedSearch = useSelector(({ srDocument }) => srDocument.selectedSearch);
	const hasReviewerRole = useSelector(({ srDocument }) =>
		(srDocument.roles || []).some((x) => x === ROLES.REVIEWER.name.toUpperCase())
	);
	const [selectedValue, setSelectedValue] = useState(initStatus({ mode, lastStatus, validatedStatus }));
	const [prevStatus, setPrevStatus] = useState(initStatus({ mode, lastStatus, validatedStatus }));
	const [openDenyingDialog, setOpenDenyingDialog] = useState(false);
	const cancelTokenSourceRef = useRef(null);
	const isUpdatable = useMemo(
		() =>
			(mode === ROLES.REVIEWER.mode && !isFrozen && !isFrozenByConsortium && hasReviewerRole) ||
			(mode === ROLES.VALIDATOR.mode && !isFrozenByConsortium),
		[mode, isFrozen, isFrozenByConsortium, hasReviewerRole]
	);
	const dInput = useRef();
	const dispatch = useDispatch();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		setSelectedValue(convertStatusDBtoInput(lastStatus));
		setPrevStatus(convertStatusDBtoInput(lastStatus));
	}, [informationId, lastStatus]);
	useEffect(
		() => {
			setSelectedValue(initStatus({ mode, lastStatus, validatedStatus }));
			setPrevStatus(initStatus({ mode, lastStatus, validatedStatus }));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[validatedStatus, lastStatus, mode]
	);

	const handleChangeComment = (newComment) => {
		const request =
			mode === ROLES.VALIDATOR.mode
				? CollabService.updateIssueComment({ informationId }, newComment, cancelTokenSourceRef.current.token)
				: CommentService.addPrimeComment(
						{ informationId },
						{ value: newComment },
						cancelTokenSourceRef.current.token
				  );
		request
			.then(() => {
				dispatch(
					updateNode({
						mode,
						pageConditions: { page: pageStart },
						informationId,
						updates: [
							{
								property: "lastCommentIssue",
								value: newComment,
							},
						],
					})
				);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleClose = (submitted) => {
		setOpenDenyingDialog(false);
		if (!submitted) {
			setSelectedValue(prevStatus);
		}
	};
	const handleCheckValidationError = (err, prev) => {
		console.error(err);
		setSelectedValue(prev || null);
	};
	const dispatchNewStatus = (status, comment) => {
		dispatch(
			updateNode({
				mode,
				pageConditions: { page: pageStart, border: true, length: savedImageLength },
				informationId,
				updates: [
					{
						property: "lastStatus",
						value: status,
					},
					{
						property: "lastComment",
						value: comment || null,
					},
				],
			})
		);
	};
	const dispatchValStatus = (status) => {
		const updates = [
			{
				property: "validatedSentenceType",
				value:
					status?.toUpperCase() === SENTENCE_TYPE.INFORMATION
						? SENTENCE_TYPE.INFORMATION
						: SENTENCE_TYPE.REQUIREMENT,
			},
		];
		if (status !== "information") {
			updates.push({
				property: "validatedStatus",
				value: status,
			});
		}
		dispatch(
			updateNode({
				mode,
				pageConditions: { page: pageStart, border: true, length: savedImageLength },
				informationId,
				updates,
			})
		);
	};
	const handleCheckValidation = (value, prev, comment) => {
		if (validatedStatus && convertStatusDBtoInput(value) === prev) {
			ValidatorService.remove(
				{
					infoId: informationId,
				},
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					dispatchValStatus(null);
					setSelectedValue(null);
				})
				.catch((err) => handleCheckValidationError(err, prev));
		} else {
			ValidatorService.validate(
				{
					infoId: informationId,
				},
				(value || "").toUpperCase(),
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					dispatchValStatus(value);
					if (comment) {
						onDispatchIssueComment(comment);
					}
					setSelectedValue(convertStatusDBtoInput(value));
				})
				.catch((err) => handleCheckValidationError(err, prev));
		}
	};
	const requestSetNewStatus = (value, comment, selectedMode) => {
		if (!selectedMode) {
			dispatchValStatus(value);
		}
		if (mode === ROLES.REVIEWER.mode) {
			const unreviewing = convertStatusDBtoInput(value) === prevStatus;
			CollabService.updateStatus(
				{ infoId: informationId },
				unreviewing ? REQ_STATUSES.UNREVIEWED : value.toUpperCase(),
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					dispatchNewStatus(unreviewing ? "unreviewed" : value, comment);
					if (comment) {
						onDispatchIssueComment(comment);
					}
					setSelectedValue(unreviewing ? "unreviewed" : convertStatusDBtoInput(value));
				})
				.catch((err) => console.error(err));
		} else if (mode === ROLES.VALIDATOR.mode) {
			handleCheckValidation(value, prevStatus, comment);
		}
	};
	const handleSubmitDialog = (comment, status) => {
		if (status[2] === "deny") {
			setOpenDenyingDialog(false);
		}
		if (comment) {
			handleChangeComment(comment);
		}
		requestSetNewStatus(convertStatusInputToDB(selectedValue), null, mode);
	};
	const handleSkip = () => {
		handleSubmitDialog(null, [lastStatus?.toLowerCase() || "-", convertStatusInputToDB(selectedValue), "deny"]);
	};
	const handleDeny = (lastS) => {
		if (checkReqValMode(mode)) {
			if (setOpeningIssuer) {
				setOpeningIssuer();
			}
			if (!hideDenyingDialogDisplay) {
				setOpenDenyingDialog(true);
				dInput.current = false;
			} else {
				requestSetNewStatus(lastS, null, mode);
			}
		}
	};
	const handleChangeStatus = (prevS, selV) => {
		const lastS = convertStatusInputToDB(selV);
		if (checkReqValMode(mode)) {
			if ((lastS === "no" || lastS === "pending") && lastS !== convertStatusInputToDB(prevS)) {
				handleDeny(lastS);
			} else {
				requestSetNewStatus(lastS, null, mode);
			}
		}
	};
	const handleChange = (value) => {
		const prevV = selectedValue;
		setPrevStatus(prevV);
		setSelectedValue(value);
		handleChangeStatus(prevV, value);
	};
	const getValue = useCallback(() => {
		const previousVersion =
			((mode === ROLES.REVIEWER.mode && (lastStatus === REQ_STATUSES.UNREVIEWED || !lastStatus)) ||
				(mode === ROLES.VALIDATOR.mode && (validatedStatus === REQ_STATUSES.UNREVIEWED || !validatedStatus))) &&
			formerStatus &&
			formerStatus !== REQ_STATUSES.UNREVIEWED &&
			convertStatusDBtoInput(formerStatus);
		const value = selectedValue !== "unreviewed" && selectedValue;
		return {
			value:
				value ||
				previousVersion ||
				convertStatusDBtoInput((mode === ROLES.REVIEWER.mode && lastStatus) || validatedStatus),
			isCurrent: ((value || previousVersion) && !!value) || true,
		};
	}, [formerStatus, lastStatus, mode, selectedValue, validatedStatus]);
	const statusValue = useMemo(() => getValue(), [getValue]);
	useEffect(
		() => {
			const handleKeyPress = (event) => {
				if (event.repeat) {
					return;
				}
				if (
					!openDenyingDialog &&
					!disableKeyEvents &&
					(selectedSearch?.informationId || selectedSearch?.infoId) === informationId
				) {
					dInput.current = true;
					switch (event.key) {
						case "1":
							handleChange(STATUS_BUTTONS.ACCEPTED);
							break;
						case "2":
							handleChange(STATUS_BUTTONS.PENDING);
							break;
						case "3":
							handleChange(STATUS_BUTTONS.DENIED);
							break;
						case "4":
							handleChange(STATUS_BUTTONS.NOT_ME);
							break;
						default:
							break;
					}
				}
			};
			window.addEventListener("keypress", handleKeyPress);
			return () => {
				window.removeEventListener("keypress", handleKeyPress);
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[disableKeyEvents, selectedSearch, openDenyingDialog]
	);

	const handleCheck = () => dispatch(setHideDenyingDialogDisplay(!hideDenyingDialogDisplay));

	return (
		<>
			{isUpdatable ? (
				<>
					<div className={styles.buttonContainer}>
						{displayButton({
							isFrozen,
							statusValue,
							hasReviewerRole,
							mode,
							handleChange,
							statusName: STATUS_BUTTONS.ACCEPTED,
							color: "var(--color-green)",
						})}
						{displayButton({
							isFrozen,
							statusValue,
							hasReviewerRole,
							mode,
							handleChange,
							statusName: STATUS_BUTTONS.PENDING,
							color: "var(--color-orange)",
						})}
						{displayButton({
							isFrozen,
							statusValue,
							hasReviewerRole,
							mode,
							handleChange,
							statusName: STATUS_BUTTONS.DENIED,
							color: "var(--color-red)",
						})}
						{!(isFrozen && statusValue.value !== "notme") && (
							<NotMyCompany
								disabled={isFrozen}
								informationId={informationId}
								isArchived={isArchived}
								lastStatus={lastStatus}
								validatedStatus={validatedStatus}
								value={statusValue}
								onDispatchNewStatus={dispatchNewStatus}
								onDispatchValStatus={dispatchValStatus}
							/>
						)}
					</div>
					<TextInputDialog
						autoFocus
						contentText={
							(mode === ROLES.VALIDATOR.mode &&
								translate("smart-review.comment-information-description-validator")) ||
							(mode === ROLES.REVIEWER.mode &&
								translate("smart-review.comment-information-description-reviewer"))
						}
						deniedInput={dInput.current}
						hidden={hideDenyingDialogDisplay}
						inputArguments={[
							lastStatus ? lastStatus?.toLowerCase() : "-",
							convertStatusInputToDB(selectedValue),
							"deny",
						]}
						open={openDenyingDialog}
						title={translate("smart-review.issue-description")}
						value={mode === ROLES.VALIDATOR.mode && ownCompany && ownCompany.issueComment}
						onClose={handleClose}
						onHide={handleCheck}
						onSkip={handleSkip}
						onSubmit={handleSubmitDialog}
					/>
				</>
			) : (
				<div className={styles.validationIcon}>
					{displayValidationIcon({
						informationId,
						validatedStatus,
						validatedSentenceType,
						validatedType,
						isFrozenByParent,
						sentenceType,
						parentName,
					})}
				</div>
			)}
		</>
	);
}
