import React from "react";
import styles from "./CollaboratorRow.module.css";
import { CustomIconButton, icon } from "../../../../../../../common/components";

export default function CollaboratorRow({ id, collaborator, onRemove }) {
	return (
		<div className={styles.main}>
			<span>{collaborator}</span>
			<CustomIconButton icon={icon.faTimes} iconColor="var(--color-dark-grey-1)" onClick={() => onRemove(id)} />
		</div>
	);
}
