import React from "react";
import { InfiniteList } from "../..";
import SearchRow from "../search-row/SearchRow";
import styles from "./SearchListInfinite.module.css";

export default function SearchListInfinite({
	emptyStateText = "",
	currentTotalElements,
	isRowChecked,
	onClickRow,
	onLoadMore,
	rowLabelKey,
	rows,
	rowTooltipKey,
	totalElements,
}) {
	const isRowLoaded = ({ index }) => index < currentTotalElements;
	const rowRenderer = ({ key, index, style }) => {
		const row = rows[index];
		return (
			<SearchRow
				key={key}
				checked={isRowChecked(row)}
				label={row[rowLabelKey]}
				style={style}
				tooltip={row[rowTooltipKey] || ""}
				onClick={() => onClickRow(row)}
			/>
		);
	};
	const noRowsRenderer = () => <div className={styles.list__emptyState}>{emptyStateText}</div>;
	return (
		<div className={styles.list}>
			<InfiniteList
				className={styles.list__infinite}
				currentTotalElements={currentTotalElements}
				height={300}
				isRowLoaded={isRowLoaded}
				loadMoreRows={onLoadMore}
				noRowsRenderer={noRowsRenderer}
				rowHeight={50}
				rowRenderer={rowRenderer}
				threshold={10}
				totalElements={totalElements}
				width={400}
			/>
		</div>
	);
}
