import React, { useState, useRef, useEffect } from "react";
import { Card, CardContent, Divider } from "@mui/material";
import { BlockTitle, CustomButton, createNotification, CustomDialog } from "../../../../common/components";
import { ApiService, BackOfficeService } from "../../../../api";
import { translate } from "../../../../common/providers";
import styles from "./DSIPlatform.module.css";

export default function DSIPlatform() {
	const cancelTokenSourceRef = useRef(null);
	const [openClearCache, setOpenClearCache] = useState(false);
	const [openEmailDatabaseIntegrity, setOpenEmailDatabaseIntegrity] = useState(false);
	const [openForceUpdateMailjet, setOpenForceUpdateMailjet] = useState(false);
	const [openForceUpdateNormDatabase, setOpenForceUpdateNormDatabase] = useState(false);
	const [openForceSendDailyNotifs, setOpenForceSendDailyNotifs] = useState(false);
	const [openForceSendWeeklyNotifs, setOpenForceSendWeeklyNotifs] = useState(false);
	useEffect(() => {
		if (!cancelTokenSourceRef.current) {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		}
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const handleOpenClearCacheDialog = () => setOpenClearCache((prev) => !prev);
	const clearCache = () =>
		BackOfficeService.clearCache(cancelTokenSourceRef.current.token)
			.then(() => {
				handleOpenClearCacheDialog();
				createNotification({ message: translate("dsi.platform-management.clear-cache.success") });
			})
			.catch((err) => console.error(err));
	const handleOpenEmailDatabaseIntegrityDialog = () => setOpenEmailDatabaseIntegrity((prev) => !prev);
	const emailDatabaseIntegrity = () =>
		BackOfficeService.sendEmailDatabaseIntegrity(cancelTokenSourceRef.current.token)
			.then((data) => {
				handleOpenEmailDatabaseIntegrityDialog();
				createNotification({
					message: translate("dsi.platform-management.send-mail-database-integrity.success", {
						docs: data.value,
					}),
				});
			})
			.catch((err) => console.error(err));
	const handleOpenForceUpdateMailjetDialog = () => setOpenForceUpdateMailjet((prev) => !prev);
	const forceUpdateMailjet = () =>
		BackOfficeService.forceUpdateMailjetUserList(cancelTokenSourceRef.current.token)
			.then((data) => {
				handleOpenForceUpdateMailjetDialog();
				createNotification({
					message: translate("dsi.platform-management.force-update-mailjet.success", { docs: data.value }),
				});
			})
			.catch((err) => console.error(err));
	const handleOpenForceUpdateNormDatabaseDialog = () => setOpenForceUpdateNormDatabase((prev) => !prev);
	const forceUpdateNormDatabase = () =>
		BackOfficeService.forceNormCheck(cancelTokenSourceRef.current.token)
			.then((data) => {
				handleOpenForceUpdateNormDatabaseDialog();
				createNotification({
					message: translate("dsi.platform-management.force-update-norm-database.success", {
						docs: data.value,
					}),
				});
			})
			.catch((err) => console.error(err));
	const handleOpenForceSendDailyNotifsDialog = () => setOpenForceSendDailyNotifs((prev) => !prev);
	const forceSendDailyNotifs = () =>
		BackOfficeService.forceSendDailyNotifs(cancelTokenSourceRef.current.token)
			.then(() => {
				handleOpenForceSendDailyNotifsDialog();
				createNotification({
					message: translate("dsi.platform-management.force-send-daily-notifs.success"),
				});
			})
			.catch((err) => console.error(err));

	const handleOpenForceSendWeeklyNotifsDialog = () => setOpenForceSendWeeklyNotifs((prev) => !prev);
	const forceSendWeeklyNotifs = () =>
		BackOfficeService.forceSendWeeklyNotifs(cancelTokenSourceRef.current.token)
			.then(() => {
				handleOpenForceSendWeeklyNotifsDialog();
				createNotification({
					message: translate("dsi.platform-management.force-send-weekly-notifs.success"),
				});
			})
			.catch((err) => console.error(err));

	return (
		<div className={styles.main}>
			<BlockTitle>{translate("dsi.platform-management.title")}</BlockTitle>
			<div className={styles.content}>
				<Card className={styles.card}>
					<CardContent className={styles.cardContent}>
						<span className={styles.cardContent__title}>
							{translate("dsi.platform-management.application-cache")}
						</span>
						<Divider />
						<CustomButton className={styles.button} onClick={handleOpenClearCacheDialog}>
							{translate("dsi.platform-management.clear-cache")}
						</CustomButton>
					</CardContent>
				</Card>
				<Card className={styles.card}>
					<CardContent className={styles.cardContent}>
						<span className={styles.cardContent__title}>
							{translate("dsi.platform-management.database-integrity")}
						</span>
						<Divider />
						<CustomButton className={styles.button} onClick={handleOpenEmailDatabaseIntegrityDialog}>
							{translate("dsi.platform-management.send-mail-database-integrity")}
						</CustomButton>
					</CardContent>
				</Card>
				<Card className={styles.card}>
					<CardContent className={styles.cardContent}>
						<span className={styles.cardContent__title}>
							{translate("dsi.platform-management.update-mailjet")}
						</span>
						<Divider />
						<CustomButton className={styles.button} onClick={handleOpenForceUpdateMailjetDialog}>
							{translate("dsi.platform-management.force-update-mailjet")}
						</CustomButton>
					</CardContent>
				</Card>
				<Card className={styles.card}>
					<CardContent className={styles.cardContent}>
						<span className={styles.cardContent__title}>
							{translate("dsi.platform-management.update-norm-database")}
						</span>
						<Divider />
						<CustomButton className={styles.button} onClick={handleOpenForceUpdateNormDatabaseDialog}>
							{translate("dsi.platform-management.force-update-norm-database")}
						</CustomButton>
					</CardContent>
				</Card>
				<Card className={styles.card}>
					<CardContent className={styles.cardContent}>
						<span className={styles.cardContent__title}>
							{translate("dsi.platform-management.send-daily-notifs")}
						</span>
						<Divider />
						<CustomButton className={styles.button} onClick={handleOpenForceSendDailyNotifsDialog}>
							{translate("dsi.platform-management.force-send-daily-notifs")}
						</CustomButton>
					</CardContent>
				</Card>
				<Card className={styles.card}>
					<CardContent className={styles.cardContent}>
						<span className={styles.cardContent__title}>
							{translate("dsi.platform-management.send-weekly-notifs")}
						</span>
						<Divider />
						<CustomButton className={styles.button} onClick={handleOpenForceSendWeeklyNotifsDialog}>
							{translate("dsi.platform-management.force-send-weekly-notifs")}
						</CustomButton>
					</CardContent>
				</Card>
			</div>
			<CustomDialog
				maxWidth="xs"
				open={openClearCache}
				subTitle={translate("dsi.platform-management.clear-cache.confirm")}
				title={translate("dsi.platform-management.clear-cache")}
				onClose={handleOpenClearCacheDialog}
				onSubmit={clearCache}
			/>
			<CustomDialog
				maxWidth="xs"
				open={openEmailDatabaseIntegrity}
				subTitle={translate("dsi.platform-management.send-mail-database-integrity.confirm")}
				title={translate("dsi.platform-management.send-mail-database-integrity")}
				onClose={handleOpenEmailDatabaseIntegrityDialog}
				onSubmit={emailDatabaseIntegrity}
			/>
			<CustomDialog
				maxWidth="xs"
				open={openForceUpdateMailjet}
				subTitle={translate("dsi.platform-management.force-update-mailjet.confirm")}
				title={translate("dsi.platform-management.force-update-mailjet")}
				onClose={handleOpenForceUpdateMailjetDialog}
				onSubmit={forceUpdateMailjet}
			/>
			<CustomDialog
				maxWidth="xs"
				open={openForceUpdateNormDatabase}
				subTitle={translate("dsi.platform-management.force-update-norm-database.confirm")}
				title={translate("dsi.platform-management.force-update-norm-database")}
				onClose={handleOpenForceUpdateNormDatabaseDialog}
				onSubmit={forceUpdateNormDatabase}
			/>
			<CustomDialog
				maxWidth="xs"
				open={openForceSendDailyNotifs}
				subTitle={translate("dsi.platform-management.force-send-daily-notifs.confirm")}
				title={translate("dsi.platform-management.force-send-daily-notifs")}
				onClose={handleOpenForceSendDailyNotifsDialog}
				onSubmit={forceSendDailyNotifs}
			/>
			<CustomDialog
				maxWidth="xs"
				open={openForceSendWeeklyNotifs}
				subTitle={translate("dsi.platform-management.force-send-weekly-notifs.confirm")}
				title={translate("dsi.platform-management.force-send-weekly-notifs")}
				onClose={handleOpenForceSendWeeklyNotifsDialog}
				onSubmit={forceSendWeeklyNotifs}
			/>
		</div>
	);
}
