import React, { useState } from "react";
import {
	SidePanel,
	SidePanelContent,
	SidePanelContainer,
	CustomSwitch,
	CustomAccordion,
} from "../../../../../common/components";
import { translateDate, translate } from "../../../../../common/providers";
import TeamTable from "./TeamTable";
import PartnersTable from "./PartnersTable";
import ProjectUpdater from "./ProjectUpdater";
import styles from "./ProjectDetails.module.css";
import { BackOfficeService } from "../../../../../api";
import { useApi } from "../../../../../common/hooks";

export default function ProjectDetails({
	companyId,
	onActiveFromParent,
	onChangeCompanyRole,
	onChangeDetails,
	onChangeReqDetection,
	onClose,
	onUpdateStatus,
	project = null,
	projectReqDetection = null,
	roles,
}) {
	const { call: getUsersOfProjects } = useApi(BackOfficeService.getUsersOfProjects);
	const { call: getPartnersOfProjects } = useApi(BackOfficeService.getPartnersOfProjects);
	const [users, setUsers] = useState();
	const [previousCompanyIdForUsers, setPreviousCompanyIdForUsers] = useState(null);
	const [previousCompanyIdForPartners, setPreviousCompanyIdForPartners] = useState(null);
	const [previousProjectIdForUser, setPreviousProjectIdForUser] = useState(null);
	const [previousProjectIdForPartner, setPreviousProjectIdForPartner] = useState(null);

	const [partners, setPartners] = useState();

	const handleUserRowClick = () => {
		const projectId = project?.id;

		if (companyId !== previousCompanyIdForUsers || projectId !== previousProjectIdForUser) {
			setPreviousCompanyIdForUsers(companyId);
			setPreviousProjectIdForUser(project?.id);
			getUsersOfProjects({ companyId, projectId })
				.then((data) => {
					setUsers(data);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	const handlePartnerRowClick = () => {
		const projectId = project?.id;
		if (companyId !== previousCompanyIdForPartners || projectId !== previousProjectIdForPartner) {
			setPreviousCompanyIdForPartners(companyId);
			setPreviousProjectIdForPartner(project?.id);
			getPartnersOfProjects({ projectId })
				.then((data) => {
					setPartners(data);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	return (
		<SidePanel open={!!project} size={70} onActive={onActiveFromParent} onClose={onClose}>
			{({ onActive }) => (
				<SidePanelContent title={translate("dsi.project-details.title")} onActive={onActive} onClose={onClose}>
					<SidePanelContainer>
						{(project && (
							<div>
								<div className={styles.title}>
									<span className={styles.name}>{project.name}</span>
									<span className={styles.date}>
										{translate("dsi.project-details.created", {
											date: translateDate(project.created),
										})}
									</span>
								</div>
								<CustomAccordion
									defaultOpen
									customAccordionClass={styles.customAccordionClass}
									customAccordionDetailsClass={styles.customAccordionDetailsClass}
									header={translate("dsi.project-details.details")}
								>
									<ProjectUpdater project={project} onChangeDetails={onChangeDetails} />
								</CustomAccordion>
								<CustomAccordion
									customAccordionClass={styles.customAccordionClass}
									header={translate("dsi.project-details.partners")}
									onExpand={() => {
										handlePartnerRowClick();
									}}
								>
									<PartnersTable
										companies={partners}
										roles={roles}
										onChangeCompanyRole={onChangeCompanyRole}
									/>
								</CustomAccordion>
								<CustomAccordion
									customAccordionClass={styles.customAccordionClass}
									header={translate("dsi.project-details.team")}
									onExpand={() => {
										handleUserRowClick();
									}}
								>
									<TeamTable
										companyId={companyId}
										projectId={project?.id}
										users={users}
										onUpdateStatus={onUpdateStatus}
									/>
								</CustomAccordion>
								<div className={styles.reqDetect}>
									<span className={styles.reqDetect__text}>
										{translate("dsi.project-details.req-detection")}
									</span>
									<span>{translate("dsi.project-details.req-detection-off")}</span>
									<CustomSwitch
										checked={projectReqDetection}
										onChange={() => onChangeReqDetection(project)}
									/>
									<span>{translate("dsi.project-details.req-detection-on")}</span>
								</div>
							</div>
						)) || (
							<div className={styles.none}>
								<span>{translate("dsi.project-details.empty-state.project.none-selected")}</span>
							</div>
						)}
					</SidePanelContainer>
				</SidePanelContent>
			)}
		</SidePanel>
	);
}
