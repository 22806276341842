import React, { createRef } from "react";
import { batch, connect } from "react-redux";
import { AnalyticsProvider, Flags, SegFlags, translate } from "../../common/providers";
import { debounce, isDeepEqual } from "../../common/utils";
import {
	CustomButton,
	CustomTooltip,
	icon,
	SidePanel,
	ViewBanner,
	ViewTabPanel,
	ViewTabs,
} from "../../common/components";
import { exportView } from "../../navigation";
import { Norms, PendingDetections } from "./tabs";
import { ApiService } from "../../api";
import styles from "./NormsCenter.module.css";
import { AddNorm } from "./components";
import {
	addCustomNorm,
	addNormToProject,
	changeNormFavoriteStatus,
	getCounters,
	getNormsInProject,
	getPendingDetections,
	getSortingKeys,
	getTrustLevels,
	setDir,
	setFilters,
	setLimit,
	setNormsInProject,
	setOrderBy,
	setPage,
	setPendingDetections,
	setPendingStatus,
	setProjectId,
	setTotalNormsResult,
	setTotalPendingResult,
	updatePendingDetection,
} from "./slice/normsCenterSlice";

const HASH_TAB_NORMS = "#norms";
const HASH_TAB_PD = "#pending-detections";

const mapStateToProps = ({ normsCenter }) => ({
	displayResults: normsCenter.displayResults,
	filters: normsCenter.filters,
	limit: normsCenter.limit,
	page: normsCenter.page,
	orderBy: normsCenter.orderBy,
	dir: normsCenter.dir,
	pendingStatus: normsCenter.pendingStatus,
	tempNormsInProject: normsCenter.tempNormsInProject,
	tempPendingDetections: normsCenter.tempPendingDetections,
	tempTotalNormsResult: normsCenter.tempTotalNormsResult,
	tempTotalPendingResult: normsCenter.tempTotalPendingResult,
	totalNormsInProject: normsCenter.totalNormsInProject,
	totalPendingDetections: normsCenter.totalPendingDetections,
});

const mapDispatchToProps = (dispatch) => ({
	onSetFilters: (filters) => dispatch(setFilters(filters)),
	onSetLimit: (limit) => dispatch(setLimit(limit)),
	onSetNormsInProject: (norms) => dispatch(setNormsInProject(norms)),
	onSetPage: (page) => dispatch(setPage(page)),
	onSetDir: (dir) => dispatch(setDir(dir)),
	onSetOrderBy: (orderBy) => dispatch(setOrderBy(orderBy)),
	onSetProjectId: (id) => dispatch(setProjectId(id)),
	onSetPendingDetections: (norms) => dispatch(setPendingDetections(norms)),
	onSetPendingStatus: (status) => dispatch(setPendingStatus(status)),
	onSetTotalNormsResult: (total) => dispatch(setTotalNormsResult(total)),
	onSetTotalPendingResult: (total) => dispatch(setTotalPendingResult(total)),
	onUpdatePendingDetection: ({ ids, update }) => dispatch(updatePendingDetection({ ids, update })),
	onAddCustomNorm: ({ payload, token }) => dispatch(addCustomNorm({ payload, token })),
	onAddNormToProject: ({ normId, token }) => dispatch(addNormToProject({ normId, token })),
	onChangeNormFavoriteStatus: ({ normId, isFavorite, token }) =>
		dispatch(changeNormFavoriteStatus({ normId, isFavorite, token })),
	onGetCounters: (token) => dispatch(getCounters(token)),
	onGetNormsInProject: ({ token, page, limit, filters, displayResults, orderBy, dir }) =>
		dispatch(getNormsInProject({ token, page, limit, filters, displayResults, orderBy, dir })),
	onGetPendingDetections: ({ token, pendingStatus, page, limit, filters, orderBy, dir, displayResults }) =>
		dispatch(getPendingDetections({ token, pendingStatus, page, limit, filters, orderBy, dir, displayResults })),
	onGetSortingKeys: (token) => dispatch(getSortingKeys(token)),
	onGetTrustLevels: (token) => dispatch(getTrustLevels(token)),
});

class NormsCenter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: window.location.hash === HASH_TAB_PD ? 1 : 0,
			openAddNormPanel: false,
			tabs: [
				{
					label: translate("norms-center.tab.norms", { count: 0 }),
					iconName: icon.faShieldAlt,
				},
				{
					label: translate("norms-center.tab.detections", { count: 0 }),
					iconName: icon.faClock,
				},
			],
			displayNormsResults: true,
			displayPendingResults: true,
		};
		this.cancelTokenSource = ApiService.getCancelTokenSource();
		this.updateCancelTokenSource = ApiService.getCancelTokenSource();
		this.normsContainerRef = createRef();
	}

	componentDidMount() {
		const documentTitle = translate("norms.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Project norms" });
		const { selectedTab } = this.state;
		const {
			limit,
			page,
			onGetTrustLevels,
			onGetCounters,
			onGetSortingKeys,
			onSetProjectId,
			totalNormsInProject,
			totalPendingDetections,
			params,
		} = this.props;
		const projectId = parseInt(params.projectId, 10);
		batch(() => {
			onSetProjectId(projectId);
			onGetCounters(this.cancelTokenSource.token);
			onGetTrustLevels(this.cancelTokenSource.token);
			onGetSortingKeys(this.cancelTokenSource.token);
			if (selectedTab === 0) {
				window.location.hash = HASH_TAB_NORMS;
				this.fetchNormsInProject({ limit, page }, this.updateCancelTokenSource.token);
			} else if (selectedTab === 1) {
				window.location.hash = HASH_TAB_PD;
				this.fetchPendingDetections({ limit, page }, this.updateCancelTokenSource.token);
			}
		});
		this.handleUpdateTabs({ totalNormsInProject, totalPendingDetections });
	}

	componentDidUpdate(prevProps, prevState) {
		const { selectedTab, displayNormsResults, displayPendingResults } = this.state;
		const {
			tempPendingDetections,
			tempTotalNormsResult,
			tempTotalPendingResult,
			totalNormsInProject,
			tempNormsInProject,
			totalPendingDetections,
			limit,
			page,
			orderBy,
			dir,
			filters,
			pendingStatus,
			onSetNormsInProject,
			onSetTotalNormsResult,
			onSetTotalPendingResult,
			onSetPendingDetections,
		} = this.props;
		if (
			prevProps.totalNormsInProject !== totalNormsInProject ||
			prevProps.totalPendingDetections !== totalPendingDetections
		) {
			this.handleUpdateTabs({ totalNormsInProject, totalPendingDetections });
		}
		if (
			prevState.selectedTab !== selectedTab ||
			prevProps.limit !== limit ||
			prevProps.page !== page ||
			prevProps.orderBy !== orderBy ||
			prevProps.dir !== dir ||
			(!!prevProps.filters && filters && !isDeepEqual(prevProps.filters, filters)) ||
			(!prevProps.filters && filters) ||
			(!!prevProps.filters && !filters) ||
			prevProps.pendingStatus !== pendingStatus
		) {
			ApiService.cancelTokens(this.updateCancelTokenSource);
			this.updateCancelTokenSource = ApiService.getCancelTokenSource();
			if (selectedTab === 0) {
				this.fetchNormsInProject({ limit, page, filters }, this.updateCancelTokenSource.token);
			} else if (selectedTab === 1) {
				this.fetchPendingDetections(
					{ limit, page, filters, status: pendingStatus },
					this.updateCancelTokenSource.token
				);
			}
		} else if (displayNormsResults && !prevState.displayNormsResults) {
			batch(() => {
				onSetNormsInProject(tempNormsInProject);
				onSetTotalNormsResult(tempTotalNormsResult);
			});
		} else if (displayPendingResults && !prevState.displayPendingResults) {
			batch(() => {
				onSetPendingDetections(tempPendingDetections);
				onSetTotalPendingResult(tempTotalPendingResult);
			});
		}
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
		ApiService.cancelTokens(this.updateCancelTokenSource);
	}

	// API
	fetchNormsInProject = ({ limit, page, filters = null }, cancelToken) => {
		const { displayNormsResults } = this.state;
		const { onGetNormsInProject, orderBy, dir } = this.props;
		onGetNormsInProject({
			token: cancelToken,
			limit,
			page,
			filters,
			displayResults: displayNormsResults,
			orderBy,
			dir,
		});
	};

	refreshNormsInProject = () => {
		const { limit, page, filters } = this.props;
		this.fetchNormsInProject({ limit, page, filters }, this.cancelTokenSource.token);
	};

	fetchPendingDetections = ({ limit, page, filters = null }, cancelToken) => {
		const { displayPendingResults } = this.state;
		const { pendingStatus, onGetPendingDetections, orderBy, dir } = this.props;
		const scrollPosition = this.normsContainerRef.current.scrollTop;
		onGetPendingDetections({
			token: cancelToken,
			pendingStatus,
			limit,
			page,
			filters,
			orderBy,
			dir,
			displayResults: displayPendingResults,
		}).then(() => this.normsContainerRef.current.scrollTo(0, scrollPosition));
	};

	refreshPendingDetections = () => {
		const { limit, page, filters, onGetCounters } = this.props;
		this.fetchPendingDetections({ limit, page, filters }, this.updateCancelTokenSource.token);
		onGetCounters(this.updateCancelTokenSource.token);
	};

	addNormToProject = (normId) => {
		const { onAddNormToProject } = this.props;
		onAddNormToProject({ normId, token: this.cancelTokenSource.token })
			.then(this.refreshNormsInProject)
			.catch((err) => console.error(err));
	};

	addCustomNorm = (payload) => {
		const { onAddCustomNorm } = this.props;
		onAddCustomNorm({ payload, token: this.cancelTokenSource.token })
			.then(this.refreshNormsInProject)
			.catch((err) => {
				console.error(err);
			});
	};
	// END API

	handleUpdatePendingNorm = ({ ids, update }) => {
		const { onUpdatePendingDetection } = this.props;
		onUpdatePendingDetection({ ids, update });
	};

	handleChangeTab = (event, selectedTab) => {
		const { onSetFilters, onSetPage, onSetDir, onSetOrderBy } = this.props;
		if (selectedTab === 0) {
			window.location.hash = HASH_TAB_NORMS;
		} else if (selectedTab === 1) {
			window.location.hash = HASH_TAB_PD;
		}
		batch(() => {
			onSetOrderBy("");
			onSetDir("");
			onSetFilters(null);
			onSetPage(0);
		});
		this.setState({ selectedTab });
	};

	handleUpdateTabs = ({ totalNormsInProject, totalPendingDetections }) => {
		this.setState({
			tabs: [
				{
					label: translate("norms-center.tab.norms", { count: totalNormsInProject }),
					iconName: icon.faShieldAlt,
				},
				{
					label: translate("norms-center.tab.detections", { count: totalPendingDetections }),
					iconName: icon.faClock,
				},
			],
		});
	};

	handleChangeFavoriteStatus = ({ normId, isFavorite }) => {
		const { onChangeNormFavoriteStatus } = this.props;
		onChangeNormFavoriteStatus({ normId, isFavorite, token: this.cancelTokenSource.token });
	};

	handleSearchNorms = debounce(({ name, documents, newVersion, favorite, status }, displayResults = false) => {
		const { onSetFilters, onSetPage } = this.props;
		const filters = {};
		if (name) {
			filters.name = name;
		}
		if (Array.isArray(documents) && documents.length > 0) {
			filters.documents = documents.join(",");
		}
		if (newVersion) {
			filters.newVersion = newVersion;
		}
		if (favorite) {
			filters.favorite = favorite;
		}
		if (status) {
			filters.status = status;
		}
		batch(() => {
			this.setState({ displayNormsResults: displayResults });
			onSetFilters(filters);
			onSetPage(0);
		});
	}, 250);

	handleSearchPendingDetections = debounce(({ textDetected, documents, trust, norm }, displayResults) => {
		const { onSetFilters, onSetPage } = this.props;
		const filters = {};
		if (textDetected) {
			filters.textDetected = textDetected;
		}
		if (Array.isArray(documents) && documents.length > 0) {
			filters.documents = documents.join(",");
		}
		if (Array.isArray(trust) && trust.length > 0) {
			filters.trust = trust.join(",");
		}
		if (norm && norm.id) {
			filters.normId = norm.id;
		}
		batch(() => {
			this.setState({ displayPendingResults: displayResults });
			onSetFilters(Object.keys(filters).length !== 0 ? filters : null);
			onSetPage(0);
		});
	}, 250);

	handleChangePendingStatus = () => {
		const { pendingStatus, onSetPendingStatus } = this.props;
		onSetPendingStatus(pendingStatus === "PENDING" ? "TRASH" : "PENDING");
	};

	handleChangePage = (_, page) => {
		const { onSetPage } = this.props;
		onSetPage(page || 0);
	};

	handleChangeLimitPerPage = (e) => {
		const { onSetLimit, onSetPage } = this.props;
		onSetLimit(e.target?.value || 25);
		onSetPage(0);
	};

	handleOpenAddNormPanel = () => this.setState({ openAddNormPanel: true });

	handleCloseAddNormPanel = () => this.setState({ openAddNormPanel: false });

	handleAddNorm = ({ id }) => {
		if (!id) {
			return;
		}
		this.addNormToProject(id);
		this.handleCloseAddNormPanel();
	};

	handleCreateNorm = (payload) => {
		if (!payload) {
			return;
		}
		this.addCustomNorm(payload);
		this.handleCloseAddNormPanel();
	};

	handleMoveToPendingDetections = (row) => {
		const { onSetFilters } = this.props;
		this.handleChangeTab(null, 1);
		onSetFilters({ norm: { id: row.normId, name: row.name } });
	};

	render() {
		const { selectedTab, tabs, openAddNormPanel } = this.state;
		return (
			<div ref={this.normsContainerRef} className={styles.normsCenter}>
				<ViewBanner
					options={[
						<CustomTooltip title={translate("norms-center.banner.btn.add-norm.tooltip")}>
							<CustomButton color="primary" variant="contained" onClick={this.handleOpenAddNormPanel}>
								{translate("norms-center.banner.btn.add-norm")}
							</CustomButton>
						</CustomTooltip>,
					]}
					titles={[
						{ title: translate("navigation:project.follow-up"), key: "followUp" },
						{ title: translate("navigation:project.project-norms") },
					]}
				/>
				<ViewTabs
					aria-label="norms-center-tabs"
					selectedTab={selectedTab}
					tabs={tabs}
					onChange={this.handleChangeTab}
				/>
				<ViewTabPanel index={0} value={selectedTab}>
					<div className={styles.normsCenter__panel}>
						<Norms
							onChangeFavoriteStatus={this.handleChangeFavoriteStatus}
							onMoveToPendingDetections={this.handleMoveToPendingDetections}
							onOpenAddNormPanel={this.handleOpenAddNormPanel}
							onPageChange={this.handleChangePage}
							onRowsPerPageChange={this.handleChangeLimitPerPage}
							onSearch={this.handleSearchNorms}
						/>
					</div>
				</ViewTabPanel>
				<ViewTabPanel index={1} value={selectedTab}>
					<div className={styles.normsCenter__panel}>
						<PendingDetections
							onChangePendingStatus={this.handleChangePendingStatus}
							onDiscardNorm={this.handleDiscardPendingNorm}
							onPageChange={this.handleChangePage}
							onRecoverNorm={this.handleRecoverPendingNorm}
							onRefreshPending={this.refreshPendingDetections}
							onRowsPerPageChange={this.handleChangeLimitPerPage}
							onSearch={this.handleSearchPendingDetections}
							onUpdatePendingNorm={this.handleUpdatePendingNorm}
							onValidateNorm={this.handleValidatePendingNorm}
						/>
					</div>
				</ViewTabPanel>
				<SidePanel open={openAddNormPanel} size={75} onClose={this.handleCloseAddNormPanel}>
					{({ onActive }) => (
						<AddNorm
							onActive={onActive}
							onAddNorm={this.handleAddNorm}
							onClose={this.handleCloseAddNormPanel}
							onCreateNorm={this.handleCreateNorm}
						/>
					)}
				</SidePanel>
			</div>
		);
	}
}

export { default as normsCenterSlice } from "./slice/normsCenterSlice";
export default exportView({
	path: "/projects/:projectId/follow-up/project-standards",
	localesPath: "/norms-center/locales",
	component: connect(mapStateToProps, mapDispatchToProps)(NormsCenter),
	flag: Flags.NORMS,
	segFlag: SegFlags.NORMS,
});
