import React from "react";
import { UserDetails, DocumentDetails } from "./components";
import { ApiService } from "../../../api";

class Details extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			width: window.innerWidth,
		};
		this.updateCancelTokenSource = ApiService.getCancelTokenSource();
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleWindowResize);
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.updateCancelTokenSource);
		window.removeEventListener("resize", this.handleWindowResize);
	}

	handleWindowResize() {
		const { width } = this.state;
		const winddowWidth = window.innerWidth;
		if ((width <= 1020 && winddowWidth > 1020) || (width > 1020 && winddowWidth <= 1020)) {
			this.setState({ width: winddowWidth });
		}
	}

	render() {
		const { width } = this.state;
		const {
			onSelectUser,
			dataSelectedDocument,
			onCheck,
			onSelectedAllDocs,
			onReset,
			rolesSelectedUser,
			SelectAllDocsToDelete,
			toDelete,
			onUpdateTabDocToDelete,
			selectedDocument,
			onSetNewDocPrecedence,
			users,
			roles,
			selectedUser,
			dataSelectedUser,
			onUpdateUsersRoles,
			hideArchives,
			projectId,
			selectedCompany,
			selectedDocDetails,
			selectedUserDetails,
		} = this.props;
		return (
			<>
				{selectedUser !== "" && dataSelectedUser && (
					<UserDetails
						dataSelectedUser={dataSelectedUser}
						hideArchives={hideArchives}
						projectId={projectId}
						rolesSelectedUser={rolesSelectedUser}
						SelectAllDocsToDelete={SelectAllDocsToDelete}
						selectedCompany={selectedCompany}
						selectedUserDetails={selectedUserDetails}
						toDelete={toDelete}
						width={width}
						onCheck={onCheck}
						onReset={onReset}
						onSelectedAllDocs={onSelectedAllDocs}
						onUpdateTabDocToDelete={onUpdateTabDocToDelete}
						onUpdateUsersRoles={onUpdateUsersRoles}
					/>
				)}
				{selectedDocument && selectedDocument !== "" && (
					<DocumentDetails
						dataSelectedDocument={dataSelectedDocument}
						projectId={projectId}
						roles={roles}
						selectedCompany={selectedCompany}
						selectedDocDetails={selectedDocDetails}
						selectedDocument={selectedDocument}
						users={users}
						onSelectUser={onSelectUser}
						onSetNewDocPrecedence={onSetNewDocPrecedence}
						onUpdateUsersRoles={onUpdateUsersRoles}
					/>
				)}
			</>
		);
	}
}

export default Details;
