import React from "react";
import {
	CustomButton,
	CustomIconButton,
	GenericDialog,
	GenericDialogContent,
	GenericDialogFooter,
	GenericDialogHeader,
	icon,
} from "../../../../../common/components";
import styles from "./UpdateQaDialog.module.css";
import { translate } from "../../../../../common/providers";

const CancelDialog = ({ close, onCancel, open }) => (
	<GenericDialog disableBackdropClick fullWidth maxWidth="md" open={open} onClose={close}>
		<GenericDialogHeader>
			<div className={styles.dialog__title}>
				<span className={styles["dialog__title-text"]}>
					{translate("qa-journal.qa-details.cancel-dialog.title")}
				</span>
				<CustomIconButton icon={icon.faTimes} onClick={close} />
			</div>
		</GenericDialogHeader>
		<GenericDialogContent dividers className={styles.dialog__content}>
			<span className={styles["margin-bottom"]}>{translate("qa-journal.qa-details.cancel-dialog.content")}</span>
		</GenericDialogContent>
		<GenericDialogFooter>
			<CustomButton color="primary" variant="outlined" onClick={onCancel}>
				{translate("qa-journal.qa-details.cancel-dialog.button.confirm-cancel")}
			</CustomButton>
			<CustomButton color="primary" variant="contained" onClick={close}>
				{translate("qa-journal.qa-details.cancel-dialog.button.back-edition")}
			</CustomButton>
		</GenericDialogFooter>
	</GenericDialog>
);
export default CancelDialog;
