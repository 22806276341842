export function convertSize(octets) {
	if (octets >= 1e9) {
		return `${(octets / 1e9).toFixed(2)}Go`;
	}
	if (octets >= 1e6) {
		return `${(octets / 1e6).toFixed(2)}Mo`;
	}
	if (octets >= 1e3) {
		return `${(octets / 1e3).toFixed(2)}Ko`;
	}
	return `${octets}octets`;
}
