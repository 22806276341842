import React, { useState } from "react";
import { useSelector } from "react-redux";
import UsersListTable from "./UsersListTable";
import { BlockTitle, CustomButton, icon, SearchInput } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import styles from "./UsersList.module.css";

function filterUsers(user, search) {
	return (
		user?.firstName?.toLowerCase().includes(search.toLowerCase()) ||
		user?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
		user?.displayName?.toLowerCase().includes(search.toLowerCase()) ||
		user?.email?.toLowerCase().includes(search.toLowerCase())
	);
}
export default function UsersList({
	isAdding,
	onSetIsAdding,
	users = [],
	selectedUser,
	onClickRow,
	onChangeLock,
	onChangeRole,
	COMPANY_ROLES = [],
}) {
	const [search, setSearch] = useState("");
	const loggedUser = useSelector(({ context }) => context.user);
	const handleChange = (e) => {
		setSearch(e.target.value);
	};
	const handleClearSearch = () => {
		setSearch("");
	};
	return (
		<>
			<BlockTitle>{translate("admin.user-list.title")}</BlockTitle>
			<div className={styles.header}>
				<SearchInput
					label={translate("common:search-label.user")}
					value={search}
					onChange={handleChange}
					onClearSearch={handleClearSearch}
				/>
				<span className={styles.userCount}>
					{translate("common:search-count.user", {
						count: users.filter((user) => filterUsers(user, search)).length,
					})}
				</span>
				<CustomButton
					className={styles.addButton}
					color="primary"
					data-testid="add.user.button"
					size="md"
					startIcon={icon.faPlus}
					variant="contained"
					onClick={isAdding ? () => onSetIsAdding(false) : () => onSetIsAdding(true)}
				>
					{translate("admin.user-list.add-user.button")}
				</CustomButton>
			</div>
			<UsersListTable
				COMPANY_ROLES={COMPANY_ROLES}
				loggedUser={loggedUser}
				selectedUser={selectedUser}
				users={(users || []).filter((user) => filterUsers(user, search))}
				onChangeLock={onChangeLock}
				onChangeRole={onChangeRole}
				onClickRow={onClickRow}
			/>
		</>
	);
}
