import React from "react";
import { CircularProgress, Checkbox } from "@mui/material";
import styles from "./QAList.module.css";
import QAListRow from "./components/QAListRow";
import { CustomTooltip, InfiniteList } from "../../../../common/components";
import { translate } from "../../../../common/providers";

export default function QAList({
	allChecked,
	checkedQa,
	hasMore = false,
	isLoadingQa,
	questionsAnswers = [],
	selectedQa,
	totalElements = 0,
	totalQaInProject,
	onCheckAll,
	onCheckQa,
	onLoadMore,
	onSelectQa,
	onDelete,
}) {
	const isRowLoaded = ({ index }) => !hasMore || index < questionsAnswers.length;
	const rowRenderer = ({ key, index, style }) => {
		const qa = questionsAnswers[index];
		return (
			<QAListRow
				key={key}
				checked={!!allChecked || checkedQa.some((q) => q.id === qa.id)}
				qa={qa}
				selected={selectedQa && selectedQa.id === qa.id}
				style={style}
				onClick={() => onCheckQa(qa.id)}
				onDelete={onDelete}
				onSelect={() => onSelectQa(qa.id)}
			/>
		);
	};
	const noRowsRenderer = () =>
		questionsAnswers.length === 0 && isLoadingQa ? (
			<div className={styles.loader}>
				<CircularProgress />
			</div>
		) : (
			translate("qa-journal.qa-journal.empty-state")
		);

	const handleLoadMore = () => onLoadMore();
	return (
		<>
			<div className={styles["qa-list"]}>
				<div className={styles["qa-list__counter"]}>
					<CustomTooltip title={translate("qa-journal.qa-list.btn.select-all")}>
						<Checkbox checked={allChecked} color="primary" size="small" onClick={onCheckAll} />
					</CustomTooltip>
					{translate("qa-journal.qa-list.result-counter", { totalElements, totalQaInProject })}
				</div>
				<div className={styles["infinite-list-container"]}>
					<InfiniteList
						className={styles["qa-list__infinite-list"]}
						currentTotalElements={questionsAnswers.length}
						isRowLoaded={isRowLoaded}
						loadMoreRows={handleLoadMore}
						noRowsRenderer={noRowsRenderer}
						rowHeight={90}
						rowRenderer={rowRenderer}
						threshold={45}
						totalElements={totalElements}
					/>
				</div>
			</div>
		</>
	);
}
