import { isNonEmptyArray } from "./array-utils";
import { searchInString } from "./function-utils";

export const getCheckedCategoriesCount = (list, checkedIds) => {
	let count = 0;
	if (!Array.isArray(list)) {
		return count;
	}
	list.forEach((element) => {
		if (checkedIds.includes(element.id)) {
			count++;
		}
		count += getCheckedCategoriesCount(element.subThematic, checkedIds);
	});
	return count;
};
export const countTotalCategories = (categories) => {
	if (!Array.isArray(categories)) {
		return 0;
	}
	let countSub = 0;
	categories.forEach((cat) => {
		countSub += countTotalCategories(cat.subThematic);
	});
	return categories.length + countSub;
};
export const getRecursiveParentsIds = (list, idToFind, parentList) => {
	let progress = { found: false, parentList: [...parentList] };
	if (!Array.isArray(list)) {
		return progress;
	}
	list.forEach((element) => {
		if (!progress.found) {
			if (element.id === idToFind) {
				progress.found = true;
				progress.parentList.push(element.id);
			} else {
				progress = getRecursiveParentsIds(element.subThematic, idToFind, progress.parentList);
				if (progress.found) {
					progress.parentList.push(element.id);
				}
			}
		}
	});
	return progress;
};
export const getParentsIdsList = (categoryTree, category) => {
	if (!category || !category.id) {
		return [];
	}
	return getRecursiveParentsIds(categoryTree, category.id, []).parentList.flat(1);
};

export function filteredCategoryTree(rows, search) {
	const newTree = [];
	rows.forEach((r) => {
		const filteredChildren = Array.isArray(r.subThematic) ? filteredCategoryTree(r.subThematic, search) : [];
		if (searchInString(r.name, search) || filteredChildren.length > 0) {
			newTree.push({ ...r, subThematic: filteredChildren });
		}
	});
	return newTree;
}

export function initThematicTree(arr, total) {
	const newTree = [...arr];
	function recurse(obj) {
		obj.checked = total === obj.contentsCount;
		obj.visited = false;
		if (isNonEmptyArray(obj.subThematic)) {
			obj.subThematic.forEach((row) => {
				recurse(row);
			});
		}
	}
	newTree.forEach((row) => {
		recurse(row);
	});
	return newTree;
}

export const updateThematicTree = (arr, item) => {
	const newTree = [...arr];
	function recurse(obj, parent, parentChecked) {
		let isUpdated = parent || false;
		if (item.id === obj.id) {
			obj.visited = true;
			isUpdated = obj.checked;
			parentChecked = !obj.checked; // eslint-disable-line no-param-reassign
			obj.checked = !obj.checked;
		}
		if (item.ancestors.some((x) => obj.id === x)) {
			obj.visited = true;
			obj.checked = true;
		}
		if (parent) {
			obj.visited = true;
			obj.checked = parentChecked;
		}
		if (Array.isArray(obj.subThematic) && obj.subThematic.length > 0) {
			obj.subThematic.forEach((row) => {
				recurse(row, isUpdated, parentChecked);
			});
		}
	}
	newTree.forEach((row) => {
		recurse(row, false, false);
	});
	return newTree;
};
