import { MenuList } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HorizontalDivider } from "../../../common/components";
import { MenuListTitle, MenuItemRelog, MenuItemSelectable } from "..";
import { checkValidity } from "../..";
import { translate } from "../../../common/providers";

const CompanyMenu = React.forwardRef(({ companies, company, homeMenus, onHotRelog, onIsSelected, onSelect }, ref) => {
	const [menus, setMenus] = useState([]);
	const [homeMenusExists, setHomeMenuExists] = useState(false);
	const [hasCompaniesList, setHasCompaniesList] = useState(false);
	useEffect(() => {
		if (Array.isArray(homeMenus) && homeMenus.length > 0) {
			const tempMenus = homeMenus.filter((m) => checkValidity(m));
			setMenus(tempMenus);
			setHomeMenuExists(tempMenus.length > 0);
		} else {
			setHomeMenuExists(false);
		}
	}, [homeMenus]);

	useEffect(() => {
		if (Array.isArray(companies) && companies.filter((c) => c.id !== company.id).length > 0) {
			setHasCompaniesList(true);
		} else {
			setHasCompaniesList(false);
		}
	}, [companies, company]);

	return (
		<>
			{homeMenusExists && (
				<>
					<MenuListTitle menuItem={translate("navigation:home.configuration")} />
					<MenuList ref={ref}>
						{menus.map((menuItem) => (
							<MenuItemSelectable
								key={menuItem.name}
								menuItem={menuItem}
								onIsSelected={onIsSelected}
								onSelect={onSelect}
							/>
						))}
					</MenuList>
				</>
			)}
			{hasCompaniesList && (
				<>
					{homeMenusExists && <HorizontalDivider />}
					<MenuListTitle menuItem={translate("navigation:home.change-company")} />
					<MenuList data-testid="companyMenu.companyList">
						{companies.map((relog) => (
							<MenuItemRelog key={relog.id} company={company} menuItem={relog} onHotRelog={onHotRelog} />
						))}
					</MenuList>
				</>
			)}
		</>
	);
});
export default CompanyMenu;
