import { MenuList } from "@mui/material";
import React, { useMemo } from "react";
import { MenuListTitle, MenuItemSelectable } from "..";
import { checkValidity } from "../..";
import { HorizontalDivider } from "../../../common/components";
import { translate } from "../../../common/providers";

const switchProject = { name: "navigation:project.switch-project", to: "/" };

const ProjectManagementMenu = React.forwardRef(({ projectMenus, onIsSelected, onSelect }, ref) => {
	const menus = useMemo(() => projectMenus.filter((m) => checkValidity(m)), [projectMenus]);
	const projectMenusExists = useMemo(() => menus.length > 0, [menus]);
	return (
		<>
			{projectMenusExists && (
				<>
					<MenuListTitle menuItem={translate("navigation:home.configuration")} />
					<MenuList ref={ref}>
						{menus.map((menuItem) => (
							<MenuItemSelectable
								key={menuItem.name}
								menuItem={menuItem}
								onIsSelected={onIsSelected}
								onSelect={onSelect}
							/>
						))}
					</MenuList>
				</>
			)}
			<HorizontalDivider />
			<MenuList data-testid="companyMenu.companyList">
				<MenuItemSelectable key={switchProject.name} menuItem={switchProject} onSelect={onSelect} />
			</MenuList>
		</>
	);
});
export default ProjectManagementMenu;
