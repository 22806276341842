import React from "react";
import { useSelector } from "react-redux";
import { BlockTitle, CustomButton, icon } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import UsersListTable from "./users-list-table/UsersListTable";
import styles from "./UsersList.module.css";

export default function UsersList({
	COMPANY_ROLES = [],
	onChangeLock,
	onChangeRole,
	onClickRow,
	onRegenPassword,
	selectedUser,
	setIsAdding,
	users = [],
}) {
	const loggedUser = useSelector(({ context }) => context.user);
	return (
		<>
			<BlockTitle>{translate("dsi.user-list.title")}</BlockTitle>
			<CustomButton
				className={styles.addUserButton}
				data-testid="add.user.button"
				startIcon={icon.faPlus}
				variant="contained"
				onClick={() => setIsAdding(true)}
			>
				{translate("dsi.dsi-user.add-user")}
			</CustomButton>
			<UsersListTable
				COMPANY_ROLES={COMPANY_ROLES}
				loggedUser={loggedUser}
				selectedUser={selectedUser}
				users={users}
				onChangeLock={onChangeLock}
				onChangeRole={onChangeRole}
				onRegenPassword={onRegenPassword}
				onSelectRow={onClickRow}
			/>
		</>
	);
}
