import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

import { CustomDialog } from "../../../../../../common/components";
import AdditionalDetailDialog from "./AdditionalDetailDialog";

export default function ParametersDialog({
	finalParameters,
	isParameterIncluded,
	isUnconditional,
	onChangeClientId,
	onChangeEmitter,
	onCloseReq,
	onFormatParameters,
	onKeyDownGrid,
	onSelect,
	onSubmitParameters,
	onUpdateParameter,
	receiveParamatersOnSelected,
}) {
	const selectedBlock = useSelector(({ srDocument }) => srDocument.selectedBlock);
	const [selected, setSelected] = useState(null);
	const newParameter = useRef(null);
	const handleSelected = (newSelected) => {
		setSelected(newSelected);
	};
	const handleNewParameter = (parameter) => {
		newParameter.current = parameter;
	};
	const handleConfirmEdit = () => {
		let payload;
		if (
			(newParameter.current &&
				newParameter.current.name &&
				newParameter.current.name?.length > 1 &&
				(selected.name !== newParameter.current.name || !selected.name)) ||
			(selected.name &&
				newParameter.current.name?.length > 1 &&
				(selected.value || selected.value !== newParameter.current.value))
		) {
			if (newParameter.current.name && newParameter.current.value && newParameter.current.value.length > 0) {
				if (
					!isUnconditional(newParameter.current.name) &&
					(!isParameterIncluded(newParameter.current.name) || newParameter.current.name === selected.name)
				) {
					if (selected.name && selected.value) {
						payload = onFormatParameters({
							newParam: { [newParameter.current.name]: newParameter.current.value || "" },
							oldParam: { [selected.name]: selected.value },
						});
					} else {
						payload = onFormatParameters({
							newParam: { [newParameter.current.name]: newParameter.current.value || "" },
						});
					}
				}
			}
		}
		onSubmitParameters(payload);
	};

	return (
		<CustomDialog
			displayCloseCross
			isForm
			open={!!selectedBlock}
			title={`R${selectedBlock?.informationId}`}
			onClose={onCloseReq}
			onSubmit={handleConfirmEdit}
		>
			<AdditionalDetailDialog
				finalParameters={finalParameters}
				isParameterIncluded={isParameterIncluded}
				isUnconditional={isUnconditional}
				receiveParamatersOnSelected={receiveParamatersOnSelected}
				onChangeClientId={onChangeClientId}
				onChangeEmitter={onChangeEmitter}
				onKeyDownGrid={onKeyDownGrid}
				onNewParameter={handleNewParameter}
				onSelect={onSelect}
				onSelected={handleSelected}
				onUpdateParameter={onUpdateParameter}
			/>
		</CustomDialog>
	);
}
