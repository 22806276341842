import React, { useState, useEffect, useRef } from "react";
import { CustomDialog } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import { ApiService, BackOfficeService } from "../../../../../api";

export default function TokenInfoDialog({ companyId, onClose, open, projectId, userId }) {
	const cancelTokenSourceRef = useRef(null);
	const [tokenDate, setTokenDate] = useState(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		const cancelToken = ApiService.getCancelTokenSource();
		if (companyId && projectId && userId) {
			BackOfficeService.getTokenAndIssueTime({ companyId, projectId, userId }, cancelToken.token).then((data) => {
				setTokenDate(data);
			});
		}
		return () => {
			ApiService.cancelTokens(cancelToken);
		};
	}, [companyId, projectId, userId]);
	return (
		<CustomDialog
			closeLabel={translate("common:btn.close")}
			maxWidth="xs"
			open={open}
			title={translate("dsi.user-details.dialog.title")}
			onClose={onClose}
		>
			<span>
				{translate("dsi.user-details.dialog.token")} : {tokenDate?.token || "-"}
				<br />
				{translate("dsi.user-details.dialog.issue-time")} : {tokenDate?.issueTime || "-"}
			</span>
		</CustomDialog>
	);
}
