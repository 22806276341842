import React from "react";
import { getTaskStatusColor } from "../../../../../common/utils";
import styles from "./TaskSelectStatus.module.css";
import { CustomButton } from "../../../../../common/components";

export default function TaskSelectStatus({ taskId, status, isRelated, updateStatus = null }) {
	const bgcolor = getTaskStatusColor(status);
	const handleClick = () => {
		if (isRelated && updateStatus) {
			updateStatus(taskId);
		}
	};
	return (
		<>
			{isRelated ? (
				<CustomButton fullWidth style={{ backgroundColor: bgcolor }} variant="contained" onClick={handleClick}>
					<span> {status}</span>
				</CustomButton>
			) : (
				<div aria-hidden className={styles.label} style={{ color: bgcolor }} onClick={handleClick}>
					{status}
				</div>
			)}
		</>
	);
}
