import React from "react";
import { useSelector } from "react-redux";
import {
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator,
} from "@mui/lab";
import styles from "./RatingHistoryPanelRow.module.css";
import { RISK_LEVEL } from "../../../../../utils/utils";
import { CustomRating } from "../../../../../../../common/components";
import { diplayDate } from "../../../../../../../common/utils";
import { translate } from "../../../../../../../common/providers";

export default function RatingHistoryPanelRow({ customStyles, modification }) {
	const language = useSelector(({ context }) => context.language.value);
	return (
		<>
			{modification.distance && (
				<TimelineItem classes={{ root: styles.timeline__item }}>
					<TimelineOppositeContent
						classes={{ root: `${styles.timeline__date} ${styles.timeline__distance}` }}
					>
						<span className={styles["timeline__distance--label"]}>{modification.distance}</span>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineConnector
							className={`${styles.timeline__connector} ${styles["timeline__connector--sole"]}`}
						/>
					</TimelineSeparator>
					<TimelineContent classes={{ root: styles.timeline__content }} />
				</TimelineItem>
			)}
			<TimelineItem classes={{ root: styles.timeline__item }}>
				<TimelineOppositeContent classes={{ root: styles.timeline__date }}>
					{diplayDate(modification.date, language)}
				</TimelineOppositeContent>
				<TimelineSeparator>
					<TimelineDot
						classes={{
							root: styles["timeline__dot--filled"],
						}}
						className={styles.timeline__dot}
					/>
					<TimelineConnector className={styles.timeline__connector} />
				</TimelineSeparator>
				<TimelineContent classes={{ root: styles.timeline__content }}>
					<div>
						<span className={styles.content__name}>{modification.userName}</span>
						<CustomRating
							customStyles={customStyles}
							iconName="faSquare"
							iconNameEmpty="faSquareRegular"
							labels={[
								translate("gonogo.details.risk-level.no-risk"),
								translate("gonogo.details.risk-level.low-risk"),
								translate("gonogo.details.risk-level.moderate-risk"),
								translate("gonogo.details.risk-level.high-risk"),
							]}
							value={RISK_LEVEL[modification.riskLevel] || 0}
						/>
					</div>
				</TimelineContent>
			</TimelineItem>
		</>
	);
}
