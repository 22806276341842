import React, { useEffect, useState } from "react";
import styles from "./NotificationHistory.module.css";
import { OpportunityService } from "../../../../../api";
import { useApi } from "../../../../../common/hooks";
import { translate, translateDate } from "../../../../../common/providers";

function NotificationRow({ name, date }) {
	return (
		<div className={styles.row}>
			<span>{name}</span>
			<span>{translateDate(date)}</span>
		</div>
	);
}

export default function NotificationHistory({ opportunityId, toUpdate }) {
	const { call: getNotificationHistory } = useApi(OpportunityService.getNotificationHistory);
	const [history, setHistory] = useState([]);
	useEffect(() => {
		if (opportunityId) {
			getNotificationHistory({ opportunityId })
				.then((nHistory) => {
					if (Array.isArray(nHistory) && nHistory.length > 0) {
						setHistory(nHistory);
					}
				})
				.catch((err) => console.error(err));
		}
	}, [opportunityId, toUpdate, getNotificationHistory]);
	return (
		<div className={styles.main}>
			<span className={styles.title}>{translate("opportunity.notify.side-panel.history.title")}</span>
			<div>
				{history.map((row, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<NotificationRow key={index} date={row.date} name={row.user} />
				))}
			</div>
		</div>
	);
}
