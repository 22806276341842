import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomIconButton, icon } from "../../../../../../../../../common/components";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../../../../../../common/providers";
import { redressInfo } from "../../../../../../../slice/document/document-slice";
import { ROLES } from "../../../../../../../constants/constants";
import { hasReviewPermissions } from "../../../../../../../permissions/review-permissions-provider";
import REVIEW_PERMISSIONS from "../../../../../../../permissions/review-permissions";

export default function RedressButton({ hoveredBlock }) {
	const isFrozen = useSelector(({ srDocument }) => srDocument.isFrozen);
	const redressingInformation = useSelector(({ srDocument }) => srDocument.redressingInformation);
	const roles = useSelector(({ srDocument }) => srDocument.roles);
	const pageData = useSelector(({ srPdf }) => srPdf.content);
	const dispatch = useDispatch();
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const handleRedressMode = () => {
		const { pageStart, informationId } = hoveredBlock;
		const completeRangeRectangles = [];
		if (!redressingInformation) {
			pageData
				.filter((x) => hoveredBlock.pageStart <= x.page + 1 && hoveredBlock.pageStart >= x.page - 1)
				.forEach((page) => {
					page[mode].forEach((sentence) => {
						sentence.rectangles.forEach((rectangle) => {
							completeRangeRectangles.push({
								order: rectangle.order,
								checked: sentence.informationId === hoveredBlock.informationId,
							});
						});
					});
				});
		}
		const checkedRectangles = (
			((pageData.find((p) => p.page === pageStart) || {})?.[mode] || []).find(
				(s) => s.informationId === informationId
			) || {}
		)?.rectangles;
		dispatch(
			redressInfo({
				page: pageStart,
				informationId,
				orders: {
					start: Math.min(...checkedRectangles.map((x) => x.order)),
					end: Math.max(...checkedRectangles.map((x) => x.order)),
				},
				redressList: completeRangeRectangles,
			})
		);
	};
	return (
		<>
			{hasReviewPermissions(REVIEW_PERMISSIONS.GROUP_SEPARATE_INFO, { roles }) &&
				roles.some(
					(role) => ROLES[role].name === ROLES.REVIEWER.name || ROLES[role].name === ROLES.VALIDATOR.name
				) &&
				isSegFeatureEnabled(SegFlags.REDRESSEMENT) && (
					<CustomIconButton
						data-testid="redress.btn"
						disabled={isFrozen}
						icon={icon.faLayerGroup}
						tooltip={
							isFrozen
								? translate("smart-review.cmp-display-overlay.tooltip.redress-frozen")
								: translate("smart-review.cmp-display-overlay.tooltip.redress-information")
						}
						onClick={handleRedressMode}
					/>
				)}
		</>
	);
}
