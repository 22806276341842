import client from "../client/client";
import { encodeParam } from "../utils/utils";

class RequirementMatrixService {
	getCompaniesSharedPrivacyInProject({ projectId }, cancelToken) {
		return client.get(`/requirement-matrix/projects/${projectId}/companies`, { config: { cancelToken } });
	}

	searchDocumentOutInProjectByNameOrRef({ projectId }, { filter, page, limit }, cancelToken) {
		return client.get(`/requirement-matrix/projects/${projectId}/documents-out`, {
			params: { filter: encodeParam(filter), page, limit },
			config: { cancelToken },
		});
	}

	searchDocumentsInProjectByName({ projectId }, { filter, page, limit }, cancelToken) {
		return client.get(`/requirement-matrix/projects/${projectId}/documents`, {
			params: { filter: encodeParam(filter), page, limit },
			config: { cancelToken },
		});
	}

	searchDocumentsInProjectByNameWithFilters({ projectId }, { filters }, { page, limit }, cancelToken) {
		return client.post(`/requirement-matrix/projects/${projectId}/document-details`, {
			payload: filters,
			params: { page, limit },
			config: { cancelToken },
		});
	}

	searchRequirementsInProject({ projectId }, filters, { page = 0, limit = 50 }, cancelToken) {
		return client.put(`/requirement-matrix/projects/${projectId}`, {
			payload: { ...filters, Type: "RequirementSearchFilterDTO", separator: "AND" },
			params: { page, limit },
			config: { cancelToken },
		});
	}

	getRequirementsInProjectCount({ projectId }, filters, cancelToken) {
		return client.put(`/requirement-matrix/projects/${projectId}/count`, {
			payload: { ...filters, Type: "RequirementSearchFilterDTO", separator: "AND" },
			config: { cancelToken },
		});
	}

	getRequirementDetails({ reqId }, cancelToken) {
		return client.get(`/requirement-matrix/requirements/${reqId}`, { config: { cancelToken } });
	}

	getRequirementDetailsMyCompany({ reqId }, cancelToken) {
		return client.get(`/requirement-matrix/requirements/${reqId}/my-company`, { config: { cancelToken } });
	}

	updateRequirement({ reqId }, payload, cancelToken) {
		return client.patch(`/requirement-matrix/requirements/${reqId}`, { payload, config: { cancelToken } });
	}

	getParametersForInformation(cancelToken) {
		return client.get("/requirement-matrix/parameters", { config: { cancelToken } });
	}

	getTotalRequirementsInProject({ projectId }, cancelToken) {
		return client.get(`/requirement-matrix/projects/${projectId}/requirements`, { config: { cancelToken } });
	}
}

export default new RequirementMatrixService();
