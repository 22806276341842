import { TextField } from "@mui/material";
import React from "react";
import styles from "./MultiInput.module.css";
import { icon } from "../../../../../icon-component";
import { CustomIconButton } from "../../../../../buttons";
import { translate } from "../../../../../../providers";

const MultiInput = ({
	disableLimitation,
	error = false,
	helperText,
	index,
	input,
	isLast,
	onAddNew,
	onChangeInput,
	onRemove,
	type = "text",
	readOnly,
}) => {
	const handleChange = (e) => {
		if (readOnly) {
			return;
		}
		onChangeInput(e.target.value, index);
	};
	const handleRemove = () => {
		if (readOnly) {
			return;
		}
		onRemove(index);
	};

	return (
		<div className={styles.multiInput__container}>
			<TextField
				autoFocus
				fullWidth
				className={styles.multiInput_textField}
				data-testid="multiInput.textField"
				disabled={readOnly}
				error={error}
				helperText={
					<>
						{!disableLimitation && input.length < 2 && (
							<span>
								{translate("common:component.filters.keyword-filter.min-input", { min: 2 })}&nbsp;
							</span>
						)}
						<span>
							{isLast &&
								(helperText || translate("common:component.filters.keyword-filter.case-sensitivity"))}
						</span>
					</>
				}
				InputLabelProps={{ classes: { outlined: styles.multiInput__inputLabel } }}
				InputProps={{ className: styles.multiInput__input }}
				type={type}
				value={input}
				onChange={handleChange}
			/>
			<div className={styles.multiInput__controlContainer}>
				<CustomIconButton
					btnClassName={styles.control__btn}
					color="primary"
					disabled={index === 0 || readOnly}
					icon={icon.faMinusSquare}
					iconClassName={styles.control__icon}
					onClick={handleRemove}
				/>
				<CustomIconButton
					btnClassName={styles.control__btn}
					color="primary"
					disabled={readOnly}
					icon={icon.faPlusSquare}
					iconClassName={styles.control__icon}
					onClick={onAddNew}
				/>
			</div>
		</div>
	);
};

export default MultiInput;
