import { Button } from "@mui/material";
import React, { useRef, useState } from "react";
import {
	CustomIconButton,
	CustomTooltip,
	icon,
	SidePanelBox,
	PositionIcon,
} from "../../../../../../../../../common/components";
import CommentHighlightedText from "../../../../../../../../../common/components/comment-highlighted-text/CommentHighlightedText";
import { POSITIONS_STATUSES } from "../../../../../../../../../common/constants/positions-statuses";
import { translate, translateDate, translateElapsed } from "../../../../../../../../../common/providers";
import { debounce } from "../../../../../../../../../common/utils";
import styles from "./PositionBox.module.css";

const debounceHover = debounce((func) => func(), 500);
export default function PositionBox({
	disableHighlighting = false,
	className = "",
	editable = false,
	position = {},
	primaryName = "",
	secondaryName = "",
	onChangePositionStatus,
	onClickAdd,
	onClickEdit,
}) {
	const [hover, setHover] = useState(false);
	const [iconsHovered, setIconsHovered] = useState(false);
	const commentRef = useRef(null);
	const handleClickEdit = () => {
		if (typeof onClickEdit === "function") {
			onClickEdit();
		}
	};
	const handleClickAddComment = () => {
		if (typeof onClickAdd === "function") {
			onClickAdd();
		}
	};
	const handleMouseEnter = () => {
		setHover(true);
	};
	const handleMouseLeave = () => {
		setHover(false);
	};
	const handleMouseIconEnter = (e) => {
		e.stopPropagation();
		setIconsHovered(true);
	};
	const handleMouseIconLeave = (e) => {
		e.stopPropagation();
		debounceHover(() => {
			setIconsHovered(false);
		});
	};
	return (
		<SidePanelBox className={styles.positionContainer} hasShadow={false}>
			<div
				className={`${styles.container} ${className}`}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<div className={styles.header}>
					<div className={styles.header__leftItems}>
						<span className={styles.leftItems__primaryName}>
							{primaryName || position.username}
							<span className={styles.leftItems__secondaryName}>{secondaryName}</span>
						</span>
					</div>
					<div className={styles.header__rightItems}>
						{!position.status || position.status === POSITIONS_STATUSES.UNREVIEWED ? (
							<span className={styles.date}>
								{translate(
									"smart-review.cmp-details.option-panel.positions-tab.position-box.in-progress"
								)}
							</span>
						) : (
							<CustomTooltip
								placement="top"
								title={
									(!!position.statusDate &&
										translateDate(position.statusDate).replaceAll(".", "/")) ||
									""
								}
							>
								<div className={styles.date} data-ishovered={iconsHovered}>
									{translateElapsed({
										value: position.statusTimeElapsed.value,
										unitKey: position.statusTimeElapsed.unit,
									})}{" "}
									|
								</div>
							</CustomTooltip>
						)}
						{editable ? (
							<div
								className={styles.iconsContainer}
								data-ishovered={iconsHovered}
								data-isstatusselected={!!position.status}
								onMouseEnter={handleMouseIconEnter}
								onMouseLeave={handleMouseIconLeave}
							>
								<PositionIcon
									clickable
									active={position.status === POSITIONS_STATUSES.YES}
									positionsStatus={POSITIONS_STATUSES.YES}
									selectedExist={position.status !== null}
									onClick={() =>
										onChangePositionStatus(
											POSITIONS_STATUSES.YES,
											POSITIONS_STATUSES.YES === position.status
										)
									}
								/>
								<PositionIcon
									clickable
									active={position.status === POSITIONS_STATUSES.PENDING}
									positionsStatus={POSITIONS_STATUSES.PENDING}
									selectedExist={position.status !== null}
									onClick={() =>
										onChangePositionStatus(
											POSITIONS_STATUSES.PENDING,
											POSITIONS_STATUSES.PENDING === position.status
										)
									}
								/>
								<PositionIcon
									clickable
									active={position.status === POSITIONS_STATUSES.NO}
									positionsStatus={POSITIONS_STATUSES.NO}
									selectedExist={position.status !== null}
									onClick={() =>
										onChangePositionStatus(
											POSITIONS_STATUSES.NO,
											POSITIONS_STATUSES.NO === position.status
										)
									}
								/>
								<PositionIcon
									clickable
									active={position.status === POSITIONS_STATUSES.NOT_ME}
									positionsStatus={POSITIONS_STATUSES.NOT_ME}
									selectedExist={position.status !== null}
									onClick={() =>
										onChangePositionStatus(
											POSITIONS_STATUSES.NOT_ME,
											POSITIONS_STATUSES.NOT_ME === position.status
										)
									}
								/>
							</div>
						) : (
							<div className={styles.iconsContainer}>
								<PositionIcon positionsStatus={POSITIONS_STATUSES[position.status]} />
							</div>
						)}
					</div>
				</div>
				<div className={styles.body}>
					<div ref={commentRef} className={styles.comment}>
						{disableHighlighting ? (
							<>{position.comment}</>
						) : (
							position.comment
								?.split("\n")
								.map((c) => (
									<CommentHighlightedText key={c} textClassName={styles.commentLine} value={c} />
								))
						)}
					</div>
				</div>
				<div className={styles.editContainer}>
					{position.commentTimeElapsed && (
						<CustomTooltip
							placement="top"
							title={
								(!!position.commentDate && translateDate(position.commentDate).replaceAll(".", "/")) ||
								""
							}
						>
							<span className={styles.date}>
								{translateElapsed({
									value: position.commentTimeElapsed.value,
									unitKey: position.commentTimeElapsed.unit,
								})}
							</span>
						</CustomTooltip>
					)}
					{editable &&
						((!!position.comment &&
							((hover && (
								<CustomIconButton
									btnClassName={styles.button}
									icon={icon.faPen}
									placement="top"
									size="sm"
									onClick={handleClickEdit}
								/>
							)) || <></>)) || (
							<Button color="primary" onClick={handleClickAddComment}>
								{translate(
									"smart-review.cmp-details.option-panel.positions-tab.position-box.add-comment"
								)}
							</Button>
						))}
				</div>
			</div>
		</SidePanelBox>
	);
}
