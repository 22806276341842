import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CircularLoader, CustomButton, Filter } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import styles from "./StatusFilter.module.css";

const StatusFilter = ({ filters, isLoading, onDirectSearch, onSearch, tempTotalSearchResult }) => {
	const [tempFilters, setTempFilters] = useState(filters);
	const needApplyDocuments = tempFilters.status !== filters.status;
	useEffect(() => {
		setTempFilters(filters);
	}, [filters]);
	const resetTempFilter = () => {
		const newTempFilters = { ...filters, status: "" };
		setTempFilters(newTempFilters);
		onSearch({ searchFilters: newTempFilters });
	};
	const generateCloseLabelText = () => {
		if (isLoading) {
			return <CircularLoader color="inherit" size={20} />;
		}
		return needApplyDocuments
			? translate("norms-center.filter.documents.apply-button", { resultsNb: tempTotalSearchResult })
			: translate("common:btn.close");
	};
	const handleChangeFilters = (filter) => {
		const newTempFilters = { ...tempFilters, status: filter };
		setTempFilters(newTempFilters);
		onDirectSearch(newTempFilters, false);
	};
	return (
		<Filter
			buttonColor={needApplyDocuments && !isLoading ? "primary" : "secondary"}
			closeLabel={generateCloseLabelText()}
			isActive={filters.status !== ""}
			label={translate("norms-center.filter.status.title")}
			onClear={() => resetTempFilter()}
			onClose={() => {
				onSearch({ filterKey: "status", tempFilters });
			}}
		>
			<CustomButton onClick={() => resetTempFilter()}>{translate("common:btn.clear-select")}</CustomButton>
			<RadioGroup
				className={styles.filters}
				value={tempFilters.status}
				onChange={(e) => {
					handleChangeFilters(e.target.value);
				}}
			>
				<FormControlLabel
					control={<Radio color="primary" />}
					label={translate("norms-center.tab.norms.table.status.active")}
					value="ACTIVE"
				/>
				<FormControlLabel
					control={<Radio color="primary" />}
					label={translate("norms-center.tab.norms.table.status.canceled")}
					value="CANCELED"
				/>
			</RadioGroup>
		</Filter>
	);
};
export default StatusFilter;
