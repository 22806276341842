import React from "react";
import { Info, PackageList, ProjectList } from ".";

const PANELS = {
	information: {
		title: "dsi.feature-management.panel.details.title",
		panel: ({ feat, onUpdateRow }) => <Info feat={feat} onUpdateRow={onUpdateRow} />,
	},
	packages: {
		title: "dsi.feature-management.panel.packages.title",
		panel: ({ feat, onUpdateRow }) => <PackageList feat={feat} onUpdateRow={onUpdateRow} />,
	},
	projects: {
		title: "dsi.feature-management.panel.projects.title",
		panel: ({ feat, platformProjects, onUpdateRow }) => (
			<ProjectList feat={feat} platformProjects={platformProjects} onUpdateRow={onUpdateRow} />
		),
	},
};

export default PANELS;
