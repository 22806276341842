import client from "../../client/client";

class CollabService {
	updateType({ infoId }, payload, cancelToken) {
		return client.patch(`/smartview/information/${infoId}`, { payload, config: { cancelToken } });
	}

	updateStatus({ infoId }, payload, cancelToken) {
		return client.patch(`/smartview/information/${infoId}/status`, {
			payload: JSON.stringify(payload),
			config: { cancelToken },
		});
	}

	getHistory({ infoId }, cancelToken) {
		return client.get(`/smartview/information/${infoId}/history`, { config: { cancelToken } });
	}

	getCoverageStatus({ infoId }, cancelToken) {
		return client.get(`/smartview/information/${infoId}/coverage`, { config: { cancelToken } });
	}

	getStringContent({ infoId }, cancelToken) {
		return client.get(`/smartview/information/${infoId}`, { config: { cancelToken } });
	}

	getListStringContent({ documentId }, informationsId, cancelToken) {
		return client.post(`/smartview/document/${documentId}/information/contents`, {
			payload: informationsId,
			config: { cancelToken },
		});
	}

	updateIssueComment({ informationId }, payload, cancelToken) {
		return client.patch(`/smartview/information/${informationId}/issuecomment`, {
			payload: { value: payload },
			config: { cancelToken },
		});
	}

	deleteIssueComment({ informationId }, cancelToken) {
		return client.delete(`/smartview/information/${informationId}/issuecomment`, { config: { cancelToken } });
	}

	getPartnershipData({ informationId }, cancelToken) {
		return client.get(`/smartview/information/${informationId}/partnership`, { config: { cancelToken } });
	}

	getMyCompanyData({ informationId }, cancelToken) {
		return client.get(`/smartview/information/${informationId}/my-company`, { config: { cancelToken } });
	}

	getVersioningData({ informationId }, cancelToken) {
		return client.get(`/smartview/information/${informationId}/version`, { config: { cancelToken } });
	}

	getQuestionAnswers({ informationId }, cancelToken) {
		return client.get(`/smartview/information/${informationId}/question-answer`, { config: { cancelToken } });
	}

	createQuestionAnswer({ informationId }, payload, cancelToken) {
		return client.post(`/smartview/information/${informationId}/question-answer`, {
			payload: { value: payload },
			config: { cancelToken },
		});
	}

	getMultiDocComments({ projectId }, { documentIds, search }, { page = 0, limit = 25 }, cancelToken) {
		return client.post(`/smartview/projects/${projectId}/comments`, {
			params: { page, limit },
			payload: { documentIds, search },
			config: { cancelToken },
		});
	}

	getMultiDocIssueComments({ projectId }, { documentIds, search }, { page = 0, limit = 25 }, cancelToken) {
		return client.post(`/smartview/projects/${projectId}/issue-comments`, {
			params: { page, limit },
			payload: { documentIds, search },
			config: { cancelToken },
		});
	}

	getAllCommentsOnInformation({ informationId }, { page = 0, limit = 25, filter = "" }, cancelToken) {
		return client.get(`/smartview/information/${informationId}/comments`, {
			params: { page, limit, filter },
			config: { cancelToken },
		});
	}

	getAllCommentsHistoryOnInformation({ informationId }, { page = 0, limit = 25 }, cancelToken) {
		return client.get(`/smartview/information/${informationId}/comments`, {
			params: { page, limit },
			config: { cancelToken },
		});
	}

	getTable({ tableId }, cancelToken) {
		return client.get(`/smartview/table/${tableId}`, {
			config: { cancelToken },
		});
	}
}

export default new CollabService();
