import React from "react";
import { translate } from "../../../../common/providers";
import styles from "./MembersListEmptyState.module.css";

export default function MembersListEmptyState() {
	return (
		<div className={styles["members-list__empty-state"]}>
			<div className={styles["members-list__empty-state-title"]}>
				{translate("team-management.members.members-list.members-list.empty-state.title")}
			</div>
			<div className={styles["members-list__empty-state-sub-title"]}>
				{translate("team-management.members.members-list.empty-state.content")}
			</div>
		</div>
	);
}
