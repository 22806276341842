import client from "../client/client";

class RedressmentService {
	redress({ docId }, payload, cancelToken) {
		return client.post(`/redressment/documents/${docId}`, { payload, config: { cancelToken } });
	}

	move({ docId }, payload, cancelToken) {
		return client.post(`/redressment/documents/${docId}/move`, { payload, config: { cancelToken } });
	}

	group({ docId }, payload, cancelToken) {
		return client.post(`/redressment/documents/${docId}/group`, { payload, config: { cancelToken } });
	}

	split({ docId }, payload, cancelToken) {
		return client.post(`/redressment/documents/${docId}/split`, { payload, config: { cancelToken } });
	}

	previewRedressInfo({ docId, infoId }, payload, cancelToken) {
		return client.post(`/redressment/documents/${docId}/information/${infoId}/crop`, {
			payload,
			config: { cancelToken },
		});
	}

	proceedRedressInfo({ docId, infoId }, payload, cancelToken) {
		return client.post(`/redressment/documents/${docId}/information/${infoId}/proceed`, {
			payload,
			config: { cancelToken },
		});
	}

	compute({ docId }, payload, cancelToken) {
		return client.post(`/redressment/documents/${docId}/requirements/compute`, {
			payload,
			config: { cancelToken },
		});
	}

	clearRequirements({ docId }, payload, cancelToken) {
		return client.post(`/redressment/documents/${docId}/requirements/clear`, { payload, config: { cancelToken } });
	}
}

export default new RedressmentService();
