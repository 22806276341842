import React from "react";
import { Divider } from "@mui/material";
import { CategoryRating } from "../..";
import styles from "./RatingHistoryPopOver.module.css";
import { translate } from "../../../../../../../common/providers";
import { RISK_LEVEL } from "../../../../../utils/utils";
import { distanceDate } from "../../../../../../../common/utils";
import { TIME_UNITS } from "../../../../../../../common/constants";

export default function RatingHistoryPopOver({ customStyles, onShowMore = null, ratings = [] }) {
	const handleClick = () => {
		if (onShowMore && typeof onShowMore === "function") {
			onShowMore();
		}
	};
	return (
		<div className={styles.main}>
			{((Array.isArray(ratings) && ((ratings.length > 4 && ratings.slice(0, 4)) || ratings)) || []).map(
				(rating, index) => (
					<div
						// eslint-disable-next-line react/no-array-index-key
						key={index}
					>
						<CategoryRating
							customStyles={customStyles}
							owner={rating.userName}
							riskValue={RISK_LEVEL[rating.riskLevel] || 0}
							time={distanceDate({
								date: rating.date,
								options: [
									TIME_UNITS.MINUTES,
									TIME_UNITS.HOURS,
									TIME_UNITS.YESTERDAY,
									TIME_UNITS.DAYS,
									TIME_UNITS.MONTHS,
								],
							})}
						/>
					</div>
				)
			)}
			{Array.isArray(ratings) && ratings.length > 4 && (
				<div>
					<Divider />
					<span className={styles.showMore} role="presentation" onClick={handleClick}>
						{translate("gonogo.details.history.popover.show-more")}
					</span>
				</div>
			)}
		</div>
	);
}
