import React from "react";
import { HorizontalDivider } from "../../../../../common/components";
import {
	ProjectReqIssues,
	ProjectReqReview,
	ProjectDocsInfo,
	ProjectTitle,
	ProjectTeamInfo,
} from "../../../components";
import styles from "./Widgets.module.css";

export default function Widgets({ onChangeCompany, onChangeUrl, openProjectId, project, selectedIndex }) {
	return (
		<div className={styles.projectWidgetContainer}>
			<div className={styles.projectTitle}>
				<ProjectTitle
					projectName={project?.projectInfo?.projectName}
					selectedIndex={selectedIndex}
					onChangeCompany={onChangeCompany}
				/>
			</div>
			<HorizontalDivider />
			<div className={styles.projectWidget}>
				{project && project.userReqValid && (
					<ProjectReqIssues
						accepted={project.userReqValid.accepted}
						denied={project.userReqValid.denied}
						notme={project.userReqValid.notme}
						pending={project.userReqValid.pending}
						projectId={openProjectId}
						unvalidated={project.userReqValid.unvalidated}
						onChangeUrl={onChangeUrl}
					/>
				)}
			</div>
			<div className={styles.projectWidget}>
				{project && project.projectReq && (
					<ProjectReqReview
						clientReq={project.projectReq.clientReq}
						coverage={project.projectReq.coverage}
						newReq={project.projectReq.newReq}
						projectId={openProjectId}
						reviewed={project.projectReq.reviewed}
						totalReq={project.projectReq.totalReq}
						onChangeUrl={onChangeUrl}
					/>
				)}
			</div>
			<div className={styles.projectWidget}>
				{project && project.documents && (
					<ProjectDocsInfo
						failed={project.documents.failed}
						inUpload={project.documents.inProgress}
						pending={project.documents.pending}
						projectId={openProjectId}
						timeout={project.documents.timeout}
						totaldocuments={project.documents.totalDocuments}
						totalpages={project.documents.totalPages}
						onChangeUrl={onChangeUrl}
					/>
				)}
			</div>
			<div className={styles.projectWidget}>
				{project && project.partners && (
					<ProjectTeamInfo partners={project.partners} projectId={openProjectId} onChangeUrl={onChangeUrl} />
				)}
			</div>
		</div>
	);
}
