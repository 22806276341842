import React, { forwardRef, useRef, useState, useMemo, useEffect } from "react";
import { MenuItem, MenuList } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { NavUtils, checkValidity } from "../../../navigation";
import { Flags, Permissions, SegFlags, translate } from "../../providers";
import DropDown from "../drop-down/DropDown";
import { icon, IconComponent } from "../icon-component";
import styles from "./ViewBanner.module.css";

const DropDownMenus = forwardRef(({ identifier, onMouseEnter, onMouseLeave, open, position = "bottom-start" }, ref) => {
	const projectId = useSelector(({ context }) => context.project?.id);
	const [childrenOpen, setChildrenOpen] = useState(false);
	const [url, setUrl] = useState("/");
	const anchorRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const menus = useMemo(() => {
		if (projectId) {
			return {
				dashboard: [
					{
						title: translate("navigation:project.my-progress"),
						to: NavUtils.goToReviewProgress(projectId),
						segFlag: SegFlags.REVIEW_PROGRESS_DASHBOARD,
					},
					{
						title: translate("navigation:project.project"),
						to: NavUtils.goToProject(projectId),
						segFlag: SegFlags.PROJECT_DASHBOARD,
					},
					{
						title: translate("navigation:project.tasks"),
						to: NavUtils.goToTasks(projectId),
						segFlag: [SegFlags.SMART_REVIEW, SegFlags.TASK],
					},
				],
				review: [
					{
						title: translate("navigation:project.my-review"),
						to: NavUtils.goToSmartReview({ projectId }),
						segFlag: [SegFlags.SMART_REVIEW, SegFlags.DOCUMENT_CENTER],
					},
					{
						title: translate("navigation:project.risk-analysis"),
						to: NavUtils.goToReviewByCategory({ projectId }),
						flag: Flags.SELECT_SYNTHESIS,
						segFlag: [SegFlags.CATEGORY, SegFlags.REVIEW_BY_CATEGORY],
					},
					{
						title: translate("navigation:project.project-requirements"),
						to: NavUtils.goToProjectRequirements(projectId),
						flag: Flags.REQUIREMENT_MATRIX,
						segFlag: SegFlags.PROJECT_REQUIREMENTS,
					},
					{
						title: translate("navigation:project.verification-of-detections"),
						to: NavUtils.goToVerificationOfDetection(projectId),
						segFlag: SegFlags.VERIFICATION_OF_DETECTIONS,
						flag: Flags.CONTROL,
						role: Permissions.PROJECT_LEADER,
						companyRole: [Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER],
					},
				],
				followUp: [
					{
						title: translate("navigation:project.question-to-the-client"),
						to: NavUtils.goToQA(projectId),
						flag: Flags.QA,
						segFlag: SegFlags.QUESTION_ANSWER,
					},
					{
						segFlag: SegFlags.DELIVERABLES,
						title: translate("navigation:project.deliverables"),
						subMenus: "deliverables",
					},
					{
						title: translate("navigation:project.project-norms"),
						flag: Flags.NORMS,
						to: NavUtils.goToProjectStandards(projectId),
						segFlag: SegFlags.NORMS,
					},
				],
				deliverables: [
					{
						title: translate("navigation:project.deliverables-to-be-produced"),
						to: NavUtils.goToDeliverablesToBeProduced(projectId),
						flag: Flags.OPERATION,
						segFlag: SegFlags.DELIVERABLES,
					},
					{
						title: translate("navigation:project.assign-to-deliverables"),
						to: NavUtils.goToAssignToDeliverables(projectId),
						flag: Flags.COVERAGE,
						segFlag: SegFlags.DELIVERABLES,
					},
				],
				projectAdmin: [
					{
						title: translate("navigation:home.admin-categories"),
						to: NavUtils.goToProjectCategories(projectId),
						flag: Flags.THEMATIC,
						segFlag: SegFlags.CATEGORY,
					},
					{
						title: translate("navigation:home.team"),
						to: NavUtils.goToTeam(projectId),
						segFlag: SegFlags.TEAM,
					},
					{
						title: translate("navigation:home.documents"),
						to: NavUtils.goToDocumentCenter(projectId),
						flag: Flags.DOCUMENT_CENTER,
						segFlag: SegFlags.DOCUMENT_CENTER,
					},
					{
						title: translate("navigation:home.rights-on-documents"),
						to: NavUtils.goToRightsOnDocuments(projectId),
						segFlag: [SegFlags.RIGHTS_ON_DOCUMENTS, SegFlags.TEAM],
					},
				],
			};
		}
		return {
			templates: [
				{
					title: translate("navigation:common.templates.search-templates"),
					flag: Flags.SEARCHTEMPLATE,
					role: [Permissions.COMPANY_ADMIN, Permissions.COMPANY_MANAGER],
					subMenus: "smartSearchTemplates",
				},
				{
					title: translate("navigation:common.templates.search-templates"),
					flag: Flags.SEARCHTEMPLATE,
					role: Permissions.COMPANY_MEMBER,
					to: NavUtils.goToMySearchTemplate(),
				},
				{
					title: translate("navigation:common.templates.category-templates"),
					flag: Flags.CATEGORYTEMPLATE,
					to: NavUtils.goToCategoryTemplate(),
				},
			],
		};
	}, [projectId]);
	useEffect(() => {
		setUrl(location.pathname);
	}, [location.pathname]);
	const handleClick = (to) => {
		navigate(to, { state: { previous: location.pathname } });
	};
	const handleOpen = () => {
		setChildrenOpen(true);
	};
	const handleClose = () => {
		setChildrenOpen(false);
	};
	const handleIsSelected = ({ to, subMenus }) => {
		if (to === "/" && url !== "/") {
			return false;
		}
		if (url?.startsWith(to)) {
			return true;
		}
		if (subMenus && Array.isArray(menus[subMenus])) {
			let count = 0;
			menus[subMenus].forEach((menu) => {
				if (url?.startsWith(menu.to)) {
					count++;
				}
			});
			if (count > 0) {
				return true;
			}
			return false;
		}
		return false;
	};
	return (
		<DropDown ref={ref} open={open} position={position} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<MenuList>
				{Array.isArray(menus[identifier]) &&
					menus[identifier].length > 0 &&
					menus[identifier].map((menu) => {
						if (!checkValidity(menu)) {
							return null;
						}
						const disabled = handleIsSelected(menu);
						return (
							(menu.subMenus && (
								<MenuItem
									key={menu.title}
									ref={anchorRef}
									className={styles.menuItem}
									data-selected={disabled}
									onMouseEnter={handleOpen}
									onMouseLeave={handleClose}
								>
									{menu.title}
									<>
										<IconComponent
											className={styles.menuItem__expand}
											color={disabled ? "var(--color-blue)" : "var(--color-black)"}
											icon={icon.faChevronRight}
											size="2xs"
										/>
										<DropDownMenus
											ref={anchorRef}
											identifier={menu.subMenus}
											open={childrenOpen}
											position="right-start"
											onMouseEnter={handleOpen}
											onMouseLeave={handleClose}
										/>
									</>

									<DropDownMenus />
								</MenuItem>
							)) || (
								<MenuItem
									key={menu.title}
									className={styles.menuItem}
									data-selected={disabled}
									disabled={disabled}
									onClick={() => handleClick(menu.to)}
								>
									{menu.title}
								</MenuItem>
							)
						);
					})}
			</MenuList>
		</DropDown>
	);
});

export default DropDownMenus;
