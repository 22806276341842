import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ApiService, SearchCardService } from "../../../../api";
import { SEARCHCARD_OWNERS } from "../../../constants/search-card-owners";
import { translateEnumSearchCardType } from "../../../providers";
import { ViewTabs } from "../../tabs";
import { SearchCardBox } from "./components";
import { InfiniteList2 } from "../..";
import styles from "./SearchCardSelectionList.module.css";

const VISIBILITY_FOR_AUTOMATION = { AUTOMATION: "AUTOMATION" };

export default function SearchCardSelectionList({
	allowDefaultChanges = false,
	defaultCheckedIds = [],
	defaultPrivacy,
	onChangeCheckedIds,
	singleSelection = false,
}) {
	const cancelTokenSourceRef = useRef(null);
	const projectId = useSelector(({ context }) => context.project?.id);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const searchCardsTabs = Object.keys(VISIBILITY_FOR_AUTOMATION).map((name) => ({
		label: translateEnumSearchCardType(name),
		id: name,
	}));
	const [selectedTab, setSelectedTab] = useState(0);
	const [checkedIds, setCheckedIds] = useState([]);
	const [defaultSet, setDefaultSet] = useState(false);
	useEffect(() => {
		if (allowDefaultChanges || !defaultSet) {
			setCheckedIds([...defaultCheckedIds]);
			setDefaultSet(true);
		}
	}, [allowDefaultChanges, defaultCheckedIds, defaultSet]);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(
		() => {
			if (searchCardsTabs.length > 0) {
				setInfiniteListRequest(
					() =>
						({ page, limit, search }) =>
							SearchCardService.getAll(
								{ projectId },
								{
									page,
									limit,
									filters: { visibility: searchCardsTabs[selectedTab].id, name: search },
								},
								cancelTokenSourceRef.current.token
							)
				);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[projectId, selectedTab]
	);
	useEffect(() => {
		if (defaultPrivacy) {
			const newSelectedTab = Object.values(SEARCHCARD_OWNERS).indexOf(defaultPrivacy);
			if (newSelectedTab >= 0) {
				setSelectedTab(newSelectedTab);
			}
		}
	}, [defaultPrivacy]);
	const handleChangeSelectedTab = (_, newTab) => {
		setSelectedTab(newTab);
	};
	const handleCheckSearchCard = (newValue, newChecked) => {
		let newCheckedIds = [];
		if (singleSelection) {
			newCheckedIds = [newValue.id];
		} else if (newChecked) {
			newCheckedIds = [...checkedIds, newValue.id];
		} else {
			newCheckedIds = checkedIds.filter((id) => id !== newValue.id);
		}
		setCheckedIds(newCheckedIds);
		onChangeCheckedIds(newCheckedIds);
	};
	const rowRenderer = (row) => {
		const checked = checkedIds.includes(row.id);
		return (
			<SearchCardBox
				key={row.id}
				checked={checked}
				searchCard={row}
				singleSelection={singleSelection}
				onCheck={() => handleCheckSearchCard(row, !checked)}
			/>
		);
	};
	return (
		<div className={styles.searchCardSelectionList}>
			<ViewTabs
				noPadding
				noShadow
				selectedTab={selectedTab}
				tabs={searchCardsTabs}
				onChange={handleChangeSelectedTab}
			/>
			<div className={styles.searchListContainer}>
				<InfiniteList2 anticipate hasSearch request={infiniteListRequest} rowRenderer={rowRenderer} />
			</div>
		</div>
	);
}
