import client from "../../client/client";

class ActionAutoTemplateService {
	getAll(payload, { page = 0, limit = 25, sort, direction }, cancelToken) {
		return client.post("/action-auto-templates/all", {
			payload,
			params: { page, limit, sort, direction },
			config: { cancelToken },
		});
	}

	createAction(payload, cancelToken) {
		return client.post("/action-auto-templates", { payload, config: { cancelToken } });
	}

	modifyActions({ ids }, cancelToken) {
		return client.patch("/action-auto-templates", {
			payload: { ids },
			config: { cancelToken },
		});
	}

	modifyActionsWithFilters({ filter, ignored }, cancelToken) {
		return client.patch("/action-auto-templates/filters", {
			payload: { filter, ignored },
			config: { cancelToken },
		});
	}

	updateAction({ actionId }, payload, cancelToken) {
		return client.put(`/action-auto-templates/${actionId}`, { payload, config: { cancelToken } });
	}

	deleteAction({ actionId }, cancelToken) {
		return client.delete(`/action-auto-templates/${actionId}`, { config: { cancelToken } });
	}

	getAction({ actionId }, cancelToken) {
		return client.get(`/action-auto-templates/actionTemplate/${actionId}`, { config: { cancelToken } });
	}
}
export default new ActionAutoTemplateService();
