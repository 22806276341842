import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { CustomIconButton, CustomTooltip, icon, IconComponent } from "../../../../common/components";
import styles from "./Filter.module.css";
import { translate } from "../../../../common/providers";
import { searchInString } from "../../../../common/utils";
import ExpandableRow from "./ExpandableRow";

const Filter = ({ addFilter, elements, isTree = false, matchField = null, onExpand, onSetFilter = null, title }) => {
	const [displayedElements, setDisplayedElements] = useState([]);
	const [search, setSearch] = useState("");

	useEffect(
		() =>
			function cleanup() {
				setDisplayedElements([]);
				setSearch("");
			},
		[]
	);

	useEffect(() => {
		setDisplayedElements(elements);
	}, [elements]);

	useEffect(() => {
		if (!search || search.length < 3) {
			setDisplayedElements(elements);
		} else if (typeof onSetFilter === "function") {
			onSetFilter(search);
		} else {
			const filteredElements = matchField
				? elements.filter((e) => searchInString(e[matchField], search))
				: elements.filter((e) => searchInString(e, search));
			setDisplayedElements(filteredElements);
		}
	}, [search, matchField, elements, onSetFilter]);

	const addElement = (element) => {
		addFilter({ element });
	};

	return (
		<div className={styles.filter__container}>
			<h6 className={styles.filter__title}>{title}</h6>
			<div className={styles.filter__search_bar}>
				<TextField
					fullWidth
					id="filter__search_bar"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconComponent icon={icon.faSearch} />
							</InputAdornment>
						),
					}}
					placeholder={translate("deliverable.search.placeholder")}
					size="small"
					value={search || ""}
					variant="outlined"
					onChange={(e) => {
						setSearch(e.target.value);
					}}
				/>
			</div>
			{Array.isArray(displayedElements) &&
				displayedElements.map((e) => {
					const label = matchField ? e[matchField] : e;
					const key = e?.id || label;
					if (!label) {
						return null;
					}
					if (isTree) {
						return (
							<ExpandableRow
								key={key}
								addElement={addElement}
								matchField={matchField}
								row={e}
								onExpand={onExpand}
							/>
						);
					}
					return (
						<div key={key} className={styles.element__row}>
							<CustomTooltip showWhenOverflow enterDelay={500} title={label}>
								<span className={styles.element__text}>{label}</span>
							</CustomTooltip>
							<span aria-hidden className={styles.element__button} onClick={() => addElement(e)}>
								<CustomIconButton className={styles.element__icon} icon={icon.faPlusCircle} />
							</span>
						</div>
					);
				})}
		</div>
	);
};
export default Filter;
