import { translate } from "../providers";
import { TIME_UNITS } from "../constants";

export const convertFormatDateToDisplay = (date) => {
	/* for followings formats :
  yyyy-MM-dd'T'HH:mm:ss.SSS
  yyyy-MM-dd'T'HH:mm:ss
  yyyy-MM-dd'T'HH:mm:ss.SSS'Z'
  yyyy-MM-dd'T'HH:mm:ss.SSSXXX
  yyyy-MM-dd'T'HH:mm:ssXXX
  */
	if (date.includes("T")) {
		return `${date.slice(0, 10)} (${date.slice(11, 19)})`;
	}
	return date.slice(0, 10);
};

export const convertFormatDateToDisplayDate = (date) => {
	/* for followings formats :
  yyyy-MM-dd'T'HH:mm:ss.SSS
  yyyy-MM-dd'T'HH:mm:ss
  yyyy-MM-dd'T'HH:mm:ss.SSS'Z'
  yyyy-MM-dd'T'HH:mm:ss.SSSXXX
  yyyy-MM-dd'T'HH:mm:ssXXX
  */
	if (!date || typeof date !== "string") {
		return new Date("");
	}
	return new Date(date.slice(0, 10));
};

export const isValidDate = (d) => d instanceof Date && !Number.isNaN(d);

export function processDateElement(dateElement) {
	return `0${dateElement}`.slice(-2);
}

export function convertAnyDateToDisplayDate(date) {
	if (!date) {
		return "";
	}
	const d = new Date(date);
	let month = `${d.getMonth() + 1}`;
	let day = `${d.getDate()}`;
	const year = d.getFullYear();

	if (month.length < 2) {
		month = `0${month}`;
	}
	if (day.length < 2) {
		day = `0${day}`;
	}

	return [year, month, day].join("-");
}

export function convertBackToOfficial(date) {
	/*
		From YYYY-MM-DD(HH-MM-SS)
		to YYYY-MM-DD'T'HH-MM-SS
	*/
	const parts = date.split("(");
	if (parts.length === 1) {
		return parts[0];
	}
	return `${parts[0]}T${parts[1].replace(")", "")}`;
}

export function getLastMonday() {
	const prevMonday = new Date();
	prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
	prevMonday.setHours(0);
	prevMonday.setMinutes(0);
	prevMonday.setSeconds(0);
	return prevMonday;
}

export function getYesterday() {
	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);
	return yesterday;
}

export function compareDates(someDate1, someDate2) {
	return (
		someDate1.getDate() === someDate2.getDate() &&
		someDate1.getMonth() === someDate2.getMonth() &&
		someDate1.getFullYear() === someDate2.getFullYear()
	);
}

export function distanceDate({
	date,
	options = [
		/*
    long : +1 years
		years : +1 years
		last_year : year prior current one (priority)
		start_of_year : between january 1st and before one month prior
		months : +1 months
		last_month : month prior to current one (priority)
		this_month : current month
		weeks : +1 weeks
    last_week : week prior to current one (priority)
    days : +1 days
    yesterday (priority)
    today (priority)
    hours
    minutes
    moments
    */
	],
}) {
	const realDate = new Date(convertBackToOfficial(date));
	const now = new Date();
	if (
		options.some((opt) =>
			[
				TIME_UNITS.LAST_YEAR,
				TIME_UNITS.START_OF_YEAR,
				TIME_UNITS.LAST_MONTH,
				TIME_UNITS.THIS_MONTH,
				TIME_UNITS.LAST_WEEK,
				TIME_UNITS.YESTERDAY,
				TIME_UNITS.TODAY,
			].includes(opt)
		)
	) {
		if (options.includes(TIME_UNITS.LAST_YEAR) && now.getFullYear() - realDate.getFullYear() === 1) {
			return translate("common:date.last-year");
		}
		if (
			options.includes(TIME_UNITS.START_OF_YEAR) &&
			realDate.getFullYear() === now.getFullYear() &&
			realDate.getMonth() < now.getMonth() - 1
		) {
			return translate("common:date.start-of-year");
		}
		if (
			options.includes(TIME_UNITS.LAST_MONTH) &&
			realDate.getFullYear() === now.getFullYear() &&
			now.getMonth() - realDate.getMonth() === 1
		) {
			return translate("common:date.last-month");
		}
		if (
			options.includes(TIME_UNITS.THIS_MONTH) &&
			realDate.getFullYear() === now.getFullYear() &&
			(!options.includes(TIME_UNITS.TODAY) || !compareDates(now, realDate)) &&
			(!options.includes(TIME_UNITS.YESTERDAY) || !compareDates(getYesterday(), realDate)) &&
			realDate.getMonth() === now.getMonth()
		) {
			return translate("common:date.this-month");
		}
		if (options.includes(TIME_UNITS.LAST_WEEK) && Date.parse(getLastMonday()) > Date.parse(realDate)) {
			return translate("common:date.last-week");
		}
		if (options.includes(TIME_UNITS.YESTERDAY) && compareDates(getYesterday(), realDate)) {
			return translate("common:date.yesterday");
		}
		if (options.includes(TIME_UNITS.TODAY) && compareDates(now, realDate)) {
			return translate("common:date.today");
		}
	}
	const seconds = Math.floor((Date.parse(now) - Date.parse(realDate)) / 1000);
	let interval = seconds / 31536000;
	if (Math.floor(interval) >= 1) {
		if (options.includes(TIME_UNITS.LONG) && Math.floor(interval) > 1) {
			return translate("common:date.long-time-ago");
		}
		if (options.includes(TIME_UNITS.YEARS) && Math.floor(interval) > 1) {
			return translate("common:date.years-ago", { interval: Math.floor(interval) });
		}
		if (options.includes(TIME_UNITS.LAST_YEAR) && Math.floor(interval) === 1) {
			return translate("common:date.year-ago");
		}
	}
	interval = seconds / 2592000;
	if (interval > 1 && options.includes(TIME_UNITS.MONTHS)) {
		return translate("common:date.months-ago", { interval: Math.floor(interval) });
	}
	interval = seconds / 86400;
	if (interval > 1 && options.includes(TIME_UNITS.DAYS)) {
		return translate("common:date.days-ago", { interval: Math.floor(interval) });
	}
	interval = seconds / 3600;
	if (interval > 1 && options.includes(TIME_UNITS.HOURS)) {
		return translate("common:date.hours-ago", { interval: Math.floor(interval) });
	}
	interval = seconds / 60;
	if (interval > 1 && options.includes(TIME_UNITS.MINUTES)) {
		return translate("common:date.minutes-ago", { interval: Math.floor(interval) });
	}
	return translate("common:date.moments-ago");
}

export const nthDay = (d) => {
	if (d > 3 && d < 21) {
		return "th";
	}
	switch (d % 10) {
		case 1:
			return "st";
		case 2:
			return "nd";
		case 3:
			return "rd";
		default:
			return "th";
	}
};

export const buildWordedDate = ({ day, month, year, language }) => {
	if (language === "en") {
		if (year && typeof year === "number") {
			return `${month} ${day}${nthDay(day)}, ${year}`;
		}
		return `${month} ${day}${nthDay(day)}`;
	}
	if (language === "fr") {
		return `${day} ${month} ${year}`;
	}
	return "";
};

export const diplayDate = (dateToDisplay, language) => {
	const date = new Date(convertBackToOfficial(dateToDisplay));
	const day = date.getDate();
	const month = translate(`common:date.month:${date.getMonth()}`);
	const year = date.getFullYear();
	const sameYear = new Date().getFullYear() === year;
	return buildWordedDate({ day, month, year: sameYear ? "" : year, language });
};

export function formatDateYMDHM(date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");

	return `${year}-${month}-${day}_${hours}:${minutes}`;
}
