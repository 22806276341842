import React, { useState } from "react";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import { DeadlineCell, DescriptionCell, PriorityCell, TaskSelectStatus } from ".";
import styles from "./TasksPanelTable.module.css";

export default function TasksPanelTable({
	currentUser,
	content,
	onUpdateDescription,
	onUpdatePriority,
	onUpdateStatus,
	onUpdateDeadline,
}) {
	const [headers] = useState([
		translate("dashboards.task-dashboard.owner"),
		translate("dashboards.task-dashboard.stakeholder"),
		translate("dashboards.task-dashboard.description"),
		translate("dashboards.task-dashboard.priority"),
		translate("dashboards.task-dashboard.status"),
		translate("dashboards.task-dashboard.deadline"),
	]);
	return (
		<CustomTable>
			<CustomTableHead>
				<CustomTableRow>
					{headers.map((header) => (
						<CustomTableCell key={header}>{header}</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody emptyState={translate("dashboards.task-dashboard.no-content")}>
				{Array.isArray(content) &&
					content.map((row) => {
						const isAuthor = row.fromUserEmail === currentUser.email;
						const isRelated = isAuthor || row.toUserEmail === currentUser.email;
						return (
							<CustomTableRow key={row.taskId} className={styles.row}>
								<CustomTableCell align="left">{row.fromUser}</CustomTableCell>
								<CustomTableCell align="left">{row.toUser}</CustomTableCell>
								<CustomTableCell align="left">
									<DescriptionCell
										data={row}
										isAuthor={isAuthor}
										updateDescription={onUpdateDescription}
									/>
								</CustomTableCell>
								<CustomTableCell align="left">
									<PriorityCell
										isAuthor={isAuthor}
										priority={row.priority}
										taskId={row.taskId}
										updatePriority={onUpdatePriority}
									/>
								</CustomTableCell>
								<CustomTableCell align="left">
									{currentUser && (
										<TaskSelectStatus
											isRelated={isRelated}
											status={row.taskStatus}
											taskId={row.taskId}
											updateStatus={onUpdateStatus}
										/>
									)}
								</CustomTableCell>
								<CustomTableCell align="left">
									<DeadlineCell data={row} isAuthor={isAuthor} updateDeadline={onUpdateDeadline} />
								</CustomTableCell>
							</CustomTableRow>
						);
					})}
			</CustomTableBody>
		</CustomTable>
	);
}
