import React from "react";
import { CustomButton, CustomIconButton, icon } from "../../../../../../common/components";
import styles from "./FlagRow.module.css";

export default function FeatRow({ feat, onSelectRow }) {
	const { description, id, name, packages, projects } = feat;
	return (
		<div key={id} className={styles.row}>
			<div>
				<span>{name}</span>
			</div>
			<div>
				<span>{description}</span>
				<CustomIconButton
					btnClassName={styles.import__btn}
					icon={icon.faPen}
					iconColor="var(--color-light-grey-2)"
					size="md"
					onClick={() => {
						onSelectRow(feat, "information");
					}}
				/>
			</div>
			<div>
				<CustomButton
					variant="outlined"
					onClick={() => {
						onSelectRow(feat, "packages");
					}}
				>
					{packages}
				</CustomButton>
			</div>
			<div>
				<CustomButton
					variant="outlined"
					onClick={() => {
						onSelectRow(feat, "projects");
					}}
				>
					{projects}
				</CustomButton>
			</div>
			<div />
		</div>
	);
}
