import React from "react";
import { CircularProgress, List } from "@mui/material";
import DocumentRow from "./DocumentRow";
import styles from "./Documents.module.css";
import { translate } from "../../../../common/providers";
import { BlockTitle } from "../../../../common/components";

export default function Documents({
	documents = [],
	onClickRow,
	selectedDocument = null,
	isLoading,
	projectId,
	showTocRedressment,
	showErrorLog,
}) {
	return (
		<>
			<BlockTitle>{translate("consistency-check.documents.title")}</BlockTitle>
			{!isLoading && (!Array.isArray(documents) || documents.length === 0) ? (
				<div className={styles["empty-state"]}>
					<div className={styles["empty-state__container"]}>
						<strong className={styles["empty-state__container--text"]}>
							{translate("consistency-check.documents.documents-empty-state")}
						</strong>
					</div>
				</div>
			) : (
				<List disablePadding style={{ height: "100%", overflowY: "auto" }}>
					{Array.isArray(documents) &&
						documents.map((document) => (
							<DocumentRow
								key={document.docId}
								document={document}
								isSelected={selectedDocument && selectedDocument.docId === document.docId}
								projectId={projectId}
								selectedDocument={selectedDocument}
								showErrorLog={showErrorLog}
								showTocRedressment={showTocRedressment}
								onClick={() => onClickRow(document)}
							/>
						))}
				</List>
			)}
			{isLoading && (
				<div className={styles.loader}>
					<CircularProgress />
				</div>
			)}
		</>
	);
}
