import React, { useRef, useMemo, useState, useEffect } from "react";
import { dangerousSetInnerHTML, getFilteredDataAttributes } from "../../utils";
import { CustomTooltip } from "..";
import styles from "./TextMidCut.module.css";

// eslint-disable-next-line quotes
const HIGHLIGHT_BEGIN = '<span class="highlight-word">';
const HIGHLIGHT_END = "</span>";

const getAllIndexes = (toSearch, toFind) => {
	const indexes = [];
	let i = -1;
	i = toSearch.indexOf(toFind, i + 1);
	while (i !== -1) {
		indexes.push(i);
		i = toSearch.indexOf(toFind, i + 1);
	}
	return indexes;
};

const TextMidCut = ({
	autoTranslate = "yes",
	charsKeptInEnd = 12,
	className = "",
	fullWidth = false,
	id = "",
	isDangerousSetInnerHTML = false,
	onMouseLeave = null,
	onMouseOver = null,
	showWhenOverflow = false,
	style = null,
	text = "",
	tooltip = false,
	tooltipTitle = "",
	...dataAttributes
}) => {
	const [isSelected, setIsSelected] = useState(false);
	const charsKeptInEndUpdate = useMemo(() => {
		if (!isDangerousSetInnerHTML) {
			return charsKeptInEnd;
		}
		const beginIndexes = getAllIndexes(text, HIGHLIGHT_BEGIN);
		const endIndexes = getAllIndexes(text, HIGHLIGHT_END);
		if (
			!Array.isArray(beginIndexes) ||
			beginIndexes.length === 0 ||
			!Array.isArray(endIndexes) ||
			endIndexes.length === 0
		) {
			return charsKeptInEnd;
		}
		const cutIndex = text.length - charsKeptInEnd;
		let newCharsKeptInEnd;
		beginIndexes.forEach((beginIndex, i) => {
			if (beginIndex < cutIndex && endIndexes[i] + HIGHLIGHT_END.length > cutIndex) {
				newCharsKeptInEnd = text.length - beginIndex;
			}
		});
		return newCharsKeptInEnd;
	}, [isDangerousSetInnerHTML, text, charsKeptInEnd]);
	const textStartRef = useRef();
	const filteredDataAttributes = getFilteredDataAttributes(dataAttributes);
	const containerRef = useRef(null);
	const highlightedElementsRef = useRef([]);
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				setIsSelected(false);
				highlightedElementsRef.current.forEach((span) => {
					span.classList.remove("label__text--fullTextCopied");
					span.classList.add("highlight-word");
				});
			}
		};
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);
	const handleDoubleClick = () => {
		setIsSelected(true);
		const modifiedText = text.replace(/<[^>]+>/g, "");
		navigator.clipboard.writeText(modifiedText).then(() => text);
		const highlightedElements = containerRef.current.querySelectorAll(".highlight-word");
		highlightedElementsRef.current = Array.from(highlightedElements);
		highlightedElements.forEach((span) => {
			span.classList.remove("highlight-word");
			span.classList.add("label__text--fullTextCopied");
		});
	};
	const getTitle = () => {
		if (
			!showWhenOverflow ||
			(showWhenOverflow && textStartRef.current?.offsetWidth < textStartRef.current?.scrollWidth)
		) {
			return tooltipTitle || text;
		}
		return "";
	};
	const label = (
		<div
			ref={containerRef}
			className={`${styles.label__text} ${fullWidth && styles["label__text--fullWidth"]} 
			${isSelected && styles["label__text--fullTextCopied"]} ${className} `}
			id={id}
			style={style}
			onDoubleClick={handleDoubleClick}
			onMouseEnter={onMouseOver}
			onMouseLeave={onMouseLeave}
			{...filteredDataAttributes}
		>
			{text &&
				((text.length <= charsKeptInEndUpdate && (
					<div ref={textStartRef} className={styles["label__text--start"]} translate={autoTranslate}>
						{(isDangerousSetInnerHTML && dangerousSetInnerHTML({ text })) || text}
					</div>
				)) || (
					<>
						<div ref={textStartRef} className={styles["label__text--start"]} translate={autoTranslate}>
							{text.at(text.length - charsKeptInEndUpdate - 1) === " " ? (
								<>
									{(isDangerousSetInnerHTML &&
										dangerousSetInnerHTML({
											text: text.substring(0, text.length - charsKeptInEndUpdate - 1),
										})) ||
										text.substring(0, text.length - charsKeptInEndUpdate - 1)}
									&nbsp;
								</>
							) : (
								(isDangerousSetInnerHTML &&
									dangerousSetInnerHTML({
										text: text.substring(0, text.length - charsKeptInEndUpdate),
									})) ||
								text.substring(0, text.length - charsKeptInEndUpdate)
							)}
						</div>
						<div className={styles["label__text--end"]} translate={autoTranslate}>
							{text.at(text.length - charsKeptInEndUpdate) === " " ? (
								<>
									&nbsp;
									{(isDangerousSetInnerHTML &&
										dangerousSetInnerHTML({
											text: text.substring(text.length + 1 - charsKeptInEndUpdate),
										})) ||
										text.substring(text.length + 1 - charsKeptInEndUpdate)}
								</>
							) : (
								(isDangerousSetInnerHTML &&
									dangerousSetInnerHTML({
										text: text.substring(text.length - charsKeptInEndUpdate),
									})) ||
								text.substring(text.length - charsKeptInEndUpdate)
							)}
						</div>
					</>
				))}
		</div>
	);
	return (
		(tooltip && textStartRef.current?.offsetWidth < textStartRef.current?.scrollWidth && (
			<CustomTooltip title={isDangerousSetInnerHTML ? dangerousSetInnerHTML({ text: getTitle() }) : getTitle()}>
				{label}
			</CustomTooltip>
		)) ||
		label
	);
};

export default TextMidCut;
