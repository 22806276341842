import React, { useRef } from "react";
import { MenuList } from "@mui/material";
import { CustomButton, DropDown, icon } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import styles from "./HelpMenu.module.css";
import { MenuItemSelectable } from "../../drop-down-menus";

const helpCenter = { name: "navigation:help-buttton.help.to-help-center" };
const academyCenter = { name: "navigation:help-buttton.help.to-academy" };
export default function HelpMenu({ open = false, onClose = null, onOpen = null, onOpenHover = null, menuName }) {
	const anchorRef = useRef(null);

	const handleOpen = () => {
		onOpen(menuName);
	};
	const handleOpenHover = () => {
		onOpenHover(menuName);
	};
	const handleClose = () => {
		onClose();
	};
	const handleSelectHelpCenter = () => {
		const hubspotWidget = window.HubSpotConversations.widget;
		hubspotWidget.open();
		handleClose();
	};
	const handleSelectAcademyCenter = () => {
		window.open("https://academy.aitenders.com", "_blank");
		handleClose();
	};
	return (
		<>
			<CustomButton
				ref={anchorRef}
				className={styles.helpButton}
				color="primary"
				endIcon={icon.faCaretDown}
				variant="outlined"
				onClick={handleOpen}
				onMouseOver={handleOpenHover}
			>
				{translate("navigation:help-buttton.help")}
			</CustomButton>
			<DropDown ref={anchorRef} open={open} onClose={handleClose}>
				<MenuList data-testid="helpMenu.menu">
					<MenuItemSelectable menuItem={helpCenter} onSelect={handleSelectHelpCenter} />
					<MenuItemSelectable menuItem={academyCenter} onSelect={handleSelectAcademyCenter} />
				</MenuList>
			</DropDown>
		</>
	);
}
