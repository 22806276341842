import React from "react";
import {
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	CustomTooltip,
	icon,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import styles from "./RelatedNormsTable.module.css";

export default function RelatedNormsTable({ relatedNorms, onSelectRow }) {
	return (
		<CustomTable>
			<CustomTableHead>
				<CustomTableRow>
					<CustomTableCell>{translate("norms-center.norm-details.related-norms.ref")}</CustomTableCell>
					<CustomTableCell>{translate("norms-center.norm-details.related-norms.date")}</CustomTableCell>
					<CustomTableCell>{translate("norms-center.norm-details.related-norms.name")}</CustomTableCell>
					<CustomTableCell className={styles.relatedNorms__headerCellBtn} />
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody emptyState={translate("norms-center.norm-details.empty-state.related-norms")}>
				{Array.isArray(relatedNorms) &&
					relatedNorms.map((row) => (
						<CustomTableRow
							key={row.uuid}
							hover
							onClick={() => {
								onSelectRow(row);
							}}
						>
							{({ isHovering }) => (
								<>
									<CustomTableCell>{row.name}</CustomTableCell>
									<CustomTableCell>{row.date?.replace("/", ".") || "-"}</CustomTableCell>
									<CustomTableCell>
										<CustomTooltip arrow showWhenOverflow title={row.title || ""}>
											<span className={styles.norms__name}>{row.title}</span>
										</CustomTooltip>
									</CustomTableCell>
									<CustomTableCell align="right">
										{isHovering && (
											<CustomIconButton
												icon={icon.faArrowRight}
												size="sm"
												onClick={() => {
													onSelectRow(row);
												}}
											/>
										)}
									</CustomTableCell>
								</>
							)}
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
