import React, { useEffect, useState } from "react";
import AdditionalparametersTable from "./additional-parameters-table/AdditionalParametersTable";

export default function AdditionalParameters({
	coreParameters,
	hasUpdateRights,
	isParameterIncluded,
	isUnconditional,
	onNewParameter,
	onSelected,
	onUpdateParameter,
	parameters,
}) {
	const [isSelected, setIsSelected] = useState(false);
	const [selected, setSelected] = useState({});
	const [isCreating, setIsCreating] = useState(false);

	useEffect(() => {
		onSelected(selected);
	}, [selected, onSelected]);
	const handleCreate = (editedParam) => {
		if (editedParam.name && editedParam.name?.length > 1) {
			if (editedParam.name && editedParam.value && editedParam.value.length > 0) {
				if (!isUnconditional(editedParam.name) && !isParameterIncluded(editedParam.name)) {
					setIsCreating(false);
					onUpdateParameter({
						newParam: { [editedParam.name]: editedParam.value || "" },
					});
				}
			}
		}
	};
	const handleInitiateEdit = (row) => {
		setIsSelected(true);
		setSelected(row);
	};
	const handleDelete = (value) => {
		onUpdateParameter({ oldParam: { [value.name]: value.value } });
	};

	const handleConfirmEdit = (editedParam) => {
		if (
			(editedParam.name &&
				editedParam.name?.length > 1 &&
				(selected.name !== editedParam.name || !selected.name)) ||
			(selected.name && editedParam.name?.length > 1 && (selected.value || selected.value !== editedParam.value))
		) {
			if (editedParam.name && editedParam.value && editedParam.value.length > 0) {
				if (
					!isUnconditional(editedParam.name) &&
					(!isParameterIncluded(editedParam.name) || editedParam.name === selected.name)
				) {
					setIsSelected(false);
					onUpdateParameter({
						newParam: { [editedParam.name]: editedParam.value || "" },
						oldParam: { [selected.name]: selected.value },
					});
					setSelected({});
				}
			}
			onNewParameter(null);
		}
	};
	const handleCancelEdit = () => {
		setIsSelected(false);
		setIsCreating(false);
		setSelected({});
	};

	return (
		<AdditionalparametersTable
			coreParameters={coreParameters}
			hasUpdateRights={hasUpdateRights}
			isCreating={isCreating}
			isParameterIncluded={isParameterIncluded}
			isSelected={isSelected}
			isUnconditional={isUnconditional}
			rows={isCreating ? [{ name: "", value: "" }, ...parameters] : parameters}
			selected={selected}
			setIsCreating={setIsCreating}
			setIsSelected={setIsSelected}
			onCancelEdit={handleCancelEdit}
			onConfirmEdit={handleConfirmEdit}
			onCreate={handleCreate}
			onDelete={handleDelete}
			onInitiateEdit={handleInitiateEdit}
			onNewParameter={onNewParameter}
		/>
	);
}
