import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./QuestionsRightPanel.module.css";
import { InfiniteList2, ViewTabs, Selector } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import { QuestionBox } from "./components";
import { ApiService, SmartViewService, ProjectTeamService } from "../../../../../api";
import QuestionDetailsSidePanel from "./components/QuestionDetailsSidePanel";
import { QUESTION_STATUSES } from "../../../../../common/constants";
import { translateEnumPhaseStatus } from "../../../utils/utils";
import { setDisableKeyEvents, setQuestionsSelectedTab } from "../../../../../common/slice";

export default function QuestionsRightPanel() {
	const selectItems = useMemo(
		() => [
			{ name: translate("smart-review.right-panel.select-status.all-statuses"), value: "" },
			...Object.values(QUESTION_STATUSES).map((item) => ({ name: item, value: item })),
		],
		[]
	);
	const defaultUserItem = useMemo(
		() => ({ displayName: translate("smart-review.right-panel.select-user.all-users") }),
		[]
	);

	const projectId = useSelector(({ context }) => context.project.id);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const selectedTab = useSelector(({ context }) => context.questionsSelectedTab.tab);
	const [selectedUser, setSelectedUser] = useState(defaultUserItem);
	const [selectedStatus, setSelectedStatus] = useState(selectItems[0]);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const [qaDetailsId, setQaDetailsId] = useState(null);
	const [openDetailsSidePanel, setOpenDetailsSidePanel] = useState(false);
	const [questionsCounts, setQuestionsCounts] = useState(null);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	const [users, setUsers] = useState([]);
	useEffect(() => {
		setSelectedStatus(selectItems[0]);
	}, [selectItems]);
	useEffect(() => {
		if (documentId) {
			setInfiniteListRequest(
				() =>
					({ limit, page, search }) =>
						SmartViewService.getQuestions(
							{ documentId },
							{
								linkedToContent: selectedTab === 1,
								linkedToDocument: selectedTab === 0,
								documentIds: {
									is: true,
									matchAll: true,
									elements: [documentId],
								},
								status: selectedStatus?.value || undefined,
								content: search || undefined,
								userIds: (selectedUser?.id && [selectedUser?.id]) || undefined,
								orderedBy: "date",
								direction: "DESC",
							},
							{ limit, page },
							cancelTokenSourceRef.current.token
						)
			);
		}
	}, [documentId, selectedTab, selectedUser, selectedStatus]);
	const loadCounts = useCallback(() => {
		if (documentId) {
			SmartViewService.getQuestionsCounts({ documentId }, cancelTokenSourceRef.current.token)
				.then((data) => {
					setQuestionsCounts(data);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [documentId]);
	const loadUsers = useCallback(() => {
		ProjectTeamService.getUsersInProject({ projectId }, { limit: 100 }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setUsers([defaultUserItem, ...(data?.contents || [])]);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [projectId, defaultUserItem]);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		loadCounts();
		loadUsers();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [loadCounts, loadUsers]);
	const handleClickDetails = (question) => {
		setQaDetailsId(question.id);
		setOpenDetailsSidePanel(true);
	};
	const rowRenderer = (row) => (
		<QuestionBox noAction={selectedTab === 0} question={row} onClickDetails={() => handleClickDetails(row)} />
	);
	const handleChangeSelectedTab = (_, tab) => {
		dispatch(setQuestionsSelectedTab({ tab, index: 0 }));
	};
	const handleCloseDetailsSidePanel = () => {
		setOpenDetailsSidePanel(false);
	};
	const handleChangeUser = (e) => {
		setSelectedUser(e.target.value);
	};
	const handleChangeStatus = (e) => {
		setSelectedStatus(e.target.value);
	};
	return (
		<>
			<div className={styles.main}>
				<ViewTabs
					className={styles.tabs}
					selectedTab={selectedTab}
					tabs={[
						{
							label: translate("smart-review.right-panel.questions.document-tab", {
								questionsCount: questionsCounts?.countByDocument || 0,
							}),
						},
						{
							label: translate("smart-review.right-panel.questions.content-tab", {
								questionsCount: questionsCounts?.countByContent || 0,
							}),
						},
					]}
					onChange={handleChangeSelectedTab}
				/>
				<div className={styles.container__filters}>
					<Selector
						field="displayName"
						items={users}
						textFieldClassName={styles.filter__textField}
						value={(users.includes(selectedUser) && selectedUser) || ""}
						variant="standard"
						onChange={handleChangeUser}
					/>
					<Selector
						className={styles.filter__selector}
						field="name"
						itemRenderer={(item) => (item.toUpperCase() === item ? translateEnumPhaseStatus(item) : item)}
						items={selectItems}
						textFieldClassName={styles.filter__textField}
						value={(selectItems.includes(selectedStatus) && selectedStatus) || ""}
						variant="standard"
						onChange={handleChangeStatus}
					/>
				</div>
				<InfiniteList2
					anticipate
					hasSearch
					className={styles.infiniteList}
					listContainerClassName={styles.listContainer}
					request={infiniteListRequest}
					rowRenderer={rowRenderer}
					scrollContainerClassName={styles.scrollContainer}
					searchPlaceholder={translate("smart-review.search-space.search")}
					textFieldClassName={styles.InfiniteList__textField}
					onSearchBlur={() => dispatch(setDisableKeyEvents(false))}
					onSearchFocus={() => dispatch(setDisableKeyEvents(true))}
				/>
			</div>
			<QuestionDetailsSidePanel
				open={openDetailsSidePanel}
				qaId={qaDetailsId}
				onClose={handleCloseDetailsSidePanel}
			/>
		</>
	);
}
