import { Flags, Permissions, SegFlags } from "../../common/providers";

export default ({ projectId }) => [
	{
		name: "navigation:project.dashboard",
		subItems: [
			{
				name: "navigation:project.my-progress",
				to: `/projects/${projectId}/dashboard/review-progress`,
				segFlag: SegFlags.REVIEW_PROGRESS_DASHBOARD,
			},
			{
				name: "navigation:project.project",
				to: `/projects/${projectId}/dashboard/project`,
				segFlag: SegFlags.PROJECT_DASHBOARD,
			},
			{
				name: "navigation:project.tasks",
				to: `/projects/${projectId}/dashboard/tasks`,
				segFlag: [SegFlags.SMART_REVIEW, SegFlags.TASK],
			},
		],
	},
	{
		name: "navigation:project.search",
		to: `/projects/${projectId}/search/my-search`,
		flag: Flags.SEARCHCARD,
		segFlag: SegFlags.SMART_SEARCH,
	},
	{
		name: "navigation:project.review",
		subItems: [
			{
				name: "navigation:project.my-review",
				to: `/projects/${projectId}/review/my-review`,
				segFlag: [SegFlags.SMART_REVIEW, SegFlags.DOCUMENT_CENTER],
			},
			{
				name: "navigation:project.risk-analysis",
				to: `/projects/${projectId}/synthesis-by-category`,
				flag: Flags.SELECT_SYNTHESIS,
				segFlag: [SegFlags.CATEGORY, SegFlags.REVIEW_BY_CATEGORY],
			},
			{
				name: "navigation:project.project-requirements",
				to: `/projects/${projectId}/review/project-requirements`,
				flag: Flags.REQUIREMENT_MATRIX,
				segFlag: SegFlags.PROJECT_REQUIREMENTS,
			},
			{
				name: "navigation:project.verification-of-detections",
				to: `/projects/${projectId}/review/verification-of-detections`,
				flag: Flags.CONTROL,
				segFlag: SegFlags.VERIFICATION_OF_DETECTIONS,
				role: Permissions.PROJECT_LEADER,
				companyRole: [Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER],
			},
		],
	},
	{
		name: "navigation:project.follow-up",
		subItems: [
			{
				name: "navigation:project.question-to-the-client",
				to: `/projects/${projectId}/follow-up/question-to-the-client`,
				flag: Flags.QA,
				segFlag: SegFlags.QUESTION_ANSWER,
			},
			{
				name: "navigation:project.deliverables",
				subItems: [
					{
						name: "navigation:project.deliverables-to-be-produced",
						to: `/projects/${projectId}/follow-up/deliverables/deliverables-to-be-produced`,
						flag: Flags.OPERATION,
						segFlag: SegFlags.DELIVERABLES,
					},
					{
						name: "navigation:project.assign-to-deliverables",
						to: `/projects/${projectId}/follow-up/deliverables/assign-to-deliverables`,
						flag: Flags.COVERAGE,
						segFlag: SegFlags.DELIVERABLES,
					},
				],
			},
			{
				name: "navigation:project.project-norms",
				to: `/projects/${projectId}/follow-up/project-standards`,
				flag: Flags.NORMS,
				segFlag: SegFlags.NORMS,
			},
		],
	},
	{
		name: "navigation:project.chat-project",
		to: `/projects/${projectId}/chat-project`,
		isBeta: true,
		flag: Flags.PROJECT_CHAT,
		segFlag: SegFlags.CHAT_PROJECT,
	},
];
