import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeService, RequirementParameterService, SearchCardTemplateService } from "../../../../api";
import { SEARCH_TYPES } from "../../../../common/constants/search-types";
import { SCORING_SORT_AVAILABLE } from "../../../../common/constants";

function getProjectId(getState) {
	const {
		companySearchTemplate: { selectedProject: project },
	} = getState();
	return project.projectId || project.id;
}

export const getTypeParameters = createAsyncThunk(
	"companySearchTemplate/getTypeParameters",
	(token) => RequirementParameterService.getReqTypes(token),
	{
		condition: (_, { getState }) => {
			const {
				companySearchTemplate: { types },
			} = getState();
			return !Array.isArray(types) || types.length === 0;
		},
	}
);

export const getCriticalityParameters = createAsyncThunk(
	"companySearchTemplate/getCriticalityParameters",
	(token) => RequirementParameterService.getCriticality(token),
	{
		condition: (_, { getState }) => {
			const {
				companySearchTemplate: { criticalities },
			} = getState();
			return !Array.isArray(criticalities) || criticalities.length === 0;
		},
	}
);

export const getNegotiabilityParameters = createAsyncThunk(
	"companySearchTemplate/getNegotiabilityParameters",
	(token) => RequirementParameterService.getNegotiability(token),
	{
		condition: (_, { getState }) => {
			const {
				companySearchTemplate: { negotiabilities },
			} = getState();
			return !Array.isArray(negotiabilities) || negotiabilities.length === 0;
		},
	}
);

export const search = createAsyncThunk(
	"companySearchTemplate/search",
	({ filters, sort, page, limit, token }, { getState }) => {
		const {
			companySearchTemplate: { isFilterTocOut },
			isDsi,
		} = getState();
		const projectId = getProjectId(getState);
		const payload = { Type: SEARCH_TYPES.template, ...(filters || {}), isFilterTocOut };
		if (SCORING_SORT_AVAILABLE) {
			payload.sort = sort;
		}
		if (isDsi) {
			return BackOfficeService.templateSearch({ projectId }, payload, { page, limit }, token);
		}
		return SearchCardTemplateService.templateSearch({ projectId }, payload, { page, limit }, token);
	}
);

export const getTotalResults = createAsyncThunk("companySearchTemplate/getTotalResults", (token, { getState }) => {
	const {
		companySearchTemplate: { isFilterTocOut },
		isDsi,
	} = getState();
	const projectId = getProjectId(getState);
	if (isDsi) {
		return BackOfficeService.countTemplateResults(
			{ projectId },
			{ Type: SEARCH_TYPES.template, isFilterTocOut },
			token
		);
	}
	return SearchCardTemplateService.countResults(
		{ projectId },
		{ Type: SEARCH_TYPES.template, isFilterTocOut },
		token
	);
});

export const countResults = createAsyncThunk(
	"companySearchTemplate/countResults",
	({ filters, token }, { getState }) => {
		const projectId = getProjectId(getState);
		const {
			companySearchTemplate: { separator, isFilterTocOut },
			isDsi,
		} = getState();
		if (isDsi) {
			return BackOfficeService.countTemplateResults(
				{ projectId },
				{ Type: SEARCH_TYPES.template, ...(filters || {}), separator, isFilterTocOut },
				token
			);
		}
		return SearchCardTemplateService.countResults(
			{ projectId },
			{ Type: SEARCH_TYPES.template, ...(filters || {}), separator, isFilterTocOut },
			token
		);
	}
);

export const getSortingAlgorithms = createAsyncThunk("mySearchTemplate/getSortingAlgorithms", (token) =>
	SearchCardTemplateService.getSortingAlgorithms(token)
);
