import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OpportunityService } from "../../../api";

export const getOpportunities = createAsyncThunk(
	"opportunity/getOpportunities",
	({ ownerIds, name, page = 0, limit = 15, token }) =>
		OpportunityService.getAllOpportunity({ ownerIds, name }, { page, limit }, token)
);

const initialState = {
	isUploading: false,
	docsFailed: false,
	openUploadDialog: false,
	uploadProgress: 0,
	uploadedFile: [],
	opportunityList: [],
	totalElements: 0,
	isLoadingList: true,
	ownerFilter: [],
	filteredIds: [],
	needUpdate: false,
	searchValue: "",
	checkedUsers: [],
	openCancelDialogAll: false,
	isClose: false,
	teamMembers: [],
};
const opportunitySlice = createSlice({
	name: "opportunity",
	initialState,
	reducers: {
		setTeamMembers: (state, { payload }) => ({ ...state, teamMembers: payload }),
		setOpenUploadDialog: (state, { payload }) => ({ ...state, openUploadDialog: payload }),
		setUploadedFile: (state, { payload }) => ({ ...state, uploadedFile: payload }),
		setOwnerFilter: (state, { payload }) => ({ ...state, ownerFilter: payload }),
		setFilteredIds: (state, { payload }) => ({ ...state, filteredIds: payload }),
		setNeedUpdate: (state, { payload }) => ({ ...state, needUpdate: payload }),
		setSearchValue: (state, { payload }) => ({ ...state, searchValue: payload }),
		setCheckedUsers: (state, { payload }) => ({ ...state, checkedUsers: payload }),
		setUploadProgress: (state, { payload }) => ({
			...state,
			uploadProgress: payload,
		}),
		setIsUploading: (state, { payload }) => ({ ...state, isUploading: payload }),
		setOpenCancelDialogAll: (state, { payload }) => ({ ...state, openCancelDialogAll: payload }),
		setIsClose: (state, { payload }) => ({ ...state, isClose: payload }),
	},
	extraReducers: (builder) => {
		builder.addCase(getOpportunities.fulfilled, (state, { payload }) => ({
			...state,
			opportunityList: payload.contents,
			totalElements: payload.totalElements,
			isLoadingList: false,
		}));
		builder.addCase(getOpportunities.pending, (state) => ({
			...state,
			isLoadingList: true,
		}));
	},
});

export const {
	setTeamMembers,
	setOpenUploadDialog,
	setUploadedFile,
	setOwnerFilter,
	setFilteredIds,
	setNeedUpdate,
	setSearchValue,
	setCheckedUsers,
	setUploadProgress,
	setIsUploading,
	setOpenCancelDialogAll,
	setIsClose,
} = opportunitySlice.actions;
export default opportunitySlice.reducer;
