import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./OptionsPanel.module.css";
import { ViewTabPanel, ViewTabs } from "../../../../../common/components";
import { Flags, isFeatureEnabled, isSegFeatureEnabled, SegFlags, translate } from "../../../../../common/providers";
import TABS from "./tabs";
import { FLAGS } from "../../../../../common/providers/feature-flag-provider";
import { ROLES } from "../../../constants/constants";
import { setIsSidePanelOpenByFilter, setSidePanel, setTabToOpen } from "../../../slice/document/document-slice";
import { SOURCE_TYPES } from "../../../../../common/constants";
import { debounce } from "../../../../../common/utils";
import { AdditionalOptions, AdditionalSidePanel, PanelHeader } from "./components";
import { setDisableKeyEvents } from "../../../../../common/slice";
import SmartReviewContext from "../../../context/smart-review-context";
import { KEY_SMART_VIEW } from "../../../../../common/providers/keys/keys";
import { setScrollTo } from "../../../slice/pdf/pdf-slice";
import { generateSearchPosition } from "../../../utils/utils";

const debounceFunc = debounce((func) => func(), 100);

const OptionsPanel = () => {
	const [selectedTab, setSelectedTab] = useState("positions");
	const [additionalSidePanelInformation, setAdditionalSidePanelInformation] = useState(null);
	const dispatch = useDispatch();
	const open = useSelector(({ srDocument }) => srDocument.sidePanelOpen);
	const pageStart = useSelector(({ srDocument }) => srDocument?.selectedSearch?.pageStart);
	const tabToOpen = useSelector(({ srDocument }) => srDocument.tabToOpen);
	const selectedMode = useSelector(({ srDocument }) => srDocument.mode);
	const myDocInfo = useSelector(({ srDocument }) => srDocument);
	const isRequirement = useSelector(({ srDocument }) => srDocument.sidePanelIsRequirement);
	const informationId = useSelector(({ srDocument }) => srDocument.sidePanelInformationId);
	const selectedSearch = useSelector(({ srDocument }) => srDocument.selectedSearch);
	const roles = useSelector(({ srDocument }) => srDocument.roles);
	const updatedInformationType = useSelector(({ srDocument }) => srDocument.updatedInformationType);
	const updatedInformationId = useSelector(({ srDocument }) => srDocument.updatedInformationId);
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const content = useSelector(({ srPdf }) => srPdf.content);
	const mode = useSelector(({ srDocument }) => srDocument.mode);

	const finalAccesses = useMemo(() => {
		if (Array.isArray(roles)) {
			const readingRole =
				(!roles.some((r) => ROLES[r].mode === ROLES.REVIEWER.mode) && [ROLES.REVIEWER.mode]) || [];
			return [...roles.map((r) => ROLES[r].mode), ...readingRole];
		}
		return [];
	}, [roles]);
	const tabHeaders = useMemo(
		() => [
			{
				id: SOURCE_TYPES.POSITIONS,
				label: translate("common:filters.select-filter.menus.positions"),
				disabled:
					!isFeatureEnabled(FLAGS.SR_PANEL_POSITIONS) || !isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
				tab: "PositionsTab",
			},
			{
				id: SOURCE_TYPES.COMMENTS,
				label: translate("smart-review.cmp-details.option-panel.comments"),
				tab: "CommentsTab",
			},
			{
				id: SOURCE_TYPES.CATEGORY,
				label: translate("smart-review.cmp-details.option-panel.categories"),
				disabled: !isSegFeatureEnabled(SegFlags.CATEGORY),
				tab: "CategoriesTab",
			},
			{
				id: SOURCE_TYPES.TASKS,
				label: translate("smart-review.cmp-details.option-panel.tasks"),
				disabled: !isSegFeatureEnabled(SegFlags.TASK) || !isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
				tab: "TasksTab",
			},
			{
				id: SOURCE_TYPES.QUESTIONS,
				label: translate("smart-review.cmp-details.option-panel.questions"),
				disabled: !isSegFeatureEnabled(SegFlags.QUESTION_ANSWER),
				tab: "QuestionsTab",
			},
			{
				id: SOURCE_TYPES.DELIVERABLES,
				label: translate("smart-review.cmp-details.option-panel.deliverables"),
				disabled:
					!isSegFeatureEnabled(SegFlags.DELIVERABLES) || !isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
				tab: "DeliverablesTab",
			},
			{
				id: SOURCE_TYPES.VERSIONING,
				label: translate("smart-review.cmp-details.option-panel.versioning"),
				tab: "VersioningTab",
			},
			{
				id: SOURCE_TYPES.SIMILAR_CONTENT,
				disabled: !isFeatureEnabled(Flags.SIMILAR_CONTENTS) || !isSegFeatureEnabled(SegFlags.EMBEDDING),
				label: translate("smart-review.cmp-details.option-panel.similar-content"),
				tab: "SimilarContentTab",
			},
			{
				id: SOURCE_TYPES.LINK_BETWEEN_CONTENTS,
				disabled: !isSegFeatureEnabled(SegFlags.LINKS) || !isFeatureEnabled(Flags.INFORMATION_LINKS),
				label: translate("smart-review.cmp-details.option-panel.link-tab"),
				tab: "LinkTab",
			},
			{
				id: SOURCE_TYPES.HISTORY,
				disabled: !isFeatureEnabled(Flags.SR_HISTORY),
				label: translate("smart-review.cmp-details.option-panel.history"),
				tab: "HistoryTab",
			},
		],
		[]
	);

	useEffect(
		() => () => {
			if (disableKeyEvents) {
				dispatch(setDisableKeyEvents(false));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => {
		if (open && tabToOpen) {
			const tab = tabHeaders.filter((tabHeader) => !tabHeader.disabled)[
				tabHeaders
					.filter((tabHeader) => !tabHeader.disabled)
					.findIndex((tabHeader) => tabHeader.id === tabToOpen)
			];
			debounceFunc(() => setSelectedTab(tab.id || SOURCE_TYPES.POSITIONS));
		}
	}, [isRequirement, open, tabHeaders, tabToOpen]);
	const handleCloseAdditionalSidePanel = () => {
		setAdditionalSidePanelInformation(null);
	};
	useEffect(() => {
		handleCloseAdditionalSidePanel();
	}, [informationId]);
	const handleAutoScroll = () => {
		dispatch(setScrollTo({ ...generateSearchPosition(selectedSearch) }));
	};
	const handleCloseSidePanel = () => {
		dispatch(
			setSidePanel({
				sidePanelOpen: false,
				informationId: null,
				page: 0,
				tabToOpen,
				isRequirement,
			})
		);
		dispatch(setIsSidePanelOpenByFilter(false));
		SmartReviewContext.setSidePanelInfo(null);
	};

	const contentId = useMemo(() => {
		const pageContent = content.find((c) => c.page === pageStart)?.[mode];
		if (pageContent) {
			const clientId = pageContent.find((c) => c.informationId === informationId)?.clientRequirementId;
			if (clientId) {
				return clientId.toUpperCase();
			}
		}
		return null;
	}, [content, informationId, pageStart, mode]);
	const handleOpenAdditionalSidePanel = (info) => {
		setAdditionalSidePanelInformation(info);
	};
	const handleChangeTab = (_, tab) => {
		const { sidePanelInfo } = JSON.parse(window.sessionStorage.getItem(KEY_SMART_VIEW)) || {};
		setSelectedTab(tab);
		dispatch(setTabToOpen(tab));
		if (disableKeyEvents) {
			dispatch(setDisableKeyEvents(false));
		}
		SmartReviewContext.setSidePanelInfo({
			...sidePanelInfo,
			tabToOpen: tab,
		});
	};
	return (
		<div className={styles.panel}>
			<PanelHeader
				contentId={contentId}
				informationId={informationId}
				isRequirement={isRequirement}
				updatedInformationId={updatedInformationId}
				updatedInformationType={updatedInformationType}
				onAutoScroll={handleAutoScroll}
				onClose={handleCloseSidePanel}
			/>
			<div className={styles.panel__content}>
				<ViewTabs
					className={styles.tabs}
					selectedTab={selectedTab}
					tabIdentifier="id"
					tabs={tabHeaders.filter((th) => !th.disabled)}
					variant="scrollable"
					onChange={handleChangeTab}
				/>
				<div className={styles.panel__subcontent}>
					{tabHeaders
						.filter((tabHeader) => !tabHeader?.disabled)
						.map((tab) => (
							<ViewTabPanel key={tab.tab} noPadding index={tab.id} value={selectedTab}>
								{TABS[tab.tab]({
									informationId,
									onOpenAdditionalSidePanel: handleOpenAdditionalSidePanel,
									pageStart,
									selectedMode,
									finalAccesses,
									myDocInfo,
									isRequirement,
									roleOnDoc:
										Object.values(ROLES).find((r) => r.mode === selectedMode)?.name ||
										ROLES.REVIEWER.name,
								})}
							</ViewTabPanel>
						))}
				</div>
			</div>
			{additionalSidePanelInformation !== null && (
				<AdditionalSidePanel
					information={additionalSidePanelInformation}
					myDocInfo={myDocInfo}
					selectedMode={selectedMode}
					onClose={handleCloseAdditionalSidePanel}
				>
					<AdditionalOptions
						information={additionalSidePanelInformation}
						myDocInfo={myDocInfo}
						selectedMode={selectedMode}
					/>
				</AdditionalSidePanel>
			)}
		</div>
	);
};

export default OptionsPanel;
