import React, { useEffect, useMemo, useState } from "react";
import styles from "./ProjectList.module.css";
import { BackOfficeService } from "../../../../../../api";
import { isNonEmptyArray } from "../../../../../../common/utils";
import { CustomSwitch, OptionsPanelContainer, SearchInput } from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import Box from "../../../box/Box";
import { useApi } from "../../../../../../common/hooks";

export default function ProjectList({ companyId, templateId, onUpdateRow }) {
	const { call: getActiveTemplateProjects } = useApi(BackOfficeService.getActiveTemplateProjects);
	const { call: updateExportTemplatesLinkWithProject } = useApi(
		BackOfficeService.updateExportTemplatesLinkWithProject
	);
	const [projects, setProjects] = useState([]);
	const [tempProjects, setTempProjects] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [show, setShow] = useState(false);

	const hasDiffBetweenProjectsAndTempProjects = useMemo(() => {
		if (!isNonEmptyArray(projects) || !isNonEmptyArray(tempProjects)) {
			return false;
		}
		return projects.every((project, i) => project.active === tempProjects[i].active);
	}, [projects, tempProjects]);
	const getSearchFilteredProjects = useMemo(() => {
		let newTempProject = tempProjects;
		if (show) {
			newTempProject = newTempProject.filter((tempProject) => tempProject.active);
		}
		return newTempProject.filter((project) =>
			project.name?.toLowerCase().includes(searchInput?.toLowerCase() || "")
		);
	}, [searchInput, tempProjects, show]);
	const selectedCount = useMemo(
		() => tempProjects.filter((tempProject) => tempProject.active).length,
		[tempProjects]
	);
	useEffect(() => {
		getActiveTemplateProjects({ companyId, templateId }).then((data) => {
			setProjects(data);
			setTempProjects(data);
		});
	}, [getActiveTemplateProjects, companyId, templateId]);
	const handleCheckProject = (project) => {
		setTempProjects((prev) =>
			prev.map((prevTempProject) => {
				if (prevTempProject.id === project.id) {
					return { ...prevTempProject, active: !prevTempProject.active };
				}
				return prevTempProject;
			})
		);
	};
	const handleReset = () => {
		setTempProjects(projects);
	};
	const handleChangeSearch = (e) => {
		setSearchInput(e.target.value);
	};
	const handleClearSearch = () => {
		setSearchInput("");
	};
	const handleSave = () => {
		let toAdd = [];
		let toRemove = [];
		projects.forEach((project, i) => {
			if (project.active !== tempProjects[i].active) {
				if (tempProjects[i].active) {
					toAdd = [...toAdd, tempProjects[i].id];
				} else {
					toRemove = [...toRemove, tempProjects[i].id];
				}
			}
		});
		updateExportTemplatesLinkWithProject(
			{ companyId, templateId },
			{
				toAdd: isNonEmptyArray(toAdd) ? toAdd : undefined,
				toRemove: isNonEmptyArray(toRemove) ? toRemove : undefined,
			}
		)
			.then(() => {
				setProjects(tempProjects);
				onUpdateRow(templateId, selectedCount);
			})
			.catch(console.error);
	};
	return (
		<OptionsPanelContainer
			buttonText={translate("common:btn.save")}
			buttonTextSecondary={translate("common:btn.cancel")}
			disabledPrimaryButton={hasDiffBetweenProjectsAndTempProjects}
			disabledSecondaryButton={hasDiffBetweenProjectsAndTempProjects}
			onClick={handleSave}
			onClickSecondary={handleReset}
		>
			<div className={styles.container}>
				<div>
					<SearchInput
						fullWidth
						disabled={projects.length === 0}
						placeholder={translate("common:btn.search")}
						value={searchInput}
						onChange={handleChangeSearch}
						onClearSearch={handleClearSearch}
					/>
				</div>
				<div>
					<span>
						{translate("dsi.feature-management.panel.project.selected-count", {
							count: selectedCount,
						})}
					</span>
					<span className={styles.switch}>
						<CustomSwitch checked={show} color="primary" onChange={() => setShow((prev) => !prev)} />
						{translate("common:list.show-selected")}
					</span>
				</div>
				{isNonEmptyArray(getSearchFilteredProjects) &&
					getSearchFilteredProjects.map((project) => (
						<Box
							key={project.id}
							checked={project?.active}
							data={project}
							title={project.name}
							onCheck={handleCheckProject}
						/>
					))}
			</div>
		</OptionsPanelContainer>
	);
}
