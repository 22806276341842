import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import Loader from "./Loader";
import { views } from "../../../views";
import { checkValidity } from "../..";
import { NotFound } from "../../../common/components";
import { NavRoute, NavRouteWrapper } from ".";
import OnboardingProject from "../../../views/onboarding-project/OnboardingProject";
import { usePrevious } from "../../../common/hooks";
import { resetDocument, setIsSidePanelOpenByNavRoute } from "../../../views/my-review/slice/document/document-slice";
import { getSegFlags } from "../../../common/providers/segmentation-flag-provider";
import { isNonEmptyArray, isNonEmptyObject } from "../../../common/utils";
import { getFlags } from "../../../common/providers/feature-flag-provider";

function generateNavRoute(view, index) {
	if (!checkValidity({ flag: view.flag, segFlag: view.segFlag })) {
		return null;
	}
	let path = view.path || null;
	if (Array.isArray(view.path) && view.path.length > 0) {
		path = view.path.map((p) => (checkValidity(p) && p.path) || null).filter(Boolean);
	}
	if (!path || (Array.isArray(path) && path.length === 0)) {
		return null;
	}
	if (Array.isArray(path)) {
		return (
			<Route
				key={index}
				exact
				element={
					<NavRouteWrapper localesPath={view.localesPath}>
						<NavRoute companyRole={view.companyRole} Component={view.component} role={view.role} />
					</NavRouteWrapper>
				}
				path={path[0]}
			>
				{path.slice(1).map((p) => (
					<Route
						key={index}
						exact
						element={
							<NavRouteWrapper localesPath={view.localesPath}>
								<NavRoute companyRole={view.companyRole} Component={view.component} role={view.role} />
							</NavRouteWrapper>
						}
						path={p}
					/>
				))}
			</Route>
		);
	}
	return (
		<Route
			key={index}
			exact
			element={
				<NavRouteWrapper localesPath={view.localesPath}>
					<NavRoute companyRole={view.companyRole} Component={view.component} role={view.role} />
				</NavRouteWrapper>
			}
			path={path}
		/>
	);
}

export default function NavRouteSwitch() {
	const segFlags = useSelector(({ context }) => context.segFlags);
	const flags = useSelector(({ context }) => context.flags);
	const loadingSegFlags = useSelector(({ context }) => context.loadingSegFlags);
	const loadingFlags = useSelector(({ context }) => context.loadingFlags);
	const location = useLocation();
	const dispatch = useDispatch();
	const getNavRoutes = useCallback(() => Object.keys(views).map((v, index) => generateNavRoute(views[v], index)), []);
	const loadedViews = useMemo(() => {
		const isInProject = location.pathname.includes("/projects/");
		const isInDSI = location.pathname.endsWith("/dsi");
		// Need to be checked if logged with a project and then decide if segFlags have to be loaded
		if (
			(isInProject && segFlags && isNonEmptyArray(getSegFlags()) && flags) ||
			(isInDSI && flags) ||
			(!isInProject && !isInDSI)
		) {
			return getNavRoutes();
		}
		return [];
	}, [getNavRoutes, location, segFlags, flags]);

	const prevLocation = usePrevious(location);
	useEffect(() => {
		const matches = new RegExp(/\/projects\/[0-9]*\/review\/my-review\//);
		if (
			prevLocation?.pathname &&
			prevLocation.pathname !== location.pathname &&
			matches.test(prevLocation.pathname) &&
			!matches.test(location.pathname)
		) {
			dispatch(resetDocument());
			dispatch(setIsSidePanelOpenByNavRoute(true));
		}
	}, [dispatch, location, prevLocation]);
	return loadingSegFlags ||
		loadingFlags ||
		!Array.isArray(loadedViews) ||
		loadedViews.length === 0 ||
		!isNonEmptyObject(getFlags()) ? (
		<Loader />
	) : (
		<Routes>
			{loadedViews.map((view) => view)}
			<Route exact element={<NavRoute Component={OnboardingProject} />} path="/projects/join" />
			<Route exact element={<NotFound />} path="*" />
		</Routes>
	);
}
