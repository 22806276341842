import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	CommonFilters,
	DocumentFilter,
	FiltersWrapper,
	PositionFilter,
	ProjectCategoriesFilter,
	generateFilters,
} from "../../../../../common/components/filters5";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../../common/providers";
import { RequirementMatrixService } from "../../../../../api";
import { useApi } from "../../../../../common/hooks";
import styles from "./CoverageFilters.module.css";

const CoverageFilters = ({ defaultFilters, onClickSearch }) => {
	const { call: getParametersForInformation } = useApi(RequirementMatrixService.getParametersForInformation);
	const { call: getRequirementsInProjectCount } = useApi(RequirementMatrixService.getRequirementsInProjectCount);
	const { call: getCompaniesSharedPrivacyInProject } = useApi(
		RequirementMatrixService.getCompaniesSharedPrivacyInProject
	);
	const [filtersPreviewRequest, setFiltersPreviewRequest] = useState();
	const [parameters, setParameters] = useState({});
	const [partners, setPartners] = useState([]);
	const projectId = useSelector(({ context }) => context.project?.id);
	const handleApply = (newFilters) => {
		onClickSearch(newFilters);
	};
	useEffect(() => {
		getCompaniesSharedPrivacyInProject({ projectId })
			.then((data) => setPartners(data))
			.catch((err) => {
				console.error(err);
			});
	}, [getCompaniesSharedPrivacyInProject, projectId]);
	useEffect(() => {
		getParametersForInformation()
			.then((data) => setParameters(data))
			.catch((err) => {
				console.error(err);
			});
	}, [getParametersForInformation]);
	useEffect(() => {
		if (projectId) {
			setFiltersPreviewRequest(() => (previewFilters) => {
				const newFilters = { ...generateFilters(previewFilters) };
				return getRequirementsInProjectCount({ projectId }, newFilters).then((data) => data);
			});
		}
	}, [getRequirementsInProjectCount, projectId]);
	const customLabelApply = (results) => {
		if (results <= 1 && results !== null) {
			return translate("common:component.filter.apply", { results });
		}
		if (results > 1) {
			return translate("common:component.filters.apply", { results });
		}
		return translate("common:btn.apply");
	};
	return (
		<div className={styles.filtersContainer}>
			<FiltersWrapper
				hasSearch
				components={[
					{
						default: true,
						enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
						component: CommonFilters.DOCUMENTS,
						renderer: <DocumentFilter />,
						withDescription: false,
					},
					{
						default: true,
						enabled: isSegFeatureEnabled(SegFlags.CATEGORY),
						component: CommonFilters.CATEGORIES,
						renderer: <ProjectCategoriesFilter disableFavoriteCategories />,
						hasFavorites: true,
					},
					{
						default: true,
						enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
						withDescription: false,
						multiSelection: true,
						labelKey: "label",
						valueKey: "key",
						component: CommonFilters.REQUIREMENT,
					},
					{
						default: true,
						enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
						component: CommonFilters.POSITIONS,
						renderer: <PositionFilter />,
						partners: partners.map((partner) => ({ ...partner, display: true })),
					},
					{
						enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
						labelKey: "label",
						valueKey: "key",
						component: CommonFilters.VERSION,
					},
					{
						enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
						component: CommonFilters.CRITICALITY,
						labelKey: "label",
						valueKey: "value",
						dynamicItems: parameters?.criticality?.map((c) => ({
							label: `common:enum.params.criticality.${c.toLowerCase()}`,
							value: c,
						})),
					},
					{
						enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
						component: CommonFilters.NEGOTIABILITY,
						labelKey: "label",
						valueKey: "value",
						dynamicItems: parameters?.negotiability?.map((n) => ({
							label: `common:enum.params.negotiability.${n.toLowerCase()}`,
							value: n,
						})),
					},
					{
						enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
						component: CommonFilters.TYPE,
						labelKey: "label",
						valueKey: "value",
						dynamicItems: parameters?.type?.map((t) => ({
							label: `common:enum.params.type.${t.toLowerCase()}`,
							value: t,
						})),
					},
				]}
				customLabelApply={customLabelApply}
				defaultFilters={defaultFilters}
				previewRequest={filtersPreviewRequest}
				secondaryComponents={[
					{
						default: true,
						enabled: true,
						withDescription: false,
						component: CommonFilters.ALLOCATE,
					},
					{
						default: true,
						enabled: true,
						withDescription: false,
						component: CommonFilters.TO_BE_TESTED,
					},
					{
						default: true,
						enabled: true,
						withDescription: false,
						component: CommonFilters.EXCLUDED,
					},
				]}
				onApply={handleApply}
			/>
		</div>
	);
};

export default CoverageFilters;
