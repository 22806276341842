import React from "react";
import { SidePanelContent, SidePanel } from "../../../../../common/components";
import { Widgets } from ".";
import { translate } from "../../../../../common/providers";

export default function WidgetSidePanel({
	nature,
	onActive: onActiveFromParent = null,
	onChangeCompany,
	onChangeUrl,
	onClose,
	open,
	openProjectId,
	project,
	projects,
	selectedIndex,
}) {
	return (
		<SidePanel open={open} size={65} onActive={onActiveFromParent} onClose={onClose}>
			{({ onActive }) => (
				<SidePanelContent
					title={translate("dashboards.portfolio-project-list.side-panel.title")}
					onActive={onActive}
					onClose={onClose}
				>
					<Widgets
						nature={nature}
						openProjectId={openProjectId}
						project={project}
						projects={projects}
						selectedIndex={selectedIndex}
						onChangeCompany={onChangeCompany}
						onChangeUrl={onChangeUrl}
					/>
				</SidePanelContent>
			)}
		</SidePanel>
	);
}
