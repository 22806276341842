import React from "react";
import { ProgressBar, IconComponent, CustomTooltip, CustomBadge } from "../../../../common/components";

import styles from "./UserDetails.module.css";

export const CanvasDocCard = ({ text, value, icon, color }) => (
	<CustomTooltip title={`${text} : ${value}`}>
		<CustomBadge
			badgeClass={styles.canvasDocCard__badge}
			className={styles.canvasDocCard__container}
			content={value}
			max={9999}
		>
			<IconComponent color={color} icon={icon} size="lg" />
		</CustomBadge>
	</CustomTooltip>
);

export const RateProgressBar = ({ rate }) => <ProgressBar label={`${Math.trunc(rate * 100)}%`} value={rate} />;
