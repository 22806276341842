import React from "react";
import { aitendersLogo, googleChromeLogo, operaLogo, microsoftEdge } from "../../common/resources/images";
import styles from "./NotSupported.module.css";
import packagejson from "../../../package.json";

const { version } = packagejson;

export default function NotSupported() {
	document.title = "Not supported | Aitenders";
	return (
		<div className={styles.mainContainer}>
			<div>
				<img alt="aitenders_logo" src={aitendersLogo} width="310" />
				<span aria-hidden="true" className={styles["versioning-btn"]}>
					v{version}
				</span>
			</div>
			<div className={styles.textContainer}>
				<div className={styles.primaryText}>Your browser is not fully supported.</div>
				<div className={styles.secondaryText}>
					To use Aitenders we recommend to use the latest version of the following browsers:
				</div>
			</div>
			<div className={styles.browsersList}>
				<div>
					<img alt="googlechrome_logo" height="48" src={googleChromeLogo} width="48" />
					<a
						className={styles.downloadLink}
						href="https://www.google.com/chrome/?brand=CHBD&brand=FHFK&gclid=Cj0KCQiAvvKBBhCXARIsACTePW8bNgLEO_C8J5pm2WKoL5jgzWNCkOxc_Grc38-KR8zaYt5fXsxcLR4aAtI2EALw_wcB&gclsrc=aw.ds"
						target="_self"
					>
						Google Chrome
					</a>
				</div>
				<div>
					<img alt="opera_logo" className={styles.operaLogo__img} height="48" src={operaLogo} width="48" />
					<span className={styles.downloadLink}>
						<span>Opera</span>
					</span>
				</div>
				<div>
					<img alt="microsoftedge_logo" height="48" src={microsoftEdge} width="48" />
					<a className={styles.downloadLink} href="https://www.microsoft.com/en-us/edge" target="_self">
						<span>Microsoft Edge</span>
					</a>
				</div>
			</div>
		</div>
	);
}
