import React from "react";
import styles from "./UnsupportedInformationBanner.module.css";
import { CustomButtonLink, I18nTranslate, IconComponent, icon } from "../../../../../common/components";
import { isNonEmptyArray } from "../../../../../common/utils";
import { translate } from "../../../../../common/providers";

export default function UnsupportedInformationBanner({ unsupportedDocuments }) {
	return (
		(isNonEmptyArray(unsupportedDocuments) && (
			<header className={styles.container}>
				<div>
					<IconComponent color="var(--color-red)" icon={icon.faExclamationTriangle} />
					<I18nTranslate
						param={{ unsupportedText: styles.unsupportedText, unsupported: unsupportedDocuments.length }}
						translationKey={
							(unsupportedDocuments.length === 1 &&
								"new-project.details-and-documents.unsupported.count.singular") ||
							"new-project.details-and-documents.unsupported.count.plural"
						}
					/>
				</div>
				<div>
					<span>{translate("new-project.details-and-documents.unsupported.information")}</span>
					<CustomButtonLink
						openInNewTab
						className={styles.link}
						color="secondary"
						to={translate("new-project.details-and-documents.unsupported.link-address")}
						variant="text"
					>
						{translate("common:redirection.to-know-more")}
					</CustomButtonLink>
				</div>
			</header>
		)) ||
		null
	);
}
