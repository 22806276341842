import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styles from "./RightPanels.module.css";
import { OptionsPanel, tabs } from ".";
import SelectionPanel from "./selection-panel/SelectionPanel";

export default function RightPanels({ isSelectionPanelOn, menu }) {
	const sidePanelOpen = useSelector(({ srDocument }) => srDocument.sidePanelOpen);
	const sidePanelInformationId = useSelector(({ srDocument }) => srDocument.sidePanelInformationId);
	const selectedTab = useMemo(() => tabs.find((tab) => tab.index === menu), [menu]);
	return (
		<div
			className={styles.rightPanel__contentContainer}
			data-has-floating-panel={isSelectionPanelOn || sidePanelOpen}
		>
			{(isSelectionPanelOn && <SelectionPanel />) ||
				(sidePanelOpen && sidePanelInformationId && <OptionsPanel />)}
			<div className={styles.rightPanel__panels}>
				{(typeof selectedTab?.component === "function" && selectedTab.component()) || null}
			</div>
		</div>
	);
}
