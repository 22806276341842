import React, { useCallback, useState, useEffect, useRef } from "react";
import { Divider } from "@mui/material";
import { translate } from "../../../../common/providers";
import { CustomDialog, createNotification } from "../../../../common/components";
import PasswordSection from "./PasswordSection";
import FieldSection from "./FieldSection";
import { BackOfficeService, ApiService } from "../../../../api";

const isEmail = (email) => /^[a-zA-Z0-9+_.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);

export default function EditUserDialog({ onClose, onUpdate, open, selectedCompany, user }) {
	const [payload, setPayload] = useState({
		firstname: "",
		lastname: "",
		email: "",
		password: "",
	});
	const [errors, setErrors] = useState({ firstname: false, lastname: false, email: false, password: false });
	const [collapsed, setCollapsed] = useState({ firstname: true, lastname: true, email: true, password: false });
	const reset = () => {
		setErrors({ firstname: false, lastname: false, email: false, password: false });
	};
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => () => reset(), []);

	useEffect(() => {
		setPayload({
			firstname: user.firstName || "",
			lastname: user.lastName || "",
			email: user.email || "",
			password: "",
		});
	}, [user]);

	const handleCloseAndAction = () => {
		if (Object.keys(errors).some((key) => errors[key])) {
			return;
		}
		const newPayload = {};
		for (const prop in payload) {
			if (payload[prop]) {
				if (prop !== "email" || (prop === "email" && payload[prop] !== user.email)) {
					newPayload[prop] = payload[prop];
				}
			}
		}
		BackOfficeService.updateUserInfo({ userId: user.id }, newPayload, cancelTokenSourceRef.current.token)
			.then((data) => {
				onUpdate(data, selectedCompany.id);
				createNotification({
					type: "success",
					message: translate("dsi.user-details.dialog.success"),
				});
			})
			.catch((err) => {
				console.error(err);
				createNotification({
					type: "error",
					message: translate("dsi.user-details.dialog.failure"),
				});
			})
			.finally(onClose);
	};

	const handleChange = (field, value) => {
		if (!field) {
			return;
		}
		if (!value || (value && value.length < 2) || (field === "email" && !isEmail(value))) {
			setErrors((prev) => ({ ...prev, [field]: true }));
		} else {
			setErrors((prev) => ({ ...prev, [field]: false }));
		}
		setPayload((prev) => ({ ...prev, [field]: value }));
	};

	const handleCollapse = (type) => setCollapsed((prev) => ({ ...prev, [type]: !prev[type] }));
	const handlePassword = useCallback((pwd) => {
		const { password, isPasswordLongEnough, containsNumbers, hasUppercase, hasLowerCase, containsSymbol } = pwd;
		if (isPasswordLongEnough && containsNumbers && hasUppercase && hasLowerCase && containsSymbol) {
			setPayload((prev) => ({ ...prev, password }));
			setErrors((prev) => ({ ...prev, password: false }));
		} else {
			setErrors((prev) => ({ ...prev, password: true }));
			setPayload((prev) => ({ ...prev, password: "" }));
		}
	}, []);

	return (
		<CustomDialog
			isForm
			open={open}
			title={translate("dsi.user-details.edit-dialog.title", { userName: user?.displayName, userId: user?.id })}
			onClose={onClose}
			onSubmit={handleCloseAndAction}
		>
			<FieldSection
				collapsed={collapsed.firstname}
				error={errors && errors.firstname}
				helperText={errors && errors.firstname && "More than 2 characters"}
				label={translate("dsi.profile.update-profile.first-name")}
				title="First Name"
				value={(payload && payload.firstname) || ""}
				onChange={(e) => handleChange("firstname", e.target.value)}
				onCollapse={() => handleCollapse("firstname")}
			/>
			<br />
			<Divider />
			<br />
			<FieldSection
				collapsed={collapsed.lastname}
				error={errors && errors.lastname}
				helperText={errors && errors.lastname && "More than 2 characters"}
				label={translate("dsi.profile.update-profile.last-name")}
				title="Last Name"
				value={(payload && payload.lastname) || ""}
				onChange={(e) => handleChange("lastname", e.target.value)}
				onCollapse={() => handleCollapse("lastname")}
			/>
			<br />
			<Divider />
			<br />
			<FieldSection
				collapsed={collapsed.email}
				error={errors && errors.email}
				helperText={errors && errors.email && "Proper email"}
				label={translate("dsi.profile.update-profile.email")}
				title="Email"
				value={(payload && payload.email) || ""}
				onChange={(e) => handleChange("email", e.target.value)}
				onCollapse={() => handleCollapse("email")}
			/>
			<br />
			<Divider />
			<br />
			<PasswordSection
				collapsed={collapsed.password}
				title="Password"
				onChange={handlePassword}
				onCollapse={() => handleCollapse("password")}
			/>
		</CustomDialog>
	);
}
