import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { aitendersLogo, aitendersCroppedLogo } from "../../../../common/resources/images";
import { NavUtils } from "../../../utils";
import { CustomButton } from "../../../../common/components";
import KeycloakService from "../../../../services/KeycloakService";
import permissions from "../../../../common/providers/permissions/permissions";

export default function ImageButton() {
	const [logo, setLogo] = useState(window.innerWidth > 1400 ? aitendersLogo : aitendersCroppedLogo);
	const navigate = useNavigate();
	useEffect(() => {
		const handleLogoSize = () => {
			setLogo(window.innerWidth > 1400 ? aitendersLogo : aitendersCroppedLogo);
		};
		window.addEventListener("resize", handleLogoSize);
		return () => {
			window.removeEventListener("resize", handleLogoSize);
		};
	}, []);
	return (
		<CustomButton
			data-testid="home.icon"
			onClick={() => {
				navigate(
					(KeycloakService.hasRole([permissions.REALM.ADMIN.claim]) && NavUtils.goToDSI()) ||
						NavUtils.goToHome()
				);
			}}
		>
			<img alt="aitenders_logo" height="24" src={logo} />
		</CustomButton>
	);
}
