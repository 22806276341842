import React, { useMemo, useState, useEffect } from "react";
import {
	CustomIconButtonLink,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	icon,
	ProgressBar,
} from "../../../../../common/components";
import { translate, translateDate } from "../../../../../common/providers";
import { sortArray } from "../../../../../common/utils";
import { NavUtils } from "../../../../../navigation";
import styles from "./PortfolioProjectListTable.module.css";

export default function PortfolioProjectListTable({ projects }) {
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");
	const [sortedTableData, setSortedTableData] = useState(projects);

	const headers = useMemo(
		() => [
			{ sortKey: "name", name: translate("dashboards.portfolio-project-list.project-name") },
			{ name: translate("dashboards.portfolio-project-list.partners") },
			{ sortKey: "type", name: translate("dashboards.portfolio-project-list.project-type") },
			{ name: translate("dashboards.portfolio-project-list.current-phase") },
			{ sortKey: "currentStatus", name: translate("dashboards.portfolio-project-list.status") },
			{ sortKey: "created", name: translate("dashboards.portfolio-project-list.created") },
			{ name: translate("dashboards.portfolio-project-list.owners") },
			{ sortKey: "reviewProgress", name: translate("dashboards.portfolio-project-list.review-progress") },
			{
				sortKey: "validationProgress",
				name: translate("dashboards.portfolio-project-list.validation-progress"),
			},
			{ name: translate("dashboards.portfolio-project-list.doc-info") },
			{ name: translate("dashboards.portfolio-project-list.action") },
		],
		[]
	);
	useEffect(() => {
		if (Array.isArray(projects) && projects.length > 0) {
			setSortedTableData(sortArray(projects, order, orderBy));
		} else {
			setSortedTableData([]);
		}
	}, [projects, order, orderBy]);
	const handleSort = (property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					{headers.map((header) => (
						<CustomTableCell
							key={header.name}
							active={orderBy === header.sortKey}
							className={styles.header}
							direction={order}
							sortKey={header.sortKey}
							onSort={() => {
								handleSort(header.sortKey);
							}}
						>
							{header.name}
						</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody emptyState={translate("dashboards.portfolio-project-list.no-project")}>
				{Array.isArray(sortedTableData) &&
					sortedTableData.map((row) => (
						<CustomTableRow key={row.id}>
							<CustomTableCell autoTranslate="no" className={styles.row__nameCell}>
								{row.name}
							</CustomTableCell>
							<CustomTableCell autoTranslate="no">
								{Array.isArray(row.partners)
									? row.partners.map((partner) => (
											<span key={partner.name}>
												{partner.name}
												<br />
											</span>
									  ))
									: null}
							</CustomTableCell>
							<CustomTableCell>{row.type}</CustomTableCell>
							<CustomTableCell>{row.nature}</CustomTableCell>
							<CustomTableCell>{row.currentStatus}</CustomTableCell>
							<CustomTableCell>{translateDate(row.created)}</CustomTableCell>
							<CustomTableCell autoTranslate="no">
								{Array.isArray(row.owners)
									? row.owners.map((owner) => (
											<span key={owner.name}>
												{owner.name}
												<br />
											</span>
									  ))
									: null}
							</CustomTableCell>
							<CustomTableCell>
								<ProgressBar
									label={`${Math.trunc(row.reviewProgress)}%`}
									value={row.reviewProgress / 100}
								/>
							</CustomTableCell>
							<CustomTableCell>
								<ProgressBar
									label={`${Math.trunc(row.validationProgress)}%`}
									value={row.validationProgress / 100}
								/>
							</CustomTableCell>
							<CustomTableCell>
								<div>
									<b>Docs:</b> {row.documents}
								</div>
								<div>
									<b>Pages:</b> {row.pages}
								</div>
								<div>
									<b>Reqs:</b> {row.totalReq}
								</div>
							</CustomTableCell>
							<CustomTableCell>
								<CustomIconButtonLink
									icon={icon.faArrowRight}
									to={NavUtils.goToProject(row.id)}
									tooltip={translate("dashboards.portfolio-project-list.action.tooltip")}
									variant="contained"
								/>
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
