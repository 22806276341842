import React, { useEffect, useMemo, useRef, useState } from "react";
import { CustomSwitch, OptionsPanelContainer, SearchInput } from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import { ApiService, FeatureManagementService, PackageManagementService } from "../../../../../../../api";
import Box from "../../../../box/Box";
import { isEqual, isNonEmptyArray } from "../../../../../../../common/utils";
import styles from "./PackageList.module.css";

export default function PackageList({ feat: { id }, onUpdateRow }) {
	const cancelTokenSourceRef = useRef();
	const [packages, setPackages] = useState([]);
	const [tempPackages, setTempPackages] = useState([]);
	const [platformPackages, setPlatformPackages] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [show, setShow] = useState(false);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		FeatureManagementService.getFeaturePackages({ featureId: id }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setPackages(data);
				setTempPackages(data);
			})
			.catch((err) => console.error(err));
		PackageManagementService.getAllPlatformPackages(searchInput, cancelTokenSourceRef.current.token)
			.then((data) => setPlatformPackages(data))
			.catch((err) => console.error(err));
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [id, searchInput]);
	const handleCheck = (data, checked) => {
		if (checked) {
			setTempPackages((prev) => prev.filter((tcp) => tcp.id !== data.id));
		} else {
			setTempPackages((prev) => [...prev, platformPackages.find((pc) => pc.id === data.id)]);
		}
	};
	const handleReset = () => {
		setTempPackages(packages);
	};
	const handleChangeSearch = (e) => {
		setSearchInput(e.target.value);
	};
	const handleClearSearch = () => {
		setSearchInput("");
	};
	const handleSave = () => {
		const toAdd = tempPackages.filter((tc) => !packages.some((c) => c.id === tc.id)).map((ta) => ta.id);
		const toRemove = packages.filter((tc) => !tempPackages.some((c) => c.id === tc.id)).map((ta) => ta.id);
		FeatureManagementService.updateFeaturePackages(
			{ featureId: id },
			{
				toAdd: isNonEmptyArray(toAdd) ? toAdd : undefined,
				toRemove: isNonEmptyArray(toRemove) ? toRemove : undefined,
			},
			cancelTokenSourceRef.current.token
		).then(() => {
			setPackages(tempPackages);
			onUpdateRow();
		});
	};
	const hasDiff = useMemo(
		() =>
			isEqual(
				packages.map((x) => x.id),
				tempPackages.map((x) => x.id)
			),
		[packages, tempPackages]
	);
	return (
		<OptionsPanelContainer
			buttonText={translate("common:btn.save")}
			buttonTextSecondary={translate("common:btn.cancel")}
			disabledPrimaryButton={hasDiff}
			disabledSecondaryButton={hasDiff}
			onClick={handleSave}
			onClickSecondary={handleReset}
		>
			<div className={styles.container}>
				<div>
					<SearchInput
						fullWidth
						disabled={platformPackages.length === 0}
						placeholder={translate("common:btn.search")}
						value={searchInput}
						onChange={handleChangeSearch}
						onClearSearch={handleClearSearch}
					/>
				</div>
				<div>
					<span>
						{translate("dsi.feature-management.panel.packages.selected-count", {
							count: tempPackages.length,
						})}
					</span>
					<span className={styles.switch}>
						<CustomSwitch checked={show} color="primary" onChange={() => setShow((prev) => !prev)} />
						{translate("common:list.show-selected")}
					</span>
				</div>
				{platformPackages
					.filter(
						(pc) =>
							(!show && pc.name?.toLowerCase().includes(searchInput?.toLowerCase() || "")) ||
							(pc.name?.toLowerCase().includes(searchInput?.toLowerCase() || "") &&
								tempPackages.some((tc) => tc.id === pc.id))
					)
					.map((c) => (
						<Box
							key={c.id}
							checked={tempPackages.some((tcp) => tcp.id === c.id)}
							content={c.description}
							data={c}
							id={c.id}
							title={c.name}
							onCheck={handleCheck}
						/>
					))}
			</div>
		</OptionsPanelContainer>
	);
}
