import React, { useState, useEffect } from "react";
import { ButtonGroup, ClickAwayListener, Popper } from "@mui/material";
import styles from "./Filter.module.css";
import { CustomIconButton, icon } from "../..";
import { translate } from "../../../providers";
import { CustomButton } from "../../buttons";

export default function Filter({
	autoTranslate = "yes",
	children,
	className = "",
	closeLabel = "",
	closeOnClick = false,
	disableClose = false,
	isActive = false,
	label,
	onClear = null,
	onClose = null,
	onOpen = null,
	title = label,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClose = () => setAnchorEl(null);
	const handleCloseWithAction = () => {
		if (onClose) {
			onClose();
		}
		handleClose();
	};
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		if (!open && onOpen) {
			onOpen();
		}
	};
	useEffect(() => {
		if (isActive && closeOnClick) {
			handleClose();
		}
	}, [isActive, closeOnClick]);
	return (
		<div className={styles.filter}>
			<ButtonGroup
				className={`${styles["button-group"]} ${className}`}
				color={!isActive ? "secondary" : "primary"}
				variant={!isActive ? "text" : "contained"}
			>
				<CustomButton
					className={styles["button-group__btn"]}
					color={isActive ? "primary" : "secondary"}
					endIcon={!isActive ? icon.faChevronDown : null}
					size="md"
					translate={autoTranslate}
					variant={isActive ? "contained" : "text"}
					onClick={handleClick}
				>
					{label}
				</CustomButton>
				{isActive && (
					<CustomIconButton
						btnClassName={styles["button-group__clear-btn"]}
						color="primary"
						icon={icon.faTimes}
						size="sm"
						variant="contained"
						onClick={onClear}
					/>
				)}
			</ButtonGroup>
			{open && (
				<ClickAwayListener mouseEvent="onClick" onClickAway={handleClose}>
					<Popper
						disablePortal
						anchorEl={anchorEl}
						className={styles.filter__popup}
						open={open}
						placement="bottom-start"
					>
						<header className={styles.popup__header}>
							<span className={styles.header__title}>{title}</span>
							<CustomIconButton icon={icon.faTimes} onClick={handleClose} />
						</header>
						<div className={styles.popup__content}>{children}</div>
						<footer className={styles.popup__footer}>
							<CustomButton
								fullWidth
								disabled={disableClose}
								variant="contained"
								onClick={handleCloseWithAction}
							>
								{closeLabel || translate("common:btn.close")}
							</CustomButton>
						</footer>
					</Popper>
				</ClickAwayListener>
			)}
		</div>
	);
}
