import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomButtonLink, CustomIconButton, I18nTranslate, icon, IconComponent } from "..";
import { ApiService } from "../../../api";
import projectTeamService from "../../../api/services/project/project-team-service";
import { NavUtils } from "../../../navigation";
import { hasPermission, isSegFeatureEnabled, SegFlags, translate } from "../../providers";
import permissions from "../../providers/permissions/permissions";
import { setCautionBannerDisabled } from "../../slice";
import styles from "./NoRoleOnDocumentBanner.module.css";

export default function NoRoleOnDocumentBanner() {
	const cautionBannerDisabled = useSelector(({ context }) => context.cautionBannerDisabled);
	const [hasRole] = useState(hasPermission(permissions.PROJECT_LEADER) || hasPermission(permissions.PROJECT_MANAGER));
	const [usersCount, setUsersCount] = useState(0);
	const companyId = useSelector(({ context }) => context.company?.id);
	const projectId = useSelector(({ context }) => context.project.id);
	const dispatch = useDispatch();
	useEffect(() => {
		const cancelToken = ApiService.getCancelTokenSource();
		if (projectId && companyId && hasRole) {
			projectTeamService
				.getUsersCountNoRole({ projectId }, cancelToken.token)
				.then((data) => {
					setUsersCount(data.count);
					if (data.count === 0) {
						dispatch(setCautionBannerDisabled(true));
					}
				})
				.catch((err) => console.error(err));
		}
		return () => {
			ApiService.cancelTokens(cancelToken);
		};
	}, [companyId, dispatch, projectId, hasRole]);
	const disableBanner = () => {
		dispatch(setCautionBannerDisabled(true));
	};
	return (
		(!cautionBannerDisabled && hasRole && usersCount > 0 && isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS) && (
			<header className={styles.container}>
				<div>
					<IconComponent color="var(--color-dark-grey-2)" icon={icon.faExclamationTriangle} />
					<I18nTranslate
						param={{ usersText: styles.usersText, usersCount }}
						translationKey={
							(usersCount === 1 && "common:components.caution-banner.user-can-not-review") ||
							"common:components.caution-banner.users-can-not-review"
						}
					/>
					<CustomButtonLink
						buttonClassName={styles.configureButton}
						to={NavUtils.goToRightsOnDocuments(projectId)}
						onClick={() => disableBanner()}
					>
						{translate("common:components.caution-banner.configure")}
					</CustomButtonLink>
				</div>
				<span>
					<CustomIconButton icon={icon.faTimes} onClick={() => disableBanner()} />
				</span>
			</header>
		)) ||
		null
	);
}
