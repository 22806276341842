import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApiService, DashboardService, FavoriteCategoryService } from "../../../api";
import { SearchInput, EmptyState, CustomButton } from "..";
import { translate } from "../../providers";
import { CategoryFilter, DocumentList } from "./components";
import styles from "./ReadingList.module.css";
import { checkFavoriteCategories, openFavoriteCategories, setDisableKeyEvents } from "../../slice";

const ReadingList = ({ isPopUp = false }) => {
	const projectId = useSelector(({ context }) => context.project?.id);
	const companyId = useSelector(({ context }) => context.company?.id);
	const favoriteCategories = useSelector(({ context }) => context.favoriteCategories?.categories);
	const [searchValue, setSearchValue] = useState("");
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [documentRequest, setDocumentRequest] = useState(null);
	const [initialSelectionLoaded, setInitialSelectionLoaded] = useState(false);
	const [totalDocuments, setTotalDocuments] = useState();
	const cancelTokenSourceRef = useRef();
	const dispatch = useDispatch();
	useEffect(() => {
		setInitialSelectionLoaded(false);
		setSelectedCategories(
			favoriteCategories
				.map((favCat) => (favCat.checked || favCat.checked === undefined ? favCat.id : 0))
				.filter((favCat) => favCat !== 0)
		);
		setInitialSelectionLoaded(true);
	}, [favoriteCategories]);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (initialSelectionLoaded && Array.isArray(selectedCategories) && selectedCategories.length > 0) {
			setDocumentRequest(
				() =>
					({ page, limit }) =>
						FavoriteCategoryService.getDocumentsByFavouriteCategories(
							{ projectId },
							{
								docName: searchValue || null,
								categoryIds: selectedCategories.length > 0 ? selectedCategories : null,
							},
							{ page, limit },
							cancelTokenSourceRef.current.token
						)
			);
		}
	}, [projectId, searchValue, selectedCategories, initialSelectionLoaded]);
	useEffect(() => {
		if ((projectId, companyId)) {
			const variables = { projectId, companyId };
			DashboardService.getProjectDocuments(variables, cancelTokenSourceRef.current.token)
				.then((data) => {
					setTotalDocuments(data.totalDocuments);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [projectId, companyId]);

	const handleChangeSearchValue = (event) => {
		setSearchValue(event.target.value);
	};
	const handleClearSearchValue = () => {
		setSearchValue("");
	};
	const handleValidate = (tempSelectedCategories) => {
		setSelectedCategories(tempSelectedCategories);
		dispatch(checkFavoriteCategories(tempSelectedCategories));
	};
	const handleClickOpen = () => {
		dispatch(openFavoriteCategories());
	};
	const manageCategoryButton = (
		<CustomButton color="primary" size="md" variant="contained" onClick={handleClickOpen}>
			{translate("dashboard.my-dashboard.my-reading-list.no-category-followed-btn")}
		</CustomButton>
	);
	const noCategoryRender = (
		<EmptyState
			btn1={manageCategoryButton}
			title={translate("dashboard.my-dashboard.my-reading-list.no-category-followed-title")}
		/>
	);

	if (totalDocuments !== 0 && (favoriteCategories.length === 0 || selectedCategories.length === 0)) {
		return <>{noCategoryRender}</>;
	}
	return (
		<div className={styles.container}>
			<SearchInput
				className={styles.searchContainer__textfield}
				label={translate("common:reading-list.search")}
				value={searchValue}
				onBlur={() => dispatch(setDisableKeyEvents(false))}
				onChange={handleChangeSearchValue}
				onClearSearch={handleClearSearchValue}
				onFocus={() => dispatch(setDisableKeyEvents(true))}
			/>
			<CategoryFilter selectedCategories={selectedCategories} onValidate={handleValidate} />
			<DocumentList
				documentRequest={documentRequest}
				isPopUp={isPopUp}
				searchValue={searchValue}
				totalDocuments={totalDocuments}
			/>
		</div>
	);
};

export default ReadingList;
