import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { MenuItem, MenuList } from "@mui/material";
import { useSelector } from "react-redux";
import { CustomIconButton, DropDown, I18nTranslate, IconComponent, icon } from "..";
import styles from "./CustomMagicDropDown.module.css";
import { translate } from "../../providers";
import { NavUtils } from "../../../navigation";
import { isNonEmptyArray } from "../../utils";

const CustomMagicDropDown = ({
	questions,
	onGetRedirectToChatFilters,
	disabled = false,
	positionDropDown,
	magicButtonSizeClassName,
}) => {
	const projectId = useSelector(({ context }) => context.project?.id);
	const [isDropDownOpen, setIsDropDownOpen] = useState(false);
	const [expandedQuestionIndex, setExpandedQuestionIndex] = useState(null);
	const anchorRef = useRef(null);

	const handleOpen = () => {
		setIsDropDownOpen(true);
	};

	const handleClose = () => {
		setIsDropDownOpen(false);
		setExpandedQuestionIndex(false);
	};
	const handleButtonClick = () => {
		if (!disabled) {
			setIsDropDownOpen((prevState) => !prevState);
		}
	};
	const handleRedirectChat = (question) => {
		const questionText = typeof question.text === "string" ? question.text : "";
		const redirectState = {
			...onGetRedirectToChatFilters(questionText, question.summary),
			notAutoLaunch: question.notAutoLaunch,
		};
		localStorage.setItem("chatRedirectState", JSON.stringify(redirectState));
		window.open(NavUtils.goToChatProject(projectId), "_blank");
	};
	const handleQuestionClick = (index, question) => {
		if (isNonEmptyArray(question.choices)) {
			setExpandedQuestionIndex(index === expandedQuestionIndex ? null : index);
		} else {
			handleRedirectChat(question);
		}
	};
	return (
		<>
			<CustomIconButton
				ref={anchorRef}
				className={`${disabled ? styles.magicWandDisabled : styles.magicWand} ${magicButtonSizeClassName} `}
				disabled={disabled}
				icon={icon.faWandMagicSparkles}
				tooltip={
					disabled ? (
						<I18nTranslate autoTranslate="no" translationKey="common:wand-magic-disabled-title-button" />
					) : (
						translate("common:wand-magic-title-button")
					)
				}
				tooltipPlacement="right"
				onClick={handleButtonClick}
			/>
			{isDropDownOpen && (
				<DropDown
					ref={anchorRef}
					open={handleOpen}
					position={positionDropDown}
					onClose={handleClose}
					onMouseLeave={handleClose}
				>
					<MenuList>
						{questions.map((question, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<div key={index}>
								<MenuItem onClick={() => handleQuestionClick(index, question)}>
									{question.title}
									{question.choices && (
										<IconComponent
											className={styles.faChevronRight}
											icon={icon.faChevronRight}
											size="2xs"
										/>
									)}
								</MenuItem>
								{index === expandedQuestionIndex && question.choices && (
									<div className={styles.choicesContainer}>
										{question.choices.map((choice, choiceIndex) => (
											// eslint-disable-next-line react/no-array-index-key
											<MenuItem key={choiceIndex} onClick={() => handleRedirectChat(choice)}>
												{choice.title}
											</MenuItem>
										))}
									</div>
								)}
							</div>
						))}
					</MenuList>
				</DropDown>
			)}
		</>
	);
};

CustomMagicDropDown.propTypes = {
	questions: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
			summary: PropTypes.string.isRequired,
			choices: PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					text: PropTypes.string.isRequired,
					summary: PropTypes.string.isRequired,
					notAutoLaunch: PropTypes.bool,
				})
			),
		})
	).isRequired,
	onGetRedirectToChatFilters: PropTypes.func.isRequired,
	positionDropDown: PropTypes.string.isRequired,
};

export default CustomMagicDropDown;
