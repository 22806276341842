import React, { useState, useEffect } from "react";
import { TableFooter, TablePagination, Paper } from "@mui/material";
import styles from "./CustomPaginationActionsTable.module.css";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
} from "../../../../../common/components";

export default function CustomPaginationActionsTable({ data = [], columns }) {
	const [rows, setRows] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	useEffect(() => setRows[data], [data]);

	const handleChangePage = (_, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e) => {
		const { value } = e.target;
		setPage(0);
		setRowsPerPage(value);
	};

	const displayRowData = (row) => {
		const items = [];
		const nbCols = Object.keys(row).length;
		for (let i = 2; i <= nbCols; i++) {
			items.push(
				<CustomTableCell key={i} align="left" className={styles.table__cell}>
					{row[`${i}`]}
				</CustomTableCell>
			);
		}
		return items;
	};

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
	return (
		<Paper className={styles.root}>
			<div className={styles.tableWrapper}>
				<CustomTable className={styles.table}>
					<colgroup>
						{columns.map((col, index) => {
							const key = index;
							return <col key={key} style={{ width: col.col }} />;
						})}
					</colgroup>
					<CustomTableHead>
						<CustomTableRow>
							{columns.map((col, index) => {
								const key = index;
								return (
									<CustomTableCell key={key} className={styles["table--title"]}>
										{col.title}
									</CustomTableCell>
								);
							})}
						</CustomTableRow>
					</CustomTableHead>
					<CustomTableBody>
						{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
							const key = index;
							return (
								<CustomTableRow key={key}>
									<CustomTableCell component="th" scope="row">
										{row["1"]}
									</CustomTableCell>
									{displayRowData(row).map((item) => item)}
								</CustomTableRow>
							);
						})}
						{emptyRows > 0 && (
							<CustomTableRow style={{ height: 48 * emptyRows }}>
								<CustomTableCell colSpan={6} />
							</CustomTableRow>
						)}
					</CustomTableBody>
					<TableFooter>
						<CustomTableRow>
							<TablePagination
								colSpan={3}
								count={rows.length}
								page={page}
								rowsPerPage={rowsPerPage}
								rowsPerPageOptions={[5, 10, 25]}
								SelectProps={{
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</CustomTableRow>
					</TableFooter>
				</CustomTable>
			</div>
		</Paper>
	);
}
