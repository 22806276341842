import React from "react";
import { SidePanel, SidePanelContent } from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";

import { CommentsTabView } from "../../../../../my-review/components/right-panels/options-panel/tabs/comments-tab/components";

const CommentsSidePanel = ({ information, onClose, open }) => (
	<SidePanel open={open} size={45} onClose={onClose}>
		<SidePanelContent title={translate("gonogo.comments-side-panel.comments")} onClose={onClose}>
			<CommentsTabView informationId={information?.informationId} />
		</SidePanelContent>
	</SidePanel>
);

export default CommentsSidePanel;
