import React from "react";
import { DialogTitle, DialogContent, DialogActions, Icon } from "@mui/material";
import { IconComponent, CustomIconButton, icon, GenericDialog, CustomButton } from "../../../../common/components";
import styles from "./ErrorLogDialog.module.css";
import { translate } from "../../../../common/providers";

const ErrorLogDialog = ({ open = false, onClose, filename = "", errors = [] }) => (
	<GenericDialog disableBackdropClick fullWidth open={open} onClose={onClose}>
		<DialogTitle>
			<div className={styles.dialog__title}>
				<span className={styles["dialog__title-text"]}>
					{translate("deliverable.import.error.title", { filename: filename ? `(${filename})` : "" })}
				</span>
				<CustomIconButton icon={icon.faTimes} onClick={onClose} />
			</div>
		</DialogTitle>
		<DialogContent dividers className={styles.dialog__content}>
			{errors.map((err) => (
				<span key={err}>
					<Icon aria-label="error">
						<IconComponent icon={icon.faExclamationCircle} />
					</Icon>
					&nbsp;
					{err}
				</span>
			))}
		</DialogContent>
		<DialogActions>
			<CustomButton color="primary" variant="contained" onClick={onClose}>
				{translate("common:btn.ok")}
			</CustomButton>
		</DialogActions>
	</GenericDialog>
);

export default ErrorLogDialog;
