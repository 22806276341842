import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CustomButton, IconComponent, icon } from "../../../../../../common/components";
import OptionsDropDown from "./components/OptionsDropDown";
import { translate } from "../../../../../../common/providers";
import { checkReqValMode } from "../../../../utils/utils";
import styles from "./Options.module.css";

export default function Options() {
	const optionsRef = useRef();
	const [open, setOpen] = useState(false);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const handleDropdown = () => {
		setOpen((prev) => !prev);
	};
	return (
		<>
			{checkReqValMode(mode) && (
				<>
					<CustomButton
						ref={optionsRef}
						color="secondary"
						startIcon={icon.faSliders}
						onClick={handleDropdown}
					>
						{translate("smart-review.header.view")}
						<IconComponent className={styles.button__collapseIcon} icon={icon.faChevronDown} />
					</CustomButton>
					<OptionsDropDown ref={optionsRef} open={open} onClose={handleDropdown} />
				</>
			)}
		</>
	);
}
