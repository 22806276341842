import React from "react";
import { Checkbox } from "@mui/material";
import styles from "./StatusButtons.module.css";
import { translate } from "../../../../providers";
import { CustomIconButton, CustomTooltip, icon } from "../../..";
import { FilterSelector } from "../../components";
import { OPERATORS } from "../../../../constants";

function UnreviewedCheckbox({ checked, size, onClick }) {
	return (
		<Checkbox
			checked={checked}
			classes={{
				root: `${styles.UnreviewedCheckBox__root} ${styles.checkbox__root}`,
			}}
			data-is-checked={checked}
			size={size}
			value="UNREVIEWED"
			onClick={onClick}
		/>
	);
}
function YesCheckbox({ checked, size, onClick }) {
	return (
		<Checkbox
			checked={checked}
			classes={{
				root: `${styles.YesCheckbox__root} ${styles.checkbox__root}`,
			}}
			data-is-checked={checked}
			size={size}
			value="YES"
			onClick={onClick}
		/>
	);
}
function PendingCheckbox({ checked, size, onClick }) {
	return (
		<Checkbox
			checked={checked}
			classes={{
				root: `${styles.PendingCheckbox__root} ${styles.checkbox__root}`,
			}}
			data-is-checked={checked}
			size={size}
			value="PENDING"
			onClick={onClick}
		/>
	);
}
function NoCheckbox({ checked, size, onClick }) {
	return (
		<Checkbox
			checked={checked}
			classes={{
				root: `${styles.NoCheckbox__root} ${styles.checkbox__root}`,
			}}
			data-is-checked={checked}
			size={size}
			value="NO"
			onClick={onClick}
		/>
	);
}
function NotMeCheckbox({ checked, size, onClick }) {
	return (
		<Checkbox
			checked={checked}
			classes={{
				root: `${styles.NotMeCheckbox__root} ${styles.checkbox__root}`,
			}}
			data-is-checked={checked}
			size={size}
			value="NOT_ME"
			onClick={onClick}
		/>
	);
}

export default function StatusButtons({
	activeCompany,
	andOr,
	onChangeAndOr,
	onSelectStatus,
	onRemove,
	onSetActiveCompany,
	order,
	partnersSelection,
	showUnreviewed = false,
	positionValue,
	readOnly,
}) {
	const handleSetCompany = (newSelection) => {
		onSetActiveCompany(activeCompany, newSelection.id);
	};
	const handleRemove = () => {
		onRemove(activeCompany);
	};
	const handleClickPosition = (e) => {
		onSelectStatus(activeCompany, e);
	};
	const showCompany = () => partnersSelection.find((partner) => partner.id.toString() === activeCompany.toString());

	return (
		<div className={styles.container}>
			<div className={styles.container__header}>
				{order === 0 && <div>{translate("common:filters.positions.where")}</div>}
				{order === 1 && (
					<FilterSelector
						itemRenderer={(value) => translate(`common:enum.separator.${value?.toLowerCase()}`)}
						items={Object.values(OPERATORS)}
						readOnly={readOnly}
						value={andOr}
						onChange={onChangeAndOr}
					/>
				)}
				{order > 1 && <div>{translate(`common:enum.separator.${andOr?.toLowerCase()}`)}</div>}
				<FilterSelector
					itemRenderer={(partner) => partner.name}
					items={partnersSelection.filter((partner) => partner.display)}
					value={showCompany()}
					onChange={handleSetCompany}
				/>
				<div className={styles.lowerCase}>{translate("common:component.filters.is-button.is")}</div>
			</div>
			<div className={styles.container__btns}>
				{showUnreviewed && (
					<CustomTooltip arrow title={translate("common:header.filter.status.unreviewed")}>
						<span>
							<UnreviewedCheckbox
								checked={!!positionValue?.includes("UNREVIEWED")}
								onClick={handleClickPosition}
							/>
						</span>
					</CustomTooltip>
				)}
				<CustomTooltip arrow title={translate("common:header.filter.status.yes")}>
					<span>
						<YesCheckbox checked={!!positionValue?.includes("YES")} onClick={handleClickPosition} />
					</span>
				</CustomTooltip>
				<CustomTooltip arrow title={translate("common:header.filter.status.pending")}>
					<span>
						<PendingCheckbox checked={!!positionValue?.includes("PENDING")} onClick={handleClickPosition} />
					</span>
				</CustomTooltip>
				<CustomTooltip arrow title={translate("common:header.filter.status.no")}>
					<span>
						<NoCheckbox checked={!!positionValue?.includes("NO")} onClick={handleClickPosition} />
					</span>
				</CustomTooltip>
				<CustomTooltip arrow title={translate("common:header.filter.status.not-me")}>
					<span>
						<NotMeCheckbox checked={!!positionValue?.includes("NOT_ME")} onClick={handleClickPosition} />
					</span>
				</CustomTooltip>
				{order === 0 ? (
					<div className={styles.tashPlaceHolder} />
				) : (
					<CustomIconButton
						btnClassName={styles.trashButton}
						icon={icon.faTrash}
						iconColor="var(--color-light-grey-1)"
						size="sm"
						onClick={handleRemove}
					/>
				)}
			</div>
		</div>
	);
}
