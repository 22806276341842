import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isNonEmptyArray } from "../../../../../../../common/utils";
import { ApiService, MultiSelectionservice } from "../../../../../../../api";
import { ActionTree, CustomIconButton, SearchInput, icon } from "../../../../../../../common/components";
import {
	filteredCategoryTree,
	initThematicTree,
	updateThematicTree,
} from "../../../../../../../common/utils/category-utils";
import { translate } from "../../../../../../../common/providers";
import styles from "./CategoriesTab.module.css";
import { setDisableKeyEvents } from "../../../../../../../common/slice";
import { updateNode } from "../../../../../slice/pdf/pdf-slice";
import {
	handleThematicCountIncrement,
	handleThematicCountDecrement,
	findBooleanPropertyInTreeArray,
} from "../../../../../slice/utils";

const debounceCheck = debounce((checkFunction) => {
	checkFunction();
}, 300);

export default function CategoriesTab() {
	const [rows, setRows] = useState([]);
	const [search, setSearch] = useState("");
	const [isChecking, setIsChecking] = useState("");
	const selectionSidePanelOpen = useSelector(({ srDocument }) => srDocument.selectionSidePanelOpen);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const projectId = useSelector(({ context }) => context.project.id);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const savedImageLength = useSelector(({ srPdf }) => srPdf?.content?.length || 0);
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const dispatch = useDispatch();
	const cancelTokenSourceRef = useRef();
	const searchInputRef = useRef();
	useEffect(
		() => {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
			return () => {
				ApiService.cancelTokens(cancelTokenSourceRef.current);
				if (disableKeyEvents) {
					dispatch(setDisableKeyEvents(false));
				}
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	useEffect(
		() => {
			if (selectionSidePanelOpen && isNonEmptyArray(selectedRectangles)) {
				MultiSelectionservice.getSelectionCategoriesCount(
					{ projectId, documentId },
					{ values: selectedRectangles.map((sr) => sr.informationId) },
					cancelTokenSourceRef.current.token
				)
					.then((data) => setRows(initThematicTree(data, selectedRectangles.length)))
					.catch((err) => console.error(err));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectionSidePanelOpen, selectedRectangles]
	);
	const handleChangeSearch = (e) => {
		setSearch(e.target.value);
	};
	const handleClearSearch = () => {
		setSearch("");
	};
	const handleCheckTreeRow = (item) => {
		const newRows = updateThematicTree([...rows], item);
		setRows(newRows);
		setIsChecking(true);
		debounceCheck(() => {
			const payload = { ids: selectedRectangles.map((sr) => sr.informationId) };
			if (item.checked) {
				payload.deleteThematic = [item.id];
			} else {
				payload.addThematic = [item.id];
			}
			MultiSelectionservice.updateSelectionCategories(
				{ projectId, documentId },
				payload,
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					setIsChecking(false);
					const isThereAnyChecked = findBooleanPropertyInTreeArray(newRows, "checked", "subThematic");
					selectedRectangles.forEach((srec) => {
						dispatch(
							updateNode({
								mode,
								informationId: srec.informationId,
								pageConditions: { length: savedImageLength, page: srec.pageStart, border: true },
								updates: [
									{
										property: "thematicCount",
										callback:
											(isThereAnyChecked && handleThematicCountIncrement) ||
											handleThematicCountDecrement,
									},
								],
							})
						);
					});
				})
				.catch((err) => console.error(err));
		});
	};
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<SearchInput
					ref={searchInputRef}
					autoFocus
					fullWidth
					label={translate("smart-review.cmp-details.option-panel.categories-tab.edit.search")}
					value={search}
					onBlur={() => dispatch(setDisableKeyEvents(false))}
					onChange={handleChangeSearch}
					onClearSearch={handleClearSearch}
					onFocus={() => dispatch(setDisableKeyEvents(true))}
				/>
				<CustomIconButton
					btnClassName={`${styles.rightIcon} ${(!isChecking && styles["rightIcon--translucent"]) || ""}`}
					icon={icon.faSave}
					iconColor="var(--color-blue)"
					size="lg"
				/>
			</div>
			{isNonEmptyArray(rows) && (
				<ActionTree
					rows={filteredCategoryTree(rows, search)}
					total={selectedRectangles.length}
					onCheckRow={handleCheckTreeRow}
				/>
			)}
		</div>
	);
}
