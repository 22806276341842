import React from "react";
import { Chip } from "@mui/material";
import { translate } from "../../../common/providers";

export default function TabTitleCounter({ title, counter }) {
	return (
		<span>
			{title}
			&nbsp;
			<Chip
				color="primary"
				label={translate(
					counter > 1
						? "consistency-check.tab-title-counter.unresolved"
						: "consistency-check.tab-title-counter-single.unresolved",
					{ counter }
				)}
			/>
		</span>
	);
}
