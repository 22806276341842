import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { CustomDialog, icon } from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import styles from "./MatchDropdownDialog.module.css";

export default function MatchDropdownDialog({
	importPartnersComments,
	keepMyPositions,
	onChangeKeepMyPositions,
	onClose,
	onSubmit,
	open,
	overwrittenCommentsNb,
	overwrittenReqsNb,
	unanimouslyAcceptedOnly,
}) {
	return (
		<CustomDialog
			iconColor="var(--color-red)"
			iconModel={icon.faExclamationTriangle}
			open={open}
			submitLabel={translate("common:btn.validate")}
			onClose={onClose}
			onSubmit={onSubmit}
		>
			<div className={styles["dialog-content"]}>
				<div className={styles["dialog-content__text"]}>
					{importPartnersComments
						? translate("smart-review.match-confirmation-comments-dialog.text", {
								reqsCount: overwrittenReqsNb,
								commentsCount: overwrittenCommentsNb,
						  })
						: translate("smart-review.match-confirmation-dialog.text", {
								reqsCount: overwrittenReqsNb,
						  })}
				</div>
				{!unanimouslyAcceptedOnly && (
					<RadioGroup value={keepMyPositions} onChange={onChangeKeepMyPositions}>
						<FormControlLabel
							value
							control={<Radio color="primary" />}
							label={translate(
								importPartnersComments
									? "smart-review.match-confirmation-dialog.keep-my-comments-and-positions"
									: "smart-review.match-confirmation-dialog.keep-my-positions"
							)}
						/>
						<FormControlLabel
							control={<Radio color="primary" />}
							label={translate(
								importPartnersComments
									? "smart-review.match-confirmation-dialog.overwrite-comments-and-positions"
									: "smart-review.match-confirmation-dialog.overwrite-positions"
							)}
							value={false}
						/>
					</RadioGroup>
				)}
			</div>
		</CustomDialog>
	);
}
