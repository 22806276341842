import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService, CommentService } from "../../../../../../../../../api";
import {
	createNotification,
	LimitedAutocompleteTextField,
	CommentBox,
	SidePanelBox,
	icon,
	CustomIconButton,
	CustomTooltip,
	ViewTabs,
	IconComponent,
	CustomButton,
} from "../../../../../../../../../common/components";
import styles from "./CommentTabView.module.css";
import { Flags, isFeatureEnabled, translate } from "../../../../../../../../../common/providers";
import { setIsNewNotification, setTempComment } from "../../../../../../../slice/document/document-slice";
import { updateNode } from "../../../../../../../slice/pdf/pdf-slice";
import { setDisableKeyEvents } from "../../../../../../../../../common/slice";
import { useApi, usePrevious } from "../../../../../../../../../common/hooks";
import { isNonEmptyArray } from "../../../../../../../../../common/utils";

const searchLimit = 10;

const CommentsTabView = ({ disableEditing = false, informationId = 0, pageStart }) => {
	const dispatch = useDispatch();
	const cancelTokenSourceRef = useRef(null);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const teamMembers = useSelector(({ context }) => context.teamMembers);
	const tempComment = useSelector(({ srDocument }) => srDocument.sidePanelTempComment);
	const commentId = useSelector(({ srDocument }) => srDocument.sidePanelCommentId || null);
	const commentOpenConversation = useSelector(({ srDocument }) => srDocument.sidePanelCommentOpenConversation);
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const user = useSelector(({ context }) => context.user);
	const [counters, setCounters] = useState({ opened: 0, closed: 0 });
	const [toRefresh, setToRefresh] = useState(false);
	const [toRefreshCounters, setToRefreshCounters] = useState(true);
	const [comments, setComments] = useState({});
	const [shiftPressed, setShiftPressed] = useState(false);
	const [isActiveNewComment, setIsActiveNewComment] = useState(false);
	const [selectedTab, setSelectedTab] = useState("open");
	const [autoFocus, setAutofocus] = useState(false);
	const prevCommentId = usePrevious(commentId);
	const prevInformationId = usePrevious(informationId);
	const prevSelectedTab = usePrevious(selectedTab);
	const { call: getAllPrimeComments } = useApi(CommentService.getAllPrimeComments);
	const { call: getCommentStatusCount } = useApi(CommentService.getCommentStatusCount);
	const itemRefs = useRef([]);
	const tabHeaders = useMemo(
		() => [
			{
				id: "open",
				label: translate("common:comment.conversation.open", { count: counters?.opened }),
			},
			{
				id: "close",
				label: translate("common:comment.conversation.closed", { count: counters?.closed }),
			},
		],
		[counters]
	);
	const handleRefresh = useCallback(() => {
		setToRefresh(true);
	}, []);
	useEffect(
		() => {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
			return () => {
				ApiService.cancelTokens(cancelTokenSourceRef.current);
				if (disableKeyEvents) {
					dispatch(setDisableKeyEvents(false));
				}
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	useEffect(() => {
		const newCommentId = commentId && commentId !== prevCommentId;
		const newInformationId = informationId && informationId !== prevInformationId;
		const newTab = selectedTab !== prevSelectedTab;
		if (newCommentId || newInformationId || newTab) {
			handleRefresh();
		}
	}, [
		commentId,
		commentOpenConversation,
		handleRefresh,
		informationId,
		prevCommentId,
		prevInformationId,
		prevSelectedTab,
		selectedTab,
	]);
	const handleResponse = useCallback(
		(data) => {
			setToRefresh(false);
			if (pageStart) {
				dispatch(
					updateNode({
						mode,
						pageConditions: { page: pageStart },
						informationId,
						updates: [
							{
								property: "commentsCount",
								value: data?.totalElements,
							},
						],
					})
				);
			}
		},
		[dispatch, pageStart, mode, informationId]
	);
	const handleDisplayPrevious = useCallback(() => {
		getAllPrimeComments(
			{ informationId },
			{ page: comments.pageNumber + 1, limit: 5, isOpenForDiscussion: selectedTab === "open" }
		)
			.then((data) => {
				const { contents, ...rest } = data;
				setComments((prev) => ({ ...rest, contents: [...prev.contents, ...contents] }));
			})
			.catch(console.error);
	}, [selectedTab, informationId, comments, getAllPrimeComments]);
	useEffect(
		() => {
			if (informationId && toRefresh) {
				const limit = (commentId && searchLimit) || 5;
				if (commentId) {
					setSelectedTab((commentOpenConversation && "open") || "close");
					const requests = (p) =>
						getAllPrimeComments(
							{ informationId },
							{ page: p, limit, isOpenForDiscussion: commentOpenConversation }
						)
							.then((data) => {
								setComments((prev) => ({
									...prev,
									...data,
									contents: [
										...((p !== 0 && prev?.contents) || []),
										...data.contents.map(
											(com) => (com.commentId === commentId && { ...com, openMore: true }) || com
										),
									],
								}));
								if (data?.contents?.some((com) => com.commentId !== commentId) && data.hasMore) {
									requests(p + 1);
								}
							})
							.catch(console.error);
					requests(0);
				} else {
					getAllPrimeComments(
						{ informationId },
						{ page: 0, limit, isOpenForDiscussion: selectedTab === "open" }
					)
						.then((data) => {
							handleResponse(data);
							setComments(data);
							setAutofocus(data?.contents?.length === 0);
						})
						.catch(console.error);
				}
				setToRefresh(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[informationId, selectedTab, handleResponse, toRefresh, commentId, commentOpenConversation, getAllPrimeComments]
	);
	useEffect(() => {
		if (informationId || toRefreshCounters) {
			getCommentStatusCount({ informationId })
				.then((data) => {
					setCounters(data);
					setToRefreshCounters(false);
				})
				.catch(console.error);
		}
	}, [toRefreshCounters, informationId, getCommentStatusCount]);
	const handleAddComment = useCallback(() => {
		CommentService.addPrimeComment({ informationId }, { value: tempComment }, cancelTokenSourceRef.current.token)
			.then((data) => {
				const newComment = { ...data, childrenCount: 0, hasChild: false, isOpenForDiscussion: true };
				if (isNonEmptyArray(comments?.contents)) {
					setComments((prev) => ({ ...prev, contents: [newComment, ...prev.contents] }));
				} else {
					setToRefresh(true);
				}
				setToRefreshCounters(true);
				createNotification({
					type: "success",
					message: translate("common:comment.notification.taken-into-account"),
				});
				dispatch(setTempComment(""));
				dispatch(setIsNewNotification(true));
				if (pageStart) {
					dispatch(
						updateNode({
							mode,
							pageConditions: { page: pageStart },
							informationId,
							updates: [
								{
									property: "commentsCount",
									value: 1,
								},
							],
						})
					);
				}
			})
			.catch(console.error);
	}, [dispatch, informationId, mode, pageStart, tempComment, comments]);
	const handleBlur = useCallback(() => {
		dispatch(setDisableKeyEvents(false));
		setIsActiveNewComment(false);
	}, [dispatch]);
	const handleFocus = () => {
		dispatch(setDisableKeyEvents(true));
		setIsActiveNewComment(true);
	};
	useEffect(() => {
		const handleKeyReleased = (e) => {
			if (isActiveNewComment && e.key === "Shift") {
				setShiftPressed(false);
			}
		};
		const handleKeyDetect = (e) => {
			if (isActiveNewComment && e.key === "Shift") {
				setShiftPressed(true);
			}
			if (isActiveNewComment && e.key === "Enter" && !shiftPressed) {
				const autocompleteOpen = document.querySelector(
					"[role='listbox'][class*='_mentions__suggestions__list_']"
				);
				if (!autocompleteOpen && tempComment?.length > 0 && /\S/.test(tempComment)) {
					handleAddComment();
				}
			}
			if (isActiveNewComment && (e.key === "ArrowLeft" || e.key === "ArrowRight")) {
				e.preventDefault();
				const textField = e.target;
				if (e.key === "ArrowLeft") {
					if (textField.selectionStart > 0) {
						textField.setSelectionRange(textField.selectionStart - 1, textField.selectionStart - 1);
					}
				} else if (e.key === "ArrowRight") {
					if (textField.selectionStart < textField.value.length) {
						textField.setSelectionRange(textField.selectionStart + 1, textField.selectionStart + 1);
					}
				}
			}
			if (isActiveNewComment && e.key === "Escape") {
				handleBlur();
				setTempComment("");
			}
		};
		window.addEventListener("keydown", handleKeyDetect, true);
		window.addEventListener("keyup", handleKeyReleased, true);
		return () => {
			window.removeEventListener("keydown", handleKeyDetect, true);
			window.removeEventListener("keyup", handleKeyReleased, true);
		};
	}, [isActiveNewComment, shiftPressed, tempComment, handleAddComment, handleBlur]);
	const handleChangeTempComment = (newComment) => {
		if ((/\S/.test(newComment) && (tempComment?.length === 0 || !tempComment)) || tempComment?.length > 0) {
			dispatch(setTempComment(newComment));
		}
	};
	const handleUpdateComment = useCallback(({ parentId, childId, data }) => {
		if (parentId) {
			setComments((prev) => ({
				...prev,
				contents: prev.contents.map(
					(c) =>
						(c.commentId === parentId && {
							...c,
							comments: {
								...c.comments,
								contents: c.comments.contents.map(
									(child) => (child.commentId === childId && data) || child
								),
							},
						}) ||
						c
				),
			}));
		} else if (data?.commentId) {
			setComments((prev) => ({
				...prev,
				contents: prev.contents.map((com) => (com.commentId === data.commentId && { ...com, ...data }) || com),
			}));
		}
	}, []);
	const handleAttachChildren = useCallback((id, data) => {
		setComments((prev) => ({
			...prev,
			contents: prev.contents.map(
				(com) =>
					(com.commentId === id && {
						...com,
						comments: { ...(com.comments || {}), ...data },
						openMore: true,
					}) ||
					com
			),
		}));
	}, []);
	const handleOpenConversation = useCallback((id) => {
		setComments((prev) => ({
			...prev,
			contents: prev.contents.map((com) => (com.commentId === id && { ...com, openMore: true }) || com),
		}));
	}, []);
	const handleDeleteComment = useCallback((id, parentId, isLast) => {
		if (!parentId) {
			setComments((prev) => ({
				...prev,
				contents: prev.contents.filter((c) => c.commentId !== id),
			}));
		} else {
			setComments((prev) => ({
				...prev,
				contents: prev.contents.map(
					(com) =>
						(com.commentId === parentId && {
							...com,
							openMore: !!isLast,
							comments: {
								...com.comments,
								contents: com.comments.contents.filter((child) => child.commentId !== id),
							},
						}) ||
						com
				),
			}));
		}
		setToRefreshCounters(true);
	}, []);
	const handleRefreshCounters = useCallback(() => {
		setToRefreshCounters(true);
	}, []);
	const HistoryRowRender = useCallback(
		({ comment }) => (
			<CommentBox
				key={comment.commentId}
				isParent
				comment={comment}
				disableEditing={disableEditing}
				isOpenConversation={selectedTab === "open"}
				teamMembers={teamMembers}
				onAttachChildren={handleAttachChildren}
				onDeleteComment={handleDeleteComment}
				onOpenConversation={handleOpenConversation}
				onRefresh={handleRefresh}
				onRefreshCounters={handleRefreshCounters}
				onUpdateComment={handleUpdateComment}
			/>
		),
		[
			disableEditing,
			handleRefresh,
			handleRefreshCounters,
			selectedTab,
			teamMembers,
			handleUpdateComment,
			handleAttachChildren,
			handleOpenConversation,
			handleDeleteComment,
		]
	);
	const handleChangeTab = (_, tab) => {
		setSelectedTab(tab);
		setComments({});
		setToRefresh(true);
	};
	return (
		<div className={styles.container}>
			<ViewTabs
				className={styles.tabs}
				selectedTab={selectedTab}
				tabIdentifier="id"
				tabs={tabHeaders}
				variant="scrollable"
				onChange={handleChangeTab}
			/>
			{!disableEditing && selectedTab === "open" && (
				<SidePanelBox className={styles.container__newComment} hasShadow={false}>
					<div className={styles.container__line}>
						<CustomTooltip showWhenOverflow title={user.displayName}>
							<div className={styles.name}>{user.displayName}</div>
						</CustomTooltip>
					</div>
					<LimitedAutocompleteTextField
						multiline
						autoFocus={autoFocus}
						className={styles.textField}
						data={teamMembers}
						displayLength={tempComment?.length > 0}
						helperTextClassName={styles.helperTextClassName}
						maxLength={512}
						placeholder={translate("common:comment.add-comment")}
						rows={tempComment?.length > 3 ? 4 : 1}
						size="sm"
						trigger={`${(isFeatureEnabled(Flags.MENTIONS) && "@") || null}`}
						value={tempComment}
						onBlur={handleBlur}
						onChange={handleChangeTempComment}
						onFocus={handleFocus}
					/>
					{tempComment?.length > 0 && (
						<div className={styles.line__rightItems}>
							<CustomIconButton
								btnClassName={styles.btnClassName}
								data-testid="comment.send.btn"
								icon={icon.faPaperPlane}
								iconClassName={styles.iconClassName}
								tooltip={translate("common:comment.send-button")}
								variant="outlined"
								onClick={handleAddComment}
							/>
						</div>
					)}
				</SidePanelBox>
			)}
			<div className={styles.listScrollContainer}>
				{(comments?.contents?.length > 0 &&
					comments?.contents?.map((prime, index) => (
						<div key={prime.commentId} ref={(el) => (itemRefs.current[index] = el)}>
							<HistoryRowRender comment={prime} />
						</div>
					))) || (
					<div className={styles.emptyStateContainer}>
						<IconComponent color="var(--color-blue)" icon={icon.faInfoCircle} />
						{translate("common:comment.empty-state.no-results")}
					</div>
				)}
				{comments?.hasMore && (
					<CustomButton
						className={styles.displayMore__button}
						color="secondary"
						variant="outlined"
						onClick={handleDisplayPrevious}
					>
						{translate("common:comment.row.display-previous")}
					</CustomButton>
				)}
			</div>
		</div>
	);
};

export default CommentsTabView;
