/* eslint-disable react/no-array-index-key */
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import {
	CommonFilters,
	CustomButton,
	CustomIconButton,
	DocumentFilter,
	FiltersWrapper,
	I18nTranslate,
	IconComponent,
	InformationIdFilter,
	KeywordFilter,
	PositionFilter,
	ProjectCategoriesFilter,
	adjustFiltersFromPayload,
	createNotification,
	icon,
} from "../../../../common/components";
import styles from "./ConversationBox.module.css";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../common/providers";
import aitendersWhiteLogo from "./images/aitendersWhiteLogo.svg";

const ConversationBox = ({ conversation, onOpenSidePanel, setIdChatAnswer, parameters, partners }) => {
	const userInitials = useSelector(({ context }) => context.user?.initials);
	const handleCopyMessage = () => {
		const tempElement = document.createElement("div");
		tempElement.innerHTML = conversation.content;
		const table = tempElement.querySelector("table");
		if (!table) {
			navigator.clipboard
				.writeText(conversation.content)
				.then(() => {
					createNotification({
						type: "info",
						message: translate("chat-project.notification-conversation-copied"),
					});
				})
				.catch((error) => {
					console.error("Failed to copy text: ", error);
				});
			return;
		}
		document.body.appendChild(tempElement);
		tempElement.style.position = "absolute";
		tempElement.style.left = "-9999px";
		const range = document.createRange();
		range.selectNode(table);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);
		try {
			document.execCommand("copy");
			createNotification({
				type: "info",
				message: translate("chat-project.notification-conversation-copied"),
			});
		} catch (error) {
			console.error("Failed to copy text: ", error);
		}
		window.getSelection().removeAllRanges();
		document.body.removeChild(tempElement);
	};

	function getTranslationKey(documentReferences, informationReferences) {
		if (documentReferences > 1 && informationReferences > 1) {
			return "chat-project.display-button-name-contents-in-documents";
		}
		if (informationReferences > 1 && documentReferences === 1) {
			return "chat-project.display-button-name-contents-in-document";
		}
		return "chat-project.display-button-name-content-in-document";
	}
	const adjustedFilters = useMemo(() => {
		if (conversation?.filterDTO) {
			return adjustFiltersFromPayload(conversation.filterDTO);
		}
		return null;
	}, [conversation]);
	return (
		<div className={styles.container}>
			{conversation.owner !== "system" ? (
				<IconComponent autoTranslate="no" className={styles.user__icon}>
					{userInitials}
				</IconComponent>
			) : (
				<IconComponent autoTranslate="no" className={styles.conversation__icon}>
					<img alt="aitenders_logo" height="18px" src={aitendersWhiteLogo} width="22px" />
				</IconComponent>
			)}

			<div className={styles.content__container}>
				<div>
					{conversation.content?.split("\n").map((line, index) => (
						// eslint-disable-next-line react/no-danger
						<div key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(line) }} />
					))}
					{conversation.owner !== "system" && (
						<FiltersWrapper
							multiline
							readOnly
							className={styles.filters__wrapper}
							components={[
								{
									enabled: true,
									component: CommonFilters.KEYWORDS,
									renderer: <KeywordFilter readOnly />,
								},
								{
									enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
									withDescription: false,
									multiSelection: true,
									labelKey: "label",
									valueKey: "key",
									component: CommonFilters.REQUIREMENT,
								},
								{
									enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
									component: CommonFilters.DOCUMENTS,
									renderer: <DocumentFilter disabled />,
									withDescription: false,
								},
								{
									enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
									component: CommonFilters.TOC,
									renderer: <KeywordFilter readOnly />,
								},
								{
									enabled: isSegFeatureEnabled(SegFlags.CATEGORY),
									component: CommonFilters.CATEGORIES,
									hasSearch: false,
									renderer: <ProjectCategoriesFilter disableFavoriteCategories readOnly />,
									hasFavorites: true,
								},
								{
									enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
									component: CommonFilters.POSITIONS,
									renderer: <PositionFilter readOnly />,
									partners,
								},
								{
									enabled: true,
									component: CommonFilters.INFORMATION_ID,
									renderer: <InformationIdFilter readOnly />,
								},
								{
									enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
									component: CommonFilters.CRITICALITY,
									labelKey: "label",
									valueKey: "value",
									dynamicItems:
										parameters.criticality?.map((c) => ({
											label: `common:enum.params.criticality.${c.toLowerCase()}`,
											value: c,
										})) || [],
								},
								{
									enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
									component: CommonFilters.NEGOTIABILITY,
									labelKey: "label",
									valueKey: "value",
									dynamicItems:
										parameters.negotiability?.map((n) => ({
											label: `common:enum.params.negotiability.${n.toLowerCase()}`,
											value: n,
										})) || [],
								},
								{
									enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
									component: CommonFilters.TYPE,
									labelKey: "label",
									valueKey: "value",
									dynamicItems:
										parameters.type?.map((t) => ({
											label: `common:enum.params.type.${t.toLowerCase()}`,
											value: t,
										})) || [],
								},
							]}
							defaultFilters={adjustedFilters}
							disablePortal={false}
							filterPopUpPlacement="auto"
						/>
					)}
					{conversation.owner === "system" && (
						<div className={styles.response_actions}>
							<div className={styles.btn__container}>
								{(conversation.informationReferences && conversation.documentReferences && (
									<CustomButton
										variant="outlined"
										onClick={() => {
											onOpenSidePanel();
											setIdChatAnswer(conversation.id);
										}}
									>
										{(conversation.informationReferences && conversation.documentReferences && (
											<I18nTranslate
												param={{
													documentReferences: conversation.documentReferences,
													informationReferences: conversation.informationReferences,
												}}
												translationKey={getTranslationKey(
													conversation.documentReferences,
													conversation.informationReferences
												)}
											/>
										)) ||
											null}
									</CustomButton>
								)) ||
									null}
							</div>
							<CustomIconButton
								className={styles.copyButton}
								data-testid="copy-response-button"
								icon={icon.faCopy}
								size="sm"
								variant="outlined"
								onClick={handleCopyMessage}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ConversationBox;
