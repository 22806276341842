export const isZipMimeTypes = (type) =>
	["application/zip", "application/x-zip-compressed", "multipart/x-zip", "application/zip-compressed"].includes(type);
export const isWordMimeTypes = (type) =>
	[
		"application/msword", // DOC (Word 97-2003 Document)
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX (Word Document)
		"application/msword", // DOT (Word 97-2003 Document Template)
		"application/vnd.openxmlformats-officedocument.wordprocessingml.template", // DOTX (Word Document Template)
		"application/vnd.ms-word.document.macroEnabled.12", // DOCM (Word Macro-Enabled Document)
		"application/vnd.ms-word.template.macroEnabled.12", // DOTM (Word Macro-Enabled Document Template)
		"application/rtf", // RTF (Rich Text Format)
	].includes(type);
export const isWordExtension = (extension) => ["doc", "rtf", "docx", "odt"].includes(extension);
export const isWordExtensionByName = (name) => [".doc", ".rtf", ".docx", ".odt"].some((ext) => name?.endsWith(ext));
export const isExcelMimeTypes = (type) =>
	[
		"application/vnd.ms-excel", // XLS (Excel 97-2003 Workbook)
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX (Excel Workbook)
		"application/vnd.ms-excel", // XLT (Excel 97-2003 Template)
		"application/vnd.openxmlformats-officedocument.spreadsheetml.template", // XLTX (Excel Template)
		"application/vnd.ms-excel.sheet.macroEnabled.12", // XLSM (Excel Macro-Enabled Workbook)
		"application/vnd.ms-excel.template.macroEnabled.12", // XLTM (Excel Macro-Enabled Template)
		"application/vnd.ms-excel.addin.macroEnabled.12", // XLAM (Excel Add-In)
	].includes(type);
export const isExcelExtension = (extension) => ["xls", "xlsx", "xlsm"].includes(extension);
export const isExcelExtensionByName = (name) => [".xls", ".xlsx", ".xlsm"].some((ext) => name?.endsWith(ext));
export const isPdfMimeTypes = (type) =>
	[
		"application/pdf", // PDF Document
	].includes(type);
export const isOdtMimeTypes = (type) =>
	[
		"application/vnd.oasis.opendocument.text", // PDF Document
	].includes(type);

export const isAllowedFile = (type) =>
	isZipMimeTypes(type) ||
	isWordMimeTypes(type) ||
	isExcelMimeTypes(type) ||
	isPdfMimeTypes(type) ||
	isOdtMimeTypes(type);

export function matchTypeIcon({ name, type }) {
	if (!type && !name) {
		return "faFileRegular";
	}
	if (type === "folder") {
		return "faFolder";
	}
	if (isZipMimeTypes(type) || type === "zip" || name?.endsWith(".zip")) {
		return "faFileZipper";
	}
	if (isWordExtensionByName(name) || isWordMimeTypes(type) || isWordExtension(type)) {
		return "faFileWord";
	}
	if (isExcelExtensionByName(name) || isExcelMimeTypes(type) || isExcelExtension(type)) {
		return "faFileExcel";
	}
	if (isPdfMimeTypes(type) || type === "pdf" || name?.endsWith(".pdf")) {
		return "faFilePdf";
	}
	return "faFileRegular";
}
