import React, { useState } from "react";
import { TextField } from "@mui/material";
import {
	CustomButton,
	CustomTooltip,
	OptionsPanelContainer,
	SidePanel,
	SidePanelContent,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import styles from "./TemplateCreatePanel.module.css";
import { isExcelMimeTypes } from "../../../../../../common/utils";
import { useApi } from "../../../../../../common/hooks";
import { BackOfficeService } from "../../../../../../api";

const TemplateCreatePanel = ({ companyId, onClose, onCreate, open }) => {
	const { call: createExportTemplate } = useApi(BackOfficeService.createExportTemplate);
	const [name, setName] = useState("");
	const [file, setFile] = useState(null);
	const [errors, setErrors] = useState({});
	const handleClose = () => {
		setName("");
		setFile(null);
		setErrors({});
		onClose();
	};
	const handleSubmit = () => {
		createExportTemplate({ companyId }, { file, name })
			.then((data) => {
				onCreate(data);
				handleClose();
			})
			.catch(console.error);
	};
	const handleChange = (value) => {
		setName(value);
		setErrors((prev) => ({ ...prev, name: value.length < 3 }));
	};
	const handleOpenFile = (e) => {
		const tempFile = e.target.files[0];
		if (!tempFile) {
			setErrors((prev) => ({ ...prev, file: "no-file" }));
			return;
		}
		setFile(tempFile);
		if (!isExcelMimeTypes(e.target.files[0].type)) {
			setErrors((prev) => ({ ...prev, file: "wrong-type" }));
			return;
		}
		setErrors((prev) => ({ ...prev, file: false }));
	};
	return (
		<SidePanel open={open} size={65} onClose={handleClose}>
			{({ onActive }) => (
				<SidePanelContent
					title={translate("dsi.export-templates-management.create-template")}
					onActive={onActive}
					onClose={handleClose}
				>
					<OptionsPanelContainer
						buttonText={translate("common:btn.submit")}
						disabledPrimaryButton={errors.name || errors.file || !file || !name}
						onClick={handleSubmit}
					>
						<TextField
							fullWidth
							required
							error={errors?.name && name.length > 0}
							helperText={
								errors.name &&
								name.length > 0 &&
								translate("common:error.required-with-min-data", { min: 3 })
							}
							label={translate("search-template.dialogs.create-template-dialog.input.name")}
							size="small"
							value={name}
							variant="outlined"
							onChange={(e) => handleChange(e.target.value)}
						/>
						<div className={styles.importContainer}>
							<CustomTooltip
								showWhenOverflow
								title={file?.name || translate("dsi.export-templates-management.import-template")}
							>
								<div className={styles.file}>
									{file?.name || translate("dsi.export-templates-management.import-template")}
								</div>
							</CustomTooltip>
							<CustomButton component="label" variant="outlined">
								<input hidden accept=".xls, .xlsx" type="file" onChange={handleOpenFile} />
								{translate("search-template.import.btn")}
							</CustomButton>
						</div>
						{errors.file && (
							<span className={styles.fileError}>
								{translate(`dsi.export-templates-management.import-template.error.${errors.file}`)}
							</span>
						)}
					</OptionsPanelContainer>
				</SidePanelContent>
			)}
		</SidePanel>
	);
};

export default TemplateCreatePanel;
