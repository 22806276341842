import React, { useEffect, useState } from "react";
import styles from "./DSIExportTemplates.module.css";
import { BackOfficeService } from "../../../../api";
import { TemplateCreatePanel, TemplateHeaders, TemplatePanels, TemplateRow } from "./components";
import { isNonEmptyArray } from "../../../../common/utils";
import { useApi, useEffectOnce } from "../../../../common/hooks";
import { CompaniesList } from "../../components";
import { BlockTitle, CustomButton, CustomDialog, icon } from "../../../../common/components";
import { translate } from "../../../../common/providers";

export default function DSIExportTemplates({ companies }) {
	const { call: getAllExportTemplates } = useApi(BackOfficeService.getExportTemplates);
	const { call: deleteExportTemplates } = useApi(BackOfficeService.deleteExportTemplates);
	const [selectedRow, setSelectedRow] = useState(null);
	const [panel, setPanel] = useState("");
	const [exportTemplates, setExportTemplates] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [openTemplateCreatePanel, setOpenTemplateCreatePanel] = useState(false);
	const [templateToDeleteId, setTemplateToDeleteId] = useState(0);

	const handleSelectCompany = (company) => {
		if (company !== selectedCompany) {
			setSelectedCompany(company);
		}
	};
	useEffect(() => {
		if (selectedCompany?.id) {
			getAllExportTemplates({ companyId: selectedCompany.id }).then(setExportTemplates).catch(console.error);
		}
	}, [getAllExportTemplates, selectedCompany]);
	useEffectOnce(
		() => {
			handleSelectCompany(companies[0]);
		},
		[],
		() => selectedCompany === null && isNonEmptyArray(companies)
	);
	const handleSelectRow = (row, pane) => {
		setSelectedRow(row);
		setPanel(pane);
	};
	const handleClose = () => {
		setSelectedRow(null);
		setPanel("");
	};
	const handleUpdateRow = (templateId, count) => {
		setExportTemplates((prev) =>
			prev.map((template) => {
				if (template.id === templateId) {
					return { ...template, projects: count };
				}
				return template;
			})
		);
	};
	const handleCreate = (newTemplate) => {
		setExportTemplates((prev) => [...prev, { ...newTemplate, projects: 0 }]);
	};
	const handleCloseTemplateCreatePanel = () => {
		setOpenTemplateCreatePanel(false);
	};
	const handleOpenTemplateCreatePanel = () => {
		setOpenTemplateCreatePanel(true);
	};
	const handleCloseDeleteDialog = () => {
		setTemplateToDeleteId(0);
	};
	const handleOpenDeleteDialog = (templateId) => {
		setTemplateToDeleteId(templateId);
	};
	const handleDelete = () => {
		deleteExportTemplates({ companyId: selectedCompany.id, templateId: templateToDeleteId })
			.then(() => {
				setExportTemplates((prev) =>
					prev.filter((prevExportTemplate) => prevExportTemplate.id !== templateToDeleteId)
				);
				handleCloseDeleteDialog();
			})
			.catch(console.error);
	};
	return (
		<>
			<div className={styles.leftPanel}>
				<CompaniesList
					companies={companies || []}
					selectedCompany={selectedCompany}
					onClickRow={handleSelectCompany}
				/>
			</div>
			<div className={styles.rightPanel}>
				<BlockTitle>{translate("dsi.export-templates-management.templates")}</BlockTitle>
				<div className={styles.createContainer}>
					<CustomButton variant="contained" onClick={handleOpenTemplateCreatePanel}>
						{translate("dsi.export-templates-management.create-template")}
					</CustomButton>
				</div>
				<TemplateHeaders />
				<div className={styles.list}>
					{isNonEmptyArray(exportTemplates) &&
						exportTemplates.map((template) => (
							<TemplateRow
								key={template.id}
								template={template}
								onDelete={handleOpenDeleteDialog}
								onSelectRow={handleSelectRow}
							/>
						))}
				</div>
				<TemplateCreatePanel
					companyId={selectedCompany?.id}
					open={openTemplateCreatePanel}
					onClose={handleCloseTemplateCreatePanel}
					onCreate={handleCreate}
				/>
				<TemplatePanels
					companyId={selectedCompany?.id}
					open={!!selectedRow}
					panel={panel}
					template={selectedRow}
					onClose={handleClose}
					onUpdateRow={handleUpdateRow}
				/>
			</div>
			<CustomDialog
				data-testid="confirm.delete.dialog"
				iconColor="var(--color-red)"
				iconModel={icon.faExclamationTriangle}
				open={templateToDeleteId}
				submitLabel={translate("common:btn.delete")}
				subTitle={translate("dsi.export-templates-management.delte-warning")}
				onClose={handleCloseDeleteDialog}
				onSubmit={handleDelete}
			/>
		</>
	);
}
