import React, { useEffect } from "react";
import { Checkbox, Radio } from "@mui/material";
import { InfiniteList2 } from "../../../infinite-list";
import styles from "./InfiniteListFilter.module.css";
import { CustomTooltip } from "../../../tooltip";

const InfiniteListFilter = ({
	identifier,
	labelIdentifier,
	onChange,
	onChangeSearch,
	request,
	search,
	singleSelection = false,
	value = [],
}) => {
	useEffect(() => {
		onChangeSearch(search);
	}, [onChangeSearch, search]);
	const handleSelect = (id) => {
		if (singleSelection) {
			onChange(id);
		} else {
			let tempValue = [...value];
			if (value.includes(id)) {
				tempValue = tempValue.filter((tValue) => tValue !== id);
			} else {
				tempValue = [...tempValue, id];
			}
			onChange(tempValue);
		}
	};
	const rowRenderer = (row) => (
		<div key={row[identifier]} className={styles.list__row}>
			{singleSelection ? (
				<Radio
					checked={value === row[identifier]}
					classes={{ root: styles.list__checkbox }}
					size="small"
					onClick={() => handleSelect(row[identifier])}
				/>
			) : (
				<Checkbox
					checked={value.includes(row[identifier])}
					classes={{ root: styles.list__checkbox }}
					size="small"
					onClick={() => handleSelect(row[identifier])}
				/>
			)}
			<CustomTooltip showWhenOverflow title={row[labelIdentifier]}>
				<span className={styles.list__rowLabel}> {row[labelIdentifier]}</span>
			</CustomTooltip>
		</div>
	);
	return (
		<div className={styles.container}>
			<InfiniteList2 request={request} rowRenderer={rowRenderer} />
		</div>
	);
};

export default InfiniteListFilter;
