import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/material";
import { InfiniteList2 } from "../../../infinite-list";
import { ApiService, SearchCardService, SearchCardTemplateService } from "../../../../../api";

const SearchCardFilter = ({ isMultiProject, search, value, onChange }) => {
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const projectId = useSelector(({ context }) => context.project?.id);
	const cancelTokenSourceRef = useRef();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		setInfiniteListRequest(() => ({ page, limit }) => {
			if (isMultiProject) {
				return SearchCardTemplateService.getTemplates(
					{ page, limit },
					{ filters: { name: search, templateOwner: "AUTOMATION" } },
					cancelTokenSourceRef.current.token
				);
			}
			return SearchCardService.getAll(
				{ projectId },
				{ page, limit, filters: { name: search, visibility: "AUTOMATION" } },
				cancelTokenSourceRef.current.token
			);
		});
	}, [isMultiProject, projectId, search]);
	const handleChange = (cardId) => {
		let tempSelection = value ? [...value] : [];
		if (tempSelection.includes(cardId)) {
			tempSelection = tempSelection.filter((select) => select !== cardId);
		} else {
			tempSelection = [...tempSelection, cardId];
		}
		onChange(tempSelection);
	};
	const rowRenderer = (row) => (
		<div>
			<Checkbox checked={value?.includes(row.id)} onChange={() => handleChange(row.id)} />
			{row.title}
		</div>
	);
	return (
		<div>
			<InfiniteList2 request={infiniteListRequest} rowRenderer={rowRenderer} />
		</div>
	);
};

export default SearchCardFilter;
