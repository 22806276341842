import React from "react";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../common/providers";
import styles from "./MembersListHeader.module.css";

export default function MembersListHeader() {
	return (
		<div className={styles["members-row"]}>
			<div className={styles["members-row__name-cell"]}>
				<span>{translate("team-management.members.header.name")}</span>
			</div>
			<div className={styles["members-row__select-cell"]}>{translate("team-management.members.header.role")}</div>
			{isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS)}
			<div className={styles["members-row__button-cell"]} />
			<div className={styles["members-row__button-cell"]} />
		</div>
	);
}
