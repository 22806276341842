import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@mui/material";
import { setPage, setLimit } from "../../../../slice/dsi-slice";
import {
	CustomTable,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	CustomTableBody,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import styles from "./UserList.module.css";

export default function UserList() {
	const page = useSelector(({ dsi }) => dsi.page);
	const limit = useSelector(({ dsi }) => dsi.limit);
	const results = useSelector(({ dsi }) => dsi.results);
	const totalElements = useSelector(({ dsi }) => dsi.totalElements);
	const dispatch = useDispatch();
	const handleChangePage = (_, p) => {
		if (totalElements > 0) {
			dispatch(setPage(p));
		}
	};
	const handleChangeLimitPerPage = (e) => {
		if (totalElements > 0) {
			dispatch(setLimit(e.target?.value || 25));
			dispatch(setPage(0));
		}
	};
	return (
		<>
			<CustomTable stickyHeader>
				<CustomTableHead>
					<CustomTableRow>
						{[{ name: "lastname" }, { name: "firstname" }, { name: "email" }].map((header) => (
							<CustomTableCell key={header.name} direction="asc">
								{header.name}
							</CustomTableCell>
						))}
					</CustomTableRow>
				</CustomTableHead>
				<CustomTableBody emptyState={translate("dsi.dsi-export-user.empty")}>
					{Array.isArray(results) &&
						results.map((row) => (
							<CustomTableRow key={row.id} hover>
								<CustomTableCell>{row.lastname}</CustomTableCell>
								<CustomTableCell>{row.firstname}</CustomTableCell>
								<CustomTableCell>{row.email}</CustomTableCell>
							</CustomTableRow>
						))}
				</CustomTableBody>
			</CustomTable>
			<TablePagination
				className={styles.tablePagination}
				component="div"
				count={totalElements || 0}
				page={page}
				rowsPerPage={limit}
				rowsPerPageOptions={[10, 25, 50]}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeLimitPerPage}
			/>
		</>
	);
}
