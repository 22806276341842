import React, { useState } from "react";
import { TextField } from "@mui/material";
import styles from "../TagDialog.module.css";
import { translate } from "../../../../../common/providers";
import { CustomDialog } from "../../../../../common/components";

const NewTagDialog = ({ open, close, onCreateNewTag }) => {
	const [name, setName] = useState("");
	const changeName = (value) => {
		setName(value);
	};
	const handleCloseAndAction = () => {
		onCreateNewTag(name);
		close();
	};
	return (
		<CustomDialog
			displayCloseCross
			isForm
			open={open}
			title={translate("qa-journal.qa-actions.new-tag-dialog.title")}
			onClose={close}
			onSubmit={handleCloseAndAction}
		>
			<div className={`${styles["margin-bottom"]} ${styles["padding-top"]}`} style={{ display: "flex" }}>
				<TextField
					fullWidth
					required
					className={styles["dialog__name-field"]}
					label={translate("qa-journal.qa-actions.new-tag-dialog.texfield-label")}
					size="small"
					variant="outlined"
					onChange={(e) => changeName(e.target.value)}
				/>
			</div>
		</CustomDialog>
	);
};
export default NewTagDialog;
