import client from "../client/client";

class RequirementParameterService {
	getParameters({ reqId }, cancelToken) {
		return client.get(`/requirements/${reqId}/params`, { config: { cancelToken } });
	}

	updateParameters({ reqId }, payload, cancelToken) {
		return client.patch(`/requirements/${reqId}/params`, { payload, config: { cancelToken } });
	}

	getCriticality(cancelToken) {
		return client.get("/requirements/params/criticality", { config: { cancelToken } });
	}

	getNegotiability(cancelToken) {
		return client.get("/requirements/params/negotiability", { config: { cancelToken } });
	}

	getReqTypes(cancelToken) {
		return client.get("/requirements/params/type", { config: { cancelToken } });
	}
}

export default new RequirementParameterService();
