export const TIME_UNITS = {
	LONG: "long",
	YEARS: "years",
	LAST_YEAR: "last_year",
	START_OF_YEAR: "start_of_year",
	MONTHS: "months",
	LAST_MONTH: "last_month",
	THIS_MONTH: "this_month",
	WEEKS: "weeks",
	LAST_WEEK: "last_week",
	DAYS: "days",
	YESTERDAY: "yesterday",
	TODAY: "today",
	HOURS: "hours",
	MINUTES: "minutes",
	MOMENTS: "moments",
};
