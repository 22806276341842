import React from "react";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";

export default function PartnersTable({ companies = [] }) {
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					<CustomTableCell>{translate("admin.project-details.th.code")}</CustomTableCell>
					<CustomTableCell>{translate("admin.project-details.th.name")}</CustomTableCell>
					<CustomTableCell>{translate("admin.project-details.th.role")}</CustomTableCell>
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody>
				{Array.isArray(companies) &&
					companies.map((company) => (
						<CustomTableRow key={company.id}>
							<CustomTableCell autoTranslate="no">{company.shortname}</CustomTableCell>
							<CustomTableCell autoTranslate="no">{company.name}</CustomTableCell>
							<CustomTableCell>{company.role ? company.role : "-"}</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
