import React from "react";
import ProjectList from "./ProjectList";

const PANELS = {
	projects: {
		title: "dsi.export-templates-management.panel.projects.title",
		panel: ({ companyId, templateId, platformProjects, onUpdateRow }) => (
			<ProjectList
				companyId={companyId}
				platformProjects={platformProjects}
				templateId={templateId}
				onUpdateRow={onUpdateRow}
			/>
		),
	},
};

export default PANELS;
