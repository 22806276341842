import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CustomIconButton, createNotification, icon } from "../../../../../../../../../common/components";
import { translate } from "../../../../../../../../../common/providers";
import { CollabService } from "../../../../../../../../../api";
import { useApi } from "../../../../../../../../../common/hooks";

function isCellInTable(table, cell) {
	const { x1: xt1, y1: yt1, x2: xt2, y2: yt2 } = table;
	const { x1: xc1, y1: yc1, x2: xc2, y2: yc2 } = cell;

	const isXWithin = xc1 >= xt1 && xc2 <= xt2;
	const isYWithin = yc1 >= yt1 && yc2 <= yt2;

	return isXWithin && isYWithin;
}

export default function CopyTableToClipboardButton({ hoveredBlock }) {
	const { call: getTable } = useApi(CollabService.getTable);
	const [selectedTable, setSelectedTable] = useState(null);
	const allTables = useSelector(({ srPdf }) => srPdf.tables);
	const isTableInPage = useMemo(() => hoveredBlock.contentType === "TABLE_CELL", [hoveredBlock]);
	const isSupportedTable = useMemo(
		() => allTables.find((table) => isCellInTable(table, hoveredBlock)),
		[allTables, hoveredBlock]
	);
	const handleCopyClipboard = () => {
		if (hoveredBlock) {
			const trueTables = allTables.filter((t) => t.page === hoveredBlock.pageStart);
			setSelectedTable(trueTables.find((table) => isCellInTable(table, hoveredBlock)));
		}
	};

	useEffect(() => {
		if (selectedTable) {
			getTable({ tableId: selectedTable.tableId })
				.then((data) => {
					if (navigator.clipboard && window.ClipboardItem) {
						const clipboardItem = new window.ClipboardItem({
							"text/html": new Blob([data.value], { type: "text/html" }),
							"text/plain": new Blob([data.value], { type: "text/plain" }),
						});
						navigator.clipboard.write([clipboardItem]).then(() => {
							createNotification({
								message: translate("smart-review.image-container.notification.table-copied"),
							});
						});
						setSelectedTable(null);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [selectedTable, hoveredBlock, getTable]);

	return (
		<>
			{isTableInPage && isSupportedTable && (
				<CustomIconButton
					icon={icon.faTable}
					tooltip={translate("smart-review.cmp-display-overlay.tooltip.copy-table-content")}
					onClick={handleCopyClipboard}
				/>
			)}
		</>
	);
}
