import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import { CustomIconButton, DocumentProgress, icon } from "../../../../../common/components";
import styles from "./DocumentProgressList.module.css";

export default function DocumentProgressList({ documents = [], indentLevel = 0, onCloseDocument = null }) {
	let allowExpand = true;
	const [expanded, setExpanded] = useState(false);
	const preventExpand = () => {
		allowExpand = false;
	};
	const handleChange = (event, newExpanded) => {
		setTimeout(() => {
			if (allowExpand) {
				setExpanded(newExpanded);
			} else {
				allowExpand = true;
			}
		}, 0);
	};
	const getChevronIcon = () => (expanded ? icon.faChevronDown : icon.faChevronRight);
	const handleClose = (index) => {
		preventExpand();
		if (indentLevel > 0 || !onCloseDocument) {
			return;
		}
		onCloseDocument(index);
	};
	return (
		Array.isArray(documents) &&
		documents.length > 0 &&
		documents.map((d, index) =>
			Array.isArray(d.documents) && d.documents.length > 0 ? (
				<Accordion
					key={d.id}
					classes={{
						root: styles.accordion__root,
					}}
					expanded={expanded}
					onChange={handleChange}
				>
					<AccordionSummary
						classes={{
							root: styles.root,
							content: styles.content,
						}}
						className={styles.accordion__header}
					>
						<CustomIconButton className={styles["expand-button"]} icon={getChevronIcon()} />
						<DocumentProgress
							expandable
							displayCloseButton={indentLevel === 0 && !!onCloseDocument}
							displayProgress={false}
							documentName={d.fileName}
							indentLevel={indentLevel}
							nbPages={d.pageCount}
							status={d.status}
							onClose={() => handleClose(index)}
						/>
					</AccordionSummary>
					<AccordionDetails
						classes={{
							root: styles["accordion-details__root"],
						}}
					>
						<DocumentProgressList
							displayProgress={false}
							documents={d.documents}
							indentLevel={indentLevel + 1}
						/>
					</AccordionDetails>
				</Accordion>
			) : (
				<DocumentProgress
					key={d.id}
					displayCloseButton={indentLevel === 0 && !!onCloseDocument}
					displayProgress={false}
					documentName={d.fileName}
					indentLevel={indentLevel}
					nbPages={d.pageCount}
					status={d.status}
					onClose={() => handleClose(index)}
				/>
			)
		)
	);
}
