import React from "react";
import { QuestionsTabView } from "./components";

export default function QuestionsTab({
	disableEditing,
	informationId = 0,
	onActive = null,
	pageStart = 0,
	selectedMode,
}) {
	return (
		<QuestionsTabView
			disableEditing={disableEditing}
			informationId={informationId}
			pageStart={pageStart}
			selectedMode={selectedMode}
			onActive={onActive}
		/>
	);
}
