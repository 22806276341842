import React, { useEffect, useReducer, useRef } from "react";
import { useSelector } from "react-redux";
import { CustomMenuButton, createNotification, icon } from "../../../../common/components";
import DocumentCenterMainDropDown from "../document-center-main-drop-down/DocumentCenterMainDropDown";
import { translate } from "../../../../common/providers";

export default function DocumentCenterMenuHeader({ onImportFile, onOpenNewFolderDialog }) {
	const dropDownControlRef = useRef(null);
	const hasReceivedModifications = useSelector(({ documentCenter }) => documentCenter.hasReceivedModifications);
	const hasActionPermission = useSelector(({ documentCenter }) => documentCenter.hasActionPermission);
	const [openNewFolderDialog, dispatchOpenNewFolderDialog] = useReducer((prev) => !prev, false);

	useEffect(() => {
		if (hasReceivedModifications && openNewFolderDialog) {
			dispatchOpenNewFolderDialog();
		}
	}, [hasReceivedModifications, openNewFolderDialog]);

	const handleOnCloseDialog = () => {
		dropDownControlRef.current.onClose();
	};

	const handleOpenNewFolderDialog = () => {
		handleOnCloseDialog();
		onOpenNewFolderDialog();
	};

	const handleImportFile = (e) => {
		handleOnCloseDialog();
		const allowedFiles = Object.values(e.target.files).filter((file) => typeof file !== "number");
		if (Object.values(e.target.files).length > allowedFiles.length) {
			createNotification({
				type: "error",
				message: translate("common:upload-file.bad-format"),
			});
		}
		if (allowedFiles.length > 0) {
			onImportFile(allowedFiles);
		}
	};

	return (
		hasActionPermission && (
			<CustomMenuButton
				ref={dropDownControlRef}
				disablePortal
				fullWidth
				color="primary"
				disabled={hasReceivedModifications}
				size="md"
				startIcon={icon.faPlus}
				text={translate("document-center.menu.new")}
			>
				<DocumentCenterMainDropDown onImportFiles={handleImportFile} onNewFolder={handleOpenNewFolderDialog} />
			</CustomMenuButton>
		)
	);
}
