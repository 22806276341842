import React, { useState, useEffect } from "react";
import { CircularProgress, Divider, List } from "@mui/material";
import CompanyRow from "./components/CompanyRow";
import styles from "./Panel.module.css";
import PanelHeader from "./PanelHeader";
import { Permissions } from "../../../../common/providers";
import { BlockTitle } from "../../../../common/components";
import { searchInString } from "../../../../common/utils";

function searchCompanyInTree(tree, search) {
	if (!Array.isArray(tree) || tree.length === 0 || !search) {
		return [];
	}
	const found = [];
	tree.forEach((cny) => {
		const company = { ...cny };
		company.companies = [...searchCompanyInTree(company.companies, search)];
		if (searchInString(company.name, search) || company.companies.length !== 0) {
			found.push(company);
		}
	});
	return found;
}

export default function Panel({
	title,
	companies = [],
	selectedCompany,
	isLoading,
	isProjectDirector,
	isUserProjectLeader,
	onAddPartner,
	onChangeCompanyAccess,
	onEdit,
	onSelectCompany,
	onClickPrivacy,
}) {
	const [search, setSearch] = useState("");
	const [displayedCompanies, setDisplayedCompanies] = useState(companies);

	useEffect(() => {
		if (!search) {
			setDisplayedCompanies(companies);
		} else {
			setDisplayedCompanies(searchCompanyInTree(companies, search));
		}
	}, [companies, search]);

	const handleSearch = (e) => setSearch(e.target.value || "");
	const handleClearSearch = () => setSearch("");

	return (
		<div className={styles.panel}>
			<BlockTitle>{title}</BlockTitle>
			<PanelHeader
				isLoading={isLoading}
				isUserProjectLeader={isUserProjectLeader}
				search={search}
				onAdd={onAddPartner}
				onClearSearch={handleClearSearch}
				onClickPrivacy={onClickPrivacy}
				onSearch={handleSearch}
			/>
			<Divider />

			{!isLoading && (
				<List dense disablePadding className={styles.panel__list}>
					{displayedCompanies.map((company) => (
						<CompanyRow
							key={company.id}
							company={company}
							displayLeaderIcon={company.role === Permissions.PROJECT_LEADER.role}
							isProjectDirector={isProjectDirector}
							selectedCompany={selectedCompany}
							onBlock={onChangeCompanyAccess}
							onClickCompany={onSelectCompany}
							onEdit={onEdit}
						/>
					))}
				</List>
			)}
			{isLoading && (
				<div className={styles.loader}>
					<CircularProgress />
				</div>
			)}
		</div>
	);
}
