import { Dialog } from "@mui/material";
import React from "react";

export default function GenericDialog({
	className = "",
	classes = null,
	children,
	disableBackdropClick = false,
	disableEnforceFocus = false,
	disableEscapeKeyDown = false,
	fullScreen = false,
	fullWidth = false,
	hideBackdrop = false,
	maxWidth = "sm",
	onClose,
	onEnter = null,
	onEntered = null,
	onEntering = null,
	onExit = null,
	onExited = null,
	onExiting = null,
	open = false,
}) {
	const handleClose = (event, reason) => {
		if (disableBackdropClick && reason === "backdropClick") {
			return;
		}
		if (typeof onClose === "function") {
			onClose(event, reason);
		}
	};
	return (
		<Dialog
			classes={classes}
			className={className}
			disableEnforceFocus={disableEnforceFocus}
			disableEscapeKeyDown={disableEscapeKeyDown}
			fullScreen={fullScreen}
			fullWidth={fullWidth}
			hideBackdrop={hideBackdrop}
			maxWidth={maxWidth}
			open={open}
			TransitionProps={{
				onEnter,
				onEntered,
				onEntering,
				onExit,
				onExited,
				onExiting,
			}}
			onClose={handleClose}
		>
			{children}
		</Dialog>
	);
}
