import client from "../../client/client";

class NormsFavoriteService {
	add({ normId, projectId }, cancelToken) {
		return client.post(`/favorite-norms/norms/${normId}/projects/${projectId}`, { config: { cancelToken } });
	}

	delete({ normId, projectId }, cancelToken) {
		return client.delete(`/favorite-norms/norms/${normId}/projects/${projectId}`, { config: { cancelToken } });
	}
}

export default new NormsFavoriteService();
