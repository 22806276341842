import React from "react";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../common/providers";
import { CustomButton, icon, Tile, TileBody, TileHeader, VerticalDivider } from "../../../../common/components";
import styles from "./ProjectDocsInfo.module.css";
import { NavUtils } from "../../../../navigation";

export default function ProjectDocsInfo({ className = "", onChangeUrl, projectId, totaldocuments, totalpages }) {
	return (
		<Tile className={className}>
			<TileHeader
				className={styles.header}
				iconName={icon.faFileAlt}
				title={translate("dashboards.project-docs-infos.title.docs-uploaded")}
			/>
			<TileBody>
				<div className={styles.container}>
					<div className={styles.figuresContainer}>
						<div className={styles.figure}>
							<span className={styles.figure__number}>{totaldocuments}</span>
							<span className={styles.figure__label}>
								{translate("dashboards.project-docs-infos.card.content.documents")}
							</span>
						</div>
						<VerticalDivider />
						<div className={styles.figure}>
							<span className={styles.figure__number}>{totalpages}</span>
							<span className={styles.figure__label}>
								{translate("dashboards.project-docs-infos.card.content.pages")}
							</span>
						</div>
					</div>
					{isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER) && (
						<CustomButton
							className={styles.manageDocumentsBtn}
							variant="outlined"
							onClick={() => onChangeUrl(NavUtils.goToDocumentCenter(projectId))}
						>
							{translate("dashboards.project-docs-infos.card.manage-documents")}
						</CustomButton>
					)}
				</div>
			</TileBody>
		</Tile>
	);
}
