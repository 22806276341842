import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiService, ProjectDocumentsService } from "../../../../../api";
import styles from "./DocumentFilter.module.css";
import { DocByFolder, DocByRole, SelectionList } from "./components";
import { translate } from "../../../../providers";
import { ToggleButtons } from "../../../toggles";
import { isNonEmptyArray } from "../../../../utils";

export default function DocumentFilter({
	search,
	onChange,
	onSetSelectedDocuments,
	selectedDocuments,
	disabled,
	value = { is: true, matchAll: true, elements: [] },
}) {
	const [selectedOption, setSelectedOption] = useState(null);
	const [mode, setMode] = useState("folder");
	const [documents, setDocuments] = useState({ precedence: [], role: [] });
	const projectId = useSelector(({ context }) => context.project.id);
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		ProjectDocumentsService.getAllPrecedences({ projectId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setDocuments((prev) => ({ ...prev, precedence: data.filter((prec) => prec.count !== 0) }));
				ProjectDocumentsService.getAllRoles({ projectId }, cancelTokenSourceRef.current.token)
					.then((dataRole) => {
						setDocuments((prev) => ({ ...prev, role: dataRole }));
					})
					.catch((err) => console.error(err));
			})
			.catch((err) => {
				console.error(err);
			});
	}, [projectId]);
	useEffect(() => {
		setSelectedOption(null);
	}, [mode]);
	useEffect(() => {
		let request = null;
		if (mode === "role" || mode === "precedence") {
			const documentCount = documents[mode].find((opt) => opt[mode] === selectedOption?.[mode])?.documents
				?.length;
			if (mode === "role") {
				if (selectedOption && !documentCount) {
					request = ProjectDocumentsService.getRole;
				}
			}
			if (mode === "precedence") {
				if (selectedOption && !documentCount) {
					request = ProjectDocumentsService.getPrecedence;
				}
			}
		}
		if (typeof request === "function") {
			const selectedOptionMode = mode === "precedence" ? `${mode}Id` : mode;
			request(
				{ projectId, [selectedOptionMode]: selectedOption[selectedOptionMode] },
				cancelTokenSourceRef.current.token
			)
				.then((data) => {
					const tempDocuments = documents?.[mode].map((doc) =>
						doc[selectedOptionMode] === selectedOption[selectedOptionMode]
							? { ...doc, documents: data }
							: { ...doc }
					);
					setDocuments((prev) => ({ ...prev, [mode]: tempDocuments }));
				})
				.catch((err) => console.error(err));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode, selectedOption, projectId]);
	const handleOpen = (option) => {
		if (disabled) {
			return;
		}
		if (option[mode]) {
			setSelectedOption(option);
		}
	};
	const handleResetOption = () => setSelectedOption(null);
	const handleClear = () => {
		if (disabled) {
			return;
		}
		onChange({ is: value.is, matchAll: true, elements: [] });
	};
	const handleUnselect = (doc) => {
		if (disabled) {
			return;
		}
		const newValue = { ...value, elements: value.elements || [] };
		const newSelection = newValue.elements.filter((pDoc) => pDoc !== doc.id);
		onChange({ is: value.is, matchAll: true, elements: newSelection });
	};
	const handleSelection = (doc) => {
		if (disabled) {
			return;
		}
		const newValue = { ...value, elements: value.elements || [] };
		let newSelection = [];
		if (
			typeof onSetSelectedDocuments === "function" &&
			!selectedDocuments.some((selectedDocument) => selectedDocument.id === doc.id)
		) {
			onSetSelectedDocuments([...selectedDocuments, { id: doc.id, documentName: doc.documentName }]);
			newSelection = [...newValue.elements, doc.id];
		} else {
			newSelection = value.elements?.some((el) => el === doc.id)
				? newValue.elements.filter((pDoc) => pDoc !== doc.id)
				: [...newValue.elements, doc.id];
		}
		onChange({ is: value.is, matchAll: true, elements: newSelection });
	};
	const handleChange = (_, newMode) => {
		if (disabled) {
			return;
		}
		setMode(newMode);
		setSelectedOption(null);
	};
	return (
		<div className={styles.main}>
			<ToggleButtons
				exclusive
				labelized
				btnClassName={styles.toggleGroup__btn}
				className={styles.toggleGroup}
				content={[
					{
						value: "folder",
						icon: "faFolder",
						tooltip: translate("common:filters.document-list.folder-list"),
					},
					{
						value: "precedence",
						icon: "faStar",
						tooltip: translate("common:filters.document-list.precedence-list"),
					},
					{
						value: "role",
						icon: "faUserTie",
						tooltip: translate("common:filters.document-list.role-list"),
					},
				]}
				size="large"
				value={mode}
				onChange={handleChange}
			/>
			{isNonEmptyArray(selectedDocuments) && (
				<SelectionList
					disabled={disabled}
					selection={selectedDocuments.filter((selectedDoc) =>
						value.elements?.some((doc) => doc === selectedDoc.id)
					)}
					onClear={handleClear}
					onSelect={handleUnselect}
				/>
			)}
			{!search && (mode === "role" || mode === "precedence") && (
				<div>
					<DocByRole
						documents={documents}
						selectedOption={selectedOption}
						selection={value.elements}
						type={mode}
						onDocumentSelection={handleSelection}
						onOpen={handleOpen}
						onResetOption={handleResetOption}
						onSetSelectedDocuments={onSetSelectedDocuments}
					/>
				</div>
			)}
			{(search || mode === "folder") && (
				<DocByFolder
					disabled={disabled}
					documents={documents}
					externalSearch={search}
					selection={value.elements}
					onDocumentSelection={handleSelection}
					onSetSelectedDocuments={onSetSelectedDocuments}
				/>
			)}
		</div>
	);
}
