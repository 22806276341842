import React from "react";
import { CompanyList, FeatureList, Info, ProjectList } from ".";

const PANELS = {
	features: {
		title: "dsi.package-management.panel.features.title",
		panel: ({ onUpdateRow, pck }) => <FeatureList id={pck?.id} onUpdateRow={onUpdateRow} />,
	},
	companies: {
		title: "dsi.package-management.panel.companies.title",
		panel: ({ onUpdateRow, pck, platformCompanies }) => (
			<CompanyList id={pck?.id} platformCompanies={platformCompanies} onUpdateRow={onUpdateRow} />
		),
	},
	projects: {
		title: "dsi.package-management.panel.projects.title",
		panel: ({ pck }) => <ProjectList id={pck?.id} />,
	},
	info: {
		title: "dsi.package-management.panel.info.title",
		panel: ({ pck, onClose, onUpdateRow }) => <Info pck={pck} onClose={onClose} onUpdateRow={onUpdateRow} />,
	},
	creation: {
		title: "dsi.package-management.panel.creation.title",
		panel: ({ onClose, onRefreshList }) => <Info onClose={onClose} onRefreshList={onRefreshList} />,
	},
};

export default PANELS;
