import React, { useEffect, useState } from "react";
import { debounce } from "../../../../../../common/utils";
import { translate } from "../../../../../../common/providers";
import { CustomButton, SearchInput } from "../../../../../../common/components";
import styles from "./FilterHeader.module.css";

const debounceFunc = debounce((func) => func(), 500);

export default function FilterHeader({ onChangeFilters, onOpenCreation }) {
	const [name, setName] = useState("");

	useEffect(() => {
		debounceFunc(() => onChangeFilters({ name }));
	}, [name, onChangeFilters]);
	const handleChangeSearchValue = (e) => {
		const { value } = e.target;
		setName(value);
	};
	const handleClearSearchValue = () => {
		setName("");
	};

	return (
		<div className={styles.header}>
			<SearchInput
				className={styles.searchContainer__textfield}
				label={translate("common:categories.textfield.label")}
				value={name}
				onChange={handleChangeSearchValue}
				onClearSearch={handleClearSearchValue}
			/>
			<CustomButton color="primary" variant="contained" onClick={onOpenCreation}>
				{translate("dsi.package-management.panel.creation.title")}
			</CustomButton>
		</div>
	);
}
