import React from "react";
import { ListItem, ListItemText } from "@mui/material";
import { CustomIconButton, icon, CustomTooltip } from "../../../../common/components";
import { translate } from "../../../../common/providers";

export default function DocumentRow({ document, onClick, isSelected, showTocRedressment, showErrorLog }) {
	return (
		<ListItem
			button
			divider
			alignItems="flex-start"
			style={{
				backgroundColor: `${isSelected ? "var(--color-light-grey-3)" : ""}`,
				paddingTop: "10px",
				paddingBottom: "10px",
			}}
			onClick={onClick}
		>
			<CustomTooltip title={document.docFilename}>
				<ListItemText primary={document.docName} style={{ overflowWrap: "break-word" }} translate="no" />
			</CustomTooltip>
			<div style={{ display: "flex", margin: "auto" }}>
				<CustomIconButton
					badgeColor="secondary"
					badgeContent={document.contentsCounter + document.tocsCounter}
					icon={icon.faExclamation}
					size="md"
					tooltip={translate("consistency-check.document-row.btn.go-to-log-error")}
					onClick={() => showErrorLog(document)}
				/>

				<CustomIconButton
					icon={icon.faAlignLeft}
					tooltip={translate("consistency-check.errors.btn.go-to-toc-redressment")}
					onClick={() => showTocRedressment(document)}
				/>
			</div>
		</ListItem>
	);
}
