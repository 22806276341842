import { createSlice } from "@reduxjs/toolkit";
import { search } from "./dsi-thunks";

const initFilters = () => ({ companies: [], projects: [], search: "" });

const initialState = {
	limit: 25,
	page: 0,
	totalResults: 0,
	results: [],
	filters: initFilters(),
	IsLoadingUsers: false,
	previousFilters: initFilters(),
	tempFilters: initFilters(),
};

const dsiSlice = createSlice({
	name: "dsi",
	initialState,
	reducers: {
		setPage: (state, { payload }) => ({ ...state, page: payload }),
		setLimit: (state, { payload }) => ({ ...state, limit: payload }),
		setFilters: (state, { payload }) => ({
			...state,
			page: 0,
			filters: payload,
			tempFilters: payload,
			results: state.tempResults,
			totalElements: state.tempTotalElements,
		}),
		setTempFilters: (state, { payload }) => ({
			...state,
			tempFilters: payload,
			tempTotalElements: state.totalElements,
		}),
	},
	extraReducers: (builder) => {
		builder.addCase(search.fulfilled, (state, action) => {
			const { contents, totalElements } = action.payload;
			const { display, unfiltered } = action.meta.arg;
			return {
				...state,
				results: display ? contents : state.results,
				tempResults: !display ? contents : [],
				totalElements: display ? totalElements : state.totalElements,
				tempTotalElements: !display ? totalElements : 0,
				filters: display ? unfiltered : state.filters,
				tempFilters: unfiltered,
				IsLoadingUsers: false,
			};
		});
		builder.addCase(search.pending, (state) => ({
			...state,
			IsLoadingUsers: true,
		}));
		builder.addCase(search.rejected, (state) => ({
			...state,
			IsLoadingUsers: false,
		}));
		builder.addMatcher(
			({ type }) => typeof type === "string" && type.endsWith("rejected"),
			(_, { error }) => {
				console.error(error.message);
			}
		);
	},
});

export { search };
export const { setTempFilters, setPage, setLimit, setFilters } = dsiSlice.actions;
export default dsiSlice.reducer;
