import client from "../../client/client";

class SearchCardActionService {
	getAllContentIds({ projectId }, payload, cancelToken) {
		return client.post(`/searchcard-actions/projects/${projectId}`, { payload, config: { cancelToken } });
	}

	getProjectInformationAttributes({ projectId }, cancelToken) {
		return client.get(`/searchcard-actions/projects/${projectId}/information-attributes`, {
			config: { cancelToken },
		});
	}

	updateProjectInformationAttributes({ projectId }, payload, cancelToken) {
		return client.patch(`/searchcard-actions/projects/${projectId}/information-attributes`, {
			payload,
			config: { cancelToken },
		});
	}

	getInformationAttributesCounters({ projectId, attribute }, payload, cancelToken) {
		return client.post(`/searchcard-actions/projects/${projectId}/information-attributes/${attribute}`, {
			payload,
			config: { cancelToken },
		});
	}

	getCategoryCounters({ projectId }, payload, cancelToken) {
		return client.post(`/searchcard-actions/projects/${projectId}/information-categories`, {
			payload,
			config: { cancelToken },
		});
	}

	updateCategories({ projectId }, payload, cancelToken) {
		return client.patch(`/searchcard-actions/projects/${projectId}/information-categories`, {
			payload,
			config: { cancelToken },
		});
	}

	getTypeCounters({ projectId }, payload, cancelToken) {
		return client.post(`/searchcard-actions/projects/${projectId}/information-type`, {
			payload,
			config: { cancelToken },
		});
	}

	updateType({ projectId }, payload, cancelToken) {
		return client.patch(`/searchcard-actions/projects/${projectId}/information-type`, {
			payload,
			config: { cancelToken },
		});
	}
}

export default new SearchCardActionService();
