import React from "react";
import { SidePanel, SidePanelContent } from "../../../../../../common/components";
import PANELS from "./panels";
import { translate } from "../../../../../../common/providers";

export default function FlagPanels({
	feat,
	onActive: onActiveFromParent = null,
	onClose,
	onUpdateRow,
	open,
	panel,
	platformProjects,
}) {
	return (
		<SidePanel open={open} size={65} onActive={onActiveFromParent} onClose={onClose}>
			{({ onActive }) => (
				<SidePanelContent
					title={panel && translate(PANELS[panel].title, { name: feat?.name })}
					onActive={onActive}
					onClose={onClose}
				>
					{feat && panel && PANELS[panel].panel({ feat, platformProjects, onUpdateRow })}
				</SidePanelContent>
			)}
		</SidePanel>
	);
}
