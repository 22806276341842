import { Flags, Permissions, CompanyType } from "../../common/providers";

export default [
	{
		name: "navigation:home.admin",
		to: "/admin",
		flag: Flags.ADMIN,
		role: Permissions.COMPANY_ADMIN,
		allowedCompanyTypes: [CompanyType.COMPANY, CompanyType.BUSINESS_UNIT],
	},
];
