import client from "../../client/client";

class ValidatorService {
	// Gets meta data of validator page view
	getContents({ docId }, { page = 0 }, cancelToken) {
		return client.get(`/smartview/validator/documents/${docId}`, { params: { page }, config: { cancelToken } });
	}

	// Transfert data from review to validation in a document
	transferReqToVall({ docId }, payload, cancelToken) {
		return client.put(`/smartview/validator/documents/${docId}/transfer`, { payload, config: { cancelToken } });
	}

	/* Validates all invalidated req's that have been agreed by all reviewers
	(hence all have made a review) */
	validateAll({ docId }, cancelToken) {
		return client.post(`/smartview/validator/documents/${docId}`, { config: { cancelToken } });
	}

	// Freezes all validated status in a document
	freezeAllValidated({ docId }, cancelToken) {
		return client.put(`/smartview/validator/documents/${docId}/freeze`, { config: { cancelToken } });
	}

	// Unfreezes all validated status in a document
	unfreezeAllValidated({ docId }, cancelToken) {
		return client.put(`/smartview/validator/documents/${docId}/unfreeze`, { config: { cancelToken } });
	}

	// Freezes/Unfreezes validated status of a req
	updateFreezeOnRequirement({ informationId }, cancelToken) {
		return client.put(`/smartview/validator/information/${informationId}`, { config: { cancelToken } });
	}

	// Validates information validates type
	validate({ infoId }, payload, cancelToken) {
		return client.patch(`/smartview/validator/information/${infoId}/status`, {
			payload: JSON.stringify(payload),
			config: { cancelToken },
		});
	}

	// Removes validation done by validator on an information
	remove({ infoId }, cancelToken) {
		return client.delete(`/smartview/validator/information/${infoId}`, { config: { cancelToken } });
	}

	searchByStatus({ docId }, { version, target, otherstatus, category }, cancelToken) {
		return client.get(`/quickaccess/val/documents/${docId}/filter`, {
			params: { version, target, otherstatus, category },
			config: { cancelToken },
		});
	}

	transferReqToVallFromCompany({ docId }, payload, cancelToken) {
		return client.put(`/smartview/validator/documents/${docId}/transfer-company`, {
			payload,
			config: { cancelToken },
		});
	}

	forceSwitchRequirementType({ infoId }, payload, cancelToken) {
		return client.patch(`/smartview/validator/information/${infoId}/type`, { payload, config: { cancelToken } });
	}

	transferReqToVallFromAllPartners({ docId }, payload, cancelToken) {
		return client.put(`/smartview/validator/documents/${docId}/transfer-partners`, {
			payload,
			config: { cancelToken },
		});
	}
}
export default new ValidatorService();
