import React, { useState, useEffect, useCallback } from "react";
import { Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToggleButtons, SearchInput, icon, ViewTab } from "../../../../../../common/components";
import styles from "./Header.module.css";
import { translate } from "../../../../../../common/providers";
import { setSelectedMode, setSearch, setSortingAlgorithms, setSelectedSortAlgo } from "../../../../slice/gonogo-slice";
import { SearchCardTemplateService } from "../../../../../../api";
import { NavUtils } from "../../../../../../navigation";
import Sort from "../../../../../../common/components/drop-down/sort-drop-down/Sort";
import { isNonEmptyArray } from "../../../../../../common/utils";
import { useApi } from "../../../../../../common/hooks";
import { SCORING_SORT_AVAILABLE } from "../../../../../../common/constants";

const displayTabs = false;

export default function Header() {
	const [selectedTab, setSelectedTab] = useState(0);
	const dispatch = useDispatch();
	const projectId = useSelector(({ context }) => context.project.id);
	const search = useSelector(({ gonogo }) => gonogo.search);
	const currentCategory = useSelector(({ gonogo }) => gonogo.currentCategory);
	const totalElements = useSelector(({ gonogo }) => gonogo.results?.totalElements);
	const sortingAlgorithms = useSelector(({ gonogo }) => gonogo.sortingAlgorithms);
	const selectedSortAlgo = useSelector(({ gonogo }) => gonogo.selectedSortAlgo);
	const { call: getSortingAlgorithms } = useApi(SearchCardTemplateService.getSortingAlgorithms);
	const navigate = useNavigate();
	useEffect(() => {
		if (SCORING_SORT_AVAILABLE && !isNonEmptyArray(sortingAlgorithms)) {
			getSortingAlgorithms().then((data) => {
				dispatch(setSortingAlgorithms(data));
				if (isNonEmptyArray(data)) {
					dispatch(setSelectedSortAlgo(data[0]));
				}
			});
		}
	}, [dispatch, getSortingAlgorithms, sortingAlgorithms]);
	const selectedMode = useSelector(({ gonogo }) => gonogo.selectedMode);
	const handleChangeMode = (_, newMode) => {
		if (newMode !== selectedMode && newMode !== null) {
			dispatch(setSelectedMode(newMode));
			navigate(NavUtils.goToReviewByCategory({ projectId, categoryId: currentCategory.id, attached: newMode }));
		}
	};
	const handleChangeSearch = (e) => {
		dispatch(setSearch(e.target.value));
	};
	const handleClearSearch = () => {
		dispatch(setSearch(""));
	};
	const handleChangeSelectedTab = (_, tab) => {
		if (tab === selectedTab) {
			return;
		}
		setSelectedTab(tab);
	};
	const handleChangeSort = useCallback(
		(algo) => {
			dispatch(setSelectedSortAlgo(algo));
		},
		[dispatch]
	);
	return (
		<div className={styles.header}>
			<div className={styles.header__filters}>
				<ToggleButtons
					exclusive
					labelized
					btnClassName={styles.toggleBtns__btn}
					content={[
						{ value: 0, icon: "faAlignLeft", label: translate("gonogo.content.header.toggle.all") },
						{ value: 1, icon: "faFlag", label: translate("gonogo.content.header.toggle.important") },
						{ value: 2, icon: "faTrash", label: translate("gonogo.content.header.toggle.deleted") },
					]}
					data-testid="gonogo.header.toggle"
					disabled={!currentCategory}
					value={selectedMode}
					onChange={handleChangeMode}
				/>
				<SearchInput
					disabled={!currentCategory}
					label={translate("gonogo.content.header.toggle.search-placeholder")}
					value={search}
					onChange={handleChangeSearch}
					onClearSearch={handleClearSearch}
				/>
				{SCORING_SORT_AVAILABLE && (
					<Sort
						selectedSortAlgo={selectedSortAlgo}
						sortingAlgorithms={sortingAlgorithms}
						onChangeSort={handleChangeSort}
					/>
				)}
			</div>
			<div />
			{displayTabs && (
				<div>
					<Tabs
						indicatorColor="primary"
						textColor="primary"
						value={selectedTab}
						onChange={handleChangeSelectedTab}
					>
						<ViewTab iconName={icon.faList} label="Contents" value={0} />
						<ViewTab iconName={icon.faFile} label="Documents" value={1} />
					</Tabs>
				</div>
			)}
			<span>
				{search && totalElements && translate("gonogo.content.search-results.counter", { totalElements })}
			</span>
		</div>
	);
}
