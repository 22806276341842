import React, { useRef, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import { CLICK_TYPES, ROLES } from "../../../../../constants/constants";
import { checkReq, checkReqValMode } from "../../../../../utils/utils";
import {
	setHoveredBlock,
	setSelectedBlock,
	setSelectedRectangles,
	setSelectedSearch,
	setSelectionBarCoordinates,
	setShiftSections,
} from "../../../../../slice/document/document-slice";
import styles from "./RenderRectangle.module.css";
import { createSegments, renderPositionStyle } from "../../../utils/utils";
import { RequirementLayer } from "..";
import { isNonEmptyArray } from "../../../../../../../common/utils";
import SmartReviewContext from "../../../../../context/smart-review-context";

export default function RenderRectangle({
	height,
	isInSelection = false,
	onChangeVisibility,
	openedQuickAccess,
	page,
	rectangle,
	tableHeight,
	width,
	hoveredBlock,
	onEnterArea,
}) {
	const dispatch = useDispatch();
	const areaRef = useRef(null);
	const pdfContainerId = document.getElementById("pdfContainer_id");
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const sidePanelOpen = useSelector(({ srDocument }) => srDocument.sidePanelOpen);
	const redressingInformation = useSelector(({ srDocument }) => srDocument.redressingInformation);
	const selectedSearch = useSelector(({ srDocument }) => srDocument.selectedSearch);
	const currentFocus = useSelector(({ srDocument }) => srDocument.currentFocus);
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const shiftSections = useSelector(({ srDocument }) => srDocument.shiftSections);
	const selectionBarCoordinates = useSelector(({ srDocument }) => srDocument.selectionBarCoordinates);
	const selectionType = useSelector(({ srDocument }) => srDocument.selectionType);
	const isSelecting = useSelector(({ srDocument }) => srDocument.isSelecting);
	const tabToOpen = useSelector(({ srDocument }) => srDocument.tabToOpen);
	const isSidePanelOpenByFilter = useSelector(({ srDocument }) => srDocument.isSidePanelOpenByFilter);
	const isReqContent = useMemo(() => checkReq(rectangle) && checkReqValMode(mode), [mode, rectangle]);
	const isRedrContent = useMemo(() => mode === ROLES.EDITOR.mode, [mode]);
	const isSearchResult = useMemo(
		() =>
			(selectedSearch &&
				rectangle?.informationId === selectedSearch?.infoId &&
				(sidePanelOpen || isSidePanelOpenByFilter)) ||
			rectangle?.informationId === selectedSearch?.infoId ||
			(rectangle.info_id &&
				rectangle.info_id === selectedSearch?.infoId &&
				!sidePanelOpen &&
				!isSidePanelOpenByFilter),
		[isSidePanelOpenByFilter, selectedSearch, sidePanelOpen, rectangle]
	);

	/* CLICK AND SELECT */
	useEffect(() => {
		if (selectionType === CLICK_TYPES.SHIFT && shiftSections.length > 1) {
			const sections = createSegments(shiftSections);
			sections.forEach((section) => {
				if (!section.some((x) => x === null)) {
					const min = Math.min(...section.map((sr) => sr.rectangles[0].order));
					const max = Math.max(...section.map((sr) => sr.rectangles[0].order));
					const { rectangles } = rectangle;
					if (rectangles[0].order < max && rectangles[0].order > min) {
						dispatch(setSelectedRectangles({ rectangle, inside: true }));
					}
				}
			});
		}
	}, [selectionType, rectangle, dispatch, shiftSections]);
	const handleControlSelection = (e) => {
		const selected = selectedRectangles.some((sr) => sr.informationId === rectangle.informationId);
		dispatch(setSelectedRectangles({ rectangle, inside: !selected }));
		const parentRect = document.getElementById("pdfViewerList_id").getBoundingClientRect();
		const x = e.clientX - parentRect.left;
		const y = e.clientY - parentRect.top;
		dispatch(setSelectionBarCoordinates({ x, y }));
	};
	const handleShiftSelection = () => {
		dispatch(setSelectedRectangles({ rectangle, inside: true }));
		dispatch(setShiftSections(rectangle));
	};
	const handleMouseEnter = (e) => {
		if (selectedRectangles.length > 0 && !selectionBarCoordinates) {
			const parentRect = document.getElementById("pdfViewerList_id").getBoundingClientRect();
			const x = e.clientX - parentRect.left;
			const y = e.clientY - parentRect.top;
			if (selectedRectangles.some((sr) => rectangle.informationId === sr.informationId)) {
				dispatch(setSelectionBarCoordinates({ x, y }));
			}
			dispatch(setHoveredBlock(null));
		}
		if (!isSelecting && selectedRectangles.length < 1) {
			onEnterArea(rectangle);
		}
	};
	const clickArea = (md) => {
		if (checkReqValMode(mode)) {
			const { informationId, pageStart, x1, x2, y1, y2 } = md;
			const isRequirement = checkReq(md);
			const additional =
				(!sidePanelOpen && {
					openSidePanel: true,
				}) ||
				{};
			SmartReviewContext.setSidePanelInfo({
				infoId: informationId,
				pageStart,
				x1,
				x2,
				y1,
				y2,
				isRequirement,
				openSidePanel: true,
				tabToOpen,
			});
			dispatch(
				setSelectedSearch({
					infoId: informationId,
					pageStart,
					x1,
					x2,
					y1,
					y2,
					isRequirement,
					...additional,
				})
			);
		} else {
			dispatch(setSelectedBlock({ ...md, page }));
		}
	};
	const handleClickRectangle = (e) => {
		e.stopPropagation();
		if ((e.ctrlKey || e.metaKey) && checkReqValMode(mode)) {
			handleControlSelection(e);
		} else if (e.shiftKey && checkReqValMode(mode)) {
			handleShiftSelection();
		} else {
			clickArea(rectangle);
		}
	};
	/* METADATA */
	const handleVisibility = (isVisible, content) => {
		if (isVisible && openedQuickAccess && onChangeVisibility) {
			onChangeVisibility({ id: content.info_id, position: content.coords.y1 });
		}
	};
	return (
		<React.Fragment key={rectangle.contentId}>
			{rectangle?.informationId === hoveredBlock?.informationId &&
				rectangle?.pageStart === hoveredBlock?.pageStart && (
					<RequirementLayer
						hoveredBlock={hoveredBlock}
						left={width * rectangle.x1 - 5 || 0}
						page={page}
						top={height * rectangle.y1 - 5 || 0}
						onUpdateHoveredArea={onEnterArea}
					/>
				)}
			<VisibilitySensor
				partialVisibility
				containment={pdfContainerId}
				offset={{ bottom: tableHeight * 0.8, top: tableHeight * 0.2 }}
				onChange={(isVisible) => {
					if (handleVisibility) {
						handleVisibility(isVisible, rectangle);
					}
				}}
			>
				<area
					ref={areaRef}
					alt={rectangle.contentId}
					className={`${styles.area}`}
					data-clickable={isReqContent || isRedrContent}
					data-current-focus={currentFocus}
					data-hovered={
						((rectangle.info_id && rectangle.info_id === hoveredBlock?.informationId) ||
							(rectangle.informationId && rectangle.informationId === hoveredBlock?.informationId)) &&
						!redressingInformation
					}
					data-info-content={!checkReqValMode(mode) && !checkReq(rectangle) && !redressingInformation}
					data-information-id={rectangle?.info_id || rectangle?.informationId}
					data-is-in-selection={isInSelection}
					data-is-search-result={isSearchResult}
					data-last-status={rectangle?.lastStatus}
					data-last-validated-status={rectangle?.validatedStatus}
					data-page={rectangle?.pageStart}
					data-rectangles={JSON.stringify(
						(isNonEmptyArray(rectangle.rectangles) &&
							rectangle.rectangles.map((r) => `${r.order}:${r.contentId}`)) ||
							[]
					)}
					data-redr-content={isRedrContent}
					data-redressing={!!redressingInformation}
					data-req-content={isReqContent && !redressingInformation}
					data-selection-on={isSelecting}
					data-testid="content.layer.area"
					role="presentation"
					style={{
						...renderPositionStyle(rectangle, width, height),
						zIndex: (rectangle?.informationId === hoveredBlock?.informationId && 10) || 1,
					}}
					onClick={handleClickRectangle}
					onMouseEnter={handleMouseEnter}
				/>
			</VisibilitySensor>
		</React.Fragment>
	);
}
