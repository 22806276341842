import client from "../client/client";

class ControlService {
	getErrorCountersByProject({ projectId }, cancelToken) {
		return client.get(`/control/projects/${projectId}/counters`, { config: { cancelToken } });
	}

	getErrorCountersByDocument({ documentId }, cancelToken) {
		return client.get(`/control/documents/${documentId}/counters`, { config: { cancelToken } });
	}

	getContentErrorsByDocument({ documentId }, { page, limit }, cancelToken) {
		return client.get(`/control/documents/${documentId}/contents`, {
			params: { page, limit },
			config: { cancelToken },
		});
	}

	getTocErrorsByDocument({ documentId }, { page, limit }, cancelToken) {
		return client.get(`/control/documents/${documentId}/tocs`, {
			params: { page, limit },
			config: { cancelToken },
		});
	}

	updateStatus({ errorId }, payload, cancelToken) {
		return client.patch(`/control/integrity-errors/${errorId}/status`, { payload, config: { cancelToken } });
	}
}

export default new ControlService();
