import { ButtonGroup } from "@mui/material";
import React from "react";
import { icon } from "../..";
import styles from "./ButtonsTextField.module.css";
import ComponentsTextField from "../components-textfield/ComponentsTextField";
import { translate } from "../../../providers";
import { CustomButton, CustomIconButton } from "../../buttons";

// items is a list of objects of shape
// {
// 	displayField:string,
// 	keyField?:string (""),
// 	color?:string (default),
// 	deletable?:boolean (false)
// }
const ButtonsTextField = React.forwardRef(
	(
		{
			displayField,
			InputProps,
			items,
			value,
			onChange,
			onClickItem,
			onPaste,
			helperText = "",
			keyField = "",
			placeholder = "",
			onClearItems = null,
			onDeleteItem = null,
			onKeyDown = null,
			onSuppr = null,
		},
		ref
	) => {
		const handleKeyDown = (event) => {
			if (onKeyDown !== null) {
				onKeyDown(event);
			}
			if (event.keyCode === 8 && value === "") {
				onSuppr();
			}
		};
		return (
			<>
				{onClearItems !== null && items.length > 0 && (
					<div className={styles["clear-button__container"]}>
						<CustomButton color="primary" size="md" variant="text" onClick={onClearItems}>
							<span>{translate("common:btn.clear-all")}</span>
						</CustomButton>
					</div>
				)}
				<ComponentsTextField
					ref={ref}
					helperText={helperText}
					InputProps={InputProps}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					onKeyDown={handleKeyDown}
					onPaste={onPaste}
				>
					{items.map((item, index) => (
						<ButtonGroup
							key={(keyField !== "" && item[keyField]) || index}
							className={styles["button-group"]}
							color={item.color || "default"}
							variant="contained"
						>
							<CustomButton
								className={styles["button-group__btn"]}
								size="md"
								variant="contained"
								onClick={() => onClickItem(item, index)}
							>
								{item[displayField]}
							</CustomButton>
							{item.deletable && (
								<CustomIconButton
									btnClassName={styles["button-group__delete-button"]}
									color="primary"
									icon={icon.faTimes}
									size="sm"
									variant="contained"
									onClick={() => onDeleteItem(item, index)}
								/>
							)}
						</ButtonGroup>
					))}
				</ComponentsTextField>
			</>
		);
	}
);
export default ButtonsTextField;
