import React, { useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./SearchResult.module.css";
import { cutAndStyleChange, getIndicesOf } from "../../search-space-utils";
import { dangerousSetInnerHTML, searchInString } from "../../../../../../../common/utils";
import { CustomTooltip, SidePanelBox } from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";

export default function SearchResult({ result, search }) {
	const documentName = useSelector(({ srDocument }) => srDocument.documentName);
	const resultRef = useRef(null);

	let indices = null;
	let contents = [];
	if (!searchInString(search, "req_")) {
		indices = getIndicesOf(search?.toLowerCase(), result.content?.toLowerCase());
		contents = cutAndStyleChange(result.content, indices, search.length);
	}
	return (
		<SidePanelBox clickable className={styles.container} hasShadow={false}>
			<div ref={resultRef} className={styles.result}>
				{contents.map((content, index) =>
					content.styled ? (
						// eslint-disable-next-line react/no-array-index-key
						<span key={index} className={styles.highlighted}>
							{content.content}
						</span>
					) : (
						// eslint-disable-next-line react/no-array-index-key
						<span key={index}>{dangerousSetInnerHTML({ text: content.content })}</span>
					)
				)}
			</div>
			<div className={styles.container__line}>
				<CustomTooltip showWhenOverflow title={documentName}>
					<div className={styles.line__leftItems}>{documentName}</div>
				</CustomTooltip>
				<div className={styles.line__rightItems}>
					{translate("common:reading-list.documentList.header.page")}&nbsp;
					{result.pageStart}
				</div>
			</div>
		</SidePanelBox>
	);
}
