import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import styles from "./CurrentConversation.module.css";
import ConversationBox from "./ConversationBox";
import ConversationProposition from "./ConversationProposition";
import SourceSidePanel from "./SourceSidePanel";
import { IconComponent, InfiniteList2 } from "../../../../common/components";
import UserGuide from "./UserQuide";
import aitendersWhiteLogo from "./images/aitendersWhiteLogo.svg";

const CurrentConversation = ({
	onChangeTextFilter,
	onConversationUpdate,
	onGetChat,
	selectedChat,
	textValue,
	toRefreshConversation,
	onCancelAnswer,
	parameters,
	partners,
}) => {
	const projectId = useSelector(({ context }) => context.project?.id);
	const [openSidePanel, setOpenSidePanel] = useState(false);
	const [commentsRequest, setCommentsRequest] = useState(null);
	const [idChatAnswer, setIdChatAnswer] = useState(null);
	useEffect(() => {
		if (projectId && selectedChat && toRefreshConversation) {
			setCommentsRequest(
				() =>
					({ page, limit }) =>
						onGetChat({ projectId, chatId: selectedChat }, { page, limit })
			);
		} else {
			setCommentsRequest(null);
		}
	}, [onGetChat, projectId, selectedChat, toRefreshConversation]);
	const handleCloseSidePanel = () => {
		setOpenSidePanel(false);
	};
	const handleOpenSidePanel = (chatId) => {
		setOpenSidePanel(chatId);
	};
	const rowRenderer = (conversation) => (
		<ConversationBox
			key={conversation.id}
			conversation={conversation}
			parameters={parameters}
			partners={partners}
			setIdChatAnswer={setIdChatAnswer}
			onOpenSidePanel={() => handleOpenSidePanel(selectedChat)}
		/>
	);
	const cautionBannerDisabled = useSelector(({ context }) => context.cautionBannerDisabled);
	return (
		<div className={styles.container}>
			<div className={styles.conversationChat} data-caution-banner-disabled={cautionBannerDisabled}>
				{selectedChat !== 0 && (
					<InfiniteList2
						reversedScroll
						callOnResponse={onConversationUpdate}
						className={styles.infiniteList}
						request={commentsRequest}
						rowRenderer={rowRenderer}
						scrollContainerClassName={styles.infiniteList__scrollContainer}
					/>
				)}
				{textValue === "" && selectedChat === 0 && (
					<div className={styles.userGuide}>
						<UserGuide />
					</div>
				)}
			</div>
			{!onCancelAnswer && textValue !== "" && (
				<>
					<ConversationBox
						conversation={{ id: selectedChat, content: textValue }}
						parameters={parameters}
						partners={partners}
						setIdChatAnswer={setIdChatAnswer}
						onOpenSidePanel={handleOpenSidePanel}
					/>
					<div className={styles.loading_answer}>
						<IconComponent autoTranslate="no" className={styles.conversation__icon}>
							<img alt="aitenders_logo" height="18px" src={aitendersWhiteLogo} width="22px" />
						</IconComponent>
						<Skeleton className={styles.skeleton} sx={{ fontSize: "2rem" }} />
					</div>
				</>
			)}
			<div className={styles.conversation_proposition}>
				{textValue === "" && selectedChat === 0 && (
					<ConversationProposition onChangeTextFilter={onChangeTextFilter} />
				)}
			</div>
			<SourceSidePanel
				idChatAnswer={idChatAnswer}
				open={openSidePanel}
				selectedChat={selectedChat}
				onClose={handleCloseSidePanel}
			/>
		</div>
	);
};

export default CurrentConversation;
