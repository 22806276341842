export const ZOOM_STEP = 10;
export const ZOOM_MIN = 20;
export const ZOOM_MAX = 500;

export const MAX_SM_PAGE_BUFFER = 10;
export const MIN_WIDTH = 1300;
export const DETAIL_BLOCK_WIDTH = 520;
export const PDF_RATIO = 0.6;

export const SMART_REVIEW_MENUS = {
	default: "default",
	comments: "comments",
};
export const SENTENCE_TYPE = {
	INFORMATION: "INFORMATION",
	REQUIREMENT: "REQUIREMENT",
};

export const ROLES = {
	EDITOR: { name: "Editor", modeName: "Editor", mode: "redr", icon: "faPen" },
	VALIDATOR: { name: "Validator", modeName: "Validator", mode: "val", icon: "faCheckDouble" },
	REVIEWER: { name: "Reviewer", modeName: "Reviewer", mode: "req", icon: "faCheck" },
	READ: { name: "Read", modeName: "Reader", mode: "req", icon: "faEye" },
};

export const OPERATORS = {
	AND: "AND",
	OR: "OR",
};

export const UPDATE_RIGHTS = ["VALIDATOR", "REVIEWER"];

export const DOCUMENT_STATUSES = {
	PENDING: "PENDING",
	COMPLETE: "COMPLETE",
	ARCHIVED: "ARCHIVED",
};

export const REQ_STATUSES = {
	UNREVIEWED: "UNREVIEWED",
	NOT_ME: "NOT_ME",
};

export const COMPANY_TYPES = {
	COMPANY: "COMPANY",
	CONSORTIUM: "CONSORTIUM",
	BUSINESS_UNIT: "BUSINESS_UNIT",
};

export const VERSION_STATUSES = {
	IDENTICAL: "IDENTICAL",
	MODIFIED: "MODIFIED",
	MODIFIED_MINOR: "MODIFIED_MINOR",
	NEW: "NEW",
};
export const CONTENT_RANGE = 1;
export const QA_FILTER_LIMIT = 25;
export const DOC_FILTER_LIMIT = 50;
export const CLICK_TYPES = {
	SHIFT: "shiftKey",
	CTRL: "ctrlKey",
	CLICK: "click",
};
export const STATUS_BUTTONS = {
	ACCEPTED: "accepted",
	PENDING: "pending",
	DENIED: "denied",
	NOT_ME: "notme",
};
export const ABSOLUTE_LINKS = {
	SIMILAR: "SIMILAR",
	CONTRADICTS: "CONTRADICTS",
	MODIFIES: "MODIFIES",
	REPLACES: "REPLACES",
	COMPLETES: "COMPLETES",
};
export const COMMENT_FILTER_OPTIONS = {
	AUTHOR: "iamTheWriter",
	REPLIED: "isReplied",
	MENTIONED: "iamMentionedIn",
};
