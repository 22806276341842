import { isNumber, isString } from "./function-utils";

export const isEqual = (arr1, arr2) => {
	if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
		return false;
	}
	if (arr1.length !== arr2.length) {
		return false;
	}
	const sorted1 = [...arr1];
	const sorted2 = [...arr2];
	sorted1.sort();
	sorted2.sort();
	for (let i = 0; i < sorted1.length; i++) {
		if (sorted1[i] !== sorted2[i]) {
			return false;
		}
	}
	return true;
};

export const isNonEmptyArray = (arr1) => Array.isArray(arr1) && arr1.length > 0;

export const areNumbersInArrayConsecutive = (arr) => {
	if (!isNonEmptyArray(arr) || arr.some((n) => Number.isNaN(n))) {
		return false;
	}
	let min = Math.min(...arr);
	let num = 0;
	for (const element of arr) {
		num = num ^ min ^ element; // eslint-disable-line no-bitwise
		min += 1;
	}
	if (num === 0) {
		return true;
	}
	return false;
};

export const multiSort = (arr = [], sortBy = []) =>
	[...arr].sort((a, b) => {
		for (const prop of sortBy) {
			if (isString(a[prop]) && isString(b[prop])) {
				return a[prop].localeCompare(b[prop], undefined, { sensitivity: "base" });
			}
			if (isNumber(a[prop] && isNumber(b[prop]))) {
				if (a[prop] !== b[prop]) {
					return a[prop] < b[prop] ? -1 : 1;
				}
				return 0;
			}
			return 0;
		}
		return 0;
	});
