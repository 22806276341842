import React, { useState } from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { CustomDialog } from "../../../../../common/components";
import styles from "./ConfirmImportDialog.module.css";
import { translate } from "../../../../../common/providers";

const ConfirmImportDialog = ({ open = false, onClose, onConfirm, template = [], eraseOption = false }) => {
	const [shouldErase, setShouldErase] = useState(false);

	const handleOnConfirm = () => {
		onConfirm(shouldErase);
		setShouldErase(false);
	};
	const handleChangeErase = () => setShouldErase((prev) => !prev);
	const handleOnClose = () => {
		onClose();
		setShouldErase(false);
	};

	return (
		<CustomDialog
			disableBackdropClick
			displayCloseCross
			isForm
			maxWidth={false}
			open={open}
			title={translate("qa-journal.qa-import.confirm-import-dialog.confirm")}
			onClose={handleOnClose}
			onSubmit={() => handleOnConfirm()}
		>
			{eraseOption ? (
				<FormControlLabel
					control={
						<Checkbox
							checked={shouldErase}
							color="primary"
							name="shouldErase"
							onChange={handleChangeErase}
						/>
					}
					label={translate("qa-journal.qa-import.confirm-import-dialog.label.erase")}
				/>
			) : (
				<span>{translate("qa-journal.qa-import.confirm-import-dialog.confirm.warning")}</span>
			)}

			<span>
				<u className={styles.dialog__text}>
					<strong>{translate("qa-journal.qa-import.confirm-import-dialog.confirm.headers.required")}</strong>
				</u>
				{translate("qa-journal.qa-import.confirm-import-dialog.confirm.headers.order")}
			</span>
			<table className={styles.template__table}>
				<tbody>
					<tr>
						{template.map((s) => (
							<th key={s} className={styles.template__cell}>
								{s}
							</th>
						))}
					</tr>
				</tbody>
			</table>
		</CustomDialog>
	);
};

export default ConfirmImportDialog;
