import React, { useRef } from "react";
import styles from "./OpportunityImports.module.css";
import { translate } from "../../../../../../../common/providers";
import DocumentRow from "./DocumentRow";
import { IconComponent, icon } from "../../../../../../../common/components";

export default function OpportunityImports({ files, totalFileSize, onAdd, onRemove }) {
	const inputRef = useRef(null);
	const handleBrowserUpload = (event) => {
		const newfiles = Array.from(event.target.files);
		onAdd(newfiles);
		inputRef.current.value = null;
	};
	return (
		<div className={styles.main}>
			<span className={styles.title}>
				{translate("opportunity.creation.side-panel.documents.title")}
				<span className={styles.mandatory}>*</span>
			</span>
			{totalFileSize >= 1.5e9 && (
				<div className={styles.limit__warning}>
					{translate("opportunity.creation.side-panel.documents.total-weight.warning")}
				</div>
			)}
			{files.map((doc) => (
				<DocumentRow key={doc.name} document={doc} onRemove={onRemove} />
			))}
			<br />
			<div className={styles.addDocument__button}>
				<label htmlFor="file-input">
					<span>
						<IconComponent
							className={styles.addDocumentIcon}
							color="var(--color-dark-grey-1)"
							icon={icon.faPlus}
						/>
						{translate("opportunity.creation.side-panel.documents.button.add")}
					</span>
					<input
						ref={inputRef}
						hidden
						multiple
						accept=".zip,.pdf,.docx,.doc,.odt,.xlsx,.xlsm"
						id="file-input"
						type="file"
						onChange={handleBrowserUpload}
					/>
				</label>
			</div>
		</div>
	);
}
