import React from "react";
import { CustomIconButton, IconComponent, icon } from "../../../../..";
import styles from "./HeaderItem.module.css";

export default function HeaderItem({ iconName, label, mode, onResetOption }) {
	return (
		<span className={styles.parent}>
			<CustomIconButton icon={icon.faChevronLeft} size="sm" onClick={onResetOption} />
			&nbsp;
			{(!mode && label) || (
				<>
					<IconComponent icon={icon[iconName]} iconClassName={styles.icon} size="xs" />
					{label}
				</>
			)}
		</span>
	);
}
