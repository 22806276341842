import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffectOnce } from "../../common/hooks";
import { AnalyticsProvider, isSegFeatureEnabled, SegFlags, translate } from "../../common/providers";
import { exportView } from "../../navigation";
import CanvasContainer from "./CanvasContainer";

const Canvas = () => {
	const companyName = useSelector(({ context }) => context?.company?.name);
	const projectId = useSelector(({ context }) => context?.project?.id);
	const location = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		const documentTitle = translate("canvas.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Rights on documents" });
	}, []);
	useEffectOnce(
		() => {
			if (isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS)) {
				if (location.hash) {
					navigate(`${location.hash}?pt5=true`);
				} else {
					navigate("?pt5=true");
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[location],
		() => !location.hash.includes("?pt5=")
	);
	return (
		<CanvasContainer
			companyName={companyName}
			getDocumentMethod="getDocumentsPrecedence"
			getUserMethod="getUsersInProject"
			projectId={projectId}
		/>
	);
};

export default exportView({
	path: "/projects/:projectId/rights-on-documents",
	localesPath: "/canvas/locales",
	component: Canvas,
	segFlag: [SegFlags.RIGHTS_ON_DOCUMENTS, SegFlags.TEAM],
});
