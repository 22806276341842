import React from "react";
import styles from "./LinkButton.module.css";

export default function LinkButton({ onClick = null, className = "", children }) {
	const handleKeyPress = (event) => {
		if (event.key === "Enter" && onClick) {
			onClick();
		}
	};
	return (
		<div
			className={`${styles.LinkButton} ${className}`}
			role="button"
			tabIndex={0}
			onClick={onClick}
			onKeyPress={handleKeyPress}
		>
			{children}
		</div>
	);
}
