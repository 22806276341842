import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService, ProjectCreationService } from "../../../../api";
import {
	AddMembersSidePanel,
	CustomButton,
	CustomButtonLink,
	I18nTranslate,
	KnowledgeBasePopOver,
} from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { loadProjectCreation } from "../../slice/newProjectSlice";
import styles from "./AddMembersStep.module.css";
import { AddNewMembersDialog, MembersList } from "./components";

export default function AddMembersStep() {
	const dispatch = useDispatch();
	const projectCreation = useSelector((state) => state.newProject.projectCreation);
	const hasTeamSegFlag = useSelector((state) => state.newProject.hasTeamSegFlag);
	const [availableUsersLoading, setAvailableUsersLoading] = useState(false);
	const [availableUsers, setAvailableUsers] = useState([]);
	const [totalAvailableUsers, setTotalAvailableUsers] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [openPanel, setOpenPanel] = useState(false);
	const [checkedUsers, setCheckedUsers] = useState([]);
	const [prefilledEmail, setPrefilledEmail] = useState("");
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const handleOpenPanel = () => {
		setOpenPanel(true);
	};
	const handleClosePanel = () => {
		setOpenPanel(false);
		setCheckedUsers([]);
	};
	const handleAddToProject = () => {
		const payload = checkedUsers.map((u) => ({ userId: u.userId }));
		ProjectCreationService.addExistingUser(
			{ projectId: projectCreation.project.id },
			payload,
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				dispatch(loadProjectCreation({ token: cancelTokenSourceRef.current.token }));
				handleClosePanel();
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleSearchUsers = useCallback((searchPage, limit, filter = null) => {
		ProjectCreationService.searchAvailableUsers(
			{ filter, limit, page: searchPage },
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				const { contents } = data;
				setAvailableUsersLoading(false);
				setAvailableUsers(contents || []);
				setTotalAvailableUsers(data.totalElements);
			})
			.catch((err) => {
				setAvailableUsersLoading(false);
				console.error(err);
			});
	}, []);
	const handleCloseDialog = () => {
		setOpenDialog(false);
	};
	const handleSubmitDialog = () => {
		setOpenDialog(false);
		handleClosePanel();
	};
	const handleAddPrefilledEmail = (searchText) => {
		setPrefilledEmail(searchText);
	};
	return (
		<>
			<div className={styles["main-container"]}>
				{(hasTeamSegFlag && (
					<>
						<div className={styles.container__header}>
							<div>
								<div className={styles["step-title"]}>
									{translate("new-project.add-member.title")}
									<KnowledgeBasePopOver
										label={<I18nTranslate translationKey="new-project.add-member.information" />}
										link={translate("new-project.add-member.link")}
										linkLabel={translate("common:information.learn-more")}
									/>
								</div>
								<div className={styles.tooltip} />
							</div>
							<CustomButton color="primary" variant="contained" onClick={handleOpenPanel}>
								{translate("new-project.add-member.button.add-active-users")}
							</CustomButton>
						</div>
						<MembersList />
					</>
				)) || (
					<div className={styles.noAccess__container}>
						{translate("new-project.add-member.no-access")}
						<CustomButtonLink
							openInNewTab
							rel="noreferrer"
							target="_blank"
							to={translate("new-project.add-member.no-access.redirect-url")}
						>
							{translate("common:information.learn-more")}
						</CustomButtonLink>
					</div>
				)}
			</div>
			{hasTeamSegFlag && (
				<>
					<AddMembersSidePanel
						addExistingOnly={false}
						addToProjectLabel={translate("new-project.add-member.side-panel.button.add-to-project")}
						availableUsers={availableUsers}
						availableUsersLoading={availableUsersLoading}
						checkedUsers={checkedUsers}
						newMembersLabel={translate("new-project.add-member.side-panel.button.add-new-members")}
						newMembersOnly={false}
						open={openPanel}
						title={translate("new-project.add-member.side-panel.active-members")}
						totalAvailableUsers={totalAvailableUsers}
						onAddPrefilledEmail={handleAddPrefilledEmail}
						onAddToProject={handleAddToProject}
						onChangeAvailableUsersLoading={setAvailableUsersLoading}
						onChangeCheckedUsers={setCheckedUsers}
						onChangeOpenDialog={setOpenDialog}
						onClose={handleClosePanel}
						onSearchUsers={handleSearchUsers}
					/>
					<AddNewMembersDialog
						open={openDialog}
						prefilledEmail={prefilledEmail}
						onClose={handleCloseDialog}
						onSubmit={handleSubmitDialog}
					/>
				</>
			)}
		</>
	);
}
