import React, { useState, useRef, useEffect } from "react";
import { ButtonGroup, MenuItem, MenuList } from "@mui/material";
import { icon as faIcon } from "../..";
import styles from "./CustomSplitButtonMenu.module.css";
import { CustomTooltip } from "../../tooltip";
import DropDown from "../../drop-down/DropDown";
import CustomButton from "../custom-button/CustomButton";
import CustomIconButton from "../custom-icon-button/CustomIconButton";
import { getColorOnCondition } from "../utils";

// options shape : [{label: "", value: "", tooltip: "optional", disabled: false}] // label to display, value to compare
export default function CustomSplitButtonMenu({
	color = "primary",
	disabled = false,
	icon = null,
	onSelect,
	options,
	size = "md",
	tooltip = "",
	value,
	variant = "outlined",
}) {
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);
	useEffect(() => {
		if (disabled) {
			setOpen(false);
		}
	}, [disabled]);
	useEffect(() => {
		const getSelectedIndex = (selectedValue) => {
			if (selectedValue && Array.isArray(options) && options.some((opt) => opt.value === value)) {
				return options.findIndex((opt) => opt.value === value);
			}
			return 0;
		};
		setSelectedIndex(getSelectedIndex(value));
	}, [value, options]);
	const dropDownIconColor = getColorOnCondition({ variant, color });
	const handleChoose = (index) => onSelect(options[index]);
	const handleMouseOver = () => setHovered(true);
	const handleMouseLeave = () => setHovered(false);
	const handleMenuItemClick = (ev) => {
		const index = ev.currentTarget.value;
		setSelectedIndex(index);
		setOpen(false);
		handleChoose(index);
	};

	const handleToggle = () => setOpen((prevOpen) => !prevOpen);

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	return (
		<>
			<CustomTooltip arrow placement="right" title={tooltip}>
				<div>
					<ButtonGroup
						ref={anchorRef}
						disableElevation
						className={styles.splitBtn__btnGroup}
						disabled={disabled}
					>
						<CustomButton
							className={styles.splitBtn__btn}
							color={color}
							size={size}
							startIcon={icon}
							variant={variant}
							onClick={handleToggle}
							onMouseLeave={handleMouseLeave}
							onMouseOver={handleMouseOver}
						>
							{options[selectedIndex].label}
						</CustomButton>
						<CustomIconButton
							btnClassName={`${styles.splitBtn__iconBtn} ${
								hovered || selectedIndex >= -1 ? styles["splitBtn__iconBtn--active"] : ""
							}`}
							color={color}
							dropDownIconColor={dropDownIconColor}
							icon={open ? faIcon.faCaretUp : faIcon.faCaretDown}
							size={size}
							variant={variant}
							onClick={handleToggle}
							onMouseLeave={handleMouseLeave}
							onMouseOver={handleMouseOver}
						/>
					</ButtonGroup>
				</div>
			</CustomTooltip>
			<DropDown ref={anchorRef} open={open} position="bottom" onClose={handleClose}>
				<MenuList id="split-button-menu">
					{Array.isArray(options) &&
						options.map((option, index) => (
							<CustomTooltip key={option.value} arrow placement="right" title={option.tooltip || ""}>
								<div>
									<MenuItem
										className={styles.splitBtn__option}
										disabled={index === selectedIndex || option.disabled}
										selected={index === selectedIndex}
										tooltip={option.tooltip || ""}
										value={index}
										onClick={handleMenuItemClick}
									>
										{option.label}
										<div className={styles["splitBtn__option--subLabel"]}>{option.subLabel}</div>
									</MenuItem>
								</div>
							</CustomTooltip>
						))}
				</MenuList>
			</DropDown>
		</>
	);
}
