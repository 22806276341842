import React, { useState } from "react";
import Details from "./details/Details";
import styles from "./DocumentDetails.module.css";
import { translate } from "../../../../common/providers";
import { CustomDialog } from "../../../../common/components";

const DocumentDetails = ({ handleOpenDialog, handleDeleteDocument, document }) => {
	const [openDocumentRemoveDialog, setOpenDocumentRemoveDialog] = useState(false);

	const handleOpenDocumentRemoveDialog = () => {
		setOpenDocumentRemoveDialog(true);
	};

	const handleCloseDocumentRemoveDialog = () => {
		setOpenDocumentRemoveDialog(false);
	};

	const handleCloseDocumentRemoveDialogAndConfirm = () => {
		handleDeleteDocument();
		setOpenDocumentRemoveDialog(false);
	};

	return (
		document && (
			<div className={styles.container}>
				<div className={styles.title}>
					<span className={styles.title__text} translate="no">
						{document.name}
					</span>
				</div>
				<div className={styles["container--margin"]}>
					<Details document={document} onEdit={handleOpenDialog} onRemove={handleOpenDocumentRemoveDialog} />
				</div>
				<CustomDialog
					maxWidth="xs"
					open={openDocumentRemoveDialog}
					subTitle={translate("deliverable.document-details.delete-confirmation-doc")}
					title={translate("deliverable.document-details.delete-confirmation.title")}
					onClose={handleCloseDocumentRemoveDialog}
					onSubmit={handleCloseDocumentRemoveDialogAndConfirm}
				/>
			</div>
		)
	);
};

export default DocumentDetails;
