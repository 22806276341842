import React, { useMemo } from "react";
import styles from "./ClientSublayer.module.css";
import { checkReq } from "../../../../../../utils/utils";
import { translate } from "../../../../../../../../common/providers";

export default function ClientSublayer({ hoveredBlock }) {
	const isClient = useMemo(() => hoveredBlock?.sentenceClassifySource === "CLIENT", [hoveredBlock]);
	return (
		<>
			{(isClient || (!isClient && checkReq(hoveredBlock))) && (
				<div className={styles["client-sublayer"]}>
					{isClient &&
						`${
							hoveredBlock?.clientRequirementId ||
							(hoveredBlock.parameters &&
								(hoveredBlock.parameters.clientId || hoveredBlock.parameters.ID)) ||
							translate("smart-review.cmp-display-overlay.client-req")
						}`}
					{!isClient && checkReq(hoveredBlock) && `REQ_${hoveredBlock.informationId}`}
				</div>
			)}
		</>
	);
}
