import React from "react";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../common/providers";
import {
	CustomBadge,
	CustomButton,
	CustomTooltip,
	icon,
	IconComponent,
	ProgressBar,
	Tile,
	TileBody,
	TileHeader,
} from "../../../../common/components";
import styles from "./ProjectReqIssues.module.css";
import { NavUtils } from "../../../../navigation";

export default function ProjectReqIssues({
	accepted,
	className = "",
	denied,
	notme,
	onChangeUrl,
	pending,
	projectId,
	unvalidated,
}) {
	let coverage = 0;
	if (denied + unvalidated + notme + accepted + pending !== 0) {
		coverage = (denied + notme + accepted + pending) / (denied + unvalidated + notme + accepted + pending);
	}

	return (
		<Tile className={className}>
			<TileHeader
				className={styles.header}
				iconName={icon.faCheckDouble}
				title={translate("dashboards.project-req-issues.title")}
			/>
			<TileBody>
				<div className={styles.projectReqIssues}>
					<ProgressBar label={`${Math.trunc(coverage * 100)}%`} value={coverage} />
					<div className={styles.projectReqIssues__badges}>
						<CustomTooltip
							className={styles.tooltip}
							placement="top"
							title={`${translate("dashboards.project-req-issues.title.accepted-project")} (${accepted})`}
						>
							<CustomBadge showZero className={styles.accepted} content={accepted} max={999}>
								<IconComponent className={styles.accepted} icon={icon.faCheckCircle} size="4xl" />
							</CustomBadge>
						</CustomTooltip>
						<CustomTooltip
							className={styles.tooltip}
							placement="top"
							title={`${translate("dashboards.project-req-issues.title.pending-project")} (${pending})`}
						>
							<CustomBadge showZero className={styles.pending} content={pending} max={999}>
								<IconComponent className={styles.pending} icon={icon.faClock} size="4xl" />
							</CustomBadge>
						</CustomTooltip>
						<CustomTooltip
							className={styles.tooltip}
							placement="top"
							title={`${translate("dashboards.project-req-issues.title.denied-project")} (${denied})`}
						>
							<CustomBadge
								showZero
								className={styles.denied}
								content={denied}
								max={999}
								style={{ color: "var(--color-dark-blue)" }}
							>
								<IconComponent className={styles.denied} icon={icon.faTimesCircle} size="4xl" />
							</CustomBadge>
						</CustomTooltip>
						<CustomTooltip
							className={styles.tooltip}
							placement="top"
							title={`${translate("dashboards.project-req-issues.title.notme-project")} (${notme})`}
						>
							<CustomBadge showZero className={styles.notme} content={notme} max={999} name="help">
								<IconComponent className={styles.notme} icon={icon.faNotEqual} size="4xl" />
							</CustomBadge>
						</CustomTooltip>
						<CustomTooltip
							className={styles.tooltip}
							placement="top"
							title={`${translate(
								"dashboards.project-req-issues.title.unreviwed-project"
							)} (${unvalidated})`}
						>
							<CustomBadge showZero className={styles.unvalidated} content={unvalidated} max={150}>
								<IconComponent className={styles.unvalidated} icon={icon.faHourglassHalf} size="4xl" />
							</CustomBadge>
						</CustomTooltip>
					</div>
					{isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS) && isSegFeatureEnabled(SegFlags.TEAM) && (
						<CustomButton
							className={styles.manageRightsBtn}
							data-testid={`manageRightsOfValidationProject_${projectId}`}
							variant="outlined"
							onClick={() => onChangeUrl(NavUtils.goToRightsOnDocuments(projectId))}
						>
							{translate("dashboards.project-req-review.content.manage-rights")}
						</CustomButton>
					)}
				</div>
			</TileBody>
		</Tile>
	);
}
