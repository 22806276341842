import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { NavRoute, NavRouteSwitch } from "./components";
import KeycloakService from "../../services/KeycloakService";
import Login from "../../views/login/Login";
import OnboardingProject from "../../views/onboarding-project/OnboardingProject";
import Register from "../../views/register/Register";
import HandleBackButton from "../event-listeners/handle-back-button/HandleBackButton";

export default function AppRoutes() {
	const isLogged = KeycloakService.isLoggedIn();
	const hasEntered = useSelector(({ context }) => context.hasEntered);
	return isLogged && hasEntered ? (
		<>
			<HandleBackButton />
			<NavRouteSwitch />
		</>
	) : (
		<Routes>
			<Route exact element={<NavRoute Component={OnboardingProject} />} path="/projects/join" />
			<Route exact element={<NavRoute Component={Register} />} path="/register" />
			<Route element={<Login />} path="*" />
		</Routes>
	);
}
