import React from "react";
import { SidePanel, SidePanelContent } from "../../../../../../common/components";
import PANELS from "./panels";
import { translate } from "../../../../../../common/providers";

export default function TemplatePanels({
	companyId,
	onActive: onActiveFromParent = null,
	onClose,
	onUpdateRow,
	open,
	panel,
	template,
}) {
	return (
		<SidePanel open={open} size={65} onActive={onActiveFromParent} onClose={onClose}>
			{({ onActive }) => (
				<SidePanelContent
					title={panel && translate(PANELS[panel].title, { name: template?.name })}
					onActive={onActive}
					onClose={onClose}
				>
					{template && panel && PANELS[panel].panel({ companyId, templateId: template?.id, onUpdateRow })}
				</SidePanelContent>
			)}
		</SidePanel>
	);
}
