import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomIconButton, CustomTooltip, icon } from "../../../../../../../../../common/components";
import styles from "./Row.module.css";
import {
	setClauseId,
	setIsHandleScroll,
	setIsPdfScrolling,
} from "../../../../../../../slice/project-document/project-document-slice";
import ReviewRedirectionChapName from "../../../../ChatRedirection/ReviewRedirectionChapName";
import { isSegFeatureEnabled, SegFlags } from "../../../../../../../../../common/providers";

const Row = ({ data, hasChildren, label, level, open, onOpen, onSelect }) => {
	const [hovered, setHovered] = useState(false);
	const clauseId = useSelector(({ srProjectDocument }) => srProjectDocument.clauseId);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const isPdfScrolling = useSelector(({ srProjectDocument }) => srProjectDocument.isPdfScrolling);
	const isThereAnotherFilter = useSelector(({ srProjectDocument }) => srProjectDocument.isThereAnotherFilter);
	const isThereAnotherTransverseFilter = useSelector(
		({ srProjectDocument }) => srProjectDocument.isThereAnotherTransverseFilter
	);
	const dispatch = useDispatch();

	const handleSelect = (e) => {
		dispatch(setIsPdfScrolling(false));
		dispatch(setIsHandleScroll(true));
		e.preventDefault();
		e.stopPropagation();
		onSelect(data);
		dispatch(setClauseId(data.id));
	};
	const handleMouseEnter = () => {
		setHovered(true);
	};
	const handleMouseLeave = () => {
		setHovered(false);
	};
	return (
		<div
			className={styles.container}
			data-is-child={level > 0}
			data-level={level}
			data-selected={data.id === documentId || (!isPdfScrolling && data.id === clauseId && level > 0)}
			data-testid="tocElement.btn"
			style={(level > 0 && { paddingLeft: 24 * level }) || {}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div className={styles.content} data-has-children={hasChildren} role="presentation" onClick={handleSelect}>
				{hasChildren && (
					<CustomIconButton
						btnClassName={styles.button__btn}
						icon={(open && icon.faChevronDown) || icon.faChevronRight}
						iconClassName={styles.button__icon}
						size="sm"
						onClick={onOpen}
					/>
				)}
				<CustomTooltip showWhenOverflow enterDelay={1000} enterNextDelay={1000} title={label}>
					<span className={styles.label}>{label}</span>
				</CustomTooltip>
				<span>
					{hovered && level > 0 && isSegFeatureEnabled(SegFlags.CHAT_PROJECT) && (
						<ReviewRedirectionChapName label={label} />
					)}
				</span>
				{(isThereAnotherFilter || isThereAnotherTransverseFilter) &&
					(data?.matchedContent > 0 || data?.nbInformation > 0) && (
						<span className={styles.count}>{data.matchedContent || data.nbInformation}</span>
					)}
			</div>
		</div>
	);
};
export default Row;
