import { MenuItem, MenuList } from "@mui/material";
import React, { useRef } from "react";
import { translate } from "../../../../../providers";
import { CustomMenuButton } from "../../../../buttons";
import { icon, IconComponent } from "../../../../icon-component";
import styles from "./FiltersSelector.module.css";

export default function FiltersSelector({
	addFilterButtonVariant = "outlined",
	filters,
	onClickKey,
	disabledKeys,
	readOnly,
}) {
	const menuButtonRef = useRef(null);
	const handleChange = (key) => {
		if (menuButtonRef.current) {
			menuButtonRef.current.onClose();
		}
		onClickKey(key);
	};
	const hasIcon = filters.some((filter) => !!filter.icon);
	return (
		!readOnly && (
			<CustomMenuButton
				ref={menuButtonRef}
				color={addFilterButtonVariant === "contained" ? "primary" : "secondary"}
				size="md"
				text={
					<>
						<IconComponent
							color={
								addFilterButtonVariant === "contained"
									? "var(--color-white)"
									: "var(--color-light-grey-1)"
							}
							icon={icon.faPlus}
							size="lg"
						/>
						{translate("common:filters.select-filter.title")}
					</>
				}
				variant={addFilterButtonVariant}
			>
				<MenuList className={styles.menuList}>
					{filters.map((filter) => (
						<MenuItem
							key={filter.key}
							className={styles.menuItem}
							disabled={
								filter.key !== "keywordFilters" &&
								filter.key !== "tocFilters" &&
								disabledKeys.includes(filter.key)
							}
							onClick={() => handleChange(filter.key)}
						>
							<div className={hasIcon ? styles.menuItem__icon : ""}>
								{filter.icon && (
									<IconComponent color="var(--color-dark-grey-1)" icon={filter.icon} size="sm" />
								)}
							</div>
							<div className={styles.menuItem__text}>{translate(filter.label)}</div>
						</MenuItem>
					))}
				</MenuList>
			</CustomMenuButton>
		)
	);
}
