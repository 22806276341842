import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService } from "../../../../api";
import { CircularLoader } from "../../../../common/components";
import styles from "./DocumentCenterMenu.module.css";
import { getTree } from "../../slice/document-center-slice";
import DirectoryTree from "../directory-tree/DirectoryTree";
import { translate } from "../../../../common/providers";

export default function DocumentCenterMenu({ onClickDirectory }) {
	const dispatch = useDispatch();
	const projectId = useSelector(({ context }) => context.project.id);
	const currentDirectoryId = useSelector(({ documentCenter }) => documentCenter.currentDirectoryId);
	const hasReceivedModifications = useSelector(({ documentCenter }) => documentCenter.hasReceivedModifications);
	const isLoadingTree = useSelector(({ documentCenter }) => documentCenter.isLoadingTree);
	const isLoadingCost = useSelector(({ documentCenter }) => documentCenter.isLoadingCost);
	const cost = useSelector(({ documentCenter }) => documentCenter.cost);
	const tree = useSelector(({ documentCenter }) => documentCenter.tree);
	const [openNewFolderDialog, dispatchOpenNewFolderDialog] = useReducer((prev) => !prev, false);

	useEffect(() => {
		const cancelTokenSource = ApiService.getCancelTokenSource();
		dispatch(getTree({ projectId, token: cancelTokenSource.token }));
		return () => {
			ApiService.cancelTokens(cancelTokenSource);
		};
	}, [projectId, dispatch]);

	useEffect(() => {
		if (hasReceivedModifications && openNewFolderDialog) {
			dispatchOpenNewFolderDialog();
		}
	}, [hasReceivedModifications, openNewFolderDialog]);

	return (
		<div className={styles.menu}>
			{isLoadingTree || isLoadingCost ? (
				<div className={styles.menu__loader}>
					<CircularLoader color="primary" size={80} />
				</div>
			) : (
				<>
					<div className={styles.menu__tree}>
						<div className={styles.tree}>
							<DirectoryTree
								currentDirectoryId={currentDirectoryId}
								disabled={hasReceivedModifications}
								tree={tree}
								onClickDirectory={onClickDirectory}
							/>
						</div>
					</div>
					<div className={styles.menu__cost}>
						<span className={styles.cost__title}>{translate("document-center.menu.cost.total")}</span>
						<span className={styles.cost__content}>
							{translate("document-center.menu.cost.value", { cost })}
						</span>
						<span className={styles.cost__subText}>
							{translate("document-center.menu.including-all-versions")}
						</span>
					</div>
				</>
			)}
		</div>
	);
}
