import client from "../../client/client";

class NormsPendingService {
	update(payload, cancelToken) {
		return client.put("/pending-norms", { payload, config: { cancelToken } });
	}

	updateMatchedNormMultiple(payload, cancelToken) {
		return client.patch("/pending-norms", { payload, config: { cancelToken } });
	}

	getSimilars({ matchId }, cancelToken) {
		return client.get(`/pending-norms/${matchId}`, { config: { cancelToken } });
	}

	getAutomaticDetections({ pendingId }, cancelToken) {
		return client.get(`/pending-norms/${pendingId}/auto-detections`, {
			config: { cancelToken },
		});
	}

	getNormsCoordinates({ pendingId }, cancelToken) {
		return client.get(`pending-norms/${pendingId}/coordinates`, {
			config: { cancelToken },
		});
	}

	updateMatchedNorm({ matchId, normId }, cancelToken) {
		return client.put(`/pending-norms/${matchId}/norms/${normId}`, { config: { cancelToken } });
	}

	updateSimilarMatchedNorm({ pendingId, normId }, cancelToken) {
		return client.put(`/pending-norms/${pendingId}/norms/${normId}/similar`, { config: { cancelToken } });
	}

	validateAllSimilar({ pendingId }, cancelToken) {
		return client.put(`/pending-norms/${pendingId}/similar`, { config: { cancelToken } });
	}

	getPendingInProject(
		{ projectId },
		cancelToken,
		{ normId, status, textDetected, documents, trust, page = 0, limit = 25, orderBy = "DOCUMENT", dir = "ASC" } = {}
	) {
		return client.get(`/pending-norms/projects/${projectId}`, {
			params: { normId, status, textDetected, documents, trust, page, limit, orderBy, dir },
			config: { cancelToken },
		});
	}

	updateAllFilteredPending({ projectId }, payload, cancelToken) {
		return client.put(`/pending-norms/projects/${projectId}`, {
			payload,
			config: { cancelToken },
		});
	}

	getPendingNorms({ projectId }, { name, status, page = 0, limit = 25 }, cancelToken) {
		return client.get(`/pending-norms/projects/${projectId}/norms`, {
			params: { name, status, page, limit },
			config: { cancelToken },
		});
	}

	getTotal({ projectId }, cancelToken) {
		return client.get(`/pending-norms/projects/${projectId}/total`, { config: { cancelToken } });
	}

	getSortingKeys(cancelToken) {
		return client.get("/pending-norms/sorting", { config: { cancelToken } });
	}

	getStatus(cancelToken) {
		return client.get("/pending-norms/status", { config: { cancelToken } });
	}

	getTrustLevels(cancelToken) {
		return client.get("/pending-norms/trust", { config: { cancelToken } });
	}

	getInformationFromPendingNorm({ pendingId, docId }, cancelToken) {
		return client.get(`/pending-norms/${pendingId}/documents/${docId}/information`, { config: { cancelToken } });
	}
}

export default new NormsPendingService();
