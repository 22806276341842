import React from "react";
import { Parameters, Redressment } from ".";

export default function Dialogs() {
	return (
		<>
			<Parameters />
			<Redressment />
		</>
	);
}
