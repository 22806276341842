import { InputAdornment, TextField } from "@mui/material";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { CustomIconButton, icon, IconComponent, ScrollContainer, Selector } from "..";
import styles from "./InfiniteList2.module.css";
import { debounce } from "../../utils";
import { translate } from "../../providers";

const debouncedFunction = debounce((func) => func());
export default function InfiniteList2({
	anticipate = false,
	callOnResponse = null,
	children,
	className = "",
	defaultSelectItem,
	hasSearch = false,
	hasSelect = false,
	itemLabelField = "name",
	noRowsText = null,
	onSearchBlur,
	onSearchFocus,
	request,
	rowRenderer,
	scrollContainerClassName = "",
	listContainerClassName = "",
	searchPlaceholder = "",
	selectItems = [],
	style,
	textFieldClassName = "",
	headerRow,
	reversedScroll = false,
}) {
	const [isLoading, setIsLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [searchValueDebounced, setSearchValueDebounced] = useState("");
	const [resultPage, setResultPage] = useState(0);
	const [elements, setElements] = useState([]);
	const [selectedItem, setSelectedItem] = useState(null);
	const hasMore = useRef(null);

	const loadMore = useCallback(
		(search, select, pageNum = 0) => {
			if (typeof request === "function") {
				setIsLoading(true);
				request({
					page: pageNum,
					limit: 25,
					search,
					select,
				})
					.then((data) => {
						if (data) {
							setElements((prev) =>
								pageNum === 0 ? data.contents || [] : [...prev, ...(data.contents || [])]
							);
							setIsLoading(false);
							hasMore.current = !!data.hasMore;
							if (typeof callOnResponse === "function") {
								callOnResponse(data);
							}
						} else {
							hasMore.current = false;
						}
					})
					.catch((err) => {
						hasMore.current = false;
						console.error(err);
					})
					.finally(() => {
						setIsLoading(false);
					});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[request]
	);
	const reloadDeliverables = useCallback(
		(search, item) => {
			setElements([]);
			loadMore(search, item, 0);
			setResultPage(1);
		},
		[loadMore]
	);
	useEffect(() => {
		if (typeof request === "function" && hasMore.current !== null) {
			hasMore.current = null;
			reloadDeliverables(searchValueDebounced, selectedItem);
		}
	}, [searchValueDebounced, selectedItem, reloadDeliverables, request]);
	useEffect(() => {
		if (defaultSelectItem) {
			setSelectedItem(defaultSelectItem);
		} else if (Array.isArray(selectItems) && selectItems.length > 0) {
			setSelectedItem(selectItems[0]);
		}
	}, [defaultSelectItem, selectItems]);
	const handleChangeSearchText = (event) => {
		const { value } = event.target;
		setElements([]);
		setSearchValue(value);
		setIsLoading(true);
		debouncedFunction(() => {
			setSearchValueDebounced(value);
		});
	};
	const handleResetSearchText = () => {
		setSearchValue("");
		setSearchValueDebounced("");
		setElements([]);
	};
	const handleLoadMore = () => {
		if (
			hasMore.current !== false &&
			!isLoading &&
			typeof request === "function" &&
			(!!selectedItem || !hasSelect)
		) {
			loadMore(searchValue, selectedItem, resultPage);
			setResultPage(resultPage + 1);
		}
	};
	const handleChangeSelectedItem = (e) => {
		setSelectedItem(e.target.value);
	};
	return (
		<div className={`${styles.container} ${className}`} style={style}>
			{(hasSearch || hasSelect) && (
				<div className={styles.container__filters}>
					{hasSearch && (
						<TextField
							className={`${styles.textField} ${textFieldClassName}`}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{!searchValue && (
											<IconComponent color="var(--color-light-grey-2)" icon={icon.faSearch} />
										)}
										{searchValue && (
											<CustomIconButton
												className={styles.resetButton}
												icon={icon.faTimes}
												onClick={handleResetSearchText}
											/>
										)}
									</InputAdornment>
								),
							}}
							placeholder={searchPlaceholder || translate("common:btn.search")}
							size="small"
							value={searchValue}
							variant="outlined"
							onBlur={onSearchBlur}
							onChange={handleChangeSearchText}
							onFocus={onSearchFocus}
						/>
					)}
					{hasSelect && !!selectedItem && (
						<Selector
							field={itemLabelField}
							items={selectItems}
							value={(selectItems.includes(selectedItem) && selectedItem) || ""}
							variant="standard"
							onChange={handleChangeSelectedItem}
						/>
					)}
					{children}
				</div>
			)}
			<div className={`${styles.listContainer} ${listContainerClassName}`} data-searchable={hasSearch}>
				<ScrollContainer
					anticipate={anticipate}
					className={`${scrollContainerClassName} ${reversedScroll ? styles.reverseScollList : ""}`}
					isLoading={isLoading}
					reversedScroll={reversedScroll}
					onHitBottom={reversedScroll ? null : handleLoadMore}
					onHitTop={reversedScroll ? handleLoadMore : null}
				>
					{headerRow && <div className={styles.rowContainer}>{rowRenderer(headerRow)}</div>}
					{elements.map((element, index) => (
						<div key={element?.id || index} className={styles.rowContainer}>
							{rowRenderer(element)}
						</div>
					))}
					{elements.length === 0 && !isLoading && (
						<div className={styles.noRowsContainer}>
							{noRowsText || (
								<>
									<IconComponent color="var(--color-blue)" icon={icon.faInfoCircle} size="2xl" />
									{translate("common:empty-state.no-results")}
								</>
							)}
						</div>
					)}
				</ScrollContainer>
			</div>
		</div>
	);
}
