import client from "../../client/client";

class PositionsService {
	getMyTeamPositions({ reqId }, { page = 0, limit = 25 }, cancelToken) {
		return client.get(`/smartview/requirements/${reqId}/positions/my-team/reviews`, {
			params: { page, limit },
			config: { cancelToken },
		});
	}

	getOtherTeamsPositions({ reqId }, { page = 0, limit = 25 }, cancelToken) {
		return client.get(`/smartview/requirements/${reqId}/positions/other-teams`, {
			params: { page, limit },
			config: { cancelToken },
		});
	}

	getMyPosition({ reqId }, cancelToken) {
		return client.get(`/smartview/requirements/${reqId}/positions/me`, {
			config: { cancelToken },
		});
	}

	getMyTeamPosition({ reqId }, cancelToken) {
		return client.get(`/smartview/requirements/${reqId}/positions/my-team`, {
			config: { cancelToken },
		});
	}

	getMyTeamCounters({ reqId }, cancelToken) {
		return client.get(`/smartview/requirements/${reqId}/positions/my-team/counters`, {
			config: { cancelToken },
		});
	}

	getOtherTeamCounters({ reqId }, cancelToken) {
		return client.get(`/smartview/requirements/${reqId}/positions/other-teams/counters`, {
			config: { cancelToken },
		});
	}

	getPositionsCounters({ reqId }, cancelToken) {
		return client.get(`/smartview/requirements/${reqId}/positions/counters`, {
			config: { cancelToken },
		});
	}
}
export default new PositionsService();
