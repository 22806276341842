import { Checkbox, CircularProgress, InputAdornment, TextField } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ApiService, CategoryService } from "../../../../api";
import { translate } from "../../../providers";
import { debounce, isNonEmptyArray, updateTreeBranch } from "../../../utils";
import { countTotalCategories, getParentsIdsList } from "../../../utils/category-utils";
import { CustomIconButton } from "../../buttons";
import { I18nTranslate } from "../../i18n";
import { icon, IconComponent } from "../../icon-component";
import CategoryList from "../category-list/CategoryList";
import styles from "./CategorySelectionList.module.css";
import { setDisableKeyEvents } from "../../../slice";
import { usePrevious } from "../../../hooks";

const debouncedFunction = debounce((func) => func());

const getIdsRecursively = (array, result = []) => {
	if (!Array.isArray(array) || array.length === 0) {
		return null;
	}
	let temp = result;
	array.forEach((a) => {
		temp = [...temp, a.id];
		if (a.subThematic) {
			temp = getIdsRecursively(a.subThematic, temp);
		}
	});
	return temp;
};

export default function CategorySelectionList({
	allowDefaultChanges = false, // When true, component takes in consideration eventual changes of 'defaultCheckedIds'
	allowPreExpand = false,
	availableParents,
	canSelectAll,
	checkChildren = false, // When true, children are automatically checked when user checks its parent
	defaultCheckedIds = [],
	includeNone = false,
	independantSelection = false, // When true, user can check a child whithout automatically check its parent
	isEditable = false,
	projectId,
	onChangeCheckedIds,
	returnCatOnSelection = false,
	singleSelection = false,
}) {
	const [checkedIds, setCheckedIds] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [expandedIds, setExpandedIds] = useState([]);
	const [initialSelectionApplied, setInitialSelectionApplied] = useState(false);
	const [totalCategoriesCount, setTotalCategoriesCount] = useState(0);
	const [categorySearch, setCategorySearch] = useState("");
	const [categorySearchDebounced, setCategorySearchDebounced] = useState("");
	const cancelTokenSourceRef = useRef(null);
	const [categories, setCategories] = useState([]);
	const dispatch = useDispatch();

	const getIdsToExpand = useCallback(() => {
		const categoriesToExpand = [];
		defaultCheckedIds.forEach((checkedId) => {
			const tempParentIds = getParentsIdsList(categories, { id: checkedId });
			tempParentIds.forEach((tempParentId) => {
				if (checkedId !== tempParentId && !categoriesToExpand.includes(tempParentId)) {
					categoriesToExpand.push(tempParentId);
				}
			});
		});
		return categoriesToExpand;
	}, [categories, defaultCheckedIds]);
	const prevProjectId = usePrevious(projectId);
	useEffect(() => {
		if (
			allowDefaultChanges &&
			isNonEmptyArray(categories) &&
			(!initialSelectionApplied || projectId !== prevProjectId)
		) {
			setCheckedIds(JSON.parse(JSON.stringify(defaultCheckedIds)));
			if (allowPreExpand) {
				const categoriesToExpand = getIdsToExpand();
				if (isNonEmptyArray(categoriesToExpand)) {
					setExpandedIds(categoriesToExpand);
				}
			}
			setInitialSelectionApplied(true);
		}
	}, [
		projectId,
		prevProjectId,
		allowDefaultChanges,
		defaultCheckedIds,
		categories,
		allowPreExpand,
		getIdsToExpand,
		initialSelectionApplied,
	]);

	const loadCategories = useCallback(
		(search) => {
			setIsLoading(true);
			if (search) {
				CategoryService.getTreeForProject(
					{ projectId, search, details: false },
					cancelTokenSourceRef.current.token
				)
					.then((data) => {
						setCategories(data || []);
						setTotalCategoriesCount(countTotalCategories(data));
					})
					.catch((err) => console.error(err))
					.finally(() => setIsLoading(false));
			} else if (availableParents) {
				setCategories(availableParents);
				setIsLoading(false);
			} else {
				CategoryService.getTreeParentsForProject(
					{ projectId, details: false },
					cancelTokenSourceRef.current.token
				)
					.then((data) => {
						setCategories(data || []);
						if (!singleSelection) {
							CategoryService.getCountOfAllTreeForProject(
								{ projectId },
								cancelTokenSourceRef.current.token
							).then((count) => setTotalCategoriesCount(count.count));
						}
					})
					.catch((err) => console.error(err))
					.finally(() => setIsLoading(false));
			}
		},
		[projectId, singleSelection, availableParents]
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();

		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		loadCategories(categorySearchDebounced);
	}, [loadCategories, categorySearchDebounced]);
	const handleResetSearchText = () => {
		setCategorySearch("");
		setCategorySearchDebounced("");
	};
	const handleChangeSearchText = (event) => {
		const { value } = event.target;
		setCategorySearch(value);
		setIsLoading(true);
		debouncedFunction(() => {
			setIsLoading(false);
			setCategorySearchDebounced(value);
		});
	};
	const handleCheck = (checked, checkedCategory) => {
		if (singleSelection) {
			setCheckedIds([checkedCategory.id]);
			if (returnCatOnSelection) {
				onChangeCheckedIds(checkedCategory);
			} else {
				onChangeCheckedIds([checkedCategory.id]);
			}
			return;
		}
		CategoryService.getAllChildrenOfParentForProject(
			{ parentId: checkedCategory.id },
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				const childrenIdsList = [checkedCategory.id, ...data.ids];
				let newCheckedIds = [];
				let extraCheckedIds = [];
				if (checked) {
					extraCheckedIds = [checkedCategory.id];
					if (checkChildren) {
						extraCheckedIds = [...extraCheckedIds, ...childrenIdsList];
					}
					const result = checkedIds.concat(extraCheckedIds);
					newCheckedIds = result.filter((cat, index) => result.indexOf(cat) === index);
				} else {
					extraCheckedIds = independantSelection ? [checkedCategory.id] : childrenIdsList;
					newCheckedIds = checkedIds.filter(
						(prevId) => !extraCheckedIds.some((childId) => childId === prevId)
					);
				}
				setCheckedIds(newCheckedIds);
				onChangeCheckedIds(newCheckedIds);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => setIsLoading(false));
	};
	const handleCheckAll = () => {
		if (checkedIds.length > 0) {
			setCheckedIds([]);
			onChangeCheckedIds([]);
		} else if (categorySearch) {
			let tempCheckedIds = [...checkedIds];
			getIdsRecursively(categories).forEach((subCategoryId) => {
				if (!tempCheckedIds.includes(subCategoryId)) {
					tempCheckedIds = [...tempCheckedIds, subCategoryId];
				}
			});
			setCheckedIds(tempCheckedIds);
			onChangeCheckedIds(tempCheckedIds);
		} else {
			CategoryService.getAllIds({ projectId }, cancelTokenSourceRef.current.token)
				.then((data) => {
					setCheckedIds(data.ids);
					onChangeCheckedIds(data.ids);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => setIsLoading(false));
		}
	};
	const countChecked = useCallback(
		(categoriesList, initialCount = 0) => {
			if (!Array.isArray(categoriesList)) {
				return 0;
			}
			let count = initialCount;
			categoriesList.forEach((cat) => {
				if (checkedIds.includes(cat.id)) {
					count++;
				}
				if (cat.subThematic?.length > 0) {
					count = countChecked(cat.subThematic, count);
				}
			});
			return count;
		},
		[checkedIds]
	);
	const handleExpand = (expanded, category) => {
		if (!expanded && (!Array.isArray(category.subThematic) || category.subThematic.length === 0) && category.id) {
			CategoryService.getTreeChildrenForProject(
				{ parentId: category.id, details: false },
				cancelTokenSourceRef.current.token
			).then((data) => {
				setCategories((prev) =>
					updateTreeBranch({
						tree: prev,
						idToReplace: category.id,
						newBranch: {
							...category,
							subThematic: data,
							expanded: true,
						},
						iterativeBranchName: "subThematic",
					})
				);
			});
		} else {
			setCategories((prev) =>
				updateTreeBranch({
					tree: prev,
					idToReplace: category.id,
					newBranch: {
						...category,
						expanded: !expanded,
					},
					iterativeBranchName: "subThematic",
				})
			);
		}
	};
	return (
		<div className={styles.container}>
			{!singleSelection && (
				<div>
					<I18nTranslate
						param={{
							totalCategoriesCount,
							selectedCategoriesCount: checkedIds.length,
						}}
						translationKey={
							checkedIds.length > 1
								? "common:template-selection.counter"
								: "common:template-single-selection.counter"
						}
					/>
				</div>
			)}
			<TextField
				autoFocus
				fullWidth
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							{isLoading && <CircularProgress size={25} thickness={3} />}
							{!isLoading && !categorySearch && <IconComponent icon={icon.faSearch} />}
							{!isLoading && categorySearch && (
								<CustomIconButton icon={icon.faTimesCircleRegular} onClick={handleResetSearchText} />
							)}
						</InputAdornment>
					),
				}}
				placeholder={translate("common:categories.import-panel.search")}
				size="small"
				value={categorySearch}
				variant="outlined"
				onBlur={() => dispatch(setDisableKeyEvents(false))}
				onChange={handleChangeSearchText}
				onFocus={() => dispatch(setDisableKeyEvents(true))}
			/>
			<div className={`${styles.list} ${(isLoading && styles.loadingContent) || ""}`}>
				{(Array.isArray(categories) && categories.length > 0 && (
					<>
						{canSelectAll && (
							<div className={styles.selectAll}>
								<Checkbox
									checked={checkedIds.length === totalCategoriesCount}
									indeterminate={checkedIds.length > 0 && checkedIds.length < totalCategoriesCount}
									onChange={handleCheckAll}
								/>
								{translate("common:btn.select-all")}
							</div>
						)}
						<CategoryList
							categoryList={categories}
							checkedIds={checkedIds}
							editable={isEditable}
							expandedIds={expandedIds}
							includeNone={includeNone}
							singleSelection={singleSelection}
							onCheck={handleCheck}
							onExpand={handleExpand}
						/>
					</>
				)) || (
					<div className={styles.emptyState}>
						<IconComponent
							className={styles.emptyStateIcon}
							color="var(--color-blue)"
							icon={icon.faInfoCircle}
						/>
						{translate("common:categories.import-panel.empty-state")}
					</div>
				)}
			</div>
		</div>
	);
}
