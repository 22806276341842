import { Chip } from "@mui/material";
import React from "react";
import styles from "./DisplayTableRow.module.css";
import { getStatusBgColor, getStatusColor } from "../../../../../../../common/utils";
import { CustomTableCell, CustomTableRow, CustomTooltip } from "../../../../../../../common/components";
import { translateEnumStatus } from "../../../../../../../common/providers";

function setTextOrEmptyState(text) {
	return text || "-";
}

function getRowFromText(row) {
	return (
		<CustomTableRow className={styles["table-row"]}>
			<CustomTableCell
				autoTranslate="no"
				className={styles["table-cell--bold"]}
				component="th"
				scope="row"
				width="30%"
			>
				{row.label}
			</CustomTableCell>
			<CustomTableCell align="right" autoTranslate="no">
				{setTextOrEmptyState(row.value)}
			</CustomTableCell>
		</CustomTableRow>
	);
}

function getRowFromArray(row) {
	return (
		<CustomTableRow className={styles["table-row"]}>
			<CustomTableCell
				autoTranslate="no"
				className={styles["table-cell--bold"]}
				component="th"
				scope="row"
				width="30%"
			>
				{row.label}
			</CustomTableCell>
			<CustomTableCell align="right">
				{row.value.length > 0
					? row.value.map((val, index) => {
							const label = val && typeof val === "object" ? val.element : val;
							const key = `${label}#${index}`;
							const style = val && typeof val === "object" ? val.style : {};
							return (
								<CustomTooltip key={key} enterDelay={500} title={label}>
									<Chip
										className={styles["display-table__chip"]}
										label={label}
										size="small"
										style={style}
									/>
								</CustomTooltip>
							);
					  })
					: "-"}
			</CustomTableCell>
		</CustomTableRow>
	);
}

function getRowFromStatus(row) {
	return (
		<CustomTableRow style={{ backgroundColor: getStatusBgColor(row.value) }}>
			<CustomTableCell
				autoTranslate="no"
				className={styles["table-cell--bold"]}
				component="th"
				scope="row"
				width="30%"
			>
				{row.label}
			</CustomTableCell>
			<CustomTableCell align="right" autoTranslate="no" style={{ color: getStatusColor(row.value) }}>
				{translateEnumStatus(row.value)}
			</CustomTableCell>
		</CustomTableRow>
	);
}

const DisplayTableRow = ({ row }) => {
	if (Array.isArray(row.value)) {
		return getRowFromArray(row);
	}
	if (row.type === "STATUS") {
		return getRowFromStatus(row);
	}
	if (row.type === "INFORMATION") {
		return getRowFromText(row);
	}
	return null;
};

export default DisplayTableRow;
