import React from "react";
import { HorizontalDivider } from "../..";
import styles from "./TileFooter.module.css";

export default function TileFooter({ children, className = "", noDivider = false }) {
	return (
		<footer className={styles.tileFooter}>
			{!noDivider && <HorizontalDivider />}
			<div className={`${styles.tileFooter__content} ${className}`}>{children}</div>
		</footer>
	);
}
