import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { TaskBox, TaskAddSidePanel } from "..";
import { ApiService, ProjectTeamService, TaskService } from "../../../../../../../../../api";
import { updateNode } from "../../../../../../../slice/pdf/pdf-slice";
import styles from "./TasksTabView.module.css";
import {
	icon,
	IconComponent,
	CircularLoader,
	createNotification,
	OptionsPanelContainer,
} from "../../../../../../../../../common/components";
import { translate } from "../../../../../../../../../common/providers";
import { handleTaskHistorySlice } from "../../../../../../../slice/utils";

const handleTaskHistory = (oldTaskHistory, newTasks) => {
	if (oldTaskHistory) {
		const temp = [...oldTaskHistory];
		newTasks.forEach((newTask) => {
			const id = temp.findIndex((oldTask) => oldTask.id === newTask.id);
			if (id > -1) {
				temp[id] = newTask;
			} else {
				temp.push(newTask);
			}
		});
		return temp;
	}
	return newTasks;
};

const TasksTabView = ({ informationId = 0, onActive = null, pageStart = 0, selectedMode }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [priorities, setPriorities] = useState([]);
	const cancelTokenSourceRef = useRef(null);
	const [taskHistory, setTaskHistory] = useState([]);
	const [teammatesRequest, setTeammatesRequest] = useState(null);
	const isRequirement = useSelector(({ srDocument }) => srDocument.sidePanelIsRequirement);
	const dispatch = useDispatch();
	const { userId, projectId, companyId } = useSelector(
		({ context }) => ({
			userId: context.user.id,
			projectId: context.project.id,
			companyId: context.company.id,
		}),
		shallowEqual
	);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		setIsLoading(true);
		if (informationId !== null) {
			TaskService.getAllByInfoId({ infoId: informationId }, cancelTokenSourceRef.current.token)
				.then((data) => {
					setTaskHistory(data);
					dispatch(
						updateNode({
							mode: selectedMode,
							pageConditions: { page: pageStart },
							informationId,
							updates: [
								{ property: "tasksCount", value: data.length },
								{
									property: "taskHistory",
									callback: handleTaskHistorySlice,
									parameters: { tasks: data },
								},
							],
						})
					);
					setIsLoading(false);
				})
				.catch((err) => {
					console.error(err);
					setIsLoading(false);
				});
		}
	}, [informationId, pageStart, dispatch, selectedMode]);
	useEffect(() => {
		TaskService.getPriorities(cancelTokenSourceRef.current.token)
			.then((data) => {
				setPriorities(data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);
	useEffect(() => {
		if (companyId && projectId) {
			setTeammatesRequest(
				() =>
					({ limit, page: teammatesPage }) =>
						ProjectTeamService.getUsersByCompany(
							{
								projectId,
								companyId,
							},
							{ limit, teammatesPage, status: "ACTIVE" },
							cancelTokenSourceRef.current.token
						)
			);
		}
	}, [companyId, projectId]);
	const updateStatusInState = (taskId) => {
		const temp = JSON.parse(JSON.stringify(taskHistory));
		temp.forEach((task) => {
			if (task.id === taskId) {
				switch (task.status) {
					case "NEW":
						task.status = "IN_PROGRESS";
						break;
					case "IN_PROGRESS":
						task.status = "DONE";
						break;
					case "DONE":
						task.status = "NEW";
						break;
					default:
						break;
				}
			}
		});
		const newTaskHistory = handleTaskHistory([...taskHistory], temp);
		setTaskHistory(newTaskHistory);
		dispatch(
			updateNode({
				mode: selectedMode,
				pageConditions: { page: pageStart },
				informationId,
				updates: [
					{ property: "tasksCount", value: newTaskHistory.length },
					{ property: "taskHistory", callback: handleTaskHistory, parameters: { tasks: temp } },
				],
			})
		);
	};
	const handleUpdateStatus = (taskId) => {
		TaskService.nextStep({ taskId }, cancelTokenSourceRef.current.token)
			.then(() => {
				updateStatusInState(taskId);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleAddNewTask = (description, priority, deadline, targetUserEmail) => {
		const payload = {
			projectId,
			informationId,
			description,
			targetUserEmail,
			deadline,
			priority,
		};
		TaskService.create(payload, cancelTokenSourceRef.current.token)
			.then((data) => {
				const newTaskHistory = handleTaskHistory([...taskHistory], [data]);
				setTaskHistory(newTaskHistory);
				dispatch(
					updateNode({
						mode: selectedMode,
						pageConditions: { page: pageStart },
						informationId,
						updates: [
							{ property: "tasksCount", value: newTaskHistory.length },
							{
								property: "taskHistory",
								callback: handleTaskHistorySlice,
								parameters: { tasks: [data] },
							},
						],
					})
				);
				createNotification({
					type: "success",
					message: translate("smart-review.task-form.notification.task-successfully-created", {
						taskDescription: data.description,
					}),
				});
				handleClose();
			})
			.catch((err) => {
				console.error(err);
			});
	};
	return (
		<div className={styles.container}>
			{isRequirement ? (
				<>
					<OptionsPanelContainer
						buttonText={translate("common:btn.add")}
						mainClassName={styles.main}
						title={translate("smart-review.cmp-details.option-panel.tasks")}
						onClick={() => handleOpen()}
					>
						{Array.isArray(taskHistory) &&
							taskHistory.length > 0 &&
							taskHistory.map((task) => (
								<TaskBox
									key={task.id}
									task={task}
									userId={userId}
									onUpdateStatus={handleUpdateStatus}
								/>
							))}
						{(!Array.isArray(taskHistory) || taskHistory.length === 0) && !isLoading && (
							<div className={styles.noContent}>
								<IconComponent
									className={styles.noContent__infoIcon}
									color="var(--color-blue)"
									icon={icon.faInfoCircle}
								/>
								{translate("smart-review.cmp-details.option-panel.task-tab.no-content")}
							</div>
						)}
						{isLoading && (
							<div className={styles.loading}>
								<CircularLoader />
							</div>
						)}
					</OptionsPanelContainer>
					<TaskAddSidePanel
						open={open}
						priorities={priorities}
						reqId={informationId}
						teammatesRequest={teammatesRequest}
						onActive={onActive}
						onAddNewTask={handleAddNewTask}
						onClose={handleClose}
					/>
				</>
			) : (
				<div className={styles.emptyState}>{translate("smart-review.right-panel.empty-state")}</div>
			)}
		</div>
	);
};

export default TasksTabView;
