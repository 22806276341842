import React from "react";
import { AccordionDetails, Divider, OutlinedInput } from "@mui/material";
import {
	CustomButton,
	CustomIconButton,
	CustomTooltip,
	IconComponent,
	Selector,
	icon,
} from "../../../../../../../../../../common/components";
import { dangerousSetInnerHTML } from "../../../../../../../../../../common/utils";
import { translate, translateDate, translateEnumVersionStatus } from "../../../../../../../../../../common/providers";
import { setDisableKeyEvents } from "../../../../../../../../../../common/slice";
import styles from "../VersioningTabView.module.css";

function VersionText({ text }) {
	return dangerousSetInnerHTML({ text });
}
export default function AccordionUploaded({
	content,
	dispatch,
	displayError,
	documentInfo,
	handleChange,
	handleApply,
	handleClickDisplay,
	handleSelect,
	hasLeaderPermissions,
	idValue,
	infoUpdated,
	informationId,
	original,
	previousVersion,
	previousUpload,
	status,
	versionStatuses,
}) {
	return (
		<AccordionDetails>
			<Divider />
			<div className={styles.content}>
				<div className={styles.documentVersion}>
					<div className={styles.documentName}>
						<div
							className={styles.button}
							data-has-button={!!(original.previousInfoId && original.previousPage)}
						>
							{original.previousInfoId && original.previousPage && (
								<CustomIconButton
									data-testid="file.icon.btn"
									icon={icon.faFile}
									size="sm"
									tooltip={translate("common:btn.display")}
									variant="outlined"
									onClick={() => handleClickDisplay(previousVersion, original.previousPage)}
								/>
							)}
							<IconComponent
								className={styles.separator}
								data-has-button={!!(original.previousInfoId && original.previousPage)}
								icon={icon.faArrowRight}
								size="xl"
							/>
						</div>
						<span>{documentInfo.previousVersionName}</span>
						<br />
						<span className={styles.uploaded}>{translateDate(previousUpload)}</span>
					</div>
				</div>
				<div className={styles.documentVersion}>
					<div className={styles.documentName}>
						<span>{documentInfo?.documentName}</span>
						<br />
						<span className={styles.uploaded}>{translateDate(documentInfo?.uploaded)}</span>
					</div>
				</div>
			</div>
			<Divider />
			<div className={styles.content}>
				<div className={styles.documentVersion}>
					<div className={styles.documentName}>
						{hasLeaderPermissions && (
							<OutlinedInput
								classes={{
									root: styles.input,
								}}
								placeholder={translate(
									"smart-review.cmp-details.option-panel.versioning.information-id"
								)}
								type="number"
								value={idValue || 0}
								onBlur={() => dispatch(setDisableKeyEvents(false))}
								onChange={handleChange}
								onFocus={() => dispatch(setDisableKeyEvents(true))}
							/>
						)}
						{!hasLeaderPermissions && (
							<span>
								{idValue &&
									translate(
										documentInfo.sidePanelIsRequirement
											? "smart-review.cmp-details.option-panel.versioning.info-requirement"
											: "smart-review.cmp-details.option-panel.versioning.info-information",
										{
											infoId: idValue,
										}
									)}
							</span>
						)}
					</div>
				</div>
				<div className={styles.informationInput}>
					<div className={styles.documentName}>
						<IconComponent className={styles.separatorInput} icon={icon.faArrowRight} size="xl" />
						<span>
							{informationId &&
								translate(
									documentInfo.sidePanelIsRequirement
										? "smart-review.cmp-details.option-panel.versioning.info-requirement"
										: "smart-review.cmp-details.option-panel.versioning.info-information",
									{
										infoId: informationId,
									}
								)}
						</span>
					</div>
				</div>
			</div>
			<div className={styles.content}>
				<div className={styles.documentName}>
					{hasLeaderPermissions && (
						<div>
							<Selector
								autoTranslate="no"
								itemRenderer={(label) => translateEnumVersionStatus(label)}
								items={versionStatuses || []}
								value={status}
								valueRenderer={(label) => translateEnumVersionStatus(label)}
								onChange={handleSelect}
							/>
							<div className={styles.buttonApply}>
								<CustomTooltip>
									<span>
										<CustomButton disabled={!infoUpdated} variant="contained" onClick={handleApply}>
											{translate("common:btn.apply")}
										</CustomButton>
									</span>
								</CustomTooltip>
							</div>
						</div>
					)}
					{!hasLeaderPermissions && <span>{translateEnumVersionStatus(status)}</span>}
				</div>
			</div>
			<Divider />
			<div className={styles.content}>
				{!displayError && <VersionText text={original?.diff || content} />}
				{displayError && translate("smart-review.cmp-details.option-panel.versioning.information-id.error")}
			</div>
		</AccordionDetails>
	);
}
