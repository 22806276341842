import React, { useCallback, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./GonogoDetails.module.css";
import { translate } from "../../../../common/providers";
import {
	CustomPopOver,
	IconComponent,
	icon,
	CustomButton,
	HorizontalDivider,
	CustomDialog,
} from "../../../../common/components";
import { Comments, RatingHistoryPopOver, CategoryRating, RatingHistoryPanel } from "./components";
import { setSelectedMode, updateTreeNode, setCategoryNote } from "../../slice/gonogo-slice";
import { ApiService, GonogoSynthesisService } from "../../../../api";
import { RISK_LEVEL } from "../../utils/utils";
import { NavUtils } from "../../../../navigation";

function getKeyByValue(object, value) {
	return Object.keys(object).find((key) => object[key] === value);
}

export default function GonogoDetails() {
	const [openHistoryPopUp, setOpenHistoryPopUp] = useState(false);
	const [openPanel, setOpenPanel] = useState(false);
	const [note, setNote] = useState(null);
	const [noteHistory, setNoteHistory] = useState(null);
	const [isOverflowing, setIsOverflowing] = useState(true);
	const [descriptionFull, setDescriptionFull] = useState(false);
	const [openWarningDialog, setOpenWarningDialog] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [riskLevelValue, setRiskLevelValue] = useState(1);
	const projectId = useSelector(({ context }) => context.project.id);
	const currentCategory = useSelector(({ gonogo }) => gonogo.currentCategory);
	const selectedMode = useSelector(({ gonogo }) => gonogo.selectedMode);
	const cancelTokenSourceRef = useRef(null);
	const descriptionRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const fetchNoteHistory = useCallback(() => {
		if (currentCategory?.id) {
			GonogoSynthesisService.getNoteHistory(
				{ categoryId: currentCategory.id },
				{},
				cancelTokenSourceRef.current.token
			)
				.then((data) => {
					setNoteHistory(data);
				})
				.catch((err) => console.error(err));
		}
	}, [currentCategory?.id]);
	useEffect(() => {
		if (!currentCategory?.id) {
			return;
		}
		GonogoSynthesisService.getNote({ categoryId: currentCategory.id }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setNote(data);
			})
			.catch((err) => console.error(err));
		fetchNoteHistory();
	}, [currentCategory?.id, dispatch, fetchNoteHistory]);
	useEffect(() => {
		setIsOverflowing(
			descriptionRef.current?.clientWidth < descriptionRef.current?.scrollWidth ||
				descriptionRef.current?.clientHeight < descriptionRef.current?.scrollHeight
		);
	}, [currentCategory]);
	useEffect(() => {
		const handleIsOverFlowing = () => {
			setIsOverflowing(
				descriptionRef.current?.clientHeight < descriptionRef.current?.scrollHeight ||
					descriptionRef.current?.clientWidth < descriptionRef.current?.scrollWidth
			);
		};
		window.addEventListener("resize", handleIsOverFlowing);
		return () => {
			window.removeEventListener("resize", handleIsOverFlowing);
		};
	}, []);
	const handleChangeRiskValue = (newValue) => {
		setOpenWarningDialog(true);
		setRiskLevelValue(newValue);
	};
	const confirmChangeRiskValue = (newValue) => {
		const updatedRiskValue = newValue && riskLevelValue !== newValue ? newValue : riskLevelValue;
		GonogoSynthesisService.updateNote(
			{ categoryId: currentCategory.id },
			getKeyByValue(RISK_LEVEL, updatedRiskValue),
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				setNote(data);
				dispatch(setCategoryNote(data.riskLevel));
				dispatch(updateTreeNode({ id: currentCategory.id, updates: { note: data.riskLevel } }));
			})
			.catch((err) => console.error(err));
	};
	const handleCloseWarningDialog = () => {
		setOpenWarningDialog(false);
	};
	const handleSetFlagged = () => {
		dispatch(setSelectedMode(1));
		navigate(NavUtils.goToReviewByCategory({ projectId, categoryId: currentCategory.id, attached: true }));
	};
	const handleOpenHistoryPopUp = () => {
		fetchNoteHistory();
		setOpenHistoryPopUp(true);
	};
	const handleCloseHistoryPopUp = () => {
		setOpenHistoryPopUp(false);
	};
	const handleClosePanel = () => {
		setOpenPanel((prev) => !prev);
	};
	const handleShowMore = () => {
		handleClosePanel();
		handleCloseHistoryPopUp();
	};
	const handleShowFullDescription = () => {
		setDescriptionFull((prev) => !prev);
	};
	const handleRemoveRiskLevel = () => {
		setOpenDeleteDialog(true);
	};
	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};
	const handleConfirmDeleteRiskValue = () => {
		GonogoSynthesisService.deleteNote({ categoryId: currentCategory.id }, cancelTokenSourceRef.current.token)
			.then(() => {
				setNote(undefined);
				dispatch(setCategoryNote(undefined));
				dispatch(updateTreeNode({ id: currentCategory.id, updates: { note: undefined } }));
			})
			.catch((err) => console.error(err))
			.finally(() => handleCloseDeleteDialog());
	};
	return (
		<div className={styles.gonogoDetails}>
			{currentCategory && (
				<>
					<div className={styles.header}>{translate("gonogo.details.synthesis-category")}</div>
					<HorizontalDivider />
					<span className={styles.subHeader}>{translate("gonogo.details.synthesis.description")}</span>
					{currentCategory?.description ? (
						<span ref={descriptionRef} className={styles.description} data-visible={descriptionFull}>
							{currentCategory?.description}
						</span>
					) : (
						<span className={styles.noDescription}>
							{translate("gonogo.details.synthesis.no-description")}
						</span>
					)}
					{isOverflowing && (
						<CustomButton className={styles.showMore__btn} onClick={handleShowFullDescription}>
							{descriptionFull ? translate("common:btn.show-less") : translate("common:btn.show-more")}
						</CustomButton>
					)}
					<HorizontalDivider className={styles.divider} />
					<span className={styles.subHeader}>{translate("gonogo.details.risk-level")}</span>
					<div>
						<CategoryRating
							customStyles={styles}
							owner={note?.userName || ""}
							riskValue={RISK_LEVEL[note?.riskLevel] || 0}
							onChange={RISK_LEVEL[note?.riskLevel] ? handleChangeRiskValue : confirmChangeRiskValue}
							onRemove={handleRemoveRiskLevel}
						/>
						{(noteHistory?.contents || []).length > 0 && (
							<div>
								<CustomPopOver
									hovered
									content={
										<RatingHistoryPopOver
											customStyles={styles}
											ratings={noteHistory?.contents}
											onShowMore={handleShowMore}
										/>
									}
									open={openHistoryPopUp}
									placement="bottom"
									onClose={handleCloseHistoryPopUp}
								>
									<span
										className={styles.history}
										role="presentation"
										onClick={handleShowMore}
										onMouseEnter={handleOpenHistoryPopUp}
									>
										<IconComponent className={styles.historyIcon} icon={icon.faClock} />
										{translate("gonogo.details.history")}
									</span>
								</CustomPopOver>
							</div>
						)}
					</div>
					{!selectedMode && currentCategory?.attachedContentsCount > 0 && (
						<div className={styles.flagSection}>
							<span>{translate("gonogo.details.important-content")}</span>
							<div>
								<CustomButton
									fullWidth
									className={styles.flagged}
									color="secondary"
									size="md"
									startIcon={icon.faFlag}
									variant="outlined"
									onClick={handleSetFlagged}
								>
									{translate("gonogo.details.flagged-content.counter", {
										flaggedCounter: currentCategory?.attachedContentsCount,
									})}
									<IconComponent icon={icon.faChevronRight} />
								</CustomButton>
							</div>
						</div>
					)}
					<Comments categoryId={currentCategory?.id} />
					<RatingHistoryPanel
						customStyles={styles}
						open={openPanel}
						ratings={noteHistory}
						onClose={handleClosePanel}
					/>
					<CustomDialog
						closeLabel={translate("gonogo.details.risk-level-change.cancel-button")}
						data-testid="confirm.delete.btn"
						iconColor="var(--color-red)"
						iconModel={icon.faExclamationTriangle}
						open={openWarningDialog}
						submitLabel={translate("gonogo.details.risk-level-change.confirm-button")}
						subTitle={translate("gonogo.details.risk-level-change.text")}
						title={translate("gonogo.details.risk-level-change.title")}
						onClose={handleCloseWarningDialog}
						onSubmit={() => {
							confirmChangeRiskValue();
							handleCloseWarningDialog();
						}}
					/>
					<CustomDialog
						closeLabel={translate("gonogo.details.risk-level-change.cancel-button")}
						data-testid="confirm.delete.btn"
						iconColor="var(--color-red)"
						open={openDeleteDialog}
						submitLabel={translate("common:btn.delete")}
						subTitle={translate("gonogo.details.risk-level-delete.text")}
						title={translate("gonogo.details.risk-level-delete.title")}
						onClose={handleCloseDeleteDialog}
						onSubmit={handleConfirmDeleteRiskValue}
					/>
				</>
			)}
		</div>
	);
}
