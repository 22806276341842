import { createAsyncThunk } from "@reduxjs/toolkit";
import { SearchCardService, CategoryService, RequirementParameterService } from "../../../../api";
import { SEARCH_TYPES } from "../../../../common/constants/search-types";
import { VISIBILITY } from "../../../../common/constants";
import { generateFilters } from "../../../../common/components";

function getProjectId(getState) {
	const {
		context: {
			project: { id: projectId },
		},
	} = getState();
	return projectId;
}
function getFilters(getState) {
	const {
		searchForAutomation: { filters },
	} = getState();
	return { ...filters };
}
export const getTypeParameters = createAsyncThunk(
	"searchForAutomation/getTypeParameters",
	(token) => RequirementParameterService.getReqTypes(token),
	{
		condition: (_, { getState }) => {
			const {
				searchForAutomation: { types },
			} = getState();
			return !Array.isArray(types) || types.length === 0;
		},
	}
);

export const getCriticalityParameters = createAsyncThunk(
	"searchForAutomation/getCriticalityParameters",
	(token) => RequirementParameterService.getCriticality(token),
	{
		condition: (_, { getState }) => {
			const {
				searchForAutomation: { criticalities },
			} = getState();
			return !Array.isArray(criticalities) || criticalities.length === 0;
		},
	}
);

export const getNegotiabilityParameters = createAsyncThunk(
	"searchForAutomation/getNegotiabilityParameters",
	(token) => RequirementParameterService.getNegotiability(token),
	{
		condition: (_, { getState }) => {
			const {
				searchForAutomation: { negotiabilities },
			} = getState();
			return !Array.isArray(negotiabilities) || negotiabilities.length === 0;
		},
	}
);

export const getCategoriesParents = createAsyncThunk(
	"searchForAutomation/getCategoriesParents",
	(token, { getState }) => {
		const projectId = getProjectId(getState);
		const filters = getFilters(getState);
		return CategoryService.getTreeParentsForWithFilters(
			{ projectId },
			{ filters: { ...filters, Type: "InformationSearchFilterDTO" } },
			token
		);
	},
	{
		condition: (_, { getState }) => {
			const {
				searchForAutomation: { categories },
			} = getState();
			return !Array.isArray(categories) || categories.length === 0;
		},
	}
);

export const getCategoriesChildren = createAsyncThunk(
	"searchForAutomation/getCategoriesChildren",
	({ token, parentId }) => CategoryService.getTreeChildrenForProject({ parentId, details: false }, token)
);

export const getAllSearches = createAsyncThunk(
	"searchForAutomation/getAllSearches",
	({ filters = { visibility: VISIBILITY.PRIVATE }, token }, { getState }) => {
		const projectId = getProjectId(getState);
		return SearchCardService.getAll({ projectId }, { filters, page: 0, limit: 20 }, token);
	}
);

export const search = createAsyncThunk(
	"searchForAutomation/search",
	({ filters, page, limit, token }, { getState }) => {
		const projectId = getProjectId(getState);
		const {
			searchForAutomation: { isFilterTocOut },
		} = getState();
		return SearchCardService.search(
			{ projectId },
			{ isFilterTocOut, ...(filters || {}), Type: SEARCH_TYPES.card },
			page,
			limit,
			token
		);
	}
);

export const countResults = createAsyncThunk("searchForAutomation/countResults", ({ filters, token }, { getState }) => {
	const projectId = getProjectId(getState);
	const {
		searchForAutomation: { isFilterTocOut },
	} = getState();
	return SearchCardService.countResults(
		{ projectId },
		{ isFilterTocOut, ...(filters || {}), Type: SEARCH_TYPES.card },
		token
	);
});
export const createSearch = createAsyncThunk(
	"searchForAutomation/createSearch",
	({ title, description, visibility, token }, { getState }) => {
		const projectId = getProjectId(getState);
		const {
			searchForAutomation: { isFilterTocOut, filters, totalMatches },
		} = getState();
		const payload = {
			title,
			description,
			visibility,
			filtersResults: {
				resultCount: totalMatches,
				filters: generateFilters({
					isFilterTocOut,
					...filters,
				}),
			},
		};
		return SearchCardService.create({ projectId }, payload, token);
	}
);

// Must pass creatorId in payload
export const updateSearchFilters = createAsyncThunk(
	"searchForAutomation/updateSearchFilters",
	({ searchCardId, token }, { getState }) => {
		const {
			searchForAutomation: { isFilterTocOut, filters, totalMatches },
		} = getState();
		const payload = {
			resultCount: totalMatches,
			filters: generateFilters({ ...filters, isFilterTocOut }),
		};
		return SearchCardService.updateFilters({ searchCardId }, payload, token);
	}
);

// Must pass creatorId in payload
export const deleteSearch = createAsyncThunk("searchForAutomation/deleteSearch", ({ searchCardId, token }) =>
	SearchCardService.delete({ searchCardId }, token)
);

// Must pass creatorId in payload
export const editSearch = createAsyncThunk("searchForAutomation/editSearch", ({ searchCardId, payload, token }) =>
	SearchCardService.update({ searchCardId }, payload, token)
);
