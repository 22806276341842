import React from "react";
import { checkValidity } from "../..";
import { NotFound } from "../../../common/components";

export default function NavComponent({ Component, role, companyRole, project, ...props }) {
	return checkValidity({ role, companyRole: (project && companyRole) || null }) ? (
		<Component {...props} />
	) : (
		<NotFound {...props} />
	);
}
