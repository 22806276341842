import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox, InputAdornment, MenuItem } from "@mui/material";
import { ApiService, SearchCardTemplateService } from "../../../../../../api";
import {
	CircularLoader,
	CustomButton,
	I18nTranslate,
	icon,
	IconComponent,
	InfiniteList2,
	InfiniteListSelector,
	OptionsPanelContainer,
	SidePanel,
	SidePanelContent,
	ViewTabs,
} from "../../../../../../common/components";
import { hasPermission, Permissions, translate, translateEnumTemplatetype } from "../../../../../../common/providers";
import styles from "./AddTemplateSidePanel.module.css";
import { TemplateBox } from "../../../../components";
import { VISIBILITY_TEMPLATE, VISIBILITY_TEMPLATE_CREATION } from "../../../../../../common/constants";
import { setIsUpdating } from "../../../slice/my-search-slice";

export default function AddTemplateSidePanel({ open, onClose }) {
	const cancelTokenSourceRef = useRef(null);
	const projectId = useSelector(({ context }) => context.project.id);
	const companyId = useSelector(({ context }) => context.company.id);
	const companyName = useSelector(({ context }) => context.company.name);
	const [selectedTab, setSelectedTab] = useState(0);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const [checkedTemplates, setCheckedTemplates] = useState([]);
	const [uncheckedTemplates, setUncheckedTemplates] = useState([]);
	const [addedTemplates, setAddedTemplates] = useState([]);
	const [addedLoadingTemplates, setAddedLoadingTemplates] = useState([]);
	const [authorsRequest, setAuthorsRequest] = useState(null);
	const [authorInput, setAuthorInput] = useState(null);
	const [templatesCount, setTemplatesCount] = useState(0);
	const [allChecked, setAllChecked] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [isAllAdded, setIsAllAdded] = useState(false);
	const [notAddedTemplates, setNotAddedTemplates] = useState([]);
	const [isAllAddedLoading, setIsAllAddedLoading] = useState(false);
	const dispatch = useDispatch();
	const addTemplateTabs = useMemo(
		() =>
			Object.values(
				hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER])
					? VISIBILITY_TEMPLATE
					: VISIBILITY_TEMPLATE_CREATION
			).map((type) => ({
				label: translateEnumTemplatetype(type, { companyName }),
			})),
		[companyName]
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		setInfiniteListRequest(
			() =>
				({ page, limit }) =>
					SearchCardTemplateService.getTemplates(
						{ page, limit },
						{ filters: {} },
						cancelTokenSourceRef.current.token
					)
		);
		setAuthorsRequest(
			() =>
				({ limit, page }) =>
					SearchCardTemplateService.getAuthors(
						{
							companyId,
						},
						{ limit, page },
						cancelTokenSourceRef.current.token
					)
		);
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [projectId, companyId]);
	useEffect(() => {
		const templateOwner = Object.values(VISIBILITY_TEMPLATE)[selectedTab];
		setAddedTemplates([]);
		setCheckedTemplates([]);
		setIsAllAdded(false);
		setAllChecked(false);
		setUncheckedTemplates([]);
		setNotAddedTemplates([]);
		setInfiniteListRequest(() => ({ page, limit, search }) => {
			setSearchValue(search || undefined);
			return SearchCardTemplateService.getTemplates(
				{ page, limit },
				{
					filters: {
						templateOwner,
						name: search,
						users: selectedTab === 1 && authorInput?.id ? [authorInput.id] : undefined,
					},
				},
				cancelTokenSourceRef.current.token
			);
		});
	}, [selectedTab, authorInput]);
	const handleChangeSelectedTab = (_, newTab) => {
		setSelectedTab(newTab);
	};
	const handleCheckTemplate = (template, newChecked) => {
		if (newChecked) {
			setCheckedTemplates((prev) => [...prev, template.id]);
			setUncheckedTemplates((prev) => prev.filter((tmpId) => tmpId !== template.id));
		} else {
			setCheckedTemplates((prev) => prev.filter((tmpId) => tmpId !== template.id));
			if (allChecked) {
				setUncheckedTemplates((prev) => [...prev, template.id]);
			}
		}
	};
	const handleAddTemplates = (templatesIds) => {
		setAddedLoadingTemplates((prev) => [...prev, ...templatesIds]);
		SearchCardTemplateService.addTemplatesToProject(
			{ projectId },
			{ ids: templatesIds, visibility: "PRIVATE" },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				setAddedTemplates((prev) => [...prev, ...templatesIds]);
				setAddedLoadingTemplates((prev) => prev.filter((tmpId) => !templatesIds.includes(tmpId)));
				dispatch(setIsUpdating(true));
			})
			.catch((err) => {
				console.error(err);
				setAddedLoadingTemplates((prev) => prev.filter((tmpId) => !templatesIds.includes(tmpId)));
			});
	};
	const addCheckedTemplates = () => {
		if (!allChecked) {
			handleAddTemplates(checkedTemplates);
		} else {
			let templateOwner = Object.values(VISIBILITY_TEMPLATE)[selectedTab];
			if (templateOwner === VISIBILITY_TEMPLATE.AITENDERS) {
				templateOwner = undefined;
			}
			setIsAllAddedLoading(true);
			SearchCardTemplateService.addTemplatesToProjectByFilter(
				{ projectId },
				{
					templateOwner,
					name: searchValue,
					users: selectedTab === 1 && authorInput?.id ? [authorInput.id] : undefined,
					excludedIds: uncheckedTemplates,
					visibility: "PRIVATE",
				},
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					setIsAllAdded(true);
					setNotAddedTemplates([...uncheckedTemplates]);
					setAddedTemplates([]);
					setAddedLoadingTemplates([]);
					setIsAllAddedLoading(false);
					dispatch(setIsUpdating(true));
				})
				.catch((err) => {
					console.error(err);
					setAddedLoadingTemplates([]);
					setIsAllAddedLoading(false);
				});
		}
	};
	const isRowChecked = (row) =>
		(allChecked && !uncheckedTemplates.includes(row.id)) || checkedTemplates.includes(row.id);
	const rowRenderer = (row) => (
		<TemplateBox
			isMySearch
			checked={isRowChecked(row)}
			isAdded={isAllAdded ? !notAddedTemplates.includes(row.id) : addedTemplates.includes(row.id)}
			isAddedLoading={addedLoadingTemplates.includes(row.id)}
			template={row}
			onAdd={(tmp) => handleAddTemplates([tmp.id])}
			onCheck={(newChecked) => handleCheckTemplate(row, newChecked)}
		/>
	);
	const selectorRowRenderer = ({ loadedRow, onClick }) => (
		<MenuItem key={loadedRow?.id} onClick={() => onClick(loadedRow)}>
			<span>{loadedRow ? loadedRow?.displayName || loadedRow?.email : translate("common:all")}</span>
		</MenuItem>
	);
	const handleChangeAuthorInput = (newAuthor) => {
		setAuthorInput(newAuthor);
	};
	const endAdornmentRenderer = (openTeammates1) => (
		<InputAdornment position="end">
			<IconComponent color="var(--color-dark-grey-1)" icon={openTeammates1 ? icon.faCaretUp : icon.faCaretDown} />
		</InputAdornment>
	);
	const onInfiniteResponse = (data) => {
		setTemplatesCount(data.totalElements || 0);
	};
	const handleCheckAll = (event) => {
		const newChecked = event.target.checked;
		setAllChecked(newChecked);
		if (newChecked) {
			setUncheckedTemplates([]);
		} else {
			setCheckedTemplates([]);
		}
	};
	const handleClose = () => {
		setAddedTemplates([]);
		setCheckedTemplates([]);
		setIsAllAdded(false);
		setAllChecked(false);
		setUncheckedTemplates([]);
		setNotAddedTemplates([]);
		setAuthorInput(null);
		onClose();
	};
	return (
		<SidePanel offset={2} open={open} size={60} onClose={handleClose}>
			<SidePanelContent title={translate("smart-search.add-template-panel.title")} onClose={handleClose}>
				<ViewTabs
					selectedTab={selectedTab}
					tabs={addTemplateTabs}
					variant="scrollable"
					onChange={handleChangeSelectedTab}
				/>
				<OptionsPanelContainer noHeader className={styles.main} onClickSecondary={handleClose}>
					<InfiniteList2
						hasSearch
						callOnResponse={onInfiniteResponse}
						request={infiniteListRequest}
						rowRenderer={rowRenderer}
					>
						<>
							{selectedTab === 1 && (
								<InfiniteListSelector
									nullable
									classes={{ input: styles.infiniteSelector }}
									classNameInfiniteList={styles.infiniteSelector__list}
									endAdornmentRenderer={endAdornmentRenderer}
									infiniteListRequest={authorsRequest}
									rowRenderer={selectorRowRenderer}
									value={
										authorInput?.displayName ||
										translate("smart-search.add-template-panel.author-select.author")
									}
									onClick={handleChangeAuthorInput}
								/>
							)}
							<div className={styles.selectAllContainer}>
								<Checkbox
									checked={
										(allChecked && uncheckedTemplates.length === 0) ||
										(templatesCount !== 0 && templatesCount === checkedTemplates.length)
									}
									color="primary"
									onChange={handleCheckAll}
								/>
								{((allChecked || checkedTemplates.length > 0) && (
									<div className={styles.actionsRow}>
										<I18nTranslate
											param={{
												templatesCount,
												selectionCount: allChecked
													? templatesCount - uncheckedTemplates.length
													: checkedTemplates.length,
											}}
											translationKey="common:add-template-panel.templates-count.selection"
										/>
										<span className={styles.actionsRow__separator}>&nbsp;|&nbsp;</span>
										<span className={styles.actionsRow__actionText}>
											{translate("common:add-template-panel.actions")}
										</span>
										{(isAllAddedLoading && (
											<CustomButton
												className={styles.actionsRow__addButton}
												color="primary"
												variant="contained"
											>
												<CircularLoader color="info" size={25} />
											</CustomButton>
										)) || (
											<CustomButton
												className={styles.actionsRow__addButton}
												color="primary"
												data-testId="addTemplateBtn"
												startIcon={icon.faPlus}
												variant="contained"
												onClick={addCheckedTemplates}
											>
												{translate("common:btn.add")}
											</CustomButton>
										)}
									</div>
								)) || (
									<span className={styles.selectAllContainer__count}>
										{translate("common:add-template-panel.templates-count", {
											templatesCount,
										})}
									</span>
								)}
							</div>
						</>
					</InfiniteList2>
				</OptionsPanelContainer>
			</SidePanelContent>
		</SidePanel>
	);
}
