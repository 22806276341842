import React from "react";
import styles from "./AdditionalSidePanel.module.css";
import { PanelHeader } from "..";
import { SENTENCE_TYPE } from "../../../../../constants/constants";

const AdditionalSidePanel = ({ children, information, onClose }) => (
	<div className={styles.additionalSidePanel__container}>
		<PanelHeader
			confidence={information.confidence}
			informationId={information.informationId}
			isRequirement={information.informationType === SENTENCE_TYPE.REQUIREMENT}
			projectName={information.projectName}
			onClose={onClose}
		/>
		{children}
	</div>
);

export default AdditionalSidePanel;
