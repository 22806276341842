import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RedressLayer, ContentLayer, VersionLayer } from ".";
import DeletedVersionLayer from "./deleted-version-layer/DeletedVersionLayer";

export default function Layers(props) {
	const [priority, setPriority] = useState(false);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const { metadata, ...rest } = props || {};
	const { hoveredContent } = rest;
	const { page, deletedData, ...modeData } = metadata || {};
	const [overlayHeight, setOverlayHeight] = useState(0);
	const [overlayCoordinates, setOverlayCoordinates] = useState({ left: null, top: null });
	const searchData = useSelector(({ srDocument }) => srDocument.searchData);
	const isSelecting = useSelector(({ srDocument }) => srDocument.isSelecting);
	const hasSelectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles?.length > 0);

	const handlePriority = (prio) => setPriority(prio);
	useEffect(
		() => {
			if (hoveredContent?.informationId) {
				setOverlayCoordinates({
					top: rest.height * hoveredContent.y1 - 5,
					left: rest.width * hoveredContent.x1 - 5,
				});
			}
			const block = document.getElementById("overlay");
			if (block) {
				setOverlayHeight(block.clientHeight);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[hoveredContent?.informationId]
	);
	return (
		<>
			<ContentLayer
				{...rest}
				metadata={modeData[mode]}
				page={page}
				searchData={(searchData || []).filter((sd) => sd.pageStart === page)}
			/>
			{!isSelecting && !hasSelectedRectangles && (
				<>
					<VersionLayer
						height={rest.height}
						metadata={modeData[mode]}
						priority={priority}
						onSetPriority={handlePriority}
					/>
					<DeletedVersionLayer
						deletedData={deletedData}
						height={rest.height}
						priority={priority}
						onSetPriority={handlePriority}
					/>
				</>
			)}
			<RedressLayer
				hoveredBlock={hoveredContent}
				left={overlayCoordinates?.left || 0}
				metadata={modeData[mode]}
				overlayHeight={overlayHeight}
				page={page}
				top={overlayCoordinates?.top || 0}
				width={rest.width}
			/>
		</>
	);
}
