import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField } from "@mui/material";
import styles from "./PageHandler.module.css";
import { debounce } from "../../../../../../../../common/utils";
import { setScrollTo } from "../../../../../../slice/pdf/pdf-slice";

const debouncedSearch = debounce((func) => func(), 500);
export default function PageHandler() {
	const numberOfPages = useSelector(({ srDocument }) => srDocument.numberOfPages);
	const page = useSelector(({ srPdf }) => srPdf.page);
	const [localPage, setLocalPage] = useState(0);
	const [localDebouncedPage, setLocalDebouncedPage] = useState(0);
	const [automaticPageChangeEnabled, setAutomaticPageChangeEnabled] = useState(true);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const dispatch = useDispatch();
	useEffect(() => {
		if (automaticPageChangeEnabled) {
			setLocalPage(page + 1);
		}
	}, [page, automaticPageChangeEnabled]);
	useEffect(
		() => {
			if (localDebouncedPage && localPage === localDebouncedPage) {
				setLocalPage(localPage);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[localDebouncedPage]
	);
	const handleChangePage = (value) => {
		let tempPage = value ? Number.parseInt(value, 10) : value;
		if (numberOfPages < tempPage) {
			tempPage = numberOfPages;
		}
		if (tempPage !== "" && (Number.isNaN(tempPage) || tempPage <= 0)) {
			tempPage = 1;
		}
		setLocalPage(tempPage);
		debouncedSearch(() => {
			setLocalDebouncedPage(tempPage);
			dispatch(setScrollTo({ page: tempPage, position: 0.01 }));
		});
	};
	const handleDisableAutoInput = () => {
		setAutomaticPageChangeEnabled(false);
		if (!localPage) {
			setLocalPage(1);
		}
	};
	const handleEnableAutoInput = () => {
		setAutomaticPageChangeEnabled(true);
	};
	return (
		<>
			{documentId && (
				<div className={styles.pageContainer}>
					<TextField
						classes={{ root: styles.pageContainer__pageInput }}
						data-testid="page.handler.textfield"
						inputProps={{
							className: styles["pageContainer__pageInput--padding"],
						}}
						type="text"
						value={localPage}
						variant="outlined"
						onBlur={handleEnableAutoInput}
						onChange={(e) => handleChangePage(e.target.value)}
						onClick={handleDisableAutoInput}
					/>
					<span>/{numberOfPages}</span>
				</div>
			)}
		</>
	);
}
