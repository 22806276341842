import React, { useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import {
	setFinalParameters,
	setSelectedBlock,
	getCriticalityList,
	getNegotiabilityList,
	getReqTypesList,
} from "../../../slice/document/document-slice";
import { updateNode } from "../../../slice/pdf/pdf-slice";
import { SENTENCE_TYPE } from "../../../constants/constants";
import ParametersDialog from "./additional-detail/ParametersDialog";
import { ApiService, RequirementParameterService } from "../../../../../api";
import { checkReqValMode } from "../../../utils/utils";
import { hasProperty } from "../../../../../common/utils";
import { createNotification } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";

export default function Parameters() {
	const finalParameters = useSelector(({ srDocument }) => srDocument.finalParameters);
	const selectedBlock = useSelector(({ srDocument }) => srDocument.selectedBlock);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(
		() => {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
			batch(() => {
				dispatch(getCriticalityList(cancelTokenSourceRef.current.token));
				dispatch(getNegotiabilityList(cancelTokenSourceRef.current.token));
				dispatch(getReqTypesList(cancelTokenSourceRef.current.token));
			});
			return () => {
				ApiService.cancelTokens(cancelTokenSourceRef.current);
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleChangeEmitter = () => {
		dispatch(
			setFinalParameters({
				parameterId: selectedBlock.informationId,
				finalParameters: {
					...finalParameters,
					reqTypeSource: finalParameters.reqTypeSource === "CLIENT" ? "AUTOMATIC" : "CLIENT",
				},
			})
		);
	};

	const formatParameters = ({ newParam = null, oldParam = null }) => {
		const newFinalParameters = JSON.parse(JSON.stringify(finalParameters));
		if (oldParam) {
			delete newFinalParameters.projectParams[Object.keys(oldParam)[0]];
		}
		if (newParam) {
			newFinalParameters.projectParams = { ...newFinalParameters.projectParams, ...newParam };
		}
		return newFinalParameters;
	};

	const handleUpdateParameter = ({ newParam, oldParam }) => {
		dispatch(
			setFinalParameters({
				parameterId: selectedBlock.informationId,
				finalParameters: formatParameters({ newParam, oldParam }),
			})
		);
	};

	const isParameterIncluded = (paramName) => {
		if (finalParameters.parameters) {
			return Object.keys(finalParameters.parameters).includes(paramName);
		}
		return false;
	};

	const handleChangeClientId = (e) => {
		const { value } = e.target;
		if (value === "") {
			const newFinalParameters = JSON.parse(JSON.stringify(finalParameters));
			delete newFinalParameters.clientId;
			newFinalParameters.reqTypeSource = "AUTOMATIC";
			dispatch(
				setFinalParameters({ parameterId: selectedBlock.informationId, finalParameters: newFinalParameters })
			);
		} else {
			dispatch(
				setFinalParameters({
					parameterId: selectedBlock.informationId,
					finalParameters: { ...finalParameters, clientId: value },
				})
			);
		}
	};

	const handleSelect = (e, type) => {
		const { value } = e.target;
		if (value === "") {
			const tempFinalParameters = { ...finalParameters };
			delete tempFinalParameters[type];
			dispatch(
				setFinalParameters({ parameterId: selectedBlock.informationId, finalParameters: tempFinalParameters })
			);
		} else {
			dispatch(
				setFinalParameters({
					parameterId: selectedBlock.informationId,
					finalParameters: { ...finalParameters, [type]: value },
				})
			);
		}
	};

	const isUnconditional = (param) => {
		const loweredParam = param?.toLowerCase();
		return (
			loweredParam === "id" ||
			loweredParam === "criticality" ||
			loweredParam === "negotiability" ||
			loweredParam === "type" ||
			loweredParam === "reqtypesource"
		);
	};

	const receiveParamatersOnSelected = useCallback(() => {
		dispatch(
			setFinalParameters({ parameterId: selectedBlock.informationId, finalParameters: selectedBlock.parameters })
		);
	}, [selectedBlock, dispatch]);

	const handleCloseReq = () => {
		dispatch(setSelectedBlock(null));
	};
	const checkId = (origin, destination) => {
		if (hasProperty(origin, "id")) {
			if (!origin.id || origin.id.length === 0) {
				destination.id = null;
			}
		}
	};
	const cleanParameters = (params) => {
		checkId(finalParameters, params);
		return params;
	};

	const handleSubmitParameters = (payload) => {
		RequirementParameterService.updateParameters(
			{ reqId: selectedBlock.informationId },
			cleanParameters(payload || finalParameters),
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				const { parameters, ...x } = payload || finalParameters;
				const updates = [
					{ property: "parameters", value: { ...x, ...parameters } },
					{ property: "clientRequirementId", value: x?.clientId },
				];
				dispatch(
					updateNode({
						mode,
						informationId: selectedBlock.informationId,
						pageConditions: { page: selectedBlock.pageStart },
						updates,
					})
				);
				dispatch(
					setSelectedBlock({
						...selectedBlock,
						parameters: { ...parameters, ...x },
						clientRequirementId: x?.clientId,
					})
				);
				createNotification({
					type: "success",
					message: translate("smart-review.additional.notification.update-success", {
						reqId: selectedBlock.informationId,
					}),
				});
				handleCloseReq();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleKeyDownGrid = (key) => {
		if (key === "esc") {
			if (selectedBlock !== null) {
				dispatch(setSelectedBlock(null));
			}
		}
	};
	return (
		<>
			{selectedBlock &&
				checkReqValMode(mode) &&
				(selectedBlock.validatedSentenceType === SENTENCE_TYPE.REQUIREMENT ||
					(!selectedBlock.validatedSentenceType &&
						selectedBlock.sentenceType === SENTENCE_TYPE.REQUIREMENT)) && (
					<ParametersDialog
						finalParameters={finalParameters}
						isParameterIncluded={isParameterIncluded}
						isUnconditional={isUnconditional}
						receiveParamatersOnSelected={receiveParamatersOnSelected}
						onChangeClientId={handleChangeClientId}
						onChangeEmitter={handleChangeEmitter}
						onCloseReq={handleCloseReq}
						onFormatParameters={formatParameters}
						onKeyDownGrid={handleKeyDownGrid}
						onSelect={handleSelect}
						onSubmitParameters={handleSubmitParameters}
						onUpdateParameter={handleUpdateParameter}
					/>
				)}
		</>
	);
}
