import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { loadViewTranslation } from "../../../common/providers";
import Loader from "./Loader";

export default function NavRouteWrapper({ localesPath, children }) {
	const { value } = useSelector(({ context }) => context.language);
	const [trsLoaded, setTrsLoaded] = useState(false);
	const metadataLoaded = useSelector(({ context }) =>
		[
			context.loadingCompanies,
			context.loadingProject,
			context.loadingFlags,
			context.loadingSegFlags,
			context.loadingUser,
		].every((e) => !e)
	);
	useEffect(() => {
		if (localesPath) {
			loadViewTranslation(value, localesPath)
				.then(() => setTrsLoaded(true))
				.catch((err) => console.error(err));
		}
		return () => setTrsLoaded(false);
	}, [value, localesPath]);
	return metadataLoaded && trsLoaded ? children : <Loader />;
}
