import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./ChatList.module.css";
import { CustomButton, InfiniteList2, icon } from "../../../../common/components";
import ChatRow from "./ChatRow";
import { ChatProjectService } from "../../../../api";
import { useApi } from "../../../../common/hooks";
import { translate } from "../../../../common/providers";

const ChatList = ({ onChangeSelectedChat, onListUpdate, onResetFilters, onSetToRefresh, selectedChat, toRefresh }) => {
	const { call: getAllChats } = useApi(ChatProjectService.getAllChats);
	const projectId = useSelector(({ context }) => context.project?.id);
	const [commentsRequest, setCommentsRequest] = useState(null);

	useEffect(() => {
		if (projectId && toRefresh) {
			setCommentsRequest(
				() =>
					({ page, limit }) =>
						getAllChats({ projectId }, { page, limit })
			);
		}
	}, [getAllChats, projectId, toRefresh]);
	const handleRefresh = () => {
		onSetToRefresh(true);
	};
	const rowRenderer = (chat) => (
		<ChatRow
			key={chat.id}
			data-testid={`chat-row-${chat.id}`}
			row={chat}
			selected={chat.id === selectedChat}
			onChangeSelectedChat={onChangeSelectedChat}
			onRefresh={handleRefresh}
			onResetFilters={onResetFilters}
		/>
	);
	const startNewConversation = () => {
		onResetFilters();
		onChangeSelectedChat(0);
	};
	const cautionBannerDisabled = useSelector(({ context }) => context.cautionBannerDisabled);
	return (
		<div className={styles.container}>
			<div className={styles.btn__container}>
				<CustomButton
					color="secondary"
					icon={icon.faPlus}
					size="sm"
					variant="outlined"
					onClick={startNewConversation}
				>
					{translate("chat-project.add-new-conversation-button")}
				</CustomButton>
			</div>
			<div className={styles.conversationList} data-caution-banner-disabled={cautionBannerDisabled}>
				<InfiniteList2
					callOnResponse={onListUpdate}
					className={styles.infiniteList}
					request={commentsRequest}
					rowRenderer={rowRenderer}
					scrollContainerClassName={styles.infiniteList__scrollContainer}
				/>
			</div>
		</div>
	);
};

export default ChatList;
