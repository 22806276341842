import client from "../client/client";

class TaskService {
	create(payload, cancelToken) {
		return client.post("/tasks", { payload, config: { cancelToken } });
	}

	deleteTask({ taskId }, cancelToken) {
		return client.delete(`/tasks/${taskId}`, { config: { cancelToken } });
	}

	updateTask({ taskId }, payload, cancelToken) {
		return client.patch(`/tasks/${taskId}`, { payload, config: { cancelToken } });
	}

	getComments({ taskId }, cancelToken) {
		return client.get(`/tasks/${taskId}/comments`, { config: { cancelToken } });
	}

	comment({ taskId }, payload, cancelToken) {
		return client.post(`/tasks/${taskId}/comments`, { payload: { value: payload }, config: { cancelToken } });
	}

	updateStatus({ taskId }, status, cancelToken) {
		return client.put(`/tasks/${taskId}/status`, { payload: status, config: { cancelToken } });
	}

	nextStep({ taskId }, cancelToken) {
		return client.patch(`/tasks/${taskId}/status`, { config: { cancelToken } });
	}

	getAllByInfoId({ infoId }, cancelToken) {
		return client.get(`/tasks/information/${infoId}`, { config: { cancelToken } });
	}

	getPriorities(cancelToken) {
		return client.get("/tasks/priorities", { config: { cancelToken } });
	}

	getAllByProject({ projectId }, cancelToken) {
		return client.get(`/tasks/projects/${projectId}`, { config: { cancelToken } });
	}

	getStatuses(cancelToken) {
		return client.get("/tasks/statuses", { config: { cancelToken } });
	}
}

export default new TaskService();
