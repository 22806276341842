const SCOPES = { PLATFORM: "PLATFORM", COMPANY: "COMPANY", PROJECT: "PROJECT", DOCUMENT: "DOCUMENT" };
const PERMISSIONS = {
	// SSO REALM CLAIM
	REALM: { AITENDERS: { claim: "AITENDERS" }, ADMIN: { claim: "ADMIN" } },
	// PLATFORM
	PLATFORM_ADMIN: { scope: SCOPES.PLATFORM, role: "ADMIN" },
	PLATFORM_USER: { scope: SCOPES.PLATFORM, role: "USER" },
	// COMPANY
	COMPANY_ADMIN: { scope: SCOPES.COMPANY, role: "ADMIN" },
	COMPANY_MANAGER: { scope: SCOPES.COMPANY, role: "MANAGER" },
	COMPANY_MEMBER: { scope: SCOPES.COMPANY, role: "MEMBER" },
	// PROJECT
	PROJECT_LEADER: { scope: SCOPES.PROJECT, role: "LEADER" },
	PROJECT_MANAGER: { scope: SCOPES.PROJECT, role: "MANAGER" },
	PROJECT_MEMBER: { scope: SCOPES.PROJECT, role: "MEMBER" },
	// DOCUMENT /!\ Cannot be used with hasPermission because Document is not saved into context yet! Ask Romain.
	EDITOR: { scope: SCOPES.DOCUMENT, role: "EDITOR" },
	VALIDATOR: { scope: SCOPES.DOCUMENT, role: "VALIDATOR" },
	REVIEWER: { scope: SCOPES.DOCUMENT, role: "REVIEWER" },
};

export { PERMISSIONS as default, SCOPES };
