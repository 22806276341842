import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./CustomCircularProgress.module.css";
import { progressStepColor, adjustValue } from "../utils/utils";

const strokeWidth = 5;
const calculateStyleProps = (size) => {
	let progressSize = 0;
	let textOffset = 0;
	let percentOffset = 0;
	let topTextOffset = 0;
	switch (size) {
		case "small":
			progressSize = 40;
			textOffset = 8;
			topTextOffset = 2;
			percentOffset = 5;
			break;
		case "medium":
			progressSize = 60;
			textOffset = 12;
			topTextOffset = 4;
			percentOffset = 7;
			break;
		case "large":
			progressSize = 90;
			textOffset = 16;
			topTextOffset = 6;
			percentOffset = 9;
			break;
		default:
			break;
	}
	const center = progressSize / 2;
	const radius = progressSize / 2 - strokeWidth / 2;
	const circumference = 2 * Math.PI * radius;
	return { progressSize, textOffset, topTextOffset, percentOffset, center, circumference, radius };
};
const CustomCircularProgress = ({
	strokeColor = null,
	value,
	total = 1,
	size = "medium",
	showInPercentage = false,
}) => {
	const circleRef = useRef(null);
	const { progressSize, textOffset, percentOffset, center, circumference, radius, topTextOffset } =
		calculateStyleProps(size);
	const progress = adjustValue((total && value / total) || 0);
	const offset = (1 - progress) * circumference;
	return (
		<svg className={styles.container} height={progressSize} width={progressSize}>
			<circle
				className={styles.progressCircle__bg}
				cx={center}
				cy={center}
				r={radius}
				stroke="var(--color-light-grey-3)"
				strokeWidth={strokeWidth}
			/>
			<circle
				ref={circleRef}
				className={styles.progressCircle__progress}
				cx={center}
				cy={center}
				r={radius}
				stroke={strokeColor || progressStepColor(progress)}
				strokeDasharray={circumference}
				strokeDashoffset={offset}
				strokeLinecap="round"
				strokeWidth={strokeWidth}
			/>
			{!showInPercentage && (
				<>
					<text className={styles.progessCircle__text} data-size={size} x={center} y={center - topTextOffset}>
						{value}
					</text>
					<text
						className={`${styles.progessCircle__text} ${styles["progessCircle__text--grey"]}`}
						data-size={size}
						data-text-color={size}
						x={center}
						y={center + textOffset}
					>
						{total}
					</text>
				</>
			)}
			{showInPercentage && (
				<text className={styles.progessCircle__text} data-size={size} x={center} y={center + percentOffset}>
					{progress * 100}%
				</text>
			)}
		</svg>
	);
};
CustomCircularProgress.propTypes = {
	size: PropTypes.oneOf(["small", "medium", "large"]),
};
CustomCircularProgress.defaultProps = {
	size: "medium",
};
export default CustomCircularProgress;
