import React from "react";
import { batch, connect } from "react-redux";
import { ApiService } from "../../../api";
import {
	AnalyticsProvider,
	Flags,
	isSegFeatureEnabled,
	Permissions,
	SegFlags,
	translate,
} from "../../../common/providers";
import { exportView } from "../../../navigation";
import {
	AutomationDialog,
	CustomButton,
	HorizontalDivider,
	SideDrawerMain,
	ViewBanner,
	generateFilters,
} from "../../../common/components";
import { SmartSearchDrawer } from "./components";
import {
	getAllSearches,
	search,
	setFilters,
	setIsLoadingSearch,
	countResults,
	createSearch,
	getCriticalityParameters,
	getNegotiabilityParameters,
	getTypeParameters,
	updateSearchFilters,
	setTotalResults,
	setIsFilterTocOut,
} from "./slice/search-for-automation-slice";
import { removeNullFilters } from "./slice/search-for-automation-utils";
import { SmartSearchResult, SmartSearchFilters } from "../components";
import { setCrossFilters } from "../../../common/slice";
import { isNonEmptyObject } from "../../../common/utils";
import styles from "./SearchForAutomation.module.css";

const mapStateToProps = ({ context, searchForAutomation }) => ({
	crossFilters: context.crossFilters,
	filters: searchForAutomation.filters,
	limit: searchForAutomation.limit,
	projectId: context.project.id,
	selectedSearch: searchForAutomation.currentSearch.id,
	modified: searchForAutomation.modified,
});

const mapDispatchToProps = (dispatch) => ({
	onGetAllSearches: (token) => dispatch(getAllSearches(token)),
	onSetIsFilterTocOut: (boolean) => dispatch(setIsFilterTocOut(boolean)),
	onSearch: ({ filters, page, limit, token }) => dispatch(search({ filters, page, limit, token })),
	onSetFilters: (filters) => dispatch(setFilters(filters)),
	onSetCrossFilters: (filters) => dispatch(setCrossFilters(filters)),
	onSetLoadingSearch: (isLoading) => dispatch(setIsLoadingSearch(isLoading)),
	onCountResults: ({ filters, token }) => dispatch(countResults({ filters, token })),
	onCreateSearch: ({ token, ...payload }) => dispatch(createSearch({ ...payload, token })),
	onSetIsLoadingSearch: (isLoading) => dispatch(setIsLoadingSearch(isLoading)),
	onUpdateSearchFilters: ({ searchCardId, creatorId, token }) =>
		dispatch(updateSearchFilters({ searchCardId, creatorId, token })),
});

class SearchForAutomation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openDrawer: true,
			openAddAutomation: false,
		};
		this.cancelTokenSource = ApiService.getCancelTokenSource();
		this.crossFilterTimeout = null;
	}

	componentDidMount() {
		const documentTitle = translate("smart-search.document.search-for-automation-title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Search for automation" });
		this.initSearch();
	}

	initSearch = () => {
		const { filters, limit, onSearch, onSetCrossFilters, onSetLoadingSearch } = this.props;
		if (isNonEmptyObject(filters)) {
			batch(() => {
				onSetLoadingSearch(true);
				onSearch({
					filters: generateFilters(filters),
					page: 0,
					limit,
					token: this.cancelTokenSource.token,
				});
			});
			this.crossFilterTimeout = setTimeout(() => {
				onSetCrossFilters(null);
			}, 1000);
		}
	};

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
		clearInterval(this.crossFilterTimeout);
	}

	handleOpenDrawer = () => this.setState((prev) => ({ openDrawer: !prev.openDrawer }));

	handleOpenAddAutomation = () => {
		this.setState((prev) => ({ openAddAutomation: !prev.openAddAutomation }));
	};

	render() {
		const { openDrawer, openAddAutomation } = this.state;
		const {
			selectedSearch,
			modified,
			onSearch,
			onSetFilters,
			onCountResults,
			onCreateSearch,
			onSetIsLoadingSearch,
			onUpdateSearchFilters,
			onSetIsFilterTocOut,
		} = this.props;
		const newAutomation = (
			<>
				{isSegFeatureEnabled(SegFlags.AUTOMATION) && (
					<CustomButton
						className={styles.titleWrapper_newAutomationBtn}
						disabled={!selectedSearch || modified}
						variant="contained"
						onClick={this.handleOpenAddAutomation}
					>
						{translate("common:automation.actions.new-automation")}
					</CustomButton>
				)}
			</>
		);
		return (
			<>
				<ViewBanner
					options={[newAutomation]}
					titles={[{ title: translate("navigation:project.searches-for-automation") }]}
				/>
				<SideDrawerMain
					className={styles.sideDrawer__main}
					contentClassName={styles.sideDrawer__content}
					DrawerComponent={SmartSearchDrawer}
					openDrawer={openDrawer}
					onOpenDrawer={this.handleOpenDrawer}
				>
					<SmartSearchFilters
						getCriticalityParameters={getCriticalityParameters}
						getNegotiabilityParameters={getNegotiabilityParameters}
						getTypeParameters={getTypeParameters}
						setTotalResults={setTotalResults}
						slice="searchForAutomation"
						onCountResults={onCountResults}
						onCreateSearch={onCreateSearch}
						onOpenAddAutomation={this.handleOpenAddAutomation}
						onRemoveNullFilters={removeNullFilters}
						onSearch={onSearch}
						onSetFilters={onSetFilters}
						onSetIsFilterTocOut={onSetIsFilterTocOut}
						onSetIsLoadingSearch={onSetIsLoadingSearch}
						onUpdateSearchFilters={onUpdateSearchFilters}
					/>
					<HorizontalDivider />
					<SmartSearchResult slice="searchForAutomation" onReset={this.initSearch} onSearch={onSearch} />
					<AutomationDialog
						isNew
						open={openAddAutomation}
						preSelectedSearchCards={selectedSearch}
						onClose={this.handleOpenAddAutomation}
						onSubmit={this.handleOpenAddAutomation}
					/>
				</SideDrawerMain>
			</>
		);
	}
}

export { default as searchForAutomationSlice } from "./slice/search-for-automation-slice";
export default exportView({
	path: "/projects/:projectId/search/search-for-automation",
	component: connect(mapStateToProps, mapDispatchToProps)(SearchForAutomation),
	localesPath: "/smart-search/locales",
	flag: Flags.SEARCHCARD,
	segFlag: [SegFlags.SMART_SEARCH, SegFlags.AUTOMATION],
	companyRole: [Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER],
	role: [Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER],
});
