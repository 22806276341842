import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from "@mui/material";
import React, { useMemo } from "react";
import { icon, IconComponent } from "../../../icon-component";
import styles from "./ExpandableCategoryRow.module.css";
import { CustomTooltip } from "../../../tooltip";

let allowExpand = true;

const ExpandableCategoryRow = ({
	checkedCategories = [],
	category,
	indentLevel = 0,
	onCheck,
	onIsChecked,
	onToggleExpand,

	parentCounters,
	readOnly,
}) => {
	const isChecked = useMemo(() => onIsChecked(category.id), [onIsChecked, category.id]);
	const hasSubCategories = useMemo(() => category.subThematic?.length > 0 || category.childrenCount > 0, [category]);
	const categoryCounters = useMemo(
		() => parentCounters.find((parent) => parent.parentId === category.id)?.counter || 0,
		[category, parentCounters]
	);
	const handleChange = () => {
		setTimeout(() => {
			if (allowExpand) {
				onToggleExpand(category);
			} else {
				allowExpand = true;
			}
		}, 0);
	};
	const handleCheck = () => {
		allowExpand = false;
		onCheck(category, isChecked);
	};
	return (
		<Accordion
			classes={{
				root: styles.accordion__root,
			}}
			expanded={!!category.expanded || category.filtered !== undefined}
			style={{
				marginLeft: indentLevel > 0 ? 32 : 0,
			}}
			onChange={handleChange}
		>
			<AccordionSummary
				classes={{
					root: styles.accordionSummary__root,
					content: styles.accordionSummary__content,
				}}
				style={{
					cursor: hasSubCategories ? "pointer" : "default",
				}}
			>
				{hasSubCategories && (
					<IconComponent
						className={styles.expandIcon}
						color="var(--shades-grey-600)"
						icon={
							((category.expanded || category.filtered !== undefined) && icon.faChevronDown) ||
							icon.faChevronRight
						}
						size="lg"
					/>
				)}
				<Checkbox
					checked={isChecked}
					className={styles.checkBox}
					disabled={readOnly}
					size="small"
					onChange={handleCheck}
				/>
				{((category.childrenCount > 0 || category.subThematic?.length > 0) && (
					<CustomTooltip
						showWhenOverflow
						title={
							<>
								{category.name} ({categoryCounters}/
								{category.childrenCount || category.subThematic?.length})
							</>
						}
					>
						<span
							className={`${styles.categoryName} ${styles["categoryName--bold"]} ${
								(category.filtered === false || category.prevFilteredState === false) &&
								styles["categoryName--greyed"]
							}`}
						>
							{category.name} ({categoryCounters}/{category.childrenCount || category.subThematic?.length}
							)
						</span>
					</CustomTooltip>
				)) || (
					<CustomTooltip showWhenOverflow title={category.name}>
						<span className={styles.categoryName}>{category.name}</span>
					</CustomTooltip>
				)}
			</AccordionSummary>
			<AccordionDetails
				classes={{
					root: styles.accordionDetails__root,
				}}
			>
				{Array.isArray(category.subThematic) &&
					category.subThematic.length > 0 &&
					category.subThematic.map((subCat) => (
						<ExpandableCategoryRow
							key={subCat.id}
							category={subCat}
							checkedCategories={checkedCategories}
							indentLevel={indentLevel + 1}
							parentCounters={parentCounters}
							onCheck={onCheck}
							onIsChecked={onIsChecked}
							onToggleExpand={onToggleExpand}
						/>
					))}
			</AccordionDetails>
		</Accordion>
	);
};

export default ExpandableCategoryRow;
