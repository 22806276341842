import React, { useEffect, useRef } from "react";
import { batch, useSelector, useDispatch } from "react-redux";
import { ApiService } from "../../../../../../../../../api";
import { getRootTransverse } from "../../../../../../../slice/project-document/project-document-thunks";
import { Clause, Row } from ".";
import { generatePayload, generateSearchPosition } from "../../../../../../../utils/utils";
import {
	setClauseId,
	setOpenDocumentNode,
	setTemporaryQaSearchIndex,
} from "../../../../../../../slice/project-document/project-document-slice";
import { setScrollTo } from "../../../../../../../slice/pdf/pdf-slice";
import { debounce } from "../../../../../../../../../common/utils";
import { setTemporaryDocument } from "../../../../../../../slice/document/document-slice";
import SmartReviewContext from "../../../../../../../context/smart-review-context";

const debounceFunc = debounce((func) => func(), 500);
export default function CollapseNodes({ document }) {
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	const filters = useSelector(({ srProjectDocument }) => srProjectDocument.filters);
	const projectId = useSelector(({ context }) => context?.project?.id);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const totalElements = useSelector(({ srDocument }) => srDocument.qaMetadata?.totalElements);
	const isThereAnotherFilter = useSelector(({ srProjectDocument }) => srProjectDocument.isThereAnotherFilter);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleDocumentSelection = (row) => {
		dispatch(setClauseId(""));
		if (!isThereAnotherFilter) {
			dispatch(setTemporaryDocument({ id: row.id }));
			SmartReviewContext.setSidePanelInfo(null);
		} else {
			dispatch(setTemporaryQaSearchIndex({ index: row.totalContent, scrollTo: true }));
		}
	};
	const handleSelectRoot = (id, totalContent) => {
		if (!document.subClauses) {
			dispatch(
				getRootTransverse({
					projectId,
					documentId: id,
					filters: { ...generatePayload(filters), totalContent },
					token: cancelTokenSourceRef.current.token,
				})
			);
		}
	};
	const handleOpen = (e) => {
		e.stopPropagation();
		e.preventDefault();
		batch(() => {
			dispatch(setOpenDocumentNode(document.id));
			handleSelectRoot(document.id, document.totalContent);
		});
	};

	const handleTocSelection = (toc) => {
		debounceFunc(() => {
			dispatch(setClauseId(toc.id));
			if (!isThereAnotherFilter) {
				batch(() => {
					if (documentId !== toc.documentId) {
						dispatch(
							setTemporaryDocument({
								id: toc.documentId,
								fromToc: true,
								coordinates: { page: toc.page, y1: toc.rectangle.y1 },
							})
						);
						SmartReviewContext.setSidePanelInfo(null);
					} else {
						dispatch(setScrollTo(generateSearchPosition({ page: toc.page, y1: toc.rectangle.y1 })));
					}
				});
			} else if (toc?.totalContent <= totalElements) {
				if (toc.nbInformation > 0) {
					dispatch(setTemporaryQaSearchIndex({ index: toc.totalContent, scrollTo: true }));
				} else if (documentId !== toc.documentId) {
					dispatch(
						setTemporaryDocument({
							id: toc.documentId,
							fromToc: true,
							coordinates: { page: toc.page, y1: toc.rectangle.y1 },
						})
					);
				} else {
					dispatch(setScrollTo(generateSearchPosition({ page: toc.page, y1: toc.rectangle.y1 })));
				}
			} else {
				const newIndex = (toc.parents?.reverse()?.find((par) => par?.totalContent > 0) || {}).totalContent || 0;
				if (newIndex && newIndex >= 0 && newIndex < totalElements) {
					dispatch(setTemporaryQaSearchIndex({ index: newIndex, scrollTo: true }));
				}
				if (documentId !== toc.documentId) {
					dispatch(
						setTemporaryDocument({
							id: toc.documentId,
							fromToc: true,
							coordinates: { page: toc.page, y1: toc.rectangle.y1 },
						})
					);
				} else {
					dispatch(setScrollTo(generateSearchPosition({ page: toc.page, y1: toc.rectangle.y1 })));
				}
			}
		});
	};
	return (
		<>
			<Row
				data={document}
				hasChildren={document?.clauseExist}
				label={document?.name}
				level={0}
				open={document?.open}
				onOpen={handleOpen}
				onSelect={handleDocumentSelection}
			/>
			{document?.open &&
				Array.isArray(document.subClauses) &&
				document.subClauses.length > 0 &&
				document.subClauses.map((clause) => (
					<Clause
						key={clause.id}
						data={{ ...clause, documentId: document.id }}
						level={0}
						onSelect={handleTocSelection}
					/>
				))}
		</>
	);
}
