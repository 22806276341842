import React, { useEffect, useState } from "react";
import { TextField, Checkbox } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "../TagDialog.module.css";
import { translate } from "../../../../../common/providers";
import NewTagDialog from "./NewTagDialog";
import { CustomButton, CustomDialog } from "../../../../../common/components";

const TagListDialog = ({ open, close, tags, onCreateQuestionTag, onCreateNewTag }) => {
	const [openNewTagDialog, setOpenNewTagDialog] = useState(false);
	const [selectedTags, setSelectedTags] = useState([]);
	const handleNewTagDialog = () => {
		setOpenNewTagDialog((prev) => !prev);
	};
	const handleChangeTags = (checked, value) => {
		let newSelectedTags = selectedTags;
		if (checked) {
			newSelectedTags.push(value);
		} else {
			newSelectedTags = selectedTags.filter((val) => val !== value);
		}
		setSelectedTags(newSelectedTags);
	};
	const handleSubmit = () => {
		onCreateQuestionTag(selectedTags);
		close();
		setSelectedTags([]);
	};
	useEffect(() => {
		if (!open) {
			setSelectedTags([]);
		}
	}, [open]);
	return (
		<CustomDialog
			displayCloseCross
			isForm
			open={open}
			title={translate("qa-journal.qa-actions.tag-list-dialog.title.assign-tags")}
			onClose={close}
			onSubmit={handleSubmit}
		>
			<div className={`${styles["margin-bottom"]} ${styles["padding-top"]}`} style={{ display: "flex" }}>
				<Autocomplete
					disableCloseOnSelect
					fullWidth
					multiple
					getOptionLabel={(option) => option.tag}
					id="checkboxes-tags"
					options={tags}
					renderInput={(params) => (
						<TextField
							{...params}
							label={translate("qa-journal.qa-actions.tag-list-dialog.tag.label")}
							placeholder={translate("qa-journal.qa-actions.tag-list-dialog.tag.label")}
							size="small"
							variant="outlined"
						/>
					)}
					renderOption={(props, option, { selected }) => (
						<li {...props} className={styles.item}>
							<Checkbox
								disableRipple
								checked={selected}
								color="primary"
								edge="start"
								onChange={(e) => {
									handleChangeTags(e.target.checked, option);
								}}
							/>
							{option.tag}
						</li>
					)}
				/>
			</div>
			<div className={styles["margin-bottom"]} style={{ display: "flex" }}>
				<CustomButton color="primary" onClick={handleNewTagDialog}>
					{translate("qa-journal.qa-actions.tag-list-dialog.btn.new-tag")}
				</CustomButton>
			</div>
			<NewTagDialog close={handleNewTagDialog} open={openNewTagDialog} onCreateNewTag={onCreateNewTag} />
		</CustomDialog>
	);
};
export default TagListDialog;
