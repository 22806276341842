import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNode } from "../../../../slice/pdf/pdf-slice";
import { ApiService, ValidatorService } from "../../../../../../api";
import { createNotification, icon, CustomIconButton } from "../../../../../../common/components";
import { handleFreezeValRequirement } from "../../../../slice/utils";
import { translate } from "../../../../../../common/providers";
import styles from "./ValidationChecker.module.css";
import { ROLES, SENTENCE_TYPE } from "../../../../constants/constants";

const ValidationChecker = ({ meta, onHoverBlock }) => {
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	const savedImageLength = useSelector(({ srPdf }) => srPdf?.content?.length || 0);
	const reqData = useSelector(
		({ srPdf }) => srPdf?.content?.find((pageData) => pageData.page === meta.pageStart)?.req
	);

	const mode = useSelector(({ srDocument }) => srDocument.mode);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const handleCheckFreeze = () => {
		if (meta.validatedStatus) {
			ValidatorService.updateFreezeOnRequirement(
				{ informationId: meta.informationId },
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					dispatch(
						updateNode({
							mode: ROLES.VALIDATOR.mode,
							pageConditions: { length: savedImageLength, page: meta.pageStart, border: true },
							informationId: meta.informationId,
							updates: [
								{
									property: "isFrozen",
									callback: handleFreezeValRequirement,
								},
							],
						})
					);
					if (reqData) {
						dispatch(
							updateNode({
								mode: ROLES.REVIEWER.mode,
								pageConditions: { length: savedImageLength, page: meta.pageStart, border: true },
								informationId: meta.informationId,
								updates: [
									{
										property: "isFrozen",
										value: !meta.isFrozen,
									},
								],
							})
						);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			createNotification({
				type: "error",
				message: translate("smart-review.validation-checker.error.you-can-not-freeze"),
			});
		}
	};
	return (
		<>
			{(meta.sentenceType === SENTENCE_TYPE.INFORMATION &&
				!meta.gapOnType &&
				((meta.validatedSentenceType &&
					meta.validatedSentenceType?.toUpperCase() !== SENTENCE_TYPE.REQUIREMENT) ||
					!meta.validatedSentenceType)) ||
			(meta.validatedSentenceType &&
				meta.validatedSentenceType?.toUpperCase() === SENTENCE_TYPE.INFORMATION) ? null : (
				<>
					{meta.validatedStatus && !meta.isFrozenByParent && (
						<div
							key={meta.informationId}
							className={styles.container}
							onMouseEnter={() => {
								onHoverBlock({ id: meta.informationId, page: meta.pageStart });
							}}
						>
							{meta.validatedStatus !== "UNREVIEWED" &&
								(ROLES.VALIDATOR.mode === mode || meta.isFrozen) && (
									<CustomIconButton
										color={(meta.isFrozen && "primary") || "secondary"}
										disabled={mode !== ROLES.VALIDATOR.mode}
										icon={(meta.isFrozen && icon.faLock) || icon.faLockOpen}
										size="sm"
										tooltip={
											(mode !== ROLES.VALIDATOR.mode &&
												translate(
													"smart-review.validation-checker.title.frozen-requirement"
												)) ||
											(meta.isFrozen &&
												translate(
													"smart-review.validation-checker.title.unfreeze-requirement"
												)) ||
											translate("smart-review.validation-checker.title.freeze-requirement")
										}
										onClick={handleCheckFreeze}
									/>
								)}
						</div>
					)}
				</>
			)}
		</>
	);
};

export default ValidationChecker;
