import React from "react";
import { SidePanel } from "../../../../common/components";
import NormPanel from "./NormPanel";

export default function Norm({ open, onClose, norm, accessTab = 0, onActive: onActiveFromParent = null }) {
	return (
		<SidePanel open={open} size={65} onActive={onActiveFromParent} onClose={onClose}>
			<NormPanel accessTab={accessTab} norm={norm} onClose={onClose} />
		</SidePanel>
	);
}
