import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useState } from "react";
import { CustomDialog } from "..";
import { translate } from "../../../providers";
import { I18nTranslate } from "../../i18n";
import styles from "./CategoryDeleteDialog.module.css";

export default function CategoryDeleteDialog({
	categoryToDelete = {},
	isTemplate = false,
	onClose,
	open,
	onSubmit,
	parentCategory,
}) {
	const [keepSubCategories, setKeepSubCategories] = useState(true);
	const handleChangeKeepSubCategories = () => {
		setKeepSubCategories((prev) => !prev);
	};
	const handleSubmit = () => {
		onSubmit(keepSubCategories);
		setKeepSubCategories(true);
	};
	return (
		<CustomDialog
			className={styles.dialog}
			open={open}
			submitLabel={
				categoryToDelete.actionCount >= 1
					? translate("common:btn.delete-cat-and-automation")
					: translate("common:btn.delete")
			}
			title={translate("common:categories.delete-dialog.title")}
			onClose={onClose}
			onSubmit={handleSubmit}
		>
			{!isTemplate && (
				<I18nTranslate
					className={styles.dialog__text}
					param={{
						categoryName: categoryToDelete.name,
						contentsCount: categoryToDelete.contentsCount,
						documentsCount: categoryToDelete.documentsCount,
					}}
					translationKey={
						(categoryToDelete.contentsCount === 1 &&
							categoryToDelete.documentsCount === 1 &&
							"common:categories.delete-dialog.text-categorie-document") ||
						(categoryToDelete.contentsCount > 1 &&
							categoryToDelete.documentsCount === 1 &&
							"common:categories.delete-dialog.text-categories-document") ||
						(categoryToDelete.contentsCount > 1 &&
							categoryToDelete.documentsCount > 1 &&
							"common:categories.delete-dialog.text-categories-documents") ||
						"common:categories.delete-dialog.text.no-content"
					}
				/>
			)}
			{categoryToDelete.actionCount >= 1 && (
				<I18nTranslate
					className={`${styles.dialog__text} ${
						categoryToDelete.contentsCount >= 1 && styles["dialog__text--action"]
					}`}
					param={{
						categoryName: categoryToDelete.name,
						contentsCount: categoryToDelete.contentsCount,
						documentsCount: categoryToDelete.documentsCount,
					}}
					translationKey="common:categories.delete-dialog.text-categories-automation"
				/>
			)}
			{(categoryToDelete.childrenCount > 0 ||
				(Array.isArray(categoryToDelete.subThematic) && categoryToDelete.subThematic.length > 0)) && (
				<RadioGroup
					className={styles.dialog__radioGroup}
					value={keepSubCategories}
					onChange={handleChangeKeepSubCategories}
				>
					<FormControlLabel
						value
						control={<Radio color="primary" />}
						label={
							(parentCategory &&
								translate("common:categories.delete-dialog.radio.keep-subcategories", {
									newParentName: parentCategory.name,
								})) ||
							translate("common:categories.delete-dialog.radio.keep-subcategories.no-parent")
						}
					/>
					<FormControlLabel
						control={<Radio color="primary" />}
						label={translate("common:categories.delete-dialog.radio.delete-subcategories")}
						value={false}
					/>
				</RadioGroup>
			)}
		</CustomDialog>
	);
}
