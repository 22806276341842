import React from "react";
import { translate, translateEnumStatus } from "../../../../../../../common/providers";
import { CustomTable, CustomTableBody, CustomTableCell, CustomTableRow } from "../../../../../../../common/components";
import { getStatusBgColor, getStatusColor } from "../../../../../../../common/utils";
import styles from "./ReqPositionTable.module.css";

export default function ReqPositionsTable({ positions = [] }) {
	return (
		<CustomTable dense>
			<CustomTableBody emptyState={translate("common:empty-state.none")}>
				{Array.isArray(positions) &&
					positions.map((row) => (
						<CustomTableRow key={row.companyName}>
							<CustomTableCell
								autoTranslate="no"
								className={styles["table-cell--bold"]}
								component="th"
								scope="row"
								style={{ backgroundColor: getStatusBgColor(row.status) }}
								width="30%"
							>
								{row.companyName}
							</CustomTableCell>
							<CustomTableCell
								align="right"
								className={styles.positions__status}
								style={{
									backgroundColor: getStatusBgColor(row.status),
								}}
							>
								<span style={{ color: getStatusColor(row.status) }} translate="no">
									{translateEnumStatus(row.status)}
								</span>
								{row.comment && ` : ${row.comment}`}
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
