import React from "react";
import { CircularProgress } from "@mui/material";
import styles from "./ReqDetails.module.css";

export default function ReqDetailsLoader() {
	return (
		<div className={styles["req-details__empty-state"]}>
			<CircularProgress />
		</div>
	);
}
