import React, { useEffect, useRef, useState } from "react";
import { TableContainer, TablePagination, TextField } from "@mui/material";
import styles from "./Errors.module.css";
import { CustomDialog, createNotification } from "../../../../common/components";
import { RedressmentService, ControlService, ApiService } from "../../../../api";
import { Permissions, translate } from "../../../../common/providers";
import ErrorsTable from "./errors-table/ErrorsTable";

const GROUP_ABOVE = "GROUP_ABOVE";
const GROUP_BELOW = "GROUP_BELOW";
const TYPE_CLAUSE = "TYPE_CLAUSE";
const TYPE_PARAGRAPH = "TYPE_PARAGRAPH";

export default function Errors({
	errors,
	isLoading,
	selectedDocument = null,
	projectId,
	showTocRedressment,
	showAllErrors,
	showOnlyResolvedErrors,
	getContentErrorsByDocument,
	getDocumentError,
	getTocErrorsByDocument,
	handleChangeRowsPerPage,
	handleChangePage,
	page,
	limit,
}) {
	const cancelTokenSourceRef = useRef(null);
	const [toc, setToc] = useState("");
	const [selectedRow, setSelectedRow] = useState(null);
	const [open, setOpen] = useState(false);
	const [openWarning, setOpenWarning] = useState(false);
	const [titleWarning, setTitleWarning] = useState("");
	const [contentWarning, setContentWarning] = useState("");
	const [confirmRedress, setConfirmRedress] = useState({ action: "", openConfirmationRedress: false });
	const [openConfirmationIgnore, setOpenConfirmationIgnore] = useState(false);
	const [openConfirmationResolve, setOpenConfirmationResolve] = useState(false);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleChangeToc = (event) => {
		setToc(event.target.value);
	};

	const handleCloseConfirmation = () => {
		setConfirmRedress({ action: "", openConfirmationRedress: false });
		setOpenConfirmationIgnore(false);
		setOpenConfirmationResolve(false);
	};

	const handleRedressAction = (row, action) => {
		let payload;
		let promise = null;
		switch (action) {
			case GROUP_ABOVE:
			case GROUP_BELOW:
				payload = {
					contentId: row.content.id,
					action: GROUP_ABOVE ? "ABOVE" : "BELOW",
				};
				promise = RedressmentService.group(
					{ docId: selectedDocument.docId },
					payload,
					cancelTokenSourceRef.current.token
				);
				break;
			case TYPE_CLAUSE:
				payload = {
					id: row.content.id,
					type: "CLAUSE",
					action: "RIGHT",
					toc,
				};
				promise = RedressmentService.redress(
					{ docId: selectedDocument.docId },
					payload,
					cancelTokenSourceRef.current.token
				);
				setToc("");
				break;
			case TYPE_PARAGRAPH:
				payload = {
					id: row.content.id,
					type: "TYPE_PARAGRAPH",
					action: null,
					toc: null,
				};
				promise = RedressmentService.redress(
					{ docId: selectedDocument.docId },
					payload,
					cancelTokenSourceRef.current.token
				);
				break;
			default:
				break;
		}
		if (!promise) {
			return;
		}
		promise
			.then(() => {
				createNotification({
					type: "success",
					message: translate("consistency-check.errors.notification.success-redress-message"),
					title: translate("consistency-check.errors.notification.success-title"),
				});
				handleCloseConfirmation();
				getContentErrorsByDocument({ documentId: selectedDocument.docId }, cancelTokenSourceRef.current);
				getDocumentError({ documentId: selectedDocument.docId });
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setToc("");
	};

	const handleRedressConfirmation = (act) => {
		if (Permissions.PROJECT_LEADER) {
			setConfirmRedress({ action: act, openConfirmationRedress: true });
		} else {
			setOpenWarning(true);
			setTitleWarning("Warning");
			setContentWarning(translate("consistency-check.errors.warning.insufficient-privileges"));
		}
	};

	const handleClickOnAction = ({ action, row }) => {
		setSelectedRow(row);
		if (action === TYPE_CLAUSE) {
			handleClickOpen();
		} else {
			handleRedressConfirmation(action);
		}
	};

	const handleCloseWarning = () => {
		setOpenWarning(false);
		setTitleWarning("");
		setContentWarning("");
	};
	const handleIgnoreConfirmation = (row) => {
		setSelectedRow(row);
		setOpenConfirmationIgnore(true);
	};

	const handleResolveConfirmation = (row) => {
		setSelectedRow(row);
		setOpenConfirmationResolve(true);
	};

	const changeErrorStatus = (row, status) => {
		createNotification({ message: translate("consistency-check.errors.notification.info-processing") });
		ControlService.updateStatus({ errorId: row.id }, { status }, cancelTokenSourceRef.current.token)
			.then(() => {
				getContentErrorsByDocument({ documentId: selectedDocument.docId }, cancelTokenSourceRef.current);
				getTocErrorsByDocument({ documentId: selectedDocument.docId }, cancelTokenSourceRef.current);
				getDocumentError({ documentId: selectedDocument.docId });
				createNotification({
					type: "success",
					message: translate("consistency-check.errors.notification.success-message"),
					title: translate("consistency-check.errors.notification.success-title"),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	};

	return (
		<>
			{!isLoading && (!Array.isArray(errors.contents) || errors.contents.length === 0) ? (
				<div className={styles["empty-state"]}>
					<div className={styles["empty-state__container"]}>
						<strong className={styles["empty-state__container--text"]}>
							{translate("consistency-check.errors.empty-state")}
						</strong>
					</div>
				</div>
			) : (
				<div className={styles.root} style={{ overflow: "hidden", flex: 1 }}>
					<TableContainer className={styles.container}>
						<ErrorsTable
							contents={errors.contents}
							handleClickOnAction={handleClickOnAction}
							handleIgnoreConfirmation={handleIgnoreConfirmation}
							handleResolveConfirmation={handleResolveConfirmation}
							isLoading={isLoading}
							projectId={projectId}
							selectedDocument={selectedDocument}
							showAllErrors={showAllErrors}
							showOnlyResolvedErrors={showOnlyResolvedErrors}
							showTocRedressment={showTocRedressment}
						/>
					</TableContainer>
					<TablePagination
						component="div"
						count={errors.totalElements || 0}
						page={page}
						rowsPerPage={limit}
						rowsPerPageOptions={[10, 25, 100]}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
					<CustomDialog
						closeLabel={translate("common:btn.close")}
						maxWidth="xs"
						open={openWarning}
						subTitle={contentWarning}
						title={titleWarning}
						onClose={handleCloseWarning}
					/>
					<CustomDialog
						open={confirmRedress && confirmRedress.openConfirmationRedress}
						subTitle={translate("consistency-check.errors.confirm-dialog.confirmation-text", {
							action:
								(confirmRedress &&
									translate(
										`consistency-check.errors.actions.${confirmRedress.action
											?.toLowerCase()
											.replaceAll("_", "-")}`
									)) ||
								"",
						})}
						title={translate("consistency-check.errors.confirm-dialog.title")}
						onClose={handleCloseConfirmation}
						onSubmit={() => {
							handleCloseConfirmation();
							handleRedressAction(selectedRow, confirmRedress.action);
						}}
					/>
					<CustomDialog
						open={openConfirmationIgnore}
						subTitle={translate("consistency-check.errors.confirm-dialog.ignore-error-text")}
						title={translate("consistency-check.errors.confirm-dialog.title")}
						onClose={handleCloseConfirmation}
						onSubmit={() => {
							handleCloseConfirmation();
							changeErrorStatus(selectedRow, "IGNORED");
						}}
					/>
					<CustomDialog
						open={openConfirmationResolve}
						subTitle={translate("consistency-check.errors.confirm-dialog.resolve-error-text")}
						title={translate("consistency-check.errors.confirm-dialog.title")}
						onClose={handleCloseConfirmation}
						onSubmit={() => {
							handleCloseConfirmation();
							changeErrorStatus(selectedRow, "RESOLVED");
						}}
					/>
					<CustomDialog
						open={open}
						subTitle={translate("consistency-check.redress-dialog.toc-identifier-form-text")}
						title={translate("consistency-check.redress-dialog.toc-identifier-form-title")}
						onClose={handleClose}
						onSubmit={() => handleRedressAction(selectedRow, TYPE_CLAUSE)}
					>
						<TextField
							autoFocus
							fullWidth
							id="toc"
							label={translate("consistency-check.redress-dialog.toc-identifier-form-title")}
							margin="dense"
							type="text"
							value={toc || ""}
							onChange={handleChangeToc}
						/>
					</CustomDialog>
				</div>
			)}
		</>
	);
}
