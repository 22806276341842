import React, { useEffect, useReducer, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DocumentFilter } from "../..";
import { ApiService, ContentService, NormsProjectService } from "../../../../../api";
import {
	CustomDialog,
	CustomIconButton,
	icon,
	Preview,
	SidePanelContainer,
	SidePanelHeader,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import { getNormsInProject } from "../../../slice/normsCenterSlice";
import DetectionsTabTable from "./detections-tab-table/DetectionsTabTable";
import styles from "./DetectionTab.module.css";

export default function DetectionsTab({ normId, onActive }) {
	const dispatch = useDispatch();
	const { limit, page, filters } = useSelector(
		({ normsCenter }) => ({
			limit: normsCenter.limit,
			page: normsCenter.page,
			filters: normsCenter.filters,
		}),
		shallowEqual
	);
	const project = useSelector(({ context }) => context.project);
	const [openPreview, setOpenPreview] = useReducer((prev) => !prev, false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState({ open: false, onSubmit: null });
	const [isLoading, setIsLoading] = useState(false);
	const [selectedInfo, setSelectedInfo] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [detections, setDetections] = useState([]);
	const [checkedRows, setCheckedRows] = useState([]);
	const [tempDetections, setTempDetections] = useState([]);
	const [detectionFilters, setDetectionFilters] = useState({ documents: [] });
	const cancelTokenSrcRef = useRef(null);
	useEffect(() => {
		cancelTokenSrcRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSrcRef.current);
			cancelTokenSrcRef.current = null;
		};
	}, []);
	const fetchDetections = ({ id, projectId, cancelTokenSrc, documents, displayResults }) => {
		setIsLoading(true);
		NormsProjectService.getNormDetections({ normId: id, projectId }, cancelTokenSrc.token, { documents })
			.then((data) => {
				if (displayResults) {
					setDetections(data);
				} else {
					setTempDetections(data);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
			});
	};
	useEffect(() => {
		if (!selectedInfo && selectedRow?.contentId && selectedRow?.docId) {
			ContentService.getInformationIdFromContent(
				{
					docId: selectedRow?.docId,
					contentId: selectedRow?.contentId,
				},
				cancelTokenSrcRef.current.token
			)
				.then((data) => {
					setSelectedInfo(data);
				})
				.catch((err) => console.error(err));
		}
	}, [selectedInfo, selectedRow]);
	useEffect(() => {
		if (!normId) {
			return null;
		}
		const projectId = project.id;
		const cancelTokenSrc = ApiService.getCancelTokenSource();
		fetchDetections({ id: normId, projectId, cancelTokenSrc, documents: "", displayResults: true });
		return () => {
			ApiService.cancelTokens(cancelTokenSrc);
		};
	}, [normId, project]);
	const deleteDetections = (normInProjectIds) => {
		NormsProjectService.deleteNormsInProject({ normInProjectIds }, cancelTokenSrcRef.current.token)
			.then(() => {
				const projectId = project.id;
				const documents =
					(Array.isArray(detectionFilters?.documents) &&
						detectionFilters.documents.length > 0 &&
						detectionFilters.documents.join(",")) ||
					[];
				dispatch(
					getNormsInProject({
						token: cancelTokenSrcRef.current.token,
						limit,
						page,
						filters,
						displayResults: true,
					})
				);
				fetchDetections({
					id: normId,
					projectId,
					cancelTokenSrc: cancelTokenSrcRef.current,
					documents,
					displayResults: true,
				});
			})
			.catch((err) => console.error(err));
	};
	const handleClickPreview = (row) => {
		setSelectedRow(row);
		setOpenPreview();
	};
	const handleCloseDialog = () => setOpenDeleteDialog({ open: false, onSubmit: null });
	const handleClickDelete = (row) =>
		setOpenDeleteDialog({
			open: true,
			onSubmit: () => {
				setCheckedRows((prev) => prev.filter((c) => c.normInProjectId !== row.normInProjectId));
				deleteDetections([row.normInProjectId]);
				handleCloseDialog();
			},
		});
	const handleDeleteSelected = () =>
		setOpenDeleteDialog({
			open: true,
			onSubmit: () => {
				setCheckedRows([]);
				deleteDetections(checkedRows.map((c) => c.normInProjectId));
				handleCloseDialog();
			},
		});
	const handleCheck = (e, row) => {
		const { checked } = e.target;
		if (checked) {
			setCheckedRows((prev) => [...prev, row]);
		} else {
			setCheckedRows((prev) => prev.filter((r) => r.normInProjectId !== row.normInProjectId));
		}
	};
	const handleCheckAll = (e) => {
		const { checked } = e.target;

		if (checked) {
			setCheckedRows(detections);
		} else {
			setCheckedRows([]);
		}
	};
	const proceedSearch = (newFilters, clickSearch) => {
		const projectId = project.id;
		const documents =
			(Array.isArray(newFilters?.documents) &&
				newFilters.documents.length > 0 &&
				newFilters.documents.join(",")) ||
			"";
		fetchDetections({
			id: normId,
			projectId,
			cancelTokenSrc: cancelTokenSrcRef.current,
			documents,
			displayResults: clickSearch,
		});
	};
	const handleSearch = ({ searchFilters, tempFilters, filterKey }) => {
		setDetectionFilters(searchFilters ?? tempFilters);
		if (!filterKey) {
			proceedSearch(searchFilters ?? tempFilters, true);
		} else {
			setDetections(tempDetections);
		}
	};
	return (
		<>
			<SidePanelContainer>
				<h5>{translate("norms-center.norm.tabs.detections.title")}</h5>
				<SidePanelHeader>
					<DocumentFilter
						filters={detectionFilters}
						isLoading={isLoading}
						tempTotalSearchResult={tempDetections?.length || 0}
						onDirectSearch={proceedSearch}
						onSearch={handleSearch}
					/>
					<div className={styles.detections__spacer} />
					{Array.isArray(checkedRows) && checkedRows.length > 0 && (
						<span className={styles.detections__selection}>
							<span className={styles.selection__label}>
								{translate("norms-center.norm.tabs.detections.selection")}
							</span>
							<CustomIconButton icon={icon.faTrash} variant="contained" onClick={handleDeleteSelected} />
						</span>
					)}
				</SidePanelHeader>
				<DetectionsTabTable
					checked={checkedRows}
					detections={detections}
					numSelected={checkedRows.length}
					onCheckAllRows={handleCheckAll}
					onCheckRow={handleCheck}
					onClickDelete={handleClickDelete}
					onClickPreview={handleClickPreview}
				/>
			</SidePanelContainer>
			<Preview
				coordinates={[
					{
						x1: selectedRow?.x1 || 0,
						x2: selectedRow?.x2 || 0,
						y1: selectedRow?.y1 || 0,
						y2: selectedRow?.y2 || 0,
					},
				]}
				docId={selectedRow?.docId || 0}
				infoId={selectedInfo}
				open={openPreview}
				page={selectedRow?.page || 0}
				projectId={project?.id}
				onActive={onActive}
				onClose={setOpenPreview}
			/>
			<CustomDialog
				open={openDeleteDialog?.open || false}
				submitLabel={translate("norms-center.norm.tabs.detections.dialog.submit")}
				subTitle={translate("norms-center.norm.tabs.detections.dialog.content")}
				title={translate("norms-center.norm.tabs.detections.dialog.title")}
				onClose={handleCloseDialog}
				onSubmit={openDeleteDialog?.onSubmit}
			/>
		</>
	);
}
