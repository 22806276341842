import React from "react";
import { VersioningTabView } from "./components";

export default function VersioningTab({ informationId = 0, onActive = null, pageStart = 0, selectedMode }) {
	return (
		<VersioningTabView
			informationId={informationId}
			pageStart={pageStart}
			selectedMode={selectedMode}
			onActive={onActive}
		/>
	);
}
