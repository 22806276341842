import React from "react";
import { useLocation } from "react-router-dom";
import { Checkbox } from "@mui/material";
import { CustomButton, CustomIconButtonLink, CustomTooltip, icon } from "../../../common/components";
import { hasPermission, Permissions, translate, translateEnumDocumentUserRole } from "../../../common/providers";
import styles from "./Document.module.css";
import { NavUtils } from "../../../navigation/utils";
import { SOURCE_TYPES } from "../../../common/constants";

function getBgColor(selectedDocument, currentDocument, isArchived) {
	let color = "var(--color-white)";
	if (selectedDocument === currentDocument) {
		color = "var(--color-light-grey-4)";
	}
	if (isArchived) {
		color = "var(--color-light-grey-3)";
	}
	return color;
}

const Document = ({
	checked,
	column,
	document,
	onCheckDocument,
	onSelectDocument,
	onSelectDocuments,
	projectId,
	roles,
	selectedDocument,
}) => {
	const location = useLocation();
	const getCounter = (role) => {
		switch (role) {
			case "EDITOR":
				return document.documentData.countEditors || "";
			case "VALIDATOR":
				return document.documentData.countValidators || "";
			case "REVIEWER":
				return document.documentData.countReviewers || "";
			default:
				return "";
		}
	};
	const handleClick = () => {
		onSelectDocument(document, column.id);
	};
	const isArchived = document.documentData.documentStatus === "ARCHIVED";
	return (
		<div
			className={styles.container}
			style={{ backgroundColor: getBgColor(selectedDocument, document.id, isArchived) }}
		>
			{!hasPermission(Permissions.PROJECT_MEMBER) && (
				<CustomTooltip arrow title={isArchived ? translate("canvas-document-details.select.tooltip") : ""}>
					<Checkbox
						checked={checked}
						className={styles.buttonCheckBox}
						color="primary"
						disabled={isArchived}
						onClick={() => {
							onCheckDocument(document.id);
							onSelectDocuments(!document.checked, document.documentData.id);
						}}
					/>
				</CustomTooltip>
			)}
			<div className={styles.name} role="presentation" onClick={handleClick}>
				<CustomTooltip title={document.documentData.documentFilename}>
					<CustomButton fullWidth className={styles.nameButton} color="secondary" size="md">
						{document.documentData.documentName}
					</CustomButton>
				</CustomTooltip>
			</div>
			<div className={styles.toCmp}>
				<CustomIconButtonLink
					icon={icon.faTable}
					state={{ previous: location.pathname }}
					to={NavUtils.goToSmartReview({
						projectId,
						documentId: document.documentData.id,
						pageStart: 1,
						source: SOURCE_TYPES.CANVAS,
					})}
					tooltip={translate("canvas.document.tooltip.to-smart-view")}
				/>
			</div>
			{roles.map((role) => {
				const counter = getCounter(role);
				return (
					<div key={role} className={styles.counterContainer} style={{ width: `${30 / roles.length}%` }}>
						<CustomTooltip title={translateEnumDocumentUserRole(role)}>
							<div className={styles.counter}>
								<strong>{counter || "-"}</strong>
							</div>
						</CustomTooltip>
					</div>
				);
			})}
		</div>
	);
};

export default Document;
