import React from "react";
import { icon } from "../../../common/components";
import { isFeatureEnabled } from "../../../common/providers";
import {
	DSICanvas,
	DSICompany,
	DSIProjects,
	DSIUsers,
	DSIExportUser,
	DSIPlatform,
	DSISearchTemplates,
	DSIFlags,
	DSIPackages,
	DSIExportTemplates,
} from "../panels";

const TABS = [
	{
		tab: { icon: icon.faCog, title: "dsi.tab.tooltip.platform-management" },
		panel: () => <DSIPlatform />,
	},
	{
		tab: { icon: icon.faBuilding, title: "dsi.tab.tooltip.company-management" },
		panel: ({ companies, activeUsersInCompany, createBu, createCompany, getCompanyActiveUsers, updateCompany }) => (
			<DSICompany
				activeUsersInCompany={activeUsersInCompany}
				companies={companies}
				onCreateBu={createBu}
				onCreateCompany={createCompany}
				onSelectCompany={getCompanyActiveUsers}
				onUpdateCompany={updateCompany}
			/>
		),
	},
	{
		tab: { icon: icon.faUserFriends, title: "dsi.tab.tooltip.member-management" },
		panel: ({
			companies,
			userRolesInCompany,
			userRolesInProject,
			changeBlockUser,
			getCompanyUsers,
			createUser,
			regeneratePwdForUser,
			updateUser,
			updateUserRoleInProject,
			updateUserRoleInCompany,
			updateUserStatusInProject,
		}) => (
			<DSIUsers
				companies={companies}
				userRolesInCompany={userRolesInCompany}
				userRolesInProject={userRolesInProject}
				onChangeBlockUser={changeBlockUser}
				onCreateUser={createUser}
				onRegeneratePassword={regeneratePwdForUser}
				onSelectCompany={getCompanyUsers}
				onUpdateUser={updateUser}
				onUpdateUserRoleInCompany={updateUserRoleInCompany}
				onUpdateUserRoleInProject={updateUserRoleInProject}
				onUpdateUserStatusInProject={updateUserStatusInProject}
			/>
		),
	},
	{
		tab: { icon: icon.faStar, title: "dsi.tab.tooltip.member-export" },
		panel: ({ companies }) => <DSIExportUser companies={companies} />,
	},
	{
		tab: { icon: icon.faGavel, title: "dsi.tab.tooltip.project-management" },
		panel: ({
			companies,
			companyRolesInProject,
			userRolesInProject,
			getCompanyProjects,
			upateCompanyRoleInProject,
			addExistingPartnerToProject,
			addExistingUserToProject,
			updateUserStatusInProject,
		}) => (
			<DSIProjects
				companies={companies}
				companyRolesInProject={companyRolesInProject}
				userRolesInProject={userRolesInProject}
				onAddExistingPartnerToProject={addExistingPartnerToProject}
				onAddExistingUserToProject={addExistingUserToProject}
				onSelectCompany={getCompanyProjects}
				onUpateCompanyRoleInProject={upateCompanyRoleInProject}
				onUpdateUserStatusInProject={updateUserStatusInProject}
			/>
		),
	},
	{
		tab: { icon: icon.faFolderOpen, title: "dsi.tab.tooltip.document-management" },
		panel: ({ companies, getCompanyProjects }) => (
			<DSICanvas companies={companies} onSelectCompany={getCompanyProjects} />
		),
	},
	{
		tab: { icon: icon.faDraftingCompass, title: "dsi.tab.tooltip.search-templates" },
		panel: () => <DSISearchTemplates />,
	},
	{
		tab: { icon: icon.faFlag, title: "dsi.tab.tooltip.flag-management" },
		panel: () => <DSIFlags />,
	},
	{
		tab: { icon: icon.faCubes, title: "dsi.tab.tooltip.package-management" },
		panel: ({ companies }) => <DSIPackages platformCompanies={companies} />,
	},
	{
		tab: { icon: icon.faFileExport, title: "dsi.tab.tooltip.export-templates-management" },
		panel: ({ companies }) => <DSIExportTemplates companies={companies} />,
	},
];

export default TABS.filter((tab) => !tab.flag || isFeatureEnabled(tab.flag));
