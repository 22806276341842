import { MenuItem } from "@mui/material";
import React from "react";
import styles from "../MenuItem.module.css";

const MenuItemRelog = ({ company, menuItem, onHotRelog = null }) => {
	const isCompanySelected = menuItem.id === company.id;
	const handleHotRelog = () => {
		onHotRelog(menuItem);
	};
	const currentURL = window.location.href;
	const endsWithAdmin = currentURL.endsWith("/admin");
	return (
		<MenuItem
			className={`${styles.menuItem} ${isCompanySelected && styles["menuItem--selected"]}`}
			disabled={endsWithAdmin && menuItem.role !== "ADMIN"}
			disableRipple={isCompanySelected}
			translate="no"
			onClick={isCompanySelected ? null : handleHotRelog}
		>
			- {menuItem.name || menuItem.companyName}
		</MenuItem>
	);
};
export default MenuItemRelog;
