import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useMemo } from "react";
import { CustomIconButton, CustomTooltip, IconComponent, icon } from "../../../../common/components";
import styles from "./ExpandableRow.module.css";

const ExpandableRow = ({ addElement, indentLevel = 0, matchField, onExpand, row }) => {
	const isExpandable = useMemo(
		() => row.childrenCount > 0 || (Array.isArray(row.subThematic) && row.subThematic.length > 0),
		[row]
	);
	const handleExpand = () => {
		if (isExpandable) {
			onExpand(row);
		}
	};
	return (
		<Accordion
			classes={{
				root: styles.accordion__root,
			}}
			expanded={!!row.expanded}
			style={{
				marginLeft: 8,
			}}
			onChange={handleExpand}
		>
			<AccordionSummary
				classes={{
					root: styles.accordionSummary__root,
					content: styles.accordionSummary__content,
				}}
				style={{
					cursor: row.childrenCount > 0 ? "pointer" : "default",
				}}
			>
				<span className={styles.row__title}>
					{isExpandable && (
						<IconComponent
							className={styles.expandIcon}
							color="var(--shades-grey-600)"
							icon={(row.expanded && icon.faChevronDown) || icon.faChevronRight}
							size="sm"
						/>
					)}
					<CustomTooltip showWhenOverflow title={row[matchField]}>
						<span
							className={styles.row__label}
							style={{
								width: `${260 - indentLevel * 8}px`,
							}}
						>
							{row[matchField]}
						</span>
					</CustomTooltip>
				</span>
				<span aria-hidden className={styles.element__button} onClick={() => addElement(row)}>
					<CustomIconButton className={styles.element__icon} icon={icon.faPlusCircle} />
				</span>
			</AccordionSummary>
			<AccordionDetails
				classes={{
					root: styles.accordionDetails__root,
				}}
			>
				{Array.isArray(row.subThematic) &&
					row.subThematic.length > 0 &&
					row.subThematic.map((cat) => (
						<ExpandableRow
							addElement={addElement}
							indentLevel={indentLevel + 1}
							matchField={matchField}
							row={cat}
							onExpand={onExpand}
						/>
					))}
			</AccordionDetails>
		</Accordion>
	);
};

export default ExpandableRow;
