import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { CustomButton, CustomButtonLink } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { NavUtils } from "../../../../navigation";
import styles from "./EmptyState.module.css";

const EmptyState = ({ onOpenDocList }) => {
	const projectId = useSelector(({ context }) => context?.project?.id);
	const precedences = useSelector(({ srProjectDocument }) => srProjectDocument?.documents?.precedence);
	const isThereAnyDocument = useMemo(() => precedences.some((precedence) => precedence.count > 0), [precedences]);
	const qaTotalResult = useSelector(({ srDocument }) => srDocument.qaMetadata?.totalElements);
	const isThereAnotherFilter = useSelector(({ srProjectDocument }) => srProjectDocument.isThereAnotherFilter);

	const handleClick = () => {
		onOpenDocList();
	};
	return (
		<div className={styles.emptyState__wrapper}>
			<header className={styles.emptyState__header}>
				{(isThereAnotherFilter && qaTotalResult === 0 && translate("smart-review.empty-state.no-matches")) ||
					(isThereAnyDocument && translate("smart-review.no-document-selected")) ||
					translate("smart-review.no-document-in-project")}
			</header>
			<main className={styles.emptyState__body}>
				{!isThereAnyDocument && (
					<>
						{translate("smart-review.no-document-in-project.documents-access")}
						<a
							href={translate("common:redirection.documents-knowledge-base.link")}
							rel="noopener noreferrer"
							target="_blank"
						>
							{translate("smart-review.no-document-in-project.documents-access-2")}
						</a>
					</>
				)}
			</main>
			<footer className={styles.emptyState__footer}>
				{!isThereAnotherFilter && (
					<div>
						{(isThereAnyDocument && (
							<CustomButton size="md" variant="contained" onClick={handleClick}>
								{translate("smart-review.no-document-selected.button")}
							</CustomButton>
						)) || (
							<CustomButtonLink to={NavUtils.goToDocumentCenter(projectId)} variant="contained">
								{translate("smart-review.no-document-in-project.button")}
							</CustomButtonLink>
						)}
					</div>
				)}
			</footer>
		</div>
	);
};

export default EmptyState;
