import React from "react";
import { CustomButton } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import styles from "./SummaryBlock.module.css";

export default function SummaryBlock({ children, hideAction = false, onClickEdit, title = "" }) {
	return (
		<div>
			<div className={styles.header}>
				<span>{title}</span>
				{!hideAction && <CustomButton onClick={onClickEdit}>{translate("common:btn.edit")}</CustomButton>}
			</div>
			<div className={styles.block}>{children}</div>
		</div>
	);
}
