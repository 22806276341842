import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Divider } from "@mui/material";
import styles from "./DocumentDialog.module.css";
import { CustomDialog, SearchListContent } from "../../../../common/components";
import { translate } from "../../../../common/providers";

const initialState = (document) => {
	if (document) {
		return {
			name: document.name,
			ref: document.ref,
			ownerId: (document.owner && document.owner.id) || "",
			format: document.format,
			type: document.type,
			thematics: (document?.thematic && document.thematic.map((c) => c.id)) || [],
			phaseId: (document.phase && document.phase.id) || "",
		};
	}
	return { name: "", ref: "", format: "", type: "", thematics: [], phaseId: "", ownerId: "" };
};
const initialErrorState = { name: false, ref: false, type: false, format: false, owner: false };

const DocumentDialog = ({
	action,
	document = null,
	fbs = [],
	formats = [],
	onClose,
	onExpand,
	open,
	owners = [],
	phases = [],
	types = [],
}) => {
	const [errors, setErrors] = useState(initialErrorState);
	const [payload, setPayload] = useState(initialState(document));
	useEffect(() => {
		setPayload(initialState(document));
	}, [document]);

	const changeName = (name) => {
		if (!name || name.length < 3) {
			setErrors((prev) => ({ ...prev, name: true }));
		} else if (errors.name) {
			setErrors((prev) => ({ ...prev, name: false }));
		}
		setPayload((prev) => ({ ...prev, name }));
	};

	const changeMandatoryFields = (content, field) => {
		if (!content) {
			setErrors((prev) => ({ ...prev, [field]: true }));
		} else if (errors[field]) {
			setErrors((prev) => ({ ...prev, [field]: false }));
		}
		setPayload((prev) => ({ ...prev, [field]: content }));
	};

	const changeNullableFields = (content, field) => {
		setPayload((prev) => ({ ...prev, [field]: content }));
	};

	const reset = () => {
		setErrors(initialErrorState);
		setPayload(initialState(document));
	};
	const handleCheckCategory = (checkedElements) => {
		setPayload((prev) => ({ ...prev, thematics: checkedElements }));
	};

	const handleCloseAndAction = () => {
		const errs = {
			name: !(payload.name && payload.name.length > 2),
			ref: !payload.ref,
			owner: !payload.ownerId,
			type: !payload.type,
			format: !payload.format,
			phaseId: !payload.phaseId,
		};
		const hasError = Object.keys(errs).some((key) => errs[key]);
		if (!hasError) {
			action(payload);
			onClose();
		}
		setErrors(errs);
	};

	return (
		<CustomDialog
			disableBackdropClick
			displayCloseCross
			isForm
			open={open}
			title={
				document
					? translate("common:deliverables.document-dialog.doc-update")
					: translate("common:deliverables.document-dialog.doc-create")
			}
			onClose={onClose}
			onExited={reset}
			onSubmit={handleCloseAndAction}
		>
			<div className={styles["margin-bottom"]}>
				<TextField
					fullWidth
					required
					error={errors.name}
					helperText={errors.name && translate("common:error.required-with-min-data", { min: "3" })}
					label={translate("common:deliverables.document-dialog.doc-name")}
					size="small"
					value={payload.name || (document && document.name) || ""}
					variant="outlined"
					onChange={(e) => changeName(e.target.value)}
				/>
				<TextField
					fullWidth
					required
					select
					className={styles["dialog__select-field"]}
					error={errors.format}
					helperText={errors.format && translate("common:error.required")}
					label={translate("common:deliverables.document-dialog.format")}
					size="small"
					value={(payload && payload.format) || (document && document.format) || ""}
					variant="outlined"
					onChange={(e) => changeMandatoryFields(e.target.value, "format")}
				>
					{formats &&
						formats.map((format) => (
							<MenuItem key={format} value={format}>
								{format}
							</MenuItem>
						))}
				</TextField>
				<TextField
					fullWidth
					required
					select
					className={styles["dialog__select-field"]}
					error={errors.type}
					helperText={errors.type && translate("common:error.required")}
					label={translate("common:deliverables.document-dialog.type")}
					size="small"
					value={(payload && payload.type) || (document && document.type) || ""}
					variant="outlined"
					onChange={(e) => changeMandatoryFields(e.target.value, "type")}
				>
					{types &&
						types.map((type) => (
							<MenuItem key={type} value={type}>
								{type}
							</MenuItem>
						))}
				</TextField>
			</div>
			<div className={styles["margin-bottom"]} style={{ display: "flex" }}>
				<TextField
					fullWidth
					required
					error={errors.ref}
					helperText={errors.ref && translate("common:error.required")}
					label={translate("common:deliverables.document-dialog.ref-id")}
					size="small"
					value={payload.ref || (document && document.ref) || ""}
					variant="outlined"
					onChange={(e) => changeMandatoryFields(e.target.value, "ref")}
				/>
				<span style={{ margin: "0 8px" }} />
				<TextField
					fullWidth
					required
					select
					error={errors.owner}
					helperText={errors.owner && translate("common:error.required")}
					label={translate("common:deliverables.document-dialog.owner")}
					size="small"
					value={payload.ownerId || (document && document.owner && document.owner.id) || ""}
					variant="outlined"
					onChange={(e) => changeMandatoryFields(e.target.value, "ownerId")}
				>
					{owners.map((owner) => (
						<MenuItem key={owner.id} value={owner.id}>
							{owner.displayName}
						</MenuItem>
					))}
				</TextField>
			</div>
			<TextField
				fullWidth
				required
				select
				className={styles["margin-bottom"]}
				error={errors.phaseId}
				helperText={errors.phaseId && translate("common:error.required")}
				label={translate("common:deliverables.document-dialog.phase-selection")}
				size="small"
				value={payload.phaseId || (document && document.phase && document.phase.id) || ""}
				variant="outlined"
				onChange={(e) => changeNullableFields(e.target.value, "phaseId")}
			>
				{phases.map((phase) => (
					<MenuItem key={phase.id} value={phase.id}>
						{phase.name}
					</MenuItem>
				))}
			</TextField>
			<Divider className={styles["margin-bottom"]} />
			<div className={styles["dialog__content-title"]}>
				{translate("common:deliverables.document-dialog.fbs-selection")}
			</div>
			<SearchListContent
				isTree
				initChecked={(document?.thematics && document.thematics.map((c) => c.id)) || []}
				rowKey="id"
				rowLabelKey="name"
				rows={fbs}
				rowTooltipKey="name"
				subRowCounter="childrenCount"
				subRowKey="subThematic"
				onClickRow={handleCheckCategory}
				onExpand={onExpand}
			/>
		</CustomDialog>
	);
};
export default DocumentDialog;
