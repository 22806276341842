import React from "react";
import { CustomButton } from "../../../../../../common/components";
import styles from "./ButtonOption.module.css";

export default function ButtonOption({ buttonDisabled = false, buttonText, onButtonClick, text }) {
	return (
		<div className={styles.option}>
			<span className={styles.option__text}>{text}</span>
			<span className={styles.option__control}>
				<CustomButton color="primary" disabled={buttonDisabled} onClick={onButtonClick}>
					{buttonText}
				</CustomButton>
			</span>
		</div>
	);
}
