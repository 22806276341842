import React, { useEffect, useState } from "react";
import styles from "./CustomRating.module.css";
import { icon, CustomIconButton } from "..";

export default function CustomRating({
	customStyles,
	iconName,
	iconNameEmpty,
	iconNameRemove,
	labels = [],
	nullable = false,
	onChange,
	onRemove,
	value, // starts from 1, index +1 from labels
}) {
	const [hover, setHover] = useState(-1);
	const isActive = onChange && typeof onChange === "function";
	const handleChange = (newValue) => {
		if (!nullable && !newValue) {
			return;
		}
		if (typeof onRemove === "function" && hover === value - 1) {
			onRemove();
		} else if (isActive) {
			onChange(newValue);
		}
	};
	useEffect(() => {
		if (value !== null || value !== undefined) {
			setHover(-1);
		}
	}, [value]);
	const styleRating = () => {
		if (typeof onRemove === "function" && hover === value - 1) {
			return customStyles["icon-remove"];
		}
		return customStyles[`icon-${Math.ceil((hover > -1 && hover + 1) || value)}`];
	};
	const handleIcon = (index) => {
		if (typeof onRemove === "function" && hover === value - 1 && index === hover) {
			return icon[iconNameRemove];
		}
		return (
			(((hover > -1 && hover >= index) || (value && value - 1 >= index && hover === -1)) && icon[iconName]) ||
			icon[iconNameEmpty]
		);
	};
	return (
		<div className={styles.main}>
			{Array.isArray(labels) &&
				labels.length > 1 &&
				labels.map((label, index) => (
					<div
						key={label}
						className={styles.rating}
						onMouseEnter={() => {
							if (isActive) {
								setHover(index);
							}
						}}
						onMouseLeave={() => {
							if (isActive) {
								setHover(-1);
							}
						}}
					>
						{iconName && (
							<CustomIconButton
								btnClassName={`${styles.icon} ${!isActive && styles["icon--inactive"]}`}
								className={styles.icon}
								data-testid="risk.level.rating"
								fontSize="inherit"
								icon={handleIcon(index)}
								iconClassName={`${
									(((hover > -1 && hover >= index) ||
										(value && value - 1 >= index && hover === -1)) &&
										styleRating()) ||
									""
								}`}
								tooltip={label}
								onClick={() => handleChange(index + 1)}
							/>
						)}
					</div>
				))}
		</div>
	);
}
