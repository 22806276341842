import React from "react";
import { useSelector } from "react-redux";
import { CopyTableToClipboardButton, CopyToClipboardButton, RedressButton, UpdateSentenceTypeButton } from ".";
import { hasUpdateRights } from "../../../../../../utils/utils";

export default function ActionSublayer({ className = "", hoveredBlock, onUpdateHoveredArea }) {
	const roles = useSelector(({ srDocument }) => srDocument.roles);
	return (
		<div className={className}>
			<CopyTableToClipboardButton hoveredBlock={hoveredBlock} />
			<CopyToClipboardButton hoveredBlock={hoveredBlock} />
			{hasUpdateRights(roles) && (
				<>
					<RedressButton hoveredBlock={hoveredBlock} />
					<UpdateSentenceTypeButton hoveredBlock={hoveredBlock} onUpdateHoveredArea={onUpdateHoveredArea} />
				</>
			)}
		</div>
	);
}
