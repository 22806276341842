import React from "react";
import { translate } from "../../../providers";
import ErrorMessage from "../error-message/ErrorMessage";
import styles from "./NotFound.module.css";

export default function NotFound() {
	return (
		<div className={styles.notFound__container} data-testid="notFound">
			<ErrorMessage
				content={translate("common:empty-state.not-found.message")}
				title={translate("common:empty-state.not-found.title")}
			/>
		</div>
	);
}
