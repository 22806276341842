import React from "react";
import { Tab } from "@mui/material";
import styles from "./ViewTab.module.css";
import { IconComponent } from "../..";

export default function ViewTab({
	"data-level": dataLevel,
	className = "",
	iconName,
	key = "",
	label,
	onChange,
	disabled = false,
	selected = false,
	textColor,
	value,
}) {
	const icon =
		(iconName && (
			<IconComponent
				className={styles.viewTab__icon}
				color={selected ? "var(--color-blue)" : "var(--color-dark-grey-1)"}
				icon={iconName}
			/>
		)) ||
		null;
	const lbl = icon ? ` ${label}` : label;
	return (
		<Tab
			key={key}
			disableFocusRipple
			disableRipple
			classes={{ root: styles.viewTab, wrapper: styles.viewTab__wrapper }}
			className={className}
			data-level={dataLevel}
			disabled={disabled}
			icon={icon}
			iconPosition="start"
			label={lbl}
			selected={selected}
			textColor={textColor}
			value={value}
			onChange={onChange}
		/>
	);
}
