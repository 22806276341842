import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { translate } from "../../../../common/providers";
import styles from "./QAFilters.module.css";
import { icon, IconComponent } from "../../../../common/components";

const QAFilters = ({ onChangeStatus, filters }) => {
	const [selectedIndex, setSelectedIndex] = useState(0);

	useEffect(() => {
		if (!filters.status) {
			setSelectedIndex(0);
		}
	}, [filters.status]);

	const handleListItemClick = (e, status, index) => {
		setSelectedIndex(index);
		onChangeStatus(status);
	};

	return (
		<div className={styles.root}>
			<List aria-label="main mailbox folders" component="nav">
				<ListItem
					button
					classes={{ root: styles.ListItem, selected: styles.selected }}
					selected={selectedIndex === 0}
					onClick={(e) => handleListItemClick(e, null, 0)}
				>
					<ListItemIcon>
						<IconComponent
							color={selectedIndex === 0 ? "var(--color-blue)" : "var(--color-dark-grey-1)"}
							icon={icon.faGlobe}
							size="lg"
						/>
					</ListItemIcon>
					<ListItemText primary={translate("qa-journal.qa-filters.qa-filters.list-item.type.all")} />
				</ListItem>
				<ListItem
					button
					classes={{ root: styles.ListItem, selected: styles.selected }}
					selected={selectedIndex === 1}
					onClick={(e) => handleListItemClick(e, "DRAFT", 1)}
				>
					<ListItemIcon>
						<IconComponent
							color={selectedIndex === 1 ? "var(--color-blue)" : "var(--color-dark-grey-1)"}
							icon={icon.faEnvelopeOpen}
							size="lg"
						/>
					</ListItemIcon>
					<ListItemText primary={translate("qa-journal.qa-filters.qa-filters.list-item.type.draft")} />
				</ListItem>
				<ListItem
					button
					classes={{ root: styles.ListItem, selected: styles.selected }}
					selected={selectedIndex === 2}
					onClick={(e) => handleListItemClick(e, "SENT", 2)}
				>
					<ListItemIcon>
						<IconComponent
							color={selectedIndex === 2 ? "var(--color-blue)" : "var(--color-dark-grey-1)"}
							icon={icon.faHourglassRegular}
							size="lg"
						/>
					</ListItemIcon>
					<ListItemText
						primary={translate("qa-journal.qa-filters.qa-filters.list-item.type.waiting-for-reply")}
					/>
				</ListItem>
				<ListItem
					button
					classes={{ root: styles.ListItem, selected: styles.selected }}
					selected={selectedIndex === 3}
					onClick={(e) => handleListItemClick(e, "ANSWERED", 3)}
				>
					<ListItemIcon>
						<IconComponent
							color={selectedIndex === 3 ? "var(--color-blue)" : "var(--color-dark-grey-1)"}
							icon={icon.faInbox}
							size="lg"
						/>
					</ListItemIcon>
					<ListItemText primary={translate("qa-journal.qa-filters.qa-filters.list-item.type.replied")} />
				</ListItem>
				<ListItem
					button
					classes={{ root: styles.ListItem, selected: styles.selected }}
					selected={selectedIndex === 4}
					onClick={(e) => handleListItemClick(e, "FROM_COMPETITION", 4)}
				>
					<ListItemIcon>
						<IconComponent
							color={selectedIndex === 4 ? "var(--color-blue)" : "var(--color-dark-grey-1)"}
							icon={icon.faUsers}
							size="lg"
						/>
					</ListItemIcon>
					<ListItemText
						primary={translate("qa-journal.qa-filters.qa-filters.list-item.type.from-competition")}
					/>
				</ListItem>
			</List>
		</div>
	);
};
export default QAFilters;
