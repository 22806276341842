import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	SearchCardService,
	CategoryService,
	RequirementParameterService,
	SearchCardTemplateService,
} from "../../../../api";
import { SEARCH_TYPES } from "../../../../common/constants/search-types";
import { VISIBILITY } from "../../../../common/constants";
import { generateFilters } from "../../../../common/components";

function getProjectId(getState) {
	const {
		context: {
			project: { id: projectId },
		},
	} = getState();
	return projectId;
}
function getFilters(getState) {
	const {
		mySearch: { filters },
	} = getState();
	return { ...filters };
}
function isCurrentUserSearchCreator({ creatorId }, { getState }) {
	const {
		context: {
			user: { id },
		},
	} = getState();
	return id === creatorId;
}

export const getTypeParameters = createAsyncThunk(
	"mySearch/getTypeParameters",
	(token) => RequirementParameterService.getReqTypes(token),
	{
		condition: (_, { getState }) => {
			const {
				mySearch: { types },
			} = getState();
			return !Array.isArray(types) || types.length === 0;
		},
	}
);

export const getCriticalityParameters = createAsyncThunk(
	"mySearch/getCriticalityParameters",
	(token) => RequirementParameterService.getCriticality(token),
	{
		condition: (_, { getState }) => {
			const {
				mySearch: { criticalities },
			} = getState();
			return !Array.isArray(criticalities) || criticalities.length === 0;
		},
	}
);

export const getNegotiabilityParameters = createAsyncThunk(
	"mySearch/getNegotiabilityParameters",
	(token) => RequirementParameterService.getNegotiability(token),
	{
		condition: (_, { getState }) => {
			const {
				mySearch: { negotiabilities },
			} = getState();
			return !Array.isArray(negotiabilities) || negotiabilities.length === 0;
		},
	}
);

export const getCategoriesParents = createAsyncThunk(
	"mySearch/getCategoriesParents",
	(token, { getState }) => {
		const projectId = getProjectId(getState);
		const filters = getFilters(getState);
		return CategoryService.getTreeParentsForWithFilters(
			{ projectId },
			{ filters: { ...filters, Type: "InformationSearchFilterDTO" } },
			token
		);
	},
	{
		condition: (_, { getState }) => {
			const {
				mySearch: { categories },
			} = getState();
			return !Array.isArray(categories) || categories.length === 0;
		},
	}
);

export const getCategoriesChildren = createAsyncThunk("mySearch/getCategoriesChildren", ({ token, parentId }) =>
	CategoryService.getTreeChildrenForProject({ parentId, details: false }, token)
);

export const getAllSearches = createAsyncThunk(
	"mySearch/getAllSearches",
	({ filters = { visibility: VISIBILITY.PRIVATE }, token }, { getState }) => {
		const projectId = getProjectId(getState);
		return SearchCardService.getAll({ projectId }, { filters, page: 0, limit: 20 }, token);
	}
);

export const search = createAsyncThunk("mySearch/search", ({ filters, page, limit, sort, token }, { getState }) => {
	const projectId = getProjectId(getState);
	const {
		mySearch: { isFilterTocOut },
	} = getState();
	return SearchCardService.search(
		{ projectId },
		{ isFilterTocOut, ...(filters || {}), Type: SEARCH_TYPES.card },
		page,
		limit,
		sort,
		token
	);
});

export const countResults = createAsyncThunk("mySearch/countResults", ({ filters, token }, { getState }) => {
	const projectId = getProjectId(getState);
	const {
		mySearch: { isFilterTocOut },
	} = getState();
	return SearchCardService.countResults(
		{ projectId },
		{ isFilterTocOut, ...(filters || {}), Type: SEARCH_TYPES.card },
		token
	);
});

export const createSearch = createAsyncThunk(
	"mySearch/createSearch",
	({ title, description, visibility, token }, { getState }) => {
		const projectId = getProjectId(getState);
		const {
			mySearch: { filters, totalMatches, isFilterTocOut },
		} = getState();
		const payload = {
			title,
			description,
			visibility,
			filtersResults: {
				resultCount: totalMatches,
				filters: generateFilters({
					isFilterTocOut,
					...filters,
				}),
			},
		};
		return SearchCardService.create({ projectId }, payload, token);
	}
);

// Must pass creatorId in payload
export const updateSearchFilters = createAsyncThunk(
	"mySearch/updateSearchFilters",
	({ searchCardId, token }, { getState }) => {
		const {
			mySearch: { isFilterTocOut, filters, totalMatches },
		} = getState();
		const payload = {
			resultCount: totalMatches,
			filters: generateFilters({ ...filters, isFilterTocOut }),
		};
		return SearchCardService.updateFilters({ searchCardId }, payload, token);
	},
	{
		condition: isCurrentUserSearchCreator,
	}
);

// Must pass creatorId in payload
export const deleteSearch = createAsyncThunk(
	"mySearch/deleteSearch",
	({ searchCardId, token }) => SearchCardService.delete({ searchCardId }, token),
	{
		condition: isCurrentUserSearchCreator,
	}
);

// Must pass creatorId in payload
export const editSearch = createAsyncThunk(
	"mySearch/editSearch",
	({ searchCardId, payload, token }) => SearchCardService.update({ searchCardId }, payload, token),
	{
		condition: isCurrentUserSearchCreator,
	}
);

export const getSortingAlgorithms = createAsyncThunk("mySearch/getSortingAlgorithms", (token) =>
	SearchCardTemplateService.getSortingAlgorithms(token)
);
