import { MenuItem } from "@mui/material";
import React from "react";
import { translate } from "../../../../common/providers";
import styles from "../MenuItem.module.css";

const MenuItemSelectable = ({ autoTranslate = "yes", menuItem, onIsSelected = null, onSelect }) => {
	const isSelected = onIsSelected && onIsSelected(menuItem.to || menuItem.name);
	const handleSelect = () => {
		if (!isSelected) {
			onSelect(menuItem);
		}
	};
	return (
		<MenuItem
			className={`${styles.menuItem} ${isSelected && styles["menuItem--selected"]}`}
			disableRipple={isSelected}
			translate={autoTranslate}
			onClick={handleSelect}
		>
			{translate(menuItem.name)}
		</MenuItem>
	);
};
export default MenuItemSelectable;
