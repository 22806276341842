import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { NormsFavoriteService, NormsPendingService, NormsProjectService, NormsService } from "../../../api";
import { createNotification } from "../../../common/components";
import { translate } from "../../../common/providers";
import { displayTrustLevel } from "../utils/utils";

function changeNormFavStatus(normsInProject, normId, isFavorite) {
	if (Array.isArray(normsInProject) && normsInProject.some((norm) => norm.normId === normId)) {
		normsInProject.find((norm) => norm.normId === normId).isFavorite = isFavorite;
	}
}

function getProjectId(getState) {
	const {
		normsCenter: { projectId },
	} = getState();
	return projectId;
}

const initialState = {
	projectId: 0,
	sortingKeys: [],
	normsInProject: [],
	tempNormsInProject: [],
	pendingDetections: [],
	tempPendingDetections: [],
	trustLevels: [],
	totalNormsInProject: 0,
	totalNormsResult: 0,
	tempTotalNormsResult: 0,
	tempTotalPendingResult: 0,
	totalPendingDetections: 0,
	totalPendingResult: 0,
	isLoadingNorms: false,
	isLoadingPendingNorms: false,
	page: 0,
	limit: 25,
	orderBy: "",
	dir: "",
	filters: {},
	pendingStatus: "PENDING",
};

export const getNormsInProject = createAsyncThunk(
	"normsCenter/getNormsInProject",
	({ token, page, limit, filters = null, orderBy, dir }, { getState }) => {
		const projectId = getProjectId(getState);
		return NormsProjectService.getNorms({ projectId }, token, { ...(filters || {}), page, limit, orderBy, dir });
	}
);

export const getPendingDetections = createAsyncThunk(
	"normsCenter/getPendingDetections",
	({ token, pendingStatus, page, limit, filters = null, orderBy, dir }, { getState }) => {
		const projectId = getProjectId(getState);
		return NormsPendingService.getPendingInProject({ projectId }, token, {
			...(filters || {}),
			status: pendingStatus,
			page,
			limit,
			orderBy,
			dir,
		});
	}
);

export const getSortingKeys = createAsyncThunk("normsCenter/getSortingKeys", (token) =>
	NormsPendingService.getSortingKeys(token)
);

export const getTrustLevels = createAsyncThunk("normsCenter/getTrustLevels", (token) =>
	NormsPendingService.getTrustLevels(token)
);

export const getCounters = createAsyncThunk("normsCenter/getCounters", (token, { getState }) => {
	const projectId = getProjectId(getState);
	return Promise.all([
		NormsProjectService.getTotal({ projectId }, token),
		NormsPendingService.getTotal({ projectId }, token),
	]);
});

export const changePendingNormsStatus = createAsyncThunk(
	"normsCenter/changePendingNormsStatus",
	({ status, pendingIds, token }) => NormsPendingService.update({ status, pendingNormsIds: pendingIds }, token)
);
export const changeNormFavoriteStatus = createAsyncThunk(
	"normsCenter/changeNormFavoriteStatus",
	({ normId, isFavorite, token }, { getState }) => {
		const projectId = getProjectId(getState);
		if (isFavorite) {
			return NormsFavoriteService.delete({ normId, projectId }, token);
		}
		return NormsFavoriteService.add({ normId, projectId }, token);
	}
);
export const addNormToProject = createAsyncThunk("normsCenter/addNormToProject", ({ normId, token }, { getState }) => {
	const projectId = getProjectId(getState);
	return NormsProjectService.addToProject({ normId, projectId }, token);
});
export const addCustomNorm = createAsyncThunk("normsCenter/addCustomNorm", ({ payload, token }, { getState }) => {
	const projectId = getProjectId(getState);
	return NormsProjectService.addCustomNorm({ projectId }, payload, token);
});
export const deleteCustomNorm = createAsyncThunk(
	"normsCenter/deleteCustomNorm",
	({ normId, token }) => NormsService.delete({ normId }, token),
	{ condition: ({ normId }) => !!normId }
);
export const updateNorm = createAsyncThunk("normsCenter/updateNorm", ({ normId, payload, token }, { getState }) => {
	const projectId = getProjectId(getState);
	return NormsProjectService.update({ normId, projectId }, payload, token);
});

const normsCenterSlice = createSlice({
	name: "normsCenter",
	initialState,
	reducers: {
		setProjectId: (state, { payload }) => ({ ...state, projectId: payload }),
		setOrderBy: (state, { payload }) => ({ ...state, orderBy: payload }),
		setDir: (state, { payload }) => ({ ...state, dir: payload }),
		setPage: (state, { payload }) => ({ ...state, page: payload }),
		setLimit: (state, { payload }) => ({ ...state, limit: payload }),
		setPendingStatus: (state, { payload }) => ({ ...state, pendingStatus: payload }),
		setFilters: (state, { payload }) => ({ ...state, filters: payload }),
		setTotalNormsInProject: (state, { payload }) => ({ ...state, totalNormsInProject: payload }),
		setTotalNormsResult: (state, { payload }) => ({ ...state, totalNormsResult: payload }),
		setTotalPendingResult: (state, { payload }) => ({ ...state, totalPendingResult: payload }),
		setNormsInProject: (state, { payload }) => ({ ...state, normsInProject: payload }),
		setPendingDetections: (state, { payload }) => ({ ...state, pendingDetections: payload }),
		setTotalPendingDetections: (state, { payload }) => ({ ...state, totalPendingDetections: payload }),
		updatePendingDetection: (state, { payload }) => ({
			...state,
			pendingDetections: state.pendingDetections.map((x) =>
				payload.ids.includes(x.pendingId) ? { ...x, ...payload.update } : x
			),
		}),
	},
	extraReducers: (builder) => {
		builder.addCase(getNormsInProject.pending, (state) => ({
			...state,
			isLoadingNorms: true,
		}));
		builder.addCase(getNormsInProject.rejected, (state) => ({
			...state,
			isLoadingNorms: false,
		}));
		builder.addCase(getNormsInProject.fulfilled, (state, action) => {
			const { filters, displayResults } = action.meta.arg;
			const { contents, totalElements, pageNumber } = action.payload;
			const newState = {
				...state,
				[displayResults ? "normsInProject" : "tempNormsInProject"]: contents,
				tempTotalNormsResult: totalElements,
				totalNormsResult: displayResults ? totalElements : state.totalNormsResult,
				page: pageNumber,
				isLoadingNorms: false,
			};
			if (!filters) {
				newState.totalNormsInProject = totalElements;
			}
			return newState;
		});
		builder.addCase(getPendingDetections.pending, (state) => ({
			...state,
			isLoadingPendingNorms: true,
		}));
		builder.addCase(getPendingDetections.rejected, (state) => ({
			...state,
			isLoadingPendingNorms: false,
		}));
		builder.addCase(getPendingDetections.fulfilled, (state, action) => {
			const { filters, pendingStatus, displayResults, orderBy, dir } = action.meta.arg;
			const { contents, totalElements, pageNumber } = action.payload;
			const newState = {
				...state,
				[displayResults ? "pendingDetections" : "tempPendingDetections"]: contents,
				tempTotalPendingResult: totalElements,
				totalPendingResult: displayResults ? totalElements : state.totalPendingResult,
				page: pageNumber,
				isLoadingPendingNorms: false,
				orderBy,
				dir,
			};
			if (!filters && pendingStatus !== "TRASH") {
				newState.totalPendingDetections = totalElements;
			}
			return newState;
		});
		builder.addCase(getSortingKeys.fulfilled, (state, { payload }) => ({ ...state, sortingKeys: payload }));
		builder.addCase(getTrustLevels.fulfilled, (state, { payload }) => ({
			...state,
			trustLevels: payload.map((lvl) => ({ name: lvl, displayName: displayTrustLevel(lvl) })),
		}));
		builder.addCase(getCounters.fulfilled, (state, { payload }) => ({
			...state,
			totalNormsInProject: payload[0]?.total || 0,
			totalPendingDetections: payload[1]?.total || 0,
		}));
		builder.addCase(changePendingNormsStatus.fulfilled, (state, { meta }) => {
			const { status } = meta.arg;
			if (status === "VALIDATED") {
				state.totalNormsInProject += 1;
			}
		});
		builder.addCase(changeNormFavoriteStatus.fulfilled, (state, { meta, payload }) => {
			const { normsInProject } = state;
			const { normId } = meta.arg;
			const { isFavorite } = payload;
			changeNormFavStatus(normsInProject, normId, isFavorite);
		});
		builder.addCase(updateNorm.fulfilled, (state, { payload, meta }) => {
			const { normId } = meta.arg;
			const { name, isFavorite, date } = payload;
			state.normsInProject = state.normsInProject.map((norm) => {
				if (norm.normId === normId) {
					return { ...norm, name, released: date, isFavorite };
				}
				return norm;
			});
		});
		builder.addCase(addNormToProject.fulfilled, (state, { payload }) => {
			const { name } = payload;
			createNotification({
				type: "success",
				message: translate("norms-center.add-norm.notification.success", { name }),
			});
			return state;
		});
		builder.addMatcher(
			({ type }) => typeof type === "string" && type.endsWith("rejected"),
			(_, { error }) => {
				console.error(error.message);
			}
		);
	},
});

export const {
	setTotalNormsInProject,
	setTotalPendingDetections,
	setProjectId,
	setOrderBy,
	setDir,
	setPage,
	setLimit,
	setFilters,
	setPendingStatus,
	updatePendingDetection,
	setNormsInProject,
	setPendingDetections,
	setTotalNormsResult,
	setTotalPendingResult,
} = normsCenterSlice.actions;
export default normsCenterSlice.reducer;
