import React from "react";
import { useSelector } from "react-redux";
import { InputAdornment } from "@mui/material";
import {
	Flags,
	isFeatureEnabled,
	isSegFeatureEnabled,
	SegFlags,
	translate,
} from "../../../../../../../common/providers";
import { icon, IconComponent, InfiniteListSelector, Selector } from "../../../../../../../common/components";
import { DashboardFilters } from "../..";
import styles from "./OptionHeader.module.css";
import SelectDropDownRow from "./select-drop-down-list/SelectDropDownRow";

const displayValue = (item, isMyEntity) => {
	if (isMyEntity) {
		return (
			<span>
				{item}
				<IconComponent className={styles.item__icon} icon={icon.faBuilding} />
			</span>
		);
	}
	return item;
};
const displayOwnItem = (item, isMyEntity) => {
	if (isMyEntity) {
		return (
			<span>
				{item}
				<span className={styles.item__me}>{translate("dashboard.my-dashboard.option-header.my-entity")}</span>
			</span>
		);
	}
	return item;
};
const rowRenderer =
	({ userMe }) =>
	({ loadedRow, onClick }) =>
		<SelectDropDownRow key={loadedRow.id} myId={userMe?.id} user={loadedRow} onClick={onClick} />;

export default function OptionHeader({
	companies,
	details,
	filters,
	infiniteListRequest,
	onChangeGroupByCompany,
	onChangeGroupByUser,
	setExternalStates,
	users = null,
}) {
	const { company, userMe } = useSelector(({ context }) => ({
		company: context.company,
		userMe: context.user,
	}));
	const endAdornmentRenderer = (isDropDownOpen) => (
		<InputAdornment position="end">
			{users && filters.user?.id === userMe.id && (
				<IconComponent className={styles.myAccountLogo} icon={icon.faUser} size="md" />
			)}
			<IconComponent
				color="var(--color-dark-grey-1)"
				icon={(isDropDownOpen && icon.faCaretUp) || icon.faCaretDown}
				size="md"
			/>
		</InputAdornment>
	);
	const row = rowRenderer({ userMe });
	return (
		<>
			<div className={styles.optionHeader}>
				<DashboardFilters details={details} />
				<div>
					{onChangeGroupByUser && isSegFeatureEnabled(SegFlags.TEAM) && (
						<InfiniteListSelector
							callOnResponse={setExternalStates}
							classes={{ input: styles.input__select }}
							className={styles.optionHeader__selector}
							classNameInfiniteList={styles.selector__infiniteList}
							endAdornmentRenderer={endAdornmentRenderer}
							infiniteListRequest={infiniteListRequest}
							rowRenderer={row}
							value={filters.user?.displayName || filters.user?.email || ""}
							onClick={onChangeGroupByUser}
						/>
					)}
					{onChangeGroupByCompany &&
						isSegFeatureEnabled(SegFlags.TEAM) &&
						isFeatureEnabled(Flags.PARTNERS) &&
						isSegFeatureEnabled(SegFlags.PARTNERS) && (
							<Selector
								className={styles.optionHeader__selector}
								fallbackField="name"
								field="name"
								itemRenderer={(item) => displayOwnItem(item, item === company.name)}
								items={companies}
								value={filters?.company || ""}
								valueRenderer={(item) => displayValue(item.name, item.id === company.id)}
								onChange={onChangeGroupByCompany}
							/>
						)}
				</div>
			</div>
		</>
	);
}
