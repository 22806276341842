import React, { useState } from "react";
import Section from "../section/Section";
import styles from "./RelatedDeliverables.module.css";
import { CustomTooltip } from "../../../../../../../common/components";
import { isNonEmptyArray } from "../../../../../../../common/utils";
import { useApi } from "../../../../../../../common/hooks";
import { SppRequirementService } from "../../../../../../../api";

const RelatedDeliverables = ({ deliverables, projectId, requirementId }) => {
	const { call: getParagraphsOfDeliverable } = useApi(SppRequirementService.getParagraphsOfDeliverable);
	const [paragraphs, setParagraphs] = useState([]);
	const handleCollapse = (id) => {
		if (!isNonEmptyArray(paragraphs)) {
			getParagraphsOfDeliverable({ projectId, requirementId, deliverableId: id })
				.then((data) => {
					setParagraphs(data);
				})
				.catch(console.error);
		}
	};
	return (
		<div>
			{isNonEmptyArray(deliverables) &&
				deliverables.map((deliverable) => (
					<Section
						key={deliverable.id}
						className={styles.sectionHeader}
						elevation={0}
						title={deliverable.name}
						onCollapse={() => handleCollapse(deliverable.id)}
					>
						<div>
							{isNonEmptyArray(paragraphs) &&
								paragraphs.map((paragraph) => (
									<div key={paragraph.id} className={styles.paragraphContainer}>
										<CustomTooltip showWhenOverflow title={paragraph.title}>
											<div className={styles.title}>{paragraph.title}</div>
										</CustomTooltip>
										<div className={styles.content}>{paragraph.content}</div>
									</div>
								))}
						</div>
					</Section>
				))}
		</div>
	);
};

export default RelatedDeliverables;
