import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
	SidePanel,
	SidePanelContent,
	CustomDialog,
	SidePanelContainer,
	SidePanelHeader,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import { ApiService, NormsPendingService } from "../../../../../../api";
import { Detections, DetectionHeader, MatchedNorms } from "..";
import SimilarDetectionBody from "../detections/detection-table/SimilarDetectionBody";
import { getCounters } from "../../../../slice/normsCenterSlice";

export default function SimilarDetections({
	open,
	pendingId,
	textDetected,
	onOpenSimilarDetections,
	onRefreshPending,
	onActive: onActiveFromParent = null,
}) {
	const [coordinates, setCoordinates] = useState([]);
	const [selectedInfo, setSelectedInfo] = useState(null);
	const [similarDetections, setSimilarDetections] = useState([]);
	const [isLoadingSimilar, setIsLoadingSimilar] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [validateSubmit, setValidateSubmit] = useState(true);
	const [selectedRow, setSelectedRow] = useState(null);
	const [checkedRows, setCheckedRows] = useState([]);
	const [openPreviewDocument, setOpenPreviewDocument] = useState(false);
	const [openMatchedNorms, setOpenMatchedNorms] = useState(false);
	const cancelTokenSourceRef = useRef(null);
	const [openMultipleMatchedNorms, setOpenMultipleMatchedNorms] = useState(false);
	const handleOpenMultipleMatchedNorms = () => {
		setOpenMultipleMatchedNorms((prev) => !prev);
	};
	const dispatch = useDispatch();
	useEffect(() => {
		setCheckedRows([]);
	}, []);
	useEffect(() => {
		setCheckedRows([]);
		setValidateSubmit(false);
	}, [onOpenSimilarDetections]);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const fetchNormsCoordinates = (docId, pendingNormId) => {
		NormsPendingService.getNormsCoordinates({ pendingId: pendingNormId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setCoordinates(data.map((coord) => ({ ...coord, primary: coord.target })));
			})
			.catch((err) => {
				console.error(err);
			});
		NormsPendingService.getInformationFromPendingNorm(
			{ pendingId: pendingNormId, docId },
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				setSelectedInfo(data);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const fetchSimilarDetections = (id) => {
		setIsLoadingSimilar(true);
		NormsPendingService.getSimilars({ matchId: id }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setSimilarDetections(data);
				setIsLoadingSimilar(false);
			})
			.catch((err) => {
				console.error(err);
				setIsLoadingSimilar(false);
			});
	};
	useEffect(() => {
		setSimilarDetections([]);
		if (pendingId && open) {
			fetchSimilarDetections(pendingId);
		}
	}, [pendingId, open]);

	const changePendingNormsStatus = ({ status, pendingIds }) => {
		NormsPendingService.update({ status, pendingNormsIds: pendingIds }, cancelTokenSourceRef.current.token)
			.then(() => {
				fetchSimilarDetections(pendingId);
				onRefreshPending();
				dispatch(getCounters(cancelTokenSourceRef.current.token));
			})
			.catch((err) => console.error(err));
	};
	const handleDiscardPendingNorm = (pendingIds) => {
		changePendingNormsStatus({
			status: "TRASH",
			pendingIds: Array.isArray(pendingIds) ? pendingIds : [pendingIds],
		});
	};
	const handleOpenPreviewDocument = () => setOpenPreviewDocument((prev) => !prev);
	const handleOpenMatchedNorms = () => setOpenMatchedNorms((prev) => !prev);
	const handleDisplayDocument = (row) => {
		const { docId, pendingNormId } = row;
		setSelectedRow(row);
		fetchNormsCoordinates(docId, pendingNormId);
		handleOpenPreviewDocument();
	};
	const handleClickMatchedNorms = (row) => {
		setSelectedRow(row);
		handleOpenMatchedNorms();
	};
	const handleCheckRow = (checked, row) => {
		if (checked) {
			setCheckedRows((prev) => [...prev, row]);
		} else {
			setCheckedRows((prev) => prev.filter((r) => r.pendingNormId !== row.pendingNormId));
		}
	};
	const handleCheckAllRows = (e) => {
		const { checked } = e.target;
		if (checked) {
			setCheckedRows(similarDetections);
		} else {
			setCheckedRows([]);
		}
	};
	const handleValidatePendingNorm = (pendingIds) => {
		changePendingNormsStatus({
			status: "VALIDATED",
			pendingIds: Array.isArray(pendingIds) ? pendingIds : [pendingIds],
		});
	};
	const handleCloseDialog = () => {
		setOpenDialog((prev) => !prev);
	};

	const handleDiscardSelectedNorm = () => {
		handleCloseDialog();
		setValidateSubmit(false);
	};
	const handleValidateSelectedNorm = () => {
		handleCloseDialog();
		setValidateSubmit(true);
	};
	const handleSubmitValidateSelectedNorm = () => {
		handleValidatePendingNorm(checkedRows.map((row) => row.pendingNormId));
		setCheckedRows([]);
	};

	const handleSubmitDiscardSelectedNorm = () => {
		handleDiscardPendingNorm(checkedRows.map((row) => row.pendingNormId));
		setCheckedRows([]);
	};
	const handleSubmit = () => {
		if (validateSubmit) {
			handleSubmitValidateSelectedNorm();
		} else {
			handleSubmitDiscardSelectedNorm();
		}
		handleCloseDialog();
	};

	const handleUpdatePendingNorm = ({ ids, update }) => {
		setSimilarDetections((prev) => prev.map((x) => (ids.includes(x.pendingNormId) ? { ...x, ...update } : x)));
		setCheckedRows([]);
	};
	const handleEditSelectedNorm = (status) => {
		if (status === "TRASH") {
			handleDiscardSelectedNorm();
		} else if (status === "VALIDATED") {
			handleValidateSelectedNorm();
		}
	};
	return (
		<>
			<SidePanel open={open} size={100} onActive={onActiveFromParent} onClose={onOpenSimilarDetections}>
				{({ onActive }) => (
					<SidePanelContent
						title={translate("norms-center.similar-detections.title")}
						onActive={onActive}
						onClose={onOpenSimilarDetections}
					>
						<SidePanelContainer>
							<h5>
								{translate("norms-center.similar-detections.table-title", {
									text: textDetected,
								})}
							</h5>
							<SidePanelHeader>
								<DetectionHeader
									pendingStatus="SIMILAR"
									selectedInfo={selectedInfo}
									selectedRows={checkedRows.length}
									totalSearchResult={similarDetections.length}
									onEditSelectedNorm={handleEditSelectedNorm}
									onOpenMultipleMatchedNorms={handleOpenMultipleMatchedNorms}
								/>
							</SidePanelHeader>
							<Detections
								checkedRows={checkedRows}
								coordinates={coordinates}
								detections={similarDetections}
								headers={[
									{
										sortKey: "DOCUMENT",
										name: translate("norms-center.tab.detections.headers.document"),
									},
									{ name: translate("norms-center.tab.detections.headers.page") },
									{ name: translate("norms-center.tab.detections.headers.preview") },
									{
										name: translate("norms-center.tab.detections.headers.text-detected"),
									},
									{ sortKey: "NORM", name: translate("norms-center.tab.detections.headers.matched") },
									{ sortKey: "TRUST", name: translate("norms-center.tab.detections.headers.trust") },
									{ name: translate("norms-center.tab.detections.headers.valid") },
									{ name: translate("norms-center.tab.detections.headers.discard") },
								]}
								isLoading={isLoadingSimilar}
								openMatchedNorms={openMatchedNorms}
								openPreviewDocument={openPreviewDocument}
								selectedRow={{ ...selectedRow, textDetected }}
								TableBody={SimilarDetectionBody}
								tableBodyProps={{
									checked: checkedRows,
									detections: similarDetections,
									onCheckRow: handleCheckRow,
									onClickMatchedNorms: handleClickMatchedNorms,
									onDiscardNorm: handleDiscardPendingNorm,
									onDisplayDocument: handleDisplayDocument,
									onValidateNorm: handleValidatePendingNorm,
								}}
								totalSearchResult={similarDetections.length}
								onActive={onActive}
								onCheckAllRows={handleCheckAllRows}
								onOpenMatchedNorms={handleOpenMatchedNorms}
								onOpenPreviewDocument={handleOpenPreviewDocument}
								onUpdatePendingNorm={handleUpdatePendingNorm}
							/>
							<MatchedNorms
								open={openMultipleMatchedNorms}
								pendingIds={checkedRows.map((row) => row.pendingId)}
								onActive={onActive}
								onOpenMatchedNorms={handleOpenMultipleMatchedNorms}
								onUpdatePendingNorm={handleUpdatePendingNorm}
							/>
						</SidePanelContainer>
						<CustomDialog
							open={openDialog}
							submitLabel={
								validateSubmit
									? translate("common:btn.validate")
									: translate("norms-center.similar-detections.dialog.discard.submit")
							}
							title={
								validateSubmit
									? translate("norms-center.similar-detections.dialog.validate.title")
									: translate("norms-center.similar-detections.dialog.discard.title")
							}
							onClose={handleCloseDialog}
							onSubmit={handleSubmit}
						>
							{validateSubmit
								? translate("norms-center.tab.detections.similar.validate-selection", {
										count: checkedRows.length,
								  })
								: translate("norms-center.tab.detections.similar.discard-selection", {
										count: checkedRows.length,
								  })}
						</CustomDialog>
					</SidePanelContent>
				)}
			</SidePanel>
		</>
	);
}
