import React, { useCallback, useEffect, useRef } from "react";
import { CircularLoader } from "..";
import styles from "./ScrollContainer.module.css";

export default function ScrollContainer({
	anticipate = false,
	children,
	className = "",
	isLoading = false,
	onHitBottom,
	onHitTop,
	reversedScroll = false,
}) {
	const containerRef = useRef(null);
	const handleScroll = useCallback(() => {
		const { scrollHeight, clientHeight, scrollTop } = containerRef.current;
		if (
			(reversedScroll &&
				Math.abs(scrollHeight - (-scrollTop + clientHeight)) <= 1 + (anticipate ? 2 * clientHeight : 0) &&
				typeof onHitTop === "function") ||
			(!reversedScroll && scrollTop === 0 && typeof onHitTop === "function")
		) {
			onHitTop();
		} else if (
			(reversedScroll && scrollTop === 0 && typeof onHitBottom === "function") ||
			(!reversedScroll &&
				Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1 + (anticipate ? 2 * clientHeight : 0) &&
				typeof onHitBottom === "function")
		) {
			onHitBottom();
		}
	}, [onHitBottom, onHitTop, anticipate, reversedScroll]);
	useEffect(() => {
		const { scrollHeight, clientHeight, scrollTop } = containerRef.current;
		if (
			reversedScroll &&
			Math.abs(scrollHeight - (-scrollTop + clientHeight)) <= 1 + (anticipate ? 2 * clientHeight : 0) &&
			typeof onHitTop === "function"
		) {
			onHitTop();
		} else if (
			(scrollHeight === clientHeight ||
				Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1 + (anticipate ? 2 * clientHeight : 0)) &&
			typeof onHitBottom === "function"
		) {
			onHitBottom();
		}
	}, [onHitTop, children, onHitBottom, anticipate, reversedScroll]);
	return (
		<div
			ref={containerRef}
			className={`${styles.container} ${className}`}
			data-loading={isLoading}
			onScroll={handleScroll}
		>
			{children}
			{isLoading && (
				<div className={styles.loaderContainer}>
					<CircularLoader size={40} />
				</div>
			)}
		</div>
	);
}
