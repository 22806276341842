import { MenuItem } from "@mui/material";
import React, { useState, useRef } from "react";
import { icon, IconComponent, DropDown } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { checkValidity } from "../../..";
import MenuItemSelectable from "../menu-item-selectable/MenuItemSelectable";
import styles from "./MenuItemDropDown.module.css";

const MenuItemDropDown = ({ menuItem, onSelect, onIncludes, onIsSelected, onClose }) => {
	const [open, setOpen] = useState(false);
	const dropDownAnchorRef = useRef(null);
	const handleOpen = () => {
		if (open) {
			setOpen(false);
		} else {
			setOpen(menuItem.name);
		}
	};
	const handleHover = () => {
		setOpen(menuItem.name);
	};
	if (!Array.isArray(menuItem.subItems) || menuItem.subItems.length === 0 || !checkValidity(menuItem)) {
		return null;
	}
	const subItems = menuItem.subItems.filter((m) => checkValidity(m));
	return (
		subItems.length !== 0 && (
			<>
				<MenuItem
					key={menuItem.name}
					ref={dropDownAnchorRef}
					className={`${styles.dropDownButton__menuItem} ${
						onIncludes(subItems) && styles["dropDownButton__menuItem--selected"]
					}`}
					onClick={handleOpen}
					onMouseEnter={handleHover}
				>
					{translate(menuItem.name)}
					{subItems && (
						<IconComponent className={styles.dropDownButton__icon} icon={icon.faChevronRight} size="2xs" />
					)}
				</MenuItem>
				<DropDown
					ref={dropDownAnchorRef}
					open={open === menuItem.name}
					position="right-start"
					onClose={onClose}
				>
					<>
						{subItems.map((menu) => (
							<MenuItemSelectable
								key={menu.name}
								menuItem={menu}
								onIsSelected={onIsSelected}
								onSelect={onSelect}
							/>
						))}
					</>
				</DropDown>
			</>
		)
	);
};
export default MenuItemDropDown;
