export const VISIBILITY = { PRIVATE: "PRIVATE", PUBLIC: "PUBLIC" };
export const VISIBILITY_TEMPLATE = { PRIVATE: "PRIVATE", COMPANY: "COMPANY", AITENDERS: "AITENDERS" };
export const VISIBILITY_FOR_COPY = { PRIVATE: "PRIVATE", PUBLIC: "PUBLIC", AUTOMATION: "AUTOMATION" };
export const VISIBILITY_FOR_COPY_TEMPLATE = {
	PRIVATE: "PRIVATE",
	COMPANY: "COMPANY",
	AUTOMATION: "AUTOMATION",
};
export const VISIBILITY_TEMPLATE_CREATION = { PRIVATE: "PRIVATE", AUTOMATION: "COMPANY" };
export const VISIBILITY_TEMPLATE_AUTOMATION = { AUTOMATION: "AUTOMATION", AITENDERS: "AITENDERS" };
