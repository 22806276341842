import React, { useMemo } from "react";
import { TableContainer, Paper, Chip } from "@mui/material";
import {
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableRow,
	icon,
} from "../../../../../common/components";
import { hasCompanyPermission, hasPermission, Permissions, translate } from "../../../../../common/providers";
import styles from "./Details.module.css";

function Details({ document, onEdit, onRemove }) {
	const hasCreationPermission = useMemo(
		() =>
			hasCompanyPermission([Permissions.PROJECT_LEADER]) &&
			hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]),
		[]
	);
	return (
		<TableContainer component={Paper}>
			<CustomTable dense>
				<CustomTableBody>
					{hasCreationPermission && (
						<CustomTableRow>
							<CustomTableCell />
							<CustomTableCell align="right">
								<CustomIconButton icon={icon.faPen} size="md" onClick={onEdit} />
								<CustomIconButton icon={icon.faTrash} size="md" onClick={onRemove} />
							</CustomTableCell>
						</CustomTableRow>
					)}
					<CustomTableRow className={styles.tableRow}>
						<CustomTableCell>{translate("deliverables.details.th.ref")}</CustomTableCell>
						<CustomTableCell align="right" autoTranslate="no">
							{document.ref || "-"}
						</CustomTableCell>
					</CustomTableRow>
					<CustomTableRow className={styles.tableRow}>
						<CustomTableCell>{translate("deliverables.details.th.type")}</CustomTableCell>
						<CustomTableCell align="right">{document.type || "-"}</CustomTableCell>
					</CustomTableRow>
					<CustomTableRow className={styles.tableRow}>
						<CustomTableCell>{translate("deliverables.details.th.format")}</CustomTableCell>
						<CustomTableCell align="right">{document.format || "-"}</CustomTableCell>
					</CustomTableRow>
					<CustomTableRow className={styles.tableRow}>
						<CustomTableCell>{translate("deliverables.details.th.phase")}</CustomTableCell>
						<CustomTableCell align="right">
							{(document.phase && document.phase.name) || "-"}
						</CustomTableCell>
					</CustomTableRow>
					<CustomTableRow className={styles.tableRow}>
						<CustomTableCell>{translate("deliverables.details.th.owner")}</CustomTableCell>
						<CustomTableCell align="right" autoTranslate="no">
							{(document.owner && document.owner.displayName) || "-"}
						</CustomTableCell>
					</CustomTableRow>
					<CustomTableRow className={styles.tableRow}>
						<CustomTableCell>{translate("deliverables.details.th.fbs")}</CustomTableCell>
						<CustomTableCell align="right">
							{Array.isArray(document?.thematics)
								? document.thematics.map((c) => (
										<Chip
											key={c.id}
											label={c.name}
											style={{ height: "inherit", marginLeft: "2px" }}
										/>
								  ))
								: "-"}
						</CustomTableCell>
					</CustomTableRow>
				</CustomTableBody>
			</CustomTable>
		</TableContainer>
	);
}

export default Details;
