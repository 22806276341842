import { Divider } from "@mui/material";
import React from "react";
import styles from "./VerticalDivider.module.css";

export default function VerticalDivider({ inFlexContainer = false, className = "" }) {
	return (
		<Divider
			className={`${styles.verticalDivider} ${className}`}
			flexItem={inFlexContainer}
			orientation="vertical"
			variant="fullWidth"
		/>
	);
}
