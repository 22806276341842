import React, { useEffect, useMemo, useRef } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { CustomDialog, I18nTranslate } from "../../../../../../../../../common/components";
import { translate } from "../../../../../../../../../common/providers";
import { checkHardInformation } from "../../../../../../../utils/utils";
import { ApiService, MultiSelectionservice } from "../../../../../../../../../api";
import { updateNode } from "../../../../../../../slice/pdf/pdf-slice";
import { setSelectedRectangles, setSelectionBarCoordinates } from "../../../../../../../slice/document/document-slice";
import { REQ_STATUSES, SENTENCE_TYPE } from "../../../../../../../constants/constants";
import styles from "./ClassificationDialog.module.css";

const hasNoStatus = (content) =>
	[content.lastStatus, content.validatedStatus].every((x) => !x || x === REQ_STATUSES.UNREVIEWED);

export default function ClassificationDialog({ open, onClose }) {
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const projectId = useSelector(({ context }) => context.project?.id);
	const savedImageLength = useSelector(({ srPdf }) => srPdf?.content?.length || 0);

	const dispatch = useDispatch();
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			cancelTokenSourceRef.current.cancel();
		};
	}, []);
	const handlePlural = (reqN, infoN) => {
		const translateType = "smart-review.selection-dialog.reclassify.";
		if (infoN <= 1 && reqN <= 1) {
			return translateType.concat("req-and-info-to-req");
		}
		if (infoN > 1 && reqN <= 1) {
			return translateType.concat("req-and-infos-to-req");
		}
		if (infoN <= 1 && reqN > 1) {
			return translateType.concat("reqs-and-info-to-req");
		}
		return translateType.concat("reqs-and-infos-to-req");
	};
	const counters = useMemo(() => {
		const cts = { req: 0, info: 0 };
		(selectedRectangles || [])
			.filter((sr) => hasNoStatus(sr))
			.forEach((element) => {
				if (element?.isReq || checkHardInformation(element)) {
					cts.req++;
				} else {
					cts.info++;
				}
			});
		return cts;
	}, [selectedRectangles]);
	const finalRectangles = useMemo(
		() => (selectedRectangles || []).filter((sr) => hasNoStatus(sr)),
		[selectedRectangles]
	);
	const handleSubmit = () => {
		const newType = (!counters.info && SENTENCE_TYPE.INFORMATION) || SENTENCE_TYPE.REQUIREMENT;
		const payload = {
			ids: finalRectangles.map((sr) => sr.informationId),
			type: newType,
		};
		MultiSelectionservice.updateSelectionType(
			{ projectId, documentId },
			payload,
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				batch(() => {
					finalRectangles.forEach((sr) => {
						dispatch(
							updateNode({
								mode,
								pageConditions: { page: sr.pageStart, border: true, length: savedImageLength },
								informationId: sr.informationId,
								updates: [
									{
										property: "sentenceType",
										value: newType,
									},
								],
							})
						);
					});
					dispatch(setSelectedRectangles(null));
					dispatch(setSelectionBarCoordinates(null));
				});
			})
			.catch((err) => console.error(err));
		onClose();
	};
	return (
		<CustomDialog
			autoFocus
			open={open}
			submitLabel={translate("common:btn.confirm")}
			title={translate("smart-review.selection-bar.reclassify")}
			onClose={onClose}
			onSubmit={handleSubmit}
		>
			<div className={styles.content}>
				{(!counters.info &&
					translate(
						counters.req > 1
							? "smart-review.selection-dialog.reclassify.reqs-to-infos"
							: "smart-review.selection-dialog.reclassify.req-to-info",
						{ req: counters.req }
					)) ||
					(!counters.req &&
						translate(
							counters.info > 1
								? "smart-review.selection-dialog.reclassify.infos-to-reqs"
								: "smart-review.selection-dialog.reclassify.info-to-req",
							{ info: counters.info }
						)) || (
						<I18nTranslate
							param={{ req: counters.req, info: counters.info }}
							translationKey={handlePlural(counters.req, counters.info)}
						/>
					)}
				{finalRectangles.length !== selectedRectangles.length && (
					<div>
						{translate("smart-review.update-sentence.multiple-selection.has-review", {
							difference: selectedRectangles.length - finalRectangles.length,
						})}
					</div>
				)}
			</div>
		</CustomDialog>
	);
}
