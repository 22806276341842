import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlockTitle, createNotification } from "../../../../common/components";
import { Header, UserList } from "./components";
import { ApiService, BackOfficeService } from "../../../../api";
import { translate } from "../../../../common/providers";
import { search } from "../../slice/dsi-slice";
import { usePrevious } from "../../../../common/hooks";
import { downloadFile } from "../../../../common/utils";
import { removeNullFilters } from "../../utils/utils";
import styles from "./DSIExportUser.module.css";

export default function DSIExportUser({ companies = [] }) {
	const [openExport, setOpenExport] = useState(false);
	const [projects, setProjects] = useState([]);
	const savedPage = useSelector(({ dsi }) => dsi.page);
	const savedLimit = useSelector(({ dsi }) => dsi.limit);
	const savedFilters = useSelector(({ dsi }) => dsi.filters);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(() => {
		if (!cancelTokenSourceRef.current) {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		}
		BackOfficeService.getAllProjects(cancelTokenSourceRef.current.token)
			.then((data) => setProjects(data))
			.catch((err) => console.error(err));
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const handleSearchUsers = useCallback(
		({ filters, page, limit, display }) => {
			dispatch(
				search({
					filters: removeNullFilters(filters),
					unfiltered: filters,
					page,
					limit,
					token: cancelTokenSourceRef.current.token,
					display,
				})
			);
		},
		[dispatch]
	);
	const prevPage = usePrevious(savedPage);
	const prevLimit = usePrevious(savedLimit);
	useEffect(() => {
		if (savedPage !== prevPage || savedLimit !== prevLimit) {
			handleSearchUsers({
				page: savedPage,
				limit: savedLimit,
				filters: savedFilters,
				display: true,
			});
		}
	}, [handleSearchUsers, savedPage, savedLimit, savedFilters, prevPage, prevLimit]);
	const handleOpenExport = () => {
		setOpenExport((prev) => !prev);
	};
	const handleExport = () => {
		BackOfficeService.exportUsers(removeNullFilters(savedFilters), cancelTokenSourceRef.current.token)
			.then(({ data, filename }) => {
				downloadFile({ data, filename, filetype: "xlsx" });
				handleOpenExport();
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				createNotification({ message: translate("dsi.dsi-export-user.success") });
			});
	};
	return (
		<>
			<div className={styles.main} translate="no">
				<BlockTitle>{translate("dsi.dsi-export-user.title")}</BlockTitle>
				<Header
					companies={companies}
					openExport={openExport}
					projects={projects}
					onExport={handleExport}
					onOpenExport={handleOpenExport}
					onSearch={handleSearchUsers}
				/>
				<UserList onSearch={handleSearchUsers} />
			</div>
		</>
	);
}
