import client from "../../client/client";
import { encodeParam } from "../../utils/utils";

class ProjectDocumentService {
	uploadNewVersion({ prevDocumentId }, file, progressCallback, cancelToken) {
		return client.upload(`/documents/${prevDocumentId}/uploads`, file, {
			config: { cancelToken, onUploadProgress: progressCallback },
		});
	}

	search({ projectId }, { name, page = 0, limit = 15 }, cancelToken) {
		return client.get(`projects/${projectId}/documents`, {
			params: { name: encodeParam(name), page, limit },
			config: { cancelToken },
		});
	}

	getCompanyDocumentSpecificRoleUsers({ projectId, docId, role }, cancelToken) {
		return client.get(`projects/${projectId}/documents/${docId}/roles/${role}`, { config: { cancelToken } });
	}

	getDocuments({ projectId }, cancelToken) {
		return client.get(`projects/${projectId}/documents/all`, { config: { cancelToken } });
	}

	getAllDetailedDocuments({ projectId }, cancelToken) {
		return client.get(`projects/${projectId}/documents/all/me/roles`, { config: { cancelToken } });
	}

	getCost({ projectId }, cancelToken) {
		return client.get(`projects/${projectId}/documents/cost`, { config: { cancelToken } });
	}

	getDocumentsPrecedence({ projectId }, { filter }, cancelToken) {
		return client.get(`/projects/${projectId}/documents/precedence`, {
			params: { filter },
			config: { cancelToken },
		});
	}

	updateDocumentTypes({ projectId }, { docIds, dirIds, type }, cancelToken) {
		return client.patch(`/projects/${projectId}/type`, {
			payload: { docIds, dirIds, type },
			config: { cancelToken },
		});
	}

	getUploads({ projectId }, cancelToken) {
		return client.get(`projects/${projectId}/documents/uploads`, { config: { cancelToken } });
	}

	uploadDocuments({ projectId }, files, { directoryId }, progressCallback, cancelToken) {
		return client.upload(`/projects/${projectId}/documents/uploads`, files, {
			payload: { directoryId },
			config: { cancelToken, onUploadProgress: progressCallback },
		});
	}
}

export default new ProjectDocumentService();
