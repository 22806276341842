import client from "../../client/client";
import ContextProvider from "../../../common/providers/context-provider";

class UserService {
	verifyEmail({ email }, cancelToken) {
		return client.get("/users", { params: { email }, config: { cancelToken } });
	}

	loginWithCompany({ companyId }, cancelToken, { token = "" } = {}) {
		const config = { config: { cancelToken } };
		if (token) {
			config.config.headers = { Authorization: token };
		}
		return client.post(`/users/login/${companyId}`, config);
	}

	getAllUserCompanies(cancelToken) {
		return client.get("/users/login/companies", { config: { cancelToken } });
	}

	logout(cancelToken) {
		ContextProvider.logout();
		return client.get("/users/logout", { config: { cancelToken } });
	}
}

export default new UserService();
