import React from "react";
import { CustomButton, CustomIconButton, icon, TextMidCut } from "..";
import { FILE_TYPES } from "../../constants/file-types";
import styles from "./BreadcrumbButton.module.css";
import { dangerousSetInnerHTML } from "../../utils";

const BreadcrumbButton = ({
	charsKeptInEnd = 12,
	className = "",
	disabled = false,
	id = "",
	isDangerousSetInnerHTML = false,
	location,
	onSelectOption = null,
	style = null,
}) => {
	const handleSelectOption = () => {
		onSelectOption(location ? { ...location, type: FILE_TYPES.FOLDER } : null);
	};
	if (location?.label) {
		return disabled || !onSelectOption ? (
			<TextMidCut
				isDangerousSetInnerHTML
				tooltip
				autoTranslate="no"
				charsKeptInEnd={charsKeptInEnd}
				className={`${styles["button--disabled"]} ${className}`}
				id={id}
				style={style}
				text={location.label}
			/>
		) : (
			<CustomButton color="secondary" size="md" onClick={handleSelectOption}>
				{(isDangerousSetInnerHTML && dangerousSetInnerHTML({ text: location.label })) || location.label}
			</CustomButton>
		);
	}
	return (
		<CustomIconButton
			btnClassName={styles.button}
			disabled={disabled}
			icon={icon.faHome}
			iconColor="var(--color-dark-grey-1)"
			id={id}
			size="sm"
			onClick={handleSelectOption}
		/>
	);
};

export default BreadcrumbButton;
