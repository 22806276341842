import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./DeliverableRow.module.css";
import { CircularLoader, CustomTooltip } from "../../../../../common/components";

const DeliverableRow = ({
	deliverable,
	loadingChanges = false,
	onSelect,
	onSetResetDeliverable,
	resetDeliverable,
	selectedCount,
}) => {
	const [localDeliv, setLocalDeliv] = useState(deliverable);
	useEffect(() => {
		if (resetDeliverable) {
			setLocalDeliv(deliverable);
			if (typeof onSetResetDeliverable === "function") {
				onSetResetDeliverable(false);
			}
		}
	}, [resetDeliverable, deliverable, onSetResetDeliverable]);
	const handleCheck = () => {
		let tempDeliv = null;
		if (localDeliv.reqCount) {
			tempDeliv = { ...localDeliv, reqCount: 0 };
			if (tempDeliv.reqCount === deliverable.reqCount) {
				onSelect({ deliverable: tempDeliv, action: "fullRemove" });
			} else {
				onSelect({ deliverable: tempDeliv, action: "toRemove" });
			}
		} else {
			tempDeliv = { ...localDeliv, reqCount: selectedCount };
			if (tempDeliv.reqCount === deliverable.reqCount) {
				onSelect({ deliverable: tempDeliv, action: "fullRemove" });
			} else {
				onSelect({ deliverable: tempDeliv, action: "toAdd" });
			}
		}
		setLocalDeliv(tempDeliv);
	};
	return (
		<div className={styles.row}>
			{(loadingChanges && <CircularLoader className={styles.row__checkbox} size={24} />) || (
				<Checkbox
					checked={localDeliv.reqCount >= selectedCount && localDeliv.reqCount > 0}
					className={styles.row__checkbox}
					indeterminate={localDeliv.reqCount > 0 && localDeliv.reqCount < selectedCount}
					onClick={handleCheck}
				/>
			)}
			<div className={styles.row__content}>
				<CustomTooltip showWhenOverflow title={localDeliv.name}>
					<div className={styles.row__documentName}>{localDeliv.name}</div>
				</CustomTooltip>
				<CustomTooltip showWhenOverflow title={localDeliv.ref}>
					<div className={styles.row__documentRef}>{localDeliv.ref}</div>
				</CustomTooltip>
			</div>
			{selectedCount > 1 && (
				<div className={styles.row__counters}>
					{localDeliv.reqCount} / {selectedCount}
				</div>
			)}
		</div>
	);
};

export default DeliverableRow;
