import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, MenuItem, TextField, RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
import styles from "./ProjectDetails.module.css";
import {
	setProjectCreation,
	setIsModified,
	setHasProjectRequirementsSegFlag,
	setHasTeamSegFlag,
	setHasCategorySegFlag,
	setCheckedCategoriesIds,
	setHasPermissionsSegFlag,
} from "../../../slice/newProjectSlice";
import { SegFlags, translate } from "../../../../../common/providers";
import { ProjectService, ApiService, ProjectCreationService } from "../../../../../api";
import Loader from "../../../../../navigation/routes/components/Loader";
import {
	CustomTooltip,
	I18nTranslate,
	icon,
	IconComponent,
	KnowledgeBasePopOver,
} from "../../../../../common/components";
import { isNonEmptyArray } from "../../../../../common/utils";

export default function ProjectDetails({ onAddCheckedCategories }) {
	const projectCreation = useSelector(({ newProject }) => newProject.projectCreation);
	const hasProjectRequirementsSegFlag = useSelector(({ newProject }) => newProject.hasProjectRequirementsSegFlag);
	const [projectTypes, setProjectTypes] = useState([]);
	const packages = useSelector(({ newProject }) => newProject.packages);
	const companyName = useSelector(({ context }) => context.company.name);
	const cancelTokenSourceRef = useRef(null);
	const radioGroupRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(
		() => {
			const packageId = projectCreation?.project?.package?.id;
			if (cancelTokenSourceRef.current) {
				if (packageId) {
					ProjectCreationService.getIsFeatureInPackage(
						{ packageId },
						{
							segFlags: [
								SegFlags.PROJECT_REQUIREMENTS,
								SegFlags.CATEGORY,
								SegFlags.TEAM,
								SegFlags.PERMISSIONS,
							],
						},
						cancelTokenSourceRef.current.token
					).then((data) => {
						const localHasProjectReqSegFlag = data.find(
							(d) => d.segmentationFlag === SegFlags.PROJECT_REQUIREMENTS
						).isActive;
						const localHasCategorySegFlag = data.find(
							(d) => d.segmentationFlag === SegFlags.CATEGORY
						).isActive;
						const localHasTeamSegFlag = data.find((d) => d.segmentationFlag === SegFlags.TEAM).isActive;
						const localHasPermissionsSegFlag = data.find(
							(d) => d.segmentationFlag === SegFlags.PERMISSIONS
						).isActive;
						dispatch(setHasProjectRequirementsSegFlag(localHasProjectReqSegFlag));
						dispatch(setHasCategorySegFlag(localHasCategorySegFlag));
						dispatch(setHasTeamSegFlag(localHasTeamSegFlag));
						dispatch(setHasPermissionsSegFlag(localHasPermissionsSegFlag));
						if (!localHasProjectReqSegFlag) {
							dispatch(
								setProjectCreation({
									...projectCreation,
									project: { ...projectCreation.project, reqDetection: false },
								})
							);
						}
						if (!localHasCategorySegFlag) {
							dispatch(setCheckedCategoriesIds([]));
							onAddCheckedCategories([]);
						}
						if (!localHasTeamSegFlag) {
							if (isNonEmptyArray(projectCreation.users)) {
								projectCreation.users.forEach((user) => {
									if (user?.id) {
										ProjectCreationService.deleteUser(
											{ userId: user.id },
											cancelTokenSourceRef.current.token
										).catch((err) => {
											console.error(err);
										});
									}
								});
							}
							dispatch(setCheckedCategoriesIds([]));
						}
					});
				} else {
					dispatch(setHasProjectRequirementsSegFlag(false));
					dispatch(
						setProjectCreation({
							...projectCreation,
							project: { ...projectCreation.project, reqDetection: false },
						})
					);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, projectCreation?.project?.package]
	);
	const handleChangeProjectName = (e) => {
		dispatch(
			setProjectCreation({
				...projectCreation,
				project: { ...projectCreation.project, name: e?.target.value || "" },
			})
		);
		dispatch(setIsModified(true));
	};
	const handleChangeProjectType = (e) => {
		dispatch(
			setProjectCreation({
				...projectCreation,
				project: { ...projectCreation.project, type: e?.target.value || "" },
			})
		);
		dispatch(setIsModified(true));
	};
	const handleChangePackage = (e) => {
		const { value } = e.target;
		dispatch(
			setProjectCreation({
				...projectCreation,
				project: {
					...projectCreation.project,
					package: value || "",
				},
				featPackage: value?.name,
			})
		);
		dispatch(setIsModified(true));
		if (hasProjectRequirementsSegFlag) {
			radioGroupRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	useEffect(() => {
		if (radioGroupRef.current) {
			radioGroupRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [hasProjectRequirementsSegFlag]);

	const handleChangeReqDetection = (e) => {
		dispatch(
			setProjectCreation({
				...projectCreation,
				project: { ...projectCreation.project, reqDetection: Number.parseInt(e.target.value, 10) === 1 },
			})
		);
		dispatch(setIsModified(true));
	};

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		ProjectService.getTypes(cancelTokenSourceRef.current.token)
			.then((data) => {
				setProjectTypes(data);
			})
			.catch((err) => console.error(err));
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	return (
		<form className={styles.form}>
			<div className={styles.title}>
				{translate("new-project.details-and-documents.text-field-label.project-name")}
				<IconComponent
					className={styles.requiredIcon}
					color="var(--color-red)"
					icon={icon.faAsterisk}
					size="2xs"
				/>
			</div>
			<TextField
				data-testid="new-project.name"
				InputLabelProps={{ className: styles.inputLabelProps }}
				InputProps={{ className: styles.inputProps }}
				label={translate("new-project.details-and-documents.text-field.project-name.placeholder")}
				size="small"
				value={projectCreation?.project?.name || ""}
				variant="outlined"
				onChange={handleChangeProjectName}
			/>
			<div className={styles.title}>
				{translate("new-project.details-and-documents.text-field-label.project-type")}
				<IconComponent
					className={styles.requiredIcon}
					color="var(--color-red)"
					icon={icon.faAsterisk}
					size="2xs"
				/>
				<KnowledgeBasePopOver
					label={
						<I18nTranslate translationKey="new-project.details-and-documents.project-type.information" />
					}
				/>
			</div>
			{isNonEmptyArray(projectTypes) ? (
				<TextField
					select
					data-testid="new-project.type"
					InputLabelProps={{ className: styles.inputLabelProps }}
					InputProps={{ className: styles.inputProps }}
					label={translate("new-project.details-and-documents.text-field.project-type.placeholder")}
					size="small"
					value={projectCreation.project?.type || ""}
					variant="outlined"
					onChange={handleChangeProjectType}
				>
					{projectTypes.map((t) => (
						<MenuItem key={t} value={t}>
							{t}
						</MenuItem>
					))}
				</TextField>
			) : (
				<Loader />
			)}
			<span className={styles.description}>{translate("new-project.details-and-documents.description")}</span>
			<div className={styles.package}>
				<span className={styles.title}>
					{translate("new-project.details-and-documents.text-field-label.package")}
					<IconComponent
						className={styles.requiredIcon}
						color="var(--color-red)"
						icon={icon.faAsterisk}
						size="2xs"
					/>
					<KnowledgeBasePopOver
						label={<I18nTranslate translationKey="new-project.details-and-documents.package.information" />}
					/>
				</span>
				<div className={styles.tooltipPack} />
			</div>
			{(isNonEmptyArray(packages) && (
				<TextField
					select
					data-testid="new-project.package"
					defaultValue=""
					InputLabelProps={{ className: styles.inputLabelProps }}
					InputProps={{ className: styles.inputProps }}
					label={translate("new-project.details-and-documents.text-field.package.placeholder")}
					size="small"
					value={
						(projectCreation?.featPackage &&
							packages.find((p) => p.name === projectCreation?.featPackage)) ||
						""
					}
					variant="outlined"
					onChange={handleChangePackage}
				>
					{packages.map((t) => (
						<MenuItem key={t.id} value={t}>
							{t.name}
						</MenuItem>
					))}
				</TextField>
			)) || (
				<span className={styles.package__errorMessage}>
					{translate("new-project.details-and-documents.text-field.package.no-package.error")}
				</span>
			)}
			{hasProjectRequirementsSegFlag && (
				<>
					<Divider className={styles.divider} />
					<div className={styles.title}>
						{translate("new-project.details-and-documents.implicit-requirements.algorithm.selection")}
						<IconComponent
							className={styles.requiredIcon}
							color="var(--color-red)"
							icon={icon.faAsterisk}
							size="2xs"
						/>
						<KnowledgeBasePopOver
							label={translate(
								"new-project.details-and-documents.implicit-requirements.algorithm.information"
							)}
							link={translate("new-project.details-and-documents.implicit-requirements.algorithm.link")}
						/>
					</div>
					<RadioGroup
						ref={radioGroupRef}
						data-testid="new-project.detection"
						value={(projectCreation.project?.reqDetection && 1) || 0}
						onChange={handleChangeReqDetection}
					>
						<FormControlLabel
							control={<Radio color="primary" size="small" />}
							label={
								<Typography className={styles.radio}>
									{translate(
										"new-project.details-and-documents.implicit-requirements.algorithm.none"
									)}
								</Typography>
							}
							value={0}
						/>
						<div className={styles.package}>
							<FormControlLabel
								control={<Radio color="primary" size="small" />}
								label={
									<Typography className={styles.radio}>
										{translate(
											"new-project.details-and-documents.implicit-requirements.algorithm.standart"
										)}
									</Typography>
								}
								value={1}
							/>
							<div className={styles.tooltipReq} />
						</div>
						<FormControlLabel
							disabled
							control={<Radio color="primary" size="small" />}
							label={
								<CustomTooltip
									title={translate(
										"new-project.details-and-documents.implicit-requirements.no-alorightm-warning"
									)}
								>
									<Typography className={`${styles.radio} ${styles["radio--disabled"]}`}>
										{translate(
											"new-project.details-and-documents.implicit-requirements.algorithm.proprietary",
											{ companyName }
										)}
									</Typography>
								</CustomTooltip>
							}
							value={2}
						/>
					</RadioGroup>
				</>
			)}
		</form>
	);
}
