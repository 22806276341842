import { KEYWORD_FILTER_TYPE } from "../../../../common/constants";
import { isDeepEqualComplex } from "../../../../common/utils";
import { isPositiveKeywordFilter } from "../../../smart-search/utils/utils";

export function removeNullFilters(filters) {
	const newFilters = {};
	Object.entries(filters).forEach(([fKey, value]) => {
		if (!fKey || value === undefined || value === null) {
			return;
		}
		newFilters[fKey] = value;
	});
	return newFilters;
}
export function hasBeenModified({ filters, previousFilters, isFilterTocOut, previousIsFilterTocOut }) {
	return [isFilterTocOut === previousIsFilterTocOut, isDeepEqualComplex(filters, previousFilters)].some((eq) => !eq);
}
function incrementKeywordFilters(keywordFilters, types, { numberOfPositiveKeywordFilters, hasNegativeKeywordFilters }) {
	types.forEach((type) => {
		if (keywordFilters[type] && Array.isArray(keywordFilters[type].filters)) {
			keywordFilters[type].filters.forEach((filter) => {
				const { operator } = filter;
				if (isPositiveKeywordFilter(operator)) {
					++numberOfPositiveKeywordFilters[type];
				} else {
					hasNegativeKeywordFilters[type] = true;
				}
			});
		}
	});
}
export function computeKeywordFiltersExistence({ keywordFilters, tocFilters }) {
	const hasNegativeKeywordFilters = { [KEYWORD_FILTER_TYPE.PARAGRAPH]: false, [KEYWORD_FILTER_TYPE.TOC]: false };
	const numberOfPositiveKeywordFilters = { [KEYWORD_FILTER_TYPE.PARAGRAPH]: 0, [KEYWORD_FILTER_TYPE.TOC]: 0 };
	if (
		(!keywordFilters || !Array.isArray(keywordFilters.filters) || keywordFilters.length === 0) &&
		(!tocFilters || !Array.isArray(tocFilters.filters) || tocFilters.length === 0)
	) {
		return { hasNegativeKeywordFilters, numberOfPositiveKeywordFilters };
	}
	incrementKeywordFilters(
		{ [KEYWORD_FILTER_TYPE.PARAGRAPH]: keywordFilters, [KEYWORD_FILTER_TYPE.TOC]: tocFilters },
		[KEYWORD_FILTER_TYPE.PARAGRAPH, KEYWORD_FILTER_TYPE.TOC],
		{ hasNegativeKeywordFilters, numberOfPositiveKeywordFilters }
	);
	return { hasNegativeKeywordFilters, numberOfPositiveKeywordFilters };
}
