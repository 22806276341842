import { TextField } from "@mui/material";
import React, { useState } from "react";
import styles from "./ComponentsTextField.module.css";

const ComponentsTextField = React.forwardRef(
	(
		{
			value,
			onChange,
			onKeyDown,
			onPaste,
			children,
			InputProps,
			placeholder = "",
			className = "",
			helperText = "",
		},
		ref
	) => {
		const [isFocus, setIsFocus] = useState(false);
		const handleFocus = () => {
			setIsFocus(true);
		};
		const handleBlur = () => {
			setIsFocus(false);
		};
		return (
			<>
				<div className={`${styles["textfield-container"]} ${className}`} data-focus={isFocus}>
					{children}
					<TextField
						fullWidth
						multiline
						classes={{
							root: styles["textfield-root"],
						}}
						className={styles["textfield-container__input"]}
						InputProps={InputProps}
						inputRef={ref}
						placeholder={placeholder}
						size="small"
						value={value}
						variant="outlined"
						onBlur={handleBlur}
						onChange={onChange}
						onFocus={handleFocus}
						onKeyDown={onKeyDown}
						onPaste={onPaste}
					/>
				</div>
				<div className={styles["textfield-container__helperText"]}>{helperText}</div>
			</>
		);
	}
);
export default ComponentsTextField;
