import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { MatchedNorms } from "..";
import { I18nTranslate, Preview } from "../../../../../../common/components";
import { ApiService } from "../../../../../../api";
import DetectionsTable from "./detection-table/DetectionsTable";
import EmptyState from "./empty-state/EmptyState";
import { setOrderBy, setDir } from "../../../../slice/normsCenterSlice";
import { translate } from "../../../../../../common/providers";
import styles from "./Detections.module.css";

export default function Detections({
	checkedRows,
	coordinates,
	detections = [],
	headers,
	isLoading,
	onActive,
	onPageChange,
	onRowsPerPageChange,
	onCheckAllRows,
	onOpenMatchedNorms,
	onOpenPreviewDocument,
	onResetFilters,
	onUpdatePendingNorm,
	openMatchedNorms,
	openPreviewDocument,
	page,
	pageLimit,
	selectedInfo,
	selectedRow,
	TableBody,
	tableBodyProps,
	totalSearchResult,
	isGlobalSelection,
	onClickSelectAll,
}) {
	const dispatch = useDispatch();
	const filters = useSelector(({ normsCenter }) => normsCenter.filters);
	const orderBy = useSelector(({ normsCenter }) => normsCenter.orderBy);
	const dir = useSelector(({ normsCenter }) => normsCenter.dir);
	const projectId = useSelector(({ context }) => context.project?.id);
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const handleSort = (property) => {
		const isAsc = orderBy === property && dir === "ASC";
		dispatch(setOrderBy(property));
		dispatch(setDir(isAsc ? "DESC" : "ASC"));
	};
	return (
		<>
			{!isGlobalSelection &&
				checkedRows.length === detections.length &&
				detections.length > 0 &&
				detections.length !== totalSearchResult && (
					<div className={styles.selectionOption}>
						<I18nTranslate
							param={{ detectionsCount: checkedRows.length }}
							translationKey="norms-center.tab.detections.selected-detections"
						/>
						<Button
							className={styles.selectionOption__selectAllBtn}
							color="primary"
							variant="text"
							onClick={onClickSelectAll}
						>
							{translate("norms-center.tab.detections.select-all", {
								detectionsCount: totalSearchResult,
							})}
						</Button>
					</div>
				)}
			<DetectionsTable
				detections={detections}
				headers={headers}
				numSelected={checkedRows.length}
				page={page}
				rowsPerPageLimit={pageLimit || null}
				totalElements={totalSearchResult}
				onCheckAllRows={onCheckAllRows}
				onPageChange={onPageChange || null}
				onResetFilters={onResetFilters}
				onRowsPerPageChange={onRowsPerPageChange || null}
				onSort={handleSort}
			>
				<TableBody
					emptyState={<EmptyState hasFilters={filters} onResetFilters={onResetFilters} />}
					isLoading={isLoading}
					{...tableBodyProps}
				/>
			</DetectionsTable>
			<Preview
				coordinates={coordinates}
				docId={selectedRow?.docId || 0}
				infoId={selectedInfo}
				open={openPreviewDocument}
				page={selectedRow?.page || 0}
				projectId={projectId}
				onActive={onActive}
				onClose={onOpenPreviewDocument}
			/>
			<MatchedNorms
				matchedNormId={selectedRow?.matchedNormId}
				open={openMatchedNorms}
				pendingId={selectedRow?.pendingId || selectedRow?.pendingNormId}
				textDetected={selectedRow?.textDetected}
				onActive={onActive}
				onOpenMatchedNorms={onOpenMatchedNorms}
				onUpdatePendingNorm={onUpdatePendingNorm}
			/>
		</>
	);
}
