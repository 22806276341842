// DO NOT IMPORT NotFound, Login, ForgotPassword, ResetPwd, ChangePwd, Versionning, Support, NotSupported //
import Admin from "./admin/Admin";
import Canvas from "./canvas/Canvas";
import ConsistencyCheck from "./consistency-check/ConsistencyCheck";
import { DashboardPortfolio, MyDashboard, Project, Tasks } from "./dashboards";
import DocumentCenterV2, { documentCenterSlice } from "./document-center/DocumentCenter";
import DSI, { dsiSlice } from "./dsi/DSI";
import NormsCenter, { normsCenterSlice } from "./norms-center/NormsCenter";
import { Coverage, Deliverables, RequirementMatrix } from "./produce";
import Profile from "./profile/Profile";
import NotificationSettings from "./notification-settings/NotificationSettings";
import QAJournal from "./qa-journal/QAJournal";
import TeamManagement from "./team-management/TeamManagement";
import NewProject, { newProjectSlice } from "./new-project/NewProject";
import { MySearch, SearchForAutomation } from "./smart-search";
import { MySearchTemplate, CompanySearchTemplate } from "./search-template";
import AdminCategories from "./admin-categories/AdminCategories";
import CategoryTemplate from "./category-template/CategoryTemplate";
import Gonogo, { gonogoSlice } from "./gonogo/Gonogo";
import MyReview, { srProjectDocumentsSlice, srPdfSlice, srDocumentSlice } from "./my-review/MyReview";
import { dashboardSlice } from "./dashboards/dashboard-project/my-dashboard/MyDashboard";
import { mySearchSlice } from "./smart-search/my-search/MySearch";
import { searchForAutomationSlice } from "./smart-search/search-for-automation/SearchForAutomation";
import { mySearchTemplateSlice } from "./search-template/my-search-template/MySearchTemplateView";
import { companySearchTemplateSlice } from "./search-template/company-search-template/CompanySearchTemplateView";
import Opportunity, { opportunitySlice } from "./opportunity/Opportunity";
import MultiProjectSearch from "./multi-project-search/MultiProjectSearch";
import ChatProject from "./chat-project/ChatProject";

export const slices = {
	documentCenter: documentCenterSlice,
	normsCenter: normsCenterSlice,
	newProject: newProjectSlice,
	dsi: dsiSlice,
	mySearch: mySearchSlice,
	mySearchTemplate: mySearchTemplateSlice,
	companySearchTemplate: companySearchTemplateSlice,
	dashboard: dashboardSlice,
	searchForAutomation: searchForAutomationSlice,
	gonogo: gonogoSlice,
	srPdf: srPdfSlice,
	srDocument: srDocumentSlice,
	srProjectDocument: srProjectDocumentsSlice,
	opportunity: opportunitySlice,
};
export const views = {
	Admin,
	Canvas,
	ConsistencyCheck,
	DashboardPortfolio,
	DocumentCenterV2,
	DSI,
	MyDashboard,
	Project,
	Tasks,
	NormsCenter,
	SearchForAutomation,
	Coverage,
	Deliverables,
	RequirementMatrix,
	Profile,
	NotificationSettings,
	QAJournal,
	TeamManagement,
	NewProject,
	MySearch,
	AdminCategories,
	CategoryTemplate,
	MySearchTemplate,
	CompanySearchTemplate,
	Gonogo,
	MyReview,
	Opportunity,
	MultiProjectSearch,
	ChatProject,
};
