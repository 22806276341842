import client from "../../client/client";
import { encodeParam } from "../../utils/utils";

class QAService {
	/* Gets all questions on a document */
	getAllQuestionsOnDocument({ documentId }, cancelToken) {
		return client.get(`/qa/documents/${documentId}`, { config: { cancelToken } });
	}

	/* Gets all questions on information */
	getAllQuestionsOnInformation({ infoId }, cancelToken) {
		return client.get(`/qa/information/${infoId}`, { config: { cancelToken } });
	}

	/* Get all questions on project */
	getGeneralQuestions({ projectId }, cancelToken) {
		return client.get(`/qa/projects/${projectId}`, { config: { cancelToken } });
	}

	/* Gets all draft questions in project grouped by documents and requirements (Left side of QA page) */
	getIdentifiableDraftedQuestions({ projectId }, cancelToken) {
		return client.get(`/qa/projects/${projectId}/drafts`, { config: { cancelToken } });
	}

	/* Gets all sent external questions with their answers, grouped by documents and requirements */
	getAllExternals({ projectId }, cancelToken) {
		return client.get(`/qa/projects/${projectId}/externals`, { config: { cancelToken } });
	}

	/* Gets all sent internal questions with their answers, grouped by documents and requirements */
	getAllInternals({ projectId }, cancelToken) {
		return client.get(`/qa/projects/${projectId}/internals`, { config: { cancelToken } });
	}

	getQuestionStatus({ projectId }, cancelToken) {
		return client.get(`/question-answer/projects/${projectId}/status`, { config: { cancelToken } });
	}

	searchQuestionAnswerInProject({ projectId }, payload, cancelToken, { page = 0, limit = 50 } = {}) {
		return client.put(`/question-answer/projects/${projectId}`, {
			payload,
			params: { page, limit },
			config: { cancelToken },
		});
	}

	getQaDetails({ projectId, qaId }, cancelToken) {
		return client.get(`/question-answer/projects/${projectId}/qa/${qaId}`, { config: { cancelToken } });
	}

	createQuestionAnswerInProject({ projectId }, payload, cancelToken) {
		return client.post(`/question-answer/projects/${projectId}`, { payload, config: { cancelToken } });
	}

	deleteQuestionAnswerInProject({ projectId }, payload, cancelToken) {
		return client.patch(`/question-answer/projects/${projectId}/qa`, { payload, config: { cancelToken } });
	}

	updateQuestionAnswerStatusInProject({ projectId }, payload, cancelToken) {
		return client.patch(`/question-answer/projects/${projectId}/status`, { payload, config: { cancelToken } });
	}

	updateQuestionAnswerSentDateInProject({ projectId }, payload, cancelToken) {
		return client.patch(`/question-answer/projects/${projectId}/sent-date`, { payload, config: { cancelToken } });
	}

	updateQuestionAnswerReceiveDateInProject({ projectId }, payload, cancelToken) {
		return client.patch(`/question-answer/projects/${projectId}/receive-date`, {
			payload,
			config: { cancelToken },
		});
	}

	updateQuestionAnswer({ projectId, qaId }, payload, cancelToken) {
		return client.put(`/question-answer/projects/${projectId}/qa/${qaId}`, {
			payload,
			config: { cancelToken },
		});
	}

	getQaDocumentReference({ projectId, qaId }, payload, cancelToken) {
		return client.get(`/question-answer/projects/${projectId}/qa/${qaId}/documents`, {
			config: { cancelToken },
			params: { search: encodeParam(payload) },
		});
	}

	getQaClauseReference({ projectId, qaId, documentId }, payload, cancelToken) {
		return client.get(`/question-answer/projects/${projectId}/qa/${qaId}/documents/${documentId}/clauses`, {
			config: { cancelToken },
			params: { search: encodeParam(payload) },
		});
	}

	getQaSubClauseReference({ projectId, qaId, clauseId, documentId }, payload, cancelToken) {
		return client.get(
			`/question-answer/projects/${projectId}/qa/${qaId}/documents/${documentId}/clauses/${clauseId}`,
			{ config: { cancelToken }, params: { search: encodeParam(payload) } }
		);
	}

	getQaInformationReference({ projectId, qaId, clauseId, documentId }, cancelToken) {
		return client.get(
			`/question-answer/projects/${projectId}/qa/${qaId}/documents/${documentId}/clauses/${clauseId}/informations`,
			{ config: { cancelToken } }
		);
	}

	updateQaReference({ projectId, qaId }, payload, cancelToken) {
		return client.post(`/question-answer/projects/${projectId}/qa/${qaId}/references`, {
			payload,
			config: { cancelToken },
		});
	}

	deleteQaReference({ projectId, qaId, referenceId }, cancelToken) {
		return client.delete(`/question-answer/projects/${projectId}/qa/${qaId}/references/${referenceId}`, {
			config: { cancelToken },
		});
	}

	deleteQaTag({ projectId, qaId, tagId }, cancelToken) {
		return client.delete(`/question-answer/projects/${projectId}/qa/${qaId}/tags/${tagId}`, {
			config: { cancelToken },
		});
	}

	addQaTag({ projectId }, payload, cancelToken) {
		return client.patch(`/question-answer/projects/${projectId}/tags`, {
			payload,
			config: { cancelToken },
		});
	}

	getQAReferences({ projectId, qaId }, cancelToken) {
		return client.get(`question-answer/projects/${projectId}/qa/${qaId}/references`, {
			config: { cancelToken },
		});
	}

	getImportTemplate({ projectId }, cancelToken) {
		return client.get(`/question-answer/projects/${projectId}/import`, { config: { cancelToken } });
	}

	importQuestionAnswer({ projectId }, file, shouldErase, cancelToken) {
		return client.upload(`/question-answer/projects/${projectId}/import`, file, {
			payload: shouldErase,
			config: { cancelToken },
		});
	}
}
export default new QAService();
