import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNonEmptyArray } from "../../../../../common/utils";
import { setSelectedSearch } from "../../../slice/document/document-slice";
import { isOverlap } from "../../document-viewer/utils/utils";
import { hasReviewPermissions } from "../../../permissions/review-permissions-provider";
import REVIEW_PERMISSIONS from "../../../permissions/review-permissions";
import { KEY_SMART_VIEW } from "../../../../../common/providers/keys/keys";
import { setSelectedMenu } from "../../../slice/pdf/pdf-slice";
import { isInMyReviewPage } from "../../../../../navigation";

const HIT_TARGET_RATIO = 0.2;

const generateSelected = (closestInnerDiv, documentId) => {
	const inId = parseInt(closestInnerDiv.getAttribute("data-information-id"), 10);
	return {
		informationId: inId,
		infoId: inId,
		documentId,
		rectangles: JSON.parse(closestInnerDiv.getAttribute("data-rectangles")).map((item) => {
			const [order, contentId] = item.split(":");
			return {
				order: parseInt(order, 10),
				contentId: parseInt(contentId, 10),
			};
		}),
		pageStart: parseInt(closestInnerDiv.getAttribute("data-page"), 10),
		isReq: JSON.parse(closestInnerDiv.getAttribute("data-req-content")),
		contentId: parseInt(closestInnerDiv.getAttribute("alt"), 10),
		lastStatus: closestInnerDiv.getAttribute("data-last-status"),
		validatedStatus: closestInnerDiv.getAttribute("data-last-validated-status"),
	};
};

const computeClosestDiv = (targetRect, page) => {
	const targetMark = targetRect.top + targetRect.height * HIT_TARGET_RATIO;
	let minDistance = Infinity;
	let closestInnerDiv = null;
	for (let i = page - 1; i <= page + 1; i++) {
		if (i >= 0) {
			const divId = `row_${i}_pdf_viewer`;
			const div = document.getElementById(divId);
			if (div) {
				const areasInDiv = div.querySelectorAll(
					// eslint-disable-next-line
					`area[class^="RenderRectangle_area__"]`
				);
				const arrayAreasInDiv = Array.from(areasInDiv);
				for (const area of arrayAreasInDiv) {
					const areaRect = area?.getBoundingClientRect();
					if (areaRect && isOverlap(areaRect, targetRect)) {
						const distance = Math.abs(areaRect.top - targetMark);
						if (distance < minDistance) {
							minDistance = distance;
							closestInnerDiv = area;
						}
						if (distance > minDistance) {
							break;
						}
					}
				}
			}
		}
	}
	return closestInnerDiv;
};

export default function ShorcutEffect({ hoveredContent, onResetHovered }) {
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const selectedSearch = useSelector(({ srDocument }) => srDocument.selectedSearch);
	const metadata = useSelector(({ srPdf }) => srPdf.content);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const numberOfPages = useSelector(({ srDocument }) => srDocument.numberOfPages);
	const page = useSelector(({ srPdf }) => srPdf.page);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const roles = useSelector(({ srDocument }) => srDocument.roles);
	const selectionSidePanelOpen = useSelector(({ srDocument }) => srDocument.selectionSidePanelOpen);
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const isSelecting = useSelector(({ srDocument }) => srDocument.isSelecting);
	const selectedMenu = useSelector(({ srPdf }) => srPdf.selectedMenu);
	const isThereAnotherFilter = useSelector(({ srProjectDocument }) => srProjectDocument.isThereAnotherFilter);
	const dispatch = useDispatch();
	const isSelectionPanelOn = useMemo(
		() =>
			selectionSidePanelOpen &&
			selectedRectangles.length > 1 &&
			!isSelecting &&
			hasReviewPermissions(REVIEW_PERMISSIONS.CATEGORY_UPDATE, { roles }),
		[roles, selectionSidePanelOpen, selectedRectangles, isSelecting]
	);

	const dispatchAndReset = (payload) => {
		dispatch(setSelectedSearch(payload));
		if (hoveredContent) {
			onResetHovered();
		}
	};

	const goToPreviousItem = (currentContent) => {
		const previousPage = ([...metadata]?.reverse() || []).find(
			(x) => x.page < currentContent?.pageStart && isNonEmptyArray(x[mode])
		);
		if (previousPage) {
			// eslint-disable-next-line prefer-destructuring
			let lastOfPrevious = previousPage[mode][previousPage[mode].length - 1];
			if (lastOfPrevious.infoId === currentContent.infoId) {
				if (previousPage[mode].length > 1) {
					// eslint-disable-next-line prefer-destructuring
					lastOfPrevious = previousPage[mode][previousPage[mode].length - 2];
				} else if (currentContent?.pageStart > 2) {
					const anteriorPage = ([...metadata]?.reverse() || []).find(
						(x) =>
							x.page < currentContent?.pageStart &&
							currentContent?.pageStart !== lastOfPrevious.pageStart &&
							isNonEmptyArray(x[mode])
					);
					const anteriorPageItem = anteriorPage?.[mode]?.[anteriorPage[mode].length - 1];
					if (anteriorPageItem) {
						lastOfPrevious = anteriorPageItem;
					} else {
						lastOfPrevious = null;
					}
				} else {
					lastOfPrevious = null;
				}
			}
			if (lastOfPrevious) {
				dispatchAndReset({
					...lastOfPrevious,
					infoId: lastOfPrevious.informationId,
					documentId,
				});
			}
		}
	};
	const goToNextItem = (currentContent) => {
		const nextPage = metadata.find((x) => x.page > currentContent?.pageStart && isNonEmptyArray(x[mode]));
		if (nextPage) {
			// eslint-disable-next-line prefer-destructuring
			let firstOfNext = nextPage[mode][0];
			if (firstOfNext.infoId === currentContent.infoId) {
				if (nextPage[mode].length > 1) {
					// eslint-disable-next-line prefer-destructuring
					firstOfNext = nextPage[mode][1];
				} else if (currentContent?.pageStart < numberOfPages) {
					const posteriorPage = metadata.find(
						(x) =>
							x.page > currentContent?.pageStart &&
							currentContent?.pageStart !== firstOfNext.pageStart &&
							isNonEmptyArray(x[mode])
					);
					const posteriorPageItem = posteriorPage?.[mode]?.[posteriorPage[mode].length - 1];
					if (posteriorPageItem) {
						firstOfNext = posteriorPageItem;
					} else {
						firstOfNext = null;
					}
				} else {
					firstOfNext = null;
				}
			}
			if (firstOfNext) {
				dispatchAndReset({
					...firstOfNext,
					infoId: firstOfNext.informationId,
					documentId,
				});
			}
		}
	};
	const handleArrows = (e) => {
		const currentContent = hoveredContent || selectedSearch;
		const dirLeft = e.key === "ArrowLeft";
		if (currentContent) {
			const selectedPageData = metadata?.find((x) => x.page === currentContent?.pageStart)?.[mode];
			const currentIndex = selectedPageData.findIndex(
				(x) => x.informationId === currentContent.informationId || x.informationId === currentContent.infoId
			);
			if (dirLeft) {
				if (currentIndex > 0) {
					const newData = selectedPageData[currentIndex - 1];
					dispatchAndReset({ ...newData, infoId: newData.informationId, documentId });
				} else if (selectedSearch?.pageStart > 1) {
					goToPreviousItem(currentContent);
				}
			}
			if (!dirLeft) {
				if (currentIndex < selectedPageData.length - 1) {
					const newData = selectedPageData[currentIndex + 1];
					dispatchAndReset({ ...newData, infoId: newData.informationId, documentId });
				} else if (selectedSearch?.pageStart < numberOfPages) {
					goToNextItem(currentContent);
				}
			}
		} else {
			const targetRect = document.getElementById("pdfContainer_id").getBoundingClientRect();
			if (targetRect) {
				const closestInnerDiv = computeClosestDiv(targetRect, page);
				if (closestInnerDiv) {
					const newSelected = generateSelected(closestInnerDiv, documentId);
					dispatchAndReset(newSelected);
				} else if (dirLeft) {
					goToPreviousItem();
				} else {
					goToNextItem();
				}
			}
		}
	};

	const handleKeyPress = (e) => {
		if (!disableKeyEvents) {
			const pdfDiv = document.getElementById("pdfContainer_id");
			if (pdfDiv) {
				if (e.key === "ArrowDown") {
					pdfDiv.scrollBy({ top: 200 });
				}
				if (e.key === "ArrowUp") {
					pdfDiv.scrollBy({ top: -200 });
				}
				if ((e.key === "ArrowLeft" || e.key === "ArrowRight") && !isThereAnotherFilter && !isSelectionPanelOn) {
					handleArrows(e);
				}
				if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "F" && selectedMenu !== 4) {
					dispatch(setSelectedMenu(4));
					dispatch(setSelectedSearch(null));
					window.sessionStorage.setItem(
						KEY_SMART_VIEW,
						JSON.stringify({
							...(JSON.parse(window.sessionStorage.getItem(KEY_SMART_VIEW)) || {}),
							menu: 4,
						})
					);
				}
			}
		}
	};
	useEffect(
		() => {
			window.addEventListener("keydown", handleKeyPress);
			return () => {
				window.removeEventListener("keydown", handleKeyPress);
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[disableKeyEvents, dispatch, hoveredContent, metadata, mode, page, selectedSearch]
	);

	const location = useLocation();
	const navigate = useNavigate();
	window.onpopstate = () => {
		if (location?.state?.previous && isInMyReviewPage(location)) {
			navigate(location.state.previous);
		}
	};

	return <div />;
}
