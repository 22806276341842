import { FormControl, Grid, Switch, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ApiService, DashboardService } from "../../../../api";
import {
	createNotification,
	CustomButton,
	CustomDatePicker,
	GenericDialog,
	GenericDialogContent,
	GenericDialogFooter,
	GenericDialogHeader,
	Selector,
} from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { convertAnyDateToDisplayDate } from "../../../../common/utils";
import styles from "./EditMilestoneDialog.module.css";

function filterMilestones(list, externalFilter) {
	if (!Array.isArray(list)) {
		return [];
	}
	return list.filter((m) => (m.type === "EXTERNAL") === externalFilter);
}

export default function EditMilestoneDialog({ milestonesList, onEditMilestone, open, onClose }) {
	const [deadline, setDeadline] = useState(null);
	const [name, setName] = useState("");
	const [milestones, setMilestones] = useState([]);
	const [isExternal, setIsExternal] = useState(false);
	const [selectedMilestone, setSelectedMilestone] = useState(null);
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const handleChangeMilestone = (newMilestone) => {
		setSelectedMilestone(newMilestone);
		setDeadline(newMilestone?.date);
		setName(newMilestone?.name || "");
	};
	useEffect(() => {
		const newMilestones = filterMilestones(milestonesList, isExternal);
		setMilestones(newMilestones);
		handleChangeMilestone(newMilestones.length > 0 ? newMilestones[0] : null);
	}, [milestonesList, isExternal]);
	const reset = () => {
		setDeadline(selectedMilestone?.date);
		setName(selectedMilestone?.name);
	};
	const handleClose = () => {
		onClose();
		reset();
	};
	const handleChangeDeadLine = (e) => {
		setDeadline(e);
	};
	const handleChangeName = (e) => {
		setName(e.target.value);
	};
	const handleSelectMilestone = (e) => {
		handleChangeMilestone(e.target.value);
	};
	const handleSubmit = () => {
		handleClose();
		DashboardService.UpdateDeleteUserDefinedMilestone(
			{
				milestoneId: selectedMilestone.id,
			},
			{
				ops: "UPDATE",
				name,
				date: convertAnyDateToDisplayDate(deadline),
				type: selectedMilestone.type,
			},
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				handleClose();
				createNotification({
					type: "success",
					message: translate("dashboards.edit-milestones.success-notification", { name }),
				});
				onEditMilestone();
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleSwitch = () => {
		const newMilestones = filterMilestones(milestonesList, !isExternal);
		setMilestones(newMilestones);
		handleChangeMilestone(newMilestones.length > 0 ? newMilestones[0] : null);
		setIsExternal((prevIsExternal) => !prevIsExternal);
	};
	return (
		<GenericDialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
			<GenericDialogHeader>{translate("dashboards.edit-milestones.dialog.title")}</GenericDialogHeader>
			<GenericDialogContent dividers>
				<Grid container alignItems="center" className={styles.switch}>
					<Grid item>Internal</Grid>
					<Grid item>
						<Switch checked={isExternal} color="primary" name="filterMilestones" onChange={handleSwitch} />
					</Grid>
					<Grid item>External</Grid>
				</Grid>
				<FormControl fullWidth margin="normal">
					{(milestones.length > 0 && (
						<>
							<Selector
								className={`${styles.dialog__input} ${styles["dialog__input--first"]}`}
								field="name"
								items={milestones}
								value={selectedMilestone || ""}
								onChange={handleSelectMilestone}
							/>

							<TextField
								className={styles.dialog__input}
								label={translate("dashboards.edit-milestones.input-label.name")}
								type="text"
								value={name}
								onChange={handleChangeName}
							/>

							<CustomDatePicker
								clearable
								className={styles.dialog__input}
								label={translate("dashboards.add-new-milestones.label.select-date")}
								minDate={new Date()}
								value={deadline}
								onChange={handleChangeDeadLine}
							/>
						</>
					)) || (
						<span>
							{isExternal
								? translate("dashboards.edit-milestones.no-external-milestone")
								: translate("dashboards.edit-milestones.no-internal-milestone")}
						</span>
					)}
				</FormControl>
			</GenericDialogContent>
			<GenericDialogFooter>
				<CustomButton color="primary" variant="outlined" onClick={handleClose}>
					{translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton
					color="primary"
					disabled={selectedMilestone === null}
					variant="contained"
					onClick={handleSubmit}
				>
					{translate("common:btn.submit")}
				</CustomButton>
			</GenericDialogFooter>
		</GenericDialog>
	);
}
