import React, { useEffect, useReducer, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, MenuList } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmDeleteDialog, MoveDialog, SaveFileDialog } from "..";
import {
	CustomButtonLink,
	CustomDialog,
	CustomMenuButton,
	CustomTooltip,
	HorizontalDivider,
	icon,
	IconComponent,
	SidePanel,
	SidePanelContent,
	ViewTabPanel,
	ViewTabs,
} from "../../../../common/components";
import { NavUtils } from "../../../../navigation";
import DocumentCenterPanelTitle from "./document-center-panel-title/DocumentCenterPanelTitle";
import { DocumentCenterInformationTab, DocumentCenterVersionTab } from "./tabs";
import styles from "./DocumentCenterSidePanel.module.css";
import { useApi } from "../../../../common/hooks";
import { ApiService, ProjectDirectoryService, ProjectDocumentService } from "../../../../api";
import {
	getContent,
	getTree,
	ROOT_DIR,
	setHasReceivedModifications,
	setOpenUploadDialog,
} from "../../slice/document-center-slice";
import { compareInsensitiveCaseString, downloadFile } from "../../../../common/utils";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../common/providers";
import { onBeforeUnloadHandler } from "../../utils/utils";
import { SOURCE_TYPES } from "../../../../common/constants";
import UnsupportedDocumentBanner from "./UnsupportedDocumentBanner";
import { FILE_TYPES } from "../../../../common/constants/file-types";
import { DOCUMENT_STATUS } from "../../constants/constants";

export default function DocumentCenterSidePanel({ accessTab = 0, id, onClose, open, status, type }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [selectedTab, setSelectedTab] = useState(0);
	const [downloading, setDownloading] = useState(false);
	const { call: onUploadNewVersion } = useApi(ProjectDocumentService.uploadNewVersion);
	const { call: onDownload } = useApi(ProjectDirectoryService.download);
	const { call: onMove } = useApi(ProjectDirectoryService.moveDirectoryContent);
	const { call: onDelete } = useApi(ProjectDirectoryService.deleteSelection);
	const [openRenameDialog, setOpenRenameDialog] = useState(false);
	const [openMoveDialog, dispatchOpenMoveDialog] = useReducer((prev) => !prev, false);
	const [openConfirmDeleteDialog, dispatchOpenConfirmDeleteDialog] = useReducer((prev) => !prev, false);
	const projectId = useSelector(({ context }) => context.project.id);
	const currentDirectoryId = useSelector(({ documentCenter }) => documentCenter.currentDirectoryId);
	const hasActionPermission = useSelector(({ documentCenter }) => documentCenter.hasActionPermission);
	const { label, version, docType } = useSelector(
		({ documentCenter }) =>
			documentCenter.currentDirectoryContent.contents?.find((c) => c.id === id && c.type === type) || {
				label: "",
				version: 1,
				docType: "",
			}
	);
	const blurButtonRef = useRef(null);

	useEffect(() => () => setSelectedTab(0), []);
	useEffect(() => setSelectedTab(accessTab ?? 0), [accessTab]);
	const handleOpenRenameDialog = () => {
		blurButtonRef?.current.blur();
		setOpenRenameDialog((prev) => !prev);
	};
	const handleUploadVersion = (e) => {
		if (!hasActionPermission) {
			return;
		}
		const targetFiles = e.target.files;
		const file = Object.keys(targetFiles).map((key) => targetFiles[key])[0];
		if (!file) {
			return;
		}
		window.addEventListener("beforeunload", onBeforeUnloadHandler);
		onUploadNewVersion({ prevDocumentId: id }, file, null)
			.then(() => {
				dispatch(setHasReceivedModifications(true));
			})
			.finally(() => {
				window.removeEventListener("beforeunload", onBeforeUnloadHandler);
			});
		dispatch(setOpenUploadDialog(true));
	};
	const handleRedirectToCanvas = () => {
		if (isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS)) {
			navigate(NavUtils.goToRightsOnDocuments(projectId, id));
		}
	};
	const handleDownload = () => {
		setDownloading(true);
		onDownload(
			{ projectId },
			{
				fromDirectoryId: currentDirectoryId,
				directoryIds: type === "folder" ? [id] : [],
				documentIds: type === "folder" ? [] : [id],
			}
		)
			.then(({ data, filename }) => downloadFile({ data, filename, filetype: "zip" }))
			.catch((err) => console.error(err))
			.finally(() => setDownloading(false));
	};
	const handleClose = () => {
		setSelectedTab(0);
		onClose();
	};
	const resetTreeAndContent = () => {
		handleClose();
		const { token } = ApiService.getCancelTokenSource();
		dispatch(getTree({ projectId, token }));
		dispatch(getContent({ projectId, directoryId: currentDirectoryId, token }));
	};
	const handleMove = (selectedDirectoryId) => {
		dispatchOpenMoveDialog();
		onMove(
			{ projectId },
			{
				toDirectoryId: selectedDirectoryId === ROOT_DIR ? null : selectedDirectoryId,
				fromDirectoryId: currentDirectoryId,
				directoryIds: type === "folder" ? [id] : [],
				documentIds: type === "folder" ? [] : [id],
			}
		)
			.then(resetTreeAndContent)
			.catch(console.error);
	};
	const handleDelete = (deleteContent) => {
		if (type === "folder") {
			dispatchOpenConfirmDeleteDialog();
		}
		onDelete(
			{ projectId },
			{
				fromDirectoryId: currentDirectoryId,
				deleteContent,
				directoryIds: type === "folder" ? [id] : [],
				documentIds: type === "folder" ? [] : [id],
			}
		)
			.then(resetTreeAndContent)
			.catch(console.error);
	};

	const handleOpenDeleteConfirmation = () => {
		if (type === "folder") {
			dispatchOpenConfirmDeleteDialog();
		} else {
			handleDelete(false);
		}
	};

	const tabs = (type === "folder" && [{ label: translate("document-center.side-panel.information") }]) || [
		{ label: translate("document-center.side-panel.information") },
		{ label: translate("document-center.side-panel.versions") },
	];
	const isFile = type !== "folder";
	const isUnsupported = useMemo(() => compareInsensitiveCaseString(type, FILE_TYPES.UNSUPPORTED), [type]);
	const isOngoing = useMemo(
		() => status === DOCUMENT_STATUS.IN_PROGRESS || status === DOCUMENT_STATUS.PENDING,
		[status]
	);
	return (
		<SidePanel offset={10} open={open} size={75} onClose={handleClose}>
			<SidePanelContent
				secondaryActions={
					<span className={styles.secondaryActions}>
						{isFile &&
							hasActionPermission &&
							!isUnsupported &&
							!isOngoing &&
							isSegFeatureEnabled(SegFlags.VERSIONS) && (
								<CustomTooltip arrow title={translate("document-center.side-panel.versions.add")}>
									<label className={styles.secondaryActions__input} htmlFor="import">
										<input
											hidden
											accept=".doc,.docx,.odt,.pdf,.rtf,.xls,.xlsm,.xlsx,.zip"
											aria-label="Upload new version"
											id="import"
											name="import"
											type="file"
											onChange={handleUploadVersion}
										/>
										<IconComponent color="var(--color-light-grey-2)" icon={icon.faPlus} size="lg" />
									</label>
								</CustomTooltip>
							)}
						{(hasActionPermission || isFile) && (
							<CustomMenuButton
								icon={icon.faEllipsisV}
								iconColor="var(--color-light-grey-2)"
								variant="text"
							>
								<MenuList className={styles.menu}>
									{isFile &&
										isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS) &&
										!isUnsupported &&
										!isOngoing && (
											<MenuItem onClick={handleRedirectToCanvas}>
												<span className={styles.menu__item}>
													<div>
														<IconComponent
															color="var(--color-light-grey-2)"
															icon={icon.faUserFriends}
														/>
													</div>
													<span className={styles.menu__text}>
														{translate("document-center.side-panel.menu.rights")}
													</span>
												</span>
											</MenuItem>
										)}
									{hasActionPermission && (
										<MenuItem onClick={handleDownload}>
											<span className={styles.menu__item}>
												<div>
													<IconComponent
														color="var(--color-light-grey-2)"
														icon={icon.faDownload}
													/>
												</div>
												<span className={styles.menu__text}>
													{translate("document-center.side-panel.menu.download")}
												</span>
											</span>
										</MenuItem>
									)}
									{hasActionPermission && !isOngoing && (
										<MenuItem onClick={dispatchOpenMoveDialog}>
											<span className={styles.menu__item}>
												<div>
													<IconComponent
														color="var(--color-light-grey-2)"
														icon={icon.faArrowRight}
													/>
												</div>
												<span className={styles.menu__text}>
													{translate("document-center.side-panel.menu.move")}
												</span>
											</span>
										</MenuItem>
									)}
									{hasActionPermission && <HorizontalDivider />}
									{hasActionPermission && (
										<MenuItem onClick={handleOpenDeleteConfirmation}>
											<span className={styles.menu__item}>
												<div>
													<IconComponent
														color="var(--color-light-grey-2)"
														icon={icon.faTrash}
													/>
												</div>
												<span className={styles.menu__text}>
													{version > 1
														? translate(
																"document-center.side-panel.menu.delete-last-version"
														  )
														: translate("document-center.side-panel.menu.delete")}
												</span>
											</span>
										</MenuItem>
									)}
								</MenuList>
							</CustomMenuButton>
						)}
						{isFile && isSegFeatureEnabled(SegFlags.SMART_REVIEW) && (
							<CustomButtonLink
								color="primary"
								state={{ previous: location.pathname }}
								to={NavUtils.goToSmartReview({
									projectId,
									documentId: id,
									source: SOURCE_TYPES.DOC_CENTER,
								})}
								variant="contained"
							>
								{translate("document-center.side-panel.open-document")}
							</CustomButtonLink>
						)}
					</span>
				}
				title={
					<DocumentCenterPanelTitle
						ref={blurButtonRef}
						docType={docType}
						title={label}
						onRename={handleOpenRenameDialog}
					/>
				}
				onClose={handleClose}
			>
				<ViewTabs selectedTab={selectedTab} tabs={tabs} onChange={(_, tab) => setSelectedTab(tab)} />
				<ViewTabPanel
					noPadding
					className={styles.tabPanel}
					data-is-unsupported={isUnsupported}
					index={0}
					value={selectedTab}
				>
					{isUnsupported && <UnsupportedDocumentBanner />}
					<DocumentCenterInformationTab id={id} status={status} type={type} />
				</ViewTabPanel>
				{isFile && (
					<ViewTabPanel
						noPadding
						className={styles.tabPanel}
						data-is-unsupported={isUnsupported}
						index={1}
						value={selectedTab}
					>
						{isUnsupported && <UnsupportedDocumentBanner />}
						<DocumentCenterVersionTab
							id={id}
							isOngoing={isOngoing}
							type={type}
							onAddVersion={handleUploadVersion}
						/>
					</ViewTabPanel>
				)}
				<SaveFileDialog
					docType={docType}
					fileId={id}
					isUnsupported={isUnsupported}
					open={openRenameDialog}
					type={isFile ? "file" : "folder"}
					value={label}
					onClose={handleOpenRenameDialog}
				/>
				<MoveDialog
					include={false}
					numberOfElements={1}
					open={openMoveDialog}
					selectedFolders={isFile ? [] : [id]}
					onClose={dispatchOpenMoveDialog}
					onMove={handleMove}
				/>
				<ConfirmDeleteDialog
					isFolder={!isFile}
					open={openConfirmDeleteDialog}
					text={translate(
						isFile
							? "document-center.side-panel.confirm-delete.text.file"
							: "document-center.side-panel.confirm-delete.text.folder"
					)}
					textSubDir={translate("document-center.side-panel.confirm-delete.sub-text")}
					onClose={dispatchOpenConfirmDeleteDialog}
					onConfirm={handleDelete}
				/>
				<CustomDialog
					loader
					maxWidth="xs"
					open={downloading}
					subTitle={translate("document-center.download-process-dialog.file.helper-text")}
					title={translate("document-center.download-process-dialog.file.main-text")}
				/>
			</SidePanelContent>
		</SidePanel>
	);
}
