import { createAsyncThunk } from "@reduxjs/toolkit";
import { DocumentService, ReqViewService, SmartViewService, ValidatorService } from "../../../../api";
import { isSegFeatureEnabled, SegFlags } from "../../../../common/providers";
import { ROLES } from "../../constants/constants";

function getDocId(getState) {
	const {
		srDocument: { documentId },
	} = getState();
	return documentId;
}

function getProjectId(getState) {
	const {
		context: {
			project: { id: projectId },
		},
	} = getState();
	return projectId;
}

export const getPage = createAsyncThunk("srPdf/getPage", ({ docId, page, token }) =>
	DocumentService.getPage({ docId }, { page }, token)
);
export const getMetadata = createAsyncThunk("srPdf/getMetadata", ({ mode, page, token }, { getState }) => {
	const docId = getDocId(getState);
	if (mode === ROLES.EDITOR.mode) {
		if (!isSegFeatureEnabled(SegFlags.REDRESSEMENT)) {
			return null;
		}
		const projectId = getProjectId(getState);
		return SmartViewService.getRedressment({ projectId, docId }, { page }, token);
	}
	if (mode === ROLES.VALIDATOR.mode) {
		return ValidatorService.getContents({ docId }, { page }, token);
	}
	return ReqViewService.getContents({ docId }, { page }, token);
});
export const getDeletedData = createAsyncThunk("srPdf/getDeletedData", ({ docId, page, token }) =>
	ReqViewService.getDeletedContents({ docId }, { page }, token)
);
