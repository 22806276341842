import React, { useState, useEffect, useRef } from "react";
import { DialogTitle, DialogContent, DialogActions, TextField, InputAdornment, CircularProgress } from "@mui/material";
import { debounce } from "../../../../common/utils";
import { UserService, ApiService } from "../../../../api";
import styles from "./InviteMembersDialog.module.css";
import { Permissions, translate } from "../../../../common/providers";
import { CustomIconButton, icon, GenericDialog, CustomButton } from "../../../../common/components";

let isSubscribed = false;

const deboucedVerify = debounce(({ setIsLoadingVerif, setErrors, value }, cancelTokenSource) => {
	if (!value) {
		setIsLoadingVerif(false);
		return;
	}
	UserService.verifyEmail({ email: value }, cancelTokenSource.token)
		.then((data) => {
			if (isSubscribed) {
				setErrors((prev) => ({ ...prev, emailTaken: data }));
				setIsLoadingVerif(false);
			}
		})
		.catch((err) => (isSubscribed ? console.error(err) : null));
}, 1000);

export default function InviteMembersDialog({ open, onClose, projectId, companyId, companyName, addUser }) {
	const [isLoadingVerif, setIsLoadingVerif] = useState(false);
	const [payload, setPayload] = useState({
		firstname: "",
		lastname: "",
		email: "",
		role: Permissions.COMPANY_MEMBER.role,
	});
	const [errors, setErrors] = useState({ firstname: false, lastname: false, email: false });

	const reset = () => {
		setPayload({ firstname: "", lastname: "", email: "", role: Permissions.COMPANY_MEMBER.role });
		setErrors({ firstname: false, lastname: false, email: false, emailTaken: false });
		setIsLoadingVerif(false);
	};
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		isSubscribed = true;
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
			isSubscribed = false;
			reset();
		};
	}, []);

	const handleCloseAndAction = () => {
		if (Object.keys(errors).some((key) => errors[key]) || Object.keys(payload).some((key) => !payload[key])) {
			Object.keys(payload).forEach((key) => !payload[key] && setErrors((prev) => ({ ...prev, [key]: true })));
			return;
		}

		addUser({ isNew: true, user: payload });
		onClose();
	};

	const handleChange = (field, value) => {
		if (!field) {
			return;
		}
		if (!value) {
			setErrors((prev) => ({ ...prev, [field]: true }));
		} else {
			setErrors((prev) => ({ ...prev, [field]: false }));
		}
		if (field === "email" && value) {
			setIsLoadingVerif(true);
			deboucedVerify(
				{
					setIsLoadingVerif,
					setErrors,
					value,
				},
				cancelTokenSourceRef.current
			);
		}
		setPayload((prev) => ({ ...prev, [field]: value }));
	};

	useEffect(() => {
		const cancelTokenSource = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSource);
		};
	}, [projectId, companyId]);

	return (
		<GenericDialog disableBackdropClick fullWidth open={open} onClose={onClose} onExited={reset}>
			<DialogTitle>
				<div className={styles.dialog__title}>
					<span className={styles["dialog__title-text"]}>
						{translate("team-management.add-user-dialog.title", { companyName })}
					</span>
					<CustomIconButton icon={icon.faTimes} onClick={onClose} />
				</div>
			</DialogTitle>
			<DialogContent dividers className={styles.dialog__content}>
				<div>
					<TextField
						fullWidth
						required
						className={styles.dialog__field}
						error={errors && (errors.emailTaken || errors.email)}
						helperText={
							errors &&
							((errors.emailTaken && translate("team-management.add-user-dialog.error.email-taken")) ||
								(errors.email && "Required"))
						}
						InputProps={
							isLoadingVerif
								? {
										endAdornment: (
											<InputAdornment position="end">
												<CircularProgress size={25} thickness={3} />
											</InputAdornment>
										),
								  }
								: null
						}
						label="Email"
						value={(payload && payload.email) || ""}
						variant="outlined"
						onChange={(e) => handleChange("email", e.target.value)}
					/>
					<TextField
						fullWidth
						required
						className={styles.dialog__field}
						error={errors && errors.firstname}
						helperText={errors && errors.firstname && "Required"}
						label={translate("team-management.add-user-dialog.firstname")}
						value={(payload && payload.firstname) || ""}
						variant="outlined"
						onChange={(e) => handleChange("firstname", e.target.value)}
					/>
					<TextField
						fullWidth
						required
						className={styles.dialog__field}
						error={errors && errors.lastname}
						helperText={errors && errors.lastname && "Required"}
						label={translate("team-management.add-user-dialog.lastname")}
						value={(payload && payload.lastname) || ""}
						variant="outlined"
						onChange={(e) => handleChange("lastname", e.target.value)}
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<CustomButton color="primary" variant="outlined" onClick={onClose}>
					{translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton color="primary" variant="contained" onClick={handleCloseAndAction}>
					{translate("common:btn.submit")}
				</CustomButton>
			</DialogActions>
		</GenericDialog>
	);
}
