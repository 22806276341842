/**
 * Prompts a user when they exit the page
 */

import { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

function useConfirmExit(confirmExit, when = true, action = null) {
	const { navigator } = useContext(NavigationContext);
	useEffect(() => {
		if (!when) {
			// eslint-disable-next-line
			return;
		}
		const { push } = navigator;
		navigator.push = (...args) => {
			const result = confirmExit();

			if (result !== false) {
				if (result && action) {
					action();
				}
				push(...args);
			}
		};

		// eslint-disable-next-line
		return () => {
			navigator.push = push;
		};
	}, [action, navigator, confirmExit, when]);
}

export function usePromptNavigation({ message, when = true, action = null }) {
	useEffect(() => {
		if (when) {
			window.onbeforeunload = () => message;
		}
		return () => {
			window.onbeforeunload = null;
		};
	}, [message, when]);

	const confirmExit = useCallback(
		() =>
			// eslint-disable-next-line
			window.confirm(message),
		[message]
	);
	useConfirmExit(confirmExit, when, action);
}
