import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
	AnalyticsProvider,
	Flags,
	hasCompanyPermission,
	hasPermission,
	isFeatureEnabled,
	isSegFeatureEnabled,
	Permissions,
	SegFlags,
	translate,
	translateEnumProjectRole,
} from "../../common/providers";
import { capitalize } from "../../common/utils";
import styles from "./TeamManagement.module.css";
import { AddPartnerDialog, AddConsortiumDialog, Members, Panel, UpdateCompanyDialog } from "./components";
import { ApiService, ProjectTeamService, RoleService } from "../../api";
import { ViewBanner } from "../../common/components";
import { exportView } from "../../navigation";
import PrivacySidePanel from "./components/privacy-side-panel/PrivacySidePanel";

function searchCompanyInTreeById(tree, companyId) {
	if (!Array.isArray(tree) || tree.length === 0 || !companyId) {
		return null;
	}
	let found = null;
	for (const company of tree) {
		if (found) {
			break;
		}
		if (company.id === companyId) {
			found = company;
			break;
		}
		found = searchCompanyInTreeById(company.companies, companyId);
	}
	return found;
}

const TeamManagement = () => {
	const params = useParams();
	const projectId = useMemo(() => params.projectId, [params.projectId]);
	const showPartners = useMemo(
		() =>
			isSegFeatureEnabled(SegFlags.PARTNERS) &&
			isFeatureEnabled(Flags.PARTNERS) &&
			hasPermission(Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER),
		[]
	);
	const [companies, setCompanies] = useState([]);
	const [companyProjectRoles, setCompanyProjectRoles] = useState([]);
	const [userProjectRoles, setUserProjectRoles] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [openAddPartnerDialog, setOpenAddPartnerDialog] = useState(false);
	const [openAddConsortiumDialog, setOpenAddConsortiumDialog] = useState(false);
	const [partnerLoading, setPartnerLoading] = useState(false);
	// isProjectDirector Company role [LEADER, MANAGER]; User role [LEADER]
	const [isProjectDirector, setIsProjectDirector] = useState(false);
	const [openEditCompany, setOpenEditCompany] = useState(false);
	const [editCompany, setEditCompany] = useState(null);
	const [myCompany, setMyCompany] = useState(null);
	const [openPrivacySidePanel, setOpenPrivacySidePanel] = useState(false);
	const cancelTokenSourceRef = useRef(null);
	const updateCancelTokenSourceRef = useRef(null);
	const reduxCompany = useSelector(({ context }) => context.company);

	useEffect(() => {
		const documentTitle = translate("team-management.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Team" });
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		updateCancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
			ApiService.cancelTokens(updateCancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (!Array.isArray(userProjectRoles) || userProjectRoles.length === 0) {
			RoleService.listUserInProject(cancelTokenSourceRef.current.token)
				.then((data) =>
					setUserProjectRoles(data.map((r) => ({ name: r, displayName: translateEnumProjectRole(r) })))
				)
				.catch((err) => {
					console.error(err);
				});
		}
	}, [userProjectRoles]);
	useEffect(() => {
		if (!Array.isArray(companyProjectRoles) || companyProjectRoles.length === 0) {
			RoleService.listCompanyInProject(cancelTokenSourceRef.current.token)
				.then((data) => setCompanyProjectRoles(data.map((r) => ({ name: r, displayName: capitalize(r) }))))
				.catch((err) => {
					console.error(err);
				});
		}
	}, [companyProjectRoles]);
	useEffect(() => {
		if (!isSegFeatureEnabled(SegFlags.PARTNERS)) {
			return;
		}
		setPartnerLoading(true);
		ProjectTeamService.getCompanies({ projectId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				const newMyCompany = searchCompanyInTreeById(data, reduxCompany.id);
				const newIsProjectDirector =
					(newMyCompany.role === Permissions.PROJECT_LEADER.role ||
						newMyCompany.role === Permissions.PROJECT_MANAGER.role) &&
					hasPermission(Permissions.PROJECT_LEADER);
				setPartnerLoading(false);
				setCompanies(data);
				setSelectedCompany(newMyCompany);
				setIsProjectDirector(newIsProjectDirector);
				setMyCompany(newMyCompany);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [reduxCompany.id, projectId]);
	useEffect(() => {
		ApiService.cancelTokens(updateCancelTokenSourceRef.current);
		updateCancelTokenSourceRef.current = ApiService.getCancelTokenSource();
	}, [selectedCompany]);

	const handleSelectCompany = (company) => {
		setSelectedCompany(company);
	};
	// If the user is PENDING, then it's invitation to the company has been revoked
	// and therefore he does not belong to it anymore. Hence he is no longer active
	const handleUpdateActiveUsersCount = (isUserActive, amount) => {
		const company = { ...selectedCompany };
		if (Object.keys(company).length === 0) {
			return;
		}
		if (amount) {
			company.activeUsers = isUserActive ? company.activeUsers + amount : company.activeUsers - amount;
		} else {
			company.activeUsers = isUserActive ? company.activeUsers + 1 : company.activeUsers - 1;
		}
		setSelectedCompany(company);
	};

	const onChangeCompanyAccess = ({ company }) => {
		if (!company || !selectedCompany) {
			return;
		}
		const tempCompanyId = company.id;
		const isBlocked = company.blocked;
		ProjectTeamService.changeCompanyAccess(
			{ projectId, companyId: tempCompanyId },
			isBlocked,
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				const cny = searchCompanyInTreeById(companies, tempCompanyId);
				if (cny) {
					cny.blocked = !isBlocked;
					if (!isBlocked && cny.users) {
						for (const user of cny.users) {
							user.blocked = !isBlocked;
						}
					}
					const tempCompanies = companies.map((c) => (c.id === cny.id ? cny : c));
					setCompanies(tempCompanies);
				}
				if (!isBlocked && selectedCompany.id === company.id) {
					setSelectedCompany(cny);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleAddPartnerDialog = () => setOpenAddPartnerDialog(!openAddPartnerDialog);

	const handleAddConsortiumDialog = () => setOpenAddConsortiumDialog(!openAddConsortiumDialog);

	const handleUpdateAllCompanies = (updateCompanies) => {
		const selectedCompanyInCompanies = searchCompanyInTreeById(updateCompanies, selectedCompany?.id);
		selectedCompanyInCompanies.users = selectedCompany?.users || null;
		setCompanies(Array.isArray(updateCompanies) ? updateCompanies : []);
		setSelectedCompany(selectedCompanyInCompanies);
	};

	const handleAddConsortium = (payload) => {
		if (!payload) {
			return;
		}
		ProjectTeamService.createConsortium({ projectId }, payload, cancelTokenSourceRef.current.token)
			.then((data) => handleUpdateAllCompanies(data))
			.catch((err) => {
				console.error(err);
			});
	};

	const handleUpdateCompany = (payload) => {
		if (!editCompany || !payload) {
			return;
		}
		ProjectTeamService.updateCompanyChildren(
			{ projectId, companyId: editCompany.id },
			payload,
			cancelTokenSourceRef.current.token
		)
			.then((data) => handleUpdateAllCompanies(data))
			.catch((err) => {
				console.error(err);
			});
	};

	const handleEditCompany = ({ company }) => {
		if (!company) {
			return;
		}
		setOpenEditCompany(true);
		setEditCompany(company);
	};

	const handleCloseEditCompanyDialog = () => {
		setOpenEditCompany(false);
		setEditCompany(null);
	};

	const handleOpenPrivacySidePanel = () => {
		setOpenPrivacySidePanel(true);
	};

	const handleClosePrivacySidePanel = () => {
		setOpenPrivacySidePanel(false);
	};

	const isMyCompanyProjectLeader = hasCompanyPermission(Permissions.PROJECT_LEADER);
	const isUserProjectLeader = hasPermission(Permissions.PROJECT_LEADER);

	return (
		<>
			<ViewBanner
				titles={[
					{ title: translate("navigation:project.project-configuration"), key: "projectAdmin" },
					{ title: translate("navigation:home.team") },
				]}
			/>
			<div className={styles.container}>
				{showPartners && (
					<Panel
						companies={companies}
						isLoading={partnerLoading}
						isProjectDirector={isProjectDirector}
						isUserProjectLeader={isUserProjectLeader}
						selectedCompany={selectedCompany}
						title={translate("team-management.partners.title")}
						onAddPartner={handleAddConsortiumDialog}
						onChangeCompanyAccess={onChangeCompanyAccess}
						onClickPrivacy={handleOpenPrivacySidePanel}
						onEdit={handleEditCompany}
						onSelectCompany={handleSelectCompany}
					/>
				)}
				{((!isSegFeatureEnabled(SegFlags.PARTNERS) && reduxCompany) || (selectedCompany && myCompany)) && (
					<Members
						isMyCompanyProjectLeader={isMyCompanyProjectLeader}
						isUserProjectLeader={isUserProjectLeader}
						isUserProjectManager={hasPermission(Permissions.PROJECT_MANAGER)}
						myCompany={myCompany || reduxCompany}
						projectId={projectId}
						projectRoles={userProjectRoles}
						selectedCompany={selectedCompany || reduxCompany}
						onUpdateActiveUsersCount={handleUpdateActiveUsersCount}
					/>
				)}
				{isProjectDirector && openAddPartnerDialog && (
					<AddPartnerDialog open={openAddPartnerDialog || false} onClose={handleAddPartnerDialog} />
				)}
				{isProjectDirector && openAddConsortiumDialog && (
					<AddConsortiumDialog
						open={openAddConsortiumDialog || false}
						projectId={projectId}
						onClose={handleAddConsortiumDialog}
						onSubmit={handleAddConsortium}
					/>
				)}
				{isProjectDirector && openEditCompany && (
					<UpdateCompanyDialog
						company={editCompany}
						open={openEditCompany || false}
						projectId={projectId}
						onClose={handleCloseEditCompanyDialog}
						onSubmit={handleUpdateCompany}
					/>
				)}
			</div>
			{isUserProjectLeader && (
				<PrivacySidePanel
					companyId={myCompany?.id}
					companyName={myCompany?.name}
					isMyCompanyProjectLeader={isMyCompanyProjectLeader}
					open={openPrivacySidePanel}
					projectId={projectId}
					onClose={handleClosePrivacySidePanel}
				/>
			)}
		</>
	);
};

export default exportView({
	path: "/projects/:projectId/team",
	localesPath: "/team-management/locales",
	component: TeamManagement,
	flag: Flags.PARTNERS,
	segFlag: SegFlags.TEAM,
});
