import React from "react";
import { MentionsInput, Mention } from "react-mentions";
import styles from "./AutocompleteTextField.module.css";

const AutocompleteTextField = ({
	autoFocus = false,
	classNames,
	placeholder = "",
	data = [],
	fullWidth,
	onAdd = null,
	onBlur,
	onChange,
	onFocus,
	onKeyDownCapture,
	rows,
	size,
	trigger,
	value = "",
}) => {
	let style = {};
	if (rows) {
		style = {
			input: {
				overflow: "auto",
				height: 16 * rows + 18,
			},
			highlighter: {
				boxSizing: "border-box",
				overflow: "hidden",
				height: 16 * rows + 18,
			},
		};
	}
	const combinedStyle = { ...styles, ...classNames };
	return (
		<MentionsInput
			allowSpaceInQuery
			autoFocus={autoFocus}
			className="mentions"
			classNames={combinedStyle}
			fullWidth={fullWidth}
			placeholder={placeholder}
			rows={rows}
			size={size}
			spellCheck="false"
			style={style}
			value={value}
			onBlur={onBlur}
			onChange={onChange}
			onFocus={onFocus}
			onKeyDownCapture={onKeyDownCapture}
		>
			<Mention className={styles.mentions__mention} data={data} trigger={trigger ?? null} onAdd={onAdd} />
		</MentionsInput>
	);
};

export default AutocompleteTextField;
