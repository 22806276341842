import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomTooltip, icon } from "../..";
import styles from "./ToggleButtons.module.css";

export default function ToggleButtons({
	btnClassName = "",
	className = "",
	content = [],
	disabled = false,
	exclusive = false,
	labelized = false,
	onChange,
	size = "small",
	value,
}) {
	return (
		<ToggleButtonGroup className={className} exclusive={exclusive} size={size} value={value} onChange={onChange}>
			{Array.isArray(content) &&
				content.map((toggle) => (
					<ToggleButton
						key={toggle.value}
						className={`${styles.toggleBtns__btn} ${btnClassName}`}
						disabled={disabled}
						value={toggle.value}
					>
						{(toggle.icon &&
							((toggle.tooltip && (
								<CustomTooltip title={toggle.tooltip}>
									<span className={styles.toggleBtn__icon}>
										<FontAwesomeIcon icon={icon[toggle.icon]} />
										{labelized && (toggle.label || toggle.tooltip || toggle.value)}
									</span>
								</CustomTooltip>
							)) || (
								<span className={styles.toggleBtn__icon}>
									<FontAwesomeIcon icon={icon[toggle.icon]} />
									{labelized && (toggle.label || toggle.tooltip || toggle.value)}
								</span>
							))) ||
							toggle.label ||
							toggle.tooltip ||
							toggle.value}
					</ToggleButton>
				))}
		</ToggleButtonGroup>
	);
}
