import React from "react";
import { Select, MenuItem, FormControl, InputLabel, Collapse } from "@mui/material";
import styles from "./EditReqDialog.module.css";
import { translate, translateEnumParam } from "../../../../../common/providers";
import { CustomIconButton, icon } from "../../../../../common/components";

export default function ParamsSection({ params, collapsed, selectedParams, onChangeParams, onCollaspe }) {
	return (
		<section className={styles["dialog-content__section"]}>
			<span className={styles["dialog-content__section-title"]}>
				<CustomIconButton icon={collapsed ? icon.faChevronDown : icon.faChevronRight} onClick={onCollaspe} />
				{translate("requirement-matrix.param-section.parameters")}
			</span>
			<Collapse unmountOnExit in={collapsed} timeout="auto">
				<div className={styles["dialog-content__section-params"]}>
					{params &&
						Object.keys(params).map((param) => (
							<FormControl
								key={param}
								fullWidth
								className={styles["dialog-content__section-param"]}
								variant="outlined"
							>
								<InputLabel className={styles["dialog-content__input-label"]} translate="no">
									{translateEnumParam(param)}
								</InputLabel>
								<Select
									size="small"
									translate="no"
									value={(selectedParams && selectedParams[param]) || ""}
									variant="outlined"
									onChange={(e) => onChangeParams(param, e.target.value)}
								>
									<MenuItem value="">
										<em>{translate("common:empty-state.none")}</em>
									</MenuItem>
									{params[param].map((p) => (
										<MenuItem key={p} translate="no" value={p}>
											{translateEnumParam(param, p)}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						))}
				</div>
			</Collapse>
		</section>
	);
}
