import React from "react";
import styles from "./EmptyState.module.css";

export default function EmptyState({ btn1, btn2 = null, className = "", content, link, linkAddress, title }) {
	return (
		<div className={`${styles.emptyState__wrapper} ${className}`}>
			<header className={styles.emptyState__header}>{title}</header>
			<main className={styles.emptyState__body}>
				{content}
				<a href={linkAddress} rel="noopener noreferrer" target="_blank">
					{link}
				</a>
			</main>
			<footer className={styles.emptyState__footer}>
				{btn1}
				{btn2}
			</footer>
		</div>
	);
}
