import React from "react";
import { Collapse } from "@mui/material";
import styles from "./EditUserDialog.module.css";
import { CustomIconButton, icon } from "../../../../common/components";
import PasswordInput from "./PasswordInput";

export default function PasswordSection({ collapsed, onChange, onCollapse, title }) {
	return (
		<section className={styles["dialog-content__section"]}>
			<span className={styles["dialog-content__section-title"]}>
				<CustomIconButton icon={collapsed ? icon.faChevronDown : icon.faChevronRight} onClick={onCollapse} />
				{title}
			</span>
			<Collapse unmountOnExit in={collapsed} timeout="auto">
				<div className={styles["dialog-content__password"]}>
					<PasswordInput onChange={onChange} />
				</div>
			</Collapse>
		</section>
	);
}
