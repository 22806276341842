import { Accordion, AccordionSummary } from "@mui/material";
import React, { useCallback, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Permissions,
	translate,
	hasCompanyPermission,
	hasPermission,
	translateDate,
	isSegFeatureEnabled,
	SegFlags,
} from "../../../../../../../../../common/providers";
import { CircularLoader, IconComponent, icon, Preview } from "../../../../../../../../../common/components";
import { ApiService, ContentService, DocumentService, VersionRedressmentService } from "../../../../../../../../../api";
import styles from "./VersioningTabView.module.css";
import { setDocumentInfo } from "../../../../../../../slice/document/document-slice";
import { setHasVersioningUpdate, updateNode } from "../../../../../../../slice/pdf/pdf-slice";
import { useApi } from "../../../../../../../../../common/hooks";
import AccordionUploaded from "./versioning-tab-accordion/AccordionUploaded";
// import AccordionOriginal from "./versioning-tab-accordion/AccordionOriginal";

const convertStatus = (status) => {
	let newStatus = status.replace("UPDATED", "MODIFIED");
	if (newStatus.includes("MODIFIED") && !newStatus.includes("MINOR")) {
		newStatus = "MODIFIED";
	}
	return newStatus;
};
export default function VersioningTabView({ informationId, pageStart, selectedMode }) {
	const { call, cancel } = useApi(DocumentService.getVersions);
	const [original, setOriginal] = useState(null);
	const [previousUpload, setPreviousUpload] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [displayError, setDisplayError] = useState(false);
	const [coordinates, setCoordinates] = useState([]);
	const [preview, setPreview] = useState(null);
	// const [originalContent, setOriginalContent] = useState("");
	const [content, setContent] = useState("");
	const [idValue, setIdValue] = useState(null);
	const [status, setStatus] = useState("");
	const [infoUpdated, setInfoUpdated] = useState(false);
	const [uploadedInfo, setUploadedInfo] = useState(true);
	const docId = useSelector(({ srDocument }) => srDocument.documentId);
	const versionStatuses = useSelector(({ srDocument }) => srDocument.versionStatuses);
	const previousVersion = useSelector(
		({ srDocument }) => srDocument.previousVersionId || srDocument.previousVersionName
	);
	const documentInfo = useSelector(({ srDocument }) => srDocument);
	const dispatch = useDispatch();
	const cancelTokenSourceRef = useRef(null);
	const fetchPreviousDetails = useCallback(() => {
		if (previousVersion) {
			VersionRedressmentService.getPreviousInformationDetails(
				{ informationId },
				cancelTokenSourceRef.current.token
			)
				.then((data) => {
					setOriginal(data);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
		setIsLoading(false);
	}, [informationId, previousVersion]);
	useEffect(() => {
		if (cancelTokenSourceRef.current) {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		}
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		if (isSegFeatureEnabled(SegFlags.VERSIONS)) {
			setIsLoading(true);
			if (informationId) {
				fetchPreviousDetails();
			}
		}
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [informationId, fetchPreviousDetails]);
	useEffect(() => {
		if (!documentInfo) {
			DocumentService.getInformation({ docId }, cancelTokenSourceRef.current.token)
				.then((docInfo) => dispatch(setDocumentInfo(docInfo)))
				.catch((error) => console.error(error));
		}
	}, [docId, documentInfo, dispatch]);
	useEffect(() => {
		if (original?.previousInfoId) {
			setIdValue(original?.previousInfoId);
		} else {
			setIdValue(0);
		}
		setStatus(original?.status || "NEW");
		if (original?.previousContent) {
			setContent(original?.content);
			// setOriginalContent(original?.previousContent);
		}
	}, [original]);
	useEffect(() => {
		if (isSegFeatureEnabled(SegFlags.VERSIONS)) {
			call({ docId })
				.then((versions) => {
					setPreviousUpload(versions[1]?.uploaded);
				})
				.catch(console.error);
		}
		return () => {
			cancel();
		};
	}, [docId, call, cancel]);
	const handleApply = () => {
		const payload = { idv2: informationId, status };
		if (status !== "NEW") {
			payload.idv1 = Number.parseInt(idValue, 10);
		}
		VersionRedressmentService.updateInformationVersion(payload, cancelTokenSourceRef.current.token)
			.then(() => {
				dispatch(
					updateNode({
						mode: selectedMode,
						pageConditions: { page: pageStart },
						informationId,
						updates: [{ property: "infoVersionStatus", value: convertStatus(status) }],
					})
				);
				dispatch(setHasVersioningUpdate(true));
				fetchPreviousDetails(informationId);
			})
			.catch((err) => console.error(err));
		setInfoUpdated(false);
	};
	const handleChangeInformationId = (e) => {
		const { value } = e.target;
		if (value > 0) {
			setDisplayError(false);
			setIdValue(value);
			if (value === original?.previousInfoId.toString()) {
				setInfoUpdated(false);
			} else {
				setInfoUpdated(true);
			}
		}
	};

	const handleClickDisplay = (documentId, pageNumber) => {
		setPreview({
			documentId,
			projectId: documentInfo.projectId,
			page: pageNumber,
		});
		ContentService.getContentCoordinatesFromInformation(
			{
				docId: documentId,
				informationId: idValue,
			},
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				setCoordinates(data);
			})
			.catch((err) => console.error(err));
	};
	const handleCloseDisplay = () => setPreview(null);
	const handleExpandUploadedInfo = () => {
		setUploadedInfo((prev) => !prev);
	};
	const handleSelect = (e) => {
		const { value } = e.target;
		setStatus(value);
		if (value === original?.status) {
			setInfoUpdated(false);
		} else {
			setInfoUpdated(true);
		}
	};
	const hasLeaderPermissions =
		hasCompanyPermission(Permissions.PROJECT_LEADER) && hasPermission(Permissions.PROJECT_LEADER);
	return (
		<div className={styles.container}>
			{isSegFeatureEnabled(SegFlags.VERSIONS) ? (
				(documentInfo?.previousVersionId && original && !isLoading && (
					<div className={styles.versioningTabView}>
						<Accordion expanded={uploadedInfo} onChange={handleExpandUploadedInfo}>
							<AccordionSummary
								className={styles.accordionSummary}
								expandIcon={<IconComponent icon={icon.faChevronDown} />}
							>
								<div className={styles.subtitle}>
									{documentInfo?.uploaded &&
										translate("smart-review.cmp-details.option-panel.versioning.uploaded-date", {
											informationDate: translateDate(documentInfo?.uploaded),
										})}
								</div>
							</AccordionSummary>
							<AccordionUploaded
								content={content}
								dispatch={dispatch}
								displayError={displayError}
								documentInfo={documentInfo}
								handleApply={handleApply}
								handleChange={handleChangeInformationId}
								handleClickDisplay={handleClickDisplay}
								handleSelect={handleSelect}
								hasLeaderPermissions={hasLeaderPermissions}
								idValue={idValue}
								informationId={informationId}
								infoUpdated={infoUpdated}
								original={original}
								previousUpload={previousUpload}
								previousVersion={previousVersion}
								status={status}
								versionStatuses={versionStatuses}
							/>
						</Accordion>
						{/* Hidden since it is complicate to display OG version. But we might do it later on */}
						{/* {original.previousContent && (
							<>
								<Accordion>
									<AccordionSummary
										className={styles.accordionSummary}
										expandIcon={<IconComponent icon={icon.faChevronDown} />}
									>
										<div className={styles.subtitle}>
											{documentInfo?.uploaded &&
												translate(
													"smart-review.cmp-details.option-panel.versioning.original-date",
													{
														informationDate: translateDate(previousUpload),
													}
												)}
										</div>
									</AccordionSummary>
									<AccordionOriginal
										documentInfo={documentInfo}
										handleClickDisplay={handleClickDisplay}
										original={original}
										originalContent={originalContent}
										previousUpload={previousUpload}
										previousVersion={previousVersion}
									/>
								</Accordion>
							</>
						)} */}
					</div>
				)) ||
				(!isLoading && (
					<div className={styles.emptyState}>
						{translate("smart-review.cmp-details.option-panel.versioning.no-previous-version")}
					</div>
				))
			) : (
				<div className={styles.noContent}>
					<IconComponent
						className={styles.noContent__infoIcon}
						color="var(--color-blue)"
						icon={icon.faInfoCircle}
						size="sm"
					/>
					<div className={styles.noContent__desc}>
						{translate("smart-review.cmp-details.option-panel.versioning.no-content-version")}
					</div>
				</div>
			)}
			{isLoading && (
				<div className={styles.loading}>
					<CircularLoader />
				</div>
			)}
			<Preview
				coordinates={coordinates}
				docId={preview?.documentId}
				infoId={idValue}
				open={!!preview}
				page={preview?.page}
				projectId={preview?.projectId}
				onClose={handleCloseDisplay}
			/>
		</div>
	);
}
