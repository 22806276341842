import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton, icon, DropDown } from "../../../../common/components";
import { MenuItemSelectable } from "../..";
import styles from "./LanguageMenu.module.css";
import { languages } from "../../../../common/constants";
import { setFirstTimeLogin, setLanguage } from "../../../../common/slice";
import { ApiService } from "../../../../api";

export default function LanguageMenu({ open, onClose = null, menuName, onOpenHover, onOpen = null }) {
	const language = useSelector(({ context }) => context.language);
	const preventNavigation = useSelector(({ context }) => context.preventNavigation);
	const anchorRef = useRef(null);
	const dispatch = useDispatch();
	const handleIsSelectedLanguage = (value) => {
		if (value === language.value) {
			return true;
		}
		return false;
	};
	const handleOpenHover = () => {
		onOpenHover(menuName);
	};
	const handleOpen = () => {
		onOpen(menuName);
	};

	const selectLanguage = (value) => {
		const cancelToken = ApiService.getCancelTokenSource();
		dispatch(setLanguage({ value, cancelToken }));
		dispatch(setFirstTimeLogin(false));
	};
	const handleSelectLanguage = ({ value }) => {
		if (language.value === value) {
			onClose();
			return;
		}
		if (preventNavigation.active) {
			// eslint-disable-next-line
			const confirm = window.confirm(preventNavigation.message);
			if (confirm) {
				selectLanguage(value);
			}
		} else {
			selectLanguage(value);
		}
		onClose();
	};
	return (
		<>
			<CustomButton
				ref={anchorRef}
				autoTranslate="no"
				className={styles.languageMenu}
				color="secondary"
				data-cy="languageMenu_selector"
				endIcon={icon.faCaretDown}
				variant="outlined"
				onClick={handleOpen}
				onMouseOver={handleOpenHover}
			>
				{language.name}
			</CustomButton>
			<DropDown ref={anchorRef} open={open} onClose={onClose}>
				<div>
					{languages.map((menu) => (
						<MenuItemSelectable
							key={menu.value}
							autoTranslate="no"
							menuItem={menu}
							onIsSelected={handleIsSelectedLanguage}
							onSelect={handleSelectLanguage}
						/>
					))}
				</div>
			</DropDown>
		</>
	);
}
