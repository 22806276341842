import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionAutoService, ApiService, CategoryService, SearchCardService } from "../../api";
import {
	AutomationDialog,
	CategoryDeleteDialog,
	CategoryDialog,
	CircularLoader,
	createNotification,
	CustomButton,
	EmptyState,
	ExpandableCategoryRow,
	HeaderCategoryRow,
	icon,
	SearchInput,
	ViewBanner,
} from "../../common/components";
import {
	AnalyticsProvider,
	Flags,
	hasCompanyPermission,
	hasPermission,
	isSegFeatureEnabled,
	Permissions,
	SegFlags,
	translate,
} from "../../common/providers";
import { removeFavoriteCategory } from "../../common/slice";
import { debounce, isNonEmptyArray, updateTreeBranch } from "../../common/utils";
import { exportView } from "../../navigation";
import styles from "./AdminCategories.module.css";
import { CategorySubscriptionsDialog, ImportTransferCategorySidePanel } from "./components";
import { ACTION_TYPES } from "./constants/constants";
import { addCategories, deleteCategory, getParent, getCategoryFromTree } from "./utils/utils";
import { useApi } from "../../common/hooks";

const debouncedSearch = debounce((searchFunction) => {
	searchFunction();
}, 500);

const AdminCategories = () => {
	const [categories, setCategories] = useState([]);
	const [isTreeLoading, setIsTreeLoading] = useState(false);
	const [isNew, setIsNew] = useState(true);
	const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
	const [tempCategory, setTempCategory] = useState({});
	const [tempParent, setTempParent] = useState({});
	const [openImportSidePanel, setOpenImportSidePanel] = useState(false);
	const [openTransferSidePanel, setOpenTransferSidePanel] = useState(false);
	const [needUpdate, setNeedUpdate] = useState(false);
	const [openAutomationDialog, setOpenAutomationDialog] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const projectId = useSelector(({ context }) => context.project.id);
	const [tempAutomation, setTempAutomation] = useState(null);
	const cancelTokenSourceRef = useRef(null);
	const updateCancelTokenSourceRef = useRef(null);
	const importMenuButtonRef = useRef(null);
	const { call: trigger } = useApi(ActionAutoService.trigger);
	const { call: getAutomations, cancel: cancelGetAutomations } = useApi(CategoryService.getThematicAutomations);
	const { call: getFilters } = useApi(SearchCardService.getFilters);
	const { call: updateFilters } = useApi(SearchCardService.updateFilters);

	const dispatch = useDispatch();
	const isProjectDirector = useMemo(
		() =>
			hasCompanyPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]) &&
			hasPermission([Permissions.PROJECT_LEADER]),
		[]
	);
	const getTree = useCallback(
		(searchText = "") => {
			setIsTreeLoading(true);
			const request = searchText
				? CategoryService.getTreeForProject(
						{ projectId, search: searchText },
						cancelTokenSourceRef.current.token
				  )
				: CategoryService.getTreeParentsForProject({ projectId }, cancelTokenSourceRef.current.token);
			request
				.then((data) => setCategories(data || []))
				.catch((err) => console.error(err))
				.finally(() => setIsTreeLoading(false));
		},
		[projectId]
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		const documentTitle = translate("admin-categories.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Project categories" });
		updateCancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
			ApiService.cancelTokens(updateCancelTokenSourceRef.current);
		};
	}, []);

	useEffect(() => {
		if (!debouncedSearchValue && !searchValue) {
			getTree(searchValue);
		} else if (searchValue === debouncedSearchValue) {
			ApiService.cancelTokens(updateCancelTokenSourceRef.current);
			updateCancelTokenSourceRef.current = ApiService.getCancelTokenSource();
			setIsTreeLoading(true);
			getTree(searchValue);
		}
		if (needUpdate) {
			setNeedUpdate(false);
		}
	}, [getTree, searchValue, debouncedSearchValue, needUpdate]);

	const handleClickAdd = (parentId) => {
		setOpenCategoryDialog(true);
		setIsNew(true);
		setTempParent(getCategoryFromTree(categories, parentId));
		setTempCategory({});
	};
	const handleClickDelete = (category) => {
		setOpenDeleteDialog(true);
		setTempCategory({ ...category });
		setTempParent(getParent(categories, category.id));
	};
	const handleClickEdit = (category) => {
		setOpenCategoryDialog(true);
		setIsNew(false);
		setTempCategory({ ...category });
		setTempParent(getParent(categories, category.id));
	};
	const handleCloseCategoryDialog = () => {
		setOpenCategoryDialog(false);
	};
	const handleSubmitCategoryDialog = (
		newCategory,
		parentChanged,
		newParent,
		callback,
		tempSearchCards,
		automationIdsToDelete
	) => {
		const newParentId = newParent?.id || null;
		let adjustedSearchCards = [...tempSearchCards];
		if (isNonEmptyArray(tempSearchCards)) {
			adjustedSearchCards = tempSearchCards.map((tsc) => {
				if (isNonEmptyArray(tsc?.filters?.keywordFilters?.filters)) {
					tsc.filters.keywordFilters.filters = tsc.filters.keywordFilters.filters.filter((f) =>
						isNonEmptyArray(f.keywords)
					);
					return tsc;
				}
				return undefined;
			});
		}
		const payload = {
			thematicName: newCategory.name,
			description: newCategory.description || null,
			parentId: isNew ? newParentId : newCategory.parentId,
			searches:
				(isNonEmptyArray(adjustedSearchCards) &&
					adjustedSearchCards.map((tsc) => {
						const { name, filters } = tsc;
						return {
							name,
							search: filters,
						};
					})) ||
				null,
		};
		const request =
			(isNew &&
				CategoryService.createFastTrackAutomation(
					{ projectId },
					payload,
					cancelTokenSourceRef.current.token
				)) ||
			CategoryService.updateFastTrackAutomation(
				{ thematicId: newCategory.id },
				{
					...payload,
					automationIdsToDelete: (isNonEmptyArray(automationIdsToDelete) && automationIdsToDelete) || null,
				},
				cancelTokenSourceRef.current.token
			);

		request
			.then((data) => {
				if (data) {
					let newCategories = [];
					let isSet = false;
					if (isNew) {
						isSet = true;
						if (data.parentId) {
							CategoryService.getTreeChildrenForProject(
								{ parentId: data.parentId },
								cancelTokenSourceRef.current.token
							)
								.then((oldList) => {
									setCategories((prev) =>
										updateTreeBranch(
											{
												tree: prev,
												idToReplace: data.parentId,
												newBranch: {
													...getCategoryFromTree(categories, data.parentId),
													subThematic: oldList,
													expanded: true,
												},
												iterativeBranchName: "subThematic",
											},
											{ sortKey: "name" }
										)
									);
								})
								.catch((err) => console.error(err));
						} else {
							getTree(debouncedSearchValue);
						}
					} else {
						const expanded =
							!!newCategory.expanded &&
							Array.isArray(newCategory.subThematic) &&
							newCategory.subThematic.length > 0;
						if (parentChanged) {
							newCategories = deleteCategory(categories, newCategory.id);
							if (!expanded) {
								CategoryService.getTreeChildrenForProject(
									{ parentId: data.parentId },
									cancelTokenSourceRef.current.token
								)
									.then((oldList) => {
										setCategories((prev) =>
											updateTreeBranch(
												{
													tree: prev,
													idToReplace: data.parentId,
													newBranch: {
														...getCategoryFromTree(categories, data.parentId),
														subThematic: oldList,
														expanded: true,
													},
													iterativeBranchName: "subThematic",
												},
												{ sortKey: "name" }
											)
										);
									})
									.catch((err) => console.error(err));
							} else {
								newCategories = addCategories(newCategories, newCategory.parentId, [
									{ ...data, expanded },
								]);
							}
						} else {
							newCategories = updateTreeBranch(
								{
									tree: categories,
									idToReplace: newCategory.id,
									newBranch: {
										...data,
										expanded,
									},
									iterativeBranchName: "subThematic",
								},
								{ sortKey: "name" }
							);
						}
					}
					if (!isSet) {
						setCategories(newCategories);
						if (typeof callback === "function") {
							callback();
						}
					}
				}
				setOpenCategoryDialog(false);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};
	const handleSubmitDeleteDialog = (subThematicKeep) => {
		CategoryService.patchProjectTreeUpdate(
			{ projectId },
			{
				operation: ACTION_TYPES.delete,
				id: tempCategory.id,
				subThematicKeep,
			},
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				let newCategories = [...categories];
				if (subThematicKeep) {
					const { parentId } = tempCategory;
					newCategories = addCategories(
						categories,
						parentId,
						Array.isArray(tempCategory.subThematic) ? tempCategory.subThematic : []
					);
				}
				newCategories = deleteCategory(newCategories, tempCategory.id);
				setOpenDeleteDialog(false);
				setCategories(newCategories);
				dispatch(removeFavoriteCategory(tempCategory.id));
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleToggleExpand = (category) => {
		let isExpanded = true;
		if (category.filtered === false && category.expanded === undefined) {
			isExpanded = false;
		}
		if (category.filtered === undefined && !category.expanded) {
			CategoryService.getTreeChildrenForProject({ parentId: category.id }, cancelTokenSourceRef.current.token)
				.then((data) => {
					setCategories((prev) =>
						updateTreeBranch({
							tree: prev,
							idToReplace: category.id,
							newBranch: {
								...category,
								subThematic: data,
								expanded:
									!category.filtered && category.expanded === undefined
										? isExpanded
										: !category.expanded,
							},
							iterativeBranchName: "subThematic",
						})
					);
				})
				.catch((err) => console.error(err));
		} else {
			setCategories((prev) =>
				updateTreeBranch({
					tree: prev,
					idToReplace: category.id,
					newBranch: {
						...category,
						expanded:
							!category.filtered && category.expanded === undefined ? isExpanded : !category.expanded,
					},
					iterativeBranchName: "subThematic",
				})
			);
		}
	};
	const handleClearSearchValue = () => {
		setSearchValue("");
		setDebouncedSearchValue("");
	};
	const handleChangeSearchValue = (event) => {
		const newValue = event.target.value;
		setSearchValue(newValue);
		debouncedSearch(() => {
			setDebouncedSearchValue(newValue);
		});
	};
	const handleOpenImportSidePanel = () => {
		if (importMenuButtonRef.current) {
			importMenuButtonRef.current.onClose();
		}
		setOpenImportSidePanel(true);
	};
	const handleCloseImportSidePanel = () => {
		setOpenImportSidePanel(false);
	};
	const handleSubmitImportSidePanel = () => {
		handleCloseImportSidePanel();
		setNeedUpdate(true);
	};
	const handleOpenTransferSidePanel = () => {
		setOpenTransferSidePanel(true);
	};
	const handleCloseTransferSidePanel = () => {
		setOpenTransferSidePanel(false);
	};
	const handleSubmitTransferSidePanel = () => {
		handleCloseTransferSidePanel();
		setNeedUpdate(true);
	};

	const handleClickAddAction = (thematic) => {
		setTempAutomation({ thematicId: thematic.id });
		setTempCategory(thematic);
		setOpenAutomationDialog(true);
	};
	const handleCloseAutomationDialog = () => {
		setOpenAutomationDialog(false);
	};
	const handleSubmitAutomationDialog = () => {
		handleCloseAutomationDialog();
		const newCategories = updateTreeBranch({
			tree: categories,
			idToReplace: tempAutomation.thematicId,
			newBranch: {
				...tempCategory,
				actionCount: 1,
			},
			iterativeBranchName: "subThematic",
		});
		setCategories(newCategories);
	};
	const handleOpenUsersList = (category) => {
		setSelectedCategory(category);
	};
	const handleCloseSubscriptionsDialog = () => {
		setSelectedCategory(null);
	};

	const handleLaunch = (row) => {
		const { actionId } = row;
		if (actionId) {
			trigger({ projectId, actionId })
				.then(() => {
					createNotification({
						type: "success",
						message: translate("common:condensed-automation.automation.launch.success"),
					});
					setNeedUpdate(true);
				})
				.catch(console.error);
		}
	};
	const NewCategoryButton = (
		<CustomButton
			color="primary"
			data-testid="new.category.btn"
			size="md"
			startIcon={icon.faPlus}
			variant="contained"
			onClick={handleClickAdd}
		>
			{translate("common:categories.button.new-thematic")}
		</CustomButton>
	);
	const TransferButton = (
		<CustomButton
			color="secondary"
			disabled={!Array.isArray(categories) || categories.length === 0}
			size="md"
			startIcon={icon.faArrowUpFromBracket}
			variant="outlined"
			onClick={handleOpenTransferSidePanel}
		>
			{translate("common:categories.button.transfer")}
		</CustomButton>
	);
	const ImportButton = (
		<>
			<CustomButton
				color="secondary"
				size="md"
				startIcon={icon.faFileImport}
				variant="outlined"
				onClick={handleOpenImportSidePanel}
			>
				{translate("admin-categories.button.import")}
			</CustomButton>
		</>
	);

	return (
		<>
			<ViewBanner
				options={
					(isProjectDirector && !isTreeLoading && [TransferButton, ImportButton, NewCategoryButton]) || []
				}
				titles={[
					{ title: translate("navigation:project.project-configuration"), key: "projectAdmin" },
					{ title: translate("navigation:home.admin-categories") },
				]}
			/>
			<div className={styles.mainContainer}>
				<div className={styles.searchContainer}>
					{((!isTreeLoading && isNonEmptyArray(categories)) || searchValue) && (
						<SearchInput
							className={styles.searchContainer__textfield}
							label={translate("common:categories.textfield.label")}
							value={searchValue}
							onChange={handleChangeSearchValue}
							onClearSearch={handleClearSearchValue}
						/>
					)}
				</div>
				<div className={styles.mainContainer__list} data-testid="category.list">
					{(isTreeLoading && (
						<div className={styles.mainContainer__loader}>
							<CircularLoader />
						</div>
					)) ||
						(isNonEmptyArray(categories) && (
							<>
								<HeaderCategoryRow
									disableCheckboxes
									isAdminCategories
									categoriesCount={(categories || []).length}
									isChecked={false}
									isCompanyManagerOrAdmin={isProjectDirector}
									projectId={projectId}
								/>
								{categories.map((category) => (
									<ExpandableCategoryRow
										key={category.id}
										isAdminCategories
										category={category}
										expanded={
											(category.filtered === false && category.expanded === undefined) ||
											category.expanded
										}
										isActionsAllowed={isProjectDirector}
										projectId={projectId}
										onClickAdd={handleClickAdd}
										onClickAddAction={handleClickAddAction}
										onClickDelete={handleClickDelete}
										onClickEdit={handleClickEdit}
										onOpenUsersList={handleOpenUsersList}
										onToggleExpand={handleToggleExpand}
									/>
								))}
							</>
						)) || (
							<EmptyState
								btn1={isProjectDirector && !isTreeLoading && ImportButton}
								btn2={isProjectDirector && !isTreeLoading && NewCategoryButton}
								title={
									searchValue
										? translate("admin-categories.empty-state.filter")
										: translate("admin-categories.empty-state")
								}
							/>
						)}
				</div>
			</div>
			<CategoryDialog
				isAdminCategories
				availableParents={!searchValue && categories}
				category={tempCategory}
				isNew={isNew}
				open={openCategoryDialog}
				parentCategory={tempParent}
				onClose={handleCloseCategoryDialog}
				onGetAutomations={{ getAutomations, cancelGetAutomations }}
				onGetFilters={getFilters}
				onLaunch={handleLaunch}
				onSubmit={handleSubmitCategoryDialog}
				onUpdateFilters={updateFilters}
			/>
			<CategoryDeleteDialog
				categoryToDelete={tempCategory}
				open={openDeleteDialog}
				parentCategory={tempParent}
				onClose={handleCloseDeleteDialog}
				onSubmit={handleSubmitDeleteDialog}
			/>
			<ImportTransferCategorySidePanel
				importing={openImportSidePanel}
				open={openImportSidePanel || openTransferSidePanel}
				title={translate(
					(openImportSidePanel && "common:categories.import-panel.title") ||
						"common:categories.transfer-panel.title"
				)}
				onClose={(openImportSidePanel && handleCloseImportSidePanel) || handleCloseTransferSidePanel}
				onSubmit={(openImportSidePanel && handleSubmitImportSidePanel) || handleSubmitTransferSidePanel}
			/>
			<AutomationDialog
				isNew
				action={tempAutomation}
				availableParents={!searchValue && categories}
				open={openAutomationDialog}
				onClose={handleCloseAutomationDialog}
				onSubmit={handleSubmitAutomationDialog}
			/>
			{isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES) && (
				<CategorySubscriptionsDialog
					category={selectedCategory}
					open={!!selectedCategory}
					onClose={handleCloseSubscriptionsDialog}
				/>
			)}
		</>
	);
};

export default exportView({
	path: "/projects/:projectId/project-categories",
	localesPath: "/admin-categories/locales",
	component: AdminCategories,
	flag: Flags.THEMATIC,
	segFlag: SegFlags.CATEGORY,
});
