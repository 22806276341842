import { Checkbox } from "@mui/material";
import React from "react";
import { I18nTranslate } from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import styles from "./MatchContentFromPartner.module.css";

export default function MatchContentFromPartner({
	importPartnersComments,
	isOptionSelected,
	onChangeImportPartnersComments,
}) {
	return (
		(isOptionSelected && (
			<>
				<span className={styles.match__text}>
					{translate("smart-review.match-dialog.review-status-of-selected-partner-will-be-used")}
				</span>
				<span className={styles.match__text}>
					{translate("smart-review.match-dialog.partner-need-to-be-affiliated")}
				</span>
				<div>
					<Checkbox
						checked={importPartnersComments}
						color="primary"
						onChange={onChangeImportPartnersComments}
					/>
					<span>{translate("smart-review.match-dialog.import-partner-comments")}</span>
				</div>
			</>
		)) || (
			<span>
				<I18nTranslate
					className={styles.match__text}
					translationKey="smart-review.match-dialog.most-critic-partners-position"
				/>
			</span>
		)
	);
}
