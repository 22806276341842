import React from "react";
import ActionTreeRow from "./components/ActionTreeRow";

export default function ActionTree({ rows, total, onCheckRow }) {
	return (
		<div>
			{rows.map((x) => (
				<ActionTreeRow key={x.id} row={x} total={total} onCheckRow={onCheckRow} />
			))}
		</div>
	);
}
