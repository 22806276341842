import React from "react";
import { translate } from "../../../../common/providers";
import { ProjectDetails, ProjectDocuments } from "./components";
import styles from "./DetailsAndDocumentsStep.module.css";

export default function DetailsAndDocumentsStep({ onAddCheckedCategories }) {
	return (
		<div className={styles["main-container"]}>
			<div className={styles["container__left-panel"]}>
				<span className={styles["step-title"]}>{translate("new-project.details-and-documents.title")}</span>
				<ProjectDetails onAddCheckedCategories={onAddCheckedCategories} />
			</div>
			<ProjectDocuments />
		</div>
	);
}
