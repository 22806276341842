import React, { useState } from "react";
import { translate } from "../../../../common/providers";
import { BlockTitle, CustomTableCell, CustomTableRow, IconComponent, icon } from "../../../../common/components";
import styles from "./CompaniesList.module.css";

function getCompanyHasChildren(data) {
	const result = [];
	for (const item of data) {
		if (item.children?.length > 0) {
			result.push(item.id);
			result.push(...getCompanyHasChildren(item.children));
		}
	}
	return result;
}

export default function CompaniesList({ companies = [], onClickRow, selectedCompany = null }) {
	const [expandedRows, setExpandedRows] = useState(getCompanyHasChildren(companies));
	const toggleRowExpansion = (companyId) => {
		if (expandedRows.includes(companyId)) {
			setExpandedRows(expandedRows.filter((id) => id !== companyId));
		} else {
			setExpandedRows([...expandedRows, companyId]);
		}
	};
	const handleRowClick = (company) => {
		if (company.children) {
			toggleRowExpansion(company.id);
		}
		onClickRow(company);
	};

	const renderCompanyRows = (company, level = 0) => (
		<React.Fragment key={company.id}>
			<CustomTableRow
				hover
				className={`${styles.tableRow} ${selectedCompany === company && styles["tableRow--selected"]}`}
				onClick={() => handleRowClick(company)}
			>
				<CustomTableCell style={{ paddingLeft: `${16 + level * 16}px` }}>
					{company.children && (
						<IconComponent
							color="var(--color-dark-grey-1)"
							icon={expandedRows.includes(company.id) ? icon.faChevronDown : icon.faChevronRight}
							size="xs"
							translate="no"
						/>
					)}
					<span className={company?.children ? styles.parentName : styles.childName}>
						{company.companyName}
					</span>
				</CustomTableCell>
			</CustomTableRow>
			{Array.isArray(company.children) &&
				expandedRows.includes(company.id) &&
				company.children.map((childCompany) => renderCompanyRows(childCompany, level + 1))}
		</React.Fragment>
	);

	return (
		<div>
			<BlockTitle>{translate("dsi.company-list.title")}</BlockTitle>
			<div className={styles.companyRow}>
				{Array.isArray(companies) && companies.map((company) => renderCompanyRows(company))}
			</div>
		</div>
	);
}
