import React from "react";
import styles from "./TemplateRow.module.css";
import { CustomButton, CustomIconButton, CustomTooltip, icon } from "../../../../../../common/components";

export default function TemplateRow({ onDelete, onSelectRow, template }) {
	return (
		<div key={template.id} className={styles.row}>
			<CustomTooltip showWhenOverflow title={template.name}>
				<div className={styles.name}>{template.name}</div>
			</CustomTooltip>
			<div className={styles.projects}>
				<CustomButton
					variant="outlined"
					onClick={() => {
						onSelectRow(template, "projects");
					}}
				>
					{template.projects}
				</CustomButton>
			</div>
			<div className={styles.actions}>
				<CustomIconButton
					icon={icon.faTrash}
					size="sm"
					variant="outlined"
					onClick={() => onDelete(template.id)}
				/>
			</div>
		</div>
	);
}
