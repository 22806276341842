import React from "react";
import { CustomTooltip } from "../../tooltip";
import styles from "./Status.module.css";

const Status = ({ className = "", onClick, size = "md", style = {}, title = "", value = 0 }) => (
	<CustomTooltip arrow title={title}>
		<div
			className={`${styles.status} ${className}`}
			data-size={size}
			role="presentation"
			style={style}
			onClick={onClick}
		>
			<div className={styles.status__innerWrapper}>{value}</div>
		</div>
	</CustomTooltip>
);

export default Status;
