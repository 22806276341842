import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CustomButton, IconComponent, icon } from "../../../../../../common/components";
import LockDropDown from "./components/LockDropDown";
import { ROLES } from "../../../../constants/constants";
import { translate } from "../../../../../../common/providers";
import { checkReqValMode } from "../../../../utils/utils";
import styles from "./Lock.module.css";

export default function Lock() {
	const lockRef = useRef();
	const [open, setOpen] = useState(false);
	const roles = useSelector(({ srDocument }) => srDocument.roles);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const handleDropdown = () => {
		setOpen((prev) => !prev);
	};
	const hasValidatorRole = Array.isArray(roles) && roles.some((x) => x === ROLES.VALIDATOR.name.toUpperCase());
	return (
		<>
			{hasValidatorRole && checkReqValMode(mode) && (
				<>
					<CustomButton ref={lockRef} color="secondary" startIcon={icon.faLock} onClick={handleDropdown}>
						{translate("smart-review.header.lock")}
						<IconComponent className={styles.button__collapseIcon} icon={icon.faChevronDown} />
					</CustomButton>
					<LockDropDown ref={lockRef} open={open} onClose={handleDropdown} />
				</>
			)}
		</>
	);
}
