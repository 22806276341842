import React from "react";
import { translate } from "../../../../../../common/providers";
import styles from "./PackageHeaders.module.css";

export default function PackageHeaders() {
	return (
		<div className={styles.main}>
			<div>{translate("dsi.package-management.table.header.package-name")}</div>
			<div>{translate("dsi.package-management.table.header.description")}</div>
			<div>{translate("dsi.package-management.table.header.features")}</div>
			<div>{translate("dsi.package-management.table.header.companies")}</div>
			<div>{translate("dsi.package-management.table.header.projects")}</div>
		</div>
	);
}
