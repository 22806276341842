import React from "react";
import { useSelector } from "react-redux";
import styles from "./SelectParameters.module.css";
import { Selector } from "../../../../../common/components";
import { translateEnumParam } from "../../../../../common/providers";

export default function SelectParameters({ canUpdate, criticalityValue, negotiabilityValue, onSelect, typeValue }) {
	const criticalityList = useSelector(({ srDocument }) => srDocument.criticalityList);
	const negotiabilityList = useSelector(({ srDocument }) => srDocument.negotiabilityList);
	const reqTypeList = useSelector(({ srDocument }) => srDocument.reqTypeList);

	return (
		<div className={styles.selections}>
			<Selector
				withNone
				autoTranslate="no"
				className={styles.formControl}
				disabled={!canUpdate}
				itemRenderer={(label) => translateEnumParam("type", label)}
				items={reqTypeList || []}
				label={translateEnumParam("type")}
				value={typeValue}
				onChange={(e) => onSelect(e, "type")}
			/>
			<Selector
				withNone
				autoTranslate="no"
				className={styles.formControl}
				disabled={!canUpdate}
				itemRenderer={(label) => translateEnumParam("criticality", label)}
				items={criticalityList || []}
				label={translateEnumParam("criticality")}
				value={criticalityValue}
				onChange={(e) => onSelect(e, "criticality")}
			/>
			<Selector
				withNone
				autoTranslate="no"
				className={styles.formControl}
				disabled={!canUpdate}
				itemRenderer={(label) => translateEnumParam("negotiability", label)}
				items={negotiabilityList || []}
				label={translateEnumParam("negotiability")}
				value={
					negotiabilityValue &&
					negotiabilityList &&
					negotiabilityList.includes(negotiabilityValue.toUpperCase())
						? negotiabilityValue
						: ""
				}
				onChange={(e) => onSelect(e, "negotiability")}
			/>
		</div>
	);
}
