import React from "react";
import {
	CustomButton,
	icon,
	ProgressBar,
	Tile,
	TileBody,
	TileHeader,
	VerticalDivider,
} from "../../../../common/components";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../common/providers";
import styles from "./ProjectReqReview.module.css";
import { NavUtils } from "../../../../navigation";

export default function ProjectReqReview({
	className = "",
	clientReq,
	coverage,
	newReq,
	onChangeUrl,
	projectId,
	totalReq,
}) {
	return (
		<Tile className={className}>
			<TileHeader
				className={styles.header}
				iconName={icon.faCheckDouble}
				title={translate("dashboards.project-req-review.title.project-progress")}
			/>
			<TileBody>
				<div className={styles.projectReqReview}>
					<ProgressBar label={`${Math.trunc(coverage)}%`} value={coverage / 100} />
					<div className={styles.figuresContainer}>
						<div className={styles.figure}>
							<span className={styles.figure__number}>{clientReq}</span>
							<span className={styles.figure__label}>
								{translate("dashboards.project-req-review.content.req-client")}
							</span>
						</div>

						<VerticalDivider />
						<div className={styles.figure}>
							<span className={styles.figure__number}>{newReq}</span>
							<span className={styles.figure__label}>
								{translate("dashboards.project-req-review.content.req-others")}
							</span>
						</div>
						<VerticalDivider />
						<div className={styles.figure}>
							<span className={styles.figure__number}>{totalReq}</span>
							<span className={styles.figure__label}>
								{translate("dashboards.project-req-review.content.total")}
							</span>
						</div>
					</div>
					{isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS) && isSegFeatureEnabled(SegFlags.TEAM) && (
						<CustomButton
							className={styles.manageRightsBtn}
							data-testid={`manageRightsOfReviewProject_${projectId}`}
							variant="outlined"
							onClick={() => onChangeUrl(NavUtils.goToRightsOnDocuments(projectId))}
						>
							{translate("dashboards.project-req-review.content.manage-rights")}
						</CustomButton>
					)}
				</div>
			</TileBody>
		</Tile>
	);
}
