import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardService, RequirementParameterService, CategoryService } from "../../../../../api";
import { SEARCH_TYPES } from "../../../../../common/constants/search-types";

function getProjectId(getState) {
	const {
		context: {
			project: { id: projectId },
		},
	} = getState();
	return projectId;
}

function getCompanyId(getState) {
	const {
		context: {
			company: { id: companyId },
		},
	} = getState();
	return companyId;
}

export const getReviewDocuments = createAsyncThunk(
	"dashboard/getReviewDocuments",
	({ userId, filters, token }, { getState }) => {
		const projectId = getProjectId(getState);
		const companyId = getCompanyId(getState);
		const {
			dashboard: { separator },
		} = getState();
		return DashboardService.getUserProgress(
			{ projectId, companyId, userId },
			{ Type: SEARCH_TYPES.card, separator, ...(filters || {}) },
			token
		);
	}
);

export const getCompanyDocuments = createAsyncThunk(
	"dashboard/getCompanyDocuments",
	({ companyId, filters, token }, { getState }) => {
		const projectId = getProjectId(getState);
		const cpId = companyId || getCompanyId(getState);
		const {
			dashboard: { separator },
		} = getState();
		return DashboardService.getCompanyProgress(
			{ projectId, companyId: cpId },
			{ Type: SEARCH_TYPES.card, separator, ...(filters || {}) },
			token
		);
	}
);

export const getDocumentDetails = createAsyncThunk(
	"dashboard/getDocumentDetails",
	({ documentId, filters, token }, { getState }) => {
		const projectId = getProjectId(getState);
		const companyId = getCompanyId(getState);
		const {
			dashboard: { separator },
		} = getState();
		return DashboardService.getDocumentProgress(
			{ projectId, companyId, documentId },
			{ Type: SEARCH_TYPES.card, separator, ...(filters || {}) },
			token
		);
	}
);

/** FIXED DATA FETCH * */

export const getTypeParameters = createAsyncThunk(
	"dashboard/getTypeParameters",
	(token) => RequirementParameterService.getReqTypes(token),
	{
		condition: (_, { getState }) => {
			const {
				dashboard: { types },
			} = getState();
			return !Array.isArray(types) || types.length === 0;
		},
	}
);

export const getCriticalityParameters = createAsyncThunk(
	"dashboard/getCriticalityParameters",
	(token) => RequirementParameterService.getCriticality(token),
	{
		condition: (_, { getState }) => {
			const {
				dashboard: { criticalities },
			} = getState();
			return !Array.isArray(criticalities) || criticalities.length === 0;
		},
	}
);

export const getNegotiabilityParameters = createAsyncThunk(
	"dashboard/getNegotiabilityParameters",
	(token) => RequirementParameterService.getNegotiability(token),
	{
		condition: (_, { getState }) => {
			const {
				dashboard: { negotiabilities },
			} = getState();
			return !Array.isArray(negotiabilities) || negotiabilities.length === 0;
		},
	}
);

export const getCategoriesParents = createAsyncThunk(
	"dashboard/getCategoriesParents",
	(token, { getState }) => {
		const projectId = getProjectId(getState);
		return CategoryService.getTreeParentsForProject({ projectId, details: false }, token);
	},
	{
		condition: (_, { getState }) => {
			const {
				dashboard: { categories },
			} = getState();
			return !Array.isArray(categories) || categories.length === 0;
		},
	}
);

export const getCategoriesChildren = createAsyncThunk("dashboard/getCategoriesChildren", ({ token, parentId }) =>
	CategoryService.getTreeChildrenForProject({ parentId, details: false }, token)
);
