import client from "../../client/client";

class MultiSelectionservice {
	getSelectionCategoriesCount({ projectId, documentId }, { values }, cancelToken) {
		return client.post(`/smartview/actions/projects/${projectId}/documents/${documentId}/information-categories`, {
			payload: { values },
			config: { cancelToken },
		});
	}

	updateSelectionCategories({ projectId, documentId }, { ids, addThematic, deleteThematic }, cancelToken) {
		return client.patch(`/smartview/actions/projects/${projectId}/documents/${documentId}/information-categories`, {
			payload: { ids, addThematic, deleteThematic },
			config: { cancelToken },
		});
	}

	updateSelectionType({ projectId, documentId }, { ids, type }, cancelToken) {
		return client.patch(`/smartview/actions/projects/${projectId}/documents/${documentId}/information-type`, {
			payload: { ids, type },
			config: { cancelToken },
		});
	}
}

export default new MultiSelectionservice();
