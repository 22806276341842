import client from "../../client/client";

class VersionRedressmentService {
	updateInformationVersion(payload, cancelToken) {
		return client.post("/version-redressment", {
			payload,
			config: { cancelToken },
		});
	}

	getInformationStringContent({ documentId, informationId }, cancelToken) {
		return client.get(`/version-redressment/documents/${documentId}/information/${informationId}`, {
			config: { cancelToken },
		});
	}

	getPreviousInformationDetails({ informationId }, cancelToken) {
		return client.get(`/version-redressment/information/${informationId}`, {
			config: { cancelToken },
		});
	}

	getStatuses(cancelToken) {
		return client.get("/version-redressment/status", {
			config: { cancelToken },
		});
	}
}
export default new VersionRedressmentService();
