import client from "../../client/client";

class PackageManagementService {
	createPackage(payload, cancelToken) {
		return client.post("/backoffice/packages", { payload, config: { cancelToken } });
	}

	updatePackage({ packageId }, payload, cancelToken) {
		return client.put(`/backoffice/packages/${packageId}`, { payload, config: { cancelToken } });
	}

	getActivePackageCompanies({ packageId }, cancelToken) {
		return client.get(`/backoffice/packages/${packageId}/companies`, { config: { cancelToken } });
	}

	updateActivePackageCompanies({ packageId }, payload, cancelToken) {
		return client.put(`/backoffice/packages/${packageId}/companies`, { payload, config: { cancelToken } });
	}

	getPackageFeatures({ packageId }, cancelToken) {
		return client.get(`/backoffice/packages/${packageId}/features`, { config: { cancelToken } });
	}

	updatePackageFeatures({ packageId }, payload, cancelToken) {
		return client.put(`/backoffice/packages/${packageId}/features`, { payload, config: { cancelToken } });
	}

	getActivePackageProjects({ packageId }, cancelToken) {
		return client.get(`/backoffice/packages/${packageId}/projects`, { config: { cancelToken } });
	}

	getAllPlatformPackages(name, cancelToken) {
		return client.get("/backoffice/packages/all", { params: { name }, config: { cancelToken } });
	}
}
export default new PackageManagementService();
