import React from "react";
import SearchTemplate from "../../../search-template/company-search-template/CompanySearchTemplate";
import styles from "./DSISearchTemplates.module.css";

export default function DSISearchTemplates() {
	return (
		<div className={styles.container}>
			<SearchTemplate />
		</div>
	);
}
