import React from "react";
import { CustomTooltip } from "../..";
import styles from "./MultipleProgressBar.module.css";

export default function MultipleProgressBar({
	className = "",
	colors = [],
	hideFigures = false,
	progresses = [],
	tooltips = [],
}) {
	const progressSum = progresses.reduce((a, b) => a + b) || 1;
	return (
		<div className={`${styles.container} ${className}`}>
			{progresses.map(
				(progress, index) =>
					progress > 0 && (
						// eslint-disable-next-line react/no-array-index-key
						<CustomTooltip key={index} placement="top" title={tooltips[index] || ""}>
							<div
								className={styles.progressSlice}
								data-testid="progress.slice"
								style={{ width: `${(100 * progress) / progressSum}%`, backgroundColor: colors[index] }}
							>
								<span>{!hideFigures && progress > 0 && progress}</span>
							</div>
						</CustomTooltip>
					)
			)}
		</div>
	);
}
