import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApiService } from "../../../../../../../api";
import { translate } from "../../../../../../../common/providers";
import styles from "./PartnershipRow.module.css";
import { CustomButton, CustomTooltip, LimitedTextField } from "../../../../../../../common/components";
import { setDisableKeyEvents } from "../../../../../../../common/slice";

function renderTextField(open, userCanEdit, issue, handleSubmit, handleChange, handleKeyPress, handleFocus) {
	if (open) {
		return (
			<LimitedTextField
				autoFocus
				fullWidth
				multiline
				disabled={!userCanEdit}
				maxLength={512}
				maxRow={5}
				value={issue}
				onBlur={handleSubmit}
				onChange={handleChange}
				onFocus={handleFocus}
				onKeyPress={handleKeyPress}
			/>
		);
	}
	if (issue && issue !== "") {
		return (
			<CustomButton
				disableElevation
				fullWidth
				className={styles["issue-btn"]}
				color="secondary"
				disabled={!userCanEdit}
			>
				<span className={styles.issue}>{issue}</span>
				<span className={styles.issue__space}> </span>
			</CustomButton>
		);
	}
	return (
		<CustomButton disabled fullWidth className={styles["issue-btn"]} color="secondary">
			<span className={styles.issue__empty}>{translate("common:empty-state.none")}</span>
		</CustomButton>
	);
}

export default function PartnershipRow({
	data,
	isArchived,
	partnerCompanyId,
	partnerCompanyName,
	partnerIssueComment,
	partnerStatus,
	myCompany,
	onChangeIssue = null,
	onSubmit = null,
}) {
	const [issue, setIssue] = useState(partnerIssueComment || "");
	const [open, setOpen] = useState(false);
	const companyId = useSelector(({ context }) => context.company.id);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const userCanEdit =
		companyId === partnerCompanyId && !data.isFrozenByParent && !isArchived && !data.isFrozen && mode === "val";
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		const newIssue = partnerIssueComment || "";
		setIssue(newIssue);
	}, [partnerIssueComment]);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
	}, []);
	const handleFocus = () => dispatch(setDisableKeyEvents(true));
	const handleChange = (value) => {
		if (onChangeIssue) {
			onChangeIssue(value);
		}
		setIssue(value);
	};
	const handleSubmit = () => {
		dispatch(setDisableKeyEvents(false));
		if (issue !== partnerIssueComment) {
			if (onSubmit) {
				onSubmit();
			}
		}
		setOpen(false);
	};
	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			handleSubmit();
		}
	};
	const setStatusColor = (status) => {
		if (status) {
			switch (status.toUpperCase()) {
				case "UNREVIEWED":
					return styles.status__new;
				case "NOT_ME":
					return styles.status__notme;
				case "YES":
					return styles.status__yes;
				case "NO":
					return styles.status__no;
				case "PENDING":
					return styles.status__pending;
				default:
					return styles.status__default;
			}
		} else {
			return styles.status__default;
		}
	};
	return (
		<div className={styles.partnership__row}>
			<div
				className={`${styles["company-name__container"]} ${setStatusColor(
					(myCompany && data.validatedStatus) || partnerStatus
				)}`}
				data-testid={`partner.position.${partnerStatus}`}
			>
				<CustomTooltip showWhenOverflow title={partnerCompanyName}>
					<div className={styles["company-name"]} translate="no">
						{partnerCompanyName}
					</div>
				</CustomTooltip>
			</div>
			<div className={styles["issue__content--container"]}>
				<div
					className={styles.issue__comment}
					role="presentation"
					onClick={() => {
						if (userCanEdit) {
							setOpen(true);
						}
					}}
				>
					{renderTextField(open, userCanEdit, issue, handleSubmit, handleChange, handleKeyPress, handleFocus)}
				</div>
			</div>
		</div>
	);
}
