import React from "react";
import styles from "./ReqRow.module.css";
import { dangerousSetInnerHTML } from "../../../../../common/utils";

export default function ReqRow({ style, id, clientRef, content, onClick, selected }) {
	const className = selected ? `${styles["req-row"]} ${styles["req-row--selected"]}` : `${styles["req-row"]}`;
	return (
		<button className={className} style={style} type="button" onClick={onClick}>
			<div className={styles["req-row__header"]}>
				<span className={styles["req-row__title"]}>{!!id && `REQ_${id}`}</span>
				{clientRef && <div className={styles["req-row__ref"]}>[{clientRef}]</div>}
			</div>
			<p className={styles["req-row__content"]}>{dangerousSetInnerHTML({ text: content })}</p>
		</button>
	);
}
