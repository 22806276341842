import React from "react";
import { useSelector } from "react-redux";
import { translate } from "../../../../../../common/providers";
import CustomMagicDropDown from "../../../../../../common/components/ChatDropDown/CustomMagicDropDown ";

export default function ReviewRedirectionFilter() {
	const transverseFilters = useSelector(({ srProjectDocument }) => srProjectDocument.transverseFilters);
	const questions = [
		{
			title: translate("smart-review-contradiction"),
			text: translate("smart-review-contradiction-paragraph.question"),
		},
		{
			title: translate("smart-review-summarize-paragraph"),
			text: translate("smart-review-summarize.question"),
		},
		{
			title: translate("smart-review.ask-another-question"),
			text: "",
		},
	];

	const handleGetRedirectToChatFilters = (value, summary) => ({
		state: {
			filters: {
				filter: transverseFilters,
				value,
				summary,
			},
		},
	});

	return (
		<CustomMagicDropDown
			positionDropDown="bottom-end"
			questions={questions}
			onGetRedirectToChatFilters={handleGetRedirectToChatFilters}
		/>
	);
}
