import React from "react";
import { InputLabel, FormControl, MenuItem, Select, Paper, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { translate } from "../../../../../../common/providers";
import styles from "./RedressDialog.module.css";
import { setDisableKeyEvents } from "../../../../../../common/slice";

export default function RedressContent({
	action,
	CONTENT_TYPES,
	error,
	handleChangeAction,
	handleChangeToc,
	handleChangeType,
	REDRESS_ACTION,
	selectedBlock,
	toc,
	type,
}) {
	const dispatch = useDispatch();
	return (
		<>
			<div className={styles.container}>
				<Paper className={styles.paper}>
					<span className={styles.content}>{selectedBlock?.contentType}</span>
				</Paper>
				<Paper className={styles.paper}>
					<FormControl className={styles.content}>
						<Select labelWidth={100} size="small" value={type || ""} onChange={handleChangeType}>
							{CONTENT_TYPES.map((contentType) =>
								contentType === selectedBlock?.contentType ? null : (
									<MenuItem key={contentType} value={contentType}>
										{contentType}
									</MenuItem>
								)
							)}
						</Select>
					</FormControl>
				</Paper>
			</div>
			{selectedBlock?.contentType === "PARAGRAPH" ? (
				<div className={styles.container}>
					<Paper className={styles.paper}>
						<FormControl className={styles.content}>
							<InputLabel className={styles.inputLabel}>
								{translate("smart-review.redress-content.redress-direction")}
							</InputLabel>
							<Select
								labelWidth={100}
								size="small"
								value={action || REDRESS_ACTION[0]}
								onChange={handleChangeAction}
							>
								<MenuItem value={REDRESS_ACTION[0]}>
									{translate("smart-review.redress-content.item-same-level-as-previous-clause")}
								</MenuItem>
								<MenuItem value={REDRESS_ACTION[1]}>
									{translate("smart-review.redress-content.item-under-prev-clause")}
								</MenuItem>
							</Select>
						</FormControl>
					</Paper>
				</div>
			) : null}
			{selectedBlock?.contentType === "PARAGRAPH" ? (
				<div className={styles.container}>
					<Paper className={styles.paper}>
						<FormControl className={styles.content}>
							<TextField
								error={error || false}
								helperText={error && translate("smart-review.redress-content.label.mandatory-entry")}
								label={translate("smart-review.redress-content.label.new-toc")}
								size="small"
								value={toc || ""}
								variant="outlined"
								onBlur={() => dispatch(setDisableKeyEvents(false))}
								onChange={handleChangeToc}
								onFocus={() => dispatch(setDisableKeyEvents(true))}
							/>
						</FormControl>
					</Paper>
				</div>
			) : null}
		</>
	);
}
