import React, { useState } from "react";
import { DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import styles from "./AddPartnerDialog.module.css";
import { translate } from "../../../../common/providers";
import { CustomIconButton, icon, GenericDialog, CustomButton } from "../../../../common/components";

export default function AddPartnerDialog({ open, onClose }) {
	const [email, setEmail] = useState("");

	const handleCloseAndAction = () => {
		onClose();
	};
	const handleChange = (e) => setEmail(e.target.value);

	const reset = () => {
		setEmail("");
	};

	return (
		<GenericDialog disableBackdropClick fullWidth open={open} onClose={onClose} onExited={reset}>
			<DialogTitle>
				<div className={styles.dialog__title}>
					<span className={styles["dialog__title-text"]}>
						{translate("team-management.add-partner-dialog.title")}
					</span>
					<CustomIconButton icon={icon.faTimes} onClick={onClose} />
				</div>
			</DialogTitle>
			<DialogContent dividers className={styles.dialog__content}>
				<TextField
					label={translate("team-management.add-partner-dialog.label")}
					value={email}
					variant="outlined"
					onChange={handleChange}
				/>
			</DialogContent>
			<DialogActions>
				<CustomButton color="primary" variant="outlined" onClick={onClose}>
					{translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton color="primary" variant="contained" onClick={handleCloseAndAction}>
					{translate("common:btn.submit")}
				</CustomButton>
			</DialogActions>
		</GenericDialog>
	);
}
