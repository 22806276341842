import React, { useState } from "react";
import { TextField } from "@mui/material";
import { Permissions, translate } from "../../../../common/providers";
import {
	CustomButton,
	CustomIconButton,
	icon,
	SidePanel,
	SidePanelContent,
	SidePanelContainer,
} from "../../../../common/components";
import USerDetailsTable from "./UserDetailsTable";
import EditUserDialog from "./EditUserDialog";

export default function UserDetails({
	PROJECT_ROLES,
	isAdding,
	onActiveFromParent,
	onAdd,
	onChangeRole,
	onClose,
	onUpdateStatus,
	onUpdateUser,
	selectedCompany,
	user,
}) {
	const [payload, setPayload] = useState({
		firstname: "",
		lastname: "",
		email: "",
		role: Permissions.COMPANY_MEMBER.role,
	});
	const [openEdit, setOpenEdit] = useState(false);
	const [errors, setErrors] = useState({ firstname: false, lastname: false, email: false });

	const onChange = (value, field) => {
		setErrors((prev) => ({ ...prev, [field]: false }));
		setPayload((prev) => ({ ...prev, [field]: value }));
	};

	const reset = () => {
		setPayload({ firstname: "", lastname: "", email: "", role: Permissions.COMPANY_MEMBER.role });
		setErrors({ firstname: false, lastname: false, email: false });
	};

	const isEmail = (email) => /^[a-zA-Z0-9+_.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
	const isValid = (email) => {
		if (!isEmail(email)) {
			return false;
		}
		return true;
	};

	const onSubmit = () => {
		const errs = {
			firstname: !(payload.firstname && payload.firstname.length > 2),
			lastname: !(payload.lastname && payload.lastname.length > 2),
			email: !isValid(payload.email),
		};
		const hasError = Object.keys(errs).some((key) => errs[key]);
		if (!hasError) {
			onAdd({ payload });
			reset();
		}
		setErrors(errs);
	};

	const handleOpenEdit = () => {
		setOpenEdit((prev) => !prev);
	};
	return (
		<SidePanel open={!!user || isAdding} size={70} onActive={onActiveFromParent} onClose={onClose}>
			{({ onActive }) => (
				<SidePanelContent
					title={
						isAdding
							? translate("dsi.user-details.title.adding-user")
							: translate("dsi.user-details.title.user-details")
					}
					onActive={onActive}
					onClose={onClose}
				>
					<SidePanelContainer>
						<div style={{ display: "flex", flexDirection: "column" }}>
							{user || isAdding ? null : (
								<div style={{ display: "flex" }}>
									<span style={{ margin: "50% auto", textTransform: "uppercase" }}>
										{translate("dsi.user-details.no-user-selected")}
									</span>
								</div>
							)}
							{user && !isAdding && (
								<div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											margin: "16px 0",
											alignItems: "center",
											justifyItems: "center",
										}}
									>
										<span style={{ fontWeight: "bold" }} translate="no">
											{user.displayName}
										</span>
										<span style={{ fontStyle: "italic" }} translate="no">
											{user.email}
										</span>
										<div>
											<CustomIconButton
												icon={icon.faUserEdit}
												tooltip={translate("dsi.user-details.edit-tooltip")}
												variant="contained"
												onClick={handleOpenEdit}
											/>
										</div>
									</div>
									<USerDetailsTable
										PROJECT_ROLES={PROJECT_ROLES}
										projects={user.projects}
										onChangeRole={onChangeRole}
										onUpdateStatus={onUpdateStatus}
									/>
								</div>
							)}
							{isAdding && (
								<div style={{ display: "flex", flexDirection: "column", margin: "16px" }}>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											flexGrow: 1,
											margin: "16px auto",
											width: "80%",
										}}
									>
										<TextField
											required
											error={errors && errors.firstname}
											helperText={
												errors &&
												errors.firstname &&
												translate("common:error.required-with-min-data", { min: 3 })
											}
											label={translate("dsi.user-details.label.firt-name")}
											size="small"
											value={payload.firstname || ""}
											variant="outlined"
											onChange={(e) => onChange(e.target.value, "firstname")}
										/>
										<span style={{ margin: "8px" }} />
										<TextField
											required
											error={errors && errors.lastname}
											helperText={
												errors &&
												errors.lastname &&
												translate("common:error.required-with-min-data", { min: 3 })
											}
											label={translate("dsi.user-details.label.last-name")}
											size="small"
											value={payload.lastname || ""}
											variant="outlined"
											onChange={(e) => onChange(e.target.value, "lastname")}
										/>
										<span style={{ margin: "8px" }} />
										<TextField
											required
											error={errors && errors.email}
											helperText={
												errors &&
												errors.email &&
												translate("common:multiple-email-input.error-message.not-valid")
											}
											label={translate("dsi.user-details.label.email")}
											size="small"
											value={payload.email || ""}
											variant="outlined"
											onChange={(e) => onChange(e.target.value, "email")}
										/>
										<span style={{ margin: "16px" }} />
										<CustomButton fullWidth color="primary" variant="contained" onClick={onSubmit}>
											{translate("common:btn.submit")}
										</CustomButton>
									</div>
								</div>
							)}
							{openEdit && (
								<EditUserDialog
									open={openEdit}
									selectedCompany={selectedCompany}
									user={user}
									onClose={handleOpenEdit}
									onUpdate={onUpdateUser}
								/>
							)}
						</div>
					</SidePanelContainer>
				</SidePanelContent>
			)}
		</SidePanel>
	);
}
