import { Chip } from "@mui/material";
import React, { useState } from "react";
import {
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	icon,
} from "../../../../../common/components";
import { translate, translateDate } from "../../../../../common/providers";
import styles from "./ProjectsListTable.module.css";

export default function ProjectsListTable({ projects, onSelectRow, onChangeArchive, onChangeName }) {
	const [tableHeaders] = useState([
		translate("admin.project-list.th.type"),
		translate("admin.project-list.th.name"),
		translate("admin.project-list.th.creation-date"),
		translate("admin.project-list.th.company-role"),
		translate("admin.project-list.th.status"),
		translate("admin.project-list.th.actions"),
	]);
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					{tableHeaders.map((header) => (
						<CustomTableCell key={header} className={styles.header}>
							{header}
						</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody emptyState={translate("admin.project-list.project")}>
				{Array.isArray(projects) &&
					projects.map((row) => (
						<CustomTableRow
							key={row.id}
							hover
							className={styles.tableRow}
							onClick={() => onSelectRow({ project: row })}
						>
							<CustomTableCell className={styles.tableCell}>
								<Chip label="OPERATION" size="small" />
							</CustomTableCell>
							<CustomTableCell autoTranslate="no" className={styles.tableCell}>
								{row.name}
							</CustomTableCell>
							<CustomTableCell className={styles.tableCell}>{translateDate(row.created)}</CustomTableCell>
							<CustomTableCell className={styles.tableCell}>{row.role}</CustomTableCell>
							<CustomTableCell className={styles.tableCell}>
								<Chip
									color={row.currentStatus === "ACTIVE" ? "primary" : "default"}
									label={row.currentStatus}
									size="small"
								/>
							</CustomTableCell>
							<CustomTableCell className={styles.tableCell}>
								<CustomIconButton
									icon={icon.faPenToSquare}
									tooltip={translate("admin.project-list.project-rename")}
									variant="contained"
									onClick={() => onChangeName({ project: row })}
								/>
								<CustomIconButton
									icon={
										row.currentStatus === "ACTIVE" ? icon.faFileArchive : icon.faFileArchiveRegular
									}
									tooltip={
										row.currentStatus === "ACTIVE"
											? translate("admin.project-list.current-status.archive")
											: translate("admin.project-list.current-status.unarchive")
									}
									variant="contained"
									onClick={() => onChangeArchive({ project: row })}
								/>
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
