import React from "react";
import { Collapse, TextField } from "@mui/material";
import styles from "./EditUserDialog.module.css";
import { CustomIconButton, icon } from "../../../../common/components";

export default function FieldSection({ collapsed, error, helperText, label, onChange, onCollapse, title, value }) {
	return (
		<section className={styles["dialog-content__section"]}>
			<span className={styles["dialog-content__section-title"]}>
				<CustomIconButton icon={collapsed ? icon.faChevronDown : icon.faChevronRight} onClick={onCollapse} />
				{title}
			</span>
			<Collapse unmountOnExit in={collapsed} timeout="auto">
				<TextField
					fullWidth
					className={styles.dialog__field}
					error={error}
					helperText={helperText}
					label={label}
					size="small"
					value={value}
					variant="outlined"
					onChange={onChange}
				/>
			</Collapse>
		</section>
	);
}
