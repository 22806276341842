import React from "react";
import { CustomTable, CustomTableBody } from "../../../../../../../common/components";
import DisplayTableRow from "./DisplayTableRow";

/**
 * row = { label: "", value: ""/[], type: "STATUS"/"INFORMATION" }
 */
export default function DisplayTable({ rows = [] }) {
	return (
		<CustomTable dense>
			<CustomTableBody>
				{Array.isArray(rows) && rows.map((row) => <DisplayTableRow key={row.key} row={row} />)}
			</CustomTableBody>
		</CustomTable>
	);
}
