import React, { useState } from "react";
import { TextField } from "@mui/material";
import { CustomDialog } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import styles from "./UpdateQaDialog.module.css";

const UpdateQaDialog = ({ close, onUpdateQa, open, qaDetails }) => {
	const [qaText, setQaText] = useState("");
	const [clientId, setClientId] = useState("");
	const [answer, setAnswer] = useState("");
	const handleQaChange = (e) => setQaText(e.target?.value || "");
	const handleClientIdChange = (e) => setClientId(e.target?.value || "");
	const handleAnswerChange = (e) => setAnswer(e.target?.value || "");
	const handleSubmit = (e) => {
		if (e) {
			e.preventDefault();
		}
		if (!onUpdateQa) {
			return;
		}
		onUpdateQa(qaText, clientId, answer);
		setQaText("");
		setClientId("");
		setAnswer("");
	};
	return (
		<CustomDialog
			disableBackdropClick
			fullWidth
			isForm
			open={open}
			title={translate("qa-journal.qa-details.update-qa-dialog.title")}
			onClose={close}
			onSubmit={handleSubmit}
		>
			<form className={styles["dialog-body"]} onSubmit={handleSubmit}>
				<TextField
					fullWidth
					multiline
					defaultValue={qaDetails.questionAsked}
					InputLabelProps={{ shrink: true, required: false }}
					label={translate("qa-journal.qa-details.update-qa-dialog.textfield.question-asked.label")}
					size="small"
					type="text"
					variant="outlined"
					onChange={handleQaChange}
				/>
				<TextField
					fullWidth
					defaultValue={qaDetails.clientId}
					InputLabelProps={{ shrink: true, required: false }}
					label={translate("qa-journal.qa-details.update-qa-dialog.textfield.client-id.label")}
					size="small"
					type="text"
					variant="outlined"
					onChange={handleClientIdChange}
				/>
				<TextField
					fullWidth
					multiline
					defaultValue={qaDetails.questionAnswer}
					InputLabelProps={{ shrink: true, required: false }}
					label={translate("qa-journal.qa-details.update-qa-dialog.textfield.answer.label")}
					size="small"
					type="text"
					variant="outlined"
					onChange={handleAnswerChange}
				/>
			</form>
		</CustomDialog>
	);
};
export default UpdateQaDialog;
