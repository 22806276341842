import React from "react";
import { Tooltip } from "@mui/material";
import styles from "./CategoryRiskLevel.module.css";
import TextMidCut from "../text-mid-cut/TextMidCut";

export default function CategoryRiskLevel({ classId, category }) {
	return (
		<div className={`${styles.chip} ${classId || ""}`}>
			<Tooltip key={category.categoryId}>
				<TextMidCut
					fullWidth
					tooltip
					charsKeptInEnd={6}
					className={styles.chip__root}
					text={category?.categoryName || category?.name}
				/>
			</Tooltip>
		</div>
	);
}
