import React, { useState, useRef, useEffect } from "react";
import { debounce } from "../../../../../../common/utils";
import { translate } from "../../../../../../common/providers";
import { ApiService, PackageManagementService } from "../../../../../../api";
import { SearchInput, Selector } from "../../../../../../common/components";
import styles from "./FilterHeader.module.css";

const debounceFunc = debounce((func) => func(), 500);

export default function FilterHeader({ onChangeFilters }) {
	const [name, setName] = useState("");
	const [packages, setPackages] = useState([]);
	const [selectedPackage, setSelectedPackage] = useState("");
	const cancelTokenSourceRef = useRef();

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		PackageManagementService.getAllPlatformPackages("", cancelTokenSourceRef.current.token)
			.then((data) => {
				if (Array.isArray(data)) {
					setPackages(data);
				}
			})
			.catch((err) => console.error(err));
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	useEffect(() => {
		debounceFunc(() => onChangeFilters({ name, packageId: selectedPackage?.id || null }));
	}, [name, selectedPackage, onChangeFilters]);
	const handleChangeSearchValue = (e) => {
		setName(e.target.value);
	};
	const handleClearSearchValue = () => {
		setName("");
	};
	const handleChangePackage = (e) => {
		setSelectedPackage(e.target.value);
	};

	return (
		<div className={styles.header}>
			<SearchInput
				className={styles.searchContainer__textfield}
				label={translate("common:categories.textfield.label")}
				value={name}
				onChange={handleChangeSearchValue}
				onClearSearch={handleClearSearchValue}
			/>
			<Selector
				fullWidth
				withNone
				field="name"
				items={packages}
				label={translate("dsi.flag-list.filter-header.package")}
				value={selectedPackage}
				onChange={handleChangePackage}
			/>
		</div>
	);
}
