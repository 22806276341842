import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AnalyticsProvider, Flags, translate } from "../../../common/providers";
import { exportView } from "../../../navigation";
import SearchTemplate from "./MySearchTemplate";
import { setIsDsi } from "./slice/my-search-template-slice";

const MySearchTemplateView = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		const documentTitle = translate("search-template.document.title-search-templates");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Search templates" });
		dispatch(setIsDsi(false));
	}, [dispatch]);

	return <SearchTemplate />;
};

export { default as mySearchTemplateSlice } from "./slice/my-search-template-slice";
export default exportView({
	path: [{ path: "/search-templates/search-templates" }, { path: ":id" }],
	component: MySearchTemplateView,
	localesPath: "/search-template/locales",
	flag: Flags.SEARCHCARD,
});
