import { Alert, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService, BackOfficeService } from "../../../../../api";
import searchCardTemplateService from "../../../../../api/services/search-card-template-service";
import { CustomDialog, generateFilters, icon, IconComponent } from "../../../../../common/components";
import {
	VISIBILITY_TEMPLATE_CREATION,
	VISIBILITY_TEMPLATE_AUTOMATION,
	VISIBILITY_FOR_COPY_TEMPLATE,
} from "../../../../../common/constants";
import { SEARCH_TYPES } from "../../../../../common/constants/search-types";
import { Permissions, translate } from "../../../../../common/providers";
import { checkValidity } from "../../../../../navigation";
import styles from "./CreateTemplateDialog.module.css";

export default function CreateTemplateDialog({
	isMakingCopy,
	isMySearchTemplate,
	onClose,
	onSubmit,
	hasWarning = false,
	open,
	setIsUpdating,
	slice,
}) {
	const { title, description, visibility } = useSelector((state) => state[slice].currentSearch);
	const filters = useSelector((state) => state[slice].filters);
	const isFilterTocOut = useSelector((state) => state[slice].isFilterTocOut);
	const isDsi = useSelector((state) => state[slice].isDsi);
	const visibilityInputs = useMemo(() => {
		if (isMakingCopy) {
			if (!checkValidity({ role: [Permissions.COMPANY_ADMIN, Permissions.COMPANY_MANAGER] })) {
				delete VISIBILITY_FOR_COPY_TEMPLATE.AUTOMATION;
			}
			return VISIBILITY_FOR_COPY_TEMPLATE;
		}
		if (isMySearchTemplate) {
			return VISIBILITY_TEMPLATE_CREATION;
		}
		return VISIBILITY_TEMPLATE_AUTOMATION;
	}, [isMakingCopy, isMySearchTemplate]);
	const [nameInput, setNameInput] = useState("");
	const [descriptionInput, setDescriptionInput] = useState("");
	const [visibilityInput, setVisibilityInput] = useState(visibility);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (open) {
			setNameInput("");
			setDescriptionInput("");
		}
	}, [open]);
	useEffect(() => {
		if (isMakingCopy) {
			setNameInput(
				`${title} (${translate("search-template.dialogs.create-template-dialog.copy")?.toLowerCase()})`
			);
			setDescriptionInput(description);
			setVisibilityInput(visibility === "AITENDERS" ? visibilityInputs.PRIVATE : visibility);
		}
	}, [isMakingCopy, visibility, title, description, visibilityInputs]);
	const handleChangeName = (e) => {
		setNameInput(e.target.value);
	};
	const handleChangeDescription = (e) => {
		setDescriptionInput(e.target.value);
	};
	const handleChangeVisibility = (e) => {
		setVisibilityInput(e.target.value);
	};
	const getVisibility = () => {
		if (isDsi) {
			return visibilityInputs.AITENDERS;
		}
		if (isMySearchTemplate || isMakingCopy) {
			return visibilityInput;
		}
		return visibilityInputs.AUTOMATION;
	};
	const handleSaveTemplate = () => {
		dispatch(setIsUpdating(true));
		const payload = {
			isUpdating: !isMakingCopy,
			title: nameInput,
			description: descriptionInput || null,
			visibility: getVisibility(),
			filtersResults: generateFilters({ ...filters, Type: SEARCH_TYPES.template, isFilterTocOut }),
		};
		(isDsi ? BackOfficeService : searchCardTemplateService)
			.createTemplate(payload, cancelTokenSourceRef.current.token)
			.then((data) => {
				dispatch(setIsUpdating(false));
				onSubmit(data);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	return (
		<CustomDialog
			isForm
			className={styles.dialog}
			open={open}
			submitDisabled={!nameInput}
			submitLabel={translate("common:btn.save")}
			title={
				isMakingCopy
					? translate("search-template.dialogs.copy-template-dialog.title")
					: translate("search-template.dialogs.create-template-dialog.title")
			}
			onClose={onClose}
			onSubmit={handleSaveTemplate}
		>
			<div className={styles.content__inputs}>
				<TextField
					color="primary"
					helperText={translate("search-template.dialogs.create-template-dialog.input.name.helper")}
					inputProps={{ type: "text", maxLength: 250 }}
					placeholder={`${translate("search-template.dialogs.create-template-dialog.input.name")}*`}
					size="small"
					value={nameInput}
					variant="outlined"
					onChange={handleChangeName}
				/>
				<TextField
					color="primary"
					helperText={translate("search-template.dialogs.create-template-dialog.input.description.helper")}
					inputProps={{ type: "text", maxLength: 1000 }}
					placeholder={translate("search-template.dialogs.create-template-dialog.input.description")}
					size="small"
					value={descriptionInput}
					variant="outlined"
					onChange={handleChangeDescription}
				/>
				{(isMySearchTemplate || isMakingCopy) && (
					<>
						<TextField
							select
							color="primary"
							helperText={translate(
								"search-template.dialogs.create-template-dialog.input.visibility.helper"
							)}
							size="small"
							value={visibilityInput}
							variant="outlined"
							onChange={handleChangeVisibility}
						>
							{Object.entries(visibilityInputs).map(([key, value]) => (
								<MenuItem key={key} value={value}>
									{translate(
										`search-template.dialogs.create-template-dialog.input.visibility.${value
											?.toLowerCase()
											.replaceAll("_", "-")}`
									)}
								</MenuItem>
							))}
						</TextField>
					</>
				)}
				{(!isMySearchTemplate || isDsi) && !isMakingCopy && (
					<div className={styles.infoBox}>
						<IconComponent
							className={styles.infoIcon}
							color="var(--color-blue)"
							icon={icon.faInfoCircle}
							size="lg"
						/>
						{translate("search-template.dialogs.create-template-dialog.visible-leader-manager")}
					</div>
				)}
				{hasWarning && (
					<Alert severity="error">{translate("search-template.dialogs.create-template-dialog.alert")}</Alert>
				)}
			</div>
		</CustomDialog>
	);
}
