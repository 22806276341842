import React from "react";
import { CustomAccordion, CustomTooltip } from "../../../../../../../../../common/components";
import styles from "./DeliverablesPhasesList.module.css";
import { DeliverablesList } from "..";
import { isNonEmptyArray } from "../../../../../../../../../common/utils";

export default function DeliverablesPhasesList({
	hasFilters,
	onAllocate,
	onExpandPhase,
	phases,
	reqId,
	setSelectedCount,
	showSelected,
}) {
	return (
		<>
			{isNonEmptyArray(phases) &&
				phases.map((phase) => {
					const isExpanded = phase?.deliverablesCount > 0 || phase?.deliverables?.length > 0;
					return (
						<CustomAccordion
							key={phase.phaseId}
							className={styles.accordionContent}
							defaultOpen={hasFilters || showSelected || phase.defaultExpanded}
							header={
								<CustomTooltip showWhenOverflow title={phase?.phaseName}>
									<div className={styles.line}>{phase?.phaseName}</div>
								</CustomTooltip>
							}
							iconColor="var(--color-light-grey-1)"
							onExpand={() => onExpandPhase({ expandablePhase: phase })}
						>
							{(isExpanded && (
								<DeliverablesList
									deliverables={phase.deliverables}
									reqId={reqId}
									setSelectedCount={setSelectedCount}
									showSelected={showSelected}
									onAllocate={onAllocate}
								/>
							)) ||
								null}
						</CustomAccordion>
					);
				})}
		</>
	);
}
