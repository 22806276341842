import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { CustomDialog } from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import styles from "./TextInputDialog.module.css";

export default function TextInputDialog({
	autoFocus = false,
	contentText,
	deniedInput = false,
	hidden,
	inputArguments,
	onClose,
	onHide,
	onSkip,
	onSubmit,
	open,
	title,
	value,
}) {
	const [text, setText] = useState("");
	const [errorMessage, setErrorMessage] = useState(false);
	useEffect(() => {
		if (value) {
			setText(value);
		}
	}, [value]);
	const handleClose = (boolean) => {
		setText(value || "");
		setErrorMessage(false);
		onClose(boolean);
	};
	const handleSkip = () => {
		setText(value || "");
		setErrorMessage(false);
		if (typeof onSkip === "function") {
			onSkip();
		}
	};

	const handleSubmitAndClose = () => {
		if (typeof onSubmit === "function" && typeof onClose === "function" && text.length > 1) {
			if (inputArguments) {
				onSubmit(text, inputArguments);
			} else {
				onSubmit(text);
			}
			setText("");
			handleClose(true);
		} else {
			setErrorMessage(translate("smart-review.text-input-dialog.error.please-write-more-consistent"));
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			handleSubmitAndClose();
		}
	};

	const handleChange = (event) => {
		if (!deniedInput) {
			setText(event.target.value);
			setErrorMessage(null);
		}
	};

	return (
		<CustomDialog
			checked={hidden}
			closeLabel={translate("smart-review.redress-info.dialog.skip")}
			data-testid="form-dialog-title"
			open={open}
			subTitle={contentText}
			title={title}
			onChangeCheckbox={onHide}
			onClose={() => {
				handleClose(false);
			}}
			onSkip={handleSkip}
			onSubmit={handleSubmitAndClose}
		>
			{errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
			<TextField
				fullWidth
				multiline
				autoFocus={open && autoFocus}
				id="outlined-multiline-flexible"
				InputLabelProps={{ className: styles.field }}
				inputProps={{ className: styles.field }}
				label={`${translate("smart-review.text-input-dialog.label.comment")}*`}
				margin="normal"
				maxRows="5"
				size="small"
				value={text}
				variant="outlined"
				onChange={handleChange}
				onKeyPress={handleKeyPress}
			/>
		</CustomDialog>
	);
}
