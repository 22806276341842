import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { ApiService, NormsPendingService } from "../../../../api";
import { Filter, SearchListContent } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { useApiDebounced } from "../../../../common/hooks";

export default function NormFilter({ onSelectNorm, selectedNorm, status }) {
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState(null);
	const projectId = useSelector(({ context }) => context.project.id);
	const cancelTokenSourceRef = useRef(null);
	const { call: debouncedSearchNorm } = useApiDebounced(NormsPendingService.getPendingNorms, 1000);
	useEffect(() => {
		if (!cancelTokenSourceRef.current) {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		}
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		setIsLoading(false);
		setPage(0);
	}, []);
	const handleLoading = (loading) => {
		setIsLoading(loading);
	};
	const loadMore = (name, statusFilter, pageNumber = 0) => {
		if (!cancelTokenSourceRef.current) {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		}
		return NormsPendingService.getPendingNorms(
			{ projectId },
			{ name, status: statusFilter, page: pageNumber },
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				setSearchResults((prev) => ({
					...data,
					contents: prev?.contents ? [...prev.contents, ...data.contents] : data.contents,
				}));
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => handleLoading(false));
	};
	const handleLoadMore = () => {
		if (page < searchResults.totalPages - 1) {
			const newPage = page + 1;
			setPage(newPage);
			return loadMore(search, status, page);
		}
		return null;
	};

	const handleSearch = useCallback(
		(e) => {
			handleLoading(true);
			const name = e?.target?.value || "";
			setSearch(name);
			setPage(0);
			const handleResponse = (data) => {
				handleLoading(false);
				setSearchResults(data);
			};
			const handleError = (err) => console.error(err);
			debouncedSearchNorm(handleResponse, handleError, { projectId }, { name, status });
		},
		[debouncedSearchNorm, status, projectId]
	);
	const isNormChecked = (norm) => norm && norm.id === selectedNorm?.id;
	const handleClear = () => {
		handleSearch();
		onSelectNorm(null);
	};
	const handleClearSearch = () => setSearch("");
	const handleOpen = () => {
		if (!selectedNorm && !isLoading && !open) {
			handleSearch();
			handleClearSearch();
			setOpen(true);
		}
	};
	const handleCheck = (norm) => {
		if (!norm) {
			return;
		}
		const newNorm = isNormChecked(norm) ? null : norm;
		onSelectNorm(newNorm);
	};
	return (
		<Filter
			closeOnClick
			isActive={selectedNorm}
			label={selectedNorm?.name || translate("norms-center.filter.norm.title")}
			title={translate("norms-center.filter.norm.title")}
			onClear={handleClear}
			onOpen={handleOpen}
		>
			<SearchListContent
				isInfinite
				emptyStateText={translate("common:empty-state.no-results")}
				isLoading={isLoading}
				isRowChecked={isNormChecked}
				rowKey="id"
				rowLabelKey="name"
				rows={searchResults?.contents || []}
				searchInput={search}
				searchPlaceholder={translate("norms-center.filter.norm.search")}
				totalElements={searchResults?.totalElements}
				onChangeSearch={handleSearch}
				onClearSearch={handleClearSearch}
				onClearSelection={handleClear}
				onClickRow={handleCheck}
				onLoadMore={handleLoadMore}
			/>
		</Filter>
	);
}
