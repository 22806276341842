import { TextField } from "@mui/material";
import React from "react";
import { translate } from "../../../../common/providers";
import { CustomButton, icon } from "../../../../common/components";
import styles from "./DSICompany.module.css";

export default function DSICompanyCreation({
	errors,
	payload,
	selectedCompany,
	onChangeCompanyFields,
	onImportTemplates,
	onExportTemplates,
	onSubmitCompany,
}) {
	return (
		<div className={styles.company__mainContainer}>
			<div className={styles.company__container}>
				<TextField
					required
					error={errors && errors.shortName}
					helperText={errors && errors.shortName && "Required"}
					label={translate("dsi.dsi-company.th.shortname")}
					translate="no"
					value={payload.shortName || ""}
					variant="outlined"
					onChange={(e) => onChangeCompanyFields(e.target.value, "shortName")}
				/>
				<span className={styles.dividerSpace} />
				<TextField
					required
					error={errors && errors.name}
					helperText={errors && errors.name && "Required"}
					label={translate("dsi.dsi-company.th.name")}
					value={payload.name || ""}
					variant="outlined"
					onChange={(e) => onChangeCompanyFields(e.target.value, "name")}
				/>
				<span className={styles.dividerSpace} />
				<CustomButton fullWidth color="primary" variant="contained" onClick={onSubmitCompany}>
					{selectedCompany ? translate("common:btn.edit") : translate("common:btn.submit")}
				</CustomButton>
				{selectedCompany && (
					<>
						<div className={styles.templating}>
							<div className={styles.textTitle}>
								<span>{translate("dsi.dsi-company.category-templating")}</span>
							</div>
							<div>
								<CustomButton
									color="primary"
									component="label"
									size="md"
									startIcon={icon.faFileImport}
									variant="outlined"
								>
									<input hidden accept=".json" type="file" onChange={onImportTemplates} />
									{translate("dsi.dsi-company.category-templating.import")}
								</CustomButton>
							</div>
							<div>
								<CustomButton
									color="primary"
									size="md"
									startIcon={icon.faDownload}
									variant="outlined"
									onClick={onExportTemplates}
								>
									{translate("dsi.dsi-company.category-templating.download")}
								</CustomButton>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
