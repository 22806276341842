import { Radio } from "@mui/material";
import React, { useMemo } from "react";
import styles from "./OptionContainer.module.css";
import MultiInput from "../multi-input/MultiInput";
import { translate } from "../../../../../../providers";
import { CustomSplitButtonMenu } from "../../../../../buttons";
import { HorizontalDivider } from "../../../../../dividers";
import { CustomTooltip } from "../../../../../tooltip";
import { SEPARATOR } from "../../../../../../../views/smart-search/utils/utils";

const OptionContainer = ({
	disabled = false,
	disabledOptionTooltip,
	globalSeparator,
	label = "",
	matchAll,
	onAddNew,
	onChangeInput,
	onChangeMatchAll,
	onChangeSelectedOption,
	onRemove,
	operator,
	selected = false,
	textInputs,
	readOnly,
}) => {
	const options = useMemo(
		() => [
			{ label: translate("common:enum.separator.or"), value: "any" },
			{ label: translate("common:enum.separator.and"), value: "all" },
		],
		[]
	);
	const handleChangeSelectedOption = () => {
		if (!disabled && !readOnly) {
			onChangeSelectedOption(operator.name);
		}
	};
	const handleTooltip = () => {
		if (globalSeparator === SEPARATOR.OR) {
			return translate("common:filters.keywords.disable-negative-filters.toc.no-or-global");
		}
		return translate(disabledOptionTooltip);
	};

	return (
		<CustomTooltip arrow placement="right" title={disabled ? handleTooltip() : ""}>
			<div
				className={styles.container}
				data-selected={selected}
				role="presentation"
				onClick={handleChangeSelectedOption}
			>
				<div>
					<Radio checked={selected} disabled={disabled || readOnly} onChange={handleChangeSelectedOption} />
					<span className={styles.label} data-disabled={disabled}>
						{label}
					</span>
				</div>
				{selected && textInputs.length > 1 && (
					<div className={styles.matchAll__container}>
						<span className={styles.matchAll__helperText}>
							{translate("common:component.filters.match-criteria.match")}
						</span>
						<CustomSplitButtonMenu
							color="secondary"
							options={options}
							tooltip={translate("common:component.filters.match-criteria.tooltip")}
							value={matchAll ? "all" : "any"}
							onSelect={onChangeMatchAll}
						/>
						<span className={styles.matchAll__helperText}>
							{translate("common:component.filters.match-criteria.criteria")}
						</span>
						<HorizontalDivider />
					</div>
				)}
				{selected &&
					Array.isArray(textInputs) &&
					textInputs.map((textInput, index) => (
						<MultiInput
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							index={index}
							input={textInput}
							isLast={textInputs.length - 1 === index}
							readOnly={readOnly}
							onAddNew={onAddNew}
							onChangeInput={onChangeInput}
							onRemove={onRemove}
						/>
					))}
			</div>
		</CustomTooltip>
	);
};

export default OptionContainer;
