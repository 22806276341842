import React, { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import styles from "./AddUserDialog.module.css";
import { translate } from "../../../../common/providers";
import { CustomDialog } from "../../../../common/components";

export default function AddUserDialog({ open, onClose, onSubmit, users }) {
	const [selectedUser, setSelectedUser] = useState(null);
	const handleSelectUser = (e) => setSelectedUser(e.target.value);
	const handleSubmit = () => {
		if (!selectedUser) {
			return;
		}
		onSubmit(selectedUser);
		onClose();
	};
	return (
		<CustomDialog
			isForm
			open={open}
			title={translate("dsi.add-user-dialog.add-user-to-project")}
			onClose={onClose}
			onSubmit={handleSubmit}
		>
			<FormControl
				fullWidth
				className={styles.formControl}
				label={translate("smart-review.redress-dialog.select-action")}
				variant="outlined"
			>
				<InputLabel className={styles.inputLabel}>
					{translate("dsi.add-user-dialog.label.select-user")}
				</InputLabel>
				<Select fullWidth size="small" value={selectedUser || ""} onChange={handleSelectUser}>
					{users?.map((user) => (
						<MenuItem key={user.id} translate="no" value={user}>
							{user.displayName} - {user.email}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</CustomDialog>
	);
}
