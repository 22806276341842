import React, { useEffect, useState } from "react";
import { isNonEmptyArray } from "../../../../utils";
import ListRow from "./ListRow";
import { CircularLoader } from "../../../loaders";
import styles from "./ListWithRequest.module.css";

const ListWithRequest = ({ labelName = "", onChange, request, search, value }) => {
	const [elements, setElements] = useState([]);
	const [loadingElements, setLoadingElements] = useState(false);
	const [checked, setChecked] = useState([]);
	useEffect(() => {
		if (value === undefined) {
			setChecked([]);
		} else {
			setChecked(value);
		}
	}, [value]);
	useEffect(() => {
		if (typeof request === "function") {
			setLoadingElements(true);
			request({ search })
				.then((data) => setElements(data))
				.catch(console.error)
				.finally(() => {
					setLoadingElements(false);
				});
		}
	}, [request, search]);
	const handleSelect = (selectedId) => {
		if (Array.isArray(checked)) {
			const newChecked = (checked.includes(selectedId) && checked.filter((item) => item !== selectedId)) || [
				...checked,
				selectedId,
			];
			setChecked(newChecked);
			onChange(isNonEmptyArray(newChecked) ? newChecked : undefined);
		}
	};
	return (
		<div>
			{(loadingElements && (
				<div className={styles.loaderContainer}>
					<CircularLoader size={45} />
				</div>
			)) || (
				<>
					{isNonEmptyArray(elements) &&
						elements.map((element) => (
							<ListRow
								key={element.id}
								checked={checked.includes(element.id)}
								labelName={labelName}
								row={element}
								onClick={handleSelect}
							/>
						))}
				</>
			)}
		</div>
	);
};

export default ListWithRequest;
