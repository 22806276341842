import { TableRow } from "@mui/material";
import React, { useState } from "react";
import styles from "./CustomTableRow.module.css";

export default function CustomTableRow({
	children,
	className = "",
	"data-testid": dataTestId = null,
	hover = false,
	hoverClassName = "",
	onClick = null,
	style = null,
}) {
	const [isHovering, setIsHovering] = useState(false);
	const handleEnter = () => {
		setIsHovering(true);
	};
	const handleLeave = () => {
		setIsHovering(false);
	};
	const innerElements = typeof children === "function" ? children({ isHovering }) : children;
	return (
		<TableRow
			classes={{
				root: hoverClassName,
			}}
			className={`${(hover && styles.row) || ""} ${(onClick && styles["row--clickable"]) || ""} ${className}`}
			data-testid={dataTestId}
			style={style}
			onClick={onClick}
			onMouseEnter={handleEnter}
			onMouseLeave={handleLeave}
		>
			{innerElements}
		</TableRow>
	);
}
