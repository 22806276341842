import client from "../client/client";

class DashboardService {
	/*
	 * USER
	 */

	getUserValidationStats({ projectId }, cancelToken) {
		return client.get(`/dashboards/users/me/projects/${projectId}/validation`, {
			config: { cancelToken },
		});
	}

	getUserReviewStats({ projectId }, cancelToken) {
		return client.get(`/dashboards/users/me/projects/${projectId}/review`, {
			config: { cancelToken },
		});
	}

	/*
	 * PROJECT
	 */

	getCompanyProgress({ projectId, companyId }, payload, cancelToken) {
		return client.post(`/dashboards/projects/${projectId}/companies/${companyId}/company-progress`, {
			config: { cancelToken },
			payload,
		});
	}

	getCompanyProgressCount({ projectId, companyId }, payload, cancelToken) {
		return client.post(`/dashboards/projects/${projectId}/companies/${companyId}/company-progress/count`, {
			config: { cancelToken },
			payload,
		});
	}

	getDocumentProgress({ projectId, companyId, documentId }, payload, cancelToken) {
		return client.post(
			`/dashboards/projects/${projectId}/companies/${companyId}/documents/${documentId}/document-progress`,
			{
				config: { cancelToken },
				payload: { Type: "InformationSearchFilterDTO", ...payload },
			}
		);
	}

	getDocumentProgressCount({ projectId, companyId, documentId }, payload, cancelToken) {
		return client.post(
			`/dashboards/projects/${projectId}/companies/${companyId}/documents/${documentId}/document-progress/count`,
			{
				config: { cancelToken },
				payload,
			}
		);
	}

	getUserProgress({ projectId, companyId, userId }, payload, cancelToken) {
		return client.post(`/dashboards/projects/${projectId}/companies/${companyId}/users/${userId}/review-progress`, {
			config: { cancelToken },
			payload,
		});
	}

	getUserProgressCount({ projectId, companyId, userId }, payload, cancelToken) {
		return client.post(
			`/dashboards/projects/${projectId}/companies/${companyId}/users/${userId}/review-progress/count`,
			{
				config: { cancelToken },
				payload,
			}
		);
	}

	getProjectDocuments({ projectId, companyId }, cancelToken) {
		return client.get(`/dashboards/projects/${projectId}/companies/${companyId}/documents`, {
			config: { cancelToken },
		});
	}

	getProjectPartners({ projectId, companyId }, cancelToken) {
		return client.get(`/dashboards/projects/${projectId}/companies/${companyId}/teams`, {
			config: { cancelToken },
		});
	}

	getProjectRequirements({ projectId, companyId }, cancelToken) {
		return client.get(`/dashboards/projects/${projectId}/companies/${companyId}/requirements`, {
			config: { cancelToken },
		});
	}

	getProjectRequirementsValidation({ projectId, companyId }, cancelToken) {
		return client.get(`/dashboards/projects/${projectId}/companies/${companyId}/requirements/validation`, {
			config: { cancelToken },
		});
	}

	/*
	 * MILESTONES
	 */
	getProjectMilestones({ projectId, companyId }, cancelToken) {
		return client.get(`/milestones/projects/${projectId}/companies/${companyId}`, { config: { cancelToken } });
	}

	addNewUserDefinedMilestone({ projectId }, payload, cancelToken) {
		return client.post(`/milestones/projects/${projectId}`, { payload, config: { cancelToken } });
	}

	UpdateDeleteUserDefinedMilestone({ milestoneId }, payload, cancelToken) {
		return client.patch(`/milestones/${milestoneId}`, { payload, config: { cancelToken } });
	}

	externalMilestones({ projectId }, payload, cancelToken) {
		return client.patch(`/milestones/projects/${projectId}/external`, { payload, config: { cancelToken } });
	}

	internalMilestones({ projectId }, payload, cancelToken) {
		return client.patch(`/milestones/projects/${projectId}/internal`, { payload, config: { cancelToken } });
	}

	/*
	 * COMPANY
	 */
	getProjectsInformation({ companyId }, cancelToken) {
		return client.get(`/dashboards/companies/${companyId}/projects`, { config: { cancelToken } });
	}
}

export default new DashboardService();
