import React from "react";
import { useSelector } from "react-redux";
import { translate } from "../../../../../../common/providers";
import CustomMagicDropDown from "../../../../../../common/components/ChatDropDown/CustomMagicDropDown ";
import styles from "./ReviewRedirectionChapName.module.css";

export default function ReviewRedirectionChapName({ label }) {
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const questions = [
		{
			title: translate("smart-review.translate-chapter"),
			choices: [
				{
					title: translate("smart-review.french-language"),
					text: translate("smart-review.chapter-translate.question", {
						language: translate("smart-review.french-language"),
					}),
					summary: translate("smart-review.chapter-translate.summary", {
						nameChapter: label,
					}),
				},
				{
					title: translate("smart-review.english-language"),
					text: translate("smart-review.chapter-translate.question", {
						language: translate("smart-review.english-language"),
					}),
					summary: translate("smart-review.chapter-translate.summary", {
						nameChapter: label,
					}),
				},
				{
					title: translate("smart-review.spanish-language"),
					text: translate("smart-review.chapter-translate.question", {
						language: translate("smart-review.spanish-language"),
					}),
					summary: translate("smart-review.chapter-translate.summary", {
						nameChapter: label,
					}),
				},
			],
		},
		{
			title: translate("smart-review-summarize-chapter"),
			text: translate("smart-review-summarize.question"),
			summary: translate("smart-review-summarize-chapter.summary", {
				nameChapter: label,
			}),
		},
		{
			title: translate("smart-review-contradiction"),
			text: translate("smart-review-contradiction-chapter.question"),
			summary: translate("smart-review-contradiction-chapter.summary"),
		},
		{
			title: translate("smart-review.ask-another-question"),
			text: "",
		},
	];

	const handleGetRedirectToChatFilters = (value, summary) => ({
		state: {
			filters: {
				filter: {
					documents: {
						elements: [documentId],
						is: true,
						matchAll: true,
					},
					tocFilters: {
						filters: [
							{
								operator: "IS_EXACTLY",
								matchAll: true,
								keywords: [label],
							},
						],
					},
					separator: "AND",
					Type: "QuickAccessSearchFilterDTO",
				},
				value,
				summary,
			},
		},
	});

	return (
		<CustomMagicDropDown
			magicButtonSizeClassName={styles.magicButtonSize}
			positionDropDown="bottom-end"
			questions={questions}
			onGetRedirectToChatFilters={handleGetRedirectToChatFilters}
		/>
	);
}
