import React from "react";
import { TextField } from "@mui/material";
import styles from "../TagDialog.module.css";
import { CustomButton } from "../../../../../common/components";

const UpdateDateRow = ({ label, onDateChange, updateButtonLabel, date, onUpdateDate }) => (
	<div className={`${styles["margin-bottom"]} ${styles["padding-top"]}`}>
		<TextField
			InputLabelProps={{ shrink: true, required: false }}
			label={label}
			type="date"
			onChange={onDateChange}
		/>
		<CustomButton color="primary" onClick={() => onUpdateDate(date)}>
			{updateButtonLabel}
		</CustomButton>
	</div>
);
export default UpdateDateRow;
