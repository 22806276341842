import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService, OpportunityService } from "../../../../../api";
import { Filter4 } from "../../../../../common/components/filters4";
import FilterSearchListOpportunity from "../../../../../common/components/filters4/filter-search-list/FilterSearchListOpportunity";
import { translate } from "../../../../../common/providers";
import { setCheckedUsers, setFilteredIds } from "../../../slice/opportunitySlice";
import styles from "./OpportunityFilter.module.css";

export default function OpportunityFilter({ setPage }) {
	const [owners, setOwners] = useState([]);
	const [searchedOwners, setSearchedOwners] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [needApply, setNeedApply] = useState(false);
	const checkedUsers = useSelector(({ opportunity }) => opportunity.checkedUsers);
	const tokenSourceRef = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		tokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(tokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		OpportunityService.getOpportunitiesOwners(tokenSourceRef.current.token)
			.then((data) => setOwners(data))
			.catch((err) => console.error(err));
	}, []);
	const handleSearchOwner = (e) => {
		const value = e?.target?.value || "";
		setSearchInput(value);
		const searchResult = owners.filter((item) =>
			String(item.displayName.toLowerCase()).includes(value.toLowerCase())
		);
		setSearchedOwners(searchResult);
	};
	const handleCheckItem = (item) => {
		dispatch(setCheckedUsers(owners.filter((elem) => item.some((ele) => ele === elem.displayName))));
		if (item?.length !== 0) {
			setNeedApply(true);
		} else {
			setNeedApply(false);
		}
	};
	const handleClearChecked = () => {
		dispatch(setFilteredIds(() => []));
		setNeedApply(false);
	};
	const resetSelection = () => {
		dispatch(setFilteredIds(() => []));
		dispatch(setCheckedUsers([]));
		setNeedApply(false);
	};
	const handleFilterApply = () => {
		dispatch(setFilteredIds(checkedUsers?.map((m) => m.id)));
		setPage(0);
	};

	return (
		<Filter4
			contentClassName={styles.filter}
			label={translate("oppotuny.list.filter.label")}
			submitBtnColor={needApply ? "primary" : "secondary"}
			submitBtnLabel={needApply ? translate("common:btn.apply") : translate("common:btn.close")}
			title={translate("oppotuny.list.filter.label")}
			tooltipActive={translate("Owner")}
			onClear={handleClearChecked}
			onSubmit={handleFilterApply}
		>
			<FilterSearchListOpportunity
				defaultInputValue={searchInput}
				initChecked={checkedUsers.map((m) => m.displayName)}
				rowKey="displayName"
				rowLabelKey="displayName"
				rows={searchedOwners?.length !== 0 || searchInput?.length !== 0 ? searchedOwners : owners}
				searchPlaceholder={translate("oppotuny.list.filter.search.label")}
				onChangeSearch={handleSearchOwner}
				onClear={resetSelection}
				onClearSearch={() => handleSearchOwner()}
				onClickRow={handleCheckItem}
			/>
		</Filter4>
	);
}
