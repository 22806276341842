import { Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CustomButton, NoRoleOnDocumentBanner, VersioningDialog } from "../../../common/components";
import { checkValidity, DropDownMenu, ImageButton, LanguageMenu, ProfileMenu } from "../..";
import styles from "./Navbar.module.css";
import packagejson from "../../../../package.json";
import { CommonMenu, CompanyMenu, ProjectManagementMenu, ProjectMenu, MentionMenu } from "../../components/menus";
import {
	hasPermission,
	isFeatureEnabled,
	isSegFeatureEnabled,
	Flags,
	Permissions,
	SegFlags,
	translate,
} from "../../../common/providers";
import HelpMenu from "../../components/menu-components/help-menu/HelpMenu";

const { version } = packagejson;

const menuIds = {
	companyMenu: "company-menu",
	mentionMenu: "mention-menu",
	projectMenu: "project-menu",
	profileMenu: "profile-menu",
	languageMenu: "language-menu",
	helpMenu: "help-menu",
};

const Navbar = ({
	commonMenus = [],
	company = "",
	currentOpenedMenu = "",
	homeMenus = [],
	isLogged = false,
	mentionsMenus = [],
	onClickLogout,
	onClickVersion,
	onClose,
	onHotRelog,
	onIncludes,
	onIsSelected,
	onOpen,
	onOpenHover,
	onSelect,
	openVersion = false,
	project = null,
	projectManagementMenus = [],
	projectMenus = [],
	relogCompanies = [],
	setOpenVersion,
}) => {
	const [companyMenuDisabled, setCompanyMenuDisabled] = useState(false);
	const isLoadingFlags = useSelector(({ context }) => context.loadingFlags);
	const isLoadingUser = useSelector(({ context }) => context.loadingUser);
	const companies = useSelector(({ context }) => context.companies);
	useEffect(() => {
		if (
			(!Array.isArray(homeMenus) ||
				homeMenus.length <= 0 ||
				homeMenus.filter((m) => checkValidity(m)).length <= 0) &&
			(!company ||
				(project !== null &&
					(!Array.isArray(project?.teams) || project.teams.filter((c) => c.id !== company.id).length <= 0)) ||
				(!project && !hasPermission(Permissions.PLATFORM_ADMIN) && relogCompanies.length <= 1))
		) {
			setCompanyMenuDisabled(true);
		} else {
			setCompanyMenuDisabled(false);
		}
	}, [homeMenus, project, company, relogCompanies]);
	return (
		<>
			<Toolbar className={styles.navbar}>
				<span className={styles.navbar__mainMenus}>
					<ImageButton />
					<CustomButton
						disableRipple
						className={styles.navbar__versionButton}
						color="secondary"
						onClick={onClickVersion}
					>
						{version}
					</CustomButton>
				</span>
				{isLogged && (
					<>
						{(project && (
							<span className={styles.navbar__projectMenus} data-cy="navbar__projectMenus">
								<ProjectMenu
									currentOpenedMenu={currentOpenedMenu}
									projectMenus={projectMenus}
									onClose={onClose}
									onIncludes={onIncludes}
									onIsSelected={onIsSelected}
									onOpen={onOpen}
									onOpenHover={onOpenHover}
									onSelect={onSelect}
								/>
							</span>
						)) ||
							(!hasPermission(Permissions.PLATFORM_ADMIN) && !isLoadingFlags && !isLoadingUser && (
								<span className={styles.navbar__projectMenus}>
									<CommonMenu
										commonMenus={commonMenus}
										currentOpenedMenu={currentOpenedMenu}
										onClose={onClose}
										onIncludes={onIncludes}
										onIsSelected={onIsSelected}
										onOpen={onOpen}
										onOpenHover={onOpenHover}
										onSelect={onSelect}
									/>
								</span>
							))}
						<span className={styles.navbar__userMenus} data-cy="navbar__userMenus">
							{project && (
								<>
									{isFeatureEnabled(Flags.MENTIONS) && (
										<MentionMenu
											data-testid="mentionMenu.btn"
											mentions={[]}
											menuName={menuIds.mentionMenu}
											open={currentOpenedMenu === menuIds.mentionMenu}
											project={project}
											selected={onIncludes(mentionsMenus)}
											onClose={onClose}
											onOpen={onOpen}
										/>
									)}
									<DropDownMenu
										autoTranslate="no"
										data-testid="projectMenu.btn"
										menuName={menuIds.projectMenu}
										open={currentOpenedMenu === menuIds.projectMenu}
										selected={onIncludes(projectManagementMenus)}
										title={translate("navigation:project.project-configuration")}
										onClose={onClose}
										onOpen={onOpen}
										onOpenHover={onOpenHover}
									>
										<ProjectManagementMenu
											projectMenus={projectManagementMenus}
											onIsSelected={onIsSelected}
											onSelect={onSelect}
										/>
									</DropDownMenu>
								</>
							)}
							{company && (
								<DropDownMenu
									autoTranslate="no"
									data-testid="companyMenu.btn"
									disabled={companyMenuDisabled}
									menuName={menuIds.companyMenu}
									open={currentOpenedMenu === menuIds.companyMenu}
									selected={onIncludes(homeMenus)}
									title={companies.find((c) => c.id === company.id)?.companyName}
									onClose={onClose}
									onOpen={onOpen}
									onOpenHover={onOpenHover}
								>
									<CompanyMenu
										companies={project?.teams || relogCompanies || []}
										company={company}
										homeMenus={homeMenus}
										onHotRelog={onHotRelog}
										onIsSelected={onIsSelected}
										onSelect={onSelect}
									/>
								</DropDownMenu>
							)}
							<ProfileMenu
								menuName={menuIds.profileMenu}
								open={currentOpenedMenu === menuIds.profileMenu}
								onClickLogout={onClickLogout}
								onClose={onClose}
								onIsSelected={onIsSelected}
								onOpen={onOpen}
								onOpenHover={onOpenHover}
								onSelect={onSelect}
							/>
							<HelpMenu
								menuName={menuIds.helpMenu}
								open={currentOpenedMenu === menuIds.helpMenu}
								onClose={onClose}
								onOpen={onOpen}
								onOpenHover={onOpenHover}
							/>
							<LanguageMenu
								menuName={menuIds.languageMenu}
								open={currentOpenedMenu === menuIds.languageMenu}
								onClose={onClose}
								onOpen={onOpen}
								onOpenHover={onOpenHover}
							/>
						</span>
					</>
				)}
			</Toolbar>
			<VersioningDialog handleClose={() => setOpenVersion(false)} open={openVersion} />
			{project && isSegFeatureEnabled(SegFlags.TEAM) && <NoRoleOnDocumentBanner />}
		</>
	);
};
export default Navbar;
