import React from "react";
import { useSelector } from "react-redux";
import RedressInfo from "./redress-information/RedressInfo";

export default function RedressLayer({ hoveredBlock, metadata, page, width, overlayHeight, top, left }) {
	const redressingInformation = useSelector(({ srDocument }) => srDocument.redressingInformation);
	const frozenDocument = useSelector(({ srDocument }) => srDocument.frozenDocument);
	const redressingPage = useSelector(({ srDocument }) => srDocument.redressingPage);
	return (
		<>
			{redressingInformation && !frozenDocument && page <= redressingPage + 1 && page >= redressingPage - 1 && (
				<RedressInfo
					height={overlayHeight}
					hoveredMetadata={hoveredBlock}
					left={left}
					metadata={metadata}
					top={overlayHeight ? top - overlayHeight : top - 32}
					width={width}
				/>
			)}
		</>
	);
}
