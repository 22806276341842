import React, { useEffect, useRef, useState } from "react";
import { Box, Chip } from "@mui/material";
import Axios from "axios";
import styles from "./QADetails.module.css";
import {
	IconComponent,
	CustomIconButton,
	icon,
	SidePanel,
	SidePanelContent,
	createNotification,
	CustomButton,
} from "../../../../common/components";
import { translate, translateDate } from "../../../../common/providers";
import { ApiService, ContentService, QAService } from "../../../../api";
import { CancelDialog, RelatedContent, UpdateQaDialog } from "./components";

export default function QADetails({
	qaDetails,
	selectedQa,
	displayDocuments,
	onChangeDisplayReference,
	updateQaQuestionAsked,
	references,
	projectId,
	displayClauses,
	onClickClause,
	clausesLists,
	onGoBack,
	projectReferences,
	onSaveRelatedContents,
	onSearchDocuments,
	onSearchClauses,
	contentLoading,
	onClearDocumentsSearch,
	onClearClausesSearch,
}) {
	const [displayModal, setDisplayModal] = useState(false);
	const [editContentOn, setEditContentOn] = useState(false);
	const [panelTitle, setPanelTitle] = useState(translate("qa-journal.qa-details.qa-details.panel-title"));
	const [openUpdateQaDialog, setOpenUpdateQaDialog] = useState(false);
	const [openCancelDialog, setOpenCancelDialog] = useState(false);
	const [modifications, setModifications] = useState([]);
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleReferenceCoordinates = (docId, contentId) =>
		ContentService.getContentCoordinates(
			{
				docId,
				contentId,
			},
			cancelTokenSourceRef.current.token
		);

	const handleModalChange = () => {
		setDisplayModal(!displayModal);
		if (!displayModal) {
			onChangeDisplayReference(false, false);
			setEditContentOn(false);
		}
	};
	const handleOpenSidePanel = () => {
		onChangeDisplayReference(true, false);
		handleModalChange();
	};
	const handleClickEditContent = () => {
		setEditContentOn(true);
		onChangeDisplayReference(true, false);
		setPanelTitle(translate("qa-journal.qa-details.qa-details.panel-title.edit"));
	};
	const handleOpenUpdateQaDialog = () => {
		setOpenUpdateQaDialog(true);
	};
	const handleCloseDialog = () => {
		setOpenUpdateQaDialog(false);
	};
	const handleUpdateQa = (qaText, clientId, answer) => {
		updateQaQuestionAsked(qaText, clientId, answer);
		handleCloseDialog();
	};
	const handleClickSave = () => {
		const requests = [];
		modifications.forEach((m) => {
			if (m.checked) {
				const payload = {};
				if (m.clause) {
					payload.clauseId = m.clause.id;
				} else {
					payload.documentId = m.document.documentId;
				}
				requests.push(
					QAService.updateQaReference(
						{ projectId, qaId: selectedQa.id },
						payload,
						cancelTokenSourceRef.current.token
					)
				);
			} else {
				requests.push(
					QAService.deleteQaReference(
						{
							projectId,
							qaId: selectedQa.id,
							referenceId: m.clause ? m.clause.referenceId : m.document.referenceId,
						},
						cancelTokenSourceRef.current.token
					)
				);
			}
		});
		Axios.all(requests)
			.then(
				Axios.spread(() => {
					createNotification({
						type: "success",
						message: translate("qa-journal.qa-details.update-qa-dialog.notification.modifications-saved"),
					});
					setEditContentOn(false);
					setModifications([]);
					onSaveRelatedContents(selectedQa);
				})
			)
			.catch((err) => {
				console.error(err);
				setEditContentOn(false);
				setModifications([]);
			});
	};
	const handleCheckDocument = (checked, document) => {
		const newModifications = [...modifications];
		const modificationIndex = newModifications.findIndex(
			(m) => m.document && m.document.documentId === document.documentId
		);
		if (modificationIndex >= 0) {
			newModifications.splice(modificationIndex, 1);
		} else {
			newModifications.push({
				document,
				checked,
			});
		}
		setModifications(newModifications);
	};
	const handleCheckClause = (checked, clause, docId = 0) => {
		const newModifications = [...modifications];
		const modificationIndex = newModifications.findIndex((m) => m.clause && m.clause.id === clause.id);

		if (modificationIndex >= 0) {
			newModifications.splice(modificationIndex, 1);
		} else {
			newModifications.push({
				clause,
				checked,
				modificationDocumentId: docId,
			});
		}
		setModifications(newModifications);
	};
	const handleCloseCancelDialog = () => {
		setOpenCancelDialog(false);
	};
	const handleCancel = () => {
		handleCloseCancelDialog();
		setEditContentOn(false);
		setModifications([]);
	};
	const handleOpenCancelDialog = () => {
		if (modifications.length > 0) {
			setOpenCancelDialog(true);
		} else {
			handleCancel();
		}
	};
	return selectedQa ? (
		<>
			<div className={styles["details-header"]}>
				<div className={styles["title-container"]}>
					<div className={styles["title-container__row"]}>
						<CustomButton
							color="secondary"
							startIcon={icon.faSitemap}
							variant="contained"
							onClick={handleOpenSidePanel}
						>
							<Box className={styles["qa-navigation"]}>
								{translate("qa-journal.qa-details.references", {
									qaDetails: qaDetails.referencesNumber,
								})}
							</Box>
						</CustomButton>
						<div className={styles.asker}>{qaDetails.asker}</div>
						<div className={styles.title__tag}>
							{qaDetails.tags &&
								qaDetails.tags.map((tag) => (
									<Chip
										key={tag.id}
										className={styles.tag}
										color="default"
										label={tag.tag}
										size="small"
									/>
								))}
						</div>
					</div>
					<div className={styles["title-container__row"]}>
						{qaDetails.clientId && (
							<Box className={styles.title__id}>
								{translate("qa-journal.qa-details.client-id", { clientId: qaDetails.clientId })}
							</Box>
						)}
						<Box className={styles.title__id}>
							{translate("qa-journal.qa-details.import-id", { qaId: qaDetails.id })}
						</Box>
					</div>
				</div>
				<CustomIconButton
					icon={icon.faPen}
					iconClassName={styles.edit}
					size="lg"
					onClick={handleOpenUpdateQaDialog}
				/>
			</div>
			<div className={styles.bloc}>
				<div className={styles.dates}>
					<span>
						{translate("qa-journal.qa-details.qa-details.date.created", {
							createdDate: translateDate(qaDetails.created),
						})}
					</span>
					<span>
						{translate("qa-journal.qa-details.qa-details.date.sent", {
							sentDate: translateDate(qaDetails.sent),
						})}
					</span>
					<span>
						{translate("qa-journal.qa-details.qa-details.date.received", {
							receivedDate: translateDate(qaDetails.received),
						})}
					</span>
				</div>
				<div className={styles.content}>
					{qaDetails.questionAsked?.split("\n").map((q) => (
						<p key={q} className={styles.commentLine}>
							{q}
						</p>
					))}
				</div>
			</div>
			<div className={styles.bloc}>
				<div className={styles["answer-head"]}>{translate("qa-journal.qa-details.qa-details.answer.head")}</div>
				<div className={styles["date-b"]} />
				<div className={styles.content}>
					{(!!qaDetails.questionAnswer &&
						qaDetails.questionAnswer?.split("\n").map((a) => (
							<p key={a} className={styles.commentLine}>
								{a}
							</p>
						))) ||
						translate("qa-journal.qa-details.qa-details.answer.empty")}
				</div>
			</div>
			<UpdateQaDialog
				close={handleCloseDialog}
				open={openUpdateQaDialog}
				qaDetails={qaDetails}
				onUpdateQa={handleUpdateQa}
			/>
			<CancelDialog close={handleCloseCancelDialog} open={openCancelDialog} onCancel={handleCancel} />
			<SidePanel open={displayModal} size={40} onClose={handleModalChange}>
				<SidePanelContent title={panelTitle} onClose={handleModalChange}>
					<RelatedContent
						clausesLists={clausesLists}
						contentLoading={contentLoading}
						displayClauses={displayClauses}
						displayDocuments={displayDocuments}
						editContentOn={editContentOn}
						handleCoordinates={handleReferenceCoordinates}
						modifications={modifications}
						projectId={projectId}
						projectReferences={projectReferences}
						references={references}
						onCancel={handleOpenCancelDialog}
						onChangeDisplayReference={onChangeDisplayReference}
						onCheckClause={handleCheckClause}
						onCheckDocument={handleCheckDocument}
						onClearClausesSearch={onClearClausesSearch}
						onClearDocumentsSearch={onClearDocumentsSearch}
						onClickClause={onClickClause}
						onClickEditContent={handleClickEditContent}
						onClickSave={handleClickSave}
						onGoBack={onGoBack}
						onSearchClauses={onSearchClauses}
						onSearchDocuments={onSearchDocuments}
					/>
				</SidePanelContent>
			</SidePanel>
		</>
	) : (
		<div className={styles["no-content"]}>
			<IconComponent color="var(--color-light-grey-4)" icon={icon.faHandPointUp} size="5xl" />
			<span>{translate("qa-journal.qa-details.qa-details.no-question-selected")}</span>
		</div>
	);
}
