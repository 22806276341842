import client from "../../client/client";

class ProjectTeamService {
	getCompanies({ projectId }, cancelToken) {
		return client.get(`/projects/${projectId}/companies`, { config: { cancelToken } });
	}

	getUsersCountNoRole({ projectId }, cancelToken) {
		return client.get(`/projects/${projectId}/noroles`, { config: { cancelToken } });
	}

	changeCompanyAccess({ projectId, companyId }, status, cancelToken) {
		return client.put(`/projects/${projectId}/companies/${companyId}`, {
			payload: status.toString(),
			config: { cancelToken },
		});
	}

	searchCompanyMembers({ projectId, companyId }, search, cancelToken) {
		return client.get(`/projects/${projectId}/companies/${companyId}/users/search`, {
			params: search,
			config: { cancelToken },
		});
	}

	getUsersByCompany({ projectId, companyId }, { filter, status, page, limit }, cancelToken) {
		return client.get(`/projects/${projectId}/companies/${companyId}/users`, {
			params: { filter, status, page, limit },
			config: { cancelToken },
		});
	}

	getCounts({ projectId, companyId }, cancelToken) {
		return client.get(`/projects/${projectId}/companies/${companyId}/users/counters`, {
			config: { cancelToken },
		});
	}

	getUsersInProject({ projectId }, { limit = 500 }, cancelToken) {
		return client.get(`/projects/${projectId}/users`, { params: { limit }, config: { cancelToken } });
	}

	getSharingCompanies({ projectId }, cancelToken) {
		return client.get(`/projects/${projectId}/companies/shared`, { config: { cancelToken } });
	}

	changeUserAccess({ projectId, userId }, payload, cancelToken) {
		return client.put(`/projects/${projectId}/users/${userId}`, {
			payload,
			config: { cancelToken },
		});
	}

	joinProject({ projectId }, cancelToken) {
		return client.get(`/projects/${projectId}/join`, {
			params: { projectId },
			config: { cancelToken },
		});
	}

	changeUserRole({ projectId, userId }, payload, cancelToken) {
		return client.patch(`/projects/${projectId}/users/${userId}`, { payload, config: { cancelToken } });
	}

	addExistingUsers({ projectId, companyId }, payload, cancelToken) {
		return client.post(`/projects/${projectId}/companies/${companyId}/users/add`, {
			config: { cancelToken },
			payload,
		});
	}

	addExistingUsersBulk({ projectId, companyId }, payload, cancelToken) {
		return client.post(`/projects/${projectId}/companies/${companyId}/users/bulk`, {
			payload,
			config: { cancelToken },
		});
	}

	addExistingPartner({ projectId, companyId }, cancelToken) {
		return client.post(`/projects/${projectId}/companies/${companyId}`, { config: { cancelToken } });
	}

	createAndAddUser({ projectId, companyId }, payload, cancelToken) {
		return client.post(`/projects/${projectId}/companies/${companyId}/users/create`, {
			payload,
			config: { cancelToken },
		});
	}

	createConsortium({ projectId }, payload, cancelToken) {
		return client.post(`/projects/${projectId}/consortiums`, { payload, config: { cancelToken } });
	}

	updateCompanyChildren({ projectId, companyId }, payload, cancelToken) {
		return client.put(`/projects/${projectId}/companies/${companyId}/children`, {
			payload,
			config: { cancelToken },
		});
	}

	searchUsersInProject({ projectId }, name, cancelToken) {
		return client.get(`/projects/${projectId}/users/search`, { params: { name }, config: { cancelToken } });
	}

	searchUsersInProjectAndCompany({ projectId }, name, cancelToken) {
		return client.get(`/projects/${projectId}/company/users`, { params: { name }, config: { cancelToken } });
	}

	getAvailableChildrenFromParentExclusive({ projectId }, cancelToken) {
		return client.get(`/projects/${projectId}/companies/available`, { config: { cancelToken } });
	}

	getAvailableChildrenFromParentInclusive({ projectId, companyId }, payload, cancelToken) {
		return client.get(`/projects/${projectId}/companies/${companyId}/available`, {
			params: { search: payload },
			config: { cancelToken },
		});
	}

	getPartners({ projectId }, cancelToken) {
		return client.get(`/projects/${projectId}/partners/shared`, { config: { cancelToken } });
	}

	getAvailableUsers({ projectId }, { filter, page, limit }, cancelToken) {
		return client.get(`/projects/${projectId}/users/mycompany/search`, {
			config: { cancelToken },
			params: { filter, page, limit },
		});
	}

	getAvailableUsersForConsortium({ projectId, consortiumId }, { filter, page, limit }, cancelToken) {
		return client.get(`/projects/${projectId}/consortiums/${consortiumId}/users/available`, {
			config: { cancelToken },
			params: { filter, page, limit },
		});
	}

	sendInvitation({ projectId, userId, companyId }, cancelToken) {
		return client.post(`/projects/${projectId}/companies/${companyId}/users/${userId}/invite`, {
			config: { cancelToken },
		});
	}

	checkEmails({ projectId, companyId }, payload, cancelToken) {
		return client.post(`/projects/${projectId}/companies/${companyId}/users/check`, {
			payload,
			config: { cancelToken },
		});
	}

	getCompanyPrivacies({ projectId }, cancelToken) {
		return client.get(`/privacy/projects/${projectId}`, { config: { cancelToken } });
	}

	updateCompaniesPrivacies({ projectId }, payload, cancelToken) {
		return client.patch(`/privacy/projects/${projectId}`, { payload, config: { cancelToken } });
	}
}

export default new ProjectTeamService();
