import { KEYWORD_FILTER_TYPE } from "../../../../../common/constants";
import { isDeepEqualComplex, removeUnderscore } from "../../../../../common/utils";
import { translateEnumDocumentUserRole } from "../../../../../common/providers";
import { CLASSIFICATION, CONTENT_TYPE, THEMATICS, isPositiveKeywordFilter } from "../utils/utils";

export function removeNullFilters(filters) {
	const newFilters = {};
	if (filters === null || typeof filters !== "object") {
		return newFilters;
	}
	Object.entries(filters).forEach(([fKey, value]) => {
		if (!fKey || value === undefined || value === null) {
			return;
		}
		newFilters[fKey] = value;
	});
	return newFilters;
}
export function hasBeenModified({ filters, previousFilters, separator, previousSeparator }) {
	return [separator === previousSeparator, isDeepEqualComplex(filters, previousFilters)].some((eq) => !eq);
}
export function setNewResult(result, payload) {
	const { modification, key, value, type, analyticalAxe, rows } = payload;
	switch (modification) {
		case CLASSIFICATION: {
			const params = { ...result.attributes.parameters };
			if (value === "None") {
				delete params[key?.toLowerCase()];
			} else {
				params[key?.toLowerCase()] = value.toUpperCase();
			}
			return {
				...result,
				attributes: {
					...result.attributes,
					parameters: params,
				},
			};
		}
		case CONTENT_TYPE:
			return { ...result, type };
		case THEMATICS: {
			const currentAxe = result.categories.find((ax) => ax.analyticalAxeId === analyticalAxe.id);
			const currentCategories = currentAxe?.categories || [];
			const categories = rows
				.filter((r) => r.checked || (r.count > 0 && !r.visited && currentCategories.some((c) => c.id === r.id)))
				.map(({ id, name }) => ({ id, name }));
			return {
				...result,
				categories: (currentAxe &&
					result.categories.map((ax) =>
						ax.analyticalAxeId === analyticalAxe.id ? { ...ax, categories } : ax
					)) || [
					...result.categories,
					{
						analyticalAxeId: analyticalAxe.id,
						analyticalAxeName: analyticalAxe.name,
						categories,
					},
				],
			};
		}
		default:
			return result;
	}
}
function incrementKeywordFilters(keywordFilters, types, { numberOfPositiveKeywordFilters, hasNegativeKeywordFilters }) {
	types.forEach((type) => {
		if (keywordFilters[type] && Array.isArray(keywordFilters[type].filters)) {
			keywordFilters[type].filters.forEach((filter) => {
				const { operator } = filter;
				if (isPositiveKeywordFilter(operator)) {
					++numberOfPositiveKeywordFilters[type];
				} else {
					hasNegativeKeywordFilters[type] = true;
				}
			});
		}
	});
}
export function computeKeywordFiltersExistence({ keywordFilters, tocFilters }) {
	const hasNegativeKeywordFilters = { [KEYWORD_FILTER_TYPE.PARAGRAPH]: false, [KEYWORD_FILTER_TYPE.TOC]: false };
	const numberOfPositiveKeywordFilters = { [KEYWORD_FILTER_TYPE.PARAGRAPH]: 0, [KEYWORD_FILTER_TYPE.TOC]: 0 };
	if (
		(!keywordFilters || !Array.isArray(keywordFilters.filters) || keywordFilters.length === 0) &&
		(!tocFilters || !Array.isArray(tocFilters.filters) || tocFilters.length === 0)
	) {
		return { hasNegativeKeywordFilters, numberOfPositiveKeywordFilters };
	}
	incrementKeywordFilters(
		{ [KEYWORD_FILTER_TYPE.PARAGRAPH]: keywordFilters, [KEYWORD_FILTER_TYPE.TOC]: tocFilters },
		[KEYWORD_FILTER_TYPE.PARAGRAPH, KEYWORD_FILTER_TYPE.TOC],
		{ hasNegativeKeywordFilters, numberOfPositiveKeywordFilters }
	);
	return { hasNegativeKeywordFilters, numberOfPositiveKeywordFilters };
}

export function formatDocuments(documents, isDocument) {
	const rows = [];
	documents.forEach((doc) => {
		const { document, documentId, total, done, users, role, totalUnfiltered, reviewedUnfiltered, ...info } = doc;
		rows.push({
			...info,
			name: isDocument ? removeUnderscore(document) : users.displayName,
			id: documentId,
			done,
			total,
			totalReqs: totalUnfiltered,
			allReqs: reviewedUnfiltered,
			role: (role && translateEnumDocumentUserRole(role)) || users?.map((u) => u.displayName)?.join(", ") || "",
		});
	});
	return rows;
}
