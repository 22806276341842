import { Flags } from "../../common/providers";

export default [
	{
		name: "navigation:common.opportunities",
		to: "/opportunity",
		flag: Flags.OPPORTUNITY,
	},
	{
		name: "navigation:common.projects",
		to: "/",
	},
	{
		name: "navigation:common.templates",
		subItems: [
			{
				name: "navigation:common.templates.search-templates",
				to: "/search-templates/search-templates",
				flag: Flags.SEARCHTEMPLATE,
			},
			{
				name: "navigation:common.templates.category-templates",
				to: "/category-template",
				flag: Flags.CATEGORYTEMPLATE,
			},
		],
	},
	{
		name: "navigation:common.multi-project-search",
		to: "/multi-project-search",
		flag: Flags.WRITE_PLUGIN,
	},
];
