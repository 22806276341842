import { debounce } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GenericDialog, GenericDialogHeader } from "../..";
import { ApiService, CategoryService, FavoriteCategoryService } from "../../../../api";
import { translate } from "../../../providers";
import {
	subscribeToCategory,
	unsubscribeToCategory,
	openFavoriteCategories,
	closeFavoriteCategories,
} from "../../../slice";
import { updateTreeBranch } from "../../../utils";
import { FavoriteCategoriesContent } from "./components";
import FavoriteCategoriesFooter from "./components/favorite-categories-footer/FavoriteCategoriesFooter";

const debounceSearch = debounce((searchFunction) => {
	searchFunction();
}, 1000);

const FavoriteCategoriesDialog = () => {
	const projectId = useSelector(({ context }) => context.project?.id);
	const favoriteCategoriesOpen = useSelector(({ context }) => context.favoriteCategories.open);
	const isManaging = useSelector(({ context }) => context.favoriteCategories.isManaging);
	const projectName = useSelector(({ context }) => context.project.name);
	const [isTreeLoading, setIsTreeLoading] = useState(false);
	const [categories, setCategories] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [searchValueDebounced, setSearchValueDebounced] = useState("");
	const [isWelcome, setIsWelcome] = useState(true);
	const cancelTokenSourceRef = useRef();
	const dispatch = useDispatch();

	const getTree = useCallback(
		(searchText = "") => {
			setIsTreeLoading(true);
			if (searchText) {
				CategoryService.getTreeForProject({ projectId, search: searchText }, cancelTokenSourceRef.current.token)
					.then((data) => {
						setCategories(data || []);
					})
					.catch((err) => console.error(err))
					.finally(() => setIsTreeLoading(false));
			} else {
				CategoryService.getTreeParentsForProject({ projectId }, cancelTokenSourceRef.current.token)
					.then((data) => {
						setCategories(data || []);
					})
					.catch((err) => console.error(err))
					.finally(() => setIsTreeLoading(false));
			}
		},
		[projectId]
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		dispatch(openFavoriteCategories);
	}, [dispatch]);
	useEffect(() => {
		if (searchValue === searchValueDebounced) {
			getTree(searchValue);
		}
	}, [getTree, searchValue, searchValueDebounced]);

	const handleClearSearchValue = () => {
		setSearchValue("");
		setSearchValueDebounced("");
	};
	const handleChangeSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
		debounceSearch(() => setSearchValueDebounced(value));
	};
	const handleToggleExpand = (category) => {
		let isExpanded = true;
		if (category.filtered === false && category.expanded === undefined) {
			isExpanded = false;
		}
		if (
			category.filtered === undefined &&
			!category.expanded &&
			(!Array.isArray(category.subThematic) || category.subThematic?.length === 0)
		) {
			CategoryService.getTreeChildrenForProject({ parentId: category.id }, cancelTokenSourceRef.current.token)
				.then((data) => {
					setCategories((prev) =>
						updateTreeBranch({
							tree: prev,
							idToReplace: category.id,
							newBranch: {
								...category,
								subThematic: data,
								expanded:
									!category.filtered && category.expanded === undefined
										? isExpanded
										: !category.expanded,
							},
							iterativeBranchName: "subThematic",
						})
					);
				})
				.catch((err) => console.error(err));
		} else {
			setCategories((prev) =>
				updateTreeBranch({
					tree: prev,
					idToReplace: category.id,
					newBranch: {
						...category,
						expanded:
							!category.filtered && category.expanded === undefined ? isExpanded : !category.expanded,
					},
					iterativeBranchName: "subThematic",
				})
			);
		}
	};
	const handleSubscribe = (e, category) => {
		const { id, name, description } = category;
		e.stopPropagation();
		FavoriteCategoryService.subscribeToCategory(
			{ projectId, categoryId: category.id },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				dispatch(
					subscribeToCategory({
						id,
						name,
						description,
					})
				);
			})
			.catch((err) => console.error(err));
	};
	const handleUnsubscribe = (e, category) => {
		e.stopPropagation();
		FavoriteCategoryService.unsubscribeFromCategory(
			{ projectId, categoryId: category.id },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				dispatch(unsubscribeToCategory(category.id));
			})
			.catch((err) => console.error(err));
	};
	const handleCloseFavoriteCategories = () => {
		dispatch(closeFavoriteCategories());
	};
	const getHeader = () => {
		if (isManaging) {
			return translate("common:favorite-category-dialog.header.manage");
		}
		if (isWelcome) {
			return translate("common:favorite-category-dialog.header.welcome", { projectName });
		}
		return translate("common:favorite-category-dialog.header.subscribe");
	};
	const handleBack = () => {
		setIsWelcome(true);
	};
	const handleNext = () => {
		setIsWelcome(false);
	};
	return (
		<>
			<GenericDialog open={favoriteCategoriesOpen}>
				<GenericDialogHeader onClose={handleCloseFavoriteCategories}>{getHeader()}</GenericDialogHeader>
				<FavoriteCategoriesContent
					categories={categories}
					isManaging={isManaging}
					isTreeLoading={isTreeLoading}
					isWelcome={isWelcome}
					searchValue={searchValue}
					onChangeSearchValue={handleChangeSearchValue}
					onClearSearchValue={handleClearSearchValue}
					onSubscribe={handleSubscribe}
					onToggleExpand={handleToggleExpand}
					onUnsubscribe={handleUnsubscribe}
				/>
				<FavoriteCategoriesFooter
					isManaging={isManaging}
					isWelcome={isWelcome}
					onBack={handleBack}
					onCloseFavoriteCategories={handleCloseFavoriteCategories}
					onNext={handleNext}
				/>
			</GenericDialog>
		</>
	);
};

export default FavoriteCategoriesDialog;
