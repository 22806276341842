import React from "react";
import { useSelector } from "react-redux";
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { CustomDialog, CustomIconButton, CustomTooltip, icon, IconComponent } from "../../../../../common/components";
import styles from "./EditDialog.module.css";
import { translate } from "../../../../../common/providers";
import { VISIBILITY } from "../../../../../common/constants";

export default function EditDialog({ isForMySearch, items, onClose, onEdit, onRemove, open, title, userId }) {
	const companyName = useSelector(({ context }) => context.company.name);
	return (
		<CustomDialog
			displayCloseCross
			isForm
			closeLabel={translate("common:btn.ok")}
			open={open}
			title={title}
			onClose={onClose}
		>
			<List dense>
				{items.map((item) => (
					<ListItem key={item.id}>
						<CustomTooltip arrow placement="left" title={item.title}>
							<ListItemText
								classes={{ primary: styles.item__title }}
								primary={item.title}
								secondary={
									(item.visibility !== VISIBILITY.PRIVATE && (
										<span className={styles.item__subTitle}>
											<IconComponent
												color="var(--color-light-grey-2)"
												icon={icon.faUsers}
												size="xs"
											/>
											{translate(
												`smart-search.enum.visibility.${item.visibility?.toLowerCase()}`,
												{ companyName }
											)}
										</span>
									)) ||
									""
								}
							/>
						</CustomTooltip>
						{(!isForMySearch || item.creatorId === userId) && (
							<ListItemSecondaryAction>
								<CustomIconButton icon={icon.faPen} size="md" onClick={() => onEdit(item)} />
								<CustomIconButton
									data-testid={`delete_${item.id}`}
									icon={icon.faTrash}
									size="md"
									onClick={() => onRemove(item)}
								/>
							</ListItemSecondaryAction>
						)}
					</ListItem>
				))}
			</List>
		</CustomDialog>
	);
}
