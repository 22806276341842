import { Checkbox } from "@mui/material";
import React from "react";
import styles from "./ListRow.module.css";
import { CustomTooltip } from "../../../tooltip";

const ListRow = ({ checked, labelName = "", row, onClick }) => {
	const handleClick = () => {
		onClick(row.id);
	};
	return (
		<div className={styles.rowContainer} role="presentation" onClick={handleClick}>
			<Checkbox checked={checked} className={styles.checkBox} size="small" />
			<CustomTooltip showWhenOverflow title={row.name || row[labelName]}>
				<span className={styles.rowName}>{row.name || row[labelName]}</span>
			</CustomTooltip>
		</div>
	);
};

export default ListRow;
