import client from "../client/client";

class FavoriteCategoryService {
	getDocumentsByFavouriteCategories({ projectId }, payload, { page, limit }, cancelToken) {
		return client.post(`/favorite-category/projects/${projectId}`, {
			payload,
			params: { page, limit },
			config: { cancelToken },
		});
	}

	getAllFavoriteCategories({ projectId }, cancelToken) {
		return client.get(`/favorite-category/projects/${projectId}/categories`, { config: { cancelToken } });
	}

	getAllUsersByCategory({ projectId, categoryId, name }, cancelToken) {
		return client.get(`/favorite-category/projects/${projectId}/categories/${categoryId}`, {
			config: { cancelToken },
			params: { name },
		});
	}

	subscribeToCategory({ projectId, categoryId }, cancelToken) {
		return client.put(`/favorite-category/projects/${projectId}/categories/${categoryId}/subscribe`, {
			config: { cancelToken },
		});
	}

	unsubscribeFromCategory({ projectId, categoryId }, cancelToken) {
		return client.put(`/favorite-category/projects/${projectId}/categories/${categoryId}/unsubscribe`, {
			config: { cancelToken },
		});
	}

	getCategorySubscribtions({ projectId, search }, cancelToken) {
		return client.get(`favorite-category/projects/${projectId}/subscription`, {
			params: { search },
			config: { cancelToken },
		});
	}
}
export default new FavoriteCategoryService();
