import React from "react";
import { translate } from "../../../../../../../common/providers";
import styles from "./EmptyState.module.css";
import { CustomButton } from "../../../../../../../common/components";

export default function EmptyState({ hasFilters, onResetFilters }) {
	return (
		<div className={styles.emptyState}>
			<p>
				{hasFilters
					? translate("norms-center.tab.detections.empty-state-with-filter")
					: translate("norms-center.tab.detections.empty-state")}
			</p>
			<span>
				{hasFilters && (
					<CustomButton color="primary" onClick={onResetFilters}>
						{translate("norms-center.norm-table.filter-reset")}
					</CustomButton>
				)}
			</span>
		</div>
	);
}
