import React, { useState, useRef, useEffect } from "react";
import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconComponent, icon, TextMidCut, CustomTooltip, CircularLoader } from "../../../../../../common/components";
import styles from "./CategoryRow.module.css";
import { setCurrentCategory } from "../../../../slice/gonogo-slice";
import { ApiService } from "../../../../../../api";
import { RISK_LEVEL } from "../../../../utils/utils";
import { NavUtils } from "../../../../../../navigation";

export default function CategoryRow({
	category: { subTree, name, contentsCount, attachedContentsCount, note, id, ...rest },
	marginLeft = 0,
}) {
	const [open, setOpen] = useState(true);
	const cancelTokenSourceRef = useRef(null);
	const projectId = useSelector(({ context }) => context.project.id);
	const currentCategory = useSelector(({ gonogo }) => gonogo.currentCategory);
	const isAnalysisInProgress = useSelector(({ gonogo }) => gonogo.isAnalysisInProgress);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const handleOpen = (e) => {
		e.stopPropagation();
		setOpen((prev) => !prev);
	};
	const handleSelectCategory = (e) => {
		e.stopPropagation();
		if (id !== currentCategory?.id) {
			navigate(NavUtils.goToReviewByCategory({ projectId, categoryId: id, attached: false }));
			dispatch(setCurrentCategory({ ...rest, name, contentsCount, attachedContentsCount, note, id }));
		}
	};
	return (
		<>
			<div
				className={styles.row}
				data-clickable={Array.isArray(subTree) && subTree.length > 0}
				data-selected={currentCategory?.id === id}
				data-testid={`category_${id}`}
				role="presentation"
				onClick={handleSelectCategory}
			>
				<div
					className={styles.label}
					data-testid={`category.${id}.row.label`}
					style={{
						paddingLeft: marginLeft + 8 * ((subTree || []).length > 0 ? 1 : 2),
					}}
				>
					{(subTree || []).length > 0 && (
						<span role="presentation" onClick={handleOpen}>
							<IconComponent
								className={styles["label--icon"]}
								color="var(--color-light-grey-2)"
								icon={open ? icon.faChevronDown : icon.faChevronRight}
							/>
						</span>
					)}
					<TextMidCut fullWidth tooltip charsKeptInEnd={6} text={name} />
				</div>
				<div>
					<CustomTooltip showWhenOverflow title={contentsCount}>
						{(isAnalysisInProgress && <CircularLoader size={14} />) || (
							<span className={styles.details} data-testid={`category.${id}.content`}>
								{contentsCount}
							</span>
						)}
					</CustomTooltip>
					<CustomTooltip showWhenOverflow title={attachedContentsCount}>
						<span className={styles.details} data-testid={`category.${id}.attach`}>
							{attachedContentsCount}
						</span>
					</CustomTooltip>
					<div>
						<Avatar
							classes={{ root: styles.avatar, square: `${styles.square} risk-level` }}
							data-risk-level={note}
							variant="square"
						>
							{(note && RISK_LEVEL[note]) || "-"}
						</Avatar>
					</div>
				</div>
			</div>
			{open &&
				Array.isArray(subTree) &&
				subTree.length > 0 &&
				subTree.map((sub) => <CategoryRow key={sub.id} category={sub} marginLeft={marginLeft + 8} />)}
		</>
	);
}
