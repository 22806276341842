import React from "react";
import { CustomSwitch } from "../../../../../../common/components";
import styles from "./SwitchOption.module.css";

export default function SwitchOption({ isChecked, onChange, text }) {
	return (
		<div className={styles.option}>
			<span className={styles.option__text}>{text}</span>
			<span className={styles.option__control}>
				<CustomSwitch checked={isChecked} color="primary" onChange={onChange} />
			</span>
		</div>
	);
}
