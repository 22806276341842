import { useDispatch, useSelector } from "react-redux";
import React, { useMemo, useState, useRef, useEffect, useCallback } from "react";
import {
	CustomIconButton,
	CustomTooltip,
	icon,
	SidePanelBox,
	PositionIcon,
	LimitedAutocompleteTextField,
} from "../../../../../../../../../common/components";
import { POSITIONS_STATUSES } from "../../../../../../../../../common/constants/positions-statuses";
import {
	Flags,
	isFeatureEnabled,
	translate,
	translateDate,
	translateElapsed,
} from "../../../../../../../../../common/providers";
import { debounce } from "../../../../../../../../../common/utils";
import styles from "./PositionComment.module.css";
import { setDisableKeyEvents } from "../../../../../../../../../common/slice";
import CommentHighlightedText from "../../../../../../../../../common/components/comment-highlighted-text/CommentHighlightedText";
import { ROLES } from "../../../../../../../constants/constants";
import { ApiService } from "../../../../../../../../../api";

const debounceFunc = debounce((func) => func(), 500);
export default function PositionComment({
	className = "",
	disableEditing = false,
	disableHighlighting = false,
	editable = false,
	isEditingAsValidator = false,
	isValidation = false,
	onAddComment,
	onChangePositionStatus,
	onChangeTempComment,
	onEditComment,
	position = {},
	primaryName = "",
	secondaryName = "",
	tempComment = "",
}) {
	const [iconsHovered, setIconsHovered] = useState(false);
	const [isEditComment, setIsEditComment] = useState(false);
	const [shiftPressed, setShiftPressed] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const teamMembers = useSelector(({ context }) => context.teamMembers);
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const { pageStart, infoId, informationId } = useSelector(({ srDocument }) => srDocument?.selectedSearch);
	const contentMetadata = useSelector(({ srPdf }) => srPdf.content);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	const commentRef = useRef(null);

	useEffect(
		() => {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
			return () => {
				ApiService.cancelTokens(cancelTokenSourceRef.current);
				if (disableKeyEvents) {
					dispatch(setDisableKeyEvents(false));
				}
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	useEffect(() => {
		setIsEditComment(false);
		setIsActive(false);
	}, [position]);
	const infoMetadata = useMemo(
		() =>
			contentMetadata
				.find((p) => p.page === pageStart)
				?.[mode]?.find((info) => info.informationId === informationId || info.informationId === infoId) || null,
		[contentMetadata, mode, pageStart, infoId, informationId]
	);
	const hasReviewerRole = useSelector(({ srDocument }) =>
		(srDocument.roles || []).some((x) => x === ROLES.REVIEWER.name.toUpperCase())
	);
	const isUpdatable = useMemo(
		() =>
			(mode === ROLES.REVIEWER.mode &&
				!infoMetadata?.isFrozen &&
				!infoMetadata?.isFrozenByConsortium &&
				hasReviewerRole) ||
			(mode === ROLES.VALIDATOR.mode && !infoMetadata?.isFrozenByConsortium),
		[mode, infoMetadata, hasReviewerRole]
	);
	const handleAddComment = useCallback(() => {
		setIsEditComment(false);
		onAddComment(tempComment);
	}, [onAddComment, tempComment]);
	useEffect(() => {
		const handleKeyReleased = (e) => {
			if (isActive && e.key === "Shift") {
				setShiftPressed(false);
			}
		};
		const handleKeyDetect = (e) => {
			if (isActive && e.key === "Shift") {
				setShiftPressed(true);
			}
			if (isActive && e.key === "Enter" && !shiftPressed) {
				const autocompleteOpen = document.querySelector(
					"[role='listbox'][class*='_mentions__suggestions__list_']"
				);
				if (!autocompleteOpen && tempComment?.length > 0 && /\S/.test(tempComment)) {
					handleAddComment();
					if (disableKeyEvents) {
						dispatch(setDisableKeyEvents(false));
						setIsActive(false);
					}
				}
			}
			if (isActive && (e.key === "ArrowLeft" || e.key === "ArrowRight")) {
				e.preventDefault();
				const textField = e.target;
				if (e.key === "ArrowLeft") {
					if (textField.selectionStart > 0) {
						textField.setSelectionRange(textField.selectionStart - 1, textField.selectionStart - 1);
					}
				} else if (e.key === "ArrowRight") {
					if (textField.selectionStart < textField.value.length) {
						textField.setSelectionRange(textField.selectionStart + 1, textField.selectionStart + 1);
					}
				}
			}
		};
		window.addEventListener("keydown", handleKeyDetect, true);
		window.addEventListener("keyup", handleKeyReleased, true);
		return () => {
			window.removeEventListener("keydown", handleKeyDetect, true);
			window.removeEventListener("keyup", handleKeyReleased, true);
		};
	}, [dispatch, shiftPressed, isActive, tempComment, disableKeyEvents, handleAddComment]);

	const handleMouseIconEnter = (e) => {
		e.stopPropagation();
		debounceFunc(() => {
			setIconsHovered(true);
		});
	};
	const handleMouseIconLeave = (e) => {
		e.stopPropagation();
		debounceFunc(() => {
			setIconsHovered(false);
		});
	};
	const handleEditComment = (oldComment) => {
		setIsEditComment(true);
		onEditComment(oldComment);
	};
	const handleBlur = () => {
		dispatch(setDisableKeyEvents(false));
		setTimeout(() => setIsEditComment(false), 100);
		setIsActive(false);
	};
	const handleFocus = () => {
		dispatch(setDisableKeyEvents(true));
		setIsActive(true);
	};
	useEffect(() => {
		const handleKeyPress = (event) => {
			if (
				!disableKeyEvents &&
				editable &&
				((isValidation && isEditingAsValidator) || (!isValidation && !isEditingAsValidator))
			) {
				switch (event.key) {
					case "1":
						onChangePositionStatus(POSITIONS_STATUSES.YES, POSITIONS_STATUSES.YES === position.status);
						break;
					case "2":
						onChangePositionStatus(
							POSITIONS_STATUSES.PENDING,
							POSITIONS_STATUSES.PENDING === position.status
						);
						break;
					case "3":
						onChangePositionStatus(POSITIONS_STATUSES.NO, POSITIONS_STATUSES.NO === position.status);
						break;
					case "4":
						onChangePositionStatus(
							POSITIONS_STATUSES.NOT_ME,
							POSITIONS_STATUSES.NOT_ME === position.status
						);
						break;
					default:
						break;
				}
			}
		};
		window.addEventListener("keydown", handleKeyPress);
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [dispatch, editable, disableKeyEvents, position, onChangePositionStatus, isValidation, isEditingAsValidator]);
	const renderHighlightedText = () =>
		position.comment
			?.split("\n")
			.map((c) => <CommentHighlightedText key={c} textClassName={styles.commentLine} value={c} />);

	const editorTypeDisplay = useMemo(
		() => (isEditingAsValidator && isValidation) || (!isValidation && !isEditingAsValidator),
		[isEditingAsValidator, isValidation]
	);
	const allowEdit = useMemo(
		() => !disableEditing && (position?.comment === null || isEditComment),
		[disableEditing, position, isEditComment]
	);
	return (
		<SidePanelBox className={styles.main} hasShadow={false}>
			<div className={`${styles.container} ${className}`}>
				<div className={styles.header}>
					<div className={styles.header__leftItems}>
						<span className={styles.leftItems__primaryName}>
							{primaryName || position.username}
							<span className={styles.leftItems__secondaryName}>{secondaryName}</span>
						</span>
					</div>
					<div className={styles.header__rightItems}>
						{!position.status || position.status === POSITIONS_STATUSES.UNREVIEWED ? (
							<span className={styles.date}>
								{translate(
									"smart-review.cmp-details.option-panel.positions-tab.position-box.in-progress"
								)}
							</span>
						) : (
							<CustomTooltip
								placement="top"
								title={position?.statusDate && translateDate(position.statusDate).replaceAll(".", "/")}
							>
								<div className={styles.date} data-ishovered={iconsHovered}>
									{translateElapsed({
										value: position.statusTimeElapsed.value,
										unitKey: position.statusTimeElapsed.unit,
									})}{" "}
									|
								</div>
							</CustomTooltip>
						)}
						{isUpdatable && editable ? (
							<div
								className={styles.iconsContainer}
								data-ishovered={iconsHovered}
								data-isstatusselected={!!position?.status}
								onMouseEnter={handleMouseIconEnter}
								onMouseLeave={handleMouseIconLeave}
							>
								<PositionIcon
									clickable
									active={position.status === POSITIONS_STATUSES.YES}
									positionsStatus={POSITIONS_STATUSES.YES}
									selectedExist={position.status !== null}
									onClick={() =>
										onChangePositionStatus(
											POSITIONS_STATUSES.YES,
											POSITIONS_STATUSES.YES === position.status
										)
									}
								/>
								<PositionIcon
									clickable
									active={position.status === POSITIONS_STATUSES.PENDING}
									positionsStatus={POSITIONS_STATUSES.PENDING}
									selectedExist={position.status !== null}
									onClick={() =>
										onChangePositionStatus(
											POSITIONS_STATUSES.PENDING,
											POSITIONS_STATUSES.PENDING === position.status
										)
									}
								/>
								<PositionIcon
									clickable
									active={position.status === POSITIONS_STATUSES.NO}
									positionsStatus={POSITIONS_STATUSES.NO}
									selectedExist={position.status !== null}
									onClick={() =>
										onChangePositionStatus(
											POSITIONS_STATUSES.NO,
											POSITIONS_STATUSES.NO === position.status
										)
									}
								/>
								<PositionIcon
									clickable
									active={position.status === POSITIONS_STATUSES.NOT_ME}
									positionsStatus={POSITIONS_STATUSES.NOT_ME}
									selectedExist={position.status !== null}
									onClick={() =>
										onChangePositionStatus(
											POSITIONS_STATUSES.NOT_ME,
											POSITIONS_STATUSES.NOT_ME === position.status
										)
									}
								/>
							</div>
						) : (
							<div className={styles.iconsContainer}>
								<PositionIcon positionsStatus={POSITIONS_STATUSES[position.status]} />
							</div>
						)}
					</div>
				</div>
				<div className={styles.body}>
					{(editorTypeDisplay &&
						(allowEdit ? (
							<>
								<LimitedAutocompleteTextField
									className={styles.textField}
									data={teamMembers}
									displayLength={tempComment.length !== 0}
									helperTextClassName={styles.helperTextClassName}
									placeholder={translate("common:comment.add-comment")}
									trigger={`${(isFeatureEnabled(Flags.MENTIONS) && "@") || null}`}
									value={tempComment}
									onBlur={handleBlur}
									onChange={onChangeTempComment}
									onFocus={handleFocus}
								/>
								{tempComment.length !== 0 && (
									<div className={styles.line__rightItems}>
										<CustomIconButton
											btnClassName={styles.btnClassName}
											data-testid="comment.send.btn"
											disabled={tempComment.length === 0}
											icon={icon.faPaperPlane}
											iconClassName={styles.iconClassName}
											tooltip={translate("common:comment.send-button")}
											variant="outlined"
											onClick={handleAddComment}
										/>
									</div>
								)}
							</>
						) : (
							<div ref={commentRef} className={styles.comment}>
								{(disableHighlighting && <>{position.comment}</>) || renderHighlightedText()}
								<div className={styles.editContainer}>
									{position.commentTimeElapsed && (
										<CustomTooltip
											placement="top"
											title={
												(position?.commentDate &&
													translateDate(position.commentDate).replaceAll(".", "/")) ||
												""
											}
										>
											<span className={styles.date}>
												{translateElapsed({
													value: position.commentTimeElapsed.value,
													unitKey: position.commentTimeElapsed.unit,
												})}
											</span>
										</CustomTooltip>
									)}
									{!disableEditing && (
										<CustomIconButton
											btnClassName={styles.btnClassName}
											data-testid="comment.send.btn"
											icon={icon.faPen}
											iconClassName={styles.iconClassName}
											tooltip={translate("smart-review.right-panel.menu.edit-button")}
											variant="outlined"
											onClick={() => handleEditComment(position.comment)}
										/>
									)}
								</div>
							</div>
						))) || (
							<div className={styles.comment}>
								{(disableHighlighting && <>{position.comment}</>) || renderHighlightedText()}
								<div className={styles.editContainer}>
									{position.commentTimeElapsed && (
										<CustomTooltip
											placement="top"
											title={
												position?.commentDate &&
												translateDate(position.commentDate).replaceAll(".", "/")
											}
										>
											<span className={styles.date}>
												{translateElapsed({
													value: position.commentTimeElapsed.value,
													unitKey: position.commentTimeElapsed.unit,
												})}
											</span>
										</CustomTooltip>
									)}
								</div>
							</div>
						) ||
						""}
				</div>
			</div>
		</SidePanelBox>
	);
}
