import React, { useEffect, useState } from "react";
import { Paper, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./ReqDetails.module.css";
import ReqDetailsEmptyState from "./ReqDetailsEmptyState";
import ReqDetailsLoader from "./ReqDetailsLoader";
import { Header, ReqInfoTable, Section, ReqReviewsTable, ReqPositionsTable, RelatedDeliverables } from "./components";
import { Flags, isFeatureEnabled, translate } from "../../../../../common/providers";
import { isNonEmptyArray, dangerousSetInnerHTML } from "../../../../../common/utils";
import { SppRequirementService } from "../../../../../api";
import { useApi } from "../../../../../common/hooks";

export default function ReqDetails({ isLoadingDetails, onClickEdit, requirement }) {
	const { call: getDeliverablesForRequirement } = useApi(SppRequirementService.getDeliverablesForRequirement);
	const projectId = useSelector(({ context }) => context.project.id || 0);
	const [deliverables, setDeliverables] = useState([]);
	const {
		positions,
		reviews,
		id,
		content,
		clientRef,
		documentName,
		criticality,
		clause,
		attributes,
		negotiability,
		category,
		type,
		documentOuts,
	} = requirement || {};
	useEffect(() => {
		setDeliverables([]);
	}, [requirement]);
	const handleCollapse = () => {
		if (!isNonEmptyArray(deliverables)) {
			getDeliverablesForRequirement({ projectId, requirementId: requirement.id })
				.then((data) => {
					setDeliverables(data);
				})
				.catch(console.error);
		}
	};
	return (
		<div className={styles["req-details"]}>
			{isLoadingDetails && <ReqDetailsLoader />}
			{!isLoadingDetails && !requirement && <ReqDetailsEmptyState />}
			{!isLoadingDetails && !!requirement && (
				<>
					<Paper square>
						<Header projectId={projectId} requirement={requirement} onClickEdit={onClickEdit} />
						<Divider />
						<div className={styles["req-details__content"]}>{dangerousSetInnerHTML({ text: content })}</div>
					</Paper>
					<br />
					<Section title={translate("requirement-matrix.req-details.section.company-position")}>
						<ReqPositionsTable positions={positions} />
					</Section>
					<Section title={translate("requirement-matrix.req-details.section.reviewer")}>
						<ReqReviewsTable reviews={reviews} />
					</Section>
					<Section title={translate("requirement-matrix.req-details.section.information")}>
						<ReqInfoTable
							attributes={attributes}
							category={category}
							clause={clause}
							clientRef={clientRef}
							criticality={criticality}
							documentName={documentName}
							documentOuts={documentOuts}
							id={id}
							negotiability={negotiability}
							type={type}
						/>
					</Section>
					{isFeatureEnabled(Flags.COVERAGE_PLUGIN) && (
						<Section
							elevation={0}
							title={translate("requirement-matrix.req-details.section.related-deliverables")}
							onCollapse={handleCollapse}
						>
							<RelatedDeliverables
								deliverables={deliverables}
								projectId={projectId}
								requirementId={requirement.id}
							/>
						</Section>
					)}
				</>
			)}
		</div>
	);
}
