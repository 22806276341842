import React from "react";
import {
	CustomButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableRow,
	EmptyState,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import styles from "./CustomExportTemplates.module.css";
import { isNonEmptyArray } from "../../../../../common/utils";

export default function CustomExportTemplates({ exportTemplates, handleCustomExport }) {
	return (
		<CustomTable>
			<CustomTableBody>
				{isNonEmptyArray(exportTemplates) ? (
					exportTemplates.map((template) => (
						<CustomTableRow key={template.id}>
							<CustomTableCell>{template.name}</CustomTableCell>
							<CustomTableCell>
								<CustomButton variant="contained" onClick={() => handleCustomExport(template)}>
									{translate("common:btn.export")}
								</CustomButton>
							</CustomTableCell>
						</CustomTableRow>
					))
				) : (
					<EmptyState
						className={styles.emptyState}
						content={translate("common:export-dialog.no-custom-template")}
						link={translate("common:export-dialog.no-custom-template.cta")}
						linkAddress="mailto:support@aitenders.com"
					/>
				)}
			</CustomTableBody>
		</CustomTable>
	);
}
