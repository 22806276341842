import { Checkbox, TablePagination } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { CustomTable, CustomTableCell, CustomTableHead, CustomTableRow } from "../../../../../../../common/components";
import styles from "./DetectionsTable.module.css";

export default function DetectionsTable({
	children,
	detections,
	headers,
	numSelected,
	page,
	rowsPerPageLimit,
	totalElements,
	onPageChange,
	onRowsPerPageChange,
	onCheckAllRows,
	onSort,
}) {
	const orderBy = useSelector(({ normsCenter }) => normsCenter.orderBy);
	const dir = useSelector(({ normsCenter }) => normsCenter.dir);
	const rowCount = detections && detections.length;
	return (
		<>
			<CustomTable stickyHeader>
				<CustomTableHead>
					<CustomTableRow>
						<CustomTableCell className={styles.header}>
							<Checkbox
								checked={rowCount > 0 && numSelected === rowCount}
								color="primary"
								indeterminate={numSelected > 0 && numSelected < rowCount}
								onChange={onCheckAllRows}
							/>
						</CustomTableCell>
						{Array.isArray(headers) &&
							headers.length > 0 &&
							headers.map((header) => (
								<CustomTableCell
									key={header.name}
									active={orderBy === header.sortKey}
									className={styles.header}
									direction={dir?.toLowerCase() || "asc"}
									sortKey={header.sortKey}
									onSort={() => {
										if (typeof onSort === "function") {
											onSort(header.sortKey);
										}
									}}
								>
									{header.name}
								</CustomTableCell>
							))}
					</CustomTableRow>
				</CustomTableHead>
				{children}
			</CustomTable>
			{rowsPerPageLimit && Array.isArray(detections) && detections.length > 0 && (
				<TablePagination
					className={styles.tablePagination}
					component="div"
					count={totalElements}
					page={page}
					rowsPerPage={rowsPerPageLimit}
					rowsPerPageOptions={[10, 25, 100]}
					onPageChange={onPageChange}
					onRowsPerPageChange={onRowsPerPageChange}
				/>
			)}
		</>
	);
}
