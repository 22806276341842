import React, { useEffect, useState } from "react";
import { Radio } from "@mui/material";
import { CustomDialog } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { useApi } from "../../../../common/hooks";
import { DocumentService } from "../../../../api";
import { isNonEmptyArray } from "../../../../common/utils";
import styles from "./ChangeTypeDialog.module.css";

const ChangeTypeDialog = ({ open = false, onClose, onSubmit }) => {
	const { call: onGetDocumentTypes } = useApi(DocumentService.getDocumentTypes);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [selectedType, setSelectedType] = useState("DELIVERABLE");
	useEffect(() => {
		if (open) {
			onGetDocumentTypes()
				.then((data) => setDocumentTypes(data))
				.catch(console.error);
		}
	}, [open, onGetDocumentTypes]);
	const handleSubmit = () => {
		onSubmit(selectedType);
	};
	const handleClose = () => {
		setSelectedType("DELIVERABLE");
		onClose();
	};
	return (
		<CustomDialog
			open={open}
			submitDisabled={!selectedType}
			title={translate("document-center.table.actions.add-type")}
			onClose={handleClose}
			onSubmit={handleSubmit}
		>
			<div>
				{isNonEmptyArray(documentTypes) &&
					documentTypes.map((documentType) =>
						documentType !== "QA" ? (
							<div
								key={documentType}
								className={styles.list__item}
								role="presentation"
								onClick={() => setSelectedType(documentType)}
							>
								<Radio
									checked={selectedType === documentType}
									classes={{ root: styles.list__checkbox }}
									size="small"
								/>
								{translate(`document-center.table.actions.type.${documentType.toLowerCase()}`)}
							</div>
						) : (
							<></>
						)
					)}
			</div>
		</CustomDialog>
	);
};

export default ChangeTypeDialog;
