import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../../../../../common/providers";
import styles from "./OpportunityCollaborators.module.css";
import { AutocompleteTextField } from "../../../../../../../common/components";
import { useApi } from "../../../../../../../common/hooks";
import { CompanyService } from "../../../../../../../api";
import CollaboratorRow from "./CollaboratorRow";
import { setTeamMembers } from "../../../../../slice/opportunitySlice";

export default function OpportunityCollaborators({ collaborators, onAdd, onRemove }) {
	const [name, setName] = useState("");
	const companyId = useSelector(({ context }) => context.company.id);
	const teamMembers = useSelector(({ opportunity }) => opportunity.teamMembers);
	const ownId = useSelector(({ context }) => context.user.id);
	const dispatch = useDispatch();
	const { call: getAllUser } = useApi(CompanyService.getAllUser, 1000);
	useEffect(() => {
		if (teamMembers.length === 0) {
			getAllUser({ companyId })
				.then((team) => dispatch(setTeamMembers(team)))
				.catch((err) => console.error(err));
		}
	}, [companyId, dispatch, teamMembers, getAllUser]);
	const handleChange = (e) => setName(e.target.value);
	const handleAdd = (id) => {
		onAdd(id);
		setName("");
	};
	return (
		<div className={styles.main}>
			<span className={styles.title}>{translate("opportunity.creation.side-panel.collaborators.title")}</span>
			<AutocompleteTextField
				fullWidth
				multiline
				data={teamMembers
					.filter((tm) => !collaborators.includes(tm.id) && (tm.displayName || tm.email) && ownId !== tm.id)
					.map((tm) => ({ ...tm, display: tm.displayName || tm.email }))}
				maxLength={512}
				placeholder={translate("opportunity.creation.side-panel.collaborators.label")}
				rows={1}
				trigger=""
				value={name}
				onAdd={handleAdd}
				onChange={handleChange}
			/>
			<div className={styles.list}>
				{teamMembers
					.filter((tm) => collaborators.includes(tm.id))
					.map((tm) => (
						<CollaboratorRow key={tm.id} collaborator={tm.displayName} id={tm.id} onRemove={onRemove} />
					))}
			</div>
		</div>
	);
}
