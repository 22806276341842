import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import styles from "./GonogoContent.module.css";
import { Header, Row } from "./components";
import { CustomIconButton, icon, EmptyState } from "../../../../common/components";
import { getCategoryInformation, setIsLoadingContent } from "../../slice/gonogo-slice";
import { ApiService } from "../../../../api";
import { debounce } from "../../../../common/utils";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../common/providers";

const cache = new CellMeasurerCache({
	fixedWidth: true,
	defaultHeight: 150,
	minHeight: 50,
});

const debounceFunc = debounce((searchFunction) => {
	searchFunction();
}, 300);

function defineParams({ selectedMode, search }) {
	const params = {};
	if (selectedMode === 1) {
		params.attached = true;
	}
	if (selectedMode === 2) {
		params.excluded = true;
	}
	if (search.length > 0) {
		params.content = search;
	}
	return params;
}

export default function GonogoContent() {
	const listRef = useRef();
	const { contents, hasMore, pageNumber } = useSelector(({ gonogo }) => gonogo.results);
	const isLoadingContent = useSelector(({ gonogo }) => gonogo.isLoadingContent);
	const isAnalysisInProgress = useSelector(({ gonogo }) => gonogo.isAnalysisInProgress);
	const categoryId = useSelector(({ gonogo }) => gonogo.currentCategory?.id);
	const selectedMode = useSelector(({ gonogo }) => gonogo.selectedMode);
	const search = useSelector(({ gonogo }) => gonogo.search);
	const [page, setPage] = useState(0);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const refreshRefCache = () => {
		cache.clearAll();
		listRef?.current?.recomputeRowHeights();
		listRef?.current?.forceUpdate();
		listRef?.current?.forceUpdateGrid();
	};
	const refreshGrid = () => {
		refreshRefCache();
	};
	useEffect(() => {
		if (categoryId) {
			setPage(0);
			listRef?.current?.scrollToRow(0);
			refreshRefCache();
		}
	}, [categoryId, selectedMode, search]);
	useEffect(() => {
		window.addEventListener("resize", refreshRefCache);
		return () => {
			window.removeEventListener("resize", refreshRefCache);
		};
	}, []);

	const handleLoadMore = () => {
		if (hasMore && !isLoadingContent && page === pageNumber) {
			setPage(pageNumber + 1);
			dispatch(setIsLoadingContent(true));
			dispatch(
				getCategoryInformation({
					categoryId,
					page: pageNumber + 1,
					token: cancelTokenSourceRef.current.token,
					params: defineParams({ selectedMode, search }),
				})
			);
		}
	};
	const handleRowsRendered = ({ overscanStopIndex }) => {
		if (contents.length - 20 < overscanStopIndex) {
			debounceFunc(() => {
				handleLoadMore();
			});
		}
	};
	const debouncedSearch = useCallback(() => {
		dispatch(setIsLoadingContent(true));
		debounceFunc(() => {
			dispatch(
				getCategoryInformation({
					categoryId,
					token: cancelTokenSourceRef.current.token,
					page: 0,
					params: defineParams({ selectedMode, search }),
				})
			);
		});
	}, [dispatch, categoryId, selectedMode, search]);

	useEffect(() => {
		if (!categoryId) {
			return;
		}
		debouncedSearch(search, selectedMode);
	}, [selectedMode, search, debouncedSearch, categoryId]);

	const noContentRender = (
		<EmptyState
			content={translate("gonogo.content.no-content.text")}
			link={translate("gonogo.content.no-content.link")}
			linkAddress={translate("gonogo.content.no-content.link-address")}
			title={translate("gonogo.content.no-content.title")}
		/>
	);

	const noRowRenderer = () => {
		const noRowContent = (selectedMode === 0 && <>{noContentRender}</>) ||
			(selectedMode === 1 && isSegFeatureEnabled(SegFlags.SMART_SEARCH) && (
				<>
					{translate("gonogo.content.flagged.no-rows.first-half")}
					<CustomIconButton disabled icon={icon.faFlag} size="sm" variant="outlined" />
					{translate("gonogo.content.flagged.no-rows.second-half")}
				</>
			)) || <>{translate("gonogo.content.deleted.no-rows")}</>;
		return (
			(!contents || contents.length === 0) &&
			!isAnalysisInProgress &&
			!isLoadingContent && <div className={styles.noRowsContainer}>{noRowContent}</div>
		);
	};

	const rowRenderer = ({ key, index, parent, style }) => (
		<CellMeasurer key={key} cache={cache} columnIndex={0} parent={parent} rowIndex={index}>
			{({ registerChild }) => (
				<div key={key} ref={registerChild} className={styles.row} style={style}>
					<Row
						key={contents[index].informationId}
						data={contents[index]}
						rowIndex={index}
						onRefresh={refreshGrid}
					/>
				</div>
			)}
		</CellMeasurer>
	);

	return (
		<div className={styles.main}>
			<Header />
			<div className={styles.content}>
				<AutoSizer>
					{({ width, height }) => (
						<List
							ref={(ref) => {
								listRef.current = ref;
							}}
							data-testid="gonogo.content.list"
							deferredMeasurementCache={cache}
							height={height - 1}
							noRowsRenderer={noRowRenderer}
							overscanRowCount={5}
							rowCount={contents?.length || 0}
							rowHeight={cache.rowHeight}
							rowRenderer={rowRenderer}
							scrollToAlignment="start"
							width={width - 1}
							onRowsRendered={handleRowsRendered}
						/>
					)}
				</AutoSizer>
			</div>
		</div>
	);
}
