import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService, RedressmentService } from "../../../../../../../../api";
import {
	redressInfo,
	resetSidePanelContent,
	setHideFusionDialogDisplay,
} from "../../../../../../slice/document/document-slice";
import { resetContent } from "../../../../../../slice/pdf/pdf-slice";
import RedressInfoCheckbox from "./RedressInfoCheckbox";
import { IconComponent, CustomIconButton, createNotification, icon } from "../../../../../../../../common/components";
import { translate } from "../../../../../../../../common/providers";
import styles from "./RedressInfo.module.css";
import RedressDialog from "../../components/redress-dialog/RedressDialog";

export default function RedressInfo({ height, hoveredMetadata, left, metadata, top, width }) {
	const [openRedressDialog, setOpenRedressDialog] = useState(false);
	const [loadingCombination, setLoadingCombination] = useState(false);
	const redressingInformation = useSelector(({ srDocument }) => srDocument.redressingInformation);
	const hideFusionDialogDisplay = useSelector(({ srDocument }) => srDocument.hideFusionDialogDisplay);
	const redressList = useSelector(({ srDocument }) => srDocument.redressList);
	const docId = useSelector(({ srDocument }) => srDocument.documentId);
	const redressingPage = useSelector(({ srDocument }) => srDocument.redressingPage);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const dispatch = useDispatch();
	const cancelTokenSourceRef = useRef(null);
	const cancelTokenUpdateSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		cancelTokenUpdateSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			cancelTokenSourceRef.current.cancel();
			cancelTokenUpdateSourceRef.current.cancel();
		};
	}, []);
	const handleCloseRedressDialog = () => {
		setOpenRedressDialog((prev) => !prev);
	};
	const resetRedress = () => {
		dispatch(redressInfo({ informationId: null, page: null, orders: null, redressList: null }));
	};
	const handleSubmitRedressDialog = () => {
		if (redressingInformation) {
			setLoadingCombination(true);
			RedressmentService.proceedRedressInfo(
				{ docId, infoId: redressingInformation },
				{
					min: Math.min(...redressList.filter((x) => x.checked).map((x) => x.order)),
					max: Math.max(...redressList.filter((x) => x.checked).map((x) => x.order)),
				},
				cancelTokenSourceRef.current.token
			)
				.then((pages) => {
					resetRedress();
					createNotification({ type: "success", message: translate("smart-review.redress-info.success") });
					dispatch(resetContent({ pages, mode }));
					dispatch(resetSidePanelContent(true));
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					if (openRedressDialog) {
						handleCloseRedressDialog();
					}
					setLoadingCombination(false);
				});
		}
	};
	const handleConfirmInfoRedressing = () => {
		if (!hideFusionDialogDisplay) {
			handleCloseRedressDialog();
		} else {
			handleSubmitRedressDialog();
		}
	};
	const handleCheck = () => dispatch(setHideFusionDialogDisplay(!hideFusionDialogDisplay));
	return (
		<>
			<RedressInfoCheckbox height={height} metadata={metadata} width={width} />
			{hoveredMetadata &&
				hoveredMetadata?.pageStart <= redressingPage + 1 &&
				hoveredMetadata?.pageStart >= redressingPage - 1 && (
					<div className={styles.redressInfo__overlay} style={{ top, left }}>
						<span className={styles.redressId}>
							REQ_{redressingInformation}
							<IconComponent color="var(--color-light-grey-3)" icon={icon.faLayerGroup} size="md" />
						</span>
						<CustomIconButton
							className={styles.iconButton}
							icon={icon.faCheck}
							tooltip={translate("smart-review.redress-overlay.validate-button")}
							onClick={handleConfirmInfoRedressing}
						/>
						<CustomIconButton
							className={styles.iconButton}
							icon={icon.faTimes}
							tooltip={translate("smart-review.redress-overlay.cancel-button")}
							onClick={resetRedress}
						/>
					</div>
				)}
			{openRedressDialog && (
				<RedressDialog
					count={redressList.filter((x) => x.checked).length}
					hidden={hideFusionDialogDisplay}
					open={openRedressDialog}
					redressingId={redressingInformation}
					submitDisabled={loadingCombination}
					onClose={handleCloseRedressDialog}
					onHide={handleCheck}
					onSubmit={handleSubmitRedressDialog}
				/>
			)}
		</>
	);
}
