import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useSelector } from "react-redux";
import {
	CircularLoader,
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableRow,
	CustomTooltip,
	icon,
	IconComponent,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import styles from "./UploadedDocsTable.module.css";

export default function UploadedDocsTable({ pendingProjects, setPendingProjects }) {
	const isUploading = useSelector(({ opportunity }) => opportunity.isUploading);
	const handleRemoveRow = (toRemove) => {
		const filteredRow = [...pendingProjects].filter(
			(row) =>
				(toRemove.single && row?.name !== toRemove?.name) ||
				(!toRemove.single && row.document?.name !== toRemove.document?.name)
		);
		setPendingProjects(filteredRow);
	};
	return (
		<CustomTable dense>
			<CustomTableBody>
				{Array.isArray(pendingProjects) &&
					[...pendingProjects].reverse().map((row, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<CustomTableRow key={index} hover className={styles.row}>
							{({ isHovering }) => (
								<>
									<CustomTableCell className={`${styles.row__cell} ${styles["row__cell--name"]}`}>
										<div className={styles.docTitle}>
											<IconComponent
												className={styles.docIcon}
												color="var(--shades-grey-v2-40)"
												icon={icon.faFolder}
												size="sm"
											/>
											<div className={styles.docDetails}>
												<CustomTooltip
													showWhenOverflow
													placement="right"
													title={(row.single && row?.name) || row.document?.name}
												>
													<span
														className={
															row.status === "Canceled"
																? styles.docDetails__canceled
																: styles.docDetails__name
														}
													>
														{(row.single && row?.name) || row.document?.name}
													</span>
												</CustomTooltip>
												<span className={styles.docDetails__info}>
													{(row.status === "Canceled" && (
														<>{translate("opportunity.progress.import-canceled")}</>
													)) ||
														(row.status === "ok" && (
															<> {translate("opportunity.progress.import-completed")}</>
														)) ||
														(row.status === "Error" && (
															<> {translate("opportunity.progress.import-error")}</>
														))}
												</span>
											</div>
										</div>
									</CustomTableCell>
									<CustomTableCell align="right">
										{!isHovering && (
											<div className={`${styles.row__cell} ${styles["row__cell--status"]}`}>
												{row.progress !== 100 ? (
													<div className={styles.row__loader}>
														<CircularLoader
															className={styles.row__loader}
															size={20}
															thickness={8.2}
															value={row.progress}
															variant="determinate"
														/>
													</div>
												) : (
													(row.status === "ok" && (
														<IconComponent
															className={styles.docIcon}
															color="var(--color-green)"
															icon={icon.faCheckCircle}
															size="sm"
														/>
													)) ||
													(row.status === "Error" && (
														<IconComponent
															className={styles.docIcon}
															color="var(--color-red)"
															icon={icon.faExclamationTriangle}
															size="sm"
														/>
													))
												)}
											</div>
										)}
										{isHovering && (
											<div className={`${styles.row__cell} ${styles["row__cell--status"]}`}>
												{(row.progress === 100 || !isUploading) && (
													<CustomIconButton
														className={styles.row__trash}
														icon={faTrash}
														size="sm"
														tooltip={translate("opportunity.progress.import-remove-btn")}
														onClick={() => handleRemoveRow(row)}
													/>
												)}
											</div>
										)}
									</CustomTableCell>
								</>
							)}
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
