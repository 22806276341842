import React from "react";
import styles from "./ConversationProposition.module.css";
import { CustomButton } from "../../../../common/components";
import { translate } from "../../../../common/providers";

const ConversationProposition = ({ onChangeTextFilter }) => {
	const questionCount = 8;
	const questionElements = [];
	for (let i = 1; i <= questionCount; i++) {
		const questionTitle = translate(`chat-project.conversation-question${i}.title`);
		const questionValue = translate(`chat-project.conversation-proposition.question${i}`);
		questionElements.push(
			<div key={i} className={styles.proposition__container}>
				<div className={styles.proposition_title}>{questionTitle}</div>
				<CustomButton
					size="sm"
					variant="contained"
					onClick={() => onChangeTextFilter(questionValue, questionTitle)}
				>
					{translate("chat-project.select-question")}
				</CustomButton>
			</div>
		);
	}
	return (
		<div className={styles.container}>
			<div className={styles.title}>{translate("chat-project.question.help")}</div>
			<div className={styles.propositions}>{questionElements}</div>
		</div>
	);
};

export default ConversationProposition;
