import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ProjectDirectoryService } from "../../../../api";
import {
	CustomBreadcrumb,
	CustomButton,
	GenericDialog,
	GenericDialogContent,
	GenericDialogFooter,
	GenericDialogHeader,
	icon,
	IconComponent,
} from "../../../../common/components";
import { useApi } from "../../../../common/hooks";
import { translate } from "../../../../common/providers";
import DirectoryTree from "../directory-tree/DirectoryTree";
import styles from "./MoveDialog.module.css";

export default function MoveDialog({ include, numberOfElements, onClose, onMove, open, selectedFolders }) {
	const projectId = useSelector(({ context }) => context.project.id);
	const tree = useSelector(({ documentCenter }) => documentCenter.tree);
	const currentDirectoryId = useSelector(({ documentCenter }) => documentCenter.currentDirectoryId);
	const [location, setLocation] = useState([]);
	const [selectedDirectoryId, setSelectedDirectoryId] = useState(currentDirectoryId);
	const { call: onGetLocation } = useApi(ProjectDirectoryService.getLocation);

	useEffect(() => {
		if (currentDirectoryId === selectedDirectoryId) {
			setLocation([]);
			return;
		}
		onGetLocation({ projectId, directoryId: selectedDirectoryId }).then(setLocation);
	}, [projectId, currentDirectoryId, selectedDirectoryId, onGetLocation]);
	const handleSetSelectedDirectoryId = ({ id }) => {
		setSelectedDirectoryId(id);
	};
	const handleClose = () => {
		setSelectedDirectoryId(currentDirectoryId);
		onClose();
	};

	const handleMove = () => {
		onMove(selectedDirectoryId);
	};
	return (
		<GenericDialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
			<GenericDialogHeader onClose={handleClose}>
				<h4>
					{(numberOfElements > 1 &&
						translate("document-center.move-dialog.title.plural", { elements: numberOfElements })) ||
						translate("document-center.move-dialog.title.singular")}
				</h4>
				<div className={styles.move__header}>
					<IconComponent icon={icon.faArrowRight} size="md" />
					<CustomBreadcrumb
						className={styles.moveDialog_breadcrumb}
						iconName="faFolder"
						locations={location}
					/>
				</div>
			</GenericDialogHeader>
			<GenericDialogContent dividers className={styles.move__content}>
				<DirectoryTree
					currentDirectoryId={selectedDirectoryId}
					includeNodes={include}
					selectedNodes={selectedFolders}
					tree={tree}
					onClickDirectory={handleSetSelectedDirectoryId}
				/>
			</GenericDialogContent>
			<GenericDialogFooter>
				<CustomButton color="primary" variant="outlined" onClick={handleClose}>
					{translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton
					color="primary"
					disabled={currentDirectoryId === selectedDirectoryId}
					variant="contained"
					onClick={handleMove}
				>
					{translate("document-center.move-dialog.submit")}
				</CustomButton>
			</GenericDialogFooter>
		</GenericDialog>
	);
}
