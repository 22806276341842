import React, { forwardRef } from "react";
import { CustomButton, CustomTooltip, icon, IconComponent } from "../../../../../common/components";
import styles from "./SearchTemplateMenuItem.module.css";
import { VISIBILITY_TEMPLATE } from "../../../../../common/constants/visibility";

const SearchTemplateMenuItem = forwardRef(({ disabled, onClick, resultCount, style, title, visibility }, ref) => (
	<CustomTooltip arrow placement="right" title={title}>
		<div ref={ref} style={style}>
			<CustomButton fullWidth className={styles.menu__item} disabled={disabled} variant="text" onClick={onClick}>
				<span className={styles.item__labelLeft}>
					<span className={`${styles.item__title} ${(disabled && styles["item__title--disabled"]) || ""}`}>
						{title}
						{visibility === VISIBILITY_TEMPLATE.COMPANY && (
							<IconComponent color="var(--color-light-grey-2)" icon={icon.faUsers} size="xs" />
						)}
					</span>
				</span>
				<span className={styles.item__results}>{resultCount}</span>
			</CustomButton>
		</div>
	</CustomTooltip>
));
export default SearchTemplateMenuItem;
