import { KEYWORD_FILTER_OPERATOR } from "../../../common/constants";

export const SEPARATOR = { AND: "AND", OR: "OR" };

export const CONTENT_TYPE = "CONTENT_TYPE";
export const CLASSIFICATION = "CLASSIFICATION";
export const CATEGORIES = "CATEGORIES";
export const CRITICALITY = "CRITICALITY";
export const NEGOTIABILITY = "NEGOTIABILITY";
export const TYPE = "TYPE";

export const isPositiveKeywordFilter = (operator) =>
	[KEYWORD_FILTER_OPERATOR.IS_EXACTLY, KEYWORD_FILTER_OPERATOR.START_WITH].includes(operator);

const rmIdFilter = (filter) => ({
	filters: filter.map((el) => {
		const { id, ...rest } = el;
		return rest;
	}),
});
export const removeDateId = (filters) => {
	const tempFilter = { ...filters };
	if (filters.tocFilters) {
		tempFilter.tocFilters = rmIdFilter(filters.tocFilters.filters);
	}
	if (filters.keywordFilters) {
		tempFilter.keywordFilters = rmIdFilter(filters.keywordFilters.filters);
	}
	return tempFilter;
};
