import React from "react";
import { CustomButton, CustomTooltip } from "../../../../common/components";
import styles from "./MenuButton.module.css";

export default function MenuButton({ children = "", selected = false, onClick = null }) {
	return (
		<CustomButton
			className={`${styles.menuButton} ${selected && styles["menuButton--selected"]}`}
			color="secondary"
			disableRipple={selected}
			onClick={onClick}
		>
			<CustomTooltip showWhenOverflow placement="bottom-start" title={children || ""}>
				<span className={styles["menuButton__text--crop"]}>{children}</span>
			</CustomTooltip>
		</CustomButton>
	);
}
