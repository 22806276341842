import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { AddUserSidePanel, UsersList, UserDetails, SingleFieldDialog, CompaniesList } from "../../components";
import styles from "./AdminUsers.module.css";
import { translate } from "../../../../common/providers";

const REASON = "reason";

export default function AdminUsers({
	companies = [],
	getCompanyUsers,
	onAddUser,
	onChangeLock,
	onChangeRole,
	COMPANY_ROLES,
	companyIdToUser,
}) {
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [isAdding, setIsAdding] = useState(false);
	const [openSingleFieldDialog, setOpenSingleFieldDialog] = useState({ isOpen: false });
	const loggedIncompanyId = useSelector(({ context }) => context.company?.id);

	const handleSelectCompany = useCallback(
		(company) => {
			if (!selectedCompany || company?.id !== selectedCompany?.id) {
				setSelectedCompany(company);
				getCompanyUsers({ company });
			}
		},
		[selectedCompany, getCompanyUsers]
	);
	const findCompanyWithAdminUserRole = useCallback(
		(dataTree) => {
			for (const company of dataTree) {
				if (company.userRole === "ADMIN" && company.id === loggedIncompanyId) {
					return company;
				}
				if (company.children) {
					const innerCompanyId = findCompanyWithAdminUserRole(company.children);
					if (innerCompanyId) {
						return innerCompanyId;
					}
				}
			}
			return null;
		},
		[loggedIncompanyId]
	);
	useEffect(() => {
		if (!selectedCompany && Array.isArray(companies) && companies.length > 0) {
			handleSelectCompany(findCompanyWithAdminUserRole(companies));
		}
	}, [selectedCompany, companies, handleSelectCompany, companyIdToUser, findCompanyWithAdminUserRole]);
	const handleSelectUser = ({ user }) => {
		if (user !== selectedUser) {
			setSelectedUser(user);
		}
	};

	const handleChangeRole = ({ role, user }) => {
		if (!role || !user || !selectedCompany || user.companyRole === role) {
			return;
		}
		onChangeRole({ companyId: selectedCompany.id, userId: user.id, role });
	};

	const handleChangeLock = ({ user }) => {
		if (!user) {
			return;
		}
		const isLocked = user.locked;
		if (isLocked) {
			onChangeLock({ companyId: selectedCompany.id, userId: user.id, isLocked });
		} else {
			setOpenSingleFieldDialog({
				isOpen: true,
				title: translate("admin.users.change-lock.title"),
				field: REASON,
				label: translate("admin.users.change-lock.text"),
				onSubmit: ({ reason }) => {
					onChangeLock({ companyId: selectedCompany.id, userId: user.id, reason, isLocked });
				},
			});
		}
	};
	return (
		<>
			<div className={styles["left-panel"]}>
				<CompaniesList
					companies={companies}
					selectedCompany={selectedCompany}
					onClickRow={handleSelectCompany}
				/>
			</div>
			<div className={styles["middle-panel"]}>
				<UsersList
					COMPANY_ROLES={COMPANY_ROLES}
					isAdding={isAdding}
					selectedUser={selectedUser}
					users={companyIdToUser}
					onChangeLock={handleChangeLock}
					onChangeRole={handleChangeRole}
					onClickRow={handleSelectUser}
					onSetIsAdding={setIsAdding}
				/>
			</div>
			<div className={styles["right-panel"]}>
				<UserDetails user={selectedUser} />
			</div>
			<AddUserSidePanel
				open={isAdding}
				onAdd={({ payload }) => {
					onAddUser({ companyId: selectedCompany.id, payload });
					setIsAdding(false);
				}}
				onClose={() => setIsAdding(false)}
			/>
			<SingleFieldDialog
				field={openSingleFieldDialog && openSingleFieldDialog.field}
				label={openSingleFieldDialog && openSingleFieldDialog.label}
				open={openSingleFieldDialog && openSingleFieldDialog.isOpen}
				title={openSingleFieldDialog && openSingleFieldDialog.title}
				onClose={() => setOpenSingleFieldDialog({ isOpen: false })}
				onSubmit={openSingleFieldDialog && openSingleFieldDialog.onSubmit}
			/>
		</>
	);
}
