import { SENTENCE_TYPE, UPDATE_RIGHTS, DOCUMENT_STATUSES, ROLES, ABSOLUTE_LINKS } from "../constants/constants";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../common/providers";
import { clearEmpties, hasProperty, isNonEmptyArray } from "../../../common/utils";
import { SOURCE_TYPES } from "../../../common/constants";

export const convertStatusInputToDB = (status) => {
	const toConvert = status ? status?.toLowerCase() : status;
	switch (toConvert) {
		case "accepted":
			return "yes";
		case "accepted with comment":
			return "yes_but";
		case "denied":
			return "no";
		case "notme":
			return "not_me";
		case "pending":
			return "pending";
		case "unreviewed":
		default:
			return "unreviewed";
	}
};
export const convertStatusDBtoInput = (status) => {
	const toConvert = status ? status?.toLowerCase() : status;
	switch (toConvert) {
		case "yes":
			return "accepted";
		case "yes_but":
			return "accepted with comment";
		case "no":
			return "denied";
		case "not_me":
			return "notme";
		case "pending":
			return "pending";
		case "new":
		case "unreviewed":
		default:
			return "unreviewed";
	}
};
export const checkReq = (metadata) => {
	if (metadata) {
		if (metadata.validatedSentenceType) {
			return (
				metadata.validatedSentenceType?.toUpperCase() === SENTENCE_TYPE.REQUIREMENT &&
				isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS)
			);
		}
		return (
			metadata.sentenceType?.toUpperCase() === SENTENCE_TYPE.REQUIREMENT &&
			isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS)
		);
	}
	return false;
};
export const checkHardInformation = (data) =>
	data.validatedSentenceType?.toUpperCase() === SENTENCE_TYPE.REQUIREMENT ||
	(!data.validatedSentenceType && data.sentenceType === SENTENCE_TYPE.REQUIREMENT);
export const checkReqValMode = (mode) => mode === ROLES.REVIEWER.mode || mode === ROLES.VALIDATOR.mode;
export const hasUpdateRights = (roles) =>
	Array.isArray(roles) && UPDATE_RIGHTS.some((ur) => roles.some((r) => r === ur));
export const isArchived = (status) => status === DOCUMENT_STATUSES.ARCHIVED;
export const currentRole = (roles, mode) => hasUpdateRights(roles) && checkReqValMode(mode);

export const translateRole = (value) => translate(`common:enum.document.user-role.${value?.toLowerCase()}`);
const translatePosition = (value) => translate(`common:enum.status.${value?.toLowerCase().replace("_", "-")}`);
export const translateModalPosition = (name, value) => `${name} : ${translatePosition(value)}`;
export const translateEnumPhaseStatus = (value) =>
	translate(`common:enum.question-status.${value?.toLowerCase().replace("_", "-")}`);

export const generatePayload = (filters) => {
	const { documentsIds, ...rest } = filters;
	const newFilters = { ...rest };
	if (documentsIds && Array.isArray(documentsIds) && documentsIds.length > 0) {
		newFilters.documentsIds = documentsIds.map((d) => d.id);
	}
	return clearEmpties(newFilters);
};

export const generateSearchPosition = (selectedSearch) => {
	if (!selectedSearch) {
		return null;
	}
	const { page, pageStart, y1, rectangles } = selectedSearch;
	const payload = { page: page || pageStart };
	if (y1) {
		payload.position = y1;
	}
	if (Array.isArray(rectangles)) {
		payload.position = rectangles[0].y1;
	}
	return payload;
};

export const getNewTab = (oldTab) => {
	if (oldTab === "") {
		return SOURCE_TYPES.POSITIONS;
	}
	return oldTab;
};

export function computeAbsoluteTop(pageDimensions, index, position, zoom) {
	if (!Array.isArray(pageDimensions) || index >= pageDimensions.length || index < 0) {
		return 0;
	}
	const currentPageHeight = ((pageDimensions[index].height || 0) * zoom) / 100;
	const absolutePreviousPagesHeight =
		((pageDimensions.slice(0, index).reduce((acc, curr) => acc + curr.height, 0) || 0) * zoom) / 100;
	return currentPageHeight * position + absolutePreviousPagesHeight || 0;
}

export function computeTop(data, prev, top, pageDimensions, zoom) {
	const { rectangles: prevRec, pageStart: prevPage } = prev || {};
	const { rectangles, pageStart } = data || {};
	const prevTop =
		top || (prev && computeAbsoluteTop(pageDimensions, prevPage - 1, prevRec?.[0]?.y1 || 0, zoom)) || null;
	const currTop = computeAbsoluteTop(pageDimensions, pageStart - 1, rectangles?.[0]?.y1 || 0, zoom);
	const newTop =
		(prevRec?.[0] && prevRec[0]?.y1 < rectangles?.[0]?.y1 && prevTop && prevTop + 34 > currTop && prevTop + 34) ||
		currTop;
	return { ...data, top: newTop - 5 };
}

export function checkDocumentsIds(documentsIds) {
	if (!isNonEmptyArray(documentsIds)) {
		return false;
	}
	documentsIds.forEach((element) => {
		if (!hasProperty(element, "id") || !Number.isFinite(element.id)) {
			return false;
		}
		if (!hasProperty(element, "documentName") || typeof element.documentName !== "string") {
			return false;
		}
		if (!hasProperty(element, "name") || typeof element.name !== "string") {
			return false;
		}
		if (hasProperty(element, "forced") && typeof element.documentName !== "boolean") {
			return false;
		}
		return true;
	});
	return true;
}
export function linkConversion(linkType) {
	switch (linkType) {
		case "Is replaced by":
		case "Replaces":
			return { lk: ABSOLUTE_LINKS.REPLACES, color: "var(--color-blue)" };
		case "Is completed by":
		case "Completes":
			return { lk: ABSOLUTE_LINKS.COMPLETES, color: "var(--color-blue)" };
		case "Is contradicted by":
		case "Contradicts":
			return { lk: ABSOLUTE_LINKS.CONTRADICTS, color: "var(--color-red)" };
		case "Is modified by":
		case "Modifies":
			return { lk: ABSOLUTE_LINKS.MODIFIES, color: "var(--color-blue)" };
		case "Similar":
			return { lk: ABSOLUTE_LINKS.SIMILAR, color: "var(--color-dark-grey-1)" };
		default:
			return null;
	}
}
export function oppositeLink(linkType) {
	switch (linkType) {
		case "Is replaced by":
			return "Replaces";
		case "Replaces":
			return "Is replaced by";
		case "Is completed by":
			return "Completes";
		case "Completes":
			return "Is completed by";
		case "Is contradicted by":
			return "Contradicts";
		case "Contradicts":
			return "Is contradicted by";
		case "Is modified by":
			return "Modifies";
		case "Modifies":
			return "Is modified by";
		case "Similar":
			return "Similar";
		default:
			return null;
	}
}
export function linkDirections({ informationId, linkId, linkType }) {
	const payload = {};
	if (!informationId || !linkId) {
		return {};
	}
	payload.from = informationId;
	payload.to = linkId;
	payload.link = linkType;
	return payload;
}

export const translateEnumLinks = (value) => translate(`common:link.${value?.toLowerCase().replaceAll(" ", "-")}`);
