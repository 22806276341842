import React, { useState } from "react";
import styles from "./SearchRowTree.module.css";
import SearchRow from "../search-row/SearchRow";
import SearchList from "../search-list/SearchList";
import { CustomIconButton, icon } from "../..";
import { useEffectOnce, usePrevious } from "../../../hooks";

function checkNonEmptyTree(array, isTree, count) {
	return (isTree && Array.isArray(array) && array.length > 0) || count > 0;
}

export default function SearchRowTree({
	ancestors = [],
	defaultOpen = false,
	emptyStateText = "",
	isRowChecked,
	isTree = true,
	level = 0,
	onAddAncestor,
	onClickRow,
	onExpand,
	row,
	rowLabelKey,
	rowKey = rowLabelKey,
	rowRenderer,
	rowTooltipKey,
	subRowCounter,
	subRowKey,
}) {
	const [open, setOpen] = useState(defaultOpen);
	const handleOpen = () => {
		if (typeof onExpand === "function") {
			onExpand(row);
		}
		setOpen((prev) => !prev);
	};
	const previousRow = usePrevious(row);
	useEffectOnce(
		() => {
			if (onAddAncestor) {
				onAddAncestor({ checked: row[rowKey], list: ancestors });
			}
		},
		[isRowChecked, row],
		() => isRowChecked(row) === true && previousRow !== row
	);
	return (
		<React.Fragment key={row[rowKey]}>
			<div
				className={
					(!checkNonEmptyTree(row[subRowKey], isTree, row[subRowCounter]) && styles["list--padding-left"]) ||
					""
				}
			>
				{checkNonEmptyTree(row[subRowKey], isTree, row[subRowCounter]) && (
					<CustomIconButton
						icon={open ? icon.faChevronDown : icon.faChevronRight}
						size="sm"
						onClick={handleOpen}
					/>
				)}
				{rowRenderer ? (
					rowRenderer(row, () => onClickRow({ ...row, ancestors }))
				) : (
					<SearchRow
						key={row[rowKey]}
						checked={isRowChecked(row)}
						hasChildren={row[subRowCounter] > 0}
						label={row[rowLabelKey]}
						tooltip={row[rowTooltipKey] || ""}
						onClick={() => onClickRow({ ...row, ancestors })}
					/>
				)}
			</div>
			{open && checkNonEmptyTree(row[subRowKey], isTree, row[subRowCounter]) && (
				<SearchList
					ancestors={[...ancestors, row[rowKey]]}
					emptyStateText={emptyStateText}
					isRowChecked={isRowChecked}
					level={level + 1}
					rowKey={rowKey}
					rowLabelKey={rowLabelKey}
					rowRenderer={rowRenderer}
					rows={row[subRowKey]}
					rowTooltipKey={rowTooltipKey}
					subRowCounter={subRowCounter}
					subRowKey={subRowKey}
					onAddAncestor={onAddAncestor}
					onClickRow={onClickRow}
					onExpand={onExpand}
				/>
			)}
		</React.Fragment>
	);
}
