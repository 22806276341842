import React, { useState } from "react";
import ActionRow from "../../action-row/ActionRow";

export default function ActionTreeRow({ ancestors = [], level = 0, onCheckRow, row, total }) {
	const [open, setOpen] = useState(true);
	const handleOpen = () => setOpen((prev) => !prev);
	const hasSubRows = Array.isArray(row.subThematic) && row.subThematic.length > 0;
	return (
		<div style={{ paddingLeft: level > 0 ? 45 : 0 }}>
			<ActionRow
				multiple
				hasSubRows={hasSubRows}
				item={{ ...row, ancestors }}
				open={open}
				total={total}
				onCheckRow={onCheckRow}
				onOpen={handleOpen}
			/>
			{open &&
				hasSubRows &&
				row.subThematic.map((sub) => (
					<ActionTreeRow
						key={sub.id}
						ancestors={[...ancestors, row.id]}
						level={level + 1}
						row={sub}
						total={total}
						onCheckRow={onCheckRow}
					/>
				))}
		</div>
	);
}
