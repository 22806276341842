import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { ApiService, RequirementMatrixService } from "../../../../../api";
import {
	DocumentFilter,
	FiltersWrapper,
	CommonFilters,
	ProjectCategoriesFilter,
	PositionFilter,
	generateFilters,
} from "../../../../../common/components/filters5";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../../common/providers";

export default function ReqFilters({ defaultFilters, onApply }) {
	const project = useSelector(({ context }) => context.project);
	const [parameters, setParameters] = useState({});
	const [partners, setPartners] = useState([]);
	const [filtersPreviewRequest, setFiltersPreviewRequest] = useState();
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	useEffect(() => {
		RequirementMatrixService.getParametersForInformation(cancelTokenSourceRef.current.token)
			.then(setParameters)
			.catch((err) => {
				console.error(err);
			});
	}, []);
	useEffect(
		() => {
			setFiltersPreviewRequest(() => (previewFilters) => {
				const newFilters = { ...generateFilters(previewFilters) };
				return RequirementMatrixService.getRequirementsInProjectCount(
					{ projectId: project.id },
					newFilters,
					cancelTokenSourceRef.current.token
				).then((data) => data);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[project]
	);
	const getPartners = useCallback(() => {
		RequirementMatrixService.getCompaniesSharedPrivacyInProject(
			{ projectId: project.id },
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				setPartners(data.map((d) => ({ ...d, display: true })));
			})
			.catch((err) => {
				console.error(err);
			});
	}, [project]);
	useEffect(() => {
		getPartners();
	}, [getPartners]);
	const customLabelApply = (results) => {
		if (results <= 1 && results !== null) {
			return translate("common:component.filter.apply", { results });
		}
		if (results > 1) {
			return translate("common:component.filters.apply", { results });
		}
		return translate("common:btn.apply");
	};
	return (
		<FiltersWrapper
			hasSearch
			components={[
				{
					default: true,
					enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
					component: CommonFilters.DOCUMENTS,
					renderer: <DocumentFilter />,
					withDescription: false,
				},
				{
					default: true,
					enabled: isSegFeatureEnabled(SegFlags.CATEGORY),
					component: CommonFilters.CATEGORIES,
					renderer: <ProjectCategoriesFilter disableFavoriteCategories />,
					hasFavorites: true,
				},
				{
					default: true,
					enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
					withDescription: false,
					multiSelection: true,
					labelKey: "label",
					valueKey: "key",
					component: CommonFilters.REQUIREMENT,
					hasIsSeparator: false,
				},
				{
					default: true,
					enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
					component: CommonFilters.POSITIONS,
					renderer: <PositionFilter />,
					partners,
				},
				{
					enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
					labelKey: "label",
					valueKey: "key",
					component: CommonFilters.VERSION,
				},
				{
					enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
					component: CommonFilters.CRITICALITY,
					labelKey: "label",
					valueKey: "value",
					dynamicItems: parameters?.criticality?.map((c) => ({
						label: `common:enum.params.criticality.${c.toLowerCase()}`,
						value: c,
					})),
				},
				{
					enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
					component: CommonFilters.NEGOTIABILITY,
					labelKey: "label",
					valueKey: "value",
					dynamicItems: parameters?.negotiability?.map((n) => ({
						label: `common:enum.params.negotiability.${n.toLowerCase()}`,
						value: n,
					})),
				},
				{
					enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
					component: CommonFilters.TYPE,
					labelKey: "label",
					valueKey: "value",
					dynamicItems: parameters?.type?.map((t) => ({
						label: `common:enum.params.type.${t.toLowerCase()}`,
						value: t,
					})),
				},
			]}
			customLabelApply={customLabelApply}
			defaultFilters={defaultFilters}
			previewRequest={filtersPreviewRequest}
			onApply={onApply}
		/>
	);
}
