import React, { useEffect, useRef, useState } from "react";
import { OptionsPanelContainer, SearchInput } from "../../../../../../../common/components";
import { ApiService, PackageManagementService } from "../../../../../../../api";
import Box from "../../../../box/Box";
import { translate } from "../../../../../../../common/providers";
import styles from "./ProjectList.module.css";

export default function ProjectList({ id }) {
	const cancelTokenSourceRef = useRef();
	const [projects, setProjects] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		PackageManagementService.getActivePackageProjects({ packageId: id }, cancelTokenSourceRef.current.token).then(
			(data) => {
				setProjects(data);
			}
		);
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [id]);
	const handleChangeSearch = (e) => {
		setSearchInput(e.target.value);
	};
	const handleClearSearch = () => {
		setSearchInput("");
	};
	return (
		<OptionsPanelContainer>
			<div className={styles.container}>
				<div>
					<SearchInput
						fullWidth
						disabled={projects.length === 0}
						placeholder={translate("common:btn.search")}
						value={searchInput}
						onChange={handleChangeSearch}
						onClearSearch={handleClearSearch}
					/>
				</div>
				<div className={styles.list}>
					{projects
						.filter((pc) => pc.name?.toLowerCase().includes(searchInput?.toLowerCase() || ""))
						.map((p) => (
							<Box key={p.id} content={p.created} title={p.name} />
						))}
				</div>
			</div>
		</OptionsPanelContainer>
	);
}
