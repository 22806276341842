import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./ParameterBlock.module.css";

const setColor = ({ property, value }) => {
	switch (true) {
		case property === "criticality" && value === "CRITICAL":
			return styles["parameter__crititicalty--critical"];
		case property === "criticality" && value === "MAJOR":
			return styles["parameter__crititicalty--major"];
		case property === "criticality" && value === "MINOR":
			return styles["parameter__crititicalty--minor"];
		case property === "negotiability" && value === "MUST":
			return styles["parameter__negotiability--must"];
		case property === "negotiability" && value === "SHOULD":
			return styles["parameter__negotiability--should"];
		case property === "negotiability" && value === "COULD":
			return styles["parameter__negotiability--could"];
		case property === "negotiability" && value === "WOULD":
			return styles["parameter__negotiability--would"];
		case property === "type":
		default:
			return styles.parameter__default;
	}
};

export default function ParameterBlock({ hoveredBlock }) {
	const pageContent = useSelector(({ srPdf }) => srPdf.content);
	const mode = useSelector(({ srDocument }) => srDocument.mode);

	const [arrayedParameters, setArrayedParameters] = useState([]);
	useEffect(() => {
		const block = ((pageContent.find((pc) => pc.page === hoveredBlock.pageStart) || {})[mode] || []).find(
			(req) => req.informationId === hoveredBlock.informationId
		)?.parameters;
		setArrayedParameters(
			(block &&
				Object.keys(block)
					.filter((p) => (p === "type" || p === "criticality" || p === "negotiability") && block[p])
					.map((property) => ({
						parameter: property,
						value: block[property],
						parameterStyle: setColor({ property, value: block[property] }),
					}))) ||
				[]
		);
	}, [pageContent, hoveredBlock, mode]);

	return (
		<>
			{arrayedParameters.map(
				(item) =>
					item.value && (
						<div key={item.value} className={`${styles.parameter} ${item.parameterStyle}`}>
							{item.value}
						</div>
					)
			)}
		</>
	);
}
