import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService, TransverseService } from "../../../../../../api";
import { icon, IconComponent, InfiniteList2 } from "../../../../../../common/components";
import { useEffectOnce, usePrevious } from "../../../../../../common/hooks";
import { translate } from "../../../../../../common/providers";
import { isDeepEqual } from "../../../../../../common/utils";
import { DOC_FILTER_LIMIT } from "../../../../constants/constants";
import { setMultiDocs } from "../../../../slice/project-document/project-document-slice";
import { generatePayload } from "../../../../utils/utils";
import { CollapseNodes } from "./components/toc-dropdown/components";
import styles from "./MultiToc.module.css";

const MultiToc = ({ open }) => {
	const multiDocs = useSelector(({ srProjectDocument }) => srProjectDocument.multiDocs);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const projectId = useSelector(({ context }) => context?.project?.id);
	const filters = useSelector(({ srProjectDocument }) => srProjectDocument.filters);
	const isContextLoadedToRedux = useSelector(({ srProjectDocument }) => srProjectDocument.isContextLoadedToRedux);
	const hasCounterUpdate = useSelector(({ srPdf }) => srPdf.hasCounterUpdate);
	const isThereAnotherFilter = useSelector(({ srProjectDocument }) => srProjectDocument.isThereAnotherFilter);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const prevFilters = usePrevious(filters);
	const fetchDocuments = (page) =>
		TransverseService.getDocuments(
			{ projectId },
			{ page, limit: DOC_FILTER_LIMIT },
			generatePayload(filters),
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				dispatch(setMultiDocs(data));
				return data;
			})
			.catch((err) => console.error(err));

	useEffectOnce(
		() =>
			setInfiniteListRequest(
				() =>
					({ page }) =>
						fetchDocuments(page)
			),
		[filters],
		() => filters
	);

	useEffect(
		() => {
			if (
				isContextLoadedToRedux &&
				((projectId && !isDeepEqual(prevFilters, filters)) || (isThereAnotherFilter && hasCounterUpdate))
			) {
				setInfiniteListRequest(
					() =>
						({ page }) =>
							fetchDocuments(page)
				);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filters, prevFilters, hasCounterUpdate, isContextLoadedToRedux]
	);
	return (
		<>
			{open && (
				<div className={styles.container}>
					<div className={styles.title}>
						{translate("smart-review.header.toc-list")}
						{isThereAnotherFilter && <IconComponent icon={icon.faAlignLeft} />}
					</div>
					<div className={styles.scrollcontainer}>
						<InfiniteList2
							anticipate
							className={styles.list}
							request={infiniteListRequest}
							rowRenderer={(toc) => (
								<CollapseNodes
									key={toc.id}
									document={multiDocs?.contents?.find((doc) => doc.id === toc.id) || toc}
								/>
							)}
						/>
					</div>
				</div>
			)}
		</>
	);
};
export default MultiToc;
