import React from "react";
import { constants } from "react-virtualized-tree";
import { CustomIconButton, icon } from "../../../../common/components";
import styles from "./Expandable.module.css";

const { UPDATE } = constants.UPDATE_TYPE;

const updateNode = (originalNode, newState) => ({
	node: {
		...originalNode,
		state: {
			...originalNode.state,
			...newState,
		},
	},
	type: UPDATE,
});

const getNodeRenderOptions = (node) => ({
	hasChildren: Array.isArray(node.children) && node.children.length > 0,
	isExpanded: !!node.state?.expanded,
});

export default function Expandable({ disabled = false, onChange, node, children, index }) {
	const { hasChildren, isExpanded } = getNodeRenderOptions(node);
	const handleChange = () => onChange({ ...updateNode(node, { expanded: !isExpanded }), index });
	return (
		<span className={styles.expandable} disabled={disabled}>
			{(hasChildren && (
				<CustomIconButton
					btnClassName={styles.expandable__btn}
					disabled={disabled}
					icon={isExpanded ? icon.faChevronDown : icon.faChevronRight}
					iconClassName={styles.expandable__chevron}
					size="sm"
					onClick={handleChange}
				/>
			)) || (
				<CustomIconButton
					disabled
					btnClassName={styles.expandable__btn}
					iconClassName={styles.expandable__chevron}
					size="sm"
				>
					{" "}
				</CustomIconButton>
			)}
			{children}
		</span>
	);
}
