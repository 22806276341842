import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
	CircularLoader,
	CustomAccordion,
	SidePanel,
	SidePanelBox,
	SidePanelContent,
} from "../../../../../../common/components";
import styles from "./QuestionDetailsSidePanel.module.css";
import { translate, translateDate } from "../../../../../../common/providers";
import { ApiService, QAService } from "../../../../../../api";

const DetailInfo = ({ title, content }) => (
	<div className={styles.detailInfo}>
		<div className={styles.detailInfo__title}>{title || "-"}</div>
		<div>{content || "-"}</div>
	</div>
);

export default function QuestionDetailsSidePanel({ open, onClose, qaId }) {
	const [details, setDetails] = useState(null);
	const [isLoading, setIsLoading] = useState(null);
	const cancelTokenSourceRef = useRef(null);
	const projectId = useSelector((state) => state.context.project?.id);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (open) {
			setIsLoading(true);
			QAService.getQaDetails({ projectId, qaId }, cancelTokenSourceRef.current.token)
				.then((data) => {
					setDetails(data);
					setIsLoading(false);
				})
				.catch((err) => {
					setIsLoading(false);
					console.error(err);
				});
		}
	}, [projectId, qaId, open]);

	return (
		<SidePanel open={open} size={40} onClose={onClose}>
			<SidePanelContent autoTranslate="no" title={translate("smart-review.qa-dialog.title")} onClose={onClose}>
				{(isLoading && (
					<div className={styles.loaderContainer}>
						<CircularLoader />
					</div>
				)) ||
					(details && (
						<SidePanelBox>
							<CustomAccordion
								defaultOpen
								header={
									<span className={styles.accordionTitle}>
										{translate("smart-review.qa-dialog.information")}
									</span>
								}
							>
								<div className={styles.informationAccordion}>
									<div>
										<DetailInfo
											content={translateDate(details.created)}
											title={translate("smart-review.qa-dialog.creation-date")}
										/>
										<DetailInfo
											content={details.asker?.displayName || details.asker || ""}
											title={translate("smart-review.qa-dialog.author")}
										/>
										<DetailInfo
											content={translateDate(details.modified)}
											title={translate("smart-review.qa-dialog.last")}
										/>
									</div>
									<div>
										<DetailInfo
											content={details.clientId}
											title={translate("smart-review.qa-dialog.client-id")}
										/>
										<DetailInfo
											content={translateDate(details.received)}
											title={translate("smart-review.qa-dialog.answer-reception")}
										/>
										<DetailInfo
											content={translateDate(details.sent)}
											title={translate("smart-review.qa-dialog.answer-sent")}
										/>
									</div>
								</div>
							</CustomAccordion>
							<CustomAccordion
								defaultOpen
								header={
									<span className={styles.accordionTitle}>
										{translate("smart-review.qa-dialog.question-and-answer")}
									</span>
								}
							>
								<DetailInfo
									content={details.questionAsked}
									title={translate("smart-review.qa-dialog.details.request-description")}
								/>
								<DetailInfo
									content={details.questionAnswer}
									title={translate("smart-review.qa-dialog.details.response")}
								/>
							</CustomAccordion>
						</SidePanelBox>
					))}
			</SidePanelContent>
		</SidePanel>
	);
}
