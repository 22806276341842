import React, { useEffect, useRef, useState } from "react";
import { ButtonGroup, ClickAwayListener, Popper } from "@mui/material";
import styles from "./Filter4.module.css";
import { CustomIconButton, icon } from "../..";
import { translate } from "../../../providers";
import { CircularLoader } from "../../loaders";
import { CustomTooltip } from "../../tooltip";
import { CustomButton } from "../../buttons";

export default function Filter4({
	children,
	contentClassName = "",
	disabled = false,
	disableRemoveButton = false,
	isActive = false,
	isLoading = false,
	label,
	labelActive = label,
	onClose = null,
	onOpen = null,
	onRemove = null,
	onSubmit = onClose,
	openOnMount = false,
	removeButtonTooltip = "",
	submitBtnColor = "secondary",
	submitBtnLabel = "",
	title = label,
	tooltipActive = labelActive,
}) {
	const labelBtnRef = useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	useEffect(() => {
		if (!openOnMount) {
			return;
		}
		setTimeout(() => labelBtnRef.current?.click(), 0);
	}, [openOnMount]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		if (!open && typeof onOpen === "function") {
			onOpen();
		}
	};
	const handleClose = () => {
		setAnchorEl(null);
		if (typeof onClose === "function") {
			onClose();
		}
	};
	const handleCloseWithAction = () => {
		setAnchorEl(null);
		if (typeof onSubmit === "function") {
			onSubmit();
		}
	};
	return (
		<div className={styles.filter}>
			{!isActive && (
				<CustomButton ref={labelBtnRef} color="secondary" endIcon={icon.faChevronDown} onClick={handleClick}>
					{label}
				</CustomButton>
			)}
			{isActive && (
				<ButtonGroup className={styles["btn-group"]} color="primary" variant="contained">
					<CustomButton ref={labelBtnRef} color="primary" size="md" variant="contained" onClick={handleClick}>
						<CustomTooltip arrow placement="bottom" title={tooltipActive}>
							{labelActive}
						</CustomTooltip>
					</CustomButton>
					<CustomIconButton
						btnClassName={styles["button-group__remove-btn"]}
						color="primary"
						disabled={disableRemoveButton}
						icon={icon.faTimes}
						size="sm"
						tooltip={removeButtonTooltip}
						tooltipPlacement="bottom"
						variant="contained"
						onClick={onRemove}
					/>
				</ButtonGroup>
			)}
			{open && (
				<ClickAwayListener mouseEvent="onClick" onClickAway={handleClose}>
					<Popper
						disablePortal
						anchorEl={anchorEl}
						className={styles.filter__popup}
						open={open}
						placement="bottom-start"
					>
						<div className={styles.popup__header}>
							<span className={styles.header__title}>{title}</span>
							<CustomIconButton icon={icon.faTimes} onClick={handleClose} />
						</div>
						<div className={`${styles.popup__content} ${contentClassName}`}>{children}</div>
						<div className={styles.popup__footer}>
							<CustomButton
								fullWidth
								color={submitBtnColor}
								disabled={disabled || isLoading}
								variant="contained"
								onClick={handleCloseWithAction}
							>
								{(isLoading && <CircularLoader color="inherit" size={25} />) ||
									submitBtnLabel ||
									translate("common:btn.close")}
							</CustomButton>
						</div>
					</Popper>
				</ClickAwayListener>
			)}
		</div>
	);
}
