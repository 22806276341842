import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { CustomButton, CustomIconButton, CustomLink, SidePanel, SidePanelContent, icon } from "../..";
import { translate } from "../../../providers";
import styles from "./AddAutomaticCategorizationSidePanel.module.css";
import { FilterSearch } from "../../filters5/wrappers/components";
import { FilterSelector } from "../../filters5/components";
import { KEYWORD_FILTER_OPERATOR, OPERATORS } from "../../../constants";
import MultipleKeywordsInput from "../../input/multiple-input/keywords/MultipleKeywordsInput";
import { formatDateYMDHM, isNonEmptyObject } from "../../../utils";

const COPY_TYPES = { SEARCH: "SEARCH", ...KEYWORD_FILTER_OPERATOR };
const DEFAULT_INIT_FILTERS = [
	{
		operator: KEYWORD_FILTER_OPERATOR.IS_EXACTLY,
		matchAll: false,
		keywords: [],
	},
	{
		operator: KEYWORD_FILTER_OPERATOR.START_WITH,
		matchAll: false,
		keywords: [],
	},
];

export default function AddAutomaticCategorizationSidePanel({
	categoryName,
	defaultFilters,
	isTemplate,
	searchCardId,
	searchCardName,
	setIsEdit,
	open,
	onClose,
	onSubmit,
	onGetFilters,
}) {
	const [search, setSearch] = useState("");
	const [tempFilters, setTempfilters] = useState(DEFAULT_INIT_FILTERS);
	const [tempAutomationName, setTempAutomationName] = useState("");
	const [currentFilters, setCurrentFilters] = useState({});
	const [isComplex, setIsComplex] = useState(false);
	const [andOr, setAndOr] = useState(OPERATORS.OR);
	const projectId = useSelector(({ context }) => context.project?.id);

	const initFilters = useCallback((data) => {
		const { content, separator, keywordFilters } = data.filters;
		if (content) {
			setSearch(content);
		}
		setAndOr(separator);
		if (keywordFilters) {
			setTempfilters([
				...keywordFilters.filters,
				...DEFAULT_INIT_FILTERS.filter(
					(dif) => !keywordFilters.filters.some((dkf) => dkf.operator === dif.operator)
				),
			]);
		}
		setCurrentFilters(data);
	}, []);
	useEffect(() => {
		if (defaultFilters?.tempId || defaultFilters?.tempId === 0) {
			setTempAutomationName(defaultFilters.name);
		} else {
			setTempAutomationName(searchCardId ? searchCardName : categoryName);
		}
	}, [searchCardId, searchCardName, categoryName, open, currentFilters, defaultFilters]);
	useEffect(
		() => {
			// eslint-disable-next-line quotes
			const openDialog = document.querySelector('div.MuiDialog-root[role="presentation"]');
			if (openDialog) {
				if (open) {
					// MUI dialog z-index = 1300
					openDialog.style.zIndex = "100";
					if (searchCardId && onGetFilters && typeof onGetFilters === "function") {
						onGetFilters({ id: searchCardId })
							.then((data) => {
								initFilters(data);
								setIsComplex(data.isComplexeFilters);
							})
							.catch(console.error);
					} else if (isNonEmptyObject(defaultFilters)) {
						initFilters(defaultFilters);
					}
				} else {
					openDialog.style.zIndex = "1300";
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[open, searchCardId, defaultFilters, initFilters]
	);

	const handleTextSearch = (content) => {
		setSearch(content);
	};
	const handleDeleteAll = () => {
		setSearch("");
		setCurrentFilters({});
		setTempfilters(DEFAULT_INIT_FILTERS);
		setTempAutomationName("");
	};
	const handleClose = () => {
		onClose();
		setIsComplex(false);
		handleDeleteAll();
		setTempAutomationName(null);
	};
	const handleSubmit = () => {
		const filters = {
			...currentFilters,
			separator: andOr,
			Type: (isTemplate && "TemplateSearchFilterDTO") || "InformationSearchFilterDTO",
			keywordFilters: { filters: tempFilters },
			content: search || null,
		};
		const payload = {
			filters,
		};
		if (!searchCardId) {
			payload.name = tempAutomationName || categoryName;
			payload.description = formatDateYMDHM(new Date());
		}
		if (searchCardId) {
			payload.id = searchCardId;
			payload.name = tempAutomationName || searchCardName;
		}
		if (defaultFilters?.tempId || defaultFilters?.tempId === 0) {
			payload.tempId = defaultFilters.tempId;
		}
		onSubmit(payload);
		setIsEdit(true);
		handleClose();
	};
	const handleSetOrOperator = (op, entry) => {
		setTempfilters((prev) =>
			prev.map((x) => (x.operator === op && { ...x, matchAll: entry !== OPERATORS.OR }) || x)
		);
	};
	const handleAndOrOperator = (entry) => {
		setAndOr(entry);
	};
	const exactlyKeywords = useMemo(
		() => tempFilters.find((tf) => tf.operator === COPY_TYPES.IS_EXACTLY).keywords,
		[tempFilters]
	);
	const startWithKeywords = useMemo(
		() => tempFilters.find((tf) => tf.operator === COPY_TYPES.START_WITH).keywords,
		[tempFilters]
	);

	const handleCopyClipboard = (type) => {
		switch (type) {
			case COPY_TYPES.SEARCH:
				navigator.clipboard.writeText(search);
				break;
			case COPY_TYPES.IS_EXACTLY:
				navigator.clipboard.writeText(
					exactlyKeywords.map((key) => (key.includes(" ") ? `"${key}"` : key)).join(" ")
				);
				break;
			case COPY_TYPES.START_WITH:
				navigator.clipboard.writeText(
					startWithKeywords.map((key) => (key.includes(" ") ? `"${key}"` : key)).join(" ")
				);
				break;
			default:
				break;
		}
	};
	const handleChangeName = (e) => {
		setTempAutomationName(e.target.value);
	};
	const handleUpdateKeyword = (key, keywords) => {
		setTempfilters((prev) => prev.map((x) => (x.operator === key && { ...x, keywords }) || x));
	};
	const areEmptyFilters = useMemo(
		() => !/\S/.test(search) && !tempFilters.some((tf) => tf.keywords.length > 0),
		[tempFilters, search]
	);
	const handleCriteriaDisplay = (entry) =>
		translate(`common:enum.separator.${entry.toLowerCase().replace("_", "-")}`).toLowerCase();
	return (
		<SidePanel disableBackdropClick disableEscapeKeyDown open={open} size={38} onClose={handleClose}>
			<SidePanelContent
				secondaryActions={
					<CustomButton color="primary" variant="text" onClick={handleDeleteAll}>
						{translate(
							"common:condensed-automation.category.automatic-categorization.side-panel.delete.button"
						)}
					</CustomButton>
				}
				title={
					((searchCardId || defaultFilters?.tempId !== undefined) &&
						translate(
							"common:condensed-automation.category.automatic-categorization.dialog.modification.title"
						)) ||
					translate("common:condensed-automation.category.automatic-categorization.dialog.creation.title")
				}
				onClose={handleClose}
			>
				<div className={styles.main}>
					<div className={styles.container}>
						<div className={styles.name_container}>
							<div className={styles.name_title}>
								{`${translate(
									"common:condensed-automation.category.automatic-categorization.side-panel.automation.name"
								)}*`}
							</div>
							<TextField
								fullWidth
								className={styles.panel__categoryNameContainer}
								data-testid="new.automation.name"
								inputProps={{ className: styles.panel__categoryNameInput }}
								maxLength={80}
								size="sm"
								value={tempAutomationName || ""}
								onChange={handleChangeName}
							/>
						</div>
						<div className={styles.fulltext_container}>
							<div className={styles.fulltext_title}>
								{translate(
									"common:condensed-automation.category.automatic-categorization.side-panel.search.title"
								)}
							</div>
							<div className={styles.fulltext_main}>
								<FilterSearch
									autocomplete
									debounceSearch={false}
									noSearch={isTemplate}
									placeholder={translate("common:multiple-keywords-input.text-field.label")}
									search={search}
									size="sm"
									onSearch={handleTextSearch}
								/>
								<CustomIconButton
									disabled={!search}
									icon={icon.faCopy}
									size="sm"
									tooltip={translate(
										"common:condensed-automation.category.automatic-categorization.side-panel.copy.tooltip"
									)}
									variant="outlined"
									onClick={() => handleCopyClipboard(COPY_TYPES.SEARCH)}
								/>
							</div>
						</div>
						<div>
							<div className={styles.box}>
								<div />
								<span>
									{translate(
										"common:condensed-automation.category.automatic-categorization.side-panel.exact-expression"
									)}
								</span>
								<FilterSelector
									itemRenderer={handleCriteriaDisplay}
									items={Object.values(OPERATORS)}
									size="md"
									value={
										(tempFilters.find((x) => x.operator === KEYWORD_FILTER_OPERATOR.IS_EXACTLY)
											.matchAll &&
											OPERATORS.AND) ||
										OPERATORS.OR
									}
									onChange={(entry) => handleSetOrOperator(KEYWORD_FILTER_OPERATOR.IS_EXACTLY, entry)}
								/>
							</div>
							<div className={styles.multiplInput_container}>
								<MultipleKeywordsInput
									spaceAllowed
									className={styles.multiplInput}
									defaultKeywords={exactlyKeywords}
									property={KEYWORD_FILTER_OPERATOR.IS_EXACTLY}
									onUpdate={handleUpdateKeyword}
								/>
								<CustomIconButton
									className={exactlyKeywords.length === 0 ? styles.copyDeactive : styles.copyActive}
									data-testid="exact.copy.btn"
									disabled={exactlyKeywords.length === 0}
									icon={icon.faCopy}
									size="sm"
									tooltip={translate(
										"common:condensed-automation.category.automatic-categorization.side-panel.copy.tooltip"
									)}
									variant="outlined"
									onClick={() => handleCopyClipboard(COPY_TYPES.IS_EXACTLY)}
								/>
							</div>
						</div>
						<div>
							<div className={styles.box}>
								<div>
									<FilterSelector
										itemRenderer={(itemValue) =>
											translate(`common:enum.separator.${itemValue?.toLowerCase()}`)
										}
										items={Object.values(OPERATORS)}
										size="md"
										value={andOr}
										onChange={handleAndOrOperator}
									/>
								</div>
								<span>
									{translate(
										"common:condensed-automation.category.automatic-categorization.side-panel.start-with"
									)}
								</span>
								<FilterSelector
									itemRenderer={handleCriteriaDisplay}
									items={Object.values(OPERATORS)}
									size="md"
									value={
										(tempFilters.find((x) => x.operator === KEYWORD_FILTER_OPERATOR.START_WITH)
											.matchAll &&
											OPERATORS.AND) ||
										OPERATORS.OR
									}
									onChange={(entry) => handleSetOrOperator(KEYWORD_FILTER_OPERATOR.START_WITH, entry)}
								/>
							</div>
							<div className={styles.multiplInput_container}>
								<MultipleKeywordsInput
									className={styles.multiplInput}
									defaultKeywords={startWithKeywords}
									property={KEYWORD_FILTER_OPERATOR.START_WITH}
									onUpdate={handleUpdateKeyword}
								/>
								<CustomIconButton
									className={startWithKeywords.length === 0 ? styles.copyDeactive : styles.copyActive}
									data-testid="start.copy.btn"
									disabled={startWithKeywords.length === 0}
									icon={icon.faCopy}
									size="sm"
									tooltip={translate(
										"common:condensed-automation.category.automatic-categorization.side-panel.copy.tooltip"
									)}
									variant="outlined"
									onClick={() => handleCopyClipboard(COPY_TYPES.START_WITH)}
								/>
							</div>
						</div>
						{isComplex && (
							<div className={styles.complexBanner}>
								{translate("common:condensed-automation.complex-filters.banner")}&nbsp;
								<CustomLink
									className={styles.link}
									to={
										isTemplate
											? `/search-templates/search-for-automation-templates?templateId=${searchCardId}`
											: `/projects/${projectId}/search/search-for-automation?searchCardId=${searchCardId}`
									}
								>
									{(isTemplate &&
										translate(
											"common:condensed-automation.complex-filters.redirection-template"
										)) ||
										translate("common:condensed-automation.complex-filters.redirection")}
								</CustomLink>
							</div>
						)}
					</div>
					<div className={styles.footer}>
						<CustomButton color="secondary" variant="outlined" onClick={handleClose}>
							{translate("common:btn.cancel")}
						</CustomButton>
						<CustomButton
							color="primary"
							disabled={areEmptyFilters || !tempAutomationName || tempAutomationName.length === 0}
							variant="contained"
							onClick={handleSubmit}
						>
							{translate("common:btn.submit")}
						</CustomButton>
					</div>
				</div>
			</SidePanelContent>
		</SidePanel>
	);
}
