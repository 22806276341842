import React, { useEffect, useState } from "react";
import { DeliverableSwitch, DeliverablesTabLayout } from "..";
import { DeliverablesService } from "../../../../../../../../../api";
import styles from "./DeliverablesTabContent.module.css";
import { useApi } from "../../../../../../../../../common/hooks";
import { translate } from "../../../../../../../../../common/providers";

export default function DeliverablesTabContent({ reqId, coverageStatus, onUpdate, onSetSelectedTab, selectedTab }) {
	const { call: updateCoverageStatus } = useApi(DeliverablesService.updateCoverageStatus);
	const { call: getCoverageStatus } = useApi(DeliverablesService.getCoverageStatus);
	const [tempCoverage, setTempCoverage] = useState(coverageStatus);

	useEffect(() => {
		getCoverageStatus({ reqId })
			.then((data) => setTempCoverage(data))
			.catch(console.error);
	}, [reqId, getCoverageStatus]);
	const updateCoverageStatusService = (status) => {
		updateCoverageStatus({ reqId }, status)
			.then(() => {
				onUpdate(status);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleCoverageStatusChange = (event) => {
		const { checked } = event.target;
		const newCoverageStatus = checked ? "TO_BE_COVERED" : "EXCLUDED";
		setTempCoverage(newCoverageStatus);
		updateCoverageStatusService(newCoverageStatus);
	};

	return (
		<div className={styles.main}>
			<DeliverableSwitch
				className={styles.radios}
				coverageStatus={tempCoverage}
				onCoverageStatusChange={handleCoverageStatusChange}
			/>
			{(tempCoverage === "EXCLUDED" && (
				<div className={styles.emptyState}>{translate("smart-review.right-panel.deliverables.excluded")}</div>
			)) || (
				<div className={styles.container}>
					<DeliverablesTabLayout
						reqId={reqId}
						selectedTab={selectedTab}
						onSetSelectedTab={onSetSelectedTab}
					/>
				</div>
			)}
		</div>
	);
}
