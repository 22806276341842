export const checkEmail = (email) => {
	const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return re.test(String(email)?.toLowerCase());
};

export const isStrongPassword = (str) => {
	const format = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@!#$%^&+=])(?=.{8,})");
	return format.test(str);
};

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();

export const removeUnderscore = (str) => str.replace(/_/g, " ");

export const alphaSort = (str1, str2) => str1.localeCompare(str2);

export const toInitials = (firstName, lastName) => {
	if (lastName && firstName) {
		return `${firstName[0]}${lastName[0]}`;
	}
	if (lastName) {
		return lastName[0];
	}
	if (firstName) {
		return firstName[0];
	}
	return "";
};

export const toInitialsUserName = (userName) => {
	if (!userName || typeof userName !== "string") {
		return "";
	}
	return userName
		.split(" ")
		.map((word) => word[0])
		.join("");
};

export const compareInsensitiveCaseString = (str1, str2) => {
	if (!str1 || !str2) {
		return false;
	}
	return str1.toLowerCase() === str2.toLowerCase();
};
