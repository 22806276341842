import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./SelectionPanel.module.css";
import { CustomIconButton, icon, ViewTabPanel, ViewTabs } from "../../../../../common/components";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../../common/providers";
import TABS from "./tabs";
import {
	setCurrentFocus,
	setSelectedRectangles,
	setSelectionSidePanelOpen,
} from "../../../slice/document/document-slice";
import { clearFirstLevelEmpties, isNonEmptyObject } from "../../../../../common/utils";
import { hasReviewPermissions } from "../../../permissions/review-permissions-provider";
import REVIEW_PERMISSIONS from "../../../permissions/review-permissions";

const SelectionPanel = () => {
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const filters = useSelector(({ srProjectDocument }) => srProjectDocument.filters);
	const roles = useSelector(({ srDocument }) => srDocument.roles);
	const [selectedTab, setSelectedTab] = useState(0);
	const tabHeaders = [
		{
			id: 0,
			label: translate("smart-review.cmp-details.option-panel.categories"),
			disabled:
				!isSegFeatureEnabled(SegFlags.CATEGORY) ||
				!hasReviewPermissions(REVIEW_PERMISSIONS.CATEGORY_UPDATE, { roles }),
			tab: "CategoriesTab",
		},
	];
	const dispatch = useDispatch();
	const handleCloseSidePanel = () => {
		dispatch(setSelectionSidePanelOpen(false));
		dispatch(setSelectedRectangles(null));
		if (!isNonEmptyObject(clearFirstLevelEmpties(filters))) {
			dispatch(setCurrentFocus("qa"));
		}
	};
	useEffect(() => {
		setSelectedTab(0);
	}, []);
	return (
		<div className={styles.panel}>
			<div className={styles.panel__header}>
				<CustomIconButton
					className={styles.header__icon}
					icon={icon.faTimes}
					iconClassname={styles["header__icon--large"]}
					onClick={handleCloseSidePanel}
				/>
				<span className={styles.header__text} translate="no">
					{translate("smart-review.selection-panel.title", { selected: selectedRectangles.length })}
				</span>
			</div>
			<div className={styles.panel__content}>
				<>
					<ViewTabs
						className={styles.tabs}
						selectedTab={selectedTab}
						tabs={tabHeaders.filter((th) => !th.disabled)}
						variant="scrollable"
						onChange={(_, tab) => {
							setSelectedTab(tab);
						}}
					/>
					<div className={styles.panel__subcontent}>
						{tabHeaders
							.filter((tabHeader) => !tabHeader?.disabled)
							.map((tab, index) => (
								<ViewTabPanel key={tab.tab} noPadding index={index} value={selectedTab}>
									{TABS[tab.tab]()}
								</ViewTabPanel>
							))}
					</div>
				</>
			</div>
		</div>
	);
};

export default SelectionPanel;
