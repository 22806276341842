import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MenuItem, MenuList } from "@mui/material";
import {
	CustomButton,
	CustomDialog,
	CustomIconButton,
	CustomTooltip,
	DropDown,
	LimitedAutocompleteTextField,
	SidePanelBox,
	icon,
} from "..";
import { POSITIONS_STATUSES } from "../../constants/positions-statuses";
import { Flags, isFeatureEnabled, translate, translateDate, translateElapsed } from "../../providers";
import styles from "./CommentBox.module.css";
import CommentHighlightedText from "../comment-highlighted-text/CommentHighlightedText";
import { setDisableKeyEvents } from "../../slice";
import { CommentService } from "../../../api";
import { setCommentData, setIsNewNotification } from "../../../views/my-review/slice/document/document-slice";
import { isNonEmptyArray } from "../../utils";
import { useApi } from "../../hooks";

export default function CommentBox({
	comment = {},
	disableEditing = false,
	isLast = false,
	isOpenConversation = false,
	isParent = false,
	onAttachChildren = null,
	onDeleteComment = null,
	onOpenConversation = null,
	onRefresh,
	onRefreshCounters = null,
	onSearchChangePage,
	onUpdateComment = null,
	parentId = null,
	teamMembers,
}) {
	const commentId = useSelector(({ srDocument }) => srDocument.sidePanelCommentId);
	const userId = useSelector(({ context }) => context.user.id);
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [tempComment, setTempComment] = useState("");
	const [tempNewComment, setTempNewComment] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const [shiftPressed, setShiftPressed] = useState(false);
	const [isActiveEditComment, setIsActiveEditComment] = useState(false);
	const [isActiveReplyComment, setIsActiveReplyComment] = useState(false);
	const [openDropComment, setOpenDropComment] = useState(false);
	const commentRef = useRef(null);
	const commentMenuRef = useRef();
	const { call: answerComment } = useApi(CommentService.answerComment);
	const { call: updateAnswerComment } = useApi(CommentService.updateAnswerComment);
	const { call: deleteComment } = useApi(CommentService.deleteComment);
	const { call: getAnswerComments } = useApi(CommentService.getAnswerComments);
	const { call: updateDiscussionStatus } = useApi(CommentService.updateDiscussionStatus);
	const dispatch = useDispatch();
	let preventSearchChangePage = false;

	const handleSearchChangePage = () => {
		if (!preventSearchChangePage && onSearchChangePage && typeof onSearchChangePage === "function") {
			onSearchChangePage({ pageStart: comment.page, infoId: comment.informationId, y1: comment.y1 });
		}
		preventSearchChangePage = false;
	};
	useEffect(
		() => () => {
			if (disableKeyEvents) {
				dispatch(setDisableKeyEvents(false));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	useEffect(() => {
		if (commentRef?.current) {
			commentRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
			if (commentId) {
				dispatch(setCommentData({ sidePanelCommentId: null }));
			}
		}
	}, [commentRef, commentId, dispatch]);
	useEffect(() => {
		if (isEditing) {
			setTempComment(comment.comment);
		}
	}, [comment, isEditing]);
	const handleStartEditing = () => {
		setIsEditing(true);
	};
	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};
	const handleChangeTempComment = (value) => {
		if ((/\S/.test(value) && (tempComment?.length === 0 || !tempComment)) || tempComment?.length > 0) {
			setTempComment(value);
		}
	};
	const handleChangeTempNewComment = (value) => {
		if ((/\S/.test(value) && (tempNewComment?.length === 0 || !tempNewComment)) || tempNewComment?.length > 0) {
			setTempNewComment(value);
		}
	};
	const handleBlurEdit = useCallback(() => {
		dispatch(setDisableKeyEvents(false));
		setIsActiveEditComment(false);
		if (tempComment?.length === 0) {
			setIsEditing(false);
		}
	}, [dispatch, tempComment]);
	const handleFocusEdit = () => {
		dispatch(setDisableKeyEvents(true));
		setIsActiveEditComment(true);
	};
	const handleBlurReply = useCallback(() => {
		dispatch(setDisableKeyEvents(false));
		setIsActiveReplyComment(false);
	}, [dispatch]);
	const handleFocusReply = () => {
		dispatch(setDisableKeyEvents(true));
		setIsActiveReplyComment(true);
	};
	const handleOpenConversation = useCallback(() => {
		if (
			!comment?.openMore &&
			comment.childrenCount > 0 &&
			onOpenConversation &&
			typeof onOpenConversation === "function"
		) {
			onOpenConversation(comment?.commentId);
		}
	}, [comment, onOpenConversation]);
	const handleAttachChildren = useCallback(
		(data) => {
			if (comment?.openMore || !comment?.hasChild) {
				const commentsMetadata =
					(!comment?.hasChild && {
						hasMore: false,
						pageNumber: 0,
						totalElements: 1,
						totalPages: 1,
					}) ||
					{};
				onAttachChildren(comment.commentId, {
					...commentsMetadata,
					contents: [...(comment?.comments?.contents || []), data],
				});
			}
		},
		[comment, onAttachChildren]
	);
	const handleReply = useCallback(
		(event) => {
			answerComment({ commentId: comment.commentId }, { value: tempNewComment })
				.then((data) => {
					handleAttachChildren(data);
					handleOpenConversation();
					onRefreshCounters();
					dispatch(setIsNewNotification(true));
				})
				.catch(console.error)
				.finally(() => {
					setIsEditing(false);
					handleBlurReply();
					setTempNewComment("");
					if (event) {
						event.target.blur();
					}
				});
		},
		[
			answerComment,
			comment,
			tempNewComment,
			onRefreshCounters,
			dispatch,
			handleBlurReply,
			handleOpenConversation,
			handleAttachChildren,
		]
	);
	const handleEdit = useCallback(
		(event) => {
			updateAnswerComment({ commentId: comment.commentId }, { value: tempComment })
				.then((data) => {
					if (!isParent) {
						onUpdateComment({ parentId: data.parentId, childId: comment.commentId, data });
					} else {
						onUpdateComment({ data });
					}
					dispatch(setIsNewNotification(true));
				})
				.catch(console.error)
				.finally(() => {
					setIsEditing(false);
					handleBlurEdit();
					setTempComment("");
					if (event) {
						event.target.blur();
					}
				});
		},
		[updateAnswerComment, comment, isParent, tempComment, dispatch, handleBlurEdit, onUpdateComment]
	);
	useEffect(() => {
		const handleKeyReleased = (e) => {
			if ((isActiveEditComment || isActiveReplyComment) && e.key === "Shift") {
				setShiftPressed(false);
			}
		};
		const handleKeyDetect = (e) => {
			if ((isActiveEditComment || isActiveReplyComment) && e.key === "Shift") {
				setShiftPressed(true);
			}
			if ((isActiveEditComment || isActiveReplyComment) && e.key === "Enter" && !shiftPressed) {
				const autocompleteOpen = document.querySelector(
					"[role='listbox'][class*='_mentions__suggestions__list_']"
				);
				if (!autocompleteOpen) {
					if (isActiveEditComment && tempComment?.length > 0 && /\S/.test(tempComment)) {
						handleEdit(e);
					}
					if (isActiveReplyComment && tempNewComment?.length > 0 && /\S/.test(tempNewComment)) {
						handleReply(e);
					}
				}
			}
			if ((isActiveReplyComment || isActiveEditComment) && (e.key === "ArrowLeft" || e.key === "ArrowRight")) {
				e.preventDefault();
				const textField = e.target;
				if (e.key === "ArrowLeft") {
					if (textField.selectionStart > 0) {
						textField.setSelectionRange(textField.selectionStart - 1, textField.selectionStart - 1);
					}
				} else if (e.key === "ArrowRight") {
					if (textField.selectionStart < textField.value.length) {
						textField.setSelectionRange(textField.selectionStart + 1, textField.selectionStart + 1);
					}
				}
			}
			if ((isActiveEditComment || isActiveReplyComment) && e.key === "Escape") {
				if (isActiveEditComment) {
					handleBlurEdit();
				}
				if (isActiveReplyComment) {
					handleBlurReply();
					setTempNewComment("");
				}
				setIsEditing(false);
			}
		};
		const handleScroll = () => {
			if (openDropComment) {
				setOpenDropComment(false);
			}
		};
		window.addEventListener("keydown", handleKeyDetect, true);
		window.addEventListener("keyup", handleKeyReleased, true);
		document.addEventListener("scroll", handleScroll, true);

		return () => {
			window.removeEventListener("keydown", handleKeyDetect, true);
			window.removeEventListener("keyup", handleKeyReleased, true);
			document.removeEventListener("scroll", handleScroll, true);
		};
	}, [
		dispatch,
		isActiveEditComment,
		isActiveReplyComment,
		openDropComment,
		shiftPressed,
		tempComment,
		tempNewComment,
		handleEdit,
		handleBlurEdit,
		handleBlurReply,
		handleReply,
	]);
	const handleDelete = useCallback(
		() =>
			deleteComment({ commentId: comment.commentId })
				.then(() => {
					onDeleteComment(comment.commentId, parentId, isLast);
				})
				.catch(console.error)
				.finally(() => handleCloseDeleteDialog()),
		[deleteComment, comment, parentId, onDeleteComment, isLast]
	);
	const handleDialogDelete = useCallback(() => {
		setOpenDeleteDialog(true);
	}, []);
	const HistoryRowRender = useCallback(
		({ reply, isLastItem }) => (
			<CommentBox
				key={reply.commentId}
				comment={reply}
				disableEditing={disableEditing}
				isLast={isLastItem}
				isOpenConversation={isOpenConversation}
				parentId={comment.commentId}
				teamMembers={teamMembers}
				onDeleteComment={onDeleteComment}
				onRefresh={onRefresh}
				onRefreshCounters={onRefreshCounters}
				onUpdateComment={onUpdateComment}
			/>
		),
		[
			comment,
			disableEditing,
			isOpenConversation,
			teamMembers,
			onRefresh,
			onRefreshCounters,
			onDeleteComment,
			onUpdateComment,
		]
	);
	const handleOpenReplies = useCallback(() => {
		getAnswerComments({ commentId: comment.commentId }, { page: 0, limit: 10 })
			.then((data) => {
				onAttachChildren(comment.commentId, { ...data, contents: data.contents.reverse() });
			})
			.catch(console.error);
	}, [getAnswerComments, onAttachChildren, comment]);
	useEffect(() => {
		if (comment?.openMore && !isNonEmptyArray(comment?.comments?.contents) && comment?.childrenCount > 0) {
			handleOpenReplies();
		}
	}, [comment, handleOpenReplies]);
	const handleDisplayPrevious = () => {
		getAnswerComments({ commentId: comment.commentId }, { page: comment.comments.pageNumber + 1, limit: 10 })
			.then((data) => {
				const { contents, ...rest } = data;
				onAttachChildren(comment.commentId, {
					...rest,
					contents: [...contents.reverse(), ...comment.comments.contents],
				});
			})
			.catch(console.error);
	};
	const handleDropdown = () => {
		setOpenDropComment((prev) => !prev);
	};
	const handleConversationStatus = useCallback(() => {
		if (!comment?.commentId) {
			return;
		}
		updateDiscussionStatus({ commentId: comment.commentId }, !isOpenConversation)
			.then(() => {
				onRefresh();
				onRefreshCounters();
			})
			.catch(console.error);
	}, [updateDiscussionStatus, comment, onRefresh, onRefreshCounters, isOpenConversation]);
	const commentMenuList = useMemo(() => {
		const reopenConv = {
			key: "reOpenConversation",
			label: "common:comment.row.reopen-conversation",
			action: handleConversationStatus,
		};
		const closeConv = {
			key: "closeConversation",
			label: "common:comment.row.close-conversation",
			action: handleConversationStatus,
		};
		const commentMenu = [
			{
				key: "updateComment",
				label: "common:comment.row.modify-comment",
				action: handleStartEditing,
				test: "comment.edit.btn",
			},
			{
				key: "deleteComment",
				label: "common:comment.row.delete-comment",
				test: "comment.main.delete.btn",
				action: handleDialogDelete,
				disabled: comment?.hasChild,
			},
		];
		const replyMenu = [
			{ key: "updateReply", label: "common:comment.row.modify-answer", action: handleStartEditing },
			{
				key: "deleteReply",
				label: "common:comment.row.delete-answer",
				action: handleDialogDelete,
			},
		];
		if (isParent) {
			if (isOpenConversation) {
				if (comment?.userId === userId) {
					return [closeConv, ...commentMenu];
				}
				return [closeConv];
			}
			return [reopenConv];
		}
		if (!isLast) {
			return replyMenu.slice(0, 1);
		}
		return replyMenu;
	}, [isOpenConversation, isParent, comment, userId, isLast, handleConversationStatus, handleDialogDelete]);
	return (
		<SidePanelBox className={styles.container} hasShadow={false} onClick={handleSearchChangePage}>
			<div className={styles.container__line} data-is-closed-conversation={!isOpenConversation}>
				<div className={styles.line__leftItems}>
					<span className={styles.username}>{comment.username} | </span>
					{comment.status === POSITIONS_STATUSES.UNREVIEWED ? (
						<span className={styles.date}>{translate("common:comment.row.comment.in-progress")}</span>
					) : (
						<span className={styles.grayed}>
							<CustomTooltip
								placement="top"
								title={translateDate(
									(comment?.lastModified !== comment.date && comment?.lastModified) || comment.date
								).replaceAll(".", "/")}
							>
								<div className={styles.date}>
									{comment?.lastModified !== comment.date && (
										<>{translate("common:date.modified")}&nbsp;</>
									)}
									{translateElapsed({
										value: comment?.elapsed?.value,
										unitKey: comment?.elapsed?.unit,
									})}
								</div>
							</CustomTooltip>
						</span>
					)}
				</div>
				{(isParent || (!isParent && comment?.userId === userId && isOpenConversation)) && (
					<CustomIconButton
						ref={commentMenuRef}
						btnClassName={styles.actionButton}
						data-testid="comment.options.btn"
						icon={icon.faEllipsisH}
						iconClassName={styles.actionButton__icon}
						variant="outlined"
						onClick={handleDropdown}
					/>
				)}
			</div>
			<div className={styles.container__inner} data-is-closed-conversation={!isOpenConversation}>
				{(isEditing && (
					<div className={styles["container__inner--margin"]}>
						<LimitedAutocompleteTextField
							autoFocus
							multiline
							className={styles.textField}
							data={teamMembers}
							displayLength={tempComment?.length > 0}
							helperTextClassName={styles.helperTextClassName}
							maxLength={512}
							placeholder={translate("common:comment.add-comment")}
							rows={tempComment?.length > 3 ? 4 : 1}
							size="sm"
							trigger={`${(isFeatureEnabled(Flags.MENTIONS) && "@") || null}`}
							value={tempComment}
							onBlur={handleBlurEdit}
							onChange={handleChangeTempComment}
							onFocus={handleFocusEdit}
						/>
						{tempComment?.length > 0 && (
							<div className={styles.line__rightItems}>
								<CustomIconButton
									btnClassName={styles.btnClassName}
									data-testid="comment.send.btn"
									icon={icon.faPaperPlane}
									iconClassName={styles.iconClassName}
									tooltip={translate("common:comment.send-button")}
									variant="outlined"
									onClick={handleEdit}
								/>
							</div>
						)}
					</div>
				)) || (
					<div ref={comment?.commentId === commentId ? commentRef : null} className={styles.comment}>
						{comment.comment.split("\n").map((com, index) => (
							<CommentHighlightedText
								// eslint-disable-next-line react/no-array-index-key
								key={`${com}${index}`}
								textClassName={styles.commentLine}
								value={com}
							/>
						))}
					</div>
				)}
				{isParent && comment?.hasChild && !comment?.openMore && (
					<div>
						<CustomButton
							className={styles.displayer}
							color="primary"
							variant="text"
							onClick={handleOpenReplies}
						>
							{(comment?.childrenCount > 1 &&
								translate("common:comment.row.display-replies", {
									childrenCount: comment?.childrenCount,
								})) ||
								translate("common:comment.row.display-replies.singular")}
						</CustomButton>
					</div>
				)}
				{comment?.openMore && (
					<div className={styles.conversation__container}>
						{comment?.comments?.hasMore && (
							<div>
								<CustomButton
									className={styles.displayer}
									color="primary"
									variant="text"
									onClick={handleDisplayPrevious}
								>
									{translate("common:comment.row.display-previous")}
								</CustomButton>
							</div>
						)}
						{isNonEmptyArray(comment?.comments?.contents) &&
							comment.comments.contents.map((com, index) => (
								<HistoryRowRender
									key={com.commentId}
									isLastItem={index === comment.comments.contents.length - 1}
									reply={com}
								/>
							))}
					</div>
				)}
				{isParent && isOpenConversation && (
					<div className={styles.replyContainer} data-has-text={tempNewComment?.length > 0}>
						<LimitedAutocompleteTextField
							multiline
							className={styles.textField}
							data={teamMembers}
							displayLength={tempNewComment?.length > 0}
							helperTextClassName={styles.helperTextClassName}
							maxLength={512}
							placeholder={translate("common:comment.row.comment.reply-to-comment")}
							rows={tempNewComment?.length > 3 ? 4 : 1}
							size="sm"
							trigger={`${(isFeatureEnabled(Flags.MENTIONS) && "@") || null}`}
							value={tempNewComment}
							onBlur={handleBlurReply}
							onChange={handleChangeTempNewComment}
							onFocus={handleFocusReply}
						/>
						{tempNewComment?.length > 0 && (
							<div className={styles.line__rightItems}>
								<CustomIconButton
									btnClassName={styles.btnClassName}
									data-testid="comment.send.btn"
									icon={icon.faPaperPlane}
									iconClassName={styles.iconClassName}
									tooltip={translate("common:comment.send-button")}
									variant="outlined"
									onClick={handleReply}
								/>
							</div>
						)}
					</div>
				)}
			</div>
			<CustomDialog
				data-testid="confirm.delete.dialog"
				iconColor="var(--color-red)"
				iconModel={icon.faExclamationTriangle}
				open={openDeleteDialog}
				submitLabel={translate("common:btn.delete")}
				subTitle={translate("common:comment.row.comment.delete-popup.helper")}
				title={translate("common:delete-confirmation-popup.title")}
				onClose={handleCloseDeleteDialog}
				onSubmit={handleDelete}
			/>
			<DropDown ref={commentMenuRef} open={openDropComment} position="bottom-end" onClose={handleDropdown}>
				<div className={styles.dropDownContainer}>
					<MenuList>
						{commentMenuList.map((item) => (
							<MenuItem
								key={item.key}
								data-testid={item?.test || ""}
								disabled={item?.disabled}
								onClick={() => {
									item.action();
									handleDropdown();
								}}
							>
								<div className={styles.menuItem__text}>{translate(item.label)}</div>
							</MenuItem>
						))}
					</MenuList>
				</div>
			</DropDown>
		</SidePanelBox>
	);
}
