import { FormControl, InputLabel, MenuItem, Select, Chip } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { createNotification, CustomDialog } from "../../../../../../common/components";
import { ApiService, ContentService, RedressmentService } from "../../../../../../api";
import RedressContent from "./RedressContent";
import RedressGroup from "./RedressGroup";
import RedressMove from "./RedressMove";
import RedressSplit from "./RedressSplit";
import { translate } from "../../../../../../common/providers";
import styles from "./RedressDialog.module.css";

const GROUP_ACTION = ["ABOVE", "BELOW"];
const REDRESS_ACTION = ["LEFT", "RIGHT"];
const CONTENT_TYPES = ["PARAGRAPH", "CLAUSE"];
const REDR_GROUP = "REDR_GROUP";
const REDR_MOVE = "REDR_MOVE";
const REDR_REDRESS = "REDR_REDRESS";
const REDR_SPLIT = "REDR_SPLIT";

export default function RedressDialog({ isProcessingRedressment, onClose, onEndProcessing, onReloadData, open }) {
	const [redrAction, setRedrAction] = useState("");
	const [action, setAction] = useState("");
	const [error, setError] = useState(false);
	const [toc, setToc] = useState("");
	const [type, setType] = useState("");
	const [tocList, setTocList] = useState([]);
	const [splits, setSplits] = useState([]);
	const [contentLines, setContentLines] = useState([]);
	const [loadingMove, setLoadingMove] = useState(false);
	const [loadingLines, setLoadingLines] = useState(false);
	const selectedBlock = useSelector(({ srDocument }) => srDocument.selectedBlock);
	const docId = useSelector(({ srDocument }) => srDocument.documentId);
	const cancelTokenSourceRef = useRef(null);
	const inputRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const fetchContentLine = useCallback(() => {
		if (selectedBlock?.contentId) {
			setLoadingLines(true);
			ContentService.getAllContentLines(
				{ contentId: selectedBlock.contentId },
				cancelTokenSourceRef.current.token
			)
				.then((data) => {
					setContentLines(data || []);
				})
				.catch((err) => console.error(err))
				.finally(() => setLoadingLines(false));
		}
	}, [selectedBlock]);

	useEffect(
		() => {
			if (redrAction === REDR_SPLIT) {
				fetchContentLine();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedBlock]
	);

	const fetchToc = (forceUpdate = false) => {
		const isTocEmpty = !Array.isArray(tocList) || tocList.length === 0;
		if (isTocEmpty || forceUpdate) {
			if (isTocEmpty) {
				setLoadingMove(true);
			}
			ContentService.getDocumentClauses({ docId }, cancelTokenSourceRef.current.token)
				.then((data) => {
					setTocList(data || []);
				})
				.catch((err) => console.error(err))
				.finally(() => {
					if (isTocEmpty) {
						setLoadingMove(false);
					}
				});
		}
	};

	const handleMove = (contentId, act) => {
		if (redrAction !== REDR_MOVE) {
			return;
		}
		const payload = {
			id: contentId,
			type: "CLAUSE",
			action: act || REDRESS_ACTION[0],
		};
		createNotification({ message: translate("smart-review.redress-dialog.notification.processing") });
		RedressmentService.move({ docId }, payload, cancelTokenSourceRef.current.token)
			.then(() => {
				createNotification({
					type: "success",
					message: translate("smart-review.redress-dialog.notification.operation-successful"),
				});
				fetchToc(true);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleClose = ({ isProcessing }) => {
		onClose({ isProcessing: isProcessing || isProcessingRedressment });
	};

	const request = (promise) => {
		createNotification({ message: translate("smart-review.redress-dialog.notification.processing") });
		promise
			.then(() => {
				onReloadData();
				createNotification({
					type: "success",
					message: translate("smart-review.redress-dialog.notification.operation-successful"),
				});
				onEndProcessing();
			})
			.catch((err) => {
				console.error(err);
				onEndProcessing();
			});
	};

	const handleSubmitAndClose = () => {
		let payload = null;
		let promise = null;
		switch (redrAction) {
			case REDR_REDRESS:
				if (!type) {
					return;
				}
				if (type === "CLAUSE" && !toc) {
					setError(true);
					return;
				}
				payload = {
					id: selectedBlock?.contentId,
					type,
					action: action || REDRESS_ACTION[0],
					toc: toc || null,
				};
				promise = RedressmentService.redress({ docId }, payload, cancelTokenSourceRef.current.token);
				break;
			case REDR_SPLIT:
				if (!splits || !Array.isArray(splits) || splits.length === 0) {
					return;
				}
				payload = {
					id: selectedBlock?.contentId,
					splits: splits.map((line) => line.id),
				};
				promise = RedressmentService.split({ docId }, payload, cancelTokenSourceRef.current.token);
				break;
			case REDR_GROUP:
				payload = {
					contentId: selectedBlock?.contentId,
					action: action || (selectedBlock?.contentType !== "CLAUSE" && GROUP_ACTION[0]) || GROUP_ACTION[1],
				};
				promise = RedressmentService.group({ docId }, payload, cancelTokenSourceRef.current.token);
				break;
			default:
				handleClose();
				return;
		}
		request(promise);
		handleClose({ isProcessing: true });
	};

	const handleChangeRedrAction = (event) => {
		setRedrAction(event.target.value);
		setAction("");
		setError(false);
		setToc("");
		setType("");
		setSplits([]);
		setLoadingMove(false);
		setLoadingLines(false);
		if (event.target.value === REDR_MOVE) {
			fetchToc(false);
		} else if (event.target.value === REDR_SPLIT) {
			fetchContentLine();
		}
	};

	const handleChangeType = (event) => {
		setType(event.target.value);
	};

	const handleChangeAction = (event) => {
		setAction(event.target.value);
	};

	const handleChangeToc = (event) => {
		setToc(event.target.value);
		setError(!event.target.value);
	};

	const handleSplit = (splits2) => {
		setSplits((prev) => (!Array.isArray(splits2) ? prev : splits2));
	};

	return (
		<CustomDialog
			displayCloseCross
			isForm
			open={open}
			submitDisabled={!redrAction}
			subTitle={
				isProcessingRedressment && translate("smart-review.redress-dialog.redressment-operation-in-progress")
			}
			title={translate("smart-review.redress-dialog.title")}
			onClose={handleClose}
			onSubmit={redrAction !== REDR_MOVE && handleSubmitAndClose}
		>
			{!isProcessingRedressment && (
				<>
					<FormControl
						className={styles.formControl}
						label={translate("smart-review.redress-dialog.select-action")}
						variant="outlined"
					>
						<InputLabel ref={inputRef} className={styles.inputLabel}>
							{translate("smart-review.redress-dialog.select-action")}
						</InputLabel>
						<Select size="small" value={redrAction} onChange={handleChangeRedrAction}>
							{(selectedBlock?.contentType === "PARAGRAPH" ||
								selectedBlock?.contentType === "CLAUSE") && (
								<MenuItem value={REDR_REDRESS}>
									{translate("smart-review.redress-dialog.item-redress-content")}
								</MenuItem>
							)}
							<MenuItem value={REDR_MOVE}>
								{translate("smart-review.redress-dialog.item-reorganize-toc")}
							</MenuItem>
							{(selectedBlock?.contentType === "PARAGRAPH" ||
								selectedBlock?.contentType === "CLAUSE") && (
								<MenuItem value={REDR_SPLIT}>
									{translate("smart-review.redress-dialog.item-split-content")}
								</MenuItem>
							)}
							{(selectedBlock?.contentType === "PARAGRAPH" ||
								selectedBlock?.contentType === "CLAUSE") && (
								<MenuItem value={REDR_GROUP}>
									{translate("smart-review.redress-dialog.item-group-content")}
								</MenuItem>
							)}
						</Select>
					</FormControl>
					{redrAction === REDR_REDRESS && (
						<RedressContent
							action={action}
							CONTENT_TYPES={CONTENT_TYPES}
							error={error}
							handleChangeAction={handleChangeAction}
							handleChangeToc={handleChangeToc}
							handleChangeType={handleChangeType}
							REDRESS_ACTION={REDRESS_ACTION}
							selectedBlock={selectedBlock}
							toc={toc}
							type={type}
						/>
					)}
					{redrAction === REDR_MOVE && (
						<RedressMove
							handleMove={handleMove}
							loading={loadingMove}
							REDRESS_ACTION={REDRESS_ACTION}
							tocList={tocList}
						/>
					)}
					{redrAction === REDR_SPLIT && (
						<RedressSplit handleSplit={handleSplit} lines={contentLines} loading={loadingLines} />
					)}
					{redrAction === REDR_GROUP && (
						<RedressGroup
							action={action}
							GROUP_ACTION={GROUP_ACTION}
							handleChangeAction={handleChangeAction}
							isClause={selectedBlock?.contentType === "CLAUSE"}
						/>
					)}
				</>
			)}
			{selectedBlock?.contentType && (
				<Chip
					className={styles.contentTypeChip}
					label={selectedBlock?.contentType}
					size="small"
					variant="outlined"
				/>
			)}
		</CustomDialog>
	);
}
