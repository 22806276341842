import React, { useCallback, useEffect, useState } from "react";
import { Alert, AlertTitle, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { translate } from "../../../../common/providers";
import { BlockTitle, CustomButton } from "../../../../common/components";
import AdminCompanyTable from "./admin-company-table/AdminCompanyTable";
import { COMPANY_TYPES } from "../../../my-review/constants/constants";
import styles from "./AdminCompany.module.css";
import { isNonEmptyArray, searchInTree } from "../../../../common/utils";
import { useEffectOnce } from "../../../../common/hooks";

export default function AdminCompany({ companies = [], onEditBu }) {
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [payload, setPayload] = useState({ shortName: "", name: "" });
	const [errors, setErrors] = useState({ shortName: false, name: false });
	const loggedIncompanyId = useSelector(({ context }) => context.company?.id);
	const handleSelectCompany = useCallback(
		(company) => {
			if (!selectedCompany || company?.id !== selectedCompany?.id) {
				setSelectedCompany(company);
			}
		},
		[selectedCompany]
	);
	useEffect(() => {
		if (selectedCompany) {
			setSelectedCompany(searchInTree(companies, selectedCompany?.id));
		}
	}, [companies, selectedCompany]);

	useEffectOnce(
		() => {
			handleSelectCompany(companies[0]);
		},
		[],
		() => selectedCompany === null && isNonEmptyArray(companies)
	);
	const findCompanyWithAdminUserRole = useCallback(
		(dataTree) => {
			for (const company of dataTree) {
				if (company.userRole === "ADMIN" && company.id === loggedIncompanyId) {
					return company;
				}
				if (company.children) {
					const innerCompanyId = findCompanyWithAdminUserRole(company.children);
					if (innerCompanyId) {
						return innerCompanyId;
					}
				}
			}
			return null;
		},
		[loggedIncompanyId]
	);

	useEffect(() => {
		if (!selectedCompany && Array.isArray(companies) && companies.length > 0) {
			handleSelectCompany(findCompanyWithAdminUserRole(companies));
		}
	}, [selectedCompany, companies, handleSelectCompany, findCompanyWithAdminUserRole]);
	const reset = () => {
		setPayload({ shortName: "", name: "" });
		setErrors({ shortName: false, name: false });
	};

	const handleChangeCompany = (company) => {
		if (company !== selectedCompany) {
			setSelectedCompany(company);
			reset();
		}
	};

	const onChange = (value, field) => {
		if (!value) {
			setErrors((prev) => ({ ...prev, [field]: true }));
		} else {
			setErrors((prev) => ({ ...prev, [field]: false }));
		}
		setPayload((prev) => ({ ...prev, [field]: value }));
	};

	const handleSubmit = () => {
		const nameToValidate = payload.name || selectedCompany.companyName;
		const shortNameToValidate = payload.shortName || selectedCompany.shortName;
		const errs = {
			name: !nameToValidate,
			shortName: !shortNameToValidate,
		};
		const hasError = Object.keys(errs).some((key) => errs[key]);
		if (!hasError && selectedCompany) {
			onEditBu({
				company: selectedCompany,
				payload: { shortName: shortNameToValidate, name: nameToValidate },
			});
			setSelectedCompany("");
			reset();
		}
		setErrors(errs);
	};
	return (
		<>
			<div
				className={`${styles.companyContainer} ${
					selectedCompany ? styles["container--half-width"] : styles["container--full-width"]
				}`}
			>
				<BlockTitle>{translate("admin.admin-company.title")}</BlockTitle>
				<div className={styles.companyTable}>
					<AdminCompanyTable
						companies={companies}
						selectedCompany={selectedCompany}
						onSelectRow={handleChangeCompany}
					/>
				</div>
			</div>
			{selectedCompany ? (
				<div className={styles.selectedCompany}>
					<BlockTitle>
						{translate("admin.admin-company.titleBu", { bu: selectedCompany.companyName })}
					</BlockTitle>
					<div className={styles.companyInfo}>
						<div className={styles.companyInfo__edit}>
							<TextField
								required
								disabled={selectedCompany.type === COMPANY_TYPES.CONSORTIUM}
								error={errors && errors.shortName}
								helperText={errors && errors.shortName && translate("common:error.required")}
								label={translate("admin.admin-company.th.shortname")}
								translate="no"
								value={payload.shortName || selectedCompany.shortName || ""}
								variant="outlined"
								onChange={(e) => onChange(e.target.value, "shortName")}
							/>
							<span className={styles.dividerSpace} />
							<TextField
								required
								disabled={selectedCompany.type === COMPANY_TYPES.CONSORTIUM}
								error={errors && errors.name}
								helperText={errors && errors.name && translate("common:error.required")}
								label={translate("admin.admin-company.th.name")}
								value={payload.name || selectedCompany.companyName || ""}
								variant="outlined"
								onChange={(e) => onChange(e.target.value, "name")}
							/>
							<span className={styles.dividerSpace} />
							<CustomButton
								fullWidth
								color="primary"
								disabled={
									selectedCompany.type === COMPANY_TYPES.CONSORTIUM ||
									selectedCompany.id !== loggedIncompanyId
								}
								variant="contained"
								onClick={handleSubmit}
							>
								{translate("common:btn.submit")}
							</CustomButton>
						</div>
						<Alert className={styles.alert} severity="info">
							<AlertTitle>{translate("admin.admin-company.alert.seg-flag.bu.title")}</AlertTitle>
							{translate("admin.admin-company.alert.seg-flag.bu.content")}
						</Alert>
					</div>
				</div>
			) : null}
		</>
	);
}
