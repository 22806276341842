import React from "react";
import { InfiniteLoader, List } from "react-virtualized";

/**
 *  isRowLoaded = ({ index }) => !hasMore || index < currentTotalElements;
 *  rowRenderer = ({ key, index, style }) => { elements[index]; return <Component key={key} .../> }
 *  noRowsRenderer = () => <EmptyState />
 *  loadMoreRows = () => Promise (notice that Promise is returned)
 */
export default function InfiniteList({
	isRowLoaded,
	loadMoreRows,
	totalElements,
	threshold = 50,
	width = 1920,
	height = 1080,
	noRowsRenderer,
	currentTotalElements,
	rowHeight = 50,
	rowRenderer,
	onScroll,
	className,
}) {
	return (
		<InfiniteLoader
			isRowLoaded={isRowLoaded}
			loadMoreRows={loadMoreRows}
			rowCount={totalElements}
			threshold={threshold}
		>
			{({ onRowsRendered, registerChild }) => (
				<List
					ref={registerChild}
					className={className}
					height={height}
					noRowsRenderer={noRowsRenderer}
					rowCount={currentTotalElements}
					rowHeight={rowHeight}
					rowRenderer={rowRenderer}
					width={width}
					onRowsRendered={onRowsRendered}
					onScroll={onScroll}
				/>
			)}
		</InfiniteLoader>
	);
}
