import { Badge } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
	CustomButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	CustomTooltip,
	icon,
	IconComponent,
	Selector,
	TextMidCut,
} from "../../../../../common/components";
import { Flags, isFeatureEnabled, translate, translateDate } from "../../../../../common/providers";
import { USER_STATUS_IN_PROJECT } from "../../../constants/constants";
import styles from "./DashPortfolioTable.module.css";

export default function DashPortfolioTable({
	onChangeCompany,
	onChangeTeam,
	onJoinProject,
	onOpenSidePanel,
	onSelectRow,
	onSetIndex,
	projectList,
	selectedRow,
	selectedTeams,
	onClickSort,
	sortDirection,
	sortKey,
}) {
	const [headers] = useState([
		{
			className: styles.headerCell,
			name: translate("dashboards.dash-portfolio.th.project-type"),
		},
		{ className: styles.headerCell, name: translate("dashboards.dash-portfolio.th.name"), sortKey: "name" },
		{
			className: styles.headerCell,
			name: translate("dashboards.dash-portfolio.th.date"),
			sortKey: "created",
		},
		{ className: styles.headerCell, name: translate("dashboards.dash-portfolio.th.team") },
		{ className: styles.headerCell_action },
		{ className: styles.headerCell_action },
	]);
	const companyName = useSelector(({ context }) => context.company?.name);
	const handleSelectRow = (row, index) => {
		if (row.userStatusInProject !== USER_STATUS_IN_PROJECT.active) {
			return;
		}
		if ((row?.teams || []).length > 0) {
			onSelectRow(row);
			onSetIndex(index);
		}
	};
	return (
		<CustomTable dense stickyHeader>
			<CustomTableHead>
				<CustomTableRow className={styles.row}>
					{headers.map((header, index) => (
						<CustomTableCell
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							active={header.sortKey === sortKey}
							className={`${styles.header} ${header.className}`}
							direction={sortDirection}
							sortKey={header.sortKey}
							onSort={() => onClickSort(header.sortKey)}
						>
							{header.name}
						</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody emptyState={translate("dashboards.dashboard-portfolio-user.no-filtered-projects")}>
				{Array.isArray(projectList) &&
					selectedTeams.length === projectList.length &&
					projectList.map((row, index) => (
						<CustomTableRow
							key={row.projectId}
							className={`${styles.row} ${
								(selectedRow?.projectId === row.projectId && styles["row--selected"]) || ""
							}
								${(row.userStatusInProject !== USER_STATUS_IN_PROJECT.active && styles["row--locked"]) || ""}`}
							data-testid={`projectJoin_${row.projectId}`}
							hover={row.userStatusInProject === USER_STATUS_IN_PROJECT.active}
							hoverClassName={styles["row--hover"]}
							onClick={() => handleSelectRow(row, index)}
						>
							<CustomTableCell autoTranslate="no" className={styles.row__cell}>
								<TextMidCut text={row.projectType} />
							</CustomTableCell>
							<CustomTableCell autoTranslate="no" className={styles.row__cell}>
								<TextMidCut tooltip text={row.projectName} />
							</CustomTableCell>
							<CustomTableCell className={styles.row__cell}>
								{translateDate(row.creationDate)}
							</CustomTableCell>
							<CustomTableCell className={styles.row__cell}>
								{row.userStatusInProject !== USER_STATUS_IN_PROJECT.pending &&
									Array.isArray(selectedTeams) &&
									Array.isArray(row?.teams) && (
										<Selector
											autoTranslate="no"
											className={styles.cell__selector}
											disabled={row.teams.length < 2}
											field="name"
											items={row.teams}
											value={selectedTeams[index] || ""}
											variant="standard"
											onChange={(e) => onChangeTeam(index, e)}
										/>
									)}
							</CustomTableCell>
							<CustomTableCell className={`${styles.row__cell} ${styles.cell__action}`}>
								<div>
									{(row.userStatusInProject === USER_STATUS_IN_PROJECT.active && (
										<CustomButton
											data-cy="project_open"
											data-testid={`openProject_${row.projectId}`}
											tooltip={translate("dashboards.dash-portfolio.arrow.tooltip")}
											variant="outlined"
											onClick={(e) => {
												e.stopPropagation();
												onChangeCompany(index);
											}}
										>
											{translate("dashboards.project-req-review.content.open-project")}
										</CustomButton>
									)) ||
										(row.userStatusInProject === USER_STATUS_IN_PROJECT.pending && (
											<CustomButton
												variant="contained"
												onClick={() => onJoinProject(row.projectId)}
											>
												{translate("dashboards.dash-portfolio.join")}
											</CustomButton>
										)) || (
											<CustomTooltip
												title={translate(
													"dashboards.dash-portfolio.project-warning.no-access.tooltip",
													{ companyName }
												)}
											>
												<IconComponent color="var(--color-dark-grey-1)" icon={icon.faLock} />
											</CustomTooltip>
										)}
									{row.userStatusInProject === USER_STATUS_IN_PROJECT.active && (
										<CustomButton variant="outlined" onClick={onOpenSidePanel}>
											{translate("dashboards.project-req-review.content.see-details")}
										</CustomButton>
									)}
								</div>
							</CustomTableCell>
							{isFeatureEnabled(Flags.MENTIONS) && (
								<CustomTableCell
									className={styles.row__cell}
									data-testid={`unreadMention.count__${row.unreadMentions}`}
								>
									{(row.userStatusInProject === USER_STATUS_IN_PROJECT.active ||
										row.userStatusInProject === USER_STATUS_IN_PROJECT.pending) && (
										<Badge
											badgeContent={row.unreadMentions}
											classes={{ badge: styles.badgeStyle }}
											color="primary"
											max={99}
										>
											<IconComponent
												color={`${
													(row.unreadMentions > 0 && "var(--color-blue)") ||
													"var(--color-dark-grey-1)"
												}`}
												icon={icon.faBell}
												size="lg"
											/>
										</Badge>
									)}
								</CustomTableCell>
							)}
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
