import React from "react";
import { CustomIconButton } from "../../buttons";
import CustomLink from "../custom-link/CustomLink";

export default function CustomIconButtonLink({
	className = "",
	disabled = false,
	icon,
	iconClassName = "",
	iconColor = "inherit",
	onClick = null,
	openInNewTab = false,
	size = "md",
	state = null,
	to,
	tooltip = "",
	tooltipPlacement = "bottom",
	variant = "text",
}) {
	return (
		<CustomLink disabled={disabled} openInNewTab={openInNewTab} state={state} to={to}>
			<CustomIconButton
				className={className}
				disabled={disabled}
				icon={icon}
				iconClassName={iconClassName}
				iconColor={iconColor}
				size={size}
				tooltip={tooltip}
				tooltipPlacement={tooltipPlacement}
				variant={variant}
				onClick={onClick}
			/>
		</CustomLink>
	);
}
