import React from "react";
import { translate } from "../../../../../common/providers";
import styles from "./ReqList.module.css";

export default function ReqListEmptyState() {
	return (
		<div className={styles["req-list__empty-state"]}>
			<div className={styles["req-list__empty-state-title"]}>
				{translate("requirement-matrix.req-list.empty-state.title")}
			</div>
			<div className={styles["req-list__empty-state-sub-title"]}>
				{translate("requirement-matrix.req-list.empty-state.content")}
			</div>
		</div>
	);
}
