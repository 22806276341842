import React from "react";
import { SidePanel, SidePanelBox, SidePanelContent } from "../..";
import CommentHighlightedText from "../../comment-highlighted-text/CommentHighlightedText";
import styles from "./ShowMoreSidePanel.module.css";

export default function ShowMoreSidePanel({ comment, open, onClose, title = "" }) {
	return (
		<SidePanel offset={2} open={open} size={40} onClose={onClose}>
			<SidePanelContent title={title} onClose={onClose}>
				<SidePanelBox className={styles.commentBox} hasShadow={false}>
					<CommentHighlightedText textClassName={styles.commentLine} value={comment} />
				</SidePanelBox>
			</SidePanelContent>
		</SidePanel>
	);
}
