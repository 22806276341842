import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedBlock } from "../../../slice/document/document-slice";
import { resetContent } from "../../../slice/pdf/pdf-slice";
import RedressDialog from "./components/RedressDialog";
import { checkReqValMode } from "../../../utils/utils";

export default function Redressment() {
	const selectedBlock = useSelector(({ srDocument }) => srDocument.selectedBlock);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const page = useSelector(({ srPdf }) => srPdf.page);
	const dispatch = useDispatch();
	const [isProcessingRedressment, setIsProcessingRedressment] = useState(false);
	const handleCloseRedressDialog = ({ isProcessing = false }) => {
		setIsProcessingRedressment(isProcessing);
		dispatch(setSelectedBlock(null));
	};
	const handleEndProcessingRedressment = () => {
		setIsProcessingRedressment(false);
	};
	const reloadData = () => {
		dispatch(resetContent({ pages: [page, page + 1, page + 2], mode }));
	};
	return (
		<>
			{!checkReqValMode(mode) && (
				<RedressDialog
					isProcessingRedressment={isProcessingRedressment}
					open={!!selectedBlock}
					onClose={handleCloseRedressDialog}
					onEndProcessing={handleEndProcessingRedressment}
					onReloadData={reloadData}
				/>
			)}
		</>
	);
}
