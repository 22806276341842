import React, { useEffect, useState } from "react";
import { List, Checkbox, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";
import { translate, translateEnumExportDialog } from "../../../../../common/providers";
import ExportRow from "./ExportRow";
import {
	CustomButton,
	CustomDialog,
	ViewTabs,
	createNotification,
	generateFilters,
} from "../../../../../common/components";
import { useApi } from "../../../../../common/hooks";
import { ExportService } from "../../../../../api";
import { downloadFile } from "../../../../../common/utils";
import CustomExportTemplates from "./CustomExportTemplates";
import styles from "./ExportDialog.module.css";

export default function ExportDialog({ filters, open, onClose }) {
	const { call: getRequirementsColumns } = useApi(ExportService.getRequirementsColumns);
	const { call: exportRequirements } = useApi(ExportService.exportRequirements);
	const { call: exportRequirementsCustomTemplate } = useApi(ExportService.exportRequirementsCustomTemplate);
	const { call: getActiveExportTemplatesByProject } = useApi(ExportService.getActiveExportTemplatesByProject);
	const projectId = useSelector(({ context }) => context.project?.id);
	const [columns, setColumns] = useState([]);
	const [selectedColumns, setSelectedColumns] = useState([]);
	const [exportTemplates, setExportTemplates] = useState([]);
	useEffect(() => {
		getRequirementsColumns()
			.then((data) => setColumns(data))
			.catch((err) => console.error(err));
	}, [getRequirementsColumns]);
	useEffect(() => {
		getActiveExportTemplatesByProject({ projectId })
			.then((data) => {
				setExportTemplates(data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [projectId, getActiveExportTemplatesByProject]);
	const handleToggle = (column) => {
		let selected = selectedColumns;
		const checked = selected.some((v) => v === column);
		if (checked) {
			selected = selected.filter((v) => v !== column);
		} else {
			selected.push(column);
		}
		setSelectedColumns([...selected]);
	};
	const handlePushUp = (column, index) => {
		if (index === 0) {
			return;
		}
		const c = columns;
		const prevValue = c[index - 1];
		c.splice(index - 1, 1, column);
		c.splice(index, 1, prevValue);
		setColumns([...c]);
	};
	const handlePushDown = (column, index) => {
		if (index === columns.length - 1) {
			return;
		}
		const c = columns;
		const prevValue = c[index + 1];
		c.splice(index + 1, 1, column);
		c.splice(index, 1, prevValue);
		setColumns([...c]);
	};

	const exportFilteredRequirements = (newColumns) => {
		if (newColumns.length === 0) {
			return;
		}
		createNotification({ message: translate("common:notification.exporting") });
		exportRequirements(
			{ projectId },
			{ filters: { ...generateFilters(filters), Type: "RequirementSearchFilterDTO" }, columns: newColumns }
		)
			.then(({ data, filename }) => downloadFile({ data, filename, filetype: "xlsx" }))
			.catch((err) => {
				console.error(err);
			});
	};

	const handleExport = () => {
		const columnsOrdered = [...selectedColumns];
		columnsOrdered.sort((c1, c2) => columns.indexOf(c1) - columns.indexOf(c2));
		exportFilteredRequirements(columnsOrdered);
		onClose();
	};
	const handleSelectAll = () => {
		if (selectedColumns.length === columns.length) {
			setSelectedColumns([]);
		} else {
			setSelectedColumns([...columns]);
		}
	};
	const handleCustomExport = (template) => {
		createNotification({ message: translate("requirement-matrix.notification.exporting") });
		exportRequirementsCustomTemplate({ projectId, templateId: template.id })
			.then(({ data, filename }) => {
				downloadFile({ data, filename, filetype: "xlsx" });
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => onClose());
	};
	const [selectedTab, setSelectedTab] = useState(0);
	return (
		<CustomDialog
			disableBackdropClick
			displayCloseCross
			hideCloseButton
			isForm
			closeLabel={translate("common:btn.cancel")}
			open={open}
			title={translate("common:export-dialog.title")}
			onClose={onClose}
		>
			<ViewTabs
				className={styles.tabsHeader}
				selectedTab={selectedTab}
				tabs={[
					{ label: translate("common:export-dialog.standard-export") },
					{ label: translate("common:export-dialog.customized-export") },
				]}
				onChange={(_, newTab) => setSelectedTab(newTab)}
			/>
			{selectedTab === 0 ? (
				<>
					<i>{translate("common:export-dialog.sub-title")}</i>
					<List>
						<ListItem button dense onClick={handleSelectAll}>
							<ListItemIcon>
								<Checkbox
									disableRipple
									checked={selectedColumns.length === columns.length}
									color="primary"
									edge="start"
									size="small"
								/>
							</ListItemIcon>
							<ListItemText
								primary={translate("common:btn.select-all")}
								style={{ textTransform: "uppercase" }}
							/>
						</ListItem>
						{columns.map((column, index) => {
							const label = translateEnumExportDialog(column);
							return (
								<ExportRow
									key={column}
									checked={selectedColumns.some((v) => v === column)}
									handlePushDown={() => handlePushDown(column, index)}
									handlePushUp={() => handlePushUp(column, index)}
									handleToggle={() => handleToggle(column)}
									index={index}
									label={label}
									maxIndex={columns.length - 1}
								/>
							);
						})}
						<div className={styles.dialogExportButtons}>
							<CustomButton color="secondary" variant="outlined" onClick={onClose}>
								{translate("common:btn.cancel")}
							</CustomButton>
							<CustomButton
								color="primary"
								disabled={!Array.isArray(selectedColumns) || selectedColumns.length === 0}
								variant="contained"
								onClick={handleExport}
							>
								{translate("common:btn.submit")}
							</CustomButton>
						</div>
					</List>
				</>
			) : (
				<CustomExportTemplates exportTemplates={exportTemplates} handleCustomExport={handleCustomExport} />
			)}
		</CustomDialog>
	);
}
