import { Chip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
	CustomIconButton,
	CustomIconButtonLink,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	CustomTooltip,
	icon,
} from "../../../../../common/components";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../../common/providers";
import { NavUtils } from "../../../../../navigation";
import { translateEnumRowType, translateEnumStatus } from "../../../utils/utils";
import styles from "./ErrorsTable.module.css";

export default function ErrorsTable({
	contents,
	handleClickOnAction,
	handleIgnoreConfirmation,
	handleResolveConfirmation,
	isLoading,
	projectId,
	selectedDocument,
	showAllErrors,
	showOnlyResolvedErrors,
	showTocRedressment,
}) {
	const location = useLocation();
	const [headers, setHeaders] = useState([
		{
			name: translate("consistency-check.errors.th.description"),
			className: styles["table-headers--width-25"],
		},
		{
			name: translate("consistency-check.errors.th.content"),
			className: styles["table-headers--width-25"],
		},
		{
			name: translate("consistency-check.errors.th.page"),
			className: styles["table-headers--width-10"],
		},
		{
			name: (
				<span className={styles.headerIcon}>
					{translate("consistency-check.errors.th.status")}
					<CustomIconButton
						icon={showAllErrors ? icon.faEyeSlash : icon.faEye}
						tooltip={
							showAllErrors
								? translate("consistency-check.errors.th.btn.hide-resolved-errors")
								: translate("consistency-check.errors.th.btn.show-resolved-errors")
						}
						onClick={() => showOnlyResolvedErrors()}
					/>
				</span>
			),
			className: styles["table-headers--width-10"],
		},
		{
			name: translate("consistency-check.errors.th.actions"),
			className: styles["table-headers--width-30"],
		},
	]);
	useEffect(() => {
		setHeaders([
			{
				name: translate("consistency-check.errors.th.description"),
				className: styles["table-headers--width-25"],
			},
			{
				name: translate("consistency-check.errors.th.content"),
				className: styles["table-headers--width-25"],
			},
			{
				name: translate("consistency-check.errors.th.page"),
				className: styles["table-headers--width-10"],
			},
			{
				name: (
					<span className={styles.headerIcon}>
						{translate("consistency-check.errors.th.status")}
						<CustomIconButton
							icon={showAllErrors ? icon.faEyeSlash : icon.faEye}
							tooltip={
								showAllErrors
									? translate("consistency-check.errors.th.btn.hide-resolved-errors")
									: translate("consistency-check.errors.th.btn.show-resolved-errors")
							}
							onClick={() => showOnlyResolvedErrors()}
						/>
					</span>
				),
				className: styles["table-headers--width-10"],
			},
			{
				name: translate("consistency-check.errors.th.actions"),
				className: styles["table-headers--width-29"],
			},
		]);
	}, [showAllErrors, showOnlyResolvedErrors]);
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					{headers.map((header) => (
						<CustomTableCell key={header.name} className={`${styles.header} ${header.className}`}>
							{header.name}
						</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody isLoading={isLoading}>
				{Array.isArray(contents) &&
					contents.map((row) => (
						<CustomTableRow key={row.id} hover role="checkbox" tabIndex={-1}>
							<CustomTableCell>
								<CustomTooltip
									enterDelay={250}
									title={
										<>
											<Typography color="inherit" translate="no">
												{translateEnumRowType(row.type)}
											</Typography>
											{row.type && row.type.includes("CONTENT") ? row.description : null}
										</>
									}
								>
									<div>{row.description}</div>
								</CustomTooltip>
							</CustomTableCell>
							<CustomTableCell>
								{row.type && row.type.includes("CONTENT") ? row.content.content : row.description}
							</CustomTableCell>
							<CustomTableCell>{row.pageNumber}</CustomTableCell>
							<CustomTableCell>
								<Chip label={translateEnumStatus(row.status)} size="small" translate="no" />
							</CustomTableCell>
							<CustomTableCell>
								{isSegFeatureEnabled(SegFlags.SMART_REVIEW) && (
									<CustomIconButtonLink
										icon={icon.faTable}
										state={{ previous: location.pathname }}
										to={NavUtils.goToSmartReview({
											projectId,
											documentId: selectedDocument.docId,
											pageStart: row.pageNumber,
										})}
										tooltip={translate("common:navigation.go-to-my-review")}
										variant="contained"
									/>
								)}
								<CustomIconButton
									disabled={row.status === "RESOLVED"}
									icon={icon.faBan}
									tooltip={translate("consistency-check.errors.btn.ignore-error")}
									variant="contained"
									onClick={() => {
										handleIgnoreConfirmation(row);
									}}
								/>
								<CustomIconButton
									icon={icon.faCheck}
									tooltip={translate("consistency-check.errors.btn.resolve-error")}
									variant="contained"
									onClick={() => {
										handleResolveConfirmation(row);
									}}
								/>
								{row.actions &&
									Array.isArray(row.actions) &&
									row.actions.map((action) => (
										<React.Fragment key={action}>
											{action === "MOVE" ? (
												<CustomIconButton
													icon={icon.faAlignLeft}
													tooltip={translate(
														"consistency-check.errors.btn.go-to-toc-redressment"
													)}
													variant="contained"
													onClick={() => showTocRedressment(selectedDocument)}
												/>
											) : (
												<CustomIconButton
													disabled={row.status === "RESOLVED"}
													iconClassName={styles.charIcon}
													label={action.charAt(0)}
													tooltip={translate(
														`consistency-check.errors.actions.${action
															?.toLowerCase()
															.replaceAll("_", "-")}`
													)}
													variant="contained"
													onClick={() =>
														handleClickOnAction({
															action,
															row,
														})
													}
												/>
											)}
										</React.Fragment>
									))}
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
