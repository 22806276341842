import React, { useEffect, useState } from "react";
import { ProjectTeamService } from "../../../../api";
import {
	createNotification,
	CustomButton,
	IconComponent,
	icon,
	SidePanel,
	SidePanelContent,
	CustomIconButton,
} from "../../../../common/components";
import { useApi } from "../../../../common/hooks";
import { translate } from "../../../../common/providers";
import PrivacyGrid from "../privacy-grid/PrivacyGrid";
import styles from "./PrivacySidePanel.module.css";

export default function PrivacySidePanel({ companyId, isMyCompanyProjectLeader = false, open, onClose, projectId }) {
	const [modifications, setModifications] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [closeGridBanner, setCloseGridBanner] = useState(true);
	const { call: updatePrivacy } = useApi(ProjectTeamService.updateCompaniesPrivacies);
	const { call: getPrivacy, isLoading } = useApi(ProjectTeamService.getCompanyPrivacies);
	useEffect(() => {
		if (!open) {
			return;
		}
		getPrivacy({ projectId })
			.then((data) => {
				setCompanies(data.map((company) => ({ ...company.companyFrom, companyTo: company.companyTo })));
			})
			.catch((err) => {
				console.error(err);
			});
	}, [projectId, getPrivacy, open]);
	const handleClose = () => {
		setModifications([]);
		setCompanies([]);
		setCloseGridBanner(true);
		onClose();
	};
	const handleApply = () => {
		updatePrivacy({ projectId }, modifications)
			.then(() => {
				createNotification({
					type: "success",
					message: translate("team-management.privacy-side-panel.update-message.success"),
				});
				handleClose();
			})
			.catch((err) => console.error(err));
	};
	const updateModifications = (companyFrom, companyTo, checked) => {
		let newModifications = [...modifications];
		const modification = newModifications.find((modif) => modif.companyFrom === companyFrom.id);
		if (!modification) {
			newModifications.push({
				companyFrom: companyFrom.id,
				companyToPrivacy: [{ companyId: companyTo.id, privacy: !checked }],
			});
			setModifications(newModifications);
			return;
		}
		const newCompanyToPrivacy = modification.companyToPrivacy.filter((cnyTo) => cnyTo.companyId !== companyTo.id);
		if (newCompanyToPrivacy.length !== modification.companyToPrivacy.length) {
			if (newCompanyToPrivacy.length === 0) {
				newModifications = newModifications.filter((modif) => modif.companyFrom !== companyFrom.id);
			} else {
				modification.companyToPrivacy = newCompanyToPrivacy;
			}
		} else {
			modification.companyToPrivacy.push({
				companyId: companyTo.id,
				privacy: !checked,
			});
		}
		setModifications(newModifications);
	};
	const updateCompanies = (companyFrom, companyTo, checked) => {
		const privacyCompanyFrom = companies.find((company) => company.id === companyFrom.id);
		if (!privacyCompanyFrom) {
			return;
		}
		setCompanies((prev) => {
			const privacyCompanyTo = privacyCompanyFrom.companyTo.find((company) => company.id === companyTo.id);
			if (privacyCompanyTo) {
				privacyCompanyTo.privacy = !checked;
			}
			return prev;
		});
	};
	const handleChangePrivacy = (event, { companyFrom, companyTo }) => {
		const { checked } = event.target;
		updateCompanies(companyFrom, companyTo, checked);
		updateModifications(companyFrom, companyTo, checked);
	};
	return (
		<SidePanel open={open} size={65} onClose={handleClose}>
			<SidePanelContent
				secondaryActions={
					<CustomButton
						color="primary"
						disabled={modifications.length === 0}
						variant="contained"
						onClick={handleApply}
					>
						{translate("common:btn.apply")}
					</CustomButton>
				}
				title={translate("team-management.privacy-side-panel.title")}
				onClose={handleClose}
			>
				<div className={styles.panelContent}>
					<span className={styles.panelContent__description}>
						{translate("team-management.privacy-side-panel.description")}
					</span>
					{closeGridBanner && (
						<div className={styles.panelContent__gridDescription}>
							<div className={styles.gridDescription__rightSide}>
								<IconComponent
									className={styles.gridDescription__icon}
									color="var(--color-blue)"
									icon={icon.faInfoCircle}
									size="sm"
								/>
								<div>
									<span className={styles.gridDescription__title}>
										{translate("team-management.privacy-side-panel.grid-description.title")}
									</span>
									<br />
									<span className={styles.gridDescription__text}>
										{translate("team-management.privacy-side-panel.grid-description.text")}
									</span>
								</div>
							</div>
							<div className={styles.gridDescription__leftSide}>
								<CustomIconButton
									icon={icon.faTimes}
									iconClassName={styles.gridDescription__closeBtn}
									size="sm"
									onClick={() => setCloseGridBanner(false)}
								/>
							</div>
						</div>
					)}
					<div className={styles.panelContent__gridContainer}>
						<PrivacyGrid
							companies={companies}
							companyId={companyId}
							isLoading={isLoading}
							isMyCompanyProjectLeader={isMyCompanyProjectLeader}
							onChangePrivacy={handleChangePrivacy}
						/>
					</div>
				</div>
			</SidePanelContent>
		</SidePanel>
	);
}
