import React from "react";
import { TasksTabView } from "./components";

export default function TasksTab({ informationId = 0, onActive = null, pageStart = 0, selectedMode }) {
	return (
		<TasksTabView
			informationId={informationId}
			pageStart={pageStart}
			selectedMode={selectedMode}
			onActive={onActive}
		/>
	);
}
