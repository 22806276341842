import React, { useState, useEffect } from "react";
import { Fab, CircularProgress } from "@mui/material";
import { CustomButton, icon, IconComponent } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import styles from "./RedressToc.module.css";
import { usePrevious } from "../../../../common/hooks";

export default function RedressToc({ handleMove, loading, REDRESS_ACTION, tocList, movingToc, maxMode }) {
	const [selectedToc, setSelectedToc] = useState(null);
	const prevToc = usePrevious(tocList);
	const [selectedKey, setSelectedKey] = useState("");

	const handleMoveContent = (action) => {
		if (selectedToc != null) {
			let foundNode = tocList.find((t) => t.id === selectedToc.id);
			const findNode = (parents, id) => {
				if (!Array.isArray(parents)) {
					return;
				}
				for (const parent of parents) {
					if (parent.id === id) {
						foundNode = parent;
						break;
					}
					findNode(parent.subClauses, id);
				}
			};
			if (!foundNode) {
				findNode(tocList, selectedToc.id);
			}
			const tmp = { ...foundNode };
			if (action === REDRESS_ACTION[1]) {
				++foundNode.level;
			} else {
				--foundNode.level;
			}
			tmp.level = foundNode.level;
			setSelectedToc(tmp);
			handleMove(action, foundNode);
		}
	};

	useEffect(() => {
		if (prevToc !== tocList) {
			setSelectedKey("");
		}
	}, [tocList, prevToc]);

	const handleClick = (toc, key) => {
		if (!toc || key === null) {
			return;
		}
		if (selectedKey === key) {
			setSelectedKey("");
			setSelectedToc(null);
		} else {
			setSelectedKey(key);
			setSelectedToc(toc);
		}
	};

	const runSubClauses = (subClauses, parentKey, level) => {
		if (subClauses === null || !Array.isArray(subClauses) || subClauses.length < 1) {
			return null;
		}
		return subClauses.map((toc) => {
			const childKey = `${parentKey + toc.id}-`;
			if (!toc.level) {
				toc.level = level;
			}
			return (
				<div key={childKey} className={styles.container}>
					<CustomButton
						className={styles.toc__button}
						color={
							selectedKey && (childKey.startsWith(selectedKey) || selectedKey === parentKey)
								? "primary"
								: "secondary"
						}
						style={{ paddingLeft: `${toc.level * 10}px` }}
						variant={
							selectedKey && (childKey.startsWith(selectedKey) || selectedKey === parentKey)
								? "contained"
								: "outlined"
						}
						onClick={() => handleClick(toc, childKey)}
					>
						{toc.content}
					</CustomButton>
					{runSubClauses(toc.subClauses, childKey, level + 1)}
				</div>
			);
		});
	};

	return (
		<div className={styles.container}>
			{loading ? (
				<div style={{ display: "flex", height: "inherit" }}>
					<CircularProgress size={60} style={{ margin: "auto" }} />
				</div>
			) : (
				<>
					<Fab
						aria-label="redress-left"
						className={(maxMode && styles["fabLeft--maxMode"]) || styles.fabLeft}
						color="primary"
						disabled={!selectedKey || selectedToc.level === 1 || movingToc}
						label={translate("consistency-check.redress-toc.btn.move-left")}
						size="medium"
						onClick={() => handleMoveContent(REDRESS_ACTION[0])}
					>
						<IconComponent
							color={((!selectedKey || movingToc) && "var(--color-light-grey-2)") || "var(--color-white)"}
							icon={icon.faCaretLeft}
						/>
					</Fab>

					<div className={styles.toc}>
						{Array.isArray(tocList) &&
							tocList.map((toc) => {
								const key = `root${toc.id}-`;
								if (!toc.level) {
									toc.level = 1;
								}
								return (
									<div key={key} className={styles.container}>
										<CustomButton
											className={styles.toc__button}
											color={selectedKey && selectedKey === key ? "primary" : "secondary"}
											style={{ paddingLeft: `${toc.level * 10}px` }}
											variant={selectedKey && selectedKey === key ? "contained" : "text"}
											onClick={() => handleClick(toc, key)}
										>
											{toc.content}
										</CustomButton>
										{runSubClauses(toc.subClauses, key, toc.level + 1)}
									</div>
								);
							})}
					</div>

					<Fab
						aria-label="redress-right"
						className={styles.fabRight}
						color="primary"
						disabled={!selectedKey || movingToc}
						label={translate("consistency-check.redress-toc.btn.move-right")}
						size="medium"
						onClick={() => handleMoveContent(REDRESS_ACTION[1])}
					>
						<IconComponent
							color={((!selectedKey || movingToc) && "var(--color-light-grey-2)") || "var(--color-white)"}
							icon={icon.faCaretRight}
						/>
					</Fab>
				</>
			)}
		</div>
	);
}
