import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService } from "../../../../api";
import { icon, IconComponent, LabeledCheckbox, SearchInput, SidePanelHeader } from "../../../../common/components";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../common/providers";
import { isDeepEqual, isNonEmptyObject } from "../../../../common/utils";
import { DocumentFilter, Norm, NormDetailsSidePanel, StatusFilter } from "../../components";
import NormsTable from "./norms-table/NormsTable";
import { setOrderBy, setDir } from "../../slice/normsCenterSlice";
import styles from "./Norms.module.css";

export default function Norms({
	onChangeFavoriteStatus,
	onMoveToPendingDetections,
	onOpenAddNormPanel,
	onPageChange,
	onRowsPerPageChange,
	onSearch,
}) {
	const dispatch = useDispatch();
	const norms = useSelector(({ normsCenter }) => normsCenter.normsInProject);
	const totalSearchResult = useSelector(({ normsCenter }) => normsCenter.totalNormsResult);
	const tempTotalSearchResult = useSelector(({ normsCenter }) => normsCenter.tempTotalNormsResult);
	const isLoading = useSelector(({ normsCenter }) => normsCenter.isLoadingNorms);
	const page = useSelector(({ normsCenter }) => normsCenter.page);
	const pageLimit = useSelector(({ normsCenter }) => normsCenter.limit);
	const [accessTab, setAccessTab] = useState(0);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedNewVersion, setSelectedNewVersion] = useState(null);
	const [filters, setFilters] = useState({ name: "", documents: [], newVersion: false, favorite: false, status: "" });
	const cancelTokenSourceRef = useRef(null);
	const orderBy = useSelector(({ normsCenter }) => normsCenter.orderBy);
	const dir = useSelector(({ normsCenter }) => normsCenter.dir);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(
		() =>
			setSelectedRow((prev) => {
				const row = (prev && norms.find((n) => n.normId === prev.normId)) || null;
				setAccessTab(0);
				return row;
			}),
		[norms]
	);

	const handleSearch = ({ filterKey = "", searchFilters = null, searchContent = null, tempFilters = null }) => {
		if (
			searchFilters ||
			typeof searchContent === "string" ||
			(filterKey && !isDeepEqual(tempFilters[filterKey], filters[filterKey]))
		) {
			const payloadFilters = searchFilters ?? tempFilters;
			onSearch(payloadFilters, true);
			setFilters({ ...payloadFilters });
		}
	};

	const handleChangeNameFilter = (e) => {
		const newFilters = { ...filters, name: e.target.value };
		setFilters(newFilters);
		handleSearch({ searchFilters: newFilters });
	};
	const handleClearNameFilter = () => {
		const newFilters = { ...filters, name: "" };
		setFilters(newFilters);
		handleSearch({ searchFilters: newFilters });
	};

	const handleChangeNewVersionFilter = (e) => {
		const newFilters = { ...filters, newVersion: e.target.checked };
		setFilters(newFilters);
		handleSearch({ searchFilters: newFilters });
	};
	const handleChangeFavoriteFilter = (e) => {
		const newFilters = { ...filters, favorite: e.target.checked };
		setFilters(newFilters);
		handleSearch({ searchFilters: newFilters });
	};
	const handleSelectRow = (row) => setSelectedRow(row);
	const handleCloseDetails = () => {
		setSelectedRow();
		setAccessTab(0);
	};
	const handleClickNewVersion = (row) => setSelectedNewVersion(row);
	const handleCloseNewVersion = () => setSelectedNewVersion();
	const handleClickDetections = (row) => {
		setAccessTab(1);
		handleSelectRow(row);
	};

	const resetFilters = () => {
		const newFilters = { name: "", documents: [], newVersion: false, favorite: false, status: "" };
		setFilters(newFilters);
		handleSearch({ searchFilters: newFilters });
	};
	const handleSort = (property) => {
		const isAsc = orderBy === property && dir === "ASC";
		dispatch(setOrderBy(property));
		dispatch(setDir(isAsc ? "DESC" : "ASC"));
	};
	return (
		<>
			<SidePanelHeader>
				<SearchInput
					label={translate("norms-center.tab.norms.filter")}
					value={(filters && filters.name) || ""}
					onChange={handleChangeNameFilter}
					onClearSearch={handleClearNameFilter}
				/>
				{isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS) && (
					<DocumentFilter
						filters={filters}
						isLoading={isLoading}
						tempTotalSearchResult={tempTotalSearchResult}
						onDirectSearch={onSearch}
						onSearch={handleSearch}
					/>
				)}
				<StatusFilter
					filters={filters}
					isLoading={isLoading}
					tempTotalSearchResult={tempTotalSearchResult}
					onDirectSearch={onSearch}
					onSearch={handleSearch}
				/>
				<LabeledCheckbox
					checked={(filters && filters.newVersion) || false}
					label={
						<>
							<IconComponent icon={icon.faCalendarCheck} />{" "}
							{translate("norms-center.tab.norms.check.version")}
						</>
					}
					onChange={handleChangeNewVersionFilter}
				/>
				<LabeledCheckbox
					checked={(filters && filters.favorite) || false}
					label={
						<>
							<IconComponent icon={icon.faStar} /> {translate("norms-center.tab.norms.check.favorite")}
						</>
					}
					onChange={handleChangeFavoriteFilter}
				/>
				<div className={styles.norms__spacer} />
				<span className={styles.norms__searchResult}>
					{translate(
						totalSearchResult > 1 ? "norms-center.tab.search-results" : "norms-center.tab.search-result",
						{ totalSearchResult }
					)}
				</span>
			</SidePanelHeader>
			<NormsTable
				hasFilters={
					isNonEmptyObject(filters.documents) ||
					filters.newVersion ||
					filters.favorite ||
					filters.name.length > 0 ||
					filters.status
				}
				isLoading={isLoading}
				norms={norms}
				orderBy={orderBy}
				orderDirection={dir?.toLowerCase()}
				page={page}
				rowsPerPageLimit={pageLimit}
				totalElements={totalSearchResult}
				onChangeFavoriteStatus={onChangeFavoriteStatus}
				onClickDetections={handleClickDetections}
				onClickNewVersion={handleClickNewVersion}
				onClickPending={onMoveToPendingDetections}
				onOpenAddNormPanel={onOpenAddNormPanel}
				onPageChange={onPageChange}
				onResetFilters={resetFilters}
				onRowsPerPageChange={onRowsPerPageChange}
				onSelectRow={handleSelectRow}
				onSort={handleSort}
			/>
			<Norm accessTab={accessTab || 0} norm={selectedRow} open={!!selectedRow} onClose={handleCloseDetails} />
			<NormDetailsSidePanel
				norm={selectedNewVersion}
				normId={selectedNewVersion?.newVersionId || 0}
				normName={selectedNewVersion?.newVersion || ""}
				open={!!selectedNewVersion}
				onClose={handleCloseNewVersion}
			/>
		</>
	);
}
