import PositionsTab from "./positions-tab/PositionsTab";
import CommentsTab from "./comments-tab/CommentsTab";
import TasksTab from "./tasks-tab/TasksTab";
import QuestionsTab from "./questions-tab/QuestionsTab";
import DeliverablesTab from "./deliverables-tab/DeliverablesTab";
import CategoriesTab from "./categories-tab/CategoriesTab";
import VersioningTab from "./versioning-tab/VersioningTab";
import HistoryTab from "./history-tab/HistoryTab";
import SimilarContentTab from "./similar-content-tab/SimilarContentTab";
import LinkTab from "./link-tab/LinkTab";

const TABS = {
	PositionsTab,
	CommentsTab,
	TasksTab,
	QuestionsTab,
	DeliverablesTab,
	CategoriesTab,
	VersioningTab,
	SimilarContentTab,
	LinkTab,
	HistoryTab,
};

export default TABS;
