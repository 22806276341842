import { TextField } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import styles from "./LimitedTextField.module.css";

const LimitedTextField = ({
	autoFocus = false,
	className = "",
	color = "primary",
	containerClassName = "",
	disabled = false,
	error = false,
	fullWidth = false,
	InputProps = { className: styles.inputProps },
	label = "",
	maxLength = 256,
	multiline = false,
	onBlur,
	onChange,
	onFocus,
	onKeyPress,
	placeholder = "",
	required = false,
	rows,
	size = "sm",
	value = "",
	variant = "outlined",
	"data-testid": dataTestId = null,
}) => {
	const handleChange = (e) => {
		if (e.target.value.length <= maxLength) {
			onChange(e.target.value);
		} else {
			onChange(e.target.value.substring(0, maxLength));
		}
	};
	return (
		<span className={`${(fullWidth && styles["wrapper--fullWidth"]) || ""} ${containerClassName}`}>
			<TextField
				autoFocus={autoFocus}
				className={`${styles.tf} ${className}`}
				color={color}
				data-size={size}
				data-testid={dataTestId}
				disabled={disabled}
				error={error}
				fullWidth={fullWidth}
				InputProps={InputProps}
				label={label}
				multiline={multiline}
				placeholder={placeholder}
				required={required}
				rows={rows}
				sx={{
					"& label.Mui-focused": {
						transform:
							size === "sm" ? "translate(12px, 1px) scale(0.75)" : "translate(15px, -9px) scale(0.75)",
					},
				}}
				value={value}
				variant={variant}
				onBlur={onBlur}
				onChange={handleChange}
				onFocus={onFocus}
				onKeyPress={onKeyPress}
			/>
			<div className={styles.helperText}>
				{value.length}/{maxLength}
			</div>
		</span>
	);
};
LimitedTextField.propTypes = {
	size: PropTypes.oneOf(["sm", "md"]),
};
LimitedTextField.defaultProps = {
	size: "sm",
};
export default LimitedTextField;
