import React, { useEffect, useMemo, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import {
	adjustFiltersFromPayload,
	CustomButton,
	CustomIconButton,
	icon,
	IconComponent,
	InfiniteListSelector,
} from "../../../../common/components";
import styles from "./SearchTemplateDrawer.module.css";
import { ApiService, BackOfficeService, SearchCardTemplateService } from "../../../../api";
import { hasPermission, Permissions, translate, translateEnumTemplatetype } from "../../../../common/providers";
import { useApi } from "../../../../common/hooks";
import SearchTemplateMenu from "./menu/SearchTemplateMenu";
import { debounce } from "../../../../common/utils";
import { OPERATORS } from "../../../../common/constants";

const debouncedFunction = debounce((func) => func());
export default function SearchTemplateDrawer({
	search,
	setCurrentSearch,
	setFilters,
	setIsLoadingSearch,
	setIsUpdating,
	slice,
	VISIBILITY_CONSTANTS,
}) {
	const dispatch = useDispatch();
	const currentSearchId = useSelector((state) => state[slice].currentSearch?.id || 0);
	const selectedProject = useSelector((state) => state[slice].selectedProject);
	const isDsi = useSelector((state) => state[slice].isDsi);
	const isUpdating = useSelector((state) => state[slice].isUpdating);
	const [searchValue, setSearchValue] = useState("");
	const tokenSrcRef = useRef(null);
	const [openedMenuIndex, setOpenedMenuIndex] = useState(null);
	const [searchValueDebounced, setSearchValueDebounced] = useState("");
	const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);
	const [authorsRequest, setAuthorsRequest] = useState(null);
	const [authorInput, setAuthorInput] = useState(null);
	const visibilityConstantKeys = useMemo(() => Object.keys(VISIBILITY_CONSTANTS), [VISIBILITY_CONSTANTS]);
	const companyId = useSelector(({ context }) => context.company?.id);
	const companyName = useSelector(({ context }) => context.company?.name);
	const [searchParams] = useSearchParams();
	const [authorSearch, setAuthorSearch] = useState("");
	const menus = useMemo(() => {
		if (!hasPermission([Permissions.COMPANY_ADMIN, Permissions.COMPANY_MANAGER]) && !isDsi) {
			delete VISIBILITY_CONSTANTS.AITENDERS;
		}
		return Object.values(VISIBILITY_CONSTANTS)
			.filter((type) => !isDsi || type === VISIBILITY_CONSTANTS.AITENDERS)
			.map((type) => ({
				key: type,
				title: translateEnumTemplatetype(type, { companyName }),
				request: ({ limit: reqLimit, page }) =>
					(isDsi ? BackOfficeService : SearchCardTemplateService).getTemplates(
						{ page, limit: reqLimit },
						{
							filters: {
								templateOwner: type,
								name: searchValueDebounced,
								users:
									authorInput?.id && type !== VISIBILITY_CONSTANTS[visibilityConstantKeys[0]]
										? [authorInput.id]
										: undefined,
							},
						},
						tokenSrcRef.current.token
					),
			}));
	}, [searchValueDebounced, authorInput, isDsi, companyName, VISIBILITY_CONSTANTS, visibilityConstantKeys]);
	const { call: getTemplateDetails, cancel: cancelGetTemplateDetails } = useApi(
		(isDsi ? BackOfficeService : SearchCardTemplateService).getTemplateDetails
	);

	useEffect(() => {
		tokenSrcRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(tokenSrcRef.current);
		};
	}, []);

	useEffect(() => {
		const newInitialTemplateId = searchParams.get("templateId") || 0;
		if (newInitialTemplateId > 0) {
			SearchCardTemplateService.getTemplateDetails(
				{ searchTemplateId: newInitialTemplateId },
				tokenSrcRef.current.token
			)
				.then((data) => {
					batch(() => {
						dispatch(setIsLoadingSearch(false));
						dispatch(
							search({
								filters: data.filters,
								page: 0,
								limit: 25,
								token: tokenSrcRef.current.token,
							})
						);
						dispatch(setFilters(adjustFiltersFromPayload(data.filters)));
						dispatch(
							setCurrentSearch({
								currentSearch: {
									isNew: false,
									...data,
								},
								isFilterTocOut: data.filters.isFilterTocOut,
								filters: adjustFiltersFromPayload(data.filters),
							})
						);
					});
				})
				.catch((err) => {
					console.error(err);
					dispatch(setIsLoadingSearch(false));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setAuthorsRequest(
			() =>
				({ limit: authorsLimit, page }) =>
					SearchCardTemplateService.getAuthors(
						{
							companyId,
						},
						{ limit: authorsLimit, page, filter: authorSearch },
						tokenSrcRef.current.token
					)
		);
	}, [selectedProject, companyId, authorSearch]);
	const handleNewSearch = () => {
		ApiService.cancelTokens(tokenSrcRef.current);
		tokenSrcRef.current = ApiService.getCancelTokenSource();
		batch(() => {
			dispatch(
				setCurrentSearch({
					currentSearch: {
						isNew: true,
						id: 0,
						creatorId: 0,
						title: "",
						description: "",
						visibility: VISIBILITY_CONSTANTS[visibilityConstantKeys[0]],
						listKey: "",
					},
					filters: { separator: OPERATORS.AND, Type: "TemplateSearchFilterDTO" },
					isFilterTocOut: false,
					totalMatches: 0,
					page: 0,
				})
			);
			dispatch(setIsLoadingSearch(true));
		});
	};
	const handleClickSearch = (key, template) => {
		cancelGetTemplateDetails();
		batch(() => {
			dispatch(setIsLoadingSearch(true));
			dispatch(
				setCurrentSearch({
					currentSearch: template,
				})
			);
		});
		getTemplateDetails({ searchTemplateId: template.id })
			.then((data) => {
				batch(() => {
					dispatch(setIsLoadingSearch(false));
					dispatch(setFilters(adjustFiltersFromPayload(data.filters)));
					dispatch(
						setCurrentSearch({
							currentSearch: {
								isNew: false,
								listKey: key,
								...template,
							},
							filters: adjustFiltersFromPayload(data.filters),
							isFilterTocOut: data.filters.isFilterTocOut,
						})
					);
				});
			})
			.catch((err) => {
				console.error(err);
				dispatch(setIsLoadingSearch(false));
			});
	};
	const handleRemoveSearch = ({ id }) => {
		dispatch(setIsUpdating(true));
		(isDsi ? BackOfficeService : SearchCardTemplateService)
			.deleteTemplate({ templateId: id }, tokenSrcRef.current.token)
			.then(() => {
				if (id === currentSearchId) {
					handleNewSearch();
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				dispatch(setIsUpdating(false));
			});
	};
	const handleOpenMenu = (index) => {
		setOpenedMenuIndex((prev) => (prev === index ? null : index));
	};
	const handleResetSearchText = () => {
		setSearchValue("");
		setSearchValueDebounced("");
	};
	const handleChangeSearchText = (event) => {
		const { value } = event.target;
		setSearchValue(value);
		setIsTemplatesLoading(true);
		debouncedFunction(() => {
			setSearchValueDebounced(value);
			setIsTemplatesLoading(false);
		});
	};
	const handleChangeAuthorInput = (newAuthor) => {
		setAuthorInput(newAuthor);
	};
	const endAdornmentRenderer = (isOpened) => (
		<InputAdornment position="end">
			<IconComponent color="var(--color-dark-grey-1)" icon={isOpened ? icon.faCaretUp : icon.faCaretDown} />
		</InputAdornment>
	);
	// To think about multiple selection
	const selectorRowRenderer = ({ loadedRow, onClick }) => (
		<MenuItem key={loadedRow?.id} onClick={() => onClick(loadedRow)}>
			<span>
				{loadedRow?.displayName ||
					loadedRow?.email ||
					translate("search-template.add-template-panel.author-select.author")}
			</span>
		</MenuItem>
	);

	const handleSetAuthorSearch = (e) => {
		setAuthorSearch(e.target.value);
	};
	const handleResetAuthorSearch = () => {
		setAuthorSearch("");
	};

	const isNew = !currentSearchId || currentSearchId === 0;
	return (
		<>
			<div className={styles.drawer}>
				<CustomButton
					fullWidth
					className={` ${styles.newSearchButton}`}
					disabled={isNew}
					startIcon={icon.faSearch}
					variant="outlined"
					onClick={handleNewSearch}
				>
					{translate("search-template.drawer.new-template")}
				</CustomButton>
				<div className={styles.searchFieldsContainer}>
					<TextField
						className={`${styles.textField}`}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{!searchValue && (
										<IconComponent color="var(--color-light-grey-2)" icon={icon.faSearch} />
									)}
									{searchValue && (
										<CustomIconButton
											className={styles.resetButton}
											icon={icon.faTimes}
											onClick={handleResetSearchText}
										/>
									)}
								</InputAdornment>
							),
							style: {
								fontSize: "var(--font-size-sm)",
								height: 32,
							},
						}}
						placeholder={translate("common:btn.search")}
						size="small"
						value={searchValue}
						variant="outlined"
						onChange={handleChangeSearchText}
					/>
					{!isDsi && slice !== "companySearchTemplate" && (
						<InfiniteListSelector
							nullable
							classes={{ input: styles.infiniteSelector }}
							classNameInfiniteList={styles.infiniteSelector__list}
							disabled={openedMenuIndex !== 1 || !selectedProject}
							endAdornmentRenderer={endAdornmentRenderer}
							infiniteListRequest={authorsRequest}
							rowRenderer={selectorRowRenderer}
							searchValue={authorSearch}
							value={
								authorInput?.displayName ||
								translate("search-template.add-template-panel.author-select.author")
							}
							onChangeSearchText={handleSetAuthorSearch}
							onClick={handleChangeAuthorInput}
							onResetSearchText={handleResetAuthorSearch}
						/>
					)}
				</div>
				{menus.map((menu, index) => {
					const isEditable = isDsi || menu.key === VISIBILITY_CONSTANTS[visibilityConstantKeys[0]];
					return (
						<SearchTemplateMenu
							key={menu.key}
							hasAuthorsSearch={!isDsi && slice !== "companySearchTemplate"}
							infiniteRequest={isUpdating ? null : menu.request}
							isLoading={isTemplatesLoading}
							menusCount={menus.length}
							open={openedMenuIndex === index}
							setIsUpdating={setIsUpdating}
							slice={slice}
							templateOwner={menu.key}
							title={menu.title}
							onClickItem={(parameters) => handleClickSearch(menu.key, parameters)}
							onClickOpen={() => handleOpenMenu(index)}
							onRemove={(isEditable && handleRemoveSearch) || null}
						/>
					);
				})}
			</div>
		</>
	);
}
