import React from "react";
import { Checkbox } from "@mui/material";
import {
	CustomIconButton,
	CustomTableBody,
	CustomTableCell,
	CustomTableRow,
	CustomTooltip,
	icon,
	IconComponent,
} from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import { displayTrustLevel } from "../../../../../utils/utils";
import styles from "./SimilarDetectionBody.module.css";

export default function SimilarDetectionBody({
	checked,
	detections,
	emptyState,
	isLoading,
	onCheckRow,
	onClickMatchedNorms,
	onDiscardNorm,
	onDisplayDocument,
	onValidateNorm,
}) {
	return (
		<CustomTableBody emptyState={emptyState} isLoading={isLoading}>
			{Array.isArray(detections) &&
				detections.map((row) => (
					<CustomTableRow key={row.pendingNormId} hover>
						<CustomTableCell>
							<Checkbox
								checked={checked.some((r) => r.pendingNormId === row.pendingNormId)}
								color="primary"
								onChange={(e) => {
									onCheckRow(e.target.checked, row);
								}}
								onClick={(e) => e.stopPropagation()}
							/>
						</CustomTableCell>
						<CustomTableCell>
							<CustomTooltip title={row.docFilename}>
								<span>{row.docName}</span>
							</CustomTooltip>
						</CustomTableCell>
						<CustomTableCell>{row.page}</CustomTableCell>
						<CustomTableCell>
							<CustomIconButton
								icon={icon.faFileAlt}
								tooltip={translate("norms-center.tab.detections.table.display-document.tooltip")}
								variant="contained"
								onClick={(e) => {
									e.stopPropagation();
									onDisplayDocument(row);
								}}
							/>
						</CustomTableCell>
						<CustomTableCell>
							<CustomTooltip title={row.matchedNormName}>
								<span>{row.matchedNormName}</span>
							</CustomTooltip>
						</CustomTableCell>
						<CustomTableCell>
							<CustomTooltip title={translate("norms-center.tab.detections.table.matched-norm.tooltip")}>
								<span
									className={styles.similarDetection__matchedNorm}
									role="presentation"
									onClick={(e) => {
										e.stopPropagation();
										onClickMatchedNorms(row);
									}}
								>
									<IconComponent className={styles.matchedIcon} icon={icon.faPen} />
									{row.matchedNormName}
								</span>
							</CustomTooltip>
						</CustomTableCell>
						<CustomTableCell>{displayTrustLevel(row.trust)}</CustomTableCell>
						<CustomTableCell>
							<CustomIconButton
								icon={icon.faCheck}
								tooltip={translate("norms-center.tab.detections.table.valid.tooltip")}
								variant="contained"
								onClick={(e) => {
									e.stopPropagation();
									onValidateNorm(row.pendingNormId);
								}}
							/>
						</CustomTableCell>
						<CustomTableCell>
							<CustomIconButton
								icon={icon.faTimes}
								tooltip={translate("norms-center.tab.detections.table.discard.tooltip")}
								variant="contained"
								onClick={(e) => {
									e.stopPropagation();
									onDiscardNorm(row.pendingNormId);
								}}
							/>
						</CustomTableCell>
					</CustomTableRow>
				))}
		</CustomTableBody>
	);
}
