import React from "react";
import { PositionsTabView } from "./components";

export default function PositionsTab({
	disableEditing,
	informationId,
	isRequirementAdditional,
	myDocInfo,
	pageStart,
	selectedMode,
}) {
	return (
		<PositionsTabView
			disableEditing={disableEditing}
			informationId={informationId}
			isRequirementAdditional={isRequirementAdditional}
			myDocInfo={myDocInfo}
			pageStart={pageStart}
			selectedMode={selectedMode}
		/>
	);
}
