import React from "react";
import AutocompleteTextField from "./AutocompleteTextField";
import styles from "./LimitedAutocompleteTextField.module.css";

const LimitedAutocompleteTextField = ({
	autoFocus,
	data,
	placeholder,
	onBlur,
	onChange,
	onFocus,
	maxLength = 256,
	className = "",
	displayLength = true,
	helperTextClassName,
	rows,
	size,
	trigger,
	value,
}) => {
	const handleChange = (e) => {
		if (e.target.value.length <= maxLength) {
			onChange(e.target.value);
		} else {
			onChange(e.target.value.substring(0, maxLength));
		}
	};
	return (
		<span className={className}>
			<AutocompleteTextField
				autoFocus={autoFocus}
				data={data}
				maxLength={maxLength}
				placeholder={placeholder}
				rows={rows}
				size={size}
				trigger={trigger}
				value={value}
				onBlur={onBlur}
				onChange={handleChange}
				onFocus={onFocus}
			/>
			<div className={`${styles.helperText} ${helperTextClassName}`}>
				{displayLength && (
					<span>
						{value.length}/{maxLength}
					</span>
				)}
			</div>
		</span>
	);
};

export default LimitedAutocompleteTextField;
