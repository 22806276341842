import React, { useRef } from "react";
import { CustomButton, icon, IconComponent, DropDown, CustomTooltip } from "../../../common/components";
import styles from "./DropDownMenu.module.css";

const DropDownMenu = ({
	autoTranslate = "yes",
	bold = false,
	children = null,
	disabled = false,
	hovered = false,
	menuName,
	onClose = null,
	onOpen = null,
	onOpenHover = null,
	open = false,
	selected = false,
	title = "",
	"data-testid": dataTestId = null,
}) => {
	const anchorRef = useRef(null);
	const handleOpenHover = () => {
		onOpenHover(menuName);
	};
	const handleOpen = () => {
		onOpen(menuName);
	};
	return (
		<>
			<CustomButton
				ref={anchorRef}
				className={`${bold && styles["dropDownMenu--bold"]} ${open && styles["dropDown--contained"]} ${
					styles.dropDownMenu
				} ${selected && styles["dropDownMenu--selected"]}
				`}
				color="secondary"
				data-testid={dataTestId}
				disabled={disabled}
				onClick={handleOpen}
				onMouseOver={handleOpenHover}
			>
				<CustomTooltip showWhenOverflow placement="bottom-start" title={title || ""} translate={autoTranslate}>
					<span className={styles["dropDownMenu__text--crop"]} translate={autoTranslate}>
						{title}
					</span>
				</CustomTooltip>
				{!disabled && (
					<IconComponent
						className={`${styles.dropDownMenu__icon} ${open && styles["dropDownMenu__icon--open"]}`}
						color={open ? "var(--color-white)" : "var(--color-black)"}
						icon={open ? icon.faChevronUp : icon.faChevronDown}
						size="xs"
					/>
				)}
			</CustomButton>
			<DropDown ref={anchorRef} open={open || hovered} onClose={onClose}>
				{children}
			</DropDown>
		</>
	);
};

export default DropDownMenu;
