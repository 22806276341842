import React, { useEffect, useRef, useState } from "react";
import { useDispatch, batch, useSelector, shallowEqual } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiService, UserService } from "../../api";
import {
	getFlags,
	getProject,
	getSegFlags,
	getUser,
	getPreferedLanguage,
	resetProject,
	getAllFavoriteCategories,
	getTeamMembers,
	setUpdateRelogCompanyList,
	setCompanies,
} from "../../common/slice";
import Navbar from "./navbar/Navbar";
import KeycloakService from "../../services/KeycloakService";
import { commonMenu, homeMenu, mentionsMenu, projectManagementMenu, projectMenu } from "../menus";
import { isSegFeatureEnabled, loadNavTranslation, SegFlags } from "../../common/providers";
import { getProjectIdFromURL } from "..";
import superstore from "../../store";

const NavBarContainer = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { value } = useSelector(({ context }) => context.language);
	const updateRelogCompanyList = useSelector(({ context }) => context.updateRelogCompanyList);
	const { project, company, segFlags } = useSelector(
		({ context }) => ({
			project: context.project,
			company: context.company,
			segFlags: context.segFlags,
		}),
		shallowEqual
	);
	const isLogged = KeycloakService.isLoggedIn();
	const preventNavigation = useSelector(({ context }) => context.preventNavigation);
	const [currentOpenedMenu, setCurrentOpenedMenu] = useState("");
	const [relogCompanies, setRelogCompanies] = useState([]);
	const [mentionsMenus, setMentionsMenus] = useState(null);
	const [projectMenus, setProjectMenus] = useState(null);
	const [projectManagementMenus, setProjectManagementMenus] = useState(null);
	const [render, setRender] = useState(false);
	const [url, setUrl] = useState("/");
	const [projectId, setProjectId] = useState(null);
	const [openVersion, setOpenVersion] = useState(false);
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		const currentUrl = location.pathname;
		setUrl(currentUrl);
		const matchedID = getProjectIdFromURL(currentUrl);
		if (matchedID && matchedID !== "join") {
			setProjectId(matchedID);
		} else {
			setProjectId(null);
		}
	}, [location.pathname]);
	useEffect(() => {
		const cancelToken = ApiService.getCancelTokenSource();
		if (isLogged) {
			setTimeout(() => {
				batch(() => {
					dispatch(getFlags(cancelToken.token));
					dispatch(getUser(cancelToken.token));
					dispatch(getPreferedLanguage(cancelToken.token));
				});
			}, 100);
		}
		return () => {
			ApiService.cancelTokens(cancelToken);
		};
	}, [isLogged, projectId, dispatch]);
	useEffect(() => {
		const cancelToken = ApiService.getCancelTokenSource();
		if (isLogged && projectId) {
			batch(() => {
				dispatch(getProject({ projectId, token: cancelToken.token }));
				dispatch(getSegFlags({ projectId, token: cancelToken.token }));
				dispatch(getTeamMembers({ projectId, token: cancelToken.token }));
			});
		} else {
			dispatch(resetProject(null));
		}
		return () => {
			ApiService.cancelTokens(cancelToken);
		};
	}, [isLogged, projectId, dispatch]);
	useEffect(() => {
		if (projectId && SegFlags && isSegFeatureEnabled(SegFlags.FAVORITE_CATEGORIES)) {
			dispatch(getAllFavoriteCategories({ projectId, token: cancelTokenSourceRef.current.token }));
		}
	}, [segFlags, dispatch, projectId]);
	useEffect(() => {
		if (project) {
			setProjectMenus(projectMenu({ projectId: project.id }));
			setProjectManagementMenus(projectManagementMenu({ projectId: project.id }));
			setMentionsMenus(mentionsMenu({ projectId: project.id }));
		}
	}, [project]);
	useEffect(() => {
		loadNavTranslation(value)
			.then(() => setRender(true))
			.catch((err) => console.error(err));
		return () => setRender(false);
	}, [value]);
	useEffect(() => {
		if (isLogged && !project?.id) {
			UserService.getAllUserCompanies(cancelTokenSourceRef.current.token)
				.then((data) => {
					setRelogCompanies(data.data);
					dispatch(setCompanies(data?.data));
				})
				.catch((err) => console.error(err))
				.finally(() => {
					dispatch(setUpdateRelogCompanyList(false));
				});
		}
	}, [isLogged, dispatch, updateRelogCompanyList, project]);

	const handleOpen = (menuValue) => {
		if (currentOpenedMenu !== menuValue) {
			setCurrentOpenedMenu(menuValue);
		} else {
			setCurrentOpenedMenu("");
		}
	};
	const handleOpenHover = (menuValue) => {
		if (currentOpenedMenu) {
			setCurrentOpenedMenu(menuValue);
		}
	};
	const handleClose = () => {
		setCurrentOpenedMenu("");
	};
	const handleSelect = (menu) => {
		navigate(menu.to, { state: { previous: location.pathname } });
		handleClose();
	};
	const handleHomeSelect = () => {
		window.history.pushState({}, "", "/");
	};
	const handleClickLogout = () => {
		if (!preventNavigation.active) {
			const cancelToken = ApiService.getCancelTokenSource().token;
			UserService.logout(cancelToken).finally(() => {
				superstore.persistor.purge();
				handleHomeSelect();
				KeycloakService.doLogout().then(() => {
					KeycloakService.clearToken();
				});
			});
		}
	};

	const handleIsSelected = (menuUrl) => {
		if (menuUrl === "/" && url !== "/") {
			return false;
		}
		if (url?.startsWith(menuUrl)) {
			return true;
		}
		return false;
	};
	const handleIncludes = (array, i = 0) => {
		let count = i;
		if (Array.isArray(array)) {
			array.forEach((menu) => {
				if (url?.startsWith(menu.to)) {
					count++;
				}
				if (menu.subItems) {
					count = handleIncludes(menu.subItems, count);
				}
			});
		}
		return count;
	};
	const handleClickVersion = () => {
		setOpenVersion(true);
		handleClose();
	};
	const handleHotRelog = (companyToRelog) => {
		handleClose();
		if (!companyToRelog) {
			return;
		}
		UserService.loginWithCompany({ companyId: companyToRelog.id }, cancelTokenSourceRef.current.token)
			.then(() => {
				window.location.reload();
			})
			.catch((err) => console.error(err));
	};
	return render ? (
		<Navbar
			commonMenus={commonMenu}
			company={company}
			currentOpenedMenu={currentOpenedMenu}
			homeMenus={homeMenu}
			isLogged={isLogged}
			mentionsMenus={mentionsMenus}
			openVersion={openVersion}
			project={project}
			projectManagementMenus={projectManagementMenus}
			projectMenus={projectMenus}
			relogCompanies={relogCompanies}
			setOpenVersion={setOpenVersion}
			onClickLogout={handleClickLogout}
			onClickVersion={handleClickVersion}
			onClose={handleClose}
			onHotRelog={handleHotRelog}
			onIncludes={handleIncludes}
			onIsSelected={handleIsSelected}
			onOpen={handleOpen}
			onOpenHover={handleOpenHover}
			onSelect={handleSelect}
		/>
	) : null;
};
export default NavBarContainer;
