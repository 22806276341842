import { TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { translate } from "../../../../common/providers";
import { CustomButton } from "../../../../common/components";
import styles from "./Search.module.css";

const Search = ({ onApplyFilter }) => {
	const [textSearch, setTextSearch] = useState("");
	const handleApplyFilter = useCallback(() => {
		onApplyFilter({ value: textSearch });
	}, [textSearch, onApplyFilter]);

	useEffect(() => {
		const handleKeyDetect = (e) => {
			if (e.repeat) {
				return;
			}
			if (e.key === "Enter") {
				handleApplyFilter();
			}
		};
		window.addEventListener("keydown", handleKeyDetect, true);
		return () => {
			window.removeEventListener("keydown", handleKeyDetect, true);
		};
	}, [handleApplyFilter]);

	const handleChangeSearch = (e) => {
		setTextSearch(e.target.value.split("\n").join(""));
	};
	return (
		<div className={styles.container}>
			<TextField
				fullWidth
				placeholder={translate("multi-project-search.search-text.placeHolder")}
				value={textSearch}
				onChange={handleChangeSearch}
			/>
			<div className={styles.btnContainer}>
				<CustomButton variant="contained" onClick={handleApplyFilter}>
					{translate("common:btn.search")}
				</CustomButton>
			</div>
		</div>
	);
};

export default Search;
