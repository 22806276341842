import DOMPurify from "dompurify";
import { FILE_TYPES_EXTENSIONS } from "../constants/file-types";
import { hasProperty } from "./object-utils";

export const debounce = (func, wait = 1000) => {
	let timeout;
	return (...args) => {
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			timeout = null;
			func(...args);
		}, wait);
	};
};

export const validatePayload = ({ payload = {}, rules = [] }) => {
	/* HOW TO
	const rule = {
		test: ({ field }) => condition that 'field' should valid,
		error: { field : x, message: "Error message to display" }
	} */
	const errors = rules.reduce((errs, rule) => {
		if (!rule.test(payload)) {
			errs.push(rule.error || { message: "Error in payload" });
		}
		return errs;
	}, []);
	return { errors, isValid: errors.length === 0 };
};

export const range = ({ start, end, step }) => {
	let actualEnd = end;
	let actualStart = start;
	let actualStep = step;
	if (!end) {
		actualEnd = start;
		actualStart = 0;
	}
	if (!step) {
		actualStep = start < actualEnd ? 1 : -1;
	}
	let index = 0;
	let length = Math.max(Math.ceil((actualEnd - actualStart) / (actualStep || 1)), 0);
	const result = Array(length);
	while (length) {
		const pos = index;
		result[pos] = actualStart;
		actualStart += actualStep;
		index++;
		length--;
	}
	return result;
};

export const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

export const getStatusColor = (status) => {
	const actual = status?.toUpperCase();
	switch (actual) {
		case "NOT_ME":
			return "var(--status-not-me)";
		case "YES":
			return "var(--status-yes)";
		case "NO":
			return "var(--status-no)";
		case "PENDING":
			return "var(--status-pending)";
		case "UNREVIEWED":
		default:
			return "var(--status-unreviewed)";
	}
};

export const getStatusBgColor = (status) => {
	const actual = status?.toUpperCase();
	switch (actual) {
		case "NOT_ME":
			return "var(--status-not-me-20)";
		case "YES":
			return "var(--status-yes-20)";
		case "NO":
			return "var(--status-no-20)";
		case "PENDING":
			return "var(--status-pending-20)";
		case "UNREVIEWED":
		default:
			return "var(--status-unreviewed-20)";
	}
};

export const getTaskStatusColor = (status) => {
	const actual = status?.toUpperCase();
	switch (actual) {
		case "NEW":
			return "var(--color-red)";
		case "IN_PROGRESS":
			return "var(--color-orange)";
		case "DONE":
			return "var(--color-green)";
		default:
			return "var(--color-black)";
	}
};

export const getTaskPriorityColor = (status) => {
	const actual = status?.toUpperCase();
	switch (actual) {
		case "HIGH":
			return "var(--color-red)";
		case "NORMAL":
			return "var(--color-orange)";
		case "LOW":
			return "var(--color-green)";
		default:
			return "var(--color-black)";
	}
};

export const isObject = (object) => object != null && typeof object === "object";

export const isDeepEqual = (object1, object2) => {
	if (!isObject(object1) || !isObject(object2)) {
		return false;
	}
	return JSON.stringify(object1) === JSON.stringify(object2);
};

export const isDeepEqualComplex = (object1, object2) => {
	if (!object1 || !object2) {
		return false;
	}
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
		return false;
	}
	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		if ((areObjects && !isDeepEqualComplex(val1, val2)) || (!areObjects && val1 !== val2)) {
			return false;
		}
	}
	return true;
};

function recurse(thematics) {
	let categories = [];
	if (!Array.isArray(thematics)) {
		return categories;
	}
	for (const thematic of thematics) {
		if (hasProperty(thematic, "categories") && Array.isArray(thematic.categories)) {
			categories = categories.concat(thematic.categories);
		}
		if (hasProperty(thematic, "subAnalyticalAxe")) {
			categories = categories.concat(recurse(thematic.subAnalyticalAxe));
		}
	}
	return categories;
}

export const flattenThematicCategories = (thematic) => {
	if (!thematic) {
		return [];
	}
	return recurse(thematic.subAnalyticalAxe || thematic);
};

// MIME ref : https://developer.mozilla.org/fr/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const getMIMEForExtension = (extension) => {
	switch (extension) {
		case "pdf":
			return "application/pdf";
		case "xlsx":
			return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		case "zip":
			return "application/zip";
		case "xlsm":
			return "application/vnd.ms-excel.sheet.macroEnabled.12";
		default:
			return "";
	}
};

const endsWithOneOf = (name, endings) => endings.some((end) => name.endsWith(end));

export const downloadFile = ({ data, filename, filetype }) => {
	const options = { type: getMIMEForExtension(filetype) };
	const url = window.URL.createObjectURL(new Blob([data], options));
	const name = endsWithOneOf(filename, FILE_TYPES_EXTENSIONS) ? filename : `${filename}.${filetype}`;
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", name);
	link.click();
	window.URL.revokeObjectURL(url);
};

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator, sortStart = 0) {
	const sortableArray = array.slice(sortStart);
	const stabilizedThis = sortableArray.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return array.slice(0, sortStart).concat(stabilizedThis.map((el) => el[0]));
}
/*
	order 		--> Direction of order (asc, desc)
	orderBy 	--> Sort key(sortKey) of column by which we want to order
	startIndex	--> From which array index we want to sort by default 0
*/
export const sortArray = (array, order, orderBy, startIndex) =>
	stableSort(array, getComparator(order, orderBy), startIndex);

export const removeItemFromPosition = (array, position) => {
	const newArray = [...array];
	newArray.splice(position, 1);
	return newArray;
};

export const getCSSVariable = (varName) => getComputedStyle(document.documentElement).getPropertyValue(varName);

export const isInBetween = (value, edge1, edge2, includeLeft, includeRight) =>
	(includeRight ? value <= Math.max(edge1, edge2) : value < Math.max(edge1, edge2)) &&
	(includeLeft ? value >= Math.min(edge1, edge2) : value > Math.min(edge1, edge2));

export const dangerousSetInnerHTML = ({ autoTranslate = "yes", className = "", text }) => (
	/* eslint-disable */
	<span
		className={className}
		dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
		translate={autoTranslate}
	/>
	/* eslint-enable */
);

export const searchInString = (string, search) => {
	if (!string || typeof string !== "string" || typeof search !== "string") {
		return false;
	}
	return string?.toLowerCase().includes(search?.toLowerCase());
};

export const getFilteredDataAttributes = (props) => {
	if (props === null || typeof props !== "object") {
		return {};
	}
	const results = {};
	Object.keys(props)
		.filter((key) => key.startsWith("data-"))
		.forEach((key) => {
			results[key] = props[key];
		});
	return results;
};

export const getIconFromFiletype = (filetype) => {
	switch (true) {
		case searchInString(filetype, "pdf"):
			return "faFilePdf";
		case searchInString(filetype, "folder"):
			return "faFolder";
		default:
			return "faFile";
	}
};
export const isValueActive = (value, key, index) => {
	if (key === "categories") {
		return value?.CATEGORY?.length > 0 || value?.FAVORITE?.length > 0;
	}
	if (key === "documents" || key === "requirement" || key === "deliverables") {
		return value?.elements?.length > 0 || value?.length > 0;
	}
	if (key === "tocFilters" || key === "keywordFilters") {
		return value?.filters?.[index]?.keywords?.length > 0;
	}
	return ![null, undefined, ""].includes(value) && !["[]", "{}", ""].includes(JSON.stringify(value));
};
export const checkAitendersUser = (email) => {
	if (!email) {
		return false;
	}
	return email.endsWith("@aitenders.com");
};
export function isNumber(value) {
	return typeof value === "number" && Number.isFinite(value) && value !== null && value !== undefined;
}
export function isString(value) {
	return typeof value === "string" || value instanceof String;
}

export function nonNullAndDifferent(a, b) {
	return !!a && !!b && a !== b;
}
export function nonNullAndDifferentObjects(objA, objB) {
	return !!objA && !!objB && !isDeepEqualComplex(objA, objB);
}

export function strictValueBetween(min, val, max) {
	return val > min && val < max;
}
