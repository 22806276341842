import React, { useState } from "react";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	icon,
	IconComponent,
} from "../../../../common/components";
import { translate } from "../../../../common/providers";
import styles from "./DSICompanyTable.module.css";

function getCompanyHasChildren(data) {
	const result = [];
	for (const item of data) {
		if (item.children && item.children.length > 0) {
			result.push(item.id);
			result.push(...getCompanyHasChildren(item.children));
		}
	}
	return result;
}

export default function DSICompanyTable({ companies, selectedCompany, onSelectRow }) {
	const [headers] = useState([
		translate("dsi.dsi-company.th.shortname"),
		translate("dsi.dsi-company.th.name"),
		translate("dsi.dsi-company.th.type"),
	]);
	const [expandedRows, setExpandedRows] = useState(getCompanyHasChildren(companies));
	const toggleRowExpansion = (companyId) => {
		if (expandedRows.includes(companyId)) {
			setExpandedRows(expandedRows.filter((id) => id !== companyId));
		} else {
			setExpandedRows([...expandedRows, companyId]);
		}
	};
	const handleRowClick = (company) => {
		if (company.children) {
			toggleRowExpansion(company.id);
		}
		onSelectRow(company);
	};
	const renderCompanyRows = (company, level = 0) => (
		<React.Fragment key={company.id}>
			<CustomTableRow
				hover
				className={selectedCompany === company ? styles["tableRow--selected"] : styles.tableRow}
				onClick={() => handleRowClick(company)}
			>
				<CustomTableCell style={{ paddingLeft: `${16 + level * 16}px` }}>
					{company.children && (
						<IconComponent
							color="var(--color-dark-grey-1)"
							icon={expandedRows.includes(company.id) ? icon.faChevronDown : icon.faChevronRight}
							size="xs"
							translate="no"
						/>
					)}
					<span className={company?.children ? styles.parentName : styles.childName}>
						{company.shortName}
					</span>
				</CustomTableCell>
				<CustomTableCell style={{ paddingLeft: `${16 + level * 16}px` }}>{company.companyName}</CustomTableCell>
				<CustomTableCell style={{ paddingLeft: `${16 + level * 16}px` }}>{company.type}</CustomTableCell>
			</CustomTableRow>
			{Array.isArray(company.children) &&
				expandedRows.includes(company.id) &&
				company.children.map((childCompany) => renderCompanyRows(childCompany, level + 1))}
		</React.Fragment>
	);

	return (
		<CustomTable>
			<CustomTableHead>
				<CustomTableRow>
					{headers.map((header) => (
						<CustomTableCell key={header} className={styles.cell}>
							{header}
						</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody>
				{Array.isArray(companies) && companies.map((company) => renderCompanyRows(company))}
			</CustomTableBody>
		</CustomTable>
	);
}
