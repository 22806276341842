import React, { useState, useEffect } from "react";
import { LabeledCheckbox } from "../../../checkboxes";
import { CustomTooltip, CustomIconButton, icon } from "../../..";
import styles from "./FilterSearchRow.module.css";
import { useEffectOnce, usePrevious } from "../../../../hooks";

function checkNonEmptyTree(array, isTree, counter) {
	return (isTree && Array.isArray(array) && array.length > 0) || counter > 0;
}

export default function FilterSearchRow({
	ancestors = [],
	checkedElements = [],
	defaultOpen = false,
	disabled = false,
	hasChildren = false,
	isRowChecked,
	isTree,
	label,
	level = 0,
	onAddAncestor,
	onClick,
	onExpand,
	row,
	rowKey,
	rowLabelKey,
	rowSecondaryLabelKey,
	rowLabelClassName = "",
	secondaryLabel,
	subRowCounter,
	subRowKey,
	withRowLabelTooltip,
}) {
	const [open, setOpen] = useState(defaultOpen);
	const [checked, setChecked] = useState(false);
	const handleOpen = () => {
		if (typeof onExpand === "function") {
			onExpand(row);
		}
		setOpen((prev) => !prev);
	};
	const previousRow = usePrevious(row);
	useEffectOnce(
		() => {
			if (onAddAncestor) {
				onAddAncestor({ checked: row[rowKey], list: ancestors });
			}
		},
		[isRowChecked, row],
		() => isRowChecked(row) === true && previousRow !== row
	);
	useEffect(() => {
		setChecked(checkedElements.some((element) => element === row[rowKey]));
	}, [checkedElements, row, rowKey]);
	return (
		<div
			style={{
				paddingLeft:
					(isTree &&
						(level > 0 ? 32 : 0) +
							(!checkNonEmptyTree(row[subRowKey], isTree, row[subRowCounter]) ? 32 : 0)) ||
					0,
			}}
		>
			{checkNonEmptyTree(row[subRowKey], isTree, row[subRowCounter]) && (
				<CustomIconButton
					icon={open ? icon.faChevronDown : icon.faChevronRight}
					size="sm"
					onClick={handleOpen}
				/>
			)}
			<LabeledCheckbox
				checkboxClassName={styles.row__checkbox}
				checked={checked}
				className={styles.labeledCheckBox}
				disabled={disabled}
				label={
					<CustomTooltip arrow title={(withRowLabelTooltip && label) || ""}>
						<span className={`${styles.labels} ${rowLabelClassName}`}>
							<span className={`${styles.label__main} ${hasChildren && styles["label__main--bold"]}`}>
								{label}
							</span>
							{secondaryLabel && <span className={styles.label__secondary}>{secondaryLabel}</span>}
						</span>
					</CustomTooltip>
				}
				labelClassName={styles.row__label}
				labelPlacement="end"
				size="small"
				onClick={() => onClick({ ...row, ancestors })}
			/>
			{open &&
				isTree &&
				Array.isArray(row[subRowKey]) &&
				row[subRowKey].length > 0 &&
				row[subRowKey].map((el) => (
					<FilterSearchRow
						key={el[rowKey]}
						ancestors={[...ancestors, row[rowKey]]}
						checked={isRowChecked}
						checkedElements={checkedElements}
						hasChildren={el.childrenCount > 0}
						isRowChecked={isRowChecked}
						isTree={isTree}
						label={el[rowLabelKey]}
						level={level + 1}
						row={el}
						rowKey={rowKey}
						rowLabelKey={rowLabelKey}
						secondaryLabel={el[rowSecondaryLabelKey]}
						subRowCounter={subRowCounter}
						subRowKey={subRowKey}
						withRowLabelTooltip={withRowLabelTooltip}
						onAddAncestor={onAddAncestor}
						onClick={onClick}
						onExpand={onExpand}
					/>
				))}
		</div>
	);
}
