import React from "react";
import { Divider } from "@mui/material";
import { connect } from "react-redux";
import styles from "./RequirementMatrix.module.css";
import { ApiService, CategoryService, RequirementMatrixService } from "../../../api";
import { AnalyticsProvider, Flags, isSegFeatureEnabled, SegFlags, translate } from "../../../common/providers";
import { isDeepEqual, updateTreeBranch } from "../../../common/utils";
import { EditReqDialog, ReqDetails, ReqFilters, ReqList } from "./components";
import { exportView } from "../../../navigation";
import { CustomButton, generateFilters, icon, ViewBanner } from "../../../common/components";
import { ExportDialog } from "../coverage/components";
import { SEPARATOR } from "../../smart-search/utils/utils";

const mapStateToProps = ({ context }) => ({
	project: context.project,
});

class RequirementMatrix extends React.Component {
	constructor(props) {
		super(props);
		const updateCancelTokenSources = [ApiService.getCancelTokenSource(), ApiService.getCancelTokenSource()];
		this.state = {
			totalRequirementsInProject: 0,
			currentPage: 0,
			requirements: {}, // Page + Contents(requirements)
			filters: { separator: SEPARATOR.AND },
			categories: null,
			parameters: null,
			selectedRequirement: null,
			isLoadingReqs: false,
			isLoadingDetails: false,
			openEditRedDialog: false,
			updateCancelTokenSources,
			openExport: false,
		};
		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentDidMount() {
		const documentTitle = translate("requirement-matrix.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Project requirements" });
		this.getFirstRequirements();
		this.getParametersForInformation();
		this.getCategoriesInProject();
	}

	componentWillUnmount() {
		const { updateCancelTokenSources } = this.state;
		updateCancelTokenSources.forEach((tokenSource) => {
			ApiService.cancelTokens(tokenSource);
		});
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	componentDidUpdate(_, prevState) {
		const { selectedRequirement, filters, updateCancelTokenSources } = this.state;
		if (
			(!prevState.selectedRequirement && !!selectedRequirement) ||
			(selectedRequirement && prevState.selectedRequirement.id !== selectedRequirement.id)
		) {
			ApiService.cancelTokens(updateCancelTokenSources[0]);
			updateCancelTokenSources[0] = ApiService.getCancelTokenSource();
			this.getRequirementDetails(updateCancelTokenSources[0]);
		}
		if (!!prevState.filters && filters && !isDeepEqual(prevState.filters, filters)) {
			ApiService.cancelTokens(updateCancelTokenSources[1]);
			updateCancelTokenSources[1] = ApiService.getCancelTokenSource();
			this.getRequirements(updateCancelTokenSources[1]);
		}
	}

	getFirstRequirements = () => {
		const { project } = this.props;
		this.setState({ isLoadingReqs: true });
		RequirementMatrixService.searchRequirementsInProject(
			{ projectId: project.id },
			{},
			{},
			this.cancelTokenSource.token
		)
			.then((data) =>
				this.setState({
					requirements: data,
					isLoadingReqs: false,
					currentPage: 0,
					totalRequirementsInProject: data.totalElements,
				})
			)
			.catch((err) => {
				console.error(err);
				this.setState({ isLoadingReqs: false });
			});
	};

	getRequirements = (cancelTokenSource) => {
		const { filters } = this.state;
		const { project } = this.props;
		this.setState({ isLoadingReqs: true });
		RequirementMatrixService.searchRequirementsInProject(
			{ projectId: project.id },
			generateFilters(filters),
			{},
			cancelTokenSource.token
		)
			.then((data) => {
				this.setState({
					requirements: data,
					selectedRequirement: null,
					currentPage: 0,
					isLoadingReqs: false,
				});
			})
			.catch((err) => {
				console.error(err);
				this.setState({ isLoadingReqs: false });
			});
	};

	getRequirementDetails = (cancelTokenSource) => {
		const { selectedRequirement } = this.state;
		if (!selectedRequirement || !selectedRequirement.id) {
			return;
		}
		this.setState({ isLoadingDetails: true });
		const reqId = selectedRequirement.id;
		if (isSegFeatureEnabled(SegFlags.PARTNERS)) {
			RequirementMatrixService.getRequirementDetails({ reqId }, cancelTokenSource.token)
				.then((data) => {
					this.setState({ isLoadingDetails: false, selectedRequirement: data });
				})
				.catch((err) => this.handleErrorWhileDetailsLoading(err));
		} else {
			RequirementMatrixService.getRequirementDetailsMyCompany({ reqId }, cancelTokenSource.token)
				.then((data) => {
					this.setState({ isLoadingDetails: false, selectedRequirement: data });
				})
				.catch((err) => this.handleErrorWhileDetailsLoading(err));
		}
	};

	getCategoriesInProject = () => {
		const { categories } = this.state;
		const { project } = this.props;
		if (categories) {
			return;
		}
		CategoryService.getTreeParentsForProject(
			{ projectId: project.id, details: false },
			this.cancelTokenSource.token
		)
			.then((data) =>
				this.setState({
					categories: data,
				})
			)
			.catch((err) => {
				console.error(err);
			});
	};

	getParametersForInformation = () => {
		const { parameters } = this.state;
		if (parameters) {
			return;
		}
		RequirementMatrixService.getParametersForInformation(this.cancelTokenSource.token)
			.then((data) => this.setState({ parameters: data }))
			.catch((err) => {
				console.error(err);
			});
	};

	updateRequirement = (payload) => {
		const { selectedRequirement } = this.state;
		if (!selectedRequirement || !selectedRequirement.id) {
			return;
		}
		const reqId = selectedRequirement.id;
		this.setState({ isLoadingDetails: true });
		RequirementMatrixService.updateRequirement({ reqId }, payload, this.cancelTokenSource.token)
			.then((data) => this.setState({ isLoadingDetails: false, selectedRequirement: data }))
			.catch((err) => this.handleErrorWhileDetailsLoading(err));
	};

	handleErrorWhileDetailsLoading = (err) => {
		console.error(err);
		this.setState({ isLoadingDetails: false });
	};

	handleLoadMore = () => {
		const { currentPage, requirements, filters } = this.state;
		const { project } = this.props;
		if (currentPage < requirements.totalPages - 1) {
			const page = currentPage + 1;
			this.setState({ isLoadingReqs: true, currentPage: page });
			return RequirementMatrixService.searchRequirementsInProject(
				{ projectId: project.id },
				generateFilters(filters),
				{ page },
				this.cancelTokenSource.token
			)
				.then((data) =>
					this.setState((prev) => ({
						requirements: { ...data, contents: [...prev.requirements.contents, ...data.contents] },
						isLoadingReqs: false,
					}))
				)
				.catch((err) => {
					this.setState({ isLoadingReqs: false });
					console.error(err);
				});
		}
		return null;
	};

	handleSelectRequirement = (id) => {
		const { requirements } = this.state;
		if (!id) {
			return;
		}
		if (!requirements) {
			this.setState({ selectedRequirement: null });
			return;
		}
		const requirement = requirements.contents.find((req) => req.id === id);
		const { selectedRequirement } = this.state;
		if (!requirement || (selectedRequirement && requirement.id === selectedRequirement.id)) {
			return;
		}
		this.setState({ selectedRequirement: requirement });
	};

	handleOpenEditReqDialog = () => {
		const { selectedRequirement } = this.state;
		if (!selectedRequirement) {
			return;
		}
		this.setState({ openEditRedDialog: true });
	};

	handleCloseEditReqDialog = () => {
		this.setState({ openEditRedDialog: false });
	};

	handleApplyFilters = (newFilters) => {
		this.setState({
			filters: newFilters,
		});
	};

	handleExpandCategory = (category) => {
		if (!Array.isArray(category.subThematic) || category.subThematic.length === 0) {
			CategoryService.getTreeChildrenForProject(
				{ parentId: category.id, details: false },
				this.cancelTokenSource.token
			)
				.then((data) => {
					this.setState((prev) => ({
						categories: updateTreeBranch({
							tree: prev.categories,
							idToReplace: category.id,
							newBranch: {
								...category,
								subThematic: data,
							},
							iterativeBranchName: "subThematic",
						}),
					}));
				})
				.catch((err) => console.error(err));
		}
	};

	handleOpenExportDialog = () => this.setState((prevState) => ({ openExport: !prevState.openExport }));

	render() {
		const {
			categories,
			parameters,
			requirements,
			isLoadingReqs,
			selectedRequirement,
			totalRequirementsInProject,
			isLoadingDetails,
			openEditRedDialog,
			openExport,
			filters,
		} = this.state;
		const exportButton = (
			<CustomButton
				className={styles.uploadButton}
				color="primary"
				startIcon={icon.faUpload}
				variant="contained"
				onClick={this.handleOpenExportDialog}
			>
				{translate("common:btn.export")}
			</CustomButton>
		);
		return (
			<>
				<ViewBanner
					options={[exportButton]}
					titles={[
						{ title: translate("navigation:project.review"), key: "review" },
						{ title: translate("navigation:project.project-requirements") },
					]}
				/>
				<div className={styles.headerContainer}>
					<ReqFilters defaultFilters={filters} onApply={this.handleApplyFilters} />
				</div>
				<div className={styles.container}>
					<ReqList
						hasMore={requirements.hasMore}
						isLoadingReqs={isLoadingReqs}
						requirements={requirements.contents}
						selectedRequirement={selectedRequirement}
						totalElements={requirements.totalElements}
						totalRequirementsInProject={totalRequirementsInProject}
						onLoadMore={this.handleLoadMore}
						onSelectRequirement={this.handleSelectRequirement}
					/>
					<Divider orientation="vertical" />
					<ReqDetails
						isLoadingDetails={isLoadingDetails}
						requirement={selectedRequirement}
						onClickEdit={this.handleOpenEditReqDialog}
					/>
				</div>
				<EditReqDialog
					categories={categories}
					open={openEditRedDialog}
					params={parameters}
					requirement={selectedRequirement}
					onClose={this.handleCloseEditReqDialog}
					onExpand={this.handleExpandCategory}
					onUpdate={this.updateRequirement}
				/>
				<ExportDialog filters={filters} open={openExport} onClose={this.handleOpenExportDialog} />
			</>
		);
	}
}

export default exportView({
	path: "/projects/:projectId/review/project-requirements",
	localesPath: "/produce/requirement-matrix/locales",
	component: connect(mapStateToProps)(RequirementMatrix),
	flag: Flags.REQUIREMENT_MATRIX,
	segFlag: SegFlags.PROJECT_REQUIREMENTS,
});
