import { Checkbox, Radio } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApiService } from "../../../../../api";
import { CustomButton, CustomTooltip, I18nTranslate, SidePanelBox } from "../../..";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../providers";
import { NavUtils } from "../../../../../navigation";
import styles from "./SearchCardBox.module.css";

export default function SearchCardBox({ checked = false, onCheck, searchCard = {}, singleSelection = false }) {
	const projectId = useSelector(({ context }) => context.project?.id);
	const location = useLocation();
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleCheck = () => {
		if (typeof onCheck === "function") {
			onCheck(!checked);
		}
	};
	const handleClickDetails = () => {
		if (location.pathname.includes("search-for-automation") || location.pathname.includes("automation")) {
			window.open(NavUtils.goToSearchForAutomation(projectId, searchCard.id));
		} else {
			window.open(NavUtils.goToMySearch(projectId, searchCard.id));
		}
	};
	return (
		<SidePanelBox className={styles.container} hasShadow={false}>
			<div className={styles.searchCardBox}>
				<div className={styles.searchCardBox__checkboxContainer}>
					{(singleSelection && <Radio checked={checked} color="primary" onChange={handleCheck} />) || (
						<Checkbox checked={checked} color="primary" onChange={handleCheck} />
					)}
				</div>
				<div className={styles.searchCardBox__contentContainer}>
					<div className={styles.contentContainer__header}>
						<CustomTooltip title={searchCard.title || ""}>
							<div className={styles.header__title}>{searchCard.title}</div>
						</CustomTooltip>
						<div className={styles.resultsContainer}>
							{searchCard.resultCount !== null && (
								<I18nTranslate
									param={{
										resultsCount: searchCard.resultCount,
									}}
									translationKey="common:add-template-panel.box.results"
								/>
							)}
						</div>
					</div>
					<div className={styles.descriptionContainer}>{searchCard.description}</div>
				</div>
				<div className={styles.detailsButtonContainer}>
					{isSegFeatureEnabled(SegFlags.SMART_SEARCH) && projectId && (
						<CustomButton variant="outlined" onClick={handleClickDetails}>
							{translate("common:add-template-panel.box.details-button")}
						</CustomButton>
					)}
				</div>
			</div>
		</SidePanelBox>
	);
}
