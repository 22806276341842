import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./ZoomHandler.module.css";
import { CustomIconButton, icon } from "../../../../../../../../common/components";
import { setZoom, setScrollTo } from "../../../../../../slice/pdf/pdf-slice";
import { ZOOM_MAX, ZOOM_MIN, ZOOM_STEP } from "../../../../../../constants/constants";
import { usePrevious } from "../../../../../../../../common/hooks";

function getResolutionWidth() {
	return window.innerWidth;
}

function getZoom(imageWidth, openDrawer) {
	const resolutionWidth = getResolutionWidth();
	const possibilities = Array.from(
		Array(Math.trunc((ZOOM_MAX - ZOOM_MIN) / ZOOM_STEP)),
		(_, i) => Math.floor((ZOOM_MIN + i * ZOOM_STEP) * 100) / 100
	);
	const goal = (resolutionWidth * ((openDrawer && 0.5) || 0.6) * 100) / imageWidth - 10;
	return possibilities.reduce((prev, curr) =>
		Math.abs(curr - goal) < Math.abs(prev - goal) && curr <= goal ? curr : prev
	);
}
export default function ZoomHandler({ openDrawer }) {
	const zoom = useSelector(({ srPdf }) => srPdf.zoom);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const page = useSelector(({ srPdf }) => srPdf.page);
	const pageDimensions = useSelector(({ srDocument }) => srDocument.pageDimensions);
	const dispatch = useDispatch();

	const initZoom = useCallback(
		() => {
			if (Array.isArray(pageDimensions)) {
				const maxHeight = Math.max(...pageDimensions.map((o) => o.height));
				const maxWidth = Math.max(...pageDimensions.map((o) => o.width));
				const finalZoom = getZoom(maxWidth, openDrawer);
				if (zoom > 0 && !openDrawer) {
					dispatch(setZoom(zoom));
				} else if (maxHeight > 0 && maxWidth > 0) {
					dispatch(setZoom(finalZoom > 150 ? 150 : finalZoom));
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[documentId, pageDimensions, openDrawer, dispatch]
	);
	const prevDocId = usePrevious(documentId);

	useEffect(
		() => {
			if ((prevDocId && documentId && documentId !== prevDocId) || !zoom) {
				initZoom();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[documentId, pageDimensions, openDrawer, dispatch]
	);

	useEffect(() => {
		const handleRezoom = () => {
			initZoom();
		};
		window.addEventListener("resize", handleRezoom);
		return () => {
			window.removeEventListener("resize", handleRezoom);
		};
	}, [initZoom]);

	const handleZoomOut = () => {
		if (zoom > ZOOM_MIN) {
			dispatch(setZoom(zoom - ZOOM_STEP));
			dispatch(setScrollTo({ position: 0.01, page: page + 1 }));
		}
	};
	const handleZoomIn = () => {
		if (zoom < ZOOM_MAX) {
			dispatch(setZoom(zoom + ZOOM_STEP));
			dispatch(setScrollTo({ position: 0.01, page: page + 1 }));
		}
	};
	return (
		<div className={styles.zoom}>
			{documentId && (
				<>
					<CustomIconButton
						icon={icon.faMinusCircle}
						iconColor="var(--color-dark-grey-4)"
						onClick={handleZoomOut}
					/>
					{`${Math.trunc(zoom)}%`}
					<CustomIconButton
						icon={icon.faPlusCircle}
						iconColor="var(--color-dark-grey-4)"
						onClick={handleZoomIn}
					/>
				</>
			)}
		</div>
	);
}
