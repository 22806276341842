import React from "react";
import { Checkbox, Radio } from "@mui/material";
import styles from "./ActionRow.module.css";
import { CustomIconButton, icon } from "../../..";
import { isNonEmptyArray } from "../../../../utils";

export default function ActionRow({
	hasSubRows,
	item,
	multiple = false,
	onCheckRow,
	onOpen = null,
	open = false,
	total,
}) {
	const handleChange = () => {
		onCheckRow(item);
	};
	return (
		<div key={item.name} className={styles.actionRow__row}>
			<div
				className={`${styles.actionRow__content} ${!hasSubRows && styles["actionRow--leftPadding"]}`}
				data-is-parent={isNonEmptyArray(item.subThematic)}
			>
				{onOpen && isNonEmptyArray(item.subThematic) && (
					<CustomIconButton
						icon={open ? icon.faChevronDown : icon.faChevronRight}
						size="sm"
						onClick={onOpen}
					/>
				)}
				{multiple ? (
					<div className={styles.actionRow__checkContainer}>
						<CheckboxRow item={item} total={total} onChange={handleChange} />
					</div>
				) : (
					<div className={styles.actionRow__checkContainer}>
						<RadioRow item={item} onChange={handleChange} />
					</div>
				)}
				<span className={`${styles.actionRow__name} ${hasSubRows && styles["actionRow__name--bold"]}`}>
					{item.displayName || item.name}
				</span>
			</div>
			{!item.visited && item.contentsCount > 0 && item.contentsCount < total && (
				<div className={styles.actionRow__count}>
					{item.contentsCount} of {total} selected
				</div>
			)}
		</div>
	);
}

function CheckboxRow({ item, onChange, total }) {
	return (
		<Checkbox
			checked={item.checked}
			className={styles.checkbox}
			color="primary"
			indeterminate={item.contentsCount > 0 && item.contentsCount < total && !item.visited}
			size="medium"
			value={item.name}
			onChange={onChange}
		/>
	);
}

function RadioRow({ item, onChange }) {
	return <Radio checked={item.checked} color="primary" size="medium" onChange={onChange} />;
}
