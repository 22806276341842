/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import {
	InfiniteList2,
	MultipleProgressBar,
	ViewTabPanel,
	ViewTabs,
	I18nTranslate,
} from "../../../../../../../../../common/components";
import {
	Flags,
	isFeatureEnabled,
	isSegFeatureEnabled,
	Permissions,
	SegFlags,
	translate,
	translateEnumStatus,
} from "../../../../../../../../../common/providers";
import { PositionBox } from "..";
import {
	ApiService,
	CollabService,
	CommentService,
	PositionsService,
	ValidatorService,
} from "../../../../../../../../../api";
import styles from "./PositionsTabView.module.css";
import { updateNode } from "../../../../../../../slice/pdf/pdf-slice";
import { ROLES } from "../../../../../../../constants/constants";
import { POSITIONS_STATUSES } from "../../../../../../../../../common/constants/positions-statuses";
import PositionComment from "../position-comment/PositionComment";
import { debounce } from "../../../../../../../../../common/utils";

const debouncedFunction = debounce((func) => func(), 0);
export default function PositionsTabView({
	disableEditing = false,
	isRequirementAdditional = false,
	pageStart,
	informationId,
	myDocInfo,
	selectedMode,
}) {
	const [selectedTab, setSelectedTab] = useState(0);
	const cancelTokenSourceRef = useRef(null);
	const [inifiniteListRequests, setInfiniteListRequests] = useState([]);
	const [myPosition, setMyPosition] = useState({});
	const [myTeamPosition, setMyTeamPosition] = useState({});
	const [teamCounts, setTeamCounts] = useState({});
	const [myTeamPositionsCounts, setMyTeamPositionsCounts] = useState({});
	const [otherTeamsPositionsCounts, setOtherTeamsPositionsCounts] = useState({});
	const [tempComment, setTempComment] = useState("");
	const [tempIssueComment, setTempIssueComment] = useState("");
	const isRequirement = useSelector(({ srDocument }) => srDocument.sidePanelIsRequirement);
	const savedImageLength = useSelector(({ srPdf }) => srPdf?.content?.length || 0);
	const dispatch = useDispatch();

	const tabs = [
		{
			label: translate("smart-review.right-panel.positions-tab.my-team"),
		},
		{
			label: translate("smart-review.right-panel.positions-tab.all-teams"),
		},
	];

	const loadMyPosition = useCallback(() => {
		PositionsService.getMyPosition({ reqId: informationId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setMyPosition(data);
				setTempComment("");
			})
			.catch((err) => console.error(err));
	}, [informationId]);
	const loadCompanyPosition = useCallback(() => {
		PositionsService.getMyTeamPosition({ reqId: informationId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setMyTeamPosition(data);
			})
			.catch((err) => console.error(err));
	}, [informationId]);
	const loadTeamsCounts = useCallback(() => {
		PositionsService.getPositionsCounters({ reqId: informationId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setTeamCounts(data);
			})
			.catch((err) => console.error(err));
	}, [informationId]);
	const loadPositionsCounts = useCallback(() => {
		Axios.all([
			PositionsService.getMyTeamCounters({ reqId: informationId }, cancelTokenSourceRef.current.token),
			PositionsService.getOtherTeamCounters({ reqId: informationId }, cancelTokenSourceRef.current.token),
		])
			.then(
				Axios.spread((...data) => {
					setMyTeamPositionsCounts(data[0]);
					setOtherTeamsPositionsCounts(data[1]);
					dispatch(
						updateNode({
							mode: selectedMode,
							pageConditions: { page: pageStart },
							informationId,
							updates: [{ property: "reviewCounters", value: data[0] }],
						})
					);
				})
			)
			.catch((err) => {
				console.error(err);
			});
	}, [informationId, selectedMode, pageStart, dispatch]);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	useEffect(() => {
		if (informationId) {
			debouncedFunction(() => {
				setInfiniteListRequests([
					(args) =>
						PositionsService.getMyTeamPositions(
							{ reqId: informationId },
							args,
							cancelTokenSourceRef.current.token
						),
					(args) =>
						PositionsService.getOtherTeamsPositions(
							{ reqId: informationId },
							args,
							cancelTokenSourceRef.current.token
						),
				]);
			});
			loadMyPosition(informationId);
			loadCompanyPosition(informationId);
			loadTeamsCounts(informationId);
			loadPositionsCounts(informationId);
		}
	}, [informationId, loadMyPosition, loadCompanyPosition, loadTeamsCounts, loadPositionsCounts]);

	const infiniteListRowRenderer = (row) => (
		<PositionBox
			className={styles.rowContainer}
			position={row}
			primaryName={
				selectedTab === 1 ? (
					<I18nTranslate
						param={{ validatorName: row.companyName }}
						translationKey="smart-review.cmp-details.option-panel.positions-tab.position-box.validation-position"
					/>
				) : (
					<I18nTranslate
						param={{ name: row.username }}
						translationKey="smart-review.cmp-details.option-panel.positions-tab.position-box.review-position"
					/>
				)
			}
			secondaryName={selectedTab === 1 ? row.username : ""}
		/>
	);
	const handleChangeStatus = (newStatus, removeStatus, isValidator) => {
		if (!newStatus) {
			return;
		}
		const finalStatus = removeStatus ? POSITIONS_STATUSES.UNREVIEWED : newStatus;
		const request = isValidator
			? ValidatorService.validate({ infoId: informationId }, finalStatus, cancelTokenSourceRef.current.token)
			: CollabService.updateStatus({ infoId: informationId }, finalStatus, cancelTokenSourceRef.current.token);
		request
			.then(() => {
				loadPositionsCounts();
				if (isValidator) {
					const updates = [
						{
							property: "validatedSentenceType",
							value: newStatus === "information" ? newStatus : "requirement",
						},
					];
					if (newStatus !== "information") {
						updates.push({
							property: "validatedStatus",
							value: finalStatus,
						});
					}
					dispatch(
						updateNode({
							mode: ROLES.VALIDATOR.mode,
							pageConditions: { page: pageStart, border: true, length: savedImageLength },
							informationId,
							updates,
						})
					);
				}
				if (isValidator) {
					loadCompanyPosition(informationId);
				} else {
					loadMyPosition(informationId);
					dispatch(
						updateNode({
							mode: ROLES.REVIEWER.mode,
							pageConditions: { page: pageStart, border: true, length: savedImageLength },
							informationId,
							updates: [
								{
									property: "lastStatus",
									value: finalStatus.toLowerCase(),
								},
							],
						})
					);
				}
			})
			.catch((err) => console.error(err));
	};
	const handleAddComment = (newComment, editAsValidator = false) => {
		if (myPosition?.comment === newComment) {
			return;
		}
		const request =
			(editAsValidator &&
				CollabService.updateIssueComment({ informationId }, newComment, cancelTokenSourceRef.current.token)) ||
			(!myPosition?.comment &&
				CommentService.addPrimeComment(
					{ informationId },
					{ value: newComment },
					cancelTokenSourceRef.current.token
				)) ||
			CommentService.updateAnswerComment(
				{ commentId: myPosition?.commentId },
				{ value: newComment },
				cancelTokenSourceRef.current.token
			);
		if (request) {
			request
				.then(() => {
					if (editAsValidator) {
						loadCompanyPosition(informationId);
					} else {
						loadMyPosition(informationId);
					}
					dispatch(
						updateNode({
							mode: selectedMode,
							pageConditions: { page: pageStart },
							informationId,
							updates: [{ property: "lastCommentIssue", value: newComment }],
						})
					);
				})
				.catch((err) => {
					console.error(err);
				});
		}
		setTempComment("");
	};
	const handleEditComment = (oldComment) => {
		setTempComment(oldComment);
	};
	const handleChangeTempComment = (newComment) => {
		if ((/\S/.test(newComment) && (tempComment?.length === 0 || !tempComment)) || tempComment?.length > 0) {
			setTempComment(newComment);
		}
	};
	const handleChangeTempIssueComment = (newIssueComment) => {
		setTempIssueComment(newIssueComment);
	};
	return (
		<div className={styles.container} data-disable-diting={disableEditing}>
			{(isRequirement && !disableEditing) || (isRequirementAdditional && disableEditing) ? (
				<>
					{((myDocInfo?.roles || []).includes(Permissions.REVIEWER.role) || disableEditing) && (
						<PositionComment
							disableEditing={disableEditing}
							editable={!disableEditing}
							isEditingAsValidator={selectedMode === "val"}
							position={myPosition}
							primaryName={translate(
								"smart-review.cmp-details.option-panel.positions-tab.position-box.my-review-position"
							)}
							tempComment={tempComment}
							onAddComment={(newComment) => handleAddComment(newComment, false)}
							onChangePositionStatus={(status, removeStatus) => {
								handleChangeStatus(status, removeStatus, false);
							}}
							onChangeTempComment={handleChangeTempComment}
							onEditComment={handleEditComment}
						/>
					)}
					<ViewTabs
						className={styles.tabs}
						selectedTab={selectedTab}
						tabs={[
							{
								label: translate("smart-review.right-panel.positions-tab.my-team.counter", {
									count: teamCounts.reviewers || 0,
								}),
							},
							...[
								(isFeatureEnabled(Flags.PARTNERS) &&
									isSegFeatureEnabled(SegFlags.PARTNERS) && {
										label: translate("smart-review.right-panel.positions-tab.all-teams.counter", {
											count: teamCounts.teams || 0,
										}),
									}) ||
									[],
							],
						]}
						onChange={(_, tab) => {
							setSelectedTab(tab);
						}}
					/>
					<PositionComment
						isValidation
						editable={selectedMode === "val" && !disableEditing}
						isEditingAsValidator={selectedMode === "val"}
						position={myTeamPosition}
						primaryName={
							<I18nTranslate
								param={{ validatorName: myTeamPosition.companyName }}
								translationKey="smart-review.cmp-details.option-panel.positions-tab.position-box.validation-position"
							/>
						}
						secondaryName={(selectedTab === 1 && myTeamPosition.username) || ""}
						tempComment={tempIssueComment}
						onAddComment={(newComment) => handleAddComment(newComment, true)}
						onChangePositionStatus={(status, removeStatus) => {
							handleChangeStatus(status, removeStatus, true);
						}}
						onChangeTempComment={handleChangeTempIssueComment}
						onEditComment={handleEditComment}
					/>
					{tabs.map((tab, index) => {
						const counts = index === 0 ? myTeamPositionsCounts : otherTeamsPositionsCounts;
						return (
							<ViewTabPanel
								key={tab.label}
								noPadding
								className={styles.viewTabPanel}
								data-disable-editing={disableEditing}
								index={index}
								value={selectedTab}
							>
								<MultipleProgressBar
									className={styles.positionsCounter}
									colors={[
										"var(--color-green)",
										"var(--color-orange)",
										"var(--color-red)",
										"var(--color-black)",
										"var(--color-light-grey-1)",
									]}
									progresses={[
										counts.yes,
										counts.pending,
										counts.no,
										counts.notMe,
										counts.unreviewed,
									]}
									tooltips={[
										translateEnumStatus("yes"),
										translateEnumStatus("pending"),
										translateEnumStatus("no"),
										translateEnumStatus("not-me"),
										translateEnumStatus("unreviewed"),
									]}
								/>
								<InfiniteList2
									className={
										(disableEditing && styles.infinityListContainer__showAll) ||
										((myDocInfo?.roles || []).includes(Permissions.REVIEWER.role) &&
										((myPosition?.comment && myPosition.comment?.length > 80) ||
											(myTeamPosition?.comment && myTeamPosition.comment?.length > 80))
											? styles.infinityListContainer__largeText
											: styles.infinityListContainer__shortText) ||
										styles["infinityListContainer--noPosition"]
									}
									listContainerClassName={styles.listContainer}
									request={inifiniteListRequests[index]}
									rowRenderer={infiniteListRowRenderer}
									scrollContainerClassName={styles.scrollContainer}
								/>
							</ViewTabPanel>
						);
					})}
				</>
			) : (
				<div className={styles.emptyState}>{translate("smart-review.right-panel.empty-state")}</div>
			)}
		</div>
	);
}
