import React from "react";
import { MenuList } from "@mui/material";
import { MenuItemSelectable, MenuItemDropDown, DropDownMenu, MenuButton } from "..";
import { translate } from "../../../common/providers";
import { checkValidity } from "../..";

export default function CommonMenu({
	currentOpenedMenu,
	commonMenus,
	onIsSelected,
	onClose,
	onOpen,
	onOpenHover,
	onSelect,
	onIncludes,
}) {
	return (
		Array.isArray(commonMenus) &&
		commonMenus.map((projectMenu) => {
			if (!checkValidity(projectMenu)) {
				return null;
			}
			if (Array.isArray(projectMenu.subItems) && projectMenu.subItems.length > 0) {
				const subItems = projectMenu.subItems.filter((m) => checkValidity(m));
				return subItems.length === 0 ? null : (
					<DropDownMenu
						key={projectMenu.name}
						bold
						menuName={projectMenu.name}
						open={currentOpenedMenu === projectMenu.name}
						selected={onIncludes(subItems)}
						title={translate(projectMenu.name)}
						onClose={onClose}
						onOpen={onOpen}
						onOpenHover={onOpenHover}
					>
						<MenuList>
							{subItems.map((menuItem) =>
								menuItem.subItems ? (
									<MenuItemDropDown
										key={menuItem.name}
										menuItem={menuItem}
										onClose={onClose}
										onIncludes={onIncludes}
										onIsSelected={onIsSelected}
										onSelect={onSelect}
									/>
								) : (
									checkValidity(menuItem) && (
										<MenuItemSelectable
											key={menuItem.name}
											menuItem={menuItem}
											onIsSelected={onIsSelected}
											onSelect={onSelect}
										/>
									)
								)
							)}
						</MenuList>
					</DropDownMenu>
				);
			}
			return (
				<MenuButton
					key={projectMenu.name}
					selected={onIsSelected(projectMenu.to)}
					onClick={(event) => {
						onSelect(projectMenu, event, null);
					}}
				>
					{translate(projectMenu.name)}
				</MenuButton>
			);
		})
	);
}
