import React, { useEffect, useMemo, useRef, useState } from "react";
import { batch, useSelector, useDispatch } from "react-redux";
import styles from "./SelectionLayer.module.css";
import { CustomIconButton, VerticalDivider, createNotification, icon } from "../../../../../../../common/components";
import {
	setHideFusionDialogDisplay,
	setSelectedRectangles,
	setSelectedSearch,
	setSelectionBarCoordinates,
	setSelectionSidePanelOpen,
	setSidePanel,
} from "../../../../../slice/document/document-slice";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../../../../common/providers";
import ClassificationDialog from "./dialogs/classification/ClassificationDialog";
import {
	hasAnyMultipleReviewPermissions,
	hasReviewPermissions,
} from "../../../../../permissions/review-permissions-provider";
import REVIEW_PERMISSIONS from "../../../../../permissions/review-permissions";
import { hasUpdateRights } from "../../../../../utils/utils";
import RedressDialog from "../../layers/components/redress-dialog/RedressDialog";
import { areNumbersInArrayConsecutive, isNonEmptyArray } from "../../../../../../../common/utils";
import redressmentService from "../../../../../../../api/services/redressment-service";
import { ApiService } from "../../../../../../../api";
import { resetContent } from "../../../../../slice/pdf/pdf-slice";
import { drawSelectionBox } from "../../../utils/utils";

export default function SelectionLayer({ end, selectionRectangleRef, start }) {
	const dispatch = useDispatch();
	const [openClassificationDialog, setOpenClassificationDialog] = useState(false);
	const [openRedressDialog, setOpenRedressDialog] = useState(false);
	const [loadingCombination, setLoadingCombination] = useState(false);
	const docId = useSelector(({ srDocument }) => srDocument.documentId);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const isFrozen = useSelector(({ srDocument }) => srDocument.isFrozen);
	const roles = useSelector(({ srDocument }) => srDocument.roles);
	const selectionBarCoordinates = useSelector(({ srDocument }) => srDocument.selectionBarCoordinates);
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const hideFusionDialogDisplay = useSelector(({ srDocument }) => srDocument.hideFusionDialogDisplay);
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			cancelTokenSourceRef.current.cancel();
		};
	}, []);
	useEffect(() => {
		if (selectedRectangles.length > 1) {
			dispatch(setSelectionSidePanelOpen(true));
		}
	}, [dispatch, selectedRectangles]);
	useEffect(() => {
		const handleMouseMove = (event) => {
			const divRect = document.getElementById("selectionActionBar")?.getBoundingClientRect();
			if (divRect) {
				const extendedRect = {
					left: divRect.left - 32,
					top: divRect.top - 32,
					right: divRect.right + 32,
					bottom: divRect.bottom + 32,
				};
				const mouseX = event.clientX;
				const mouseY = event.clientY;
				if (
					mouseX < extendedRect.left ||
					mouseX > extendedRect.right ||
					mouseY < extendedRect.top ||
					mouseY > extendedRect.bottom
				) {
					dispatch(setSelectionBarCoordinates(null));
				}
			}
		};
		document.addEventListener("mousemove", handleMouseMove);
		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
		};
	}, [dispatch]);
	const handleOpenClassificationDialog = () => {
		setOpenClassificationDialog(true);
	};
	const handleCloseClassificationDialog = () => {
		setOpenClassificationDialog(false);
	};
	const handleOpenRedressDialog = () => {
		setOpenRedressDialog(true);
	};
	const handleCloseRedressDialog = () => {
		setOpenRedressDialog(false);
	};
	const handleCheck = () => dispatch(setHideFusionDialogDisplay(!hideFusionDialogDisplay));
	const redressingDetails = useMemo(() => {
		if (!isNonEmptyArray(selectedRectangles)) {
			return {};
		}
		const requirement = selectedRectangles.find((p) => p.isReq);
		const rectangles = selectedRectangles.map((p) => p.rectangles).flat();
		const min = Math.min(...rectangles.map((r) => r.order));
		const minContentId = rectangles.find((r) => r.order === min)?.contentId;
		const max = Math.max(...rectangles.map((r) => r.order));
		const redressingId =
			requirement?.informationId || selectedRectangles.find((sr) => sr.contentId === minContentId)?.informationId;
		return { redressingId, min, max };
	}, [selectedRectangles]);
	const handleSubmitRedressDialog = () => {
		const { min, max, redressingId } = redressingDetails;
		if (redressingId && min && max) {
			setLoadingCombination(true);
			redressmentService
				.proceedRedressInfo(
					{ docId, infoId: redressingId },
					{
						min,
						max,
					},
					cancelTokenSourceRef.current.token
				)
				.then((pages) => {
					batch(() => {
						dispatch(setSelectionSidePanelOpen(false));
						dispatch(
							setSidePanel({
								sidePanelOpen: false,
								informationId: null,
								page: 0,
								tabToOpen: "",
								isRequirement: null,
							})
						);
						dispatch(setSelectedSearch(null));
						createNotification({
							type: "success",
							message: translate("smart-review.redress-info.success"),
						});
						dispatch(resetContent({ pages, mode }));
						dispatch(setSelectedRectangles(null));
						dispatch(setSelectionBarCoordinates(null));
					});
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					if (openRedressDialog) {
						handleCloseRedressDialog();
					}
					setLoadingCombination(false);
				});
		}
	};
	const handleConfirmInfoRedressing = () => {
		if (!hideFusionDialogDisplay) {
			handleOpenRedressDialog();
		} else {
			handleSubmitRedressDialog();
		}
	};
	const isOpenable = useMemo(
		() =>
			!isFrozen &&
			(isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS) || isSegFeatureEnabled(SegFlags.REDRESSEMENT)) &&
			hasUpdateRights(roles) &&
			hasAnyMultipleReviewPermissions(
				[
					REVIEW_PERMISSIONS.CATEGORY_UPDATE,
					REVIEW_PERMISSIONS.CHANGE_TYPE_NO_REVIEW,
					REVIEW_PERMISSIONS.CHANGE_REQ_TO_INFO_WITH_STATUS,
					REVIEW_PERMISSIONS.GROUP_SEPARATE_INFO,
				],
				{ roles }
			),
		[isFrozen, roles]
	);

	const isSelectionContiguous = useMemo(() => {
		const orderIds = selectedRectangles
			.map((p) => p.rectangles)
			.flat()
			.map((p) => p.order);
		return areNumbersInArrayConsecutive(orderIds);
	}, [selectedRectangles]);

	useEffect(
		() => {
			if (start && end && selectionRectangleRef?.current) {
				drawSelectionBox(selectionRectangleRef.current, start, end);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[start, end]
	);

	return (
		<>
			{start && end && <div ref={selectionRectangleRef} className={styles.selectionRectangle} />}
			{isOpenable && selectionBarCoordinates && selectedRectangles.length > 1 && (
				<div
					className={styles.actionBar}
					data-group={hasReviewPermissions(REVIEW_PERMISSIONS.GROUP_SEPARATE_INFO, { roles })}
					id="selectionActionBar"
					style={{
						left: selectionBarCoordinates.x - 44,
						top: selectionBarCoordinates.y - 40,
					}}
				>
					{isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS) &&
						hasReviewPermissions(REVIEW_PERMISSIONS.CHANGE_TYPE_NO_REVIEW, { roles }) && (
							<CustomIconButton
								icon={icon.faExchangeAlt}
								size="md"
								tooltip={translate("smart-review.selection-bar.reclassify")}
								onClick={handleOpenClassificationDialog}
							/>
						)}
					{isSegFeatureEnabled(SegFlags.REDRESSEMENT) &&
						hasReviewPermissions(REVIEW_PERMISSIONS.GROUP_SEPARATE_INFO, { roles }) && (
							<>
								<VerticalDivider />
								<CustomIconButton
									data-testid="group.btn"
									disabled={!isSelectionContiguous || selectedRectangles.length < 2}
									icon={icon.faObjectGroup}
									size="md"
									tooltip={
										(selectedRectangles.length < 2 &&
											translate(
												"smart-review.selection-bar.group.disabled.insufficient-length"
											)) ||
										(!isSelectionContiguous &&
											translate("smart-review.selection-bar.group.disabled.non-contiguous")) ||
										translate("smart-review.selection-bar.group")
									}
									onClick={handleConfirmInfoRedressing}
								/>
							</>
						)}
				</div>
			)}
			<ClassificationDialog open={openClassificationDialog} onClose={handleCloseClassificationDialog} />
			<RedressDialog
				count={selectedRectangles.length}
				hidden={hideFusionDialogDisplay}
				open={openRedressDialog}
				redressingId={redressingDetails?.redressingId}
				submitDisabled={loadingCombination}
				onClose={handleCloseRedressDialog}
				onHide={handleCheck}
				onSubmit={handleSubmitRedressDialog}
			/>
		</>
	);
}
