import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { CustomTooltip, icon, IconComponent } from "../../../../../../common/components";
import styles from "./Document.module.css";
import { translate } from "../../../../../../common/providers";

const Document = ({ document, index }) => (
	<>
		<Draggable draggableId={`DOC_${document.id.toString()}`} index={index} type="Document">
			{(provided, snapshot) => (
				<div
					style={snapshot.isDragging && { ...provided.draggableProps.style }}
					{...provided.draggableProps}
					key={document.id}
					ref={provided.innerRef}
					className={styles.document}
				>
					<div className={styles.document__handle} {...provided.dragHandleProps}>
						<IconComponent
							color="var(--color-dark-grey-1)"
							data-testid={`draggableDoc_${document.id}`}
							icon={icon.faGripLines}
							size="lg"
						/>
					</div>
					<div className={styles.docRowInformationContainer}>
						<div className={styles.documentInformation}>
							<CustomTooltip showWhenOverflow title={document.documentName}>
								<span
									className={styles.documentName}
									data-automatically-classified={document.source === "AUTOMATIC"}
									data-testid="precedence.document.name"
								>
									{document.documentName}
								</span>
							</CustomTooltip>
							{document.source === "AUTOMATIC" && (
								<span className={styles.autoClassified__helpertext}>
									({translate("document-center.side-panel.manage-document.automatically-classified")})
								</span>
							)}
						</div>
					</div>
				</div>
			)}
		</Draggable>
	</>
);

export default Document;
