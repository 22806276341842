import {
	faAlignCenter,
	faAlignLeft,
	faAsterisk,
	faAngleDoubleRight,
	faAngleRight,
	faArrowDown,
	faArrowLeft,
	faArrowRight,
	faArrowRightArrowLeft,
	faArrowUp,
	faBan,
	faBars,
	faBarsStaggered,
	faBell,
	faBookOpenReader,
	faBookmark,
	faBriefcase,
	faBuilding,
	faCalendar,
	faCalendarAlt,
	faCalendarCheck,
	faCalendarDay,
	faCaretDown,
	faCaretLeft,
	faCaretRight,
	faCaretUp,
	faChartPie,
	faCheck,
	faCheckCircle,
	faCheckDouble,
	faCheckSquare,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faClipboardCheck,
	faClipboardList,
	faClock,
	faCodeBranch,
	faCodeFork,
	faCog,
	faColumns,
	faComment,
	faComments,
	faCompassDrafting,
	faCopy,
	faCubes,
	faDownload,
	faDraftingCompass,
	faEllipsisH,
	faEllipsisV,
	faEnvelopeOpen,
	faEquals,
	faExchangeAlt,
	faExclamation,
	faExclamationCircle,
	faExclamationTriangle,
	faEye,
	faEyeSlash,
	faFile,
	faFileAlt,
	faFileArchive,
	faFileArrowUp,
	faFileExport,
	faFileImport,
	faFilePdf,
	faFileSignature,
	faFileUpload,
	faFilter,
	faFlag,
	faFolder,
	faFolderOpen,
	faFolderPlus,
	faGavel,
	faGlobe,
	faGripLines,
	faHammer,
	faHandPaper,
	faHandPointUp,
	faHandsHelping,
	faHashtag,
	faHome,
	faHourglassHalf,
	faInbox,
	faInfo,
	faInfoCircle,
	faKey,
	faLayerGroup,
	faList,
	faLock,
	faLockOpen,
	faMinus,
	faMinusCircle,
	faMinusSquare,
	faMoneyCheck,
	faNotEqual,
	faObjectGroup,
	faObjectUngroup,
	faPaperPlane,
	faPen,
	faPenFancy,
	faPlus,
	faPlusCircle,
	faPlusSquare,
	faQuestionCircle,
	faRadiation,
	faRedoAlt,
	faSave,
	faSearch,
	faShapes,
	faShareAlt,
	faShield,
	faShieldAlt,
	faSignInAlt,
	faSitemap,
	faSliders,
	faSort,
	faSortAmountDown,
	faSortAmountUp,
	faSortDown,
	faSortUp,
	faSquare,
	faStar,
	faStarOfLife,
	faStepBackward,
	faStepForward,
	faSyncAlt,
	faTable,
	faTag,
	faTimes,
	faTimesCircle,
	faTrash,
	faTrashArrowUp,
	faUndo,
	faUpload,
	faUpRightFromSquare,
	faUpRightAndDownLeftFromCenter,
	faDownLeftAndUpRightToCenter,
	faUser,
	faUserCheck,
	faUserCog,
	faUserEdit,
	faUserFriends,
	faUserPlus,
	faSquareXmark,
	faUsers,
	faUserTie,
	faRefresh,
	faPenToSquare,
	faStream,
	faHandshakeAngle,
	faFileLines,
	faWandMagic,
	faFileWord,
	faFileExcel,
	faFileZipper,
	faEnvelope,
	faLink,
	faWandMagicSparkles,
	faArrowUpFromBracket,
	faLocationCrosshairs,
} from "@fortawesome/free-solid-svg-icons";
import {
	faCalendarCheck as faCalendarCheckRegular,
	faChartBar as faChartBarRegular,
	faCheckCircle as faCheckCircleRegular,
	faClock as faClockRegular,
	faFileArchive as faFileArchiveRegular,
	faHourglass as faHourglassRegular,
	faQuestionCircle as faQuestionCircleRegular,
	faSquare as faSquareRegular,
	faStar as faStarRegular,
	faTimesCircle as faTimesCircleRegular,
	faFile as faFileRegular,
} from "@fortawesome/free-regular-svg-icons";

export default {
	faAlignCenter,
	faAlignLeft,
	faAngleDoubleRight,
	faAngleRight,
	faArrowDown,
	faAsterisk,
	faArrowLeft,
	faArrowRight,
	faArrowRightArrowLeft,
	faArrowUp,
	faArrowUpFromBracket,
	faBan,
	faBars,
	faBarsStaggered,
	faBell,
	faBookOpenReader,
	faBookmark,
	faBriefcase,
	faBuilding,
	faCalendar,
	faCalendarAlt,
	faCalendarCheck,
	faCalendarCheckRegular,
	faCalendarDay,
	faCaretDown,
	faCaretLeft,
	faCaretRight,
	faCaretUp,
	faSquareXmark,
	faChartBarRegular,
	faChartPie,
	faCheck,
	faCheckCircle,
	faCheckCircleRegular,
	faCheckDouble,
	faCheckSquare,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faClipboardCheck,
	faClipboardList,
	faClock,
	faClockRegular,
	faCodeBranch,
	faCodeFork,
	faCog,
	faColumns,
	faComment,
	faComments,
	faCompassDrafting,
	faCopy,
	faCubes,
	faDownload,
	faDraftingCompass,
	faEllipsisH,
	faEllipsisV,
	faEnvelopeOpen,
	faEquals,
	faExchangeAlt,
	faExclamation,
	faExclamationCircle,
	faExclamationTriangle,
	faEye,
	faEyeSlash,
	faFile,
	faFileAlt,
	faFileArchive,
	faFileArrowUp,
	faFileArchiveRegular,
	faFileExport,
	faFileImport,
	faFilePdf,
	faFileSignature,
	faFileUpload,
	faFilter,
	faFlag,
	faFolder,
	faFolderOpen,
	faFolderPlus,
	faGavel,
	faGlobe,
	faGripLines,
	faHammer,
	faHandPaper,
	faHandPointUp,
	faHandsHelping,
	faHashtag,
	faHome,
	faHourglassHalf,
	faHourglassRegular,
	faInbox,
	faInfo,
	faInfoCircle,
	faKey,
	faLayerGroup,
	faList,
	faLock,
	faLockOpen,
	faMinus,
	faMinusCircle,
	faMinusSquare,
	faMoneyCheck,
	faNotEqual,
	faObjectGroup,
	faObjectUngroup,
	faPaperPlane,
	faPen,
	faPenFancy,
	faPlus,
	faPlusCircle,
	faPlusSquare,
	faQuestionCircle,
	faQuestionCircleRegular,
	faRadiation,
	faRedoAlt,
	faSave,
	faSearch,
	faShapes,
	faShareAlt,
	faShield,
	faShieldAlt,
	faSignInAlt,
	faSitemap,
	faSliders,
	faSort,
	faSortAmountDown,
	faSortAmountUp,
	faSortDown,
	faSortUp,
	faSquare,
	faSquareRegular,
	faStar,
	faStarOfLife,
	faStarRegular,
	faStepBackward,
	faStepForward,
	faSyncAlt,
	faTable,
	faTag,
	faTimes,
	faTimesCircle,
	faTimesCircleRegular,
	faFileRegular,
	faTrash,
	faTrashArrowUp,
	faUndo,
	faUpload,
	faUpRightFromSquare,
	faUpRightAndDownLeftFromCenter,
	faDownLeftAndUpRightToCenter,
	faUser,
	faUserCheck,
	faUserCog,
	faUserEdit,
	faUserFriends,
	faUserPlus,
	faUsers,
	faUserTie,
	faRefresh,
	faPenToSquare,
	faStream,
	faHandshakeAngle,
	faFileLines,
	faWandMagic,
	faWandMagicSparkles,
	faFileWord,
	faFileExcel,
	faFileZipper,
	faEnvelope,
	faLink,
	faLocationCrosshairs,
};
