import client from "../../client/client";

class TransverseService {
	getDocuments({ projectId }, { page = 0, limit = 25 }, payload, cancelToken) {
		return client.post(`/smartview/projects/${projectId}/documents`, {
			payload,
			params: { page, limit },
			config: { cancelToken },
		});
	}

	getDocumentRoot({ projectId, documentId }, filters, cancelToken) {
		return client.post(`/smartview/projects/${projectId}/documents/${documentId}/clauses`, {
			payload: filters,
			config: { cancelToken },
		});
	}

	getDocumentClause({ projectId, documentId, clauseId }, filters, cancelToken) {
		return client.post(`/smartview/projects/${projectId}/documents/${documentId}/clauses/${clauseId}`, {
			payload: filters,
			config: { cancelToken },
		});
	}

	getCount({ projectId }, filters, cancelToken) {
		return client.post(`/smartview/projects/${projectId}/documents/count`, {
			payload: filters,
			config: { cancelToken },
		});
	}

	getInformation({ projectId }, { page = 0, limit = 25 }, filters, cancelToken) {
		return client.post(`/smartview/projects/${projectId}/documents/filter`, {
			payload: filters,
			params: { page, limit },
			config: { cancelToken },
		});
	}
}

export default new TransverseService();
