import React from "react";
import { TextField } from "@mui/material";
import styles from "./OpportunityName.module.css";
import { translate } from "../../../../../../../common/providers";

export default function OpportunityName({ error, name, onChange }) {
	return (
		<div className={styles.main}>
			<span className={styles.title}>
				{translate("opportunity.creation.side-panel.name.title")} <span className={styles.mandatory}>*</span>
			</span>
			<TextField
				fullWidth
				required
				error={name === "" || error}
				helperText={
					(name === "" && translate("opportunity.creation.side-panel.name.error")) ||
					(error && translate("opportunity.creation.side-panel.name.error.existing-name"))
				}
				size="small"
				value={name}
				onChange={onChange}
			/>
		</div>
	);
}
