import { Box } from "@mui/material";
import React from "react";
import CircularLoader from "./CircularLoader";
import styles from "./CircularLoaderWithBackground.module.css";

export default function CircularLoaderWithBackground({
	className = "",
	size = 100,
	color = "primary",
	thickness = 3.6,
	value = 0,
	variant = "indeterminate",
}) {
	return (
		<Box sx={{ position: "relative" }}>
			<CircularLoader
				className={`${styles.loader__background} ${className}`}
				size={size}
				thickness={thickness}
				value={100}
				variant="determinate"
			/>
			<CircularLoader
				className={`${styles.loader} ${className}`}
				color={color}
				size={size}
				thickness={thickness}
				value={value}
				variant={variant}
			/>
		</Box>
	);
}
