import React from "react";
import {
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	icon,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import styles from "./NewVersionTabTable.module.css";

export default function NewVersionTabTable({ newVersions, onSelectRow }) {
	return (
		<CustomTable>
			<CustomTableHead>
				<CustomTableRow>
					<CustomTableCell>{translate("norms-center.norm.tabs.new-version.table.norm")}</CustomTableCell>
					<CustomTableCell>{translate("norms-center.norm.tabs.new-version.table.date")}</CustomTableCell>
					<CustomTableCell className={styles.normVersion__headerCellBtn} />
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody>
				{Array.isArray(newVersions) &&
					newVersions.map((row) => (
						<CustomTableRow
							key={row.id}
							onClick={() => {
								onSelectRow(row);
							}}
						>
							{({ isHovering }) => (
								<>
									<CustomTableCell>{row.name}</CustomTableCell>
									<CustomTableCell>{row.date}</CustomTableCell>
									<CustomTableCell align="right">
										{isHovering && (
											<CustomIconButton
												icon={icon.faArrowRight}
												size="sm"
												onClick={() => {
													onSelectRow(row);
												}}
											/>
										)}
									</CustomTableCell>
								</>
							)}
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
