import { Radio, TableContainer } from "@mui/material";
import React, { useState } from "react";
import {
	CustomButton,
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	icon,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import styles from "./UsersListTable.module.css";

export default function UsersListTable({
	users,
	COMPANY_ROLES,
	loggedUser,
	onChangeRole,
	onRegenPassword,
	onChangeLock,
	onSelectRow,
	selectedUser,
}) {
	const [headers] = useState(() => [
		{ title: translate("dsi.user-list.th.name") },
		{ title: translate("dsi.user-list.th.admin") },
		{ title: translate("dsi.user-list.th.manager") },
		{ title: translate("dsi.user-list.th.member") },
		{ title: translate("dsi.user-list.th.actions"), className: styles.tableRow__actionsCell },
	]);
	return (
		<TableContainer className={styles.tableContainer}>
			<CustomTable stickyHeader>
				<CustomTableHead>
					<CustomTableRow>
						{headers.map((header) => (
							<CustomTableCell key={header.title} className={`${styles.header} ${header.className}`}>
								{header.title}
							</CustomTableCell>
						))}
					</CustomTableRow>
				</CustomTableHead>
				<CustomTableBody emptyState={translate("dsi.user-list.empty-state.user")}>
					{Array.isArray(users) &&
						users.map((row) => (
							<CustomTableRow
								key={row.id}
								hover
								className={selectedUser === row && styles["tableRow--selected"]}
							>
								<CustomTableCell autoTranslate="no">{row.displayName || row.email}</CustomTableCell>
								{Array.isArray(COMPANY_ROLES) &&
									COMPANY_ROLES.map((role) => (
										<CustomTableCell key={role.name}>
											<Radio
												checked={row.companyRole === role.name}
												color="primary"
												disabled={row.id === loggedUser.id}
												onChange={() => onChangeRole({ role: role.name, user: row })}
											/>
										</CustomTableCell>
									))}
								<CustomTableCell>
									<CustomIconButton
										icon={icon.faKey}
										tooltip={translate("dsi.user-list.regenerate-password")}
										variant="contained"
										onClick={() => onRegenPassword({ user: row })}
									/>
									<CustomIconButton
										className={styles.tableRow__lockButton}
										icon={row.locked ? icon.faLock : icon.faLockOpen}
										tooltip={
											row.locked
												? translate("dsi.user-list.unlock-user-access")
												: translate("dsi.user-list.lock-user-access")
										}
										variant="contained"
										onClick={() => onChangeLock({ user: row })}
									/>
									<CustomButton variant="contained" onClick={() => onSelectRow({ user: row })}>
										{translate("dsi.user-list.details")}
									</CustomButton>
								</CustomTableCell>
							</CustomTableRow>
						))}
				</CustomTableBody>
			</CustomTable>
		</TableContainer>
	);
}
