import React from "react";
import { CustomDialog, Selector } from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import styles from "./MatchDialog.module.css";

export default function MatchDialog({
	children,
	defaultOption = null,
	onChangeSelectedOption,
	onClose,
	open,
	options = [],
	onSubmit,
	selectedOption = null,
	selectField = "",
	submitDisabled,
	title,
}) {
	const handleChangeSelectedOption = (e) => {
		const { value } = e.target;
		onChangeSelectedOption(value === defaultOption ? null : value);
	};
	return (
		<CustomDialog
			isForm
			open={open}
			submitDisabled={submitDisabled}
			submitLabel={translate("smart-review.match-dialog.submit")}
			title={title}
			onClose={onClose}
			onSubmit={onSubmit}
		>
			<div className={styles.dialog}>
				<Selector
					className={styles.select}
					fallbackField="email"
					field={selectField}
					items={[defaultOption, ...options]}
					value={selectedOption || defaultOption}
					onChange={handleChangeSelectedOption}
				/>
				{children}
			</div>
		</CustomDialog>
	);
}
