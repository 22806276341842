import React from "react";
import {
	CustomButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableRow,
	CustomTooltip,
	icon,
	IconComponent,
	Tile,
	TileBody,
	TileHeader,
	VerticalDivider,
} from "../../../../common/components";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../common/providers";
import { NavUtils } from "../../../../navigation";
import styles from "./ProjectTeamInfo.module.css";

const ProjectTeamInfo = ({ onChangeUrl, partners, projectId }) => (
	<Tile>
		<TileHeader
			className={styles.header}
			iconName={icon.faUsers}
			title={translate("dashboards.project-team-info-card.title")}
		/>
		<TileBody>
			<div className={styles.container}>
				<div className={`${styles.container__half} ${styles["container__half--left"]}`}>
					<div className={styles.tableContainer}>
						<CustomTable dense>
							<CustomTableBody>
								{Array.isArray(partners.companiesAndUsers) &&
									partners.companiesAndUsers.map((partner) => (
										<CustomTableRow key={partner.companyId}>
											<CustomTableCell className={styles.partnerCell}>
												<CustomTooltip showWhenOverflow title={partner.companyName}>
													<span className={styles.partnerName}>{partner.companyName}</span>
												</CustomTooltip>
											</CustomTableCell>
											<CustomTableCell className={styles.partnerCell}>
												<span className={styles.partnerCount}>
													<IconComponent
														color="var(--color-light-grey-1)"
														icon={icon.faUser}
													/>
													<span className={styles.userCount}>{partner.users}</span>
												</span>
											</CustomTableCell>
										</CustomTableRow>
									))}
							</CustomTableBody>
						</CustomTable>
					</div>
				</div>
				<VerticalDivider />
				<div className={`${styles.container__half} ${styles["container__half--right"]}`}>
					<div className={styles.figure}>
						<span className={styles.figure__number}>{partners.totalCompanies}</span>
						<span className={styles.figure__label}>
							{translate("dashboards.project-team-info-card.partners")}
						</span>
					</div>
					<div className={styles.figure}>
						<span className={styles.figure__number}>{partners.totalUsers}</span>
						<span className={styles.figure__label}>
							{translate("dashboards.project-team-info-card.total-users")}
						</span>
					</div>
					{isSegFeatureEnabled(SegFlags.TEAM) && (
						<CustomButton
							className={styles.manageTeamBtn}
							variant="outlined"
							onClick={() => onChangeUrl(NavUtils.goToTeam(projectId))}
						>
							<CustomTooltip
								showWhenOverflow
								title={translate("dashboards.project-team-info-card.display-team")}
							>
								<span className={styles.manageTeamBtn__text}>
									{translate("dashboards.project-team-info-card.display-team")}
								</span>
							</CustomTooltip>
						</CustomButton>
					)}
				</div>
			</div>
		</TileBody>
	</Tile>
);

export default ProjectTeamInfo;
