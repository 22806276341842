import { Chip } from "@mui/material";
import React, { useState } from "react";
import {
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	icon,
} from "../../../../../common/components";
import { translate, translateDate } from "../../../../../common/providers";
import styles from "./ProjectsListTable.module.css";

export default function ProjectsListTable({
	isCanvas,
	onAddPartner,
	onAddUser,
	onDownload,
	onRelaunchDocs,
	onSelectRow,
	projects,
}) {
	const [tableHeaders] = useState([
		translate("dsi.project-list.th.name"),
		translate("dsi.project-list.th.creation-date"),
		translate("dsi.project-list.th.company-role"),
		translate("dsi.project-list.th.status"),
		translate("dsi.project-list.th.actions"),
	]);
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					{tableHeaders.map((header) => (
						<CustomTableCell key={header} className={styles.header}>
							{header}
						</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody emptyState={translate("dsi.project-list.project")}>
				{Array.isArray(projects) &&
					projects.map((row) => (
						<CustomTableRow key={row.id}>
							<CustomTableCell>{row.name}</CustomTableCell>
							<CustomTableCell>{translateDate(row.created)}</CustomTableCell>
							<CustomTableCell>{row.role}</CustomTableCell>
							<CustomTableCell>
								<Chip
									color={row.currentStatus === "ACTIVE" ? "primary" : "default"}
									label={row.currentStatus}
									size="medium"
								/>
							</CustomTableCell>
							<CustomTableCell>
								{!isCanvas && (
									<>
										<CustomIconButton
											icon={icon.faInfoCircle}
											tooltip={translate("dsi.project-list.action.update-project-details")}
											variant="contained"
											onClick={() => onSelectRow(row)}
										/>
										<CustomIconButton
											icon={icon.faUserPlus}
											tooltip={translate("dsi.project-list.action.add-user")}
											variant="contained"
											onClick={() => onAddUser(row)}
										/>
										<CustomIconButton
											icon={icon.faUserCog}
											tooltip={translate("dsi.project-list.action.add-project-partner")}
											variant="contained"
											onClick={() => onAddPartner(row)}
										/>
										<CustomIconButton
											icon={icon.faDownload}
											tooltip={translate("dsi.project-list.action.download-project-docs")}
											variant="contained"
											onClick={() => onDownload(row)}
										/>
										<CustomIconButton
											icon={icon.faRefresh}
											tooltip={translate("dsi.project-list.action.relaunch")}
											variant="contained"
											onClick={() => onRelaunchDocs(row)}
										/>
									</>
								)}
								{isCanvas && (
									<CustomIconButton
										icon={icon.faFile}
										tooltip="Edit document roles"
										variant="contained"
										onClick={() => onSelectRow(row)}
									/>
								)}
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
