import React from "react";
import { Divider } from "@mui/material";
import { icon, CustomButton } from "../../../../../../common/components";
import { UserFilters, ExportDialog } from "..";
import { translate } from "../../../../../../common/providers";
import styles from "./Header.module.css";

const Header = React.forwardRef(({ companies, projects, openExport, onOpenExport, onExport, onSearch }, ref) => (
	<div ref={ref}>
		<div className={styles["header__action-bar"]}>
			<div className={styles["header__action-filters"]}>
				<UserFilters companies={companies} projects={projects} onSearch={onSearch} />
			</div>
			<div className={styles["header__action-buttons"]}>
				<CustomButton color="secondary" startIcon={icon.faUpload} variant="outlined" onClick={onOpenExport}>
					{translate("common:header.btn.export-user-list")}
				</CustomButton>
			</div>
		</div>
		<Divider />
		<ExportDialog open={openExport} onClose={onOpenExport} onExport={onExport} />
	</div>
));

export default Header;
