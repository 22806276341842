import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { ApiService, CollabService, ReqViewService } from "../../../../../../../api";
import styles from "./StatusModal.module.css";
import { Flags, isFeatureEnabled, translate } from "../../../../../../../common/providers";
import PartnershipRow from "./PartnershipRow";
import { getStatusBgColor, getStatusColor } from "../../../../../../../common/utils";
import { CustomTooltip } from "../../../../../../../common/components";
import { DOCUMENT_STATUSES } from "../../../../../constants/constants";
import { translateModalPosition } from "../../../../../utils/utils";

const StatusModal = ({
	currentPosition,
	data,
	getPartnership,
	informationId,
	onCommentIssueUpdate,
	onHoverStatusBlock,
	open,
	partnership,
	scrollTop,
	onDispatchIssueComment,
}) => {
	const [otherStatus, setOtherStatus] = useState([]);
	const [focusedIssue, setFocusedIssue] = useState("");
	const [focusedPartnerIssueComment, setFocusedPartnerIssueComment] = useState("");

	const cancelTokenSourceRef = useRef(null);
	const companyId = useSelector(({ context }) => context.company.id);
	const isArchived = useSelector(({ srDocument }) => srDocument.status === DOCUMENT_STATUSES.ARCHIVED);
	const partners = useSelector(({ srDocument }) => srDocument.partners);

	const fetchStatusList = useCallback(() => {
		ReqViewService.listStatusOnReq(
			{
				reqId: informationId,
			},
			cancelTokenSourceRef.current.token
		)
			.then((newData) => setOtherStatus(newData))
			.catch((err) => console.error(err));
	}, [informationId]);
	const handleSubmit = useCallback(() => {
		if (focusedIssue !== focusedPartnerIssueComment) {
			if (focusedIssue.length > 0) {
				CollabService.updateIssueComment({ informationId }, focusedIssue, cancelTokenSourceRef.current.token)
					.then(() => onDispatchIssueComment(focusedIssue))
					.catch((err) => {
						console.error(err);
					});
			} else if (focusedPartnerIssueComment) {
				CollabService.deleteIssueComment({ informationId }, cancelTokenSourceRef.current.token)
					.then(() => onDispatchIssueComment(focusedIssue))
					.catch((err) => {
						console.error(err);
					});
			}
			setFocusedPartnerIssueComment(focusedIssue);
		}
	}, [onDispatchIssueComment, focusedIssue, focusedPartnerIssueComment, informationId]);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	useEffect(() => {
		onCommentIssueUpdate(companyId, data.lastCommentIssue);
	}, [data.lastCommentIssue, data.informationId, companyId, onCommentIssueUpdate]);

	useEffect(() => {
		if (!data.validatedStatus || open) {
			if (isFeatureEnabled(Flags.PARTNERS)) {
				getPartnership();
			}
			fetchStatusList();
		}
	}, [data, open, getPartnership, fetchStatusList]);
	useEffect(() => {
		if (!open) {
			handleSubmit();
			setFocusedIssue("");
			setFocusedPartnerIssueComment("");
		}
	}, [open, handleSubmit]);
	const handleChangeIssue = (newIssue, issueComment) => {
		setFocusedIssue(newIssue);
		setFocusedPartnerIssueComment(issueComment);
	};
	const panelHeight = document.getElementById("syncedDetailsRightPanel__id")?.clientHeight;
	return (
		<>
			{open && (
				<div
					className={styles.main}
					style={{
						bottom: currentPosition - scrollTop <= panelHeight - 250 ? -250 : 30,
					}}
					onMouseEnter={() => {
						onHoverStatusBlock(true);
					}}
					onMouseLeave={() => {
						onHoverStatusBlock(false);
					}}
				>
					<div className={styles.status__container}>
						{Array.isArray(otherStatus) &&
							otherStatus.map((x) => (
								<CustomTooltip key={x.id} title={translateModalPosition(x.displayName, x.status)}>
									<div
										key={x.displayName}
										className={`${styles.status__item}`}
										data-testid={`user.position.${x.status}`}
										style={{
											backgroundColor: getStatusBgColor(x.status),
											color: getStatusColor(x.status),
										}}
										translate="no"
									>
										{x.shortName}
									</div>
								</CustomTooltip>
							))}
					</div>
					{isFeatureEnabled(Flags.PARTNERS) && (
						<div className={styles.companies}>
							<div className={styles.companies__description}>
								<div className={styles.description__title}>
									{translate("smart-review.cmp-details.modals.status.company-position")}
								</div>
							</div>
							<div className={styles.partnerships}>
								{Array.isArray(partnership) &&
									partnership
										.filter(
											(x) =>
												(partners && partners.some((y) => y.id === x.companyId && y.display)) ||
												companyId === x.companyId
										)
										.map((x) => (
											<PartnershipRow
												key={x.companyId}
												data={data}
												isArchived={isArchived}
												myCompany={companyId === x.companyId}
												partnerCompanyId={x.companyId}
												partnerCompanyName={x.companyName}
												partnerIssueComment={x.issueComment}
												partners={partners}
												partnerStatus={x.status}
												onChangeIssue={(newIssue) =>
													handleChangeIssue(newIssue, x.issueComment)
												}
												onSubmit={handleSubmit}
											/>
										))}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default StatusModal;
