import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import {
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	CustomTooltip,
	icon,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";

export default function DetectionsTabTable({
	className = "",
	onCheckAllRows,
	onCheckRow,
	onClickDelete,
	onClickPreview,
	detections,
	numSelected,
	checked,
}) {
	const [headers] = useState([
		translate("norms-center.norm.tabs.detections.table.document"),
		translate("norms-center.tab.detections.headers.text-detected"),
		translate("norms-center.norm.tabs.detections.table.page"),
		translate("norms-center.norm.tabs.detections.table.preview"),
		translate("norms-center.norm.tabs.detections.table.delete"),
	]);
	const rowCount = detections && detections.length;
	return (
		<CustomTable className={className}>
			<CustomTableHead>
				<CustomTableRow>
					<CustomTableCell>
						<Checkbox
							checked={rowCount > 0 && numSelected === rowCount}
							color="primary"
							indeterminate={numSelected > 0 && numSelected < rowCount}
							onChange={onCheckAllRows}
						/>
					</CustomTableCell>
					{headers.map((header) => (
						<CustomTableCell key={header}>{header}</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody emptyState={translate("norms-center.norm.tabs.detections.table.empty-state")}>
				{Array.isArray(detections) &&
					detections.map((row) => (
						<CustomTableRow key={row.normInProjectId}>
							<CustomTableCell>
								<Checkbox
									checked={checked.some((r) => r.normInProjectId === row.normInProjectId)}
									color="primary"
									onChange={(e) => {
										onCheckRow(e, row);
									}}
								/>
							</CustomTableCell>
							<CustomTableCell autoTranslate="no">
								<CustomTooltip title={row.docFilename}>
									<span>{row.docName}</span>
								</CustomTooltip>
							</CustomTableCell>
							<CustomTableCell autoTranslate="no">
								<CustomTooltip title={row.textDetected}>
									<span>{row.textDetected}</span>
								</CustomTooltip>
							</CustomTableCell>
							<CustomTableCell>{row.page}</CustomTableCell>
							<CustomTableCell>
								<CustomIconButton
									icon={icon.faFileAlt}
									variant="contained"
									onClick={() => {
										onClickPreview(row);
									}}
								/>
							</CustomTableCell>
							<CustomTableCell>
								<CustomIconButton
									icon={icon.faTrash}
									variant="contained"
									onClick={() => {
										onClickDelete(row);
									}}
								/>
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
