import React from "react";
import styles from "./SidePanelBox.module.css";

export default function SidePanelBox({
	children,
	className = "",
	clickable = false,
	hasShadow = true,
	onClick = null,
	onMouseEnter = null,
	onMouseLeave = null,
}) {
	return (
		<div
			className={`${styles.container} ${className}`}
			data-clickable={clickable}
			data-shadow={hasShadow}
			role="presentation"
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{children}
		</div>
	);
}
