import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { CompaniesList, ProjectsList, ProjectDetails } from "../../components";
import styles from "./AdminProject.module.css";
import { translate } from "../../../../common/providers";
import { SingleFieldDialog } from "../../../../common/components";

export default function AdminProject({
	companies,
	getCompanyProjects,
	onChangeArchive,
	onChangeName,
	companyIdToProject,
}) {
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [selectedProject, setSelectedProject] = useState(null);
	const [openRenameDialog, setOpenRenameDialog] = useState(false);
	const loggedIncompanyId = useSelector(({ context }) => context.company?.id);

	const handleSelectCompany = useCallback(
		(company) => {
			if (company?.id !== selectedCompany?.id) {
				setSelectedCompany(company);
				getCompanyProjects({ company });
			}
		},
		[selectedCompany, getCompanyProjects]
	);
	const findCompanyWithAdminUserRole = useCallback(
		(dataTree) => {
			for (const company of dataTree) {
				if (company.userRole === "ADMIN" && company.id === loggedIncompanyId) {
					return company;
				}
				if (company.children) {
					const innerCompanyId = findCompanyWithAdminUserRole(company.children);
					if (innerCompanyId) {
						return innerCompanyId;
					}
				}
			}
			return null;
		},
		[loggedIncompanyId]
	);
	useEffect(() => {
		if (!selectedCompany && Array.isArray(companies) && companies.length > 0) {
			handleSelectCompany(findCompanyWithAdminUserRole(companies));
		}
	}, [selectedCompany, companies, handleSelectCompany, companyIdToProject, findCompanyWithAdminUserRole]);

	const handleSelectProject = ({ project }) => {
		if (project !== selectedProject) {
			setSelectedProject(project);
		}
	};

	const handleArchive = ({ project }) => {
		if (!project || !selectedCompany) {
			return;
		}
		onChangeArchive({
			companyId: selectedCompany.id,
			projectId: project.id,
			name: project.name,
			status: project.currentStatus === "ACTIVE" ? "ARCHIVED" : "ACTIVE",
		});
	};

	const handleDialogRename = () => {
		if (!selectedCompany) {
			return;
		}
		setOpenRenameDialog((prevOpen) => !prevOpen);
	};

	const handleRename = (newName) => {
		onChangeName({
			companyId: selectedCompany.id,
			projectId: selectedProject.id,
			name: newName,
			status: selectedProject.currentStatus,
		});
	};

	return (
		<>
			<div className={styles["right-panel"]}>
				<CompaniesList
					companies={companies || []}
					selectedCompany={selectedCompany}
					onClickRow={handleSelectCompany}
				/>
			</div>
			<div className={styles["middle-panel"]}>
				<ProjectsList
					projects={companyIdToProject}
					onChangeArchive={handleArchive}
					onChangeName={handleDialogRename}
					onClickRow={handleSelectProject}
				/>
			</div>
			<div className={styles["left-panel"]}>
				<ProjectDetails project={selectedProject} />
			</div>
			<SingleFieldDialog
				label={translate("admin.project.save-dialog.file.label")}
				open={openRenameDialog}
				submitLabel={translate("admin.project.save-dialog.title.rename")}
				title={translate("admin.project.save-dialog.title.rename")}
				value={selectedProject?.name}
				onClose={handleDialogRename}
				onSubmit={handleRename}
			/>
		</>
	);
}
