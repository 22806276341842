import client from "../../client/client";

class ProjectDocumentsService {
	getDirectory({ projectId, directoryId }, { limit = 25, page = 0, search = "" }, cancelToken) {
		return client.get(`/smartview/projects/${projectId}/document-tabs/directories/${directoryId}`, {
			params: { limit, page, search },
			config: { cancelToken },
		});
	}

	getRoot({ projectId }, { limit = 25, page = 0, search = "" }, cancelToken) {
		return client.get(`/smartview/projects/${projectId}/document-tabs/directories/root`, {
			params: { limit, page, search },
			config: { cancelToken },
		});
	}

	getAllPrecedences({ projectId }, cancelToken) {
		return client.get(`/smartview/projects/${projectId}/document-tabs/me/precedence`, {
			config: { cancelToken },
		});
	}

	getPrecedence({ precedenceId }, cancelToken) {
		return client.get(`/smartview/document-tabs/me/precedence/${precedenceId}`, {
			config: { cancelToken },
		});
	}

	getAllRoles({ projectId }, cancelToken) {
		return client.get(`/smartview/projects/${projectId}/document-tabs/me/roles`, {
			config: { cancelToken },
		});
	}

	getRole({ projectId, role }, cancelToken) {
		return client.get(`/smartview/projects/${projectId}/document-tabs/me/roles/${role}`, {
			config: { cancelToken },
		});
	}
}

export default new ProjectDocumentsService();
