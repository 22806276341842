import { Checkbox } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomButton, DropDown, SearchInput } from "../../..";
import { translate } from "../../../../providers";
import { openFavoriteCategories } from "../../../../slice";
import styles from "./CategoryFilter.module.css";

const CategoryFilter = ({ onValidate, selectedCategories }) => {
	const favoriteCategories = useSelector(({ context }) => context.favoriteCategories.categories);
	const [open, setOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [filteredCategories, setFilteredCategories] = useState([]);
	const [tempSelectedCategories, setTempSelectedCategories] = useState(selectedCategories);
	const anchorRef = useRef();
	const dispatch = useDispatch();
	useEffect(() => {
		setTempSelectedCategories(selectedCategories);
	}, [selectedCategories]);
	useEffect(() => {
		setFilteredCategories(
			favoriteCategories.filter((favcat) => favcat.name.toLowerCase().includes(searchValue.toLocaleLowerCase()))
		);
	}, [favoriteCategories, searchValue]);
	const handleOpen = () => {
		setOpen((prev) => !prev);
	};
	const handleClose = () => {
		setTempSelectedCategories(selectedCategories);
		setOpen(false);
	};
	const handleOpenFavoriteCategories = () => {
		dispatch(openFavoriteCategories());
		handleClose();
	};
	const handleCheck = (isChecked, id) => {
		if (isChecked) {
			setTempSelectedCategories(tempSelectedCategories.filter((tempCat) => tempCat !== id));
		} else {
			setTempSelectedCategories([...tempSelectedCategories, id]);
		}
	};
	const handleValidate = () => {
		onValidate(tempSelectedCategories);
		handleClose();
	};
	const handleClearSearchValue = () => {
		setSearchValue("");
	};
	const handleChangeSearchValue = (event) => {
		setSearchValue(event.target.value);
	};
	return (
		<>
			<CustomButton ref={anchorRef} size="md" variant="contained" onClick={handleOpen}>
				{translate("common:reading-list.category-filter.button", {
					selected: tempSelectedCategories.length,
					total: favoriteCategories.length,
				})}
			</CustomButton>
			<DropDown ref={anchorRef} open={open} onClose={handleClose}>
				<div className={styles.container}>
					<CustomButton onClick={handleOpenFavoriteCategories}>
						{translate("common:reading-list.category-filter.manage-my-categories")}
					</CustomButton>
					<div className={styles.rowsContainer}>
						<SearchInput
							className={styles.searchContainer__textfield}
							label={translate("common:favorite-category-dialog.search")}
							value={searchValue}
							onChange={handleChangeSearchValue}
							onClearSearch={handleClearSearchValue}
						/>
						{Array.isArray(filteredCategories) &&
							filteredCategories.length > 0 &&
							filteredCategories.map((favCat) => {
								const isChecked = tempSelectedCategories.includes(favCat.id);
								return (
									<div key={favCat.id} className={styles.categoryRow}>
										<Checkbox
											checked={isChecked}
											onChange={() => handleCheck(isChecked, favCat.id)}
										/>
										{favCat.name}
									</div>
								);
							})}
					</div>
					<CustomButton variant="contained" onClick={handleValidate}>
						{translate("common:btn.submit")}
					</CustomButton>
				</div>
			</DropDown>
		</>
	);
};

export default CategoryFilter;
