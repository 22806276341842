import React, { useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { BlockTitle, createNotification, HorizontalDivider } from "../../../../common/components";
import { CompanyType, translate } from "../../../../common/providers";
import DSICompanyTable from "./DSICompanyTable";
import { BackOfficeService } from "../../../../api";
import { useApi } from "../../../../common/hooks";
import { downloadFile } from "../../../../common/utils";
import DSICompanyCreation from "./DSICompanyCreation";
import DSIBuCreation from "./DSIBuCreation";
import styles from "./DSICompany.module.css";

export default function DSICompany({
	activeUsersInCompany = [],
	companies = [],
	onCreateBu,
	onCreateCompany,
	onSelectCompany,
	onUpdateCompany,
}) {
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [isCompanyOrBu, setIsCompanyOrBu] = useState("COMPANY");
	const [companyPayload, setCompanyPayload] = useState({ shortName: "", name: "", type: CompanyType.COMPANY });
	const [errors, setErrors] = useState({ shortName: false, name: false, type: false });
	const [buPayload, setBuPayload] = useState({ name: "", shortName: "", companyId: 0, adminId: 0 });
	const [buErrors, setBuErrors] = useState({ name: "", shortName: "", companyId: 0, adminId: 0 });
	const { call: importTemplateThematicsFromCompany } = useApi(BackOfficeService.importTemplateThematicsFromCompany);
	const { call: exportTemplateThematicsFromCompany } = useApi(BackOfficeService.exportTemplateThematicsFromCompany);

	const onChangeCompanyFields = (value, field) => {
		if (!value) {
			setErrors((prev) => ({ ...prev, [field]: true }));
		} else {
			setErrors((prev) => ({ ...prev, [field]: false }));
			setCompanyPayload((prev) => ({ ...prev, [field]: value }));
		}
	};

	const reset = () => {
		setCompanyPayload({ shortName: "", name: "", type: "COMPANY" });
		setErrors({ shortName: false, name: false, type: false });
	};

	const onSubmitCompany = () => {
		const errs = {
			name: !companyPayload.name,
			shortName: !companyPayload.shortName,
		};
		const hasError = Object.keys(errs).some((key) => errs[key]);
		if (hasError) {
			setErrors(errs);
			return;
		}
		if (selectedCompany) {
			onUpdateCompany(selectedCompany.id, companyPayload);
			setSelectedCompany(null);
		} else {
			onCreateCompany(companyPayload);
		}
		reset();
	};
	const handleSelectCompany = (company) => {
		if (selectedCompany && selectedCompany.id === company.id) {
			setSelectedCompany(null);
			reset();
		} else {
			setSelectedCompany(company);
			setCompanyPayload({ shortName: company.shortName, name: company.companyName, type: "COMPANY" });
			setErrors({ shortName: false, name: false, type: false });
		}
	};
	const handleImportTemplates = (event) => {
		const targetFiles = event.target.files;
		const files = Object.values(targetFiles);
		if (selectedCompany?.id && files.length > 0) {
			importTemplateThematicsFromCompany({ companyId: selectedCompany?.id }, { file: files[0] })
				.then(() =>
					createNotification({
						type: "success",
						message: translate("dsi.dsi-company.category-templating.import.success-notification"),
					})
				)
				.catch(console.error);
		}
	};
	const handleExportTemplates = () => {
		if (selectedCompany?.id) {
			exportTemplateThematicsFromCompany({ companyId: selectedCompany?.id })
				.then(({ data, filename }) => {
					downloadFile({ data, filename, filetype: "json" });
					createNotification({
						type: "success",
						message: translate("dsi.dsi-company.category-templating.download.success-notification"),
					});
				})
				.catch(console.error);
		}
	};
	const handleSelectCompanyOrBu = (e) => {
		setIsCompanyOrBu(e.target.value);
		setSelectedCompany(null);
		reset();
	};
	const onChangeBuFields = (value, field) => {
		if (!value) {
			setBuErrors((prev) => ({ ...prev, [field]: true }));
		} else {
			setBuErrors((prev) => ({ ...prev, [field]: false }));
			setBuPayload((prev) => ({ ...prev, [field]: value }));
		}
	};
	const onSubmitBu = () => {
		onCreateBu(buPayload, selectedCompany);
	};

	return (
		<>
			<div className={styles.leftContainer} translate="no">
				<BlockTitle>{translate("dsi.dsi-company.title")}</BlockTitle>
				<div className={styles.companyTable}>
					<DSICompanyTable
						companies={companies}
						selectedCompany={selectedCompany}
						onSelectRow={handleSelectCompany}
					/>
				</div>
			</div>
			<div className={styles.rightContainer} translate="no">
				<BlockTitle autoTranslate="no">
					{translate(
						(selectedCompany && "dsi.dsi-company.company.update") ||
							"dsi.dsi-company.company-or-bu.create.tilte",
						{ companyName: selectedCompany?.companyName }
					)}
				</BlockTitle>
				<div className={styles.mainContainer}>
					<RadioGroup
						className={styles.radioSelector}
						value={isCompanyOrBu}
						onChange={handleSelectCompanyOrBu}
					>
						<FormControlLabel
							control={<Radio color="primary" />}
							label={translate("dsi.dsi-company.company.create")}
							value="COMPANY"
						/>
						<FormControlLabel
							control={<Radio color="primary" />}
							label={translate("dsi.dsi-company.bu.create")}
							value="BU"
						/>
					</RadioGroup>
					<HorizontalDivider className={styles.horizontalDivider} />
					{isCompanyOrBu === "COMPANY" ? (
						<DSICompanyCreation
							errors={errors}
							payload={companyPayload}
							selectedCompany={selectedCompany}
							onChangeCompanyFields={onChangeCompanyFields}
							onExportTemplates={handleExportTemplates}
							onImportTemplates={handleImportTemplates}
							onSubmitCompany={onSubmitCompany}
						/>
					) : (
						<DSIBuCreation
							activeUsersInCompany={activeUsersInCompany}
							buErrors={buErrors}
							buPayload={buPayload}
							companies={companies}
							onChangeBuFields={onChangeBuFields}
							onSelectCompany={onSelectCompany}
							onSubmitBu={onSubmitBu}
						/>
					)}
				</div>
			</div>
		</>
	);
}
