import { TableContainer } from "@mui/material";
import React, { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	CircularLoaderWithBackground,
	CustomIconButton,
	GenericDialog,
	HorizontalDivider,
	icon,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import { setIsClose, setOpenUploadDialog } from "../../../slice/opportunitySlice";
import UploadedDocsTable from "./document-table/UploadedDocsTable";
import styles from "./OpportunityProgress.module.css";

export default function OpportunityProgress({ pendingProjects, onCloseOne, onCloseUploadDialog, setPendingProjects }) {
	const [reduced, dispatchReduced] = useReducer((prev) => !prev, false);
	const isUploading = useSelector(({ opportunity }) => opportunity.isUploading);
	const openUploadDialog = useSelector(({ opportunity }) => opportunity.openUploadDialog);
	const uploadProgress = useSelector(({ opportunity }) => opportunity.uploadProgress);
	const dispatch = useDispatch();
	const handleCloseImportDialog = () => {
		if (isUploading) {
			onCloseUploadDialog();
			dispatch(setIsClose(true));
		} else {
			dispatch(setOpenUploadDialog(false));
			const filteredRow = pendingProjects.filter((row) => row.status === "Error");
			setPendingProjects(filteredRow);
		}
	};
	useEffect(() => {
		if (Array.isArray(pendingProjects) && pendingProjects.length === 0 && openUploadDialog) {
			dispatch(setOpenUploadDialog(false));
		}
	}, [pendingProjects, openUploadDialog, dispatch]);
	return (
		<GenericDialog
			disableBackdropClick
			disableEnforceFocus
			disableEscapeKeyDown
			hideBackdrop
			classes={{ paper: styles.dialog__paper, container: styles.dialog__container }}
			className={styles.dialog}
			open={openUploadDialog}
		>
			<div className={styles.dialog__content}>
				{(reduced && (
					<div className={styles.content__reduced}>
						<span className={styles.reduced__text}>
							{isUploading ? (
								<>
									<CircularLoaderWithBackground
										size={24}
										thickness={7.2}
										value={uploadProgress || 0}
										variant="determinate"
									/>
									&nbsp; {translate("opportunity.progress.import-in-progress")}
								</>
							) : (
								<div>{translate("opportunity.progress.import-completed")}</div>
							)}
						</span>
						<span className={styles.import__btns}>
							<CustomIconButton
								disableHovering
								btnClassName={styles.import__btn}
								icon={icon.faChevronUp}
								iconColor="var(--color-light-grey-2)"
								size="md"
								onClick={dispatchReduced}
							/>
							<CustomIconButton
								disableHovering
								btnClassName={styles.import__btn}
								icon={icon.faTimes}
								iconColor="var(--color-light-grey-2)"
								size="md"
								onClick={handleCloseImportDialog}
							/>
						</span>
					</div>
				)) || (
					<div className={styles.content__files}>
						<div className={styles.content__import}>
							<span className={styles.import__text}>
								{!isUploading
									? translate("opportunity.progress.import-completed")
									: translate("opportunity.progress.import-in-progress")}
							</span>
							<span className={styles.import__btns}>
								<CustomIconButton
									disableHovering
									btnClassName={styles.import__btn}
									icon={icon.faChevronDown}
									iconColor="var(--color-light-grey-2)"
									size="md"
									onClick={dispatchReduced}
								/>
								<CustomIconButton
									disableHovering
									btnClassName={styles.import__btn}
									icon={icon.faTimes}
									iconColor="var(--color-light-grey-2)"
									size="md"
									onClick={handleCloseImportDialog}
								/>
							</span>
						</div>
						<HorizontalDivider />
						<TableContainer className={styles.tabel__container}>
							<UploadedDocsTable
								pendingProjects={pendingProjects}
								setPendingProjects={setPendingProjects}
								onClose={onCloseOne}
							/>
						</TableContainer>
					</div>
				)}
			</div>
		</GenericDialog>
	);
}
