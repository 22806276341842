import { FormControl, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
	icon,
	IconComponent,
	LimitedTextField,
	InfiniteListSelector,
	SidePanel,
	SidePanelContent,
	OptionsPanelContainer,
	CustomDatePicker,
} from "../../../../../../../../../common/components";
import { translate } from "../../../../../../../../../common/providers";
import { convertAnyDateToDisplayDate } from "../../../../../../../../../common/utils";
import styles from "./TaskAddSidePanel.module.css";
import helperTextStyles from "../../../HelperText.module.css";
import { TeammateRow } from "..";
import { setDisableKeyEvents } from "../../../../../../../../../common/slice";

const currentDate = convertAnyDateToDisplayDate(new Date());
const rowRenderer = ({ loadedRow, onClick }) => <TeammateRow user={loadedRow} onClick={onClick} />;

const TaskAddSidePanel = ({
	onActive = null,
	onAddNewTask,
	onClose,
	open = false,
	priorities = [],
	reqId = 0,
	teammatesRequest = null,
}) => {
	const [description, setDescription] = useState("");
	const [newPriority, setNewPriority] = useState("");
	const [deadline, setDeadline] = useState(null);
	const [isAllDataCorrect, setIsAllDataCorrect] = useState(false);
	const [responsablePerson, setResponsablePerson] = useState("");
	const [descriptionErr, setDescriptionErr] = useState(false);
	const [deadlineErr, setDeadlineErr] = useState(false);
	const [responsablePersonErr, setResponsablePersonErr] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!descriptionErr && !deadlineErr && !responsablePersonErr && description && responsablePerson) {
			setIsAllDataCorrect(true);
		} else {
			setIsAllDataCorrect(false);
		}
	}, [descriptionErr, deadlineErr, responsablePersonErr, description, newPriority, deadline, responsablePerson]);
	useEffect(() => {
		setDescription("");
		setNewPriority("");
		setDeadline(null);
		setIsAllDataCorrect(false);
		setResponsablePerson("");
		setDescriptionErr(false);
		setDeadlineErr(false);
		setResponsablePersonErr(false);
	}, [open]);
	const handleChangeDescription = (value) => {
		setDescription(value);
		setDescriptionErr(value.length < 2);
	};
	const handleChangePriority = (e) => {
		setNewPriority(e.target.value);
	};
	const handleChangeDate = (e) => {
		setDeadline(e);
		const formatedDate = convertAnyDateToDisplayDate(e);
		if (
			(new Date(currentDate) <= new Date(formatedDate) || formatedDate === "") &&
			!Number.isNaN(Number(new Date(e)))
		) {
			setDeadlineErr(false);
		} else {
			setDeadlineErr(true);
		}
	};
	const handleChangeResponsablePerson = (user) => {
		setResponsablePerson(user);
		setResponsablePersonErr(!user);
	};
	const handleSubmit = () => {
		if (isAllDataCorrect) {
			onAddNewTask(
				description,
				newPriority || (Array.isArray(priorities) && priorities[2]),
				convertAnyDateToDisplayDate(deadline) || null,
				responsablePerson.email
			);
		} else {
			if (!description) {
				setDescriptionErr(true);
			}
			if (!responsablePerson) {
				setResponsablePersonErr(true);
			}
		}
	};
	const endAdornmentRenderer = (openTeammates1) => (
		<InputAdornment position="end">
			<IconComponent color="var(--color-dark-grey-1)" icon={openTeammates1 ? icon.faCaretUp : icon.faCaretDown} />
		</InputAdornment>
	);
	return (
		<SidePanel
			disableBackdropClick
			disableEscapeKeyDown
			open={open}
			size={38}
			onActive={onActive}
			onClose={onClose}
		>
			<SidePanelContent
				title={translate("smart-review.cmp-details.option-panel.title", { reqId })}
				onClose={onClose}
			>
				<OptionsPanelContainer
					buttonText={translate("common:btn.save")}
					buttonTextSecondary={translate("common:btn.cancel")}
					disabledPrimaryButton={!isAllDataCorrect}
					title={translate("smart-review.cmp-details.option-panel.tasks")}
					onClick={handleSubmit}
					onClickSecondary={onClose}
				>
					<LimitedTextField
						fullWidth
						multiline
						error={descriptionErr}
						maxLength={512}
						placeholder={translate("smart-review.cmp-details.option-panel.task-tab.description")}
						rows={4}
						value={description}
						onBlur={() => dispatch(setDisableKeyEvents(false))}
						onChange={handleChangeDescription}
						onFocus={() => dispatch(setDisableKeyEvents(true))}
					/>
					<span className={helperTextStyles.helperText}>
						{translate("smart-review.cmp-details.option-panel.task-tab.description.helper-text")}
					</span>
					<FormControl fullWidth variant="outlined">
						<InputLabel className={styles.select__placeHolder} size="small">
							{translate("smart-review.cmp-details.option-panel.task-tab.priority")}
						</InputLabel>
						<Select
							classes={{ select: styles.input__select }}
							defaultValue=""
							value={newPriority}
							onChange={handleChangePriority}
						>
							{priorities.map((priority) => (
								<MenuItem key={priority} value={priority}>
									{priority}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<span className={helperTextStyles.helperText}>
						{translate("smart-review.cmp-details.option-panel.task-tab.priority.helper-text")}
					</span>
					<CustomDatePicker
						fullWidth
						minDate={new Date()}
						value={deadline}
						onBlur={() => dispatch(setDisableKeyEvents(false))}
						onChange={handleChangeDate}
						onFocus={() => dispatch(setDisableKeyEvents(true))}
					/>
					<span className={helperTextStyles.helperText}>
						{translate("smart-review.cmp-details.option-panel.task-tab.deadline.helper-text")}
					</span>
					<FormControl fullWidth error={responsablePersonErr} variant="outlined">
						<InfiniteListSelector
							classes={{ input: styles.input__select }}
							classNameInfiniteList={styles.selector__infiniteList}
							endAdornmentRenderer={endAdornmentRenderer}
							infiniteListRequest={teammatesRequest}
							placeholder={translate("smart-review.cmp-details.option-panel.task-tab.assigned-to")}
							placeholderClassName={styles.select__placeHolder}
							rowRenderer={rowRenderer}
							shrink={!!responsablePerson.displayName}
							value={responsablePerson.displayName || ""}
							onClick={handleChangeResponsablePerson}
						/>
					</FormControl>
					<span className={helperTextStyles.helperText}>
						{translate("smart-review.cmp-details.option-panel.task-tab.assigned-to.helper-text")}
					</span>
				</OptionsPanelContainer>
			</SidePanelContent>
		</SidePanel>
	);
};

export default TaskAddSidePanel;
