import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { icon, IconComponent } from "../../../../common/components";
import styles from "./PhasesCycle.module.css";

function StyledIconButtonLeft({ children, onClick }) {
	return (
		<IconButton
			classes={{
				root: styles.StyledIconButtonLeft__root,
			}}
			onClick={onClick}
		>
			{children}
		</IconButton>
	);
}

function StyledIconButtonRight({ children, onClick }) {
	return (
		<IconButton
			classes={{
				root: styles.StyledIconButtonRight__root,
			}}
			onClick={onClick}
		>
			{children}
		</IconButton>
	);
}
function StyledIconButtonMiddleLeft({ children, onClick }) {
	return (
		<IconButton
			classes={{
				root: styles.StyledIconButtonMiddleLeft__root,
			}}
			onClick={onClick}
		>
			{children}
		</IconButton>
	);
}

const PhasesCycle = ({ phases, selectedDocuments, handleUpdateSelectedDocuments }) => {
	const [size, setSize] = useState((phases && phases.length - 1) || 0); // to start at 0

	const yOrigin = 50; // container y origin (% top)
	// const xOrigin = 50; // container x origin (% left)
	// const wPhase = "10"; // width of phase div
	// const hPhase = ""; // height of phase div

	useEffect(() => {
		setSize((phases && phases.length - 1) || 0);
	}, [phases]);

	const calcPosition = (origin, position) => {
		// Translate to container origin and calculate phase coordinates
		const coord = 2 * origin - ((size - position) / size) * (2 * origin);
		return origin + (coord - origin);
	};

	const drawParallelogramLeft = (position, name, id) => (
		<div
			key={position}
			className={styles["phase__parallelogram--left"]}
			style={{
				top: `calc(${Math.floor(calcPosition(yOrigin, position))}% + 5% * ${position})`,
				// left: `calc(${Math.floor(calcPosition(xOrigin, position))}% - 0%)`,
				left: `calc(2.4% * ${position})`,
			}}
		>
			{Array.isArray(selectedDocuments) && selectedDocuments.length > 0 ? (
				<StyledIconButtonLeft onClick={() => handleUpdateSelectedDocuments(id)}>
					<IconComponent icon={icon.faPlus} />
				</StyledIconButtonLeft>
			) : null}
			<div className={styles["phase__parallelogram-text--left"]}>
				<span className={styles.phase__text}>
					{position} : {name}
				</span>
			</div>
		</div>
	);

	const drawParallelogramRight = (position, name, id) => (
		<div
			key={position}
			className={styles["phase__parallelogram--right"]}
			style={{
				top: `calc(${Math.floor(calcPosition(yOrigin, size - position))}% + 5% * ${size - position})`,
				// right: `${Math.floor(calcPosition(xOrigin, size - position))}%`,
				right: `calc(2.4% * ${size - position})`,
			}}
		>
			{Array.isArray(selectedDocuments) && selectedDocuments.length > 0 ? (
				<StyledIconButtonRight onClick={() => handleUpdateSelectedDocuments(id)}>
					<IconComponent icon={icon.faPlus} />
				</StyledIconButtonRight>
			) : null}
			<div className={styles["phase__parallelogram-text--right"]}>
				<span className={styles.phase__text}>
					{position} : {name}
				</span>
			</div>
		</div>
	);

	const drawMiddleForm = (position, name, id) => (
		<div
			key={position}
			className={styles.phase__trapezoid}
			style={{
				top: `calc(${Math.floor(calcPosition(yOrigin, size - position))}% + 5% * ${size - position})`,
				left: `calc(2.4% * ${position})`,
				width: `calc(50% + ${2.4 * (size - position)}%)`,
			}}
		>
			{Array.isArray(selectedDocuments) && selectedDocuments.length > 0 ? (
				<StyledIconButtonMiddleLeft onClick={() => handleUpdateSelectedDocuments(id)}>
					<IconComponent icon={icon.faPlus} />
				</StyledIconButtonMiddleLeft>
			) : null}
			<div className={styles["phase__trapezoid-text"]}>
				<span className={styles.phase__text}>
					{position} : {name}
				</span>
			</div>
		</div>
	);

	return (
		<div style={{ position: "relative", width: "calc(100% - 400px)", margin: "5%", display: "flex" }}>
			{Array.isArray(phases) &&
				size !== 0 &&
				phases.map((phase, index) => {
					if (index < size / 2) {
						return drawParallelogramLeft(phase.position, phase.name, phase.id);
					}
					if (index > size / 2) {
						return drawParallelogramRight(phase.position, phase.name, phase.id);
					}
					return drawMiddleForm(phase.position, phase.name, phase.id);
				})}
		</div>
	);
};
export default PhasesCycle;
