import client from "../client/client";

class RoleService {
	listUserOnDocument(cancelToken) {
		return client.get("/roles/user-document", { config: { cancelToken } });
	}

	listUserInCompany(cancelToken) {
		return client.get("/roles/user-company", { config: { cancelToken } });
	}

	listCompanyInProject(cancelToken) {
		return client.get("/roles/company-project", { config: { cancelToken } });
	}

	listUserInProject(cancelToken) {
		return client.get("/roles/user-project", { config: { cancelToken } });
	}
}

export default new RoleService();
