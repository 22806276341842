import React from "react";
import { CustomDialog } from "../../../../../../../../common/components";
import { translate } from "../../../../../../../../common/providers";

export default function RedressDialog({
	count,
	hidden,
	onClose,
	onHide,
	onSubmit,
	open,
	redressingId = null,
	submitDisabled = false,
}) {
	return (
		<CustomDialog
			checked={hidden}
			closeLabel={translate("smart-review.redress-info.dialog.skip")}
			open={open}
			submitDisabled={submitDisabled}
			submitLabel={translate("smart-review.redress-info.dialog.submit", {
				count,
			})}
			title={translate("smart-review.redress-info.dialog.title")}
			onChangeCheckbox={onHide}
			onClose={onClose}
			onSubmit={onSubmit}
		>
			<p>{translate("smart-review.redress-info.dialog.change", { reqId: redressingId })}</p>
			<p>{translate("smart-review.redress-info.dialog.warning")}</p>
			<p>{translate("smart-review.redress-info.dialog.confirm")}</p>
		</CustomDialog>
	);
}
