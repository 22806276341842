import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ApiService, ProjectService, ProjectTeamService, SmartViewService } from "../../../../../../../api";
import { CustomButton, CustomDialog, CustomTooltip, DropDown } from "../../../../../../../common/components";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../../../../common/providers";
import styles from "./MatchDropDown.module.css";
import { MatchContentFromPartner, MatchContentFromReviewer, MatchDialog, MatchDropdownDialog } from ".";

const MatchDropDown = React.forwardRef(({ onClose = null, open }, ref) => {
	const [matchDialogOpen, setMatchDialogOpen] = useState(false);
	const [matchDialogReviewerOptions, setMatchDialogReviewerOptions] = useState([]);
	const [matchDialogPartnerOptions, setMatchDialogPartnerOptions] = useState([]);
	const [matchDialogTitle, setMatchDialogTitle] = useState("");
	const [selectedMatchDialogOption, setSelectedMatchDialogOption] = useState(null);
	const [unanimouslyAcceptedOnly, setUnanimouslyAcceptedOnly] = useState(false);
	const [fromReviewer, setFromReviewer] = useState(true);
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [overwrittenReqsNb, setOverwrittenReqsNb] = useState(0);
	const [informationDialogOpen, setInformationDialogOpen] = useState(false);
	const [keepMyPositions, setKeepMyPositions] = useState(true);
	const [importPartnersComments, setImportPartnersComments] = useState(false);
	const [overwrittenCommentsNb, setOverwrittenCommentsNb] = useState(0);
	const [loadingValidation, setLoadingValidation] = useState(false);
	const [reviewerName, setReviewerName] = useState("");
	const [updatedReqsCount, setUpdatedReqsCount] = useState(0);
	const [updatedCommentsCount, setUpdatedCommentsCount] = useState(0);
	const cancelTokenSourceRef = useRef(null);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const documentName = useSelector(({ srDocument }) => srDocument.documentName);

	const projectId = useSelector(({ context }) => context.project.id);

	const myCompany = useSelector(({ context }) => context.company);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (open) {
			ProjectService.getUsersByDocumentAndRole(
				{ projectId, documentId, role: "REVIEWER" },
				cancelTokenSourceRef.current.token
			)
				.then((options) => {
					setMatchDialogReviewerOptions(options);
				})
				.catch((err) => {
					console.error(err);
				});
			if (isSegFeatureEnabled(SegFlags.PARTNERS)) {
				ProjectTeamService.getPartners({ projectId }, cancelTokenSourceRef.current.token)
					.then((options) => {
						setMatchDialogPartnerOptions(options);
					})
					.catch((err) => {
						console.error(err);
					});
			}
		}
	}, [open, projectId, documentId]);
	useEffect(() => {
		if (selectedMatchDialogOption) {
			setReviewerName(
				selectedMatchDialogOption.companyName ||
					selectedMatchDialogOption.displayName ||
					selectedMatchDialogOption.email
			);
		} else {
			setReviewerName(fromReviewer ? myCompany.name : translate("smart-review.match-reviewer.all-partners"));
		}
	}, [selectedMatchDialogOption, fromReviewer, myCompany]);
	const handleCloseMatchDialog = () => {
		setMatchDialogOpen(false);
		setSelectedMatchDialogOption(null);
	};
	const handleChangeImportPartnersComments = () => {
		setImportPartnersComments((prev) => !prev);
	};
	const loadMatchCountFromReviewer = () => {
		if (selectedMatchDialogOption?.id) {
			return SmartViewService.getOverwrittenRequirementsCountByReviewer(
				{ documentId, reviewerId: selectedMatchDialogOption.id },
				cancelTokenSourceRef.current.token
			);
		}
		if (unanimouslyAcceptedOnly) {
			return SmartViewService.getOverwrittenRequirementsCountInvalidated(
				{ documentId },
				cancelTokenSourceRef.current.token
			);
		}
		return SmartViewService.getOverwrittenRequirementsCountByCompany(
			{ documentId, companyId: myCompany.id },
			cancelTokenSourceRef.current.token
		);
	};
	const loadMatchCountFromPartner = () => {
		if (selectedMatchDialogOption?.id) {
			return SmartViewService.getOverwrittenRequirementsCountByCompany(
				{ documentId, companyId: selectedMatchDialogOption.id },
				cancelTokenSourceRef.current.token
			);
		}
		return SmartViewService.getOverwrittenRequirementsCountByAllPartners(
			{ documentId },
			cancelTokenSourceRef.current.token
		);
	};
	const matchFromReviewer = () => {
		if (selectedMatchDialogOption?.id) {
			return SmartViewService.transferFromReviewer(
				{ documentId },
				{ reviewerId: selectedMatchDialogOption.id, overwrite: !keepMyPositions },
				cancelTokenSourceRef.current.token
			);
		}
		if (unanimouslyAcceptedOnly) {
			return SmartViewService.validateUnanimouslyAccepted({ documentId }, cancelTokenSourceRef.current.token);
		}
		return SmartViewService.transferFromMyCompany(
			{ documentId },
			{ overwrite: !keepMyPositions },
			cancelTokenSourceRef.current.token
		);
	};
	const matchFromPartner = () => {
		if (selectedMatchDialogOption?.id) {
			return SmartViewService.transferFromCompany(
				{ documentId },
				{
					companyId: selectedMatchDialogOption.id,
					overwritePositions: !keepMyPositions,
					overwriteComments: !keepMyPositions && importPartnersComments,
					transferComments: importPartnersComments,
				},
				cancelTokenSourceRef.current.token
			);
		}
		return SmartViewService.transferFromAllPartners(
			{ documentId },
			{ overwrite: !keepMyPositions },
			cancelTokenSourceRef.current.token
		);
	};
	const handleCloseConfirmationDialog = () => {
		setConfirmationDialogOpen(false);
		setUnanimouslyAcceptedOnly(false);
	};
	const handleSubmitConfirmationDialog = () => {
		let request;
		setLoadingValidation(true);
		if (fromReviewer) {
			request = matchFromReviewer();
		} else {
			request = matchFromPartner();
		}
		request
			.then((data) => {
				setUpdatedReqsCount(data.count || data.positions || 0);
				setUpdatedCommentsCount(data.comments || 0);
				setInformationDialogOpen(true);
				setMatchDialogOpen(false);
				handleCloseConfirmationDialog();
				setLoadingValidation(false);
				setUnanimouslyAcceptedOnly(false);
			})
			.catch((err) => {
				console.error(err);
				setUnanimouslyAcceptedOnly(false);
				setLoadingValidation(false);
			});
	};
	const handleSubmitMatchDialog = () => {
		let request;
		if (fromReviewer) {
			request = loadMatchCountFromReviewer();
		} else {
			request = loadMatchCountFromPartner();
		}
		request
			.then((data) => {
				setOverwrittenCommentsNb(data.comments || 0);
				setOverwrittenReqsNb(data.positions || data.count || 0);
				if (data.comments || data.positions || data.count) {
					setConfirmationDialogOpen(true);
				} else {
					handleSubmitConfirmationDialog();
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleClickMatchReviewerPosition = () => {
		onClose();
		setFromReviewer(true);
		setMatchDialogOpen(true);
		setMatchDialogTitle(translate("smart-review.match-dialog.title.match-reviewer-position"));
	};
	const handleClickMatchPartnerPosition = () => {
		setFromReviewer(false);
		setMatchDialogOpen(true);
		setMatchDialogTitle(translate("smart-review.match-dialog.title.match-partner-position"));
	};
	const handleChangeSelectedOption = (newOption) => {
		setSelectedMatchDialogOption(newOption);
	};
	const handleChangeUnanimouslyAcceptedOnly = () => {
		setUnanimouslyAcceptedOnly((prev) => !prev);
	};
	const handleCloseInformationDialog = () => {
		setInformationDialogOpen(false);
		window.location.reload();
	};
	const handleChangeKeepMyPositions = () => {
		setKeepMyPositions((prev) => !prev);
	};
	return (
		<>
			<DropDown ref={ref} open={open} onClose={onClose}>
				<div className={styles.dropDownContainer}>
					<CustomTooltip
						title={
							(matchDialogReviewerOptions.length === 0 &&
								translate("smart-review.match-drop-down.no-position")) ||
							""
						}
					>
						<div>
							<CustomButton
								fullWidth
								color="secondary"
								disabled={matchDialogReviewerOptions.length === 0}
								onClick={handleClickMatchReviewerPosition}
							>
								{translate("smart-review.match-drop-down.match-reviewer-position")}
							</CustomButton>
						</div>
					</CustomTooltip>
					{isSegFeatureEnabled(SegFlags.PARTNERS) && (
						<CustomTooltip
							title={
								(matchDialogPartnerOptions.length === 0 &&
									translate("smart-review.match-drop-down.no-position")) ||
								""
							}
						>
							<div>
								<CustomButton
									fullWidth
									color="secondary"
									disabled={matchDialogPartnerOptions.length === 0}
									onClick={handleClickMatchPartnerPosition}
								>
									{translate("smart-review.match-drop-down.match-partner-position")}
								</CustomButton>
							</div>
						</CustomTooltip>
					)}
				</div>
			</DropDown>
			<MatchDialog
				defaultOption={
					fromReviewer
						? {
								displayName: translate("smart-review.match-dialog.whole-team"),
						  }
						: { companyName: translate("smart-review.match-reviewer.all-partners") }
				}
				open={matchDialogOpen && !confirmationDialogOpen}
				options={
					fromReviewer
						? matchDialogReviewerOptions.map((item) => ({
								...item,
								disabled: item.positionsCount === 0,
								tooltip:
									item.positionsCount === 0
										? translate("smart-review.match-drop-down.no-reviewer-position")
										: "",
						  }))
						: matchDialogPartnerOptions
				}
				selectedOption={selectedMatchDialogOption}
				selectField={fromReviewer ? "displayName" : "companyName"}
				title={matchDialogTitle}
				onChangeSelectedOption={handleChangeSelectedOption}
				onClose={handleCloseMatchDialog}
				onSubmit={handleSubmitMatchDialog}
			>
				{fromReviewer ? (
					<MatchContentFromReviewer
						isOptionSelected={!!selectedMatchDialogOption?.id}
						unanimouslyAcceptedOnly={unanimouslyAcceptedOnly}
						onChangeUnanimouslyAcceptedOnly={handleChangeUnanimouslyAcceptedOnly}
					/>
				) : (
					<MatchContentFromPartner
						importPartnersComments={importPartnersComments}
						isOptionSelected={!!selectedMatchDialogOption?.id}
						onChangeImportPartnersComments={handleChangeImportPartnersComments}
					/>
				)}
				{translate("smart-review.match-dialog.information-document", { documentName })}
			</MatchDialog>
			<MatchDropdownDialog
				importPartnersComments={importPartnersComments}
				keepMyPositions={keepMyPositions}
				open={confirmationDialogOpen}
				overwrittenCommentsNb={overwrittenCommentsNb}
				overwrittenReqsNb={overwrittenReqsNb}
				unanimouslyAcceptedOnly={unanimouslyAcceptedOnly}
				onChangeKeepMyPositions={handleChangeKeepMyPositions}
				onClose={handleCloseConfirmationDialog}
				onSubmit={handleSubmitConfirmationDialog}
			/>
			<CustomDialog
				closeLabel={translate("common:btn.close")}
				open={informationDialogOpen}
				subTitle={
					(importPartnersComments &&
						translate("smart-review.match-information-dialog.content.comments", {
							reqsCount: updatedReqsCount,
							commentsCount: updatedCommentsCount,
							reviewerName,
						})) ||
					translate("smart-review.match-information-dialog.content.reqs", {
						reqsCount: updatedReqsCount,
						reviewerName,
						documentName,
					})
				}
				title={translate("smart-review.match-information-dialog.title")}
				onClose={handleCloseInformationDialog}
			/>
			<Backdrop className={styles.backdrop} open={loadingValidation}>
				<div className={styles.container__backdrop}>
					<CircularProgress color="inherit" />
					{translate("smart-review.match-dropdown.backdrop.label")}
				</div>
			</Backdrop>
		</>
	);
});

export default MatchDropDown;
