import React, { useEffect } from "react";
import { ViewBanner } from "../../../common/components";
import PortfolioUser from "./portfolio-user/PortfolioUser";
import { exportView } from "../../../navigation";
import { AnalyticsProvider, ContextProvider, translate } from "../../../common/providers";

const DashboardPortfolio = () => {
	useEffect(() => {
		const documentTitle = translate("dashboard.document.title");
		document.title = documentTitle;
		if (ContextProvider.isLogged()) {
			ContextProvider.setHomeTab("projects");
		}
		AnalyticsProvider.trackPageView({ documentTitle: "Portfolio" });
	}, []);
	return (
		<>
			<ViewBanner />
			<PortfolioUser />
		</>
	);
};

export default exportView({
	path: "/",
	localesPath: "/dashboards/dashboard-portfolio/locales",
	component: DashboardPortfolio,
});
