import { MenuList, MenuItem } from "@mui/material";
import React, { useRef } from "react";
import styles from "./ActionDropdown.module.css";
import { icon, DropDown, CircularLoader, CustomButton } from "../../../../../common/components";
import { capitalize, removeUnderscore } from "../../../../../common/utils";

export default function ActionDropdown({
	isLoading = false,
	items = [],
	type = "",
	open = false,
	onToggle,
	onClose,
	onOpenDialog,
	title = "",
}) {
	const anchorRef = useRef(null);
	return (
		<>
			<CustomButton
				ref={anchorRef}
				color="secondary"
				endIcon={icon.faSortDown}
				variant="outlined"
				onClick={onToggle}
			>
				{title || type}
			</CustomButton>
			<DropDown ref={anchorRef} open={open} onClose={onClose}>
				{isLoading ? (
					<div className={styles.actionDropdown__loader}>
						<CircularLoader size={48} />
					</div>
				) : (
					<MenuList>
						{items.map((item) => (
							<MenuItem
								key={item?.id}
								onClick={() => {
									onOpenDialog({ item, type });
								}}
							>
								{capitalize(removeUnderscore(item?.displayName || item?.name || ""))}
							</MenuItem>
						))}
					</MenuList>
				)}
			</DropDown>
		</>
	);
}
