import React, { useState } from "react";
import { ERROR_STATUSES_TRANSLATION_KEYS, DOCUMENT_STATUSES } from "../../../constants/documentStatuses";
import { translate, translateEnumDocumentStatus } from "../../../providers";
import { CustomIconButton } from "../../buttons";
import { IconComponent, icon } from "../../icon-component";
import styles from "./DocumentProgress.module.css";
import { matchTypeIcon } from "../../../utils";

export default function DocumentProgress({
	documentName = "",
	expandable = false,
	progress = 0,
	nbPages = 0,
	onClose = null,
	displayProgress = true,
	status = DOCUMENT_STATUSES.completed,
	indentLevel = 0,
	displayCloseButton = true,
	labelClassName = "",
}) {
	const errorStatusTranslationKey = ERROR_STATUSES_TRANSLATION_KEYS[status];
	const [preventClick, setPreventClick] = useState(false);
	const handleClose = () => {
		setPreventClick(true);
		onClose();
	};
	return (
		<div
			className={styles.progress}
			data-first-level={indentLevel === 0 && expandable}
			data-single-document={indentLevel === 0 && !expandable}
			data-sublevels={indentLevel > 0}
			style={{ paddingLeft: indentLevel * 16 + 32 }}
		>
			<div className={styles["progress__first-line"]}>
				<div
					className={`${styles["first-line__left"]} ${
						(errorStatusTranslationKey && styles.error) || ""
					} ${labelClassName}`}
					translate="no"
				>
					<IconComponent
						color={(errorStatusTranslationKey && "var(--shades-grey-400)") || "var(--color-dark-grey-1)"}
						icon={icon[matchTypeIcon({ name: documentName })]}
					/>
					<span>
						{documentName}
						{errorStatusTranslationKey
							? ` (${translateEnumDocumentStatus(errorStatusTranslationKey)})`
							: ""}
					</span>
				</div>
				<div className={styles["first-line__right"]}>
					{nbPages > 0 && (
						<div className={styles["page-number"]}>
							{nbPages > 1
								? translate("new-project.details-and-documents.document-progress.pages-multiple", {
										nbPages,
								  })
								: translate("new-project.details-and-documents.document-progress.pages-single")}
						</div>
					)}
					{displayCloseButton && onClose && (
						<CustomIconButton
							className={styles["close-icon"]}
							disabled={preventClick}
							icon={icon.faTimes}
							onClick={handleClose}
						/>
					)}
				</div>
			</div>
			{displayProgress && status === DOCUMENT_STATUSES.completed && (
				<div className={styles.progressbar__back}>
					<div className={styles.progressbar__front} style={{ width: `${progress * 100}%` }} />
				</div>
			)}
		</div>
	);
}
