import React from "react";
import { translate } from "../../../../../../common/providers";
import styles from "./TemplateHeaders.module.css";

export default function TemplateHeaders() {
	return (
		<div className={styles.main}>
			<div className={styles.name}>{translate("dsi.export-templates-management.table.header.templates")}</div>
			<div className={styles.projects}>{translate("dsi.export-templates-management.table.header.projects")}</div>
			<div className={styles.actions} />
		</div>
	);
}
