import React, { useState } from "react";
import styles from "./DocumentTableRowStatus.module.css";
import { DOCUMENT_STATUS } from "../../constants/constants";
import { translate } from "../../../../common/providers";
import { CircularLoaderWithBackground, CustomIconButton, icon } from "../../../../common/components";
import { DocumentService } from "../../../../api";
import { useApi } from "../../../../common/hooks";

const DocumentTableRowStatus = ({ docId, docStatus, progression, onDelete }) => {
	const [isHovered, setIsHovered] = useState(false);
	const { call: callRetryExtract } = useApi(DocumentService.retryExtract);
	const handleRetry = () => callRetryExtract({ docId });
	const displayStatusIcon = () => {
		switch (docStatus) {
			case DOCUMENT_STATUS.IN_PROGRESS:
				return (
					<>
						<div className={`${styles.file__loader} ${styles["file__loader--visible"]}`}>
							<CircularLoaderWithBackground
								size={16}
								thickness={10}
								value={progression || 0}
								variant="determinate"
							/>
						</div>
						<div className={`${styles.file__loader} ${styles["file__loader--hidden"]}`}>
							<CustomIconButton
								disableHovering
								btnClassName={styles.file__iconBtn}
								icon={icon.faTimes}
								iconColor="var(--color-black)"
								tooltip={translate("document-center.upload-dialog.file.delete")}
								onClick={() => {
									onDelete(docId);
								}}
							/>
						</div>
					</>
				);
			case DOCUMENT_STATUS.TIMEOUT:
			case DOCUMENT_STATUS.FAILED:
				return (
					<>
						<CustomIconButton
							icon={(isHovered && icon.faRedoAlt) || icon.faExclamationTriangle}
							iconColor={(!isHovered && "var(--color-red)") || "var(--color-light-grey-2)"}
							tooltip={translate("document-center.upload-dialog.file.retry")}
							onClick={handleRetry}
							onMouseLeave={() => {
								setIsHovered(false);
							}}
							onMouseOver={() => {
								setIsHovered(true);
							}}
						/>
					</>
				);
			default:
				return null;
		}
	};
	return (
		<div
			className={styles.container}
			role="presentation"
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			{displayStatusIcon()}
		</div>
	);
};

export default DocumentTableRowStatus;
