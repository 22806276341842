import React from "react";
import { Checkbox } from "@mui/material";
import {
	CustomIconButton,
	CustomTableBody,
	CustomTableCell,
	CustomTableRow,
	CustomTooltip,
	icon,
	IconComponent,
} from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import { displayTrustLevel } from "../../../../../utils/utils";
import { removeUnderscore } from "../../../../../../../common/utils";
import styles from "./PendingDetectionBody.module.css";

export default function PendingDetectionBody({
	checked,
	detections,
	emptyState,
	isLoading,
	pendingStatus,
	onCheckRow,
	onClickMatchedNorms,
	onClickSimilarDetections,
	onChangeNormsStatus,
	onDisplayDocument,
	onOpenValidateSimilarDialog,
}) {
	return (
		<CustomTableBody emptyState={emptyState} isLoading={isLoading}>
			{Array.isArray(detections) &&
				detections.map((row) => {
					const noCandidate = row.pendingId === null;
					return (
						<CustomTableRow key={row.pendingId} hover>
							<CustomTableCell>
								<Checkbox
									checked={checked.some((r) => r.pendingId === row.pendingId)}
									color="primary"
									onChange={(e) => {
										onCheckRow(e.target.checked, row);
									}}
									onClick={(e) => e.stopPropagation()}
								/>
							</CustomTableCell>
							<CustomTableCell>
								<CustomTooltip title={row.docFilename}>
									<span>{removeUnderscore(row.docName)}</span>
								</CustomTooltip>
							</CustomTableCell>
							<CustomTableCell>{row.page}</CustomTableCell>
							<CustomTableCell>
								<CustomIconButton
									icon={icon.faFileAlt}
									tooltip={translate("norms-center.tab.detections.table.display-document.tooltip")}
									variant="contained"
									onClick={(e) => {
										e.stopPropagation();
										onDisplayDocument(row);
									}}
								/>
							</CustomTableCell>
							<CustomTableCell>{row.textDetected}</CustomTableCell>
							<CustomTableCell>
								{pendingStatus === "PENDING" && (
									<CustomTooltip
										title={
											noCandidate
												? ""
												: translate("norms-center.tab.detections.table.matched-norm.tooltip")
										}
									>
										<span
											className={`${styles.pendingDetection__matchedNorm} ${
												noCandidate ? styles["pendingDetection__matchedNorm--blue"] : ""
											}`}
											role="presentation"
											onClick={(e) => {
												e.stopPropagation();
												onClickMatchedNorms(row);
											}}
										>
											<IconComponent
												className={styles.matchedIcon}
												icon={noCandidate ? icon.faPlus : icon.faPen}
											/>
											{noCandidate
												? translate("norms-center.tab.detections.table.choose-a-norm")
												: row.matchedNormName}
										</span>
									</CustomTooltip>
								)}
								{pendingStatus === "TRASH" && row.matchedNormName}
							</CustomTableCell>
							<CustomTableCell>{displayTrustLevel(row.trust)}</CustomTableCell>
							<CustomTableCell>
								{!!row.similarDetections && (
									<CustomIconButton
										label={row.similarDetections}
										tooltip={translate(
											"norms-center.tab.detections.table.similar-detections.tooltip"
										)}
										variant="contained"
										onClick={(e) => {
											e.stopPropagation();
											onClickSimilarDetections(row);
										}}
									/>
								)}
							</CustomTableCell>
							{pendingStatus === "PENDING" && (
								<>
									<CustomTableCell>
										{row.pendingId && (
											<CustomIconButton
												icon={icon.faCheck}
												tooltip={translate("norms-center.tab.detections.table.valid.tooltip")}
												variant="contained"
												onClick={(e) => {
													e.stopPropagation();
													onChangeNormsStatus("VALIDATED", row.pendingId);
												}}
											/>
										)}
									</CustomTableCell>
									<CustomTableCell className={styles.pendingDetection__cell}>
										{row.pendingId && (
											<CustomIconButton
												icon={icon.faCheckDouble}
												tooltip={translate(
													"norms-center.tab.detections.table.valid-with-similar.tooltip"
												)}
												variant="contained"
												onClick={(e) => {
													e.stopPropagation();
													onOpenValidateSimilarDialog(row);
												}}
											/>
										)}
									</CustomTableCell>
									<CustomTableCell>
										<CustomIconButton
											icon={icon.faTimes}
											tooltip={translate("norms-center.tab.detections.table.discard.tooltip")}
											variant="contained"
											onClick={(e) => {
												e.stopPropagation();
												onChangeNormsStatus("TRASH", row.pendingId);
											}}
										/>
									</CustomTableCell>
								</>
							)}
							{pendingStatus === "TRASH" && (
								<CustomTableCell>
									<CustomIconButton
										icon={icon.faUndo}
										tooltip={translate("norms-center.tab.detections.table.recover.tooltip")}
										variant="contained"
										onClick={(e) => {
											e.stopPropagation();
											onChangeNormsStatus("PENDING", row.pendingId);
										}}
									/>
								</CustomTableCell>
							)}
						</CustomTableRow>
					);
				})}
		</CustomTableBody>
	);
}
