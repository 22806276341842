import { Alert, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { ApiService } from "../../../../../api";
import searchCardTemplateService from "../../../../../api/services/search-card-template-service";
import { CustomDialog, IconComponent, generateFilters, icon } from "../../../../../common/components";
import { VISIBILITY_TEMPLATE_CREATION, VISIBILITY_TEMPLATE_AUTOMATION } from "../../../../../common/constants";
import { SEARCH_TYPES } from "../../../../../common/constants/search-types";
import { translate } from "../../../../../common/providers";
import styles from "./CreateTemplateDialog.module.css";

export default function CreateTemplateDialog({ isForMySearch, onClose, onSubmit, hasWarning = false, open, slice }) {
	const [nameInput, setNameInput] = useState("");
	const [descriptionInput, setDescriptionInput] = useState("");
	const filters = useSelector(({ mySearch }) => mySearch.filters);
	const isDsi = useSelector((state) => state[slice].isDsi);
	const cancelTokenSourceRef = useRef(null);
	const visibilityInputs = useMemo(
		() => (isForMySearch ? VISIBILITY_TEMPLATE_CREATION : VISIBILITY_TEMPLATE_AUTOMATION),
		[isForMySearch]
	);
	const [visibilityInput, setVisibilityInput] = useState(
		isForMySearch ? visibilityInputs.PRIVATE : visibilityInputs.AUTOMATION
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (open) {
			setNameInput("");
			setDescriptionInput("");
		}
	}, [open]);
	const handleChangeName = (e) => {
		setNameInput(e.target.value);
	};
	const handleChangeDescription = (e) => {
		setDescriptionInput(e.target.value);
	};
	const handleChangeVisibility = (e) => {
		setVisibilityInput(e.target.value);
	};
	const handleSaveTemplate = () => {
		const payload = {
			title: nameInput,
			description: descriptionInput || null,
			visibility: visibilityInput,
			filtersResults: generateFilters({ ...filters, Type: SEARCH_TYPES.template }),
		};
		searchCardTemplateService
			.createTemplate(payload, cancelTokenSourceRef.current.token)
			.then(() => {
				onSubmit();
			})
			.catch((err) => {
				console.error(err);
			});
	};
	return (
		<CustomDialog
			isForm
			className={styles.dialog}
			open={open}
			submitDisabled={!nameInput}
			submitLabel={translate("common:btn.save")}
			title={translate("smart-search.dialogs.create-template-dialog.title")}
			onClose={onClose}
			onSubmit={handleSaveTemplate}
		>
			<div className={styles.content__inputs}>
				<TextField
					color="primary"
					helperText={translate("smart-search.dialogs.create-template-dialog.input.name.helper")}
					inputProps={({ type: "text", maxLength: 250 }, { "data-testid": "st.create.name" })}
					placeholder={`${translate("smart-search.dialogs.create-template-dialog.input.name")}*`}
					size="small"
					value={nameInput}
					variant="outlined"
					onChange={handleChangeName}
				/>
				<TextField
					color="primary"
					helperText={translate("smart-search.dialogs.create-template-dialog.input.description.helper")}
					inputProps={({ type: "text", maxLength: 1000 }, { "data-testid": "st.create.description" })}
					placeholder={translate("smart-search.dialogs.create-template-dialog.input.description")}
					size="small"
					value={descriptionInput}
					variant="outlined"
					onChange={handleChangeDescription}
				/>
				{((isDsi || isForMySearch) && (
					<TextField
						select
						color="primary"
						helperText={translate("smart-search.dialogs.create-template-dialog.input.visibility.helper")}
						size="small"
						value={visibilityInput}
						variant="outlined"
						onChange={handleChangeVisibility}
					>
						{Object.entries(visibilityInputs).map(([key, value]) => (
							<MenuItem key={key} value={value}>
								{translate(
									`smart-search.dialogs.create-template-dialog.input.visibility.${value
										?.toLowerCase()
										.replaceAll("_", "-")}`
								)}
							</MenuItem>
						))}
					</TextField>
				)) || (
					<div className={styles.infoBox}>
						<IconComponent
							className={styles.infoIcon}
							color="var(--color-blue)"
							icon={icon.faInfoCircle}
							size="lg"
						/>
						{translate("smart-search.dialogs.save-search-dialog.visible-manager-leader")}
					</div>
				)}
				{hasWarning && (
					<Alert severity="error">{translate("smart-search.dialogs.create-template-dialog.alert")}</Alert>
				)}
			</div>
		</CustomDialog>
	);
}
