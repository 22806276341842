import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	IconComponent,
	createNotification,
	CustomIconButton,
	icon,
	CustomButton,
	CustomPopOver,
	CustomDialog,
} from "../../../../../../../../common/components";
import { ApiService, CollabService, ReqViewService } from "../../../../../../../../api";
import { setHasVersioningUpdate, updateNode } from "../../../../../../slice/pdf/pdf-slice";
import { setHoveredBlock, setSelectedSearch } from "../../../../../../slice/document/document-slice";
import { translate, hasPermission, hasCompanyPermission, Permissions } from "../../../../../../../../common/providers";
import styles from "./RequirementVersionOverlay.module.css";
import { NavUtils } from "../../../../../../../../navigation";
import { dangerousSetInnerHTML } from "../../../../../../../../common/utils";
import { SCOPES } from "../../../../../../../../common/providers/permissions/permissions";
import { checkHardInformation } from "../../../../../../utils/utils";
import { VERSION_STATUSES } from "../../../../../../constants/constants";
import { SOURCE_TYPES } from "../../../../../../../../common/constants";

function VersionText({ text }) {
	return dangerousSetInnerHTML({ text });
}
export default function RequirementVersionOverlay({
	hoveredWarning,
	metadata,
	priority,
	onClose,
	setHoveredWarning,
	onSetPriority,
}) {
	const [open, setOpen] = useState(false);
	const [mode, setMode] = useState("reclassify");
	const isNew = metadata?.infoVersionStatus === VERSION_STATUSES.NEW;
	const isModified = metadata?.infoVersionStatus === VERSION_STATUSES.MODIFIED;
	const isModifiedMinor = metadata?.infoVersionStatus === VERSION_STATUSES.MODIFIED_MINOR;
	const [openPopInfoVersionStatus, setOpenPopInfoVersionStatus] = useState(false);
	const dispatch = useDispatch();
	const [openConfirmation, setOpenConfirmation] = useState(false);
	const projectId = useSelector(({ context }) => context.project.id || 0);
	const hasVersioningUpdate = useSelector(({ srPdf }) => srPdf.hasVersioningUpdate);
	const showReclassifyButton = useRef(false);
	const cancelTokenSourceRef = useRef(null);
	const userRole = useSelector(({ context }) => context.roles[SCOPES.PROJECT]);
	const selectedMode = useSelector(({ srDocument }) => srDocument.mode);
	const isSelecting = useSelector(({ srDocument }) => srDocument.isSelecting);

	const popUpTitle = useMemo(
		() => ({
			reclassify: translate("smart-review.version-confirm-dialog.reclassify.title"),
			setIdentical: translate("smart-review.version-confirm-dialog.identical.title"),
			removeNew: translate("smart-review.version-confirm-dialog.identical.title"),
		}),
		[]
	);
	const popUpText = useMemo(
		() => ({
			reclassify: translate("smart-review.version-confirm-dialog.reclassify.text"),
			setIdentical: translate("smart-review.version-confirm-dialog.identical.text"),
			removeNew: translate("smart-review.version-confirm-dialog.remove-new.text"),
		}),
		[]
	);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		showReclassifyButton.current =
			hasPermission(Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER) &&
			hasCompanyPermission(Permissions.PROJECT_LEADER);
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (hoveredWarning !== metadata.informationId) {
			setOpen(false);
		}
	}, [hoveredWarning, metadata]);
	const getVersioningData = (informationId) => {
		CollabService.getVersioningData({ informationId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				dispatch(
					updateNode({
						mode: selectedMode,
						pageConditions: { page: metadata.pageStart },
						informationId: metadata.informationId,
						updates: [{ property: "versionDifference", value: data }],
					})
				);
			})
			.catch((err) => console.error(err));
	};
	useEffect(
		() => {
			if (hasVersioningUpdate && open) {
				getVersioningData(metadata.informationId);
				dispatch(setHasVersioningUpdate(false));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, hasVersioningUpdate, open]
	);
	const handleMouseEnter = () => {
		if (typeof onClose === "function") {
			onClose();
		}
		if (!isSelecting) {
			if (priority) {
				onSetPriority("single");
			}
			dispatch(setHoveredBlock(metadata));
			setHoveredWarning(metadata.informationId);
			setOpen(true);
			if (!metadata?.versionDifference) {
				getVersioningData(metadata.informationId);
			}
		}
	};
	const handleClose = (e) => {
		e?.stopPropagation();
		setOpen(false);
	};
	useEffect(() => {
		if (priority && priority !== "single") {
			setOpen(false);
		}
	}, [priority, onClose]);
	const handleRedirect = () => {
		if (metadata?.versionDifference?.oldDocumentId && metadata?.versionDifference?.oldPageStart) {
			window.location.href = NavUtils.goToSmartReview({
				projectId,
				documentId: metadata.versionDifference?.oldDocumentId,
				pageStart: metadata.versionDifference?.oldPageStart,
			});
		}
	};
	const handleMouseLeave = () => {
		dispatch(setHoveredBlock(null));
	};

	const handleOpenConfirmation = () => setOpenConfirmation((prev) => !prev);

	const handleSubmit = () => {
		handleOpenConfirmation();
		const infoVersionStatus = mode === "reclassify" ? VERSION_STATUSES.NEW : VERSION_STATUSES.IDENTICAL;
		ReqViewService[mode]({ infoId: metadata.informationId }, cancelTokenSourceRef.current.token)
			.then(() => {
				createNotification({
					type: "success",
					message: translate("smart-review.version-notification.success-message"),
					title: translate("smart-review.version-notification.success-title"),
				});
				dispatch(
					updateNode({
						mode: selectedMode,
						pageConditions: { page: metadata.pageStart },
						informationId: metadata.informationId,
						updates: [{ property: "infoVersionStatus", value: infoVersionStatus }],
					})
				);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const diffText = {
		text: metadata?.versionDifference?.diff,
		title: translate(
			isNew ? "smart-review.version-icon.version-new-content" : "smart-review.version-icon.version-difference"
		),
	};
	const handleRectify = () => {
		setMode("reclassify");
		handleOpenConfirmation();
	};
	const handleIdentical = () => {
		setMode("setIdentical");
		handleOpenConfirmation();
	};
	const handleNew = () => {
		setMode("removeNew");
		handleOpenConfirmation();
	};
	const handleOpenPop = () => {
		setOpenPopInfoVersionStatus(true);
	};

	const setInfoVersionStatusText = () => {
		if (isModifiedMinor) {
			return translate("smart-review.version-info-icon.modified_minor");
		}
		if (isModified) {
			return translate("smart-review.version-info-icon.modified");
		}
		return translate("smart-review.version-info-icon.new");
	};
	const handleOpenSidePanel = () => {
		const isRequirement = checkHardInformation(metadata);
		handleClose();
		dispatch(
			setSelectedSearch({
				infoId: metadata.informationId,
				pageStart: metadata.pageStart,
				x1: metadata.rectangle.x1,
				x2: metadata.rectangle.x2,
				y1: metadata.rectangle.y1,
				y2: metadata.rectangle.y2,
				openSidePanel: true,
				source: SOURCE_TYPES.VERSIONING,
				isRequirement,
			})
		);
	};
	return (
		<>
			<div
				key={metadata.informationId}
				className={styles.versionIcon}
				role="presentation"
				onClick={handleOpenSidePanel}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<CustomPopOver
					content={
						<>
							<div className={styles.content}>
								<div className={styles.modal__actions}>
									<div>
										{metadata?.versionDifference?.diff && (
											<CustomIconButton
												icon={icon.faColumns}
												iconClassName={styles.icon}
												tooltip={translate("smart-review.version-icon.redirection")}
												onClick={handleRedirect}
											/>
										)}
										<CustomPopOver
											hovered
											content={setInfoVersionStatusText()}
											open={openPopInfoVersionStatus}
											placement="bottom"
											onClose={() => setOpenPopInfoVersionStatus(false)}
										>
											<span role="presentation" onMouseEnter={handleOpenPop}>
												<IconComponent
													className={styles.icon}
													fontSize="24px"
													icon={icon.faInfoCircle}
													size="lg"
												/>
											</span>
										</CustomPopOver>
									</div>
									<CustomIconButton
										icon={icon.faTimes}
										iconClassName={styles.icon}
										onClick={handleClose}
									/>
								</div>
								<div>
									<h6>{diffText.title}</h6>
									<VersionText text={diffText?.text || ""} />
									{(isModified || isModifiedMinor) && showReclassifyButton.current && (
										<>
											<div className={styles.subTitle}>
												{translate("smart-review.version-modal.no-modification")}
											</div>
											<div>
												<span
													className={`${styles["negative__label--underline"]} ${styles["negative__label--clickable"]} ${styles["negative__label--margin-right"]}`}
													role="presentation"
													onClick={handleRectify}
												>
													<IconComponent className={styles.icon} icon={icon.faPlus} />
													{translate("smart-review.version-modal.action.new")}
												</span>
												<span
													className={`${styles["negative__label--underline"]} ${styles["negative__label--clickable"]}`}
													role="presentation"
													onClick={handleIdentical}
												>
													<IconComponent className={styles.icon} icon={icon.faEquals} />
													{translate("smart-review.version-modal.action.identical")}
												</span>
											</div>
										</>
									)}
								</div>
								{(userRole.includes("LEADER") || userRole.includes("MANAGER")) && isNew && (
									<div>
										<div className={styles.modal__notNewContent}>
											{translate("smart-review.version-modal.action.not-new-content")}
										</div>
										<CustomButton
											color="secondary"
											startIcon={icon.faGripLines}
											onClick={handleNew}
										>
											{translate("smart-review.version-modal.action.remove-tag")}
										</CustomButton>
									</div>
								)}
							</div>
						</>
					}
					open={open}
					placement="bottom"
					onClose={handleClose}
				>
					<span role="presentation" onClick={handleOpenSidePanel}>
						<IconComponent
							color={
								(isNew && "var(--color-blue)") ||
								(isModified && "var(--color-red)") ||
								(isModifiedMinor && "var(--color-keppel)")
							}
							icon={isNew ? icon.faPlusCircle : icon.faExclamationTriangle}
							size="lg"
						/>
					</span>
				</CustomPopOver>
			</div>
			<CustomDialog
				open={openConfirmation}
				subTitle={popUpText[mode]}
				title={popUpTitle[mode]}
				onClose={handleOpenConfirmation}
				onSubmit={handleSubmit}
			/>
		</>
	);
}
