import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CircularLoader, CustomButton, CustomDialog, icon, InfiniteList2 } from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import { SaveSearchDialog } from "../../..";
import EditDialog from "../../edit-dialog/EditDialog";
import SmartSearchMenuItem from "../smart-search-menu-item/SmartSearchMenuItem";
import styles from "./SmartSearchMenu.module.css";

export default function SmartSearchMenu({
	currentSearchId,
	hasAuthorsSearch = false,
	infiniteRequest,
	isLoading = false,
	items,
	isForMySearch,
	menusCount,
	onClickItem,
	onClickOpen,
	onEdit,
	onRemove,
	open,
	slice,
	title,
}) {
	const userId = useSelector(({ context }) => context.user.id);
	const mySearches = useSelector((context) => context[slice].mySearches);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [openEditSearchDialog, setOpenEditSearchDialog] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const isModifiable = onEdit && onRemove;

	const handleOpenEditDialog = () => setOpenEditDialog((prev) => !prev);
	const handleCloseEditSearchDialog = () => setOpenEditSearchDialog(null);
	const handleEdit = (item) => {
		setOpenEditSearchDialog({
			...item,
			onSubmit: (payload) => {
				onEdit(payload);
			},
		});
	};
	const handleCloseConfirmDialog = () => setOpenConfirmDialog(null);
	const handleRemove = ({ id, title: scTitle, creatorId }) => {
		setOpenConfirmDialog({
			id,
			text: translate("smart-search.drawer.menu.remove-dialog.text", { title: scTitle }),
			onConfirm: () => {
				onRemove({ id, creatorId });
				handleCloseConfirmDialog();
			},
		});
	};
	const rowRenderer = (item) => {
		const reduxItem = mySearches?.filter((mySearch) => mySearch.id === item.id)[0] || item;
		const disabled = reduxItem.id === currentSearchId;
		return (
			<SmartSearchMenuItem
				key={reduxItem.id}
				disabled={disabled}
				isModifiable={isModifiable}
				resultCount={reduxItem.resultCount}
				title={reduxItem.title}
				visibility={reduxItem.visibility}
				onClick={() => onClickItem(reduxItem)}
			/>
		);
	};
	return (
		<>
			<span className={styles.menu__header}>
				<CustomButton
					className={styles.menu__btn}
					endIcon={open ? icon.faChevronDown : icon.faChevronRight}
					variant="text"
					onClick={onClickOpen}
				>
					{title}
				</CustomButton>
				{isModifiable && open && items?.length > 0 && (
					<CustomButton className={styles.menu__editBtn} onClick={handleOpenEditDialog}>
						{translate("common:btn.edit")}
					</CustomButton>
				)}
			</span>
			{open &&
				(isLoading ? (
					<div className={styles.loaderContainer}>
						<CircularLoader size={40} />
					</div>
				) : (
					<InfiniteList2
						className={styles.infiniteListContainer}
						request={infiniteRequest}
						rowRenderer={rowRenderer}
						scrollContainerClassName={styles.infiniteList__scrollContainer}
						style={{ maxHeight: `calc(100vh - ${311 + menusCount * 40 + (hasAuthorsSearch ? 40 : 0)}px)` }}
					/>
				))}
			<EditDialog
				isForMySearch={isForMySearch}
				items={items || []}
				open={openEditDialog}
				title={title}
				userId={userId}
				onClose={handleOpenEditDialog}
				onEdit={handleEdit}
				onRemove={handleRemove}
			/>
			<CustomDialog
				open={!!openConfirmDialog}
				submitLabel={translate("common:btn.confirm")}
				subTitle={openConfirmDialog?.text || ""}
				title={translate("smart-search.drawer.menu.remove-dialog.title")}
				onClose={handleCloseConfirmDialog}
				onSubmit={openConfirmDialog?.onConfirm || null}
			/>
			<SaveSearchDialog
				isEditting
				creatorId={openEditSearchDialog?.creatorId || 0}
				description={openEditSearchDialog?.description || ""}
				id={openEditSearchDialog?.id || 0}
				isForMySearch={isForMySearch}
				open={!!openEditSearchDialog}
				title={openEditSearchDialog?.title || ""}
				visibility={openEditSearchDialog?.visibility || ""}
				onClose={handleCloseEditSearchDialog}
				onSubmit={openEditSearchDialog?.onSubmit || null}
			/>
		</>
	);
}
