import { createAsyncThunk } from "@reduxjs/toolkit";
import { TransverseService } from "../../../../api";

export const getRootTransverse = createAsyncThunk(
	"projectDocument/getRootTransverse",
	({ projectId, documentId, filters, token }) =>
		TransverseService.getDocumentRoot({ projectId, documentId }, filters, token)
);

export const getClauseTransverse = createAsyncThunk(
	"projectDocument/getClauseTransverse",
	({ projectId, documentId, clauseId, filters, token }) =>
		TransverseService.getDocumentClause({ projectId, documentId, clauseId }, filters, token)
);
