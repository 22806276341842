import React from "react";
import styles from "./UnsupportedDocumentBanner.module.css";
import { translate } from "../../../../common/providers";

export default function UnsupportedDocumentBanner() {
	return (
		<header className={styles.container}>
			<div>
				<span>{translate("document-center.side-panel.unsupported.information")}</span>
				<a
					href={translate("document-center.side-panel.unsupported.link")}
					rel="noopener noreferrer"
					target="_blank"
				>
					{translate("common:redirection.to-know-more")}
				</a>
			</div>
		</header>
	);
}
