import React from "react";
import { TableBody } from "@mui/material";
import { CircularLoader } from "../../loaders";
import styles from "./OpportunityTableBody.module.css";

export default function OpportunityTableBody({ children, className = "", emptyState = "", isLoading = false }) {
	return (
		<TableBody className={className}>
			{(isLoading && (
				<tr>
					<td className={styles.cell} colSpan="9999">
						<CircularLoader />
					</td>
				</tr>
			)) ||
				null}
			{(!isLoading && ((Array.isArray(children) && children.length === 0) || !children) && emptyState && (
				<tr>
					<td className={styles.cell__emptyState} colSpan="9999">
						<span className={styles.emptyState}>{emptyState}</span>
					</td>
				</tr>
			)) ||
				null}
			{(!isLoading && children) || null}
		</TableBody>
	);
}
