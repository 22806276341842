import React, { useCallback, useEffect, useRef, useState } from "react";
import { FilterHeader, PackageRow, PackageHeaders, PackagePanels } from "./components";
import { ApiService, PackageManagementService } from "../../../../api";
import styles from "./DSIPackages.module.css";

export default function DSIPackages({ platformCompanies }) {
	const cancelTokenSourceRef = useRef();
	const [packages, setPackages] = useState([]);
	const [plName, setName] = useState("");
	const [selectedRow, setSelectedRow] = useState(null);
	const [panel, setPanel] = useState("");
	const handleChangeFilters = useCallback(({ name }) => {
		setName(name);
	}, []);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		PackageManagementService.getAllPlatformPackages(plName, cancelTokenSourceRef.current.token).then((data) =>
			setPackages(data)
		);
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [plName]);
	const handleSelectRow = (row, pane) => {
		setSelectedRow(row);
		setPanel(pane);
	};
	const handleClose = () => {
		setSelectedRow(null);
		setPanel("");
	};

	const handleUpdateRow = (id, key, value) => {
		setPackages((prev) => prev.map((x) => (x.id === id && { ...x, [key]: value }) || x));
	};

	const handleOpenCreation = () => {
		setPanel("creation");
	};
	const handleRefreshList = (newPackage) => {
		setPackages((prev) => [...prev, newPackage]);
	};
	return (
		<div className={styles.container}>
			<FilterHeader onChangeFilters={handleChangeFilters} onOpenCreation={handleOpenCreation} />
			<PackageHeaders />
			<div className={styles.list}>
				{packages.map((pck) => (
					<PackageRow key={pck.id} pck={pck} onSelectRow={handleSelectRow} />
				))}
			</div>
			<PackagePanels
				open={!!panel}
				panel={panel}
				pck={selectedRow}
				platformCompanies={platformCompanies}
				onClose={handleClose}
				onRefreshList={handleRefreshList}
				onUpdateRow={handleUpdateRow}
			/>
		</div>
	);
}
