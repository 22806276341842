import React from "react";
import { useLocation } from "react-router-dom";
import { Card, CardContent, Checkbox, Divider } from "@mui/material";
import { CanvasDocCard, RateProgressBar } from "./DocCards";
import { ApiService, BackOfficeService, CanvasService, RoleService } from "../../../../api";
import { hasPermission, isSegFeatureEnabled, Permissions, SegFlags, translate } from "../../../../common/providers";
import {
	createNotification,
	CustomButton,
	CustomButtonLink,
	CustomDialog,
	CustomTooltip,
	icon,
} from "../../../../common/components";
import styles from "./UserDetails.module.css";
import { NavUtils } from "../../../../navigation";
import { SOURCE_TYPES } from "../../../../common/constants";

function checkArchived(doc) {
	return doc.documentStatus === "ARCHIVED";
}

function getDocArchiveStyles(doc) {
	return checkArchived(doc) ? styles.card__archivedDoc : "";
}

function DisplayByUser({ data, hideArchives, onCheck, onUpdateTabDocToDelete, projectId, role, width }) {
	const location = useLocation();
	if (data.length === 0) {
		return null;
	}
	return (
		<div className={styles.userDetailsContainer}>
			<CustomButton className={styles.buttonRole} color="primary" size="sm">
				{translate(`common:enum.document.user-role.${role.toLowerCase()}`)}
			</CustomButton>
			<div className={styles.wrapperRole}>
				{data
					.filter((x) => (!checkArchived(x) && hideArchives) || !hideArchives)
					.map((doc) => (
						<div key={doc.documentId} className={styles.userDetailContainer} width={width}>
							<Card className={`${styles.card} ${getDocArchiveStyles(doc)}`}>
								<CardContent className={styles.card__content}>
									<div className={styles.cardHeader}>
										{!hasPermission(Permissions.PROJECT_MEMBER) && (
											<CustomTooltip
												arrow
												title={
													checkArchived(doc)
														? translate("canvas-document-details.select.tooltip")
														: ""
												}
											>
												<Checkbox
													checked={doc.checked}
													className={styles.userCard__checkbox}
													color="primary"
													disabled={checkArchived(doc)}
													onClick={() => {
														onCheck(doc.documentId, role);
														onUpdateTabDocToDelete(
															!doc.checked,
															doc.documentId,
															doc.documentName,
															role
														);
													}}
												/>
											</CustomTooltip>
										)}
										<CustomTooltip title={doc.documentFilename}>
											<CustomButtonLink
												autoTranslate="no"
												buttonClassName={styles.buttonByUser}
												color="primary"
												disabled={!isSegFeatureEnabled(SegFlags.SMART_REVIEW)}
												state={{ previous: location.pathname }}
												to={NavUtils.goToSmartReview({
													projectId,
													documentId: doc.documentId,
													source: SOURCE_TYPES.CANVAS,
												})}
												variant="contained"
											>
												{doc.documentName.split("_").join(" ")}
											</CustomButtonLink>
										</CustomTooltip>
									</div>
									<Divider />
									<div className={styles.canvasDocCard}>
										{isSegFeatureEnabled(SegFlags.TASK) && (
											<CanvasDocCard
												color="var(--color-orange)"
												icon={icon.faClipboardList}
												text={translate("canvas.document-details.pending-tasks")}
												value={doc.countTasks || 0}
											/>
										)}
										{role === Permissions.REVIEWER.role ? (
											<>
												<CanvasDocCard
													color="var(--color-green)"
													icon={icon.faUserCheck}
													text={translate("canvas.document-details.reviewed")}
													value={(!Number.isNaN(doc.countReviewed) && doc.countReviewed) || 0}
												/>
												<CanvasDocCard
													color="var(--color-light-grey-1)"
													icon={icon.faHourglassHalf}
													text={translate("canvas.document-details.unreviewed")}
													value={
														(!Number.isNaN(doc.countUnreviewed) && doc.countUnreviewed) || 0
													}
												/>
											</>
										) : null}
										{role === Permissions.VALIDATOR.role ? (
											<>
												<CanvasDocCard
													color="var(--color-green)"
													icon={icon.faUserCheck}
													text={translate("canvas.document-details.validated")}
													value={(!Number.isNaN(doc.countReviewed) && doc.countReviewed) || 0}
												/>
												{isSegFeatureEnabled(SegFlags.QUESTION_ANSWER) && (
													<CanvasDocCard
														color="var(--color-blue)"
														icon={icon.faHandPaper}
														text={translate("canvas.document-details.questions")}
														value={(!Number.isNaN(doc.countQnAs) && doc.countQnAs) || 0}
													/>
												)}
												<CanvasDocCard
													color="var(--color-light-grey-1)"
													icon={icon.faHourglassHalf}
													text={translate("canvas.document-details.unreviewed")}
													value={
														(!Number.isNaN(doc.countUnreviewed) && doc.countUnreviewed) || 0
													}
												/>
											</>
										) : null}
									</div>
									<Divider />
									<RateProgressBar rate={doc.rate || 0} />
								</CardContent>
							</Card>
						</div>
					))}
			</div>
		</div>
	);
}

class UserDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rolesOnDocument: [],
			openConfirmationDelete: false,
		};
		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentDidMount() {
		RoleService.listUserOnDocument(this.cancelTokenSource.token)
			.then((data) =>
				this.setState({
					rolesOnDocument: (Array.isArray(data) && data) || [],
				})
			)
			.catch((err) => console.error(err));
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	handleCloseConfirmation = () => {
		const { onReset } = this.props;
		this.setState({
			openConfirmationDelete: false,
		});
		onReset();
	};

	handleOpenConfirmation = () => {
		this.setState({
			openConfirmationDelete: true,
		});
	};

	handleSubmitConfirmation = () => {
		const { toDelete, dataSelectedUser, selectedCompany, onUpdateUsersRoles, projectId } = this.props;
		const toDeleteBis = toDelete.map((x) => ({
			documentId: x.documentId,
			role: x.role,
		}));
		const service = selectedCompany ? BackOfficeService : CanvasService;
		const method = selectedCompany ? "deleteUserDocumentRoles" : "removeMultiUsersRoleOnDocuments";
		service[method](
			{ projectId, userId: dataSelectedUser.id, companyId: selectedCompany?.id },
			toDeleteBis,
			this.cancelTokenSource.token
		)
			.then(() => {
				onUpdateUsersRoles();
				this.handleCloseConfirmation();
				createNotification({
					type: "success",
					message: translate("canvas.user-details.success.user-has-been-removed", {
						displayName: dataSelectedUser.displayName,
					}),
					timeout: 1500,
				});
			})
			.catch((err) => {
				console.error(err);
			});
	};

	render() {
		const { openConfirmationDelete, rolesOnDocument } = this.state;
		const {
			dataSelectedUser,
			SelectAllDocsToDelete,
			onSelectedAllDocs,
			rolesSelectedUser,
			toDelete,
			width,
			onUpdateTabDocToDelete,
			onCheck,
			hideArchives,
			projectId,
			selectedUserDetails,
		} = this.props;
		return (
			<>
				<div className={styles.title} translate="no">
					{translate("canvas.user-details.title.user-details", {
						displayName: dataSelectedUser.displayName,
						email: dataSelectedUser.email,
					})}
				</div>
				<div className={styles.subContainer}>
					<div>
						<div className={styles.buttonDelete}>
							{!hasPermission(Permissions.PROJECT_MEMBER) && (
								<>
									<CustomTooltip
										placement="top"
										title={translate("canvas.user-details.tooltip.select-all-the-documents")}
									>
										<Checkbox
											checked={SelectAllDocsToDelete}
											color="primary"
											data-testid="remove.all.users.btn"
											onClick={onSelectedAllDocs}
										/>
									</CustomTooltip>

									<CustomTooltip
										placement="top"
										title={translate(
											"canvas.user-details.tooltip.remove-role-on-selected-document"
										)}
									>
										<span>
											<CustomButton
												className={styles.buttonRemove}
												color="primary"
												disabled={rolesSelectedUser.length === 0 || toDelete.length === 0}
												size="md"
												variant="contained"
												onClick={this.handleOpenConfirmation}
											>
												{translate("canvas.user-details.remove-role-on-selected-document")}
											</CustomButton>
										</span>
									</CustomTooltip>
								</>
							)}
						</div>
					</div>
				</div>
				{rolesOnDocument.map((x) => (
					<DisplayByUser
						key={x}
						data={selectedUserDetails?.find((details) => details.role === x)?.documentStatuses || []}
						hideArchives={hideArchives}
						projectId={projectId}
						role={x}
						width={width}
						onCheck={onCheck}
						onUpdateTabDocToDelete={onUpdateTabDocToDelete}
					/>
				))}
				<CustomDialog
					open={openConfirmationDelete}
					subTitle={translate("canvas.user-details.confirm-dialog.text")}
					title={translate("canvas.user-details.confirm-dialog.title")}
					onClose={this.handleCloseConfirmation}
					onSubmit={this.handleSubmitConfirmation}
				/>
			</>
		);
	}
}

export default UserDetails;
