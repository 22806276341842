import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SidePanel, SidePanelContent, LimitedAutocompleteTextField, LimitedTextField } from "../..";
import { Flags, isFeatureEnabled, translate } from "../../../providers";
import { OptionsPanelContainer } from "..";
import styles from "./CommentSidePanel.module.css";
import { setDisableKeyEvents } from "../../../slice";

const CommentSidePanel = ({
	disableBackdropClick = false,
	disableEscapeKeyDown = false,
	disableAutocomplete = false,
	isEditingAsValidator = false,
	oldComment = null,
	onActive = null,
	onAddComment,
	onClose,
	onEditComment,
	open = false,
	title = "",
	optionsPanelContainerTitle = "",
	placeholder = "",
	helperText = "",
}) => {
	const [newComment, setNewComment] = useState("");
	const teamMembers = useSelector(({ context }) => context.teamMembers);
	const dispatch = useDispatch();

	useEffect(() => {
		if (oldComment) {
			setNewComment(oldComment);
		} else {
			setNewComment("");
		}
	}, [oldComment, open]);

	const handleComment = (value) => {
		setNewComment(value);
	};
	const handleClick = () => {
		if (newComment.length > 1) {
			if (oldComment) {
				onEditComment(newComment, oldComment);
			} else {
				onAddComment(newComment);
			}
		}
	};
	return (
		<SidePanel
			disableBackdropClick={disableBackdropClick}
			disableEscapeKeyDown={disableEscapeKeyDown}
			open={open}
			size={38}
			onActive={onActive}
			onClose={onClose}
		>
			<SidePanelContent title={title} onClose={onClose}>
				<OptionsPanelContainer
					buttonText={translate("common:btn.save")}
					buttonTextSecondary={translate("common:btn.cancel")}
					disabledPrimaryButton={newComment.length < 2}
					title={optionsPanelContainerTitle}
					onClick={handleClick}
					onClickSecondary={onClose}
				>
					{(!isEditingAsValidator && !disableAutocomplete && (
						<LimitedAutocompleteTextField
							fullWidth
							multiline
							data={teamMembers}
							maxLength={512}
							placeholder={placeholder}
							rows={16}
							trigger={`${(isFeatureEnabled(Flags.MENTIONS) && "@") || null}`}
							value={newComment}
							onBlur={() => dispatch(setDisableKeyEvents(false))}
							onChange={handleComment}
							onFocus={() => dispatch(setDisableKeyEvents(true))}
						/>
					)) || (
						<LimitedTextField
							fullWidth
							multiline
							maxLength={512}
							placeholder={placeholder}
							rows={16}
							value={newComment}
							onBlur={() => dispatch(setDisableKeyEvents(false))}
							onChange={handleComment}
							onFocus={() => dispatch(setDisableKeyEvents(true))}
						/>
					)}

					{helperText && helperText.length > 0 && <span className={styles.helperText}>{helperText}</span>}
				</OptionsPanelContainer>
			</SidePanelContent>
		</SidePanel>
	);
};

export default CommentSidePanel;
