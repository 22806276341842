import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { useEffectOnce } from "../../common/hooks";
import { NavUtils } from "../../navigation/utils";
import {
	AnalyticsProvider,
	Flags,
	hasCompanyPermission,
	hasPermission,
	isSegFeatureEnabled,
	Permissions,
	SegFlags,
	translate,
} from "../../common/providers";
import { ActionAutoService, ApiService, GonogoCategoryService } from "../../api";
import {
	CircularLoader,
	createNotification,
	CustomButton,
	CustomButtonLink,
	CustomDialog,
	EmptyState,
	icon,
	SideDrawerMain,
	VerticalDivider,
	ViewBanner,
	withWebsocket,
} from "../../common/components";
import { exportView } from "../../navigation";
import { CategoryDrawer, GonogoHeader, GonogoContent, GonogoDetails } from "./components";
import { downloadFile, isNonEmptyArray } from "../../common/utils";
import styles from "./Gonogo.module.css";
import { getCategoriesTree, setCurrentCategory, setIsAnalysisInProgress, setSelectedMode } from "./slice/gonogo-slice";
import { findNode } from "./slice/gonogo-utils";
import dashboardService from "../../api/services/dashboard-service";

const Gonogo = ({ subscribeWS }) => {
	const [openDrawer, setOpenDrawer] = useState(true);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	const categories = useSelector(({ gonogo }) => gonogo?.categories);
	const defaultCurrentCategory = useSelector(({ gonogo }) => gonogo.defaultCurrentCategory);
	const projectId = useSelector(({ context }) => context.project.id);
	const companyId = useSelector(({ context }) => context.company?.id);
	const [totalDocuments, setTotalDocuments] = useState();
	const navigate = useNavigate();
	const location = useLocation();
	const currentCategory = useSelector(({ gonogo }) => gonogo.currentCategory);
	const isLoadingCategories = useSelector(({ gonogo }) => gonogo.isLoadingCategories);
	const isAnalysisInProgress = useSelector(({ gonogo }) => gonogo.isAnalysisInProgress);
	const [isDownloading, setIsDownLoading] = useState(false);
	const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
	const [isLaunched, setIsLaunched] = useState(false);

	const getDefaultCategory = useCallback(() => {
		dispatch(setCurrentCategory(defaultCurrentCategory));
		navigate(NavUtils.goToReviewByCategory({ projectId, categoryId: defaultCurrentCategory.id, attached: false }));
	}, [defaultCurrentCategory, dispatch, navigate, projectId]);

	useEffect(() => {
		const documentTitle = translate("gonogo-title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Risks analysis" });
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (!isAnalysisInProgress) {
			dispatch(getCategoriesTree(cancelTokenSourceRef.current.token));
		}
	}, [dispatch, isAnalysisInProgress]);
	const handleAutomationMessage = useCallback(
		({ reason }) => {
			if (reason === "Action trigger ends") {
				dispatch(setIsAnalysisInProgress(false));
			}
		},
		[dispatch]
	);
	useEffect(() => {
		dispatch(setIsAnalysisInProgress(false));
	}, [dispatch]);
	useEffect(() => {
		subscribeWS(`/topic/synthesis-by-category/projects/${projectId}/refresh`, handleAutomationMessage);
	}, [subscribeWS, projectId, handleAutomationMessage]);
	useEffect(() => {
		setIsLoadingDocuments(true);
		if ((projectId, companyId)) {
			dashboardService
				.getProjectDocuments({ projectId, companyId }, cancelTokenSourceRef.current.token)
				.then((data) => {
					setTotalDocuments(data.totalDocuments);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => setIsLoadingDocuments(false));
		}
	}, [projectId, companyId]);
	useEffectOnce(
		() => {
			const urlCategoryId = parseInt(new URLSearchParams(location.search).get("categoryId"), 10);
			if (!urlCategoryId) {
				getDefaultCategory();
			} else {
				const foundCategory = findNode(urlCategoryId, categories);
				if (foundCategory) {
					dispatch(setCurrentCategory(foundCategory));
				} else {
					getDefaultCategory();
				}
				const urlFlagged = new URLSearchParams(location.search).get("attached");
				if (foundCategory && urlFlagged) {
					try {
						const flagged = JSON.parse(urlFlagged.toLowerCase());
						dispatch(setSelectedMode(flagged));
					} catch (e) {
						console.error(e);
					}
				}
			}
		},
		[defaultCurrentCategory, categories, dispatch, getDefaultCategory, location.search],
		() => !!defaultCurrentCategory
	);

	const handleOpenDrawer = () => setOpenDrawer((prev) => !prev);

	const renderWildCard = () =>
		isSegFeatureEnabled(SegFlags.CATEGORY) &&
		hasCompanyPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]) &&
		hasPermission(Permissions.PROJECT_LEADER) && (
			<CustomButtonLink
				color="secondary"
				data-testid="manage.categories.btn"
				startIcon={icon.faCog}
				to={NavUtils.goToProjectCategories(projectId)}
				variant="outlined"
			>
				{translate("gonogo.manage-thematics")}
			</CustomButtonLink>
		);
	const handleClickLaunchActions = () => {
		setIsLaunched(true);
		ActionAutoService.triggerAll({ projectId }, cancelTokenSourceRef.current.token)
			.then(() => {
				dispatch(setCurrentCategory({ ...currentCategory }));
				dispatch(setIsAnalysisInProgress(true));
				createNotification({ type: "success", message: translate("common:actions-launched") });
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLaunched(false);
			});
	};
	const handleExport = () => {
		setIsDownLoading(true);
		GonogoCategoryService.exportCategories({ projectId }, cancelTokenSourceRef.current.token)
			.then(({ data, filename }) => {
				downloadFile({ data, filename, filetype: "xlsx" });
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsDownLoading(false);
			});
	};
	const launchButton = (
		<CustomButton
			disabled={categories.length === 0 || isLaunched}
			startIcon={icon.faWandMagic}
			variant="outlined"
			onClick={handleClickLaunchActions}
		>
			{translate("gonogo.launch-actions-button")}
		</CustomButton>
	);
	const exportButton = (
		<CustomButton
			color="secondary"
			disabled={categories.length === 0}
			startIcon={icon.faDownload}
			variant="outlined"
			onClick={handleExport}
		>
			{translate("gonogo.export-analysis-button")}
		</CustomButton>
	);
	const accessToProjectCategoryButton = (
		<CustomButtonLink
			color="primary"
			data-testid="accessToCategory.btn"
			to={NavUtils.goToProjectCategories(projectId)}
			variant="contained"
		>
			{translate("gonogo.content.no-category.access-projects-btn")}
		</CustomButtonLink>
	);
	const accessToDocCenterButton = (
		<CustomButtonLink color="primary" to={NavUtils.goToDocumentCenter(projectId)} variant="contained">
			{translate("gonogo.content.no-documents.access-documents-btn")}
		</CustomButtonLink>
	);
	const noDocumentsRender = (
		<EmptyState
			btn1={accessToDocCenterButton}
			content={translate("gonogo.content.no-documents.text")}
			link={translate("gonogo.content.no-documents.link")}
			linkAddress={translate("gonogo.content.no-documents.link-address")}
			title={translate("gonogo.content.no-documents.title")}
		/>
	);
	const noCategoryRender = (
		<EmptyState
			btn1={
				hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]) &&
				accessToProjectCategoryButton
			}
			content={translate("gonogo.content.no-category.text")}
			link={translate("gonogo.content.no-category.link")}
			linkAddress={translate("gonogo.content.no-category.link-address")}
			title={translate("gonogo.content.no-category.title")}
		/>
	);
	return (
		<>
			<ViewBanner
				options={(isSegFeatureEnabled(SegFlags.AUTOMATION) && [exportButton, launchButton]) || [exportButton]}
				titles={[
					{ title: translate("navigation:project.review"), key: "review" },
					{ title: translate("navigation:project.risk-analysis") },
				]}
			/>
			{(!isLoadingDocuments && totalDocuments === 0 && (
				<div className={styles.noCategoryContainer}>{noDocumentsRender}</div>
			)) ||
				(isLoadingCategories ? (
					<div className={styles.sideDrawer__loader}>
						<CircularLoader size={45} />
					</div>
				) : (
					!isNonEmptyArray(categories) && <div className={styles.noCategoryContainer}>{noCategoryRender}</div>
				)) || (
					<SideDrawerMain
						className={styles.sideDrawer__main}
						contentClassName={styles.sideDrawer__content}
						DrawerComponent={CategoryDrawer}
						openDrawer={openDrawer}
						wildcard={renderWildCard}
						wildCardClassName={styles.wildcard}
						onOpenDrawer={handleOpenDrawer}
					>
						<GonogoHeader />
						<Divider />
						<div className={styles.content}>
							<GonogoContent />
							<VerticalDivider className={styles.divider} />
							<GonogoDetails />
						</div>
					</SideDrawerMain>
				)}
			<CustomDialog
				loader
				maxWidth="xs"
				open={isDownloading}
				subTitle={translate("gonogo.export.wait-message")}
				title={translate("gonogo.export")}
			/>
		</>
	);
};

export { default as gonogoSlice } from "./slice/gonogo-slice";
export default exportView({
	path: "/projects/:projectId/synthesis-by-category",
	localesPath: "/gonogo/locales",
	component: withWebsocket(Gonogo),
	flag: Flags.SELECT_SYNTHESIS,
	segFlag: [SegFlags.CATEGORY, SegFlags.REVIEW_BY_CATEGORY],
});
