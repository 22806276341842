import React from "react";
import { Checkbox } from "@mui/material";
import styles from "./DeliverablesList.module.css";
import { isNonEmptyArray } from "../../../../../../utils";
import { CustomTooltip } from "../../../../../tooltip";

const Deliverableslist = ({ deliverables, onChange, readOnly, selection }) => (
	<div className={styles.container}>
		{isNonEmptyArray(deliverables) &&
			deliverables.map((deliverable) => {
				const isChecked = selection?.includes(deliverable.id) || false;
				return (
					<div className={styles.row}>
						<Checkbox
							checked={isChecked}
							disabled={readOnly}
							onChange={() => onChange(deliverable.id, isChecked)}
						/>
						<div className={styles.row__content} translate="no">
							<CustomTooltip showWhenOverflow title={deliverable.name}>
								<div className={styles.row__documentName}>{deliverable.name}</div>
							</CustomTooltip>
							<CustomTooltip showWhenOverflow title={deliverable.ref}>
								<div className={styles.row__documentRef}>{deliverable.ref}</div>
							</CustomTooltip>
						</div>
					</div>
				);
			})}
	</div>
);

export default Deliverableslist;
