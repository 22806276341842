import React from "react";
import { CircularProgress, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { translate } from "../../../../common/providers";
import styles from "./AddConsortiumDialog.module.css";

const AutoCompleteSearch = ({ users, searchUser, handleSelectUser, isLoadingUsers, error }) => {
	const filterOptions = createFilterOptions({
		stringify: (option) => `${option.displayName} ${option.email}`,
	});
	return (
		<Autocomplete
			fullWidth
			className={styles.dialog__field}
			filterOptions={filterOptions}
			getOptionLabel={(option) => option.displayName}
			loading={isLoadingUsers}
			options={users}
			renderInput={(params) => (
				<TextField
					{...params}
					error={error}
					helperText={error && translate("common:error.required")}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{isLoadingUsers ? <CircularProgress size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
					label={translate("team-management.add-user-dialog.label-search-team-leader")}
					variant="outlined"
				/>
			)}
			renderOption={(props, option) => (
				<span {...props}>
					<b className={styles.autoCompleteSearch__userDisplayName}>{option.displayName}</b> ({option.email})
				</span>
			)}
			onChange={(e, value) => {
				handleSelectUser(value);
			}}
			onInputChange={(e) => {
				searchUser(e);
			}}
		/>
	);
};
export default AutoCompleteSearch;
