import React, { useState } from "react";
import { TextField, Checkbox, ListItemText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "./QADialog.module.css";
import { translate } from "../../../../common/providers";
import { LimitedTextField, CustomDialog } from "../../../../common/components";

export default function QADialog({ open, close, tags, createQA }) {
	const [question, setQuestion] = useState("");
	const [selectedTags, setSelectedTags] = useState([]);

	const changeQuestion = (value) => {
		setQuestion(value);
	};

	const handleChangeTags = (checked, value) => {
		let newSelectedTags = selectedTags;
		if (checked) {
			newSelectedTags.push(value.id);
		} else {
			newSelectedTags = selectedTags.filter((val) => val !== value.id);
		}
		setSelectedTags(newSelectedTags);
	};

	const handleCancel = () => {
		close();
		setSelectedTags([]);
	};

	const handleSubmit = () => {
		const payload = {
			questionAsked: question,
			tag: selectedTags || null,
			informationId: null,
			documentId: null,
			clauseId: null,
		};
		createQA(payload);
		close();
		setSelectedTags([]);
	};

	return (
		<CustomDialog
			disableBackdropClick
			displayCloseCross
			isForm
			open={open}
			title={translate("qa-journal.qa-dialog.qa-dialog.title")}
			onClose={handleCancel}
			onSubmit={handleSubmit}
		>
			<div className={`${styles["margin-bottom"]} ${styles["padding-top"]}`} style={{ display: "flex" }}>
				<LimitedTextField
					fullWidth
					multiline
					required
					className={styles["dialog__name-field"]}
					label={translate("qa-journal.qa-dialog.qa-dialog.question-label")}
					maxLength={512}
					rows={4}
					value={question}
					onChange={changeQuestion}
				/>
			</div>
			{tags && (
				<div className={styles["margin-bottom"]} style={{ display: "flex" }}>
					<Autocomplete
						disableCloseOnSelect
						fullWidth
						multiple
						getOptionLabel={(option) => option.tag}
						id="checkboxes-create-qa-tags"
						options={tags}
						renderInput={(params) => (
							<TextField
								{...params}
								label={translate("qa-journal.qa-dialog.qa-dialog.tag-label")}
								placeholder={translate("qa-journal.qa-dialog.qa-dialog.tag-label")}
								size="small"
								variant="outlined"
							/>
						)}
						renderOption={(props, option, { selected }) => (
							<div {...props} className={styles.tag__row}>
								<Checkbox
									checked={selected}
									color="primary"
									edge="start"
									onChange={(e) => {
										handleChangeTags(e.target.checked, option);
									}}
								/>
								<ListItemText
									primary={option.tag}
									onClick={() => {
										handleChangeTags(!selected, option);
									}}
								/>
							</div>
						)}
					/>
				</div>
			)}
		</CustomDialog>
	);
}
