import React, { useEffect, useMemo, useState } from "react";
import { KEYWORD_FILTER_OPERATOR } from "../../../../../constants";
import OptionContainer from "../components/option-container/OptionContainer";
import { translate } from "../../../../../providers";
import { useEffectOnce } from "../../../../../hooks";
import { SEPARATOR } from "../../../../../../views/dashboards/dashboard-project/my-dashboard/utils/utils";

const OPERATORS = [
	{
		name: KEYWORD_FILTER_OPERATOR.IS_EXACTLY,
		inputLabelKey: "common:component.filters.keyword-filter.keyword-sentence",
		isNegativeFilter: false,
	},
	{
		name: KEYWORD_FILTER_OPERATOR.IS_NOT_EXACTLY,
		inputLabelKey: "common:component.filters.keyword-filter.keyword-sentence",
		isNegativeFilter: true,
	},
	{
		name: KEYWORD_FILTER_OPERATOR.START_WITH,
		inputLabelKey: "common:component.filters.keyword-filter.keyword",
		isNegativeFilter: false,
	},
	{
		name: KEYWORD_FILTER_OPERATOR.START_NOT_WITH,
		inputLabelKey: "common:component.filters.keyword-filter.keyword",
		isNegativeFilter: true,
	},
];
const getTranslateEnumKey = (operator) =>
	`common:enum.keyword-filter.operator.${operator?.toLowerCase().replaceAll("_", "-")}`;

const KeywordFilter = ({
	appliedValue,
	disabledOptionTooltip,
	filterIndex,
	globalSeparator,
	keyJustAdded,
	lastPositive,
	onChange,
	value,
	readOnly = false,
}) => {
	const [selectedOperator, setSelectedOperator] = useState(KEYWORD_FILTER_OPERATOR.IS_EXACTLY);
	const [textInputs, setTextInputs] = useState([""]);
	const [matchAll, setMatchAll] = useState(false);
	const [containsPositiveFilter, setContainsPositiveFilter] = useState(false);
	const canApplyNegative = useMemo(
		() =>
			lastPositive &&
			(value?.filters?.[filterIndex]?.operator === KEYWORD_FILTER_OPERATOR.IS_EXACTLY ||
				value?.filters?.[filterIndex]?.operator === KEYWORD_FILTER_OPERATOR.START_WITH),
		[lastPositive, filterIndex, value]
	);
	useEffectOnce(
		() => {
			if (value?.filters?.length > 0) {
				setContainsPositiveFilter(
					value.filters.some(
						(filter) =>
							filter.operator === KEYWORD_FILTER_OPERATOR.IS_EXACTLY ||
							filter.operator === KEYWORD_FILTER_OPERATOR.START_WITH
					)
				);
			}
			if (!keyJustAdded && value?.filters[filterIndex]) {
				setSelectedOperator(value.filters[filterIndex].operator);
				setTextInputs(value.filters[filterIndex].keywords);
				setMatchAll(value.filters[filterIndex].matchAll);
			}
		},
		[value, filterIndex, keyJustAdded],
		() => keyJustAdded || filterIndex !== undefined
	);
	useEffect(() => {
		if (textInputs.some((val) => val.length > 0)) {
			let tempFilters = [];
			if (Array.isArray(value?.filters)) {
				tempFilters = [...value.filters];
			}
			tempFilters[filterIndex] = { keywords: textInputs, operator: selectedOperator, matchAll };
			if (textInputs.some((textInput) => textInput.length < 2)) {
				if (Array.isArray(appliedValue?.filters)) {
					onChange({ filters: [...appliedValue.filters] });
				} else {
					onChange({ filters: [] });
				}
			} else {
				onChange({ filters: tempFilters });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [textInputs, selectedOperator, matchAll]);
	const handleChangeSelectedOperator = (newOperator) => {
		if (readOnly) {
			return;
		}
		setSelectedOperator(newOperator);
	};
	const handleChangeInput = (newValue, index) => {
		if (readOnly) {
			return;
		}
		let editedValue = newValue;
		if (
			selectedOperator === KEYWORD_FILTER_OPERATOR.START_WITH ||
			selectedOperator === KEYWORD_FILTER_OPERATOR.START_NOT_WITH
		) {
			editedValue = newValue.split(" ").join("");
		}
		const tempTextInput = [...textInputs];
		tempTextInput[index] = editedValue;
		setTextInputs(tempTextInput);
	};
	const handleAddNew = () => {
		if (readOnly) {
			return;
		}
		setTextInputs((prev) => [...prev, ""]);
	};
	const handleRemove = (index) => {
		if (readOnly) {
			return;
		}
		const tempTextInputs = [...textInputs];
		tempTextInputs.splice(index, 1);
		setTextInputs(tempTextInputs);
	};
	const handleChangeMatchAll = ({ value: matchAllValue }) => {
		if (readOnly) {
			return;
		}
		setMatchAll(matchAllValue === "all");
	};
	return (
		<div>
			{OPERATORS.map((operator) => (
				<OptionContainer
					key={operator.name}
					disabled={
						(globalSeparator === SEPARATOR.OR || !containsPositiveFilter || canApplyNegative) &&
						operator.isNegativeFilter
					}
					disabledOptionTooltip={disabledOptionTooltip}
					globalSeparator={globalSeparator}
					label={translate(getTranslateEnumKey(operator.name))?.toLowerCase()}
					matchAll={matchAll}
					operator={operator}
					readOnly={readOnly}
					selected={operator.name === selectedOperator}
					textInputs={textInputs}
					onAddNew={handleAddNew}
					onChangeInput={handleChangeInput}
					onChangeMatchAll={handleChangeMatchAll}
					onChangeSelectedOption={handleChangeSelectedOperator}
					onRemove={handleRemove}
				/>
			))}
		</div>
	);
};

export default KeywordFilter;
