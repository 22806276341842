import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import styles from "./SortDropDown.module.css";
import { DropDown } from "../..";
import { translateSortByAlgorithm } from "./utils";
import { isNonEmptyArray } from "../../../utils";

const SortDropDown = React.forwardRef(
	({ onChangeSort, onClose = null, open, selectedSortAlgo, sortingAlgorithms }, ref) => (
		<DropDown ref={ref} className={styles.dropdown} open={open} onClose={onClose}>
			<RadioGroup
				className={styles.radioGroup}
				value={selectedSortAlgo}
				onChange={(e, value) => {
					onChangeSort(value);
				}}
			>
				{isNonEmptyArray(sortingAlgorithms) &&
					sortingAlgorithms.map((algo) => (
						<FormControlLabel
							key={algo}
							classes={{ label: styles.radios__label }}
							className={styles.labeledCheckbox}
							control={<Radio color="primary" />}
							label={translateSortByAlgorithm(algo)}
							value={algo}
						/>
					))}
			</RadioGroup>
		</DropDown>
	)
);

export default SortDropDown;
