import React, { useEffect, useState } from "react";
import { ApiService, NormsService } from "../../../../api";
import { SearchInput, YearPicker, SidePanelHeader } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import styles from "./NormList.module.css";
import { debounce } from "../../../../common/utils";
import NormListTable from "./norm-list-table/NormListTable";

const debouncedSearch = debounce((value, setValue) => setValue(value), 500);

export default function NormList({
	multipleOnly,
	onCreateCustomNorm,
	onSelectRow,
	onShowDetails,
	CustomActionButtonBody,
}) {
	const [normFilter, setNormFilter] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [dateFilter, setDateFilter] = useState(null);
	const [paging, setPaging] = useState({ page: 0, limit: 25 });
	const [paginatedPage, setPaginatedPage] = useState({ contents: [], totalElements: 0 });
	const [isLoadingNorms, setIsLoadingNorms] = useState(false);
	useEffect(() => {
		const cancalTokenSource = ApiService.getCancelTokenSource();
		const params = { ...paging };
		if (searchValue) {
			params.name = searchValue.toString();
		}
		if (dateFilter) {
			params.date = dateFilter?.getFullYear();
		}
		setIsLoadingNorms(true);
		NormsService.getAll(cancalTokenSource.token, params)
			.then(({ contents, totalElements }) => {
				setPaginatedPage({ totalElements, contents });
				setIsLoadingNorms(false);
			})
			.catch((err) => {
				console.error(err);
				setIsLoadingNorms(false);
			});
		return () => {
			ApiService.cancelTokens(cancalTokenSource);
		};
	}, [searchValue, dateFilter, paging]);
	const handleChangeNormFilter = (e) => {
		const value = e?.target?.value || "";
		setNormFilter(value);
		setPaging((prev) => ({ ...prev, page: 0 }));
		debouncedSearch(value, setSearchValue);
	};
	const handleClearNormFilter = () => {
		setPaging((prev) => ({ ...prev, page: 0 }));
		setNormFilter("");
		setSearchValue("");
	};
	const handleChangeDateFilter = (date) => {
		setPaging((prev) => ({ ...prev, page: 0 }));
		setDateFilter(date);
	};
	const handleChangePage = (_, page) => setPaging((prev) => ({ ...prev, page }));
	const handleChangePageLimit = (e) => {
		setPaging((prev) => ({ ...prev, page: 0, limit: e?.target?.value || 25 }));
	};
	return (
		<>
			<SidePanelHeader>
				<SearchInput
					isLoading={isLoadingNorms}
					label={translate("norms-center.add-norm.filters.name")}
					value={normFilter}
					onChange={handleChangeNormFilter}
					onClearSearch={handleClearNormFilter}
				/>
				<YearPicker
					clearable
					label={translate("norms-center.add-norm.filters.date")}
					value={dateFilter}
					onChange={handleChangeDateFilter}
				/>
				<div className={styles.normList__spacer} />
				<span className={styles.normList__searchResult}>
					{translate(
						paginatedPage.totalElements > 1
							? "norms-center.tab.search-results"
							: "norms-center.tab.search-result",
						{
							totalSearchResult: paginatedPage.totalElements,
						}
					)}
				</span>
			</SidePanelHeader>
			<NormListTable
				contents={paginatedPage.contents || []}
				CustomActionButtonBody={CustomActionButtonBody}
				isLoading={isLoadingNorms}
				multipleOnly={multipleOnly}
				page={paging.page}
				rowsPerPageLimit={paging.limit}
				totalElements={paginatedPage.totalElements || 0}
				onCreateCustomNorm={onCreateCustomNorm}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangePageLimit}
				onSelectRow={onSelectRow}
				onShowDetails={onShowDetails}
			/>
		</>
	);
}
