import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import styles from "./UpdateProfileDialog.module.css";
import { translate } from "../../../../common/providers";
import { CustomDialog } from "../../../../common/components";

export default function UpdateProfileDialog({ open, onClose, user, onUpdate }) {
	const [payload, setPayload] = useState({
		firstname: "",
		lastname: "",
	});
	const [errors, setErrors] = useState({ firstname: false, lastname: false });

	const reset = () => {
		setErrors({ firstname: false, lastname: false });
	};

	useEffect(() => () => reset(), []);

	useEffect(() => {
		setPayload({ firstname: user.firstName || "", lastname: user.lastName || "" });
	}, [user]);

	const handleCloseAndAction = () => {
		if (Object.keys(errors).some((key) => errors[key]) || Object.keys(payload).some((key) => !payload[key])) {
			Object.keys(payload).forEach((key) => !payload[key] && setErrors((prev) => ({ ...prev, [key]: true })));
			return;
		}
		onUpdate(payload);
		onClose();
	};

	const handleChange = (field, value) => {
		if (!field) {
			return;
		}
		if (!value) {
			setErrors((prev) => ({ ...prev, [field]: true }));
		} else {
			setErrors((prev) => ({ ...prev, [field]: false }));
		}
		setPayload((prev) => ({ ...prev, [field]: value }));
	};

	return (
		<CustomDialog
			disableBackdropClick
			displayCloseCross
			fullWidth
			isForm
			open={open}
			title={translate("profile.update-profile.btn")}
			onClose={onClose}
			onExited={reset}
			onSubmit={handleCloseAndAction}
		>
			<TextField
				fullWidth
				required
				className={styles.dialog__field}
				error={errors && errors.firstname}
				helperText={errors && errors.firstname && "Required"}
				label={translate("profile.update-profile.first-name")}
				size="small"
				value={(payload && payload.firstname) || ""}
				variant="outlined"
				onChange={(e) => handleChange("firstname", e.target.value)}
			/>
			<TextField
				fullWidth
				required
				className={styles.dialog__field}
				error={errors && errors.lastname}
				helperText={errors && errors.lastname && "Required"}
				label={translate("profile.update-profile.last-name")}
				size="small"
				value={(payload && payload.lastname) || ""}
				variant="outlined"
				onChange={(e) => handleChange("lastname", e.target.value)}
			/>
		</CustomDialog>
	);
}
