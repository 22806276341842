import React, { useEffect, useMemo, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { CustomButton, icon, SearchInput } from "../../../../../common/components";
import {
	deleteSearch,
	getAllSearches,
	search,
	setCurrentSearch,
	editSearch,
	setIsLoadingSearch,
	updateSearchResultCount,
	setItems,
	setIsUpdating,
} from "../../slice/search-for-automation-slice";
import styles from "./SmartSearchDrawer.module.css";
import { SmartSearchMenu } from "../../../components";
import { ApiService, SearchCardService } from "../../../../../api";
import { Flags, hasPermission, isFeatureEnabled, Permissions, translate } from "../../../../../common/providers";
import { useApi } from "../../../../../common/hooks";
import AddTemplateSidePanel from "./add-template-sidepanel/AddTemplateSidePanel";
import { OPERATORS, VISIBILITY } from "../../../../../common/constants";
import { debounce } from "../../../../../common/utils";

const SEARCH_TYPES = {
	MY_SEARCHES: "mySearches",
	TEAM_SEARCHES: "teamSearches",
	PROJECT_SEARCHES: "projectSearches",
};
const debouncedFunction = debounce((func) => func());
const generateFilters = ({ menus, openedMenuIndex, authorInput, searchValueDebounced }) => {
	const filters = { visibility: menus[openedMenuIndex].visibility };
	if (authorInput) {
		filters.users = [authorInput.id];
	}
	if (searchValueDebounced) {
		filters.name = searchValueDebounced;
	}
	return filters;
};
export default function SmartSearchDrawer() {
	const currentSearchId = useSelector(({ searchForAutomation }) => searchForAutomation.currentSearch?.id || 0);
	const mySearches = useSelector(({ searchForAutomation }) => searchForAutomation.mySearches);
	const projectId = useSelector(({ context }) => context.project.id);
	const searchLimit = useSelector(({ searchForAutomation }) => searchForAutomation.limit);
	const isUpdating = useSelector(({ searchForAutomation }) => searchForAutomation.isUpdating);

	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const [isSearchesLoading, setIsSearchesLoading] = useState(false);
	const [openAddTemplatePanel, setOpenAddTemplatePanel] = useState(false);
	const [openedMenuIndex, setOpenedMenuIndex] = useState(0);
	const [searchValue, setSearchValue] = useState("");
	const [searchValueDebounced, setSearchValueDebounced] = useState("");

	const tokenSrcRef = useRef(null);
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const { call: getSearchCard, cancel: cancelGetSearchCard } = useApi(SearchCardService.get);

	const menus = useMemo(
		() => [
			{
				key: SEARCH_TYPES.MY_SEARCHES,
				visibility: "AUTOMATION",
				title: translate("smart-search.drawer.menu.search-for-automation"),
				items: mySearches,
			},
		],
		[mySearches]
	);

	useEffect(() => {
		tokenSrcRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(tokenSrcRef.current);
		};
	}, []);

	useEffect(
		() => {
			if ((projectId || isUpdating) && openedMenuIndex >= 0 && menus[openedMenuIndex]) {
				setTimeout(() => {
					setInfiniteListRequest(
						() =>
							({ page, limit }) =>
								SearchCardService.getAll(
									{ projectId },
									{
										page,
										limit,
										filters: generateFilters({
											openedMenuIndex,
											menus,
											searchValueDebounced,
										}),
									},
									tokenSrcRef.current.token
								).then((data) => {
									dispatch(setItems({ items: data.contents || [], key: menus[openedMenuIndex].key }));
									return data;
								})
					);
				}, 300);
				if (isUpdating) {
					dispatch(setIsUpdating(false));
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, isUpdating, openedMenuIndex, searchValueDebounced]
	);

	const handleNewSearch = () => {
		ApiService.cancelTokens(tokenSrcRef.current);
		tokenSrcRef.current = ApiService.getCancelTokenSource();
		batch(() => {
			dispatch(
				setCurrentSearch({
					currentSearch: {
						isNew: true,
						id: 0,
						creatorId: 0,
						title: "",
						description: "",
						visibility: VISIBILITY.PRIVATE,
						listKey: "",
					},
					filters: { separator: OPERATORS.AND, Type: "InformationSearchFilterDTO" },
					isFilterTocOut: false,
					totalMatches: 0,
					page: 0,
				})
			);
			dispatch(setIsLoadingSearch(true));
			dispatch(search({ page: 0, limit: searchLimit, token: tokenSrcRef.current.token }));
		});
	};
	const handleClickSearch = (key, { id, ...rest }) => {
		const page = 0;
		cancelGetSearchCard();
		batch(() => {
			dispatch(setIsLoadingSearch(true));
			dispatch(
				setCurrentSearch({
					currentSearch: {
						isNew: false,
						id,
						listKey: key,
						...rest,
					},
					filters: { separator: OPERATORS.AND, Type: "InformationSearchFilterDTO" },
					isFilterTocOut: false,
					totalMatches: 0,
					page: 0,
					totalPages: 0,
					hasMore: false,
					results: [],
				})
			);
		});
		getSearchCard({ searchCardId: id }, page, searchLimit)
			.then(({ details, filters: filterCount }) => {
				const { contents, totalPages, hasMore } = details;
				const { filters, resultCount } = filterCount;
				const { isFilterTocOut, ...actualFilters } = filters;
				batch(() => {
					dispatch(setIsLoadingSearch(false));
					dispatch(
						updateSearchResultCount({
							id,
							resultCount,
							listKey: key,
						})
					);
					dispatch(
						setCurrentSearch({
							currentSearch: {
								isNew: false,
								id,
								listKey: key,
								...rest,
							},
							filters: actualFilters,
							isFilterTocOut,
							totalMatches: resultCount,
							page,
							totalPages,
							hasMore,
							results: contents,
						})
					);
				});
			})
			.catch((err) => {
				console.error(err);
				dispatch(setIsLoadingSearch(false));
			});
	};

	useEffect(() => {
		const newSearchCardId = +searchParams.get("searchCardId") || 0;
		if (newSearchCardId > 0) {
			let searchCard = null;
			const key = menus.find((menu) =>
				menu.items.some((item) => {
					const isEqual = item.id === newSearchCardId;
					if (isEqual) {
						searchCard = item;
					}
					return isEqual;
				})
			)?.key;
			if (searchCard && key) {
				handleClickSearch(key, searchCard);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams, menus]);

	const handleEditSearch = ({ id, ...payload }) => {
		dispatch(
			editSearch({
				searchCardId: id,
				creatorId: payload.creatorId,
				payload: { ...payload, description: payload.description || undefined },
				token: tokenSrcRef.current.token,
			})
		);
		dispatch(setIsUpdating(true));
	};
	const handleRemoveSearch = ({ id, creatorId }) => {
		dispatch(deleteSearch({ searchCardId: id, creatorId, token: tokenSrcRef.current.token })).then(() => {
			if (id === currentSearchId) {
				handleNewSearch();
			}
		});
		dispatch(setIsUpdating(true));
	};
	const handleOpenMenu = (index) => {
		setOpenedMenuIndex((prev) => (prev === index ? null : index));
	};
	const handleOpenAddTemplatePanel = () => {
		setOpenAddTemplatePanel(true);
	};
	const handleCloseAddTemplatePanel = () => {
		setOpenAddTemplatePanel(false);
		dispatch(getAllSearches({ token: tokenSrcRef.current.token, key: menus[openedMenuIndex].key }));
	};
	const handleChangeSearchText = (event) => {
		const { value } = event.target;
		setSearchValue(value);
		setIsSearchesLoading(true);
		debouncedFunction(() => {
			setSearchValueDebounced(value);
			setIsSearchesLoading(false);
		});
	};
	const handleResetSearchText = () => {
		setSearchValue("");
		setSearchValueDebounced("");
	};

	const isNew = !currentSearchId || currentSearchId === 0;
	return (
		<>
			<div className={styles.drawer}>
				<div className={styles.buttonsContainer}>
					{isFeatureEnabled(Flags.SEARCHTEMPLATE) &&
						hasPermission([Permissions.COMPANY_ADMIN, Permissions.COMPANY_MANAGER]) && (
							<CustomButton
								color="secondary"
								startIcon={icon.faPlus}
								variant="outlined"
								onClick={handleOpenAddTemplatePanel}
							>
								{translate("smart-search.drawer.add-template")}
							</CustomButton>
						)}
					<CustomButton
						className={styles.drawer__newSearchLabel}
						disabled={isNew}
						startIcon={icon.faSearch}
						variant="outlined"
						onClick={handleNewSearch}
					>
						{translate("smart-search.drawer.new-search")}
					</CustomButton>
				</div>
				<div className={styles.searchFieldsContainer}>
					<SearchInput
						fullWidth
						placeholder={translate("common:btn.search")}
						value={searchValue}
						onChange={handleChangeSearchText}
						onClearSearch={handleResetSearchText}
					/>
				</div>
				{menus.map((menu, index) => (
					<SmartSearchMenu
						key={menu.key}
						currentSearchId={currentSearchId}
						infiniteRequest={infiniteListRequest}
						isLoading={isSearchesLoading}
						items={menu.items}
						menusCount={menus.length}
						open={openedMenuIndex === index}
						slice="searchForAutomation"
						title={menu.title}
						onClickItem={(params) => handleClickSearch(menu.key, params)}
						onClickOpen={() => handleOpenMenu(index)}
						onEdit={handleEditSearch || null}
						onRemove={handleRemoveSearch || null}
					/>
				))}
			</div>
			<AddTemplateSidePanel open={openAddTemplatePanel} onClose={handleCloseAddTemplatePanel} />
		</>
	);
}
