import React, { useRef, useState } from "react";
import SortDropDown from "./SortDropDown";
import styles from "./Sort.module.css";
import { CustomButton, icon, IconComponent } from "../..";
import { translateSortAlgorithm } from "./utils";

export default function Sort({ selectedSortAlgo, sortingAlgorithms, onChangeSort }) {
	const optionsRef = useRef();
	const [open, setOpen] = useState(false);
	const handleDropdown = () => {
		setOpen((prev) => !prev);
	};
	const handleChange = (value) => {
		onChangeSort(value);
		handleDropdown();
	};
	return (
		<>
			<CustomButton
				ref={optionsRef}
				color="secondary"
				startIcon={icon.faSortAmountDown}
				variant="outlined"
				onClick={handleDropdown}
			>
				{translateSortAlgorithm(selectedSortAlgo)}
				<IconComponent className={styles.button__collapseIcon} icon={icon.faChevronDown} />
			</CustomButton>
			<SortDropDown
				ref={optionsRef}
				open={open}
				selectedSortAlgo={selectedSortAlgo}
				sortingAlgorithms={sortingAlgorithms}
				onChangeSort={handleChange}
				onClose={handleDropdown}
			/>
		</>
	);
}
