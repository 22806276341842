import React from "react";
import { CustomTooltip, CustomButton, IconComponent, SidePanelContainer } from "../..";
import styles from "./OptionsPanelContainer.module.css";

const OptionsPanelContainer = ({
	buttonIcon = null,
	buttonText = "",
	buttonTextSecondary = "",
	children,
	className = "",
	disabledPrimaryButton = false,
	disabledSecondaryButton = false,
	mainClassName = null,
	noHeader = false,
	onClick,
	onClickSecondary = null,
	primaryTooltip = "",
	rightIcon = null,
	rightIconClassName = "",
	title = "",
}) => (
	<SidePanelContainer className={`${styles.sidePanelContainer} ${mainClassName}`}>
		<div>
			{!noHeader && (
				<div className={styles.header}>
					<span className={styles.title}>{title}</span>
					<span className={styles.btnContainer}>
						{buttonTextSecondary && onClickSecondary && (
							<CustomButton
								className={styles.btnContainer__btnSecondary}
								disabled={disabledSecondaryButton}
								size="md"
								variant="text"
								onClick={onClickSecondary}
							>
								{buttonTextSecondary}
							</CustomButton>
						)}
						{buttonText && onClick && (
							<CustomTooltip title={primaryTooltip}>
								<span>
									<CustomButton
										disabled={disabledPrimaryButton}
										size="md"
										starIcon={buttonIcon}
										variant="contained"
										onClick={onClick}
									>
										{buttonText}
									</CustomButton>
								</span>
							</CustomTooltip>
						)}
						{rightIcon && <IconComponent className={rightIconClassName} icon={rightIcon} size="xl" />}
					</span>
				</div>
			)}
			<div className={`${styles.optionsContainer} ${className}`}>{children}</div>
		</div>
	</SidePanelContainer>
);

export default OptionsPanelContainer;
