import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./LinkRow.module.css";
import {
	CustomButtonLink,
	CustomDialog,
	CustomIconButton,
	Hierarchy,
	IconComponent,
	icon,
} from "../../../../../../../../../common/components";
import { translate, translateElapsed } from "../../../../../../../../../common/providers";
import { useApi } from "../../../../../../../../../common/hooks";
import informationLinkService from "../../../../../../../../../api/services/smartview/information-link-service";
import { linkConversion, oppositeLink, translateEnumLinks } from "../../../../../../../utils/utils";
import { SENTENCE_TYPE } from "../../../../../../../constants/constants";
import { NavUtils } from "../../../../../../../../../navigation";
import LinkEditDialog from "./LinkEditDialog";
import { handleLinkTypeCountDecrement } from "../../../../../../../slice/utils";
import { updateNode } from "../../../../../../../slice/pdf/pdf-slice";

const LinkRow = ({ data, informationId, onEditLink, onRemoveLink }) => {
	const { call: deleteLink } = useApi(informationLinkService.deleteLink);
	const {
		content,
		documentId,
		documentName,
		informationId: linkedId,
		informationType,
		link,
		linkId,
		pageStart,
		location,
		elapsed,
	} = data;
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const projectId = useSelector(({ srDocument }) => srDocument.projectId);
	const linkTypes = useSelector(({ srDocument }) => srDocument.linkTypes);
	const companyId = useSelector(({ context }) => context.company?.id);
	const selectedMode = useSelector(({ srDocument }) => srDocument.mode);
	const selectedSearch = useSelector(({ srDocument }) => srDocument.selectedSearch);
	const linkDetails = useMemo(() => linkConversion(link), [link]);
	const navLocation = useLocation();
	const dispatch = useDispatch();

	const handleDelete = () => {
		deleteLink({ informationId, linkId })
			.then(() => {
				onRemoveLink(linkId);
				const updates = (opposite) => [
					{
						property: "linkTypeCount",
						callback: handleLinkTypeCountDecrement,
						parameters: { linkType: (opposite && oppositeLink(link)) || link },
					},
				];
				dispatch(
					updateNode({
						mode: selectedMode,
						pageConditions: { page: selectedSearch.pageStart },
						informationId,
						updates: updates(false),
					})
				);
				if (documentId === data.documentId) {
					dispatch(
						updateNode({
							mode: selectedMode,
							pageConditions: { page: pageStart },
							informationId: linkedId,
							updates: updates(true),
						})
					);
				}
				setOpenDeleteDialog(false);
			})
			.catch(console.error);
	};
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<div>
					<span>
						<IconComponent
							className={styles.linkIcon}
							color={linkDetails?.color}
							icon={icon.faLink}
							size="xs"
						/>
						<span className={styles.linkType}>{translateEnumLinks(link).replaceAll(".", "")}</span>
						<span className={styles.contentId}>
							{(informationType === SENTENCE_TYPE.INFORMATION && "INFO") || "REQ"}_{linkedId}
						</span>
					</span>
				</div>
				<div className={styles.elapsedTime}>
					{translateElapsed({
						value: elapsed?.value,
						unitKey: elapsed?.unit,
					})}
				</div>
			</div>
			<div>
				<Hierarchy
					isDangerousSetInnerHTML
					breadcrumbClassName={styles.heriarchy}
					className={styles.heriarchy}
					documentName={documentName}
					hierarchy={location}
				/>
			</div>
			<div className={styles.text}>{content}</div>
			<div className={styles.footer}>
				<CustomButtonLink
					openInNewTab
					color="secondary"
					startIcon={icon.faUpRightFromSquare}
					state={navLocation?.state}
					to={NavUtils.goToSmartReview({
						projectId,
						documentId,
						pageStart,
						infoId: linkedId,
						infoType: informationType,
						companyId,
					})}
					variant="outlined"
				>
					{translate("common:redirection.open-content")}
				</CustomButtonLink>
				<CustomIconButton
					icon={icon.faPenToSquare}
					size="sm"
					variant="outlined"
					onClick={() => {
						setOpenEditDialog(true);
					}}
				/>
				<CustomIconButton
					icon={icon.faTrash}
					size="sm"
					variant="outlined"
					onClick={() => {
						setOpenDeleteDialog(true);
					}}
				/>
				<CustomDialog
					iconColor="var(--color-red)"
					iconModel={icon.faExclamationTriangle}
					open={openDeleteDialog}
					submitLabel={translate("common:btn.delete")}
					subTitle={translate(
						"smart-review.cmp-details.option-panel.link-tab.delete-link.confirmation-message"
					)}
					title={translate("common:delete-confirmation-popup.title")}
					onClose={() => {
						setOpenDeleteDialog(false);
					}}
					onSubmit={handleDelete}
				/>
				<LinkEditDialog
					informationId={informationId}
					link={link}
					linkId={linkId}
					linkTypes={linkTypes}
					open={openEditDialog}
					onClose={() => {
						setOpenEditDialog(false);
					}}
					onEditLink={onEditLink}
				/>
			</div>
		</div>
	);
};

export default LinkRow;
