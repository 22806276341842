import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CustomButton, SidePanel, SidePanelContent } from "../../../../../common/components";
import styles from "./OpportunityCreationPanel.module.css";
import { translate } from "../../../../../common/providers";
import { OpportunityCollaborators, OpportunityImports, OpportunityName } from "./components";

export default function OpportunityCreationPanel({ initial, open, onActive, onClose, onSubmit }) {
	const [name, setName] = useState("");
	const [errorExisting, setErrorExisting] = useState(false);
	const [collaborators, setCollaborators] = useState([]);
	const [files, setFiles] = useState([]);
	const [totalFileSize, setTotalFileSize] = useState(0);
	const opportunityList = useSelector(({ opportunity }) => opportunity.opportunityList);
	const handleSetName = (e) => {
		setName(e.target.value);
		if (opportunityList.find((oppo) => oppo.name === e.target.value)) {
			setErrorExisting(true);
		} else if (errorExisting) {
			setErrorExisting(false);
		}
	};
	const handleAddCollaborator = (col) => {
		setCollaborators((prev) => [...prev, col]);
	};
	const handleRemoveCollaborator = (col) => {
		setCollaborators((prev) => prev.filter((pc) => col !== pc));
	};
	const handleAddFiles = (docs) => {
		setFiles((prev) => [...prev, ...docs]);
		setTotalFileSize(totalFileSize + docs.reduce((sum, doc) => sum + doc.size, 0));
	};
	const handleRemoveFile = (doc) => {
		const removedFiles = files.filter((f) => f.name === doc.name);
		setTotalFileSize(totalFileSize - removedFiles.reduce((sum, f) => sum + f.size, 0));
		setFiles((prev) => prev.filter((f) => f.name !== doc.name));
	};
	useEffect(() => {
		if (initial.length > 0) {
			setFiles(initial);
			setTotalFileSize(initial.reduce((sum, file) => sum + file.size, 0));
		} else if (initial) {
			setFiles([initial]);
			setTotalFileSize(0);
		}
	}, [initial]);
	const reset = () => {
		setName("");
		setCollaborators([]);
		setFiles([]);
	};
	const handleClose = () => {
		reset();
		onClose();
	};
	const handleSubmit = () => {
		const payload = {
			name,
			isSingleProject: true,
			userIds: collaborators,
		};
		onSubmit(files, payload);
		handleClose();
	};
	return (
		<SidePanel
			disableBackdropClick
			disableEscapeKeyDown
			open={open}
			size={30}
			onActive={onActive}
			onClose={handleClose}
		>
			<SidePanelContent title={translate("opportunity.creation.side-panel.title")} onClose={handleClose}>
				<div className={styles.main}>
					<div className={styles.container}>
						<OpportunityName error={errorExisting} name={name} onChange={handleSetName} />
						<OpportunityCollaborators
							collaborators={collaborators}
							onAdd={handleAddCollaborator}
							onRemove={handleRemoveCollaborator}
						/>
						<OpportunityImports
							files={files}
							totalFileSize={totalFileSize}
							onAdd={handleAddFiles}
							onRemove={handleRemoveFile}
						/>
					</div>
					<div className={styles.footer}>
						<CustomButton color="secondary" variant="outlined" onClick={handleClose}>
							{translate("common:btn.cancel")}
						</CustomButton>
						<CustomButton
							color="primary"
							disabled={files.length < 1 || name.length < 1 || totalFileSize > 1.5e9 || errorExisting}
							variant="contained"
							onClick={handleSubmit}
						>
							{translate("common:btn.submit")}
						</CustomButton>
					</div>
				</div>
			</SidePanelContent>
		</SidePanel>
	);
}
