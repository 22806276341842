import MatomoTracker from "@datapunt/matomo-tracker-js";

const tracker = new MatomoTracker({
	urlBase: window.env.REACT_APP_ANALYTICS_URL,
	siteId: window.env.REACT_APP_ANALYTICS_SITE_ID,
	disabled: window.env.REACT_APP_ANALYTICS_DISABLED,
	linkTracking: false,
});

export default {
	trackPageView: ({ documentTitle, href = window.location }) =>
		tracker.trackPageView({
			documentTitle,
			href,
		}),
};
