import React, { useCallback, useEffect, useMemo, useState } from "react";
import { batch } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import { debounce } from "../../utils";
import { CircularLoader } from "..";
import { translate } from "../../providers";
import styles from "./PdfViewer.module.css";

const debounceFetchPages = debounce((handleGetPages, start, end) => {
	handleGetPages(start, end);
}, 500);

export default function PdfViewer({
	content = [],
	height,
	numberOfPages = 0,
	onGetMetadata = null,
	onGetPage = null,
	onRenderLayers = null,
	onSetPage = null,
	pageDimensions = [],
	scrollRef = null,
	showScrollbar = true,
	zoom = 100,
	onScroll = null,
	onMouseEnter = null,
	onRenderAdditionalPanel = undefined,
	onRenderGlobalOverlay = null,
}) {
	const [history, setHistory] = useState([]);
	useEffect(() => {
		if (content.length === 0) {
			setHistory([]);
		}
	}, [content]);

	const handleGetPages = useCallback(
		(start, end) => {
			if (typeof onGetPage === "function") {
				batch(() => {
					for (let i = start; i <= end; i++) {
						if (i > -1 && i < numberOfPages) {
							if (!history.some((c) => c === i)) {
								setHistory((prev) => [...prev, i]);
								onGetPage(i + 1);
							}
							if (typeof onGetMetadata === "function") {
								onGetMetadata(i + 1);
							}
						}
					}
				});
			}
		},
		[history, onGetMetadata, onGetPage, numberOfPages]
	);

	const handleVisibility = (isVisible, id) => {
		if (isVisible && typeof onSetPage === "function") {
			onSetPage(id);
			debounceFetchPages(handleGetPages, id - 5, id + 5);
		}
	};

	const rowRenderer = ({ key, index, dimensions }) => {
		const row = content.find((c) => c.page === index + 1);
		const effectiveWidth = (dimensions.width * zoom) / 100 || 0;
		const effectiveHeigth = (dimensions.height * zoom) / 100 || 0;
		const { image, isLoading, ...metadata } = row || {};
		const pdfContainerId = document.getElementById("pdfContainer_id");
		const preventDragHandler = (e) => {
			e.preventDefault();
		};
		return (
			<div
				key={key}
				className={styles.row}
				data-testid="pdf-reviewer.row"
				id={`row_${key + 1}_pdf_viewer`}
				style={{ height: effectiveHeigth }}
			>
				<VisibilitySensor
					delayedCall
					partialVisibility
					containment={pdfContainerId}
					offset={{ bottom: height * 0.7, top: height * 0.3 }}
					onChange={(isVisible) => {
						if (handleVisibility) {
							handleVisibility(isVisible, index);
						}
					}}
				>
					<div className={styles.imageContainer} onDragStart={preventDragHandler}>
						{row &&
							((isLoading && (
								<div className={styles.image__loader}>
									<CircularLoader color="primary" size={80} />
								</div>
							)) ||
								(image && (
									<>
										<img
											alt={`image_${key}`}
											className={styles.image}
											height={effectiveHeigth}
											role="presentation"
											src={image}
											width={effectiveWidth}
										/>
										{typeof onRenderLayers === "function" && (
											<>
												{onRenderLayers({
													metadata,
													height: effectiveHeigth,
													width: effectiveWidth,
													tableHeight: height,
													onChangeVisibility: null,
													openedQuickAccess: false,
												})}
											</>
										)}
									</>
								)) || (
									<div className={styles.image__error}>
										{translate("common:pdf-image.unavailable", { page: index + 1 })}
									</div>
								))}
					</div>
				</VisibilitySensor>
			</div>
		);
	};

	const totalHeight = useMemo(
		() => ((pageDimensions || []).reduce((acc, curr) => acc + curr.height, 0) * zoom) / 100 || 0,
		[pageDimensions, zoom]
	);
	const additionalPanel = useMemo(() => {
		if (onRenderAdditionalPanel && typeof onRenderAdditionalPanel === "function") {
			return onRenderAdditionalPanel();
		}
		return null;
	}, [onRenderAdditionalPanel]);
	return (
		<>
			<div
				ref={scrollRef}
				className={`${styles.pdfViewer} ${!showScrollbar && styles.pdfViewer__hideScroll} `}
				data-has-right-panel={onRenderAdditionalPanel !== undefined}
				data-right-panel-on={!!additionalPanel}
				id="pdfContainer_id"
				style={{ height }}
				onMouseEnter={onMouseEnter}
				onScroll={onScroll}
			>
				<div className={styles.pdfViewerList} style={{ height: totalHeight }}>
					<div
						className={styles.pdf}
						data-has-right-panel={onRenderAdditionalPanel !== undefined}
						data-right-panel-on={!!additionalPanel}
						id="pdfViewerList_id"
					>
						{pageDimensions.map((dimensions, index) => rowRenderer({ key: index, index, dimensions }))}
						{typeof onRenderGlobalOverlay === "function" && onRenderGlobalOverlay()}
					</div>
					{!!additionalPanel && <div className={styles.rightPanel}>{additionalPanel}</div>}
				</div>
			</div>
		</>
	);
}
