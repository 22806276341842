import React, { useRef, useEffect, useMemo } from "react";

import { CustomIconButton, icon, createNotification } from "../../../../../../../../../common/components";
import { translate } from "../../../../../../../../../common/providers";
import { ApiService, CollabService } from "../../../../../../../../../api";

export default function CopyToClipboardButton({ hoveredBlock }) {
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			cancelTokenSourceRef.current.cancel();
		};
	}, []);
	const requestContentString = () => {
		if (hoveredBlock) {
			CollabService.getStringContent({ infoId: hoveredBlock.informationId }, cancelTokenSourceRef.current.token)
				.then((data) => {
					navigator.clipboard.writeText(data.value);
					createNotification({
						message: translate("smart-review.image-container.notification.content-copied"),
					});
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};
	const canCopy = useMemo(
		() =>
			hoveredBlock.contentType === "TEXT" ||
			hoveredBlock.contentType === "PARAGRAPH" ||
			hoveredBlock.contentType === "CLAUSE" ||
			hoveredBlock.contentType === "TABLE_CELL",
		[hoveredBlock]
	);
	return (
		<>
			{canCopy && (
				<CustomIconButton
					icon={icon.faCopy}
					tooltip={translate("smart-review.cmp-display-overlay.tooltip.copy-content")}
					onClick={requestContentString}
				/>
			)}
		</>
	);
}
