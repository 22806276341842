import { createAsyncThunk } from "@reduxjs/toolkit";
import { GonogoCategoryService, GonogoInformationService } from "../../../api";

function getProjectId(getState) {
	const {
		context: {
			project: { id: projectId },
		},
	} = getState();
	return projectId;
}

export const getCategoriesTree = createAsyncThunk("gonogo/getCategoriesTree", (token, { getState }) => {
	const projectId = getProjectId(getState);
	return GonogoCategoryService.getCategoriesTree({ projectId }, token);
});
export const getCategoryInformation = createAsyncThunk(
	"gonogo/getCategoryInformation",
	({ categoryId, params, page, limit, token }) =>
		GonogoInformationService.getCategoryInformation({ categoryId }, { params, page, limit }, token)
);
export const attachInformationCategory = createAsyncThunk(
	"gonogo/attachInformationCategory",
	({ categoryId, informationId, payload, token }) =>
		GonogoInformationService.attachInformationCategory({ categoryId, informationId }, payload, token)
);
