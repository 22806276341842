import React from "react";
import { CustomTooltip } from "../../tooltip";
import styles from "./CustomDoubleEntryGrid.module.css";

export default function CustomDoubleEntryGrid({
	cellRenderer,
	cellHeight = null,
	cellWidth = null,
	className = "",
	diagonalText = "",
	squareSide = 100,
	xDisplayField,
	xItems = [],
	xKeyField = "",
	yDisplayField,
	yItems = [],
	yKeyField = "",
}) {
	return (
		<div className={`${styles.gridContainer} ${className}`}>
			<div className={`${styles.row} ${styles["row--first"]}`}>
				<div className={styles.row__cornerContainer} style={{ width: squareSide, height: squareSide }}>
					<div
						className={styles["row__corner--diagonal"]}
						style={{
							minWidth: squareSide,
							minHeight: squareSide,
							maxWidth: squareSide,
							maxHeight: squareSide,
						}}
					>
						<div className={styles.row__corner}>
							<div className={styles.corner__text}>{diagonalText}</div>
						</div>
					</div>
				</div>
				{xItems.map((xItem) => (
					<div
						key={xItem[xKeyField]}
						className={styles.row__xNameContainer}
						style={{ minHeight: squareSide, width: cellWidth, minWidth: cellWidth }}
					>
						<CustomTooltip title={xItem[xDisplayField]}>
							<div className={styles["row__xNameContainer--text"]} style={{ minWidth: squareSide }}>
								{xItem[xDisplayField]}
							</div>
						</CustomTooltip>
					</div>
				))}
			</div>
			{yItems.map((yItem) => (
				<div key={yItem[yKeyField]} className={styles.row}>
					<div
						className={styles.row__yName}
						style={{ minWidth: squareSide, maxWidth: squareSide, height: cellHeight }}
					>
						<CustomTooltip title={yItem[yDisplayField]}>
							<div className={styles["row__yName--text"]}>{yItem[yDisplayField]}</div>
						</CustomTooltip>
					</div>
					{xItems.map((xItem) => (
						<div
							key={xItem[xKeyField]}
							className={styles.row__cell}
							style={{ height: cellHeight, minHeight: cellHeight, width: cellWidth, minWidth: cellWidth }}
						>
							{cellRenderer({ xItem, yItem })}
						</div>
					))}
				</div>
			))}
		</div>
	);
}
