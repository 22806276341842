import { hasCompanyPermission, hasPermission } from "../../../common/providers";
import { isNonEmptyArray } from "../../../common/utils";

export function hasReviewPermissions(actionPermissions, { roles }) {
	const requirements = [
		{
			required: !!actionPermissions?.userDocRole,
			fulfilled:
				isNonEmptyArray(actionPermissions?.userDocRole) &&
				actionPermissions.userDocRole.filter((value) => roles.includes(value.toUpperCase())).length > 0,
		},
		{
			required: !!actionPermissions?.companyProject,
			fulfilled: hasCompanyPermission(actionPermissions?.companyProject || []),
		},
		{
			required: !!actionPermissions?.userProjectRole,
			fulfilled: hasPermission(actionPermissions?.userProjectRole || []),
		},
	];
	return requirements.filter((req) => req.required).every((req) => req.fulfilled);
}

export function hasAllMultipleReviewPermissions(actionPermissionsList, { roles }) {
	return actionPermissionsList.every((apl) => hasReviewPermissions(apl, { roles }));
}

export function hasAnyMultipleReviewPermissions(actionPermissionsList, { roles }) {
	return actionPermissionsList.some((apl) => hasReviewPermissions(apl, { roles }));
}
