import React from "react";
import { ListItem, ListItemText, Divider, ListItemIcon, Checkbox } from "@mui/material";
import { translate } from "../../../../../common/providers";

export default function RelatedContentClauses({ item, editContentOn, onCheck, onClickClause, itemIsChecked }) {
	const handleClickClause = () => {
		if (item.subclauseExist) {
			onClickClause(item.id);
		}
	};
	const handleChange = (event) => {
		onCheck(event.target.checked, item);
	};
	const renderClauseSecondaryText = ({ clauseIsReferenced, nbINformationReferenced, nbSubclauseReferenced }) => {
		if (clauseIsReferenced) {
			return translate("qa-journal.qa-details.related-content.secondary-text.all-section");
		}
		if (nbINformationReferenced > 0 || nbSubclauseReferenced > 0) {
			return translate("qa-journal.qa-details.related-content.secondary-text.content-number", {
				contentReferenced: (nbINformationReferenced > 0 && nbINformationReferenced) || nbSubclauseReferenced,
			});
		}
		return translate("qa-journal.qa-details.related-content.secondary-text.no-content");
	};
	return (
		<>
			<ListItem key={item.id} button={item.subclauseExist}>
				<ListItemIcon>
					<Checkbox
						disableRipple
						checked={itemIsChecked}
						color="primary"
						disabled={!editContentOn}
						edge="start"
						onChange={handleChange}
					/>
				</ListItemIcon>
				<ListItemText
					id={item.id}
					primary={item.content}
					secondary={renderClauseSecondaryText(item)}
					onClick={handleClickClause}
				/>
			</ListItem>
			<Divider />
		</>
	);
}
