import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService, ProjectCreationService } from "../../../../../api";
import {
	createNotification,
	DocumentProgress,
	I18nTranslate,
	icon,
	IconComponent,
} from "../../../../../common/components";
import { loadProjectCreation, setIsUploading, setDocsFailed } from "../../../slice/newProjectSlice";
import styles from "./ProjectDocuments.module.css";
import { translate } from "../../../../../common/providers";
import DocumentProgressList from "./DocumentProgressList";
import { DOCUMENT_STATUSES } from "../../../../../common/constants/documentStatuses";
import { isNonEmptyArray } from "../../../../../common/utils";
import UnsupportedInformationBanner from "./UnsupportedInformationBanner";
import DocumentDragAndDropInfo from "../../../../../common/components/drag-and-drop/document-drag-and-drop-info/DocumentDragAndDropInfo";

export default function ProjectDocuments() {
	const [dragOver, setDragOver] = useState(false);
	const projectCreation = useSelector((state) => state.newProject.projectCreation);
	const cancelTokenSourceRef = useRef(null);
	const pendingDocumentsTokensSources = useRef([]);
	const dispatch = useDispatch();
	const [pendingDocs, setPendingDocs] = useState([]);
	const inputBrowseMoreRef = useRef(null);
	const inputBrowseRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
			pendingDocumentsTokensSources.current.forEach((s) => {
				ApiService.cancelTokens(s);
			});
		};
	}, []);
	useEffect(() => {
		dispatch(setIsUploading(pendingDocs.length > 0));
	}, [pendingDocs, dispatch]);
	useEffect(() => {
		if (projectCreation.documents) {
			dispatch(
				setDocsFailed(
					projectCreation.documents.some(
						(d) =>
							d.status !== DOCUMENT_STATUSES.completed && d.status !== DOCUMENT_STATUSES.errorNotSupported
					)
				)
			);
		}
	}, [projectCreation, dispatch]);
	const handleDragOver = (event) => {
		event.preventDefault();
		setDragOver(true);
	};
	const handleDragLeave = (event) => {
		if (!event.relatedTarget || !event.currentTarget.contains(event.relatedTarget)) {
			setDragOver(false);
		}
	};
	const uploadDocs = (docsList) => {
		const notUploadedPendingDocs = docsList.map((d) => ({
			document: d,
			progress: 0,
		}));
		const newPendingDocs = [...pendingDocs, ...notUploadedPendingDocs];
		setPendingDocs(newPendingDocs);
		const tokensSourcesList = notUploadedPendingDocs.map(() => ApiService.getCancelTokenSource());
		pendingDocumentsTokensSources.current = [...pendingDocumentsTokensSources.current, ...tokensSourcesList];
		const requests = notUploadedPendingDocs.map((p, index) =>
			ProjectCreationService.uploadDocuments(
				{ projectId: projectCreation.project?.id },
				p.document,
				(progressEvent) => {
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total) / 100;
					setPendingDocs((prev) => {
						const doc = prev.find((d) => d.document.name === p.document.name);
						if (doc) {
							const filteredPendingDocs = prev.filter((d) => d.document.name !== p.document.name);
							doc.progress = percentCompleted;
							filteredPendingDocs.push(doc);
							return filteredPendingDocs.sort((a, b) => (a.document.name > b.document.name ? 1 : -1));
						}
						return prev;
					});
				},
				pendingDocumentsTokensSources.current[index].token
			)
		);
		requests.forEach((request) => {
			request
				.then((data) => {
					setPendingDocs((prev) => {
						if (data.id) {
							const docIndex = prev.findIndex((d) => data.fileName !== d.document.name);
							const newDocs = [...prev];
							newDocs.splice(docIndex, 1);
							pendingDocumentsTokensSources.current.splice(docIndex, 1);
							return newDocs;
						}
						return prev;
					});
					dispatch(loadProjectCreation({ token: cancelTokenSourceRef.current.token, documentsOnly: true }));
				})
				.catch((err) => {
					console.error(err);
					ProjectCreationService.getOngoingProject(cancelTokenSourceRef.current.token)
						.then((data) => {
							setPendingDocs((prev) => {
								let docFound = false;
								const newDocs = [...prev];
								prev.forEach((d, index) => {
									if (
										!docFound &&
										!data.documents.some((doc) => doc.documentName === d.fileName) &&
										d.progress === 1
									) {
										newDocs.splice(index, 1);
										docFound = true;
									}
								});
								return newDocs;
							});
						})
						.catch((e) => {
							console.error(e);
						});
				});
		});
	};
	const uploadMoreDocs = (docsList) => {
		const filesToUpload = [];
		Object.values(docsList).forEach((newFile) => {
			let exists = false;
			pendingDocs.forEach((pd) => {
				if (pd.document.name === newFile.name) {
					createNotification({
						type: "error",
						message: translate(
							"new-project.details-and-documents.project-documents.error-message.already-being-uploaded",
							{ fileName: newFile.name }
						),
					});
					exists = true;
				}
			});
			if (!exists) {
				filesToUpload.push(newFile);
			}
		});
		uploadDocs(filesToUpload);
	};
	const handleChangeFiles = (event) => {
		if (event.target.files.length > 0) {
			const filesToUpload = Object.values(event.target.files).filter((f) => typeof f !== "number");
			uploadDocs(filesToUpload);
		}
		inputBrowseRef.current.value = null;
	};
	const handleDrop = (event) => {
		event.preventDefault();
		setDragOver(false);
		if (event.dataTransfer.files.length > 0) {
			uploadMoreDocs(Object.values(event.dataTransfer.files).filter((f) => typeof f !== "number"));
		}
	};
	const handleBrowseMore = (event) => {
		if (event.target.files.length > 0) {
			uploadMoreDocs(Object.values(event.target.files).filter((f) => typeof f !== "number"));
		}
		inputBrowseMoreRef.current.value = null;
	};
	const handleCancelFileUpload = (index) => {
		ApiService.cancelTokens(pendingDocumentsTokensSources.current[index]);
		const newPendingDocs = [...pendingDocs];
		newPendingDocs.splice(index, 1);
		setPendingDocs(newPendingDocs);
		pendingDocumentsTokensSources.current.splice(index, 1);
		dispatch(loadProjectCreation({ token: cancelTokenSourceRef.current.token, documentsOnly: true }));
	};
	const handleRemoveFile = (index) => {
		ProjectCreationService.deleteDocument(
			{ documentId: projectCreation.documents[index].id },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				createNotification({
					type: "success",
					message: translate(
						"new-project.details-and-documents.project-documents.success-message.document-deleted"
					),
				});
				dispatch(loadProjectCreation({ token: cancelTokenSourceRef.current.token, documentsOnly: true }));
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const displayList =
		(Array.isArray(pendingDocs) && pendingDocs.length > 0) ||
		(Array.isArray(projectCreation.documents) && projectCreation.documents.length > 0);
	const unsupportedDocuments = useMemo(() => {
		const unsupported = [];
		const listUnsupported = (original, lister) => {
			for (const el of original) {
				if (el.status === DOCUMENT_STATUSES.errorNotSupported) {
					unsupported.push(el);
				}
				if (isNonEmptyArray(el?.documents)) {
					listUnsupported(el.documents, lister);
				}
			}
		};
		if (!isNonEmptyArray(projectCreation.documents)) {
			return [];
		}
		listUnsupported(projectCreation.documents, unsupported);
		return unsupported;
	}, [projectCreation.documents]);
	return (
		<div className={styles.documents}>
			<div className={styles.documents__header}>
				{pendingDocs.length > 0
					? translate("new-project.details-and-documents.project-documents.header.files-selected", {
							filesNb: pendingDocs.length + (projectCreation.documents || []).length,
					  })
					: translate("new-project.details-and-documents.project-documents.header.upload-documents")}
			</div>
			<UnsupportedInformationBanner unsupportedDocuments={unsupportedDocuments} />
			<div
				className={`${styles.documents__body} ${pendingDocs.length > 0 ? styles.documents__list : ""}`}
				data-dragover={dragOver}
				onDragLeave={handleDragLeave}
				onDragOver={handleDragOver}
				onDrop={handleDrop}
			>
				{dragOver && <DocumentDragAndDropInfo project className={styles.dragDropInfo} />}
				{(displayList && (
					<>
						<DocumentProgressList
							documents={projectCreation.documents}
							onCloseDocument={handleRemoveFile}
						/>
						{Array.isArray(pendingDocs) &&
							pendingDocs.length > 0 &&
							pendingDocs.map((pd, index) => (
								<DocumentProgress
									key={pd.document.name}
									documentName={pd.document.name}
									progress={pd.progress * 0.8}
									onClose={() => handleCancelFileUpload(index)}
								/>
							))}
					</>
				)) || (
					<div className={styles["upload-container"]}>
						<IconComponent
							className={styles.body__icon}
							data-dragover={dragOver}
							icon={icon.faFileUpload}
						/>
						{!dragOver && (
							<>
								<div className={styles.body__text}>
									<I18nTranslate
										className={styles.documents__dragAndDrop}
										translationKey="new-project.details-and-documents.project-documents.drag-and-drop"
									/>
								</div>
								<label className="custom-file-upload" htmlFor="file-upload">
									<div className={styles.body__button} color="primary">
										{translate("new-project.details-and-documents.project-documents.browse")}
									</div>
									<input
										ref={inputBrowseRef}
										multiple
										accept="*"
										className={styles["file-upload"]}
										id="file-upload"
										type="file"
										onChange={handleChangeFiles}
									/>
								</label>
								<div className={styles.document__helper}>
									<span className={styles.tranlate_list}>
										<ul>
											<li>
												{translate(
													"new-project.details-and-documents.project-documents.supported"
												)}
											</li>
											<li>
												{translate(
													"new-project.details-and-documents.project-documents.recommended"
												)}
											</li>
											<li>{translate("common:upload-file.size-condition")}</li>
										</ul>
									</span>
								</div>
							</>
						)}
					</div>
				)}
			</div>
			<div className={styles.documents__footer}>
				{(pendingDocs.length > 0 || (projectCreation.documents || []).length > 0) && (
					<>
						<div>
							<span>{translate("new-project.details-and-documents.reminder.formats")}</span>
							<br />
							<span>{translate("new-project.details-and-documents.unsupported.information")}</span>
						</div>
						<label className="custom-file-upload" htmlFor="file-upload-2">
							<div className={styles.body__button} color="primary">
								{translate(
									"new-project.details-and-documents.project-documents.header.upload-other-documents"
								)}
							</div>
							<input
								ref={inputBrowseMoreRef}
								multiple
								className={styles["file-upload"]}
								id="file-upload-2"
								type="file"
								onChange={handleBrowseMore}
							/>
						</label>
					</>
				)}
			</div>
		</div>
	);
}
