import React from "react";
import { useSelector } from "react-redux";
import styles from "./Header.module.css";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../../../../common/providers";
import { CustomIconButton, CustomIconButtonLink, icon } from "../../../../../../../common/components";
import { NavUtils } from "../../../../../../../navigation";

export default function Header({ onClickEdit, onClickSR, projectId, requirement }) {
	const companyId = useSelector(({ context }) => context.company?.id);
	return (
		<div className={styles.header}>
			<div className={styles.header__title}>
				{translate("requirement-matrix.req-details.header", { requirementId: requirement.id })}
			</div>
			<span className={styles.header__buttons}>
				{isSegFeatureEnabled(SegFlags.SMART_REVIEW) && (
					<CustomIconButtonLink
						openInNewTab
						disabled={!(requirement.documentId && requirement.page)}
						icon={icon.faColumns}
						to={NavUtils.goToSmartReview({
							projectId,
							documentId: requirement.documentId,
							pageStart: requirement.page,
							infoId: requirement.id,
							companyId,
						})}
						tooltip={translate("common:navigation.go-to-my-review")}
						onClick={onClickSR}
					/>
				)}
				<CustomIconButton
					icon={icon.faPen}
					tooltip={translate("requirement-matrix.req-details.header.edit-req")}
					onClick={onClickEdit}
				/>
			</span>
		</div>
	);
}
