import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import DetailBlock from "./DetailBlock";
import DocumentVersionSignalMessage from "./DocumentVersionSignalMessage";
import { ROLES, SENTENCE_TYPE, REQ_STATUSES, VERSION_STATUSES } from "../../../../constants/constants";
import { checkReq } from "../../../../utils/utils";
import styles from "./SuperDetailBlock.module.css";
import { isDeepEqual } from "../../../../../../common/utils";

const SuperDetailBlock = React.memo(
	({ currentPosition, data, hoveredBlock, isFirstInPage, onMouseEnterBlock }) => {
		const [hovered, setHovered] = useState(false);
		const mode = useSelector(({ srDocument }) => srDocument.mode);
		const showVersioning = useSelector(({ srDocument }) => srDocument.showVersioning);
		const handleHoverSignal = () => {
			if (data?.infoVersionStatus !== VERSION_STATUSES.MODIFIED) {
				setHovered((prev) => !prev);
			}
		};
		const pdfContent = useSelector(({ srPdf }) => srPdf.content);
		const hasPartAbove =
			isFirstInPage &&
			(pdfContent.find((content) => content.page === data.pageStart - 1)?.[mode] || []).some(
				(block) => block.informationId === data.informationId
			);
		const displayDocVersionSignalMessage = useMemo(
			() =>
				!hovered &&
				data.sentenceType === SENTENCE_TYPE.REQUIREMENT &&
				(data?.infoVersionStatus === VERSION_STATUSES.NEW ||
					data?.infoVersionStatus === VERSION_STATUSES.MODIFIED) &&
				((mode === ROLES.REVIEWER.mode && (!data.lastStatus || data?.lastStatus === REQ_STATUSES.UNREVIEWED)) ||
					(mode === ROLES.VALIDATOR.mode &&
						(!data.validatedStatus || data?.validatedStatus === REQ_STATUSES.UNREVIEWED))),
			[data, hovered, mode]
		);
		return (
			(!hasPartAbove && (
				<div
					key={`${data.informationId}-${data.pageStart}`}
					className={styles.superDetailBlock__block}
					data-is-hovered={hoveredBlock?.informationId === data.informationId}
					data-is-requirement={checkReq(data)}
					style={{
						top: data.top,
					}}
				>
					<DetailBlock
						currentPosition={currentPosition}
						data={data}
						hoveredBlock={hoveredBlock}
						onHoverSignal={handleHoverSignal}
						onMouseEnterBlock={onMouseEnterBlock}
					/>
					{displayDocVersionSignalMessage && (
						<DocumentVersionSignalMessage
							data={data}
							isNew={data?.infoVersionStatus === VERSION_STATUSES.NEW}
							showVersioning={showVersioning}
							onHoverSignal={handleHoverSignal}
						/>
					)}
				</div>
			)) ||
			null
		);
	},
	(prevProps, nextProps) =>
		!(
			prevProps?.currentPosition !== nextProps?.currentPosition ||
			prevProps?.data?.informationId !== nextProps?.data?.informationId ||
			(nextProps?.data?.informationId === nextProps?.hoveredBlock?.informationId &&
				nextProps?.hoveredBlock?.informationId !== prevProps.hoveredBlock?.informationId) ||
			(prevProps?.data?.informationId === prevProps?.hoveredBlock?.informationId &&
				nextProps?.hoveredBlock?.informationId !== prevProps.hoveredBlock?.informationId) ||
			prevProps?.data?.sentenceType !== nextProps?.data?.sentenceType ||
			prevProps?.data?.validatedSentenceType !== nextProps?.data?.validatedSentenceType ||
			prevProps?.data?.thematicCount !== nextProps?.data?.thematicCount ||
			prevProps?.data?.commentsCount !== nextProps?.data?.commentsCount ||
			prevProps?.data?.coverageStatus !== nextProps?.data?.coverageStatus ||
			prevProps?.data?.deliverablesCount !== nextProps?.data?.deliverablesCount ||
			prevProps?.data?.questionAnswer !== nextProps?.data?.questionAnswer ||
			prevProps?.data?.tasksCount !== nextProps?.data?.tasksCount ||
			!isDeepEqual(prevProps?.data?.linkTypeCount, nextProps?.data?.linkTypeCount)
		)
);

export default SuperDetailBlock;
