import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProjectCreationService } from "../../../api";

const initialState = {
	currentStep: 0,
	projectCreation: {},
	isModified: false,
	userRoles: [],
	isUploading: false,
	docsFailed: false,
	currentUser: {},
	checkedCategoriesIds: [],
	packages: [],
	hasProjectRequirementsSegFlag: false,
	hasTeamSegFlag: false,
	hasCategorySegFlag: false,
	hasPermissionsSegFlag: false,
};

export const loadProjectCreation = createAsyncThunk("newProject/loadProjectCreation", ({ token }) =>
	ProjectCreationService.getOngoingProject(token)
);

const newProjectSlice = createSlice({
	name: "newProject",
	initialState,
	reducers: {
		setCurrentStep: (state, { payload }) => ({ ...state, currentStep: payload }),
		setProjectCreation: (state, { payload }) => ({ ...state, projectCreation: payload }),
		setHasProjectRequirementsSegFlag: (state, { payload }) => ({
			...state,
			hasProjectRequirementsSegFlag: payload,
		}),
		setHasTeamSegFlag: (state, { payload }) => ({ ...state, hasTeamSegFlag: payload }),
		setHasCategorySegFlag: (state, { payload }) => ({ ...state, hasCategorySegFlag: payload }),
		setHasPermissionsSegFlag: (state, { payload }) => ({ ...state, hasPermissionsSegFlag: payload }),
		setIsModified: (state, { payload }) => ({ ...state, isModified: payload }),
		setUserRoles: (state, { payload }) => ({ ...state, userRoles: payload }),
		setIsUploading: (state, { payload }) => ({ ...state, isUploading: payload }),
		setDocsFailed: (state, { payload }) => ({ ...state, docsFailed: payload }),
		setCurrentUser: (state, { payload }) => ({ ...state, currentUser: payload }),
		setCheckedCategoriesIds: (state, { payload }) => ({ ...state, checkedCategoriesIds: payload }),
		setPackages: (state, { payload }) => ({ ...state, packages: payload }),
	},
	extraReducers: (builder) => {
		builder.addCase(loadProjectCreation.fulfilled, (state, action) => {
			const { documentsOnly } = action.meta.arg;
			if (documentsOnly) {
				return {
					...state,
					projectCreation: {
						...state.projectCreation,
						documents: action.payload.documents,
						totalPages: action.payload.totalPages,
						price: action.payload.price,
					},
				};
			}
			return {
				...state,
				projectCreation: { ...action.payload, users: [state.currentUser].concat(action.payload.users) },
			};
		});
		builder.addMatcher(
			({ type }) => typeof type === "string" && type.endsWith("rejected"),
			(_, { error }) => {
				console.error(error.message);
			}
		);
	},
});

export const {
	setCurrentStep,
	setProjectCreation,
	setHasProjectRequirementsSegFlag,
	setHasTeamSegFlag,
	setHasCategorySegFlag,
	setHasPermissionsSegFlag,
	setIsModified,
	setUserRoles,
	setIsUploading,
	setCurrentUser,
	setDocsFailed,
	setCheckedCategoriesIds,
	setPackages,
} = newProjectSlice.actions;
export default newProjectSlice.reducer;
