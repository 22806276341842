import React, { useState, useEffect } from "react";
import styles from "./CategoryRowRiskLevel.module.css";
import CategoryRiskLevel from "./CategoryRiskLevel";
import ShowMore from "./ShowMore";
import { debounce } from "../../utils";

export default function CategoryRowRiskLevel({ categories = [], containerId }) {
	const [toDisplay, setToDisplay] = useState(0);
	useEffect(() => {
		let mounted = true;
		const computeToDisplay = debounce(() => {
			const containerWidth = document.getElementById(`row_${containerId}`)?.offsetWidth;
			const nodeList = [...document.querySelectorAll(`.class_${containerId}`)];
			if (Array.isArray(nodeList) && nodeList.length > 0) {
				let index = 0;
				let acc = 0;
				if (mounted) {
					Array.from(nodeList).forEach((item, i) => {
						if (acc + item.offsetWidth <= containerWidth) {
							index = i;
						}
						acc += item.offsetWidth;
					});
					setToDisplay(index);
				}
			}
		}, 400);
		if (mounted) {
			computeToDisplay();
		}
		window.addEventListener("resize", computeToDisplay);
		return () => {
			mounted = false;
			window.removeEventListener("resize", computeToDisplay);
		};
	}, [containerId, categories]);
	return (
		<div className={styles.row}>
			<div className={styles.chips} id={`row_${containerId}`}>
				{Array.isArray(categories) &&
					((toDisplay > 0 && categories.slice(0, toDisplay + 1)) || categories).map((cat) => (
						<CategoryRiskLevel
							key={cat.categoryId || cat.id}
							category={cat}
							classId={`class_${containerId}`}
						/>
					))}
			</div>
			{categories.length > 0 && toDisplay + 1 < categories.length && toDisplay > 0 && (
				<ShowMore categories={categories.slice(toDisplay)} toDisplay={categories.length - toDisplay} />
			)}
		</div>
	);
}
