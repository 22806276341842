import React from "react";
import { Avatar } from "@mui/material";
import { CustomRating, CustomTooltip } from "../../../../../../common/components";
import { toInitialsUserName } from "../../../../../../common/utils";
import styles from "./CategoryRating.module.css";
import { translate } from "../../../../../../common/providers";

export default function CategoryRating({
	customStyles,
	onChange = null,
	onRemove = null,
	owner = "",
	riskValue,
	time = null,
}) {
	return (
		<div className={styles.categoryRating}>
			{time && <span className={styles.categoryRating__time}>{time}</span>}
			<div className={styles.categoryRating__rating}>
				<CustomTooltip title={owner}>
					<Avatar alt={owner} className={styles.ownerIcon} variant="circular">
						{toInitialsUserName(owner)}
					</Avatar>
				</CustomTooltip>
				<CustomRating
					customStyles={customStyles}
					iconName="faSquare"
					iconNameEmpty="faSquareRegular"
					iconNameRemove="faSquareXmark"
					labels={[
						translate("gonogo.details.risk-level.no-risk"),
						translate("gonogo.details.risk-level.low-risk"),
						translate("gonogo.details.risk-level.moderate-risk"),
						translate("gonogo.details.risk-level.high-risk"),
					]}
					value={riskValue}
					onChange={onChange}
					onRemove={onRemove}
				/>
			</div>
		</div>
	);
}
