import React from "react";
import { NormDetails } from "..";
import { SidePanel, SidePanelContent } from "../../../../common/components";

export default function NormDetailsSidePanel({ open, onClose, normId, normName, onActive: onActiveFromParent = null }) {
	return (
		<SidePanel open={open} size={65} onActive={onActiveFromParent} onClose={onClose}>
			{({ onActive }) => (
				<SidePanelContent title={normName} onClose={onClose}>
					<NormDetails normId={normId} onActive={onActive} />
				</SidePanelContent>
			)}
		</SidePanel>
	);
}
