import client from "../../client/client";
import { encodeParam } from "../../utils/utils";

class OperationPhaseService {
	getAllDocumentsInPhase({ projectId, phaseId }, cancelToken, { filter, page, limit } = {}) {
		return client.get(`/operation-phases/${phaseId}/projects/${projectId}/documents`, {
			params: { filter: encodeParam(filter), page, limit },
			config: { cancelToken },
		});
	}

	getCompanyModels({ companyId }, cancelToken) {
		return client.get(`/operation-phases/companies/${companyId}/models`, { config: { cancelToken } });
	}

	getAllByProject({ projectId }, cancelToken) {
		return client.get(`/operation-phases/projects/${projectId}`, { config: { cancelToken } });
	}

	getCoverage({ projectId }, cancelToken) {
		return client.get(`/operation-phases/projects/${projectId}/coverage`, { config: { cancelToken } });
	}

	setModelInProject({ projectId }, modelId, cancelToken) {
		return client.put(`/operation-phases/projects/${projectId}/models`, {
			payload: modelId,
			config: { cancelToken },
		});
	}

	getAllPhasesForReq({ reqId }, cancelToken) {
		return client.get(`/operation-phases/requirements/${reqId}`, { config: { cancelToken } });
	}
}

export default new OperationPhaseService();
