import { MenuList } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MenuItemSelectable, MenuItemDropDown, DropDownMenu, MenuButton } from "..";
import { translate } from "../../../common/providers";
import { checkValidity } from "../..";
import { isNonEmptyArray } from "../../../common/utils";
import styles from "./ProjectMenu.module.css";

export default function ProjectMenu({
	currentOpenedMenu,
	projectMenus,
	onIsSelected,
	onClose,
	onOpen,
	onOpenHover,
	onSelect,
	onIncludes,
}) {
	const [prMenus, setPrMenus] = useState([]);
	const segFlags = useSelector(({ context }) => context.segFlags);
	useEffect(() => {
		if (Array.isArray(projectMenus)) {
			setPrMenus(projectMenus);
		}
	}, [projectMenus, segFlags]);
	return (
		Array.isArray(prMenus) &&
		prMenus.map((projectMenu) => {
			if (!checkValidity(projectMenu)) {
				return null;
			}
			const subItems =
				(isNonEmptyArray(projectMenu.subItems) && projectMenu.subItems.filter((m) => checkValidity(m))) || null;
			if (subItems && (subItems.length > 1 || (subItems.length === 1 && subItems[0].name !== projectMenu.name))) {
				const hasNoSubItems = subItems.every(
					(sb) => sb.subItems && sb.subItems.filter((m) => checkValidity(m)).length === 0
				);
				return subItems.length === 0 || (subItems.length > 0 && hasNoSubItems) ? null : (
					<DropDownMenu
						key={projectMenu.name}
						bold
						menuName={projectMenu.name}
						open={currentOpenedMenu === projectMenu.name}
						selected={onIncludes(subItems)}
						title={translate(projectMenu.name)}
						onClose={onClose}
						onOpen={onOpen}
						onOpenHover={onOpenHover}
					>
						<MenuList>
							{subItems.map((menuItem) =>
								menuItem.subItems ? (
									<MenuItemDropDown
										key={menuItem.name}
										menuItem={menuItem}
										onClose={onClose}
										onIncludes={onIncludes}
										onIsSelected={onIsSelected}
										onSelect={onSelect}
									/>
								) : (
									checkValidity(menuItem) && (
										<MenuItemSelectable
											key={menuItem.name}
											menuItem={menuItem}
											onIsSelected={onIsSelected}
											onSelect={onSelect}
										/>
									)
								)
							)}
						</MenuList>
					</DropDownMenu>
				);
			}
			const prMenu = subItems?.[0] || projectMenu;
			return (
				(prMenu?.to && (
					<MenuButton
						key={prMenu.name}
						selected={onIsSelected(prMenu.to)}
						onClick={(event) => {
							onSelect(prMenu, event, null);
						}}
					>
						{prMenu.isBeta ? (
							<span>
								{`${translate(prMenu.name)} `}
								<span className={styles.title_version}> [Beta]</span>
							</span>
						) : (
							translate(prMenu.name)
						)}
					</MenuButton>
				)) ||
				null
			);
		})
	);
}
