import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/material";
import styles from "./RequirementList.module.css";
import { I18nTranslate, InfiniteList2, Preview, generateFilters } from "../../../../../common/components";
import { RequirementMatrixService } from "../../../../../api";
import RequirementRow from "./RequirementRow";
import { useApi } from "../../../../../common/hooks";
import { SENTENCE_TYPE } from "../../../../my-review/constants/constants";

const RequirementList = ({
	allChecked,
	checkedRows = [],
	disabledCheckBox = false,
	filters,
	onCheck,
	onCheckAll,
	onSetSelectedCount,
	selectedCount,
}) => {
	const { call: searchRequirementsInProject } = useApi(RequirementMatrixService.searchRequirementsInProject);
	const { call: getRequirementsInProjectCount } = useApi(RequirementMatrixService.getRequirementsInProjectCount);
	const projectId = useSelector(({ context }) => context.project?.id);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const [totalResults, setTotalResults] = useState(0);
	const [totalMatches, setTotalMatches] = useState(0);
	const [preview, setPreview] = useState(null);

	useEffect(() => {
		if (projectId) {
			getRequirementsInProjectCount({ projectId }, {}).then((data) => setTotalResults(data));
		}
	}, [getRequirementsInProjectCount, projectId]);
	useEffect(() => {
		setInfiniteListRequest(
			() =>
				({ page, limit }) =>
					searchRequirementsInProject({ projectId }, generateFilters(filters), { page, limit })
		);
	}, [searchRequirementsInProject, filters, projectId]);
	useEffect(() => {
		onSetSelectedCount(allChecked ? totalMatches - checkedRows.length : checkedRows.length);
	}, [onSetSelectedCount, checkedRows.length, allChecked, totalMatches]);
	const getAdjustedCoordinates = (recievedPreview) => {
		if (recievedPreview.numberOfPages === 1) {
			return [{ ...recievedPreview.rectangles[0], x1: 0.07, x2: 0.94, y2: recievedPreview.rectangles[1].y2 }];
		}
		if (recievedPreview.numberOfPages === 2) {
			return recievedPreview.rectangles.map((coord, index) => ({
				x1: 0.07,
				x2: 0.94,
				y1: index === 0 ? coord.y1 : 0.02,
				y2: index === 0 ? 0.98 : coord.y2,
				pageNumber: recievedPreview.pageStart + index,
			}));
		}
		return [...Array(recievedPreview.numberOfPages)].map((_, index) => ({
			x1: 0.07,
			x2: 0.94,
			y1: index === 0 ? recievedPreview.rectangles[0].y1 : 0.02,
			y2: index === recievedPreview.numberOfPages - 1 ? recievedPreview.rectangles[1].y2 : 0.98,
			contentId: recievedPreview.pageStart + index,
			pageNumber: recievedPreview.pageStart + index,
		}));
	};
	const handleDisplay = (data) => {
		setPreview({ coordinates: getAdjustedCoordinates(data), ...data });
	};
	const handleCloseDisplay = () => {
		setPreview(null);
	};
	const rowRenderer = (row) => (
		<RequirementRow
			key={row.id}
			allChecked={allChecked}
			checkedRows={checkedRows}
			disabledCheckBox={disabledCheckBox}
			row={row}
			onCheck={onCheck}
			onDisplay={handleDisplay}
		/>
	);
	const handleResponse = (data) => {
		setTotalMatches(data.totalElements);
	};
	const handlePlural = (elements, string) => {
		const translateType = "common:results.count-";
		if (elements > 1) {
			return translateType.concat(string);
		}
		return translateType.concat("single-", string);
	};
	return (
		<div className={styles.container}>
			<div>
				<Checkbox
					checked={selectedCount !== 0}
					disabled={disabledCheckBox}
					indeterminate={selectedCount !== 0 && selectedCount < totalMatches}
					onChange={onCheckAll}
				/>
				{(selectedCount === 0 && (
					<I18nTranslate
						param={{ totalMatches, totalResults }}
						translationKey={handlePlural(totalMatches, "match")}
					/>
				)) || (
					<I18nTranslate
						param={{
							totalMatches,
							nbSelected: selectedCount,
						}}
						translationKey={handlePlural(selectedCount, "selected-match")}
					/>
				)}
			</div>
			<InfiniteList2
				callOnResponse={handleResponse}
				className={styles.infiniteList}
				request={infiniteListRequest}
				rowRenderer={rowRenderer}
				scrollContainerClassName={styles.scrollContainer}
			/>
			<Preview
				coordinates={preview?.coordinates}
				docId={preview?.documentId}
				infoId={preview?.informationId}
				infoType={SENTENCE_TYPE.REQUIREMENT}
				open={!!preview}
				page={preview?.pageStart}
				projectId={projectId}
				onClose={handleCloseDisplay}
			/>
		</div>
	);
};

export default RequirementList;
