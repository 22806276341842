import React from "react";
import styles from "./DetectionHeader.module.css";
import {
	CustomIconButton,
	icon,
	CustomTooltip,
	VerticalDivider,
	I18nTranslate,
	CustomButton,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";

export default function DetectionHeader({
	pendingStatus,
	totalSearchResult = 0,
	selectedRows = 0,
	onEditSelectedNorm,
	onOpenMultipleMatchedNorms,
}) {
	return (
		<div className={styles.container}>
			{(totalSearchResult > 0 && selectedRows > 0 && (
				<span className={styles.selection}>
					<I18nTranslate
						param={{
							totalResults: totalSearchResult,
							nbSelected: selectedRows,
						}}
						translationKey="norms-center.tab.detections.table.count-selected.total"
					/>
					<span className={styles.divider}>
						<VerticalDivider height={40} />
					</span>
					<span className={styles["results--text"]}>
						{translate("norms-center.tab.detections.table.actions")}
					</span>
					{pendingStatus === "SIMILAR" && selectedRows > 0 && (
						<CustomTooltip title={translate("norms-center.tab.detections.table.matched-norm.tooltip")}>
							<CustomButton
								className={styles["btn--leftPading"]}
								color="secondary"
								startIcon={icon.faPen}
								variant="outlined"
								onClick={(e) => {
									e.stopPropagation();
									onOpenMultipleMatchedNorms();
								}}
							>
								{translate("norms-center.tab.detections.table.matched-norm.selection")}
							</CustomButton>
						</CustomTooltip>
					)}
					{pendingStatus !== "TRASH" && (
						<>
							<CustomIconButton
								btnClassName={styles["btn--margin"]}
								icon={icon.faCheck}
								tooltip={translate("norms-center.tab.detections.table.valid-select.tooltip")}
								variant="outlined"
								onClick={(e) => {
									e.stopPropagation();
									onEditSelectedNorm("VALIDATED");
								}}
							/>
							<CustomIconButton
								icon={icon.faTimes}
								tooltip={translate("norms-center.tab.detections.table.discard-select.tooltip")}
								variant="outlined"
								onClick={(e) => {
									e.stopPropagation();
									onEditSelectedNorm("TRASH");
								}}
							/>
						</>
					)}
					{pendingStatus === "TRASH" && (
						<CustomIconButton
							btnClassName={styles["btn--leftMargin"]}
							icon={icon.faUndo}
							tooltip={translate("norms-center.tab.detections.table.recover-select.tooltip")}
							variant="outlined"
							onClick={() => onEditSelectedNorm("PENDING")}
						/>
					)}
				</span>
			)) ||
				((totalSearchResult || totalSearchResult === 0) && (
					<span className={styles.searchResult}>
						{translate(
							totalSearchResult > 1
								? "norms-center.tab.search-results"
								: "norms-center.tab.search-result",
							{ totalSearchResult }
						)}
					</span>
				))}
		</div>
	);
}
