import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { ValidatorService, CollabService, ApiService } from "../../../../../../api";
import { CustomIconButton, icon } from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import styles from "./StatusCheckButtons.module.css";
import { ROLES, DOCUMENT_STATUSES } from "../../../../constants/constants";

export default function NotMyCompany({
	disabled,
	informationId,
	lastStatus,
	onDispatchNewStatus,
	onDispatchValStatus,
	validatedStatus,
	value,
}) {
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const isArchived = useSelector(({ srDocument }) => srDocument.status === DOCUMENT_STATUSES.ARCHIVED);
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleCheckedNotMe = () => {
		let newStatus = null;
		let promise = null;
		if (
			(mode === ROLES.VALIDATOR.mode && validatedStatus !== "NOT_ME" && validatedStatus !== "not_me") ||
			(mode === ROLES.REVIEWER.mode && lastStatus !== "NOT_ME" && lastStatus !== "not_me")
		) {
			newStatus = "NOT_ME";
		} else {
			newStatus = "UNREVIEWED";
		}
		if (mode === ROLES.REVIEWER.mode) {
			promise = CollabService.updateStatus(
				{ infoId: informationId },
				newStatus,
				cancelTokenSourceRef.current.token
			);
		} else if (newStatus !== "NOT_ME") {
			promise = ValidatorService.remove({ infoId: informationId }, cancelTokenSourceRef.current.token);
		} else {
			promise = ValidatorService.validate(
				{
					infoId: informationId,
				},
				"NOT_ME",
				cancelTokenSourceRef.current.token
			);
		}
		promise
			.then(() => {
				if (mode === ROLES.REVIEWER.mode) {
					onDispatchNewStatus(newStatus, null);
				} else {
					onDispatchValStatus(newStatus);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};
	return (
		<CustomIconButton
			btnClassName={`${styles.button} ${styles["button--notMe"]}`}
			data-checked={value.value === "notme"}
			data-is-current={value.isCurrent}
			data-other-checked={value.value !== "unreviewed" && value.value !== "notme"}
			disabled={isArchived || disabled}
			icon={icon.faNotEqual}
			iconClassName={styles.icon}
			size="sm"
			tooltip={translate("smart-review.not-my-company.title.out-of-my-scope")}
			tooltipPlacement="top"
			onClick={handleCheckedNotMe}
		/>
	);
}
