import client from "../client/client";

class SppRequirementService {
	getDeliverablesForRequirement({ projectId, requirementId }, cancelToken) {
		return client.get(`/spp/projects/${projectId}/requirements/${requirementId}/document-out`, {
			config: { cancelToken },
		});
	}

	getParagraphsOfDeliverable({ projectId, requirementId, deliverableId }, cancelToken) {
		return client.get(`/spp/projects/${projectId}/requirements/${requirementId}/document-out/${deliverableId}`, {
			config: { cancelToken },
		});
	}
}

export default new SppRequirementService();
