import client from "../../client/client";

class ActionAutoService {
	getAll({ projectId }, payload, { page = 0, limit = 25, sort, direction }, cancelToken) {
		return client.post(`/action-auto/projects/${projectId}/all`, {
			payload,
			params: { page, limit, sort, direction },
			config: { cancelToken },
		});
	}

	triggerAll({ projectId }, cancelToken) {
		return client.post(`/action-auto/projects/${projectId}/actionAutos`, { config: { cancelToken } });
	}

	trigger({ projectId, actionId }, cancelToken) {
		return client.post(`/action-auto/projects/${projectId}/actionAuto/${actionId}`, { config: { cancelToken } });
	}

	createAction({ projectId }, payload, cancelToken) {
		return client.post(`/action-auto/projects/${projectId}`, { payload, config: { cancelToken } });
	}

	modifyActions({ projectId }, { operation, ids }, cancelToken) {
		return client.patch(`/action-auto/projects/${projectId}`, {
			payload: { operation, ids },
			config: { cancelToken },
		});
	}

	modifyActionsWithFilters({ projectId }, { operation, filter, ignored }, cancelToken) {
		return client.patch(`/action-auto/projects/${projectId}/filters`, {
			payload: { operation, filter, ignored },
			config: { cancelToken },
		});
	}

	updateAction({ projectId, actionId }, payload, cancelToken) {
		return client.put(`/action-auto/projects/${projectId}/actionAuto/${actionId}`, {
			payload,
			config: { cancelToken },
		});
	}

	deleteAction({ actionId, projectId }, cancelToken) {
		return client.delete(`/action-auto/projects/${projectId}/actionAuto/${actionId}`, { config: { cancelToken } });
	}

	getAction({ actionId, projectId }, cancelToken) {
		return client.get(`/action-auto/projects/${projectId}/actionAuto/${actionId}`, { config: { cancelToken } });
	}
}
export default new ActionAutoService();
