import React, { Component } from "react";
import { connect } from "react-redux";
import { AnalyticsProvider, Flags, SegFlags, translate } from "../../common/providers";
import { checkAitendersUser, debounce, downloadFile, isDeepEqual, isNonEmptyArray } from "../../common/utils";
import { exportView } from "../../navigation";
import styles from "./QAJournal.module.css";
import QAList from "./components/qa-list/QAList";
import QASearch from "./components/qa-search/QASearch";
import QAImport from "./components/qa-import/QAImport";
import QAFilters from "./components/qa-filters/QAFilters";
import QAActions from "./components/qa-actions/QAActions";
import { ApiService, ExportService, QAService, QATagService } from "../../api";
import { createNotification, CustomButton, icon, ViewBanner } from "../../common/components";
import QADetails from "./components/qa-details/QADetails";

const mapStateToProps = ({ context }) => ({
	project: context.project,
	userMail: context.user.email,
});

class QandAJournal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadingQa: false,
			checkedQa: [],
			selectedQa: null,
			currentPage: 0,
			questionsAnswers: [],
			filters: {},
			tags: [],
			qaDetails: {},
			allChecked: false,
			totalQaInProject: 0,
			importTemplate: [],
			importResults: null,
			references: [],
			displayClauses: false,
			displayDocuments: false,
			clausesLists: [],
			projectReferences: [],
			contentLoading: false,
			ignoredIds: [],
			openCreateDialog: false,
			file: null,
			openConfirmDialog: false,
		};
		this.inputRef = React.createRef();
		this.cancelTokenSource = ApiService.getCancelTokenSource();
		this.updateCancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentDidMount() {
		const documentTitle = translate("qa-journal.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Question to the client" });
		this.getInitialQA();
		this.getImportTemplate();
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.updateCancelTokenSource);
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	componentDidUpdate(_, prevState) {
		const { filters } = this.state;
		if (!!prevState.filters && filters && !isDeepEqual(prevState.filters, filters)) {
			ApiService.cancelTokens(this.updateCancelTokenSource);
			this.updateCancelTokenSource = ApiService.getCancelTokenSource();
			this.getFilteredQa(this.updateCancelTokenSource);
		}
	}

	getInitialQA = () => {
		const { project } = this.props;
		this.setState({ isLoadingQa: true });
		QAService.searchQuestionAnswerInProject({ projectId: project.id }, {}, this.cancelTokenSource.token, {})
			.then((data) => {
				this.setState({
					questionsAnswers: data,
					isLoadingQa: false,
					currentPage: 0,
					totalQaInProject: data.totalElements,
				});
			})
			.catch((err) => {
				console.error(err);
				this.setState({ isLoadingQa: false });
			});
	};

	getFilteredQa = (cancelTokenSource) => {
		const { filters } = this.state;
		const { project } = this.props;
		this.setState({ isLoadingQa: true });
		QAService.searchQuestionAnswerInProject({ projectId: project.id }, filters, cancelTokenSource.token, {})
			.then((data) =>
				this.setState({
					questionsAnswers: data,
					selectedQa: null,
					currentPage: 0,
					isLoadingQa: false,
					checkedQa: [],
					allChecked: false,
				})
			)
			.catch((err) => {
				console.error(err);
				this.setState({ isLoadingQa: false });
			});
	};

	getQADetails = (qa) => {
		const { project } = this.props;
		QAService.getQaDetails({ projectId: project.id, qaId: qa.id }, this.cancelTokenSource.token)
			.then((data) => {
				this.setState({
					qaDetails: data,
				});
			})
			.catch((err) => {
				console.error(err);
				this.setState({ isLoadingQa: false });
			});
	};

	getAllTags = () => {
		const { project } = this.props;
		QATagService.getQuestionTagInProject({ projectId: project.id }, this.cancelTokenSource.token)
			.then((data) => {
				if (Array.isArray(data) && data) {
					this.setState({
						tags: data,
					});
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	createNewTag = (tag) => {
		const { project } = this.props;
		QATagService.createQuestionTagInProject({ projectId: project.id }, tag, this.cancelTokenSource.token)
			.then(() => {
				createNotification({
					type: "success",
					message: translate("qa-journal.qa-journal.success-message.tags-created"),
				});
				this.getAllTags();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	createQuestionTag = (tags) => {
		const { project } = this.props;
		const payload = this.generateActionPayload({ tagId: tags.map((tag) => tag.id) });
		QAService.addQaTag({ projectId: project.id }, payload, this.cancelTokenSource.token)
			.then(() => {
				createNotification({
					type: "success",
					message: translate("qa-journal.qa-journal.success-message.tags-added"),
				});
				this.getInitialQA();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	createQA = (payload) => {
		const { project } = this.props;
		const { filters } = this.state;
		QAService.createQuestionAnswerInProject({ projectId: project.id }, payload, this.cancelTokenSource.token)
			.then((data) => {
				if (
					!isNonEmptyArray(filters?.documentIds) &&
					((filters?.tags || []).some((t) => data.tags.some((tag) => tag.id === t)) ||
						!isNonEmptyArray(filters?.tags)) &&
					(!isNonEmptyArray(filters?.userIds) || filters?.userIds.some((uid) => data.asker.id === uid))
				) {
					this.setState((prev) => ({
						questionsAnswers: {
							...prev.questionsAnswers,
							contents: [...prev.questionsAnswers.contents, data],
							totalElements:
								((!filters.status || filters.status === "DRAFT") &&
									prev.questionsAnswers.totalElements + 1) ||
								prev.totalElements,
						},
					}));
				}
				this.setState((prev) => ({
					totalQaInProject: prev.totalQaInProject + 1,
				}));

				createNotification({
					type: "success",
					message: translate("qa-journal.qa-dialog.qa-dialog.info-dialog.content"),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	};

	handleLoadMore = () => {
		const { currentPage, questionsAnswers, filters } = this.state;
		const { project } = this.props;
		if (currentPage < questionsAnswers.totalPages - 1) {
			const page = currentPage + 1;
			this.setState({ isLoadingQa: true, currentPage: page });
			return QAService.searchQuestionAnswerInProject(
				{ projectId: project.id },
				filters,
				this.cancelTokenSource.token,
				{ page }
			)
				.then((data) =>
					this.setState((prev) => ({
						questionsAnswers: { ...data, contents: [...prev.questionsAnswers.contents, ...data.contents] },
						isLoadingQa: false,
					}))
				)
				.catch((err) => {
					this.setState({ isLoadingQa: false });
					console.error(err);
				});
		}
		return null;
	};

	handleCheckQa = (id) => {
		if (!id) {
			return;
		}
		const { checkedQa, ignoredIds, allChecked } = this.state;
		const { questionsAnswers } = this.state;
		const qa = questionsAnswers.contents.find((q) => q.id === id);
		let newIgnoredIds = [...ignoredIds];
		let newCheckedQa = [...checkedQa];
		let newAllChecked = allChecked;
		if (allChecked) {
			if (ignoredIds.some((ignoredId) => ignoredId === id)) {
				newIgnoredIds = ignoredIds.filter((ignoredId) => ignoredId !== id);
			} else {
				newIgnoredIds.push(id);
			}
		}
		if (checkedQa.some((q) => q.id === id)) {
			newCheckedQa = checkedQa.filter((r) => r.id !== id);
		} else {
			newCheckedQa.push(qa);
			if (newCheckedQa.length === questionsAnswers.contents.length) {
				newAllChecked = true;
			}
		}
		this.setState({
			selectedQa: null,
			checkedQa: newCheckedQa,
			ignoredIds: newIgnoredIds,
			allChecked: newAllChecked,
		});
	};

	handleCheckAll = () => {
		const { allChecked, ignoredIds } = this.state;
		let newAllChecked = !allChecked;
		if (allChecked && ignoredIds.length > 0) {
			newAllChecked = true;
		}
		this.setState((prev) => ({
			allChecked: newAllChecked,
			checkedQa: newAllChecked ? prev.questionsAnswers.contents : [],
			ignoredIds: [],
		}));
	};

	handleSelectQa = (id) => {
		const { questionsAnswers } = this.state;
		if (!id || !questionsAnswers) {
			this.setState({ selectedQa: null });
			return;
		}
		const qa = questionsAnswers.contents.find((q) => q.id === id);
		const { selectedQa } = this.state;
		if (!qa || (selectedQa && qa.id === selectedQa.id)) {
			this.setState({ selectedQa: null });
		} else {
			this.setState({ selectedQa: qa, checkedQa: [], allChecked: false });
			this.getQADetails(qa);
			this.getDocuments(qa.id);
		}
	};

	handleSearch = (payload) => {
		this.setState({
			filters: payload,
		});
	};

	handleUpdateSearch = (payload, displayResults) => {
		const { filters } = this.state;
		this.handleSearch({ ...payload, status: filters.status }, displayResults);
	};

	handleChangeStatus = (newStatus) => {
		const { filters } = this.state;
		this.handleSearch({ ...filters, status: newStatus });
	};

	handleChangeDisplayReference = (showDocumentReference, showClausesReference, documentId, payload = "") => {
		const { qaDetails } = this.state;
		const { project } = this.props;
		if (documentId) {
			QAService.getQaClauseReference(
				{ projectId: project.id, qaId: qaDetails.id, documentId },
				payload,
				this.cancelTokenSource.token
			)
				.then((data) => {
					this.setState({
						clausesLists: [{ list: data, currentIndex: null }],
						displayDocuments: showDocumentReference,
						displayClauses: showClausesReference,
					});
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			this.setState({
				displayDocuments: showDocumentReference,
				displayClauses: showClausesReference,
			});
		}
	};

	handleClickClause = (clauseId, documentId, payload = "") => {
		const { qaDetails, clausesLists } = this.state;
		const { project } = this.props;
		if (documentId && clauseId) {
			QAService.getQaSubClauseReference(
				{ projectId: project.id, qaId: qaDetails.id, documentId, clauseId },
				payload,
				this.cancelTokenSource.token
			)
				.then((data) => {
					const newClausesLists = clausesLists;
					newClausesLists[newClausesLists.length - 1].currentIndex = newClausesLists[
						newClausesLists.length - 1
					].list.findIndex((c) => c.id === clauseId);
					this.setState({
						clausesLists: [
							...newClausesLists,
							{
								list: data,
								currentIndex: null,
							},
						],
					});
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	generateActionPayload = ({ date, status, tagId }) => {
		const { checkedQa, allChecked, ignoredIds, filters } = this.state;
		return {
			...{ date, status, tagId },
			...(allChecked ? { filter: { ...filters, notIds: ignoredIds } } : { qaIds: checkedQa.map((qa) => qa.id) }),
		};
	};

	updateQaStatus = (status) => {
		const { filters } = this.state;
		const { project } = this.props;
		QAService.updateQuestionAnswerStatusInProject(
			{ projectId: project.id },
			this.generateActionPayload({ status }),
			this.cancelTokenSource.token
		)
			.then(() => {
				if (filters.status) {
					this.getFilteredQa(this.updateCancelTokenSource);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	deleteQaTag = (qa, tag) => {
		const { questionsAnswers } = this.state;
		const { project } = this.props;
		QAService.deleteQaTag({ projectId: project.id, qaId: qa.id, tagId: tag.id }, this.cancelTokenSource.token)
			.then(() => {
				const newQAsContents = [...questionsAnswers.contents];
				const qaIndex = newQAsContents.findIndex((q) => q.id === qa.id);
				newQAsContents[qaIndex].tags.splice(
					newQAsContents[qaIndex].tags.findIndex((t) => t.id === tag.id),
					1
				);
				this.setState((prevState) => ({
					questionsAnswers: {
						...prevState.questionsAnswers,
						contents: newQAsContents,
					},
				}));
			})
			.catch((err) => {
				console.error(err);
			});
	};

	updateQaSentDate = (date) => {
		const { project } = this.props;
		QAService.updateQuestionAnswerSentDateInProject(
			{ projectId: project.id },
			this.generateActionPayload({ date }),
			this.cancelTokenSource.token
		)
			.then(() => {
				createNotification({
					type: "success",
					message: translate("qa-journal.qa-journal.success-message.qa-sent-date-updated"),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	};

	updateQaReceiveDate = (date) => {
		const { project } = this.props;
		QAService.updateQuestionAnswerReceiveDateInProject(
			{ projectId: project.id },
			this.generateActionPayload({ date }),
			this.cancelTokenSource.token
		)
			.then(() => {
				createNotification({
					type: "success",
					message: translate("qa-journal.qa-journal.success-message.qa-received-date-updated"),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	};

	updateQaQuestionAsked = (questionAsked, clientId, answer) => {
		const { selectedQa } = this.state;
		const { project } = this.props;
		if (questionAsked !== "" || clientId !== "" || answer !== "") {
			const payload = {
				questionAsked: questionAsked || null,
				clientId: clientId || null,
				questionAnswer: answer || null,
			};
			QAService.updateQuestionAnswer(
				{ projectId: project.id, qaId: selectedQa.id },
				payload,
				this.cancelTokenSource.token
			)
				.then((data) => {
					this.setState((prev) => {
						const newQuestionsAnswersContents = [...prev.questionsAnswers.contents];
						const qaIndex = newQuestionsAnswersContents.findIndex((qa) => qa.id === selectedQa.id);
						if (qaIndex >= 0) {
							newQuestionsAnswersContents[qaIndex].question = data.questionAsked;
							newQuestionsAnswersContents[qaIndex].clientId = clientId;
							newQuestionsAnswersContents[qaIndex].questionAnswer = answer;
						}
						return {
							qaDetails: data,
							questionsAnswers: { ...prev.questionsAnswers, contents: newQuestionsAnswersContents },
						};
					});
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	deleteQa = () => {
		const { checkedQa } = this.state;
		const { project } = this.props;
		const payload = this.generateActionPayload({});
		QAService.deleteQuestionAnswerInProject({ projectId: project.id }, payload, this.cancelTokenSource.token)
			.then(() => {
				this.setState((prev) => ({
					totalQaInProject: prev.totalQaInProject - checkedQa.length,
					questionsAnswers: {
						...prev.questionsAnswers,
						contents: prev.questionsAnswers.contents.filter((q) => checkedQa.indexOf(q) < 0),
						totalElements: prev.questionsAnswers.totalElements - checkedQa.length,
					},
					checkedQa: [],
				}));
			})
			.catch((err) => {
				console.error(err);
			});
	};

	exportQa = () => {
		const { filters } = this.state;
		const { project } = this.props;
		ExportService.exportQuestionAnswersXLSX({ projectId: project.id }, filters, this.cancelTokenSource.token)
			.then(({ data, filename }) => downloadFile({ data, filename, filetype: "xlsx" }))
			.catch((err) => {
				console.error(err);
			});
	};

	getImportTemplate = () => {
		const { importTemplate } = this.state;
		const { project } = this.props;
		if (!Array.isArray(importTemplate) || importTemplate.length === 0) {
			QAService.getImportTemplate({ projectId: project.id }, this.cancelTokenSource.token)
				.then((data) => Array.isArray(data) && this.setState({ importTemplate: data }))
				.catch((err) => console.error(err));
		}
	};

	import = (file, shouldErase) => {
		if (!file) {
			this.setState({ importResults: null });
			return;
		}
		const { project } = this.props;
		QAService.importQuestionAnswer({ projectId: project.id }, file, shouldErase, this.cancelTokenSource.token)
			.then((data) => {
				if (data.valid) {
					createNotification({
						type: "success",
						message: translate("qa-journal.qa-import.success"),
						timeout: 2000,
					});
					this.getInitialQA();
				} else {
					createNotification({
						type: "error",
						message: translate("qa-journal.qa-import.failed"),
						timeout: 2000,
					});
				}
				this.setState({ importResults: data });
			})
			.catch((err) => {
				console.error(err);
				this.setState({ importResults: null });
			});
	};

	getDocuments = (qaId, payload = "") => {
		const { project } = this.props;
		QAService.getQaDocumentReference({ projectId: project.id, qaId }, payload, this.cancelTokenSource.token).then(
			(data) => {
				this.setState({ references: data, contentLoading: false });
			}
		);
		this.loadReferences(qaId);
	};

	searchRelatedDocuments = (payload) => {
		const { selectedQa } = this.state;
		this.setState({
			contentLoading: true,
		});
		this.debouncedSearchRelatedDocuments(selectedQa.id, payload);
	};

	debouncedSearchRelatedDocuments = debounce((qaId, payload) => {
		this.getDocuments(qaId, payload);
	}, 1000);

	loadReferences = (qaId) => {
		const { project } = this.props;
		QAService.getQAReferences({ projectId: project.id, qaId }, this.cancelTokenSource.token)
			.then((data) => {
				this.setState({
					projectReferences: data,
				});
			})
			.catch((err) => {
				console.error(err);
			});
	};

	handleGoBack = ({ id }) => {
		const { clausesLists } = this.state;
		const newClausesLists = clausesLists;
		for (let i = 0; i < id; i++) {
			newClausesLists.pop();
		}
		this.setState({
			clausesLists: newClausesLists,
			displayDocuments: newClausesLists.length === 0,
			displayClauses: newClausesLists.length !== 0,
		});
	};

	handleSaveRelatedContents = (qa) => {
		this.getQADetails(qa);
		this.getDocuments(qa.id);
	};

	handleSearchClauses = (documentId, payload) => {
		const { qaDetails, clausesLists } = this.state;
		const { project } = this.props;
		this.setState({
			contentLoading: true,
		});
		if (clausesLists.length > 1) {
			const clauseId =
				clausesLists[clausesLists.length - 2].list[clausesLists[clausesLists.length - 2].currentIndex].id;
			this.debouncedGetSubClauses({ projectId: project.id, qaId: qaDetails.id, documentId, clauseId }, payload);
		} else {
			this.debouncedGetClauses({ projectId: project.id, qaId: qaDetails.id, documentId }, payload);
		}
	};

	debouncedGetSubClauses = debounce(({ projectId, qaId, documentId, clauseId }, payload) => {
		QAService.getQaSubClauseReference(
			{ projectId, qaId, documentId, clauseId },
			payload,
			this.cancelTokenSource.token
		)
			.then(this.handleChangeClauses)
			.catch(this.handleCatchClauses);
	}, 1000);

	debouncedGetClauses = debounce(({ projectId, qaId, documentId }, payload) => {
		QAService.getQaClauseReference({ projectId, qaId, documentId }, payload, this.cancelTokenSource.token)
			.then(this.handleChangeClauses)
			.catch(this.handleCatchClauses);
	}, 1000);

	handleClearDocumentsSearch = () => {
		const { selectedQa } = this.state;
		this.setState({
			contentLoading: true,
		});
		this.getDocuments(selectedQa.id);
	};

	handleClearClausesSearch = (documentId) => {
		const { qaDetails, clausesLists } = this.state;
		const { project } = this.props;
		this.setState({
			contentLoading: true,
		});
		let getRequest = null;
		if (clausesLists.length > 1) {
			const clauseId =
				clausesLists[clausesLists.length - 2].list[clausesLists[clausesLists.length - 2].currentIndex].id;
			getRequest = QAService.getQaSubClauseReference(
				{ projectId: project.id, qaId: qaDetails.id, documentId, clauseId },
				"",
				this.cancelTokenSource.token
			);
		} else {
			getRequest = QAService.getQaClauseReference(
				{ projectId: project.id, qaId: qaDetails.id, documentId },
				"",
				this.cancelTokenSource.token
			);
		}
		getRequest.then(this.handleChangeClauses).catch(this.handleCatchClauses);
	};

	handleChangeClauses = (clauses) => {
		const { clausesLists } = this.state;
		const newClausesLists = [...clausesLists];
		newClausesLists[newClausesLists.length - 1].list = clauses;
		this.setState({
			clausesLists: newClausesLists,
			contentLoading: false,
		});
	};

	handleCatchClauses = (err) => {
		console.error(err);
		this.setState({
			contentLoading: false,
		});
	};

	handleOpenCreateDialog = () => {
		this.getAllTags();
		this.setState((prev) => ({ openCreateDialog: !prev.openCreateDialog, file: null, openConfirmDialog: false }));
	};

	handleFileChangeHandler = (event) => {
		const newFile = event.target.files[0];
		this.setState({ file: newFile, openConfirmDialog: true });
	};

	handleCloseConfirmDialog = () => {
		this.setState({ openConfirmDialog: false });
	};

	render() {
		const {
			isLoadingQa,
			checkedQa,
			questionsAnswers,
			tags,
			qaDetails,
			allChecked,
			selectedQa,
			totalQaInProject,
			displayDocuments,
			displayClauses,
			filters,
			importResults,
			importTemplate,
			references,
			clausesLists,
			projectReferences,
			contentLoading,
			ignoredIds,
			openCreateDialog,
			file,
			openConfirmDialog,
		} = this.state;
		const { project, userMail } = this.props;
		const displayAllChecked =
			Array.isArray(questionsAnswers.contents) &&
			questionsAnswers.contents.length > 0 &&
			allChecked &&
			ignoredIds.length === 0;

		const ExportButton = (
			<CustomButton color="primary" startIcon={icon.faUpload} variant="outlined" onClick={() => this.exportQa()}>
				{translate("qa-journal.qa-import.qa-import.btn.export")}
			</CustomButton>
		);
		const ImportButton = (
			<>
				{checkAitendersUser(userMail) && (
					<CustomButton color="primary" component="label" startIcon={icon.faDownload} variant="outlined">
						<input
							ref={this.inputRef}
							hidden
							accept=".xls, .xlsx"
							type="file"
							onChange={this.handleFileChangeHandler}
						/>
						{translate("qa-journal.qa-import.qa-import.btn.import")} (.xls, .xlsx)
					</CustomButton>
				)}
			</>
		);
		const AddButton = (
			<CustomButton color="primary" variant="contained" onClick={this.handleOpenCreateDialog}>
				{translate("qa-journal.qa-import.qa-import.btn.add-qa")}
			</CustomButton>
		);
		return (
			<>
				<ViewBanner
					options={[ExportButton, ImportButton, AddButton]}
					titles={[
						{ title: translate("navigation:project.follow-up"), key: "followUp" },
						{ title: translate("navigation:project.question-to-the-client") },
					]}
				/>
				<div className={styles.container}>
					<div className={styles["search-panel"]}>
						<QASearch onClickSearch={this.handleUpdateSearch} />
						<div className={styles["search-panel__qa-import"]}>
							<QAImport
								eraseOption
								createQA={this.createQA}
								file={file}
								openConfirmDialog={openConfirmDialog}
								openCreateDialog={openCreateDialog}
								results={importResults}
								tags={tags}
								template={importTemplate}
								onCloseConfirmDialog={this.handleCloseConfirmDialog}
								onImport={this.import}
								onOpenCreateDialog={this.handleOpenCreateDialog}
							/>
						</div>
					</div>
					<div className={styles["panels-container"]}>
						<div className={styles["left-panel"]}>
							<QAFilters filters={filters} onChangeStatus={this.handleChangeStatus} />
						</div>
						<div className={styles["right-panels-container"]}>
							<div className={styles["actions-panel"]}>
								<QAActions
									allChecked={displayAllChecked}
									checkedQaCount={checkedQa.length}
									createNewTag={this.createNewTag}
									createQuestionTag={this.createQuestionTag}
									deleteQa={this.deleteQa}
									qaDetails={qaDetails}
									tags={tags}
									updateQaReceiveDate={this.updateQaReceiveDate}
									updateQaSentDate={this.updateQaSentDate}
									updateQaStatus={this.updateQaStatus}
									onOpenTagDialog={this.getAllTags}
								/>
							</div>
							<div className={styles["bottom-panels-container"]}>
								<div className={styles["qa-panel"]}>
									<QAList
										allChecked={displayAllChecked}
										checkedQa={checkedQa}
										hasMore={questionsAnswers.hasMore}
										isLoadingQa={isLoadingQa}
										questionsAnswers={questionsAnswers.contents}
										selectedQa={selectedQa}
										totalElements={questionsAnswers.totalElements}
										totalQaInProject={totalQaInProject}
										onCheckAll={this.handleCheckAll}
										onCheckQa={this.handleCheckQa}
										onDelete={this.deleteQaTag}
										onLoadMore={this.handleLoadMore}
										onSelectQa={this.handleSelectQa}
									/>
								</div>
								<div className={styles["details-panel"]}>
									<QADetails
										clausesLists={clausesLists}
										contentLoading={contentLoading}
										displayClauses={displayClauses}
										displayDocuments={displayDocuments}
										projectId={project.id}
										projectReferences={projectReferences}
										qaDetails={qaDetails}
										references={references}
										selectedQa={selectedQa}
										updateQaQuestionAsked={this.updateQaQuestionAsked}
										onChangeDisplayReference={this.handleChangeDisplayReference}
										onClearClausesSearch={this.handleClearClausesSearch}
										onClearDocumentsSearch={this.handleClearDocumentsSearch}
										onClickClause={this.handleClickClause}
										onGoBack={this.handleGoBack}
										onSaveRelatedContents={this.handleSaveRelatedContents}
										onSearchClauses={this.handleSearchClauses}
										onSearchDocuments={this.searchRelatedDocuments}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default exportView({
	path: "/projects/:projectId/follow-up/question-to-the-client",
	localesPath: "/qa-journal/locales",
	component: connect(mapStateToProps)(QandAJournal),
	flag: Flags.QA,
	segFlag: SegFlags.QUESTION_ANSWER,
});
