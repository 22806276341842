import React, { useState, useEffect } from "react";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { debounce } from "../../../../common/utils";
import { ProjectTeamService, ApiService } from "../../../../api";
import styles from "./AddUserConsortiumDialog.module.css";
import { translate } from "../../../../common/providers";
import AutoCompleteSearch from "./AutoCompleteSearch";
import { CustomButton, CustomIconButton, GenericDialog, icon } from "../../../../common/components";

let isSubscribed = false;

const deboucedSearch = debounce(({ setIsLoadingUsers, projectId, search, setUsers }, cancelTokenSource) => {
	if (!search) {
		setIsLoadingUsers(false);
		return;
	}
	ProjectTeamService.searchUsersInProject({ projectId }, search, cancelTokenSource.token)
		.then((data) => {
			if (isSubscribed) {
				setIsLoadingUsers(false);
				setUsers(Array.isArray(data) ? data : []);
			}
		})
		.catch((err) => (isSubscribed ? console.error(err) : null));
}, 1000);

export default function AddUserConsortiumDialog({ open, onClose, projectId, companyName, addUser }) {
	const [isLoadingUsers, setIsLoadingUsers] = useState(false);
	const [users, setUsers] = useState([]);
	const [search, setSearch] = useState("");
	const [existingUser, setExistingUser] = useState(null);
	const [existingUserError, setExistingUserError] = useState(false);

	const reset = () => {
		setExistingUser(null);
		setExistingUserError(false);
		setUsers([]);
		setIsLoadingUsers(false);
		setSearch("");
	};

	const handleSelectUser = (value) => setExistingUser(value);

	useEffect(() => {
		isSubscribed = true;
		return () => {
			isSubscribed = false;
			reset();
		};
	}, []);

	const handleCloseAndAction = () => {
		addUser(existingUser);
		onClose();
	};

	const searchUser = (e) => setSearch(e.target.value);

	useEffect(() => {
		const cancelTokenSource = ApiService.getCancelTokenSource();
		if (!search) {
			return () => {
				//	noop
			};
		}
		setIsLoadingUsers(true);
		setExistingUser(null);
		setExistingUserError(false);
		deboucedSearch({ setIsLoadingUsers, projectId, search, setUsers }, cancelTokenSource);
		return () => {
			ApiService.cancelTokens(cancelTokenSource);
		};
	}, [projectId, search]);

	return (
		<GenericDialog disableBackdropClick fullWidth open={open} onClose={onClose} onExited={reset}>
			<DialogTitle>
				<div className={styles.dialog__title}>
					<span className={styles["dialog__title-text"]}>
						{translate("team-management.add-user-dialog.title", { companyName })}
					</span>
					<CustomIconButton icon={icon.faTimes} onClick={onClose} />
				</div>
			</DialogTitle>
			<DialogContent dividers className={styles.dialog__content}>
				<AutoCompleteSearch
					existingUserError={existingUserError}
					handleSelectUser={handleSelectUser}
					isLoadingUsers={isLoadingUsers}
					searchUser={searchUser}
					users={users}
				/>
			</DialogContent>
			<DialogActions>
				<CustomButton color="primary" variant="outlined" onClick={onClose}>
					{translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton color="primary" variant="contained" onClick={handleCloseAndAction}>
					{translate("common:btn.submit")}
				</CustomButton>
			</DialogActions>
		</GenericDialog>
	);
}
