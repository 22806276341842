import React, { useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { translate } from "../../../../../../../../../common/providers";
import { DeliverablesTabContent } from "..";
import styles from "./DeliverablesTabView.module.css";
import { updateNode } from "../../../../../../../slice/pdf/pdf-slice";

export default function DeliverablesTabView({ informationId, pageStart, selectedMode }) {
	const isRequirement = useSelector(({ srDocument }) => srDocument.sidePanelIsRequirement);
	const [updatedCoverageStatus, setUpdatedCoverageStatus] = useState("");
	const [selectedTab, setSelectedTab] = useState("ALL");
	const dispatch = useDispatch();

	const handleUpdate = (newCoverageStatus, newDeliverablesCount) => {
		setUpdatedCoverageStatus(newCoverageStatus);
		batch(() => {
			dispatch(
				updateNode({
					mode: selectedMode,
					pageConditions: { page: pageStart },
					informationId,
					updates: [{ property: "coverageStatus", value: newCoverageStatus }],
				})
			);
			dispatch(
				updateNode({
					mode: selectedMode,
					pageConditions: { page: pageStart },
					informationId,
					updates: [{ property: "deliverablesCount", value: newDeliverablesCount }],
				})
			);
		});
	};

	return (
		<div className={styles.main}>
			<div className={styles.container}>
				{isRequirement ? (
					<DeliverablesTabContent
						coverageStatus={updatedCoverageStatus}
						reqId={informationId}
						selectedTab={selectedTab}
						onSetSelectedTab={setSelectedTab}
						onUpdate={handleUpdate}
					/>
				) : (
					<>
						<div className={styles.emptyState}>{translate("smart-review.right-panel.empty-state")}</div>
					</>
				)}
			</div>
		</div>
	);
}
