import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { translate } from "../../../../../common/providers";
import ParamsSection from "./ParamsSection";
import CategorySection from "./CategorySection";
import { CustomDialog } from "../../../../../common/components";

export default function EditReqDialog({ categories, onClose, onExpand, onUpdate, open, params, requirement }) {
	const [selectedParams, setSelectedParams] = useState({ type: "", negotiability: "", criticality: "" });
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [updateParams, setUpdateParams] = useState(false);
	const [updateCategories, setUpdateCategories] = useState(false);
	const [collapsed, setCollapsed] = useState({ params: true, category: false });

	useEffect(() => {
		if (!requirement) {
			return;
		}
		setSelectedParams({
			type: requirement.type,
			negotiability: requirement.negotiability,
			criticality: requirement.criticality,
		});
		if (Array.isArray(requirement.category) && requirement.category.length > 0) {
			setSelectedCategories(requirement.category.map((category) => category.id));
		}
	}, [requirement]);
	const handleUpdate = () => {
		const payload = {};
		if (updateCategories) {
			const oldThematics = requirement.category.map((category) => category.id);
			payload.addThematic = selectedCategories.filter((nt) => !oldThematics.some((ot) => ot === nt));
			payload.deleteThematic = oldThematics.filter((nt) => !selectedCategories.some((ot) => ot === nt));
		}
		if (updateParams) {
			payload.params = {
				type: selectedParams.type || null,
				negotiability: selectedParams.negotiability || null,
				criticality: selectedParams.criticality || null,
			};
		}
		setUpdateParams(false);
		setUpdateCategories(false);
		if (Object.keys(payload).length > 0) {
			onUpdate(payload);
		}
		onClose();
	};
	const handleCollaspe = (type) => setCollapsed((prev) => ({ ...prev, [type]: !prev[type] }));
	const handleChangeParams = (param, value) => {
		setUpdateParams(true);
		setSelectedParams((prev) => ({ ...prev, [param]: value }));
	};

	const handleChangeCategories = (checkedElements) => {
		if (!updateCategories) {
			setUpdateCategories(true);
		}
		setSelectedCategories(checkedElements);
	};
	return (
		<CustomDialog
			isForm
			open={open}
			title={translate("requirement-matrix.edit-req.update", { reqId: requirement?.id })}
			onClose={onClose}
			onSubmit={handleUpdate}
		>
			<ParamsSection
				collapsed={collapsed.params}
				handleCollaspe={() => handleCollaspe("params")}
				params={params}
				selectedParams={selectedParams}
				onChangeParams={handleChangeParams}
			/>
			<br />
			<Divider />
			<br />
			<CategorySection
				categories={categories}
				collapsed={collapsed.category}
				selectedCategories={selectedCategories}
				title={translate("common:header.filter.project-categories")}
				onChange={(checkedElements) => handleChangeCategories(checkedElements)}
				onCollaspe={() => handleCollaspe("category")}
				onExpand={onExpand}
			/>
		</CustomDialog>
	);
}
