import React, { useState, useRef, useEffect } from "react";
import { Chip } from "@mui/material";
import {
	createNotification,
	IconComponent,
	icon,
	CustomButton,
	GenericDialog,
	GenericDialogHeader,
	GenericDialogContent,
	GenericDialogFooter,
} from "../../../../../common/components";
import { BackOfficeService, CanvasService, ApiService } from "../../../../../api";
import { translate } from "../../../../../common/providers";
import styles from "./AddMultiUserRolesForm.module.css";

const AddMultiUserRolesForm = ({
	open,
	projectId,
	roles,
	selectedCompany,
	selectedDocuments,
	users,
	onCloseMultiUserRolesForm,
}) => {
	const cancelTokenSourceRef = useRef(null);
	const [selectedRole, setSelectedRole] = useState(null);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selection, setSelection] = useState([]);
	const [errorMessage, setErrorMessage] = useState(null);
	const [disableSubmit, setDisableSubmit] = useState(false);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleSelectedRole = (role) => {
		setSelectedRole(role);
		setSelectedUsers([]);
		setSelection([]);
		setErrorMessage("");
	};

	const handleAddUsers = (user) => {
		if (selectedRole !== null) {
			if (selection.length < 1) {
				setSelectedUsers([user.id]);
				setSelection([{ id: user.id, displayName: user.displayName || user.email }]);
				setErrorMessage("");
			} else {
				setSelectedUsers([user.id, ...selectedUsers.filter((value) => value !== user.id)]);
				setSelection([
					{ id: user.id, displayName: user.displayName || user.email },
					...selection.filter((value) => value.id !== user.id),
				]);
			}
		} else {
			setErrorMessage(translate("canvas.error.add-multi-users-roles-form.you-have-to-select-role"));
		}
	};

	const handleDelete = (user) => {
		setSelectedUsers(selectedUsers.filter((id) => id !== user.id));
		setSelection(selection.filter((value) => value.displayName !== user.displayName));
	};

	const handleResetAndClose = (submit = false) => {
		setSelectedRole(null);
		setSelectedUsers([]);
		setSelection([]);
		setErrorMessage("");
		onCloseMultiUserRolesForm(submit);
	};

	const displayNotification = (response) => {
		createNotification({
			type: "success",
			message: translate("canvas.add-multi-user-roles-form.success.role-has-been-added", {
				roleCount: response.length,
			}),
			timeout: 3000,
			priority: false,
		});
	};

	const handleSubmitAndClose = () => {
		if (!selectedRole || selectedUsers.length === 0 || selectedDocuments.length === 0) {
			setErrorMessage(translate("canvas.error.add-multi-users-roles-form.you-have-to-select-role-and-user"));
			return;
		}
		setDisableSubmit(true);
		const service = selectedCompany ? BackOfficeService : CanvasService;
		const method = selectedCompany ? "updateUserRoleOnDoc" : "addMultiUsersRoleOnDocuments";
		service[method](
			{ projectId, companyId: selectedCompany?.id },
			{
				userIds: selectedUsers,
				documentIds: selectedDocuments,
				role: selectedRole,
			},
			cancelTokenSourceRef.current.token
		)
			.then((response) => {
				displayNotification(response);
				handleResetAndClose(true);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => setDisableSubmit(false));
	};

	return (
		<>
			<GenericDialog
				fullWidth
				maxWidth="sm"
				open={open}
				onClose={() => {
					handleResetAndClose(false);
				}}
			>
				<GenericDialogHeader>
					<IconComponent color="var(--color-blue)" icon={icon.faUser} />
					{translate("canvas.add-multi-user-roles-form.title")}
				</GenericDialogHeader>
				<GenericDialogContent dividers>
					<div>
						<div className={styles.text}>{translate("canvas.add-multi-user-roles-form.select-role")}</div>
						<div className={styles.dialog__role}>
							{roles.map((role) => (
								<div
									key={role}
									className={styles.role}
									style={{
										backgroundColor:
											role === selectedRole ? "var(--color-light-green)" : "var(--color-white)",
									}}
								>
									<CustomButton
										fullWidth
										className={styles.roleBtn}
										color="secondary"
										onClick={() => handleSelectedRole(role)}
									>
										{translate(`common:enum.document.user-role.${role?.toLowerCase()}`)}
									</CustomButton>
								</div>
							))}
						</div>
					</div>
					<div className={styles.textError}>{errorMessage}</div>
					<div className={styles.form}>
						<div className={styles.user__container}>
							{users.map((user) => (
								<div
									key={user.id}
									className={styles.user}
									role="presentation"
									translate="no"
									onClick={() => {
										handleAddUsers(user);
									}}
								>
									{user.displayName || user.email}
								</div>
							))}
						</div>
						<div className={styles.selectedContainer}>
							{selection.map((user) => (
								<Chip
									key={user.id}
									className={styles.chip}
									label={user.displayName}
									translate="no"
									onDelete={() => {
										handleDelete(user);
									}}
								/>
							))}
						</div>
					</div>
				</GenericDialogContent>
				<GenericDialogFooter>
					<CustomButton
						color="primary"
						variant="outlined"
						onClick={() => {
							handleResetAndClose(false);
						}}
					>
						{translate("common:btn.cancel")}
					</CustomButton>
					<CustomButton
						color="primary"
						disabled={disableSubmit}
						variant="contained"
						onClick={handleSubmitAndClose}
					>
						{translate("common:btn.submit")}
					</CustomButton>
				</GenericDialogFooter>
			</GenericDialog>
		</>
	);
};

export default AddMultiUserRolesForm;
