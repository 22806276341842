import React, { useState, useEffect } from "react";
import QADialog from "../qa-dialog/QADialog";
import ConfirmImportDialog from "./components/ConfirmImportDialog";
import ErrorLogDialog from "./components/ErrorLogDialog";
import { hasProperty } from "../../../../common/utils";

export default function QAImport({
	createQA,
	eraseOption = false,
	file,
	onCloseConfirmDialog,
	onImport,
	onOpenCreateDialog,
	openConfirmDialog,
	openCreateDialog,
	results,
	tags,
	template = [],
}) {
	const [openErrorLogDialog, setOpenErrorLogDialog] = useState(false);

	useEffect(() => {
		setOpenErrorLogDialog(false);
	}, []);

	useEffect(() => {
		setOpenErrorLogDialog((results && hasProperty(results, "valid") && !results.valid) || false);
	}, [results]);

	const handleCloseErrorDialog = () => setOpenErrorLogDialog(false);

	const handleConfirm = (shouldErase) => {
		onImport(file, shouldErase);
		onCloseConfirmDialog();
	};

	return (
		<>
			<QADialog close={onOpenCreateDialog} createQA={createQA} open={openCreateDialog} tags={tags} />
			<ConfirmImportDialog
				eraseOption={eraseOption}
				open={openConfirmDialog}
				template={template}
				onClose={onCloseConfirmDialog}
				onConfirm={handleConfirm}
			/>
			<ErrorLogDialog
				errors={(results && results.errors) || []}
				filename={(file && file.name) || ""}
				open={openErrorLogDialog}
				onClose={handleCloseErrorDialog}
			/>
		</>
	);
}
