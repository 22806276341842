import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CustomTooltip, icon, IconComponent, TextMidCut } from "../../../../../../../../common/components";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../../../../../common/providers";
import styles from "./DocumentNameHandler.module.css";
import ReviewRedirectionId from "../ChatRedirectionFilters/ReviewRedirectionId";

export default () => {
	const frozenDocument = useSelector(({ srDocument }) => srDocument.isFrozen);
	const documentName = useSelector(({ srDocument }) => srDocument.documentName);
	const [overflowDocName, setOverflowDocName] = useState(false);
	const containerRef = useRef(null);
	useEffect(() => {
		const handleLogoSize = () => {
			setOverflowDocName(containerRef.current?.scrollWidth <= 123);
		};
		handleLogoSize();
		window.addEventListener("resize", handleLogoSize);
		return () => {
			window.removeEventListener("resize", handleLogoSize);
		};
	}, []);
	return (
		<CustomTooltip title={overflowDocName && documentName}>
			<div ref={containerRef} className={styles.secondaryHeaderRow__name}>
				<CustomTooltip
					title={
						frozenDocument
							? translate("smart-review.header.freeze-capture.locked")
							: translate("smart-review.header.freeze-capture.unlocked")
					}
				>
					<span>
						<IconComponent
							color="var(--shades-grey-400)"
							icon={frozenDocument ? icon.faLock : icon.faLockOpen}
						/>
					</span>
				</CustomTooltip>
				<TextMidCut
					tooltip
					autoTranslate="no"
					className={styles.secondaryHeaderRow__documentName}
					text={documentName}
				/>
				{isSegFeatureEnabled(SegFlags.CHAT_PROJECT) && <ReviewRedirectionId />}
			</div>
		</CustomTooltip>
	);
};
