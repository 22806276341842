import React, { useEffect, useState, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { CustomDatePicker, createNotification, CustomButton } from "../../../../common/components";
import { DashboardService, ApiService } from "../../../../api";
import { translate } from "../../../../common/providers";
import styles from "./AddMilestonesDialog.module.css";
import { convertAnyDateToDisplayDate } from "../../../../common/utils";

const AddMilestoneDialog = ({ onAdd, onClose, open }) => {
	const [name, setName] = useState("");
	const [type, setType] = useState("");
	const [date, setDate] = useState(new Date());
	const [errorName, setErrorName] = useState(false);
	const [errorDate, setErrorDate] = useState(false);
	const [errorType, setErrorType] = useState(false);
	const cancelTokenSourceRef = useRef(null);
	const projectId = useSelector(({ context }) => context.project.id);
	const companyId = useSelector(({ context }) => context.company.id);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const handleDateChange = (dateObject) => {
		setErrorDate(false);
		setDate(dateObject);
	};

	const handleNameChange = (e) => {
		const newName = (e.target && e.target.value) || "";
		setErrorName(false);
		setName(newName);
	};

	const handleTypeChange = (e) => {
		const newType = (e.target && e.target.value) || null;
		setErrorType(false);
		setType(newType);
	};

	const testSpecialCharacter = (str) => {
		if (str) {
			if (/[^a-zA-Z0-9_\s]/.test(str)) {
				createNotification({
					type: "error",
					message: translate("dashboards.add-new-milestone.notification.input-error"),
				});
				setErrorName(true);
				return false;
			}
			return true;
		}
		setErrorName(true);
		return false;
	};

	const handleErrorType = () => {
		const error = type;

		if (error !== null && error !== "") {
			setErrorType(false);
			return true;
		}
		setErrorType(true);
		return false;
	};

	const handleErrorDate = () => {
		const todayDate = new Date();
		todayDate.setHours(0, 0, 0, 0);
		const newDate = new Date(date);
		if (newDate < todayDate) {
			createNotification({
				type: "error",
				message: translate("dashboards.add-new-milestone.notification.date-error"),
			});
			setErrorDate(true);
			return false;
		}
		setErrorDate(false);
		return true;
	};

	const reset = () => {
		setName("");
		setType("");
		setDate(new Date());
		setErrorName(false);
		setErrorDate(false);
		setErrorType(false);
		onClose();
	};

	const handleSubmit = () => {
		const formattedName = name.toUpperCase();
		if (date) {
			if (testSpecialCharacter(formattedName) && handleErrorType() && handleErrorDate()) {
				DashboardService.addNewUserDefinedMilestone(
					{ projectId },
					{
						name: formattedName,
						date: convertAnyDateToDisplayDate(date),
						type,
					},
					cancelTokenSourceRef.current.token
				)
					.then(() => {
						createNotification({
							type: "success",
							message: translate("dashboards.add-new-milestone.notification.success"),
						});
						DashboardService.getProjectMilestones(
							{ projectId, companyId },
							cancelTokenSourceRef.current.token
						)
							.then((data) => {
								onAdd(
									formattedName,
									date,
									type,
									data?.userDefinedMilestone?.find((x) => x.name === formattedName)?.id
								);
								setDate(new Date());
							})
							.catch((err) => {
								console.error(err);
							});
					})
					.catch((err) => {
						console.error(err);
					});
				reset();
			} else {
				onClose();
			}
		}
	};
	return (
		<Dialog fullWidth aria-labelledby="form-dialog-title" open={open} onClose={reset}>
			<DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
				{translate("dashboards.add-new-milestone.title")}
			</DialogTitle>
			<DialogContent>
				<form>
					<TextField
						fullWidth
						required
						error={errorName}
						InputProps={{
							inputProps: {
								maxLength: 30,
							},
						}}
						label="Milestone's name"
						value={name}
						variant="outlined"
						onChange={handleNameChange}
					/>
					<CustomDatePicker
						fullWidth
						className={styles.datePicker}
						error={errorDate}
						label={translate("dashboards.add-new-milestones.label.select-date")}
						minDate={new Date()}
						value={date}
						onChange={handleDateChange}
					/>
					<TextField
						fullWidth
						required
						select
						error={errorType}
						label={translate("dashboards.add-new-milestones.label.select-type")}
						value={type || ""}
						variant="outlined"
						onChange={handleTypeChange}
					>
						{["INTERNAL", "EXTERNAL"].map((t) => (
							<MenuItem key={t} value={t}>
								{t}
							</MenuItem>
						))}
					</TextField>

					<div style={{ margin: "20px" }} />

					<CustomButton fullWidth color="primary" type="submit" variant="contained" onClick={handleSubmit}>
						{translate("dashboards.add-new-milestone.btn")}
					</CustomButton>

					<div style={{ margin: "15px" }} />
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default AddMilestoneDialog;
