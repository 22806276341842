import React, { useEffect, useMemo, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import styles from "./SearchTemplateFilters.module.css";
import {
	CustomButton,
	icon,
	CustomTooltip,
	createNotification,
	CustomDialog,
	FiltersWrapper,
	CommonFilters,
	KeywordFilter,
	generateFilters,
} from "../../../../common/components";
import { ApiService, BackOfficeService, SearchCardTemplateService } from "../../../../api";
import { CreateTemplateDialog, SaveTemplateDialog } from "..";
import { hasPermission, Permissions, translate } from "../../../../common/providers";
import { SEARCH_TYPES } from "../../../../common/constants/search-types";

export default function SearchTemplateFilters({
	getCriticalityParameters,
	getNegotiabilityParameters,
	getTypeParameters,
	setCurrentSearch,
	setFilters,
	setIsLoadingSearch,
	setIsUpdating,
	slice,
}) {
	const userId = useSelector(({ context }) => context.user.id);
	const { id, title, description, creatorId, isNew } = useSelector((state) => state[slice].currentSearch);
	const criticalities = useSelector((state) => state[slice].criticalities);
	const negotiabilities = useSelector((state) => state[slice].negotiabilities);
	const types = useSelector((state) => state[slice].types);
	const modified = useSelector((state) => state[slice].modified);
	const isFilterTocOut = useSelector((state) => state[slice].isFilterTocOut);
	const filters = useSelector((state) => state[slice].filters);
	const isDsi = useSelector((state) => state[slice].isDsi);
	const selectedProject = useSelector((state) => state[slice].selectedProject);
	const currentSearch = useSelector((state) => state[slice].currentSearch);
	const tokenSourceRef = useRef(null);
	const [filtersPreviewRequest, setFiltersPreviewRequest] = useState();
	const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
	const [openSaveTemplate, setOpenSaveTemplate] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [isMakingCopy, setIsMakingCopy] = useState(false);
	const [catFile, setCatFile] = useState();
	const dispatch = useDispatch();

	const hasFilters = useMemo(
		() => Object.keys(filters).some((item) => item !== "Type" && item !== "separator" && item !== "isFilterTocOut"),
		[filters]
	);

	useEffect(() => {
		tokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(tokenSourceRef.current);
		};
	}, []);

	useEffect(() => {
		batch(() => {
			dispatch(getCriticalityParameters(tokenSourceRef.current.token));
			dispatch(getNegotiabilityParameters(tokenSourceRef.current.token));
			dispatch(getTypeParameters(tokenSourceRef.current.token));
		});
	}, [dispatch, getCriticalityParameters, getNegotiabilityParameters, getTypeParameters]);
	useEffect(
		() => {
			if (selectedProject) {
				setFiltersPreviewRequest(() => (previewFilters) => {
					const newFilters = generateFilters(previewFilters);
					return SearchCardTemplateService.countResults(
						{ projectId: selectedProject?.projectId || selectedProject?.id },
						{ ...newFilters, isFilterTocOut },
						tokenSourceRef.current.token
					).then((data) => data);
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedProject, isFilterTocOut]
	);
	const handleApply = (payload) => {
		batch(() => {
			dispatch(setIsLoadingSearch(true));
			dispatch(setFilters(payload));
		});
	};
	const handleOpenCreateTemplate = () => setOpenCreateTemplate(true);
	const handleCloseCreateTemplate = () => {
		setOpenCreateTemplate(false);
		setIsMakingCopy(false);
	};
	const handleOpenSaveTemplate = () => setOpenSaveTemplate(true);
	const handleCloseSaveTemplate = () => setOpenSaveTemplate(false);
	const handleClickSave = () => {
		if ((slice === "companySearchTemplate" && title) || (slice === "mySearchTemplate" && userId === creatorId)) {
			handleOpenSaveTemplate();
		} else {
			handleOpenCreateTemplate();
		}
	};
	const handleSubmitCreateTemplate = (createdTemplate) => {
		setOpenCreateTemplate(false);
		setIsMakingCopy(false);
		dispatch(
			setCurrentSearch({
				currentSearch: { ...createdTemplate, isNew: false },
				filters,
				isFilterTocOut,
			})
		);
	};
	const handleSubmitSaveTemplate = (isNewTemplate) => {
		if (isNewTemplate) {
			handleCloseSaveTemplate();
			handleOpenCreateTemplate();
		} else {
			(isDsi ? BackOfficeService : SearchCardTemplateService)
				.updateTemplateFilters(
					{ searchTemplateId: id },
					{
						filters: { Type: SEARCH_TYPES.template, ...generateFilters(filters), isFilterTocOut },
						name: title,
					},
					tokenSourceRef.current.token
				)
				.then(() => {
					handleCloseSaveTemplate();
					dispatch(
						setCurrentSearch({
							currentSearch: { ...currentSearch, isNew: false },
							filters,
							isFilterTocOut,
						})
					);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};
	const displayNotification = (msg, typeP = "error") => {
		createNotification({
			type: typeP,
			message: msg,
			timeout: 3000,
		});
	};
	const uploadFile = () => {
		if (catFile !== null) {
			BackOfficeService.importCategoryTemplate({ file: catFile }, tokenSourceRef.current.token)
				.then((response) => {
					if (response.valid === false) {
						displayNotification(response.errors[0]);
					} else {
						displayNotification(translate("search-template.import.success-massage"), "success");
					}
				})
				.catch(() => {
					displayNotification(translate("search-template.import.error-massage"));
				});
		}
	};
	const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);
	const handleConfirm = () => {
		setOpenConfirmDialog(false);
		uploadFile();
	};
	const handleOpenFile = (event) => {
		const newfiles = event.target.files;
		if (newfiles.length >= 1) {
			setCatFile(newfiles[0]);
			setOpenConfirmDialog(true);
		}
	};
	const handleOpenCopySearch = () => {
		setOpenCreateTemplate(true);
		setIsMakingCopy(true);
	};
	const customLabelApply = (results) => {
		if (results <= 1 && results !== null) {
			return translate("common:component.filter.apply", { results });
		}
		if (results > 1) {
			return translate("common:component.filters.apply", { results });
		}
		return translate("common:btn.apply");
	};
	return (
		<>
			<div className={styles.filters__information}>
				<div className={styles.filters__titleWrapper}>
					<div>
						<CustomTooltip arrow placement="bottom" title={isNew ? "" : title}>
							<span className={styles.titleWrapper__title}>
								{(isNew && translate("search-template.drawer.new-template")) || title}
							</span>
						</CustomTooltip>
						{modified && (
							<span className={styles.titleWrapper__notSaved}>
								({translate("common:filters.search.not-saved")})
							</span>
						)}
					</div>
					{hasPermission(Permissions.PLATFORM_ADMIN) && (
						<CustomButton color="primary" component="label" startIcon={icon.faDownload} variant="outlined">
							<input hidden accept=".xls, .xlsx" type="file" onChange={handleOpenFile} />
							{translate("search-template.import.btn")}
						</CustomButton>
					)}
				</div>
				<div className={styles.filters__descriptionWrapper}>
					<span className={styles.filters__description}>
						{(isNew && translate("common:filters.new-search.description")) || description}
					</span>
				</div>
			</div>
			<div className={styles.filters__wrapperContainer}>
				<FiltersWrapper
					hasLargeSearch
					hasSeparator
					multiline
					addFilterButtonVariant="contained"
					className={styles.filters__wrapper}
					components={[
						{
							enabled: true,
							component: CommonFilters.KEYWORDS,
							renderer: <KeywordFilter />,
						},
						{
							enabled: true,
							withDescription: false,
							multiSelection: true,
							labelKey: "label",
							valueKey: "key",
							component: CommonFilters.REQUIREMENT,
						},
						{
							enabled: true,
							component: CommonFilters.TOC,
							renderer: <KeywordFilter />,
						},
						{
							enabled: true,
							component: CommonFilters.CRITICALITY,
							labelKey: "label",
							valueKey: "value",
							dynamicItems: criticalities?.map((c) => ({
								label: `common:enum.params.criticality.${c.name.toLowerCase()}`,
								value: c.name,
							})),
						},
						{
							enabled: true,
							component: CommonFilters.NEGOTIABILITY,
							labelKey: "label",
							valueKey: "value",
							dynamicItems: negotiabilities?.map((n) => ({
								label: `common:enum.params.negotiability.${n.name.toLowerCase()}`,
								value: n.name,
							})),
						},
						{
							enabled: true,
							component: CommonFilters.TYPE,
							labelKey: "label",
							valueKey: "value",
							dynamicItems: types?.map((t) => ({
								label: `common:enum.params.type.${t.name.toLowerCase()}`,
								value: t.name,
							})),
						},
					]}
					customLabelApply={customLabelApply}
					defaultFilters={filters}
					previewRequest={filtersPreviewRequest}
					onApply={handleApply}
				/>
				{modified && hasFilters && (
					<CustomButton
						color="primary"
						startIcon={icon.faCodeBranch}
						variant="outlined"
						onClick={handleClickSave}
					>
						{translate("search-template.filters.search.add-template")}
					</CustomButton>
				)}
				{!isDsi && !modified && !isNew && (
					<CustomButton
						color="primary"
						startIcon={icon.faCopy}
						variant="outlined"
						onClick={handleOpenCopySearch}
					>
						{translate("search-template.filters.search.copy-search")}
					</CustomButton>
				)}
			</div>
			<SaveTemplateDialog
				hasWarning={!!filters.documents || !!filters.informationIds}
				open={openSaveTemplate}
				templateName={title}
				onClose={handleCloseSaveTemplate}
				onSubmit={handleSubmitSaveTemplate}
			/>
			<CreateTemplateDialog
				hasWarning={!!filters.documents || !!filters.informationIds}
				isMakingCopy={isMakingCopy}
				isMySearchTemplate={slice === "mySearchTemplate"}
				open={openCreateTemplate}
				setIsUpdating={setIsUpdating}
				slice={slice}
				onClose={handleCloseCreateTemplate}
				onSubmit={handleSubmitCreateTemplate}
			/>
			<CustomDialog
				maxWidth="xs"
				open={openConfirmDialog}
				subTitle={translate("search-template.import.confirm-dialog-text")}
				title={translate("search-template.import.confirm-dialog-title")}
				onClose={handleCloseConfirmDialog}
				onSubmit={handleConfirm}
			/>
		</>
	);
}
