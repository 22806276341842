import React, { useMemo } from "react";
import {
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	icon,
} from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import AdditionalParameterRow from "./AdditionalParameterRow";
import styles from "./AdditionalParametersTable.module.css";

export default function AdditionalparametersTable({
	coreParameters,
	hasUpdateRights,
	isCreating,
	isParameterIncluded,
	isSelected,
	isUnconditional,
	onCancelEdit,
	onConfirmEdit,
	onCreate,
	onDelete,
	onInitiateEdit,
	onNewParameter,
	rows,
	selected,
	setIsCreating,
}) {
	const headers = useMemo(
		() => [
			{ name: translate("smart-review.req-parameters.variable-title") },
			{ name: translate("smart-review.req-parameters.value-title") },
			{
				className: styles.lastTableCell,
				name:
					!isSelected && !isCreating && hasUpdateRights ? (
						<CustomIconButton
							icon={icon.faPlusCircle}
							iconColor="var(--color-blue)"
							tooltip={translate("common:btn.add")}
							onClick={() => setIsCreating(true)}
						/>
					) : (
						""
					),
			},
		],
		[isSelected, isCreating, setIsCreating, hasUpdateRights]
	);
	return (
		<CustomTable dense>
			<CustomTableHead>
				<CustomTableRow>
					{headers.map((header) => (
						<CustomTableCell key={header.name} className={header.className}>
							{header.name}
						</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody>
				{Array.isArray(rows) &&
					rows.map((row) => (
						<AdditionalParameterRow
							key={row.name}
							hasUpdateRights={hasUpdateRights}
							isCreating={isCreating}
							isParameterIncluded={isParameterIncluded}
							isSelected={isSelected}
							isUnconditional={isUnconditional}
							row={row}
							selected={selected}
							onCancelEdit={onCancelEdit}
							onConfirmEdit={onConfirmEdit}
							onCreate={onCreate}
							onDelete={onDelete}
							onInitiateEdit={onInitiateEdit}
							onNewParameter={onNewParameter}
						/>
					))}
				{Array.isArray(coreParameters) &&
					coreParameters.map((row) => (
						<AdditionalParameterRow
							key={row.name}
							isParameterIncluded={isParameterIncluded}
							isUnconditional={isUnconditional}
							row={row}
							selected={selected}
						/>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
