import React from "react";
import { getTaskPriorityColor } from "../../../../../common/utils";
import styles from "./PriorityCell.module.css";
import { CustomButton } from "../../../../../common/components";

export default function PriorityCell({ priority, isAuthor, taskId, updatePriority = null }) {
	const bgcolor = getTaskPriorityColor(priority);
	return (
		<>
			{isAuthor ? (
				<CustomButton
					fullWidth
					style={{ backgroundColor: bgcolor }}
					variant="contained"
					onClick={updatePriority && (() => updatePriority({ taskId, priority }))}
				>
					{priority}
				</CustomButton>
			) : (
				<div className={styles.label} style={{ color: bgcolor }}>
					{priority}
				</div>
			)}
		</>
	);
}
