import React from "react";
import { useLocation } from "react-router-dom";
import {
	CustomButton,
	CustomButtonLink,
	SidePanel,
	SidePanelContent,
	Tile,
	TileBody,
	TileFooter,
	TileHeader,
} from "../../../../../common/components";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../../common/providers";
import { TaskComment, TaskContent } from ".";
import TasksPanelTable from "./TasksPanelTable";
import styles from "./TasksSidePanel.module.css";
import { NavUtils } from "../../../../../navigation";
import { SOURCE_TYPES } from "../../../../../common/constants";

export default function TasksSidePanel({
	selectedTask,
	currentUser,
	displaySidePanel,
	onPanelClose,
	onUpdateDeadLine,
	onUpdateDescription,
	onUpdatePriority,
	onUpdateStatus,
	onDialogDelete,
	project,
}) {
	const location = useLocation();
	return (
		<SidePanel open={displaySidePanel} size={65} onClose={onPanelClose}>
			<SidePanelContent title={translate("dashboards.task-dashboard.collapse")} onClose={onPanelClose}>
				{selectedTask && (
					<div className={styles.sidePanel}>
						<Tile className={styles.detail}>
							<TileHeader title={translate("dashboards.task-dashboard.details")} />
							<TileBody>
								<TasksPanelTable
									content={[selectedTask]}
									currentUser={currentUser}
									onUpdateDeadline={onUpdateDeadLine}
									onUpdateDescription={onUpdateDescription}
									onUpdatePriority={onUpdatePriority}
									onUpdateStatus={onUpdateStatus}
								/>
							</TileBody>
							<TileFooter noDivider className={styles.tileFooter}>
								{isSegFeatureEnabled(SegFlags.SMART_REVIEW) && (
									<CustomButtonLink
										color="primary"
										state={{ previous: location.pathname }}
										to={NavUtils.goToSmartReview({
											projectId: project.id,
											documentId: selectedTask.documentId,
											pageStart: selectedTask.pageStart,
											infoId: selectedTask.informationId,
											source: SOURCE_TYPES.TASKS,
										})}
									>
										{translate("dashboards.task-dashboard.smart-view-redirecting")}
									</CustomButtonLink>
								)}
								{selectedTask.fromUserEmail === currentUser.email && (
									<CustomButton
										className={styles.sidePannel__deleteButton}
										onClick={() => onDialogDelete(selectedTask)}
									>
										{translate("dashboards.task-dashboard.delete-task")}
									</CustomButton>
								)}
							</TileFooter>
						</Tile>
						<Tile className={styles.detail}>
							<TileHeader title={translate("dashboards.task-dashboard.updates")} />
							<TileBody>
								<TaskComment
									comments={selectedTask.taskComments}
									taskId={selectedTask.taskId}
									user={currentUser.displayName}
								/>
							</TileBody>
						</Tile>
						<Tile className={styles.detail}>
							<TileHeader title={translate("dashboards.task-dashboard.clause")} />
							<TileBody>{selectedTask.clauseContent}</TileBody>
						</Tile>

						<Tile className={styles.detail}>
							<TileHeader title={translate("dashboards.task-dashboard.content")} />
							<TileBody>
								<div className={styles.detail}>
									<TaskContent
										data={selectedTask}
										documentId={selectedTask.documentId}
										projectId={project.id}
									/>
								</div>
							</TileBody>
						</Tile>
					</div>
				)}
			</SidePanelContent>
		</SidePanel>
	);
}
