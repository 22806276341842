import React from "react";
import styles from "./HighlightedText.module.css";

const HighlightedText = ({
	className = "",
	textClassName = "",
	highlightTransform = {},
	key = "",
	value = "",
	regex = "",
}) => (
	<p key={key} className={textClassName}>
		{value.split(regex).map((word) => {
			if (word.match(regex) !== null) {
				return <span className={`${styles.highlightClassName} ${className}`}>{highlightTransform(word)}</span>;
			}
			return <span>{word}</span>;
		})}
	</p>
);

export default HighlightedText;
