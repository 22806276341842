import client from "../../client/client";
import { encodeParam } from "../../utils/utils";

class DocumentOutService {
	getFormats(cancelToken) {
		return client.get("/documents-out/formats", { config: { cancelToken } });
	}

	getTypes(cancelToken) {
		return client.get("/documents-out/types", { config: { cancelToken } });
	}

	getAll({ projectId }, cancelToken) {
		return client.get(`/documents-out/projects/${projectId}`, { config: { cancelToken } });
	}

	create({ projectId }, payload, cancelToken) {
		return client.post(`/documents-out/projects/${projectId}`, { payload, config: { cancelToken } });
	}

	update({ docId }, payload, cancelToken) {
		return client.patch(`/documents-out/${docId}`, { payload, config: { cancelToken } });
	}

	updateBatch(payload, cancelToken) {
		return client.patch("/documents-out", { payload, config: { cancelToken } });
	}

	delete({ docId }, cancelToken) {
		return client.delete(`/documents-out/${docId}`, { config: { cancelToken } });
	}

	getImportTemplate({ projectId }, cancelToken) {
		return client.get(`/documents-out/projects/${projectId}/import`, { config: { cancelToken } });
	}

	import({ projectId }, file, shouldErase, cancelToken) {
		return client.upload(`/documents-out/projects/${projectId}/import`, file, {
			payload: shouldErase,
			config: { cancelToken },
		});
	}

	getDocumentsByReqPhase({ reqId, phaseId }, cancelToken) {
		return client.get(`/documents-out/requirements/${reqId}/phases/${phaseId}`, { config: { cancelToken } });
	}

	updateCoverage(payload, cancelToken) {
		return client.put("/documents-out/coverage", { payload, config: { cancelToken } });
	}

	updateFilteredCoverage({ projectId }, payload, cancelToken) {
		return client.put(`/documents-out/coverage/project/${projectId}`, { payload, config: { cancelToken } });
	}

	deleteCoverage(payload, cancelToken) {
		return client.patch("/documents-out/coverage", { payload, config: { cancelToken } });
	}

	getAllDocumentsForReq({ reqId }, cancelToken) {
		return client.get(`/documents-out/requirements/${reqId}`, { config: { cancelToken } });
	}

	getFilteredDeliverables({ projectId }, { name = null, phaseId = null }, cancelToken, { page = 0, limit = 25 }) {
		return client.get(`/documents-out/projects/${projectId}/filter`, {
			params: { name: encodeParam(name), phaseId, page, limit },
			config: { cancelToken },
		});
	}

	getDeliverablesByCatFilter({ projectId }, payload, cancelToken) {
		return client.post(`/documents-out/projects/${projectId}/search`, { payload, config: { cancelToken } });
	}

	getDeliverableCount({ projectId }, cancelToken) {
		return client.get(`/documents-out/projects/${projectId}/counter`, {
			config: { cancelToken },
		});
	}

	updateDocumentsForReq({ reqId }, payload, cancelToken) {
		return client.patch(`/documents-out/requirements/${reqId}`, {
			payload,
			config: { cancelToken },
		});
	}
}

export default new DocumentOutService();
