import React from "react";
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";
import styles from "./CommentHighlightedText.module.css";
import { dangerousSetInnerHTML } from "../../utils";

const CommentHighlightedText = ({ className = "", textClassName = "", mentionId = "", value = "" }) => {
	const regex = /(@\[[^\]]*\]\([0-9]*\))/gm;
	const infosRegex = /@\[([^\]]*)\]\(([0-9]*)\)/gm;
	const userId = useSelector(({ context }) => context.user.id);
	return (
		<p className={textClassName}>
			{value.split(regex).map((word) => {
				if (word.match(regex) !== null) {
					const infos = Array.from(word.matchAll(infosRegex));
					const displayName = infos[0][1];
					const mentionnedId = parseInt(infos[0][2], 10);
					if (mentionnedId === userId) {
						return (
							<span
								key={mentionId + mentionnedId}
								className={`${styles.highlightClassName} ${className}`}
								data-testid={`mentioned.name_${displayName}`}
							>
								<b>{dangerousSetInnerHTML({ text: DOMPurify.sanitize(displayName) })}</b>
							</span>
						);
					}
					return (
						<span key={mentionId + mentionnedId} className={`${styles.highlightClassName} ${className}`}>
							{dangerousSetInnerHTML({ text: DOMPurify.sanitize(displayName) })}
						</span>
					);
				}
				return (
					<span key={word}>{dangerousSetInnerHTML({ text: DOMPurify.sanitize(word) }) || <>&nbsp;</>}</span>
				);
			})}
		</p>
	);
};

export default CommentHighlightedText;
