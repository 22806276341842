import { translate } from "../../../common/providers";

export const addCategories = (categoryTree, parentId, newCategories) => {
	const newTree = Array.isArray(categoryTree) ? [...categoryTree] : [];
	if (!Array.isArray(newCategories) || newCategories.length === 0) {
		return newTree;
	}
	if (!parentId) {
		return [...newTree, ...newCategories].sort((a, b) => (a.name > b.name ? 1 : -1));
	}

	let found = false;
	newTree.forEach((category, index) => {
		if (found === false) {
			if (category.id === parentId) {
				found = true;
				const newSubThematic = Array.isArray(newTree[index].subThematic)
					? [...newTree[index].subThematic, ...newCategories]
					: newCategories;
				newTree[index].subThematic = [...newSubThematic];
				newTree[index].subThematic.sort((a, b) => (a.name > b.name ? 1 : -1));
				newTree[index].expanded = true;
			} else if (Array.isArray(category.subThematic) && category.subThematic.length > 0) {
				newTree[index].subThematic = addCategories(newTree[index].subThematic, parentId, newCategories);
			}
		}
	});
	return newTree;
};
export const getParent = (categories, childId) => {
	if (!Array.isArray(categories) || categories.length === 0) {
		return null;
	}
	let found = null;
	categories.forEach((category) => {
		if (found === null) {
			if (Array.isArray(category.subThematic)) {
				if (category.subThematic.findIndex((cat) => cat.id === childId) >= 0) {
					found = category;
				} else {
					found = getParent(category.subThematic, childId);
				}
			}
		}
	});
	return found;
};
export const getCategoryFromTree = (categories, id) => {
	if (!Array.isArray(categories) || categories.length === 0) {
		return null;
	}
	let found = null;
	categories.forEach((category) => {
		if (found === null) {
			if (category.id === id) {
				found = category;
			} else if (Array.isArray(category.subThematic)) {
				found = getCategoryFromTree(category.subThematic, id);
			}
		}
	});
	return found;
};
export const deleteCategory = (categories, id) => {
	if (!Array.isArray(categories) || categories.length === 0) {
		return null;
	}
	let found = null;
	categories.forEach((category, index) => {
		if (found === null) {
			if (category.id === id) {
				found = category;
			} else {
				categories[index].subThematic = deleteCategory(category.subThematic, id);
			}
		}
	});
	const newTree = [...categories];
	if (found === null) {
		return newTree;
	}
	return newTree.filter((cat) => cat.id !== found.id);
};
export const generateGreyShade = (defaultRGB, level) => {
	const RGBNumber = Math.round(defaultRGB - (100 * level) / 5);
	return `rgb(${RGBNumber},${RGBNumber},${RGBNumber})`;
};

const translateEnum = (translationArea, value) => translate(`${translationArea}.${value}`);
export const translateEnumRoots = (value) => translateEnum("common:categories.view-tabs", value?.toLowerCase());
