import React, { useState } from "react";
import { TextField } from "@mui/material";
import { CustomButton, SidePanel, SidePanelContent } from "../../../../common/components";
import { Permissions, translate } from "../../../../common/providers";
import styles from "./AddUserSidePanel.module.css";

export default function AddUserSidePanel({ open, onActive = null, onAdd, onClose }) {
	const [payload, setPayload] = useState({
		firstname: "",
		lastname: "",
		email: "",
		role: Permissions.COMPANY_MEMBER.role,
	});
	const [errors, setErrors] = useState({ firstname: false, lastname: false, email: false });
	const onChange = (value, field) => {
		setErrors((prev) => ({ ...prev, [field]: false }));
		setPayload((prev) => ({ ...prev, [field]: value }));
	};
	const reset = () => {
		setPayload({ firstname: "", lastname: "", email: "", role: Permissions.COMPANY_MEMBER.role });
		setErrors({ firstname: false, lastname: false, email: false });
	};

	const isEmail = (email) => /^[a-zA-Z0-9+_.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
	const isValid = (email) => {
		if (!isEmail(email)) {
			return false;
		}
		return true;
	};
	const onSubmit = () => {
		const errs = {
			firstname: !(payload.firstname && payload.firstname.length > 2),
			lastname: !(payload.lastname && payload.lastname.length > 2),
			email: !isValid(payload.email),
		};
		const hasError = Object.keys(errs).some((key) => errs[key]);
		if (!hasError) {
			onAdd({ payload });
			reset();
		}
		setErrors(errs);
	};
	return (
		<SidePanel open={open} size={38} onActive={onActive} onClose={onClose}>
			<SidePanelContent title={translate("admin.user-details.title.adding-user")} onClose={onClose}>
				<div className={styles.container}>
					<div>
						<TextField
							required
							error={errors && errors.firstname}
							helperText={
								errors &&
								errors.firstname &&
								translate("common:error.required-with-min-data", { min: "3" })
							}
							label={translate("admin.user-details.text-field.label.first-name")}
							value={payload.firstname || ""}
							variant="outlined"
							onChange={(e) => onChange(e.target.value, "firstname")}
						/>
						<TextField
							required
							error={errors && errors.lastname}
							helperText={
								errors &&
								errors.lastname &&
								translate("common:error.required-with-min-data", { min: "3" })
							}
							label={translate("admin.user-details.text-field.label.last-name")}
							value={payload.lastname || ""}
							variant="outlined"
							onChange={(e) => onChange(e.target.value, "lastname")}
						/>
						<TextField
							required
							error={errors && errors.email}
							helperText={
								errors &&
								errors.email &&
								translate("common:multiple-email-input.error-message.not-valid")
							}
							label={translate("admin.user-details.text-field.label.email")}
							value={payload.email || ""}
							variant="outlined"
							onChange={(e) => onChange(e.target.value, "email")}
						/>
						<CustomButton fullWidth color="primary" variant="contained" onClick={onSubmit}>
							{translate("common:btn.submit")}
						</CustomButton>
					</div>
				</div>
			</SidePanelContent>
		</SidePanel>
	);
}
