import React, { useMemo } from "react";
import { Checkbox } from "@mui/material";
import styles from "./RequirementRow.module.css";
import { CustomIconButton, IconComponent, icon } from "../../../../../common/components";
import { dangerousSetInnerHTML } from "../../../../../common/utils";
import { translate } from "../../../../../common/providers";
import { ContentService } from "../../../../../api";
import { useApi } from "../../../../../common/hooks";

const RequirementRow = ({ allChecked, checkedRows, disabledCheckBox, onCheck, onDisplay, row }) => {
	const { call: getPreviewInformationChapter } = useApi(ContentService.getPreviewInformationChapter);
	const isChecked = useMemo(() => {
		if (allChecked) {
			return !checkedRows.includes(row.id);
		}
		return checkedRows.includes(row.id);
	}, [allChecked, checkedRows, row.id]);
	const handleCheck = () => {
		onCheck(row.id);
	};
	const handleGetPreviewDetails = () => {
		getPreviewInformationChapter({ docId: row.documentId, informationId: row.id })
			.then((data) => onDisplay(data))
			.catch(console.error);
	};
	return (
		<div className={styles.row}>
			<div>
				<div className={styles.reqId}>
					<div>
						<Checkbox checked={isChecked} disabled={disabledCheckBox} onChange={handleCheck} />
						<IconComponent
							className={styles.infoIcon}
							color="var(--color-red)"
							icon={icon.faAsterisk}
							size="sm"
						/>
						REQ_{row.id}
					</div>
					<div className={styles.hoverContainer}>
						<CustomIconButton
							icon={icon.faFile}
							size="sm"
							tooltip={translate("common:btn.display")}
							variant="outlined"
							onClick={handleGetPreviewDetails}
						/>
					</div>
				</div>
				<div className={styles.content}>{dangerousSetInnerHTML({ text: row.content })}</div>
			</div>
		</div>
	);
};

export default RequirementRow;
