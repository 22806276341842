import React, { useState, useRef, useEffect } from "react";
import { InputAdornment } from "@mui/material";
import { translate } from "../../../providers";
import { HorizontalDivider, icon, CustomIconButton, ButtonsTextField, CustomButton } from "../..";
import styles from "./MultipleEmailInput.module.css";
import { I18nTranslate } from "../../i18n";
import { isNonEmptyArray } from "../../../utils";

// emailsObjects is a list of objects with shape :
// {
// 	email:string,
// 	valid:bool,
// }
export default function MultipleEmailInput({
	emailsObjects = [],
	errors = [],
	onAddEmails,
	onClearEmails,
	onDeleteEmail,
	prefilledEmail = "",
}) {
	const [value, setValue] = useState("");
	const [error, setError] = useState(null);
	const [hide, setHide] = useState(true);
	const [isContentOverflowing, setIsContentOverflowing] = useState(false);
	const [failedEmails, setFailedEmails] = useState([]);
	const emailInputRef = useRef(null);
	const textRef = useRef(null);

	const handleContentOverflow = () => {
		setIsContentOverflowing(textRef.current?.scrollHeight > textRef.current?.offsetHeight);
	};
	useEffect(() => {
		window.addEventListener("resize", handleContentOverflow);
		return () => {
			window.removeEventListener("resize", handleContentOverflow);
		};
	}, []);
	useEffect(() => {
		handleContentOverflow();
	}, [failedEmails]);
	useEffect(() => {
		emailInputRef.current.focus();
	}, []);
	useEffect(() => {
		setValue(prefilledEmail);
	}, [prefilledEmail]);

	const isEmail = (email) => /^[a-zA-Z0-9+_.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
	const isValid = (email) => {
		let err = null;
		if (emailsObjects.some((e) => e.email === email)) {
			err = translate("common:multiple-email-input.error-message.already-added", { email });
		}
		if (!isEmail(email)) {
			err = translate("common:multiple-email-input.error-message.not-valid", { email });
		}
		if (err) {
			setError(err);
			return false;
		}
		return true;
	};
	const handleKeyDown = (evt) => {
		if (["Enter", "Tab", ",", ";"].includes(evt.key)) {
			evt.preventDefault();
			const v = value.trim();
			if (v && isValid(v)) {
				onAddEmails([value]);
				setValue("");
			}
		}
	};
	const handleChange = (event) => {
		setValue(event.target.value);
		setError(null);
	};
	const handleSplitEmails = (pastedEmails) => {
		let pasteArray = [];
		pasteArray = pastedEmails.split(/[\n,; \r]+/);
		return pasteArray;
	};
	const handlePaste = (event) => {
		event.preventDefault();
		const paste = event.clipboardData.getData("text");
		const pasteArray = handleSplitEmails(paste);
		const pastedEmails = pasteArray.filter((pasteSingle) => isEmail(pasteSingle));
		const detectedFailedEmails = pasteArray.filter(
			(pasteSingle) => !isEmail(pasteSingle) && pasteSingle.length > 1
		);
		setFailedEmails(detectedFailedEmails);
		if (pastedEmails) {
			const toBeAdded = pastedEmails.filter((email) => !emailsObjects.some((e) => e.email === email));
			onAddEmails(toBeAdded.filter((e, i) => toBeAdded.indexOf(e) === i));
		}
	};
	const handleClickEmail = (email) => {
		setValue(email);
		onDeleteEmail(email);
	};
	const handleResetValue = () => {
		setValue("");
	};
	const handleSuppr = () => {
		if (emailsObjects.length > 0) {
			onDeleteEmail(emailsObjects[emailsObjects.length - 1].email);
		}
	};
	const handleRenderFailedEmails = () => {
		let failedEmailsString = "";
		failedEmails.forEach((failedEmail, index) => {
			failedEmailsString += index === failedEmails.length - 1 ? failedEmail : `${failedEmail} ; `;
		});
		return failedEmailsString;
	};
	const handleShowMore = () => {
		setHide((prev) => !prev);
	};
	return (
		<>
			<ButtonsTextField
				ref={emailInputRef}
				displayField="email"
				helperText={
					value && (
						<I18nTranslate
							param={{
								hintClassName: styles.textfield__hint,
								enterClassName: styles["textfield__hint--grey"],
							}}
							translationKey="common:multiple-email-input.text-field.hint"
						/>
					)
				}
				InputProps={
					value
						? {
								endAdornment: (
									<InputAdornment position="end">
										<CustomIconButton icon={icon.faTimes} onClick={handleResetValue} />
									</InputAdornment>
								),
						  }
						: {}
				}
				items={emailsObjects.map((eo) => ({
					email: eo.email,
					color: eo.valid ? "primary" : "secondary",
					deletable: true,
				}))}
				keyField="email"
				placeholder={translate("common:multiple-email-input.text-field.label")}
				value={value}
				onChange={handleChange}
				onClearItems={onClearEmails}
				onClickItem={(item) => handleClickEmail(item.email)}
				onDeleteItem={(item) => onDeleteEmail(item.email)}
				onKeyDown={handleKeyDown}
				onPaste={handlePaste}
				onSuppr={handleSuppr}
			/>
			{error && <p className={styles.error}>{error}</p>}
			<div className={styles["errors-container"]}>
				{error && Array.isArray(errors) && errors.length > 0 && <HorizontalDivider />}
				{Array.isArray(errors) && errors.length > 0 && (
					<ul className={styles["errors-container__list"]}>
						{errors.map((e, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<li key={index}>{e}</li>
						))}
					</ul>
				)}
			</div>
			{isNonEmptyArray(failedEmails) && (
				<div>
					<div ref={textRef} className={styles.error__failedEmails} data-hide={hide}>
						<b>
							{translate(
								failedEmails.length > 1
									? "common:multiple-email-input.text-field.emails-not valid"
									: "common:multiple-email-input.text-field.email-not valid",
								{
									count: failedEmails.length,
								}
							)}
						</b>
						&nbsp;
						{handleRenderFailedEmails()}
					</div>
					{isContentOverflowing && (
						<CustomButton
							className={styles.informationContent__btn}
							color="primary"
							size="sm"
							onClick={handleShowMore}
						>
							{(hide && translate("common:btn.show-more")) || translate("common:btn.show-less")}
						</CustomButton>
					)}
				</div>
			)}
		</>
	);
}
