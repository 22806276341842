import React from "react";
import { CustomIconButton, CustomTooltip, icon } from "..";
import { POSITIONS_STATUSES } from "../../constants/positions-statuses";
import { translateEnumStatus } from "../../providers";
import styles from "./PositionIcon.module.css";

const STATUS_ICONS = {
	[POSITIONS_STATUSES.YES]: icon.faCheckCircle,
	[POSITIONS_STATUSES.NO]: icon.faTimesCircle,
	[POSITIONS_STATUSES.PENDING]: icon.faClock,
	[POSITIONS_STATUSES.NOT_ME]: icon.faNotEqual,
	[POSITIONS_STATUSES.UNREVIEWED]: icon.faHourglassHalf,
};

const ICONS_COLORS = {
	[POSITIONS_STATUSES.YES]: "var(--color-green)",
	[POSITIONS_STATUSES.NO]: "var(--color-red)",
	[POSITIONS_STATUSES.PENDING]: "var(--color-orange)",
	[POSITIONS_STATUSES.NOT_ME]: "var(--color-black)",
	[POSITIONS_STATUSES.UNREVIEWED]: "var(--color-light-grey-3)",
};

export default function PositionIcon({
	active = true,
	clickable = false,
	onClick,
	positionsStatus = POSITIONS_STATUSES.UNREVIEWED,
	selectedExist = false,
}) {
	const handleClick = () => {
		if (typeof onClick === "function" && clickable) {
			onClick();
		}
	};
	return (
		<CustomTooltip placement="top" title={translateEnumStatus(positionsStatus?.toLowerCase().replaceAll("_", "-"))}>
			<CustomIconButton
				btnClassName={`${styles.button} ${!clickable ? styles["button--not-clickable"] : ""}`}
				className={styles.buttonWrapper}
				data-active={active}
				data-positionstatus={positionsStatus}
				data-selectedexist={selectedExist}
				icon={STATUS_ICONS[positionsStatus]}
				iconColor={ICONS_COLORS[positionsStatus]}
				onClick={handleClick}
			/>
		</CustomTooltip>
	);
}
