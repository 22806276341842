import React, { useRef, useState } from "react";
import { icon, IconComponent } from "../icon-component";
import DropDownMenus from "./DropDownMenus";
import styles from "./ViewBanner.module.css";

const TitleMenu = ({ index, length, title }) => {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	const handleOpen = () => {
		if (!title.key) {
			return;
		}
		setOpen(true);
	};
	const handleClose = () => {
		if (!title.key) {
			return;
		}
		setOpen(false);
	};
	return (
		<>
			<span
				ref={anchorRef}
				className={`${styles.banner__title} ${index === length - 1 && styles["banner__title--last"]}`}
				onMouseEnter={handleOpen}
				onMouseLeave={handleClose}
			>
				{title.title}
			</span>
			{index < length - 1 && <IconComponent color="var(--color-light-grey-1)" icon={icon.faCaretRight} />}
			{title.key && (
				<DropDownMenus
					ref={anchorRef}
					identifier={title.key}
					open={open}
					onMouseEnter={handleOpen}
					onMouseLeave={handleClose}
				/>
			)}
		</>
	);
};

export default TitleMenu;
