import React from "react";
import { useSelector } from "react-redux";
import {
	CustomIconButton,
	CustomIconButtonLink,
	GenericDialog,
	GenericDialogContent,
	GenericDialogHeader,
	icon,
	ReadingList,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import { NavUtils } from "../../../../../../navigation";
import styles from "./MyReadingListPopup.module.css";

const MyReadingListPopup = ({ onClose, open }) => {
	const projectId = useSelector(({ context }) => context.project?.id);
	return (
		<GenericDialog maxWidth={false} open={open} onClose={onClose}>
			<GenericDialogHeader>
				<div className={styles.header__container}>
					<div>
						<CustomIconButton btnClassName={styles.closeButton} icon={icon.faTimes} onClick={onClose} />
						{translate("smart-review.requirement-filter.my-reading-list")}
					</div>
					<CustomIconButtonLink
						icon={icon.faUpRightAndDownLeftFromCenter}
						to={NavUtils.goToReviewProgress(projectId)}
					/>
				</div>
			</GenericDialogHeader>
			<GenericDialogContent>
				<div className={styles.container}>
					<ReadingList isPopUp />
				</div>
			</GenericDialogContent>
		</GenericDialog>
	);
};

export default MyReadingListPopup;
