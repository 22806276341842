import React, { useState } from "react";
import styles from "./SearchCardTable.module.css";
import { translate } from "../../../providers";
import { CustomIconButton, CustomTooltip, icon } from "../..";
import { isNonEmptyArray } from "../../../utils";

export default function SearchCardTable({ isTemplate, searchCards, tempSearchCards, onDelete, onEdit, onLaunch }) {
	const [headers] = useState([
		{ name: translate("common:search-card-table.headers.date"), className: styles.cell__date },
		{ name: translate("common:search-card-table.headers.name"), className: styles.cell__name },
		{ name: <span className={styles.table__iconHead} />, className: styles.cell__actions },
	]);
	const renderRow = (row, index) => (
		<div
			// eslint-disable-next-line react/no-array-index-key
			key={index}
			className={styles.row}
			data-testid={`auto.row.${row?.title || row?.searchName || row?.name}`}
		>
			<div className={styles.cell__date}>
				{row?.description?.split("_")[0] || row?.searchModifiedAt?.split(" ")[0]}
			</div>
			<CustomTooltip showWhenOverflow title={row?.title || row?.searchName || row?.name}>
				<div className={styles.cell__name}>{row?.title || row?.searchName || row?.name}</div>
			</CustomTooltip>
			<div className={styles.cell__actions}>
				<div className={styles.icons}>
					{!isTemplate && row?.tempId === undefined && (
						<CustomIconButton
							icon={icon.faWandMagicSparkles}
							tooltip={translate("common:btn.launch-action")}
							variant="outlined"
							onClick={() => {
								onLaunch(row);
							}}
						/>
					)}
					<CustomIconButton
						icon={icon.faPen}
						tooltip={translate("common:btn.edit")}
						variant="outlined"
						onClick={() => {
							onEdit(row);
						}}
					/>
					<CustomIconButton
						icon={icon.faTrash}
						tooltip={translate("common:btn.delete")}
						variant="outlined"
						onClick={() => onDelete(row)}
					/>
				</div>
			</div>
		</div>
	);
	return (
		<div className={styles.table}>
			<div className={`${styles.row} ${styles.row_header}`}>
				{headers.map((header) => (
					<div key={header.name} className={header.className}>
						{header.name}
					</div>
				))}
			</div>
			{isNonEmptyArray(tempSearchCards) && tempSearchCards.map((row, index) => renderRow(row, index)).reverse()}
			{isNonEmptyArray(searchCards) && searchCards.map((row, index) => renderRow(row, index))}
		</div>
	);
}
