import React, { useState } from "react";
import { TextField } from "@mui/material";
import { translate } from "../../../../../common/providers";

export default function DescriptionCell({ data, isAuthor, updateDescription, onFocus = null }) {
	const [description, setDesription] = useState(data.description);
	const handleChange = (e) => {
		setDesription(e.target.value);
	};
	const handleSubmit = () => {
		updateDescription({ description, taskId: data.taskId });
	};
	return (
		<TextField
			fullWidth
			multiline
			disabled={!isAuthor}
			id="outlined-flexible"
			maxRows={10}
			placeholder={translate("dashboards.task-dashboard.description")}
			value={description || ""}
			variant="outlined"
			onBlur={handleSubmit}
			onChange={handleChange}
			onFocus={onFocus}
		/>
	);
}
