import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CustomDialog, CustomIconButton, CustomTooltip, icon } from "../../../../common/components";
import styles from "./ChatRow.module.css";
import { useApi } from "../../../../common/hooks";
import { ChatProjectService } from "../../../../api";
import ChatNameEditDialog from "../../../../common/components/dialogs/chat-name-dialog/ChatNameEditDialog";
import { translate } from "../../../../common/providers";

const ChatRow = ({
	onChangeSelectedChat,
	onRefresh,
	row,
	selected = false,
	"data-testid": dataTestId,
	onResetFilters,
}) => {
	const [hovered, setHovered] = useState(false);
	const projectId = useSelector(({ context }) => context.project?.id);
	const { call: deleteChat } = useApi(ChatProjectService.deleteChat);
	const { call: editChatName } = useApi(ChatProjectService.editChatName);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [initialName, setInitialName] = useState("");
	const handleMouseEnter = () => {
		setHovered(true);
	};
	const handleMouseLeave = () => {
		setHovered(false);
	};
	const handleClick = () => {
		onChangeSelectedChat(row.id);
		onResetFilters();
	};
	const handleOpenDeleteDialog = (event) => {
		event.stopPropagation();
		setOpenDeleteDialog(true);
	};
	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};
	const handleDelete = () => {
		deleteChat({ projectId, chatId: row.id })
			.then(() => {
				if (selected) {
					onChangeSelectedChat(0);
				}
				onRefresh();
			})
			.catch((err) => console.error(err))
			.finally(() => handleCloseDeleteDialog());
	};
	const handleClickEdit = (event) => {
		event.stopPropagation();
		setInitialName(row.name);
		setOpenEditDialog(true);
	};
	const handleCloseEditDialog = () => {
		setOpenEditDialog(false);
	};
	const handleEditChatNameSubmit = (newName) => {
		editChatName({ projectId, chatId: row.id }, { value: newName })
			.then(() => {
				setInitialName(newName);
				handleCloseEditDialog();
				onRefresh();
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<>
			<div
				className={styles.row__container}
				data-selected={selected}
				data-testid={dataTestId}
				role="presentation"
				onClick={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<CustomTooltip arrow showWhenOverflow title={row.name}>
					<div className={styles.title__container}>{row.name}</div>
				</CustomTooltip>
				{hovered && (
					<div className={styles.btn__container}>
						<CustomIconButton
							btnClassName={styles.button}
							data-testid={`edit-button-${row.id}`}
							icon={icon.faPenToSquare}
							iconClassName={styles.iconClassName}
							size="xs"
							onClick={handleClickEdit}
						/>
						<CustomIconButton
							btnClassName={styles.button}
							data-testid={`delete-button-${row.id}`}
							icon={icon.faTrash}
							iconClassName={styles.iconClassName}
							size="xs"
							onClick={handleOpenDeleteDialog}
						/>
					</div>
				)}
			</div>
			<CustomDialog
				iconColor="var(--color-red)"
				iconModel={icon.faExclamationTriangle}
				open={openDeleteDialog}
				title={translate("common:delete-confirmation-popup.title")}
				onClose={handleCloseDeleteDialog}
				onSubmit={handleDelete}
			/>
			<ChatNameEditDialog
				initialName={initialName}
				open={openEditDialog}
				onClose={handleCloseEditDialog}
				onSave={handleEditChatNameSubmit}
			/>
		</>
	);
};

export default ChatRow;
