import React, { useEffect, useMemo, useState } from "react";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import {
	CustomDialog,
	CustomLink,
	CustomTooltip,
	IconComponent,
	MultipleWordInput,
	icon,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import styles from "./NewGroup.module.css";
import { isNonEmptyArray } from "../../../../../../common/utils";

const NewGroup = ({ onClose, onSubmit, open = false, precedence, precedences }) => {
	const [modifiedPrecedence, setModifiedPrecedence] = useState({ name: "", keywords: undefined });
	const [isEditing, setIsEditing] = useState(false);
	const [nameError, setNameError] = useState(false);
	const language = useSelector(({ context }) => context.language);
	const disableSubmit = useMemo(
		() =>
			nameError ||
			modifiedPrecedence?.name?.length < 1 ||
			(precedence?.precedence === modifiedPrecedence?.name &&
				precedence?.keywords === modifiedPrecedence?.keywords),
		[modifiedPrecedence, nameError, precedence]
	);
	useEffect(() => {
		if (precedence) {
			setIsEditing(true);
			setModifiedPrecedence({
				name: precedence.precedence,
				keywords: precedence.keywords,
				id: precedence.precedenceId,
			});
		}
	}, [precedence]);
	useEffect(() => {
		setNameError(
			precedences.some(
				(prec) =>
					prec.precedence.toLowerCase() === modifiedPrecedence.name.toLowerCase() &&
					prec.precedenceId !== modifiedPrecedence.id
			)
		);
	}, [precedences, modifiedPrecedence]);
	const handleResetState = () => {
		setModifiedPrecedence({ name: "", keywords: undefined });
		setNameError(false);
		setIsEditing(false);
	};
	const handleChangeName = (e) => {
		const tempPrec = { ...modifiedPrecedence };
		tempPrec.name = e.target.value;
		setModifiedPrecedence(tempPrec);
	};
	const handleClose = () => {
		handleResetState();
		onClose();
	};
	const handleSubmit = () => {
		onSubmit(modifiedPrecedence, isEditing);
		handleResetState();
	};
	const handleChange = (newKeywords) => {
		setModifiedPrecedence({
			...modifiedPrecedence,
			keywords: isNonEmptyArray(newKeywords) ? newKeywords : undefined,
		});
	};
	return (
		<CustomDialog
			isForm
			closeLabel={translate("common:btn.close")}
			open={open}
			submitDisabled={disableSubmit}
			title={
				isEditing
					? translate("document-center.side-panel.dialog.modify.title")
					: translate("document-center.side-panel.dialog.create.title")
			}
			tooltip={translate("document-center.side-panel.dialog.name.helper")}
			onClose={handleClose}
			onSubmit={handleSubmit}
		>
			<>
				<div className={styles.input__title}>
					{translate("document-center.side-panel.dialog.name.title")}
					<IconComponent className={styles.input__icon} color="var(--color-red)" icon={icon.faAsterisk} />
				</div>
				<TextField
					autoFocus
					fullWidth
					error={nameError}
					helperText={nameError && translate("document-center.side-panel.dialog.name.error")}
					placeholder={translate("document-center.side-panel.dialog.name.placeholder")}
					size="small"
					value={modifiedPrecedence.name}
					onChange={handleChangeName}
				/>
				<div className={styles.input__title}>
					{translate("document-center.side-panel.dialog.keywords.title")}
					<CustomTooltip
						arrow
						placement="right"
						title={
							<span>
								{translate("document-center.side-panel.dialog.keywords.question.tooltip")}&nbsp;
								<CustomLink
									openInNewTab
									className={styles.questionIcon__tooltipLink}
									to={
										language.value === "fr"
											? "https://help.aitenders.com/fr/document-order"
											: "https://help.aitenders.com/ordre-de-pr%C3%A9s%C3%A9ance-des-documents"
									}
								>
									{translate("common:link.see-more")}
								</CustomLink>
							</span>
						}
					>
						<IconComponent color="var(--color-light-grey-1)" icon={icon.faQuestionCircle} />
					</CustomTooltip>
				</div>
				<MultipleWordInput
					disableClearAll
					errorMessage={translate("document-center.side-panel.dialog.keywords.error")}
					placeholder={translate("document-center.side-panel.dialog.keywords.placeholder")}
					wordObjects={isNonEmptyArray(modifiedPrecedence.keywords) ? modifiedPrecedence.keywords : []}
					onChange={handleChange}
				/>
			</>
		</CustomDialog>
	);
};

export default NewGroup;
