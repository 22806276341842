import React from "react";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { TextMidCut } from "../../../../common/components";
import styles from "./GonogoHeader.module.css";
import { RISK_LEVEL } from "../../utils/utils";
import { SegFlags, isSegFeatureEnabled } from "../../../../common/providers";
import ReviewRedirectionCategory from "../gonogo-details/ChatRedirection/ReviewRedirectionCategory";

export default function GonogoHeader() {
	const currentCategory = useSelector(({ gonogo }) => gonogo.currentCategory);
	return (
		<div className={styles.main}>
			<div>
				<Avatar
					classes={{ root: styles.avatar, square: `${styles.square} risk-level` }}
					data-risk-level={currentCategory?.note || ""}
					variant="square"
				>
					{RISK_LEVEL[currentCategory?.note] || "-"}
				</Avatar>
				<div className={styles.riskName}>
					<TextMidCut tooltip text={currentCategory?.name || ""} />
				</div>
				{isSegFeatureEnabled(SegFlags.CHAT_PROJECT) && <ReviewRedirectionCategory />}
			</div>
		</div>
	);
}
