import client from "../../client/client";

class SearchCardService {
	get({ searchCardId }, page, limit, cancelToken) {
		return client.get(`/searchcard/${searchCardId}`, { params: { page, limit }, config: { cancelToken } });
	}

	countAll({ projectId }, cancelToken) {
		return client.get(`/searchcard/projects/${projectId}/countAll`, { config: { cancelToken } });
	}

	delete({ searchCardId }, cancelToken) {
		return client.delete(`/searchcard/${searchCardId}`, { config: { cancelToken } });
	}

	update({ searchCardId }, payload, cancelToken) {
		return client.patch(`/searchcard/${searchCardId}/details`, { payload, config: { cancelToken } });
	}

	getFilters({ id }, cancelToken) {
		return client.get(`/searchcard/${id}/filters`, { config: { cancelToken } });
	}

	updateFilters({ searchCardId }, payload, cancelToken) {
		return client.patch(`/searchcard/${searchCardId}/filters`, { payload, config: { cancelToken } });
	}

	getAll({ projectId }, { filters, page, limit }, cancelToken) {
		return client.post(`/searchcard/projects/${projectId}/all`, {
			params: { page, limit },
			payload: filters,
			config: { cancelToken },
		});
	}

	getReqDetailsWithFilters({ projectId }, { filters }, cancelToken) {
		return client.post(`/searchcard/${projectId}/req-parameter`, {
			payload: filters,
			config: { cancelToken },
		});
	}

	create({ projectId }, payload, cancelToken) {
		return client.post(`/searchcard/projects/${projectId}`, { payload, config: { cancelToken } });
	}

	countResults({ projectId }, filters, cancelToken) {
		return client.post(`/searchcard/projects/${projectId}/count`, { payload: filters, config: { cancelToken } });
	}

	search({ projectId }, filters, page, limit, sort, cancelToken) {
		return client.post(`/searchcard/projects/${projectId}/search`, {
			params: { page, limit },
			payload: { ...filters, sort },
			config: { cancelToken },
		});
	}
}

export default new SearchCardService();
