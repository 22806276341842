import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import styles from "./Import.module.css";
import ErrorLogDialog from "./ErrorLogDialog";
import { hasProperty } from "../../../../common/utils";
import { translate } from "../../../../common/providers";
import ConfirmImportDialog from "./ConfirmImportDialog";
import { CustomButton } from "../../../../common/components";

export default function Import({ entityToImport = "", template = [], onImport, results, eraseOption = false }) {
	const [file, setFile] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openErrorLogDialog, setOpenErrorLogDialog] = useState(false);

	useEffect(() => {
		setFile(null);
		setOpenConfirmDialog(false);
		setOpenErrorLogDialog(false);
		return function cleanup() {
			setFile(null);
			setOpenConfirmDialog(false);
		};
	}, []);

	useEffect(() => {
		setOpenErrorLogDialog((results && hasProperty(results, "valid") && !results.valid) || false);
		setIsLoading(false);
	}, [results]);

	const onOpenConfirmDialog = () => setOpenConfirmDialog(true);

	const onFileChangeHandler = (event) => {
		const newFile = event.target.files[0];
		setFile(newFile);
		onOpenConfirmDialog();
	};

	const onCloseConfirmDialog = () => setOpenConfirmDialog(false);

	const onCloseErrorDialog = () => setOpenErrorLogDialog(false);

	const onConfirm = (shouldErase) => {
		setIsLoading(true);
		onImport(file, shouldErase);
		onCloseConfirmDialog();
	};

	return (
		<div style={{ marginTop: "35px", height: "200px" }}>
			<h6 className={styles.import__title}>{translate("deliverable.import.title")}</h6>
			{isLoading ? (
				<div style={{ display: "flex", height: "inherit" }}>
					<CircularProgress style={{ margin: "auto" }} />
				</div>
			) : (
				<div className={styles.import__btn}>
					<CustomButton fullWidth color="primary" component="label" variant="contained">
						{translate("common:btn.import-repository")} {entityToImport}
						<input hidden accept=".xls, .xlsx" type="file" value="" onChange={onFileChangeHandler} />
					</CustomButton>

					<span className={styles["import__btn-types"]}>(.xls, .xlsx)</span>
				</div>
			)}
			<ConfirmImportDialog
				eraseOption={eraseOption}
				open={openConfirmDialog}
				template={template}
				onClose={onCloseConfirmDialog}
				onConfirm={onConfirm}
			/>
			<ErrorLogDialog
				errors={(results && results.errors) || []}
				filename={(file && file.name) || ""}
				open={openErrorLogDialog}
				onClose={onCloseErrorDialog}
			/>
		</div>
	);
}
