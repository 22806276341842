import React from "react";
import { NavUtils } from "../../../navigation";
import { translate } from "../../providers";
import { icon } from "../icon-component";
import { CustomButtonLink } from "../links";
import styles from "./OpportunityBanner.module.css";

export default function OpportunityBanner() {
	return (
		<header className={styles.banner}>
			<CustomButtonLink
				buttonClassName={styles.banner__opportunityBtn}
				color="secondary"
				iconColor="var(--shades-grey-v2-70)"
				size="md"
				startIcon={icon.faHome}
				to={NavUtils.goToOpportunity()}
			>
				{translate("navigation:common.opportunities")}
			</CustomButtonLink>
		</header>
	);
}
