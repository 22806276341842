import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import { ApiService, CategoryService } from "../../../../../../../../../api";
import {
	CategoryDialog,
	CategoryList,
	CustomButton,
	CustomIconButton,
	CustomTooltip,
	icon,
	IconComponent,
	ViewTabs,
} from "../../../../../../../../../common/components";
import {
	hasCompanyPermission,
	hasPermission,
	Permissions,
	translate,
} from "../../../../../../../../../common/providers";
import styles from "./CategoriesTabView.module.css";
import { debounce, updateTreeBranch } from "../../../../../../../../../common/utils";
import { updateNode } from "../../../../../../../slice/pdf/pdf-slice";
import { getCheckedCategoriesCount, getParentsIdsList } from "../../../../../../../../../common/utils/category-utils";
import { setDisableKeyEvents } from "../../../../../../../../../common/slice";
import { ACTION_TYPES } from "../../../../../../../../admin-categories/constants/constants";
import { getCategoryFromTree } from "../../../../../../../../admin-categories/utils/utils";
import { setExpandedIds } from "../../../../../../../slice/project-document/project-document-slice";

const extractCheckedIds = (categories) => {
	let ids = [];
	categories.forEach((c) => {
		if (c.matched) {
			ids.push(c.id);
		}
		if (Array.isArray(c.subThematic) && c.subThematic.length > 0) {
			ids = ids.concat(extractCheckedIds(c.subThematic));
		}
	});
	return ids;
};
const debouncedFunction = debounce((func) => func());

export default function CategoriesTabView({ disableEditing, informationId, pageStart, selectedMode }) {
	const [checkedIds, setCheckedIds] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [disabledIds, setDisabledIds] = useState([]);
	const [checkedCounts, setCheckedCounts] = useState({});
	const [showSelected, setShowSelected] = useState(false);
	const [openAddCategoryDialog, setOpenCategoryDialog] = useState(false);
	const [categorySearch, setCategorySearch] = useState("");
	const [categories, setCategories] = useState([]);
	const [categorySearchDebounced, setCategorySearchDebounced] = useState("");
	const [selectedTab, setSelectedTab] = useState(0);
	const projectId = useSelector(({ context }) => context.project.id);
	const expandedIds = useSelector(({ srProjectDocument }) => srProjectDocument.expandedIds);
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	const isProjectDirector = useMemo(
		() =>
			hasCompanyPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]) &&
			hasPermission([Permissions.PROJECT_LEADER]),
		[]
	);
	const loadCategories = useCallback(
		(search) => {
			if (informationId) {
				setIsLoading(true);
				CategoryService.getByInformation({ informationId }, { search }, cancelTokenSourceRef.current.token)
					.then((data) => {
						setCategories(data);
						setCheckedIds(extractCheckedIds(data));
						setIsLoading(false);
					})
					.catch((err) => {
						setIsLoading(false);
						console.error(err);
					});
			}
		},
		[informationId]
	);
	const isNotEmpty = useMemo(() => {
		if ((selectedTab === 1 || (disableEditing && selectedTab === 0)) && checkedIds?.length > 0) {
			return true;
		}
		if (selectedTab === 0 && !disableEditing && categories.length > 0) {
			return true;
		}
		return false;
	}, [checkedIds, disableEditing, selectedTab, categories]);
	useEffect(() => {
		if (!categorySearch) {
			setCheckedCounts(getCheckedCategoriesCount(categories, checkedIds));
		}
	}, [checkedIds, categories, categorySearch]);
	useEffect(
		() => {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
			return () => {
				ApiService.cancelTokens(cancelTokenSourceRef.current);
				if (disableKeyEvents) {
					dispatch(setDisableKeyEvents(false));
				}
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	useEffect(() => {
		loadCategories(categorySearchDebounced);
	}, [loadCategories, categorySearchDebounced]);

	const handleUpdate = (newIds, currentIds, justDisabledIds) => {
		const payload = {};
		const addThematic = newIds.filter((nt) => !currentIds.some((ot) => ot === nt));
		const deleteThematic = currentIds.filter((nt) => !newIds.some((ot) => ot === nt));
		if (addThematic.length > 0) {
			payload.addThematic = addThematic;
		}
		if (deleteThematic.length > 0) {
			payload.deleteThematic = deleteThematic;
		}
		CategoryService.patchByInformation({ informationId }, payload, cancelTokenSourceRef.current.token)
			.then(() => {
				dispatch(
					updateNode({
						mode: selectedMode,
						pageConditions: { page: pageStart },
						informationId,
						updates: [{ property: "thematicCount", value: newIds.length }],
					})
				);
				setDisabledIds((prev) => prev.filter((cat) => !justDisabledIds.includes(cat)));
			})
			.catch((err) => {
				setCheckedIds(currentIds);
				loadCategories(categorySearchDebounced);
				setDisabledIds((prev) => prev.filter((cat) => !justDisabledIds.includes(cat)));
				console.error(err);
			});
	};
	const handleResetSearchText = () => {
		setCategorySearch("");
		setCategorySearchDebounced("");
	};
	const handleChangeSearchText = (event) => {
		const { value } = event.target;
		if (categorySearch.length === 0 && value.length > 0 && showSelected) {
			setShowSelected(false);
		}
		setCategorySearch(value);
		setIsLoading(true);
		debouncedFunction(() => {
			setIsLoading(false);
			setCategorySearchDebounced(value);
		});
	};
	const handleChangeShowSelected = (_, tab) => {
		setShowSelected((prev) => !prev);
		setSelectedTab(tab);
	};
	const getChildrenIdsList = (category) => {
		if (!category) {
			return [];
		}
		if (!Array.isArray(category.subThematic)) {
			return [category.id];
		}
		return [category.id, ...category.subThematic.map((cat) => getChildrenIdsList(cat))].flat();
	};
	const handleCheck = (checked, checkedCategory) => {
		let newCheckedIds = [];
		let justDisabledIds = [];
		if (checked) {
			justDisabledIds = getParentsIdsList(categories, checkedCategory);
			const result = checkedIds.concat(justDisabledIds);
			newCheckedIds = result.filter((cat, index) => result.indexOf(cat) === index);
		} else {
			justDisabledIds = getChildrenIdsList(checkedCategory);
			newCheckedIds = checkedIds.filter((prevId) => !justDisabledIds.some((childId) => childId === prevId));
		}
		setDisabledIds((prev) => prev.concat(justDisabledIds));
		handleUpdate(newCheckedIds, checkedIds, justDisabledIds);
		setCheckedIds(newCheckedIds);
	};
	const handleExpand = (expanded, category) => {
		if (!expandedIds.includes(category.id)) {
			dispatch(setExpandedIds([...expandedIds, category.id]));
		} else {
			dispatch(setExpandedIds(expandedIds.filter((item) => item !== category.id)));
		}
	};
	const handleOpenAddCategoryDialog = () => {
		setOpenCategoryDialog(true);
	};
	const handleCloseAddCategoryDialog = () => {
		setOpenCategoryDialog(false);
	};
	const handleSubmitAddCategoryDialog = (newCategory, __, newParent) => {
		const newParentId = newParent?.id || null;
		CategoryService.patchProjectTreeUpdate(
			{ projectId },
			{
				operation: ACTION_TYPES.add,
				name: newCategory.name,
				description: newCategory.description || null,
				parentId: newParentId,
				id: null,
			},
			cancelTokenSourceRef.current.token
		).then((data) => {
			if (data.parentId) {
				CategoryService.getTreeChildrenForProject(
					{ parentId: data.parentId, details: false },
					cancelTokenSourceRef.current.token
				)
					.then((oldList) => {
						setCategories((prev) =>
							updateTreeBranch(
								{
									tree: prev,
									idToReplace: data.parentId,
									newBranch: {
										...getCategoryFromTree(categories, data.parentId),
										subThematic: oldList,
										expanded: true,
									},
									iterativeBranchName: "subThematic",
								},
								{ sortKey: "name" }
							)
						);
					})
					.catch((err) => console.error(err));
			} else {
				loadCategories(categorySearchDebounced);
			}
		});
		handleCloseAddCategoryDialog();
	};

	return (
		<div className={styles.main}>
			<div className={styles.container}>
				{!disableEditing && (
					<>
						<div className={styles.container_filter}>
							<div>
								<ViewTabs
									className={styles.tabs}
									selectedTab={selectedTab}
									tabs={[
										{
											label: translate(
												"smart-review.cmp-details.option-panel.deliverables-tab.edit.deliverables-list.all"
											),
										},
										{
											label: translate(
												"smart-review.cmp-details.option-panel.deliverables-tab.edit.deliverables-list.selected",
												{
													checkedCount: checkedCounts || 0,
												}
											),
											disabled: Number(checkedCounts) === 0,
										},
									]}
									onChange={handleChangeShowSelected}
								/>
							</div>
							<CustomIconButton
								btnClassName={`${styles.rightIcon} ${
									disabledIds.length === 0 ? styles["rightIcon--translucent"] : ""
								}`}
								icon={icon.faSave}
								iconColor="var(--color-blue)"
								size="lg"
							/>
						</div>
						<div className={styles.container_search}>
							<TextField
								autoFocus
								className={styles.search}
								InputProps={{
									className: styles.textField,
									endAdornment: (
										<InputAdornment position="end">
											{isLoading && <CircularProgress size={25} thickness={3} />}
											{!isLoading && !categorySearch && <IconComponent icon={icon.faSearch} />}
											{!isLoading && categorySearch && (
												<CustomIconButton
													icon={icon.faTimesCircleRegular}
													onClick={handleResetSearchText}
												/>
											)}
										</InputAdornment>
									),
								}}
								placeholder={translate(
									"smart-review.cmp-details.option-panel.categories-tab.edit.search"
								)}
								size="small"
								value={categorySearch}
								variant="outlined"
								onBlur={() => dispatch(setDisableKeyEvents(false))}
								onChange={handleChangeSearchText}
								onFocus={() => dispatch(setDisableKeyEvents(true))}
							/>
							<CustomTooltip
								title={
									isProjectDirector
										? ""
										: translate(
												"smart-review.cmp-details.option-panel.categories-tab.missing-rights"
										  )
								}
							>
								<span>
									<CustomButton
										color="secondary"
										disabled={!isProjectDirector}
										startIcon={icon.faPlus}
										variant="outlined"
										onClick={handleOpenAddCategoryDialog}
									>
										{translate("common:categories.button.new-thematic")}
									</CustomButton>
								</span>
							</CustomTooltip>
						</div>
					</>
				)}
				<div
					className={`${
						(!(
							hasPermission([Permissions.PROJECT_MANAGER, Permissions.PROJECT_LEADER]) &&
							hasCompanyPermission([Permissions.PROJECT_LEADER]) &&
							!disableEditing
						) &&
							styles["container_list--fullHeight"]) ||
						styles.container_list
					} ${(isLoading && styles.loadingContent) || ""}`}
				>
					{(isNotEmpty && (
						<CategoryList
							categoryList={categories}
							checkedIds={checkedIds}
							disabledIds={disabledIds}
							disableEditing={disableEditing}
							editable={
								hasPermission([Permissions.PROJECT_MANAGER, Permissions.PROJECT_LEADER]) &&
								hasCompanyPermission([Permissions.PROJECT_LEADER]) &&
								!disableEditing
							}
							expandedIds={expandedIds}
							forceExpanded={selectedTab === 1 || (disableEditing && selectedTab === 0)}
							showSelected={showSelected || disableEditing}
							onCheck={handleCheck}
							onExpand={handleExpand}
						/>
					)) || (
						<div className={styles.emptyState}>
							<IconComponent
								className={styles.emptyStateIcon}
								color="var(--color-blue)"
								icon={icon.faInfoCircle}
							/>
							{translate("smart-review.cmp-details.option-panel.categories-tab.edit.empty-state")}
						</div>
					)}
				</div>
			</div>
			<CategoryDialog
				isNew
				availableParents={!categorySearchDebounced && categories}
				open={openAddCategoryDialog}
				onClose={handleCloseAddCategoryDialog}
				onSubmit={handleSubmitAddCategoryDialog}
			/>
		</div>
	);
}
