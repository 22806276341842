export function progressColor(percent) {
	return `rgb(${250 - (percent > 0.5 ? 2 * (percent - 0.5) : 0) * 200},${
		15 + (percent < 0.5 ? 2 * percent : 1) * 200
	},0)`;
}
export function progressStepColor(percent) {
	if (percent > 0.7) {
		return "var(--color-green)";
	}
	if (percent < 0.3) {
		return "var(--color-red)";
	}
	return "var(--color-orange)";
}
export function adjustValue(value) {
	if (value <= 1 && value >= 0) {
		return value;
	}
	if (value > 1) {
		return 1;
	}
	return 0;
}
