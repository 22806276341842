import React from "react";
import { TextField } from "@mui/material";
import { TaskService, ApiService } from "../../../../../api";
import { translate } from "../../../../../common/providers";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
} from "../../../../../common/components";

class TaskComment extends React.Component {
	constructor(props) {
		super(props);
		const { comments } = this.props;
		this.state = {
			comments,
			lastComment: comments.length > 0 ? comments[comments.length - 1].comment : "",
			oldComment: comments.length > 0 ? comments[comments.length - 1].comment : "",
		};
		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	handleChange = (e) => {
		this.setState({ lastComment: e.target.value });
	};

	handleKeyPress = (e) => {
		const { lastComment, oldComment } = this.state;
		const { taskId, user } = this.props;
		if (e.key === "Enter" && lastComment.length > 0 && lastComment !== oldComment) {
			TaskService.comment({ taskId }, lastComment, this.cancelTokenSource.token)
				.then(() => {
					const lastId = oldComment.id;
					this.setState((prevState) => ({
						oldComment: lastComment,
						comments: [
							{
								id: lastId + 1,
								comment: lastComment,
								user,
								created: "Just now",
							},
							...prevState.comments,
						],
					}));
				})
				.catch((err) => console.error(err));
		}
	};

	render() {
		const { lastComment, comments } = this.state;
		const { taskId } = this.props;
		return (
			<div
				id={`task${taskId}row`}
				style={{
					width: "100%",
					height: "100%",
					position: "relative",
				}}
			>
				<TextField
					fullWidth
					label="Add update"
					margin="normal"
					maxRows="10"
					value={lastComment}
					onChange={this.handleChange}
					onKeyPress={this.handleKeyPress}
				/>
				<CustomTable dense>
					<CustomTableHead>
						<CustomTableRow>
							<CustomTableCell align="left">
								{translate("dashboards.task-dashboard.collapse.update")}
							</CustomTableCell>
							<CustomTableCell align="left">
								{translate("dashboards.task-dashboard.collapse.author")}
							</CustomTableCell>
							<CustomTableCell align="left">
								{translate("dashboards.task-dashboard.collapse.date")}
							</CustomTableCell>
						</CustomTableRow>
					</CustomTableHead>
					<CustomTableBody>
						{comments.map((comment) => (
							<CustomTableRow key={comment.id}>
								<CustomTableCell align="left">{comment.comment}</CustomTableCell>
								<CustomTableCell align="left" autoTranslate="no">
									{comment.user}
								</CustomTableCell>
								<CustomTableCell align="left">{comment.created}</CustomTableCell>
							</CustomTableRow>
						))}
					</CustomTableBody>
				</CustomTable>
			</div>
		);
	}
}

export default TaskComment;
