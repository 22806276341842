import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ApiService, RequirementMatrixService } from "../../../../api";
import { Filter, FilterSearchList, CircularLoader } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { debounce, isDeepEqual } from "../../../../common/utils";

const debouncedSearch = debounce(({ value, setIsLoadingDocuments, setDocuments, cancelTokenSourceRef, projectId }) => {
	if (cancelTokenSourceRef?.current) {
		ApiService.cancelTokens(cancelTokenSourceRef.current);
	}
	cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
	RequirementMatrixService.searchDocumentsInProjectByName(
		{ projectId },
		{ filter: value },
		cancelTokenSourceRef.current.token
	)
		.then((data) => {
			setIsLoadingDocuments(false);
			setDocuments(data?.contents || []);
		})
		.catch((err) => {
			console.error(err);
			setIsLoadingDocuments(false);
		});
});
export default function DocumentFilter({ filters, isLoading, onDirectSearch, onSearch, tempTotalSearchResult }) {
	const [tempFilters, setTempFilters] = useState(filters);
	const [documents, setDocuments] = useState([]);
	const [documentsInput, setDocumentsInput] = useState("");
	const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
	const [firstDocsFilterOpened, setFirstDocsFilterOpened] = useState(false);
	const projectId = useSelector(({ context }) => context.project.id || 0);
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const needApplyDocuments = !isDeepEqual(tempFilters.documents, filters.documents);
	const generateCloseLabelText = () => {
		if (isLoading) {
			return <CircularLoader color="inherit" size={20} />;
		}
		return needApplyDocuments
			? translate("norms-center.filter.documents.apply-button", { resultsNb: tempTotalSearchResult })
			: translate("common:btn.close");
	};

	const generateDocumentFilterName = (docs, defaultName) => {
		if (Array.isArray(docs) && docs.length > 0) {
			return `${docs.length} ${defaultName}`;
		}
		return defaultName;
	};
	const generateFilterTitle = (defaultName, nbItemsSelected) => {
		if (nbItemsSelected > 0) {
			return `${defaultName} (${nbItemsSelected})`;
		}
		return defaultName;
	};
	const resetTempFilter = (clickSearch = false) => {
		const newTempFilters = { ...filters, documents: [] };
		setTempFilters(newTempFilters);
		if (clickSearch) {
			onSearch({ searchFilters: newTempFilters });
		} else {
			onDirectSearch(newTempFilters, clickSearch);
		}
	};
	const handleChangeSearchDocuments = (e) => {
		const value = e.target?.value || "";
		setIsLoadingDocuments(true);
		setDocumentsInput(value);
		debouncedSearch({ value, setIsLoadingDocuments, setDocuments, cancelTokenSourceRef, projectId });
	};

	const setTempFilter = (filter) => {
		const newTempFilters = { ...tempFilters, documents: filter };
		setTempFilters(newTempFilters);
		onDirectSearch(newTempFilters, false);
	};
	const isItemChecked = (item) => tempFilters?.documents?.includes(item.id) || false;

	const handleCheckItem = (item) => {
		if (!item) {
			return;
		}
		setTempFilter(item);
	};

	const handleOpenDocumentsFilter = () => {
		if (documents.length === 0 && !isLoadingDocuments && !firstDocsFilterOpened) {
			handleChangeSearchDocuments("");
			setFirstDocsFilterOpened(true);
		}
		resetTempFilter(false);
	};
	return (
		<Filter
			buttonColor={needApplyDocuments && !isLoading ? "primary" : "secondary"}
			closeLabel={generateCloseLabelText()}
			isActive={filters.documents.length > 0}
			label={generateDocumentFilterName(filters.documents, translate("norms-center.filter.documents.title"))}
			title={generateFilterTitle(
				translate("norms-center.filter.documents.title"),
				(tempFilters.documents || []).length
			)}
			onClear={() => resetTempFilter(true)}
			onClose={() => onSearch({ filterKey: "documents", tempFilters })}
			onOpen={handleOpenDocumentsFilter}
		>
			<FilterSearchList
				defaultInputValue={documentsInput}
				initChecked={filters.documents}
				isLoading={isLoadingDocuments}
				isRowChecked={(row) => isItemChecked(row)}
				rowKey="id"
				rowLabelKey="name"
				rows={documents}
				searchPlaceholder={translate("norms-center.filter.documents.search")}
				searchResultCount={tempTotalSearchResult}
				onChangeSearch={handleChangeSearchDocuments}
				onClear={() => resetTempFilter(false)}
				onClearSearch={() => handleChangeSearchDocuments("")}
				onClickRow={handleCheckItem}
			/>
		</Filter>
	);
}
