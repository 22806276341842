import React from "react";
import { isFeatureEnabled, translate } from "../../../common/providers";
import Errors from "../components/errors/Errors";
import TabTitleCounter from "./TabTitleCounter";

const TABS = [
	{
		tab: ({ contentCounter }) => (
			<TabTitleCounter
				counter={contentCounter}
				title={translate("consistency-check.tabs.content-errors-tab-title")}
			/>
		),
		panel: ({
			contentErrors,
			contentErrorsLoading,
			selectedDocument,
			projectId,
			docId,
			showAllErrors,
			showOnlyResolvedErrors,
			getContentErrorsByDocument,
			getTocErrorsByDocument,
			getDocumentError,
			handleChangeRowsPerPage,
			handleChangePage,
			page,
			limit,
			getDocuments,
		}) => (
			<Errors
				docId={docId}
				errors={contentErrors}
				getContentErrorsByDocument={getContentErrorsByDocument}
				getDocumentError={getDocumentError}
				getDocuments={getDocuments}
				getTocErrorsByDocument={getTocErrorsByDocument}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				isLoading={contentErrorsLoading}
				limit={limit}
				page={page}
				projectId={projectId}
				selectedDocument={selectedDocument}
				showAllErrors={showAllErrors}
				showOnlyResolvedErrors={showOnlyResolvedErrors}
			/>
		),
	},
	{
		tab: ({ tocCounter }) => (
			<TabTitleCounter counter={tocCounter} title={translate("consistency-check.tabs.toc-errors-tab-title")} />
		),
		panel: ({
			tocErrors,
			tocErrorsLoading,
			selectedDocument,
			projectId,
			docId,
			moveLoading,
			tocList,
			showTocRedressment,
			showAllErrors,
			showOnlyResolvedErrors,
			getTocErrorsByDocument,
			getDocumentError,
			getContentErrorsByDocument,
			handleChangeRowsPerPage,
			handleChangePage,
			page,
			limit,
			getDocuments,
		}) => (
			<Errors
				docId={docId}
				errors={tocErrors}
				getContentErrorsByDocument={getContentErrorsByDocument}
				getDocumentError={getDocumentError}
				getDocuments={getDocuments}
				getTocErrorsByDocument={getTocErrorsByDocument}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				isLoading={tocErrorsLoading}
				limit={limit}
				moveLoading={moveLoading}
				page={page}
				projectId={projectId}
				selectedDocument={selectedDocument}
				showAllErrors={showAllErrors}
				showOnlyResolvedErrors={showOnlyResolvedErrors}
				showTocRedressment={showTocRedressment}
				tocList={tocList}
			/>
		),
	},
];
export default TABS.filter((tab) => (tab.flag ? isFeatureEnabled(tab.flag) : true));
