import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ClickAwayListener, OutlinedInput } from "@mui/material";
import { CircularLoader, CustomButtonLink, icon, PdfViewer, SidePanel, SidePanelContent } from "..";
import { ApiService, DocumentService } from "../../../api";
import styles from "./Preview.module.css";
import { isSegFeatureEnabled, SegFlags, translate } from "../../providers";
import { NavUtils } from "../../../navigation/utils";
import { CustomButton, CustomIconButton } from "../buttons";
import { useApi, useCurrentHeight } from "../../hooks";
import { downloadFile, isNonEmptyArray } from "../../utils";

const ZOOM_STEP = 0.1;
const ZOOM_MIN = 0.5;
const ZOOM_MAX = 2;

const getRoundedHeight = (dimension, start, end) => Math.round((end - start) * dimension) + 12;
const getRoundedWidth = (dimension, start, end) => Math.round((end - start) * dimension) + 12;
const getRoundedAxisCoordinate = (axis, coordinate) => Math.round(axis * coordinate) - 6;

export default function Preview({
	adjacentPages = 3,
	blinking = false,
	coordinates,
	docId,
	hasDownload = false,
	infoId = null,
	infoType = null,
	onActive: onActiveFromParent = null,
	onClose,
	open,
	page,
	projectId,
	role = null,
}) {
	const { call: onDownloadDocument } = useApi(DocumentService.downloadDocument);
	const refPrimary = useRef();
	const refSecondary = useRef();
	const [pageDimensions, setPageDimensions] = useState([]);
	const [previewContent, setPreviewContent] = useState(null);
	const [manual, setManual] = useState(false);
	const [loadingPreview, setLoadingPreview] = useState(true);
	const [loadingRender, setLoadingRender] = useState(false);
	const [zoom, setZoom] = useState(1);
	const [manualZoom, setManualZoom] = useState(1);
	const cautionBannerDisabled = useSelector(({ context }) => context.cautionBannerDisabled);
	const companyId = useSelector(({ context }) => context.company?.id);
	const cancelTokenSourceRef = useRef(null);
	const currentHeight = useCurrentHeight({ difference: cautionBannerDisabled ? -73 : -113 });
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (docId && page && open && previewContent === null) {
			DocumentService.getDocumentPreviewDimensions(
				{ docId, page },
				{ adjPages: adjacentPages },
				cancelTokenSourceRef.current.token
			)
				.then((dataPage) => {
					setPageDimensions(dataPage.map((p) => ({ height: p.height, width: p.width })));
					setPreviewContent(dataPage.map((q) => ({ truePage: q.pageNumber, isLoading: true })));
				})
				.catch((err) => console.error(err));
		}
		if (isNonEmptyArray(previewContent) && loadingPreview) {
			previewContent.forEach((content, index) => {
				if (!content?.image && content?.isLoading && cancelTokenSourceRef?.current) {
					DocumentService.getPage({ docId }, { page: content.truePage }, cancelTokenSourceRef.current.token)
						.then((dataImage) => {
							if (dataImage) {
								const blobImage = URL.createObjectURL(dataImage);
								setPreviewContent((prev) =>
									prev?.map(
										(prevContent) =>
											(prevContent.truePage === content.truePage && {
												...content,
												page: index + 1,
												image: blobImage,
												isLoading: false,
											}) ||
											prevContent
									)
								);
							}
						})
						.catch((err) => {
							console.error(err);
							setLoadingPreview(false);
						})
						.finally(() => setLoadingPreview(false));
				}
			});
		}
		if ((page === 0 || previewContent?.length === 0) && loadingPreview) {
			setLoadingPreview(false);
		}
	}, [adjacentPages, docId, loadingPreview, page, previewContent, open]);

	useEffect(() => {
		if (open) {
			setZoom(1);
		} else {
			setLoadingPreview(true);
			setLoadingRender(false);
			setPreviewContent(null);
		}
	}, [open]);

	useEffect(() => {
		if (isNonEmptyArray(pageDimensions)) {
			const maxWidth = pageDimensions.reduce((prev, current) =>
				prev.width > current.width ? prev : current
			).width;
			const { innerWidth } = window;
			const possibilities = Array.from(
				Array(Math.trunc((ZOOM_MAX - ZOOM_MIN) / ZOOM_STEP)),
				(_, i) => Math.floor((ZOOM_MIN + i * ZOOM_STEP) * 100) / 100
			);
			const goal = (innerWidth * 0.45 - 70) / maxWidth;
			const finalZoom = possibilities.reduce((prev, curr) =>
				Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
			);
			setZoom(finalZoom);
			setManualZoom(finalZoom * 100);
		}
	}, [pageDimensions]);

	const handleZoomOut = () => {
		if (zoom > ZOOM_MIN) {
			setZoom((prev) => prev - ZOOM_STEP);
		}
	};
	const handleZoomIn = () => {
		if (zoom < ZOOM_MAX) {
			setZoom((prev) => prev + ZOOM_STEP);
		}
	};
	const handleChangeZoom = (e) => {
		const { value } = e.target;
		setManualZoom(value);
		if (value / 100 >= ZOOM_MIN && zoom / 100 <= ZOOM_MAX) {
			setZoom(Math.floor(value) / 100);
		}
	};
	const handleOpenManual = () => {
		setManual((prev) => !prev);
	};

	useEffect(() => {
		if (open && loadingRender && refPrimary?.current) {
			refPrimary.current.scrollIntoView({ behavior: "smooth", block: "center" });
		} else if (open && loadingRender && refSecondary?.current) {
			refSecondary.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [loadingRender, open, refPrimary, refSecondary, zoom]);

	const getRender = (props, rec, index) => (
		<>
			<div
				// eslint-disable-next-line react/no-array-index-key
				key={infoId * index}
				ref={rec.primary ? refPrimary : refSecondary}
				className={styles.overlay}
				data-blinking={blinking}
				data-color={rec.primary ? "primary" : "secondary"}
				style={{
					left: getRoundedAxisCoordinate(props.width, rec.x1),
					top: getRoundedAxisCoordinate(props.height, rec.y1),
					width: getRoundedWidth(props.width, rec.x1, rec.x2),
					height: getRoundedHeight(props.height, rec.y1, rec.y2),
				}}
			/>
		</>
	);
	const handleRenderLayers = (props) => {
		if (
			props.metadata.truePage >= page - 1 &&
			props.metadata.truePage <= page + 1 &&
			isNonEmptyArray(coordinates) &&
			coordinates.length > 0 &&
			zoom > 0
		) {
			if (coordinates.some((coord) => coord?.pageNumber)) {
				if (props.metadata.truePage === page) {
					setLoadingRender(true);
				}
				return coordinates.map(
					(rec, index) => rec.pageNumber === props.metadata.truePage && getRender(props, rec, index)
				);
			}
			if (props.metadata.truePage === page) {
				setLoadingRender(true);
				return coordinates.map((rec, index) => getRender(props, rec, index));
			}
		}
		return null;
	};
	const handleDownload = () => {
		onDownloadDocument({ docId })
			.then(({ data, filename }) => downloadFile({ data, filename, filetype: "zip" }))
			.catch((err) => console.error(err));
	};
	return (
		<SidePanel open={open} size={65} onActive={onActiveFromParent} onClose={onClose}>
			{({ onActive }) => (
				<SidePanelContent
					secondaryActions={
						<div className={styles.secondaryActions__container}>
							{hasDownload && (
								<CustomButton
									color="secondary"
									startIcon={icon.faDownload}
									variant="outlined"
									onClick={handleDownload}
								>
									{translate("common:component.preview.button.download")}
								</CustomButton>
							)}
							{projectId && isSegFeatureEnabled(SegFlags.SMART_REVIEW) ? (
								<CustomButtonLink
									openInNewTab
									color="primary"
									startIcon={icon.faUpRightFromSquare}
									to={NavUtils.goToSmartReview({
										projectId,
										documentId: docId,
										pageStart: page || 1,
										infoId: infoId || null,
										infoType: infoType || null,
										role,
										companyId,
									})}
									variant="contained"
								>
									{translate("common:component.preview.open-document")}
								</CustomButtonLink>
							) : null}
						</div>
					}
					title={translate("common:component.preview.title")}
					onActive={onActive}
					onClose={onClose}
				>
					<div className={styles.container}>
						<>
							{!loadingPreview &&
								pageDimensions?.length === previewContent?.length &&
								isNonEmptyArray(previewContent) && (
									<>
										<PdfViewer
											content={previewContent}
											height={currentHeight}
											numberOfPages={pageDimensions.length}
											pageDimensions={pageDimensions}
											zoom={zoom * 150}
											onRenderLayers={handleRenderLayers}
										/>
									</>
								)}
							{loadingPreview && (
								<div>
									<CircularLoader legacy />
								</div>
							)}
							{!loadingPreview && !isNonEmptyArray(previewContent) && (
								<span>{translate("common:component.preview.unavailable-image")}</span>
							)}
						</>
						{isNonEmptyArray(previewContent) && (
							<div className={styles.zoomButtons}>
								{(!manual && (
									<>
										<CustomIconButton icon={icon.faMinus} onClick={handleZoomOut} />
										<span role="presentation" onClick={handleOpenManual}>{`${Math.trunc(
											zoom * 100
										)}%`}</span>
										<CustomIconButton icon={icon.faPlus} onClick={handleZoomIn} />
									</>
								)) || (
									<ClickAwayListener onClickAway={handleOpenManual}>
										<OutlinedInput
											classes={{
												root: styles.input,
											}}
											type="number"
											value={manualZoom}
											onChange={handleChangeZoom}
										/>
									</ClickAwayListener>
								)}
							</div>
						)}
					</div>
				</SidePanelContent>
			)}
		</SidePanel>
	);
}
