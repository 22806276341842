import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import styles from "./SelectorList.module.css";
import { CustomIconButton, DropDown, icon, IconComponent, InfiniteList2 } from "../..";
import { translate } from "../../../providers";

const SelectorList = React.forwardRef(
	(
		{
			callOnResponse,
			classNameInfiniteList,
			infiniteListRequest,
			nullable = false,
			onChangeSearchText,
			onClick,
			onClose,
			onResetSearchText,
			open,
			rowRenderer,
			searchValue = "",
		},
		ref
	) => {
		const row = (loadedRow) => rowRenderer({ onClick, loadedRow });
		return (
			<DropDown ref={ref} disablePortals open={open} onClose={onClose}>
				<div
					className={styles.listContainer}
					style={{
						width: ref.current?.clientWidth || 0,
					}}
				>
					{typeof onChangeSearchText === "function" && (
						<TextField
							className={`${styles.textField}`}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{!searchValue && (
											<IconComponent color="var(--color-light-grey-2)" icon={icon.faSearch} />
										)}
										{searchValue && typeof onResetSearchText === "function" && (
											<CustomIconButton
												className={styles.resetButton}
												icon={icon.faTimes}
												onClick={onResetSearchText}
											/>
										)}
									</InputAdornment>
								),
								style: {
									fontSize: "var(--font-size-sm)",
									height: 32,
								},
							}}
							placeholder={translate("common:btn.search")}
							size="small"
							value={searchValue}
							variant="outlined"
							onChange={onChangeSearchText}
						/>
					)}
					{nullable && row(null)}
					<InfiniteList2
						callOnResponse={callOnResponse}
						className={classNameInfiniteList}
						request={infiniteListRequest}
						rowRenderer={row}
					/>
				</div>
			</DropDown>
		);
	}
);

export default SelectorList;
