import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CustomButton, IconComponent, icon } from "../../../../../../common/components";
import { MatchDropDown } from "./components";
import { ROLES } from "../../../../constants/constants";
import { translate } from "../../../../../../common/providers";
import { checkReqValMode } from "../../../../utils/utils";
import styles from "./ReviewSynthesis.module.css";

export default function ReviewSynthesis() {
	const anchorMatchRef = useRef();
	const [open, setOpen] = useState(false);
	const roles = useSelector(({ srDocument }) => srDocument.roles);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const handleDropdown = () => {
		setOpen((prev) => !prev);
	};
	const hasValidatorRole = useMemo(
		() => Array.isArray(roles) && roles.some((x) => x === ROLES.VALIDATOR.name.toUpperCase()),
		[roles]
	);
	return (
		<>
			{hasValidatorRole && checkReqValMode(mode) && (
				<>
					<CustomButton ref={anchorMatchRef} color="secondary" icon={icon.faCopy} onClick={handleDropdown}>
						{translate("smart-review.header.match")}
						<IconComponent className={styles.button__collapseIcon} icon={icon.faChevronDown} />
					</CustomButton>
					<MatchDropDown ref={anchorMatchRef} open={open} onClose={handleDropdown} />
				</>
			)}
		</>
	);
}
