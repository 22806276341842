import React, { useState } from "react";
import { List } from "@mui/material";
import styles from "../QADetails.module.css";
import { translate } from "../../../../../common/providers";
import { RelatedContentDocuments, FilteredRelatedContent, RelatedContentClauses } from ".";
import { CustomBreadcrumb, CustomButton, SearchInput } from "../../../../../common/components";
import Loader from "../../../../../navigation/routes/components/Loader";

export default function RelatedContent({
	onClickEditContent,
	editContentOn,
	displayDocuments,
	displayClauses,
	handleCoordinates,
	onChangeDisplayReference,
	references,
	projectId,
	onGoBack,
	onClickClause,
	projectReferences,
	onClickSave,
	onCheckClause,
	modifications,
	onCancel,
	onCheckDocument,
	onSearchDocuments,
	onSearchClauses,
	contentLoading,
	onClearDocumentsSearch,
	onClearClausesSearch,
	clausesLists = [],
}) {
	const [document, setDocument] = useState({});
	const [searchText, setSearchText] = useState("");
	const handleClickDocument = (doc) => {
		onChangeDisplayReference(false, true, doc.documentId);
		setSearchText("");
		setDocument(doc);
	};
	const isDocumentChecked = (doc) => {
		const mIndex = modifications.findIndex((m) => m.document && m.document.documentId === doc.documentId);
		if (mIndex >= 0) {
			return modifications[mIndex].checked;
		}
		return doc.alldoc;
	};
	const isClauseChecked = (clause) => {
		const mIndex = modifications.findIndex((m) => m.clause && m.clause.id === clause.id);
		if (mIndex >= 0) {
			return modifications[mIndex].checked;
		}
		return clause.clauseIsReferenced;
	};
	const calculateContentReferenced = (doc) => {
		let result = doc.contentReferenced;
		modifications.forEach((m) => {
			if (m.clause && m.modificationDocumentId === doc.documentId) {
				result += m.checked ? 1 : -1;
			}
		});
		return result;
	};
	const hanldeClickClause = (clauseId) => {
		onClickClause(clauseId, document.documentId);
		setSearchText("");
	};
	const handleCheckClause = (checked, item) => {
		onCheckClause(checked, item, document.documentId);
	};
	const handleChangeSearchText = (event) => {
		const newSearchText = event.target.value;
		setSearchText(newSearchText);
		if (displayDocuments) {
			onSearchDocuments(newSearchText);
		} else if (displayClauses) {
			onSearchClauses(document.documentId, newSearchText);
		}
	};
	const handleClearSearch = () => {
		setSearchText("");

		if (displayDocuments) {
			onClearDocumentsSearch();
		} else if (displayClauses) {
			onClearClausesSearch(document.documentId);
		}
	};
	return (
		<>
			<div disabled className={styles["related-container"]}>
				{editContentOn ? (
					<div>
						<div className={styles.buttons}>
							<CustomButton
								color="primary"
								disabled={modifications.length === 0}
								variant="contained"
								onClick={onClickSave}
							>
								{translate("common:btn.save")}
							</CustomButton>
							<CustomButton color="secondary" variant="outlined" onClick={onCancel}>
								{translate("common:btn.cancel")}
							</CustomButton>
						</div>
						<List>
							{displayDocuments && (
								<>
									<SearchInput
										fullWidth
										value={searchText}
										onChange={handleChangeSearchText}
										onClearSearch={handleClearSearch}
									/>
									{(contentLoading && (
										<div className={styles["loader-container"]}>
											<Loader />
										</div>
									)) ||
										(Array.isArray(references) &&
											references.length > 0 &&
											references.map((item) => (
												<RelatedContentDocuments
													key={item.documentId}
													contentReferenced={calculateContentReferenced(item)}
													item={item}
													itemIsChecked={isDocumentChecked(item)}
													onCheck={onCheckDocument}
													onClickDocument={handleClickDocument}
												/>
											)))}
								</>
							)}
							{displayClauses && (
								<>
									<CustomBreadcrumb
										className={styles.breadcrumb}
										iconName="faFolder"
										locations={[
											{
												label:
													clausesLists.length === 1
														? null
														: (clausesLists.length === 2 && document.documentName) ||
														  clausesLists[clausesLists.length - 3].list[
																clausesLists[clausesLists.length - 3].currentIndex
														  ].content,
												id: 1,
											},

											{ label: document.documentFilename, id: 2 },
										]}
										onOptionSelection={onGoBack}
									/>
									<SearchInput
										fullWidth
										value={searchText}
										onChange={handleChangeSearchText}
										onClearSearch={handleClearSearch}
									/>
									{(contentLoading && (
										<div className={styles["loader-container"]}>
											<Loader />
										</div>
									)) ||
										(clausesLists[clausesLists.length - 1] &&
											Array.isArray(clausesLists[clausesLists.length - 1].list) &&
											clausesLists[clausesLists.length - 1].list.length > 0 &&
											clausesLists[clausesLists.length - 1].list.map((clause) => (
												<RelatedContentClauses
													key={clause.id}
													editContentOn={editContentOn}
													item={clause}
													itemIsChecked={isClauseChecked(clause)}
													onCheck={handleCheckClause}
													onClickClause={hanldeClickClause}
												/>
											)))}
								</>
							)}
						</List>
					</div>
				) : (
					<div>
						<FilteredRelatedContent
							handleCoordinates={handleCoordinates}
							projectId={projectId}
							projectReferences={projectReferences}
							references={references}
						/>
						<div className={styles["edit-button"]}>
							<CustomButton color="primary" variant="outlined" onClick={onClickEditContent}>
								{translate("qa-journal.qa-details.related-content.btn.edit")}
							</CustomButton>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
