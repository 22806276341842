import { createSlice } from "@reduxjs/toolkit";
import { KEYWORD_FILTER_TYPE, VISIBILITY } from "../../../../../common/constants";
import { translateEnumParam, translateEnumParamTooltip } from "../../../../../common/providers";
import { updateTreeBranch } from "../../../../../common/utils";
import { getCategoryFromTree } from "../../../../admin-categories/utils/utils";
import { CRITICALITY, NEGOTIABILITY, SEPARATOR, TYPE } from "../utils/utils";
import {
	getCriticalityParameters,
	getNegotiabilityParameters,
	getCategoriesParents,
	getCategoriesChildren,
	getTypeParameters,
	getReviewDocuments,
	getCompanyDocuments,
	getDocumentDetails,
} from "./dashboard-thunks";
import {
	computeKeywordFiltersExistence,
	hasBeenModified,
	removeNullFilters,
	setNewResult,
	formatDocuments,
} from "./dashboard-utils";

const initialState = {
	errorDetails: false,
	myReviewRows: [],
	myCompanyRows: [],
	tabs: [],
	documentDetails: [],
	isLoadingDetails: false,
	dashboardType: "user",
	additionalFilters: { selectedDocument: null, name: "", details: "", user: null, company: null },
	mySearches: [],
	teamSearches: [],
	projectSearches: [],
	currentSearch: {
		isNew: true,
		id: 0,
		creatorId: 0,
		title: "",
		description: "",
		visibility: VISIBILITY.PRIVATE,
		listKey: "", // When currentSearch is not new, it identifies from which list it came from
	},
	modified: false,
	hasFilters: false,
	hasNegativeKeywordFilters: { [KEYWORD_FILTER_TYPE.PARAGRAPH]: false, [KEYWORD_FILTER_TYPE.TOC]: false },
	numberOfPositiveKeywordFilters: { [KEYWORD_FILTER_TYPE.PARAGRAPH]: 0, [KEYWORD_FILTER_TYPE.TOC]: 0 },
	totalResults: 0,
	totalMatches: 0,
	results: [],
	filters: {
		separator: SEPARATOR.AND,
		// isRequirement: null,
		// isClientRequirement: null,
		// clientRef: null,
		// informationIds: null,
		// documents: null,
		// categories: null,
		// reqCriticality: null,
		// reqNegotiability: null,
		// reqType: null,
		// keywordFilters: null, // { filters: [{id: timestamp, operator, matchAll, keywords}]}
	},
	previousFilters: {},
	page: 0,
	limit: 20,
	isLoadingDocuments: false,
	isLoadingSearch: false,
	isLoadingParameters: false,
	isLoadingCategories: false,
	types: [],
	criticalities: [],
	negotiabilities: [],
	categories: [],
	hasMore: false,
	totalPages: 0,
};

const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		setTabs: (state, { payload }) => ({ ...state, tabs: payload }),
		setDashboardType: (state, { payload }) => ({ ...state, dashboardType: payload === 2 ? "company" : "user" }),
		setDisplayResult: (state) => ({
			...state,
			tabs: state.tmpTabs,
			myReviewRows: state.tmpReviewRows,
			myCompanyRows: state.tmpCompanyRows,
			tmpReviewRows: [],
			tmpCompanyRows: [],
		}),
		setCount: (state, { payload }) => ({
			...state,
			tabs: state.tabs.map((tab) => (tab.type === payload.type ? { ...tab, count: payload.count } : tab)),
		}),
		setAdditionalFilters: (state, { payload }) => ({
			...state,
			additionalFilters: { ...state.additionalFilters, [payload.key]: payload.data },
		}),
		setIsLoadingSearch: (state, { payload }) => ({ ...state, isLoadingSearch: payload }),
		setCurrentSearch: (state, { payload }) => {
			const { currentSearch, filters, ...rest } = payload;
			if (filters) {
				const cleanFilters = removeNullFilters(filters);
				const { hasNegativeKeywordFilters, numberOfPositiveKeywordFilters } =
					computeKeywordFiltersExistence(cleanFilters);
				return {
					...state,
					currentSearch,
					modified: false,
					hasFilters: Object.keys(cleanFilters).length > 0,
					filters: cleanFilters,
					previousFilters: cleanFilters,
					hasNegativeKeywordFilters,
					numberOfPositiveKeywordFilters,
					...rest,
				};
			}
			return {
				...state,
				currentSearch,
				...rest,
			};
		},
		setFilters: (state, { payload: filters }) => {
			const newFilters = removeNullFilters(filters);
			const { hasNegativeKeywordFilters, numberOfPositiveKeywordFilters } =
				computeKeywordFiltersExistence(newFilters);
			return {
				...state,
				filters: newFilters,
				hasFilters: Object.keys(newFilters).length > 0,
				modified: hasBeenModified({ ...state, filters: newFilters }),
				hasNegativeKeywordFilters,
				numberOfPositiveKeywordFilters,
			};
		},
		updateSearchResultCount: (state, { payload }) => {
			const { id, resultCount, listKey } = payload;
			if (!listKey) {
				return;
			}
			const lookupSearch = state[listKey].find((s) => s.id === id);
			if (lookupSearch) {
				lookupSearch.resultCount = resultCount;
			}
		},
		updateResultRows: (state, { payload }) => {
			const { ids, checkAll, idsToExclude, ...t } = payload;
			if (checkAll) {
				return {
					...state,
					results: state.results.map((res) =>
						!idsToExclude.includes(res.informationId) ? setNewResult(res, t) : res
					),
				};
			}
			return {
				...state,
				results: state.results.map((res) => (ids.includes(res.informationId) ? setNewResult(res, t) : res)),
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getCategoriesParents.fulfilled, (state, action) => ({
			...state,
			isLoadingCategories: false,
			categories: action.payload,
		}));
		builder.addCase(getCategoriesParents.pending, (state) => ({ ...state, isLoadingCategories: true }));
		builder.addCase(getCategoriesParents.rejected, (state) => ({ ...state, isLoadingCategories: false }));
		builder.addCase(getCategoriesChildren.fulfilled, (state, action) => {
			const categories = [...state.categories];
			const newCategory = updateTreeBranch(
				{
					tree: categories,
					idToReplace: action.meta.arg.parentId,
					newBranch: {
						...getCategoryFromTree(categories, action.meta.arg.parentId),
						subThematic: action.payload,
					},
					iterativeBranchName: "subThematic",
				},
				{ deepUpdate: true }
			);
			return {
				...state,
				categories: newCategory,
			};
		});
		builder.addCase(getReviewDocuments.fulfilled, (state, action) => {
			const { displayResult } = action.meta.arg;
			const newTabs = [...state.tabs].map((tab, index) =>
				index === state.tabs.length - 2
					? { type: tab.type, label: tab.label, count: { documents: action.payload.length } }
					: tab
			);
			return {
				...state,
				isLoadingDocuments: false,
				myReviewRows: displayResult ? formatDocuments(action.payload, true) : state.myReviewRows,
				tmpReviewRows: displayResult ? state.myReviewRows : formatDocuments(action.payload, true),
				tabs: displayResult ? newTabs : state.tabs,
				tmpTabs: displayResult ? state.tabs : newTabs,
				totalResults: displayResult ? state.totalResults : action.payload.length,
			};
		});
		builder.addCase(getReviewDocuments.pending, (state) => ({ ...state, isLoadingDocuments: true }));
		builder.addCase(getReviewDocuments.rejected, (state) => ({
			...state,
			myReviewRows: [],
			isLoadingDocuments: false,
		}));
		builder.addCase(getCompanyDocuments.fulfilled, (state, action) => {
			const { displayResult } = action.meta.arg;
			const newTabs = [...state.tabs].map((tab, index) =>
				index === state.tabs.length - 1
					? { type: tab.type, label: tab.label, count: { documents: action.payload.length } }
					: tab
			);
			return {
				...state,
				isLoadingDocuments: false,
				myCompanyRows: displayResult ? formatDocuments(action.payload, true) : state.myCompanyRows,
				tmpCompanyRows: displayResult ? [] : formatDocuments(action.payload, true),
				tmpTabs: displayResult ? state.tabs : newTabs,
				tabs: displayResult ? newTabs : state.tabs,
				totalResults: displayResult ? state.totalResults : action.payload.length,
			};
		});
		builder.addCase(getCompanyDocuments.pending, (state) => ({ ...state, isLoadingDocuments: true }));
		builder.addCase(getCompanyDocuments.rejected, (state) => ({
			...state,
			myCompanyRows: [],
			isLoadingDocuments: false,
		}));
		builder.addCase(getDocumentDetails.fulfilled, (state, action) => {
			const { displayResult } = action.meta.arg;
			return {
				...state,
				isLoadingDetails: false,
				documentDetails: displayResult ? formatDocuments(action.payload, false) : state.documentDetails,
				tmpDocumentDetails: displayResult ? [] : formatDocuments(action.payload, false),
				totalResults: displayResult ? state.totalResults : action.payload,
				errorDetails: false,
			};
		});
		builder.addCase(getDocumentDetails.pending, (state) => ({ ...state, isLoadingDetails: true }));
		builder.addCase(getDocumentDetails.rejected, (state) => ({
			...state,
			documentDetails: [],
			isLoadingDetails: false,
			errorDetails: true,
		}));
		builder.addCase(getTypeParameters.fulfilled, (state, action) => {
			const types = action.payload.map((i) => ({ name: i, displayName: translateEnumParam(TYPE, i) }));
			return { ...state, isLoadingParameters: false, types };
		});
		builder.addCase(getTypeParameters.pending, (state) => ({ ...state, isLoadingParameters: true }));
		builder.addCase(getTypeParameters.rejected, (state) => ({ ...state, isLoadingParameters: false }));
		builder.addCase(getCriticalityParameters.fulfilled, (state, action) => {
			const criticalities = action.payload.map((i) => ({
				name: i,
				displayName: translateEnumParam(CRITICALITY, i),
			}));
			return { ...state, isLoadingParameters: false, criticalities };
		});
		builder.addCase(getCriticalityParameters.pending, (state) => ({ ...state, isLoadingParameters: true }));
		builder.addCase(getCriticalityParameters.rejected, (state) => ({ ...state, isLoadingParameters: false }));
		builder.addCase(getNegotiabilityParameters.fulfilled, (state, action) => {
			const negotiabilities = action.payload.map((i) => ({
				name: i,
				displayName: translateEnumParam(NEGOTIABILITY, i),
				title: translateEnumParamTooltip(NEGOTIABILITY, i),
			}));
			return { ...state, isLoadingParameters: false, negotiabilities };
		});
		builder.addCase(getNegotiabilityParameters.pending, (state) => ({ ...state, isLoadingParameters: true }));
		builder.addCase(getNegotiabilityParameters.rejected, (state) => ({ ...state, isLoadingParameters: false }));
		builder.addMatcher(
			({ type }) => typeof type === "string" && type.endsWith("rejected"),
			(_, { error }) => {
				console.error(error.message);
			}
		);
	},
});

export {
	getCriticalityParameters,
	getNegotiabilityParameters,
	getCategoriesParents,
	getCategoriesChildren,
	getTypeParameters,
	getReviewDocuments,
	getCompanyDocuments,
	getDocumentDetails,
};
export const {
	setCount,
	setDashboardType,
	setFilters,
	setCurrentSearch,
	setIsLoadingSearch,
	updateSearchResultCount,
	updateResultRows,
	setTabs,
	setAdditionalFilters,
	setDisplayResult,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
