import React, { useRef, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { RequirementParameterService, ApiService } from "../../../../../../../../api";
import { checkReq } from "../../../../../../utils/utils";
import { debounce } from "../../../../../../../../common/utils";
import ParameterBlock from "./parameter-block/ParameterBlock";

const debounceFunction = debounce((func) => {
	func();
}, 200);
export default function ParameterSublayer({ hoveredBlock, onUpdateHoveredArea }) {
	const dispatch = useDispatch();
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			cancelTokenSourceRef.current.cancel();
		};
	}, []);

	const debouncedParametersRequest = useCallback(
		() => {
			debounceFunction(() => {
				RequirementParameterService.getParameters(
					{ reqId: hoveredBlock.informationId },
					cancelTokenSourceRef.current.token
				)
					.then((data) => {
						onUpdateHoveredArea({ ...hoveredBlock, parameters: data });
					})
					.catch((err) => {
						console.error(err);
					});
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, hoveredBlock]
	);
	useEffect(() => {
		if (checkReq(hoveredBlock) && !hoveredBlock?.parameters && hoveredBlock?.informationId) {
			debouncedParametersRequest(hoveredBlock);
		}
	}, [hoveredBlock, debouncedParametersRequest]);
	return <ParameterBlock hoveredBlock={hoveredBlock} />;
}
