import client from "../../client/client";

class ProjectCreationService {
	createProject(cancelToken) {
		return client.post("/project-creation", { config: { cancelToken } });
	}

	updateProjectDetails({ projectId }, payload, cancelToken) {
		return client.patch(`/project-creation/${projectId}`, { payload, config: { cancelToken } });
	}

	getOngoingProject(cancelToken) {
		return client.get("/project-creation", { config: { cancelToken } });
	}

	abortProjectCreation({ projectId }, cancelToken) {
		return client.delete(`/project-creation/${projectId}`, { config: { cancelToken } });
	}

	addExistingUser({ projectId }, payload, cancelToken) {
		return client.post(`/project-creation/${projectId}/users`, { payload, config: { cancelToken } });
	}

	addNewUser({ projectId }, payload, cancelToken) {
		return client.post(`/project-creation/${projectId}/users/new`, { payload, config: { cancelToken } });
	}

	searchAvailableUsers({ filter = null, limit, page }, cancelToken) {
		return client.get("/project-creation/users", { params: { filter, limit, page }, config: { cancelToken } });
	}

	deleteUser({ userId }, cancelToken) {
		return client.delete(`/project-creation/users/${userId}`, { config: { cancelToken } });
	}

	updateUserRole({ userId }, payload, cancelToken) {
		return client.put(`/project-creation/users/${userId}`, { payload, config: { cancelToken } });
	}

	uploadDocuments({ projectId }, document, progressCallback, cancelToken) {
		return client.upload(`/project-creation/${projectId}/document`, document, {
			config: { cancelToken, onUploadProgress: progressCallback },
		});
	}

	deleteDocument({ documentId }, cancelToken) {
		return client.delete(`/project-creation/documents/${documentId}`, { config: { cancelToken } });
	}

	validateProject({ projectId }, cancelToken) {
		return client.post(`/project-creation/${projectId}`, { config: { cancelToken } });
	}

	checkEmails({ projectId }, payload, cancelToken) {
		return client.patch(`/project-creation/${projectId}/users/check`, {
			payload,
			config: { cancelToken },
		});
	}

	addCategories({ projectId }, payload, cancelToken) {
		return client.post(`/project-creation/${projectId}/thematics`, { config: { cancelToken }, payload });
	}

	getProjectSummary({ projectId }, cancelToken) {
		return client.get(`/project-creation/${projectId}`, { config: { cancelToken } });
	}

	getProjectCategoriesIds({ projectId }, cancelToken) {
		return client.get(`/project-creation/${projectId}/thematics`, { config: { cancelToken } });
	}

	getCompanyPackages(cancelToken) {
		return client.get("/project-creation/packages", { config: { cancelToken } });
	}

	getIsFeatureInPackage({ packageId }, { segFlags }, cancelToken) {
		return client.post(`/project-creation/packages/${packageId}`, {
			payload: { segFlags },
			config: { cancelToken },
		});
	}
}

export default new ProjectCreationService();
