import React from "react";
import { CustomTableCell, CustomIconButton, icon } from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";
import styles from "./ButtonNormBody.module.css";

export default function ButtonNormBody({ multipleOnly = false, row, onShowDetails, onSelectRow }) {
	return (
		<>
			<CustomTableCell align="center" className={styles.btnNorm__cell}>
				<CustomIconButton
					className={styles.btnNorm__icon}
					disabled={!onShowDetails}
					icon={icon.faInfo}
					size="md"
					tooltip={translate("norms-center.automatic-detection.show-details")}
					variant="contained"
					onClick={(e) => onShowDetails(e, row.matchedNormId, row.matchedNormName)}
				/>
			</CustomTableCell>
			<CustomTableCell align="center" className={styles.btnNorm__cell}>
				<CustomIconButton
					className={styles.btnNorm__icon}
					disabled={!onSelectRow}
					icon={icon.faAngleDoubleRight}
					size="md"
					tooltip={translate("norms-center.automatic-detection.all-detections-select.tooltip")}
					variant="contained"
					onClick={() => onSelectRow(row, false)}
				/>
			</CustomTableCell>
			{!multipleOnly && (
				<CustomTableCell align="center" className={styles.btnNorm__cell}>
					<CustomIconButton
						className={styles.btnNorm__icon}
						disabled={!onSelectRow}
						icon={icon.faAngleRight}
						size="md"
						tooltip={translate("common:btn.apply")}
						variant="contained"
						onClick={() => onSelectRow(row, true)}
					/>
				</CustomTableCell>
			)}
		</>
	);
}
