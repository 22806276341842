import React, { useState } from "react";
import { TextField } from "@mui/material";
import { translate } from "../../../../../common/providers";

export default function DeadlineCell({ data, isAuthor, updateDeadline }) {
	const [deadline, setDeadline] = useState(data.deadline || null);
	const handleChange = (e) => {
		setDeadline(e.target.value);
	};
	const handleSubmit = () => {
		const today = new Date();
		const newDeadline = new Date(deadline);
		if (newDeadline >= today) {
			updateDeadline({ deadline, taskId: data.taskId });
		} else {
			setDeadline(data.deadline);
		}
	};
	return (
		<TextField
			disabled={!isAuthor}
			id="datetime-local"
			InputLabelProps={{
				shrink: true,
			}}
			label={translate("dashboards.user-project.task-details.table-head.deadline")}
			type="date"
			value={deadline}
			onBlur={handleSubmit}
			onChange={handleChange}
		/>
	);
}
