import { OPERATORS, ROLES } from "../constants/constants";
import { Permissions } from "../../../common/providers";

const REVIEW_PERMISSIONS = {
	CHANGE_TYPE_NO_REVIEW: {
		companyProject: [Permissions.PROJECT_LEADER],
		userDocRole: [ROLES.REVIEWER.modeName, ROLES.VALIDATOR.modeName],
		operator: OPERATORS.AND,
		components: ["UpdateSentenceTypeButton", "SelectionLayer"],
	},
	CHANGE_REQ_TO_INFO_WITH_STATUS: {
		companyProject: [Permissions.PROJECT_LEADER],
		userDocRole: [ROLES.VALIDATOR.modeName],
		operator: OPERATORS.AND,
		components: ["UpdateSentenceTypeButton", "SelectionLayer"],
	},
	FREEZE_CAPTURE: {
		companyProject: [Permissions.PROJECT_LEADER],
		userDocRole: [ROLES.VALIDATOR.modeName],
		operator: OPERATORS.AND,
		components: ["LockDropDown"],
	},
	CHANGE_TYPE_FROM_SEARCH: {
		companyProject: [Permissions.PROJECT_LEADER],
		userProjectRole: [Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER],
		operator: OPERATORS.AND,
	},
	CHANGE_REQ_PARAMETER: {
		companyProject: [Permissions.PROJECT_LEADER],
		userDocRole: [ROLES.REVIEWER.modeName, ROLES.VALIDATOR.modeName],
		operator: OPERATORS.AND,
		components: ["AdditionalDetailDialog"],
	},
	CHANGE_REQ_PARAMETER_FROM_SEARCH: {
		companyProject: [Permissions.PROJECT_LEADER],
		userProjectRole: [Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER],
		operator: OPERATORS.AND,
	},
	GROUP_SEPARATE_INFO: {
		companyProject: [Permissions.PROJECT_LEADER],
		userDocRole: [ROLES.REVIEWER.modeName, ROLES.VALIDATOR.modeName],
		operator: OPERATORS.AND,
		components: ["SelectionLayer", "RedressButton"],
	},
	SPLIT_MERGE_CONTENT: {
		userDocRole: [ROLES.EDITOR.modeName],
	},
	CATEGORY_UPDATE: {
		companyProject: [Permissions.PROJECT_LEADER],
		userProjectRole: [Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER],
		operator: OPERATORS.AND,
	},
};

export default REVIEW_PERMISSIONS;
