import React from "react";
import { useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { NavComponent } from ".";

export default function NavRoute({ Component, ...props }) {
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const project = useSelector(({ context }) => context.project);
	if (params.projectId && !project) {
		window.location.href = "/";
		return null;
	}
	return (
		<NavComponent
			Component={Component}
			location={location}
			navigate={navigate}
			params={params}
			project={project}
			{...props}
		/>
	);
}
