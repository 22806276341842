import React from "react";
import { MultiToc, SideDrawerOption } from "./components";

export default function Drawer({ open, onOpen }) {
	return (
		<>
			<SideDrawerOption open={open} onOpen={onOpen} />
			<MultiToc open={open} />
		</>
	);
}
