import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { updateNode, addPageToPagesUpdated } from "../../../../../../../slice/pdf/pdf-slice";
import { ApiService, CollabService, ValidatorService } from "../../../../../../../../../api";
import { CustomIconButton, icon } from "../../../../../../../../../common/components";
import { translate } from "../../../../../../../../../common/providers";
import { checkReq, isArchived } from "../../../../../../../utils/utils";
import { REQ_STATUSES, SENTENCE_TYPE } from "../../../../../../../constants/constants";
import { hasReviewPermissions } from "../../../../../../../permissions/review-permissions-provider";
import REVIEW_PERMISSIONS from "../../../../../../../permissions/review-permissions";
import { setInfoType } from "../../../../../../../slice/document/document-slice";
import { KEY_SMART_VIEW } from "../../../../../../../../../common/providers/keys/keys";
import SmartReviewContext from "../../../../../../../context/smart-review-context";

const UpdateSentenceTypeButton = ({ hoveredBlock, onUpdateHoveredArea }) => {
	const cancelTokenSourceRef = useRef(null);
	const savedImageLength = useSelector(({ srPdf }) => srPdf?.content?.length || 0);
	const content = useSelector(({ srPdf }) => srPdf?.content);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const roles = useSelector(({ srDocument }) => srDocument.roles);
	const status = useSelector(({ srDocument }) => srDocument.status);
	const isFrozen = useSelector(({ srDocument }) => srDocument.isFrozen);
	const { lastStatus, validatedStatus, informationId, pageStart } = hoveredBlock;
	const dispatch = useDispatch();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const request = () => {
		let promise = null;
		const { sidePanelInfo } = JSON.parse(window.sessionStorage.getItem(KEY_SMART_VIEW)) || {};
		if (mode === "req") {
			promise = CollabService.updateType(
				{ infoId: informationId },
				{ type: checkReq(hoveredBlock) ? SENTENCE_TYPE.INFORMATION : SENTENCE_TYPE.REQUIREMENT },
				cancelTokenSourceRef.current.token
			);
		} else {
			promise = ValidatorService.forceSwitchRequirementType(
				{ infoId: informationId },
				{ type: checkReq(hoveredBlock) ? SENTENCE_TYPE.INFORMATION : SENTENCE_TYPE.REQUIREMENT },
				cancelTokenSourceRef.current.token
			);
		}
		const newStatus = checkReq(hoveredBlock) ? SENTENCE_TYPE.INFORMATION : SENTENCE_TYPE.REQUIREMENT;
		promise
			.then(() => {
				dispatch(
					setInfoType({
						informationId,
						informationType: checkReq(hoveredBlock) ? SENTENCE_TYPE.INFORMATION : SENTENCE_TYPE.REQUIREMENT,
					})
				);
				const currentPage = content.find((c) => c.page === pageStart);
				if (currentPage.val && currentPage.req) {
					dispatch(addPageToPagesUpdated(pageStart));
				}
				if (mode === "req") {
					batch(() => {
						dispatch(
							updateNode({
								mode,
								pageConditions: { page: pageStart, border: true, length: savedImageLength },
								informationId,
								updates: [
									{
										property: "sentenceType",
										value: newStatus,
									},
								],
							})
						);
						onUpdateHoveredArea({
							lastStatus,
							validatedStatus,
							informationId,
							pageStart,
							...hoveredBlock,
							sentenceType: newStatus,
						});
					});
				} else {
					const updates = [
						{
							property: "validatedSentenceType",
							value: newStatus,
						},
					];
					if (newStatus !== "information") {
						updates.push({
							property: "validatedStatus",
							value: validatedStatus,
						});
					}
					batch(() => {
						dispatch(
							updateNode({
								mode,
								pageConditions: { page: pageStart, border: true, length: savedImageLength },
								informationId,
								updates,
							})
						);
						onUpdateHoveredArea({
							lastStatus,
							validatedStatus,
							informationId,
							pageStart,
							...hoveredBlock,
							validatedSentenceType: newStatus,
						});
					});
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				if (informationId === sidePanelInfo.infoId) {
					SmartReviewContext.setSidePanelInfo({
						...sidePanelInfo,
						isRequirement: !checkReq(hoveredBlock),
					});
				}
			});
	};

	const updateRights = useMemo(() => {
		const hasNoStatus = [lastStatus, validatedStatus].every((x) => !x || x === REQ_STATUSES.UNREVIEWED);
		const hasNoPeerReview = hoveredBlock?.reviewCounters?.total === hoveredBlock?.reviewCounters?.unreviewed;
		const hasNoReview = hasNoPeerReview && hasNoStatus;
		const disabled =
			isFrozen ||
			(!hasNoReview &&
				!hasReviewPermissions(REVIEW_PERMISSIONS.CHANGE_REQ_TO_INFO_WITH_STATUS, {
					roles: [mode === "val" ? "VALIDATOR" : ""],
				})) ||
			!hasReviewPermissions(REVIEW_PERMISSIONS.CHANGE_TYPE_NO_REVIEW, { roles });
		return { hasNoReview, disabled };
	}, [hoveredBlock, lastStatus, validatedStatus, isFrozen, roles, mode]);
	return (
		<>
			{!isArchived(status) && (
				<CustomIconButton
					disabled={updateRights.disabled}
					icon={icon.faExchangeAlt}
					tooltip={
						(isFrozen && translate("smart-review.update-sentence-type.title.warning-is-frozen")) ||
						(updateRights.disabled &&
							translate("smart-review.update-sentence-type.title.warning-has-review")) ||
						(checkReq(hoveredBlock) &&
							translate("smart-review.update-sentence-type.title.turn-into-information")) ||
						translate("smart-review.update-sentence-type.title.turn-into-req")
					}
					onClick={request}
				/>
			)}
		</>
	);
};

export default UpdateSentenceTypeButton;
