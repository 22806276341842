import client from "../../client/client";

class ProjectInfoService {
	addProjectInfo({ projectId }, payload, cancelToken) {
		return client.post(`/project-information/projects/${projectId}`, { payload, config: { cancelToken } });
	}

	getContractTypes({ projectId }, cancelToken) {
		return client.get(`/project-information/projects/${projectId}/contract-types`, { config: { cancelToken } });
	}

	getCurrencies({ projectId }, cancelToken) {
		return client.get(`/project-information/projects/${projectId}/currencies`, { config: { cancelToken } });
	}

	getActiveFeatures({ projectId }, cancelToken) {
		return client.get(`/project-information/projects/${projectId}/features`, { config: { cancelToken } });
	}

	getProjectInfo({ projectId }, cancelToken) {
		return client.get(`/project-information/projects/${projectId}`, { config: { cancelToken } });
	}

	updateProjectInfo({ projectInfoId }, payload, cancelToken) {
		return client.patch(`/project-information/${projectInfoId}`, { payload, config: { cancelToken } });
	}
}
export default new ProjectInfoService();
