import React from "react";
import { SidePane } from "react-side-pane";
import PropTypes from "prop-types";

export default function SidePanel({
	autoWidth,
	children,
	disableBackdropClick,
	disableEscapeKeyDown,
	onActive,
	offset = 10,
	onClose,
	open,
	position = "right",
	size,
}) {
	return (
		<SidePane
			autoWidth={autoWidth}
			containerId="side-pane-container"
			disableBackdropClick={disableBackdropClick}
			disableEscapeKeyDown={disableEscapeKeyDown}
			offset={offset}
			open={open}
			position={position}
			width={size}
			onActive={onActive}
			onClose={onClose}
		>
			{children}
		</SidePane>
	);
}
SidePanel.propTypes = {
	position: PropTypes.oneOf(["right", "left"]),
};
SidePanel.defaultProps = {
	position: "right",
};
