import React from "react";
import { CustomIconButton, icon } from "../../../../../../common/components";
import styles from "./SideDrawerOption.module.css";

const SideDrawerOption = ({ open, onOpen }) => {
	const handleOpen = () => {
		onOpen();
	};
	return (
		<div className={styles.sideDrawerOption}>
			<CustomIconButton
				className={styles.drawer__iconBtn}
				icon={(open && icon.faChevronLeft) || icon.faBars}
				size="sm"
				variant="outlined"
				onClick={handleOpen}
			/>
		</div>
	);
};

export default SideDrawerOption;
