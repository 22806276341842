import { Select } from "@mui/material";
import React from "react";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
} from "../../../../common/components";
import { translate } from "../../../../common/providers";

export default function USerDetailsTable({ PROJECT_ROLES, onChangeRole, onUpdateStatus = null, projects }) {
	const status = ["ACTIVE", "BLOCKED", "PENDING"];
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					<CustomTableCell>{translate("dsi.user-details.th.project")}</CustomTableCell>
					<CustomTableCell>{translate("dsi.user-details.th.role")}</CustomTableCell>
					<CustomTableCell>{translate("dsi.user-details.th.status")}</CustomTableCell>
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody emptyState={translate("common:empty-state.none")}>
				{Array.isArray(projects) &&
					projects.map((row) => (
						<CustomTableRow key={row.id}>
							<CustomTableCell>{row.name}</CustomTableCell>
							<CustomTableCell>
								<Select
									native
									value={Array.isArray(row.roles) && row.roles[0] ? row.roles[0] : "-"}
									onChange={(event) => {
										onChangeRole({ role: event.target.value, project: row });
									}}
								>
									{PROJECT_ROLES.map((r) => (
										<option key={r.name} value={r.name}>
											{r.displayName}
										</option>
									))}
								</Select>
							</CustomTableCell>
							<CustomTableCell>
								<Select
									native
									value={row.statusInProject}
									onChange={(e) => onUpdateStatus({ project: row, selectedStatus: e.target.value })}
								>
									{status.map((r) => (
										<option key={r} value={r}>
											{r}
										</option>
									))}
								</Select>
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
