import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { I18nTranslate } from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import styles from "./MatchContentFromReviewer.module.css";

export default function MatchContentFromReviewer({
	isOptionSelected,
	unanimouslyAcceptedOnly,
	onChangeUnanimouslyAcceptedOnly,
}) {
	return (
		(isOptionSelected && (
			<span className={styles.match__text}>
				{translate("smart-review.match-dialog.review-status-of-selected-reviewer-will-be-used")}
			</span>
		)) || (
			<RadioGroup value={unanimouslyAcceptedOnly} onChange={onChangeUnanimouslyAcceptedOnly}>
				<FormControlLabel
					classes={{ label: styles["match__radio-label"], root: styles.match__radio }}
					control={<Radio color="primary" />}
					label={<I18nTranslate translationKey="smart-review.match-dialog.most-critic-team-position" />}
					value={false}
				/>
				<FormControlLabel
					value
					classes={{ label: styles["match__radio-label"], root: styles.match__radio }}
					control={<Radio color="primary" />}
					label={translate("smart-review.match-dialog.unanimously-accepted")}
				/>
			</RadioGroup>
		)
	);
}
