import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ValidatorService, ApiService, SmartViewService, DocumentService } from "../../../../../../../api";
import { CustomDialog, DropDown } from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import { updateCaptureFreeze } from "../../../../../slice/document/document-slice";
import ButtonOption from "../../buttons/ButtonOption";
import SwitchOption from "../../buttons/SwitchOption";
import styles from "./LockDropDown.module.css";
import { hasReviewPermissions } from "../../../../../permissions/review-permissions-provider";
import REVIEW_PERMISSIONS from "../../../../../permissions/review-permissions";

const LockDropDown = React.forwardRef(({ open, onClose = null }, ref) => {
	const [unfreezeDialogOpen, setUnfreezeDialogOpen] = useState(false);
	const [validatedReqsCount, setValidatedReqsCount] = useState(0);
	const [lockedReqsCount, setLockedReqsCount] = useState(0);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const captureLocked = useSelector(({ srDocument }) => srDocument.isFrozen);
	const roles = useSelector(({ srDocument }) => srDocument.roles);
	const dispatch = useDispatch();
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (!open) {
			return;
		}
		Axios.all([
			SmartViewService.getValidatedReqsCount({ documentId }, cancelTokenSourceRef.current.token),
			SmartViewService.getLockedUnlockedReqsCount({ documentId }, cancelTokenSourceRef.current.token),
		])
			.then(
				Axios.spread((...data) => {
					setValidatedReqsCount(data[0].count);
					setLockedReqsCount(data[1].frozen);
				})
			)
			.catch((err) => {
				console.error(err);
			});
	}, [open, documentId]);

	const handleLockCapture = () => {
		DocumentService.freezeDocument({ docId: documentId }, cancelTokenSourceRef.current.token)
			.then(() => {
				dispatch(updateCaptureFreeze());
			})
			.catch((err) => console.error(err));
	};
	const handleFreezeValidated = () => {
		ValidatorService.freezeAllValidated({ docId: documentId }, cancelTokenSourceRef.current.token)
			.then(() => {
				window.location.reload();
			})
			.catch((err) => console.error(err));
	};
	const handleUnfreezeValidated = () => {
		ValidatorService.unfreezeAllValidated({ docId: documentId }, cancelTokenSourceRef.current.token)
			.then(() => {
				setUnfreezeDialogOpen(true);
			})
			.catch((err) => console.error(err));
	};
	const handleCloseUnfreezeDialog = () => {
		window.location.reload();
	};

	return (
		<>
			<DropDown ref={ref} open={open} onClose={onClose}>
				<div className={styles.dropDownContainer}>
					{hasReviewPermissions(REVIEW_PERMISSIONS.FREEZE_CAPTURE, { roles }) && (
						<SwitchOption
							isChecked={captureLocked}
							text={translate("smart-review.lock-drop-down.lock-capture")}
							onChange={handleLockCapture}
						/>
					)}
					<ButtonOption
						buttonDisabled={validatedReqsCount === 0}
						buttonText={translate("smart-review.lock-drop-down.button.lock-them")}
						text={translate("smart-review.lock-drop-down.validated-reqs", { nbReqs: validatedReqsCount })}
						onButtonClick={handleFreezeValidated}
					/>
					<ButtonOption
						buttonDisabled={lockedReqsCount === 0}
						buttonText={translate("smart-review.lock-drop-down.button.unlock-all")}
						text={translate("smart-review.lock-drop-down.locked-reqs", { nbReqs: lockedReqsCount })}
						onButtonClick={handleUnfreezeValidated}
					/>
				</div>
			</DropDown>
			<CustomDialog
				closeLabel={translate("common:btn.close")}
				maxWidth="xs"
				open={unfreezeDialogOpen}
				subTitle={translate("smart-review.list-pdf-images.unfreeze-dialog.content")}
				title={translate("smart-review.list-pdf-images.unfreeze-dialog.title")}
				onClose={handleCloseUnfreezeDialog}
			/>
		</>
	);
});

export default LockDropDown;
