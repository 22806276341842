import React, { useState } from "react";
import { translate } from "../../../../common/providers";
import {
	BlockTitle,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableRow,
	IconComponent,
	icon,
} from "../../../../common/components";
import styles from "./CompaniesList.module.css";

function getCompanyHasChildren(data) {
	const result = [];
	for (const item of data) {
		if (item.children && item.children.length > 0) {
			result.push(item.id);
			result.push(...getCompanyHasChildren(item.children));
		}
	}
	return result;
}

export default function CompaniesList({ companies = [], onClickRow, selectedCompany = null }) {
	const [expandedRows, setExpandedRows] = useState(getCompanyHasChildren(companies));

	const toggleRowExpansion = (companyId) => {
		if (expandedRows.includes(companyId)) {
			setExpandedRows(expandedRows.filter((id) => id !== companyId));
		} else {
			setExpandedRows([...expandedRows, companyId]);
		}
	};
	const renderCompanyRows = (company, level = 0) => (
		<>
			{company.userRole === "ADMIN" && (
				<CustomTableRow
					key={company.id}
					hover
					className={selectedCompany === company ? styles["tableRow--selected"] : styles.tableRow}
					onClick={() => {
						if (company.children) {
							toggleRowExpansion(company.id);
						}
						onClickRow(company);
					}}
				>
					<CustomTableCell style={{ paddingLeft: `${16 + level * 16}px` }}>
						{company.children && (
							<IconComponent
								color="var(--color-dark-grey-1)"
								icon={expandedRows.includes(company.id) ? icon.faChevronDown : icon.faChevronRight}
								size="xs"
								translate="no"
							/>
						)}
						<span className={company?.children ? styles.parentName : styles.childName}>
							{company.shortName}
						</span>
					</CustomTableCell>
				</CustomTableRow>
			)}
			{Array.isArray(company.children) &&
				expandedRows.includes(company.id) &&
				company.children.map((childCompany) => renderCompanyRows(childCompany, level + 1))}
		</>
	);

	return (
		<>
			<BlockTitle>{translate("admin.company-list.title")}</BlockTitle>
			<CustomTable>
				<CustomTableBody>
					{Array.isArray(companies) && companies.map((company) => renderCompanyRows(company))}
				</CustomTableBody>
			</CustomTable>
		</>
	);
}
