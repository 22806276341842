import React, { useState } from "react";
import { CustomButton, SidePanel, SidePanelContent, SidePanelContainer } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { CustomNorm, NormList, NormDetailsSidePanel } from "..";

export default function AddNorm({ onClose, onAddNorm, onCreateNorm, onActive: onActiveFromParent }) {
	const [openCreateNormPanel, setOpenCreateNormPanel] = useState(false);
	const [openDetails, setOpenDetails] = useState(null);
	const handleOpenCreateNormPanel = () => setOpenCreateNormPanel(true);
	const handleCloseCreateNormPanel = () => setOpenCreateNormPanel(false);
	const handleCreateNorm = (payload) => {
		handleCloseCreateNormPanel();
		onCreateNorm(payload);
	};
	const handleShowDetails = (normId, normName) => setOpenDetails({ normId, normName });
	const handleCloseDetails = () => setOpenDetails(null);
	return (
		<SidePanelContent
			secondaryActions={
				<CustomButton color="primary" variant="contained" onClick={handleOpenCreateNormPanel}>
					{translate("norms-center.add-norm.create-norm")}
				</CustomButton>
			}
			title={translate("norms-center.add-norm")}
			onClose={onClose}
		>
			<SidePanelContainer>
				<h5>{translate("norms-center.add-norm.panel-title")}</h5>
				<NormList
					onCreateCustomNorm={handleOpenCreateNormPanel}
					onSelectRow={onAddNorm}
					onShowDetails={handleShowDetails}
				/>
			</SidePanelContainer>
			<SidePanel
				open={openCreateNormPanel}
				size={65}
				onActive={onActiveFromParent}
				onClose={handleCloseCreateNormPanel}
			>
				<CustomNorm onClose={handleCloseCreateNormPanel} onCreate={handleCreateNorm} />
			</SidePanel>
			<NormDetailsSidePanel
				normId={openDetails?.normId || ""}
				normName={openDetails?.normName || ""}
				open={!!openDetails}
				onActive={onActiveFromParent}
				onClose={handleCloseDetails}
			/>
		</SidePanelContent>
	);
}
