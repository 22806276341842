import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, CircularProgress, MenuItem, MenuList, TextField } from "@mui/material";
import styles from "./MyReviewRedressmentModal.module.css";
import { CustomButton, CustomIconButton, CustomMenuButton, IconComponent, icon } from "../../../../common/components";
import { ApiService, RedressmentService } from "../../../../api";
import { translate } from "../../../../common/providers";

const MyReviewRedressmentModal = ({ isOpen, onClose }) => {
	const [rows, setRows] = useState([{ start: "", end: "", table: false }]);
	const [isLoading, setIsLoading] = useState(false);
	const docId = useSelector(({ srDocument }) => srDocument.documentId);
	const menuButtonRef = useRef(null);
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	const handleInputChange = (index, event) => {
		const { name, value, type, checked } = event.target;
		const newValue = type === "checkbox" ? checked : value;

		const updatedRows = [...rows];
		updatedRows[index] = {
			...updatedRows[index],
			[name]: newValue,
		};

		setRows(updatedRows);
	};
	const handleAddRow = () => {
		setRows([...rows, { start: "", end: "", table: false }]);
	};
	const handleClearRows = () => {
		setRows([{ start: "", end: "", table: false }]);
	};

	const rowsToPayload = (dryRun = true) => {
		const payload = {
			dryRun,
			redressements: [],
		};

		// Filter out empty values from each row
		for (const row of rows) {
			const redressment = {};
			if (row.table) {
				redressment.requirementType = "TABLE_ONE_LINE_PER_REQ";
				if (row.start.length > 0) {
					redressment.startMarker = row.start;
					if (row.end.length > 0) {
						redressment.endMarker = row.end;
					}
				}
			} else if (row.start.length > 0) {
				redressment.startMarker = row.start;
				if (row.end.length > 0) {
					redressment.endMarker = row.end;
					redressment.requirementType = "START_AND_END_MARKER";
				} else {
					redressment.requirementType = "START_MARKER_ONLY";
				}
			}
			if (Object.keys(redressment).length > 1) {
				payload.redressements.push(redressment);
			}
		}
		return payload;
	};
	const handleDryRun = () => {
		setIsLoading(true);
		RedressmentService.compute({ docId }, rowsToPayload(), cancelTokenSourceRef.current.token)
			.then((data) => {
				rows.forEach((row) => {
					row.number = data.detectedRequirementsCount[row.start] ?? 0;
				});
				setRows(rows);
				setIsLoading(false);
			})
			.catch((err) => console.error(err));
	};
	const handleSubmit = (event) => {
		setIsLoading(true);
		event.preventDefault();
		RedressmentService.compute({ docId }, rowsToPayload(false), cancelTokenSourceRef.current.token)
			.then(() => window.location.reload())
			.catch((err) => {
				console.error(err);
				setIsLoading(false);
			});
	};
	const handleClear = (requirementType) => {
		setIsLoading(true);
		if (menuButtonRef.current) {
			menuButtonRef.current.onClose();
		}
		const payload = {
			requirementType,
		};
		RedressmentService.clearRequirements({ docId }, payload, cancelTokenSourceRef.current.token)
			.then(() => window.location.reload())
			.catch((err) => {
				console.error(err);
				setIsLoading(false);
			});
	};

	if (!isOpen) {
		return null;
	}

	return (
		<div className={styles.modal}>
			<div className={styles.modalContent}>
				<CustomIconButton
					className={styles.close}
					data-testid="panel.close.btn"
					icon={icon.faTimes}
					onClick={onClose}
				/>
				<h3>{translate("smart-review.requirement-redressment.modal.title")}</h3>
				<div className={styles.tableContainer}>
					<table className={styles.table}>
						<thead>
							<tr>
								<th>{translate("smart-review.requirement-redressment.modal.start")}</th>
								<th>{translate("smart-review.requirement-redressment.modal.end")}</th>
								<th className={styles.limitWidth}>Table?</th>
								<th className={styles.limitWidth}>#</th>
							</tr>
						</thead>
						<tbody>
							{rows.map((row, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<tr key={index}>
									<td>
										<TextField
											name="start"
											size="small"
											value={row.start}
											onChange={(event) => handleInputChange(index, event)}
										/>
									</td>
									<td>
										<TextField
											name="end"
											size="small"
											value={row.end}
											onChange={(event) => handleInputChange(index, event)}
										/>
									</td>
									<td className={styles.flexCenter}>
										<Checkbox
											checked={row.table}
											data-is-checked={row.table}
											name="table"
											size="small"
											onClick={(event) => handleInputChange(index, event)}
										/>
									</td>
									<td className={styles.limitWidth}>{row.number}</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className={styles.buttonsContainer}>
						<CustomButton color="secondary" size="sm" variant="contained" onClick={handleAddRow}>
							{translate("smart-review.requirement-redressment.modal.add-row")}
						</CustomButton>
						<CustomButton color="secondary" size="sm" variant="contained" onClick={handleClearRows}>
							{translate("smart-review.requirement-redressment.modal.clear-rows")}
						</CustomButton>
						<CustomButton color="secondary" size="sm" variant="contained" onClick={handleDryRun}>
							{translate("smart-review.requirement-redressment.modal.dry-run")}
						</CustomButton>
						{isLoading ? <CircularProgress size={25} /> : null}
					</div>
				</div>
				<div className={styles.buttonsContainer}>
					<CustomButton color="primary" size="sm" variant="contained" onClick={handleSubmit}>
						{translate("smart-review.requirement-redressment.modal.submit")}
					</CustomButton>
					<CustomMenuButton
						ref={menuButtonRef}
						color="primary"
						size="sm"
						text={
							<>
								{translate("smart-review.requirement-redressment.modal.clear-requirements")}
								<IconComponent color="primary" icon={icon.faChevronDown} />
							</>
						}
						variant="contained"
					>
						<MenuList className={styles.menuList}>
							<MenuItem onClick={() => handleClear("EXPLICIT")}>
								{translate("smart-review.requirement-redressment.modal.requirement-type.explicit")}
							</MenuItem>
							<MenuItem onClick={() => handleClear("IMPLICIT")}>
								{translate("smart-review.requirement-redressment.modal.requirement-type.implicit")}
							</MenuItem>
							<MenuItem onClick={() => handleClear("BOTH")}>
								{translate("smart-review.requirement-redressment.modal.requirement-type.both")}
							</MenuItem>
						</MenuList>
					</CustomMenuButton>
				</div>
			</div>
		</div>
	);
};

export default MyReviewRedressmentModal;
