import React, { useState } from "react";
import { Checkbox } from "@mui/material";
import { CustomAccordion, CustomDialog, CustomTooltip } from "../../../../../../../../../common/components";
import { isNonEmptyArray } from "../../../../../../../../../common/utils";
import styles from "./BulkDialog.module.css";
import { useApi } from "../../../../../../../../../common/hooks";
import { DeliverablesService } from "../../../../../../../../../api";
import { translate } from "../../../../../../../../../common/providers";

const BulkDialog = ({ open, onClose, onUpdateBulk, phasesTree, reqId }) => {
	const { call: bulkAllocateDeliverables } = useApi(DeliverablesService.bulkAllocateDeliverables);
	const [uncheckedIds, setUncheckedIds] = useState([]);
	const handleClose = () => {
		setUncheckedIds([]);
		onClose();
	};
	const handleCheck = (deliverableId, checked) => {
		if (checked) {
			setUncheckedIds((prev) => [...prev, deliverableId]);
		} else {
			setUncheckedIds((prev) => prev.filter((p) => p !== deliverableId));
		}
	};
	const handleSubmit = () => {
		let docOutToAdd = [];
		let docOutToRemove = [];
		phasesTree.forEach((phase) => {
			if (isNonEmptyArray(phase.deliverables)) {
				phase.deliverables.forEach((deliverable) => {
					if (
						(deliverable.isCovered === false || deliverable.isCovered) &&
						uncheckedIds.includes(deliverable.id)
					) {
						docOutToRemove = [...docOutToRemove, deliverable.id];
					} else if (deliverable.isCovered === undefined && !uncheckedIds.includes(deliverable.id)) {
						docOutToAdd = [...docOutToAdd, deliverable.id];
					}
				});
			}
		});
		bulkAllocateDeliverables(
			{ reqId },
			{
				docOutToRemove: isNonEmptyArray(docOutToRemove) ? docOutToRemove : undefined,
				docOutToAdd: isNonEmptyArray(docOutToAdd) ? docOutToAdd : undefined,
			}
		)
			.then(() => {
				handleClose();
				onUpdateBulk({ addedCount: docOutToAdd.length, removedCount: docOutToRemove.length });
			})
			.catch(console.error);
	};
	return (
		<CustomDialog
			open={open}
			title={translate("smart-review.cmp-details.option-panel.deliverables-tab.bulk-allocate")}
			onClose={handleClose}
			onSubmit={handleSubmit}
		>
			<div className={styles.listContainer}>
				{isNonEmptyArray(phasesTree) &&
					phasesTree.map((phase) => (
						<CustomAccordion
							key={phase.phaseId}
							defaultOpen
							className={styles.accordionContent}
							header={
								<CustomTooltip showWhenOverflow title={phase?.phaseName}>
									<div className={styles.line}>{phase?.phaseName}</div>
								</CustomTooltip>
							}
							iconColor="var(--color-light-grey-1)"
						>
							{isNonEmptyArray(phase.deliverables) &&
								phase.deliverables.map((deliverable) => {
									const isChecked = !uncheckedIds.includes(deliverable.id);
									return (
										<div className={styles.row}>
											<Checkbox
												checked={isChecked}
												onClick={() => handleCheck(deliverable.id, isChecked)}
											/>
											<div className={styles.row__content} translate="no">
												<CustomTooltip showWhenOverflow title={deliverable.name}>
													<div className={styles.row__documentName}>{deliverable.name}</div>
												</CustomTooltip>
												<CustomTooltip showWhenOverflow title={deliverable.ref}>
													<div className={styles.row__documentRef}>{deliverable.ref}</div>
												</CustomTooltip>
											</div>
										</div>
									);
								})}
						</CustomAccordion>
					))}
			</div>
			<div>{translate("smart-review.cmp-details.option-panel.deliverables-tab.bulk-helper")}</div>
		</CustomDialog>
	);
};

export default BulkDialog;
