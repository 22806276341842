import { Checkbox, CircularProgress, InputAdornment, TextField } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CategoryTemplateService, ApiService } from "../../../../api";
import { translate } from "../../../providers";
import { debounce, isNonEmptyArray, updateTreeBranch } from "../../../utils";
import { CustomIconButton } from "../../buttons";
import { I18nTranslate } from "../../i18n";
import { icon, IconComponent } from "../../icon-component";
import CategoryList from "../category-list/CategoryList";
import styles from "./CategoryTemplateSelectionList.module.css";
import { setDisableKeyEvents } from "../../../slice";
import { getParentsIdsList } from "../../../utils/category-utils";

const debouncedFunction = debounce((func) => func());

const getIdsRecursively = (array, result = []) => {
	if (!Array.isArray(array) || array.length === 0) {
		return null;
	}
	let temp = result;
	array.forEach((a) => {
		temp = [...temp, a.id];
		if (a.subThematic) {
			temp = getIdsRecursively(a.subThematic, temp);
		}
	});
	return temp;
};

export default function CategoryTemplateSelectionList({
	allowDefaultChanges = false,
	allowPreExpand,
	availableParents,
	canSelectAll = true,
	checkChildren = false,
	defaultCheckedIds = [],
	includeNone = false,
	independantSelection = false,
	isEditable = false,
	onChangeCheckedIds,
	returnCatOnSelection = false,
	onSetHasCategory = null,
	singleSelection = false,
}) {
	const [checkedIds, setCheckedIds] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [expandedIds, setExpandedIds] = useState([]);
	const [initialSelectionApplied, setInitialSelectionApplied] = useState(false);
	const [totalCategoriesCount, setTotalCategoriesCount] = useState(0);
	const [categorySearch, setCategorySearch] = useState("");
	const [categorySearchDebounced, setCategorySearchDebounced] = useState("");
	const cancelTokenSourceRef = useRef(null);
	const [categories, setCategories] = useState([]);
	const dispatch = useDispatch();
	const getIdsToExpand = useCallback(() => {
		const categoriesToExpand = [];
		defaultCheckedIds.forEach((checkedId) => {
			const tempParentIds = getParentsIdsList(categories, { id: checkedId });
			tempParentIds.forEach((tempParentId) => {
				if (checkedId !== tempParentId && !categoriesToExpand.includes(tempParentId)) {
					categoriesToExpand.push(tempParentId);
				}
			});
		});
		return categoriesToExpand;
	}, [categories, defaultCheckedIds]);
	useEffect(() => {
		if (allowDefaultChanges && isNonEmptyArray(categories) && !initialSelectionApplied) {
			setCheckedIds(JSON.parse(JSON.stringify(defaultCheckedIds)));
			if (allowPreExpand) {
				const categoriesToExpand = getIdsToExpand();
				if (isNonEmptyArray(categoriesToExpand)) {
					setExpandedIds(categoriesToExpand);
				}
			}
			setInitialSelectionApplied(true);
		}
	}, [allowDefaultChanges, defaultCheckedIds, categories, allowPreExpand, getIdsToExpand, initialSelectionApplied]);

	const initializeRoots = useCallback(() => {
		setCheckedIds([...defaultCheckedIds]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadCategories = useCallback(
		(search) => {
			setIsLoading(true);
			if (search) {
				CategoryTemplateService.getTreeForCompany({ search }, cancelTokenSourceRef.current.token)
					.then((data) => {
						setCategories(data.thematics);
						setTotalCategoriesCount(data.count);
						if (onSetHasCategory) {
							onSetHasCategory(data.count > 0);
						}
					})
					.catch((err) => {
						console.error(err);
					})
					.finally(() => setIsLoading(false));
			} else if (availableParents) {
				setCategories(availableParents);
				setIsLoading(false);
			} else {
				CategoryTemplateService.getTreeParentsForCompany(cancelTokenSourceRef.current.token)
					.then((data) => {
						setCategories(data);
						if (onSetHasCategory) {
							onSetHasCategory(data.length > 0);
						}
					})
					.catch((err) => {
						console.error(err);
					})
					.finally(() => setIsLoading(false));
				CategoryTemplateService.getCountOfAllTreeForCompany(cancelTokenSourceRef.current.token)
					.then((data) => {
						setTotalCategoriesCount(data.count);
					})
					.catch((err) => {
						console.error(err);
					})
					.finally(() => setIsLoading(false));
			}
		},
		[onSetHasCategory, availableParents]
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		initializeRoots();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [initializeRoots]);
	useEffect(() => {
		loadCategories(categorySearchDebounced);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categorySearchDebounced]);

	const handleResetSearchText = () => {
		setCategorySearch("");
		setCategorySearchDebounced("");
	};
	const handleChangeSearchText = (event) => {
		const { value } = event.target;
		setCategorySearch(value);
		setIsLoading(true);
		debouncedFunction(() => {
			setIsLoading(false);
			setCategorySearchDebounced(value);
		});
	};
	const handleCheck = (checked, checkedCategory) => {
		if (singleSelection) {
			setCheckedIds([checkedCategory.id]);
			if (returnCatOnSelection) {
				onChangeCheckedIds(checkedCategory);
			} else {
				onChangeCheckedIds([checkedCategory.id]);
			}
			return;
		}
		CategoryTemplateService.getAllChildrenOfParentForCompany(
			{ parentId: checkedCategory.id },
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				const childrenIdsList = [checkedCategory.id, ...data.ids];
				let newCheckedIds = [];
				let extraCheckedIds = [];
				if (checked) {
					extraCheckedIds = [checkedCategory.id];
					if (checkChildren) {
						extraCheckedIds = [...extraCheckedIds, ...childrenIdsList];
					}
					const result = checkedIds.concat(extraCheckedIds);
					newCheckedIds = result.filter((cat, index) => result.indexOf(cat) === index);
				} else {
					extraCheckedIds = independantSelection ? [checkedCategory.id] : childrenIdsList;
					newCheckedIds = checkedIds.filter(
						(prevId) => !extraCheckedIds.some((childId) => childId === prevId)
					);
				}
				setCheckedIds(newCheckedIds);
				onChangeCheckedIds(newCheckedIds);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => setIsLoading(false));
	};
	const handleCheckAll = () => {
		if (checkedIds.length > 0) {
			setCheckedIds([]);
			onChangeCheckedIds([]);
		} else if (categorySearch) {
			let tempCheckedIds = [...checkedIds];
			getIdsRecursively(categories).forEach((subCategoryId) => {
				if (!tempCheckedIds.includes(subCategoryId)) {
					tempCheckedIds = [...tempCheckedIds, subCategoryId];
				}
			});
			setCheckedIds(tempCheckedIds);
			onChangeCheckedIds(tempCheckedIds);
		} else {
			CategoryTemplateService.selectAllTreeForCompany(cancelTokenSourceRef.current.token)
				.then((data) => {
					setCheckedIds(data.ids);
					onChangeCheckedIds(data.ids);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => setIsLoading(false));
		}
	};
	const handleExpand = (expanded, category) => {
		if (!expanded && (!Array.isArray(category.subThematic) || category.subThematic?.length === 0) && category.id) {
			CategoryTemplateService.getTreeChildrenForCompany(
				{ parentId: category.id },
				cancelTokenSourceRef.current.token
			)
				.then((data) => {
					setCategories((prev) =>
						updateTreeBranch({
							tree: prev,
							idToReplace: category.id,
							newBranch: {
								...category,
								subThematic: data,
							},
							iterativeBranchName: "subThematic",
						})
					);
				})
				.catch((err) => console.error(err));
		}
	};
	return (
		<div className={styles.container}>
			<div>
				<I18nTranslate
					param={{
						totalCategoriesCount,
						selectedCategoriesCount: checkedIds.length,
					}}
					translationKey={
						checkedIds.length > 1
							? "common:template-selection.counter"
							: "common:template-single-selection.counter"
					}
				/>
			</div>
			<TextField
				autoFocus
				fullWidth
				data-testid="category-template.search"
				InputProps={{
					className: styles.inputProps,
					endAdornment: (
						<InputAdornment position="end">
							{isLoading && <CircularProgress size={25} thickness={3} />}
							{!isLoading && !categorySearch && <IconComponent icon={icon.faSearch} />}
							{!isLoading && categorySearch && (
								<CustomIconButton icon={icon.faTimesCircleRegular} onClick={handleResetSearchText} />
							)}
						</InputAdornment>
					),
				}}
				placeholder={translate("common:categories.import-panel.search")}
				size="small"
				value={categorySearch}
				variant="outlined"
				onBlur={() => dispatch(setDisableKeyEvents(false))}
				onChange={handleChangeSearchText}
				onFocus={() => dispatch(setDisableKeyEvents(true))}
			/>
			<div className={`${styles.list} ${(isLoading && styles.loadingContent) || ""}`}>
				{(Array.isArray(categories) && categories.length > 0 && (
					<>
						{canSelectAll && (
							<div className={styles.selectAll}>
								<Checkbox
									checked={checkedIds.length === totalCategoriesCount}
									indeterminate={checkedIds.length > 0 && checkedIds.length < totalCategoriesCount}
									onChange={handleCheckAll}
								/>
								{translate("common:btn.select-all")}
							</div>
						)}
						<CategoryList
							categoryList={categories}
							checkedIds={checkedIds}
							editable={isEditable}
							expandedIds={expandedIds}
							includeNone={includeNone}
							singleSelection={singleSelection}
							onCheck={handleCheck}
							onExpand={handleExpand}
						/>
					</>
				)) || (
					<div className={styles.emptyState}>
						<IconComponent
							className={styles.emptyStateIcon}
							color="var(--color-blue)"
							icon={icon.faInfoCircle}
						/>
						{translate("common:categories.import-panel.empty-state")}
					</div>
				)}
			</div>
		</div>
	);
}
