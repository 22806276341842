import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./RequirementLayer.module.css";
import { checkReqValMode, checkReq } from "../../../../../utils/utils";
import { translate } from "../../../../../../../common/providers";
import { icon, CustomIconButton } from "../../../../../../../common/components";
import { SENTENCE_TYPE } from "../../../../../constants/constants";
import { setSelectedBlock } from "../../../../../slice/document/document-slice";
import { ParameterSublayer, ClientSublayer, ActionSublayer } from ".";

export default function RequirementLayer({ top, left, hoveredBlock, page, onUpdateHoveredArea }) {
	const dispatch = useDispatch();
	const [show, setShow] = useState(true);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const redressingInformation = useSelector(({ srDocument }) => srDocument.redressingInformation);
	const isSelecting = useSelector(({ srDocument }) => srDocument.isSelecting);
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const handleClickInformation = () => {
		dispatch(setSelectedBlock(hoveredBlock));
	};
	useEffect(() => {
		const handleKeydown = (e) => {
			if ((e.ctrlKey || e.metaKey || e.shiftKey) && show) {
				setShow(false);
			}
		};
		const handleKeyup = (e) => {
			if (!e.ctrlKey && !e.metaKey && !e.shiftKey) {
				setShow(true);
			}
		};
		document.addEventListener("keydown", handleKeydown);
		document.addEventListener("keyup", handleKeyup);
		return () => {
			document.removeEventListener("keydown", handleKeydown);
			document.removeEventListener("keyup", handleKeyup);
		};
	}, [show]);
	return (
		<>
			{show &&
				hoveredBlock &&
				hoveredBlock.pageStart === page &&
				checkReqValMode(mode) &&
				!redressingInformation &&
				!isSelecting &&
				selectedRectangles.length <= 1 && (
					<div
						className={styles["overlay-hover__main-container"]}
						id="overlay"
						style={{
							top: top - 42,
							left,
						}}
					>
						{checkReq(hoveredBlock) && (
							<CustomIconButton
								icon={icon.faInfoCircle}
								tooltip={translate(
									(hoveredBlock?.sentenceClassifySource === "MANUAL" &&
										hoveredBlock?.sentenceType !== SENTENCE_TYPE.INFORMATION &&
										"smart-review.cmp-display-overlay.tooltip.title.this-was-originally-an-information") ||
										"smart-review.cmp-display-overlay.tooltip.title.display-requirement-parameters"
								)}
								onClick={handleClickInformation}
							/>
						)}
						<ClientSublayer hoveredBlock={hoveredBlock} />
						<ParameterSublayer hoveredBlock={hoveredBlock} onUpdateHoveredArea={onUpdateHoveredArea} />
						<ActionSublayer
							className={styles["overlay-click"]}
							hoveredBlock={hoveredBlock}
							onUpdateHoveredArea={onUpdateHoveredArea}
						/>
					</div>
				)}
		</>
	);
}
