import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Timeline } from "@mui/lab";
import { SidePanel, SidePanelContent, InfiniteList2 } from "../../../../../../../common/components";
import styles from "./RatingHistoryPanel.module.css";
import { translate } from "../../../../../../../common/providers";
import { RatingHistoryPanelRow } from "..";
import { ApiService, GonogoSynthesisService } from "../../../../../../../api";
import { distanceDate } from "../../../../../../../common/utils";
import { TIME_UNITS } from "../../../../../../../common/constants";

export default function RatingHistoryPanel({ open, onClose, customStyles, onActive: onActiveFromParent = null }) {
	const currentCategory = useSelector(({ gonogo }) => gonogo.currentCategory);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		if (currentCategory?.id) {
			setInfiniteListRequest(
				() =>
					({ limit, page }) =>
						GonogoSynthesisService.getNoteHistory(
							{ categoryId: currentCategory.id },
							{ page, limit },
							cancelTokenSourceRef.current.token
						)
							.then((data) => {
								const options = [
									TIME_UNITS.TODAY,
									TIME_UNITS.YESTERDAY,
									TIME_UNITS.THIS_MONTH,
									TIME_UNITS.LAST_MONTH,
									TIME_UNITS.START_OF_YEAR,
									TIME_UNITS.LAST_YEAR,
									TIME_UNITS.LONG,
								];
								const contents = [...data.contents];
								const possibilities = [
									{ label: translate("common:date.today") },
									{ label: translate("common:date.yesterday") },
									{ label: translate("common:date.this-month") },
									{ label: translate("common:date.last-month") },
									{ label: translate("common:date.start-of-year") },
									{ label: translate("common:date.last-year") },
									{ label: translate("common:date.long-time-ago") },
								];
								possibilities.forEach((item) => {
									const id = contents.findIndex(
										(x) => distanceDate({ date: x.date, options }) === item.label
									);
									if (id > -1) {
										item.id = id;
									}
								});
								return {
									...data,
									contents: data.contents.map((x, i) =>
										possibilities.some((y) => y?.id === i)
											? { ...x, distance: distanceDate({ date: x.date, options }) }
											: x
									),
								};
							})
							.catch((err) => console.error(err))
			);
		}
	}, [currentCategory]);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	return (
		<SidePanel open={open} size={40} onActive={onActiveFromParent} onClose={onClose}>
			{({ onActive }) => (
				<SidePanelContent
					title={translate("gonogo.details.history.panel.title")}
					onActive={onActive}
					onClose={onClose}
				>
					<div className={styles["side-panel__content"]}>
						<div className={styles["side-panel__header"]}>{currentCategory?.name}</div>
						<Timeline align="left" classes={{ root: styles.timeline }}>
							<InfiniteList2
								anticipate
								request={infiniteListRequest}
								rowRenderer={(row) => (
									<RatingHistoryPanelRow customStyles={customStyles} modification={row} />
								)}
							/>
						</Timeline>
					</div>
				</SidePanelContent>
			)}
		</SidePanel>
	);
}
