import { convertFormatDateToDisplayDate } from "../utils/time-utils";

const regexp = /\${(.*?)\}/g;
const fallbackLanguage = "en";
let commonTranslations;
let backendTranslations;
let navigationTranslations;
let viewTranslations;
let language = fallbackLanguage;

export const translateDate = (date) => {
	let lang = language;
	if (lang === "en") {
		lang += "-GB";
	}
	const slicedDate = convertFormatDateToDisplayDate(date);
	if (slicedDate.toString() !== "Invalid Date") {
		return new Intl.DateTimeFormat(lang).format(slicedDate).replaceAll("/", ".");
	}
	return "-";
};

function replaceParams(translation, groups, params) {
	if (!Array.isArray(groups) || groups.length === 0 || !params || typeof params !== "object") {
		return translation;
	}
	let tmpTranslation = translation;
	for (let i = 0, size = groups.length; i < size; ++i) {
		const placeholder = groups[i][0] || "";
		const placeholderName = groups[i][1] || "";
		let replaceValue = placeholder ?? "";
		if (placeholder && placeholderName) {
			replaceValue = params[placeholderName] ?? "";
		}
		if (placeholderName.startsWith("trsDate")) {
			replaceValue = translateDate(replaceValue);
		}
		tmpTranslation = tmpTranslation.replace(placeholder, replaceValue);
	}
	return tmpTranslation;
}

export function translate(key, params) {
	if (!key) {
		return "#INVALID";
	}
	let translation;
	if (key.startsWith("navigation:")) {
		translation = navigationTranslations?.[key] || "";
	} else if (key.startsWith("common:")) {
		translation = commonTranslations?.[key] || "";
	} else if (key.startsWith("backend:")) {
		translation = backendTranslations?.[key] || "";
	} else {
		translation = viewTranslations?.[key] || "";
	}
	if (!translation) {
		return key;
	}
	if (translation.search(regexp) >= 0) {
		const groups = [...translation.matchAll(regexp)];
		translation = replaceParams(translation, groups, params);
	}
	return translation;
}

export const loadTranslation = async (lang) => {
	language = lang;
	try {
		const promiseCommonTranslations = import("../locales/common-translations/translation");
		const promiseBackendTranslations = import("../locales/backend-translations/translation");
		const commonTrs = (await promiseCommonTranslations) || {};
		const backendTrs = (await promiseBackendTranslations) || {};
		commonTranslations = commonTrs?.default?.[lang] || null;
		backendTranslations = backendTrs?.default?.[lang] || null;
	} catch (err) {
		console.error(err);
		throw new Error(`Error while loading translations${err}`);
	}
};

export const loadNavTranslation = async (lang) => {
	language = lang;
	try {
		const promiseNavigationTranslations = import("../../navigation/locales/translation");
		const navigationTrs = (await promiseNavigationTranslations) || {};
		navigationTranslations = navigationTrs?.default?.[lang] || null;
	} catch (err) {
		console.error(err);
		throw new Error(`Error while loading nav translations${err}`);
	}
};

export const loadViewTranslation = async (lang, localesPath) => {
	language = lang;
	if (!localesPath) {
		throw new Error("Missing view localesPath");
	}
	try {
		const viewTrs = (await import(`../../views${localesPath}/translation`)) || {};
		viewTranslations = { ...viewTranslations, ...(viewTrs?.default?.[lang] || {}) };
	} catch (err) {
		console.error(err);
		throw new Error(`Error while loading view translations${err}`);
	}
};

export const addTranslations = (addtionnalTranslations, lang) => {
	language = lang;
	viewTranslations = { ...(viewTranslations || {}), ...addtionnalTranslations[lang] };
	return viewTranslations;
};

export const translateEnum = (type, value, param = {}) =>
	translate(`common:enum.${type}.${value.replaceAll("_", "-")}`, param);

export const translateElapsed = ({ value, unitKey }) =>
	translate("common:elapsed-time", {
		unit: translateEnum("time-unit", `${unitKey?.toLowerCase()}${value > 1 ? ".plural" : ".singular"}`),
		value,
	});

export const translateEnumParam = (param, value = "") => {
	const pFormatted = param?.toLowerCase();
	const vFormatted = value?.toLowerCase();
	return translateEnum("params", value ? `${pFormatted}.${vFormatted}` : pFormatted);
};
export const translateEnumParamTooltip = (param, value = "") =>
	translateEnumParam(param, value ? `${value}.tooltip` : "tooltip");
export const translateEnumStatus = (value) => translateEnum("status", value?.toLowerCase());
export const translateEnumProjectRole = (value) => translateEnum("project-role", value?.toLowerCase());
export const translateEnumProjectRoleDescription = (value) =>
	translateEnum("project-role.description", value?.toLowerCase());
export const translateEnumDocumentStatus = (value) => translateEnum("document-status", value?.toLowerCase());
export const translateEnumDocumentUserRole = (value) => translateEnum("document.user-role", value?.toLowerCase());
export const translateEnumCoverageStatus = (value) => translateEnum("coverage-status", value?.toLowerCase());
export const translateEnumQuestionStatus = (value) => translateEnum("question-status", value?.toLowerCase());
export const translateEnumTemplatetype = (value, param) => translateEnum("template-type", value?.toLowerCase(), param);
export const translateEnumKeywordTitle = (value) => translateEnum("keywords.title", value?.toLowerCase());
export const translateEnumInformationAttribute = (value) =>
	translateEnum("filters.information-attribute", value?.toLowerCase());
export const translateEnumContentType = (value) => translateEnum("filters.content-type", value?.toLowerCase());
export const translateEnumClassification = (value) => translateEnum("filters", `req-${value?.toLowerCase()}.title`);
export const translateEnumVersionStatus = (value) => translateEnum("version-status", value?.toLowerCase());
export const translateEnumSearchCardType = (value) => translateEnum("search-card-type", value?.toLowerCase());
export const translateEnumExportDialog = (value) => translateEnum("export-dialog", value?.toLowerCase());
export const translateEnumNotificationFrequency = (value) =>
	translateEnum("notification-settings.frequency", value?.toLowerCase());
