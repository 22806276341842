import client from "../../client/client";

class FeatureManagementService {
	updateFeatureDescription({ featureId }, payload, cancelToken) {
		return client.put(`/backoffice/features/${featureId}`, { payload, config: { cancelToken } });
	}

	getFeaturePackages({ featureId }, cancelToken) {
		return client.get(`/backoffice/features/${featureId}/packages`, { config: { cancelToken } });
	}

	updateFeaturePackages({ featureId }, payload, cancelToken) {
		return client.put(`/backoffice/features/${featureId}/packages`, { payload, config: { cancelToken } });
	}

	getActiveFeatureProjects({ featureId }, cancelToken) {
		return client.get(`/backoffice/features/${featureId}/projects`, { config: { cancelToken } });
	}

	updateActiveFeatureProjects({ featureId }, payload, cancelToken) {
		return client.put(`/backoffice/features/${featureId}/projects`, { payload, config: { cancelToken } });
	}

	getAllPlatformFeatures({ page = 0, limit = 15 }, payload, cancelToken) {
		return client.post("/backoffice/features/all", { params: { page, limit }, payload, config: { cancelToken } });
	}
}
export default new FeatureManagementService();
