import React from "react";
import { useDispatch } from "react-redux";
import {
	CircularLoaderWithBackground,
	CustomIconButton,
	CustomTooltip,
	icon,
	IconComponent,
} from "../../../../../common/components";
import { isNumber, matchTypeIcon } from "../../../../../common/utils";
import styles from "./UploadFile.module.css";
import { translate } from "../../../../../common/providers";
import { deleteImport } from "../../../slice/document-center-slice";

export default function UploadFile({ batchId, name, progression }) {
	const dispatch = useDispatch();
	return (
		<div className={styles.file}>
			<div>
				<IconComponent color="var(--color-dark-grey-1)" icon={icon[matchTypeIcon({ name })]} size="md" />
			</div>
			<div className={styles.file__label}>
				<CustomTooltip arrow showWhenOverflow title={name || ""}>
					<span className={styles.file__name}>{name}</span>
				</CustomTooltip>
			</div>
			<div className={`${styles.file__loader} ${styles["file__loader--visible"]}`}>
				{isNumber(progression) && progression !== 100 ? (
					<CircularLoaderWithBackground
						size={24}
						thickness={7.2}
						value={progression || 0}
						variant="determinate"
					/>
				) : (
					<IconComponent
						color={(progression === 100 && "var(--color-green)") || "var(--color-red)"}
						icon={(progression === 100 && icon.faCheckCircle) || icon.faExclamationCircle}
						size="xl"
					/>
				)}
			</div>
			{!(isNumber(progression) && progression !== 100) && (
				<div className={`${styles.file__loader} ${styles["file__loader--hidden"]}`}>
					<CustomIconButton
						disableHovering
						btnClassName={styles.file__iconBtn}
						icon={icon.faTrash}
						iconColor="var(--color-light-grey-2)"
						tooltip={translate("document-center.upload-dialog.file.delete")}
						onClick={() => {
							dispatch(deleteImport({ name, batchId }));
						}}
					/>
				</div>
			)}
		</div>
	);
}
