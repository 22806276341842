import React from "react";
import { IconComponent, icon, CustomTooltip } from "../../..";
import styles from "./StatusIcons.module.css";
import { translate } from "../../../../providers";

export default function StatusIcons({ size = "md" }) {
	return (
		<div className={styles.iconsContainer}>
			<CustomTooltip title={translate("common:header.filter.status.unreviewed")}>
				<span className={styles.iconsContainer__container}>
					<IconComponent color="var(--color-blue)" icon={icon.faQuestionCircle} size={size} />
				</span>
			</CustomTooltip>
			<CustomTooltip title={translate("common:header.filter.status.yes")}>
				<span className={styles.iconsContainer__container}>
					<IconComponent color="var(--color-green)" icon={icon.faCheckCircle} size={size} />
				</span>
			</CustomTooltip>
			<CustomTooltip title={translate("common:header.filter.status.pending")}>
				<span className={styles.iconsContainer__container}>
					<IconComponent color="var(--color-orange)" icon={icon.faClock} size={size} />
				</span>
			</CustomTooltip>
			<CustomTooltip title={translate("common:header.filter.status.no")}>
				<span className={styles.iconsContainer__container}>
					<IconComponent color="var(--color-red)" icon={icon.faTimesCircle} size={size} />
				</span>
			</CustomTooltip>
			<CustomTooltip title={translate("common:header.filter.status.not-me")}>
				<span className={styles.iconsContainer__container}>
					<IconComponent icon={icon.faNotEqual} size={size} />
				</span>
			</CustomTooltip>
			<span className={styles.iconsContainer__placeholder} />
		</div>
	);
}
