import React from "react";
import { connect } from "react-redux";
import { translate } from "../../../../common/providers";
import { DashboardService, ApiService } from "../../../../api";
import styles from "./PortfolioProjectList.module.css";
import PortfolioProjectListTable from "./portfolio-project-list-table/PortfolioProjectListTable";
import { BlockTitle } from "../../../../common/components";

const mapStateToProps = ({ context }) => ({
	companyId: context.company.id,
});

class PortfolioProjectList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: [],
		};
		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentDidMount() {
		this.getProjects();
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	getProjects() {
		const { companyId } = this.props;
		DashboardService.getProjectsInformation({ companyId }, this.cancelTokenSource.token)
			.then((data) => {
				this.setState({
					projects: data,
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	render() {
		const { projects } = this.state;
		return (
			<div className={styles.container}>
				<div className={styles["list-container"]}>
					<BlockTitle>{translate("dashboards.portfolio-project-list.company-dash")}</BlockTitle>
					<PortfolioProjectListTable projects={projects} />
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(PortfolioProjectList);
