import React, { useEffect, useRef } from "react";
import { ApiService } from "../../../../../../api";
import AutomaticDetectionsTable from "./automatic-detections-table/AutomaticDetectionsTable";

export default function AutomaticDetections({ content, isLoading, onSelectRow, onShowDetails }) {
	const cancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	return (
		<AutomaticDetectionsTable
			content={content || []}
			isLoading={isLoading}
			onSelectRow={onSelectRow}
			onShowDetails={onShowDetails}
		/>
	);
}
