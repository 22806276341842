import React, { useMemo } from "react";
import {
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	CustomTooltip,
	icon,
	IconComponent,
} from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import styles from "./DetailsTable.module.css";

export default function DetailsTable({ isCustom, normDetails, onFavorite }) {
	const headers = useMemo(
		() =>
			isCustom
				? [
						translate("norms-center.norm-details.details.ref"),
						translate("norms-center.norm-details.details.date"),
						translate("norms-center.norm-details.details.name"),
						translate("norms-center.norm-details.details.type"),
						"",
				  ]
				: [
						translate("norms-center.norm-details.details.ref"),
						translate("norms-center.norm-details.details.date"),
						translate("norms-center.norm-details.details.name"),
						"",
				  ],
		[isCustom]
	);
	return (
		<CustomTable>
			<CustomTableHead>
				<CustomTableRow>
					{headers.map((header) => (
						<CustomTableCell key={header}>{header}</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody>
				{Array.isArray(normDetails) &&
					normDetails.map((row) => (
						<CustomTableRow key={row.name}>
							<CustomTableCell>{row.name}</CustomTableCell>
							<CustomTableCell>{row.date?.replace("/", ".") || "-"}</CustomTableCell>
							<CustomTableCell>
								<CustomTooltip arrow showWhenOverflow title={row.title || ""}>
									<span className={styles.norms__name}>{row.title}</span>
								</CustomTooltip>
							</CustomTableCell>
							{row.type === "MANUAL" && (
								<CustomTableCell>
									<IconComponent icon={icon.faUserEdit} />{" "}
									{translate("norms-center.norm-details.details.type.custom")}
								</CustomTableCell>
							)}
							<CustomTableCell>
								<CustomIconButton
									icon={row.isFavorite ? icon.faStar : icon.faStarRegular}
									size="sm"
									onClick={onFavorite}
								/>
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
