import React from "react";
import { CategoriesTabView } from "./components";

export default function CategoriesTab({ disableEditing = false, informationId, pageStart, selectedMode }) {
	return (
		<CategoriesTabView
			disableEditing={disableEditing}
			informationId={informationId}
			pageStart={pageStart}
			selectedMode={selectedMode}
		/>
	);
}
