import React from "react";
import { translateDate, translate } from "../../../../../common/providers";
import CustomPaginationActionsTable from "./CustomPaginationActionsTable";
import { convertStatusDBtoInput } from "../../../utils/utils";

const processData = (history) => {
	if (!Array.isArray(history)) {
		return [];
	}
	return history.map((element) => {
		const column = {};
		column["1"] = element.authorName;
		if (element.type === "COMMENT") {
			column["2"] = element.comment;
		} else {
			column["2"] = translate("smart-review.parameters.comment-history.changed-status", {
				status: convertStatusDBtoInput(element.status).toUpperCase(),
			});
		}
		column["3"] = translateDate(element.date);
		return column;
	});
};

const CommentHistory = ({ commentHistory }) => (
	<CustomPaginationActionsTable
		columns={[
			{ col: "20%", title: translate("smart-review.parameters.comment-history.author") },
			{ col: "60%", title: translate("smart-review.parameters.comment-history.comment") },
			{ col: "20%", title: translate("smart-review.parameters.comment-history.date") },
		]}
		data={(commentHistory && processData(commentHistory.reverse())) || []}
	/>
);

export default CommentHistory;
