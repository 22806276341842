import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Stack } from "@mui/material";
import React from "react";
import { translate } from "../../../providers";
import styles from "./CustomDatePicker.module.css";

const CustomDatePicker = ({
	className = "",
	clearable = false,
	error = false,
	fullWidth = false,
	inputFormat = "dd/MM/yyyy",
	label = "",
	minDate = "",
	onBlur,
	onChange,
	onFocus,
	value = new Date(),
}) => (
	<div className={`${styles.datePicker} ${fullWidth ? styles["datePicker--fullWidth"] : ""} ${className}`}>
		<Stack>
			<DatePicker
				cancelText={translate("common:btn.cancel")}
				clearable={clearable}
				clearText={translate("common:btn.clear-select")}
				componentsProps={{ textField: { variant: "outlined", onFocus, onBlur } }}
				error={error}
				inputFormat={inputFormat}
				label={label}
				minDate={minDate}
				okText={translate("common:btn.ok")}
				value={value}
				onChange={onChange}
			/>
		</Stack>
	</div>
);

export default CustomDatePicker;
