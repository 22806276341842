import React from "react";
import { TextField } from "@mui/material";
import {
	CustomButton,
	GenericDialog,
	GenericDialogContent,
	GenericDialogFooter,
	GenericDialogHeader,
} from "../../../../../common/components";
import styles from "./EditNameDialog.module.css";
import { translate } from "../../../../../common/providers";

export default function EditNameDialog({
	cancelLabel = "",
	label = "",
	onClose,
	onSubmit,
	onChange,
	open,
	submitLabel = "",
	title,
	value = "",
}) {
	return (
		<GenericDialog fullWidth open={open} onClose={onClose}>
			<GenericDialogHeader onClose={onClose}>{title}</GenericDialogHeader>
			<GenericDialogContent>
				<TextField
					autoFocus
					fullWidth
					required
					className={styles.textField}
					label={label}
					value={value}
					variant="outlined"
					onChange={onChange}
				/>
			</GenericDialogContent>
			<GenericDialogFooter>
				<CustomButton color="secondary" variant="outlined" onClick={onClose}>
					{cancelLabel || translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton color="primary" disabled={!value} variant="contained" onClick={onSubmit}>
					{submitLabel || translate("common:btn.save")}
				</CustomButton>
			</GenericDialogFooter>
		</GenericDialog>
	);
}
