import React from "react";
import { Table } from "@mui/material";

export default function CustomTable({
	dense = false,
	children,
	className = "",
	padding = "normal",
	stickyHeader = false,
}) {
	return (
		<Table className={className} padding={padding} size={dense ? "small" : "medium"} stickyHeader={stickyHeader}>
			{children}
		</Table>
	);
}
