import client from "../client/client";

class OpportunityService {
	uploadNewOpportunity(files, payload, progressCallback, cancelToken) {
		return client.upload("/opportunity", files, {
			payload,
			config: { cancelToken, onUploadProgress: progressCallback },
		});
	}

	getAllOpportunity({ ownerIds, name }, { page = 0, limit = 15 }, cancelToken) {
		return client.post("/opportunity/all", {
			payload: { ownerIds, name },
			params: { page, limit },
			config: { cancelToken },
		});
	}

	editOpportunity({ opportunityId }, documentName, cancelToken) {
		return client.put(`/opportunity/${opportunityId}`, {
			payload: { value: documentName },
			config: { cancelToken },
		});
	}

	downloadOpportunity({ opportunityId }, cancelToken) {
		return client.download(`/opportunity/${opportunityId}`, {
			config: { cancelToken },
		});
	}

	restartOpportunityAnalyse({ opportunityId }, cancelToken) {
		return client.post(`/opportunity/${opportunityId}`, {
			config: { cancelToken },
		});
	}

	getOpportunitiesOwners(cancelToken) {
		return client.get("/opportunity", {
			config: { cancelToken },
		});
	}

	deleteOpportunity({ opportunityId }, cancelToken) {
		return client.delete(`/opportunity/${opportunityId}`, {
			config: { cancelToken },
		});
	}

	getNotificationHistory({ opportunityId }, cancelToken) {
		return client.get(`/opportunity/${opportunityId}/users`, {
			config: { cancelToken },
		});
	}

	addNotification({ opportunityId }, userIds, cancelToken) {
		return client.post(`/opportunity/${opportunityId}/users`, {
			payload: { userIds },
			config: { cancelToken },
		});
	}
}

export default new OpportunityService();
