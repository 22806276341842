import React from "react";
import { LabeledCheckbox } from "../..";
import { CustomTooltip } from "../../tooltip";
import styles from "./SearchRow.module.css";

export default function SearchRow({ checked, disabled, hasChildren = false, label, onClick, style = null, tooltip }) {
	return (
		<LabeledCheckbox
			checkboxClassName={styles.row__checkbox}
			checked={checked}
			className={styles.labeledCheckBox}
			disabled={disabled}
			label={
				<span className={styles.labels}>
					<CustomTooltip arrow placement="right" title={tooltip}>
						<span className={`${styles.label__main} ${hasChildren && styles["label__main--bold"]}`}>
							{label}
						</span>
					</CustomTooltip>
				</span>
			}
			labelClassName={styles.row__label}
			labelPlacement="end"
			size="small"
			style={style}
			onClick={onClick}
		/>
	);
}
