import React from "react";
import { ApiService } from "../../../api";
import { CustomButton, CustomTooltip } from "../../../common/components";
import { displayNameInitialsOrEmail } from "../utils/canvas-utils";
import styles from "./UserList.module.css";

class UserList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.updateCancelTokenSource = ApiService.getCancelTokenSource();
		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.updateCancelTokenSource);
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	componentDidUpdate(prevProps) {
		const { selectedDocument, selectedUser, users, onChangePrecedenceByUser } = this.props;

		if (prevProps.selectedUser !== selectedUser && selectedUser && selectedDocument !== "") {
			ApiService.cancelTokens(this.updateCancelTokenSource);
			this.updateCancelTokenSource = ApiService.getCancelTokenSource();
			onChangePrecedenceByUser(selectedUser, users.find((x) => x.email === selectedUser).id);
		}
		if (users !== prevProps.users && users.length > 0) {
			ApiService.cancelTokens(this.updateCancelTokenSource);
			this.updateCancelTokenSource = ApiService.getCancelTokenSource();
			onChangePrecedenceByUser(users[0].email, users[0].id);
		}
	}

	render() {
		const { onChangePrecedenceByUser, selectedUser } = this.props;
		const { users } = this.props;
		return (
			<div style={{ padding: 8, minHeight: 50 }}>
				{users.map((user) => (
					<CustomTooltip key={user.email} title={user.displayName || user.email}>
						<CustomButton
							fullWidth
							autoTranslate="no"
							className={`${styles.btn} ${user.email === selectedUser && styles["btn--selected"]}`}
							color="secondary"
							size="md"
							variant="outlined"
							onClick={() => onChangePrecedenceByUser(user.email, user.id, this.cancelTokenSource)}
						>
							<span className={styles.btn__text}>{displayNameInitialsOrEmail(user)}</span>
						</CustomButton>
					</CustomTooltip>
				))}
			</div>
		);
	}
}

export default UserList;
