import client from "../../client/client";

class InformationLinkService {
	getInformationLinks({ informationId }, cancelToken) {
		return client.get(`/smartview/information/${informationId}/links`, {
			config: { cancelToken },
		});
	}

	createInformationLinks({ informationId }, { from, link, to }, cancelToken) {
		return client.post(`/smartview/information/${informationId}/links`, {
			payload: { from, link, to },
			config: { cancelToken },
		});
	}

	editLink({ informationId, linkId }, { linkType }, cancelToken) {
		return client.put(`/smartview/information/${informationId}/links/${linkId}`, {
			payload: { linkType },
			config: { cancelToken },
		});
	}

	deleteLink({ informationId, linkId }, cancelToken) {
		return client.delete(`/smartview/information/${informationId}/links/${linkId}`, {
			config: { cancelToken },
		});
	}

	getLinkTypes({ projectId }, cancelToken) {
		return client.get(`/smartview/projects/${projectId}/linkType`, { config: { cancelToken } });
	}
}

export default new InformationLinkService();
