import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog, SearchInput, SidePanelHeader, ToggleButtons } from "../../../../common/components";
import { isDeepEqual } from "../../../../common/utils";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../common/providers";
import { DocumentFilter, NormFilter, TrustFilter } from "../../components";
import { DetectionHeader, Detections, SimilarDetections } from "./components";
import PendingDetectionBody from "./components/detections/detection-table/PendingDetectionBody";
import { ApiService, NormsPendingService } from "../../../../api";
import { changePendingNormsStatus } from "../../slice/normsCenterSlice";

const getPendingHeaders = (status) => {
	const headers = [
		{ sortKey: "DOCUMENT", name: translate("norms-center.tab.detections.headers.document") },
		{ name: translate("norms-center.tab.detections.headers.page") },
		{ name: translate("norms-center.tab.detections.headers.preview") },
		{ sortKey: "TEXT", name: translate("norms-center.tab.detections.headers.text-detected") },
		{ sortKey: "NORM", name: translate("norms-center.tab.detections.headers.matched") },
		{ sortKey: "TRUST", name: translate("norms-center.tab.detections.headers.trust") },
		{ name: translate("norms-center.tab.detections.headers.similar-detection") },
	];
	if (status === "PENDING") {
		headers.push({ name: translate("norms-center.tab.detections.headers.valid") });
		headers.push({ name: translate("norms-center.tab-detections.headers.valid-with-similar") });
		headers.push({ name: translate("norms-center.tab.detections.headers.discard") });
	} else {
		headers.push({ name: translate("norms-center.tab.detections.headers.recover") });
	}
	return headers;
};

export default function PendingDetections({
	onActive,
	onPageChange,
	onChangePendingStatus,
	onRowsPerPageChange,
	onRefreshPending,
	onSearch,
	onUpdatePendingNorm,
}) {
	const [selectedRow, setSelectedRow] = useState(null);
	const [checkedRows, setCheckedRows] = useState([]);
	const [coordinates, setCoordinates] = useState([]);
	const [selectedInfo, setSelectedInfo] = useState(null);
	const [validateAllRow, setValidateAllRow] = useState(null);
	const [openValidateSimilar, setOpenValidateSimilar] = useState(false);
	const [openPreviewDocument, setOpenPreviewDocument] = useState(false);
	const [openMatchedNorms, setOpenMatchedNorms] = useState(false);
	const [openSimilarDetections, setOpenSimilarDetections] = useState(false);
	const [isGlobalSelection, setIsGlobalSelection] = useState(false);
	const normFilter = useSelector(({ normsCenter }) => normsCenter.filters?.norm);
	const [filter, setFilter] = useState({ textDetected: "", documents: [], trust: "", norm: normFilter || null });
	const pendingDetections = useSelector(({ normsCenter }) => normsCenter.pendingDetections);
	const pendingStatus = useSelector(({ normsCenter }) => normsCenter.pendingStatus);
	const totalSearchResult = useSelector(({ normsCenter }) => normsCenter.totalPendingResult);
	const tempTotalSearchResult = useSelector(({ normsCenter }) => normsCenter.tempTotalPendingResult);
	const isLoading = useSelector(({ normsCenter }) => normsCenter.isLoadingPendingNorms);
	const page = useSelector(({ normsCenter }) => normsCenter.page);
	const pageLimit = useSelector(({ normsCenter }) => normsCenter.limit);
	const projectId = useSelector(({ normsCenter }) => normsCenter.projectId);
	const pendingDetectionHeaders = useMemo(() => getPendingHeaders(pendingStatus), [pendingStatus]);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(
		() => () => {
			setFilter({ textDetected: "", documents: [], trust: "", norm: null });
			setCheckedRows([]);
		},
		[]
	);
	useEffect(() => {
		setCheckedRows([]);
	}, [pendingStatus]);
	useEffect(() => {
		onSearch(filter, true);
		setCheckedRows([]);
	}, [filter, onSearch]);
	useEffect(() => {
		if (pendingDetections.length !== checkedRows.length) {
			setIsGlobalSelection(false);
		}
	}, [pendingDetections.length, checkedRows.length]);
	const handleChangeTextDetectedFilter = (e) => setFilter((prev) => ({ ...prev, textDetected: e.target.value }));
	const handleClearTextDetectedFilter = () => setFilter((prev) => ({ ...prev, textDetected: "" }));
	const handleChangeNormFilter = (norm) => setFilter((prev) => ({ ...prev, norm }));
	const handleChangeTrustFilter = (trust) => setFilter((prev) => ({ ...prev, trust }));
	const handleOpenPreviewDocument = () => setOpenPreviewDocument((prev) => !prev);
	const handleOpenMatchedNorms = () => setOpenMatchedNorms((prev) => !prev);
	const handleOpenSimilarDetections = () => setOpenSimilarDetections((prev) => !prev);
	const fetchNormsCoordinates = (docId, pendingId) => {
		NormsPendingService.getNormsCoordinates({ pendingId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setCoordinates(data.map((coord) => ({ ...coord, primary: coord.target })));
			})
			.catch((err) => {
				console.error(err);
			});
		NormsPendingService.getInformationFromPendingNorm({ pendingId, docId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				setSelectedInfo(data);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleDisplayDocument = (row) => {
		setSelectedRow(row);
		const { docId, pendingId } = row;
		fetchNormsCoordinates(docId, pendingId);
		handleOpenPreviewDocument();
	};
	const handleClickSimilarDetections = (row) => {
		setSelectedRow(row);
		handleOpenSimilarDetections();
	};
	const handleClickMatchedNorms = (row) => {
		setSelectedRow(row);
		handleOpenMatchedNorms();
	};
	const handleCheckAllRows = (e) => {
		const { checked } = e.target;
		setCheckedRows(checked ? pendingDetections : []);
	};
	const handleChangePendingNormsStatus = (status, id) => {
		const pendingIds = !id ? checkedRows.map((row) => row.pendingId) : [id];
		dispatch(
			changePendingNormsStatus({
				status,
				pendingIds,
				token: cancelTokenSourceRef.current.token,
			})
		)
			.then(() => {
				setCheckedRows([]);
				onRefreshPending();
			})
			.catch((err) => console.error(err));
	};
	const handleUpdateAllFiltered = (statusToUpdate) => {
		const { norm, documents: documentsToSearch, trust, ...others } = filter;
		const filters = {
			...others,
			documentsToSearch,
			status: pendingStatus,
		};
		if (Array.isArray(trust) && trust.length > 0) {
			filters.trust = trust;
		}
		if (norm?.id) {
			filters.normToSearch = norm.id;
		}
		NormsPendingService.updateAllFilteredPending(
			{ projectId },
			{ filters, statusToUpdate },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				setCheckedRows([]);
				onRefreshPending();
			})
			.catch((err) => console.error(err));
	};
	const handleEditSelectedNorm = (status) => {
		if (isGlobalSelection) {
			handleUpdateAllFiltered(status);
		} else {
			handleChangePendingNormsStatus(status);
		}
	};
	const handleCheckRow = (checked, row) => {
		if (checked) {
			setCheckedRows((prev) => [...prev, row]);
		} else {
			const newCheckedRows = checkedRows.filter((r) => r.pendingId !== row.pendingId);
			setCheckedRows(newCheckedRows);
		}
	};

	const handleSearch = ({ filterKey = "", searchFilters = null, searchContent = null, tempFilters = null }) => {
		if (
			searchFilters ||
			typeof searchContent === "string" ||
			(filterKey && !isDeepEqual(tempFilters[filterKey], filter[filterKey]))
		) {
			const payloadFilters = searchFilters ?? tempFilters;
			onSearch(payloadFilters, true);
			setFilter({ ...payloadFilters });
		}
	};
	const resetFilters = () => {
		const newFilters = { textDetected: "", documents: [], trust: "", norm: null };
		setFilter(newFilters);
		handleSearch({ searchFilters: newFilters });
	};
	const handleOpenValidateSimilarDialog = (row) => {
		setValidateAllRow(row);
		setOpenValidateSimilar((prev) => !prev);
	};

	const handleValidateNormWithSimilar = (row) => {
		NormsPendingService.validateAllSimilar({ pendingId: row.pendingId }, cancelTokenSourceRef.current.token)
			.then(() => {
				onRefreshPending();
				setValidateAllRow(null);
			})
			.catch((err) => console.error(err));
	};
	const handleClickSelectAll = () => {
		setIsGlobalSelection(true);
	};
	const handlePageChange = (_, newPage) => {
		setCheckedRows([]);
		onPageChange(_, newPage);
	};
	return (
		<>
			<SidePanelHeader>
				<SearchInput
					label={translate("norms-center.tab.detections.filter")}
					value={(filter && filter.textDetected) || ""}
					onChange={handleChangeTextDetectedFilter}
					onClearSearch={handleClearTextDetectedFilter}
				/>
				{isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS) && (
					<DocumentFilter
						filters={filter}
						isLoading={isLoading}
						tempTotalSearchResult={tempTotalSearchResult}
						onDirectSearch={onSearch}
						onSearch={handleSearch}
					/>
				)}
				<NormFilter selectedNorm={filter?.norm} status={pendingStatus} onSelectNorm={handleChangeNormFilter} />
				<TrustFilter selectedTrusts={filter?.trust} onSelectTrusts={handleChangeTrustFilter} />
				<ToggleButtons
					exclusive
					content={[
						{ value: "PENDING", label: translate("norms-center.tab.detections.pending-label") },
						{ value: "TRASH", label: translate("norms-center.tab.detections.discarded-label") },
					]}
					size="small"
					value={pendingStatus}
					onChange={onChangePendingStatus}
				/>
			</SidePanelHeader>
			<DetectionHeader
				pendingStatus={pendingStatus}
				selectedRows={isGlobalSelection ? totalSearchResult : checkedRows.length}
				totalSearchResult={totalSearchResult}
				onEditSelectedNorm={handleEditSelectedNorm}
			/>
			<Detections
				checkedRows={checkedRows}
				coordinates={coordinates}
				detections={pendingDetections}
				headers={pendingDetectionHeaders}
				isGlobalSelection={isGlobalSelection}
				isLoading={isLoading}
				openMatchedNorms={openMatchedNorms}
				openPreviewDocument={openPreviewDocument}
				page={page}
				pageLimit={pageLimit}
				selectedInfo={selectedInfo}
				selectedRow={selectedRow}
				TableBody={PendingDetectionBody}
				tableBodyProps={{
					checked: checkedRows,
					detections: pendingDetections,
					pendingStatus,
					onCheckRow: handleCheckRow,
					onClickMatchedNorms: handleClickMatchedNorms,
					onClickSimilarDetections: handleClickSimilarDetections,
					onChangeNormsStatus: handleChangePendingNormsStatus,
					onDisplayDocument: handleDisplayDocument,
					onOpenValidateSimilarDialog: handleOpenValidateSimilarDialog,
				}}
				totalSearchResult={totalSearchResult}
				onActive={onActive}
				onCheckAllRows={handleCheckAllRows}
				onClickSelectAll={handleClickSelectAll}
				onOpenMatchedNorms={handleOpenMatchedNorms}
				onOpenPreviewDocument={handleOpenPreviewDocument}
				onPageChange={handlePageChange}
				onResetFilters={resetFilters}
				onRowsPerPageChange={onRowsPerPageChange}
				onUpdatePendingNorm={onUpdatePendingNorm}
			/>
			<SimilarDetections
				open={openSimilarDetections}
				pendingId={selectedRow?.pendingId}
				textDetected={selectedRow?.textDetected}
				onActive={onActive}
				onOpenSimilarDetections={handleOpenSimilarDetections}
				onRefreshPending={onRefreshPending}
			/>
			{validateAllRow && openValidateSimilar && (
				<CustomDialog
					open={openValidateSimilar}
					subTitle={translate("norms-center.similar-detections.validate-all.dialog.text", {
						count: validateAllRow.similarDetections,
						normName: validateAllRow.matchedNormName,
					})}
					title={translate("norms-center.similar-detections.validate-all.dialog.title")}
					onClose={() => {
						setOpenValidateSimilar((prev) => !prev);
					}}
					onSubmit={() => handleValidateNormWithSimilar(validateAllRow)}
				/>
			)}
		</>
	);
}
