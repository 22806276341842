import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styles from "./ContentLayer.module.css";
import { checkReq, checkReqValMode } from "../../../../../utils/utils";
import RenderRectangle from "./RenderRectangle";
import { renderPositionStyle } from "../../../utils/utils";
import { isNonEmptyArray } from "../../../../../../../common/utils";

export default function ContentLayer({
	additionalData,
	height,
	metadata,
	onChangeVisibility,
	openedQuickAccess,
	tableHeight,
	width,
	page,
	searchData,
	selectionRectangleRef,
	hoveredContent,
	onMouseEnterBlock,
}) {
	const redressingInformation = useSelector(({ srDocument }) => srDocument.redressingInformation);
	const selectedSearch = useSelector(({ srDocument }) => srDocument.selectedSearch);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const isThereAnotherFilter = useSelector(({ srProjectDocument }) => srProjectDocument.isThereAnotherFilter);
	const renderSearchFocus = (rec) => {
		const isReqContent = checkReq(rec);
		return (
			<>
				{isNonEmptyArray(rec?.rectangles) &&
					rec.rectangles.map((rectangle) => {
						const coordinates = renderPositionStyle(rectangle, width, height);
						const { top, height: focusHeight } = coordinates;
						return (
							<area
								key={rectangle.order}
								alt={rectangle.order}
								className={`${styles["search-indicator"]}`}
								data-hovered={rec.infoId === hoveredContent?.informationId}
								data-is-selected-result={selectedSearch && rec?.infoId === selectedSearch?.infoId}
								data-req-content={isReqContent && !redressingInformation}
								role="presentation"
								style={{
									top,
									left: width - 24,
									height: focusHeight,
								}}
							/>
						);
					})}
			</>
		);
	};
	const filteredSearchData = useMemo(
		() => searchData.filter((sd) => sd.documentId === documentId),
		[documentId, searchData]
	);
	return (
		<>
			{isNonEmptyArray(metadata) &&
				metadata.map((area) => {
					if (isNonEmptyArray(area?.rectangles)) {
						const { rectangle: rec, ...rest } = area;
						const { contentId } = area.rectangles ? area.rectangles[0] : 0;
						return (
							<RenderRectangle
								key={contentId}
								height={height}
								hoveredBlock={hoveredContent}
								isInSelection={
									(additionalData?.inSelection || []).some(
										(r) =>
											r.informationId === area.informationId || r.informationId === area.info_id
									) ||
									(selectedRectangles || []).some(
										(r) =>
											r.informationId === area.informationId || r.informationId === area.info_id
									)
								}
								openedQuickAccess={openedQuickAccess}
								page={page}
								rectangle={{ ...rec, contentId, ...rest }}
								selectionRectangleRef={selectionRectangleRef}
								tableHeight={tableHeight}
								width={width}
								onChangeVisibility={onChangeVisibility}
								onEnterArea={onMouseEnterBlock}
							/>
						);
					}
					return (
						<RenderRectangle
							height={height}
							hoveredBlock={hoveredContent}
							isInSelection={
								(additionalData?.inSelection || []).some(
									(r) => r.informationId === area.informationId || r.informationId === area.info_id
								) ||
								(selectedRectangles || []).some(
									(r) => r.informationId === area.informationId || r.informationId === area.info_id
								)
							}
							openedQuickAccess={openedQuickAccess}
							page={page}
							rectangle={area}
							selectionRectangleRef={selectionRectangleRef}
							tableHeight={tableHeight}
							width={width}
							onChangeVisibility={onChangeVisibility}
							onEnterArea={onMouseEnterBlock}
						/>
					);
				})}
			{isThereAnotherFilter && checkReqValMode(mode) && filteredSearchData.map((sd) => renderSearchFocus(sd))}
		</>
	);
}
