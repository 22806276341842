import { Menu, MenuItem, Popover, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import { translate } from "../../../../common/providers";
import { CustomButton, icon } from "../../../../common/components";
import styles from "./DSIBuCreation.module.css";

export default function DSIBuCreation({
	activeUsersInCompany,
	buErrors,
	buPayload,
	companies,
	onChangeBuFields,
	onSelectCompany,
	onSubmitBu,
}) {
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [isOpenUserList, setIsOpenUserList] = useState(false);
	const [isOpenCompanyList, setIsOpenCompanyList] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const anchorRefCompany = useRef(null);
	const anchorRefUser = useRef(null);

	const handleSelectCompany = (comp) => {
		if (comp !== selectedCompany) {
			onChangeBuFields(comp.id, "companyId");
			setSelectedCompany(comp);
			onSelectCompany(comp);
			setSelectedUser(null);
		}
		setIsOpenCompanyList(false);
	};
	const handleOpenUserList = () => {
		setIsOpenUserList((prevOpen) => !prevOpen);
	};
	const handleCloseUserList = () => {
		setIsOpenUserList(false);
	};

	const handleSelectUser = (user) => {
		setSelectedUser(user);
		setIsOpenUserList(false);
		onChangeBuFields(user.id, "adminId");
	};

	const handleOpenCompanyList = () => {
		setIsOpenCompanyList((prevOpen) => !prevOpen);
	};
	const handleCloseCompanyList = () => {
		setIsOpenCompanyList(false);
	};

	const handleSearchChange = (event) => {
		setSearchQuery(event.target.value);
	};
	const renderRow = (comp) => (
		<MenuItem key={comp.id} translate="no" onClick={() => handleSelectCompany(comp)}>
			{comp.companyName}
		</MenuItem>
	);
	const renderRowsWithChildren = (treeRows) =>
		treeRows.map((row) => (
			<div key={row.id}>
				{row.type !== "CONSORTIUM" && renderRow(row)}
				{row.children && row.children.length > 0 && row.children.type !== "CONSORTIUM" && (
					<div className="children-container">{renderRowsWithChildren(row.children)}</div>
				)}
			</div>
		));
	return (
		<div className={styles.mainContainer}>
			<div className={styles.container}>
				<div>
					<div className={styles.titleName}>{`${translate("dsi.dsi-company.admin-bu.select-company")}*`}</div>
					<CustomButton
						ref={anchorRefCompany}
						autoTranslate="no"
						className={styles.companyMenu}
						color="secondary"
						endIcon={icon.faChevronDown}
						size="xl"
						variant="outlined"
						onClick={handleOpenCompanyList}
					>
						{selectedCompany
							? selectedCompany.companyName
							: translate("dsi.dsi-company.admin-bu.select-company.helper")}
					</CustomButton>
					<Menu anchorEl={anchorRefCompany.current} open={isOpenCompanyList} onClose={handleCloseCompanyList}>
						{companies.length > 0 && renderRowsWithChildren(companies)}
					</Menu>
				</div>
				<div className={styles.titleName}>{`${translate("dsi.dsi-company.th.shortname")}*`}</div>
				<TextField
					required
					error={buErrors && buErrors.shortName}
					helperText={buErrors && buErrors.shortName && "Required"}
					label={translate("dsi.dsi-company.th.shortname")}
					translate="no"
					value={buPayload.shortName || ""}
					variant="outlined"
					onChange={(e) => onChangeBuFields(e.target.value, "shortName")}
				/>
				<span className={styles.dividerSpace} />
				<div className={styles.titleName}>{`${translate("dsi.dsi-company.th.name")}*`}</div>
				<TextField
					required
					error={buErrors && buErrors.name}
					helperText={buErrors && buErrors.name && "Required"}
					label={translate("dsi.dsi-company.th.name")}
					value={buPayload.name || ""}
					variant="outlined"
					onChange={(e) => onChangeBuFields(e.target.value, "name")}
				/>
				<span className={styles.dividerSpace} />
				<div>
					<div className={styles.titleName}>{`${translate("dsi.dsi-company.admin-bu.select-admin")}*`}</div>
					<CustomButton
						ref={anchorRefUser}
						autoTranslate="no"
						className={styles.userMenu}
						color="secondary"
						endIcon={icon.faChevronDown}
						size="xl"
						variant="outlined"
						onClick={handleOpenUserList}
					>
						{selectedUser ? selectedUser.displayName : translate("dsi.dsi-company.admin-bu.select-user")}
					</CustomButton>
					<Popover
						anchorEl={anchorRefUser.current}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						open={isOpenUserList}
						onClose={handleCloseUserList}
					>
						<div className={styles.dividerSpace}>
							<TextField
								autoFocus
								fullWidth
								placeholder={translate("common:search-label.user")}
								value={searchQuery}
								variant="outlined"
								onChange={handleSearchChange}
							/>
						</div>
						{activeUsersInCompany.length > 0 ? (
							activeUsersInCompany
								.filter((user) => user?.displayName?.toLowerCase().includes(searchQuery.toLowerCase()))
								.map((user) => (
									<MenuItem
										key={user.id}
										className={styles.userMenu}
										onClick={() => handleSelectUser(user)}
									>
										{user.displayName}
									</MenuItem>
								))
						) : (
							<MenuItem disabled>{translate("common:filters.select-filter.empty-state")}</MenuItem>
						)}
					</Popover>
				</div>
				<span className={styles.dividerSpace} />
				<CustomButton
					fullWidth
					color="primary"
					disabled={!selectedUser || !buPayload.name || !buPayload.shortName}
					variant="contained"
					onClick={onSubmitBu}
				>
					{translate("common:btn.submit")}
				</CustomButton>
			</div>
		</div>
	);
}
