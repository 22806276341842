import React from "react";
import { connect } from "react-redux";
import { createNotification, DrawerInterface, icon, Selector, ViewBanner } from "../../../common/components";
import {
	ApiService,
	CategoryService,
	DocumentOutService,
	OperationPhaseService,
	ProjectTeamService,
} from "../../../api";
import DocumentPanel from "./document-panel/DocumentPanel";
import DocumentDetails from "./document-details/DocumentDetails";
import DocumentDialog from "./document-dialog/DocumentDialog";
import { PhasesCycle } from "../components";
import { exportView } from "../../../navigation";
import {
	AnalyticsProvider,
	Flags,
	hasCompanyPermission,
	hasPermission,
	isSegFeatureEnabled,
	Permissions,
	SegFlags,
	translate,
} from "../../../common/providers";
import Filter from "./filter/Filter";
import Import from "./import/Import";
import styles from "./Deliverables.module.css";
import { updateTreeBranch } from "../../../common/utils";

const DIALOG_ACTIONS = { CREATE: "ADD", UPDATE: "UPDATE" };
const FILTER_ACTIONS = { ADD: "ADD", REMOVE: "REMOVE" };

const mapStateToProps = ({ context }) => ({
	projectId: context.project.id,
	companyId: context.company.id,
});

class Deliverables extends React.Component {
	constructor(props) {
		super(props);
		this.FILTERS = {
			// type must match incomming dto fields
			FBS: {
				type: "thematics",
				display: ({ id, name }) => [id, name],
				name: translate("common:header.filter.project-categories"),
				icon: icon.faSitemap,
				disabled: !isSegFeatureEnabled(SegFlags.CATEGORY),
			},
			PHASES: {
				type: "phase",
				display: ({ id, name }) => [id, name],
				name: translate("deliverables.filter-name.phases"),
				icon: icon.faClockRegular,
			},
			OWNERS: {
				type: "owner",
				display: ({ id, displayName }) => [id, displayName],
				name: translate("deliverables.filter-name.owners"),
				icon: icon.faUserFriends,
				disabled: !isSegFeatureEnabled(SegFlags.TEAM),
			},
		};
		this.menus = Object.keys(this.FILTERS).map((key, index) => ({
			...this.FILTERS[key],
			index,
		}));
		// Not a filter, but an action to set in the side menu
		this.menus.push({
			index: this.menus.length,
			name: translate("deliverables.menu-name.import"),
			icon: icon.faDownload,
			disabled: !(
				hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]) &&
				hasCompanyPermission([Permissions.PROJECT_LEADER])
			),
		});

		this.state = {
			documents: [],
			documentTypes: [],
			documentFormats: [],
			fbs: [],
			phases: [],
			phaseModels: [],
			owners: [],
			selectedPhaseModel: null,
			importTemplate: [],
			importResults: null,
			searchForFbs: "",
			filterFBS: {
				name: "filterFBS",
				type: this.FILTERS.FBS.type,
				display: this.FILTERS.FBS.display,
				color: "var(--color-white)",
				backgroundColor: "var(--color-light-blue)",
				filters: [],
			},
			filterPhases: {
				name: "filterPhases",
				type: this.FILTERS.PHASES.type,
				display: this.FILTERS.PHASES.display,
				color: "var(--color-white)",
				backgroundColor: "var(--color-light-green)",
				filters: [],
			},
			filterOwners: {
				name: "filterOwners",
				type: this.FILTERS.OWNERS.type,
				display: this.FILTERS.OWNERS.display,
				color: "var(--color-white)",
				backgroundColor: "var(--color-dark-blue)",
				filters: [],
			},
			currentFilter: 0,
			selectedDoc: null,
			selectedDocuments: [],
			openDialog: false,
			modeMax: false,
		};

		this.getFBS = this.getFBS.bind(this);
		this.import = this.import.bind(this);
		this.getPhases = this.getPhases.bind(this);
		this.getDocuments = this.getDocuments.bind(this);
		this.setPhaseModel = this.setPhaseModel.bind(this);
		this.getPhaseModels = this.getPhaseModels.bind(this);
		this.getDocumentTypes = this.getDocumentTypes.bind(this);
		this.getDocumentFormats = this.getDocumentFormats.bind(this);
		this.getProjectUsers = this.getProjectUsers.bind(this);
		this.switchFilter = this.switchFilter.bind(this);
		this.addFilter = this.addFilter.bind(this);
		this.removeFilter = this.removeFilter.bind(this);
		this.createDocument = this.createDocument.bind(this);
		this.updateDocument = this.updateDocument.bind(this);
		this.deleteDocument = this.deleteDocument.bind(this);
		this.handleSelectDoc = this.handleSelectDoc.bind(this);
		this.handleSelectModel = this.handleSelectModel.bind(this);
		this.getImportTemplate = this.getImportTemplate.bind(this);
		this.handleCheckDocument = this.handleCheckDocument.bind(this);
		this.handleCheckAllDocuments = this.handleCheckAllDocuments.bind(this);
		this.handleUpdateSelectedDocuments = this.handleUpdateSelectedDocuments.bind(this);
		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.displayContent = this.displayContent.bind(this);
		this.displayFilters = this.displayFilters.bind(this);
		this.setFilter = this.setFilter.bind(this);

		this.cancelTokenSource = ApiService.getCancelTokenSource();
	}

	componentDidMount() {
		const documentTitle = translate("deliverable.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Deliverables to be produced" });
		if (isSegFeatureEnabled(SegFlags.CATEGORY)) {
			this.getFBS();
		}
		this.getPhases();
		this.getDocuments();
		if (isSegFeatureEnabled(SegFlags.TEAM)) {
			this.getProjectUsers();
		}
		this.getDocumentTypes();
		this.getDocumentFormats();
		this.getImportTemplate();
	}

	componentDidUpdate(_, prevState) {
		const { searchForFbs } = this.state;
		if (prevState.searchForFbs !== searchForFbs) {
			this.getFBS();
		}
	}

	componentWillUnmount() {
		ApiService.cancelTokens(this.cancelTokenSource);
	}

	/* AXIOS */
	getDocuments() {
		const { projectId } = this.props;
		DocumentOutService.getAll({ projectId }, this.cancelTokenSource.token)
			.then((data) => {
				if (Array.isArray(data)) {
					this.setState({ documents: data });
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}

	getDocumentTypes() {
		const { documentTypes } = this.state;
		if (!Array.isArray(documentTypes) || documentTypes.length === 0) {
			DocumentOutService.getTypes(this.cancelTokenSource.token)
				.then((data) => {
					if (Array.isArray(data)) {
						this.setState({ documentTypes: data });
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}

	getDocumentFormats() {
		const { documentFormats } = this.state;
		if (!Array.isArray(documentFormats) || documentFormats.length === 0) {
			DocumentOutService.getFormats(this.cancelTokenSource.token)
				.then((data) => {
					if (Array.isArray(data)) {
						this.setState({ documentFormats: data });
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}

	getPhases() {
		const { phases } = this.state;
		const { projectId } = this.props;
		if (!Array.isArray(phases) || phases.length === 0) {
			OperationPhaseService.getAllByProject({ projectId }, this.cancelTokenSource.token)
				.then((data) => {
					if (Array.isArray(data) && data.length > 0) {
						this.setState({ phases: data });
					} else {
						this.getPhaseModels();
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}

	getPhaseModels() {
		const { phaseModels } = this.state;
		const { companyId } = this.props;
		if (!Array.isArray(phaseModels) || phaseModels.length === 0) {
			OperationPhaseService.getCompanyModels({ companyId }, this.cancelTokenSource.token)
				.then((data) => {
					if (Array.isArray(data)) {
						this.setState({ phaseModels: data });
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}

	setPhaseModel(modelId) {
		const { phaseModels } = this.state;
		const { projectId } = this.props;
		if (modelId && Array.isArray(phaseModels) && phaseModels.length > 0) {
			OperationPhaseService.setModelInProject({ projectId }, modelId, this.cancelTokenSource.token)
				.then((data) => {
					if (Array.isArray(data)) {
						this.setState({ phases: data });
					}
					createNotification({ type: "success", message: translate("deliverables.success-model-set") });
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}

	getFBS() {
		const { fbs, searchForFbs } = this.state;
		const { projectId } = this.props;
		if (!Array.isArray(fbs) || fbs.length === 0 || searchForFbs) {
			if (searchForFbs) {
				CategoryService.getTreeForProject(
					{ projectId, search: searchForFbs, details: false },
					this.cancelTokenSource.token
				)
					.then((data) => {
						this.setState({ fbs: data });
					})
					.catch((err) => {
						console.error(err);
					});
			} else {
				CategoryService.getTreeParentsForProject({ projectId, details: false }, this.cancelTokenSource.token)
					.then((data) => {
						this.setState({ fbs: data });
					})
					.catch((err) => {
						console.error(err);
					});
			}
		}
	}

	getProjectUsers() {
		const { owners } = this.state;
		const { projectId } = this.props;
		if (!Array.isArray(owners) || owners.length === 0) {
			ProjectTeamService.searchUsersInProjectAndCompany({ projectId }, "", this.cancelTokenSource.token)
				.then((data) => {
					if (Array.isArray(data)) {
						this.setState({ owners: data });
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}

	createDocument(payload) {
		const { projectId } = this.props;
		DocumentOutService.create({ projectId }, payload, this.cancelTokenSource.token)
			.then((data) =>
				this.setState((prev) => ({
					documents: [...prev.documents, data],
				}))
			)
			.catch((err) => {
				console.error(err);
			});
	}

	updateDocument(payload) {
		const { selectedDoc } = this.state;
		DocumentOutService.update({ docId: selectedDoc.id }, payload, this.cancelTokenSource.token)
			.then((data) => {
				this.setState((prev) => ({
					selectedDoc: data,
					documents: [data, ...prev.documents.filter((doc) => doc.id !== selectedDoc.id)],
				}));
			})
			.catch((err) => {
				console.error(err);
			});
	}

	updateDocumentBatch(payload) {
		const { documents } = this.state;
		DocumentOutService.updateBatch(payload, this.cancelTokenSource.token)
			.then((data) => {
				if (Array.isArray(data)) {
					const docs = documents.filter((doc) => !data.some((d) => doc.id === d.id));
					this.setState({ selectedDoc: null, documents: [...data, ...docs] });
					createNotification({ type: "success", message: translate("deliverables.success-operation") });
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}

	deleteDocument() {
		const { selectedDoc, documents } = this.state;
		DocumentOutService.delete({ docId: selectedDoc.id }, this.cancelTokenSource.token)
			.then(() => {
				const docs = documents.filter((doc) => doc.id !== selectedDoc.id);
				this.setState({ selectedDoc: null, documents: [...docs] });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	getImportTemplate() {
		if (
			!(
				hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]) &&
				hasCompanyPermission([Permissions.PROJECT_LEADER])
			)
		) {
			return;
		}
		const { importTemplate } = this.state;
		const { projectId } = this.props;
		if (!Array.isArray(importTemplate) || importTemplate.length === 0) {
			DocumentOutService.getImportTemplate({ projectId }, this.cancelTokenSource.token)
				.then((data) => Array.isArray(data) && this.setState({ importTemplate: data }))
				.catch((err) => console.error(err));
		}
	}

	import(file, shouldErase) {
		if (!file) {
			this.setState({ importResults: null });
			return;
		}
		const { projectId } = this.props;
		DocumentOutService.import({ projectId }, file, shouldErase, this.cancelTokenSource.token)
			.then((data) => {
				if (data.valid) {
					createNotification({
						type: "success",
						message: translate("deliverables.success-import"),
						timeout: 2000,
					});
					this.getDocuments();
				} else {
					createNotification({
						type: "error",
						message: translate("deliverables.failed-import"),
						timeout: 2000,
					});
				}
				this.setState({ importResults: data });
			})
			.catch((err) => {
				console.error(err);
				this.setState({ importResults: null });
			});
	}

	setFilter(index) {
		this.setState((prevState) => prevState.currentFilter !== index && { currentFilter: index });
	}

	actionFilter({ action, filterName, element }) {
		if (action === FILTER_ACTIONS.ADD) {
			this.addFilter(filterName, element);
		} else {
			this.removeFilter(filterName, element);
		}
	}

	addFilter(filterName, newFilter) {
		const { [filterName]: filterType } = this.state;
		if (!filterType.filters.some((filter) => filter.id === newFilter.id)) {
			filterType.filters.push(newFilter);
			this.setState({ [filterName]: filterType });
		}
	}

	removeFilter(filterName, newFilter) {
		const { [filterName]: filterType } = this.state;
		if (!Array.isArray(filterType.filters) || filterType.filters.length === 0) {
			return;
		}
		filterType.filters = filterType.filters.filter((filter) => filter.id !== newFilter.id);
		this.setState({ [filterName]: filterType });
	}

	handleSelectDoc(doc) {
		this.setState({ selectedDoc: doc });
	}

	handleCheckDocument(doc) {
		const { selectedDocuments } = this.state;
		if (selectedDocuments.some((d) => d.id === doc.id)) {
			const docs = selectedDocuments.filter((d) => d.id !== doc.id);
			this.setState({ selectedDocuments: docs });
		} else {
			this.setState((prev) => ({ selectedDocuments: [...prev.selectedDocuments, doc] }));
		}
	}

	handleCheckAllDocuments(checked) {
		if (!checked) {
			const { documents } = this.state;
			this.setState({ selectedDocuments: documents });
		} else {
			this.setState({ selectedDocuments: [] });
		}
	}

	handleUpdateSelectedDocuments(phaseId) {
		const { selectedDocuments } = this.state;
		const batch = selectedDocuments.map((doc) => ({ id: doc.id, dto: { phaseId } }));
		const payload = { ops: "UPDATE", bulk: batch };
		this.updateDocumentBatch(payload);
	}

	handleOpenDialog(action) {
		this.setState({ openDialog: true, dialogAction: action });
	}

	handleCloseDialog() {
		this.setState({ openDialog: false, dialogAction: null });
	}

	handleSelectModel(e) {
		const phaseModel = e.target.value;
		if (!phaseModel) {
			return;
		}
		this.setState({ selectedPhaseModel: phaseModel });
		this.setPhaseModel(phaseModel.id);
	}

	displayFilters() {
		return <div className={styles.filters}>{this.switchFilter()}</div>;
	}

	handleSetFbsFilter = (search) => {
		this.setState({ searchForFbs: search });
	};

	handleExpand = (category) => {
		if (Array.isArray(category.subThematic) && category.subThematic.length > 0) {
			this.setState((prev) => ({
				fbs: updateTreeBranch({
					tree: prev.fbs,
					idToReplace: category.id,
					newBranch: { ...category, expanded: !category.expanded },
					iterativeBranchName: "subThematic",
				}),
			}));
		} else {
			CategoryService.getTreeChildrenForProject(
				{ parentId: category.id, details: false },
				this.cancelTokenSource.token
			).then((data) => {
				this.setState((prev) => ({
					fbs: updateTreeBranch({
						tree: prev.fbs,
						idToReplace: category.id,
						newBranch: { ...category, subThematic: data, expanded: !category.expanded },
						iterativeBranchName: "subThematic",
					}),
				}));
			});
		}
	};

	switchFilter() {
		const {
			currentFilter,
			filterFBS,
			fbs,
			filterPhases,
			phases,
			filterOwners,
			owners,
			importResults,
			importTemplate,
		} = this.state;
		switch (this.menus[currentFilter].name) {
			case this.FILTERS.FBS.name:
				return (
					<Filter
						isTree
						addFilter={({ element }) =>
							this.actionFilter.call(this, {
								action: FILTER_ACTIONS.ADD,
								filterName: filterFBS.name,
								element,
							})
						}
						elements={fbs}
						matchField="name"
						title={translate("deliverables.fun-breakdown")}
						onExpand={this.handleExpand}
						onSetFilter={this.handleSetFbsFilter}
					/>
				);
			case this.FILTERS.PHASES.name:
				return (
					<Filter
						addFilter={({ element }) =>
							this.actionFilter.call(this, {
								action: FILTER_ACTIONS.ADD,
								filterName: filterPhases.name,
								element,
							})
						}
						elements={phases}
						matchField="name"
						title={translate("deliverables.operation-phases")}
					/>
				);
			case this.FILTERS.OWNERS.name:
				return (
					<Filter
						addFilter={({ element }) =>
							this.actionFilter.call(this, {
								action: FILTER_ACTIONS.ADD,
								filterName: filterOwners.name,
								element,
							})
						}
						elements={owners}
						matchField="displayName"
						title={translate("deliverables.documents-owners")}
					/>
				);
			default:
				return (
					<Import
						eraseOption
						entityToImport={translate("deliverables.entity-to-import")}
						results={importResults}
						template={importTemplate}
						onImport={this.import}
					/>
				);
		}
	}

	displayContent() {
		const {
			documents,
			filterFBS,
			filterPhases,
			filterOwners,
			selectedDoc,
			selectedDocuments,
			phases,
			phaseModels,
			selectedPhaseModel,
		} = this.state;
		return (
			<>
				<ViewBanner
					titles={[
						{ title: translate("navigation:project.follow-up"), key: "followUp" },
						{ title: translate("navigation:project.deliverables"), key: "deliverables" },
						{ title: translate("navigation:project.deliverables-to-be-produced") },
					]}
				/>
				<div className={styles.documentList__container}>
					<DocumentPanel
						documents={documents}
						filterTypes={[filterFBS, filterPhases, filterOwners]}
						handleCheckAllDocuments={this.handleCheckAllDocuments}
						handleCheckDocument={this.handleCheckDocument}
						handleOpenDialog={() => this.handleOpenDialog(DIALOG_ACTIONS.CREATE)}
						phases={phases}
						removeFilter={({ filterName, element }) =>
							this.actionFilter.call(this, {
								action: FILTER_ACTIONS.REMOVE,
								filterName,
								element,
							})
						}
						selectDoc={this.handleSelectDoc}
						selectedDoc={selectedDoc}
						selectedDocuments={selectedDocuments || []}
					/>
					{selectedDoc && (
						<DocumentDetails
							document={selectedDoc}
							handleDeleteDocument={this.deleteDocument}
							handleOpenDialog={() => this.handleOpenDialog(DIALOG_ACTIONS.UPDATE)}
						/>
					)}
					{!selectedDoc && Array.isArray(phases) && phases.length > 0 && (
						<PhasesCycle
							handleUpdateSelectedDocuments={this.handleUpdateSelectedDocuments}
							phases={phases}
							selectedDocuments={selectedDocuments}
						/>
					)}
					{!selectedDoc && (!Array.isArray(phases) || phases.length === 0) && (
						<div className={styles.document__selector}>
							<span>{translate("deliverables.phases.empty-model")}</span>
							{hasCompanyPermission([Permissions.PROJECT_LEADER]) &&
								hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]) && (
									<Selector
										fullWidth
										field="name"
										items={phaseModels}
										label={translate("deliverables.phases.placeholder")}
										size="medium"
										value={selectedPhaseModel || ""}
										onChange={this.handleSelectModel}
									/>
								)}
						</div>
					)}
				</div>
			</>
		);
	}

	handleChangeModeMax = (newModeMax) => {
		this.setState({ modeMax: newModeMax });
	};

	handleExpandCategoryRow = (category) => {
		if (!Array.isArray(category.subThematic) || category.subThematic.length === 0) {
			CategoryService.getTreeChildrenForProject(
				{ parentId: category.id, details: false },
				this.cancelTokenSource.token
			)
				.then((data) => {
					this.setState((prev) => ({
						fbs: updateTreeBranch({
							tree: prev.fbs,
							idToReplace: category.id,
							newBranch: {
								...category,
								subThematic: data,
							},
							iterativeBranchName: "subThematic",
						}),
					}));
				})
				.catch((err) => console.error(err));
		}
	};

	render() {
		const {
			currentFilter,
			dialogAction,
			selectedDoc,
			fbs,
			documentFormats,
			openDialog,
			owners,
			phases,
			documentTypes,
			modeMax,
		} = this.state;
		return (
			<>
				<DrawerInterface
					expandMenu
					currentMenu={currentFilter || 0}
					displayContent={this.displayContent}
					displaySubMenu={this.displayFilters}
					menus={this.menus.filter((m) => !m.disabled)}
					modeMax={modeMax}
					setMenu={this.setFilter}
					onChangeModeMax={this.handleChangeModeMax}
				/>
				<DocumentDialog
					action={
						dialogAction === DIALOG_ACTIONS.UPDATE && selectedDoc
							? this.updateDocument
							: this.createDocument
					}
					document={(dialogAction === DIALOG_ACTIONS.UPDATE && selectedDoc) || null}
					fbs={fbs}
					formats={documentFormats}
					open={!!openDialog}
					owners={owners}
					phases={phases}
					types={documentTypes}
					onClose={this.handleCloseDialog}
					onExpand={this.handleExpandCategoryRow}
				/>
			</>
		);
	}
}

export default exportView({
	path: "/projects/:projectId/follow-up/deliverables/deliverables-to-be-produced",
	localesPath: "/produce/deliverables/locales",
	component: connect(mapStateToProps)(Deliverables),
	flag: Flags.OPERATION,
	segFlag: SegFlags.DELIVERABLES,
});
