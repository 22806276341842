import React from "react";
import styles from "./Comment.module.css";
import { translate, translateElapsed } from "../../../../../../common/providers";
import { CustomIconButton, icon } from "../../../../../../common/components";

export default function Comment({ owner = false, comment = null, onEdit }) {
	return (
		<div className={styles.comment} data-testid="add.comment.div">
			{comment && (
				<>
					<div>
						<span>{comment?.userName}</span>
						<span>
							-{" "}
							{translateElapsed({
								value: comment.elapsed.value,
								unitKey: comment.elapsed.unit,
							})}
						</span>
						<div />
						{owner && (
							<CustomIconButton
								icon={icon.faPenToSquare}
								role="presentation"
								size="sm"
								tooltip={translate("common:btn.edit")}
								variant="outlined"
								onClick={onEdit}
							/>
						)}
					</div>
					<div>{comment?.content}</div>
				</>
			)}
		</div>
	);
}
