import React from "react";
import { Typography } from "@mui/material";
import styles from "./DeliverableSwitch.module.css";
import { COVERAGE_STATUSES } from "../../../../../../../../../common/constants/coverages-statuses";
import { translate } from "../../../../../../../../../common/providers";
import { CustomSwitch } from "../../../../../../../../../common/components";

export default function DeliverableSwitch({
	className = "",
	coverageStatus = COVERAGE_STATUSES.toBeCovered,
	disabled,
	onCoverageStatusChange,
}) {
	return (
		<div className={styles.switch}>
			<Typography className={className}>
				{translate("smart-review.cmp-details.option-panel.deliverables-tab.requirement-to-cover")}
			</Typography>
			<CustomSwitch
				checked={coverageStatus === "TO_BE_COVERED"}
				disabled={disabled}
				onChange={onCoverageStatusChange}
			/>
		</div>
	);
}
