import { KEY_DASHBOARD } from "../../../../../common/providers/keys/keys";

class DashboardContext {
	constructor() {
		this.storage = window.sessionStorage;
	}

	getContext() {
		try {
			return JSON.parse(this.storage.getItem(KEY_DASHBOARD));
		} catch (e) {
			console.error(e);
			return {};
		}
	}

	setContext(payload = {}) {
		let context = null;
		if (payload) {
			context = payload;
			this.storage.setItem(KEY_DASHBOARD, JSON.stringify(context));
		}
	}
}

export default new DashboardContext();
