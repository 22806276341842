import { MenuItem, TextField } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	CustomIconButton,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	icon,
	KnowledgeBasePopOver,
} from "../../../../../common/components";
import styles from "./MembersList.module.css";
import { loadProjectCreation, setUserRoles } from "../../../slice/newProjectSlice";
import { ApiService, ProjectCreationService, RoleService } from "../../../../../api";
import {
	translate,
	translateEnumProjectRole,
	translateEnumProjectRoleDescription,
} from "../../../../../common/providers";

export default function MembersList() {
	const projectCreation = useSelector((state) => state.newProject.projectCreation);
	const userRoles = useSelector((state) => state.newProject.userRoles);
	const currentUser = useSelector((state) => state.newProject.currentUser);
	const hasPermissionsSegFlag = useSelector((state) => state.newProject.hasPermissionsSegFlag);
	const cancelTokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		dispatch(loadProjectCreation({ token: cancelTokenSourceRef.current.token }));
		RoleService.listUserInProject(cancelTokenSourceRef.current.token)
			.then((data) => {
				dispatch(setUserRoles(data));
			})
			.catch((err) => {
				console.error(err);
			});
	}, [dispatch]);
	const handleChangeRole = (event, userId) => {
		const userIndex = projectCreation.users.findIndex((m) => m.id === userId);
		if (userIndex >= 0) {
			ProjectCreationService.updateUserRole(
				{ userId },
				{ role: event.target.value },
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					dispatch(loadProjectCreation({ token: cancelTokenSourceRef.current.token }));
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};
	const handleDeleteUser = (user) => {
		ProjectCreationService.deleteUser({ userId: user.id }, cancelTokenSourceRef.current.token)
			.then(() => {
				dispatch(loadProjectCreation({ token: cancelTokenSourceRef.current.token }));
			})
			.catch((err) => {
				console.error(err);
			});
	};

	return (
		<CustomTable>
			<CustomTableHead>
				<CustomTableRow>
					<CustomTableCell>{translate("new-project.add-member.members-list.header.email")}</CustomTableCell>
					<CustomTableCell>{translate("new-project.add-member.members-list.header.role")}</CustomTableCell>
					<CustomTableCell />
					<CustomTableCell />
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody>
				{Array.isArray(projectCreation.users) &&
					projectCreation.users.map((user) => (
						<CustomTableRow key={user.email} className={styles.row}>
							<CustomTableCell autoTranslate="no" className={styles["email-cell"]}>
								{user.email}
							</CustomTableCell>
							<CustomTableCell className={styles["role-cell"]}>
								{!hasPermissionsSegFlag ? (
									<KnowledgeBasePopOver
										label={translate(
											"new-project.add-member.members-list.select.role.not-allowed.by-flag"
										)}
										link={translate(
											"new-project.add-member.members-list.select.role.not-allowed.by-flag.link"
										)}
										linkLabel={translate("common:knowledge-base:access")}
									>
										{Array.isArray(userRoles) && userRoles.length > 0 && (
											<TextField
												select
												className={styles["role-cell__dropdown"]}
												disabled={user.email === currentUser.email || !hasPermissionsSegFlag}
												inputProps={{ className: styles.inputRole }}
												size="small"
												translate="no"
												value={user.role}
												variant="outlined"
												onChange={(e) => handleChangeRole(e, user.id)}
											>
												{userRoles.map((r) => (
													<MenuItem key={r} className={styles.menu} translate="no" value={r}>
														{translateEnumProjectRole(r)}
													</MenuItem>
												))}
											</TextField>
										)}
									</KnowledgeBasePopOver>
								) : (
									Array.isArray(userRoles) &&
									userRoles.length > 0 && (
										<TextField
											select
											className={styles["role-cell__dropdown"]}
											disabled={user.email === currentUser.email}
											inputProps={{ className: styles.inputRole }}
											size="small"
											translate="no"
											value={user.role}
											variant="outlined"
											onChange={(e) => handleChangeRole(e, user.id)}
										>
											{userRoles.map((r) => (
												<MenuItem key={r} className={styles.menu} translate="no" value={r}>
													{translateEnumProjectRole(r)}
												</MenuItem>
											))}
										</TextField>
									)
								)}
							</CustomTableCell>
							<CustomTableCell autoTranslate="no" className={styles["description-cell"]}>
								{translateEnumProjectRoleDescription(user.role)}
							</CustomTableCell>
							<CustomTableCell>
								{user.email !== currentUser.email && (
									<CustomIconButton
										icon={icon.faTimes}
										iconClassName={styles["dialogHeader__closeBtn--larger"]}
										onClick={() => handleDeleteUser(user)}
									/>
								)}
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
