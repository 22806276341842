const checkForNumbers = (string) => {
	const matches = new RegExp(/\d+/g);
	return matches.test(string);
};

const checkForUpperCase = (string) => {
	const matches = new RegExp(/[A-Z]/);
	return matches.test(string);
};

const checkForLowerCase = (string) => {
	const matches = new RegExp(/[a-z]/);
	return matches.test(string);
};

const checkForSymbols = (string) => {
	const matches = new RegExp(/[@!#$%^&+=*]/);
	return matches.test(string);
};

export const checkPassword = (password, passwordConfirmation) => ({
	passwordsAreEquals: password === passwordConfirmation && !!password,
	containsNumbers: checkForNumbers(password),
	hasUppercase: checkForUpperCase(password),
	hasLowerCase: checkForLowerCase(password),
	containsSymbol: checkForSymbols(password),
	isPasswordLongEnough: password.length > 7,
});
