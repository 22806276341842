import React from "react";
import { translate } from "../../../../../providers";
import { CustomButton } from "../../../../buttons";
import GenericDialogFooter from "../../../../dialog/generic-dialog-footer/GenericDialogFooter";

const FavoriteCategoriesFooter = ({ isManaging, isWelcome, onBack, onCloseFavoriteCategories, onNext }) => {
	if (isManaging) {
		return (
			<GenericDialogFooter>
				<CustomButton variant="contained" onClick={onCloseFavoriteCategories}>
					{translate("common:btn.submit")}
				</CustomButton>
			</GenericDialogFooter>
		);
	}
	if (isWelcome) {
		return (
			<GenericDialogFooter>
				<CustomButton variant="contained" onClick={onNext}>
					{translate("common:btn.next")}
				</CustomButton>
			</GenericDialogFooter>
		);
	}
	return (
		<GenericDialogFooter>
			<CustomButton color="secondary" variant="outlined" onClick={onBack}>
				{translate("common:btn.back")}
			</CustomButton>
			<CustomButton variant="contained" onClick={onCloseFavoriteCategories}>
				{translate("common:favorite-category-dialog.footer.continue")}
			</CustomButton>
		</GenericDialogFooter>
	);
};

export default FavoriteCategoriesFooter;
