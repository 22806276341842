import client from "../../client/client";

class DeliverablesService {
	getPhasesTree({ projectId }, { filters, reqId }, cancelToken) {
		return client.post(`/smartview/deliverables/projects/${projectId}`, {
			payload: { ...filters, name: filters.name ? filters.name : undefined, reqId },
			config: { cancelToken },
		});
	}

	getDeliverablesCount({ projectId }, { filters, reqId }, cancelToken) {
		return client.post(`/smartview/deliverables/projects/${projectId}/count`, {
			payload: { ...filters, name: filters.name ? filters.name : undefined, reqId },
			config: { cancelToken },
		});
	}

	getPhasesTreeForFilter({ projectId }, { name }, cancelToken) {
		return client.get(`/smartview/deliverables/projects/${projectId}`, {
			params: { name },
			config: { cancelToken },
		});
	}

	getAllPhasesByProject({ projectId }, cancelToken) {
		return client.get(`/smartview/deliverables/projects/${projectId}/phases`, {
			config: { cancelToken },
		});
	}

	getAllDeliverablesByProjectAndPhase({ projectId, phaseId, reqId }, cancelToken) {
		return client.get(`/smartview/deliverables/projects/${projectId}/phase/${phaseId}/requirement/${reqId}`, {
			config: { cancelToken },
		});
	}

	getAllDeliverablesByProjectAndPhaseForFilter({ projectId, phaseId }, cancelToken) {
		return client.get(`/smartview/deliverables/projects/${projectId}/phase/${phaseId}`, {
			config: { cancelToken },
		});
	}

	getCoverageStatus({ reqId }, cancelToken) {
		return client.get(`/smartview/deliverables/requirements/${reqId}/coverage`, { config: { cancelToken } });
	}

	updateCoverageStatus({ reqId }, status, cancelToken) {
		return client.patch(`/smartview/deliverables/requirements/${reqId}/coverage`, {
			payload: { status },
			config: { cancelToken },
		});
	}

	selectDeliverables({ reqId, deliverableId }, cancelToken) {
		return client.post(`/smartview/deliverables/requirements/${reqId}/deliverables/${deliverableId}`, {
			config: { cancelToken },
		});
	}

	unselectDeliverables({ reqId, deliverableId }, cancelToken) {
		return client.delete(`/smartview/deliverables/requirements/${reqId}/deliverables/${deliverableId}`, {
			config: { cancelToken },
		});
	}

	getSelectedCount({ reqId }, cancelToken) {
		return client.get(`/smartview/deliverables/requirements/${reqId}/selected`, { config: { cancelToken } });
	}

	bulkAllocateDeliverables({ reqId }, { docOutToRemove, docOutToAdd }, cancelToken) {
		return client.post(`/smartview/deliverables/requirements/${reqId}`, {
			payload: { docOutToRemove, docOutToAdd },
			config: { cancelToken },
		});
	}
}

export default new DeliverablesService();
