import React, { useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import { hasPermission, translate } from "../../../../common/providers";
import TagListDialog from "./components/TagListDialog";
import UpdateQADatesDialog from "./components/UpdateQADatesDialog";
import { CustomDialog, CustomIconButton, icon } from "../../../../common/components";
import styles from "./QAActions.module.css";
import permissions from "../../../../common/providers/permissions/permissions";

export default function QAActions({
	tags,
	createNewTag,
	createQuestionTag,
	updateQaStatus,
	qaDetails,
	updateQaReceiveDate,
	updateQaSentDate,
	deleteQa,
	checkedQaCount,
	allChecked,
	onOpenTagDialog,
}) {
	const [openTagDialog, setOpenTagDialog] = useState(false);
	const [openDateDialog, setOpenDateDialog] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const handleOpenDeleteDialog = () => {
		setOpenDeleteDialog(true);
	};
	const handleOpenTagDialog = () => {
		onOpenTagDialog();
		setOpenTagDialog(true);
	};
	const handleOpenDateDialog = () => {
		setOpenDateDialog(true);
	};
	const handleCloseDialog = () => {
		setOpenTagDialog(false);
		setOpenDateDialog(false);
		setOpenDeleteDialog(false);
	};
	const handleUpdateStatus = (e) => {
		updateQaStatus(e.target.dataset.value);
	};
	const handleUpdateSentDate = (date) => {
		updateQaSentDate(date);
	};
	const handleUpdateReceiveDate = (date) => {
		updateQaReceiveDate(date);
	};
	const handleDeleteQa = () => {
		deleteQa();
		handleCloseDialog();
	};

	// fix me --> it has to come from backend
	const STATUSES = [
		{ name: translate("qa-journal.qa-filters.qa-filters.list-item.type.draft"), status: "DRAFT" },
		{ name: translate("qa-journal.qa-filters.qa-filters.list-item.type.waiting-for-reply"), status: "SENT" },
		{ name: translate("qa-journal.qa-filters.qa-filters.list-item.type.replied"), status: "ANSWERED" },
		{
			name: translate("qa-journal.qa-filters.qa-filters.list-item.type.from-competition"),
			status: "FROM_COMPETITION",
		},
		// { name: translate("qa-journal.qa-filters.qa-filters.list-item.type.trash"), status: "TRASH" },
	];

	return (
		<>
			<TextField
				select
				className={styles.statusInput}
				color="primary"
				defaultValue=""
				disabled={checkedQaCount === 0}
				label={translate("qa-journal.qa-actions.qa-actions.status.label")}
				size="small"
				variant="outlined"
			>
				{STATUSES &&
					STATUSES.map((stat) => (
						<MenuItem key={stat.status} value={stat.status} onClick={handleUpdateStatus}>
							{stat.name}
						</MenuItem>
					))}
			</TextField>
			{!hasPermission(permissions.PROJECT_MEMBER) && (
				<CustomIconButton
					disabled={!allChecked && checkedQaCount === 0}
					icon={icon.faTag}
					iconClassName={
						!allChecked && checkedQaCount === 0
							? styles["qAActionsButton__icon--disabled"]
							: styles["qAActionsButton__icon--active"]
					}
					onClick={handleOpenTagDialog}
				/>
			)}
			<CustomIconButton
				disabled={!allChecked && checkedQaCount === 0}
				icon={icon.faCalendarAlt}
				iconClassName={
					!allChecked && checkedQaCount === 0
						? styles["qAActionsButton__icon--disabled"]
						: styles["qAActionsButton__icon--active"]
				}
				onClick={handleOpenDateDialog}
			/>

			{hasPermission(permissions.PROJECT_LEADER) && (
				<CustomIconButton
					disabled={!allChecked && checkedQaCount === 0}
					icon={icon.faTrash}
					iconClassName={
						!allChecked && checkedQaCount === 0
							? styles["qAActionsButton__icon--disabled"]
							: styles["qAActionsButton__icon--active"]
					}
					onClick={handleOpenDeleteDialog}
				/>
			)}
			<TagListDialog
				close={handleCloseDialog}
				open={openTagDialog}
				tags={tags}
				onCreateNewTag={createNewTag}
				onCreateQuestionTag={createQuestionTag}
			/>
			<UpdateQADatesDialog
				close={handleCloseDialog}
				open={openDateDialog}
				qaDetails={qaDetails}
				onUpdateReceiveDate={handleUpdateReceiveDate}
				onUpdateSentDate={handleUpdateSentDate}
			/>
			<CustomDialog
				closeLabel={translate("common:btn.no")}
				maxWidth="xs"
				open={openDeleteDialog}
				submitLabel={translate("common:btn.yes")}
				subTitle={translate("qa-journal.qa-filters.confirm-dialog.content")}
				title={translate("qa-journal.qa-filters.confirm-dialog.title")}
				onClose={handleCloseDialog}
				onSubmit={handleDeleteQa}
			/>
		</>
	);
}
