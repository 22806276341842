import React, { useRef, useEffect, useState, useMemo } from "react";
import { Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RoleService, AdminService, ApiService } from "../../api";
import { IconComponent, ViewBanner, ViewTabPanel } from "../../common/components";
import { AnalyticsProvider, Flags, isFeatureEnabled, Permissions, translate } from "../../common/providers";
import { exportView } from "../../navigation";
import tabs from "./tabs/tabs";
import styles from "./Admin.module.css";
import { updateTreeBranch } from "../../common/utils";
import { setIsEditBu, setUpdateRelogCompanyList } from "../../common/slice";

function StyledTabs({ children, onChange, value }) {
	return (
		<Tabs
			classes={{
				root: styles.root,
				indicator: styles.indicator,
			}}
			textColor="primary"
			value={value}
			variant="fullWidth"
			onChange={onChange}
		>
			{children}
		</Tabs>
	);
}

const Admin = () => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [companies, setCompanies] = useState([]);
	const [companyRoles, setCompanyRoles] = useState([]);
	const [companyIdToUser, setCompanyIdToUser] = useState(new Map());
	const [companyIdToProject, setCompanyIdToProject] = useState(new Map());
	const cancelTokenSourceRef = useRef(null);
	const isEditBu = useSelector(({ context }) => context.isEditBu);
	const authTabs = useMemo(() => tabs.filter((tab) => (tab.flag ? isFeatureEnabled(tab.flag) : true)), []);
	const dispatch = useDispatch();
	useEffect(() => {
		const documentTitle = translate("admin.document.title");
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Admin" });
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (!Array.isArray(companies) || companies.length === 0 || isEditBu) {
			AdminService.getCompanyTree(cancelTokenSourceRef.current.token)
				.then((dataTree) => {
					setCompanies(dataTree);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					dispatch(setIsEditBu(false));
				});
		}
	}, [companies, isEditBu, dispatch]);
	useEffect(() => {
		if (!Array.isArray(companyRoles) || companyRoles.length === 0) {
			RoleService.listUserInCompany(cancelTokenSourceRef.current.token)
				.then((data) => {
					if (Array.isArray(data)) {
						const formattedData = data.map((d) => {
							const formatted = d?.toLowerCase().split("_").join(" ");
							return {
								name: d,
								displayName:
									formatted.substring(0, 1).toUpperCase() + formatted.substring(1, formatted.length),
							};
						});
						setCompanyRoles(formattedData);
					}
				})
				.catch((err) => console.error(err));
		}
	}, [companyRoles]);

	const getProjects = ({ company }) => {
		const companyIdToProjectMap = new Map();
		if (!Array.isArray(company.projects) || company.projects.length === 0) {
			AdminService.getCompanyProjects({ companyId: company.id }, cancelTokenSourceRef.current.token)
				.then((data) => {
					if (Array.isArray(data)) {
						companyIdToProjectMap.set(company.id, data);
						setCompanyIdToProject(() =>
							companyIdToProjectMap.get(companyIdToProjectMap.keys().next().value)
						);
					}
				})
				.catch((err) => console.error(err));
		}
	};
	const getUsers = ({ company }) => {
		const companyIdToUserMap = new Map();
		if (!Array.isArray(company?.users) || company?.users.length === 0) {
			AdminService.getCompanyUsers({ companyId: company.id }, cancelTokenSourceRef.current.token)
				.then((data) => {
					companyIdToUserMap.set(company.id, data);
					setCompanyIdToUser(() => companyIdToUserMap.get(companyIdToUserMap.keys().next().value));
				})
				.catch((err) => console.error(err));
		}
	};
	const editBuisnessUnit = ({ company, payload }) => {
		if (!company.id || !payload) {
			return;
		}
		AdminService.updateCompanyName(payload, cancelTokenSourceRef.current.token)
			.then((data) => {
				const updateTree = updateTreeBranch({
					tree: companies,
					idToReplace: data.parentId,
					newBranch: {
						...company,
						...data,
						children: (company.children || []).map((child) =>
							child.id === data.id ? { ...child, ...data } : child
						),
					},
					iterativeBranchName: "children",
				});
				setCompanies(updateTree);
				dispatch(setUpdateRelogCompanyList(true));
				dispatch(setIsEditBu(true));
			})
			.catch((err) => console.error(err));
	};
	const changeRole = ({ companyId, userId, role }) => {
		if (!companyId || !userId || !role) {
			return;
		}
		AdminService.updateRoleCompany({ companyId, userId }, { role }, cancelTokenSourceRef.current.token)
			.then(() => {
				const newUsers = [...companyIdToUser];
				const indexUser = newUsers.findIndex((u) => u.id === userId);
				newUsers[indexUser].companyRole = role;
				setCompanyIdToUser(newUsers);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const changeLockCompany = ({ companyId, userId, reason, isLocked = false }) => {
		if (!companyId || !userId) {
			return;
		}
		if (isLocked) {
			AdminService.unlockUser({ companyId, userId }, cancelTokenSourceRef.current.token)
				.then(() => {
					const newUsers = [...companyIdToUser];
					const indexUser = newUsers.findIndex((u) => u.id === userId);
					newUsers[indexUser].locked = false;
					setCompanyIdToUser(newUsers);
				})
				.catch((err) => console.error(err));
		} else {
			AdminService.lockUser({ companyId, userId }, reason, cancelTokenSourceRef.current.token)
				.then(() => {
					const newUsers = [...companyIdToUser];
					const indexUser = newUsers.findIndex((u) => u.id === userId);
					newUsers[indexUser].locked = true;
					setCompanyIdToUser(newUsers);
				})
				.catch((err) => console.error(err));
		}
	};
	const addUser = ({ companyId, payload }) => {
		if (!companyId || !payload) {
			return;
		}
		AdminService.createCompanyUser(payload, { companyId }, cancelTokenSourceRef.current.token)
			.then((data) => {
				const companyIdToUserNew = [...companyIdToUser, data];
				setCompanyIdToUser(companyIdToUserNew);
			})
			.catch((err) => console.error(err));
	};
	const renameProject = ({ companyId, projectId, name, status }) => {
		if (!companyId || !projectId) {
			return;
		}
		AdminService.updateProjectName(
			{ companyId, projectId },
			{ name, currentStatus: status },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				const newProject = [...companyIdToProject];
				const indexProject = newProject.findIndex((u) => u.id === projectId);
				newProject[indexProject].name = name;
				setCompanyIdToProject(newProject);
			})
			.catch((err) => console.error(err));
	};
	const archiveProject = ({ companyId, projectId, name, status }) => {
		if (!companyId || !projectId) {
			return;
		}
		AdminService.updateProjectStatus(
			{ companyId, projectId },
			{ name, currentStatus: status },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				const newProject = [...companyIdToProject];
				const indexProject = newProject.findIndex((u) => u.id === projectId);
				newProject[indexProject].currentStatus = status;
				setCompanyIdToProject(newProject);
			})
			.catch((err) => console.error(err));
	};
	const handleChange = (event, newTabIndex) => {
		setSelectedTabIndex(newTabIndex);
	};
	return (
		<div className={styles.container}>
			{Array.isArray(companies) && companies.length > 0 ? (
				<>
					<ViewBanner titles={[{ title: translate("admin.document.banner") }]} />
					<StyledTabs value={selectedTabIndex} onChange={handleChange}>
						{authTabs.map((i) => (
							<Tab
								key={i.tab.title}
								icon={<IconComponent color="inherit" icon={i.tab.icon} size="lg" />}
								label={<div>{translate(i.tab.title)}</div>}
							/>
						))}
					</StyledTabs>
					{authTabs.map((i, index) => (
						<ViewTabPanel
							key={i.tab.title}
							noPadding
							className={styles.container__tab}
							index={index}
							value={selectedTabIndex}
						>
							{i.panel({
								companies: companies || [],
								companyRoles,
								companyIdToUser,
								companyIdToProject,
								getProjects,
								getCompanyUsers: getUsers,
								editBuisnessUnit,
								onChangeRole: changeRole,
								onChangeLockCompany: changeLockCompany,
								onAddUser: addUser,
								onChangeArchive: archiveProject,
								onChangeName: renameProject,
							})}
						</ViewTabPanel>
					))}
				</>
			) : (
				<div className={styles.emptyState}>
					<span className={styles.emptyState__text}>
						{translate("admin.admin.empty-state.no-company-registered")}
					</span>
				</div>
			)}
		</div>
	);
};

export default exportView({
	path: "/admin",
	localesPath: "/admin/locales",
	component: Admin,
	flag: Flags.ADMIN,
	role: Permissions.COMPANY_ADMIN,
});
