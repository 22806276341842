import React, { useState, useEffect } from "react";
import CustomDialog from "../custom-dialog/CustomDialog";
import styles from "./ChatNameEditDialog.module.css";
import LimitedTextField from "../../limited-text-field/LimitedTextField";
import { translate } from "../../../providers";

const ChatNameEditDialog = ({ open, onClose, onSave, initialName }) => {
	const [name, setName] = useState(initialName);

	useEffect(() => {
		setName(initialName);
	}, [initialName]);

	const handleChange = (value) => {
		setName(value);
	};

	const handleCancel = () => {
		setName(initialName);
		onClose();
	};

	const handleSubmit = () => {
		onSave(name);
		onClose();
	};

	return (
		<CustomDialog
			isForm
			className={styles.chatNameDialog}
			open={open}
			submitLabel={translate("common:btn.save")}
			title={translate("chat-project.edit-chat-name.dialog")}
			onClose={handleCancel}
			onSubmit={handleSubmit}
		>
			<LimitedTextField
				fullWidth
				multiline
				containerClassName={styles.dialog__textField}
				label={translate("chat-project.edit-chat-name.textField")}
				maxLength={250}
				size="sm"
				value={name}
				variant="outlined"
				onChange={handleChange}
			/>
		</CustomDialog>
	);
};

export default ChatNameEditDialog;
