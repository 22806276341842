import { Box, ClickAwayListener, Fade, Paper, Popper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { getCSSVariable } from "../../utils/function-utils";

const useStyles = makeStyles(() => {
	const color = getCSSVariable("--color-white");
	return {
		popoverRoot: {
			backgroundColor: color,
			maxWidth: 400,
		},
		content: {
			padding: getCSSVariable("--spacing-xs"),
			maxWidth: 400,
		},
		popper: {
			zIndex: 1101,
			top: "8px",
			// eslint-disable-next-line
			'&[x-placement*="bottom"]': { top: "var(--spacing-s)" },
			// eslint-disable-next-line
			'&[x-placement*="bottom"] $arrow': {
				top: 0,
				left: 0,
				marginTop: "-0.71em",
				marginLeft: 4,
				marginRight: 4,
				"&::before": {
					transformOrigin: "0 100%",
				},
			},
			// eslint-disable-next-line
			'&[x-placement*="top"]': { bottom: "var(--spacing-s)" },
			// eslint-disable-next-line
			'&[x-placement*="top"] $arrow': {
				bottom: 0,
				left: 0,
				marginBottom: "-0.71em",
				marginLeft: 4,
				marginRight: 4,
				"&::before": {
					transformOrigin: "100% 0",
				},
			},
			// eslint-disable-next-line
			'&[x-placement*="right"]': { left: "var(--spacing-s)" },
			// eslint-disable-next-line
			'&[x-placement*="right"] $arrow': {
				left: 0,
				marginLeft: "-0.71em",
				height: "1em",
				width: "0.71em",
				marginTop: 4,
				marginBottom: 4,
				"&::before": {
					transformOrigin: "100% 100%",
				},
			},
			// eslint-disable-next-line
			'&[x-placement*="left"]': { right: "var(--spacing-s)" },
			// eslint-disable-next-line
			'&[x-placement*="left"] $arrow': {
				right: 0,
				marginRight: "-0.71em",
				height: "1em",
				width: "0.71em",
				marginTop: 4,
				marginBottom: 4,
				"&::before": {
					transformOrigin: "0 0",
				},
			},
		},
		arrow: {
			overflow: "hidden",
			position: "absolute",
			width: "1em",
			height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
			boxSizing: "border-box",
			color,
			"&::before": {
				// eslint-disable-next-line
				content: '""',
				margin: "auto",
				display: "block",
				width: "100%",
				height: "100%",
				boxShadow: getCSSVariable("--shadow-1"),
				backgroundColor: "currentColor",
				transform: "rotate(45deg)",
			},
		},
	};
});

const CustomPopOver = ({
	arrow = true,
	children,
	className = null,
	content,
	hovered,
	onClose = null,
	open,
	placement = "bottom",
}) => {
	const classes = useStyles();
	const [arrowRef, setArrowRef] = useState(null);
	const [childNode, setChildNode] = useState(null);
	const handleClose = () => {
		if (onClose && typeof onClose === "function") {
			onClose();
		}
	};
	const handleMouseLeave = () => {
		if (hovered) {
			handleClose();
		}
	};

	return (
		<div onMouseLeave={handleMouseLeave}>
			{React.cloneElement(children, { ...children.props, ref: setChildNode })}
			<Popper
				transition
				anchorEl={childNode}
				className={classes.popper}
				modifiers={[
					{
						name: "preventOverflow",
						enabled: true,
						options: {
							altAxis: true,
							altBoundary: true,
							tether: true,
							rootBoundary: "window",
						},
					},
					{ name: "arrow", enabled: true, options: { element: arrowRef } },
				]}
				open={open}
				placement={placement}
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper onMouseLeave={handleMouseLeave}>
							<ClickAwayListener onClickAway={handleClose}>
								<Paper className={`${classes.popoverRoot} ${className}`}>
									{arrow ? <span ref={setArrowRef} className={classes.arrow} /> : null}
									<Box className={classes.content}>{content}</Box>
								</Paper>
							</ClickAwayListener>
						</Paper>
					</Fade>
				)}
			</Popper>
		</div>
	);
};

export default CustomPopOver;
