import React, { useState } from "react";
import { SearchInput } from "../..";
import { translate } from "../../../providers";
import { CustomButton } from "../../buttons";
import { CircularLoader } from "../../loaders";
import FilterSearchRow from "./filter-search-row/FilterSearchRow";
import styles from "./FilterSearchList.module.css";
import { handleCheckInTree } from "../../../utils";

export default function FilterSearchList({
	defaultInputValue = "",
	emptyStateContent = "",
	filterKey = "",
	initChecked = [],
	isLoading = false,
	isTree,
	onChangeSearch,
	onClear = null,
	onClearSearch,
	onClickRow,
	onExpand,
	rowLabelKey,
	rowKey = rowLabelKey,
	rowSecondaryLabelKey = "",
	rows,
	searchPlaceholder,
	searchResultCount = null,
	subRowCounter = "",
	subRowKey = "",
	withRowLabelTooltip = false,
}) {
	const [checkedElements, setCheckedElements] = useState(initChecked);
	const [predecessors, setPredecessors] = useState([]);
	const [inputValue, setInputValue] = useState(defaultInputValue);
	const handleChangeSearch = (e) => {
		setInputValue(e.target.value);
		onChangeSearch(e);
	};
	const handleClearSearch = () => {
		setInputValue("");
		onClearSearch();
	};

	const handleResult = ({ newCheckedElements, newPredecessors }) => {
		setCheckedElements(newCheckedElements);
		setPredecessors(newPredecessors);
		const filterIds = newCheckedElements.filter((e) => !newPredecessors.some((ce) => ce.list.includes(e)));
		onClickRow(filterIds, filterKey);
	};

	const handleClick = (row) => {
		handleCheckInTree({
			row,
			rowKey,
			isTree,
			checkedElements,
			predecessors,
			handleResult,
		});
	};
	const handleClear = () => {
		setCheckedElements([]);
		onClear();
	};
	const isRowAncestor = ({ [rowKey]: identifier }) =>
		isTree && predecessors.some((element) => element.list.some((e) => e === identifier));
	const isRowChecked = ({ [rowKey]: identifier }) => checkedElements.some((element) => element === identifier);
	const isRowCheckedOrAncestor = (row) => isRowChecked(row) || isRowAncestor(row);
	const handleAddAncestor = (predecessor) => {
		setPredecessors((prev) => [...prev, predecessor]);
	};
	const renderRow = (row) => (
		<FilterSearchRow
			key={row[rowKey]}
			ancestors={[]}
			checkedElements={checkedElements}
			hasChildren={row.children && row.children.length > 0}
			isRowChecked={isRowCheckedOrAncestor}
			isTree={isTree}
			label={row[rowLabelKey]}
			row={row}
			rowKey={rowKey}
			rowLabelKey={rowLabelKey}
			secondaryLabel={row[rowSecondaryLabelKey]}
			subRowCounter={subRowCounter}
			subRowKey={subRowKey}
			withRowLabelTooltip={withRowLabelTooltip}
			onAddAncestor={handleAddAncestor}
			onClick={handleClick}
			onExpand={onExpand}
		/>
	);
	const renderRowsWithChildren = (treeRows) =>
		treeRows.map((row) => (
			<div key={row[rowKey]}>
				{renderRow(row)}
				{row.children && row.children.length > 0 && (
					<div className="children-container">{renderRowsWithChildren(row.children)}</div>
				)}
			</div>
		));

	return (
		<div className={styles.filter}>
			<div className={styles.filter__options}>
				{onClear && (
					<CustomButton
						className={styles.options__clear}
						color="primary"
						size="md"
						variant="text"
						onClick={handleClear}
					>
						{translate("common:btn.clear-select")}
					</CustomButton>
				)}
				{onChangeSearch && (searchResultCount || searchResultCount === 0) && (
					<span className={styles.options__results}>
						{translate(searchResultCount > 1 ? "common:results" : "common:result", {
							count: searchResultCount,
						})}
					</span>
				)}
			</div>
			{!!onChangeSearch && (
				<div className={styles.filter__search}>
					<SearchInput
						fullWidth
						isLoading={isLoading}
						placeholder={searchPlaceholder}
						value={inputValue}
						onChange={handleChangeSearch}
						onClearSearch={onClearSearch ? handleClearSearch : null}
					/>
				</div>
			)}
			{isLoading && (
				<div className={styles.filter__stateContainer}>
					<CircularLoader size={45} />
				</div>
			)}
			{!isLoading && Array.isArray(rows) && rows.length > 0 && renderRowsWithChildren(rows)}
			{emptyStateContent && !isLoading && (!Array.isArray(rows) || rows.length === 0) && (
				<div className={styles.filter__stateContainer}>
					<span className={styles.stateContainer__text}>{emptyStateContent}</span>
				</div>
			)}
		</div>
	);
}
