import React from "react";
import { CustomButton, icon, IconComponent, VerticalDivider } from "../../../../../common/components";
import {
	isSegFeatureEnabled,
	SegFlags,
	translate,
	translateEnumInformationAttribute,
} from "../../../../../common/providers";
import { capitalize, removeUnderscore } from "../../../../../common/utils";
import { CLASSIFICATION, CONTENT_TYPE, CATEGORIES } from "../../../utils/utils";
import ActionDropdown from "../action-dropdown/ActionDropdown";
import styles from "./ActionsRow.module.css";

export default function ActionsRow({ openDropdown, onToggle, onCloseDropdown, onOpenDialog, informationAttributes }) {
	const handleClick = () => {
		onToggle(CATEGORIES);
		onOpenDialog({ type: capitalize(removeUnderscore(CATEGORIES)) });
	};
	return (
		<>
			<VerticalDivider height={40} />
			<span className={styles["results--text"]}>{translate("smart-search.results.actions")}</span>
			<CustomButton color="secondary" variant="outlined" onClick={handleClick}>
				<IconComponent color="var(--color-light-grey-2)" icon={icon.faTag} size="sm" />
				<span>{translate("smart-search.results.categories")}</span>
			</CustomButton>
			<ActionDropdown
				items={informationAttributes.map((x) => ({
					displayName: translateEnumInformationAttribute(x),
					name: x,
					id: x,
				}))}
				open={openDropdown === CLASSIFICATION}
				title={translate(`smart-search.results.${CLASSIFICATION.replaceAll("_", "-")?.toLowerCase()}`)}
				type={capitalize(removeUnderscore(CLASSIFICATION))}
				onClose={onCloseDropdown}
				onOpenDialog={onOpenDialog}
				onToggle={() => {
					onToggle(CLASSIFICATION);
				}}
			/>
			{isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS) && (
				<CustomButton
					color="secondary"
					variant="outlined"
					onClick={() => {
						onOpenDialog({ type: CONTENT_TYPE });
					}}
				>
					<span>
						<IconComponent color="var(--color-light-grey-2)" icon={icon.faLayerGroup} size="sm" />
						<span className={styles["content--icon"]}>
							{translate(`smart-search.results.${CONTENT_TYPE.replaceAll("_", "-")?.toLowerCase()}`)}
						</span>
					</span>
				</CustomButton>
			)}
		</>
	);
}
