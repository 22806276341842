import React from "react";
import styles from "./ReqReviewsTable.module.css";
import { translate, translateEnumStatus } from "../../../../../../../common/providers";
import {
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableRow,
	HighlightedText,
} from "../../../../../../../common/components";
import { getStatusBgColor, getStatusColor } from "../../../../../../../common/utils";

export default function ReqReviewsTable({ reviews = [] }) {
	const mentionRegex = /(@\[[^\]]*\]\([0-9]*\))/gm;
	const infosRegex = /@\[([^\]]*)\]\(([0-9]*)\)/gm;
	const highlightTransform = (name) => {
		const infos = Array.from(name.matchAll(infosRegex));
		const displayName = infos[0][1];

		return `@${displayName}`;
	};

	return (
		<CustomTable dense>
			<CustomTableBody emptyState={translate("common:empty-state.none")}>
				{Array.isArray(reviews) &&
					reviews.map((row) => (
						<CustomTableRow
							key={row.reviewerName}
							className={styles.table_row}
							style={{ backgroundColor: getStatusBgColor(row.status) }}
						>
							<CustomTableCell
								autoTranslate="no"
								className={styles["table-cell--bold"]}
								component="th"
								scope="row"
								width="30%"
							>
								{row.reviewerName}
							</CustomTableCell>
							<CustomTableCell align="right" className={styles.positions__status}>
								<span
									className={styles.status}
									style={{ color: getStatusColor(row.status) }}
									translate="no"
								>
									{translateEnumStatus(row.status)}
								</span>
								{row.reviewerComment && (
									<>
										<div className={styles.position_splitter}> : </div>
										<HighlightedText
											className={styles.mention_name}
											highlightTransform={highlightTransform}
											regex={mentionRegex}
											textClassName={styles.mention_paragraph}
											value={row.reviewerComment}
										/>
									</>
								)}
							</CustomTableCell>
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
