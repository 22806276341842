import React from "react";
import { NavUtils } from "../../../../navigation/utils";
import { translate } from "../../../providers";
import { CustomButtonLink } from "../../links";
import styles from "./ErrorMessage.module.css";

export default function ErrorMessage({ title = "Error", content = "An error has been encountered" }) {
	return (
		<div className={styles.errorMessage__wrapper}>
			<header className={styles.errorMessage__header}>{title}</header>
			<main className={styles.errorMessage__body}>{content}</main>
			<footer className={styles.errorMessage__footer}>
				<CustomButtonLink fullWidth color="secondary" to={NavUtils.goToHome()} variant="outlined">
					{translate("common:error-boundary.button.back-to-home")}
				</CustomButtonLink>
			</footer>
		</div>
	);
}
