import React from "react";
import styles from "./DocumentRow.module.css";
import { CustomIconButton, IconComponent, TextMidCut, icon } from "../../../../../../../common/components";
import { convertSize } from "../../../../../utils/utils";
import { matchTypeIcon } from "../../../../../../../common/utils";

export default function DocumentRow({ document, onRemove }) {
	const { name, size, type } = document;
	return (
		<div className={styles.row}>
			<div className={styles.main}>
				<IconComponent icon={icon[matchTypeIcon({ name, type })]} />
				<div className={styles.name}>
					<TextMidCut fullWidth tooltip charsKeptInEnd={6} text={name} />
				</div>
				<span>{convertSize(size)}</span>
				<CustomIconButton
					icon={icon.faTimes}
					iconClassname={styles.button}
					iconColor="var(--color-dark-grey-1)"
					onClick={() => onRemove(document)}
				/>
			</div>
		</div>
	);
}
