import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { MenuItem, MenuList } from "@mui/material";
import RequirementVersionOverlay from "../requirement-version/RequirementVersionOverlay";
import styles from "./RequirementVersionGrouper.module.css";
import { CustomButton, DropDown } from "../../../../../../../../common/components";

const TOPGAP = -16;

const RequirementVersionGrouper = ({
	height,
	hoveredWarning,
	metadata,
	onOpen,
	onClose,
	opennedId,
	priority,
	setHoveredWarning,
	onSetPriority,
}) => {
	const anchorRef = useRef();
	const isSelecting = useSelector(({ srDocument }) => srDocument.isSelecting);
	const top = useMemo(() => height * ((metadata[0].rectangle.y1 + metadata[0].rectangle.y2) / 2), [height, metadata]);
	useEffect(() => {
		if (priority !== "multiple" && typeof onClose === "function") {
			onClose();
		}
	}, [priority, onClose]);
	return (
		(metadata.length <= 1 && (
			<div className={styles.versionGroupIcon} style={{ top: top + TOPGAP }}>
				<RequirementVersionOverlay
					key={metadata[0].informationId}
					hoveredWarning={hoveredWarning}
					metadata={metadata[0]}
					priority={priority}
					setHoveredWarning={setHoveredWarning}
					onClose={onClose}
					onSetPriority={onSetPriority}
				/>
			</div>
		)) || (
			<>
				<CustomButton
					ref={anchorRef}
					className={styles.versionGroupIcon}
					style={{ top: top + TOPGAP }}
					variant="outlined"
					onMouseOver={() => {
						if (!isSelecting) {
							onSetPriority("multiple");
							onOpen(metadata[0].informationId);
						}
					}}
				>
					{metadata.length}
				</CustomButton>
				<DropDown ref={anchorRef} open={metadata[0].informationId === opennedId} onClose={onClose}>
					<MenuList className={styles.dropdownList}>
						{metadata.map((md) => (
							<MenuItem className={styles.dropdownItem}>
								<RequirementVersionOverlay
									key={md.informationId}
									hoveredWarning={hoveredWarning}
									metadata={md}
									setHoveredWarning={setHoveredWarning}
									onSetPriority={onSetPriority}
								/>
							</MenuItem>
						))}
					</MenuList>
				</DropDown>
			</>
		)
	);
};
export default RequirementVersionGrouper;
