import { isInBetween, isNonEmptyArray, isNonEmptyObject } from "../../../common/utils";
import { REQ_STATUSES, ROLES } from "../constants/constants";

export const setNewChanges = (metadata, updates) => {
	const newChanges = {};
	updates.forEach((item) => {
		if (!item.callback) {
			newChanges[item.property] = item.value;
		} else {
			newChanges[item.property] = item.callback(metadata, item.parameters);
		}
	});
	return newChanges;
};

export const updateMetadataRequirement = ({ content, informationId, updates }) =>
	content.map((c) => (c.informationId === informationId ? { ...c, ...setNewChanges(c, updates) } : c));

export const samePageCondition = (i, { page }) => i === page;
export const borderedPageCondition = (i, { page, length }) =>
	i === page || (page - 1 > 0 && i === page - 1) || (page <= length && i === page + 1);
export const handleQuestionCountIncrement = (metadata) => metadata.questionAnswer + 1;
export const handleThematicCountIncrement = (metadata) => metadata.thematicCount + 1;
export const handleThematicCountDecrement = (metadata) => metadata.thematicCount * 0;
export const handleLinkTypeCountIncrement = (metadata, parameters) => {
	const { linkType } = parameters;
	if (!metadata.linkTypeCount) {
		return { [linkType]: 1 };
	}
	return (
		(isNonEmptyObject(metadata?.linkTypeCount) && {
			...metadata.linkTypeCount,
			[linkType]: (metadata.linkTypeCount[linkType] && metadata.linkTypeCount[linkType] + 1) || 1,
		}) ||
		metadata.linkTypeCount
	);
};
export const handleLinkTypeCountDecrement = (metadata, parameters) => {
	const { linkType } = parameters;
	const { [linkType]: currentLink, ...rest } = metadata.linkTypeCount;
	if (currentLink - 1 > 1) {
		return { ...metadata.linkTypeCount, [linkType]: metadata.linkTypeCount[linkType] - 1 };
	}
	if (isNonEmptyObject(rest)) {
		return rest;
	}
	return null;
};
export const updateMetadataNode = (content, { mode, informationId, updates, pageConditions }) => {
	const pageCondition = pageConditions?.border ? borderedPageCondition : samePageCondition;
	return (content || []).map((pageContent) =>
		pageCondition(pageContent.page, pageConditions)
			? {
					...pageContent,
					[mode]: updateMetadataRequirement({ content: pageContent[mode], informationId, updates }),
			  }
			: pageContent
	);
};
export const handleTaskHistorySlice = (metadata, parameters) => {
	if (metadata.taskHistory) {
		const temp = [...metadata.taskHistory];
		parameters.tasks.forEach((newTask) => {
			const id = temp.findIndex((oldTask) => oldTask.id === newTask.id);
			if (id > -1) {
				temp[id] = newTask;
			} else {
				temp.push(newTask);
			}
		});
		return temp;
	}
	return parameters.tasks;
};

export const handleRedressCheck = (state, order) => {
	const { redressingOrders } = state;
	const redressingStart = Math.min(...state.redressList.filter((x) => x.checked).map((x) => x.order));
	const redressingEnd = Math.max(...state.redressList.filter((x) => x.checked).map((x) => x.order));
	const isBetweenStartAndMin = redressingStart < order && order <= redressingOrders.start;
	const isBetweenMaxAndEnd = redressingEnd > order && order >= redressingOrders.end;
	const isBetweenMinAndMax = isInBetween(order, redressingOrders.start, redressingOrders.end, true, true);
	if (order <= redressingStart || isBetweenStartAndMin) {
		return {
			...state,
			redressList: state.redressList.map((x) => ({
				...x,
				checked: isInBetween(
					x.order,
					order,
					redressingEnd,
					isBetweenStartAndMin ? false : order !== redressingStart,
					true
				),
			})),
		};
	}
	if (order >= redressingEnd || isBetweenMaxAndEnd || isBetweenMinAndMax) {
		return {
			...state,
			redressList: state.redressList.map((x) => ({
				...x,
				checked: isInBetween(
					x.order,
					redressingStart,
					order,
					true,
					order < redressingEnd ? false : order !== redressingEnd
				),
			})),
		};
	}
	return state;
};

export const handleFreezeValRequirement = (metadata) => !metadata.isFrozen;

const getCounter = (status) => {
	if (status.toLowerCase() === REQ_STATUSES.UNREVIEWED.toLowerCase()) {
		return "newCounter";
	}
	if (status === REQ_STATUSES.NOT_ME) {
		return null;
	}
	return `${status.toLowerCase()}Counter`;
};
export const updateCounters = (counters, payload) => {
	const newCounter = getCounter(payload.status);
	const oldCounter = getCounter(payload.oldStatus);
	return {
		...counters,
		[newCounter]: newCounter ? counters[newCounter] + 1 : counters[newCounter],
		[oldCounter]: oldCounter ? counters[oldCounter] - 1 : counters[oldCounter],
	};
};

export const updateTocNodes = (clauses, payload) => {
	if (clauses.length > 0) {
		const updatedClausePageNumber = [...clauses]
			.reverse()
			.find((clause) => clause.pageNumber <= payload.pageStart)?.pageNumber;
		return (clauses || []).map((clause) =>
			updatedClausePageNumber === clause.pageNumber
				? {
						...clause,
						...updateCounters(clause, payload),
						subClauses: updateTocNodes(clause.subClauses, payload),
				  }
				: clause
		);
	}
	return clauses;
};

export const checksForCounterUpdate = (updates) => {
	if (!Array.isArray(updates) || updates.length === 0) {
		return false;
	}
	return updates.some(
		(update) =>
			update.property === "lastStatus" ||
			update.property === "sentenceType" ||
			update.property === "validatedSentenceType" ||
			update.property === "validatedStatus" ||
			update.property === "commentsCount"
	);
};

export const updateToc = (clauses, payload, clauseId) => {
	if (payload?.length > 0) {
		return (clauses || []).map((clause) =>
			clauseId === clause.id
				? {
						...clause,
						subClauses: payload,
				  }
				: { ...clause, subClauses: updateToc(clause.subClauses, payload, clauseId) }
		);
	}
	return clauses;
};

export const getMode = ({ roles, role, mode }) =>
	(isNonEmptyArray(roles) && roles.some((r) => ROLES[r].mode === role) && role) ||
	(isNonEmptyArray(roles) &&
		roles.length === 1 &&
		roles.some((r) => ROLES[r].mode === ROLES.EDITOR.mode) &&
		role === ROLES.REVIEWER.mode &&
		role) ||
	(isNonEmptyArray(roles) &&
		roles.length === 1 &&
		roles.some((r) => ROLES[r].mode === ROLES.VALIDATOR.mode) &&
		role === ROLES.REVIEWER.mode &&
		ROLES.VALIDATOR.mode) ||
	(isNonEmptyArray(roles) &&
		((roles.some((r) => ROLES[r].mode === mode) && mode) ||
			(roles.includes(ROLES.REVIEWER.name.toUpperCase()) && ROLES.REVIEWER.mode) ||
			(roles.includes(ROLES.VALIDATOR.name.toUpperCase()) && ROLES.VALIDATOR.mode) ||
			ROLES?.[roles[0]]?.mode)) ||
	ROLES.REVIEWER.mode;

const updateValue = ({ propName, oldValue, value }) => (propName === "open" && !oldValue) || value;
export const updateTocNode = (clauses, clauseId, { propName, value }) =>
	(Array.isArray(clauses) &&
		clauses.map(
			(clause) =>
				(clause.id === clauseId && {
					...clause,
					[propName]: updateValue({ propName, oldValue: clause[propName], value }),
				}) || { ...clause, subClauses: updateTocNode(clause.subClauses, clauseId, { propName, value }) }
		)) ||
	clauses;

export const fillRoles = (roles) =>
	Object.entries(ROLES)
		.filter(([key]) => key !== "READ")
		.map(([key, { name }]) => roles.find((r) => r.role === key) || { value: name, role: key, count: 0 });

export function findBooleanPropertyInTree(object, property, subProperty) {
	if (object[property] === true) {
		return true;
	}
	if (isNonEmptyArray(object[subProperty])) {
		for (const sub of object[subProperty]) {
			if (findBooleanPropertyInTree(sub, property, subProperty)) {
				return true;
			}
		}
	}
	return false;
}
export function findBooleanPropertyInTreeArray(trees, property, subProperty) {
	for (const tree of trees) {
		if (findBooleanPropertyInTree(tree, property, subProperty)) {
			return true;
		}
	}
	return false;
}
