import { isNonEmptyObject } from "../../../../../common/utils";

export const getFinalCoordinates = (metadata, pageWidth, pageHeight) => {
	const { x1, x2, y1, y2 } = metadata;
	return {
		left: pageWidth * x1 - 5,
		top: pageHeight * y1 - 5,
		width: pageWidth * (x2 - x1) + 10,
		height: pageHeight * (y2 - y1) + 10,
	};
};

export const renderPositionStyle = (metadata, pageWidth, pageHeight) =>
	getFinalCoordinates(metadata, pageWidth, pageHeight);

export function isOverlap(rect1, rect2) {
	if (!rect1 || !rect2) {
		return false;
	}
	return rect1.left < rect2.right && rect1.right > rect2.left && rect1.top < rect2.bottom && rect1.bottom > rect2.top;
}

export function createSegments(arr) {
	const segments = [];
	for (let i = 0; i < arr.length; i++) {
		const start = arr[i];
		const end = i < arr.length - 1 ? arr[i + 1] : null;
		segments.push([start, end]);
		if (end === null) {
			break;
		}
	}
	return segments;
}

export function drawSelectionBox(boxElement, start, end) {
	const b = boxElement;
	if (end.left > start.left) {
		b.style.left = `${start.left}px`;
		b.style.width = `${end.left - start.left}px`;
	} else {
		b.style.left = `${end.left}px`;
		b.style.width = `${start.left - end.left}px`;
	}
	if (end.top > start.top) {
		b.style.top = `${start.top}px`;
		b.style.height = `${end.top - start.top}px`;
	} else {
		b.style.top = `${end.top}px`;
		b.style.height = `${start.top - end.top}px`;
	}
}

/** Find the type of element where  */
export const findMatchingImgElement = (element, tagName, elementProp, name) => {
	while (element) {
		if (element.tagName === tagName && element[elementProp].startsWith(name)) {
			return element;
		}
		// eslint-disable-next-line
		element = element.parentElement;
	}
	return null;
};
/** Assert multiple elements by class on click event */
export const containsClassNames = (element, classNamePrefixes) => {
	while (element) {
		if (
			element.classList &&
			Array.from(element.classList).some((className) =>
				classNamePrefixes.some((prefix) => className.startsWith(prefix))
			)
		) {
			return true;
		}
		// eslint-disable-next-line
		element = element.parentElement;
	}
	return false;
};

export const getImageKeyFromAlt = (element) => {
	/** The mouse is over an <img> with an alt attribute matching the format
	 * 	Extract the key from the alt attribute */
	if (!isNonEmptyObject(element)) {
		return null;
	}
	const [, foundKey] = (element && element.alt.split("_")) || null;
	return foundKey;
};

export const currentPageFromMouse = (pages, top, zoom) =>
	/** From a set of pages with height and top
	 * and a mouse top position within a large vertical area
	 * we determine the current page */
	(pages.findIndex((page) => {
		const pageTop = (page.top * zoom) / 100 || 0;
		const pageBottom = pageTop + (page.height * zoom) / 100;
		return top >= pageTop && top <= pageBottom;
	}) || 0) + 1;
