import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { translate } from "../../../../../providers";
import { debounce } from "../../../../../utils";
import { AutocompleteTextField } from "../../../../autocomplete-text-field";
import { ApiService, PdfViewService } from "../../../../../../api";
import styles from "./FilterSearch.module.css";
import { SearchInput } from "../../../../text-fields";

const debouncedFunction = debounce((func) => func(), 500);

export default function FilterSearch({
	noSearch = false,
	autocomplete = false,
	className = "",
	debounceSearch = true,
	fullWidth,
	label = "",
	placeholder = null,
	search,
	onSearch,
	onKeyDown,
}) {
	const mySearchProjectId = useSelector(({ context }) => context?.project?.id);
	const searchTemplateProjectId = useSelector(({ mySearchTemplate }) => mySearchTemplate?.selectedProject?.id);
	const [searchValue, setSearchValue] = useState(search);
	useEffect(() => {
		setSearchValue(search);
	}, [search]);
	const handleResetSearchText = () => {
		setSearchValue("");
		onSearch();
	};
	const handleChangeSearchText = (event, newValue, newPlainTextValue, mentions) => {
		if (mentions?.length > 0) {
			setSearchValue(newPlainTextValue);
			onSearch(newPlainTextValue);
		} else {
			const { value } = event.target;
			setSearchValue(value);
			if (debounceSearch) {
				debouncedFunction(() => {
					onSearch(value);
				});
			} else {
				onSearch(value);
			}
		}
	};
	const cancelTokenSourceRef = useRef();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const inputRefFTS = useRef();
	const getFTSSuggestions = (query, callback) => {
		debouncedFunction(() => {
			if (noSearch) {
				return [];
			}
			if (query?.length < 2) {
				return [];
			}
			let projectId;
			if (mySearchProjectId) {
				projectId = mySearchProjectId;
			} else if (searchTemplateProjectId) {
				projectId = searchTemplateProjectId;
			}
			PdfViewService.autoComplete({ projectId }, query, cancelTokenSourceRef.current.token).then((d) => {
				callback(d.map((word) => ({ id: word, display: word })));
			});
			return [];
		}, 500);
	};
	const handleKeyDownCapture = (e) => {
		if (e.repeat) {
			return;
		}
		if (e.key === "Enter") {
			e.preventDefault();
			onKeyDown(e);
		}
	};
	return (
		(autocomplete && (
			<AutocompleteTextField
				ref={inputRefFTS}
				className={className}
				classNames={styles}
				data={getFTSSuggestions}
				fullWidth={fullWidth}
				label={label}
				placeholder={placeholder === null ? translate("common:btn.search") : placeholder}
				trigger=":"
				value={searchValue}
				onChange={handleChangeSearchText}
				onClearSearch={handleResetSearchText}
				onKeyDown={onKeyDown}
				onKeyDownCapture={handleKeyDownCapture}
			/>
		)) || (
			<SearchInput
				className={className}
				fullWidth={fullWidth}
				label={label}
				placeholder={placeholder === null ? translate("common:btn.search") : placeholder}
				value={searchValue}
				onChange={handleChangeSearchText}
				onClearSearch={handleResetSearchText}
				onKeyDown={onKeyDown}
			/>
		)
	);
}
