import client from "../../client/client";

class NormsService {
	getStatus(cancelToken) {
		return client.get("/norms/status", { config: { cancelToken } });
	}

	getTypes(cancelToken) {
		return client.get("/norms/types", { config: { cancelToken } });
	}

	getAll(cancelToken, { name, date, page = 0, limit = 25 } = {}) {
		return client.get("/norms", { params: { name, date, page, limit }, config: { cancelToken } });
	}

	get({ normId }, cancelToken) {
		return client.get(`/norms/${normId}`, { config: { cancelToken } });
	}

	getVersions({ normId }, cancelToken) {
		return client.get(`/norms/${normId}/versions`, { config: { cancelToken } });
	}

	addVersion({ normId, newVersionId }, cancelToken) {
		return client.patch(`/norms/${normId}/versions/${newVersionId}`, { config: { cancelToken } });
	}

	delete({ normId }, cancelToken) {
		return client.delete(`/norms/${normId}`, { config: { cancelToken } });
	}
}

export default new NormsService();
