import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import styles from "./DebounceSearchTextField.module.css";
import { CustomIconButton, IconComponent, icon } from "../..";
import { debounce } from "../../../utils";

const debouncedFunction = debounce((func) => func(), 500);

const DebounceSearchTextField = ({
	className = "",
	isLoading = false,
	onChange,
	initValue,
	label = "",
	onBlur,
	onFocus,
	placeholder = "",
	size = "small",
}) => {
	const [localValue, setLocalValue] = useState(initValue);

	const handleChangeSearchText = (e) => {
		const { value } = e.target;
		setLocalValue(value);
		debouncedFunction(() => {
			onChange(value);
		});
	};
	const handleResetSearchText = () => {
		setLocalValue("");
		onChange("");
	};
	return (
		<TextField
			autoFocus
			className={`${styles.search} ${className}`}
			InputProps={{
				className: styles.textField,
				endAdornment: (
					<InputAdornment position="end">
						{isLoading && <CircularProgress size={20} thickness={3} />}
						{!isLoading && !localValue && <IconComponent icon={icon.faSearch} />}
						{!isLoading && localValue && (
							<CustomIconButton icon={icon.faTimesCircleRegular} onClick={handleResetSearchText} />
						)}
					</InputAdornment>
				),
			}}
			label={label}
			placeholder={placeholder}
			size={size}
			value={localValue}
			variant="outlined"
			onBlur={onBlur}
			onChange={handleChangeSearchText}
			onFocus={onFocus}
		/>
	);
};

export default DebounceSearchTextField;
