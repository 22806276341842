import React from "react";
import { Checkbox } from "@mui/material";
import { HorizontalDivider, I18nTranslate } from "../../..";
import { translate } from "../../../../providers";
import styles from "./TemplateListHeader.module.css";

export default function TemplateListHeader({
	allChecked,
	templatesCount,
	uncheckedTemplates,
	checkedTemplates,
	onCheckAll,
}) {
	return (
		<>
			<div className={styles.header}>
				<Checkbox
					checked={
						(allChecked && uncheckedTemplates.length === 0) ||
						(templatesCount !== 0 && templatesCount === checkedTemplates.length)
					}
					color="primary"
					onChange={onCheckAll}
				/>
				{((allChecked || checkedTemplates.length > 0) && (
					<div>
						<I18nTranslate
							param={{
								templatesCount,
								selectionCount: allChecked
									? templatesCount - uncheckedTemplates.length
									: checkedTemplates.length,
							}}
							translationKey="common:add-template-panel.templates-count.selection"
						/>
						<span>&nbsp;|&nbsp;</span>
						<span>{translate("common:add-template-panel.actions")}</span>
					</div>
				)) || (
					<span>
						{translate("common:add-template-panel.templates-count", {
							templatesCount,
						})}
					</span>
				)}
			</div>
			<HorizontalDivider />
		</>
	);
}
