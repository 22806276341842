import React, { useState } from "react";
import { DialogTitle, DialogContent, TextField, DialogActions } from "@mui/material";
import { CustomButton, CustomIconButton, GenericDialog, icon } from "../../../../common/components";
import styles from "./SingleFieldDialog.module.css";
import { translate } from "../../../../common/providers";

export default function SingleFieldDialog({ label = "", field, fieldValue = null, onClose, onSubmit, open, title }) {
	const [error, setError] = useState(false);
	const [payload, setPayload] = useState(fieldValue);
	const changePayload = (value) => {
		setPayload(value);
	};
	const reset = () => {
		setError(false);
		setPayload("");
	};
	const handleCloseAndSubmit = () => {
		const err = !(payload && payload.length > 2);
		if (!err) {
			onSubmit({ [field]: payload });
			onClose();
		}
		setError(err);
	};
	return (
		<GenericDialog disableBackdropClick fullWidth open={open} onClose={onClose} onExited={reset}>
			<DialogTitle>
				<div className={styles.dialog__title}>
					<span className={styles["dialog__title-text"]}>{title}</span>
					<CustomIconButton icon={icon.faTimes} onClick={onClose} />
				</div>
			</DialogTitle>
			<DialogContent dividers className={styles.dialog__content}>
				<div>
					<TextField
						required
						className={styles.dialog__field}
						error={error}
						helperText={error && "Required"}
						label={label}
						style={{ marginBottom: "8px" }}
						value={payload || ""}
						variant="outlined"
						onChange={(e) => changePayload(e.target.value)}
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<CustomButton color="primary" onClick={onClose}>
					{translate("common:btn.cancel")}
				</CustomButton>
				<CustomButton color="primary" disabled={!payload} onClick={handleCloseAndSubmit}>
					{translate("common:btn.submit")}
				</CustomButton>
			</DialogActions>
		</GenericDialog>
	);
}
