import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CustomIconButton, CustomTableCell, CustomTableRow, icon } from "../../../../../../../common/components";
import { translate } from "../../../../../../../common/providers";
import { setDisableKeyEvents } from "../../../../../../../common/slice";
import styles from "./AdditionalParameterRow.module.css";

export default function AdditionalParameterRow({
	hasUpdateRights = false,
	isCreating = false,
	isParameterIncluded,
	isSelected = false,
	isUnconditional,
	onCancelEdit,
	onConfirmEdit,
	onCreate,
	onDelete,
	onInitiateEdit,
	onNewParameter,
	row,
	selected,
}) {
	const [editedParam, setEditedParam] = useState(row || "");
	const dispatch = useDispatch();
	const handleEdit = (e, paramName) => {
		const { value } = e.target;
		const newValue = { ...editedParam };
		newValue[paramName] = value;
		setEditedParam(newValue);
	};
	useEffect(() => {
		if (isCreating || isSelected) {
			onNewParameter(editedParam);
		}
	}, [editedParam, isCreating, isSelected, onNewParameter]);
	const unconditional = isUnconditional(editedParam.name);
	const parameterIncluded = isParameterIncluded(editedParam.name);
	let nameErrorMessage = "";
	if (editedParam.name && editedParam.name.length < 2) {
		nameErrorMessage = translate("common:error.required-with-min-data", { min: 2 });
	} else if (unconditional) {
		nameErrorMessage = translate("smart-review.additional-parameters.error.invalid-name");
	} else if (parameterIncluded && editedParam.name !== selected.name) {
		nameErrorMessage = translate("smart-review.additional-parameters.error.name-in-use");
	}
	return (
		<CustomTableRow hover>
			{({ isHovering }) => (
				<>
					<CustomTableCell>
						{(isSelected && selected.name === row.name) || !row.name ? (
							<TextField
								error={!!nameErrorMessage}
								label={nameErrorMessage}
								margin="dense"
								value={editedParam.name}
								variant="outlined"
								onBlur={() => dispatch(setDisableKeyEvents(false))}
								onChange={(event) => handleEdit(event, "name")}
								onFocus={() => dispatch(setDisableKeyEvents(true))}
							/>
						) : (
							row.name
						)}
					</CustomTableCell>
					<CustomTableCell>
						{(isSelected && selected.name === row.name) || row.name === "" ? (
							<TextField
								margin="dense"
								value={editedParam.value}
								variant="outlined"
								onChange={(event) => handleEdit(event, "value")}
							/>
						) : (
							row.value
						)}
					</CustomTableCell>
					<CustomTableCell align="right" className={styles.lastCustomTableCell}>
						{!isCreating && row.name && hasUpdateRights && isHovering && !isSelected && (
							<CustomIconButton
								icon={icon.faPen}
								iconColor="var(--color-blue)"
								tooltip={translate("common:btn.edit")}
								onClick={() => onInitiateEdit(row)}
							/>
						)}
						{!isCreating && row.name && hasUpdateRights && isHovering && !isSelected && (
							<CustomIconButton
								icon={icon.faTrash}
								iconColor="var(--color-red)"
								tooltip={translate("common:btn.delete")}
								onClick={() => onDelete(row)}
							/>
						)}
						{(!row.name || (isSelected && selected.name === row.name)) && (
							<CustomIconButton
								disabled={!editedParam.name || nameErrorMessage || !editedParam.value}
								icon={icon.faCheck}
								iconColor={
									!editedParam.name || nameErrorMessage || !editedParam.value
										? "var(--color-light-grey-3)"
										: "var(--color-blue)"
								}
								tooltip={translate("common:btn.confirm")}
								onClick={isSelected ? () => onConfirmEdit(editedParam) : () => onCreate(editedParam)}
							/>
						)}
						{(!row.name || (isSelected && selected.name === row.name)) && (
							<CustomIconButton
								icon={icon.faTimes}
								iconColor="var(--color-red)"
								tooltip={translate("common:btn.cancel")}
								onClick={onCancelEdit}
							/>
						)}
					</CustomTableCell>
				</>
			)}
		</CustomTableRow>
	);
}
