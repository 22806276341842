import React, { useState, useEffect } from "react";
import {
	LimitedTextField,
	SidePanel,
	SidePanelContent,
	OptionsPanelContainer,
} from "../../../../../../common/components";
import { translate } from "../../../../../../common/providers";

const CommentAddSidePanel = ({
	oldComment = null,
	onActive = null,
	onAddComment,
	onClose,
	onEditComment,
	open = false,
}) => {
	const [newComment, setNewComment] = useState("");

	useEffect(() => {
		if (oldComment) {
			setNewComment(oldComment);
		} else {
			setNewComment("");
		}
	}, [oldComment, open]);

	const handleComment = (value) => {
		setNewComment(value);
	};
	const handleClick = () => {
		if (newComment.length > 1) {
			if (oldComment) {
				onEditComment(newComment, oldComment);
			} else {
				onAddComment(newComment);
			}
			onClose();
		}
	};
	return (
		<SidePanel
			disableBackdropClick
			disableEscapeKeyDown
			open={open}
			size={38}
			onActive={onActive}
			onClose={onClose}
		>
			<SidePanelContent
				title={
					(oldComment?.length > 0 && translate("gonogo.details.comment-synthesis.edit-comment")) ||
					translate("gonogo.details.comment-synthesis.add-comment")
				}
				onClose={onClose}
			>
				<OptionsPanelContainer
					buttonText={translate("common:btn.save")}
					buttonTextSecondary={translate("common:btn.cancel")}
					disabledPrimaryButton={newComment?.length < 2}
					title={translate("gonogo.details.comment.side-panel.title")}
					onClick={handleClick}
					onClickSecondary={onClose}
				>
					<LimitedTextField
						fullWidth
						multiline
						data-testid="gonogo.add.comment"
						maxLength={512}
						placeholder={translate("gonogo.details.comment.side-panel.placeholder")}
						rows={4}
						value={newComment}
						onChange={handleComment}
					/>
				</OptionsPanelContainer>
			</SidePanelContent>
		</SidePanel>
	);
};

export default CommentAddSidePanel;
