import React from "react";
import { Collapse } from "@mui/material";
import styles from "./EditReqDialog.module.css";
import { CustomIconButton, icon, SearchListContent } from "../../../../../common/components";

export default function CategorySection({
	categories,
	collapsed,
	onChange,
	onCollaspe,
	onExpand,
	selectedCategories,
	title,
}) {
	const handleCheckRow = (newCheckedElements) => onChange(newCheckedElements);

	return (
		<section className={styles["dialog-content__section"]}>
			<span className={styles["dialog-content__section-title"]}>
				<CustomIconButton icon={collapsed ? icon.faChevronDown : icon.faChevronRight} onClick={onCollaspe} />
				{title}
			</span>
			<Collapse unmountOnExit in={collapsed} timeout="auto">
				{Array.isArray(categories) && (
					<SearchListContent
						isTree
						parentCheckedElements={selectedCategories || []}
						rowKey="id"
						rowLabelKey="name"
						rows={categories}
						rowTooltipKey="name"
						subRowCounter="childrenCount"
						subRowKey="subThematic"
						onClickRow={handleCheckRow}
						onExpand={onExpand}
					/>
				)}
			</Collapse>
		</section>
	);
}
