import { CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiService, MyService, PublicService, UserService } from "./api";
import { useApi, useEffectOnce } from "./common/hooks";
import { loadTranslation } from "./common/providers";
import { AppRoutes, NavbarContainer, NavUtils } from "./navigation";
import KeycloakService from "./services/KeycloakService";
import { ErrorBoundary } from "./common/components";
import { setHasEntered, setCompanies } from "./common/slice/contextSlice";
import permissions from "./common/providers/permissions/permissions";

const environment = window.env.REACT_APP_ENV || "dev";

const Loader = () => (
	<div style={{ margin: "auto" }}>
		<CircularProgress color="primary" />
	</div>
);

const hasAdminClaim = () => KeycloakService.hasRole([permissions.REALM.ADMIN.claim]);

const Refresh = ({ children }) => {
	const isLogged = KeycloakService.isLoggedIn();
	const user = useSelector(({ context }) => context.user);
	const loginCompanies = useSelector(({ context }) => context.companies);
	const language = useSelector(({ context }) => context.language);
	const location = useLocation();
	const cancelTokenSourceRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { call: getProjectDetails } = useApi(MyService.getProjectDetails, 1000);
	const { call: getAllUserCompanies } = useApi(UserService.getAllUserCompanies, 1000);

	const { isLoading: isRefreshing } = useApi(PublicService.refreshToken);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);

	useEffectOnce(
		/* As in First login, gets companies to decide which access */
		() =>
			getAllUserCompanies()
				.then((companies) => dispatch(setCompanies(companies?.data || [])))
				.catch(() => {
					setTimeout(() => {
						KeycloakService.doLogout().then(() => {
							KeycloakService.clearToken();
						});
					}, 3000);
				}),
		[getAllUserCompanies, loginCompanies, dispatch],
		() => !loginCompanies
	);

	useEffect(
		() => {
			const isInProject = location.pathname.includes("/projects");
			const indexProject = location.pathname.split("/").findIndex((x) => x === "projects") + 1;
			const projectId = parseInt(location.pathname.split("/")[indexProject], 10);
			if (isInProject && !Number.isNaN(projectId)) {
				getProjectDetails({ projectId }).catch(() => {
					window.location.href = "/";
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[location]
	);

	useEffectOnce(
		/* If user has ADMIN Claim, then he's automatically redirected to DSI */
		() => {
			if (hasAdminClaim()) {
				navigate(NavUtils.goToDSI());
				dispatch(setHasEntered());
			}
		},
		[],
		() => hasAdminClaim()
	);

	useEffect(() => {
		if (
			environment &&
			user &&
			user?.id &&
			user?.email &&
			user?.displayName &&
			window.location.hostname !== "localhost"
		) {
			let userguidingUserId = `${environment}#${user.id}#${user.email}`;
			if (environment === "dev") {
				userguidingUserId = "dev";
			}
			window.userGuiding.identify(userguidingUserId, {
				name: user.displayName,
				email: user.email,
				instance: environment,
				is_active: true,
				language: language.value,
			});
		}
	}, [user, language]);

	return !isLogged && isRefreshing ? <Loader /> : children;
};

const I18n = ({ children }) => {
	const [render, setRender] = useState(false);
	const { value } = useSelector(({ context }) => context.language);
	const firstTimeLogin = useSelector(({ context }) => context.firstTimeLogin);
	const mountedRef = useRef(false);
	const locale = KeycloakService.userLanguage();
	const language = firstTimeLogin ? locale : value;
	useEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	}, []);
	useEffect(() => {
		loadTranslation(language)
			.then(() => (mountedRef.current ? setRender(true) : null))
			.catch((err) => console.error(err));
		return () => (mountedRef.current ? setRender(false) : null);
	}, [language]);
	return render ? children : <Loader />;
};

export default function Root() {
	const { hasEntered } = useSelector(
		({ context }) => ({
			hasEntered: context.hasEntered,
		}),
		shallowEqual
	);
	return (
		<Refresh>
			<I18n>
				<ErrorBoundary>
					{hasEntered && <NavbarContainer />}
					<AppRoutes />
					<NotificationContainer />
				</ErrorBoundary>
			</I18n>
		</Refresh>
	);
}
