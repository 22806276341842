import React, { useEffect, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import styles from "./DashboardFilters.module.css";
import {
	cleanFilters,
	CommonFilters,
	FiltersWrapper,
	generateFilters,
	ProjectCategoriesFilter,
	KeywordFilter,
} from "../../../../../../common/components";
import DashboardContext from "../../utils/dashboard-context";
import { setFilters, getReviewDocuments, getCompanyDocuments, getDocumentDetails } from "../../slice/dashboard-slice";
import { ApiService, DashboardService, RequirementMatrixService } from "../../../../../../api";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../../../common/providers";
import { generatePayload } from "../../../../../my-review/utils/utils";

export default function DashboardFilters({ details = false }) {
	const [parameters, setParameters] = useState({});
	const [filtersPreviewRequest, setFiltersPreviewRequest] = useState(null);
	const dispatch = useDispatch();
	const additionalFilters = useSelector(({ dashboard }) => dashboard.additionalFilters);
	const dashboardType = useSelector(({ dashboard }) => dashboard.dashboardType);
	const filters = useSelector(({ dashboard }) => dashboard.filters);
	const projectId = useSelector(({ context }) => context.project?.id);
	const companyId = useSelector(({ context }) => context.company?.id);
	const tokenSourceRef = useRef(null);

	useEffect(() => {
		tokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(tokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		RequirementMatrixService.getParametersForInformation(tokenSourceRef?.current?.token)
			.then(setParameters)
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(
		() => {
			setFiltersPreviewRequest(() => (previewFilters) => {
				const call =
					(details && DashboardService.getDocumentProgressCount) ||
					(dashboardType === "user" && DashboardService.getUserProgressCount) ||
					DashboardService.getCompanyProgressCount;
				const newFilters = generatePayload({ ...generateFilters(previewFilters) });
				return call(
					{
						projectId,
						companyId,
						documentId: additionalFilters?.selectedDocument?.id,
						userId: additionalFilters.user.id,
					},
					{ Type: "InformationSearchFilterDTO", ...newFilters },
					tokenSourceRef.current.token
				).then((data) => data);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[projectId]
	);
	const handleApply = (newFilters) => {
		if (tokenSourceRef.current?.token) {
			const call =
				(details && getDocumentDetails) ||
				(dashboardType === "user" && getReviewDocuments) ||
				getCompanyDocuments;
			batch(() => {
				dispatch(setFilters(cleanFilters(newFilters)));
				DashboardContext.setContext(newFilters);
				dispatch(
					call({
						displayResult: true,
						userId: additionalFilters?.user?.id,
						documentId: additionalFilters?.selectedDocument?.id,
						filters: generateFilters(newFilters),
						token: tokenSourceRef.current.token,
					})
				);
			});
		}
	};
	const customLabelApply = (results) => {
		if (results?.requirements <= 1 && results?.requirements !== null) {
			return translate("common:component.filter.apply", { results: results?.requirements });
		}
		if (results?.requirements > 1) {
			return translate("common:component.filters.apply", { results: results?.requirements });
		}
		return translate("common:btn.apply");
	};

	return (
		<FiltersWrapper
			hasSearch
			hasSeparator
			addFilterButtonVariant="contained"
			className={details ? styles["filters__container--noSelector"] : styles.filters__container}
			components={[
				{
					enabled: true,
					component: CommonFilters.KEYWORDS,
					renderer: <KeywordFilter />,
				},
				{
					enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
					withDescription: false,
					multiSelection: true,
					labelKey: "label",
					valueKey: "key",
					component: CommonFilters.REQUIREMENT,
					hasIsSeparator: false,
				},
				{
					enabled: isSegFeatureEnabled(SegFlags.CATEGORY),
					component: CommonFilters.CATEGORIES,
					renderer: <ProjectCategoriesFilter disableFavoriteCategories />,
					hasFavorites: true,
				},
				{
					enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
					component: CommonFilters.CLIENT_REF,
				},
				{
					enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
					component: CommonFilters.TOC,
					renderer: <KeywordFilter />,
				},
				{
					enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
					component: CommonFilters.CRITICALITY,
					labelKey: "label",
					valueKey: "value",
					dynamicItems: parameters?.criticality?.map((c) => ({
						label: `common:enum.params.criticality.${c.toLowerCase()}`,
						value: c,
					})),
				},
				{
					enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
					component: CommonFilters.NEGOTIABILITY,
					labelKey: "label",
					valueKey: "value",
					dynamicItems: parameters?.negotiability?.map((n) => ({
						label: `common:enum.params.negotiability.${n.toLowerCase()}`,
						value: n,
					})),
				},
				{
					enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
					component: CommonFilters.TYPE,
					labelKey: "label",
					valueKey: "value",
					dynamicItems: parameters?.type?.map((t) => ({
						label: `common:enum.params.type.${t.toLowerCase()}`,
						value: t,
					})),
				},
			]}
			customLabelApply={customLabelApply}
			defaultFilters={filters}
			multiline={!details}
			previewRequest={filtersPreviewRequest}
			onApply={handleApply}
		/>
	);
}
